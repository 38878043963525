import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import Quote from '@zing/neo-common/dist/components/quote';
import QuoteNavigation from '@zing/neo-common/dist/components/quote/quote-navigation';
import Icon from '@zing/neo-common/dist/components/Icon';
import Button from 'hollywood/dist/components/Button';
import Header from '../../components/Header';
import BackButton from '../../components/buttons/back';
import { reEmailQuote } from '../../redux/session/actions';
import axios from 'axios';

/**
 * Quick page knocked u pto view a quote (without changing calcs as when viewing vie requote).
 * it aint pretty, it's not supposed to be functionaly, this is a view only quick quote page.
 *
 *
 * @param history
 * @param location
 * @returns {*}
 * @constructor
 */
const QuickViewQuote = ({ history, location, reEmailQuote, auth }) => {
  const [currentSection, setCurrentSection] = useState('your-details');
  const { quote = {}, oppUuid = '' } = location.state;
  const [resendQuoteProcessing, setresendQuoteProcessing] = useState(false);
  const [regenerateQuoteProcessing, setRegenerateQuoteProcessing] = useState(false);

  function resendQuote() {
    if (resendQuoteProcessing) return;
    setresendQuoteProcessing(true);
    reEmailQuote(oppUuid, quote.id);
    setTimeout(() => {
      setresendQuoteProcessing(false);
    }, 2000);
  }

  async function regenerateQuote() {
    if (regenerateQuoteProcessing) return;
    setRegenerateQuoteProcessing(true);
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/opportunities/quote/${quote.quote_reference}/pdf`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
        'X-Tablet': auth.access_token,
      },
    });

    const pdfUrl = window.URL.createObjectURL(new Blob([res.data]));
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.setAttribute('download', `${quote.quote_reference}_${quote.lastname}.pdf`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();

    setRegenerateQuoteProcessing(false);
  }

  const imageBaseUrl = process.env.REACT_APP_API_BASE_URL.replace(/\/api/, '/users/profiles/');

  return (
    <>
      <Header logo={false}>
        <div className="quote-header-buttons">
          <BackButton
            onClick={() => {
              history.go(-1);
            }}
          />
          {auth?.user?.role === 'Admin' && (
            <Button onClick={regenerateQuote} disabled={regenerateQuoteProcessing} loading={regenerateQuoteProcessing}>
              <Icon icon="download" /> Regenerate Quote
            </Button>
          )}
          <Button format="primary" onClick={resendQuote} disabled={resendQuoteProcessing} loading={resendQuoteProcessing}>
            Re-send Quote
          </Button>
        </div>
      </Header>
      <QuoteNavigation currentSection={currentSection} />
      <Formik
        initialValues={{
          ...quote,
          agreed_terms_and_conditions: Boolean(quote.agreed_terms_and_conditions),
          agreed_cooling_off_waiver: Boolean(quote.agreed_cooling_off_waiver),
        }}
        onSubmit={() => {}}
      >
        {() => (
          <Form>
            <Quote
              imageBaseUrl={imageBaseUrl}
              isSubmittingQuote={false}
              onClickAgreeAndSign={() => {}}
              setCurrentSection={setCurrentSection}
              quote={quote}
            />
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </>
  );
};

QuickViewQuote.propTypes = {
  history: PropTypes.shape({
    go: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      quote: PropTypes.shape({}).isRequired,
      oppUuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  auth: PropTypes.shape({
    access_token: PropTypes.string,
    user: PropTypes.shape(),
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
  reEmailQuote: PropTypes.func.isRequired,
};

export default connect(({ auth }) => ({ auth }), { reEmailQuote })(QuickViewQuote);
