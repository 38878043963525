import useCheckLNumber from './use-check-lnumber';
import useEncryptedDbReady from './use-encrypted-db-ready';
import useFetchPresenter from './use-fetch-presenter';
import useMatchOpportunity from './use-match-opportunity';
import useOpportunities from './use-opportunities';
import useOpportunity from './use-opportunity';
import useScrollToTop from './use-scroll-to-top';

const hooks = {
  useCheckLNumber,
  useEncryptedDbReady,
  useFetchPresenter,
  useMatchOpportunity,
  useOpportunities,
  useOpportunity,
  useScrollToTop,
};

export default hooks;
