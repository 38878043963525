import React, { useContext } from 'react';
import Toggle from '../../hollywood-xstate/toggle';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import FieldError from '../../hollywood-xstate/field-error';

const ExternalKitLocation = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { comparisons, currentComparison } = current.context;
  const { pimComparison } = comparisons[currentComparison];

  return (
    <>
      <fieldset>
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Is the planned kit location to be external?</p>
          </Col>
          <Col width="fixed" className="right-col">
            <Toggle
              name="context.externalKitLocation"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
              goodOption="no"
              badOption="yes"
              value={current.context.externalKitLocation}
              disabled={!pimComparison.getJourney().hasSolarPV}
              onChange={(_, value) => {
                send({ type: 'IS_KIT_LOCATION_EXTERNAL', value, currentComparison });
              }}
            />
            <FieldError field="context.externalKitLocation" />
          </Col>
        </Grid>
      </fieldset>
    </>
  );
};

ExternalKitLocation.propTypes = {};

ExternalKitLocation.defaultProps = {};

export default ExternalKitLocation;
