export const packageMappings = [
  { id: 3, origName: 'Energise 325', plytixProdId: '' }, // deliberately empty..
  { id: 10, origName: 'Optimise 325', plytixProdId: '' },
  { id: 13, origName: 'Energise 305', plytixProdId: '' },
  { id: 15, origName: 'Energise 325', plytixProdId: '' },
  { id: 16, origName: 'Energise 325', plytixProdId: '' },
  { id: 19, origName: 'Optimise 305', plytixProdId: '' },
  { id: 21, origName: 'Optimise 325', plytixProdId: '' },
  { id: 23, origName: 'Viridian Energise 270', plytixProdId: '' },
  { id: 24, origName: 'Viridian Energise 300', plytixProdId: '' },
  { id: 25, origName: 'Viridian Optimise 270', plytixProdId: '' },
  { id: 26, origName: 'Viridian Optimise 300', plytixProdId: '' },
  { id: 29, origName: 'Optimise', plytixProdId: 'OPTIMISE-PKG' },
  { id: 30, origName: 'Viridian Optimise', plytixProdId: 'VIR-OPTIMISE-PKG' },
  { id: 31, origName: 'Optimise Max', plytixProdId: 'OPTIMISE-MAX-PKG' },
  { id: 32, origName: 'Viridian Optimise Max', plytixProdId: 'VIR-OPTIMISE-MAX-PKG' },
  { id: 33, origName: 'Viridian Energise', plytixProdId: 'VIR-ENERGISE-PKG' },
  { id: 34, origName: 'Energise', plytixProdId: 'ENERGISE-PKG' },
  { id: 35, origName: 'Viridian Energise Max', plytixProdId: 'VIR-ENERGISE-MAX-PKG' },
  { id: 36, origName: 'Energise Max', plytixProdId: 'ENERGISE-MAX-PKG' },
];

/**
 * @depracated  see UKSQA-1296
 */
// export const allowedPlytixProductPackageIds = [
//   'OPTIMISE-PKG',
//   'VIR-OPTIMISE-PKG',
//   'OPTIMISE-MAX-PKG',
//   'VIR-OPTIMISE-MAX-PKG',
//   'VIR-ENERGISE-PKG',
//   'ENERGISE-PKG',
//   'VIR-ENERGISE-MAX-PKG',
//   'ENERGISE-MAX-PKG',
//   'BATTERY-ONLY-PKG',
// ];
