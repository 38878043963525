import * as Yup from 'yup';
import {
  MSG_ADDRESS_LINE_1_REQUIRED,
  MSG_CITY_REQUIRED,
  MSG_EMAIL_INVALID,
  MSG_EMAIL_REQUIRED,
  MSG_FIRST_NAME_REQUIRED,
  MSG_LAST_NAME_REQUIRED,
  MSG_MOBILE_NUMERIC,
  MSG_PHONE_NUMERIC,
  MSG_SIGNATURE_IS_REQUIRED,
} from '../../../../config';

require('@zing/neo-common/dist/lib/yup');

const allowSpaces = true;

export default Yup.object().shape({
  email_pdf_copy: Yup.boolean(),
  signature: Yup.string().required(MSG_SIGNATURE_IS_REQUIRED),
  // agreed_g99_application_signature: Yup.boolean().oneOf([true], MSG_SIGNATURE_IS_REQUIRED),
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_EMAIL_REQUIRED),
  mobile: Yup.mixed().isEmptyOrNumeric(MSG_MOBILE_NUMERIC, allowSpaces),
  telephone: Yup.mixed().isEmptyOrNumeric(MSG_PHONE_NUMERIC, allowSpaces),
  // standard address (API and UI field names do not match)
  address_line_1: Yup.string().required(MSG_ADDRESS_LINE_1_REQUIRED),
  city: Yup.string().required(MSG_CITY_REQUIRED),
  postcode: Yup.string().isValidPostcode(),
  firstname: Yup.string().required(MSG_FIRST_NAME_REQUIRED),
  lastname: Yup.string().required(MSG_LAST_NAME_REQUIRED),
});
