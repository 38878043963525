import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Actions from './Actions';
import connect from 'react-redux/es/connect/connect';
import EmailPreview from '../../../../components/EmailPreview';
import Header from '../../../../components/Header';
import useFetchQuoteEmailTemplate from '../../../../hooks/use-fetch-email-template';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { Checkbox } from 'hollywood/dist/components/forms';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { customerEmail, appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { emailQuote, sessionEnd } from '../../../../redux/session/actions';
import { Form, Formik } from 'formik';
import { getArray } from '@zing/neo-common/dist/lib/safe';
import { getContextCurrentComparison } from '../../../../machines/survey.machine';
import { onSubmitEmailQuote } from './on-submit-email-quote';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import initialPimState from '../../../../redux/pim-session/initialstate';

import './email-quote.scss';

const PimEmailQuote = ({ match, emailQuote, quoteEmailTemplate, pimSessionRedux }) => {
  const [pimSession, setPimSession] = useLocalStorage('pimSession');
  const { opportunity, surveyMachine } = pimSession;
  const quotes = getArray(pimSessionRedux, 'quotes');
  const currentComparisonContext = getContextCurrentComparison(surveyMachine);
  const [processing, setProcessing] = useState(false);
  const [quoteSent, setQuoteSent] = useState(false);
  const { template = null } = quoteEmailTemplate;
  const history = useHistory();

  const initialValues = {
    customer_email: customerEmail(opportunity),
    cc_email: '',
    bcc_email: '',
    subject: 'Your E.ON Solar Quote',
    quotes: quotes.map(quote => ({
      app_uuid: appUuid(quote),
      should_email_quote: true,
      customer_package_name: quote.customer_package_name,
    })),
  };

  useFetchQuoteEmailTemplate(template);

  const onSubmit = onSubmitEmailQuote(quotes, setProcessing, setQuoteSent, emailQuote, appUuid(opportunity));

  const finishSession = () => {
    setPimSession(initialPimState);
    sessionEnd();
    history.push('/opportunities');
  };

  return (
    <>
      <Header title="Email the quote" />
      <main className="email-quote" data-testid="email-quote">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={onSubmit}
          render={({ values, submitForm }) => {
            const hasQuotesToEmail = values.quotes.some(x => x.should_email_quote);
            return (
              <>
                <Grid className="page-grid" gutters="md" align="fill">
                  <Col>
                    <Form>
                      <TextField name="customer_email" title="Confirm customer email" />
                      <TextField name="cc_email" title="CC email" />
                      <TextField name="bcc_email" title="BCC email" />
                      <h3>Select quotes to send</h3>
                      <hr />
                      <fieldset>
                        {values.quotes.map((quote, index) => (
                          <Checkbox
                            label={quote.customer_package_name}
                            name={`quotes[${index}].should_email_quote`}
                            id={`quotes[${index}].should_email_quote`}
                            testId={`quotes[${index}].should_email_quote`}
                          />
                        ))}
                        <TextField name="subject" title="Subject line" testId="subject" />
                      </fieldset>
                    </Form>
                  </Col>
                  <Col width="auto">
                    {template && (
                      <EmailPreview
                        template={template}
                        data={{
                          opportunity: {
                            ...opportunity,
                            installation_type: currentComparisonContext?.solarPvPackage?.name || '',
                          },
                        }}
                        width={380}
                        height={535}
                      />
                    )}
                  </Col>
                </Grid>
                <Actions
                  match={match}
                  submitForm={submitForm}
                  finishSession={finishSession}
                  loading={processing}
                  quoteSent={quoteSent}
                  disabled={values.customer_email.length === 0 || !hasQuotesToEmail}
                />
              </>
            );
          }}
        />
      </main>
    </>
  );
};

PimEmailQuote.propTypes = {
  match: PropTypes.shape({}).isRequired,
  emailQuote: PropTypes.func.isRequired,
  quoteEmailTemplate: PropTypes.func.isRequired,
  pimSessionRedux: PropTypes.func.isRequired,
};

export default connect(({ quoteEmailTemplate, pimSession }) => ({ quoteEmailTemplate, pimSessionRedux: pimSession }), {
  emailQuote,
  sessionEnd,
})(PimEmailQuote);
