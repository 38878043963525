export const initialState = {
  unencrypted_data: null,
  encrypted_data: null,
  refresh_app_data: {
    status: null,
    last_updated: null,
  },
  refresh_resources: {
    status: null,
    last_updated: null,
  },
  photo_upload: {
    status: null,
    last_updated: null,
  },
  remove_photo: {
    status: null,
    last_updated: null,
  },
  editable_user_details: null,
};
