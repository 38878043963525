// import PropTypes from 'prop-types';
import React from 'react';
import DiagnosticsHeader from '../../../components/diagnostics-header';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '@zing/neo-common/dist/components/Icon';
import Button from 'hollywood/dist/components/Button';
import { api } from '@zing/neo-common/dist/lib/api';

import './voucher-codes.scss';

const CSV_TEMPLATE = `"Description","Voucher Type","Code","Single Use?","Available From","Available To","Active?","Discount"
"A test 25% voucher code.","percentage","TEST25OFF","0","2024-04-26","2024-05-01","1","25"
"A test single use £300 voucher code","fixed","TEST300","1","2024-04-26","2024-05-01","1","300"`;

const VoucherCodesImportPage = ({ auth }) => {
  const [file, setFile] = React.useState(null);
  const [state, setState] = React.useState('IDLE');

  const inputRef = React.useRef(null);

  async function uploadFile() {
    const fileData = inputRef.current.files[0];
    if (!fileData || state === 'UPLOADING') return;

    setState('UPLOADING');

    try {
      const formData = new FormData();
      formData.append('file', fileData);
      const response = await api({
        accessToken: auth.access_token,
        url: `/voucher/management/upload`,
        method: 'POST',
        data: formData,
      });
      const data = response?.data;
      console.log(data);
      setState('COMPLETE');
    } catch (error) {
      console.error(error);
      setState('ERROR');
    }
  }

  return (
    <>
      <DiagnosticsHeader title="Voucher Codes" />
      <main className="voucher-codes">
        <div className="voucher-codes__import">
          <h3>Import voucher codes</h3>

          {state === 'UPLOADING' && <p>Uploading...</p>}

          {state === 'COMPLETE' && (
            <div>
              <h4>Import complete!</h4>
              <Button format="primary" type="button" onClick={() => setState('IDLE')}>
                Upload another file
              </Button>
            </div>
          )}

          {state === 'ERROR' && (
            <div>
              <h4>Error</h4>
              <p>Something went wrong whilst uploading your file.</p>
              <Button format="primary" type="button" onClick={() => setState('IDLE')}>
                Try again
              </Button>
            </div>
          )}

          {state === 'IDLE' && (
            <>
              <p className="tight">Upload a CSV containing voucher codes.</p>
              <p>
                The columns in your csv should match{' '}
                <a className="eon-red" href={encodeURI(`data:text/csv;charset=utf-8,${CSV_TEMPLATE}`)} download="voucher-codes.csv">
                  this template
                </a>
                .
              </p>
              {file ? (
                <>
                  <p>
                    <strong className="lg">{file.name}</strong>
                    <button
                      type="button"
                      onClick={() => {
                        setFile(null);
                        inputRef.current.value = '';
                      }}
                      style={{ fontSize: 10, display: 'inline-block', marginLeft: 5, verticalAlign: 'text-top' }}
                    >
                      <Icon icon="trash" />
                    </button>
                  </p>
                  <Button format="primary" size="small" onClick={() => uploadFile()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      width="1.25em"
                      height="1.25em"
                      style={{ display: 'inline-block', verticalAlign: 'text-top', marginRight: '0.5em' }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                      />
                    </svg>
                    Import Codes
                  </Button>
                </>
              ) : (
                <label htmlFor="file" className="hollywood-btn hollywood-btn--tertiary hollywood-btn--small">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    width="1.25em"
                    height="1.25em"
                    style={{ display: 'inline-block', verticalAlign: 'text-top', marginRight: '0.5em' }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                    />
                  </svg>
                  Choose File
                </label>
              )}
              <input
                type="file"
                name="file"
                id="file"
                accept=".csv"
                ref={inputRef}
                onChange={e => {
                  setFile(e.currentTarget?.files?.[0]);
                }}
                style={{ display: 'none' }}
              />
            </>
          )}
        </div>
      </main>
    </>
  );
};

VoucherCodesImportPage.propTypes = {
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({ auth }) => ({ auth }))(VoucherCodesImportPage);
