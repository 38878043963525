import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { createActiveRetiredProductsOptionList } from '../../utils/option-list-active-retired-products';

const Inverter = () => {
  const {
    current: {
      context: { comparisons, currentComparison },
    },
    send,
  } = useContext(PimSurveyMachineContext);
  const { pimComparison } = comparisons[currentComparison];

  const inverterOptions = createActiveRetiredProductsOptionList(pimComparison.getInverters(), 'id');

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor={`context.comparisons.${currentComparison}.inverter`}>Solar PV inverter option</label>
        </Col>
        <Col width="fixed" className="right-col">
          <Select
            name={`context.comparisons.${currentComparison}.inverter`}
            disabled={!pimComparison.getJourney().hasSolarPV}
            value={pimComparison.getInverter()?.prodId}
            options={inverterOptions}
            onChange={(_, prodId) => {
              if (prodId) {
                send({ type: 'SELECT_INVERTER', currentComparison, prodId });
              } else {
                send({ type: 'UNSELECT_INVERTER', currentComparison });
              }
            }}
          />
          <FieldError field={`context.comparisons.${currentComparison}.inverter`} />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default Inverter;
