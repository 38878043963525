import { appointmentMatchUrl } from '../../../../utils/opportunity';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { opportunityMatchQuoteId } from '@zing/neo-common/dist/redux/opportunity/accessor';
import BackButton from '../../../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import PropTypes from 'prop-types';
import React from 'react';

const Actions = ({ match, submitForm, finishSession, loading, disabled, quoteSent }) => (
  <div className="actions">
    <Grid valign="center" align="right">
      <Col width="auto">
        <BackButton link={appointmentMatchUrl(match, ['survey', 'quote', opportunityMatchQuoteId(match)])} />
        <Button
          disabled={disabled}
          loading={loading}
          format="primary"
          testId="send_email"
          onClick={() => {
            submitForm();
          }}
        >
          Send email
        </Button>
        {quoteSent && (
          <Button format="secondary" onClick={finishSession}>
            Finish Session
          </Button>
        )}
      </Col>
    </Grid>
  </div>
);

Actions.propTypes = {
  match: PropTypes.shape({}).isRequired,
  submitForm: PropTypes.func.isRequired,
  finishSession: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  quoteSent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Actions;
