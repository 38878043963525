import { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { useService } from '@xstate/react';

const useCustomerSearchService = () => {
  const app = useContext(AppContext);
  const [appState] = useService(app);
  return useService(appState?.context?.customerSearch);
};

export default useCustomerSearchService;
