import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'hollywood/dist/components/Button';
import classnames from 'classnames';
import { createdDateShort } from '@zing/neo-common/dist/redux/opportunity/accessor';
import PimSelectModal from '../../../../pim-select-modal';

const PipelineTableRowComponent = ({ api, businessLogic, isAdmin, quote, shaded, testId, viewQuote, requoteOpportunity }) => {
  const [showPimSelectionModal, setShowPimSelectionModal] = useState(false);

  const logic = businessLogic(quote);

  return (
    <>
      <div className={classnames('pipeline_table_row', 'pipeline_table_quote_row', shaded ? 'shaded' : '')} data-testid={testId}>
        <div className={classnames('pipeline_table_cell', 'tab_col')}>&nbsp;</div>
        <div className="pipeline_table_cell date_col" data-testid="created">
          {createdDateShort(quote)}
        </div>
        <div className="pipeline_table_cell quote_reference_col" data-testid="quote_reference">
          {quote.quote_reference}
        </div>
        <div className="pipeline_table_cell sales_consultant_name_col" data-testid="sales_consultant_name">
          {logic.salesConsultantDisplayName(quote)}
        </div>
        <div className="pipeline_table_cell signed_and_agreed_at_col" data-testid="signed_and_agreed_at">
          {logic.agreed() && (
            <>
              {logic.signedAndAgreedAt(quote)}
              {isAdmin && (
                <>
                  <br />
                  <span className="bold">By {logic.signedBy()}</span>
                </>
              )}
            </>
          )}

          {logic.disabled() && <span className="bold">Disabled</span>}

          {!logic.disabled() && !logic.agreed() && isAdmin && (
            <Button format="secondary" size="small" type="button" onClick={() => api.setAsSigned(quote.id)}>
              Set to signed
            </Button>
          )}
        </div>
        <div className="pipeline_table_cell actions_col">
          <Button
            format="secondary"
            size="small"
            type="button"
            onClick={() => {
              setShowPimSelectionModal(true);
            }}
          >
            Requote
          </Button>
          <Button format="primary" size="small" type="button" onClick={() => viewQuote(quote)}>
            View
          </Button>
        </div>
      </div>
      {showPimSelectionModal && (
        <PimSelectModal
          currentPimUuid={quote.pim_uuid}
          isOpen={showPimSelectionModal}
          onCancel={() => setShowPimSelectionModal(false)}
          onSelection={() => requoteOpportunity(quote)}
        />
      )}
    </>
  );
};

PipelineTableRowComponent.propTypes = {
  api: PropTypes.shape({
    setAsSigned: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  businessLogic: PropTypes.shape({}).isRequired,
  quote: PropTypes.shape({
    id: PropTypes.number.isRequired,
    quote_signed_by: PropTypes.string,
    agreed: PropTypes.bool,
    created_at: PropTypes.string.isRequired,
    quote_reference: PropTypes.string.isRequired,
    signed_and_agreed_at: PropTypes.string,
    pim_uuid: PropTypes.string,
  }).isRequired,
  shaded: PropTypes.bool.isRequired,
  testId: PropTypes.string.isRequired,
  requoteOpportunity: PropTypes.func.isRequired,
  viewQuote: PropTypes.func.isRequired,
};

export default PipelineTableRowComponent;
