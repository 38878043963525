import { sendParent } from 'xstate';

/**
 * Should be used by all children to send TOAST notifications to parent.
 * Keep the messages short and meaningful.
 *
 * When enough of the app is converted to state machines, we will add a TOAST service
 * that listens and displays the messages.
 *
 * I have made the params an object, in case we later want to pass in icons, button config etc.
 */
export const sendToastMessage = ({ message }) => sendParent({ type: 'TOAST', message });
