import React, { useEffect } from 'react';
import { sortChange } from '../utils';
import { useToast } from '@zing/neo-common/dist/components/Toast';
import classnames from 'classnames';
import DashboardNav from '../../dashboard-nav';
import FailedQuery from '../components/failed-query';
import NoResults from '../components/no-results';
import PipelineSearchForm from '../components/search-form';
import PipelineTableHeader from './opportunities/pipeline-table-header';
import PipelineTableRow from './opportunities/pipeline-table-row';
import PropTypes from 'prop-types';
import useConstant from 'use-constant';
import useEncryptedDbReady from '../../../hooks/use-encrypted-db-ready';
import usePipelineSearchService from '../../../hooks/use-pipeline-service';

/**
 * Pipeline search page
 * for the customer search see `../customer-page`
 */

const PipelinePage = ({ isAdmin, visitOpportunity, salesConsultant }) => {
  const [state, send] = usePipelineSearchService();
  const { sortBy, sortOrder, opportunities } = state.context;

  const api = useConstant(() => ({
    reset: () => send('RESET'),
    retry: () => send('RETRY'),
    search: () => send('SEARCH'),
    updateDateTo: value => send('UPDATE_DATE_TO', { value }),
    updateDateFrom: value => send('UPDATE_DATE_FROM', { value }),
    updateQuery: value => send('UPDATE_QUERY', { value }),
  }));

  const canShowListView = (state.matches('online.local.listView') || state.matches('offline.listView')) && Array.isArray(opportunities);
  const isOffline = state.matches('offline');

  useEncryptedDbReady(neoDb => {
    // window.neoDb = neoDb;
    send('INDEXED_DB_READY', { neoDb });
  }, []);

  // This is now with the page because we want to switch between them
  // make sure they down popup when we switch modes...!
  const [Toast, openToast, closeToast] = useToast({
    title: 'Offline Search',
    body: 'Search is limited to data already on your device, when you are working offline',
    icon: 'alert',
    action: {
      label: 'Got it',
      fn: close => {
        // The close function is passed into this callback.
        // You can do other stuff here before closing the toast (or not close it at all!)
        // eg. Promise stuff.. doSomethingAsync().then(close)
        // console.log('close the toast!');
        close();
      },
    },
  });

  // Display offline message or start the search
  useEffect(() => {
    if (isOffline) {
      return openToast();
    }
    return closeToast();
  }, [isOffline]);

  const getSortingClasses = field => classnames('sortable', sortBy === field ? sortOrder.toLowerCase() : null);

  const changeSorting = field => {
    sortChange(
      field,
      sortBy,
      value => send('UPDATE_SORT_BY', { value }),
      sortOrder,
      value => send('UPDATE_SORT_ORDER', { value })
    );
  };

  return (
    <>
      <Toast />
      <main className="pipeline shaded" data-testid="pipeline_page">
        <>
          <PipelineSearchForm values={state.context} api={api} />
          {canShowListView && (
            <div className="pipeline_table" data-testid="pipeline_table">
              <PipelineTableHeader isAdmin={isAdmin} changeSorting={changeSorting} getSortingClasses={getSortingClasses} />
              {opportunities.map((item, idx) => (
                <PipelineTableRow
                  isAdmin={isAdmin}
                  key={item.name}
                  item={item}
                  salesConsultant={salesConsultant}
                  shaded={idx % 2 !== 0}
                  testId={`pipeline_entry_${idx}`}
                  visitOpportunity={visitOpportunity}
                />
              ))}
            </div>
          )}
          {canShowListView && opportunities.length === 0 && <NoResults api={api} />}
          {state.matches('online.failure') && <FailedQuery api={api} />}
        </>
      </main>
      <DashboardNav />
    </>
  );
};

PipelinePage.propTypes = {
  salesConsultant: PropTypes.shape({}).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  visitOpportunity: PropTypes.func.isRequired,
};

export default PipelinePage;
