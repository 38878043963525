import { checkAuth } from './checkAuth';
import { get } from '@zing/neo-common/dist/lib/safe';
import { Redirect, Route } from 'react-router-dom';
import { storeCurrentUrl } from './storeSessionCurrentUrl';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export const whitelistedPaths = [
  '/opportunity/:id/:meetingId/thank-you-for-meeting-us',
  '/opportunity/:id/:meetingId/before-we-start',
  '/opportunity/:id/:meetingId/presenter',
  '/opportunity/:id/:meetingId/survey/property-details',
  '/opportunity/:id/:meetingId/survey/energy-details',
  '/opportunity/:id/:meetingId/survey/solar-pv',
  '/opportunity/:id/:meetingId/survey/battery',
  '/opportunity/:id/:meetingId/survey/ev-charging',
  '/opportunity/:id/:meetingId/survey/g99',
  '/opportunity/:id/:meetingId/g99-application',
  '/opportunity/:id/:meetingId/survey/additional-products',
  '/opportunity/:id/:meetingId/survey/installation-notes',
  '/opportunity/:id/:meetingId/terms-and-conditions/:packageId?/:quoteId?',
  '/opportunity/:id/:meetingId/your-package/:packageId?',
  '/opportunity/:id/:meetingId/payment-options/:packageId?',
  '/opportunity/:id/:meetingId/quote/:packageId/:quoteId',
  '/opportunity/:id/:meetingId/email-quote/:packageId/:quoteId',
  '/opportunity/:id/:meetingId/thank-you-for-choosing-eon/:packageId/:quoteId',
];

export class AuthRoute extends Component {
  componentDidUpdate() {
    const { computedMatch, location, sessionCurrentUrl, session, path } = this.props;
    const newUrl = get(location, 'pathname');
    const prevUrl = get(session, 'survey.current_url');

    if (prevUrl === newUrl) {
      return;
    }

    if (!whitelistedPaths.includes(path)) {
      return;
    }

    storeCurrentUrl(computedMatch, prevUrl, newUrl, sessionCurrentUrl);
  }

  render() {
    const { auth, component, sessionCurrentUrl, session, ...rest } = this.props;
    const result = checkAuth(auth);

    if (!result.valid) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              redirect: window.location.pathname, // so we can add a auth redirect on the login page,
              auth: result,
            },
          }}
        />
      );
    }

    return <Route {...rest} component={component} />;
  }
}

AuthRoute.propTypes = {
  auth: PropTypes.shape({}).isRequired,
  computedMatch: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  path: PropTypes.string.isRequired,
  session: PropTypes.shape({}).isRequired,
  sessionCurrentUrl: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired,
};
