export const SETTINGS_RESET = 'SETTINGS_RESET';
export const SETTINGS_ADD_ENCRYPTED_DATA = 'SETTINGS_ADD_ENCRYPTED_DATA';
export const SETTINGS_ADD_UNENCRYPTED_DATA = 'SETTINGS_ADD_UNENCRYPTED_DATA';

export const SETTINGS_REFRESH_APP_DATA = 'SETTINGS_REFRESH_APP_DATA';
export const SETTINGS_REFRESH_RESOURCES = 'SETTINGS_REFRESH_RESOURCES';

export const SETTINGS_PROFILE_IMAGE_UPLOAD = 'SETTINGS_PROFILE_IMAGE_UPLOAD';
export const SETTINGS_PROFILE_IMAGE_UPLOAD_REQUEST = 'SETTINGS_PROFILE_IMAGE_UPLOAD_REQUEST';
export const SETTINGS_PROFILE_IMAGE_UPLOAD_COMMIT = 'SETTINGS_PROFILE_IMAGE_UPLOAD_COMMIT';
export const SETTINGS_PROFILE_IMAGE_UPLOAD_ROLLBACK = 'SETTINGS_PROFILE_IMAGE_UPLOAD_ROLLBACK';

export const SETTINGS_PROFILE_IMAGE_REMOVE = 'SETTINGS_PROFILE_IMAGE_REMOVE';
export const SETTINGS_PROFILE_IMAGE_REMOVE_REQUEST = 'SETTINGS_PROFILE_IMAGE_REMOVE_REQUEST';
export const SETTINGS_PROFILE_IMAGE_REMOVE_COMMIT = 'SETTINGS_PROFILE_IMAGE_REMOVE_COMMIT';
export const SETTINGS_PROFILE_IMAGE_REMOVE_ROLLBACK = 'SETTINGS_PROFILE_IMAGE_REMOVE_ROLLBACK';

export const SETTINGS_UPDATE_USER = 'SETTINGS_UPDATE_USER';
export const SETTINGS_UPDATE_USER_REQUEST = 'SETTINGS_UPDATE_USER_REQUEST';
export const SETTINGS_UPDATE_USER_COMMIT = 'SETTINGS_UPDATE_USER_COMMIT';
export const SETTINGS_UPDATE_USER_ROLLBACK = 'SETTINGS_UPDATE_USER_ROLLBACK';

export const SETTINGS_UPDATE_EDITABLE_USER_DETAILS = 'SETTINGS_UPDATE_EDITABLE_USER_DETAILS';
export const SETTINGS_RESET_EDITABLE_USER_DETAILS = 'SETTINGS_RESET_EDITABLE_USER_DETAILS';
