import React, { useContext } from 'react';
import { UserContext } from '../../contexts/user-context';
import BespokePricing from '../../components/bespoke-pricing';
import Inverter from '../../components/inverter';
import IsRosemaryOrSlateRoofPreset from '../../components/is-rosemary-slate-roof-preset';
import MeterType from '../../components/meter-type';
import Panel from '../../components/panel';
import ProductTables from '../../components/product-tables';
import Roofs from './components/roofs';
import ExternalKitLocation from '../../components/external-kit-location';
import Scaffolding from '../../components/scaffolding';
import SolarPackage from '../../components/solar-package';
import SurveyFooter from '../../components/survey-footer';
import SystemTotals from './components/system-totals';
import TrenchingRequirementMeters from '../../components/trenching-requirement-meters';

import './solar-pv.scss';

const SolarPv = () => {
  const user = useContext(UserContext);
  const userIsAdmin = user.role === 'Admin';

  return (
    <main className="survey-page solar-pv" data-testid="solar-pv">
      <h3>PV Package details</h3>
      <SolarPackage />
      <Panel />
      <Roofs />
      <SystemTotals />
      <Inverter />
      <h3>House details</h3>
      <MeterType />
      <TrenchingRequirementMeters />
      <IsRosemaryOrSlateRoofPreset />
      <ExternalKitLocation />
      <Scaffolding userIsAdmin={userIsAdmin} />
      <BespokePricing />
      <SurveyFooter />
      <ProductTables />
    </main>
  );
};

export default SolarPv;
