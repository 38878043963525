import { clamp } from '@zing/neo-common/dist/lib/number';
import { get, getNumber, toFloat, toInt } from '@zing/neo-common/dist/lib/safe';
import { avoidedImportsSubcalculation } from './utils/avoided-imports-subcalculation';
import { compoundDegradation } from './utils/compound-degradation';

/**
 *
 * @param exportRateP 0.0 - 1.0 percentage value
 * @param rpiP 0.0 - 1.0 percentage value
 * @param year 1 - 25
 * @returns {number}
 */
// eslint-disable-next-line no-restricted-properties
export const calcExportRate = (exportRateP, rpiP, year) => parseFloat((exportRateP * Math.pow(1 + parseFloat(rpiP), year - 1)).toFixed(4)); // export rate

/**
 * Calculates the export income (delta) from combined and existing sub calcs
 *
 * @comment export income - export kwh = pv generation - self consumption (from avoided imports)
 * @param avoidedImportsCombined
 * @param avoidedImportsExisting
 * @param exportRate
 * @returns {*}
 */
export const calcExportIncome = (avoidedImportsCombined, avoidedImportsExisting, exportRate) =>
  clamp(parseFloat(avoidedImportsCombined.exportKwh - avoidedImportsExisting.exportKwh) * parseFloat(exportRate), 0, Number.MAX_VALUE);

export const cashFlowFirstYear = cashFlow => get(cashFlow, '[0]', {});
export const cashFlowLastYear = cashFlow => get(cashFlow, '[24]', {});

/*
 annualGeneration: 3475,						-
 avoidedImport: 2270.1,						- changes to the installed new system avoided imports
 exportRate: 0.01,
 electricityPrice: 0.13,
 exportIncome: 11.799000000000001,			- based on new system only
 avoidedImportSaving: 295.113,				- based on new system
 annualTotalBenefit: 306.912,				- based on new system
 cumulativeTotalBenefit: 306.912,			- based on new system
 co2EmissionSavings: 1.42325575,				- based on new system
 co2EmissionsFromGrid: 2.26574124,			- based on new system
 co2Emissions: 0.8424854900000001,			- based on new system
 currentExpectedSpend: 719.16,				- based on current electricity bill
 currentExpectedSavings: 295.113,			- based on new system
 projectedSpend: 424.04699999999997,			- based on new system (bill - new system)
 selfConsumption: 0.658,						- based on new system installed (full system - old system)
 selfSufficiency: 0.3133333333333333 },		- based on new system
*/

export const cashFlow = (
  forYears,
  pvAnnualGenerationKwh,
  importRate,
  existingPvAnnualGenerationKwh,
  calcSettings = 0,
  isPvPackage = false,
  isBatteryPackage = false,
  hasExistingPv = false,
  outDuringTheDay = true,
  estimatedElectricityDemand,
  batteryCapacityKwh,
  degreesFromSouth, // todo: we can remove this param
  degradationMatrix = null,
  decisions = {},
  gridEmissionFactor
) => {
  const exportRateCalcSettings = calcSettings.exportRate > 0 ? calcSettings.exportRate / 100 : 0;
  // const rpi = calcSettings.rpi > 0 ? calcSettings.rpi / 100 : 0;
  const rpi = calcSettings.rpi > 0 ? calcSettings.rpi : 0; // UKSQA-1373 - this value is already divided by 100 when saved, no need to divide again here
  const years = clamp(toInt(forYears), 1, 100);

  const cashFlow = [];

  // const productDegradationMatrix = ['2.5', '0.82']; // external data - product specific degradation matrix values
  const degradation = compoundDegradation(degradationMatrix || [], years); // degradation in the first year; degradation in subsequent years

  const ePi = getNumber(calcSettings, 'electricityPriceInflation') / 100;
  // var dEp = DEEMED_EXPORT_PERCENTAGE;
  const gEr = gridEmissionFactor;

  let tCb = 0; // cumulative total benefit

  // for the number of years required
  for (let i = 1; i <= years; i += 1) {
    const annualPvGenerationWithDegradation = toFloat(pvAnnualGenerationKwh) * toFloat(degradation[i - 1]); // calculate annual PV generation with degradation

    // PERFORM SUB-CALCS TWICE - with/without new system
    // avoided imports - combined systems
    const avoidedImportsCombined = avoidedImportsSubcalculation(
      isPvPackage,
      isBatteryPackage,
      hasExistingPv,
      outDuringTheDay,
      annualPvGenerationWithDegradation,
      existingPvAnnualGenerationKwh,
      estimatedElectricityDemand,
      batteryCapacityKwh,
      decisions
    );

    // avoided imports - existing system only
    const avoidedImportsExisting = avoidedImportsSubcalculation(
      false,
      false,
      hasExistingPv,
      outDuringTheDay,
      0,
      existingPvAnnualGenerationKwh,
      estimatedElectricityDemand,
      batteryCapacityKwh,
      decisions
    );

    // avoided imports
    const aIp = avoidedImportsCombined.electricitySourcedFromOwnPv - avoidedImportsExisting.electricitySourcedFromOwnPv; // avoided imports (difference between systems)
    // eslint-disable-next-line no-restricted-properties
    // 1.025
    const exportRate = calcExportRate(exportRateCalcSettings, rpi, i);

    // eslint-disable-next-line no-restricted-properties
    const ePr = parseFloat((parseFloat(importRate) * Math.pow(1 + parseFloat(ePi), i - 1)).toFixed(3)); // calculate annual electricity import price with inflation (to 3dp)
    const eXi = calcExportIncome(avoidedImportsCombined, avoidedImportsExisting, exportRate);

    const aIs = parseFloat(aIp) * parseFloat(ePr); // avoided import saving
    const aTb = parseFloat(eXi) + parseFloat(aIs); // annual total benefit
    tCb += aTb; // cumulative total benefit
    const cTb = tCb;

    const cOs = parseFloat(avoidedImportsCombined.totalPvGenerationKwh) * (parseFloat(gEr) / 1000); // CO2 total grid benefit of combined system
    const cOb = parseFloat(avoidedImportsExisting.importKwh) * (parseFloat(gEr) / 1000); // CO2 emissions BEFORE INSTALL (i.e. for existing system only)
    const cOa = parseFloat(avoidedImportsCombined.importKwh) * (parseFloat(gEr) / 1000); // CO2 emissions AFTER INSTALL (i.e. for combined existing AND new system)

    const cEs = parseFloat(estimatedElectricityDemand) * parseFloat(ePr); // quote graph - red section - Current expected spend
    const eXs = aIs; // quote graph - yellow section - Expected savings
    const pSp = parseFloat(cEs) - parseFloat(eXs); // quote graph - blue section - Projected spend
    const sCr = avoidedImportsCombined.selfConsumptionRatio;
    const sSr = avoidedImportsCombined.selfSufficiencyRatio;
    const eDk = avoidedImportsCombined.estimatedDemandKwh;

    cashFlow[i - 1] = {
      year: i.toString(),
      annualGeneration: annualPvGenerationWithDegradation,
      avoidedImport: aIp,
      exportRate,
      electricityPrice: ePr,
      exportIncome: eXi,
      avoidedImportSaving: aIs,
      annualTotalBenefit: aTb,
      cumulativeTotalBenefit: cTb,
      co2EmissionSavings: cOs, // emissions saved by PV generation
      co2EmissionsFromGrid: cOb, // total emissions BEFORE installation
      co2Emissions: cOa, // total emissions AFTER installation
      currentExpectedSpend: cEs, // estimatedElectricityDemand * ePr = graph - red (current expected spend) - cumulative -- SHOULD equal cTb + aIs
      currentExpectedSavings: eXs, // aIs = graph yellow - cumulative
      projectedSpend: pSp, // red - yellow = blue (projected spend) - cumulative
      selfConsumption: sCr, // self consumption percentage
      selfSufficiency: sSr, // self sufficiency percentage
      estimatedDemandKwh: eDk, // estimated demand (from reverse lookup)
      totalCombinedAnnualPvGeneration: Number(annualPvGenerationWithDegradation) + Number(existingPvAnnualGenerationKwh),
      combinedSelfConsumptionKwh: avoidedImportsCombined.selfConsumptionRatio * avoidedImportsCombined.totalPvGenerationKwh,
    };
  }

  return cashFlow;
};

// EXCEL NOTES
/*
   This function returns cashflow values for a given product selection

   ORIGINAL EXCEL EXAMPLE
     (PV Annual Generation (kWh))
     =IF(InstallationType="Battery Only",0,'Hidden Calcs'!$I$29*Q6)

     (Avoided imports (kWh))
     =IF(InstallationType="Battery Only", ('Hidden Calcs'!$O$61 - 'Hidden Calcs'!$K$52) * 'Hidden Calcs'!$I$29, 'Indicative Costs'!$D$83)
   -		'Hidden Calcs'!$O$61 =MIN(AnnualDemand/$I29, N61)
   -			AnnualDemand =IF(InstallationType="Battery Only", 'Roof & Package'!C18 * I38, 'Customer Data'!G26)
   -				'Roof & Package'!C18 = existingPvAnnualGenerationKwh	//Existing PV Annual Yield (kWh)
   -				I38 =IF(C49=1, INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_OutdDay.tb,1), 1), INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_atHome.tb,1), 1))		//Annual Demand/PV Gen ratio (batt only)
   -					C49 =IF('Customer Data'!G19="Mostly evenings and weekends", 1, 2)		//customer out most of the day
   -					INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_OutdDay.tb,1), 1)		//tablular lookup - out during day
   -					INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_atHome.tb,1), 1)		//tabular lookup - in during day
   -				'Customer Data'!G26 = estimatedElectricityDemand()	//Estimated Electricity Demand (kWh)
   -			$I29 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
   -				'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
   -				G29 = Annual PV Yield (kWh)
   -			N61 ='NEW SC lookup'!N12
   -				'NEW SC lookup'!N12 = this.cashFlowSelfConsumption().corrPvBSc	//subcalculation moved to function due to complexity
   -		'Hidden Calcs'!$K$52 ='NEW SC lookup'!G12
   -			'NEW SC lookup'!G12 = this.cashFlowSelfConsumption().PvOnlySc	//subcalculation moved to function due to complexity
   -		'Hidden Calcs'!$I$29 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
   -			'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
   -			G29 = Annual PV Yield (kWh)
   -		'Indicative Costs'!$D$83 =D27*D85
   -			D27 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
   -				'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
   -				G29 = Annual PV Yield (kWh)
   -			D85 =IF(D14="None", 'Hidden Calcs'!$K$52, 'Hidden Calcs'!$O$61)
   -				D14 = Battery type
   -				'Hidden Calcs'!$K$52 = this.cashFlowSelfConsumption().PvOnlySc	//subcalculation moved to function due to complexity
   -				'Hidden Calcs'!$O$61 =MIN(AnnualDemand/$I29, N61)
   -					AnnualDemand =IF(InstallationType="Battery Only", 'Roof & Package'!C18 * I38, 'Customer Data'!G26)
   -						//NOTE THIS IS ALSO USED ABOVE - COMPLEX CALC PART
   -					$I29 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
   -						'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
   -						G29 = Annual PV Yield (kWh)
   -					N61 = this.cashFlowSelfConsumption().corrPvBSc	//subcalculation moved to function due to complexity

     (Export rate (£/kWh))
     =QuoteExportRate*(1+FITRPI)^($B6-1)
       QuoteExportRate =IF('Customer Data'!G14="Yes",0,ExportRate)
         'Customer Data'!G14 = Grid Connected PV on site (bool)
       FITRPI = 0 //FIT tariff will become obsolete by the time of release

     (Electricity price (£/kWh))
     =ImportRate*(1+ElecPriceInflation)^($B6-1)

     (Export income (£))
     =C6*DeemedExportPerc*F6

     (Avoided import saving (£))
     =D6*G6

     (Cumulative total benefits (£))
     =H6+I6+J6

   INPUTS
     forYears						= nuymber of years to calculate figures for
     costToCustomerAfterDiscount		= Cost of product to customer						(float - monetary value)
     pvAnnualGenerationKwh			= PV Annual Generation (kWh)						('Hidden Calcs'!$I$29)
     importRate						= electricity import price							('Indicative Costs'!$K$8)
     exportRate						= electricity export price							('Price Lookups'!$BC$4)
     connectedPV						= PV connected to Grid?								(boolean value)
     isPvPackage						= PV installation									(boolean value)
     isBatteryPackage				= battery installation								(boolean value)
     estimatedElectricityDemand		= from calculations.estimatedElectricityDemand()	(float - kWh)

   OUTPUTS
     cashflow(array)	= cashflow calculatioN data as an array of values

   EXTERNAL DATA
     This function uses the matrix of compound degradation data for value lookups

   */
