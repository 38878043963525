import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { showPercentSign } from '../product-tables/utils';
import { toFixedNumber } from '@zing/neo-common/dist/lib/safe/to-fixed-number';
import ProductTableDataDriven from '../product-table-data-driven';
import PropTypes from 'prop-types';
import React from 'react';

const rowConfig = [
  { field: 'prodId', className: '', pipeline: [], totalClassName: 'noBorder' },
  { field: 'type', className: '', pipeline: [], totalClassName: 'noBorder' },
  { field: 'gsRatioGoods', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign] },
  { field: 'gsRatioServices', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign] },
  { field: 'gmUsed', className: 'center gm', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign] },
  { field: 'costPerItem', className: 'money costs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerItemGoods', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerItemServices', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'gmValuePerItem', className: 'money gm', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerItem', className: 'money exVat', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerKw', className: 'money costs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerKwGoods', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerKwServices', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'gmValuePerKw', className: 'money gm', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerKw', className: 'money exVat', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerPanel', className: 'money costs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerPanelGoods', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerPanelServices', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'gmValuePerPanel', className: 'money gm', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'exVatPricePerPanel', className: 'money exVat', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
];
const TableHead = (
  <thead>
    <tr>
      <th colSpan={4}>&nbsp;</th>
      <th colSpan={16} className="center productPricing borderRight">
        Product Pricing
      </th>
    </tr>
    <tr>
      <th colSpan={2}>&nbsp;</th>
      <th colSpan={2} className="gs center">
        GS Ratio
      </th>
      <th className="gm center noBorder">GM</th>
      <th className="center perItem" colSpan={5}>
        Per item
      </th>
      <th className="center perKw" colSpan={5}>
        Per kW
      </th>
      <th className="center perPanel borderRight" colSpan={5}>
        Per Panel
      </th>
    </tr>
    <tr>
      <th className="left">Prod ID</th>
      <th className="left">Type</th>
      <th className="center gs">Goods</th>
      <th className="center gs">Services</th>
      <th className="center gm">Rate</th>

      {/* Per item */}
      <th className="money costs">Cost</th>
      <th className="money gs">Goods</th>
      <th className="money gs ">Services</th>
      <th className="money gm ">GM</th>
      <th className="money exVat ">ExVat</th>

      {/* Per Kw */}
      <th className="money costs">Cost</th>
      <th className="money gs ">Goods</th>
      <th className="money gs ">Services</th>
      <th className="money gm ">GM</th>
      <th className="money exVat ">ExVat</th>

      {/* Per Panel */}
      <th className="money costs">Cost</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">GM</th>
      <th className="money exVat">ExVat</th>
    </tr>
  </thead>
);

const ProductPricingTable = ({ selectedProducts = [] }) => (
  <ProductTableDataDriven title="Product pricing" values={selectedProducts} rowConfig={rowConfig} TableHead={TableHead} />
);

ProductPricingTable.propTypes = {
  selectedProducts: PropTypes.shape([]).isRequired,
};

export default ProductPricingTable;
