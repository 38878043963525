import { clamp, divide } from '@zing/neo-common/dist/lib/number';
import { OCCUPANTS_LOOKUP } from '../../utils/CalculationsData';

const assignEstimatedElectricityDemandKwh = (value, context, event) => {
  const { annualElectricityDemandKwh, annualElectricityCost, numberOfOccupants, importRate = 0 } = context.comparisons[
    event.currentComparison
  ];

  // if the user has supplied an actual demand figure, use this.
  if (annualElectricityDemandKwh && annualElectricityDemandKwh > 0) {
    return annualElectricityDemandKwh;
  }
  if (annualElectricityCost && annualElectricityCost > 0 && importRate > 0) {
    return Math.round(divide(annualElectricityCost, importRate / 100));
  }
  if (numberOfOccupants && numberOfOccupants > 0) {
    const lookup = clamp(Number(numberOfOccupants), 1, 7);
    return OCCUPANTS_LOOKUP[lookup];
  }
  return 0;
};

export default assignEstimatedElectricityDemandKwh;
