import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import logic from './logic';
import Notifications from './notifications';
import { connect } from 'react-redux';
import { fetchNotifications } from '../../redux/notifications/actions';
import { get } from '@zing/neo-common/dist/lib/safe';
import { filterByReadStatus, getNotifications } from '../../redux/notifications/accessor';
import Notification from '../../components/Notification';

const Index = ({ fetchNotifications, match, notifications, processing, error }) => {
  useEffect(() => {
    fetchNotifications();
  }, []);
  const mode = get(match, 'params.status', 'read');
  const result = logic(mode, notifications, processing, error, filterByReadStatus);
  return <Notifications result={result} Notification={Notification} />;
};

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.oneOf(['read', 'unread']).isRequired,
    }).isRequired,
  }).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.oneOf([PropTypes.object, null]),
  processing: PropTypes.bool.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
};

Index.defaultProps = {
  error: null,
  notifications: [],
};

const mapStateToProps = ({ notifications }) => ({
  processing: notifications.processing,
  error: notifications.error,
  notifications: getNotifications(notifications.notifications),
});

export default connect(mapStateToProps, { fetchNotifications })(Index);
