export const OPPORTUNITIES = 'OPPORTUNITIES';
export const OPPORTUNITIES_COMMIT = 'OPPORTUNITIES_COMMIT';
export const OPPORTUNITIES_REQUEST = 'OPPORTUNITIES_REQUEST';
export const OPPORTUNITIES_ROLLBACK = 'OPPORTUNITIES_ROLLBACK';
export const RESET_OPPORTUNITIES = 'RESET_OPPORTUNITIES';

export const OPPORTUNITIES_SEARCH = 'OPPORTUNITIES_SEARCH';
export const OPPORTUNITIES_SEARCH_COMMIT = 'OPPORTUNITIES_SEARCH_COMMIT';
export const OPPORTUNITIES_SEARCH_REQUEST = 'OPPORTUNITIES_SEARCH_REQUEST';
export const OPPORTUNITIES_SEARCH_ROLLBACK = 'OPPORTUNITIES_SEARCH_ROLLBACK';

export default {
  OPPORTUNITIES,
  OPPORTUNITIES_COMMIT,
  OPPORTUNITIES_REQUEST,
  OPPORTUNITIES_ROLLBACK,
  RESET_OPPORTUNITIES,
  OPPORTUNITIES_SEARCH,
  OPPORTUNITIES_SEARCH_COMMIT,
  OPPORTUNITIES_SEARCH_REQUEST,
  OPPORTUNITIES_SEARCH_ROLLBACK,
};
