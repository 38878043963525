import React from 'react';
import PropTypes from 'prop-types';
import DashboardNav from '../../components/dashboard-nav';
import Header from '../../components/Header';
import NotificationsToggle from '../../components/toggles/notifications-toggle';

import './Notifications.scss';

const Notifications = ({ result, Notification }) => (
  <>
    <Header title="Notifications" />
    <main className="notifications" data-testid="notifications-page">
      <NotificationsToggle />
      {result.error && <h3 className="range-center">Error!</h3>}
      {result.displayItems && result.items.map(notification => <Notification notification={notification} key={notification.id} />)}
      {result.displayUnavailable && <h3 className="range-center heavy">No {result.mode} notifications</h3>}
    </main>
    <DashboardNav />
  </>
);

Notifications.propTypes = {
  result: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    displayItems: PropTypes.bool.isRequired,
    displayUnavailable: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    mode: PropTypes.oneOf(['read', 'unread']).isRequired,
  }).isRequired,
  Notification: PropTypes.node.isRequired,
};

export default Notifications;
