import addDays from 'date-fns/add_days';
import endOfDay from 'date-fns/end_of_day';
import endOfWeek from 'date-fns/end_of_week';
import endOfMonth from 'date-fns/end_of_month';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import merge from 'lodash.merge';
import startOfDay from 'date-fns/start_of_day';
import startOfMonth from 'date-fns/start_of_month';
import startOfWeek from 'date-fns/start_of_week';
import { get } from '@zing/neo-common/dist/lib/safe';

export const FILTER_THIS_WEEK = 'THIS_WEEK';
export const FILTER_NEXT_WEEK = 'NEXT_WEEK';
export const FILTER_THIS_MONTH = 'THIS_MONTH';
export const FILTER_ALL = 'ALL';

export const getFilterStartDate = (filter, now) => {
  if (filter === FILTER_ALL) {
    return null;
  }
  if (filter === FILTER_THIS_WEEK) {
    if (Number(now.getDay()) === 1) {
      return startOfDay(now);
    }
    return startOfWeek(now, { weekStartsOn: 1 });
  }
  if (filter === FILTER_NEXT_WEEK) {
    if (Number(now.getDay()) === 1) {
      return addDays(startOfDay(now), 7);
    }
    return addDays(startOfWeek(now, { weekStartsOn: 1 }), 7);
  }
  if (filter === FILTER_THIS_MONTH) {
    return startOfMonth(now);
  }
  return null;
};

export const getFilterEndDate = (filter, now) => {
  if (filter === FILTER_ALL) {
    return null;
  }
  if (filter === FILTER_THIS_WEEK) {
    if (Number(now.getDay()) === 0) {
      return endOfDay(now);
    }
    return endOfWeek(now, { weekStartsOn: 1 });
  }
  if (filter === FILTER_NEXT_WEEK) {
    if (Number(now.getDay()) === 0) {
      return addDays(endOfDay(now), 7);
    }
    return addDays(endOfWeek(now, { weekStartsOn: 1 }), 7);
  }
  if (filter === FILTER_THIS_MONTH) {
    return endOfMonth(now);
  }

  return null;
};

export const filterNull = x => x !== null;

export const filterDate = (startDate, endDate, date) => !(isBefore(date, startDate) || isAfter(date, endDate));

export const filterMeetings = (startDate, endDate) => opportunity => {
  const meetings = get(opportunity, 'meetings', []);
  opportunity.meetings = meetings.filter(m => filterDate(startDate, endDate, m.when)); // eslint-disable-line
  return opportunity.meetings.length > 0 ? opportunity : null;
};
export const hideOpportunities = (opportunities, filter = FILTER_ALL, now = new Date()) => {
  if (filter === FILTER_ALL) {
    return opportunities;
  }
  let startDate;
  let endDate;

  if (filter instanceof Date) {
    // Specific date filter
    startDate = startOfDay(filter);
    endDate = endOfDay(filter);
  } else {
    // Date range filter ('this week' etc)
    startDate = getFilterStartDate(filter, now);
    endDate = getFilterEndDate(filter, now);
  }

  return merge([], opportunities)
    .map(filterMeetings(startDate, endDate))
    .filter(filterNull);
};
