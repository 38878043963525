import React, { useContext } from 'react';
import Toggle from '../../hollywood-xstate/toggle';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { FILTER_BATTERY_CAPACITY_ALL } from '@zing/neo-common/dist/config';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const BatteryCapacityToggle = () => {
  const {
    current: {
      context: { currentComparison, comparisons },
    },
    send,
  } = useContext(PimSurveyMachineContext);

  const { pimComparison } = comparisons[currentComparison];
  const capacities = pimComparison.getBatteryCapacities().map(String);

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>Battery capacity filter</p>
        </Col>
        <Col width="fixed" className="right-col" testId="meterType">
          <Toggle
            name={`context.comparisons.${currentComparison}.batteryCapacityToggle`}
            options={[...capacities, FILTER_BATTERY_CAPACITY_ALL]}
            goodOption={capacities}
            value={comparisons[currentComparison].batteryCapacityToggle}
            onChange={(_, value) => {
              send({ type: 'BATTERY_CAPACITY_TOGGLE', currentComparison, value });
            }}
          />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default BatteryCapacityToggle;
