import React, { useContext } from 'react';
import Toggle from '../../hollywood-xstate/toggle';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { EV_FULL_INSTALL, EV_NONE, EV_READY } from '../../../../config';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const EvInstallationType = () => {
  const {
    current: {
      context: { currentComparison, comparisons },
    },
    send,
  } = useContext(PimSurveyMachineContext);

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>Type of install</p>
        </Col>
        <Col width="fixed" className="right-col" testId="evInstallationType">
          <Toggle
            name={`context.comparisons.${currentComparison}.evInstallationType`}
            options={[EV_NONE, EV_FULL_INSTALL, EV_READY]}
            goodOption={[EV_FULL_INSTALL, EV_READY]}
            value={comparisons[currentComparison].evInstallationType}
            onChange={(_, value) => {
              if (value === EV_NONE) {
                send({ type: 'EV_NONE', currentComparison, value });
              }
              if (value === EV_READY) {
                send({ type: 'EV_READY', currentComparison, value });
              }
              if (value === EV_FULL_INSTALL) {
                send({ type: 'EV_FULL_INSTALL', currentComparison, value });
              }
            }}
          />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default EvInstallationType;
