import { initialState } from './initialState';
import { PRESENTER_COMMIT, PRESENTER_REQUEST, PRESENTER_RESET, PRESENTER_ROLLBACK } from './actionTypes';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';

export default function presenter(state = initialState, action) {
  switch (action.type) {
    case RESET_ALL:
      return initialState;
    case PRESENTER_RESET:
      return initialState;
    case PRESENTER_REQUEST:
      return {
        processing: true,
        success: false,
        error: false,
        errors: {},
        presenter: state.PRESENTER,
      };
    case PRESENTER_COMMIT:
      return {
        processing: false,
        success: true,
        error: false,
        errors: {},
        presenter: action.payload,
      };
    case PRESENTER_ROLLBACK:
      return {
        processing: false,
        success: false,
        error: true,
        errors: action.payload,
        presenter: state.PRESENTER,
      };
    default:
      return state;
  }
}
