import React, { useContext } from 'react';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { getContextCurrentComparison } from '../../../../machines/survey.machine';

const YourPackageTitle = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const values = getContextCurrentComparison(current);
  return (
    <div className="page-heading eon-red">
      <Grid gutters="sm">
        <Col>
          <h1 className="range-right" data-testid="your_package_name">
            Your {values?.name} package
          </h1>
        </Col>
      </Grid>
    </div>
  );
};

YourPackageTitle.propTypes = {};

export default YourPackageTitle;
