import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { offlineMiddleware } from '@zing/neo-common/dist/redux/offline/middleware/offline-middleware';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { OFFLINE_REQUESTS_ENQUEUE } from '@zing/neo-common/dist/redux/offline/actionTypes';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const config = {
  blacklist: [OFFLINE_REQUESTS_ENQUEUE], // UKSQA-1367 - dont add api requests to offline queue in all tabs!
};

const reduxStore = createStore(
  rootReducer,
  localStorage['neo-quotation-tool-store'] ? JSON.parse(localStorage['neo-quotation-tool-store']) : {},
  composeEnhancers(applyMiddleware(thunk, offlineMiddleware, createStateSyncMiddleware(config)))
);

initStateWithPrevTab(reduxStore);

export const store = reduxStore;
