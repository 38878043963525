export const PaymentOptionsInitialState = {
  annualPercentageRate: 0,
  beforeDiscount: {
    totalCost: 0,
    totalCostIncVat: 0,
    totalVat: 0,
    totalVatAtRate1: 0,
    totalVatAtRate2: 0,
    totalDueAfterInstallation: 0,
    balanceIncVat: 0,
    depositIncVat: 0,
    depositP: 0,
    remainder: 0,
    initial: 0,
    numberOfYears: 0,
    numberOfPayments: 0,
    monthlyPayment: 0,
  },
  discountAmount: 0,
  discountPercentage: 0,
  discountType: 'None',
  postDiscount: {
    totalCost: 0,
    totalCostIncVat: 0,
    totalVat: 0,
    totalVatAtRate1: 0,
    totalVatAtRate2: 0,
    totalDueAfterInstallation: 0,
    balanceIncVat: 0,
    depositIncVat: 0,
    depositP: 0,
    initial: 0,
    numberOfYears: 0,
    numberOfPayments: 0,
    monthlyPayment: 0,
  },
  twentyFiveYearBenefit: 0,
  vatRate1: 0,
  vatRate2: 0,
};
