import React from 'react';
import HollywoodFieldError from '../../hollywood/field-error';
import PropTypes from 'prop-types';
import useSurveyValidation from '../../contexts/survey-validation-context';

const FieldError = ({ field, testId }) => {
  const { validator } = useSurveyValidation();
  return <HollywoodFieldError error={validator.error(field)} field={field} hasError={validator.hasError(field)} testId={testId} />;
};

FieldError.propTypes = {
  field: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

FieldError.defaultProps = {
  testId: null,
};

export default FieldError;
