import PropTypes from 'prop-types';
import React from 'react';
import LoginPage from '../login-page';
import { connect } from 'react-redux';
import { login } from '../../../redux/auth/actions';
import PrimaryButton from '../../../components/buttons/primary';
import { Link } from 'react-router-dom';

const Login = ({ auth, location, login, user }) => (
  <LoginPage
    auth={auth}
    config={{
      allowLoginViaToken: true,
    }}
    location={location}
    login={login}
    user={user}
  >
    <p>To login please click the Azure login button</p>
    <PrimaryButton inverted>
      <a href={`${process.env.REACT_APP_API_BASE_URL}/azure/oauth`} className="azure-login">
        Azure login
      </a>
    </PrimaryButton>

    <Link to="/login-username" className="login_username_link">
      Or login with username/password
    </Link>
  </LoginPage>
);

Login.propTypes = {
  auth: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
  login: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

Login.defaultProps = {
  auth: {},
  user: {},
};

export default connect(({ auth, user }) => ({ auth, user }), { login })(Login);
