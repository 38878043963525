import React from 'react';
import HollywoodCheckbox from '../../hollywood/checkbox';
import PropTypes from 'prop-types';
import useSurveyValidation from '../../contexts/survey-validation-context';

const Checkbox = ({ name, ...rest }) => {
  const { validator } = useSurveyValidation();
  return <HollywoodCheckbox name={name} {...rest} hasError={validator.hasError(name)} />;
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  testId: PropTypes.string,
};

Checkbox.defaultProps = {
  id: '',
  checked: false,
  className: null,
  disabled: false,
  hasError: false,
  label: null,
  onChange: null,
  onBlur: null,
  testId: null,
};

export default Checkbox;
