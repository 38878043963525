import React from 'react';
import PropTypes from 'prop-types';
import { pounds } from '@zing/neo-common/dist/lib/display';
import { PAYMENT_TYPE_GHG_SCHEME } from '../../../../config';

export const BreakdownPanel = ({ totalCost = 0, deposit = 0, paymentType = '', totalDueAfterInstallation = 0 }) => (
  <div className="breakdown-panel-wrapper">
    <div className="breakdown-panel">
      <table className="breakdown-table">
        <tbody>
          <tr className="total-cost">
            <td>Total cost</td>
            <td className="amount" data-testid="total_cost">
              <strong>{pounds(totalCost)}</strong>
            </td>
          </tr>
          {paymentType === 'none' && (
            <tr className="post-install">
              <td>Balance</td>
              <td className="amount">
                <strong>{pounds(totalDueAfterInstallation)}</strong>
              </td>
            </tr>
          )}
          {paymentType === 'oneOff' && (
            <>
              <tr>
                <td>Deposit to be paid</td>
                <td className="amount">{pounds(deposit)}</td>
              </tr>
              <tr className="post-install">
                <td>Balance to be paid (after installation)</td>
                <td className="amount">
                  <strong>{pounds(totalDueAfterInstallation)}</strong>
                </td>
              </tr>
            </>
          )}
          {paymentType === 'eonPay' && (
            <>
              <tr>
                <td>Upfront payment</td>
                <td className="amount">{pounds(0)}</td>
              </tr>
              <tr className="post-install">
                <td>0% APR Interest Free Credit amount</td>
                <td className="amount">
                  <strong>{pounds(totalDueAfterInstallation)}</strong>
                </td>
              </tr>
            </>
          )}
          {paymentType === 'inFull' && (
            <tr className="post-install">
              <td>Payment due before installation</td>
              <td className="amount">
                <strong>{pounds(totalDueAfterInstallation)}</strong>
              </td>
            </tr>
          )}
          {paymentType === 'ghgScheme' && (
            <>
              <tr>
                <td>{PAYMENT_TYPE_GHG_SCHEME}</td>
                <td className="amount">{pounds(deposit)}</td>
              </tr>
              <tr className="post-install">
                <td>Payment due</td>
                <td className="amount">
                  <strong>{pounds(totalDueAfterInstallation)}</strong>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

BreakdownPanel.propTypes = {
  totalCost: PropTypes.number.isRequired,
  deposit: PropTypes.number.isRequired,
  paymentType: PropTypes.oneOf(['none', 'oneOff', 'eonPay', 'inFull', 'ghgScheme']).isRequired,
  totalDueAfterInstallation: PropTypes.number.isRequired,
};
