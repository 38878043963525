import React, { useState, useContext } from 'react';
import handleAgreement from '@zing/neo-common/dist/components/quote/handle-agreement';
import Header from '../../../../components/Header';
import PropTypes from 'prop-types';
import Quote from '@zing/neo-common/dist/components/quote';
import QuoteHeaderButtons from './quote-header-buttons';
import QuoteNavigation from '@zing/neo-common/dist/components/quote/quote-navigation';
import QuoteSelector from './quote-selector';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { agreeQuote } from '../../../../redux/session/actions';
import { appointmentMatchUrl } from '../../../../utils/opportunity';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { get, getArray } from '@zing/neo-common/dist/lib/safe';
import { opportunityMatchQuoteId } from '@zing/neo-common/dist/redux/opportunity/accessor';
import quoteAccessor from '@zing/neo-common/dist/accessors/quote';
import { quoteSections } from '@zing/neo-common/dist/accessors/quote-sections';

import './quote.scss';
import { UserContext } from '../../contexts/user-context';

const PimQuote = ({ history, match, agreeQuote, pimSessionRedux }) => {
  const [pimSession] = useLocalStorage('pimSession');
  const [currentSection, setCurrentSection] = useState('your-details');
  const [processing, setProcessing] = useState(false);
  const quoteId = Number(opportunityMatchQuoteId(match));
  const quotes = getArray(pimSessionRedux, 'quotes');
  const quote = quotes[quoteId];
  const user = useContext(UserContext);

  // console.log('QUOTE:', quote);

  if (quotes.length === 0 || quote === null) {
    // todo: Better error handling here, provide a message and a back button or something..
    return null;
  }

  const logic = quoteAccessor(quote);
  const imageBaseUrl = process.env.REACT_APP_API_BASE_URL.replace(/\/api/, '/users/profiles/');
  const nextUrl = appointmentMatchUrl(match, ['survey', 'thank-you-for-choosing-eon', quoteId]);
  const onSubmitForm = handleAgreement({ agreeQuote, history, nextUrl, quote, setProcessing, backUrl: history?.location?.pathname });

  const quoteVersionSections = get(quote, 'quote_version.sections', quoteSections);

  return (
    <>
      <Header logo={false}>
        {quotes.length > 1 && <QuoteSelector opportunity={pimSession.opportunity} history={history} quotes={quotes} quoteId={quoteId} />}
        <QuoteHeaderButtons
          backUrl={appointmentMatchUrl(match, ['survey', 'payment-options'])}
          emailUrl={appointmentMatchUrl(match, ['survey', 'email-quote', quoteId])}
          processing={processing}
          logic={logic}
        />
      </Header>
      <QuoteNavigation currentSection={currentSection} quoteVersionSections={quoteVersionSections} />
      <Formik
        initialValues={{
          ...quote,
          agreed_terms_and_conditions: Boolean(quote.agreed_terms_and_conditions),
          agreed_cooling_off_waiver: Boolean(quote.agreed_cooling_off_waiver),
          quote_signed_by: get(quote, 'quote_signed_by', `${user.firstname} ${user.lastname}`),
        }}
        onSubmit={formValues => {
          // eslint-disable-next-line no-console
          console.log('submitting quote', formValues);
          onSubmitForm(formValues);
        }}
      >
        {() => (
          <Form>
            <Quote
              imageBaseUrl={imageBaseUrl}
              isSubmittingQuote={processing}
              onClickAgreeAndSign={() => {
                // eslint-disable-next-line no-console
                console.log('onClickAgreeAndSign');
                // eslint-disable-next-line no-console
                console.log('update quote here...');
              }}
              setCurrentSection={setCurrentSection}
              quote={quote}
            />
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </>
  );
};

PimQuote.propTypes = {
  agreeQuote: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: {
      pathname: PropTypes.string,
    },
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  pimSessionRedux: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default connect(({ pimSession }) => ({ pimSessionRedux: pimSession }), { agreeQuote })(PimQuote);
