import React, { useContext } from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import classnames from 'classnames';

import './g99-required.scss';

const G99Required = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const g99 = comparisons[currentComparison]?.dynamic?.calcs?.g99 || {};

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor="isG99Required">Calculated G99 required</label>
        </Col>
        <Col width="fixed" className="-col">
          <div className={classnames(['suggestedG99Requirement', g99.isG99Required ? 'suggestedG99RequirementRed' : null])}>
            {g99.isG99Required ? 'Yes' : 'No'}
          </div>
        </Col>
      </Grid>
    </fieldset>
  );
};

export default G99Required;
