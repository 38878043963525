import * as Yup from 'yup';
import { REQUIRED_NUMBER } from '../../../../components/Survey/validate/messages';

const schema = comparison =>
  Yup.object().shape({
    context: Yup.object().shape({
      comparisons: Yup.object().shape({
        [comparison]: Yup.object().shape({
          dynamic: Yup.object().shape({
            calcs: Yup.object().shape({
              g99: Yup.object().shape({
                totalOnSiteGeneration: Yup.number()
                  .moreThan(0)
                  .required(REQUIRED_NUMBER),
              }),
            }),
          }),
        }),
      }),
    }),
    value: Yup.object().shape({}),
  });

export default schema;
