import { appointmentUrl } from '../../../../utils/opportunity';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { connect } from 'react-redux';
import { getContextCurrentComparison } from '../../../../machines/survey.machine';
import { pimCreateQuote } from '../../../../redux/session/actions';
import { quoteSections } from '@zing/neo-common/dist/accessors/quote-sections';
import { withRouter } from 'react-router-dom';
import BackButton from '../../../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import createQuoteObject from './create-quote';
import format from 'date-fns/format';
import processQuoteSections from './process-quote-sections';
import PropTypes from 'prop-types';
import React from 'react';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { DEFAULT_TERMS_AND_CONDITIONS } from '@zing/neo-common/dist/config';

const md5 = require('md5');

const Actions = ({ history, pimCreateQuote, pimSessionRedux, current, pimUuid }) => {
  const [pimSession] = useLocalStorage('pimSession');
  const currentComparisonContext = getContextCurrentComparison(current);
  const { pimComparison, paymentType = null } = currentComparisonContext;
  const { quotes = [] } = pimSessionRedux;
  const hasQuotes = Array.isArray(quotes) && quotes.length > 0;

  return (
    <div className="actions range-right">
      <Grid valign="center" align="right">
        <Col width="auto">
          <BackButton onClick={() => history.push(appointmentUrl(pimSession.opportunity, ['survey', 'your-package']))} inverted />
          <Button
            format="ternary"
            testId="quotes"
            inverted
            disabled={!hasQuotes}
            onClick={() => {
              const quoteUrl = appointmentUrl(pimSession.opportunity, ['survey', 'quote', 0]);
              history.push(quoteUrl);
            }}
          >
            {quotes.length > 1 ? 'View quotes' : 'View quote'}
          </Button>
          <Button
            format="primary"
            testId="produceQuotes"
            onClick={() => {
              const quote = createQuoteObject(pimSession, current, pimComparison);
              const dateCreated = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
              quote.quote_created_via_app_at = dateCreated;
              quote.survey_date = dateCreated; // Upstream CRM requirement
              quote.app_uuid = md5(`${dateCreated}-${quote.package_id}`);
              quote.signed_and_agreed_at = null;
              quote.quote_version = {
                version: 1,
                terms_and_conditions: DEFAULT_TERMS_AND_CONDITIONS,
                sections: processQuoteSections(quoteSections, quote),
              };
              quote.pdf_html_source = ''; // now deprecated
              quote.pim_uuid = pimUuid;

              // we still part use redux to know when the API has returned a quote (offline?) and we have a valid reference
              pimCreateQuote(appUuid(pimSession.opportunity), quote);

              // OFFLINE_RESET_REQUESTS
              // OFFLINE_RESET_FAILED
              // SESSION_RESET_QUOTES
              // OFFLINE_IS_PROCESSING_QUEUE
              // RESET_PIM_SURVEY_QUOTES

              // now go to the quote page at quote index
              const quoteUrl = appointmentUrl(pimSession.opportunity, ['survey', 'quote', quotes.length]);
              history.push(quoteUrl);
            }}
            disabled={paymentType === null}
          >
            Produce quote
          </Button>
        </Col>
      </Grid>
    </div>
  );
};

Actions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  pimCreateQuote: PropTypes.shape({}).isRequired,
  pimSessionRedux: PropTypes.shape({
    quotes: PropTypes.shape([]).isRequired,
  }).isRequired,
  current: PropTypes.shape({}).isRequired,
  pimUuid: PropTypes.string.isRequired,
};

const RoutedActions = withRouter(Actions);

export default connect(({ pimSession, pim }) => ({ pimSessionRedux: pimSession, pimUuid: pim.uuid }), { pimCreateQuote })(RoutedActions);
