import { appointmentMatchUrl } from '../../utils/opportunity';
import { connect } from 'react-redux';
import { filterSlides } from './utils';
import { PROPERTY_DETAILS, SURVEY } from '../../utils/paths';
import { Transition, animated } from 'react-spring/renderprops';
import { updatePowra } from '../../redux/session/actions';
import Button from 'hollywood/dist/components/Button';
import Header from '../../components/Header';
import IconButton from '@zing/neo-common/dist/components/IconButton';
import Modal from 'hollywood/dist/components/Modal';
import PowraForm from '../../pim/survey/components/powra-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SlidesNavigation from './SlidesNavigation';
import { appPropTypes } from '../../app-prop-types';

import './Presenter.scss';

const Presenter = ({ presenter, session, match, history, location, updatePowra }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [showSlidesNav, setShowSlidesNav] = useState(false);
  const [showPowraModal, setShowPowraModal] = useState(false);

  const nextUrl = location?.state?.nextUrl ? location.state.nextUrl : appointmentMatchUrl(match, [SURVEY, PROPERTY_DETAILS]);

  const startSurvey = () => {
    if (!session.powra.startSurvey.done) {
      setShowPowraModal(true);
    } else {
      history.push(nextUrl);
    }
  };

  const submitPowra = powra => {
    powra.startSurvey.done = true;
    updatePowra(powra);
    history.push(nextUrl);
  };

  // Presenter not in state
  if (!presenter) {
    return (
      <>
        <Header />
        <main className="range-center" style={{ paddingTop: 200 }}>
          <p className="lg">
            The presentation has not been downloaded.
            <br /> Please go to the settings page to refresh app data.
          </p>
          <Button format="primary" link="/settings/app">
            Go to settings
          </Button>
        </main>
      </>
    );
  }

  // Check for opportunity to run in 'generic' or 'opportunity' mode
  const mode = match.params.id && match.params.id ? 'opportunity' : 'generic';
  const slides = mode === 'opportunity' ? filterSlides(presenter.slides, session.survey) : presenter.slides;

  const currentSlide = slides[currentSlideIndex];
  const menuControlTheme = currentSlide.menu_control_theme;
  const navControlTheme = currentSlide.navigation_control_theme;

  return (
    <>
      <Header transparent bordered={false} logo={false} menuButton={menuControlTheme === 'light' ? 'inverted' : true} />
      <main className="presenter">
        <Transition items={currentSlideIndex} from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }}>
          {index => props => (
            <animated.iframe
              seamless="seamless"
              sandbox="allow-same-origin"
              className="presenter__slide"
              style={props}
              title={slides[index].title}
              srcDoc={`<style>${presenter.css}${slides[index].css}</style>${slides[index].content}`}
            />
          )}
        </Transition>

        <nav className={`presenter__buttons ${navControlTheme}`} data-testid="buttons">
          {currentSlideIndex > 0 ? (
            <IconButton icon="chevron-left" onClick={() => setCurrentSlideIndex(currentSlideIndex - 1)} testId="prev" />
          ) : (
            mode === 'opportunity' && <IconButton icon="chevron-left" link="./before-we-start" testId="prev" />
          )}
          <IconButton icon="pages" onClick={() => setShowSlidesNav(true)} testId="show_slides_nav" />

          {currentSlideIndex === slides.length - 1 ? (
            mode === 'opportunity' && (
              <Button format="primary" inverted={navControlTheme === 'light'} onClick={startSurvey}>
                Go to survey
              </Button>
            )
          ) : (
            <IconButton
              className={currentSlideIndex < slides.length - 1 ? null : 'hidden'}
              icon="chevron-right"
              onClick={() => setCurrentSlideIndex(currentSlideIndex + 1)}
              testId="next"
            />
          )}
        </nav>

        <SlidesNavigation
          visible={showSlidesNav}
          slides={slides}
          onSlideSelect={setCurrentSlideIndex}
          onClose={() => setShowSlidesNav(false)}
          theme={navControlTheme}
          mode={mode}
          onStartSurveyClick={startSurvey}
        />
      </main>

      {!session.powra.startSurvey.done && (
        <Modal isOpen={showPowraModal} className="powra-modal" onClose={() => setShowPowraModal(false)}>
          <PowraForm
            sections={['startSurvey']}
            values={session.powra}
            onSubmit={submitPowra}
            showCancel
            onCancel={() => setShowPowraModal(false)}
          />
        </Modal>
      )}
    </>
  );
};

Presenter.propTypes = {
  presenter: PropTypes.shape({
    slides: PropTypes.shape([]).isRequired,
    css: PropTypes.string.isRequired,
  }).isRequired,
  session: PropTypes.shape().isRequired,
  match: PropTypes.shape(appPropTypes.matchPropTypes).isRequired,
  history: PropTypes.shape(appPropTypes.historyPropTypes).isRequired,
  location: PropTypes.shape(appPropTypes.locationPropTypes).isRequired,
  updatePowra: PropTypes.func.isRequired,
};

const mapStateToProps = ({ presenter, session }) => ({
  presenter: presenter.presenter,
  session,
});

export default connect(mapStateToProps, { updatePowra })(Presenter);
