import React from 'react';
import PropTypes from 'prop-types';
import PipelineQuoteTableRow from '../pipeline-quote-table-row';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { quoteReferenceAsKey } from '../../../../../redux/quote/accessor';

const PipelineQuoteTable = ({ api, businessLogic, isAdmin, requoteOpportunity, viewQuote, quotes, shaded }) => {
  const logic = businessLogic(quotes);
  if (!logic.hasQuotes()) {
    return null;
  }
  return (
    <div className="pipeline_quote_table" data-testid="pipeline_quote_table">
      {quotes.map(quote => (
        <PipelineQuoteTableRow
          api={api}
          isAdmin={isAdmin}
          quote={quote}
          requoteOpportunity={requoteOpportunity}
          shaded={shaded}
          key={appUuid(quote)}
          testId={`quote_${quoteReferenceAsKey(quote)}`}
          viewQuote={viewQuote}
        />
      ))}
    </div>
  );
};

PipelineQuoteTable.propTypes = {
  api: PropTypes.shape({
    setAsSigned: PropTypes.func.isRequired,
  }).isRequired,
  businessLogic: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  quotes: PropTypes.shape([]).isRequired,
  requoteOpportunity: PropTypes.func.isRequired,
  shaded: PropTypes.bool.isRequired,
  viewQuote: PropTypes.func.isRequired,
};

export default PipelineQuoteTable;
