import { api } from '@zing/neo-common/dist/lib/api';
import { AppContext } from './contexts/app-context';
import { AUTH_EXPIRED, checkAuth } from './components/PrivateRoute/checkAuth';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { inspect } from '@xstate/inspect';
import { interpret } from 'xstate';
import { isProcessingQueue, offlineStatus, dequeue } from '@zing/neo-common/dist/redux/offline/actions';
import { logout } from './redux/auth/actions';
import { PimContext } from './pim/survey/contexts/pim-context';
import { UserContext } from './pim/survey/contexts/user-context';
import { useService } from '@xstate/react';
import AppErrorBoundary from './AppErrorBoundary';
import appMachine from './machines/app.machine';
import Menu from './components/Menu';
import pimAccessor from '@zing/neo-common/dist/accessors/pim';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import RouterScroll from './components/RouterScroll';
import Routes from './routes';
import useInterval from '@use-it/interval';

if (process.env.REACT_APP_ENABLE_INSPECTOR && !window.Cypress) {
  inspect({ iframe: false });
}

export const app = interpret(appMachine, { devTools: true }).start();
app.send('INITIALIZE');

if (window) {
  window.app = app;
  app.onTransition(state => {
    // eslint-disable-next-line no-console
    if (['NOTIFY_AUTH'].includes(state.event.type)) {
      // console.log('>', state.event.type, 'filtered');
    } else {
      // eslint-disable-next-line no-console
      console.log('App state', state.event);
    }
  });
}

const App = ({ dequeue, offlineStatus, isProcessingQueue, auth, logout, user, pimStore }) => {
  const { state } = app;
  const [authState, authSend] = useService(state.context.auth);

  // Let's take care of the initial status, and then rely on events
  offlineStatus(!navigator.onLine);
  window.addEventListener('online', () => {
    offlineStatus(false);
  });
  window.addEventListener('offline', () => {
    offlineStatus(true);
  });

  const checkSession = () => {
    if (auth && !checkAuth(auth).valid) {
      logout(AUTH_EXPIRED);
    }
  };
  useInterval(() => {
    dequeue(api);
  }, 10000);
  useInterval(() => {
    checkSession();
  }, 30000);

  useEffect(() => {
    // Double click stuff...
    let preLastTouchStartAt = 0;
    let lastTouchStartAt = 0;
    const delay = 500;

    isProcessingQueue(false);

    document.addEventListener('touchstart', () => {
      preLastTouchStartAt = lastTouchStartAt;
      lastTouchStartAt = +new Date();
    });
    document.addEventListener('touchend', event => {
      const touchEndAt = +new Date();
      if (touchEndAt - preLastTouchStartAt < delay) {
        event.preventDefault();
        if (typeof event.target.click === 'function') {
          event.target.click();
        }
      }
    });
  }, [isProcessingQueue]);

  // one time check if we have refreshed the page and
  // // need to sync the auth machine..
  useEffect(() => {
    if (auth && authState.matches('loggedOut')) {
      // todo test this with an AZURE login
      authSend('AUTH_REFRESH', auth);
    }
  }, [auth, authState, authSend]);

  // I really dislike that we need to do this! The API should give us all in one call.
  useEffect(() => {
    if (auth?.email && user?.email && auth.email !== user.email) {
      app.send({ type: 'USER_DETAILS', email: user.email });
    }
  }, [auth, user]);

  const pim = pimAccessor(pimStore);

  return (
    <div className="app">
      <AppErrorBoundary>
        <BrowserRouter basename={process.env.NODE_ENV === 'production' ? 'app' : ''}>
          <RouterScroll>
            <AppContext.Provider value={app}>
              <UserContext.Provider value={user}>
                <PimContext.Provider value={{ pim }}>
                  <>
                    {auth && <Menu />}
                    <Routes />
                  </>
                </PimContext.Provider>
              </UserContext.Provider>
            </AppContext.Provider>
          </RouterScroll>
        </BrowserRouter>
      </AppErrorBoundary>
    </div>
  );
};

App.propTypes = {
  dequeue: PropTypes.func.isRequired,
  offlineStatus: PropTypes.func.isRequired,
  isProcessingQueue: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  pimStore: PropTypes.shape({}).isRequired,
};

App.defaultProps = {
  auth: {},
  user: {},
};

export default connect(({ auth, offline, pim, user }) => ({ auth, offline, pimStore: pim, user: user.user }), {
  dequeue,
  offlineStatus,
  logout,
  isProcessingQueue,
})(App);
