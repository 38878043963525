import { connect } from 'react-redux';
import { get } from '@zing/neo-common/dist/lib/safe';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DiagnosticsHeader from '../../../components/diagnostics-header';

export const RequestListTableItem = ({ item, index }) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <tr
      className={index % 2 === 1 ? 'highlight-row' : ''}
      onClick={() => {
        setShowDetail(!showDetail);
      }}
    >
      {showDetail === true && (
        <>
          <td colSpan={4}>
            <pre>{JSON.stringify(item, null, 2)}</pre>
          </td>
        </>
      )}
      {showDetail === false && (
        <>
          <td>{get(item, 'request.type', '')}</td>
          <td>{get(item, 'request.method', '')}</td>
          <td>{get(item, 'request.url', '')}</td>
        </>
      )}
    </tr>
  );
};

RequestListTableItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
};

export const RequestListTable = ({ items = [], type, title = '' }) => {
  if (items.length === 0) {
    return (
      <table className="">
        <thead>
          <tr>
            <td colSpan="3" className="tableHeaderRowTitle">
              <h2>{title}</h2>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="subHeading"> No Items</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <table className="">
      <thead>
        <tr>
          <td colSpan="3" className="tableHeaderRowTitle">
            <h2>{title}</h2>
          </td>
        </tr>
        <tr>
          <td width="30%" className="subHeading">
            Type
          </td>
          <td width="10%" className="subHeading">
            Method
          </td>
          <td className="subHeading"> Url</td>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <RequestListTableItem item={item} index={index} type={type} />
        ))}
      </tbody>
    </table>
  );
};

RequestListTable.propTypes = {
  items: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const OfflineQueuePage = ({ offline }) => {
  const { isForcedOffline, isOffline, isProcessing, requests, failed } = offline;
  return (
    <>
      <DiagnosticsHeader title="Offline Queue" />
      <main className="diagnostics">
        <Grid gutters="xl">
          <Col width="12">
            <table className="">
              <thead>
                <tr>
                  <td className="subHeading" width="33%">
                    Is Offline?
                  </td>
                  <td className="subHeading" width="33%">
                    Is Forced Offline?
                  </td>
                  <td className="subHeading" width="33%">
                    Is Processing?
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="">{isOffline ? 'Yes' : 'No'}</td>
                  <td className=""> {isForcedOffline ? 'Yes' : 'No'}</td>
                  <td className="">{isProcessing ? 'Yes' : 'No'}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col width="12">
            <RequestListTable items={failed} type="failed" title="Failed" />
          </Col>
          <Col width="12">
            <RequestListTable items={requests} type="requests" title="Requests" />
          </Col>
        </Grid>
      </main>
    </>
  );
};

OfflineQueuePage.propTypes = {
  offline: PropTypes.shape({
    isForcedOffline: PropTypes.bool.isRequired,
    isOffline: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    requests: PropTypes.shape([]).isRequired,
    failed: PropTypes.shape([]).isRequired,
  }).isRequired,
};

export default connect(({ offline }) => ({ offline }))(OfflineQueuePage);
