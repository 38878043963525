import { historyPropTypes } from './history';
import { locationPropTypes } from './location';
import { matchPropTypes } from './match';

export const appPropTypes = {
  historyPropTypes,
  locationPropTypes,
  matchPropTypes,
};

export default appPropTypes;
