import { fetchOpportunityLNumber } from '@zing/neo-common/dist/redux/opportunity/actions';
import { opportunityLnumber } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const useCheckLNumber = opportunity => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (opportunity && !opportunityLnumber(opportunity)) {
      dispatch(fetchOpportunityLNumber(opportunity));
    }
  }, [opportunity, dispatch]);
};

export default useCheckLNumber;
