import Addresses from '../../addresses/addresses';
import addressMachine from '../../../machines/addresses.machine';
import BackButton from '../../buttons/back';
import Button from 'hollywood/dist/components/Button';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import validator from './validator';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { CustomerFields } from '../CustomerFields/CustomerFields';
import { Form, Formik } from 'formik';
import { TextareaField } from '@zing/neo-common/dist/components/forms/fields/TextareaField';
import { getBoolean } from '@zing/neo-common/dist/lib/safe';
import { isSubmitDisabled } from './is-submit-disabled';
import { useMachine } from '@xstate/react';

import './customer-details-form.scss';

const CustomerDetailsForm = ({ opportunity, onSubmit, backUrl }) => {
  const [current, send, service] = useMachine(addressMachine);
  let formikSetFieldValue = null;

  useEffect(() => {
    const subscription = service.subscribe(state => {
      Object.keys(state.context).forEach(key => {
        formikSetFieldValue(key, state.context[key]);
      });
      formikSetFieldValue('different_invoice_address', state.value === 'different_invoice_address');
    });
    return subscription.unsubscribe;
  }, []);

  useEffect(() => {
    if (opportunity !== null) {
      send({ type: 'LOAD', opportunity });
    }
  }, opportunity);

  const initialValues = merge({}, opportunity, {
    different_invoice_address: getBoolean(opportunity, 'different_invoice_address'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validator}
      onSubmit={onSubmit}
      enableReinitialize
      render={({ isValid, dirty, errors, setFieldValue }) => {
        if (formikSetFieldValue === null) {
          formikSetFieldValue = setFieldValue;
        }
        if (opportunity === null) {
          return null;
        }
        return (
          <Form>
            <fieldset>
              <CustomerFields options={{ shown: { title: false } }} />
            </fieldset>
            <fieldset>
              <TextareaField name="description" title="Description / Notes" />
            </fieldset>
            <fieldset>
              <Addresses current={current} send={send} />
            </fieldset>
            <Grid align="right">
              <Col width="auto">
                <BackButton link={backUrl} />
                <Button format="primary" type="submit" disabled={isSubmitDisabled(dirty, isValid, errors)} testId="save">
                  Save
                </Button>
              </Col>
            </Grid>
          </Form>
        );
      }}
    />
  );
};

CustomerDetailsForm.defaultProps = {
  backUrl: '',
};

CustomerDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  opportunity: PropTypes.shape({}).isRequired,
  backUrl: PropTypes.string,
};

export default CustomerDetailsForm;
