import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import { NumberField } from '@zing/neo-common/dist/components/forms/fields/NumberField';
import { SelectField } from '@zing/neo-common/dist/components/forms/fields/SelectField';
import { TextareaField } from '@zing/neo-common/dist/components/forms/fields/TextareaField';
import { ToggleField } from '@zing/neo-common/dist/components/forms/fields/ToggleField';
import Button from 'hollywood/dist/components/Button';
import ErrorMessage from './ErrorMessage';

const validationSchema = Yup.object().shape({
  voucher_code: Yup.string().required('Required'),
  voucher_type: Yup.string().required('Required'),
  discount: Yup.number()
    .when('voucher_type', {
      is: 'percent',
      then: schema =>
        schema
          .max(100, 'Max 100%')
          .min(0, 'Min 0%')
          .required('Required'),
    })
    .when('voucher_type', {
      is: 'fixed',
      then: schema => schema.min(0, 'Min £0').required('Required'),
    }),

  available_from_date: Yup.string().required('Required'),
  available_to_date: Yup.string().required('Required'),
  single_use: Yup.string().required('Required'),
  active: Yup.string().required('Required'),
});

export function VoucherCodeForm({ initialValues, onSubmit, state, error }) {
  const isEditMode = initialValues.uuid;
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values }) => (
        <Form style={{ maxWidth: 800 }}>
          <Grid gutters="sm">
            <Col width="4">
              <TextField name="voucher_code" title="Code" type="text" disabled={isEditMode} />
            </Col>
            <Col width="4">
              <SelectField
                name="voucher_type"
                title="Type"
                options={[
                  { label: 'Fixed', value: 'fixed' },
                  { label: 'Percent', value: 'percentage' },
                ]}
                disabled={isEditMode}
              />
            </Col>
            <Col width="4">
              <NumberField
                name="discount"
                title="Discount"
                type="text"
                min={0}
                max={values.voucher_type === 'percentage' ? 100 : null}
                // eslint-disable-next-line no-nested-ternary
                prefix={values.voucher_type === 'fixed' ? '£' : values.voucher_type === 'percentage' ? '%' : null}
                disabled={values.voucher_type === '' || isEditMode}
              />
            </Col>
            <Col width="12">
              <TextareaField name="description" title="Description" />
            </Col>
            <Col width="6">
              <TextField name="available_from_date" title="Valid From:" type="date" disabled={isEditMode} />
            </Col>
            <Col width="6">
              <TextField name="available_to_date" title="Valid To:" type="date" />
            </Col>
            <Col width="auto">
              <ToggleField
                name="single_use"
                title="Single use?"
                options={[
                  { label: 'Yes', value: '1' },
                  { label: 'No', value: '0' },
                ]}
                goodOption="1"
                disabled={isEditMode}
              />
            </Col>
            <Col width="auto">
              <ToggleField
                name="active"
                title="Active?"
                options={[
                  { label: 'Yes', value: '1' },
                  { label: 'No', value: '0' },
                ]}
                goodOption="1"
              />
            </Col>
            <Col width="12">
              {error ? <ErrorMessage>{error}</ErrorMessage> : null}
              <Button format="secondary" link="/diagnostics/voucher-codes/list">
                Cancel
              </Button>
              <Button format="primary" type="submit" disabled={state === 'SUBMITTING'} loading={state === 'SUBMITTING'}>
                {initialValues.voucher_code ? 'Update' : 'Add'} Code
              </Button>
            </Col>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

VoucherCodeForm.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    uuid: PropTypes.string,
    voucher_code: PropTypes.string,
    voucher_type: PropTypes.string,
    description: PropTypes.string,
    single_use: PropTypes.string,
    discount: PropTypes.string,
    available_from_date: PropTypes.string,
    available_to_date: PropTypes.string,
    active: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  error: PropTypes.string,
};
VoucherCodeForm.defaultProps = {
  error: null,
};
