import { sortByFieldAsc } from '@zing/neo-common/dist/redux/product/accessor';
import { toFixedNumber } from '@zing/neo-common/dist/lib/safe';
import { VAT_RATE1, VAT_RATE2 } from '../../../../config';
import pimProductTypes from '@zing/neo-common/dist/accessors/pim-product-types';
import cloneDeep from 'lodash.clonedeep';
import { toFixed } from '@zing/neo-common/dist/lib/safe/to-fixed';

export const serviceComponentTypes = [
  pimProductTypes.installation,
  pimProductTypes.solarAddOn,
  pimProductTypes.batteryAddOn,
  pimProductTypes.installationAddOn,
  pimProductTypes.smartHeatingAddOn,
  pimProductTypes.smartHomeAddOn,
  pimProductTypes.eOnDiscountOrOffer,
  pimProductTypes.oAndMOption,
  pimProductTypes.eOnTariff,
  pimProductTypes.scaffolding,
];

export const multiply = by => value => value * by;

export const buildProductsOfInterest = journey =>
  [journey.hasSolarPV ? 'solar-pv' : null, journey.hasBattery ? 'battery-module' : null].filter(x => x !== null);

export const totalRatedPowerKw = roofs => roofs.reduce((current, roof) => current + Number(roof.ratedPowerKw), 0);

export const systemTotalRatedPowerKw = roofs => totalRatedPowerKw(roofs);

export const totalSystemSize = (journey, roofs, battery) => {
  const parts = [];
  if (journey.hasSolarPV) {
    const totalRatedPower = systemTotalRatedPowerKw(roofs);
    parts.push(`${toFixed(totalRatedPower, 3)} kWp`);
  }
  if (journey.hasBattery) {
    const systemTotalBatteryEnergyRatingKwh = battery.energyRatingKwh || 0;
    parts.push(`${toFixed(systemTotalBatteryEnergyRatingKwh, 2)} kWh`);
  }
  return parts.join(' / ');
};

export const makeBatteriesArray = battery => [
  {
    id: battery?.prodId || '',
    qty: battery?.requires?.find(r => r.product?.type === 'Battery module')?.quantity || 1,
    battery: { ...battery, requires: [] },
  },
];

export const getProductsForCrm = items => {
  const buildOutputArray = items.map(item => ({
    id: item?.product?.prodId,
    name: item?.product?.name,
    qty: item?.exportQuantity || item?.quantity,
    pushProductToCrm: Boolean(item?.product?.pushProductToCrm) || false,
    crmProdPurchasedPriority: item?.product?.crmProdPurchasedPriority,
  }));
  const result = buildOutputArray
    .filter(item => item?.pushProductToCrm === true)
    .sort(sortByFieldAsc('crmProdPurchasedPriority'))
    .map(x => {
      delete x.crmProdPurchasedPriority;
      delete x.pushProductToCrm;
      return x;
    });

  // console.log('');
  // console.log('DEBUG');
  // console.log('items', items);
  // console.log('buildOutputArray', buildOutputArray);
  // console.log('result', result);
  return result;
};

export const getShowInQuoteProducts = items => {
  const bespokeInstall = items.find(i => i?.product?.prodId === 'BESPOKE-INSTALL-COST');
  const bespokeScaffolding = items.find(i => i?.product?.prodId === 'BESPOKE-SCAFF-COST');

  return items
    .map(item => ({
      id: item?.product?.prodId,
      name: item?.product?.zingAppName !== undefined ? item?.product?.zingAppName : item?.product?.name,
      type: item?.product?.type,
      showInQuote: item?.product?.showInQuote,
      crmProdPurchasedPriority: item?.product?.crmProdPurchasedPriority,
    }))
    .filter(item => {
      // filter out all installation items other than BESPOKE-INSTALL-COST, if it is selected
      if (bespokeInstall && item.type === 'Installation') {
        return item.id === bespokeInstall.product.prodId;
      }
      // filter out all scaffolding items other than BESPOKE-SCAFF-COST, if it is selected
      if (bespokeScaffolding && item.type === 'Scaffolding') {
        return item.id === bespokeScaffolding.product.prodId;
      }
      return true;
    })
    .filter(item => item.showInQuote === true)
    .sort(sortByFieldAsc('crmProdPurchasedPriority'))
    .map(x => {
      // we need to do this or it affects the original calcs object
      const result = { ...x };
      delete result.crmProdPurchasedPriority;
      return result;
    });
};

export const convertDiscountType = discountType => {
  switch (discountType) {
    case 'percentage':
      return 'Percentage';
    case 'colleague':
      return 'Colleague';
    case 'fixed':
      return 'Fixed';
    case 'code':
      return 'Code';
  }
  return 'None';
};

export const makePaymentOptionsObject = (calcs, paymentType) => ({
  annualPercentageRate: 0,
  beforeDiscount: {
    totalCost: toFixedNumber(calcs?.totals?.exVatTotal || 0),
    totalCostIncVat: toFixedNumber(calcs?.beforeDiscountTotals?.incVatTotal || 0),
    totalVat: 0, // deprecated - no longer required in PIM Calcs
    totalVatAtRate1: 0, // deprecated - no longer required in PIM Calcs
    totalVatAtRate2: 0, // deprecated - no longer required in PIM Calcs
    totalDueAfterInstallation: 0, // deprecated - no longer required in PIM Calcs
    balanceIncVat: 0, // deprecated - no longer required in PIM Calcs
    depositIncVat: 0, // deprecated - no longer required in PIM Calcs
    depositP: 0, // deprecated
    remainder: 0, // deprecated
    initial: 0, // deprecated
    numberOfYears: 0, // deprecated
    numberOfPayments: 0, // deprecated
    monthlyPayment: 0, // deprecated
  },
  discountAmount: toFixedNumber(calcs?.totals?.discounted || 0),
  discountPercentage: toFixedNumber(calcs?.settings?.discountPercentage || 0, 4),
  discountType: convertDiscountType(calcs?.settings?.discountType),
  discountCode: calcs?.settings?.discountCode || '',
  postDiscount: {
    totalCost: toFixedNumber(calcs?.totals?.incVatTotal - calcs?.totals?.vatTotal),
    totalCostIncVat: toFixedNumber(calcs?.totals?.incVatTotal || 0),
    totalVat: toFixedNumber(calcs?.totals?.vatTotal || 0),
    totalVatAtRate1: toFixedNumber(calcs?.totals?.vatAtRate1),
    totalVatAtRate2: toFixedNumber(calcs?.totals?.vatAtRate2),
    totalDueAfterInstallation: toFixedNumber(calcs?.paymentOptions?.totalDueAfterInstallation), // test
    balanceIncVat: toFixedNumber(calcs?.paymentOptions?.totalDueAfterInstallation),
    depositIncVat: toFixedNumber(calcs?.paymentOptions?.depositIncVat),
    depositP: 0, // deprecated
    initial: 0, // deprecated
    numberOfYears: 0, // deprecated
    numberOfPayments: 0, // deprecated
    monthlyPayment: 0, // deprecated
    amountOnWhichVatRate1Charged: toFixedNumber(calcs?.totals?.amountOnWhichVatRate1Charged),
    amountOnWhichVatRate2Charged: toFixedNumber(calcs?.totals?.amountOnWhichVatRate2Charged),
  },
  twentyFiveYearBenefit: toFixedNumber(calcs?.costsAndSavings?.twentyFiveYearBenefit),
  vatRate1: VAT_RATE1,
  vatRate2: VAT_RATE2,
  paymentType,
});

export const determineIsG99Required = (overrideG99, isG99RequiredSuggestion) => {
  if (overrideG99 === 'suggested') {
    return isG99RequiredSuggestion;
  }
  return overrideG99 === 'yes';
};

export const makeProductCostsObject = calcs => {
  const { values = [] } = calcs || {};
  const productsToShowInQuote = getShowInQuoteProducts(values);
  const scaffoldItems = values.filter(x => x.product.type === pimProductTypes.scaffolding);
  const financeItems = values.filter(x => x.product.type === pimProductTypes.financeCosts);
  const expectedKitCost = calcs?.totals?.costTotalGoods || 0;
  const expectedScaffoldCost = scaffoldItems.reduce((current, next) => current + next?.product?.costPerItemServices || 0, 0);
  const financeCost = financeItems.reduce((current, next) => current + (next?.product?.costTotal || 0), 0);
  const expectedInstallCost = calcs?.totals?.costTotalServices - expectedScaffoldCost - financeCost;

  // console.log('');
  // console.log('Debugging makeProductCostsObject');
  // console.log('values', values);
  // console.log('scaffoldItems', scaffoldItems);
  // console.log('calcs.totals', calcs.totals);
  // console.log('expectedKitCost', expectedKitCost);
  // console.log('expectedScaffoldCost', expectedScaffoldCost);
  // console.log('expectedInstallCost', expectedInstallCost);
  // console.log('financeCost', financeCost);

  return {
    expected_gm_percentage: calcs?.totals?.expectedGmPercentage || 0,
    expected_gm_value: calcs?.totals?.expectedGmValue || 0,
    expected_install_cost: expectedInstallCost, // is the remaining sum of Services (minus expected_scaffold_cost and financeCost).
    expected_kit_cost: expectedKitCost, // should be the sum of Goods.
    expected_scaffold_cost: expectedScaffoldCost, // should be the sum of Services for type = "scaffolding"
    services: productsToShowInQuote.filter(x => serviceComponentTypes.includes(x.type)).map(x => x.name),
    finance_cost: financeCost,
    technicalComponents: productsToShowInQuote.filter(x => !serviceComponentTypes.includes(x.type)).map(x => x.name),
  };
};

export const cloneMachineForStorage = state => {
  const copy = cloneDeep(state);
  copy.actions = [];
  copy.activities = {};
  copy.event = {};
  copy.events = [];
  copy.history = {};
  copy.historyValue = {};
  // eslint-disable-next-line no-underscore-dangle
  copy._event = {};

  // todo: remove all the products and dynamic calcs content..
  // this will be recalculated when its loaded
  return copy;
};
