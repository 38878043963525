import React from 'react';
import camelCase from 'lodash.camelcase';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Checkbox = ({ name, id, label, className, disabled, hasError, onChange, onBlur, testId, checked }) => {
  const useId = id || name;
  const useTestId = testId || camelCase(name);
  return (
    <>
      <input
        name={name}
        id={useId}
        className={classnames('hollywood-checkbox', className, hasError ? 'invalid' : null)}
        type="checkbox"
        disabled={disabled}
        data-testid={useTestId}
        checked={!!checked}
        onChange={event => {
          if (onChange) onChange(event, event.target.checked);
        }}
        onClick={event => {
          // eslint-disable-next-line no-console
          console.log('click event', event);
        }}
        onBlur={onBlur}
      />
      <label className="hollywood-checkbox-label" htmlFor={useId}>
        {label}
      </label>
    </>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  testId: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
  hasError: false,
  id: '',
  onBlur: null,
  onChange: null,
  testId: null,
};

export default Checkbox;
