import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const NoQuotes = ({ shaded }) => (
  <div className={classnames('pipeline_no_quotes', shaded ? 'shaded' : null)} data-testid="pipeline_no_quotes">
    <div className="no_quotes range-center">
      <p>There are no quotes for this opportunity.</p>
    </div>
  </div>
);

NoQuotes.propTypes = {
  shaded: PropTypes.bool.isRequired,
};

export default NoQuotes;
