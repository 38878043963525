import React from 'react';
import Twig from 'twig';
import PropTypes from 'prop-types';

import './EmailPreview.scss';

const EmailPreview = ({ template, data, width, height }) => {
  const preparedTemplate = template ? Twig.twig({ data: template }) : null;
  let compiled = preparedTemplate ? preparedTemplate.render(data) : '';
  compiled = compiled.replace(/href/g, 'data-href');
  return <iframe className="email-preview" title="Email preview" srcDoc={compiled} width={width} height={height} />;
};

EmailPreview.propTypes = {
  template: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

EmailPreview.defaultProps = {
  data: null,
};

export default EmailPreview;
