import Icon from '@zing/neo-common/dist/components/Icon';
import Logo from '@zing/neo-common/dist/components/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import useDiagnosticsMenuService from '../../hooks/use-diagnostics-menu-service';
import useMenuService from '../../hooks/use-menu-service';
import classnames from 'classnames';

import './Header.scss';

const Header = ({ title, menuButton, logo, testId, transparent, bordered, extended, children, diagnostics }) => {
  // must be written like this because hooks cant be conditional
  const [diagnosticsMenu] = useDiagnosticsMenuService();
  const [mainMenu] = useMenuService();
  const api = diagnostics ? diagnosticsMenu : mainMenu;
  return (
    <header
      className={classnames(
        'app-header',
        transparent ? 'app-header--transparent' : null,
        bordered ? 'app-header--bordered' : null,
        extended ? 'app-header--extended' : null,
        diagnostics ? 'app-header--diagnostics' : null
      )}
      data-testid={testId || 'appHeader'}
    >
      {menuButton && (
        <button
          type="button"
          className={classnames('menu-btn', menuButton === 'inverted' ? 'inverted' : null, diagnostics ? 'diagnostics' : null)}
          onClick={() => api.open()}
          data-testid="openMenuButton"
        >
          <Icon icon="menu" flush />
        </button>
      )}

      {title && <h1>{title}</h1>}

      {logo && <Logo inverted={logo === 'inverted'} />}

      {children}
    </header>
  );
};

Header.propTypes = {
  bordered: PropTypes.bool,
  children: PropTypes.node,
  diagnostics: PropTypes.bool,
  extended: PropTypes.bool,
  logo: PropTypes.oneOf([true, false, 'inverted']),
  menuButton: PropTypes.oneOf([true, false, 'inverted']),
  testId: PropTypes.string,
  title: PropTypes.string,
  transparent: PropTypes.bool,
};

Header.defaultProps = {
  bordered: true,
  children: null,
  diagnostics: false,
  extended: false,
  logo: true,
  menuButton: true,
  testId: '',
  title: '',
  transparent: false,
};

export default Header;
