import { SalesConsultantInitialState } from './initial-state/sales-consultant';
import { PackageInitialState } from './initial-state/package';
import { RoofInitialState } from './initial-state/roof';
import { DecisionInitialState } from './initial-state/decisions';

export const initialState = {
  app_uuid: '',
  add_on_products: [], // shape = { name: '', qty: '' }
  annual_electricity_cost: '',
  annual_electricity_demand_kwh: '',
  compound_degradation: '',
  current_url: null,
  customer_electricity_price: 16, // this should eventually come from the CMS
  customer_free_epc: false,
  decisions: DecisionInitialState,
  electricity_mostly_used: [],
  epc_available: 'No',
  epc_rating: '',
  epc_register_url: '',
  eligible_customer: '',
  estimated_electricity_demand_kwh: '',
  existing_pv_annual_yield_kwh: '',
  existing_pv_inverter_total_rated_output_kw: '',
  grid_connected_pv_on_site: 'No',
  installation_notes: '',
  installation_type: '',
  is_g99_required: '',
  is_g99_required_overridden: false,
  is_listed_building: 'No',
  // is_rosemary_or_slate_roof_preset: 'No',
  meter_type: '',
  mpan: '',
  number_of_faces: '',
  number_of_occupants: '',
  number_of_stories: '',
  packages: [{ ...PackageInitialState, package_name: '' }],
  poiHasBattery: false,
  poiHasEvCharging: false,
  poiHasSolarPv: false,
  poiIsBatteryOnly: false,
  poiIsEvChargingOnly: false,
  poiIsSolarPvOnly: false,
  poiIsBatteryJourney: false,
  postcode: '',
  postcode_zone_id: '',
  products_of_interest: [],
  property_type: '',
  roofs: [{ ...RoofInitialState, name: 'Roof 1' }],
  sales_consultant: { ...SalesConsultantInitialState },
  scaffolding_installation_complexity: 'Regular',
  solar_package: '',
  system_type: '',
  total_panels: 0,
  trenching_requirement_meters: '',
};
