import { get } from '@zing/neo-common/dist/lib/safe';
import _set from 'lodash.set';

export const yesNoOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const yesNoNaOptions = [...yesNoOptions, { label: 'NA', value: 'NA' }];

export function validate(state) {
  const { values } = state;
  const errors = {};

  // ON ARRIVAL
  if (values.onArrival.siteSafetyInductions === 'No') {
    _set(errors, 'onArrival.siteSafetyInductions', 'STOP_WORK');
  }
  if (values.onArrival.siteEmergencyProcedures === 'No') {
    _set(errors, 'onArrival.siteEmergencyProcedures', 'STOP_WORK');
  }
  if (values.onArrival.unusualEnvironmentConditions === 'No') {
    _set(errors, 'onArrival.unusualEnvironmentConditions', 'STOP_WORK');
  }
  if (values.onArrival.appropriateEquipment === 'No') {
    _set(errors, 'onArrival.appropriateEquipment', 'STOP_WORK');
  }
  if (values.onArrival.opportunityInCalendar === 'No' && values.onArrival.opportunityInCalendarNote === '') {
    _set(errors, 'onArrival.opportunityInCalendarNote', 'This field is required');
  }

  // MEET CUSTOMER
  if (values.meetCustomer.vulnerableInHousehold === 'Yes' && values.meetCustomer.vulnerableInHouseholdNote === '') {
    _set(errors, 'meetCustomer.vulnerableInHouseholdNote', 'This field is required');
  }
  if (values.meetCustomer.specialPowerRequirements === 'Yes' && values.meetCustomer.specialPowerRequirementsNote === '') {
    _set(errors, 'meetCustomer.specialPowerRequirementsNote', 'This field is required');
  }
  if (values.meetCustomer.freeFromRiskyPets === 'No' && values.meetCustomer.freeFromRiskyPetsNote === '') {
    _set(errors, 'meetCustomer.freeFromRiskyPetsNote', 'This field is required');
  }
  if (values.meetCustomer.isCustomerPresentForVisit === 'No') {
    _set(errors, 'meetCustomer.isCustomerPresentForVisit', 'STOP_WORK');
  }
  if (values.meetCustomer.customerAuthForSurvey === 'No') {
    _set(errors, 'meetCustomer.customerAuthForSurvey', 'STOP_WORK');
  }

  // START SURVEY
  if (values.startSurvey.familiarWithProcedure === 'No') {
    _set(errors, 'startSurvey.familiarWithProcedure', 'STOP_WORK');
  }
  if (values.startSurvey.safeRouteOfAccess === 'No') {
    _set(errors, 'startSurvey.safeRouteOfAccess', 'STOP_WORK');
  }
  if (values.startSurvey.areaSuitablyLit === 'No') {
    _set(errors, 'startSurvey.areaSuitablyLit', 'STOP_WORK');
  }
  if (values.startSurvey.correctToolsAndCondition === 'No') {
    _set(errors, 'startSurvey.correctToolsAndCondition', 'STOP_WORK');
  }
  if (values.startSurvey.isWorkAreaSafe === 'No') {
    _set(errors, 'startSurvey.isWorkAreaSafe', 'STOP_WORK');
  }
  if (values.startSurvey.anyAdditionalHazards === 'Yes') {
    _set(errors, 'startSurvey.anyAdditionalHazards', 'STOP_WORK');
  }

  // AFTERWARDS
  if (values.afterwards.asbestosOnSite === 'Yes') {
    _set(errors, 'afterwards.asbestosOnSite', 'STOP_WORK');
  }

  return errors;
}

export function isValid(state) {
  return Object.values(state.errors).filter(Boolean).length === 0;
}
export function fieldHasError(name, state) {
  return get(state.touched, name, false) && get(state.errors, name, '');
}

export function shouldStopWork(section, state) {
  return Object.values(state.errors[section] || {}).filter(e => e === 'STOP_WORK').length > 0;
}
