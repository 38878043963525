import Appointment from '../pages/appointment';
import CreateOpportunity from '../pages/CreateOpportunity';
import CustomerDetails from '../pages/CustomerDetails';
import DiagnosticsPage from '../pages/diagnostics/diagnostics-page';
import Notifications from '../pages/Notifications';
import Opportunities from '../pages/Opportunities';
import PimBeforeWeStart from '../pages/before-we-start';
import PimSurveyPageConnected from '../pim/survey/survey-connected';
import Pipeline from '../pages/Pipeline';
import Presenter from '../pages/Presenter';
import RiskAssessment from '../pages/RiskAssessment';
import Settings from '../pages/Settings';
import PimStartSurvey from '../pages/start-survey';
import TermsAndConditions from '../pages/TermsAndConditions';
import ThankYouForMeetingUs from '../pages/ThankYouForMeetingUs';
import { ThankYouForChoosing } from '../pages/ThankYouForChoosing';
import QuickViewQuote from '../pages/quick-view-quote';

const privateRoutes = [
  { path: '/notifications/:status(read|unread)', component: Notifications },
  { path: '/opportunities', component: Opportunities },

  // PIM Survey uses /appointment path
  { path: '/appointment/:id/:meetingId', component: Appointment, exact: true },
  { path: '/appointment/:id/:meetingId/before-we-start', component: PimBeforeWeStart },
  { path: '/appointment/:id/:meetingId/customer-details', component: CustomerDetails },
  { path: '/appointment/:id/:meetingId/thank-you-for-meeting-us', component: ThankYouForMeetingUs },
  { path: '/appointment/:id/:meetingId/presenter', component: Presenter },
  { path: '/appointment/:id/:meetingId/start-survey', component: PimStartSurvey },
  { path: '/appointment/:id/:meetingId/survey', component: PimSurveyPageConnected },

  // Original survey uses /opportunity path
  // todo: is this still used? can we delete?
  { path: '/opportunity/:id/:meetingId/terms-and-conditions/:packageId?/:quoteId?', component: TermsAndConditions },
  {
    path: '/opportunity/:id/:meetingId/thank-you-for-choosing-eon/:packageId/:quoteId',
    component: ThankYouForChoosing,
  },
  { path: '/opportunity/create', component: CreateOpportunity },
  { path: '/pipeline/view-quote', component: QuickViewQuote },
  { path: '/pipeline/:mode', component: Pipeline },
  { path: '/presenter', component: Presenter },
  { path: '/risk-assessment', component: RiskAssessment },
  { path: '/settings/(app|profile)', component: Settings },
  { path: '/diagnostics', component: DiagnosticsPage },
];

export default privateRoutes;
