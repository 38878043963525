/* eslint-disable camelcase */
// import PropTypes from 'prop-types';
import { AppContext } from '../../../../contexts/app-context';
import { CustomerFields } from '../../../../components/forms/CustomerFields/CustomerFields';
import { Form, Formik } from 'formik';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { useService } from '@xstate/react';
import Button from 'hollywood/dist/components/Button';
import DiagnosticsHeader from '../../../../components/diagnostics-header';
import omit from 'lodash.omit';
import React, { useContext } from 'react';
import useConstant from 'use-constant';
import { editUserValidator, addUserValidator } from './validator';
import classNames from 'classnames';

const UsersAdminPage = () => {
  // grab the machine out of app
  const app = useContext(AppContext);
  const [appState] = useService(app);

  const [state, send] = useService(appState?.context?.diagnosticsUsers);

  const api = useConstant(() => ({
    fetch: () => send('FETCH'),
    filter: filter => send('FILTER', { filter }),
    onAddUser: () => send('ADD'),
    onCancel: () => send('CANCEL'),
    onEditUser: app_uuid => send('EDIT', { data: { data: { app_uuid } } }),
    onRetry: () => send('RETRY'),
    onSaveUser: data => send('SAVE', { data }),
    onUpdateUser: values => send('UPDATE', { app_uuid: values.app_uuid, data: omit(values, 'app_uuid') }),
  }));

  if (state.context.accessToken && state.matches('listView.idle')) {
    api.fetch();
  }

  const { users, user, filter } = state.context;

  return (
    <>
      <DiagnosticsHeader title="Users">
        <div className="diagnostics-header-buttons">
          {state.matches('listView.list') && (
            <Button format="primary" onClick={() => api.onAddUser()}>
              Add user
            </Button>
          )}
        </div>
      </DiagnosticsHeader>
      <main className="diagnostics">
        {state.matches('listView.list') && (
          <>
            <div className="users-filter">
              <button
                type="button"
                onClick={() => api.filter('enabled')}
                className={classNames('users-filter__btn', filter === 'enabled' && 'users-filter__btn--active')}
              >
                Enabled
              </button>
              <button
                type="button"
                onClick={() => api.filter('disabled')}
                className={classNames('users-filter__btn', filter === 'disabled' && 'users-filter__btn--active')}
              >
                Disabled
              </button>
              <button
                type="button"
                onClick={() => api.filter('all')}
                className={classNames('users-filter__btn', filter === 'all' && 'users-filter__btn--active')}
              >
                All
              </button>
            </div>
            <table className="results_table">
              <thead>
                <tr>
                  <td className="subHeading">User</td>
                  <td className="subHeading">Email</td>
                  <td className="subHeading">Login</td>
                  <td className="subHeading" width="120">
                    Role
                  </td>
                  <td className="subHeading" width="120">
                    Status
                  </td>
                  <td className="subHeading" width="120">
                    Actions
                  </td>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={user.app_uuid} data-testid={`${user.app_uuid}`}>
                    <td>
                      {user.firstname} {user.lastname}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.email_login}</td>
                    <td>{user.role}</td>
                    <td>{user.enabled === '1' ? 'Enabled' : 'Disabled'}</td>
                    <td>
                      <Button format="primary" onClick={() => api.onEditUser(user.app_uuid)}>
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {state.matches('addUser') && (
          <Formik
            initialValues={{
              email_login: '',
              email: '',
              enabled: '1',
              firstname: '',
              lastname: '',
              mobile: '',
              role: '',
              telephone: '',
              password: '',
              password_confirmation: '',
            }}
            validationSchema={addUserValidator}
            enableReinitialize
            onSubmit={values => {
              api.onSaveUser(values);
            }}
            render={({ submitForm, values }) => (
              <Form>
                <fieldset>
                  <h3>Add user</h3>
                  <CustomerFields
                    options={{
                      shown: {
                        lnumber: false,
                        title: false,
                        role: true,
                        emailLogin: true,
                        password: values.role === 'External',
                        password_confirmation: values.role === 'External',
                      },
                      required: {
                        role: true,
                        emailLogin: true,
                        password: values.role === 'External',
                        password_confirmation: values.role === 'External',
                      },
                    }}
                  />
                </fieldset>
                <Grid align="right" gutters="xl" className="diagnosticsActions">
                  <Col align="right">
                    <Button format="secondary" onClick={() => api.onCancel()}>
                      Cancel
                    </Button>
                    <Button format="primary" onClick={() => submitForm()}>
                      Save
                    </Button>
                  </Col>
                </Grid>
              </Form>
            )}
          />
        )}
        {state.matches('editUser') && (
          <Formik
            initialValues={{ ...user, password: '', password_confirmation: '' }}
            validationSchema={editUserValidator}
            enableReinitialize
            onSubmit={values => {
              api.onUpdateUser(values);
            }}
            render={({ submitForm, values, errors }) => (
              <Form>
                <hidden name="app_uuid" value={user?.app_uuid} />
                <fieldset>
                  <h3>Edit user</h3>
                  <CustomerFields
                    options={{
                      shown: {
                        lnumber: false,
                        title: false,
                        role: true,
                        emailLogin: true,
                        enabled: true,
                        password: values.role === 'External',
                        password_confirmation: values.role === 'External',
                      },
                      required: {
                        role: true,
                        emailLogin: true,
                        enabled: true,
                        password_confirmation: values.role === 'External' && !!values.password,
                      },
                    }}
                  />
                </fieldset>
                <Grid align="right" gutters="xl" className="diagnosticsActions">
                  <Col align="right">
                    <Button format="secondary" onClick={() => api.onCancel()}>
                      Cancel
                    </Button>
                    <Button format="primary" onClick={() => submitForm()} disabled={Object.keys(errors).length > 0}>
                      Save
                    </Button>
                  </Col>
                </Grid>
              </Form>
            )}
          />
        )}
      </main>
    </>
  );
};

UsersAdminPage.propTypes = {};

export default UsersAdminPage;
