import * as Yup from 'yup';
import {
  MSG_ADDRESS_LINE_1_REQUIRED,
  MSG_CHANNEL_REQUIRED,
  MSG_CITY,
  MSG_EMAIL_INVALID,
  MSG_EMAIL_REQUIRED,
  MSG_EON_CUSTOMER_REQUIRED,
  MSG_FIRST_NAME_REQUIRED,
  MSG_LAST_NAME_REQUIRED,
  MSG_LAT_REQUIRED,
  MSG_LNG_REQUIRED,
  MSG_LEAD_CATEGORY_REQUIRED,
  MSG_MEETING_STATUS_REQUIRED,
  MSG_MEETING_TYPE_REQUIRED,
  MSG_MOBILE_NUMERIC,
  MSG_MOBILE_REQUIRED,
  MSG_MPAN_REQUIRED,
  MSG_PHONE_NUMERIC,
  MSG_PHONE_REQUIRED,
  MSG_HOUSE_NUMBER_REQUIRED,
  MSG_HOUSE_NUMBER_MAX_LENGTH,
} from '../../../config';

require('@zing/neo-common/dist/lib/yup');

export default Yup.object().shape({
  firstname: Yup.string().required(MSG_FIRST_NAME_REQUIRED),
  lastname: Yup.string().required(MSG_LAST_NAME_REQUIRED),
  mobile: Yup.number()
    .typeError(MSG_MOBILE_NUMERIC)
    .required(MSG_MOBILE_REQUIRED),
  telephone: Yup.number()
    .typeError(MSG_PHONE_NUMERIC)
    .required(MSG_PHONE_REQUIRED),
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_EMAIL_REQUIRED),
  channel: Yup.string().required(MSG_CHANNEL_REQUIRED),
  eon_customer: Yup.string().required(MSG_EON_CUSTOMER_REQUIRED),
  lead_category: Yup.string().required(MSG_LEAD_CATEGORY_REQUIRED),
  lat: Yup.string().required(MSG_LAT_REQUIRED),
  lng: Yup.string().required(MSG_LNG_REQUIRED),
  meeting_type: Yup.string().required(MSG_MEETING_TYPE_REQUIRED),
  meeting_status: Yup.string().required(MSG_MEETING_STATUS_REQUIRED),
  house_number: Yup.string()
    .max(20, MSG_HOUSE_NUMBER_MAX_LENGTH)
    .required(MSG_HOUSE_NUMBER_REQUIRED),
  address_line_1: Yup.string().required(MSG_ADDRESS_LINE_1_REQUIRED),
  postcode: Yup.string().isValidPostcode(),
  city: Yup.string().required(MSG_CITY),
  mpan: Yup.string().required(MSG_MPAN_REQUIRED),
  invoice_house_number: Yup.string()
    .max(20, MSG_HOUSE_NUMBER_MAX_LENGTH)
    .required(MSG_HOUSE_NUMBER_REQUIRED),
  invoice_address_line_1: Yup.string().required(MSG_ADDRESS_LINE_1_REQUIRED),
  invoice_city: Yup.string().required(MSG_CITY),
  invoice_postcode: Yup.string().isValidPostcode(),
});
