import { log } from 'xstate/es/actions';

const { assign, Machine } = require('xstate');

/**
 * We MUST ensure _some_ API requests are never lost
 */
const apiMachine = Machine(
  {
    id: 'api',
    initial: 'offline',
    context: {
      queue: [],
    },
    states: {
      offline: {
        id: 'offline',
        on: {
          STATUS_ONLINE: 'online.idle',
          REQUEST: {
            actions: [
              assign({
                queue: (c, e) => [].concat(c.queue).concat(e),
              }),
            ],
          },
        },
      },
      online: {
        states: {
          idle: {
            entry: log('polling queue'),
            on: {
              STATUS_OFFLINE: {
                target: '#offline',
                actions: log('Idling, so straight to offline mode.'),
              },
            },
          },
          sending: {
            on: {
              STATUS_OFFLINE: {
                target: '#offline',
                actions: log('Sending, so delay going offline'),
              },
            },
          },
          success: {
            on: {
              STATUS_OFFLINE: {
                target: '#offline',
                actions: log('Completed, so wait until in idle to offline'),
              },
            },
          },
          fail: {
            on: {
              STATUS_OFFLINE: {
                target: '#offline',
                actions: log('Request, failed. A decision would need to be made before going offline'),
              },
            },
          },
        },
        on: {
          REQUEST: {
            target: 'online.idle',
            actions: [
              assign({
                queue: (c, e) => [].concat(c.queue).concat(e),
              }),
            ],
          },
        },
      },
    },
  },
  {
    services: {
      saveRequestsFromQueue: () => {},
      loadRequestsIntoQueue: () => {},
    },
  }
);

export default apiMachine;
