import * as types from './actionTypes';
import initialState from './initialState';

import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;
    case types.QUOTE_EMAIL_RESET:
      return initialState;
    case types.QUOTE_EMAIL_REQUEST:
      return {
        ...state,
        processing: true,
        errors: null,
      };
    case types.QUOTE_EMAIL_COMMIT:
      return {
        ...state,
        processing: false,
        errors: null,
        template: action.payload.template,
      };
    case types.QUOTE_EMAIL_ROLLBACK:
      return {
        ...state,
        processing: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};
