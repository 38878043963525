import React from 'react';
import { useTransition, animated } from 'react-spring';
import Button from 'hollywood/dist/components/Button';

import './SlidesNavigation.scss';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import Icon from '@zing/neo-common/dist/components/Icon';
import BackButton from '../../../components/buttons/back';

const SlidesNavigation = ({ onClose, onSlideSelect, visible, slides, mode, onStartSurveyClick }) => {
  const transitions = useTransition(visible, null, {
    from: { opacity: 0, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,100%,0)' },
  });

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.nav style={props} className="presenter__slides-navigation" key={key}>
          <Grid gutters="sm" align="right" valign="center">
            {mode === 'opportunity' && (
              <>
                <Col>
                  <BackButton format="secondary" inverted link="./before-we-start" />
                </Col>
                <Col width="auto">
                  <Button format="primary" onClick={onStartSurveyClick} testId="go_to_survey">
                    Go to survey
                  </Button>
                </Col>
              </>
            )}
            <Col width="auto">
              <button type="button" onClick={onClose}>
                <Icon icon="close" />
              </button>
            </Col>
          </Grid>
          <div className="slide-previews">
            {slides.map((slide, index) => (
              <button type="button" className="slide-preview" key={slide.id} onClick={() => onSlideSelect(index)}>
                <img src={slide.thumbnail} width="" height="" alt={slide.title} />
              </button>
            ))}
          </div>
        </animated.nav>
      )
  );
};

export default SlidesNavigation;
