import React, { useContext } from 'react';
import { get, getNumber, toFixed } from '@zing/neo-common/dist/lib/safe';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { normalizeEnergySpend } from './normalizeEnergySpend';
import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { pounds } from '@zing/neo-common/dist/lib/display';

import './your-energy-spend.scss';

const YourEnergySpend = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons, energyInflationRate } = current.context;
  const { calcs } = comparisons[currentComparison];

  const cashFlowFirstYear = get(calcs?.cashflow, '[0]', {});
  const cashFlow10Years = get(calcs?.cashflow, '[9]', {});
  const cashFlow15Years = get(calcs?.cashflow, '[14]', {});
  const cashFlow20Years = get(calcs?.cashflow, '[19]', {});
  const cashFlow25Years = get(calcs?.cashflow, '[24]', {});

  const years = normalizeEnergySpend([cashFlowFirstYear, cashFlow10Years, cashFlow15Years, cashFlow20Years, cashFlow25Years]);

  return (
    <section className="energy-spend">
      <h1 className="eon-red">
        How much are you likely to spend
        <br /> on electricity over the next 25 years?
      </h1>

      <div className="energy-spend__chart">
        <ul className="energy-spend__chart__key">
          <li className="current-spend">Current expected spend</li>
          <li className="projected-spend">Projected spend with your chosen solar/storage solution</li>
          <li className="savings">Expected savings</li>
        </ul>

        <Grid gutters="sm" valign="bottom">
          {years.map((item, index) => (
            <Col key={`${item.current}-${item.currentP}=${item.projected}`}>
              <Grid valign="bottom" className="energy-spend__chart__bars">
                <Col>
                  <div className="bar current" style={{ height: `${item.currentP}%` }} data-testid={`energy__current_${index}`}>
                    <span>{pounds(getNumber(item, 'currentExpectedSpend'))}</span>
                  </div>
                </Col>
                <Col>
                  <div className="savings-bubble" data-testid={`energy__savings_${index}`}>
                    {pounds(getNumber(item, 'currentExpectedSavings'))}
                    <br /> saved
                  </div>
                  <div className="bar projected" style={{ height: `${item.projectedP}%` }} data-testid={`energy__projected_${index}`}>
                    <span>{pounds(getNumber(item, 'projectedSpend'))}</span>
                  </div>
                </Col>
              </Grid>
              <p className="range-center" data-testid={`energy__annual_cost_${index}`}>
                {index === 0 ? 'Annual cost' : `Year ${item.year}*`}
              </p>
            </Col>
          ))}
        </Grid>
      </div>
      <Grid valign="center">
        <Col>
          <small className="light" data-testid="energy__price_rise_text">
            * If prices rise by {toFixed(percentageAsNumber(energyInflationRate), 1)}% year on year
          </small>
        </Col>
        <Col width="auto">
          <h3 className="benefits-message">
            Total saved over 25 years{' '}
            <span data-testid="energy__total_benefits">{pounds(getNumber(cashFlow25Years, 'cumulativeTotalBenefit'))}</span>
          </h3>
        </Col>
      </Grid>
    </section>
  );
};

YourEnergySpend.propTypes = {};

export default YourEnergySpend;
