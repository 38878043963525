/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CustomerTableHeader = ({ getSortingClasses, changeSorting }) => (
  <div className="pipeline_table_header" data-testid="pipeline_table_header">
    <div className={classnames('pipeline_table_cell', 'tab_col')}>&nbsp;</div>
    <div
      className={classnames('pipeline_table_cell', 'date_col', getSortingClasses('created_at'))}
      onClick={() => changeSorting('created_at')}
      data-testid="sortby-created"
    >
      Date created
    </div>
    <div
      className={classnames('pipeline_table_cell', 'id_col', getSortingClasses('lnumber'))}
      onClick={() => changeSorting('lnumber')}
      data-testid="sortby-lnumber"
    >
      Customer ID
    </div>
    <div
      className={classnames('pipeline_table_cell', getSortingClasses('lastname'))}
      onClick={() => changeSorting('lastname')}
      data-testid="sortby-name"
    >
      Customer name
    </div>
    <div
      className={classnames('pipeline_table_cell', 'city_col', getSortingClasses('city'))}
      onClick={() => changeSorting('city')}
      data-testid="sortby-city"
    >
      City
    </div>
    <div
      className={classnames('pipeline_table_cell', 'sales_consultant_col', getSortingClasses('sales_consultant_email'))}
      data-testid="sortby-sales_consultant"
      onClick={() => changeSorting('sales_consultant_email')}
    >
      Sales consultant
    </div>
    <div className="pipeline_table_cell actions_col">&nbsp;</div>
  </div>
);

CustomerTableHeader.propTypes = {
  getSortingClasses: PropTypes.func.isRequired,
  changeSorting: PropTypes.func.isRequired,
};

export default CustomerTableHeader;
