import DashboardNav from './dashboard-nav';
import React from 'react';
import Icon from '@zing/neo-common/dist/components/Icon';
import { NavLink } from 'react-router-dom';

import './dashboard-nav.scss';

export const isNotificationActive = (match, location) => location.pathname.includes('/notifications');

const Index = () => <DashboardNav Icon={Icon} isNotificationActive={isNotificationActive} NavLink={NavLink} />;

export default Index;
