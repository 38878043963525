import React, { useContext } from 'react';
import display from '@zing/neo-common/dist/lib/display';
import Stat from '../../../../components/your-package/stat/stat';
import StatContainer from '../../../../components/your-package/stat-container/stat-container';
import { cashFlowFirstYear } from '../../../../utils/calculations/cashflow';
import { getNumber, toFixed } from '@zing/neo-common/dist/lib/safe';
import { Grid } from '@zing/neo-common/dist/components/Grid';
import { multiplyBy1000, percentageAsNumber } from '@zing/neo-common/dist/lib/number';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

import './package-stats.scss';
import { systemTotalRatedPowerKw } from '../../pages/payment-options/utils';

const YourPackageStats = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons, existingPvInverterTotalRatedOutputKw } = current.context;
  const { pimComparison, calcs, systemTotalInstallPvKwhCombined, roofs } = comparisons[currentComparison];
  const journey = pimComparison.getJourney();
  const firstYearCashflow = cashFlowFirstYear(calcs?.cashflow);
  const statTotalSavings = display.pounds(getNumber(calcs?.costsAndSavings, 'totalAnnualIncomeAndSavings'));
  const statC02SavedPerYear = display.number(multiplyBy1000(getNumber(firstYearCashflow, 'co2EmissionSavings')), 0);
  const statPowerPerPanel = journey.hasSolarPV ? multiplyBy1000(pimComparison.getIndividualPanelPowerKw()) : 0;
  const statSelfSufficiency = toFixed(percentageAsNumber(getNumber(firstYearCashflow, 'selfSufficiency')), 0);
  const statSelfConsumption = toFixed(percentageAsNumber(getNumber(firstYearCashflow, 'selfConsumption')), 0);
  const statTotalPanels = getNumber(calcs, 'settings.numberOfPanels');
  const hasExistingPv = current.value.existingGridConnectedPvOnSite === 'yes';

  // put this back ref #UKSQA-1090 (should be battery only!)
  const systemTotalRatedPowerKwValue = systemTotalRatedPowerKw(roofs);
  const statTotalRatedPower = journey.isBatteryOnly ? toFixed(existingPvInverterTotalRatedOutputKw) : toFixed(systemTotalRatedPowerKwValue);

  // #UKSQA-1105 statAnnualPvGeneration is displaying wrong value, should be the combined values!
  const statAnnualPvGeneration = display.number(systemTotalInstallPvKwhCombined, 0);

  return (
    <section className="package-stats">
      <Grid>
        <StatContainer type="lime" description={journey.hasSolarPV ? 'Your E.ON Solar solution' : 'Your existing solar solution'}>
          {journey.hasSolarPV && (
            <>
              <Stat testId="stat_total_panels" value={statTotalPanels} description="Total no. panels across all roofs" />

              <Stat testId="stat_power_per_panel" value={statPowerPerPanel} suffix=" Watts" description="Power per panel" />
            </>
          )}
          <Stat testId="stat_total_rated_power" value={statTotalRatedPower} suffix=" kW" description="Total rated power" />
        </StatContainer>
        <StatContainer type="bordeaux" description={`Your ${hasExistingPv ? 'combined ' : ''}system performance`}>
          <Stat testId="stat_annual_pv_generation" value={statAnnualPvGeneration} suffix=" kWh" description="Annual PV generation" />
          <Stat
            testId="stat_c_02_saved_per_year"
            value={statC02SavedPerYear}
            suffix=" kg"
            description={
              <>
                CO<sub>2</sub> saved over a year
              </>
            }
          />
        </StatContainer>
        <StatContainer type="turquoise" description={`Your ${hasExistingPv ? 'combined ' : ''}system benefits`}>
          <Stat testId="stat_self_sufficiency" value={statSelfSufficiency} suffix="%" description="Self-sufficiency" />
          <Stat testId="stat_self_consumption" value={statSelfConsumption} suffix="%" description="Self-consumption" />
          <Stat
            testId="stat_bill_savings"
            value={statTotalSavings}
            description={`${hasExistingPv ? 'Additional s' : 'S'}avings and income`}
          />
        </StatContainer>
      </Grid>
    </section>
  );
};

YourPackageStats.propTypes = {};

export default YourPackageStats;
