import * as Yup from 'yup';
import { REQUIRED } from '../../../../components/Survey/validate/messages';

const schema = Yup.object().shape({
  context: Yup.object().shape({
    propertyType: Yup.string().required(REQUIRED),
    leadCategory: Yup.string().required(REQUIRED),
  }),
  // UKSQA-1333 - Set customerIsEligibleForLowerVatRate to 'yes' for all and hide field
  // value: Yup.object().shape({
  //   customerIsEligibleForLowerVatRate: Yup.string().oneOf(['yes', 'no'], REQUIRED),
  // }),
});

export default schema;
