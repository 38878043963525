import * as Yup from 'yup';

const MSG_FIRST_NAME_REQUIRED = 'First name is required';
const MSG_LAST_NAME_REQUIRED = 'Last name is required';
const MSG_MOBILE_NUMERIC = 'Mobile number is required';
const MSG_PHONE_NUMERIC = 'Telephone number is required';

const allowSpaces = true;

export default Yup.object().shape({
  mobile: Yup.mixed().required(MSG_MOBILE_NUMERIC, allowSpaces),
  telephone: Yup.mixed().required(MSG_PHONE_NUMERIC, allowSpaces),
  firstname: Yup.string().required(MSG_FIRST_NAME_REQUIRED),
  lastname: Yup.string().required(MSG_LAST_NAME_REQUIRED),
});
