import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Notification from './notification';
import { connect } from 'react-redux';
import { markNotificationAsRead } from '../../redux/notifications/actions';

const Index = ({ notification, markNotificationAsRead }) => {
  const [processing, setProcessing] = useState(false);

  const markAsRead = () => {
    setProcessing(true);
    markNotificationAsRead(notification.id);
  };

  return <Notification notification={notification} markAsRead={markAsRead} processing={processing} />;
};

Index.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    marked_as_read: PropTypes.string,
  }).isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
};

export default connect(null, { markNotificationAsRead })(Index);
