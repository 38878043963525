import { connect } from 'react-redux';
import { isAdmin } from '@zing/neo-common/dist/redux/user/accessor';
import { get } from '@zing/neo-common/dist/lib/safe';
import { withRouter } from 'react-router-dom';
import AppMenu from './app-menu';
import DiagnosticsMenu from './diagnostics-menu';
import PropTypes from 'prop-types';
import React from 'react';

import './menus.scss';

/**
 * The menu component now determines which menu to show, depending on the current route!
 */
const Menu = ({ session, location, user, canSeeDiagnostics }) => {
  // is this a diagnostics page?
  if (location.pathname.startsWith('/diagnostics')) {
    const isUserAdmin = isAdmin(user.user);
    return <DiagnosticsMenu isUserAdmin={isUserAdmin} />;
  }

  return <AppMenu session={session} canSeeDiagnostics={canSeeDiagnostics} />;
};

Menu.propTypes = {
  session: PropTypes.shape({
    opportunity: PropTypes.shape({}),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    user: PropTypes.shape({}).isRequired,
  }).isRequired,
  canSeeDiagnostics: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ session, user }) => ({
  session,
  user,
  canSeeDiagnostics: get(user, 'user.role') !== 'External',
});

export default withRouter(connect(mapStateToProps)(Menu));
