import { checkAuth } from '../../components/PrivateRoute/checkAuth';
import { get } from '@zing/neo-common/dist/lib/safe';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { Redirect } from 'react-router-dom';
import * as queryString from 'query-string';
import Logo from '@zing/neo-common/dist/components/Logo';
import PropTypes from 'prop-types';
import React from 'react';
import useAppService from '../../hooks/use-app-service';

import './login-page.scss';

const LoginPage = ({ auth, location, login, user, config, children }) => {
  const { appApi } = useAppService();

  // useEffect(() => {
  //   fetch('/api/ping');
  // }, []);

  if (config.allowLoginViaToken) {
    const { search } = location;
    if (search) {
      const parts = queryString.parseUrl(search);
      if (parts.query.auth_token) {
        login(parts.query, user, appApi);
        // Warning! do not remove!
        // It strips off the auth_token and stops a continuous loop
        return <Redirect to="/login" />;
      }
    }

    // Warning! Do not remove!
    // It allows the user get passed the login page once a token has succeeded.
    const loggedInViaToken = get(auth, 'loggedInViaToken', false);
    if (loggedInViaToken) {
      appApi.auth.refresh(auth);
      return <Redirect to="/opportunities" />;
    }
  }

  const result = checkAuth(auth);
  if (result.valid) {
    // console.log('We are logged in, does the state machine know this?');
    // console.log('state.context.auth', state.context.auth);
    appApi.auth.refresh(auth);
    return <Redirect to="/opportunities" />;
  }

  return (
    <main className="login" data-testid="login-page">
      <Grid>
        <Col className="image-col" />
        <Col width="fixed" className="login-col">
          <Logo inverted />
          <h1>Sign in</h1>
          {children}
        </Col>
      </Grid>
    </main>
  );
};

LoginPage.propTypes = {
  auth: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  login: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  config: PropTypes.shape({
    allowLoginViaToken: PropTypes.bool.isRequired,
  }).isRequired,
};

LoginPage.defaultProps = {
  auth: {},
  user: {},
};

export default LoginPage;
