import React, { useContext } from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { UserContext } from '../../contexts/user-context';
import { BETA_USER_IDS } from '../../../../config';

const G99Explanation = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const user = useContext(UserContext);
  const { currentComparison, comparisons } = current.context;
  const g99 = comparisons[currentComparison]?.dynamic?.calcs?.g99 || {};

  if (!BETA_USER_IDS.includes(user.id || undefined)) {
    return null;
  }

  return (
    <>
      <br />
      <br />
      <fieldset>
        <Grid gutters="sm" valign="center">
          <Col width="12">
            <h2>Explanation </h2>
            <div className="betaTestersNotice">(Only Beta testers can see this view)</div>
          </Col>
          <Col width="4">
            Has hybrid battery? <br />
            <small>(ignores proposed pv when `Yes`)</small>
          </Col>
          <Col width="8" className="col-right">
            {g99.hasHybridBattery ? 'Yes' : 'No'}
          </Col>
          <Col width="4">Actual proposed PV</Col>
          <Col width="8" className="col-right">
            {g99.actualProposedPv}
          </Col>

          <Col width="4">
            Has existing grid connected pv on site?
            <br />
            <small>(ignores existing pv when `No`)</small>
          </Col>
          <Col width="8" className="col-right">
            {g99.existingGridConnectedPvOnSite ? 'Yes' : 'No'}
          </Col>
          <Col width="4">Actual actual existing pv inverter total rated output Kw</Col>
          <Col width="8" className="col-right">
            {g99.actualExistingPvInverterTotalRatedOutputKw}
          </Col>
        </Grid>
      </fieldset>
    </>
  );
};

export default G99Explanation;
