import { assign, send, spawn } from 'xstate';
import { pure } from 'xstate/es/actions';
import apiMachine from './api.machine';
import authMachine from './auth.machine';
import buildMenuMachine from './menu.machine';
import customerSearchMachine from './pipeline/customer-search.machine';
import diagnosticsUsersMachine from './diagnostics-users.machine';
import gdprMachine from './gdpr.machine';
import isOfflineMachine from 'xstate-offline-machine';
import pipelineSearchMachine from './pipeline/pipeline-search.machine';
import settingsMachine from './settings.machine';

const { Machine } = require('xstate');

const appMachine = Machine({
  id: 'app',
  initial: 'idle',
  context: {
    api: undefined,
    auth: undefined,
    diagnosticsMenu: undefined,
    diagnosticsUsers: undefined,
    menu: undefined,
    offline: undefined,
    pipelineSearch: undefined,
    settings: undefined,
    user: undefined,
  },
  states: {
    idle: {
      on: {
        INITIALIZE: {
          target: 'ready',
          actions: assign({
            api: () => spawn(apiMachine, { name: 'api' }),
            auth: () => spawn(authMachine, { name: 'auth' }),
            customerSearch: () => spawn(customerSearchMachine, { name: 'customerSearch' }),
            diagnosticsMenu: () => spawn(buildMenuMachine('diagnosticsMenu')),
            diagnosticsUsers: () => spawn(diagnosticsUsersMachine, { name: 'diagnosticsUsers' }),
            gdpr: () => spawn(gdprMachine, { name: 'gdpr' }),
            menu: () => spawn(buildMenuMachine('menu')),
            offline: () => spawn(isOfflineMachine, { name: 'offline' }),
            pipelineSearch: () => spawn(pipelineSearchMachine, { name: 'pipelineSearch' }),
            settings: () => spawn(settingsMachine, { name: 'settings' }),
          }),
        },
      },
    },
    ready: {
      on: {
        // V5 will allow GLOB matching here
        '*': [
          {
            actions: send((_, event) => event, {
              to: context => context.offline,
            }),
            cond: (c, e) => e.type.startsWith('OFFLINE_'),
          },
          {
            actions: pure(() =>
              // add here if a machine needs to know about offline status
              ['api', 'customerSearch', 'diagnosticsUsers', 'settings', 'pipelineSearch'].map(actor =>
                send((context, event) => event, {
                  to: context => context[actor],
                })
              )
            ),
            cond: (c, e) => ['STATUS_ONLINE', 'STATUS_OFFLINE', 'REQUEST'].includes(e.type),
          },
          {
            actions: pure(() =>
              // add here if a machine needs to know about offline status
              [
                'auth',
                'customerSearch',
                'diagnosticsUsers',
                // 'pipelineMode',
                'pipelineSearch',
              ].map(actor =>
                send((context, event) => event, {
                  to: context => context[actor],
                })
              )
            ),
            cond: (c, e) => ['AUTH_SUCCESS', 'AUTH_LOGOUT', 'AUTH_ERROR', 'AUTH_NOTIFY', 'USER_DETAILS'].includes(e.type),
          },
          {
            actions: pure(() =>
              // add here if a machine needs to know about auth
              ['api', 'customerSearch', 'diagnosticsUsers', 'pipelineSearch'].map(actor =>
                send((context, event) => event, {
                  to: context => context[actor],
                })
              )
            ),
            cond: (c, e) => ['NOTIFY_AUTH'].includes(e.type),
          },
        ],
      },
    },
  },
});

export default appMachine;
