import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../hollywood/input';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import Button from 'hollywood/dist/components/Button';
import { api } from '@zing/neo-common/dist/lib/api';

import './voucher-input.scss';

export function VoucherInput({ discountCode, discountFixed, discountPercentage, onVoucherSelect, accessToken }) {
  const [value, setValue] = React.useState(discountCode);
  const [state, setState] = React.useState('IDLE');

  async function checkCode() {
    if (state === 'LOADING') return;
    setState('LOADING');

    try {
      const response = await api({
        accessToken,
        url: `/voucher/details?voucher_code=${value}`,
        method: 'GET',
      });
      onVoucherSelect(response.data.output);
    } catch (error) {
      setState('ERROR');
    }
  }

  function clearVoucher() {
    setValue('');
    setState('IDLE');
    onVoucherSelect(null);
  }

  return (
    <div className="form-field voucher-input">
      <label htmlFor="voucherCode">Voucher Code:</label>
      <div>
        <Grid gutters="sm" valign="center">
          <Col>
            <Input
              name="discount"
              value={value}
              onChange={(_, value) => {
                setState('IDLE');
                setValue(value);
              }}
              disabled={discountCode !== ''}
            />
          </Col>
          <Col width="auto">
            {discountCode !== '' ? (
              <Button format="tertiary" onClick={() => clearVoucher()}>
                Remove
              </Button>
            ) : (
              <Button
                format="primary"
                size="small"
                disabled={value === '' || state === 'APPLIED'}
                loading={state === 'LOADING'}
                onClick={() => checkCode()}
              >
                <span style={state === 'LOADING' ? { color: 'transparent' } : {}}>Apply</span>
              </Button>
            )}
          </Col>
        </Grid>
      </div>
      {discountCode !== null && (
        <div className="voucher-input__success">
          {discountFixed > 0 && `£${discountFixed} discount applied`}
          {discountPercentage > 0 && `${discountPercentage * 100}% discount applied`}
        </div>
      )}
      {state === 'ERROR' && <div className="voucher-input__error">Invalid voucher code</div>}
    </div>
  );
}

VoucherInput.propTypes = {
  discountCode: PropTypes.string,
  discountFixed: PropTypes.number,
  discountPercentage: PropTypes.string,
  onVoucherSelect: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
};

VoucherInput.defaultProps = {
  discountCode: '',
  discountFixed: 0,
  discountPercentage: 0,
};

export default VoucherInput;
