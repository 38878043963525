import React, { useContext } from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import Toggle from '../../hollywood-xstate/toggle';
import FieldError from '../../hollywood-xstate/field-error';

const ScaffoldComplex = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>Complex Scaffolding Installation?</p>
        </Col>
        <Col width="fixed" className="right-col">
          <Toggle
            name="context.scaffoldComplex"
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            goodOption="no"
            badOption="yes"
            value={current.context.scaffoldComplex}
            onChange={(_, value) => {
              send({ type: 'SCAFFOLD_COMPLEX', value });
            }}
          />
          <FieldError field="context.scaffoldComplex" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default ScaffoldComplex;
