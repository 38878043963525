import { get } from '@zing/neo-common/dist/lib/safe';

export const filterSlides = (slides, opportunity) =>
  slides.filter(slide => {
    // Filter out 'inactive' slides
    if (slide.status === 'Inactive') {
      return false;
    }

    // No rule, return slide
    if (!slide.rule) {
      return true;
    }

    const value = get(opportunity, slide.rule.field);
    switch (slide.rule.operator) {
      case '=':
        return value === slide.rule.value;
      case '>':
        return value > slide.rule.value;
      case '<':
        return value < slide.rule.value;
      case 'contains':
        return value.includes(slide.rule.value);
      default:
        return false;
    }
  });
