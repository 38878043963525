import React from 'react';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import { SelectField } from '@zing/neo-common/dist/components/forms/fields/SelectField';
import { ToggleField } from '@zing/neo-common/dist/components/forms/fields/ToggleField';
import { titleOptions } from '../../../config';

import './CustomerFields.scss';

export const defaults = {
  required: {
    title: false,
    firstname: true,
    lastname: true,
    telephone: true,
    mobile: true,
    email: true,
    lnumber: false,
    role: false,
    emailLogin: false,
    enabled: false,
  },
  shown: {
    title: true,
    lnumber: true,
    role: false,
    emailLogin: false,
    enabled: false,
    password: false,
  },
};

export const roleOptions = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Sales', label: 'Sales' },
  { value: 'External', label: 'External' },
];

export const enabledOptions = [
  { value: '1', label: 'Enabled' },
  { value: '0', label: 'Disabled' },
];

export const CustomerFields = ({ options }) => {
  const { required, shown } = merge({}, defaults, options);
  return (
    <Grid gutters="sm">
      {shown.title && (
        <Col width="12">
          <SelectField name="title" options={titleOptions} title="Title" isRequired={required.title} className="title-select" />
        </Col>
      )}
      <Col width="6">
        <TextField name="firstname" title="First name" isRequired={required.firstname} />
      </Col>
      <Col width="6">
        <TextField name="lastname" title="Last name" isRequired={required.lastname} />
      </Col>
      <Col width="6">
        <TextField name="telephone" title="Telephone number" type="tel" isRequired={required.telephone} />
      </Col>
      <Col width="6">
        <TextField name="mobile" title="Mobile number" type="tel" isRequired={required.mobile} />
      </Col>
      <Col width="6">
        <TextField name="email" title="Email address" type="email" isRequired={required.email} />
      </Col>
      {shown.emailLogin ? (
        <Col width="6">
          <TextField name="email_login" title="Email login" type="email" isRequired={required.emailLogin} />
        </Col>
      ) : null}
      {shown.lnumber ? (
        <Col width="6">
          <TextField name="lnumber" title="CRM customer ID" disabled />
        </Col>
      ) : null}
      {shown.role ? (
        <Col width="6">
          <SelectField name="role" options={roleOptions} title="Role" isRequired={required.role} className="role-select" />
        </Col>
      ) : null}
      <Col width="6">
        {shown.enabled ? (
          <ToggleField
            name="enabled"
            options={enabledOptions}
            title="Is enabled?"
            isRequired={required.enabled}
            className="enabled"
            goodOption="1"
            badOption="0"
          />
        ) : null}
      </Col>
      {shown.password ? (
        <Col width="6">
          <TextField name="password" title="Password" type="password" isRequired={required.password} />
        </Col>
      ) : null}
      {shown.password_confirmation ? (
        <Col width="6">
          <TextField name="password_confirmation" title="Confirm Password" type="password" isRequired={required.password_confirmation} />
        </Col>
      ) : null}
    </Grid>
  );
};

CustomerFields.propTypes = {
  options: PropTypes.shape({}).isRequired,
};
