import { get, getBoolean } from '@zing/neo-common/dist/lib/safe';
import { apiDate } from '@zing/neo-common/dist/lib/date';

const businessLogic = quote => ({
  salesConsultantDisplayName: () => {
    const name = [get(quote, 'sales_consultant_firstname'), get(quote, 'sales_consultant_lastname')].join(' ').trim();
    return name.length ? `Created by: ${name}` : '';
  },
  signedAndAgreedAt: () => {
    const signedDate = apiDate(quote.signed_and_agreed_at);
    return signedDate.length ? `Signed & agreed: ${signedDate}` : '';
  },
  /**
   * @depracated  see UKSQA-1296
   * We now disable quotes if the package id is too old!
   */
  // isRequoteDisabled: () => {
  //   const packageProdId = quote?.pim_survey?.package?.prodId;
  //   // Requoting is disabled if NOT in our allowed plytix package ids
  //   return packageProdId ? !allowedPlytixProductPackageIds.includes(packageProdId) : false;
  // },
  agreed: () => getBoolean(quote, 'agreed'),
  disabled: () => getBoolean(quote, 'is_disabled', false),
  signedBy: () => get(quote, 'quote_signed_by', 'Customer'),
});

export default businessLogic;
