import React, { useMemo } from 'react';
import pimAccessor from '@zing/neo-common/dist/accessors/pim';
import PimSurveyPage from './survey';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PimContext } from './contexts/pim-context';
import { sessionCurrentUrl } from '../../redux/session/actions';
import { UserContext } from './contexts/user-context';

// We do not want to connect the pimSession here, it will cause a re-render on every
// save (on transition)
// We only need the session once at the start whe we initialise the state machine
// so why cant we pass it via the router?
const PimSurveyPageConnected = ({ pimStore, user, sessionCurrentUrl, defaults }) => {
  const pim = useMemo(() => pimAccessor(pimStore), [pimStore]);

  return (
    <UserContext.Provider value={user}>
      <PimContext.Provider value={{ pim }}>
        <PimSurveyPage sessionCurrentUrl={sessionCurrentUrl} defaults={defaults} />
      </PimContext.Provider>
    </UserContext.Provider>
  );
};

PimSurveyPageConnected.propTypes = {
  pimStore: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
  sessionCurrentUrl: PropTypes.func.isRequired,
  defaults: PropTypes.shape({}).isRequired,
};
export default connect(({ pim, user, defaults }) => ({ pimStore: pim, user: user.user, defaults: defaults.values }), { sessionCurrentUrl })(
  PimSurveyPageConnected
);
