import React, { useContext } from 'react';
import ProductImage from '@zing/neo-common/dist/components/ProductImage';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { ProductBullets } from '@zing/neo-common/dist/components/Bullets/ProductBullets';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { ReactComponent as HouseSvg } from './house.svg';

import './your-package-details.scss';

const YourPackageDetails = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { pimComparison } = comparisons[currentComparison];

  const panel = pimComparison.getPanel();
  const inverter = pimComparison.getInverter();
  const battery = pimComparison.getBattery();
  const evChargePoint = pimComparison.getEvChargePoint();
  const isHybridBattery = pimComparison.getBatteryIsHybrid();
  const installationType = current.matches(`comparison.${currentComparison}.ev.fullInstall`);

  return (
    <section className="package-details">
      <Grid gutters="md">
        <Col>
          {panel && (
            <div className="package-item solar-panel">
              <Grid gutters="sm">
                <Col width="auto">
                  <ProductImage product={panel} />
                </Col>
                <Col>
                  <div className="package-item__details">
                    <p className="tight  lg">
                      <strong>{panel.name}</strong>
                    </p>
                    <ProductBullets product={panel} />
                  </div>
                </Col>
              </Grid>
            </div>
          )}
          {inverter && (
            <div className="package-item inverter" style={isHybridBattery ? { opacity: 0.3 } : null}>
              <Grid gutters="sm">
                <Col width="auto">
                  <ProductImage product={inverter} />
                </Col>
                <Col>
                  <div className="package-item__details">
                    <p className="tight  lg">
                      <strong>{inverter.name}</strong>
                    </p>
                    <ProductBullets product={inverter} />
                  </div>
                </Col>
              </Grid>
            </div>
          )}
          {battery && (
            <div className="package-item battery" data-testid="battery_section">
              <Grid gutters="sm">
                <Col width="auto">
                  <ProductImage product={battery} />
                </Col>
                <Col>
                  <div className="package-item__details">
                    <p className="tight  lg">
                      <strong>{battery.name}</strong>
                    </p>
                    <ProductBullets product={battery} testId="battery_list" />
                  </div>
                </Col>
              </Grid>
            </div>
          )}
          {evChargePoint && installationType && (
            <div className="package-item ev-charger" data-testid="ev_charger_section">
              <Grid gutters="sm">
                <Col width="auto">
                  <ProductImage product={evChargePoint} />
                </Col>
                <Col>
                  <div className="package-item__details">
                    <p className="tight  lg">
                      <strong>{evChargePoint.name}</strong>
                    </p>
                    <ProductBullets product={evChargePoint} />
                  </div>
                </Col>
              </Grid>
            </div>
          )}
        </Col>
        <Col width="auto">
          <HouseSvg className="house-drawing" />
        </Col>
      </Grid>
    </section>
  );
};

YourPackageDetails.propTypes = {};

export default YourPackageDetails;
