import * as Yup from 'yup';
import {
  MSG_ADDRESS_LINE_1_REQUIRED,
  MSG_CITY,
  MSG_EMAIL_INVALID,
  MSG_EMAIL_REQUIRED,
  MSG_FIRST_NAME_REQUIRED,
  MSG_LAST_NAME_REQUIRED,
  MSG_PHONE_NUMERIC,
  MSG_MOBILE_NUMERIC,
  MSG_PHONE_REQUIRED,
  MSG_MOBILE_REQUIRED,
  MSG_HOUSE_NUMBER_REQUIRED,
  MSG_HOUSE_NUMBER_MAX_LENGTH,
} from '../../../config';

require('@zing/neo-common/dist/lib/yup');

export default Yup.object().shape({
  different_invoice_address: Yup.boolean(),
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_EMAIL_REQUIRED),
  mobile: Yup.number()
    .typeError(MSG_MOBILE_NUMERIC)
    .required(MSG_MOBILE_REQUIRED),
  telephone: Yup.number()
    .typeError(MSG_PHONE_NUMERIC)
    .required(MSG_PHONE_REQUIRED),
  house_number: Yup.string()
    .max(20, MSG_HOUSE_NUMBER_MAX_LENGTH)
    .required(MSG_HOUSE_NUMBER_REQUIRED),
  address_line_1: Yup.string().required(MSG_ADDRESS_LINE_1_REQUIRED),
  city: Yup.string().required(MSG_CITY),
  postcode: Yup.string().isValidPostcode(),
  invoice_house_number: Yup.mixed().when('different_invoice_address', {
    is: true,
    then: Yup.string()
      .max(20, MSG_HOUSE_NUMBER_MAX_LENGTH)
      .required(MSG_HOUSE_NUMBER_REQUIRED),
    otherwise: Yup.mixed().nullable(true),
  }),
  invoice_address_line_1: Yup.mixed().when('different_invoice_address', {
    is: true,
    then: Yup.string().required(MSG_ADDRESS_LINE_1_REQUIRED),
    otherwise: Yup.mixed().nullable(true),
  }),
  invoice_city: Yup.mixed().when('different_invoice_address', {
    is: true,
    then: Yup.string().required(MSG_CITY),
    otherwise: Yup.mixed().nullable(true),
  }),
  invoice_postcode: Yup.mixed().when('different_invoice_address', {
    is: true,
    then: Yup.string().isValidPostcode(),
    otherwise: Yup.mixed().nullable(true),
  }),
  crm_id: Yup.mixed().nullable(true),
  firstname: Yup.string().required(MSG_FIRST_NAME_REQUIRED),
  lastname: Yup.string().required(MSG_LAST_NAME_REQUIRED),
  title: Yup.mixed().nullable(true),
});
