import axios from 'axios';
import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';
import { api } from '@zing/neo-common/dist/lib/api';
import { get } from '@zing/neo-common/dist/lib/safe';

import db from '../../databases/resources';

import {
  SETTINGS_ADD_ENCRYPTED_DATA,
  SETTINGS_ADD_UNENCRYPTED_DATA,
  SETTINGS_RESET,
  SETTINGS_REFRESH_APP_DATA,
  SETTINGS_REFRESH_RESOURCES,
  SETTINGS_PROFILE_IMAGE_UPLOAD,
  SETTINGS_PROFILE_IMAGE_REMOVE,
  SETTINGS_UPDATE_USER,
  SETTINGS_UPDATE_EDITABLE_USER_DETAILS,
  SETTINGS_RESET_EDITABLE_USER_DETAILS,
} from './actionTypes';

export function reset() {
  return { type: SETTINGS_RESET };
}

export function addEncryptedData(data) {
  return { type: SETTINGS_ADD_ENCRYPTED_DATA, data };
}

export function addUnencryptedData(data) {
  return { type: SETTINGS_ADD_UNENCRYPTED_DATA, data };
}

export const refreshAppData = status => ({ type: SETTINGS_REFRESH_APP_DATA, status });

export const refreshResources = status => ({ type: SETTINGS_REFRESH_RESOURCES, status });

export const uploadProfilePicture = formData =>
  offlineRequestAction({
    type: SETTINGS_PROFILE_IMAGE_UPLOAD,
    url: '/users/upload-profile-image',
    tag: SETTINGS_PROFILE_IMAGE_UPLOAD,
    removeTag: SETTINGS_PROFILE_IMAGE_UPLOAD,
    method: 'POST',
    payload: formData,
    json: false,
  });

export const removeProfilePicture = () =>
  offlineRequestAction({
    type: SETTINGS_PROFILE_IMAGE_REMOVE,
    url: '/users/remove-profile-image',
    tag: SETTINGS_PROFILE_IMAGE_REMOVE,
    removeTag: SETTINGS_PROFILE_IMAGE_REMOVE,
    method: 'DELETE',
  });

export const updateUserDetails = userDetails =>
  offlineRequestAction({
    type: SETTINGS_UPDATE_USER,
    url: '/users/update-user',
    tag: SETTINGS_UPDATE_USER,
    removeTag: SETTINGS_UPDATE_USER,
    method: 'PUT',
    payload: userDetails,
    commitMeta: userDetails,
    json: false,
  });

export const cacheResources = () => (dispatch, getState) => {
  // eslint-disable-next-line
  dispatch(refreshResources('Processing'));
  api({
    accessToken: getState().auth.access_token,
    method: 'GET',
    url: '/resources',
  })
    .then(response => {
      let counter = 0;
      const target = response.data.length;
      const version = Math.random()
        .toString(36)
        .substring(2, 15);
      // eslint-disable-next-line array-callback-return
      response.data.map(resource => {
        axios({
          url: resource.url,
          headers: {},
          responseType: 'blob',
          params: { sw: 'skip', version },
        }).then(d => {
          const blob = d.data;
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
            .then(dataUrl => {
              db.resources
                .put({
                  url: resource.url,
                  created_at: resource.created_at,
                  filesize: resource.filesize,
                  file: resource.file,
                  data: dataUrl,
                })
                .then(() => {
                  counter += 1;
                  if (counter >= target) {
                    dispatch(refreshResources('Completed'));
                  }
                })
                .catch(() => {
                  counter += 1;
                  if (counter >= target) {
                    dispatch(refreshResources('Completed'));
                  }
                });
            })
            .catch(() => {
              counter += 1;
              if (counter >= target) {
                dispatch(refreshResources('Completed'));
              }
            });
        });
      });
    })
    .catch(error => {
      const response = get(error, 'response', null);
      // eslint-disable-next-line no-console
      console.log(response);
    });
};

export const updateEditableUserDetails = userDetails => ({ type: SETTINGS_UPDATE_EDITABLE_USER_DETAILS, userDetails });

export const resetEditableUserDetails = () => ({ type: SETTINGS_RESET_EDITABLE_USER_DETAILS });
