export const SORT_ORDER_ASC = 'asc';
export const SORT_ORDER_DESC = 'desc';

export const initialFilterState = {
  query: '',
  dateFrom: '',
  dateTo: '',
};

export const sortChange = (field, sortBy, setSortBy, order, setOrder) => {
  // Different field, sort by this field desc
  if (sortBy !== field) {
    setSortBy(field);
    setOrder(SORT_ORDER_DESC);
    return;
  }

  // Same field, toggle between ASC and desc
  setOrder(order === SORT_ORDER_DESC ? SORT_ORDER_ASC : SORT_ORDER_DESC);
};
