import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { showPercentSign, yesNo } from '../product-tables/utils';
import { toFixedNumber } from '@zing/neo-common/dist/lib/safe/to-fixed-number';
import ProductTableDataDriven from '../product-table-data-driven';
import PropTypes from 'prop-types';
import React from 'react';

const TableHead = (
  <thead>
    <tr>
      <th colSpan={4}>&nbsp;</th>
      <th colSpan={21} className="center productPricing borderRight">
        Product VAT
      </th>
    </tr>
    <tr>
      <th colSpan={2}>&nbsp;</th>
      <th colSpan={2} className="gs center">
        GS Ratio
      </th>
      <th className="gm center" colSpan={2}>
        VAT Rate
      </th>
      <th className="center perItem" colSpan={5}>
        Per item
      </th>
      <th className="center perKw" colSpan={5}>
        Per kW
      </th>
      <th className="center perPanel borderRight" colSpan={5}>
        Per Panel
      </th>
      <th className="center exVatHeading" colSpan={4}>
        Summed Totals
      </th>
    </tr>
    <tr>
      <th className="left">Prod ID</th>
      <th className="center">EEM</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="center gm">Goods</th>
      <th className="center gm">Services</th>

      {/* Per item */}
      <th className="money costs">ExVat</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Vat</th>
      <th className="money gm2">IncVat</th>

      {/* Per Kw */}
      <th className="money costs">ExVat</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Vat</th>
      <th className="money gm2">IncVat</th>

      {/* Per Panel */}
      <th className="money costs">ExVat</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Vat</th>
      <th className="money gm2">IncVat</th>

      {/* IncVat Per Totals */}
      <th className="money exVat">Goods</th>
      <th className="money exVat">Services</th>
      <th className="money exVat">Vat</th>
      <th className="money exVat">IncVat</th>
    </tr>
  </thead>
);

const rowConfig = [
  { field: 'prodId', className: 'left', pipeline: [], totals: false, totalClassName: 'noBorder' },
  { field: 'energyEfficiencyMeasure', className: 'center', pipeline: [yesNo], totals: false, totalClassName: 'noBorder' },
  { field: 'gsRatioGoods', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'gsRatioServices', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'vatRateGoods', className: 'center gm', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'vatRateServices', className: 'center gm', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'discountedExVatPerItemTotal', className: 'money costs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerItemTotalGoods', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerItemTotalServices', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerItemTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'incVatPerItemTotal', className: 'money gm2', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },

  { field: 'discountedExVatPerKwTotal', className: 'money costs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerKwTotalGoods', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerKwTotalServices', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerKwTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'incVatPerKwTotal', className: 'money gm2', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },

  { field: 'discountedExVatPerPanelTotal', className: 'money costs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerPanelTotalGoods', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerPanelTotalServices', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatPerPanelTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'incVatPerPanelTotal', className: 'money gm2', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },

  { field: 'vatTotalGoods', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatTotalServices', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatTotal', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'incVatTotal', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
];

const ProductVatTable = ({ selectedProducts = [] }) => (
  <ProductTableDataDriven title="Product Inc-Vat pricing" values={selectedProducts} rowConfig={rowConfig} TableHead={TableHead} />
);

ProductVatTable.propTypes = {
  selectedProducts: PropTypes.shape([]).isRequired,
};

export default ProductVatTable;
