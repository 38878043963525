/**
 * DEPRECATED!!!!
 * See machines/survey/narrative-default-text
 * @param values The selected package
 * @returns {string|null}
 */

import * as display from '@zing/neo-common/dist/lib/display';
import { energyRatingKwh, powerRatingKw } from '@zing/neo-common/dist/redux/product/accessor';
import { get, getArray, getBoolean, getNumber } from '@zing/neo-common/dist/lib/safe';

export const intro = 'Your system comprises:';

export const makeRoofNames = roofs =>
  Array.isArray(roofs) && roofs.length > 0
    ? roofs
        .map(x => x.name)
        .join(', ')
        .replace(/, ([^,]*)$/, ' & $1')
    : '';

export default (values, index = 0) => {
  const result = {
    intro: '',
    lines: [],
    narrative: '',
  };

  if (!values) {
    return result;
  }

  const poiHasSolarPv = getBoolean(values, 'poiHasSolarPv');
  const poiHasBattery = getBoolean(values, 'poiHasBattery');
  const batteries = getArray(values, `packages[${index}].batteries`);
  const battery = batteries.length > 0 ? batteries[0] : null;
  const panel = get(values, `packages[${index}].panel`);
  const numberOfPanels = getNumber(values, 'total_panels');
  const numberOfFaces = getNumber(values, 'number_of_faces');
  const numberOfStories = getNumber(values, 'number_of_stories');
  const roofs = getArray(values, 'roofs');
  const numberOfRoofs = roofs.length;
  const systemSize = getNumber(values, `packages[${index}].system_total_rated_power_kw`);
  const panelPowerRating = panel ? powerRatingKw(panel) * 1000 : 0;
  const hasComplexScaffolding = get(values, 'scaffolding_installation_complexity') === 'Complex';
  result.narrative = get(values, `packages[${index}].narrative_default_text.narrative`, '');

  // [xx] number of [xxx]W panels giving a system size of [xx] kW, to be fitted on [roofs]
  if (poiHasSolarPv) {
    if (panel && numberOfPanels > 0 && numberOfRoofs > 0 && systemSize > 0 && panelPowerRating > 0) {
      let panelLine = `${numberOfPanels} x ${panelPowerRating} W panels giving a system size of ${display.number(
        systemSize,
        2
      )} kW, to be fitted on ${makeRoofNames(roofs)}`;

      if (hasComplexScaffolding) {
        panelLine += ` with scaffolding to be erected on ${numberOfFaces} ${numberOfFaces < 2 ? 'face' : 'faces'} and ${numberOfStories} ${
          numberOfStories < 2 ? 'story' : 'stories'
        } of the property`;
      }
      panelLine += '.';
      result.lines.push(panelLine);
    }
  }

  if (poiHasBattery) {
    const batteryStorageKwh = battery ? energyRatingKwh(battery) : 0;
    if (batteryStorageKwh > 0) {
      result.lines.push(`${batteryStorageKwh} kWh battery storage.`);
    }
  }

  if (result.lines.length > 0) {
    result.intro = intro;
  }

  return result;
};

export const makeNarrativePlainText = value => {
  const { intro = '', lines = [], narrative = '' } = value;
  const rows = [];

  if (intro) {
    rows.push(intro);
  }
  if (Array.isArray(lines) && lines.length > 0) {
    lines.forEach(line => {
      rows.push(`- ${line}`);
    });
  }
  if (narrative) {
    rows.push(narrative);
  }
  return rows.filter(x => x !== '').join('\n'); // remove empty rows
};
