import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const DonutChart = ({ percentage, className }) => {
  const percentageRef = useRef(null);
  const lineLength = percentageRef.current && percentageRef.current.getTotalLength();
  const lineOffset = (lineLength * (100 + percentage)) / 100;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174 157" className={`donut-chart ${className}`}>
      <path
        className="track"
        d="M136 146.38a77 77 0 1 0-98.26-.18"
        fill="none"
        stroke="#e3e3e3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
      <path
        ref={percentageRef}
        className="percentage"
        d="M136 146.38a77 77 0 1 0-98.26-.18"
        fill="none"
        stroke="#ea1c0a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
        strokeDasharray={lineLength}
        strokeDashoffset={lineOffset}
      />
    </svg>
  );
};

DonutChart.propTypes = {
  percentage: PropTypes.number.isRequired,
  className: PropTypes.string,
};

DonutChart.defaultProps = {
  className: '',
};

export default DonutChart;
