import * as actionTypes from './action-types';
import camelCaseAllFields from '@zing/neo-common/dist/accessors/calcs/camel-case-all-fields';
import initialState from './initialstate';
import processProductsOnImport from './process-products-on-import';
import produce from 'immer';
import { filterPackages, filterProducts } from '@zing/neo-common/dist/accessors/pim-filters';
import { getArray } from '@zing/neo-common/dist/lib/safe';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import { unixTimestamp } from '@zing/neo-common/dist/lib/date';

// we need to clone the objects before saving to store so they can be extended (immer limitation)
const clone = item => ({ ...item });

const pim = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_ALL:
        return initialState;

      case actionTypes.RESET_PIM:
        return initialState;

      case actionTypes.PIM_REQUEST: {
        draft.packages = [];
        draft.products = [];
        draft.relationships = [];
        draft.timestamp = null;
        draft.created_at = null;
        draft.uuid = null;
        draft.group = null;

        break;
      }

      case actionTypes.PIM_ROLLBACK:
        draft.packages = [];
        draft.products = [];
        draft.relationships = [];
        draft.timestamp = null;
        draft.created_at = null;
        draft.uuid = null;
        draft.group = null;
        break;

      case actionTypes.PIM_COMMIT: {
        // we split the incoming products data into packages and products..
        const packages = getArray(action.payload, 'products')
          .filter(filterPackages)
          .map(camelCaseAllFields)
          .map(processProductsOnImport)
          .map(clone);

        const products = getArray(action.payload, 'products')
          .filter(filterProducts)
          .map(camelCaseAllFields)
          .map(processProductsOnImport)
          .map(clone);

        const relationships = getArray(action.payload, 'relationships').map(camelCaseAllFields);

        draft.packages = packages;
        draft.products = products;
        draft.relationships = relationships;
        draft.timestamp = unixTimestamp();
        draft.created_at = action.payload.created_at;
        draft.uuid = action.payload.pim_uuid;
        draft.group = action.payload.pim_group;
        break;
      }
    }
    return draft;
  });

export default pim;
