import { LADS_CUSTOMER } from '@zing/neo-common/dist/config';
import { get, getArray } from '@zing/neo-common/dist/lib/safe';
import cloneDeep from 'lodash.clonedeep';

function removeQuoteSection(currentSections, sectionName) {
  currentSections.web = currentSections.web.filter(section => {
    if (typeof section.sections === 'string') {
      return section.sections !== sectionName;
    }
    // Remove from subsections
    if (Array.isArray(section.sections)) {
      section.sections = section.sections.filter(section => section !== sectionName);
    }
    return true;
  });
  currentSections.pdf = currentSections.pdf.filter(section => {
    if (typeof section.sections === 'string') {
      return section.sections !== sectionName;
    }
    // Remove from subsections
    if (Array.isArray(section.sections)) {
      section.sections = section.sections.filter(section => section !== sectionName);
    }
    return true;
  });
}

const processQuoteSections = (quoteSections, quote) => {
  const isLadsCustomer = quote.lead_category === LADS_CUSTOMER;
  const isSolarPvQuote = get(quote, 'panel.id', null) !== null;
  const hasRoofImages = getArray(quote, 'attachments').filter(Boolean).length > 0;

  // Clone quoteSections
  const x = cloneDeep(quoteSections);

  // remove some sections if a LADS customer
  if (isLadsCustomer) {
    removeQuoteSection(x, 'WhatNow');
    removeQuoteSection(x, 'WhatHappensNext');
    removeQuoteSection(x, 'AgreeAndSign');
    removeQuoteSection(x, 'Authorisation');
    removeQuoteSection(x, 'CancellationForm');
  }

  // only if there actually are roof images
  if (!hasRoofImages) {
    removeQuoteSection(x, 'RoofImages');
  }

  // only for solar pv quotes
  if (!isSolarPvQuote) {
    removeQuoteSection(x, 'YourSunpathDiagrams');
  }

  return x;
};

export default processQuoteSections;
