import DiagnosticsHeader from '../../components/diagnostics-header';
import React from 'react';

const WelcomeDiagnostics = () => (
  <>
    <DiagnosticsHeader title="Diagnostics" />
    <main className="diagnostics">
      <h3>Welcome to the diagnostics pages.</h3>
      <p>Please select an option from the menu</p>
    </main>
  </>
);

export default WelcomeDiagnostics;
