import React from 'react';
import PropTypes from 'prop-types';

import './notifications-toggle.scss';

const NotificationsToggle = ({ NavLink }) => (
  <div className="status-toggle" data-testid="status_toggle">
    <NavLink exact to="/notifications/unread" activeClassName="active">
      Unread
    </NavLink>
    <NavLink to="/notifications/read" activeClassName="active">
      Read
    </NavLink>
  </div>
);

NotificationsToggle.propTypes = { NavLink: PropTypes.node.isRequired };

export default NotificationsToggle;
