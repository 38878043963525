import { api } from '@zing/neo-common/dist/lib/api';

export const fetchDefaults = () => (dispatch, getState) => {
  const state = getState();
  // eslint-disable-next-line camelcase
  const accessToken = state.auth.access_token;

  dispatch({ type: 'DEFAULTS_REQUEST' });

  return api({
    accessToken,
    url: '/default-values',
    method: 'GET',
  })
    .then(response => {
      console.log('response', response.data);
      const formattedDefaultValues = {};
      (response?.data?.output?.data || []).forEach(dv => {
        formattedDefaultValues[dv.data_key] = dv.data_value;
      });
      dispatch({ type: 'DEFAULTS_SUCCESS', payload: formattedDefaultValues });
      return response.data;
    })
    .catch(err => {
      console.log('err', err);
      dispatch({ type: 'DEFAULTS_ERROR', payload: err });
      return err;
    });
};

export const updateDefaults = values => (dispatch, getState) => {
  console.log('updateDefaults', values);
  const state = getState();
  // eslint-disable-next-line camelcase
  const accessToken = state?.auth?.access_token;
  return api({
    accessToken,
    url: '/default-values',
    method: 'POST',
    data: { data_values: values },
  })
    .then(response => {
      console.log('response', response.data);
      dispatch({ type: 'DEFAULTS_SUCCESS', payload: values });
      return values;
    })
    .catch(err => {
      console.log('err', err);
      dispatch({ type: 'DEFAULTS_ERROR', payload: err });
      return err;
    });
};
