import { sortChange } from '../utils';
import { useToast } from '@zing/neo-common/dist/components/Toast';
import classnames from 'classnames';
import CustomerTableHeader from './opportunities/customer-table-header';
import CustomerTableRow from './opportunities/customer-table-row';
import DashboardNav from '../../dashboard-nav';
import FailedQuery from '../components/failed-query';
import NoResults from '../components/no-results';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SearchForm from '../components/search-form';
import useConstant from 'use-constant';
import useCustomerSearchService from '../../../hooks/use-customer-service';

/**
 * Pipeline search page
 * for the customer search see `../customer-search`
 */

const CustomerPage = ({ isAdmin, visitOpportunity }) => {
  const [state, send] = useCustomerSearchService();
  const { sortBy, sortOrder, opportunities } = state.context;

  const api = useConstant(() => ({
    reset: () => send('RESET'),
    retry: () => send('RETRY'),
    search: () => {
      if (state.matches('online')) {
        send('SEARCH');
      }
    },
    updateDateFrom: value => send('UPDATE_DATE_FROM', { value }),
    updateDateTo: value => send('UPDATE_DATE_TO', { value }),
    updateQuery: value => send('UPDATE_QUERY', { value }),
    updateSortBy: value => send('UPDATE_SORT_BY', { value }),
    updateSortOrder: value => send('UPDATE_SORT_ORDER', { value }),
  }));

  const [Toast, openToast, closeToast] = useToast({
    title: 'Offline Search',
    body: 'Search is unavailable when offline.',
    icon: 'alert',
    action: {
      label: 'Got it',
      fn: close => {
        close();
      },
    },
  });

  const isOffline = state.matches('offline');
  useEffect(() => {
    if (isOffline) {
      return openToast();
    }
    return closeToast();
  }, [isOffline]);

  const changeSorting = field => {
    sortChange(
      field,
      sortBy,
      value => api.updateSortBy(value),
      sortOrder,
      value => api.updateSortOrder(value)
    );
  };

  const canShowListView = state.matches('online.listView') && Array.isArray(opportunities);
  const cellClasses = cell => classnames('pipeline_table_cell', cell);
  const getSortingClasses = field => classnames('sortable', sortBy === field ? sortOrder.toLowerCase() : null);

  return (
    <>
      <Toast />
      <main className="pipeline customer shaded" data-testid="pipeline_page">
        <>
          <SearchForm values={state.context} api={api} isCustomerSearch />
          {canShowListView && opportunities.length > 0 && (
            <div className="pipeline_table" data-testid="pipeline_table">
              <CustomerTableHeader isAdmin={isAdmin} changeSorting={changeSorting} getSortingClasses={getSortingClasses} />
              {opportunities.map((item, idx) => (
                <CustomerTableRow
                  isAdmin={isAdmin}
                  key={item.name}
                  item={item}
                  shaded={idx % 2 !== 0}
                  cellClasses={cellClasses}
                  visitOpportunity={visitOpportunity}
                />
              ))}
            </div>
          )}
          {canShowListView && opportunities.length === 0 && <NoResults api={api} />}
          {state.matches('online.failure') && <FailedQuery api={api} />}
        </>
      </main>
      <DashboardNav />
    </>
  );
};

CustomerPage.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  visitOpportunity: PropTypes.func.isRequired,
};

export default CustomerPage;
