import FieldError from '../../hollywood-xstate/field-error';
import Radios from '../../hollywood-xstate/radios';
import React, { useContext } from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { daytimeMappings } from '../../../../config';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const EnergyConsumptionProfile = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;
  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>When does the customer mostly use electricity?</p>
        </Col>
        <Col width="fixed" className="right-col">
          <Radios
            name="energyConsumptionProfile"
            options={daytimeMappings}
            value={current.context.comparisons[currentComparison].energyConsumptionProfile}
            onChange={(_, value) => {
              send({ type: 'ENERGY_CONSUMPTION_PROFILE', currentComparison, value });
            }}
          />
          <FieldError field="energyConsumptionProfile" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default EnergyConsumptionProfile;
