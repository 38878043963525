import React, { useContext } from 'react';
import EnergyConsumptionProfile from '../../components/energy-consumption-profile';
import Estimates from '../../components/estimates';
import FieldError from '../../hollywood-xstate/field-error';
import Icon from '@zing/neo-common/dist/components/Icon';
import ImportRate from '../../components/import-rate';
import Input from '../../hollywood-xstate/input';
import MeterType from '../../components/meter-type';
import ProductTables from '../../components/product-tables';
import SurveyFooter from '../../components/survey-footer';
import Toggle from '../../hollywood-xstate/toggle';
import { ECOES_REGISTER } from '../../../../config';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const EnergyDetails = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;

  return (
    <main className="survey-page energy-details" data-testid="energyDetailsTab">
      <fieldset>
        <Grid gutters="sm" valign="center">
          <Col>
            <p>
              Is there existing grid-connected PV on site?
              <br />
              <small style={{ fontSize: '12px' }}>(MUST be completed for a battery only sale.)</small>
            </p>
          </Col>
          <Col width="fixed" className="right-col">
            <Toggle
              name="existingGridConnectedPvOnSite"
              value={current.value.existingGridConnectedPvOnSite}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
              badOption="yes"
              goodOption="no"
              onChange={(_, v) => {
                if (v === 'no') {
                  send({ type: 'EXISTING_GRID_CONNECTED_PV_ON_SITE_NO', currentComparison });
                }
                if (v === 'yes') {
                  send({ type: 'EXISTING_GRID_CONNECTED_PV_ON_SITE_YES', currentComparison });
                }
              }}
            />
            <FieldError field="existingGridConnectedPvOnSite" />
          </Col>
        </Grid>
        {current.matches('existingGridConnectedPvOnSite.yes') && (
          <>
            <Grid gutters="sm" valign="center">
              <Col>
                <label htmlFor="existingPvAnnualYieldKwh">Existing PV annual yield</label>
              </Col>
              <Col width="fixed" className="right-col">
                <Input
                  type="number"
                  name="existingPvAnnualYieldKwh"
                  prefix="kWh"
                  value={String(current.context.existingPvAnnualYieldKwh)}
                  onChange={(_, value) => {
                    send({ type: 'EXISTING_PV_ANNUAL_YIELD_KWH', currentComparison, value });
                  }}
                />
                <FieldError field="existingPvAnnualYieldKwh" />
              </Col>
            </Grid>
            <Grid gutters="sm" valign="center">
              <Col>
                <label htmlFor="existingPvInverterTotalRatedOutputKw">Existing PV inverter(s) total rated output</label>
              </Col>
              <Col width="fixed" className="right-col">
                <Input
                  type="number"
                  name="existingPvInverterTotalRatedOutputKw"
                  prefix="kW"
                  value={String(current.context.existingPvInverterTotalRatedOutputKw)}
                  onChange={(_, value) => {
                    send({ type: 'EXISTING_PV_INVERTER_TOTAL_RATED_OUTPUT_KW', currentComparison, value });
                  }}
                />
                <FieldError field="existingPvInverterTotalRatedOutputKw" />
              </Col>
            </Grid>
          </>
        )}
      </fieldset>
      <fieldset>
        <Grid gutters="sm" valign="center">
          <Col>
            <p>E.ON Customer?</p>
          </Col>
          <Col width="fixed" className="right-col">
            <Toggle
              name="eonCustomer"
              value={String(current.context.eonCustomer)}
              options={[
                { label: 'Yes', value: '178190001' },
                { label: 'No', value: '178190002' },
              ]}
              badOption="178190002"
              goodOption="178190001"
              onChange={(_, value) => {
                if (value === '178190001') {
                  send({ type: 'EON_CUSTOMER', value });
                }
                if (value === '178190002') {
                  send({ type: 'EON_CUSTOMER', value });
                }
              }}
            />
            <FieldError field="existingGridConnectedPvOnSite" />
          </Col>
        </Grid>
      </fieldset>
      <MeterType />
      <fieldset>
        <Grid gutters="md" valign="center">
          <Col width="auto">
            <label htmlFor="mpan">MPAN</label>
          </Col>
          <Col>
            <a className="ecoes-btn" href={ECOES_REGISTER} target="_blank" rel="noopener noreferrer" data-testid="ecoesRegisterUrl">
              Search Ecoes Register
              <Icon icon="chevron-right" />
            </a>
          </Col>
          <Col width="fixed" className="right-col">
            <Input
              type="number"
              name="mpan"
              value={String(current.context.mpan)}
              onChange={(_, value) => {
                send({ type: 'MPAN', value });
              }}
            />
            <FieldError field="mpan" />
          </Col>
        </Grid>
      </fieldset>
      <ImportRate />
      <EnergyConsumptionProfile />
      <Estimates />
      <SurveyFooter />
      <ProductTables />
    </main>
  );
};

EnergyDetails.propTypes = {};

export default EnergyDetails;
