import { toNumber, toFixedNumber } from '@zing/neo-common/dist/lib/safe';
import { sortByFieldAsc } from '@zing/neo-common/dist/redux/product/accessor';

export const findByProdId = id => item => item.prod_id === id;

// converts degrees to radians
export const radians = degrees => degrees / (180 / Math.PI);

// converts radians to degrees
export const degrees = radians => radians * (180 / Math.PI);

export const timeAngle = minutesFromDayStart => {
  // converts time of day to the vertical angle of the sun
  /*
    ORIGINAL EXCEL EXAMPLE
      =((B15 * 24) - 12) * 15
        B15 = minutes from the start of the day
  */

  const dayPart = (1 / (24 * 60)) * parseFloat(minutesFromDayStart); // calculate part of the day for a single minute as a decimal
  return parseFloat(((parseFloat(dayPart) * 24 - 12) * 15).toFixed(2));
};

// eslint-disable-next-line camelcase
export const intensity = (declination, latitude, inclination, orientation, time_angle, degrees = true) => {
  // determines the intensity of the light on the array for a given position, orientation and moment in time.
  /*
    ORIGINAL EXCEL EXAMPLE
      Function INTENSITY(declination As Variant, latitude As Variant, panel_tilt As Variant, _
        panel_azimuth As Variant, time_angle As Variant, Optional Degrees = False) As Variant

      If Degrees = True Then
        declination = WorksheetFunction.Radians(declination)
        latitude = WorksheetFunction.Radians(latitude)
        panel_tilt = WorksheetFunction.Radians(panel_tilt)
        panel_azimuth = WorksheetFunction.Radians(panel_azimuth)
        time_angle = WorksheetFunction.Radians(time_angle)

      End If

        INTENSITY = Sin(declination) * Sin(latitude) * Cos(panel_tilt) _
              - Sin(declination) * Cos(latitude) * Sin(panel_tilt) * Cos(panel_azimuth) _
              + Cos(declination) * Cos(latitude) * Cos(panel_tilt) * Cos(time_angle) _
              + Cos(declination) * Sin(latitude) * Sin(panel_tilt) * Cos(panel_azimuth) * Cos(time_angle) _
              + Cos(declination) * Sin(panel_tilt) * Sin(panel_azimuth) * Sin(time_angle)

      If INTENSITY < 0 Then INTENSITY = 0
  */

  if (degrees) {
    declination = radians(declination);
    latitude = radians(latitude);
    inclination = radians(inclination);
    orientation = radians(orientation);
    // eslint-disable-next-line camelcase
    time_angle = radians(time_angle);
  }
  let intensity = Math.sin(parseFloat(declination)) * Math.sin(parseFloat(latitude)) * Math.cos(parseFloat(inclination));
  intensity -=
    Math.sin(parseFloat(declination)) *
    Math.cos(parseFloat(latitude)) *
    Math.sin(parseFloat(inclination)) *
    Math.cos(parseFloat(orientation));
  intensity +=
    Math.cos(parseFloat(declination)) *
    Math.cos(parseFloat(latitude)) *
    Math.cos(parseFloat(inclination)) *
    Math.cos(parseFloat(time_angle));
  intensity +=
    Math.cos(parseFloat(declination)) *
    Math.sin(parseFloat(latitude)) *
    Math.sin(parseFloat(inclination)) *
    Math.cos(parseFloat(orientation)) *
    Math.cos(parseFloat(time_angle));
  intensity +=
    Math.cos(parseFloat(declination)) *
    Math.sin(parseFloat(inclination)) *
    Math.sin(parseFloat(orientation)) *
    Math.sin(parseFloat(time_angle));
  if (intensity < 0) {
    intensity = 0;
  } // avoid negative values

  return intensity;
};

export const pushConsoleTableProducts = (components, consoleTableComponents) =>
  components.forEach(product => {
    // format console log output in tabular form
    consoleTableComponents.push({
      name: product.name,
      value: product.value,
      quantity: product.quantity,
      gsratio: product.gsratio,
      gvalue: product.gvalue,
      svalue: product.svalue,
    });
  });

export const pushConsoleTableProductTotals = (consoleTableTotals, productCosts) =>
  consoleTableTotals.push({
    'Kit cost Calculation (inc VAT)': productCosts.name,
    'Ex-VAT price': toNumber(productCosts.ex),
    'VAT rate - GOODS': toNumber(productCosts.gVatRate),
    'VAT rate - SERVICES': toNumber(productCosts.sVatRate),
    'VAT amount - GOODS': toNumber(productCosts.gVat),
    'vat amount - SERVICES': toNumber(productCosts.sVat),
    ' ': '', // used for totals
    'Customer Prices (inc VAT)': toNumber(productCosts.total),
  });

export const numberAsPercentage = value => toNumber(value) / 100;

export const percentageAsNumber = value => toNumber(value) * 100;

/**
 * Sort the crm products by priority and clean the returned array
 *
 * @param items
 * @pure Clones the original array before sorting
 * @returns {unknown[]}
 */
export const prioritiseCrmProducts = items =>
  [...items].sort(sortByFieldAsc('priority')).map(item => {
    const { priority, ...result } = item;
    return result;
  });

/**
 * Calculates the actual gsRatio for a given input
 * @param current
 */
export const gsRatio = current =>
  // gsRatio = (1 / (totalGoods + totalServices)) * totalGoods; TODO: JC, is this true? calcs dont match the formula..
  toFixedNumber(current.totalGoods / (current.totalGoodsGm - current.discountValue + current.totalServicesGm), 3);

/**
 * Determines if we should be using the lower tax rate in our calcs
 * @param isEligibleLowerVatRate
 * @param gsRatio
 * @param gsRatioThreshold
 * @returns {boolean}
 */
export const shouldUseLowerRate = (isEligibleLowerVatRate, gsRatio, gsRatioThreshold) =>
  isEligibleLowerVatRate === true || gsRatio < gsRatioThreshold;
