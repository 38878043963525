import apiVersion from './api_version/reducer';
import neoDb from '../databases/neo';
import notifications from './notifications/reducer';
import pim from './pim/reducer';
import pimSession from './pim-session/reducer';
import presenter from './presenter/presenterReducer';
import quoteEmailTemplate from './quote-email/reducer';
import session from './session/reducer';
import settings from './settings/settingsReducer';
import { auth } from './auth/reducer';
import { combineReducers } from 'redux';
import { defaults } from './defaults/reducer';
import { offline } from '@zing/neo-common/dist/redux/offline/reducer';
import { opportunities } from './opportunities/reducer';
import { product } from './product/reducer';
import { user } from '@zing/neo-common/dist/redux/user/reducer';

const rootReducer = combineReducers({
  apiVersion,
  auth,
  defaults,
  notifications,
  offline,
  opportunities: opportunities(neoDb),
  pim,
  pimSession,
  presenter,
  product,
  quoteEmailTemplate,
  session,
  settings,
  user,
});

export default rootReducer;
