import React from 'react';
import classnames from 'classnames';
import IconButton from '@zing/neo-common/dist/components/IconButton';
import PropTypes from 'prop-types';

const PipelineTableRowIcon = ({ api, isExpanded, isOffline }) => {
  const classNames = classnames('pipeline_icon', isOffline ? 'offline' : null);
  return (
    <div className="pipeline_table_row_icon" data-testid="pipeline_table_row_icon">
      <div className={classnames('pipeline_table_cell', 'tab_col')}>
        {isExpanded && <IconButton icon="chevron-down" className={classNames} onClick={() => api.close()} />}
        {!isExpanded && <IconButton icon="chevron-right" className={classNames} onClick={() => api.open()} />}
      </div>
    </div>
  );
};

PipelineTableRowIcon.propTypes = {
  api: PropTypes.shape({
    close: PropTypes.func.isRequired,
    open: PropTypes.func.isRequired,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
};

export default PipelineTableRowIcon;
