import React from 'react';
import PropTypes from 'prop-types';

import './ErrorMessage.scss';

const ErrorMessage = ({ children, testId }) => (
  <div className="error-message" data-testid={testId}>
    {children}
  </div>
);

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

ErrorMessage.defaultProps = {
  testId: null,
};

export default ErrorMessage;
