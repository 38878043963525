import React from 'react';
import BackButton from '../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import Icon from '@zing/neo-common/dist/components/Icon';
import PropTypes from 'prop-types';
import { appointmentMatchUrl } from '../../utils/opportunity';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { CUSTOMER_DETAILS, THANK_YOU_MEETING } from '../../utils/paths';
import { withRouter } from 'react-router-dom';

const Actions = ({ match, location, history, enableStartAppointment = true, onSubmit }) => (
  <div className="actions">
    <Grid valign="center">
      <Col>
        <Button
          format="secondary"
          testId="edit-details"
          onClick={() => {
            history.push({
              pathname: appointmentMatchUrl(match, CUSTOMER_DETAILS),
              state: {
                updateOpportunityInSession: true,
                isPimSurvey: true,
                back: location.pathname,
              },
            });
          }}
        >
          Edit details <Icon icon="pencil" />
        </Button>
      </Col>
      <Col width="auto">
        <BackButton link={appointmentMatchUrl(match, THANK_YOU_MEETING)} />
        <Button
          type="button"
          format="primary"
          testId="start-presentation"
          disabled={enableStartAppointment !== true}
          onClick={() => onSubmit('presentation')}
        >
          Presentation
        </Button>
        <Button
          type="button"
          format="primary"
          testId="start-survey"
          disabled={enableStartAppointment !== true}
          onClick={() => onSubmit('survey')}
        >
          Survey
        </Button>
      </Col>
    </Grid>
  </div>
);

Actions.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  enableStartAppointment: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withRouter(Actions);
