import React, { useContext } from 'react';
import Roof from './roof';
import { PimSurveyMachineContext } from '../../../contexts/survey-context';

const Roofs = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;
  const { roofs } = current.context.comparisons[currentComparison];

  // eslint-disable-next-line react/no-array-index-key
  return roofs.map((roof, index) => <Roof index={index} key={`roof_${index}`} />);
};

export default Roofs;
