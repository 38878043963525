import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true, error };
  // }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { session } = this.props;

    if (error || errorInfo) {
      return (
        <>
          <h1>Something went wrong.</h1>
          <p>Please report this error to UAT</p>
          <h2>Error</h2>
          <pre>{error && error.toString()}</pre>
          <h3>Session</h3>
          <div style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(session, null, 2)}</div>
          <h3>Additional information</h3>
          <div style={{ whiteSpace: 'pre-wrap' }}>{errorInfo.componentStack}</div>
        </>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  session: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ session }) => ({ session });

export default connect(mapStateToProps)(AppErrorBoundary);
