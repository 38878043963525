import React from 'react';
import PropTypes from 'prop-types';
import useSurveyValidation from '../../contexts/survey-validation-context';
import HollywoodSelect from '../../hollywood/select';

const Select = props => {
  const { validator } = useSurveyValidation();
  const { name, ...rest } = props;
  return <HollywoodSelect name={name} {...rest} hasError={validator.hasError(name)} />;
};

Select.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      }),
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ])
  ).isRequired,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  testIdUseLabels: PropTypes.bool,
  value: PropTypes.string,
};

Select.defaultProps = {
  autoFocus: false,
  className: null,
  disabled: false,
  id: null,
  onBlur: null,
  onChange: null,
  placeholder: null,
  testId: null,
  testIdUseLabels: false,
  value: '',
};

export default Select;
