import { NOTIFICATIONS, NOTIFICATION_READ, RESET_NOTIFICATIONS } from './actionTypes';
import { METHOD_POST } from '@zing/neo-common/dist/lib/api';
import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';

export const fetchNotifications = () =>
  offlineRequestAction({
    type: NOTIFICATIONS,
    url: '/notifications',
  });

export const markNotificationAsRead = id =>
  offlineRequestAction({
    type: NOTIFICATION_READ,
    url: `/notifications/${id}/mark-as-read`,
    method: METHOD_POST,
    commitMeta: {
      id,
    },
    rollbackMeta: {
      id,
    },
  });

export const resetNotifications = () => ({ type: RESET_NOTIFICATIONS });
