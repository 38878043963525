export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL;

export const BEFORE_WE_START = 'before-we-start';
export const CUSTOMER_DETAILS = 'customer-details';
export const EMAIL_QUOTE = 'email-quote';
export const PAYMENT_OPTIONS = 'payment-options';
export const PRESENTER = 'presenter';
export const PROPERTY_DETAILS = 'property-details';
export const QUOTE = 'quote';
export const SURVEY = 'survey';
export const THANK_YOU_CHOOSING_EON = 'thank-you-for-choosing-eon';
export const THANK_YOU_MEETING = 'thank-you-for-meeting-us';
export const YOUR_PACKAGE = 'your-package';
