import neoDb from '../../databases/neo';
import { OPPORTUNITIES, OPPORTUNITIES_SEARCH, RESET_OPPORTUNITIES } from './actionTypes';
import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';
import { apiDateFormatDateOnly } from '@zing/neo-common/dist/lib/date';
import { api } from '@zing/neo-common/dist/lib/api';
import { get } from '@zing/neo-common/dist/lib/safe';

export const fetchOpportunitiesFromDateAsPromise = (date = new Date(), numDays = 30) => (dispatch, getState) => {
  const dateString = encodeURI(apiDateFormatDateOnly(date));
  const url = `/opportunities?when=${dateString}&days=${numDays}`;

  // eslint-disable-next-line
  return new Promise((resolve, reject) => {
    return api({
      accessToken: getState().auth.access_token || '',
      method: 'GET',
      url,
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        const response = get(error, 'response', null);
        reject(response);
      });
  });
};

export const fetchOpportunitiesFromDate = (date = new Date(), numDays = 30) => {
  const dateString = encodeURI(apiDateFormatDateOnly(date));
  const url = `/opportunities?when=${dateString}&days=${numDays}`;
  return offlineRequestAction({
    type: OPPORTUNITIES,
    url,
    tag: 'FETCH_OPPORTUNITIES',
    ignoreIfTagExists: true,
  });
};

export const searchOpportunities = (filters = { dateFrom: null, dateTo: null, query: '' }) => {
  const dateFromString = filters.dateFrom !== '' ? encodeURI(apiDateFormatDateOnly(filters.dateFrom)) : '';
  const dateToString = filters.dateTo !== '' ? encodeURI(apiDateFormatDateOnly(filters.dateTo)) : '';
  const queryString = encodeURI(filters.query || '');

  const url = `/opportunities/search?date_from=${dateFromString}&date_to=${dateToString}&query=${queryString}`;
  return offlineRequestAction({
    type: OPPORTUNITIES_SEARCH,
    url,
  });
};

export const searchOpportunitiesAsPromise = (
  filters = { dateFrom: null, dateTo: null, query: '' },
  accessToken,
  endpoint = 'search',
  limit = 1000
) => {
  const dateFromString = filters.dateFrom !== '' ? encodeURI(apiDateFormatDateOnly(filters.dateFrom)) : '';
  const dateToString = filters.dateTo !== '' ? encodeURI(apiDateFormatDateOnly(filters.dateTo)) : '';
  const queryString = encodeURI(filters.query || '');
  const url = `/opportunities/${endpoint}?date_from=${dateFromString}&date_to=${dateToString}&query=${queryString}&limit=${limit}`;

  if (!accessToken) {
    return Promise.reject(Error('Missing access token'));
  }

  // force API error (for testing)
  // return Promise.reject('Invalid URL');

  return new Promise((resolve, reject) =>
    api({
      accessToken,
      method: 'GET',
      url,
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        const response = get(error, 'response', null);
        reject(response);
      })
  );
};

export const resetOpportunities = () => ({ type: RESET_OPPORTUNITIES });

export const fetchOpportunityByAppUuid = (accessToken, opportunityAppUuid) => {
  const url = `/opportunities/${opportunityAppUuid}`;
  return api({
    accessToken,
    method: 'GET',
    url,
  });
};

export const assignToMeAsPromise = (accessToken, opportunityAppUuid, meetingAppUuid) => {
  const url = `/opportunity/assign-to-me`;

  if (!accessToken) {
    return Promise.reject(Error('Missing access token'));
  }

  const data = { opportunity_app_uuid: opportunityAppUuid };
  if (meetingAppUuid) {
    data.meeting_app_uuid = meetingAppUuid;
  }

  return new Promise((resolve, reject) => {
    let assignResponse;
    return api({
      accessToken,
      method: 'POST',
      url,
      data,
    })
      .then(response => {
        assignResponse = response.data;
        return fetchOpportunityByAppUuid(accessToken, response.data.opportunity);
      })
      .then(response => {
        neoDb.table('opportunities').put(response.data);
        resolve(assignResponse);
      })
      .catch(error => {
        const response = get(error, 'response', null);
        reject(response);
      });
  });
};

export const assignToUserAsPromise = (accessToken, userId, opportunityAppUuid, meetingAppUuid) => {
  const url = `/opportunity/assign-to-user/${userId}`;

  if (!accessToken) {
    return Promise.reject(Error('Missing access token'));
  }

  const data = { opportunity_app_uuid: opportunityAppUuid };
  if (meetingAppUuid) {
    data.meeting_app_uuid = meetingAppUuid;
  }

  return new Promise((resolve, reject) => {
    let assignResponse;
    return api({
      accessToken,
      method: 'POST',
      url,
      data,
    })
      .then(response => {
        assignResponse = response.data;
        return fetchOpportunityByAppUuid(accessToken, response.data.opportunity);
      })
      .then(response => {
        neoDb.table('opportunities').put(response.data);
        resolve(assignResponse);
      })
      .catch(error => {
        const response = get(error, 'response', null);
        reject(response);
      });
  });
};
