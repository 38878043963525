import neoDb from '../databases/neo';
import { mapMeetingDetailsById } from '../redux/opportunities/accessor';
import { useEffect, useState } from 'react';

const useOpportunity = (appUuid, meetingId) => {
  const [opportunity, setOpportunity] = useState(null);

  const callback = () => {
    neoDb
      .table('opportunities')
      .where('app_uuid')
      .equals(appUuid)
      .first(opportunity => {
        if (meetingId) {
          const processed = mapMeetingDetailsById(opportunity, meetingId);
          setOpportunity(processed);
          return;
        }
        setOpportunity(opportunity);
      });
  };

  useEffect(() => {
    if (neoDb.easyEncryptReady) {
      callback();
    } else {
      neoDb.open().then(() => callback());
    }
  }, [appUuid, neoDb.easyEncryptReady]);
  return opportunity;
};

export default useOpportunity;
