import { connect } from 'react-redux';
import { updatePowra } from '../../redux/session/actions';
import BeforeWeStartForm from './BeforeWeStartForm';
import Header from '../../components/Header';
import initialState from '../../redux/pim-session/initialstate';
import Modal from 'hollywood/dist/components/Modal';
import PowraForm from '../../pim/survey/components/powra-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import schema from '../../components/forms/customer-details-form/validator';
import useLocalStorage from '../../hooks/useLocalStorage';

import './pim-before-we-start.scss';

const PimBeforeWeStart = ({ history, session, updatePowra }) => {
  const [powraNextUrl, setPowraNextUrl] = useState(null); // null = closed, string = open (string is url to redirect to after powra is completed)
  const [pimSession] = useLocalStorage('pimSession', initialState);
  const [enableStartAppointment, setEnableStartAppointment] = useState(true);

  // validate opportunity against edit details validator..
  schema.isValid(pimSession?.opportunity).then(result => {
    if (enableStartAppointment !== result) {
      setEnableStartAppointment(result);
    }
  });

  const openPowraModal = nextUrl => setPowraNextUrl(nextUrl);
  const closePowraModal = () => setPowraNextUrl(null);

  const submitPowra = powra => {
    powra.meetCustomer.done = true;
    updatePowra(powra);
    history.push(powraNextUrl);
  };

  // eslint-disable-next-line react/prop-types
  const shouldShowPowra = session.powra.meetCustomer.done !== true;

  return (
    <>
      <Header />
      <main className="before-we-start" data-testid="before-we-start-page">
        {!enableStartAppointment && (
          <p className="powra-error" data-testid="appointment-error">
            Missing appointment information. Please edit details to continue.
          </p>
        )}
        <BeforeWeStartForm
          enableStartAppointment={enableStartAppointment}
          openPowraModal={openPowraModal}
          shouldShowPowra={shouldShowPowra}
        />
      </main>

      {shouldShowPowra && (
        <Modal isOpen={powraNextUrl !== null} className="powra-modal" onClose={closePowraModal}>
          <PowraForm onCancel={closePowraModal} onSubmit={submitPowra} sections={['meetCustomer']} showCancel values={session?.powra} />
        </Modal>
      )}
    </>
  );
};

PimBeforeWeStart.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  session: PropTypes.shape({
    powra: PropTypes.shape({}).isRequired,
  }).isRequired,
  updatePowra: PropTypes.func.isRequired,
};

export default connect(({ session }) => ({ session }), { updatePowra })(PimBeforeWeStart);
