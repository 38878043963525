import { COMPASS_LOOKUP } from '../../CalculationsData';
import { clamp } from '@zing/neo-common/dist/lib/number';

/**
 * This function returns the cardinal compass point from a compass bearing value between -180 and 180
 *
 *
 * NOTE: This function takes in a dataset which defines cardinal compass directions from a degree bearing
 *
 * ORIGINAL EXCEL EXAMPLE
 *   =IF(ISBLANK(C7),"",VLOOKUP(ROUND(C7/22.5,0)*22.5,DegreeCompass.tb,2,0))
 *
 * INPUTS
 *   bearing		= Azimuth (Degrees from South, +ive for westerly)	(C7)
 *
 * OUTPUTS
 *   direction_text	= Annual Electricity Demand (kWh) - Estimated
 *
 * FIXED DATA
 */

export const cardinalFromCompassBearing = bearing => {
  if (!Number.isNaN(parseFloat(bearing))) {
    return COMPASS_LOOKUP[Math.round(clamp(bearing, -180, 180) / 22.5) * 22.5];
  }
  return '';
};
