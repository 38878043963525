import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import BackButton from '../../components/buttons/back';

import './TroubleSigningIn.scss';

export const TroubleSigningIn = ({ history }) => (
  <>
    <Header menuButton={false} />
    <main className="trouble-sign-in" data-testid="trouble-sign-in">
      <h2>Having trouble signing in?</h2>

      <p>Please contact your line manager</p>

      <div className="back">
        <BackButton
          type="submit"
          format="primary"
          size="medium"
          onClick={() => {
            history.push('/login');
          }}
          text="Back to sign in"
        />
      </div>
    </main>
  </>
);

TroubleSigningIn.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
