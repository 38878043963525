import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'hollywood/dist/components/Modal/Modal';
import Button from 'hollywood/dist/components/Button/Button';
import { api } from '@zing/neo-common/dist/lib/api';
import { fetchPimAsPromise } from '../../redux/pim/actions';

import './pim-select-modal.scss';

function PimSelectModal({ auth, fetchPimAsPromise, isOpen = false, onCancel, onSelection, currentPimUuid }) {
  const [pimOptions, setPimOptions] = React.useState([]);
  const [showPimOptions, setShowPimOptions] = React.useState(false);

  useEffect(() => {
    async function fetchPims() {
      try {
        const pims = await api({
          accessToken: auth.access_token,
          url: '/pimlist',
          method: 'GET',
        });
        setPimOptions([pims.data.latest, ...pims.data.list]);
      } catch (error) {
        setPimOptions([]);
        setShowPimOptions(false);
        // eslint-disable-next-line no-alert
        alert('Failed to load pricebooks. Please make sure you are online and try again.');
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    if (showPimOptions) fetchPims();
  }, [showPimOptions]);

  async function proceedWithSelectedPim(event) {
    event.preventDefault();
    const selection = event.target.pim.value;
    await fetchPimAsPromise(selection);
    onSelection();
  }

  async function proceedWithExistingPim() {
    await fetchPimAsPromise(currentPimUuid);
    onSelection();
  }
  async function proceedWithLatestPim() {
    await fetchPimAsPromise();
    onSelection();
  }

  return (
    <Modal isOpen={isOpen} onClose={onCancel} className="pim-select-modal">
      <h3>Pricebook Selection</h3>
      {!showPimOptions && (
        <div>
          <p>
            <Button type="button" format="primary" onClick={() => proceedWithExistingPim()}>
              Use original pricebook
            </Button>
            <Button type="button" format="tertiary" onClick={() => proceedWithLatestPim()}>
              Use latest pricebook
            </Button>
          </p>
          <p style={{ textAlign: 'center' }}>
            <span className="lg" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              or
            </span>{' '}
            <Button type="button" format="secondary" onClick={() => setShowPimOptions(true)}>
              Select a pricebook
            </Button>
          </p>
        </div>
      )}
      {showPimOptions && (
        <>
          {pimOptions.length === 0 ? (
            <p>Loading pricebooks...</p>
          ) : (
            <form
              onSubmit={proceedWithSelectedPim}
              onReset={() => {
                setShowPimOptions(false);
              }}
            >
              <div className="form-field">
                <label htmlFor="pim">Select pricebook</label>
                <select name="pim" className="hollywood-select" defaultValue={currentPimUuid} required>
                  <option value="" disabled>
                    Select a pricebook
                  </option>
                  {pimOptions
                    .filter(pim => pim.status !== 'finished')
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    .map(pim => (
                      <option value={pim.uuid} key={pim.uuid}>
                        {pim.pim_group}
                      </option>
                    ))}
                </select>
              </div>
              <Button type="reset" format="secondary">
                Cancel
              </Button>
              <Button type="submit" format="primary">
                Select
              </Button>
            </form>
          )}
        </>
      )}
    </Modal>
  );
}

PimSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  currentPimUuid: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
  fetchPimAsPromise: PropTypes.func.isRequired,
};
PimSelectModal.defaultProps = {
  currentPimUuid: '',
};

export default connect(({ auth }) => ({ auth }), { fetchPimAsPromise })(PimSelectModal);
