import React from 'react';
import Panel from '../../Panel';
import PropTypes from 'prop-types';
import { appUuid, opportunityDate } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { Opportunity } from '../../Opportunity';
import { whenToId } from '../../../pages/Opportunities/when-to-id';

import './opportunities-list.scss';

const OpportunitiesList = ({ opportunityDates, opportunitiesByDate }) => (
  <div className="opportunities_list" data-testid="opportunities_list">
    {/* Map over dates */}
    {opportunityDates.map(when => {
      const whenUniqueId = whenToId(when);
      return (
        <div className="opportunities__date" key={when}>
          <h3 className="eon-red tight opportunities__date__label" id={whenUniqueId} data-testid={whenUniqueId}>
            {opportunityDate({ when })}
          </h3>
          <Panel flush className="opportunities__date__opportunities">
            {/* Map over opportunities in current date */}
            {opportunitiesByDate[when].map(opportunity => (
              <Opportunity opportunity={opportunity} key={appUuid(opportunity)} whenUniqueId={whenUniqueId} />
            ))}
          </Panel>
        </div>
      );
    })}
  </div>
);

OpportunitiesList.propTypes = {
  opportunityDates: PropTypes.shape([]).isRequired,
  opportunitiesByDate: PropTypes.shape([]).isRequired,
};

export default OpportunitiesList;
