import React from 'react';
import { Col } from '@zing/neo-common/dist/components/Grid';

export const Cols = () => (
  <>
    <h3>Renders children</h3>
    <Col testId="col-children">
      <span>Hello</span>
    </Col>

    <h3>Custom class</h3>
    <Col className="custom-class" testId="col-custom-class" />

    <h3>Width</h3>
    <Col width="1" testId="col-width" />

    <h3>V-Align</h3>
    <Col valign="bottom" testId="col-valign" />
  </>
);
