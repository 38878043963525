import { appUuid, meetingId, opportunityMatchId, opportunityMatchMeetingId } from '@zing/neo-common/dist/redux/opportunity/accessor';

const buildUrl = (id, meetingId, path, base = 'opportunity') => {
  if (!id) {
    return '';
  }
  let parts = [base, id, meetingId || 'no-meeting'];
  if (path) {
    if (Array.isArray(path)) {
      parts = [...parts, ...path];
    } else {
      parts.push(path);
    }
  }
  const url = parts.join('/');
  return `/${url}`;
};

export const opportunityUrl = (opportunity, path = '', base = 'opportunity') =>
  buildUrl(appUuid(opportunity), meetingId(opportunity), path, base);

export const opportunityMatchUrl = (match, path = '', base = 'opportunity') =>
  buildUrl(opportunityMatchId(match), opportunityMatchMeetingId(match), path, base);

// V2 pim switches paths to /appointment
export const appointmentUrl = (opportunity, path = '', base = 'appointment') =>
  buildUrl(appUuid(opportunity), meetingId(opportunity), path, base);

export const appointmentMatchUrl = (match, path = '', base = 'appointment') =>
  buildUrl(opportunityMatchId(match), opportunityMatchMeetingId(match), path, base);
