import { customerFirstName, customerLastName, opportunityLnumber } from '@zing/neo-common/dist/redux/opportunity/accessor';
import format from 'date-fns/format';
import some from 'lodash.some';
import { get, getArray } from '@zing/neo-common/dist/lib/safe';

export const filterOpportunities = (opportunities, filters = {}) => {
  let filteredOpportunities = opportunities;

  // Go through the filters apply filtering as needed
  filteredOpportunities = filteredOpportunities.filter(opportunity => {
    const filterDateFrom = get(filters, 'dateFrom', null);
    const filterDateTo = get(filters, 'dateTo', null);
    const filterQuery = get(filters, 'query', null);

    const lnumber = opportunityLnumber(opportunity) ? opportunityLnumber(opportunity) : '';
    if (
      filterQuery &&
      !lnumber.toLowerCase().includes(filterQuery.toLowerCase()) &&
      !`${customerFirstName(opportunity)} ${customerLastName(opportunity)}`.toLowerCase().includes(filterQuery.toLowerCase())
    ) {
      return false;
    }

    if (filterDateFrom && filterDateTo) {
      const dateFrom = format(new Date(filterDateFrom), 'YYYY-MM-DD');
      const dateTo = format(new Date(filterDateTo), 'YYYY-MM-DD');
      if (dateFrom === 'Invalid Date' || dateTo === 'Invalid Date') {
        return false;
      }
      // check if any of the meetings are within the period
      const meetingsWithinDateRange = getArray(opportunity, 'meetings').map(meeting => {
        const when = format(new Date(get(meeting, 'when')), 'YYYY-MM-DD');
        return when >= dateFrom && when <= dateTo;
      });
      return some(meetingsWithinDateRange, Boolean);
    }

    // Got past the filters, let it through
    return true;
  });

  return filteredOpportunities;
};
