import DiagnosticsHeader from '../../../components/diagnostics-header';
// import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { api } from '@zing/neo-common/dist/lib/api';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import { ToggleField } from '@zing/neo-common/dist/components/forms/fields/ToggleField';
import Button from 'hollywood/dist/components/Button';
import ErrorMessage from '../../../components/ErrorMessage';

import './voucher-codes.scss';

// const VOUCHER_CODES = [
//   { id: 1, code: 'FIXED200', description: '£200 fixed discount', type: 'fixed', amount: 200, expires: '2024-02-15T00:00:00Z' },
//   { id: 2, code: 'TAKE10', description: '10% discount', type: 'percent', amount: 10, expires: '2024-02-28T00:00:00Z' },
//   { id: 3, code: 'SECRETDEAL', description: '10% discount', type: 'percent', amount: 10, expires: '2024-03-10T00:00:00Z' },
//   { id: 4, code: 'CODE4', description: 'Voucher code 4', type: 'fixed', amount: 40, expires: '2024-02-15T00:00:00Z' },
//   { id: 5, code: 'CODE5', description: 'Voucher code 5', type: 'percent', amount: 10, expires: '2024-02-28T00:00:00Z' },
//   { id: 6, code: 'CODE6', description: 'Voucher code 6', type: 'fixed', amount: 60, expires: '2024-03-10T00:00:00Z' },
//   { id: 7, code: 'CODE7', description: 'Voucher code 7', type: 'percent', amount: 10, expires: '2024-03-15T00:00:00Z' },
//   { id: 8, code: 'CODE8', description: 'Voucher code 8', type: 'fixed', amount: 80, expires: '2024-03-20T00:00:00Z' },
//   { id: 9, code: 'CODE9', description: 'Voucher code 9', type: 'percent', amount: 10, expires: '2024-04-05T00:00:00Z' },
//   { id: 10, code: 'CODE10', description: 'Voucher code 10', type: 'fixed', amount: 100, expires: '2024-04-15T00:00:00Z' },
//   { id: 11, code: 'CODE11', description: 'Voucher code 11', type: 'percent', amount: 10, expires: '2024-04-30T00:00:00Z' },
//   { id: 12, code: 'CODE12', description: 'Voucher code 12', type: 'fixed', amount: 120, expires: '2024-05-10T00:00:00Z' },
//   { id: 13, code: 'CODE13', description: 'Voucher code 13', type: 'percent', amount: 10, expires: '2024-05-20T00:00:00Z' },
//   { id: 14, code: 'CODE14', description: 'Voucher code 14', type: 'fixed', amount: 140, expires: '2024-06-05T00:00:00Z' },
//   { id: 15, code: 'CODE15', description: 'Voucher code 15', type: 'percent', amount: 10, expires: '2024-06-15T00:00:00Z' },
//   { id: 16, code: 'CODE16', description: 'Voucher code 16', type: 'fixed', amount: 160, expires: '2024-06-30T00:00:00Z' },
//   { id: 17, code: 'CODE17', description: 'Voucher code 17', type: 'percent', amount: 10, expires: '2024-07-10T00:00:00Z' },
//   { id: 18, code: 'CODE18', description: 'Voucher code 18', type: 'fixed', amount: 180, expires: '2024-07-20T00:00:00Z' },
//   { id: 19, code: 'CODE19', description: 'Voucher code 19', type: 'percent', amount: 10, expires: '2024-08-05T00:00:00Z' },
//   { id: 20, code: 'CODE20', description: 'Voucher code 20', type: 'fixed', amount: 200, expires: '2024-08-15T00:00:00Z' },
//   { id: 21, code: 'CODE21', description: 'Voucher code 21', type: 'percent', amount: 10, expires: '2024-08-30T00:00:00Z' },
//   { id: 22, code: 'CODE22', description: 'Voucher code 22', type: 'fixed', amount: 220, expires: '2024-09-10T00:00:00Z' },
//   { id: 23, code: 'CODE23', description: 'Voucher code 23', type: 'percent', amount: 10, expires: '2024-09-20T00:00:00Z' },
//   { id: 24, code: 'CODE24', description: 'Voucher code 24', type: 'fixed', amount: 240, expires: '2024-10-05T00:00:00Z' },
//   { id: 25, code: 'CODE25', description: 'Voucher code 25', type: 'percent', amount: 10, expires: '2024-10-15T00:00:00Z' },
//   { id: 26, code: 'CODE26', description: 'Voucher code 26', type: 'fixed', amount: 260, expires: '2024-10-30T00:00:00Z' },
//   { id: 27, code: 'CODE27', description: 'Voucher code 27', type: 'percent', amount: 10, expires: '2024-11-10T00:00:00Z' },
//   { id: 28, code: 'CODE28', description: 'Voucher code 28', type: 'fixed', amount: 280, expires: '2024-11-20T00:00:00Z' },
//   { id: 29, code: 'CODE29', description: 'Voucher code 29', type: 'percent', amount: 10, expires: '2024-12-05T00:00:00Z' },
//   { id: 30, code: 'CODE30', description: 'Voucher code 30', type: 'fixed', amount: 300, expires: '2024-12-15T00:00:00Z' },
//   { id: 31, code: 'CODE31', description: 'Voucher code 31', type: 'percent', amount: 10, expires: '2024-12-30T00:00:00Z' },
//   { id: 32, code: 'CODE32', description: 'Voucher code 32', type: 'fixed', amount: 320, expires: '2024-02-29T00:00:00Z' },
//   { id: 33, code: 'CODE33', description: 'Voucher code 33', type: 'percent', amount: 20, expires: '2024-03-31T00:00:00Z' },
//   { id: 34, code: 'CODE34', description: 'Voucher code 34', type: 'fixed', amount: 340, expires: '2024-04-30T00:00:00Z' },
//   { id: 35, code: 'CODE35', description: 'Voucher code 35', type: 'percent', amount: 10, expires: '2024-05-31T00:00:00Z' },
//   { id: 36, code: 'CODE36', description: 'Voucher code 36', type: 'fixed', amount: 360, expires: '2024-06-30T00:00:00Z' },
//   { id: 37, code: 'CODE37', description: 'Voucher code 37', type: 'percent', amount: 10, expires: '2024-07-31T00:00:00Z' },
//   { id: 38, code: 'CODE38', description: 'Voucher code 38', type: 'fixed', amount: 380, expires: '2024-08-31T00:00:00Z' },
//   { id: 39, code: 'CODE39', description: 'Voucher code 39', type: 'percent', amount: 10, expires: '2024-09-30T00:00:00Z' },
//   { id: 40, code: 'CODE40', description: 'Voucher code 40', type: 'fixed', amount: 400, expires: '2024-10-31T00:00:00Z' },
//   { id: 41, code: 'MATESRATES', description: 'Cheat code', type: 'percent', amount: 99, expires: '2024-02-28T00:00:00Z' },
// ];

const VoucherCodesListPage = ({ auth }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();

  // Refetch codes when the search query changes
  const [vcError, setVcError] = React.useState(null);
  const [vcData, setVcData] = React.useState(null);
  async function loadVoucherCodes({ type, search, page = 1 }) {
    const query = new URLSearchParams();
    if (type) query.set('voucher_type', type);
    if (search) query.set('voucher_code', search);
    if (page) query.set('page', page);

    try {
      const response = await api({
        accessToken: auth.access_token,
        url: `/voucher/management/search?${query.toString()}`,
        method: 'GET',
      });
      const data = response?.data?.output;
      setVcData({
        codes: data.data,
        total: data.total,
        current_page: data.current_page,
        from: data.from,
        to: data.to,
      });
    } catch (error) {
      setVcData(null);
      setVcError(error.message);
    }
  }
  useEffect(() => {
    const type = query.get('type') ?? '';
    const search = query.get('search') ?? '';
    const page = query.get('page') ?? 1;
    setVcData(null);
    setVcError(null);
    loadVoucherCodes({ type, search, page });
  }, [search]);

  // Update the URL query string when the filters change
  function updateFilters(values) {
    const newQuery = new URLSearchParams();
    Object.entries(values).forEach(([key, value]) => {
      if (value !== '') {
        newQuery.set(key, value);
      }
    });
    history.push({ search: newQuery.toString() });
  }

  function paginate(page) {
    const newQuery = new URLSearchParams(search);
    newQuery.set('page', page);
    history.push({ search: newQuery.toString() });
  }

  return (
    <>
      <DiagnosticsHeader title="Voucher Codes" />
      <main className="voucher-codes">
        <Formik
          initialValues={{
            type: query.get('type') ?? '',
            search: query.get('search') ?? '',
          }}
          onSubmit={values => updateFilters(values)}
        >
          {({ submitForm }) => (
            <Form className="voucher-codes__filters">
              <Grid gutters="md">
                <Col>
                  <Grid gutters="xs" valign="bottom">
                    <Col>
                      <TextField name="search" title="Search:" type="search" />
                    </Col>
                    <Col width="auto">
                      <Button small format="primary" type="submit">
                        Search
                      </Button>
                    </Col>
                  </Grid>
                </Col>
                <Col width="auto">
                  <ToggleField
                    name="type"
                    id="type"
                    title="Type:"
                    options={[
                      {
                        label: 'Any',
                        value: '',
                      },
                      {
                        label: 'Fixed',
                        value: 'fixed',
                      },
                      {
                        label: 'Percent',
                        value: 'percentage',
                      },
                    ]}
                    onChange={submitForm}
                  />
                </Col>
              </Grid>
            </Form>
          )}
        </Formik>

        {vcError === null && vcData === null && (
          <p>
            <strong>Loading...</strong>
          </p>
        )}

        {vcError && (
          <ErrorMessage>
            <strong>Error loading voucher codes:</strong>
            <br />
            {vcError}
          </ErrorMessage>
        )}

        {vcData !== null &&
          (vcData.codes.length > 0 ? (
            <>
              <table className="voucher-codes__table">
                <thead>
                  <tr>
                    <th style={{ width: 200 }}>Code</th>
                    <th>Description</th>
                    <th style={{ width: 120 }}>Type</th>
                    <th style={{ width: 120 }}>Amount</th>
                    <th style={{ width: 80 }}>Active</th>
                    <th style={{ width: 120 }}>Valid From</th>
                    <th style={{ width: 120 }}>Valid To</th>
                    <th style={{ width: 72 }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {vcData.codes.map(voucherCode => (
                    <tr key={voucherCode.id}>
                      <td>{voucherCode.voucher_code}</td>
                      <td>
                        <span title={voucherCode.description}>{voucherCode.description}</span>
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>{voucherCode.voucher_type}</td>
                      <td>{voucherCode.voucher_type === 'fixed' ? `£${voucherCode.discount}` : `${voucherCode.discount}%`}</td>
                      <td>
                        <span
                          style={{
                            display: 'inline-block',
                            color: 'white',
                            backgroundColor: voucherCode.active ? `green` : `#d51607`,
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 500,
                            textTransform: 'uppercase',
                            padding: '0 4px',
                          }}
                        >
                          {voucherCode.active ? `Yes` : `No`}
                        </span>
                      </td>
                      <td>{new Date(voucherCode.available_from_date).toLocaleDateString()}</td>
                      <td>{new Date(voucherCode.available_to_date).toLocaleDateString()}</td>
                      <td>
                        <Link className="eon-red bold" to={`/diagnostics/voucher-codes/${voucherCode.uuid}`}>
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div style={{ marginTop: 40 }}>
                <Grid valign="center">
                  <Col>
                    {vcData.current_page > 1 && (
                      <Button format="secondary" onClick={() => paginate(vcData.current_page - 1)}>
                        &laquo; Previous
                      </Button>
                    )}
                  </Col>
                  <Col width="auto">
                    <p>
                      Showing {vcData.from} to {vcData.to} of {vcData.total}
                    </p>
                  </Col>
                  <Col className="range-right">
                    {vcData.total > vcData.to && (
                      <Button format="secondary" onClick={() => paginate(vcData.current_page + 1)}>
                        Next &raquo;
                      </Button>
                    )}
                  </Col>
                </Grid>
              </div>
            </>
          ) : (
            <p>
              <strong>No voucher codes found.</strong>
            </p>
          ))}
      </main>
    </>
  );
};

VoucherCodesListPage.propTypes = {
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({ auth }) => ({ auth }))(VoucherCodesListPage);
