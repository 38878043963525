import React from 'react';
import * as filters from '../../../redux/opportunities/hide-opportunities';
import Button from 'hollywood/dist/components/Button';
import DatePicker from 'react-datepicker';
import Icon from '@zing/neo-common/dist/components/Icon';
import OpportunitiesFilter from '../opportunities-filter';
import PropTypes from 'prop-types';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';

import './opportunities-nav.scss';

const OpportunitiesNav = ({ calendarOpen, filter, setFilter, setCalendarOpen, opportunityDates }) => (
  <>
    <div className="opportunities_nav" data-testid="opportunities_nav">
      <Grid valign="center">
        <Col />
        <Col width="auto">
          <OpportunitiesFilter filter={filter} setFilter={setFilter} />
        </Col>
        <Col className="range-right">
          <Button link="/opportunity/create" testId="createOpportunity">
            <Icon icon="plus" />
          </Button>
          <Button onClick={() => setCalendarOpen(true)}>
            <Icon icon="calendar" />
          </Button>
        </Col>
      </Grid>
    </div>
    {calendarOpen && (
      <div className="opportunities_calendar_overlay">
        <div className="opportunities_calendar">
          <button className="close_calendar" type="button" onClick={() => setCalendarOpen(false)} data-testid="close_calendar">
            <Icon className="eon-red" icon="close" />
          </button>
          <DatePicker
            inline
            selected={filter instanceof Date ? filter : null}
            highlightDates={opportunityDates.map(d => new Date(d))}
            onChange={date => {
              setFilter(date);
              setCalendarOpen(false);
            }}
          />
          <ul className="opportunities_calendar__key">
            <li className="future">Future events</li>
            <li className="selected">Selected</li>
          </ul>
        </div>
      </div>
    )}
  </>
);

OpportunitiesNav.propTypes = {
  calendarOpen: PropTypes.bool.isRequired,
  filter: PropTypes.oneOf([filters.FILTER_THIS_WEEK, filters.FILTER_NEXT_WEEK, filters.FILTER_THIS_MONTH, filters.FILTER_ALL]).isRequired,
  opportunityDates: PropTypes.shape([]).isRequired,
  setCalendarOpen: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default OpportunitiesNav;
