import { Machine } from 'xstate';

const buildMenuMachine = (id = 'menu', initial = 'closed') =>
  Machine({
    id,
    initial,
    states: {
      closed: {
        on: {
          OPEN: 'open',
        },
      },
      open: {
        on: {
          CLOSE: 'closed',
        },
      },
    },
  });

export default buildMenuMachine;
