import React from 'react';

import './IeMessage.scss';

const IeMessage = () => (
  <div className="app">
    <div className="ie_message">
      <div>Unsupported browser, please upgrade your browser to Microsoft edge</div>
    </div>
  </div>
);

export default IeMessage;
