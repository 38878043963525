import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import Select from '../../hollywood-xstate/select';
import React, { useContext, useState } from 'react';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import Button from '../../hollywood-xstate/button';
import Modal from 'hollywood/dist/components/Modal';
import Input from '../../hollywood-xstate/input';
import { getContextCurrentComparison } from '../../../../machines/survey.machine';

const ComparisonSelection = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const [isEditNameModalOpen, isEditNameModalOpenSet] = useState(false);
  const { currentComparison } = current.context;
  const context = getContextCurrentComparison(current);
  return (
    <>
      <Grid gutters="sm">
        <Col width="4">
          <Select
            name="comparison"
            options={['survey', 'one', 'two', 'three'].map(comparison => ({
              value: comparison,
              label: [
                current.context.comparisons[comparison].name || comparison,
                current.context.comparisons[comparison].pimComparison.getJourney().type,
              ]
                .filter(x => x !== undefined)
                .join(' - '),
            }))}
            value={current.context.currentComparison}
            onChange={(_, currentComparison) => {
              switch (currentComparison) {
                case 'survey':
                  send({ type: 'SELECT_SURVEY', currentComparison });
                  break;
                case 'one':
                  send({ type: 'SELECT_COMPARISON_ONE', currentComparison });
                  break;
                case 'two':
                  send({ type: 'SELECT_COMPARISON_TWO', currentComparison });
                  break;
                case 'three':
                  send({ type: 'SELECT_COMPARISON_THREE', currentComparison });
                  break;
              }
            }}
          />
        </Col>
        <Col width="2">
          <Button
            className="comparisonButton"
            format="primary"
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('Renaming currentComparison', currentComparison);
              isEditNameModalOpenSet(true);
            }}
          >
            Rename
          </Button>
        </Col>
      </Grid>
      <Modal
        isOpen={isEditNameModalOpen}
        className="edit-roof-name-modal"
        onClose={() => {
          isEditNameModalOpenSet(false);
          send({ type: 'EDIT_COMPARISON_NAME', currentComparison, value: context.name });
        }}
      >
        <h3>Edit the {currentComparison === 'survey' ? 'survey' : 'comparison'} name</h3>
        <div className="form-field">
          <Input
            type="text"
            name="editComparisonName"
            id="editComparisonName"
            // eslint-disable-next-line react/destructuring-assignment
            value={context?.nameEditing}
            onChange={(e, value) => {
              send({ type: 'EDIT_COMPARISON_NAME', currentComparison, value });
            }}
            onKeyDown={e => {
              if (context?.nameEditing?.length > 0 && e.key === 'Enter') {
                send({ type: 'SAVE_COMPARISON_NAME', currentComparison });
                isEditNameModalOpenSet(false);
              }
              if (e.key === 'Escape') {
                isEditNameModalOpenSet(false);
                send({ type: 'EDIT_COMPARISON_NAME', currentComparison, value: context.name });
              }
            }}
          />
          <span>Eg. Energise</span>
        </div>
        <div className="range-right">
          <Button
            format="primary"
            // eslint-disable-next-line react/destructuring-assignment
            disabled={context?.nameEditing?.length === 0}
            onClick={() => {
              send({ type: 'SAVE_COMPARISON_NAME', currentComparison });
              isEditNameModalOpenSet(false);
            }}
          >
            Save name
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ComparisonSelection;
