import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useSurveyValidation from '../contexts/survey-validation-context';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { NavLink, withRouter } from 'react-router-dom';

import './survey-tabs.scss';

const isComplete = (tab, validator) => Boolean(validator?.tabs[tab]) === true;
const isCompleteClass = (tab, validator) => (isComplete(tab, validator) ? 'complete' : null);
const isInvalidClass = (tab, validator) => (isComplete(tab, validator) ? null : 'invalid');

const SurveyTabs = ({
  match: {
    params: { id, meetingId },
  },
  baseUrl,
}) => {
  const { validator } = useSurveyValidation();
  return (
    <nav className="survey-tabs-new" data-testid="survey-tabs">
      <Grid align="fill">
        <Col>
          <NavLink
            className={classnames('survey-tabs__tab', isCompleteClass('propertyDetails', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/property-details`}
            data-testid="propertyDetailsTab"
          >
            Property details
          </NavLink>
          <NavLink
            className={classnames('survey-tabs__tab', isCompleteClass('energyDetails', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/energy-details`}
            data-testid="energyDetailsTab"
          >
            Energy details
          </NavLink>
          <NavLink
            className={classnames('survey-tabs__tab', isCompleteClass('solarPv', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/solar-pv`}
            data-testid="solarPvTab"
          >
            Solar PV
          </NavLink>

          <NavLink
            className={classnames('survey-tabs__tab', isCompleteClass('battery', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/battery`}
            data-testid="batteryTab"
          >
            Battery
          </NavLink>

          <NavLink
            className={classnames('survey-tabs__tab', isCompleteClass('evCharging', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/ev-charging`}
            data-testid="evChargingTab"
          >
            EV Charging
          </NavLink>
          <NavLink
            className={classnames('survey-tabs__tab', isCompleteClass('g99', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/g99`}
            data-testid="g99Tab"
          >
            G99
          </NavLink>
          <NavLink
            className={classnames('survey-tabs__tab', isCompleteClass('additionalProducts', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/additional-products`}
            data-testid="additionalProductsTab"
          >
            Additional Products
          </NavLink>
        </Col>
        <Col width="auto">
          <NavLink
            className="survey-tabs__tab survey-tabs__tab--edit"
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/installation-notes`}
            data-testid="installationNotesTab"
          />
          <NavLink
            className={classnames('survey-tabs__tab survey-tabs__tab--settings', isInvalidClass('settings', validator))}
            activeClassName="active"
            to={`${baseUrl}/${id}/${meetingId}/survey/settings`}
            data-testid="settingsTab"
          />
        </Col>
      </Grid>
    </nav>
  );
};

SurveyTabs.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      meetingId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default withRouter(SurveyTabs);
