import React, { useContext } from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { getNumber, toFixed } from '@zing/neo-common/dist/lib/safe';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const G99Calcs = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const g99 = comparisons[currentComparison]?.dynamic?.calcs?.g99 || {};

  return (
    <div className="g99-calcs">
      <Grid gutters="lg">
        <Col width="auto">
          <h3 className="flush" data-testid="systemTotalInstallPvKwh">
            {toFixed(getNumber(g99, 'existingPV'))} kW
          </h3>
          <p>Existing PV</p>
        </Col>
        <Col width="auto">
          <span className="h3">+</span>
        </Col>
        <Col width="auto">
          <h3 className="flush" data-testid="systemTotalInstallPvKwh">
            {toFixed(getNumber(g99, 'proposedPV'))} kW
          </h3>
          <p>Proposed PV</p>
        </Col>
        <Col width="auto">
          <span className="h3">+</span>
        </Col>
        <Col width="auto">
          <h3 className="flush" data-testid="systemTotalBatteryPowerRatingKw">
            {toFixed(getNumber(g99, 'battery'))} kW
          </h3>
          <p>Battery</p>
        </Col>
        <Col width="auto">
          <span className="h3">=</span>
        </Col>
        <Col width="auto">
          <h3 className="flush" data-testid="systemTotalOnSiteGenerationKw">
            {toFixed(getNumber(g99, 'totalOnSiteGeneration'))} kW
          </h3>
          <p>Total on-site generation</p>
        </Col>
      </Grid>
    </div>
  );
};

export default G99Calcs;
