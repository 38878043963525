import React from 'react';
import PropTypes from 'prop-types';
import BackButton from '../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import Icon from '@zing/neo-common/dist/components/Icon';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { useLocation } from 'react-router-dom';

const OpportunityActions = ({
  enableStartAppointment = true,

  meetingIsCancelled = false,
  onStartClick,
  whenUniqueId = '',
  onEditDetailsClick,
}) => {
  const location = useLocation();
  return (
    <div className="opportunity-actions">
      <Grid valign="center">
        <Col>
          {meetingIsCancelled && (
            <Button format="secondary" testId="edit-details" disabled>
              Edit details <Icon icon="pencil" />
            </Button>
          )}
          {!meetingIsCancelled && (
            <Button format="secondary" testId="edit-details" onClick={onEditDetailsClick}>
              Edit details <Icon icon="pencil" />
            </Button>
          )}
        </Col>
        <Col width="auto">
          <BackButton
            link={
              location.state?.back
                ? location.state.back
                : {
                    pathname: '/opportunities',
                    state: {
                      whenUniqueId,
                    },
                  }
            }
          />
          <Button format="primary" onClick={onStartClick} testId="start-opportunity" disabled={enableStartAppointment !== true}>
            Start appointment
          </Button>
        </Col>
      </Grid>
    </div>
  );
};
OpportunityActions.propTypes = {
  onStartClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  whenUniqueId: PropTypes.string,
  enableStartAppointment: PropTypes.bool.isRequired,
  meetingIsCancelled: PropTypes.bool.isRequired,
  onEditDetailsClick: PropTypes.func.isRequired,
};

export default OpportunityActions;
