import { EXISTING_PV_REVERSE_LOOKUP } from '../../CalculationsData';
import { booleanToInt, clamp, clampFloorInt, divide } from '@zing/neo-common/dist/lib/number';
import { toBoolean, toFixed, toNumber, get } from '@zing/neo-common/dist/lib/safe';

// import SELF_CONSUMPTION_PV from '../../self-consumption-pv.json';
// import SELF_CONSUMPTION_BATTERY from '../../self-consumption-battery.json';
import SELF_CONSUMPTION_PV from '../../self-consumption-data/self-consumption-pv--2022-12-16.json';
import SELF_CONSUMPTION_BATTERY from '../../self-consumption-data/self-consumption-battery--2022-12-16.json';

/**
 * avoidedImportsSubcalculation
 *
 * Excel formula:
 *
 * =IF(InstallationType="Battery Only", ('Hidden Calcs'!$O$61 - 'Hidden Calcs'!$K$52) * 'Hidden Calcs'!$I$29, 'Indicative Costs'!$D$83)
 *   'Hidden Calcs'!$O$61 =MIN(AnnualDemand/$I29, N61)
 *   AnnualDemand =IF(InstallationType="Battery Only", 'Roof & Package'!C18 * I38, 'Customer Data'!G26)
 *   'Roof & Package'!C18 = existingPvAnnualGenerationKwh  //Existing PV Annual Yield (kWh)
 *   I38 =IF(C49=1, INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_OutdDay.tb,1), 1), INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_atHome.tb,1), 1))    //Annual Demand/PV Gen ratio (batt only)
 *   C49 =IF('Customer Data'!G19="Mostly evenings and weekends", 1, 2)    //customer out most of the day
 *   INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_OutdDay.tb,1), 1)    //tablular lookup - out during day
 *   INDEX(SC_lookup.tb, MATCH('Hidden Calcs'!C35, SC_lookup_atHome.tb,1), 1)    //tabular lookup - in during day
 *   'Customer Data'!G26 = estimatedElectricityDemand()  //Estimated Electricity Demand (kWh)
 *   $I29 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
 *   'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
 *   G29 = Annual PV Yield (kWh)
 *   N61 ='NEW SC lookup'!N12
 *   'NEW SC lookup'!N12 = this.cashFlowSelfConsumption().corrPvBSc  //subcalculation moved to function due to complexity
 *   'Hidden Calcs'!$K$52 ='NEW SC lookup'!G12
 *   'NEW SC lookup'!G12 = this.cashFlowSelfConsumption().PvOnlySc  //subcalculation moved to function due to complexity
 *   'Hidden Calcs'!$I$29 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
 *   'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
 *   G29 = Annual PV Yield (kWh)
 *   'Indicative Costs'!$D$83 =D27*D85
 *   D27 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
 *   'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
 *   G29 = Annual PV Yield (kWh)
 *   D85 =IF(D14="None", 'Hidden Calcs'!$K$52, 'Hidden Calcs'!$O$61)
 *   D14 = Battery type
 *   'Hidden Calcs'!$K$52 = this.cashFlowSelfConsumption().PvOnlySc  //subcalculation moved to function due to complexity
 *   'Hidden Calcs'!$O$61 =MIN(AnnualDemand/$I29, N61)
 *   AnnualDemand =IF(InstallationType="Battery Only", 'Roof & Package'!C18 * I38, 'Customer Data'!G26)
 *   //NOTE THIS IS ALSO USED ABOVE - COMPLEX CALC PART
 *   $I29 =IF(InstallationType="Battery Only", 'Roof & Package'!$C$18, G29)
 *   'Roof & Package'!$C$18 = Existing PV Annual Yield (kWh)
 *   G29 = Annual PV Yield (kWh)
 *   N61 = this.cashFlowSelfConsumption().corrPvBSc  //subcalculation moved to function due to complexity
 *
 * @param isPvPackage
 * @param isBatteryPackage
 * @param hasExistingPv
 * @param outDuringDay
 * @param pvAnnualGenerationKwh
 * @param existingPvAnnualGenerationKwh
 * @param estimatedElectricityDemandKwh
 * @param batteryCapacityKwh
 * @param decisions
 * @returns {{exportKwh: number, selfSufficiencyRatio: number, selfConsumptionRatio: *, electricitySourcedFromOwnPv: number}}
 */
export const avoidedImportsSubcalculation = (
  isPvPackage, // todo: param not used. can we remove it?
  isBatteryPackage,
  hasExistingPv,
  outDuringDay = true,
  pvAnnualGenerationKwh,
  existingPvAnnualGenerationKwh,
  estimatedElectricityDemandKwh,
  batteryCapacityKwh,
  decisions
) => {
  const totalPvGenerationKwh = toBoolean(hasExistingPv)
    ? toNumber(existingPvAnnualGenerationKwh) + toNumber(pvAnnualGenerationKwh)
    : toNumber(pvAnnualGenerationKwh);

  let estimatedDemandKwh = toNumber(estimatedElectricityDemandKwh);

  const isNumOccupants = get(decisions, 'estimated_electricity_demand') === 'NUM_OCCUPANTS';

  // Use Hidden calcs:C35 -
  // find C35 in New SC Lookup table col C (round up)
  // value from col B * PV generation = estimated overall demand

  if (toBoolean(hasExistingPv) && !isNumOccupants) {
    // battery installation only - supersede existing demand value with calculated value using battery lookup
    // = IF(InstallationType="Battery Only", ROUND('Customer Data'!G26 / 'Roof & Package'!C18, 2), "N/A")
    const residualDemandToPvGenRatio = toNumber(toFixed(divide(estimatedDemandKwh, existingPvAnnualGenerationKwh), 2));

    const lookupData = EXISTING_PV_REVERSE_LOOKUP[booleanToInt(outDuringDay)];

    const lookupKeys = Object.keys(lookupData).filter(x => x <= residualDemandToPvGenRatio);
    const lookupKey = toNumber(lookupKeys[lookupKeys.length - 1]);
    const adjustedDemandToPVGenRatio = toNumber(lookupData[lookupKey]);

    estimatedDemandKwh = adjustedDemandToPVGenRatio * existingPvAnnualGenerationKwh;
  }

  // clamp annual demand to units of 500 - min 1500 / max 5500
  const clampedAnnualDemand = clamp(clampFloorInt(estimatedDemandKwh, 500), 1500, 5500);

  // clamp batteryCapacityKwh to units of 1.1 - min 0.1 / max 13.1
  const capacityKwh = (Math.floor((toNumber(batteryCapacityKwh) * 10) / 10) * 10) / 10 + 0.1;
  const clampedBatteryCapacityKwh = clamp(capacityKwh, 0.1, 13.1);

  // clamp pvAnnualGeneration to units of 300 - min 0 / max 5700
  const clampedPvAnnualGenerationKwh = clamp(clampFloorInt(totalPvGenerationKwh, 300), 0, 5700);

  // Retrofit - 1. underlying consumption (performed above)
  //			- 2. table lookup
  // PV or Both - 1. table lookup

  // obtain calculated value from lookup table
  let selfConsumptionRatio = 0;
  if (toBoolean(isBatteryPackage) === false) {
    // tabular lookup - PV only
    const pvData = SELF_CONSUMPTION_PV[booleanToInt(outDuringDay)][clampedAnnualDemand];
    if (pvData) {
      const pvKeys = Object.keys(pvData).filter(x => x <= clampedPvAnnualGenerationKwh);
      const pvKey = pvKeys[pvKeys.length - 1];
      selfConsumptionRatio = toNumber(pvData[pvKey]);
    }
  } else {
    // tabular lookup - PV + battery
    const batteryData = SELF_CONSUMPTION_BATTERY[booleanToInt(outDuringDay)][clampedAnnualDemand][clampedBatteryCapacityKwh];
    if (batteryData) {
      const batteryKeys = Object.keys(batteryData).filter(x => x <= clampedPvAnnualGenerationKwh);
      const batteryKey = batteryKeys[batteryKeys.length - 1];
      selfConsumptionRatio = toNumber(batteryData[batteryKey]);
    }
  }

  // Electricity sourced from own pv = parseFloat(clampedPvAnnualGenerationKwh) * parseFloat(selfConsumptionRatio);
  const electricitySourcedFromOwnPv = totalPvGenerationKwh * selfConsumptionRatio;

  // Self-Sufficiency (%) = Electricity sourced from own PV (kWh) / Current Electricity Demand (kWh)
  const selfSufficiencyRatio = divide(electricitySourcedFromOwnPv, estimatedDemandKwh);

  const exportKwh = totalPvGenerationKwh - electricitySourcedFromOwnPv;
  const importKwh = estimatedDemandKwh - electricitySourcedFromOwnPv;

  // making outputs safe..
  return {
    electricitySourcedFromOwnPv: toNumber(electricitySourcedFromOwnPv),
    exportKwh: toNumber(exportKwh),
    selfConsumptionRatio: toNumber(selfConsumptionRatio),
    selfSufficiencyRatio: toNumber(selfSufficiencyRatio),
    estimatedDemandKwh,
    importKwh,
    totalPvGenerationKwh,
  };
};

// OLD comments that dont seem to do anything...

// calculate annual demand
//		if (batteryInstallOnly === true) {	//battery installation only
//			if (outDuringDay === true) {
//				var AnnualDemand = parseFloat(clampedPvAnnualGenerationKwh) * parseFloat(SELF_CONSUMPTION_BATTERY['out'][clampedAnnualDemand][clampedbatteryCapacityKwh][clampedExistingPvAnnualGenerationKwh]);	//tabular lookup - out during day
//			} else {
//				var AnnualDemand = parseFloat(clampedPvAnnualGenerationKwh) * parseFloat(SELF_CONSUMPTION_BATTERY['home'][clampedAnnualDemand][clampedbatteryCapacityKwh][clampedExistingPvAnnualGenerationKwh]);	//tabular lookup - in during day
//			}
//		} else {	//PV installation
//			var AnnualDemand = estimatedElectricityDemand(estimatedDemandKwh, annual_cost, occupants, import_rate);	//estimated demand
//		}

// Electricity sourced from grid = electricity demand - electricity from own PV
// var electricitySourcedFromGrid = estimatedDemandKwh - electricitySourcedFromOwnPv;
