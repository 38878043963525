export const ELECTRICITY_PRICE_INFLATION = 0.05; // set at 5%
export const DEEMED_EXPORT_PERCENTAGE = 0.5; // set at 50%
export const GRID_EMISSION_FACTOR = 0.23104; // CO2 emission factor
// export rate = 0

export const OCCUPANTS_LOOKUP = { 1: 3100, 2: 4000, 3: 4350, 4: 4800, 5: 5100, 6: 5400, 7: 5550 };

export const COMPASS_LOOKUP = {
  '-180': 'N',
  '-157.5': 'NNE',
  '-135': 'NE',
  '-112.5': 'ENE',
  '-90': 'E',
  '-67.5': 'ESE',
  '-45': 'SE',
  '-22.5': 'SSE',
  0: 'S',
  22.5: 'SSW',
  45: 'SW',
  67.5: 'WSW',
  90: 'W',
  112.5: 'WNW',
  135: 'NW',
  157.5: 'NNW',
  180: 'N',
};

export const POSTCODE_DATA = {
  AB: '16',
  AL: '1',
  BD23: '10',
  BD24: '10',
  BA: '5E',
  BB: '7E',
  BD: '11',
  BH: '3',
  BL: '7E',
  BN: '2',
  BR: '2',
  BS: '5E',
  BT: '21',
  B: '6',
  CM21: '1',
  CM22: '1',
  CM23: '1',
  CH5: '7W',
  CH60: '7E',
  CH61: '7E',
  CH62: '7E',
  CH63: '7E',
  CH64: '7E',
  CH65: '7E',
  CH66: '7E',
  CH6: '7W',
  CH7: '7W',
  CH8: '7W',
  CH: '7E',
  CA: '8E',
  CB: '12',
  CF: '5W',
  CM: '12',
  CO: '12',
  CR: '1',
  CT: '2',
  CV: '6',
  CW: '7E',
  DH4: '9E',
  DH5: '9E',
  DA: '2',
  DD: '15',
  DE: '6',
  DG: '8S',
  DH: '10',
  DL: '10',
  DN: '11',
  DT: '3',
  DY: '6',
  EH43: '9S',
  EH44: '9S',
  EH45: '9S',
  EH46: '9S',
  EN9: '12',
  EC: '1',
  EH: '15',
  EN: '1',
  EX: '4',
  E: '1',
  FK: '14',
  FY: '7E',
  GU11: '3',
  GU12: '3',
  GU14: '3',
  GU28: '2',
  GU29: '2',
  GU30: '3',
  GU31: '3',
  GU32: '3',
  GU33: '3',
  GU34: '3',
  GU35: '3',
  GU46: '3',
  GU51: '3',
  GU52: '3',
  GL: '5E',
  GU: '1',
  G: '14',
  HA: '1',
  HD: '11',
  HG: '10',
  HP: '1',
  HR: '6',
  HS: '18',
  HU: '11',
  HX: '11',
  IV30: '16',
  IV31: '16',
  IV32: '16',
  IV36: '16',
  IG: '12',
  IP: '12',
  IV: '17',
  KW15: '19',
  KW16: '19',
  KW17: '19',
  KA: '14',
  KT: '1',
  KW: '17',
  KY: '15',
  LA10: '8E',
  LA11: '8E',
  LA12: '8E',
  LA13: '8E',
  LA14: '8E',
  LA15: '8E',
  LA16: '8E',
  LA17: '8E',
  LA18: '8E',
  LA19: '8E',
  LA20: '8E',
  LA21: '8E',
  LA22: '8E',
  LA23: '8E',
  LL23: '13',
  LL24: '13',
  LL25: '13',
  LL26: '13',
  LL27: '13',
  LL30: '13',
  LL31: '13',
  LL32: '13',
  LL33: '13',
  LL34: '13',
  LL35: '13',
  LL36: '13',
  LL37: '13',
  LL38: '13',
  LL39: '13',
  LL40: '13',
  LL41: '13',
  LL42: '13',
  LL43: '13',
  LL44: '13',
  LL45: '13',
  LL46: '13',
  LL47: '13',
  LL48: '13',
  LL49: '13',
  LL50: '13',
  LL51: '13',
  LL52: '13',
  LL53: '13',
  LL54: '13',
  LL55: '13',
  LL56: '13',
  LL57: '13',
  LL58: '13',
  LL59: '13',
  LL60: '13',
  LL61: '13',
  LL62: '13',
  LL63: '13',
  LL64: '13',
  LL65: '13',
  LL66: '13',
  LL67: '13',
  LL68: '13',
  LL69: '13',
  LL70: '13',
  LL71: '13',
  LL72: '13',
  LL73: '13',
  LL74: '13',
  LL75: '13',
  LL76: '13',
  LL77: '13',
  LL78: '13',
  LS24: '10',
  LA8: '8E',
  LA9: '8E',
  LA: '7E',
  LA7: '8E',
  LD: '13',
  LE: '6',
  LL: '7W',
  LN: '11',
  LS: '11',
  LU: '1',
  L: '7E',
  ME: '2',
  MK: '1',
  ML: '14',
  M: '7E',
  NE: '9E',
  NG: '11',
  NN: '6',
  NP: '5W',
  NPS: '13',
  NR: '12',
  NW: '1',
  N: '1',
  OL: '7E',
  OX: '1',
  PE20: '11',
  PE21: '11',
  PE22: '11',
  PE23: '11',
  PE24: '11',
  PE25: '11',
  PE10: '11',
  PE11: '11',
  PE12: '11',
  PH19: '17',
  PH20: '17',
  PH21: '17',
  PH22: '17',
  PH23: '17',
  PH24: '17',
  PH25: '17',
  PH26: '16',
  PH30: '17',
  PH31: '17',
  PH32: '17',
  PH33: '17',
  PH34: '17',
  PH35: '17',
  PH36: '17',
  PH37: '17',
  PH38: '17',
  PH39: '17',
  PH40: '17',
  PH41: '17',
  PH42: '17',
  PH43: '17',
  PH44: '17',
  PH49: '14',
  PH50: '14',
  PO18: '2',
  PO19: '2',
  PO20: '2',
  PO21: '2',
  PO22: '2',
  PE9: '11',
  PA: '14',
  PE: '12',
  PH: '15',
  PL: '4',
  PO: '3',
  PR: '7E',
  RG21: '3',
  RG22: '3',
  RG23: '3',
  RG24: '3',
  RG25: '3',
  RG26: '3',
  RG27: '3',
  RG28: '3',
  RG29: '3',
  RH10: '2',
  RH11: '2',
  RH12: '2',
  RH13: '2',
  RH14: '2',
  RH15: '2',
  RH16: '2',
  RH17: '2',
  RH18: '2',
  RH19: '2',
  RH20: '2',
  RH77: '2',
  RG: '1',
  RH: '1',
  RM: '12',
  SA14: '13',
  SA15: '13',
  SA16: '13',
  SA17: '13',
  SA18: '13',
  SA19: '13',
  SA20: '13',
  SA31: '13',
  SA32: '13',
  SA33: '13',
  SA34: '13',
  SA35: '13',
  SA36: '13',
  SA37: '13',
  SA38: '13',
  SA39: '13',
  SA40: '13',
  SA41: '13',
  SA42: '13',
  SA43: '13',
  SA44: '13',
  SA45: '13',
  SA46: '13',
  SA47: '13',
  SA48: '13',
  SA61: '13',
  SA62: '13',
  SA63: '13',
  SA64: '13',
  SA65: '13',
  SA66: '13',
  SA67: '13',
  SA68: '13',
  SA69: '13',
  SA70: '13',
  SA71: '13',
  SA72: '13',
  SA73: '13',
  SK13: '6',
  SK17: '6',
  SK22: '6',
  SK23: '6',
  SY14: '7E',
  SY15: '13',
  SY16: '13',
  SY17: '13',
  SY18: '13',
  SY19: '13',
  SY20: '13',
  SY21: '13',
  SY22: '13',
  SY23: '13',
  SY24: '13',
  SY25: '13',
  SN7: '1',
  SP10: '3',
  SP11: '3',
  SP6: '3',
  SP7: '3',
  SP8: '3',
  SP9: '3',
  SR7: '10',
  SR8: '10',
  S18: '6',
  S32: '6',
  S33: '6',
  S40: '6',
  S41: '6',
  S42: '6',
  S43: '6',
  S44: '6',
  S45: '6',
  S49: '6',
  SA: '5W',
  SE: '1',
  SG: '1',
  SK: '7E',
  SL: '1',
  SM: '1',
  SN: '5E',
  SO: '3',
  SP: '5E',
  SR: '9E',
  SS: '12',
  ST: '6',
  SW: '1',
  SY: '6',
  S: '11',
  TD12: '9E',
  TD15: '9E',
  TA: '5E',
  TD: '9S',
  TF: '6',
  TN: '2',
  TQ: '4',
  TR: '4',
  TS: '10',
  TW: '1',
  UB: '1',
  WA: '7E',
  WC: '1',
  WD: '1',
  WF: '11',
  WN: '7E',
  WR: '6',
  WS: '6',
  WV: '6',
  W: '1',
  YO15: '11',
  YO16: '11',
  YO25: '11',
  YO: '10',
  ZE: '20',
};

export const BATTERY_SIZING = {
  0: { 1: [0, 0], 0: [0, 0] },
  1: { 1: [0.1971, 0.3624], 0: [0.221, 0.4327] }, // NOTE 1 was not in original data table, we have included it as a fallback for when size is rounded to a value of 1 as the original data would default to 0 or 2
  2: { 1: [0.1971, 0.3624], 0: [0.221, 0.4327] },
  3: { 1: [0.252, 0.3851], 0: [0.268, 0.4533] },
  4: { 1: [0.3018, 0.4038], 0: [0.3097, 0.4742] },
  5: { 1: [0.343, 0.4253], 0: [0.35, 0.4856] },
  6: { 1: [0.3787, 0.4415], 0: [0.3855, 0.5018] },
  7: { 1: [0.4, 0.4504], 0: [0.4033, 0.5065] },
  8: { 1: [0.4151, 0.4579], 0: [0.421, 0.5108] },
};

/*
		RETROFIT_REVERSE_LOOKUP
			{outDuringDay : {residualDemand : adjustedResidualDemand,...}}
*/
export const EXISTING_PV_REVERSE_LOOKUP = {
  0: {
    0.0: 0.0,
    0.037: 0.1,
    0.095: 0.2,
    0.155: 0.3,
    0.215: 0.4,
    0.246: 0.45,
    0.277: 0.5,
    0.313: 0.55,
    0.349: 0.6,
    0.386: 0.65,
    0.423: 0.7,
    0.46: 0.75,
    0.497: 0.8,
    0.535: 0.85,
    0.573: 0.9,
    0.611: 0.95,
    0.649: 1.0,
    0.688: 1.05,
    0.726: 1.1,
    0.765: 1.15,
    0.804: 1.2,
    0.844: 1.25,
    0.883: 1.3,
    0.923: 1.35,
    0.963: 1.4,
    1.003: 1.45,
    1.044: 1.5,
    1.084: 1.55,
    1.125: 1.6,
    1.166: 1.65,
    1.207: 1.7,
    1.248: 1.75,
    1.289: 1.8,
    1.331: 1.85,
    1.373: 1.9,
    1.415: 1.95,
    1.457: 2.0,
    1.499: 2.05,
    1.542: 2.1,
    1.584: 2.15,
    1.627: 2.2,
    1.67: 2.25,
    1.713: 2.3,
    1.756: 2.35,
    1.799: 2.4,
    1.843: 2.45,
    1.886: 2.5,
    1.93: 2.55,
    1.974: 2.6,
    2.018: 2.65,
    2.062: 2.7,
    2.106: 2.75,
    2.151: 2.8,
    2.195: 2.85,
    2.24: 2.9,
    2.284: 2.95,
    2.329: 3.0,
    2.374: 3.05,
    2.419: 3.1,
    2.464: 3.15,
    2.51: 3.2,
    2.555: 3.25,
    2.6: 3.3,
    2.646: 3.35,
    2.691: 3.4,
    2.737: 3.45,
    2.783: 3.5,
    2.875: 3.6,
    2.967: 3.7,
    3.06: 3.8,
    3.153: 3.9,
    3.246: 4.0,
    3.339: 4.1,
    3.433: 4.2,
    3.527: 4.3,
    3.621: 4.4,
    3.716: 4.5,
    3.81: 4.6,
    3.905: 4.7,
    4.0: 4.8,
    4.096: 4.9,
    4.191: 5.0,
    4.287: 5.1,
    4.382: 5.2,
    4.478: 5.3,
    4.574: 5.4,
    4.67: 5.5,
    4.766: 5.6,
    4.863: 5.7,
    4.959: 5.8,
    5.056: 5.9,
    5.152: 6.0,
    5.249: 6.1,
    5.346: 6.2,
    5.442: 6.3,
    5.539: 6.4,
    5.636: 6.5,
    5.733: 6.6,
    5.83: 6.7,
    5.927: 6.8,
    6.024: 6.9,
    6.121: 7.0,
    6.218: 7.1,
    6.315: 7.2,
    6.413: 7.3,
    6.51: 7.4,
    6.607: 7.5,
    6.704: 7.6,
    6.801: 7.7,
    6.899: 7.8,
    6.996: 7.9,
    7.093: 8.0,
    7.58: 8.5,
    8.068: 9.0,
    8.56: 9.5,
    9.055: 10.0,
    9.551: 10.5,
    10.048: 11.0,
    10.546: 11.5,
    11.045: 12.0,
  },
  1: {
    0.0: 0.0,
    0.047: 0.1,
    0.115: 0.2,
    0.185: 0.3,
    0.255: 0.4,
    0.291: 0.45,
    0.327: 0.5,
    0.363: 0.55,
    0.399: 0.6,
    0.436: 0.65,
    0.473: 0.7,
    0.51: 0.75,
    0.547: 0.8,
    0.585: 0.85,
    0.623: 0.9,
    0.661: 0.95,
    0.699: 1.0,
    0.738: 1.05,
    0.776: 1.1,
    0.815: 1.15,
    0.854: 1.2,
    0.894: 1.25,
    0.933: 1.3,
    0.973: 1.35,
    1.013: 1.4,
    1.053: 1.45,
    1.094: 1.5,
    1.134: 1.55,
    1.175: 1.6,
    1.216: 1.65,
    1.257: 1.7,
    1.298: 1.75,
    1.339: 1.8,
    1.381: 1.85,
    1.423: 1.9,
    1.465: 1.95,
    1.507: 2.0,
    1.549: 2.05,
    1.592: 2.1,
    1.634: 2.15,
    1.677: 2.2,
    1.72: 2.25,
    1.763: 2.3,
    1.806: 2.35,
    1.849: 2.4,
    1.893: 2.45,
    1.936: 2.5,
    1.98: 2.55,
    2.024: 2.6,
    2.068: 2.65,
    2.112: 2.7,
    2.156: 2.75,
    2.201: 2.8,
    2.245: 2.85,
    2.29: 2.9,
    2.334: 2.95,
    2.379: 3.0,
    2.424: 3.05,
    2.469: 3.1,
    2.514: 3.15,
    2.56: 3.2,
    2.605: 3.25,
    2.65: 3.3,
    2.696: 3.35,
    2.741: 3.4,
    2.787: 3.45,
    2.833: 3.5,
    2.925: 3.6,
    3.017: 3.7,
    3.11: 3.8,
    3.203: 3.9,
    3.296: 4.0,
    3.389: 4.1,
    3.483: 4.2,
    3.577: 4.3,
    3.671: 4.4,
    3.766: 4.5,
    3.86: 4.6,
    3.955: 4.7,
    4.05: 4.8,
    4.146: 4.9,
    4.241: 5.0,
    4.337: 5.1,
    4.432: 5.2,
    4.528: 5.3,
    4.624: 5.4,
    4.72: 5.5,
    4.816: 5.6,
    4.913: 5.7,
    5.009: 5.8,
    5.106: 5.9,
    5.202: 6.0,
    5.299: 6.1,
    5.396: 6.2,
    5.492: 6.3,
    5.589: 6.4,
    5.686: 6.5,
    5.783: 6.6,
    5.88: 6.7,
    5.977: 6.8,
    6.074: 6.9,
    6.171: 7.0,
    6.268: 7.1,
    6.365: 7.2,
    6.463: 7.3,
    6.56: 7.4,
    6.657: 7.5,
    6.754: 7.6,
    6.851: 7.7,
    6.949: 7.8,
    7.046: 7.9,
    7.143: 8.0,
    7.63: 8.5,
    8.118: 9.0,
    8.61: 9.5,
    9.105: 10.0,
    9.601: 10.5,
    10.098: 11.0,
    10.596: 11.5,
    11.095: 12.0,
  },
};

/*
		SELF_CONSUMPTION_PV
			{ out_during_day : {annualConsumption : {annualGeneration : selfConsumptionRatio,...}}}
*/
export const SELF_CONSUMPTION_PV = {
  0: {
    1500: {
      0: 0.9,
      300: 0.7465,
      600: 0.5918,
      900: 0.4873,
      1200: 0.4159,
      1500: 0.3647,
      1800: 0.3265,
      2100: 0.297,
      2400: 0.2735,
      2700: 0.2543,
      3000: 0.2385,
      3300: 0.2251,
      3600: 0.2104,
      3900: 0.1971,
      4200: 0.1855,
      4500: 0.1754,
      4800: 0.1664,
      5100: 0.1584,
      5400: 0.1512,
      5700: 0.1448,
    },
    2000: {
      0: 0.9,
      300: 0.809,
      600: 0.6709,
      900: 0.5648,
      1200: 0.4873,
      1500: 0.4296,
      1800: 0.3855,
      2100: 0.3508,
      2400: 0.3229,
      2700: 0.2999,
      3000: 0.2807,
      3300: 0.2645,
      3600: 0.2506,
      3900: 0.2385,
      4200: 0.2279,
      4500: 0.217,
      4800: 0.2057,
      5100: 0.1957,
      5400: 0.1867,
      5700: 0.1786,
    },
    2500: {
      0: 0.9,
      300: 0.8515,
      600: 0.7302,
      900: 0.6284,
      1200: 0.5489,
      1500: 0.4873,
      1800: 0.439,
      2100: 0.4003,
      2400: 0.3687,
      2700: 0.3426,
      3000: 0.3206,
      3300: 0.3018,
      3600: 0.2857,
      3900: 0.2716,
      4200: 0.2592,
      4500: 0.2482,
      4800: 0.2385,
      5100: 0.2297,
      5400: 0.2214,
      5700: 0.2117,
    },
    3000: {
      0: 0.9,
      300: 0.8852,
      600: 0.7749,
      900: 0.6804,
      1200: 0.6017,
      1500: 0.5383,
      1800: 0.4873,
      2100: 0.4457,
      2400: 0.4113,
      2700: 0.3825,
      3000: 0.3581,
      3300: 0.3372,
      3600: 0.319,
      3900: 0.3032,
      4200: 0.2892,
      4500: 0.2768,
      4800: 0.2657,
      5100: 0.2557,
      5400: 0.2467,
      5700: 0.2385,
    },
    3500: {
      0: 0.92,
      300: 0.9154,
      600: 0.809,
      900: 0.7228,
      1200: 0.647,
      1500: 0.5834,
      1800: 0.5309,
      2100: 0.4873,
      2400: 0.4508,
      2700: 0.4199,
      3000: 0.3935,
      3300: 0.3707,
      3600: 0.3508,
      3900: 0.3334,
      4200: 0.3179,
      4500: 0.3042,
      4800: 0.2918,
      5100: 0.2807,
      5400: 0.2707,
      5700: 0.2615,
    },
    4000: {
      0: 0.94,
      300: 0.9324,
      600: 0.8361,
      900: 0.7573,
      1200: 0.6856,
      1500: 0.6232,
      1800: 0.5702,
      2100: 0.5253,
      2400: 0.4873,
      2700: 0.4547,
      3000: 0.4267,
      3300: 0.4023,
      3600: 0.381,
      3900: 0.3622,
      4200: 0.3454,
      4500: 0.3305,
      4800: 0.3171,
      5100: 0.3049,
      5400: 0.2939,
      5700: 0.2839,
    },
    4500: {
      0: 0.95,
      300: 0.9416,
      600: 0.8587,
      900: 0.7855,
      1200: 0.7185,
      1500: 0.6581,
      1800: 0.6055,
      2100: 0.5601,
      2400: 0.521,
      2700: 0.4873,
      3000: 0.4579,
      3300: 0.4323,
      3600: 0.4097,
      3900: 0.3896,
      4200: 0.3718,
      4500: 0.3558,
      4800: 0.3413,
      5100: 0.3283,
      5400: 0.3164,
      5700: 0.3055,
    },
    5000: {
      0: 0.95,
      300: 0.9333,
      600: 0.8789,
      900: 0.809,
      1200: 0.7465,
      1500: 0.6888,
      1800: 0.6371,
      2100: 0.5918,
      2400: 0.5522,
      2700: 0.5176,
      3000: 0.4873,
      3300: 0.4606,
      3600: 0.4369,
      3900: 0.4159,
      4200: 0.397,
      4500: 0.38,
      4800: 0.3647,
      5100: 0.3508,
      5400: 0.3381,
      5700: 0.3265,
    },
    5500: {
      0: 0.9,
      300: 0.9,
      600: 0.8977,
      900: 0.8289,
      1200: 0.7705,
      1500: 0.7157,
      1800: 0.6655,
      2100: 0.6207,
      2400: 0.5809,
      2700: 0.5458,
      3000: 0.5148,
      3300: 0.4873,
      3600: 0.4628,
      3900: 0.4408,
      4200: 0.4211,
      4500: 0.4033,
      4800: 0.3872,
      5100: 0.3725,
      5400: 0.3591,
      5700: 0.3468,
    },
  },
  1: {
    1500: {
      0: 0.8983,
      300: 0.6965,
      600: 0.5418,
      900: 0.4373,
      1200: 0.3659,
      1500: 0.3147,
      1800: 0.2765,
      2100: 0.247,
      2400: 0.2235,
      2700: 0.2043,
      3000: 0.1885,
      3300: 0.1751,
      3600: 0.1637,
      3900: 0.1539,
      4200: 0.1453,
      4500: 0.1377,
      4800: 0.131,
      5100: 0.1251,
      5400: 0.1197,
      5700: 0.1149,
    },
    2000: {
      0: 0.88,
      300: 0.759,
      600: 0.6209,
      900: 0.5148,
      1200: 0.4373,
      1500: 0.3796,
      1800: 0.3355,
      2100: 0.3008,
      2400: 0.2729,
      2700: 0.2499,
      3000: 0.2307,
      3300: 0.2145,
      3600: 0.2006,
      3900: 0.1885,
      4200: 0.1779,
      4500: 0.1686,
      4800: 0.1603,
      5100: 0.1529,
      5400: 0.1462,
      5700: 0.1402,
    },
    2500: {
      0: 0.87,
      300: 0.8015,
      600: 0.6802,
      900: 0.5784,
      1200: 0.4989,
      1500: 0.4373,
      1800: 0.389,
      2100: 0.3503,
      2400: 0.3187,
      2700: 0.2926,
      3000: 0.2706,
      3300: 0.2518,
      3600: 0.2357,
      3900: 0.2216,
      4200: 0.2092,
      4500: 0.1982,
      4800: 0.1885,
      5100: 0.1797,
      5400: 0.1718,
      5700: 0.1647,
    },
    3000: {
      0: 0.86,
      300: 0.8352,
      600: 0.7249,
      900: 0.6304,
      1200: 0.5517,
      1500: 0.4883,
      1800: 0.4373,
      2100: 0.3957,
      2400: 0.3613,
      2700: 0.3325,
      3000: 0.3081,
      3300: 0.2872,
      3600: 0.269,
      3900: 0.2532,
      4200: 0.2392,
      4500: 0.2268,
      4800: 0.2157,
      5100: 0.2057,
      5400: 0.1967,
      5700: 0.1885,
    },
    3500: {
      0: 0.86,
      300: 0.8654,
      600: 0.759,
      900: 0.6728,
      1200: 0.597,
      1500: 0.5334,
      1800: 0.4809,
      2100: 0.4373,
      2400: 0.4008,
      2700: 0.3699,
      3000: 0.3435,
      3300: 0.3207,
      3600: 0.3008,
      3900: 0.2834,
      4200: 0.2679,
      4500: 0.2542,
      4800: 0.2418,
      5100: 0.2307,
      5400: 0.2207,
      5700: 0.2115,
    },
    4000: {
      0: 0.88,
      300: 0.8914,
      600: 0.7861,
      900: 0.7073,
      1200: 0.6356,
      1500: 0.5732,
      1800: 0.5202,
      2100: 0.4753,
      2400: 0.4373,
      2700: 0.4047,
      3000: 0.3767,
      3300: 0.3523,
      3600: 0.331,
      3900: 0.3122,
      4200: 0.2954,
      4500: 0.2805,
      4800: 0.2671,
      5100: 0.2549,
      5400: 0.2439,
      5700: 0.2339,
    },
    4500: {
      0: 0.9,
      300: 0.9066,
      600: 0.8087,
      900: 0.7355,
      1200: 0.6685,
      1500: 0.6081,
      1800: 0.5555,
      2100: 0.5101,
      2400: 0.471,
      2700: 0.4373,
      3000: 0.4079,
      3300: 0.3823,
      3600: 0.3597,
      3900: 0.3396,
      4200: 0.3218,
      4500: 0.3058,
      4800: 0.2913,
      5100: 0.2783,
      5400: 0.2664,
      5700: 0.2555,
    },
    5000: {
      0: 0.9,
      300: 0.8983,
      600: 0.8289,
      900: 0.759,
      1200: 0.6965,
      1500: 0.6388,
      1800: 0.5871,
      2100: 0.5418,
      2400: 0.5022,
      2700: 0.4676,
      3000: 0.4373,
      3300: 0.4106,
      3600: 0.3869,
      3900: 0.3659,
      4200: 0.347,
      4500: 0.33,
      4800: 0.3147,
      5100: 0.3008,
      5400: 0.2881,
      5700: 0.2765,
    },
    5500: {
      0: 0.9,
      300: 0.9,
      600: 0.8477,
      900: 0.7789,
      1200: 0.7205,
      1500: 0.6657,
      1800: 0.6155,
      2100: 0.5707,
      2400: 0.5309,
      2700: 0.4958,
      3000: 0.4648,
      3300: 0.4373,
      3600: 0.4128,
      3900: 0.3908,
      4200: 0.3711,
      4500: 0.3533,
      4800: 0.3372,
      5100: 0.3225,
      5400: 0.3091,
      5700: 0.2968,
    },
  },
};
/*
		SELF_CONSUMPTION_BATTERY
    // THIS COMMENT IS WRONG!!
			{out_during_day : { batterySize : {annualGeneration : selfConsumptionRatio,...}}
    
      // THE DATA IS ACTUALLY STORED AS
      outDuringDay: 0|1
      annualElectricityConsumption:  1500/2000/2500 etc, clamped into groups of 500 kWh
      batterySize: 0.1, 1.1, 2.1, 3.1 kWh (Storable capacity) from column headings
      selfConsumption: 300/600/900/1200/1500 etc, clamped into groups of 300 kWh
      selfConsumptionRatio: mapped by column and row

*/
export const SELF_CONSUMPTION_BATTERY = {
  0: {
    1500: {
      0.1: {
        0: 0.965,
        300: 0.8754,
        600: 0.7387,
        900: 0.6255,
        1200: 0.5412,
        1500: 0.4777,
        1800: 0.4287,
        2100: 0.3901,
        2400: 0.3588,
        2700: 0.3329,
        3000: 0.3114,
        3300: 0.293,
        3600: 0.2739,
        3900: 0.2567,
        4200: 0.2417,
        4500: 0.2286,
        4800: 0.2168,
        5100: 0.2063,
        5400: 0.1969,
        5700: 0.1884,
      },
      1.1: {
        0: 0.965,
        300: 0.9032,
        600: 0.7762,
        900: 0.663,
        1200: 0.5764,
        1500: 0.5101,
        1800: 0.4585,
        2100: 0.4175,
        2400: 0.3841,
        2700: 0.3564,
        3000: 0.3333,
        3300: 0.3135,
        3600: 0.2931,
        3900: 0.2749,
        4200: 0.2588,
        4500: 0.2448,
        4800: 0.2322,
        5100: 0.221,
        5400: 0.2109,
        5700: 0.2018,
      },
      2.1: {
        0: 0.965,
        300: 0.926,
        600: 0.8098,
        900: 0.6979,
        1200: 0.6097,
        1500: 0.5411,
        1800: 0.4872,
        2100: 0.4441,
        2400: 0.4088,
        2700: 0.3793,
        3000: 0.3547,
        3300: 0.3335,
        3600: 0.312,
        3900: 0.2927,
        4200: 0.2757,
        4500: 0.2608,
        4800: 0.2474,
        5100: 0.2355,
        5400: 0.2247,
        5700: 0.2151,
      },
      3.1: {
        0: 0.965,
        300: 0.9407,
        600: 0.8346,
        900: 0.7248,
        1200: 0.636,
        1500: 0.5659,
        1800: 0.5104,
        2100: 0.4656,
        2400: 0.4289,
        2700: 0.3981,
        3000: 0.3723,
        3300: 0.3501,
        3600: 0.3276,
        3900: 0.3074,
        4200: 0.2896,
        4500: 0.274,
        4800: 0.26,
        5100: 0.2475,
        5400: 0.2362,
        5700: 0.2261,
      },
      4.1: {
        0: 0.965,
        300: 0.9521,
        600: 0.8546,
        900: 0.7468,
        1200: 0.6575,
        1500: 0.5864,
        1800: 0.5296,
        2100: 0.4835,
        2400: 0.4456,
        2700: 0.4138,
        3000: 0.3869,
        3300: 0.3638,
        3600: 0.3406,
        3900: 0.3197,
        4200: 0.3013,
        4500: 0.2851,
        4800: 0.2706,
        5100: 0.2576,
        5400: 0.2459,
        5700: 0.2353,
      },
      5.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8699,
        900: 0.7642,
        1200: 0.6749,
        1500: 0.603,
        1800: 0.5452,
        2100: 0.4982,
        2400: 0.4593,
        2700: 0.4266,
        3000: 0.399,
        3300: 0.3752,
        3600: 0.3513,
        3900: 0.3299,
        4200: 0.3109,
        4500: 0.2943,
        4800: 0.2794,
        5100: 0.266,
        5400: 0.2539,
        5700: 0.243,
      },
      6.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8804,
        900: 0.7763,
        1200: 0.687,
        1500: 0.6147,
        1800: 0.5562,
        2100: 0.5085,
        2400: 0.469,
        2700: 0.4357,
        3000: 0.4076,
        3300: 0.3833,
        3600: 0.359,
        3900: 0.3371,
        4200: 0.3178,
        4500: 0.3008,
        4800: 0.2856,
        5100: 0.2719,
        5400: 0.2596,
        5700: 0.2485,
      },
      7.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8893,
        900: 0.7862,
        1200: 0.6969,
        1500: 0.6241,
        1800: 0.5651,
        2100: 0.5169,
        2400: 0.4768,
        2700: 0.4431,
        3000: 0.4145,
        3300: 0.3898,
        3600: 0.3651,
        3900: 0.3429,
        4200: 0.3233,
        4500: 0.3061,
        4800: 0.2906,
        5100: 0.2767,
        5400: 0.2642,
        5700: 0.2529,
      },
      8.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8893,
        900: 0.7862,
        1200: 0.6969,
        1500: 0.6241,
        1800: 0.5651,
        2100: 0.5169,
        2400: 0.4768,
        2700: 0.4431,
        3000: 0.4145,
        3300: 0.3898,
        3600: 0.3651,
        3900: 0.3429,
        4200: 0.3233,
        4500: 0.3061,
        4800: 0.2906,
        5100: 0.2767,
        5400: 0.2642,
        5700: 0.2529,
      },
      9.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8893,
        900: 0.7862,
        1200: 0.6969,
        1500: 0.6241,
        1800: 0.5651,
        2100: 0.5169,
        2400: 0.4768,
        2700: 0.4431,
        3000: 0.4145,
        3300: 0.3898,
        3600: 0.3651,
        3900: 0.3429,
        4200: 0.3233,
        4500: 0.3061,
        4800: 0.2906,
        5100: 0.2767,
        5400: 0.2642,
        5700: 0.2529,
      },
      10.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8893,
        900: 0.7862,
        1200: 0.6969,
        1500: 0.6241,
        1800: 0.5651,
        2100: 0.5169,
        2400: 0.4768,
        2700: 0.4431,
        3000: 0.4145,
        3300: 0.3898,
        3600: 0.3651,
        3900: 0.3429,
        4200: 0.3233,
        4500: 0.3061,
        4800: 0.2906,
        5100: 0.2767,
        5400: 0.2642,
        5700: 0.2529,
      },
      11.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8893,
        900: 0.7862,
        1200: 0.6969,
        1500: 0.6241,
        1800: 0.5651,
        2100: 0.5169,
        2400: 0.4768,
        2700: 0.4431,
        3000: 0.4145,
        3300: 0.3898,
        3600: 0.3651,
        3900: 0.3429,
        4200: 0.3233,
        4500: 0.3061,
        4800: 0.2906,
        5100: 0.2767,
        5400: 0.2642,
        5700: 0.2529,
      },
      12.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8893,
        900: 0.7862,
        1200: 0.6969,
        1500: 0.6241,
        1800: 0.5651,
        2100: 0.5169,
        2400: 0.4768,
        2700: 0.4431,
        3000: 0.4145,
        3300: 0.3898,
        3600: 0.3651,
        3900: 0.3429,
        4200: 0.3233,
        4500: 0.3061,
        4800: 0.2906,
        5100: 0.2767,
        5400: 0.2642,
        5700: 0.2529,
      },
      13.1: {
        0: 0.965,
        300: 0.965,
        600: 0.8893,
        900: 0.7862,
        1200: 0.6969,
        1500: 0.6241,
        1800: 0.5651,
        2100: 0.5169,
        2400: 0.4768,
        2700: 0.4431,
        3000: 0.4145,
        3300: 0.3898,
        3600: 0.3651,
        3900: 0.3429,
        4200: 0.3233,
        4500: 0.3061,
        4800: 0.2906,
        5100: 0.2767,
        5400: 0.2642,
        5700: 0.2529,
      },
    },
    2000: {
      0.1: {
        0: 0.965,
        300: 0.9137,
        600: 0.8144,
        900: 0.7108,
        1200: 0.6255,
        1500: 0.5577,
        1800: 0.5037,
        2100: 0.46,
        2400: 0.424,
        2700: 0.3939,
        3000: 0.3685,
        3300: 0.3467,
        3600: 0.3279,
        3900: 0.3114,
        4200: 0.2968,
        4500: 0.2824,
        4800: 0.2679,
        5100: 0.2549,
        5400: 0.2433,
        5700: 0.2327,
      },
      1.1: {
        0: 0.965,
        300: 0.9335,
        600: 0.8485,
        900: 0.7487,
        1200: 0.663,
        1500: 0.5936,
        1800: 0.5374,
        2100: 0.4915,
        2400: 0.4536,
        2700: 0.4216,
        3000: 0.3945,
        3300: 0.3712,
        3600: 0.351,
        3900: 0.3333,
        4200: 0.3176,
        4500: 0.3022,
        4800: 0.2867,
        5100: 0.2729,
        5400: 0.2605,
        5700: 0.2493,
      },
      2.1: {
        0: 0.965,
        300: 0.9484,
        600: 0.878,
        900: 0.783,
        1200: 0.6979,
        1500: 0.6273,
        1800: 0.5695,
        2100: 0.5218,
        2400: 0.482,
        2700: 0.4484,
        3000: 0.4197,
        3300: 0.395,
        3600: 0.3736,
        3900: 0.3547,
        4200: 0.338,
        4500: 0.3215,
        4800: 0.3052,
        5100: 0.2906,
        5400: 0.2774,
        5700: 0.2655,
      },
      3.1: {
        0: 0.965,
        300: 0.9568,
        600: 0.8988,
        900: 0.8087,
        1200: 0.7248,
        1500: 0.6538,
        1800: 0.595,
        2100: 0.546,
        2400: 0.505,
        2700: 0.4701,
        3000: 0.4403,
        3300: 0.4145,
        3600: 0.3921,
        3900: 0.3723,
        4200: 0.3547,
        4500: 0.3375,
        4800: 0.3205,
        5100: 0.3053,
        5400: 0.2915,
        5700: 0.279,
      },
      4.1: {
        0: 0.965,
        300: 0.9631,
        600: 0.9152,
        900: 0.8295,
        1200: 0.7468,
        1500: 0.6756,
        1800: 0.616,
        2100: 0.5661,
        2400: 0.524,
        2700: 0.4882,
        3000: 0.4574,
        3300: 0.4308,
        3600: 0.4075,
        3900: 0.3869,
        4200: 0.3687,
        4500: 0.3509,
        4800: 0.3333,
        5100: 0.3175,
        5400: 0.3032,
        5700: 0.2903,
      },
      5.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9275,
        900: 0.8456,
        1200: 0.7642,
        1500: 0.693,
        1800: 0.633,
        2100: 0.5824,
        2400: 0.5395,
        2700: 0.5029,
        3000: 0.4714,
        3300: 0.4441,
        3600: 0.4202,
        3900: 0.399,
        4200: 0.3802,
        4500: 0.3619,
        4800: 0.3438,
        5100: 0.3276,
        5400: 0.3129,
        5700: 0.2996,
      },
      6.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9357,
        900: 0.8567,
        1200: 0.7763,
        1500: 0.7053,
        1800: 0.6449,
        2100: 0.5938,
        2400: 0.5505,
        2700: 0.5134,
        3000: 0.4813,
        3300: 0.4535,
        3600: 0.4292,
        3900: 0.4076,
        4200: 0.3884,
        4500: 0.3697,
        4800: 0.3513,
        5100: 0.3348,
        5400: 0.3198,
        5700: 0.3063,
      },
      7.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9427,
        900: 0.866,
        1200: 0.7862,
        1500: 0.7152,
        1800: 0.6546,
        2100: 0.6031,
        2400: 0.5593,
        2700: 0.5218,
        3000: 0.4893,
        3300: 0.4611,
        3600: 0.4364,
        3900: 0.4145,
        4200: 0.395,
        4500: 0.376,
        4800: 0.3573,
        5100: 0.3406,
        5400: 0.3254,
        5700: 0.3116,
      },
      8.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9427,
        900: 0.866,
        1200: 0.7862,
        1500: 0.7152,
        1800: 0.6546,
        2100: 0.6031,
        2400: 0.5593,
        2700: 0.5218,
        3000: 0.4893,
        3300: 0.4611,
        3600: 0.4364,
        3900: 0.4145,
        4200: 0.395,
        4500: 0.376,
        4800: 0.3573,
        5100: 0.3406,
        5400: 0.3254,
        5700: 0.3116,
      },
      9.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9427,
        900: 0.866,
        1200: 0.7862,
        1500: 0.7152,
        1800: 0.6546,
        2100: 0.6031,
        2400: 0.5593,
        2700: 0.5218,
        3000: 0.4893,
        3300: 0.4611,
        3600: 0.4364,
        3900: 0.4145,
        4200: 0.395,
        4500: 0.376,
        4800: 0.3573,
        5100: 0.3406,
        5400: 0.3254,
        5700: 0.3116,
      },
      10.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9427,
        900: 0.866,
        1200: 0.7862,
        1500: 0.7152,
        1800: 0.6546,
        2100: 0.6031,
        2400: 0.5593,
        2700: 0.5218,
        3000: 0.4893,
        3300: 0.4611,
        3600: 0.4364,
        3900: 0.4145,
        4200: 0.395,
        4500: 0.376,
        4800: 0.3573,
        5100: 0.3406,
        5400: 0.3254,
        5700: 0.3116,
      },
      11.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9427,
        900: 0.866,
        1200: 0.7862,
        1500: 0.7152,
        1800: 0.6546,
        2100: 0.6031,
        2400: 0.5593,
        2700: 0.5218,
        3000: 0.4893,
        3300: 0.4611,
        3600: 0.4364,
        3900: 0.4145,
        4200: 0.395,
        4500: 0.376,
        4800: 0.3573,
        5100: 0.3406,
        5400: 0.3254,
        5700: 0.3116,
      },
      12.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9427,
        900: 0.866,
        1200: 0.7862,
        1500: 0.7152,
        1800: 0.6546,
        2100: 0.6031,
        2400: 0.5593,
        2700: 0.5218,
        3000: 0.4893,
        3300: 0.4611,
        3600: 0.4364,
        3900: 0.4145,
        4200: 0.395,
        4500: 0.376,
        4800: 0.3573,
        5100: 0.3406,
        5400: 0.3254,
        5700: 0.3116,
      },
      13.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9427,
        900: 0.866,
        1200: 0.7862,
        1500: 0.7152,
        1800: 0.6546,
        2100: 0.6031,
        2400: 0.5593,
        2700: 0.5218,
        3000: 0.4893,
        3300: 0.4611,
        3600: 0.4364,
        3900: 0.4145,
        4200: 0.395,
        4500: 0.376,
        4800: 0.3573,
        5100: 0.3406,
        5400: 0.3254,
        5700: 0.3116,
      },
    },
    2500: {
      0.1: {
        0: 0.965,
        300: 0.9324,
        600: 0.8634,
        900: 0.775,
        1200: 0.6939,
        1500: 0.6255,
        1800: 0.569,
        2100: 0.5221,
        2400: 0.4827,
        2700: 0.4495,
        3000: 0.4211,
        3300: 0.3964,
        3600: 0.3751,
        3900: 0.3563,
        4200: 0.3396,
        4500: 0.3246,
        4800: 0.3114,
        5100: 0.2993,
        5400: 0.288,
        5700: 0.2756,
      },
      1.1: {
        0: 0.965,
        300: 0.9456,
        600: 0.8928,
        900: 0.8112,
        1200: 0.7319,
        1500: 0.663,
        1800: 0.6052,
        2100: 0.5565,
        2400: 0.5154,
        2700: 0.4805,
        3000: 0.4504,
        3300: 0.4243,
        3600: 0.4015,
        3900: 0.3814,
        4200: 0.3635,
        4500: 0.3475,
        4800: 0.3333,
        5100: 0.3203,
        5400: 0.3082,
        5700: 0.2949,
      },
      2.1: {
        0: 0.965,
        300: 0.9545,
        600: 0.9173,
        900: 0.8433,
        1200: 0.7665,
        1500: 0.6979,
        1800: 0.6392,
        2100: 0.5892,
        2400: 0.5466,
        2700: 0.5102,
        3000: 0.4787,
        3300: 0.4512,
        3600: 0.4272,
        3900: 0.4059,
        4200: 0.3869,
        4500: 0.3699,
        4800: 0.3547,
        5100: 0.3408,
        5400: 0.3279,
        5700: 0.3139,
      },
      3.1: {
        0: 0.965,
        300: 0.9584,
        600: 0.9335,
        900: 0.8665,
        1200: 0.7927,
        1500: 0.7248,
        1800: 0.6659,
        2100: 0.6152,
        2400: 0.5716,
        2700: 0.5341,
        3000: 0.5016,
        3300: 0.4731,
        3600: 0.4481,
        3900: 0.4259,
        4200: 0.406,
        4500: 0.3882,
        4800: 0.3723,
        5100: 0.3577,
        5400: 0.3441,
        5700: 0.3296,
      },
      4.1: {
        0: 0.965,
        300: 0.9612,
        600: 0.9462,
        900: 0.8851,
        1200: 0.8138,
        1500: 0.7468,
        1800: 0.6877,
        2100: 0.6365,
        2400: 0.5922,
        2700: 0.5539,
        3000: 0.5205,
        3300: 0.4912,
        3600: 0.4654,
        3900: 0.4425,
        4200: 0.4219,
        4500: 0.4035,
        4800: 0.3869,
        5100: 0.3718,
        5400: 0.3577,
        5700: 0.3426,
      },
      5.1: {
        0: 0.965,
        300: 0.9625,
        600: 0.9552,
        900: 0.8992,
        1200: 0.8303,
        1500: 0.7642,
        1800: 0.7052,
        2100: 0.6537,
        2400: 0.6088,
        2700: 0.57,
        3000: 0.5359,
        3300: 0.506,
        3600: 0.4796,
        3900: 0.4561,
        4200: 0.435,
        4500: 0.416,
        4800: 0.399,
        5100: 0.3834,
        5400: 0.3689,
        5700: 0.3534,
      },
      6.1: {
        0: 0.965,
        300: 0.9631,
        600: 0.9611,
        900: 0.9087,
        1200: 0.8417,
        1500: 0.7763,
        1800: 0.7174,
        2100: 0.6657,
        2400: 0.6206,
        2700: 0.5813,
        3000: 0.5469,
        3300: 0.5165,
        3600: 0.4897,
        3900: 0.4658,
        4200: 0.4443,
        4500: 0.425,
        4800: 0.4076,
        5100: 0.3917,
        5400: 0.3769,
        5700: 0.3611,
      },
      7.1: {
        0: 0.965,
        300: 0.9641,
        600: 0.965,
        900: 0.9168,
        1200: 0.8511,
        1500: 0.7862,
        1800: 0.7274,
        2100: 0.6755,
        2400: 0.6301,
        2700: 0.5905,
        3000: 0.5557,
        3300: 0.5249,
        3600: 0.4978,
        3900: 0.4735,
        4200: 0.4518,
        4500: 0.4321,
        4800: 0.4145,
        5100: 0.3983,
        5400: 0.3833,
        5700: 0.3673,
      },
      8.1: {
        0: 0.965,
        300: 0.9641,
        600: 0.965,
        900: 0.9168,
        1200: 0.8511,
        1500: 0.7862,
        1800: 0.7274,
        2100: 0.6755,
        2400: 0.6301,
        2700: 0.5905,
        3000: 0.5557,
        3300: 0.5249,
        3600: 0.4978,
        3900: 0.4735,
        4200: 0.4518,
        4500: 0.4321,
        4800: 0.4145,
        5100: 0.3983,
        5400: 0.3833,
        5700: 0.3673,
      },
      9.1: {
        0: 0.965,
        300: 0.9641,
        600: 0.965,
        900: 0.9168,
        1200: 0.8511,
        1500: 0.7862,
        1800: 0.7274,
        2100: 0.6755,
        2400: 0.6301,
        2700: 0.5905,
        3000: 0.5557,
        3300: 0.5249,
        3600: 0.4978,
        3900: 0.4735,
        4200: 0.4518,
        4500: 0.4321,
        4800: 0.4145,
        5100: 0.3983,
        5400: 0.3833,
        5700: 0.3673,
      },
      10.1: {
        0: 0.965,
        300: 0.9641,
        600: 0.965,
        900: 0.9168,
        1200: 0.8511,
        1500: 0.7862,
        1800: 0.7274,
        2100: 0.6755,
        2400: 0.6301,
        2700: 0.5905,
        3000: 0.5557,
        3300: 0.5249,
        3600: 0.4978,
        3900: 0.4735,
        4200: 0.4518,
        4500: 0.4321,
        4800: 0.4145,
        5100: 0.3983,
        5400: 0.3833,
        5700: 0.3673,
      },
      11.1: {
        0: 0.965,
        300: 0.9641,
        600: 0.965,
        900: 0.9168,
        1200: 0.8511,
        1500: 0.7862,
        1800: 0.7274,
        2100: 0.6755,
        2400: 0.6301,
        2700: 0.5905,
        3000: 0.5557,
        3300: 0.5249,
        3600: 0.4978,
        3900: 0.4735,
        4200: 0.4518,
        4500: 0.4321,
        4800: 0.4145,
        5100: 0.3983,
        5400: 0.3833,
        5700: 0.3673,
      },
      12.1: {
        0: 0.965,
        300: 0.9641,
        600: 0.965,
        900: 0.9168,
        1200: 0.8511,
        1500: 0.7862,
        1800: 0.7274,
        2100: 0.6755,
        2400: 0.6301,
        2700: 0.5905,
        3000: 0.5557,
        3300: 0.5249,
        3600: 0.4978,
        3900: 0.4735,
        4200: 0.4518,
        4500: 0.4321,
        4800: 0.4145,
        5100: 0.3983,
        5400: 0.3833,
        5700: 0.3673,
      },
      13.1: {
        0: 0.965,
        300: 0.9641,
        600: 0.965,
        900: 0.9168,
        1200: 0.8511,
        1500: 0.7862,
        1800: 0.7274,
        2100: 0.6755,
        2400: 0.6301,
        2700: 0.5905,
        3000: 0.5557,
        3300: 0.5249,
        3600: 0.4978,
        3900: 0.4735,
        4200: 0.4518,
        4500: 0.4321,
        4800: 0.4145,
        5100: 0.3983,
        5400: 0.3833,
        5700: 0.3673,
      },
    },
    3000: {
      0.1: {
        0: 0.965,
        300: 0.9456,
        600: 0.8944,
        900: 0.8227,
        1200: 0.7487,
        1500: 0.6824,
        1800: 0.6255,
        2100: 0.577,
        2400: 0.5356,
        2700: 0.5,
        3000: 0.4693,
        3300: 0.4426,
        3600: 0.419,
        3900: 0.3983,
        4200: 0.3798,
        4500: 0.3632,
        4800: 0.3483,
        5100: 0.3348,
        5400: 0.3226,
        5700: 0.3114,
      },
      1.1: {
        0: 0.965,
        300: 0.9539,
        600: 0.9189,
        900: 0.8562,
        1200: 0.7859,
        1500: 0.7205,
        1800: 0.663,
        2100: 0.6134,
        2400: 0.5706,
        2700: 0.5335,
        3000: 0.5013,
        3300: 0.4731,
        3600: 0.4482,
        3900: 0.4262,
        4200: 0.4065,
        4500: 0.3889,
        4800: 0.3729,
        5100: 0.3584,
        5400: 0.3453,
        5700: 0.3333,
      },
      2.1: {
        0: 0.965,
        300: 0.9588,
        600: 0.9384,
        900: 0.8851,
        1200: 0.8192,
        1500: 0.7553,
        1800: 0.6979,
        2100: 0.6476,
        2400: 0.6037,
        2700: 0.5654,
        3000: 0.532,
        3300: 0.5025,
        3600: 0.4764,
        3900: 0.4532,
        4200: 0.4324,
        4500: 0.4138,
        4800: 0.3969,
        5100: 0.3815,
        5400: 0.3675,
        5700: 0.3547,
      },
      3.1: {
        0: 0.965,
        300: 0.96,
        600: 0.9503,
        900: 0.9052,
        1200: 0.8436,
        1500: 0.7816,
        1800: 0.7248,
        2100: 0.6743,
        2400: 0.6299,
        2700: 0.5909,
        3000: 0.5565,
        3300: 0.5262,
        3600: 0.4992,
        3900: 0.4752,
        4200: 0.4536,
        4500: 0.4341,
        4800: 0.4164,
        5100: 0.4004,
        5400: 0.3857,
        5700: 0.3723,
      },
      4.1: {
        0: 0.965,
        300: 0.9608,
        600: 0.9595,
        900: 0.9211,
        1200: 0.8632,
        1500: 0.803,
        1800: 0.7468,
        2100: 0.6962,
        2400: 0.6514,
        2700: 0.6118,
        3000: 0.5768,
        3300: 0.5458,
        3600: 0.5181,
        3900: 0.4934,
        4200: 0.4711,
        4500: 0.451,
        4800: 0.4327,
        5100: 0.4161,
        5400: 0.4009,
        5700: 0.3869,
      },
      5.1: {
        0: 0.965,
        300: 0.9606,
        600: 0.965,
        900: 0.9329,
        1200: 0.8783,
        1500: 0.8197,
        1800: 0.7642,
        2100: 0.7138,
        2400: 0.6687,
        2700: 0.6287,
        3000: 0.5933,
        3300: 0.5617,
        3600: 0.5335,
        3900: 0.5082,
        4200: 0.4854,
        4500: 0.4648,
        4800: 0.4461,
        5100: 0.429,
        5400: 0.4134,
        5700: 0.399,
      },
      6.1: {
        0: 0.965,
        300: 0.9603,
        600: 0.965,
        900: 0.9407,
        1200: 0.8885,
        1500: 0.8312,
        1800: 0.7763,
        2100: 0.726,
        2400: 0.6808,
        2700: 0.6406,
        3000: 0.6048,
        3300: 0.573,
        3600: 0.5443,
        3900: 0.5187,
        4200: 0.4956,
        4500: 0.4746,
        4800: 0.4556,
        5100: 0.4382,
        5400: 0.4223,
        5700: 0.4076,
      },
      7.1: {
        0: 0.965,
        300: 0.9605,
        600: 0.965,
        900: 0.9475,
        1200: 0.8972,
        1500: 0.8408,
        1800: 0.7862,
        2100: 0.736,
        2400: 0.6907,
        2700: 0.6503,
        3000: 0.6142,
        3300: 0.582,
        3600: 0.5531,
        3900: 0.5272,
        4200: 0.5038,
        4500: 0.4825,
        4800: 0.4632,
        5100: 0.4455,
        5400: 0.4294,
        5700: 0.4145,
      },
      8.1: {
        0: 0.965,
        300: 0.9605,
        600: 0.965,
        900: 0.9475,
        1200: 0.8972,
        1500: 0.8408,
        1800: 0.7862,
        2100: 0.736,
        2400: 0.6907,
        2700: 0.6503,
        3000: 0.6142,
        3300: 0.582,
        3600: 0.5531,
        3900: 0.5272,
        4200: 0.5038,
        4500: 0.4825,
        4800: 0.4632,
        5100: 0.4455,
        5400: 0.4294,
        5700: 0.4145,
      },
      9.1: {
        0: 0.965,
        300: 0.9605,
        600: 0.965,
        900: 0.9475,
        1200: 0.8972,
        1500: 0.8408,
        1800: 0.7862,
        2100: 0.736,
        2400: 0.6907,
        2700: 0.6503,
        3000: 0.6142,
        3300: 0.582,
        3600: 0.5531,
        3900: 0.5272,
        4200: 0.5038,
        4500: 0.4825,
        4800: 0.4632,
        5100: 0.4455,
        5400: 0.4294,
        5700: 0.4145,
      },
      10.1: {
        0: 0.965,
        300: 0.9605,
        600: 0.965,
        900: 0.9475,
        1200: 0.8972,
        1500: 0.8408,
        1800: 0.7862,
        2100: 0.736,
        2400: 0.6907,
        2700: 0.6503,
        3000: 0.6142,
        3300: 0.582,
        3600: 0.5531,
        3900: 0.5272,
        4200: 0.5038,
        4500: 0.4825,
        4800: 0.4632,
        5100: 0.4455,
        5400: 0.4294,
        5700: 0.4145,
      },
      11.1: {
        0: 0.965,
        300: 0.9605,
        600: 0.965,
        900: 0.9475,
        1200: 0.8972,
        1500: 0.8408,
        1800: 0.7862,
        2100: 0.736,
        2400: 0.6907,
        2700: 0.6503,
        3000: 0.6142,
        3300: 0.582,
        3600: 0.5531,
        3900: 0.5272,
        4200: 0.5038,
        4500: 0.4825,
        4800: 0.4632,
        5100: 0.4455,
        5400: 0.4294,
        5700: 0.4145,
      },
      12.1: {
        0: 0.965,
        300: 0.9605,
        600: 0.965,
        900: 0.9475,
        1200: 0.8972,
        1500: 0.8408,
        1800: 0.7862,
        2100: 0.736,
        2400: 0.6907,
        2700: 0.6503,
        3000: 0.6142,
        3300: 0.582,
        3600: 0.5531,
        3900: 0.5272,
        4200: 0.5038,
        4500: 0.4825,
        4800: 0.4632,
        5100: 0.4455,
        5400: 0.4294,
        5700: 0.4145,
      },
      13.1: {
        0: 0.965,
        300: 0.9605,
        600: 0.965,
        900: 0.9475,
        1200: 0.8972,
        1500: 0.8408,
        1800: 0.7862,
        2100: 0.736,
        2400: 0.6907,
        2700: 0.6503,
        3000: 0.6142,
        3300: 0.582,
        3600: 0.5531,
        3900: 0.5272,
        4200: 0.5038,
        4500: 0.4825,
        4800: 0.4632,
        5100: 0.4455,
        5400: 0.4294,
        5700: 0.4145,
      },
    },
    3500: {
      0.1: {
        0: 0.965,
        300: 0.9595,
        600: 0.9137,
        900: 0.8577,
        1200: 0.7926,
        1500: 0.7302,
        1800: 0.6744,
        2100: 0.6255,
        2400: 0.583,
        2700: 0.546,
        3000: 0.5137,
        3300: 0.4852,
        3600: 0.46,
        3900: 0.4376,
        4200: 0.4175,
        4500: 0.3996,
        4800: 0.3832,
        5100: 0.3685,
        5400: 0.3551,
        5700: 0.3427,
      },
      1.1: {
        0: 0.965,
        300: 0.9644,
        600: 0.9335,
        900: 0.8879,
        1200: 0.828,
        1500: 0.7677,
        1800: 0.7124,
        2100: 0.663,
        2400: 0.6196,
        2700: 0.5814,
        3000: 0.5478,
        3300: 0.518,
        3600: 0.4915,
        3900: 0.468,
        4200: 0.4467,
        4500: 0.4276,
        4800: 0.4102,
        5100: 0.3945,
        5400: 0.3801,
        5700: 0.3669,
      },
      2.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9484,
        900: 0.9131,
        1200: 0.8591,
        1500: 0.8016,
        1800: 0.7473,
        2100: 0.6979,
        2400: 0.6539,
        2700: 0.6148,
        3000: 0.5802,
        3300: 0.5493,
        3600: 0.5218,
        3900: 0.4971,
        4200: 0.4748,
        4500: 0.4547,
        4800: 0.4364,
        5100: 0.4197,
        5400: 0.4045,
        5700: 0.3905,
      },
      3.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9568,
        900: 0.93,
        1200: 0.8813,
        1500: 0.8267,
        1800: 0.7737,
        2100: 0.7248,
        2400: 0.6807,
        2700: 0.6412,
        3000: 0.6059,
        3300: 0.5744,
        3600: 0.546,
        3900: 0.5206,
        4200: 0.4975,
        4500: 0.4767,
        4800: 0.4576,
        5100: 0.4403,
        5400: 0.4244,
        5700: 0.4098,
      },
      4.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9631,
        900: 0.9431,
        1200: 0.899,
        1500: 0.847,
        1800: 0.7952,
        2100: 0.7468,
        2400: 0.7027,
        2700: 0.6629,
        3000: 0.6271,
        3300: 0.595,
        3600: 0.5661,
        3900: 0.54,
        4200: 0.5164,
        4500: 0.4949,
        4800: 0.4753,
        5100: 0.4574,
        5400: 0.441,
        5700: 0.4258,
      },
      5.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9526,
        1200: 0.9124,
        1500: 0.8626,
        1800: 0.8121,
        2100: 0.7642,
        2400: 0.7202,
        2700: 0.6802,
        3000: 0.6442,
        3300: 0.6117,
        3600: 0.5824,
        3900: 0.5559,
        4200: 0.5317,
        4500: 0.5098,
        4800: 0.4897,
        5100: 0.4714,
        5400: 0.4546,
        5700: 0.439,
      },
      6.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9588,
        1200: 0.9213,
        1500: 0.8733,
        1800: 0.8237,
        2100: 0.7763,
        2400: 0.7324,
        2700: 0.6924,
        3000: 0.6562,
        3300: 0.6235,
        3600: 0.5938,
        3900: 0.567,
        4200: 0.5426,
        4500: 0.5204,
        4800: 0.5,
        5100: 0.4813,
        5400: 0.4642,
        5700: 0.4484,
      },
      7.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9643,
        1200: 0.929,
        1500: 0.8823,
        1800: 0.8334,
        2100: 0.7862,
        2400: 0.7424,
        2700: 0.7023,
        3000: 0.666,
        3300: 0.633,
        3600: 0.6031,
        3900: 0.576,
        4200: 0.5513,
        4500: 0.5289,
        4800: 0.5082,
        5100: 0.4893,
        5400: 0.472,
        5700: 0.4559,
      },
      8.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9643,
        1200: 0.929,
        1500: 0.8823,
        1800: 0.8334,
        2100: 0.7862,
        2400: 0.7424,
        2700: 0.7023,
        3000: 0.666,
        3300: 0.633,
        3600: 0.6031,
        3900: 0.576,
        4200: 0.5513,
        4500: 0.5289,
        4800: 0.5082,
        5100: 0.4893,
        5400: 0.472,
        5700: 0.4559,
      },
      9.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9643,
        1200: 0.929,
        1500: 0.8823,
        1800: 0.8334,
        2100: 0.7862,
        2400: 0.7424,
        2700: 0.7023,
        3000: 0.666,
        3300: 0.633,
        3600: 0.6031,
        3900: 0.576,
        4200: 0.5513,
        4500: 0.5289,
        4800: 0.5082,
        5100: 0.4893,
        5400: 0.472,
        5700: 0.4559,
      },
      10.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9643,
        1200: 0.929,
        1500: 0.8823,
        1800: 0.8334,
        2100: 0.7862,
        2400: 0.7424,
        2700: 0.7023,
        3000: 0.666,
        3300: 0.633,
        3600: 0.6031,
        3900: 0.576,
        4200: 0.5513,
        4500: 0.5289,
        4800: 0.5082,
        5100: 0.4893,
        5400: 0.472,
        5700: 0.4559,
      },
      11.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9643,
        1200: 0.929,
        1500: 0.8823,
        1800: 0.8334,
        2100: 0.7862,
        2400: 0.7424,
        2700: 0.7023,
        3000: 0.666,
        3300: 0.633,
        3600: 0.6031,
        3900: 0.576,
        4200: 0.5513,
        4500: 0.5289,
        4800: 0.5082,
        5100: 0.4893,
        5400: 0.472,
        5700: 0.4559,
      },
      12.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9643,
        1200: 0.929,
        1500: 0.8823,
        1800: 0.8334,
        2100: 0.7862,
        2400: 0.7424,
        2700: 0.7023,
        3000: 0.666,
        3300: 0.633,
        3600: 0.6031,
        3900: 0.576,
        4200: 0.5513,
        4500: 0.5289,
        4800: 0.5082,
        5100: 0.4893,
        5400: 0.472,
        5700: 0.4559,
      },
      13.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9643,
        1200: 0.929,
        1500: 0.8823,
        1800: 0.8334,
        2100: 0.7862,
        2400: 0.7424,
        2700: 0.7023,
        3000: 0.666,
        3300: 0.633,
        3600: 0.6031,
        3900: 0.576,
        4200: 0.5513,
        4500: 0.5289,
        4800: 0.5082,
        5100: 0.4893,
        5400: 0.472,
        5700: 0.4559,
      },
    },
    4000: {
      0.1: {
        0: 0.965,
        300: 0.964,
        600: 0.9262,
        900: 0.8829,
        1200: 0.8272,
        1500: 0.77,
        1800: 0.7165,
        2100: 0.6682,
        2400: 0.6255,
        2700: 0.5876,
        3000: 0.5543,
        3300: 0.5245,
        3600: 0.4981,
        3900: 0.4745,
        4200: 0.4531,
        4500: 0.4339,
        4800: 0.4165,
        5100: 0.4005,
        5400: 0.386,
        5700: 0.3727,
      },
      1.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9418,
        900: 0.9095,
        1200: 0.8604,
        1500: 0.8064,
        1800: 0.7543,
        2100: 0.7062,
        2400: 0.663,
        2700: 0.6244,
        3000: 0.5899,
        3300: 0.5591,
        3600: 0.5316,
        3900: 0.5068,
        4200: 0.4843,
        4500: 0.464,
        4800: 0.4456,
        5100: 0.4286,
        5400: 0.4132,
        5700: 0.399,
      },
      2.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9529,
        900: 0.9311,
        1200: 0.8889,
        1500: 0.8388,
        1800: 0.7885,
        2100: 0.7411,
        2400: 0.6979,
        2700: 0.6587,
        3000: 0.6236,
        3300: 0.5919,
        3600: 0.5634,
        3900: 0.5376,
        4200: 0.5142,
        4500: 0.493,
        4800: 0.4736,
        5100: 0.4558,
        5400: 0.4395,
        5700: 0.4245,
      },
      3.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9584,
        900: 0.9448,
        1200: 0.9086,
        1500: 0.8622,
        1800: 0.8141,
        2100: 0.7677,
        2400: 0.7248,
        2700: 0.6856,
        3000: 0.6501,
        3300: 0.6179,
        3600: 0.5888,
        3900: 0.5624,
        4200: 0.5382,
        4500: 0.5163,
        4800: 0.4963,
        5100: 0.4778,
        5400: 0.4609,
        5700: 0.4453,
      },
      4.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9624,
        900: 0.9554,
        1200: 0.9242,
        1500: 0.881,
        1800: 0.8347,
        2100: 0.7893,
        2400: 0.7468,
        2700: 0.7075,
        3000: 0.6718,
        3300: 0.6392,
        3600: 0.6097,
        3900: 0.5828,
        4200: 0.5581,
        4500: 0.5357,
        4800: 0.5151,
        5100: 0.4961,
        5400: 0.4786,
        5700: 0.4625,
      },
      5.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9628,
        1200: 0.9357,
        1500: 0.8953,
        1800: 0.8507,
        2100: 0.8062,
        2400: 0.7642,
        2700: 0.7251,
        3000: 0.6892,
        3300: 0.6564,
        3600: 0.6266,
        3900: 0.5993,
        4200: 0.5742,
        4500: 0.5514,
        4800: 0.5304,
        5100: 0.511,
        5400: 0.4932,
        5700: 0.4767,
      },
      6.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.9433,
        1500: 0.905,
        1800: 0.8616,
        2100: 0.8179,
        2400: 0.7763,
        2700: 0.7373,
        3000: 0.7014,
        3300: 0.6685,
        3600: 0.6384,
        3900: 0.6109,
        4200: 0.5856,
        4500: 0.5625,
        4800: 0.5413,
        5100: 0.5216,
        5400: 0.5034,
        5700: 0.4867,
      },
      7.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.95,
        1500: 0.9132,
        1800: 0.8708,
        2100: 0.8276,
        2400: 0.7862,
        2700: 0.7473,
        3000: 0.7114,
        3300: 0.6783,
        3600: 0.6481,
        3900: 0.6204,
        4200: 0.5948,
        4500: 0.5715,
        4800: 0.55,
        5100: 0.5301,
        5400: 0.5117,
        5700: 0.4947,
      },
      8.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.95,
        1500: 0.9132,
        1800: 0.8708,
        2100: 0.8276,
        2400: 0.7862,
        2700: 0.7473,
        3000: 0.7114,
        3300: 0.6783,
        3600: 0.6481,
        3900: 0.6204,
        4200: 0.5948,
        4500: 0.5715,
        4800: 0.55,
        5100: 0.5301,
        5400: 0.5117,
        5700: 0.4947,
      },
      9.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.95,
        1500: 0.9132,
        1800: 0.8708,
        2100: 0.8276,
        2400: 0.7862,
        2700: 0.7473,
        3000: 0.7114,
        3300: 0.6783,
        3600: 0.6481,
        3900: 0.6204,
        4200: 0.5948,
        4500: 0.5715,
        4800: 0.55,
        5100: 0.5301,
        5400: 0.5117,
        5700: 0.4947,
      },
      10.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.95,
        1500: 0.9132,
        1800: 0.8708,
        2100: 0.8276,
        2400: 0.7862,
        2700: 0.7473,
        3000: 0.7114,
        3300: 0.6783,
        3600: 0.6481,
        3900: 0.6204,
        4200: 0.5948,
        4500: 0.5715,
        4800: 0.55,
        5100: 0.5301,
        5400: 0.5117,
        5700: 0.4947,
      },
      11.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.95,
        1500: 0.9132,
        1800: 0.8708,
        2100: 0.8276,
        2400: 0.7862,
        2700: 0.7473,
        3000: 0.7114,
        3300: 0.6783,
        3600: 0.6481,
        3900: 0.6204,
        4200: 0.5948,
        4500: 0.5715,
        4800: 0.55,
        5100: 0.5301,
        5400: 0.5117,
        5700: 0.4947,
      },
      12.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.95,
        1500: 0.9132,
        1800: 0.8708,
        2100: 0.8276,
        2400: 0.7862,
        2700: 0.7473,
        3000: 0.7114,
        3300: 0.6783,
        3600: 0.6481,
        3900: 0.6204,
        4200: 0.5948,
        4500: 0.5715,
        4800: 0.55,
        5100: 0.5301,
        5400: 0.5117,
        5700: 0.4947,
      },
      13.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.95,
        1500: 0.9132,
        1800: 0.8708,
        2100: 0.8276,
        2400: 0.7862,
        2700: 0.7473,
        3000: 0.7114,
        3300: 0.6783,
        3600: 0.6481,
        3900: 0.6204,
        4200: 0.5948,
        4500: 0.5715,
        4800: 0.55,
        5100: 0.5301,
        5400: 0.5117,
        5700: 0.4947,
      },
    },
    4500: {
      0.1: {
        0: 0.965,
        300: 0.9639,
        600: 0.9352,
        900: 0.9008,
        1200: 0.8543,
        1500: 0.8028,
        1800: 0.7525,
        2100: 0.7058,
        2400: 0.6635,
        2700: 0.6255,
        3000: 0.5914,
        3300: 0.561,
        3600: 0.5336,
        3900: 0.5089,
        4200: 0.4866,
        4500: 0.4664,
        4800: 0.4478,
        5100: 0.4311,
        5400: 0.4156,
        5700: 0.4013,
      },
      1.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9472,
        900: 0.9239,
        1200: 0.8849,
        1500: 0.8377,
        1800: 0.7896,
        2100: 0.7437,
        2400: 0.7014,
        2700: 0.663,
        3000: 0.6282,
        3300: 0.5969,
        3600: 0.5685,
        3900: 0.5428,
        4200: 0.5195,
        4500: 0.4982,
        4800: 0.4787,
        5100: 0.461,
        5400: 0.4446,
        5700: 0.4295,
      },
      2.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9552,
        900: 0.9421,
        1200: 0.9106,
        1500: 0.8681,
        1800: 0.8227,
        2100: 0.7782,
        2400: 0.7364,
        2700: 0.6979,
        3000: 0.6627,
        3300: 0.6307,
        3600: 0.6016,
        3900: 0.575,
        4200: 0.5509,
        4500: 0.5287,
        4800: 0.5084,
        5100: 0.4898,
        5400: 0.4726,
        5700: 0.4567,
      },
      3.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9585,
        900: 0.9529,
        1200: 0.9277,
        1500: 0.8896,
        1800: 0.847,
        2100: 0.8041,
        2400: 0.763,
        2700: 0.7248,
        3000: 0.6895,
        3300: 0.6573,
        3600: 0.6278,
        3900: 0.6006,
        4200: 0.5759,
        4500: 0.5532,
        4800: 0.5323,
        5100: 0.5131,
        5400: 0.4953,
        5700: 0.4787,
      },
      4.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9608,
        900: 0.9612,
        1200: 0.9412,
        1500: 0.9068,
        1800: 0.8665,
        2100: 0.8249,
        2400: 0.7847,
        2700: 0.7468,
        3000: 0.7115,
        3300: 0.6791,
        3600: 0.6492,
        3900: 0.6217,
        4200: 0.5966,
        4500: 0.5734,
        4800: 0.552,
        5100: 0.5323,
        5400: 0.514,
        5700: 0.497,
      },
      5.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9617,
        900: 0.965,
        1200: 0.9509,
        1500: 0.9196,
        1800: 0.8814,
        2100: 0.8412,
        2400: 0.8017,
        2700: 0.7642,
        3000: 0.729,
        3300: 0.6966,
        3600: 0.6665,
        3900: 0.6388,
        4200: 0.6133,
        4500: 0.5898,
        4800: 0.568,
        5100: 0.548,
        5400: 0.5293,
        5700: 0.512,
      },
      6.1: {
        0: 0.965,
        300: 0.965,
        600: 0.962,
        900: 0.965,
        1200: 0.9573,
        1500: 0.9282,
        1800: 0.8916,
        2100: 0.8524,
        2400: 0.8134,
        2700: 0.7763,
        3000: 0.7412,
        3300: 0.7088,
        3600: 0.6786,
        3900: 0.6507,
        4200: 0.6251,
        4500: 0.6014,
        4800: 0.5793,
        5100: 0.5591,
        5400: 0.5402,
        5700: 0.5225,
      },
      7.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9628,
        900: 0.965,
        1200: 0.963,
        1500: 0.9357,
        1800: 0.9001,
        2100: 0.8617,
        2400: 0.8232,
        2700: 0.7862,
        3000: 0.7512,
        3300: 0.7187,
        3600: 0.6885,
        3900: 0.6605,
        4200: 0.6346,
        4500: 0.6107,
        4800: 0.5885,
        5100: 0.568,
        5400: 0.5489,
        5700: 0.5311,
      },
      8.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9628,
        900: 0.965,
        1200: 0.963,
        1500: 0.9357,
        1800: 0.9001,
        2100: 0.8617,
        2400: 0.8232,
        2700: 0.7862,
        3000: 0.7512,
        3300: 0.7187,
        3600: 0.6885,
        3900: 0.6605,
        4200: 0.6346,
        4500: 0.6107,
        4800: 0.5885,
        5100: 0.568,
        5400: 0.5489,
        5700: 0.5311,
      },
      9.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9628,
        900: 0.965,
        1200: 0.963,
        1500: 0.9357,
        1800: 0.9001,
        2100: 0.8617,
        2400: 0.8232,
        2700: 0.7862,
        3000: 0.7512,
        3300: 0.7187,
        3600: 0.6885,
        3900: 0.6605,
        4200: 0.6346,
        4500: 0.6107,
        4800: 0.5885,
        5100: 0.568,
        5400: 0.5489,
        5700: 0.5311,
      },
      10.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9628,
        900: 0.965,
        1200: 0.963,
        1500: 0.9357,
        1800: 0.9001,
        2100: 0.8617,
        2400: 0.8232,
        2700: 0.7862,
        3000: 0.7512,
        3300: 0.7187,
        3600: 0.6885,
        3900: 0.6605,
        4200: 0.6346,
        4500: 0.6107,
        4800: 0.5885,
        5100: 0.568,
        5400: 0.5489,
        5700: 0.5311,
      },
      11.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9628,
        900: 0.965,
        1200: 0.963,
        1500: 0.9357,
        1800: 0.9001,
        2100: 0.8617,
        2400: 0.8232,
        2700: 0.7862,
        3000: 0.7512,
        3300: 0.7187,
        3600: 0.6885,
        3900: 0.6605,
        4200: 0.6346,
        4500: 0.6107,
        4800: 0.5885,
        5100: 0.568,
        5400: 0.5489,
        5700: 0.5311,
      },
      12.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9628,
        900: 0.965,
        1200: 0.963,
        1500: 0.9357,
        1800: 0.9001,
        2100: 0.8617,
        2400: 0.8232,
        2700: 0.7862,
        3000: 0.7512,
        3300: 0.7187,
        3600: 0.6885,
        3900: 0.6605,
        4200: 0.6346,
        4500: 0.6107,
        4800: 0.5885,
        5100: 0.568,
        5400: 0.5489,
        5700: 0.5311,
      },
      13.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9628,
        900: 0.965,
        1200: 0.963,
        1500: 0.9357,
        1800: 0.9001,
        2100: 0.8617,
        2400: 0.8232,
        2700: 0.7862,
        3000: 0.7512,
        3300: 0.7187,
        3600: 0.6885,
        3900: 0.6605,
        4200: 0.6346,
        4500: 0.6107,
        4800: 0.5885,
        5100: 0.568,
        5400: 0.5489,
        5700: 0.5311,
      },
    },
    5000: {
      0.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9431,
        900: 0.9137,
        1200: 0.8754,
        1500: 0.83,
        1800: 0.7833,
        2100: 0.7387,
        2400: 0.6974,
        2700: 0.6597,
        3000: 0.6255,
        3300: 0.5946,
        3600: 0.5665,
        3900: 0.5412,
        4200: 0.518,
        4500: 0.4969,
        4800: 0.4777,
        5100: 0.46,
        5400: 0.4437,
        5700: 0.4287,
      },
      1.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9522,
        900: 0.9335,
        1200: 0.9032,
        1500: 0.8629,
        1800: 0.8192,
        2100: 0.7762,
        2400: 0.7354,
        2700: 0.6976,
        3000: 0.663,
        3300: 0.6315,
        3600: 0.6026,
        3900: 0.5764,
        4200: 0.5523,
        4500: 0.5303,
        4800: 0.5101,
        5100: 0.4915,
        5400: 0.4744,
        5700: 0.4585,
      },
      2.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9577,
        900: 0.9484,
        1200: 0.926,
        1500: 0.8911,
        1800: 0.8508,
        2100: 0.8098,
        2400: 0.77,
        2700: 0.7326,
        3000: 0.6979,
        3300: 0.666,
        3600: 0.6365,
        3900: 0.6097,
        4200: 0.5849,
        4500: 0.5621,
        4800: 0.5411,
        5100: 0.5218,
        5400: 0.5038,
        5700: 0.4872,
      },
      3.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9594,
        900: 0.9568,
        1200: 0.9407,
        1500: 0.9106,
        1800: 0.8736,
        2100: 0.8346,
        2400: 0.7961,
        2700: 0.7593,
        3000: 0.7248,
        3300: 0.6928,
        3600: 0.6632,
        3900: 0.636,
        4200: 0.6107,
        4500: 0.5874,
        4800: 0.5659,
        5100: 0.546,
        5400: 0.5276,
        5700: 0.5104,
      },
      4.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.9631,
        1200: 0.9521,
        1500: 0.926,
        1800: 0.8917,
        2100: 0.8546,
        2400: 0.8171,
        2700: 0.781,
        3000: 0.7468,
        3300: 0.7148,
        3600: 0.685,
        3900: 0.6575,
        4200: 0.632,
        4500: 0.6083,
        4800: 0.5864,
        5100: 0.5661,
        5400: 0.5472,
        5700: 0.5296,
      },
      5.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.9601,
        1500: 0.9374,
        1800: 0.9055,
        2100: 0.8699,
        2400: 0.8336,
        2700: 0.798,
        3000: 0.7642,
        3300: 0.7323,
        3600: 0.7025,
        3900: 0.6749,
        4200: 0.6491,
        4500: 0.6252,
        4800: 0.603,
        5100: 0.5824,
        5400: 0.5631,
        5700: 0.5452,
      },
      6.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.9653,
        1500: 0.9449,
        1800: 0.9148,
        2100: 0.8804,
        2400: 0.8449,
        2700: 0.8098,
        3000: 0.7763,
        3300: 0.7445,
        3600: 0.7147,
        3900: 0.687,
        4200: 0.6611,
        4500: 0.637,
        4800: 0.6147,
        5100: 0.5938,
        5400: 0.5744,
        5700: 0.5562,
      },
      7.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9515,
        1800: 0.9227,
        2100: 0.8893,
        2400: 0.8543,
        2700: 0.8196,
        3000: 0.7862,
        3300: 0.7545,
        3600: 0.7247,
        3900: 0.6969,
        4200: 0.6709,
        4500: 0.6466,
        4800: 0.6241,
        5100: 0.6031,
        5400: 0.5835,
        5700: 0.5651,
      },
      8.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9515,
        1800: 0.9227,
        2100: 0.8893,
        2400: 0.8543,
        2700: 0.8196,
        3000: 0.7862,
        3300: 0.7545,
        3600: 0.7247,
        3900: 0.6969,
        4200: 0.6709,
        4500: 0.6466,
        4800: 0.6241,
        5100: 0.6031,
        5400: 0.5835,
        5700: 0.5651,
      },
      9.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9515,
        1800: 0.9227,
        2100: 0.8893,
        2400: 0.8543,
        2700: 0.8196,
        3000: 0.7862,
        3300: 0.7545,
        3600: 0.7247,
        3900: 0.6969,
        4200: 0.6709,
        4500: 0.6466,
        4800: 0.6241,
        5100: 0.6031,
        5400: 0.5835,
        5700: 0.5651,
      },
      10.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9515,
        1800: 0.9227,
        2100: 0.8893,
        2400: 0.8543,
        2700: 0.8196,
        3000: 0.7862,
        3300: 0.7545,
        3600: 0.7247,
        3900: 0.6969,
        4200: 0.6709,
        4500: 0.6466,
        4800: 0.6241,
        5100: 0.6031,
        5400: 0.5835,
        5700: 0.5651,
      },
      11.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9515,
        1800: 0.9227,
        2100: 0.8893,
        2400: 0.8543,
        2700: 0.8196,
        3000: 0.7862,
        3300: 0.7545,
        3600: 0.7247,
        3900: 0.6969,
        4200: 0.6709,
        4500: 0.6466,
        4800: 0.6241,
        5100: 0.6031,
        5400: 0.5835,
        5700: 0.5651,
      },
      12.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9515,
        1800: 0.9227,
        2100: 0.8893,
        2400: 0.8543,
        2700: 0.8196,
        3000: 0.7862,
        3300: 0.7545,
        3600: 0.7247,
        3900: 0.6969,
        4200: 0.6709,
        4500: 0.6466,
        4800: 0.6241,
        5100: 0.6031,
        5400: 0.5835,
        5700: 0.5651,
      },
      13.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9515,
        1800: 0.9227,
        2100: 0.8893,
        2400: 0.8543,
        2700: 0.8196,
        3000: 0.7862,
        3300: 0.7545,
        3600: 0.7247,
        3900: 0.6969,
        4200: 0.6709,
        4500: 0.6466,
        4800: 0.6241,
        5100: 0.6031,
        5400: 0.5835,
        5700: 0.5651,
      },
    },
    5500: {
      0.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9511,
        900: 0.9231,
        1200: 0.8916,
        1500: 0.8521,
        1800: 0.8095,
        2100: 0.7675,
        2400: 0.7276,
        2700: 0.6905,
        3000: 0.6566,
        3300: 0.6255,
        3600: 0.5971,
        3900: 0.5712,
        4200: 0.5475,
        4500: 0.5258,
        4800: 0.5059,
        5100: 0.4875,
        5400: 0.4706,
        5700: 0.4549,
      },
      1.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9579,
        900: 0.9399,
        1200: 0.9167,
        1500: 0.8829,
        1800: 0.844,
        2100: 0.8041,
        2400: 0.7652,
        2700: 0.7286,
        3000: 0.6945,
        3300: 0.663,
        3600: 0.6341,
        3900: 0.6074,
        4200: 0.5829,
        4500: 0.5604,
        4800: 0.5396,
        5100: 0.5204,
        5400: 0.5027,
        5700: 0.4862,
      },
      2.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9615,
        900: 0.952,
        1200: 0.9367,
        1500: 0.9088,
        1800: 0.8739,
        2100: 0.8365,
        2400: 0.7992,
        2700: 0.7633,
        3000: 0.7295,
        3300: 0.6979,
        3600: 0.6686,
        3900: 0.6415,
        4200: 0.6164,
        4500: 0.5932,
        4800: 0.5718,
        5100: 0.5518,
        5400: 0.5333,
        5700: 0.5161,
      },
      3.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9621,
        900: 0.9582,
        1200: 0.9491,
        1500: 0.9263,
        1800: 0.895,
        2100: 0.8601,
        2400: 0.8244,
        2700: 0.7895,
        3000: 0.7562,
        3300: 0.7248,
        3600: 0.6955,
        3900: 0.6682,
        4200: 0.6428,
        4500: 0.6192,
        4800: 0.5973,
        5100: 0.5769,
        5400: 0.558,
        5700: 0.5403,
      },
      4.1: {
        0: 0.965,
        300: 0.965,
        600: 0.9623,
        900: 0.9628,
        1200: 0.9586,
        1500: 0.9399,
        1800: 0.9117,
        2100: 0.879,
        2400: 0.8447,
        2700: 0.8107,
        3000: 0.7779,
        3300: 0.7468,
        3600: 0.7175,
        3900: 0.69,
        4200: 0.6645,
        4500: 0.6406,
        4800: 0.6184,
        5100: 0.5976,
        5400: 0.5783,
        5700: 0.5602,
      },
      5.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.9651,
        1500: 0.9498,
        1800: 0.9242,
        2100: 0.8934,
        2400: 0.8604,
        2700: 0.8272,
        3000: 0.795,
        3300: 0.7642,
        3600: 0.735,
        3900: 0.7075,
        4200: 0.6818,
        4500: 0.6578,
        4800: 0.6354,
        5100: 0.6144,
        5400: 0.5947,
        5700: 0.5763,
      },
      6.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9563,
        1800: 0.9326,
        2100: 0.9032,
        2400: 0.8711,
        2700: 0.8387,
        3000: 0.8068,
        3300: 0.7763,
        3600: 0.7472,
        3900: 0.7198,
        4200: 0.694,
        4500: 0.6699,
        4800: 0.6473,
        5100: 0.6261,
        5400: 0.6063,
        5700: 0.5878,
      },
      7.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9621,
        1800: 0.9398,
        2100: 0.9114,
        2400: 0.8801,
        2700: 0.8481,
        3000: 0.8166,
        3300: 0.7862,
        3600: 0.7572,
        3900: 0.7297,
        4200: 0.7039,
        4500: 0.6797,
        4800: 0.657,
        5100: 0.6357,
        5400: 0.6157,
        5700: 0.597,
      },
      8.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9621,
        1800: 0.9398,
        2100: 0.9114,
        2400: 0.8801,
        2700: 0.8481,
        3000: 0.8166,
        3300: 0.7862,
        3600: 0.7572,
        3900: 0.7297,
        4200: 0.7039,
        4500: 0.6797,
        4800: 0.657,
        5100: 0.6357,
        5400: 0.6157,
        5700: 0.597,
      },
      9.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9621,
        1800: 0.9398,
        2100: 0.9114,
        2400: 0.8801,
        2700: 0.8481,
        3000: 0.8166,
        3300: 0.7862,
        3600: 0.7572,
        3900: 0.7297,
        4200: 0.7039,
        4500: 0.6797,
        4800: 0.657,
        5100: 0.6357,
        5400: 0.6157,
        5700: 0.597,
      },
      10.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9621,
        1800: 0.9398,
        2100: 0.9114,
        2400: 0.8801,
        2700: 0.8481,
        3000: 0.8166,
        3300: 0.7862,
        3600: 0.7572,
        3900: 0.7297,
        4200: 0.7039,
        4500: 0.6797,
        4800: 0.657,
        5100: 0.6357,
        5400: 0.6157,
        5700: 0.597,
      },
      11.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9621,
        1800: 0.9398,
        2100: 0.9114,
        2400: 0.8801,
        2700: 0.8481,
        3000: 0.8166,
        3300: 0.7862,
        3600: 0.7572,
        3900: 0.7297,
        4200: 0.7039,
        4500: 0.6797,
        4800: 0.657,
        5100: 0.6357,
        5400: 0.6157,
        5700: 0.597,
      },
      12.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9621,
        1800: 0.9398,
        2100: 0.9114,
        2400: 0.8801,
        2700: 0.8481,
        3000: 0.8166,
        3300: 0.7862,
        3600: 0.7572,
        3900: 0.7297,
        4200: 0.7039,
        4500: 0.6797,
        4800: 0.657,
        5100: 0.6357,
        5400: 0.6157,
        5700: 0.597,
      },
      13.1: {
        0: 0.965,
        300: 0.965,
        600: 0.965,
        900: 0.965,
        1200: 0.965,
        1500: 0.9621,
        1800: 0.9398,
        2100: 0.9114,
        2400: 0.8801,
        2700: 0.8481,
        3000: 0.8166,
        3300: 0.7862,
        3600: 0.7572,
        3900: 0.7297,
        4200: 0.7039,
        4500: 0.6797,
        4800: 0.657,
        5100: 0.6357,
        5400: 0.6157,
        5700: 0.597,
      },
    },
  },
  1: {
    1500: {
      0.1: {
        0: 0.9308,
        300: 0.8441,
        600: 0.6911,
        900: 0.5706,
        1200: 0.4833,
        1500: 0.4187,
        1800: 0.3695,
        2100: 0.3309,
        2400: 0.2998,
        2700: 0.2743,
        3000: 0.253,
        3300: 0.235,
        3600: 0.2196,
        3900: 0.2062,
        4200: 0.1945,
        4500: 0.1841,
        4800: 0.175,
        5100: 0.1668,
        5400: 0.1594,
        5700: 0.1528,
      },
      1.1: {
        0: 0.9318,
        300: 0.8838,
        600: 0.7392,
        900: 0.6169,
        1200: 0.5256,
        1500: 0.457,
        1800: 0.4043,
        2100: 0.3626,
        2400: 0.329,
        2700: 0.3012,
        3000: 0.278,
        3300: 0.2583,
        3600: 0.2414,
        3900: 0.2267,
        4200: 0.2138,
        4500: 0.2024,
        4800: 0.1923,
        5100: 0.1833,
        5400: 0.1751,
        5700: 0.1678,
      },
      2.1: {
        0: 0.9309,
        300: 0.9149,
        600: 0.7807,
        900: 0.658,
        1200: 0.564,
        1500: 0.4922,
        1800: 0.4364,
        2100: 0.3922,
        2400: 0.3562,
        2700: 0.3264,
        3000: 0.3015,
        3300: 0.2802,
        3600: 0.2619,
        3900: 0.2461,
        4200: 0.2321,
        4500: 0.2197,
        4800: 0.2087,
        5100: 0.199,
        5400: 0.1901,
        5700: 0.1821,
      },
      3.1: {
        0: 0.9288,
        300: 0.9376,
        600: 0.8142,
        900: 0.6926,
        1200: 0.5967,
        1500: 0.5226,
        1800: 0.4645,
        2100: 0.418,
        2400: 0.3801,
        2700: 0.3486,
        3000: 0.3222,
        3300: 0.2996,
        3600: 0.2802,
        3900: 0.2633,
        4200: 0.2484,
        4500: 0.2352,
        4800: 0.2234,
        5100: 0.2129,
        5400: 0.2034,
        5700: 0.1948,
      },
      4.1: {
        0: 0.9263,
        300: 0.9517,
        600: 0.8385,
        900: 0.7187,
        1200: 0.6221,
        1500: 0.5464,
        1800: 0.4867,
        2100: 0.4386,
        2400: 0.3993,
        2700: 0.3665,
        3000: 0.339,
        3300: 0.3153,
        3600: 0.295,
        3900: 0.2772,
        4200: 0.2616,
        4500: 0.2477,
        4800: 0.2353,
        5100: 0.2243,
        5400: 0.2143,
        5700: 0.2052,
      },
      5.1: {
        0: 0.9238,
        300: 0.955,
        600: 0.8545,
        900: 0.737,
        1200: 0.6404,
        1500: 0.5638,
        1800: 0.503,
        2100: 0.454,
        2400: 0.4136,
        2700: 0.3799,
        3000: 0.3516,
        3300: 0.3272,
        3600: 0.3061,
        3900: 0.2878,
        4200: 0.2716,
        4500: 0.2572,
        4800: 0.2444,
        5100: 0.233,
        5400: 0.2226,
        5700: 0.2132,
      },
      6.1: {
        0: 0.9227,
        300: 0.955,
        600: 0.8681,
        900: 0.752,
        1200: 0.6551,
        1500: 0.5778,
        1800: 0.5161,
        2100: 0.4662,
        2400: 0.425,
        2700: 0.3906,
        3000: 0.3615,
        3300: 0.3366,
        3600: 0.315,
        3900: 0.2962,
        4200: 0.2796,
        4500: 0.2648,
        4800: 0.2516,
        5100: 0.2398,
        5400: 0.2291,
        5700: 0.2195,
      },
      7.1: {
        0: 0.9215,
        300: 0.955,
        600: 0.8745,
        900: 0.7598,
        1200: 0.6631,
        1500: 0.5856,
        1800: 0.5235,
        2100: 0.4731,
        2400: 0.4316,
        2700: 0.3967,
        3000: 0.3673,
        3300: 0.342,
        3600: 0.3201,
        3900: 0.3011,
        4200: 0.2842,
        4500: 0.2692,
        4800: 0.2558,
        5100: 0.2439,
        5400: 0.233,
        5700: 0.2232,
      },
      8.1: {
        0: 0.9215,
        300: 0.955,
        600: 0.8745,
        900: 0.7598,
        1200: 0.6631,
        1500: 0.5856,
        1800: 0.5235,
        2100: 0.4731,
        2400: 0.4316,
        2700: 0.3967,
        3000: 0.3673,
        3300: 0.342,
        3600: 0.3201,
        3900: 0.3011,
        4200: 0.2842,
        4500: 0.2692,
        4800: 0.2558,
        5100: 0.2439,
        5400: 0.233,
        5700: 0.2232,
      },
      9.1: {
        0: 0.9215,
        300: 0.955,
        600: 0.8745,
        900: 0.7598,
        1200: 0.6631,
        1500: 0.5856,
        1800: 0.5235,
        2100: 0.4731,
        2400: 0.4316,
        2700: 0.3967,
        3000: 0.3673,
        3300: 0.342,
        3600: 0.3201,
        3900: 0.3011,
        4200: 0.2842,
        4500: 0.2692,
        4800: 0.2558,
        5100: 0.2439,
        5400: 0.233,
        5700: 0.2232,
      },
      10.1: {
        0: 0.9215,
        300: 0.955,
        600: 0.8745,
        900: 0.7598,
        1200: 0.6631,
        1500: 0.5856,
        1800: 0.5235,
        2100: 0.4731,
        2400: 0.4316,
        2700: 0.3967,
        3000: 0.3673,
        3300: 0.342,
        3600: 0.3201,
        3900: 0.3011,
        4200: 0.2842,
        4500: 0.2692,
        4800: 0.2558,
        5100: 0.2439,
        5400: 0.233,
        5700: 0.2232,
      },
      11.1: {
        0: 0.9215,
        300: 0.955,
        600: 0.8745,
        900: 0.7598,
        1200: 0.6631,
        1500: 0.5856,
        1800: 0.5235,
        2100: 0.4731,
        2400: 0.4316,
        2700: 0.3967,
        3000: 0.3673,
        3300: 0.342,
        3600: 0.3201,
        3900: 0.3011,
        4200: 0.2842,
        4500: 0.2692,
        4800: 0.2558,
        5100: 0.2439,
        5400: 0.233,
        5700: 0.2232,
      },
      12.1: {
        0: 0.9215,
        300: 0.955,
        600: 0.8745,
        900: 0.7598,
        1200: 0.6631,
        1500: 0.5856,
        1800: 0.5235,
        2100: 0.4731,
        2400: 0.4316,
        2700: 0.3967,
        3000: 0.3673,
        3300: 0.342,
        3600: 0.3201,
        3900: 0.3011,
        4200: 0.2842,
        4500: 0.2692,
        4800: 0.2558,
        5100: 0.2439,
        5400: 0.233,
        5700: 0.2232,
      },
      13.1: {
        0: 0.9215,
        300: 0.955,
        600: 0.8745,
        900: 0.7598,
        1200: 0.6631,
        1500: 0.5856,
        1800: 0.5235,
        2100: 0.4731,
        2400: 0.4316,
        2700: 0.3967,
        3000: 0.3673,
        3300: 0.342,
        3600: 0.3201,
        3900: 0.3011,
        4200: 0.2842,
        4500: 0.2692,
        4800: 0.2558,
        5100: 0.2439,
        5400: 0.233,
        5700: 0.2232,
      },
    },
    2000: {
      0.1: {
        0: 0.955,
        300: 0.8897,
        600: 0.7743,
        900: 0.6609,
        1200: 0.5706,
        1500: 0.5004,
        1800: 0.4452,
        2100: 0.4009,
        2400: 0.3648,
        2700: 0.3347,
        3000: 0.3094,
        3300: 0.2879,
        3600: 0.2693,
        3900: 0.253,
        4200: 0.2388,
        4500: 0.2262,
        4800: 0.2149,
        5100: 0.2049,
        5400: 0.1957,
        5700: 0.1875,
      },
      1.1: {
        0: 0.955,
        300: 0.92,
        600: 0.8203,
        900: 0.7091,
        1200: 0.6169,
        1500: 0.5436,
        1800: 0.4852,
        2100: 0.438,
        2400: 0.3992,
        2700: 0.3668,
        3000: 0.3394,
        3300: 0.316,
        3600: 0.2957,
        3900: 0.278,
        4200: 0.2624,
        4500: 0.2486,
        4800: 0.2363,
        5100: 0.2252,
        5400: 0.2152,
        5700: 0.2061,
      },
      2.1: {
        0: 0.955,
        300: 0.9419,
        600: 0.8584,
        900: 0.7509,
        1200: 0.658,
        1500: 0.5826,
        1800: 0.5218,
        2100: 0.4721,
        2400: 0.4311,
        2700: 0.3966,
        3000: 0.3673,
        3300: 0.3423,
        3600: 0.3205,
        3900: 0.3015,
        4200: 0.2847,
        4500: 0.2698,
        4800: 0.2564,
        5100: 0.2444,
        5400: 0.2336,
        5700: 0.2238,
      },
      3.1: {
        0: 0.955,
        300: 0.9562,
        600: 0.8879,
        900: 0.7852,
        1200: 0.6926,
        1500: 0.6159,
        1800: 0.5532,
        2100: 0.5017,
        2400: 0.4589,
        2700: 0.4227,
        3000: 0.3919,
        3300: 0.3654,
        3600: 0.3424,
        3900: 0.3222,
        4200: 0.3044,
        4500: 0.2885,
        4800: 0.2743,
        5100: 0.2615,
        5400: 0.2499,
        5700: 0.2395,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9082,
        900: 0.8103,
        1200: 0.7187,
        1500: 0.6415,
        1800: 0.5778,
        2100: 0.525,
        2400: 0.4809,
        2700: 0.4435,
        3000: 0.4115,
        3300: 0.384,
        3600: 0.36,
        3900: 0.339,
        4200: 0.3203,
        4500: 0.3037,
        4800: 0.2888,
        5100: 0.2754,
        5400: 0.2632,
        5700: 0.2522,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9205,
        900: 0.8271,
        1200: 0.737,
        1500: 0.6599,
        1800: 0.5956,
        2100: 0.5421,
        2400: 0.4971,
        2700: 0.4589,
        3000: 0.4262,
        3300: 0.3979,
        3600: 0.3733,
        3900: 0.3516,
        4200: 0.3323,
        4500: 0.3152,
        4800: 0.2998,
        5100: 0.2859,
        5400: 0.2733,
        5700: 0.2619,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9316,
        900: 0.8413,
        1200: 0.752,
        1500: 0.6747,
        1800: 0.61,
        2100: 0.5557,
        2400: 0.5101,
        2700: 0.4712,
        3000: 0.4378,
        3300: 0.409,
        3600: 0.3838,
        3900: 0.3615,
        4200: 0.3418,
        4500: 0.3242,
        4800: 0.3085,
        5100: 0.2942,
        5400: 0.2813,
        5700: 0.2696,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9362,
        900: 0.8482,
        1200: 0.7598,
        1500: 0.6828,
        1800: 0.6179,
        2100: 0.5634,
        2400: 0.5174,
        2700: 0.4782,
        3000: 0.4445,
        3300: 0.4153,
        3600: 0.3898,
        3900: 0.3673,
        4200: 0.3473,
        4500: 0.3295,
        4800: 0.3135,
        5100: 0.2991,
        5400: 0.286,
        5700: 0.2741,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9362,
        900: 0.8482,
        1200: 0.7598,
        1500: 0.6828,
        1800: 0.6179,
        2100: 0.5634,
        2400: 0.5174,
        2700: 0.4782,
        3000: 0.4445,
        3300: 0.4153,
        3600: 0.3898,
        3900: 0.3673,
        4200: 0.3473,
        4500: 0.3295,
        4800: 0.3135,
        5100: 0.2991,
        5400: 0.286,
        5700: 0.2741,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9362,
        900: 0.8482,
        1200: 0.7598,
        1500: 0.6828,
        1800: 0.6179,
        2100: 0.5634,
        2400: 0.5174,
        2700: 0.4782,
        3000: 0.4445,
        3300: 0.4153,
        3600: 0.3898,
        3900: 0.3673,
        4200: 0.3473,
        4500: 0.3295,
        4800: 0.3135,
        5100: 0.2991,
        5400: 0.286,
        5700: 0.2741,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9362,
        900: 0.8482,
        1200: 0.7598,
        1500: 0.6828,
        1800: 0.6179,
        2100: 0.5634,
        2400: 0.5174,
        2700: 0.4782,
        3000: 0.4445,
        3300: 0.4153,
        3600: 0.3898,
        3900: 0.3673,
        4200: 0.3473,
        4500: 0.3295,
        4800: 0.3135,
        5100: 0.2991,
        5400: 0.286,
        5700: 0.2741,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9362,
        900: 0.8482,
        1200: 0.7598,
        1500: 0.6828,
        1800: 0.6179,
        2100: 0.5634,
        2400: 0.5174,
        2700: 0.4782,
        3000: 0.4445,
        3300: 0.4153,
        3600: 0.3898,
        3900: 0.3673,
        4200: 0.3473,
        4500: 0.3295,
        4800: 0.3135,
        5100: 0.2991,
        5400: 0.286,
        5700: 0.2741,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9362,
        900: 0.8482,
        1200: 0.7598,
        1500: 0.6828,
        1800: 0.6179,
        2100: 0.5634,
        2400: 0.5174,
        2700: 0.4782,
        3000: 0.4445,
        3300: 0.4153,
        3600: 0.3898,
        3900: 0.3673,
        4200: 0.3473,
        4500: 0.3295,
        4800: 0.3135,
        5100: 0.2991,
        5400: 0.286,
        5700: 0.2741,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9362,
        900: 0.8482,
        1200: 0.7598,
        1500: 0.6828,
        1800: 0.6179,
        2100: 0.5634,
        2400: 0.5174,
        2700: 0.4782,
        3000: 0.4445,
        3300: 0.4153,
        3600: 0.3898,
        3900: 0.3673,
        4200: 0.3473,
        4500: 0.3295,
        4800: 0.3135,
        5100: 0.2991,
        5400: 0.286,
        5700: 0.2741,
      },
    },
    2500: {
      0.1: {
        0: 0.955,
        300: 0.9115,
        600: 0.8301,
        900: 0.7306,
        1200: 0.6428,
        1500: 0.5706,
        1800: 0.512,
        2100: 0.4638,
        2400: 0.4238,
        2700: 0.3903,
        3000: 0.3618,
        3300: 0.3372,
        3600: 0.316,
        3900: 0.2973,
        4200: 0.2808,
        4500: 0.2661,
        4800: 0.253,
        5100: 0.2412,
        5400: 0.2305,
        5700: 0.2209,
      },
      1.1: {
        0: 0.955,
        300: 0.933,
        600: 0.8716,
        900: 0.7782,
        1200: 0.6908,
        1500: 0.6169,
        1800: 0.5557,
        2100: 0.505,
        2400: 0.4625,
        2700: 0.4267,
        3000: 0.396,
        3300: 0.3695,
        3600: 0.3465,
        3900: 0.3262,
        4200: 0.3083,
        4500: 0.2923,
        4800: 0.278,
        5100: 0.2651,
        5400: 0.2534,
        5700: 0.2428,
      },
      2.1: {
        0: 0.955,
        300: 0.9471,
        600: 0.9046,
        900: 0.8186,
        1200: 0.7327,
        1500: 0.658,
        1800: 0.5952,
        2100: 0.5425,
        2400: 0.4979,
        2700: 0.4601,
        3000: 0.4277,
        3300: 0.3995,
        3600: 0.3749,
        3900: 0.3533,
        4200: 0.334,
        4500: 0.3168,
        4800: 0.3015,
        5100: 0.2875,
        5400: 0.2749,
        5700: 0.2635,
      },
      3.1: {
        0: 0.955,
        300: 0.9548,
        600: 0.929,
        900: 0.8506,
        1200: 0.7672,
        1500: 0.6926,
        1800: 0.6287,
        2100: 0.5746,
        2400: 0.5285,
        2700: 0.4892,
        3000: 0.4553,
        3300: 0.4257,
        3600: 0.3999,
        3900: 0.377,
        4200: 0.3567,
        4500: 0.3385,
        4800: 0.3222,
        5100: 0.3074,
        5400: 0.294,
        5700: 0.2819,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9446,
        900: 0.8733,
        1200: 0.7927,
        1500: 0.7187,
        1800: 0.6545,
        2100: 0.5996,
        2400: 0.5525,
        2700: 0.5122,
        3000: 0.4772,
        3300: 0.4466,
        3600: 0.4198,
        3900: 0.3961,
        4200: 0.3749,
        4500: 0.3559,
        4800: 0.339,
        5100: 0.3235,
        5400: 0.3095,
        5700: 0.2967,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9531,
        900: 0.8878,
        1200: 0.81,
        1500: 0.737,
        1800: 0.6729,
        2100: 0.6177,
        2400: 0.57,
        2700: 0.529,
        3000: 0.4934,
        3300: 0.4621,
        3600: 0.4347,
        3900: 0.4103,
        4200: 0.3886,
        4500: 0.3691,
        4800: 0.3516,
        5100: 0.3356,
        5400: 0.3211,
        5700: 0.308,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9004,
        1200: 0.8244,
        1500: 0.752,
        1800: 0.6879,
        2100: 0.6322,
        2400: 0.5841,
        2700: 0.5425,
        3000: 0.5063,
        3300: 0.4745,
        3600: 0.4465,
        3900: 0.4216,
        4200: 0.3994,
        4500: 0.3794,
        4800: 0.3615,
        5100: 0.3452,
        5400: 0.3304,
        5700: 0.3169,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9061,
        1200: 0.8316,
        1500: 0.7598,
        1800: 0.6959,
        2100: 0.6402,
        2400: 0.5919,
        2700: 0.5501,
        3000: 0.5136,
        3300: 0.4815,
        3600: 0.4533,
        3900: 0.4281,
        4200: 0.4057,
        4500: 0.3855,
        4800: 0.3673,
        5100: 0.3508,
        5400: 0.3357,
        5700: 0.322,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9061,
        1200: 0.8316,
        1500: 0.7598,
        1800: 0.6959,
        2100: 0.6402,
        2400: 0.5919,
        2700: 0.5501,
        3000: 0.5136,
        3300: 0.4815,
        3600: 0.4533,
        3900: 0.4281,
        4200: 0.4057,
        4500: 0.3855,
        4800: 0.3673,
        5100: 0.3508,
        5400: 0.3357,
        5700: 0.322,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9061,
        1200: 0.8316,
        1500: 0.7598,
        1800: 0.6959,
        2100: 0.6402,
        2400: 0.5919,
        2700: 0.5501,
        3000: 0.5136,
        3300: 0.4815,
        3600: 0.4533,
        3900: 0.4281,
        4200: 0.4057,
        4500: 0.3855,
        4800: 0.3673,
        5100: 0.3508,
        5400: 0.3357,
        5700: 0.322,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9061,
        1200: 0.8316,
        1500: 0.7598,
        1800: 0.6959,
        2100: 0.6402,
        2400: 0.5919,
        2700: 0.5501,
        3000: 0.5136,
        3300: 0.4815,
        3600: 0.4533,
        3900: 0.4281,
        4200: 0.4057,
        4500: 0.3855,
        4800: 0.3673,
        5100: 0.3508,
        5400: 0.3357,
        5700: 0.322,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9061,
        1200: 0.8316,
        1500: 0.7598,
        1800: 0.6959,
        2100: 0.6402,
        2400: 0.5919,
        2700: 0.5501,
        3000: 0.5136,
        3300: 0.4815,
        3600: 0.4533,
        3900: 0.4281,
        4200: 0.4057,
        4500: 0.3855,
        4800: 0.3673,
        5100: 0.3508,
        5400: 0.3357,
        5700: 0.322,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9061,
        1200: 0.8316,
        1500: 0.7598,
        1800: 0.6959,
        2100: 0.6402,
        2400: 0.5919,
        2700: 0.5501,
        3000: 0.5136,
        3300: 0.4815,
        3600: 0.4533,
        3900: 0.4281,
        4200: 0.4057,
        4500: 0.3855,
        4800: 0.3673,
        5100: 0.3508,
        5400: 0.3357,
        5700: 0.322,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9061,
        1200: 0.8316,
        1500: 0.7598,
        1800: 0.6959,
        2100: 0.6402,
        2400: 0.5919,
        2700: 0.5501,
        3000: 0.5136,
        3300: 0.4815,
        3600: 0.4533,
        3900: 0.4281,
        4200: 0.4057,
        4500: 0.3855,
        4800: 0.3673,
        5100: 0.3508,
        5400: 0.3357,
        5700: 0.322,
      },
    },
    3000: {
      0.1: {
        0: 0.955,
        300: 0.9247,
        600: 0.8665,
        900: 0.7837,
        1200: 0.7019,
        1500: 0.6307,
        1800: 0.5706,
        2100: 0.5202,
        2400: 0.4776,
        2700: 0.4414,
        3000: 0.4103,
        3300: 0.3833,
        3600: 0.3597,
        3900: 0.339,
        4200: 0.3206,
        4500: 0.3042,
        4800: 0.2894,
        5100: 0.2761,
        5400: 0.2641,
        5700: 0.253,
      },
      1.1: {
        0: 0.955,
        300: 0.9391,
        600: 0.9025,
        900: 0.8291,
        1200: 0.75,
        1500: 0.6784,
        1800: 0.6169,
        2100: 0.5644,
        2400: 0.5196,
        2700: 0.4812,
        3000: 0.448,
        3300: 0.4192,
        3600: 0.3938,
        3900: 0.3715,
        4200: 0.3515,
        4500: 0.3337,
        4800: 0.3177,
        5100: 0.3032,
        5400: 0.29,
        5700: 0.278,
      },
      2.1: {
        0: 0.955,
        300: 0.9472,
        600: 0.9299,
        900: 0.8666,
        1200: 0.7912,
        1500: 0.7204,
        1800: 0.658,
        2100: 0.6041,
        2400: 0.5577,
        2700: 0.5175,
        3000: 0.4827,
        3300: 0.4522,
        3600: 0.4253,
        3900: 0.4016,
        4200: 0.3803,
        4500: 0.3613,
        4800: 0.3441,
        5100: 0.3285,
        5400: 0.3144,
        5700: 0.3015,
      },
      3.1: {
        0: 0.955,
        300: 0.9505,
        600: 0.9491,
        900: 0.8954,
        1200: 0.8244,
        1500: 0.755,
        1800: 0.6926,
        2100: 0.6379,
        2400: 0.5903,
        2700: 0.5489,
        3000: 0.5127,
        3300: 0.481,
        3600: 0.4528,
        3900: 0.4279,
        4200: 0.4056,
        4500: 0.3855,
        4800: 0.3674,
        5100: 0.3509,
        5400: 0.336,
        5700: 0.3222,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.915,
        1200: 0.8483,
        1500: 0.7806,
        1800: 0.7187,
        2100: 0.6637,
        2400: 0.6155,
        2700: 0.5733,
        3000: 0.5363,
        3300: 0.5037,
        3600: 0.4746,
        3900: 0.4489,
        4200: 0.4257,
        4500: 0.4049,
        4800: 0.386,
        5100: 0.3689,
        5400: 0.3533,
        5700: 0.339,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9268,
        1200: 0.864,
        1500: 0.7982,
        1800: 0.737,
        2100: 0.6822,
        2400: 0.6337,
        2700: 0.5911,
        3000: 0.5536,
        3300: 0.5204,
        3600: 0.4908,
        3900: 0.4644,
        4200: 0.4407,
        4500: 0.4194,
        4800: 0.4,
        5100: 0.3824,
        5400: 0.3663,
        5700: 0.3516,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9375,
        1200: 0.8773,
        1500: 0.8128,
        1800: 0.752,
        2100: 0.6972,
        2400: 0.6484,
        2700: 0.6054,
        3000: 0.5674,
        3300: 0.5337,
        3600: 0.5036,
        3900: 0.4768,
        4200: 0.4527,
        4500: 0.4309,
        4800: 0.4111,
        5100: 0.3931,
        5400: 0.3766,
        5700: 0.3615,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9418,
        1200: 0.8836,
        1500: 0.8201,
        1800: 0.7598,
        2100: 0.7052,
        2400: 0.6564,
        2700: 0.6133,
        3000: 0.5751,
        3300: 0.5412,
        3600: 0.5109,
        3900: 0.4839,
        4200: 0.4595,
        4500: 0.4375,
        4800: 0.4175,
        5100: 0.3992,
        5400: 0.3826,
        5700: 0.3673,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9418,
        1200: 0.8836,
        1500: 0.8201,
        1800: 0.7598,
        2100: 0.7052,
        2400: 0.6564,
        2700: 0.6133,
        3000: 0.5751,
        3300: 0.5412,
        3600: 0.5109,
        3900: 0.4839,
        4200: 0.4595,
        4500: 0.4375,
        4800: 0.4175,
        5100: 0.3992,
        5400: 0.3826,
        5700: 0.3673,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9418,
        1200: 0.8836,
        1500: 0.8201,
        1800: 0.7598,
        2100: 0.7052,
        2400: 0.6564,
        2700: 0.6133,
        3000: 0.5751,
        3300: 0.5412,
        3600: 0.5109,
        3900: 0.4839,
        4200: 0.4595,
        4500: 0.4375,
        4800: 0.4175,
        5100: 0.3992,
        5400: 0.3826,
        5700: 0.3673,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9418,
        1200: 0.8836,
        1500: 0.8201,
        1800: 0.7598,
        2100: 0.7052,
        2400: 0.6564,
        2700: 0.6133,
        3000: 0.5751,
        3300: 0.5412,
        3600: 0.5109,
        3900: 0.4839,
        4200: 0.4595,
        4500: 0.4375,
        4800: 0.4175,
        5100: 0.3992,
        5400: 0.3826,
        5700: 0.3673,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9418,
        1200: 0.8836,
        1500: 0.8201,
        1800: 0.7598,
        2100: 0.7052,
        2400: 0.6564,
        2700: 0.6133,
        3000: 0.5751,
        3300: 0.5412,
        3600: 0.5109,
        3900: 0.4839,
        4200: 0.4595,
        4500: 0.4375,
        4800: 0.4175,
        5100: 0.3992,
        5400: 0.3826,
        5700: 0.3673,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9418,
        1200: 0.8836,
        1500: 0.8201,
        1800: 0.7598,
        2100: 0.7052,
        2400: 0.6564,
        2700: 0.6133,
        3000: 0.5751,
        3300: 0.5412,
        3600: 0.5109,
        3900: 0.4839,
        4200: 0.4595,
        4500: 0.4375,
        4800: 0.4175,
        5100: 0.3992,
        5400: 0.3826,
        5700: 0.3673,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9418,
        1200: 0.8836,
        1500: 0.8201,
        1800: 0.7598,
        2100: 0.7052,
        2400: 0.6564,
        2700: 0.6133,
        3000: 0.5751,
        3300: 0.5412,
        3600: 0.5109,
        3900: 0.4839,
        4200: 0.4595,
        4500: 0.4375,
        4800: 0.4175,
        5100: 0.3992,
        5400: 0.3826,
        5700: 0.3673,
      },
    },
    3500: {
      0.1: {
        0: 0.955,
        300: 0.9365,
        600: 0.8897,
        900: 0.8236,
        1200: 0.7501,
        1500: 0.6818,
        1800: 0.6221,
        2100: 0.5706,
        2400: 0.5265,
        2700: 0.4883,
        3000: 0.4553,
        3300: 0.4264,
        3600: 0.4009,
        3900: 0.3784,
        4200: 0.3583,
        4500: 0.3403,
        4800: 0.324,
        5100: 0.3094,
        5400: 0.2961,
        5700: 0.2839,
      },
      1.1: {
        0: 0.955,
        300: 0.9456,
        600: 0.92,
        900: 0.8657,
        1200: 0.7971,
        1500: 0.73,
        1800: 0.6697,
        2100: 0.6169,
        2400: 0.5709,
        2700: 0.5309,
        3000: 0.4959,
        3300: 0.4652,
        3600: 0.438,
        3900: 0.4139,
        4200: 0.3922,
        4500: 0.3729,
        4800: 0.3553,
        5100: 0.3394,
        5400: 0.325,
        5700: 0.3117,
      },
      2.1: {
        0: 0.955,
        300: 0.9496,
        600: 0.9419,
        900: 0.8995,
        1200: 0.8366,
        1500: 0.7716,
        1800: 0.7116,
        2100: 0.658,
        2400: 0.6109,
        2700: 0.5694,
        3000: 0.533,
        3300: 0.5008,
        3600: 0.4721,
        3900: 0.4466,
        4200: 0.4236,
        4500: 0.4031,
        4800: 0.3843,
        5100: 0.3673,
        5400: 0.3519,
        5700: 0.3376,
      },
      3.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9247,
        1200: 0.8677,
        1500: 0.8054,
        1800: 0.7462,
        2100: 0.6926,
        2400: 0.6447,
        2700: 0.6023,
        3000: 0.5648,
        3300: 0.5315,
        3600: 0.5017,
        3900: 0.4751,
        4200: 0.4511,
        4500: 0.4295,
        4800: 0.4098,
        5100: 0.3919,
        5400: 0.3756,
        5700: 0.3605,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.941,
        1200: 0.8894,
        1500: 0.8299,
        1800: 0.772,
        2100: 0.7187,
        2400: 0.6707,
        2700: 0.6278,
        3000: 0.5896,
        3300: 0.5555,
        3600: 0.525,
        3900: 0.4976,
        4200: 0.4728,
        4500: 0.4505,
        4800: 0.4301,
        5100: 0.4115,
        5400: 0.3946,
        5700: 0.3789,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.95,
        1200: 0.903,
        1500: 0.8462,
        1800: 0.7897,
        2100: 0.737,
        2400: 0.6891,
        2700: 0.6461,
        3000: 0.6076,
        3300: 0.5731,
        3600: 0.5421,
        3900: 0.5142,
        4200: 0.4889,
        4500: 0.4661,
        4800: 0.4452,
        5100: 0.4262,
        5400: 0.4088,
        5700: 0.3927,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.915,
        1500: 0.86,
        1800: 0.8044,
        2100: 0.752,
        2400: 0.7041,
        2700: 0.6609,
        3000: 0.6221,
        3300: 0.5872,
        3600: 0.5557,
        3900: 0.5275,
        4200: 0.5018,
        4500: 0.4785,
        4800: 0.4572,
        5100: 0.4378,
        5400: 0.4201,
        5700: 0.4036,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9202,
        1500: 0.8666,
        1800: 0.8118,
        2100: 0.7598,
        2400: 0.7121,
        2700: 0.6689,
        3000: 0.63,
        3300: 0.595,
        3600: 0.5634,
        3900: 0.5349,
        4200: 0.509,
        4500: 0.4856,
        4800: 0.4641,
        5100: 0.4445,
        5400: 0.4265,
        5700: 0.4099,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9202,
        1500: 0.8666,
        1800: 0.8118,
        2100: 0.7598,
        2400: 0.7121,
        2700: 0.6689,
        3000: 0.63,
        3300: 0.595,
        3600: 0.5634,
        3900: 0.5349,
        4200: 0.509,
        4500: 0.4856,
        4800: 0.4641,
        5100: 0.4445,
        5400: 0.4265,
        5700: 0.4099,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9202,
        1500: 0.8666,
        1800: 0.8118,
        2100: 0.7598,
        2400: 0.7121,
        2700: 0.6689,
        3000: 0.63,
        3300: 0.595,
        3600: 0.5634,
        3900: 0.5349,
        4200: 0.509,
        4500: 0.4856,
        4800: 0.4641,
        5100: 0.4445,
        5400: 0.4265,
        5700: 0.4099,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9202,
        1500: 0.8666,
        1800: 0.8118,
        2100: 0.7598,
        2400: 0.7121,
        2700: 0.6689,
        3000: 0.63,
        3300: 0.595,
        3600: 0.5634,
        3900: 0.5349,
        4200: 0.509,
        4500: 0.4856,
        4800: 0.4641,
        5100: 0.4445,
        5400: 0.4265,
        5700: 0.4099,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9202,
        1500: 0.8666,
        1800: 0.8118,
        2100: 0.7598,
        2400: 0.7121,
        2700: 0.6689,
        3000: 0.63,
        3300: 0.595,
        3600: 0.5634,
        3900: 0.5349,
        4200: 0.509,
        4500: 0.4856,
        4800: 0.4641,
        5100: 0.4445,
        5400: 0.4265,
        5700: 0.4099,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9202,
        1500: 0.8666,
        1800: 0.8118,
        2100: 0.7598,
        2400: 0.7121,
        2700: 0.6689,
        3000: 0.63,
        3300: 0.595,
        3600: 0.5634,
        3900: 0.5349,
        4200: 0.509,
        4500: 0.4856,
        4800: 0.4641,
        5100: 0.4445,
        5400: 0.4265,
        5700: 0.4099,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9202,
        1500: 0.8666,
        1800: 0.8118,
        2100: 0.7598,
        2400: 0.7121,
        2700: 0.6689,
        3000: 0.63,
        3300: 0.595,
        3600: 0.5634,
        3900: 0.5349,
        4200: 0.509,
        4500: 0.4856,
        4800: 0.4641,
        5100: 0.4445,
        5400: 0.4265,
        5700: 0.4099,
      },
    },
    4000: {
      0.1: {
        0: 0.955,
        300: 0.9469,
        600: 0.9045,
        900: 0.8529,
        1200: 0.7888,
        1500: 0.7251,
        1800: 0.667,
        2100: 0.6156,
        2400: 0.5706,
        2700: 0.5312,
        3000: 0.4968,
        3300: 0.4664,
        3600: 0.4395,
        3900: 0.4155,
        4200: 0.3939,
        4500: 0.3746,
        4800: 0.3572,
        5100: 0.3413,
        5400: 0.3268,
        5700: 0.3136,
      },
      1.1: {
        0: 0.955,
        300: 0.9521,
        600: 0.9294,
        900: 0.8913,
        1200: 0.8339,
        1500: 0.7728,
        1800: 0.7152,
        2100: 0.6631,
        2400: 0.6169,
        2700: 0.5759,
        3000: 0.5398,
        3300: 0.5077,
        3600: 0.4791,
        3900: 0.4536,
        4200: 0.4306,
        4500: 0.4099,
        4800: 0.3911,
        5100: 0.3739,
        5400: 0.3583,
        5700: 0.3439,
      },
      2.1: {
        0: 0.955,
        300: 0.9535,
        600: 0.9463,
        900: 0.9211,
        1200: 0.8709,
        1500: 0.8134,
        1800: 0.757,
        2100: 0.7049,
        2400: 0.658,
        2700: 0.616,
        3000: 0.5787,
        3300: 0.5453,
        3600: 0.5154,
        3900: 0.4886,
        4200: 0.4643,
        4500: 0.4424,
        4800: 0.4224,
        5100: 0.4042,
        5400: 0.3875,
        5700: 0.3722,
      },
      3.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9425,
        1200: 0.8994,
        1500: 0.8457,
        1800: 0.7911,
        2100: 0.7396,
        2400: 0.6926,
        2700: 0.65,
        3000: 0.6118,
        3300: 0.5775,
        3600: 0.5467,
        3900: 0.5188,
        4200: 0.4935,
        4500: 0.4707,
        4800: 0.4498,
        5100: 0.4306,
        5400: 0.4131,
        5700: 0.397,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9187,
        1500: 0.8686,
        1800: 0.8161,
        2100: 0.7654,
        2400: 0.7187,
        2700: 0.676,
        3000: 0.6374,
        3300: 0.6025,
        3600: 0.5711,
        3900: 0.5426,
        4200: 0.5166,
        4500: 0.493,
        4800: 0.4715,
        5100: 0.4517,
        5400: 0.4335,
        5700: 0.4169,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9301,
        1500: 0.8834,
        1800: 0.8328,
        2100: 0.7832,
        2400: 0.737,
        2700: 0.6944,
        3000: 0.6558,
        3300: 0.6206,
        3600: 0.5888,
        3900: 0.5599,
        4200: 0.5335,
        4500: 0.5095,
        4800: 0.4876,
        5100: 0.4673,
        5400: 0.4488,
        5700: 0.4317,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9406,
        1500: 0.8961,
        1800: 0.8469,
        2100: 0.798,
        2400: 0.752,
        2700: 0.7094,
        3000: 0.6706,
        3300: 0.6352,
        3600: 0.6031,
        3900: 0.5739,
        4200: 0.5471,
        4500: 0.5227,
        4800: 0.5004,
        5100: 0.4798,
        5400: 0.4609,
        5700: 0.4434,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9447,
        1500: 0.9019,
        1800: 0.8537,
        2100: 0.8055,
        2400: 0.7598,
        2700: 0.7174,
        3000: 0.6786,
        3300: 0.6432,
        3600: 0.611,
        3900: 0.5816,
        4200: 0.5547,
        4500: 0.5302,
        4800: 0.5076,
        5100: 0.4869,
        5400: 0.4678,
        5700: 0.4501,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9447,
        1500: 0.9019,
        1800: 0.8537,
        2100: 0.8055,
        2400: 0.7598,
        2700: 0.7174,
        3000: 0.6786,
        3300: 0.6432,
        3600: 0.611,
        3900: 0.5816,
        4200: 0.5547,
        4500: 0.5302,
        4800: 0.5076,
        5100: 0.4869,
        5400: 0.4678,
        5700: 0.4501,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9447,
        1500: 0.9019,
        1800: 0.8537,
        2100: 0.8055,
        2400: 0.7598,
        2700: 0.7174,
        3000: 0.6786,
        3300: 0.6432,
        3600: 0.611,
        3900: 0.5816,
        4200: 0.5547,
        4500: 0.5302,
        4800: 0.5076,
        5100: 0.4869,
        5400: 0.4678,
        5700: 0.4501,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9447,
        1500: 0.9019,
        1800: 0.8537,
        2100: 0.8055,
        2400: 0.7598,
        2700: 0.7174,
        3000: 0.6786,
        3300: 0.6432,
        3600: 0.611,
        3900: 0.5816,
        4200: 0.5547,
        4500: 0.5302,
        4800: 0.5076,
        5100: 0.4869,
        5400: 0.4678,
        5700: 0.4501,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9447,
        1500: 0.9019,
        1800: 0.8537,
        2100: 0.8055,
        2400: 0.7598,
        2700: 0.7174,
        3000: 0.6786,
        3300: 0.6432,
        3600: 0.611,
        3900: 0.5816,
        4200: 0.5547,
        4500: 0.5302,
        4800: 0.5076,
        5100: 0.4869,
        5400: 0.4678,
        5700: 0.4501,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9447,
        1500: 0.9019,
        1800: 0.8537,
        2100: 0.8055,
        2400: 0.7598,
        2700: 0.7174,
        3000: 0.6786,
        3300: 0.6432,
        3600: 0.611,
        3900: 0.5816,
        4200: 0.5547,
        4500: 0.5302,
        4800: 0.5076,
        5100: 0.4869,
        5400: 0.4678,
        5700: 0.4501,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9447,
        1500: 0.9019,
        1800: 0.8537,
        2100: 0.8055,
        2400: 0.7598,
        2700: 0.7174,
        3000: 0.6786,
        3300: 0.6432,
        3600: 0.611,
        3900: 0.5816,
        4200: 0.5547,
        4500: 0.5302,
        4800: 0.5076,
        5100: 0.4869,
        5400: 0.4678,
        5700: 0.4501,
      },
    },
    4500: {
      0.1: {
        0: 0.955,
        300: 0.9493,
        600: 0.9145,
        900: 0.8742,
        1200: 0.8197,
        1500: 0.7614,
        1800: 0.706,
        2100: 0.6556,
        2400: 0.6105,
        2700: 0.5706,
        3000: 0.5352,
        3300: 0.5037,
        3600: 0.4756,
        3900: 0.4504,
        4200: 0.4278,
        4500: 0.4073,
        4800: 0.3886,
        5100: 0.3718,
        5400: 0.3563,
        5700: 0.3421,
      },
      1.1: {
        0: 0.955,
        300: 0.952,
        600: 0.9345,
        900: 0.9086,
        1200: 0.8623,
        1500: 0.808,
        1800: 0.7541,
        2100: 0.7037,
        2400: 0.6579,
        2700: 0.6169,
        3000: 0.58,
        3300: 0.5471,
        3600: 0.5175,
        3900: 0.4907,
        4200: 0.4667,
        4500: 0.4448,
        4800: 0.4249,
        5100: 0.4068,
        5400: 0.3901,
        5700: 0.3747,
      },
      2.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9472,
        900: 0.9344,
        1200: 0.8965,
        1500: 0.8469,
        1800: 0.7952,
        2100: 0.7456,
        2400: 0.6997,
        2700: 0.658,
        3000: 0.6202,
        3300: 0.5862,
        3600: 0.5555,
        3900: 0.5275,
        4200: 0.5023,
        4500: 0.4793,
        4800: 0.4583,
        5100: 0.4391,
        5400: 0.4214,
        5700: 0.4051,
      },
      3.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.9521,
        1200: 0.9221,
        1500: 0.8773,
        1800: 0.8283,
        2100: 0.7799,
        2400: 0.7344,
        2700: 0.6926,
        3000: 0.6543,
        3300: 0.6196,
        3600: 0.588,
        3900: 0.5592,
        4200: 0.5331,
        4500: 0.5092,
        4800: 0.4873,
        5100: 0.4672,
        5400: 0.4487,
        5700: 0.4316,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9387,
        1500: 0.8984,
        1800: 0.852,
        2100: 0.8051,
        2400: 0.7603,
        2700: 0.7187,
        3000: 0.6803,
        3300: 0.6452,
        3600: 0.6132,
        3900: 0.5839,
        4200: 0.5572,
        4500: 0.5327,
        4800: 0.5101,
        5100: 0.4895,
        5400: 0.4704,
        5700: 0.4527,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9481,
        1500: 0.9114,
        1800: 0.8675,
        2100: 0.8221,
        2400: 0.7782,
        2700: 0.737,
        3000: 0.6987,
        3300: 0.6636,
        3600: 0.6314,
        3900: 0.6018,
        4200: 0.5748,
        4500: 0.5499,
        4800: 0.527,
        5100: 0.506,
        5400: 0.4864,
        5700: 0.4683,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.923,
        1800: 0.8808,
        2100: 0.8364,
        2400: 0.793,
        2700: 0.752,
        3000: 0.7137,
        3300: 0.6785,
        3600: 0.6461,
        3900: 0.6162,
        4200: 0.5889,
        4500: 0.5637,
        4800: 0.5404,
        5100: 0.5191,
        5400: 0.4992,
        5700: 0.4808,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9279,
        1800: 0.8869,
        2100: 0.8434,
        2400: 0.8005,
        2700: 0.7598,
        3000: 0.7217,
        3300: 0.6865,
        3600: 0.6541,
        3900: 0.6241,
        4200: 0.5967,
        4500: 0.5714,
        4800: 0.548,
        5100: 0.5265,
        5400: 0.5065,
        5700: 0.4879,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9279,
        1800: 0.8869,
        2100: 0.8434,
        2400: 0.8005,
        2700: 0.7598,
        3000: 0.7217,
        3300: 0.6865,
        3600: 0.6541,
        3900: 0.6241,
        4200: 0.5967,
        4500: 0.5714,
        4800: 0.548,
        5100: 0.5265,
        5400: 0.5065,
        5700: 0.4879,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9279,
        1800: 0.8869,
        2100: 0.8434,
        2400: 0.8005,
        2700: 0.7598,
        3000: 0.7217,
        3300: 0.6865,
        3600: 0.6541,
        3900: 0.6241,
        4200: 0.5967,
        4500: 0.5714,
        4800: 0.548,
        5100: 0.5265,
        5400: 0.5065,
        5700: 0.4879,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9279,
        1800: 0.8869,
        2100: 0.8434,
        2400: 0.8005,
        2700: 0.7598,
        3000: 0.7217,
        3300: 0.6865,
        3600: 0.6541,
        3900: 0.6241,
        4200: 0.5967,
        4500: 0.5714,
        4800: 0.548,
        5100: 0.5265,
        5400: 0.5065,
        5700: 0.4879,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9279,
        1800: 0.8869,
        2100: 0.8434,
        2400: 0.8005,
        2700: 0.7598,
        3000: 0.7217,
        3300: 0.6865,
        3600: 0.6541,
        3900: 0.6241,
        4200: 0.5967,
        4500: 0.5714,
        4800: 0.548,
        5100: 0.5265,
        5400: 0.5065,
        5700: 0.4879,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9279,
        1800: 0.8869,
        2100: 0.8434,
        2400: 0.8005,
        2700: 0.7598,
        3000: 0.7217,
        3300: 0.6865,
        3600: 0.6541,
        3900: 0.6241,
        4200: 0.5967,
        4500: 0.5714,
        4800: 0.548,
        5100: 0.5265,
        5400: 0.5065,
        5700: 0.4879,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9279,
        1800: 0.8869,
        2100: 0.8434,
        2400: 0.8005,
        2700: 0.7598,
        3000: 0.7217,
        3300: 0.6865,
        3600: 0.6541,
        3900: 0.6241,
        4200: 0.5967,
        4500: 0.5714,
        4800: 0.548,
        5100: 0.5265,
        5400: 0.5065,
        5700: 0.4879,
      },
    },
    5000: {
      0.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9224,
        900: 0.8897,
        1200: 0.8441,
        1500: 0.7919,
        1800: 0.7398,
        2100: 0.6911,
        2400: 0.6466,
        2700: 0.6066,
        3000: 0.5706,
        3300: 0.5384,
        3600: 0.5094,
        3900: 0.4833,
        4200: 0.4597,
        4500: 0.4382,
        4800: 0.4187,
        5100: 0.4009,
        5400: 0.3845,
        5700: 0.3695,
      },
      1.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9381,
        900: 0.92,
        1200: 0.8838,
        1500: 0.8368,
        1800: 0.7871,
        2100: 0.7392,
        2400: 0.6946,
        2700: 0.6538,
        3000: 0.6169,
        3300: 0.5834,
        3600: 0.553,
        3900: 0.5256,
        4200: 0.5006,
        4500: 0.4778,
        4800: 0.457,
        5100: 0.438,
        5400: 0.4204,
        5700: 0.4043,
      },
      2.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9472,
        900: 0.9419,
        1200: 0.9149,
        1500: 0.8736,
        1800: 0.8271,
        2100: 0.7807,
        2400: 0.7366,
        2700: 0.6956,
        3000: 0.658,
        3300: 0.6237,
        3600: 0.5924,
        3900: 0.564,
        4200: 0.5379,
        4500: 0.514,
        4800: 0.4922,
        5100: 0.4721,
        5400: 0.4536,
        5700: 0.4364,
      },
      3.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9376,
        1500: 0.9018,
        1800: 0.8587,
        2100: 0.8142,
        2400: 0.771,
        2700: 0.7303,
        3000: 0.6926,
        3300: 0.6578,
        3600: 0.6259,
        3900: 0.5967,
        4200: 0.5699,
        4500: 0.5452,
        4800: 0.5226,
        5100: 0.5017,
        5400: 0.4824,
        5700: 0.4645,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9517,
        1500: 0.9208,
        1800: 0.881,
        2100: 0.8385,
        2400: 0.7964,
        2700: 0.7562,
        3000: 0.7187,
        3300: 0.6839,
        3600: 0.6516,
        3900: 0.6221,
        4200: 0.5948,
        4500: 0.5696,
        4800: 0.5464,
        5100: 0.525,
        5400: 0.5051,
        5700: 0.4867,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9321,
        1800: 0.8951,
        2100: 0.8545,
        2400: 0.8136,
        2700: 0.7742,
        3000: 0.737,
        3300: 0.7023,
        3600: 0.6701,
        3900: 0.6404,
        4200: 0.6128,
        4500: 0.5873,
        4800: 0.5638,
        5100: 0.5421,
        5400: 0.5218,
        5700: 0.503,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9424,
        1800: 0.9074,
        2100: 0.8681,
        2400: 0.828,
        2700: 0.789,
        3000: 0.752,
        3300: 0.7173,
        3600: 0.685,
        3900: 0.6551,
        4200: 0.6273,
        4500: 0.6016,
        4800: 0.5778,
        5100: 0.5557,
        5400: 0.5352,
        5700: 0.5161,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9465,
        1800: 0.9128,
        2100: 0.8745,
        2400: 0.8351,
        2700: 0.7966,
        3000: 0.7598,
        3300: 0.7253,
        3600: 0.693,
        3900: 0.6631,
        4200: 0.6353,
        4500: 0.6095,
        4800: 0.5856,
        5100: 0.5634,
        5400: 0.5427,
        5700: 0.5235,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9465,
        1800: 0.9128,
        2100: 0.8745,
        2400: 0.8351,
        2700: 0.7966,
        3000: 0.7598,
        3300: 0.7253,
        3600: 0.693,
        3900: 0.6631,
        4200: 0.6353,
        4500: 0.6095,
        4800: 0.5856,
        5100: 0.5634,
        5400: 0.5427,
        5700: 0.5235,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9465,
        1800: 0.9128,
        2100: 0.8745,
        2400: 0.8351,
        2700: 0.7966,
        3000: 0.7598,
        3300: 0.7253,
        3600: 0.693,
        3900: 0.6631,
        4200: 0.6353,
        4500: 0.6095,
        4800: 0.5856,
        5100: 0.5634,
        5400: 0.5427,
        5700: 0.5235,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9465,
        1800: 0.9128,
        2100: 0.8745,
        2400: 0.8351,
        2700: 0.7966,
        3000: 0.7598,
        3300: 0.7253,
        3600: 0.693,
        3900: 0.6631,
        4200: 0.6353,
        4500: 0.6095,
        4800: 0.5856,
        5100: 0.5634,
        5400: 0.5427,
        5700: 0.5235,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9465,
        1800: 0.9128,
        2100: 0.8745,
        2400: 0.8351,
        2700: 0.7966,
        3000: 0.7598,
        3300: 0.7253,
        3600: 0.693,
        3900: 0.6631,
        4200: 0.6353,
        4500: 0.6095,
        4800: 0.5856,
        5100: 0.5634,
        5400: 0.5427,
        5700: 0.5235,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9465,
        1800: 0.9128,
        2100: 0.8745,
        2400: 0.8351,
        2700: 0.7966,
        3000: 0.7598,
        3300: 0.7253,
        3600: 0.693,
        3900: 0.6631,
        4200: 0.6353,
        4500: 0.6095,
        4800: 0.5856,
        5100: 0.5634,
        5400: 0.5427,
        5700: 0.5235,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9465,
        1800: 0.9128,
        2100: 0.8745,
        2400: 0.8351,
        2700: 0.7966,
        3000: 0.7598,
        3300: 0.7253,
        3600: 0.693,
        3900: 0.6631,
        4200: 0.6353,
        4500: 0.6095,
        4800: 0.5856,
        5100: 0.5634,
        5400: 0.5427,
        5700: 0.5235,
      },
    },
    5500: {
      0.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9295,
        900: 0.9009,
        1200: 0.8632,
        1500: 0.8171,
        1800: 0.7689,
        2100: 0.7224,
        2400: 0.679,
        2700: 0.6393,
        3000: 0.6033,
        3300: 0.5706,
        3600: 0.5411,
        3900: 0.5142,
        4200: 0.4898,
        4500: 0.4676,
        4800: 0.4473,
        5100: 0.4287,
        5400: 0.4115,
        5700: 0.3957,
      },
      1.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9416,
        900: 0.9272,
        1200: 0.8998,
        1500: 0.86,
        1800: 0.8151,
        2100: 0.7702,
        2400: 0.7272,
        2700: 0.6872,
        3000: 0.6505,
        3300: 0.6169,
        3600: 0.5862,
        3900: 0.5581,
        4200: 0.5325,
        4500: 0.509,
        4800: 0.4875,
        5100: 0.4677,
        5400: 0.4494,
        5700: 0.4325,
      },
      2.1: {
        0: 0.955,
        300: 0.955,
        600: 0.9479,
        900: 0.9456,
        1200: 0.9279,
        1500: 0.8945,
        1800: 0.8536,
        2100: 0.8108,
        2400: 0.7689,
        2700: 0.7291,
        3000: 0.6922,
        3300: 0.658,
        3600: 0.6266,
        3900: 0.5976,
        4200: 0.5711,
        4500: 0.5466,
        4800: 0.5242,
        5100: 0.5033,
        5400: 0.4841,
        5700: 0.4663,
      },
      3.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.9476,
        1500: 0.9203,
        1800: 0.8835,
        2100: 0.8433,
        2400: 0.8027,
        2700: 0.7637,
        3000: 0.7269,
        3300: 0.6926,
        3600: 0.6607,
        3900: 0.6312,
        4200: 0.604,
        4500: 0.5789,
        4800: 0.5557,
        5100: 0.5342,
        5400: 0.5142,
        5700: 0.4956,
      },
      4.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9372,
        1800: 0.9041,
        2100: 0.8663,
        2400: 0.8273,
        2700: 0.7892,
        3000: 0.7529,
        3300: 0.7187,
        3600: 0.6868,
        3900: 0.657,
        4200: 0.6295,
        4500: 0.604,
        4800: 0.5803,
        5100: 0.5583,
        5400: 0.5378,
        5700: 0.5188,
      },
      5.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.9467,
        1800: 0.9167,
        2100: 0.8812,
        2400: 0.8437,
        2700: 0.8066,
        3000: 0.7708,
        3300: 0.737,
        3600: 0.7052,
        3900: 0.6755,
        4200: 0.6478,
        4500: 0.6221,
        4800: 0.5982,
        5100: 0.5759,
        5400: 0.5551,
        5700: 0.5357,
      },
      6.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.928,
        2100: 0.894,
        2400: 0.8576,
        2700: 0.8211,
        3000: 0.7857,
        3300: 0.752,
        3600: 0.7202,
        3900: 0.6904,
        4200: 0.6626,
        4500: 0.6367,
        4800: 0.6126,
        5100: 0.59,
        5400: 0.569,
        5700: 0.5493,
      },
      7.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.9327,
        2100: 0.8999,
        2400: 0.8642,
        2700: 0.8283,
        3000: 0.7933,
        3300: 0.7598,
        3600: 0.7282,
        3900: 0.6984,
        4200: 0.6706,
        4500: 0.6447,
        4800: 0.6205,
        5100: 0.5978,
        5400: 0.5767,
        5700: 0.5569,
      },
      8.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.9327,
        2100: 0.8999,
        2400: 0.8642,
        2700: 0.8283,
        3000: 0.7933,
        3300: 0.7598,
        3600: 0.7282,
        3900: 0.6984,
        4200: 0.6706,
        4500: 0.6447,
        4800: 0.6205,
        5100: 0.5978,
        5400: 0.5767,
        5700: 0.5569,
      },
      9.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.9327,
        2100: 0.8999,
        2400: 0.8642,
        2700: 0.8283,
        3000: 0.7933,
        3300: 0.7598,
        3600: 0.7282,
        3900: 0.6984,
        4200: 0.6706,
        4500: 0.6447,
        4800: 0.6205,
        5100: 0.5978,
        5400: 0.5767,
        5700: 0.5569,
      },
      10.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.9327,
        2100: 0.8999,
        2400: 0.8642,
        2700: 0.8283,
        3000: 0.7933,
        3300: 0.7598,
        3600: 0.7282,
        3900: 0.6984,
        4200: 0.6706,
        4500: 0.6447,
        4800: 0.6205,
        5100: 0.5978,
        5400: 0.5767,
        5700: 0.5569,
      },
      11.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.9327,
        2100: 0.8999,
        2400: 0.8642,
        2700: 0.8283,
        3000: 0.7933,
        3300: 0.7598,
        3600: 0.7282,
        3900: 0.6984,
        4200: 0.6706,
        4500: 0.6447,
        4800: 0.6205,
        5100: 0.5978,
        5400: 0.5767,
        5700: 0.5569,
      },
      12.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.9327,
        2100: 0.8999,
        2400: 0.8642,
        2700: 0.8283,
        3000: 0.7933,
        3300: 0.7598,
        3600: 0.7282,
        3900: 0.6984,
        4200: 0.6706,
        4500: 0.6447,
        4800: 0.6205,
        5100: 0.5978,
        5400: 0.5767,
        5700: 0.5569,
      },
      13.1: {
        0: 0.955,
        300: 0.955,
        600: 0.955,
        900: 0.955,
        1200: 0.955,
        1500: 0.955,
        1800: 0.9327,
        2100: 0.8999,
        2400: 0.8642,
        2700: 0.8283,
        3000: 0.7933,
        3300: 0.7598,
        3600: 0.7282,
        3900: 0.6984,
        4200: 0.6706,
        4500: 0.6447,
        4800: 0.6205,
        5100: 0.5978,
        5400: 0.5767,
        5700: 0.5569,
      },
    },
  },
};

/*
		ZONE_DATA
			{zone : { orientation : [inclincation,...]}
*/
export const ZONE_DATA = {
  '1': {
    0: [
      828,
      835,
      843,
      850,
      857,
      864,
      871,
      878,
      884,
      890,
      896,
      902,
      908,
      914,
      919,
      924,
      929,
      934,
      938,
      943,
      947,
      951,
      954,
      958,
      961,
      964,
      967,
      970,
      972,
      975,
      977,
      979,
      980,
      982,
      983,
      984,
      984,
      985,
      985,
      985,
      985,
      984,
      984,
      983,
      982,
      980,
      979,
      977,
      975,
      973,
      970,
      968,
      965,
      961,
      958,
      954,
      951,
      946,
      942,
      938,
      933,
      928,
      923,
      917,
      912,
      906,
      900,
      893,
      887,
      880,
      873,
      866,
      859,
      852,
      844,
      836,
      828,
      819,
      811,
      802,
      793,
      784,
      775,
      765,
      756,
      746,
      736,
      726,
      716,
      705,
      695,
    ],
    5: [
      828,
      835,
      843,
      850,
      857,
      864,
      871,
      877,
      884,
      890,
      896,
      902,
      908,
      913,
      919,
      924,
      929,
      933,
      938,
      942,
      946,
      950,
      954,
      957,
      961,
      964,
      967,
      969,
      972,
      974,
      976,
      978,
      979,
      981,
      982,
      983,
      984,
      984,
      984,
      984,
      984,
      984,
      983,
      982,
      981,
      980,
      978,
      976,
      974,
      972,
      969,
      967,
      964,
      961,
      957,
      954,
      950,
      946,
      941,
      937,
      932,
      927,
      922,
      916,
      911,
      905,
      899,
      893,
      886,
      879,
      873,
      865,
      858,
      851,
      843,
      835,
      827,
      819,
      810,
      801,
      793,
      784,
      774,
      765,
      755,
      745,
      736,
      725,
      715,
      705,
      694,
    ],
    10: [
      828,
      835,
      843,
      850,
      857,
      864,
      870,
      877,
      883,
      889,
      895,
      901,
      907,
      912,
      917,
      922,
      927,
      932,
      936,
      941,
      945,
      949,
      952,
      956,
      959,
      962,
      965,
      968,
      970,
      972,
      974,
      976,
      977,
      979,
      980,
      981,
      981,
      982,
      982,
      982,
      982,
      981,
      981,
      980,
      979,
      977,
      976,
      974,
      972,
      969,
      967,
      964,
      961,
      958,
      955,
      951,
      947,
      943,
      939,
      934,
      929,
      925,
      919,
      914,
      908,
      902,
      896,
      890,
      884,
      877,
      870,
      863,
      856,
      848,
      841,
      833,
      825,
      816,
      808,
      799,
      790,
      781,
      772,
      763,
      753,
      743,
      733,
      724,
      713,
      703,
      692,
    ],
    15: [
      828,
      835,
      842,
      849,
      856,
      863,
      869,
      876,
      882,
      888,
      894,
      900,
      905,
      910,
      916,
      921,
      925,
      930,
      934,
      938,
      942,
      946,
      950,
      953,
      956,
      959,
      962,
      965,
      967,
      969,
      971,
      973,
      974,
      975,
      976,
      977,
      978,
      978,
      978,
      978,
      978,
      977,
      977,
      976,
      975,
      973,
      971,
      970,
      967,
      965,
      963,
      960,
      957,
      954,
      950,
      947,
      943,
      939,
      934,
      930,
      925,
      920,
      915,
      910,
      904,
      898,
      892,
      886,
      879,
      873,
      866,
      859,
      852,
      844,
      837,
      829,
      821,
      812,
      804,
      795,
      787,
      778,
      769,
      759,
      750,
      740,
      730,
      720,
      710,
      700,
      689,
    ],
    20: [
      828,
      835,
      842,
      849,
      855,
      862,
      868,
      874,
      881,
      886,
      892,
      898,
      903,
      908,
      913,
      918,
      922,
      927,
      931,
      935,
      939,
      943,
      946,
      949,
      952,
      955,
      958,
      960,
      962,
      964,
      966,
      968,
      969,
      970,
      971,
      972,
      973,
      973,
      973,
      973,
      973,
      972,
      971,
      970,
      969,
      967,
      966,
      964,
      962,
      959,
      957,
      954,
      951,
      948,
      944,
      941,
      937,
      933,
      928,
      924,
      919,
      914,
      909,
      904,
      898,
      892,
      886,
      880,
      873,
      867,
      860,
      853,
      846,
      838,
      831,
      823,
      815,
      807,
      799,
      790,
      781,
      772,
      763,
      754,
      745,
      735,
      725,
      716,
      706,
      696,
      685,
    ],
    25: [
      828,
      835,
      841,
      848,
      854,
      861,
      867,
      873,
      879,
      884,
      890,
      895,
      900,
      905,
      910,
      914,
      919,
      923,
      927,
      931,
      935,
      938,
      941,
      944,
      947,
      950,
      953,
      955,
      957,
      959,
      960,
      962,
      963,
      964,
      965,
      966,
      966,
      966,
      966,
      966,
      966,
      965,
      964,
      963,
      962,
      960,
      958,
      956,
      954,
      952,
      949,
      946,
      943,
      940,
      937,
      933,
      929,
      925,
      920,
      916,
      911,
      906,
      901,
      896,
      890,
      884,
      878,
      872,
      866,
      859,
      852,
      846,
      838,
      831,
      823,
      816,
      808,
      800,
      791,
      783,
      774,
      765,
      757,
      748,
      738,
      729,
      719,
      709,
      700,
      689,
      679,
    ],
    30: [
      828,
      834,
      841,
      847,
      853,
      859,
      865,
      871,
      876,
      882,
      887,
      892,
      897,
      901,
      906,
      910,
      914,
      918,
      922,
      926,
      929,
      933,
      936,
      939,
      941,
      944,
      946,
      948,
      950,
      952,
      953,
      955,
      956,
      957,
      957,
      958,
      958,
      958,
      958,
      958,
      957,
      956,
      955,
      954,
      953,
      951,
      949,
      947,
      945,
      943,
      940,
      937,
      934,
      931,
      927,
      923,
      919,
      915,
      911,
      906,
      902,
      897,
      891,
      886,
      881,
      875,
      869,
      863,
      856,
      850,
      843,
      836,
      829,
      822,
      814,
      807,
      799,
      791,
      783,
      774,
      766,
      757,
      748,
      739,
      730,
      721,
      711,
      702,
      692,
      682,
      672,
    ],
    35: [
      828,
      834,
      840,
      846,
      852,
      857,
      863,
      868,
      873,
      878,
      883,
      888,
      893,
      897,
      901,
      905,
      909,
      913,
      917,
      920,
      923,
      926,
      929,
      932,
      934,
      937,
      939,
      941,
      942,
      944,
      945,
      946,
      947,
      948,
      948,
      949,
      949,
      949,
      949,
      948,
      947,
      946,
      945,
      944,
      943,
      941,
      939,
      937,
      934,
      932,
      929,
      926,
      923,
      920,
      916,
      912,
      908,
      904,
      900,
      895,
      890,
      885,
      880,
      875,
      869,
      864,
      858,
      851,
      845,
      839,
      832,
      825,
      818,
      811,
      803,
      796,
      788,
      780,
      772,
      764,
      756,
      747,
      738,
      729,
      721,
      711,
      702,
      692,
      683,
      673,
      663,
    ],
    40: [
      828,
      833,
      839,
      845,
      850,
      855,
      861,
      866,
      870,
      875,
      880,
      884,
      888,
      892,
      896,
      900,
      903,
      907,
      910,
      913,
      916,
      919,
      922,
      924,
      926,
      928,
      930,
      932,
      933,
      935,
      936,
      937,
      937,
      938,
      938,
      938,
      938,
      938,
      938,
      937,
      936,
      935,
      934,
      932,
      931,
      929,
      927,
      925,
      922,
      920,
      917,
      914,
      910,
      907,
      903,
      900,
      895,
      891,
      887,
      882,
      877,
      873,
      867,
      862,
      856,
      851,
      845,
      839,
      832,
      826,
      819,
      812,
      805,
      798,
      791,
      783,
      776,
      768,
      760,
      752,
      743,
      735,
      726,
      718,
      709,
      700,
      691,
      682,
      672,
      663,
      653,
    ],
    45: [
      828,
      833,
      838,
      843,
      848,
      853,
      858,
      862,
      867,
      871,
      875,
      879,
      883,
      887,
      890,
      894,
      897,
      900,
      903,
      906,
      908,
      911,
      913,
      915,
      917,
      919,
      921,
      922,
      923,
      924,
      925,
      926,
      926,
      927,
      927,
      927,
      927,
      926,
      925,
      925,
      924,
      922,
      921,
      919,
      918,
      916,
      914,
      911,
      909,
      906,
      903,
      900,
      896,
      893,
      889,
      885,
      881,
      877,
      872,
      868,
      863,
      858,
      853,
      847,
      842,
      836,
      830,
      824,
      818,
      811,
      805,
      798,
      791,
      784,
      777,
      769,
      762,
      754,
      746,
      738,
      730,
      722,
      713,
      705,
      696,
      687,
      678,
      669,
      660,
      651,
      641,
    ],
    50: [
      828,
      833,
      837,
      842,
      846,
      851,
      855,
      859,
      863,
      867,
      871,
      874,
      878,
      881,
      884,
      887,
      890,
      893,
      895,
      898,
      900,
      902,
      904,
      906,
      907,
      909,
      910,
      911,
      912,
      913,
      914,
      914,
      914,
      914,
      914,
      914,
      914,
      913,
      912,
      911,
      910,
      909,
      907,
      905,
      903,
      901,
      899,
      896,
      894,
      891,
      888,
      885,
      881,
      878,
      874,
      870,
      866,
      861,
      857,
      852,
      847,
      842,
      837,
      832,
      826,
      820,
      814,
      808,
      802,
      796,
      789,
      782,
      775,
      768,
      761,
      754,
      747,
      739,
      731,
      723,
      715,
      707,
      699,
      691,
      682,
      673,
      664,
      656,
      647,
      637,
      628,
    ],
    55: [
      828,
      832,
      836,
      840,
      844,
      848,
      852,
      856,
      859,
      862,
      866,
      869,
      872,
      875,
      877,
      880,
      882,
      885,
      887,
      889,
      891,
      893,
      894,
      896,
      897,
      898,
      899,
      900,
      900,
      901,
      901,
      901,
      901,
      901,
      901,
      900,
      900,
      899,
      898,
      897,
      895,
      894,
      892,
      890,
      888,
      886,
      883,
      880,
      878,
      875,
      871,
      868,
      865,
      861,
      857,
      853,
      849,
      844,
      840,
      835,
      830,
      825,
      820,
      814,
      808,
      803,
      797,
      791,
      785,
      778,
      772,
      765,
      759,
      752,
      745,
      737,
      730,
      722,
      715,
      707,
      699,
      691,
      683,
      675,
      666,
      658,
      649,
      640,
      632,
      623,
      614,
    ],
    60: [
      828,
      831,
      835,
      839,
      842,
      845,
      849,
      852,
      855,
      857,
      860,
      863,
      865,
      868,
      870,
      872,
      874,
      876,
      878,
      879,
      881,
      882,
      883,
      885,
      886,
      886,
      887,
      887,
      888,
      888,
      888,
      888,
      888,
      887,
      886,
      886,
      885,
      884,
      882,
      881,
      879,
      878,
      876,
      873,
      871,
      869,
      866,
      863,
      860,
      857,
      854,
      851,
      847,
      843,
      839,
      835,
      831,
      826,
      822,
      817,
      812,
      807,
      801,
      796,
      790,
      785,
      779,
      773,
      767,
      761,
      754,
      747,
      740,
      734,
      726,
      719,
      712,
      705,
      697,
      690,
      682,
      674,
      666,
      658,
      650,
      642,
      633,
      624,
      616,
      607,
      599,
    ],
    65: [
      828,
      831,
      834,
      837,
      840,
      843,
      845,
      848,
      850,
      852,
      855,
      857,
      859,
      861,
      862,
      864,
      866,
      867,
      868,
      870,
      870,
      871,
      872,
      873,
      873,
      874,
      874,
      874,
      874,
      874,
      874,
      873,
      873,
      872,
      871,
      870,
      869,
      868,
      866,
      864,
      863,
      861,
      858,
      856,
      854,
      851,
      848,
      845,
      842,
      839,
      836,
      832,
      828,
      824,
      820,
      816,
      812,
      807,
      802,
      798,
      793,
      787,
      782,
      777,
      771,
      765,
      759,
      753,
      747,
      741,
      735,
      728,
      721,
      715,
      708,
      700,
      693,
      686,
      679,
      671,
      663,
      656,
      648,
      640,
      632,
      624,
      616,
      607,
      599,
      591,
      582,
    ],
    70: [
      828,
      830,
      833,
      835,
      837,
      839,
      841,
      843,
      845,
      847,
      849,
      850,
      852,
      853,
      854,
      855,
      856,
      857,
      858,
      859,
      859,
      860,
      860,
      861,
      861,
      861,
      861,
      861,
      860,
      860,
      859,
      858,
      857,
      856,
      855,
      854,
      852,
      851,
      849,
      847,
      845,
      843,
      841,
      838,
      835,
      833,
      830,
      826,
      823,
      820,
      816,
      812,
      809,
      805,
      800,
      796,
      792,
      787,
      782,
      777,
      772,
      767,
      762,
      756,
      751,
      745,
      739,
      733,
      727,
      721,
      714,
      708,
      701,
      695,
      688,
      681,
      673,
      666,
      659,
      652,
      644,
      637,
      629,
      621,
      613,
      605,
      597,
      590,
      581,
      573,
      565,
    ],
    75: [
      828,
      830,
      831,
      833,
      835,
      836,
      838,
      839,
      840,
      841,
      842,
      843,
      844,
      845,
      846,
      846,
      847,
      848,
      848,
      848,
      848,
      848,
      848,
      848,
      848,
      847,
      847,
      846,
      845,
      845,
      844,
      843,
      841,
      840,
      838,
      837,
      835,
      833,
      831,
      829,
      827,
      824,
      822,
      819,
      816,
      813,
      810,
      807,
      803,
      800,
      796,
      792,
      788,
      784,
      780,
      775,
      771,
      766,
      761,
      756,
      751,
      746,
      741,
      735,
      730,
      724,
      718,
      712,
      706,
      700,
      693,
      687,
      680,
      674,
      667,
      660,
      653,
      645,
      639,
      632,
      625,
      617,
      609,
      602,
      594,
      586,
      578,
      570,
      563,
      554,
      546,
    ],
    80: [
      828,
      829,
      830,
      831,
      832,
      833,
      834,
      835,
      835,
      836,
      836,
      836,
      837,
      837,
      837,
      837,
      837,
      837,
      837,
      837,
      836,
      836,
      835,
      835,
      834,
      833,
      833,
      831,
      830,
      829,
      828,
      826,
      825,
      823,
      821,
      819,
      817,
      815,
      813,
      810,
      808,
      805,
      802,
      799,
      796,
      793,
      790,
      786,
      783,
      779,
      775,
      771,
      767,
      763,
      758,
      754,
      749,
      744,
      740,
      735,
      729,
      724,
      719,
      713,
      708,
      702,
      696,
      690,
      684,
      678,
      671,
      665,
      659,
      652,
      645,
      639,
      632,
      625,
      617,
      611,
      604,
      597,
      588,
      581,
      574,
      566,
      558,
      551,
      543,
      535,
      527,
    ],
    85: [
      828,
      828,
      829,
      829,
      830,
      830,
      830,
      830,
      830,
      830,
      830,
      829,
      829,
      829,
      828,
      828,
      827,
      827,
      826,
      825,
      824,
      823,
      822,
      821,
      820,
      819,
      818,
      816,
      815,
      813,
      811,
      810,
      808,
      806,
      803,
      801,
      799,
      796,
      794,
      791,
      788,
      785,
      782,
      779,
      776,
      773,
      769,
      765,
      762,
      758,
      754,
      750,
      745,
      741,
      736,
      732,
      727,
      722,
      717,
      712,
      707,
      702,
      696,
      691,
      685,
      679,
      673,
      667,
      661,
      655,
      649,
      643,
      636,
      630,
      623,
      616,
      610,
      603,
      596,
      589,
      582,
      575,
      568,
      560,
      553,
      546,
      539,
      531,
      523,
      515,
      508,
    ],
    90: [
      828,
      828,
      827,
      827,
      827,
      826,
      826,
      825,
      825,
      824,
      823,
      822,
      821,
      820,
      819,
      818,
      817,
      816,
      815,
      813,
      812,
      811,
      809,
      807,
      806,
      804,
      802,
      801,
      799,
      797,
      795,
      792,
      790,
      788,
      785,
      783,
      780,
      777,
      775,
      771,
      768,
      765,
      762,
      758,
      755,
      751,
      748,
      744,
      740,
      736,
      732,
      727,
      723,
      718,
      714,
      709,
      704,
      699,
      694,
      689,
      684,
      679,
      673,
      668,
      662,
      656,
      650,
      645,
      639,
      632,
      626,
      620,
      614,
      607,
      601,
      594,
      587,
      580,
      574,
      567,
      560,
      553,
      546,
      539,
      531,
      524,
      517,
      510,
      502,
      495,
      488,
    ],
    95: [
      828,
      827,
      826,
      825,
      824,
      823,
      822,
      821,
      819,
      818,
      817,
      815,
      813,
      812,
      810,
      809,
      807,
      805,
      803,
      802,
      799,
      798,
      795,
      794,
      791,
      789,
      787,
      785,
      782,
      780,
      777,
      775,
      772,
      769,
      767,
      764,
      761,
      758,
      755,
      751,
      748,
      745,
      741,
      738,
      734,
      730,
      726,
      722,
      718,
      714,
      709,
      705,
      700,
      696,
      691,
      686,
      681,
      676,
      671,
      666,
      661,
      655,
      650,
      644,
      638,
      633,
      627,
      621,
      615,
      609,
      603,
      596,
      590,
      584,
      577,
      571,
      565,
      558,
      551,
      544,
      538,
      531,
      524,
      517,
      510,
      503,
      496,
      489,
      482,
      474,
      467,
    ],
    100: [
      828,
      826,
      825,
      823,
      821,
      820,
      818,
      816,
      814,
      812,
      810,
      808,
      806,
      803,
      801,
      799,
      796,
      794,
      792,
      790,
      787,
      784,
      782,
      779,
      777,
      774,
      772,
      769,
      766,
      763,
      760,
      757,
      754,
      751,
      748,
      745,
      741,
      738,
      734,
      731,
      727,
      724,
      720,
      716,
      712,
      708,
      704,
      700,
      695,
      691,
      687,
      682,
      677,
      673,
      668,
      663,
      658,
      653,
      648,
      642,
      637,
      632,
      626,
      620,
      615,
      609,
      603,
      597,
      591,
      585,
      579,
      573,
      567,
      561,
      554,
      548,
      541,
      535,
      528,
      521,
      515,
      509,
      502,
      495,
      488,
      481,
      474,
      467,
      461,
      454,
      447,
    ],
    105: [
      828,
      826,
      823,
      821,
      819,
      816,
      814,
      811,
      809,
      806,
      803,
      801,
      798,
      795,
      792,
      789,
      786,
      783,
      780,
      777,
      774,
      771,
      768,
      765,
      762,
      759,
      756,
      752,
      749,
      746,
      743,
      739,
      736,
      733,
      729,
      726,
      722,
      718,
      714,
      711,
      707,
      703,
      699,
      695,
      690,
      686,
      682,
      678,
      673,
      668,
      664,
      659,
      654,
      649,
      644,
      639,
      634,
      629,
      624,
      619,
      613,
      608,
      602,
      597,
      591,
      585,
      579,
      574,
      568,
      562,
      556,
      550,
      543,
      537,
      531,
      525,
      518,
      512,
      506,
      499,
      492,
      486,
      479,
      473,
      466,
      460,
      453,
      446,
      439,
      433,
      426,
    ],
    110: [
      828,
      825,
      822,
      819,
      816,
      813,
      810,
      807,
      804,
      800,
      797,
      794,
      790,
      787,
      783,
      780,
      776,
      773,
      769,
      766,
      762,
      758,
      755,
      751,
      748,
      744,
      740,
      737,
      733,
      729,
      725,
      722,
      718,
      714,
      710,
      706,
      702,
      698,
      694,
      690,
      686,
      682,
      677,
      673,
      669,
      664,
      660,
      655,
      651,
      646,
      641,
      636,
      631,
      626,
      621,
      616,
      611,
      606,
      600,
      595,
      590,
      584,
      579,
      573,
      567,
      561,
      556,
      550,
      544,
      538,
      532,
      526,
      520,
      514,
      508,
      502,
      495,
      489,
      483,
      476,
      470,
      464,
      457,
      451,
      445,
      438,
      431,
      425,
      419,
      412,
      405,
    ],
    115: [
      828,
      824,
      821,
      817,
      814,
      810,
      806,
      803,
      799,
      795,
      791,
      787,
      783,
      779,
      774,
      770,
      766,
      762,
      758,
      754,
      750,
      745,
      742,
      737,
      733,
      729,
      725,
      721,
      717,
      712,
      708,
      704,
      700,
      696,
      691,
      687,
      683,
      679,
      674,
      670,
      665,
      661,
      656,
      652,
      647,
      642,
      638,
      633,
      628,
      623,
      618,
      613,
      608,
      603,
      598,
      593,
      588,
      582,
      577,
      572,
      566,
      560,
      555,
      549,
      544,
      538,
      532,
      526,
      520,
      515,
      509,
      503,
      497,
      491,
      485,
      479,
      472,
      466,
      460,
      454,
      448,
      442,
      435,
      429,
      423,
      417,
      410,
      404,
      398,
      392,
      385,
    ],
    120: [
      828,
      824,
      820,
      816,
      811,
      807,
      803,
      798,
      794,
      789,
      785,
      780,
      775,
      771,
      766,
      761,
      757,
      752,
      747,
      742,
      738,
      733,
      729,
      724,
      719,
      714,
      710,
      705,
      701,
      696,
      691,
      687,
      682,
      678,
      673,
      668,
      664,
      659,
      655,
      650,
      645,
      640,
      635,
      631,
      626,
      621,
      616,
      611,
      606,
      601,
      596,
      591,
      586,
      581,
      575,
      570,
      565,
      559,
      554,
      548,
      543,
      537,
      532,
      526,
      521,
      515,
      509,
      503,
      497,
      492,
      486,
      480,
      474,
      468,
      462,
      456,
      450,
      444,
      438,
      432,
      426,
      420,
      414,
      408,
      402,
      396,
      390,
      383,
      378,
      372,
      365,
    ],
    125: [
      828,
      823,
      819,
      814,
      809,
      804,
      799,
      794,
      789,
      784,
      779,
      774,
      769,
      763,
      758,
      753,
      747,
      742,
      737,
      732,
      726,
      721,
      716,
      711,
      706,
      700,
      695,
      690,
      685,
      680,
      675,
      670,
      665,
      660,
      655,
      650,
      645,
      640,
      635,
      630,
      625,
      620,
      615,
      610,
      605,
      600,
      595,
      590,
      585,
      579,
      574,
      569,
      564,
      558,
      553,
      548,
      542,
      537,
      531,
      526,
      520,
      515,
      509,
      503,
      498,
      492,
      486,
      481,
      475,
      469,
      463,
      458,
      452,
      446,
      441,
      434,
      428,
      423,
      417,
      411,
      405,
      399,
      393,
      387,
      381,
      376,
      370,
      364,
      358,
      352,
      346,
    ],
    130: [
      828,
      823,
      818,
      812,
      807,
      802,
      796,
      791,
      785,
      779,
      773,
      768,
      762,
      756,
      750,
      745,
      739,
      733,
      727,
      721,
      716,
      710,
      704,
      698,
      693,
      687,
      682,
      676,
      670,
      665,
      659,
      654,
      648,
      643,
      638,
      632,
      627,
      622,
      616,
      611,
      606,
      601,
      595,
      590,
      585,
      579,
      574,
      569,
      564,
      558,
      553,
      548,
      542,
      537,
      531,
      526,
      520,
      515,
      509,
      504,
      498,
      493,
      487,
      482,
      476,
      470,
      465,
      459,
      453,
      448,
      442,
      436,
      431,
      425,
      419,
      413,
      408,
      402,
      397,
      390,
      385,
      379,
      373,
      368,
      362,
      356,
      351,
      345,
      339,
      334,
      328,
    ],
    135: [
      828,
      822,
      817,
      811,
      805,
      799,
      793,
      787,
      781,
      775,
      768,
      762,
      756,
      750,
      743,
      737,
      731,
      724,
      718,
      712,
      705,
      699,
      693,
      687,
      681,
      675,
      668,
      662,
      656,
      651,
      644,
      639,
      633,
      627,
      621,
      616,
      610,
      604,
      598,
      593,
      587,
      582,
      576,
      571,
      565,
      560,
      554,
      549,
      543,
      538,
      532,
      527,
      521,
      516,
      510,
      505,
      499,
      494,
      488,
      483,
      477,
      472,
      466,
      461,
      455,
      449,
      444,
      438,
      433,
      427,
      421,
      416,
      410,
      404,
      399,
      393,
      388,
      382,
      377,
      371,
      366,
      360,
      355,
      349,
      343,
      338,
      332,
      327,
      322,
      316,
      311,
    ],
    140: [
      828,
      822,
      816,
      809,
      803,
      797,
      790,
      784,
      777,
      770,
      764,
      757,
      750,
      743,
      737,
      730,
      723,
      716,
      710,
      703,
      696,
      690,
      683,
      676,
      670,
      663,
      657,
      650,
      644,
      637,
      631,
      625,
      618,
      612,
      606,
      600,
      594,
      588,
      582,
      576,
      570,
      564,
      558,
      552,
      547,
      541,
      535,
      529,
      524,
      518,
      513,
      507,
      502,
      496,
      490,
      485,
      479,
      474,
      468,
      463,
      457,
      452,
      446,
      440,
      435,
      429,
      424,
      418,
      413,
      407,
      402,
      396,
      391,
      385,
      380,
      374,
      369,
      364,
      358,
      352,
      347,
      342,
      336,
      331,
      326,
      321,
      315,
      310,
      305,
      300,
      295,
    ],
    145: [
      828,
      821,
      815,
      808,
      801,
      795,
      788,
      781,
      774,
      767,
      760,
      752,
      745,
      738,
      731,
      724,
      716,
      709,
      702,
      695,
      688,
      681,
      674,
      667,
      660,
      653,
      646,
      640,
      633,
      626,
      619,
      612,
      606,
      599,
      592,
      586,
      579,
      572,
      566,
      560,
      554,
      547,
      541,
      535,
      529,
      523,
      517,
      511,
      505,
      500,
      494,
      488,
      483,
      477,
      471,
      466,
      460,
      455,
      449,
      443,
      438,
      433,
      427,
      421,
      416,
      411,
      405,
      400,
      394,
      389,
      383,
      378,
      373,
      367,
      362,
      357,
      351,
      346,
      341,
      335,
      330,
      325,
      320,
      315,
      310,
      304,
      299,
      294,
      290,
      285,
      280,
    ],
    150: [
      828,
      821,
      814,
      807,
      800,
      793,
      786,
      778,
      771,
      763,
      756,
      748,
      741,
      733,
      726,
      718,
      711,
      703,
      696,
      688,
      681,
      674,
      667,
      659,
      652,
      645,
      638,
      630,
      623,
      616,
      609,
      602,
      595,
      588,
      581,
      573,
      566,
      560,
      553,
      546,
      539,
      533,
      526,
      520,
      513,
      507,
      501,
      494,
      488,
      482,
      477,
      471,
      465,
      459,
      454,
      448,
      442,
      437,
      431,
      426,
      420,
      415,
      409,
      404,
      398,
      393,
      387,
      382,
      377,
      372,
      366,
      361,
      356,
      350,
      345,
      340,
      335,
      330,
      325,
      320,
      314,
      309,
      304,
      300,
      295,
      290,
      285,
      280,
      275,
      270,
      266,
    ],
    155: [
      828,
      821,
      813,
      806,
      799,
      791,
      784,
      776,
      768,
      760,
      753,
      745,
      737,
      729,
      721,
      713,
      706,
      698,
      690,
      683,
      675,
      668,
      660,
      653,
      645,
      638,
      630,
      623,
      615,
      608,
      600,
      593,
      585,
      578,
      570,
      563,
      556,
      549,
      541,
      534,
      527,
      520,
      513,
      507,
      500,
      493,
      486,
      480,
      474,
      467,
      461,
      455,
      449,
      443,
      437,
      432,
      426,
      420,
      415,
      409,
      404,
      398,
      393,
      388,
      382,
      377,
      371,
      366,
      361,
      356,
      350,
      346,
      340,
      335,
      330,
      325,
      320,
      315,
      310,
      305,
      300,
      296,
      291,
      286,
      281,
      277,
      272,
      267,
      262,
      258,
      253,
    ],
    160: [
      828,
      820,
      813,
      805,
      798,
      790,
      782,
      774,
      766,
      758,
      750,
      742,
      734,
      726,
      717,
      709,
      702,
      694,
      686,
      678,
      671,
      663,
      655,
      648,
      640,
      632,
      624,
      616,
      608,
      600,
      593,
      585,
      577,
      570,
      562,
      555,
      547,
      540,
      532,
      525,
      517,
      510,
      503,
      496,
      489,
      482,
      475,
      468,
      462,
      455,
      448,
      442,
      436,
      430,
      424,
      418,
      412,
      406,
      401,
      395,
      389,
      384,
      379,
      373,
      368,
      363,
      357,
      352,
      347,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      307,
      303,
      298,
      293,
      288,
      283,
      279,
      274,
      270,
      265,
      260,
      256,
      251,
      247,
      242,
    ],
    165: [
      828,
      820,
      813,
      805,
      797,
      789,
      781,
      773,
      764,
      756,
      748,
      739,
      731,
      723,
      715,
      707,
      698,
      691,
      683,
      675,
      667,
      659,
      651,
      643,
      635,
      627,
      619,
      611,
      603,
      595,
      587,
      579,
      571,
      564,
      556,
      548,
      540,
      533,
      525,
      517,
      510,
      502,
      495,
      488,
      480,
      473,
      466,
      459,
      452,
      446,
      439,
      432,
      426,
      419,
      413,
      407,
      401,
      395,
      389,
      384,
      378,
      372,
      367,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
      301,
      297,
      292,
      287,
      283,
      278,
      274,
      269,
      265,
      260,
      256,
      251,
      247,
      243,
      238,
      234,
    ],
    170: [
      828,
      820,
      812,
      804,
      796,
      788,
      780,
      772,
      763,
      755,
      746,
      738,
      729,
      721,
      713,
      704,
      697,
      688,
      680,
      672,
      664,
      657,
      648,
      640,
      632,
      623,
      615,
      608,
      599,
      591,
      583,
      575,
      568,
      560,
      551,
      543,
      535,
      528,
      520,
      512,
      505,
      497,
      489,
      482,
      474,
      467,
      460,
      453,
      445,
      439,
      432,
      425,
      418,
      412,
      405,
      399,
      393,
      387,
      381,
      375,
      370,
      364,
      358,
      353,
      348,
      342,
      337,
      332,
      327,
      322,
      317,
      313,
      308,
      303,
      298,
      294,
      289,
      284,
      279,
      275,
      271,
      266,
      262,
      257,
      253,
      249,
      245,
      240,
      236,
      232,
      228,
    ],
    175: [
      828,
      820,
      812,
      804,
      796,
      788,
      779,
      771,
      762,
      754,
      745,
      737,
      728,
      720,
      711,
      703,
      695,
      687,
      679,
      671,
      663,
      655,
      646,
      638,
      630,
      621,
      614,
      605,
      597,
      589,
      581,
      573,
      565,
      557,
      548,
      540,
      532,
      524,
      517,
      509,
      501,
      494,
      486,
      479,
      471,
      463,
      456,
      448,
      441,
      434,
      427,
      420,
      414,
      407,
      401,
      394,
      388,
      382,
      376,
      370,
      364,
      359,
      353,
      348,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      307,
      303,
      298,
      293,
      288,
      284,
      280,
      275,
      270,
      266,
      262,
      257,
      253,
      249,
      245,
      240,
      236,
      232,
      228,
      224,
    ],
  },
  '2': {
    0: [
      938,
      947,
      956,
      965,
      974,
      982,
      990,
      999,
      1006,
      1014,
      1022,
      1029,
      1036,
      1043,
      1049,
      1056,
      1062,
      1068,
      1073,
      1078,
      1084,
      1088,
      1093,
      1098,
      1102,
      1105,
      1109,
      1112,
      1115,
      1118,
      1121,
      1123,
      1125,
      1127,
      1128,
      1130,
      1131,
      1131,
      1132,
      1132,
      1132,
      1131,
      1131,
      1130,
      1128,
      1127,
      1125,
      1123,
      1121,
      1118,
      1115,
      1112,
      1109,
      1105,
      1101,
      1097,
      1092,
      1087,
      1082,
      1077,
      1071,
      1065,
      1059,
      1052,
      1046,
      1039,
      1032,
      1024,
      1016,
      1008,
      1000,
      992,
      983,
      974,
      964,
      955,
      945,
      935,
      925,
      915,
      904,
      893,
      882,
      871,
      859,
      847,
      835,
      823,
      811,
      798,
      786,
    ],
    5: [
      938,
      947,
      956,
      965,
      973,
      982,
      990,
      998,
      1006,
      1014,
      1021,
      1029,
      1036,
      1042,
      1049,
      1055,
      1061,
      1067,
      1073,
      1078,
      1083,
      1088,
      1092,
      1097,
      1101,
      1105,
      1108,
      1112,
      1115,
      1118,
      1120,
      1122,
      1124,
      1126,
      1128,
      1129,
      1130,
      1130,
      1131,
      1131,
      1131,
      1130,
      1130,
      1129,
      1127,
      1126,
      1124,
      1122,
      1120,
      1117,
      1114,
      1111,
      1108,
      1104,
      1100,
      1096,
      1091,
      1086,
      1081,
      1076,
      1070,
      1064,
      1058,
      1052,
      1045,
      1038,
      1031,
      1023,
      1015,
      1007,
      999,
      991,
      982,
      973,
      964,
      954,
      945,
      935,
      924,
      914,
      903,
      892,
      881,
      870,
      858,
      847,
      835,
      823,
      810,
      798,
      785,
    ],
    10: [
      938,
      947,
      956,
      964,
      973,
      981,
      990,
      998,
      1005,
      1013,
      1020,
      1027,
      1034,
      1041,
      1047,
      1054,
      1060,
      1065,
      1071,
      1076,
      1081,
      1086,
      1090,
      1095,
      1099,
      1103,
      1106,
      1109,
      1112,
      1115,
      1118,
      1120,
      1122,
      1124,
      1125,
      1126,
      1127,
      1128,
      1128,
      1128,
      1128,
      1128,
      1127,
      1126,
      1125,
      1123,
      1121,
      1119,
      1117,
      1114,
      1111,
      1108,
      1105,
      1101,
      1097,
      1093,
      1088,
      1083,
      1078,
      1073,
      1067,
      1061,
      1055,
      1049,
      1042,
      1035,
      1028,
      1020,
      1013,
      1005,
      996,
      988,
      979,
      970,
      961,
      952,
      942,
      932,
      922,
      912,
      901,
      890,
      879,
      868,
      856,
      845,
      833,
      821,
      809,
      796,
      783,
    ],
    15: [
      938,
      946,
      955,
      964,
      972,
      981,
      989,
      996,
      1004,
      1011,
      1019,
      1026,
      1032,
      1039,
      1045,
      1051,
      1057,
      1063,
      1068,
      1073,
      1078,
      1083,
      1087,
      1091,
      1095,
      1099,
      1103,
      1106,
      1109,
      1111,
      1114,
      1116,
      1118,
      1119,
      1121,
      1122,
      1123,
      1123,
      1124,
      1124,
      1123,
      1123,
      1122,
      1121,
      1120,
      1118,
      1116,
      1114,
      1112,
      1109,
      1106,
      1103,
      1100,
      1096,
      1092,
      1088,
      1083,
      1078,
      1073,
      1068,
      1062,
      1056,
      1050,
      1044,
      1037,
      1030,
      1023,
      1016,
      1008,
      1000,
      992,
      983,
      975,
      966,
      957,
      947,
      938,
      928,
      918,
      908,
      897,
      886,
      875,
      864,
      853,
      842,
      830,
      818,
      805,
      793,
      781,
    ],
    20: [
      938,
      946,
      955,
      963,
      971,
      979,
      987,
      995,
      1002,
      1009,
      1016,
      1023,
      1030,
      1036,
      1042,
      1048,
      1054,
      1059,
      1064,
      1069,
      1074,
      1079,
      1083,
      1087,
      1091,
      1094,
      1097,
      1101,
      1103,
      1106,
      1108,
      1110,
      1112,
      1114,
      1115,
      1116,
      1117,
      1117,
      1117,
      1117,
      1117,
      1116,
      1116,
      1115,
      1113,
      1111,
      1110,
      1107,
      1105,
      1102,
      1099,
      1096,
      1093,
      1089,
      1085,
      1081,
      1076,
      1071,
      1066,
      1061,
      1055,
      1049,
      1043,
      1037,
      1030,
      1023,
      1016,
      1009,
      1001,
      993,
      985,
      977,
      968,
      960,
      950,
      941,
      932,
      922,
      912,
      902,
      892,
      881,
      870,
      859,
      848,
      836,
      825,
      813,
      801,
      789,
      777,
    ],
    25: [
      938,
      946,
      954,
      962,
      970,
      978,
      985,
      993,
      1000,
      1007,
      1013,
      1020,
      1026,
      1032,
      1038,
      1044,
      1049,
      1054,
      1059,
      1064,
      1069,
      1073,
      1077,
      1081,
      1084,
      1088,
      1091,
      1094,
      1096,
      1099,
      1101,
      1103,
      1105,
      1106,
      1107,
      1108,
      1109,
      1109,
      1109,
      1109,
      1109,
      1108,
      1107,
      1106,
      1104,
      1103,
      1101,
      1099,
      1096,
      1093,
      1090,
      1087,
      1084,
      1080,
      1076,
      1071,
      1067,
      1062,
      1057,
      1052,
      1046,
      1040,
      1034,
      1028,
      1021,
      1014,
      1007,
      1000,
      992,
      985,
      977,
      969,
      960,
      951,
      942,
      933,
      924,
      914,
      904,
      894,
      884,
      874,
      863,
      852,
      841,
      830,
      819,
      807,
      795,
      783,
      771,
    ],
    30: [
      938,
      945,
      953,
      961,
      969,
      976,
      983,
      990,
      997,
      1003,
      1010,
      1016,
      1022,
      1028,
      1033,
      1039,
      1044,
      1049,
      1053,
      1058,
      1062,
      1066,
      1070,
      1074,
      1077,
      1080,
      1083,
      1086,
      1088,
      1090,
      1092,
      1094,
      1096,
      1097,
      1098,
      1098,
      1099,
      1099,
      1099,
      1099,
      1099,
      1098,
      1097,
      1095,
      1094,
      1092,
      1090,
      1088,
      1085,
      1082,
      1079,
      1076,
      1072,
      1069,
      1065,
      1060,
      1056,
      1051,
      1046,
      1040,
      1035,
      1029,
      1023,
      1017,
      1010,
      1003,
      996,
      989,
      982,
      974,
      966,
      958,
      949,
      941,
      932,
      923,
      914,
      904,
      895,
      885,
      875,
      864,
      854,
      843,
      833,
      821,
      810,
      799,
      787,
      776,
      764,
    ],
    35: [
      938,
      945,
      952,
      960,
      967,
      974,
      980,
      987,
      993,
      1000,
      1006,
      1011,
      1017,
      1022,
      1028,
      1033,
      1037,
      1042,
      1046,
      1051,
      1055,
      1058,
      1062,
      1065,
      1068,
      1071,
      1074,
      1076,
      1078,
      1081,
      1082,
      1084,
      1085,
      1086,
      1087,
      1087,
      1088,
      1088,
      1088,
      1087,
      1087,
      1086,
      1085,
      1083,
      1081,
      1080,
      1077,
      1075,
      1072,
      1070,
      1066,
      1063,
      1059,
      1056,
      1051,
      1047,
      1042,
      1038,
      1033,
      1027,
      1022,
      1016,
      1010,
      1004,
      997,
      990,
      983,
      976,
      969,
      961,
      953,
      945,
      937,
      929,
      920,
      911,
      902,
      892,
      883,
      873,
      863,
      853,
      843,
      832,
      822,
      811,
      800,
      789,
      778,
      766,
      755,
    ],
    40: [
      938,
      944,
      951,
      958,
      965,
      971,
      978,
      984,
      990,
      995,
      1001,
      1006,
      1011,
      1016,
      1021,
      1026,
      1030,
      1035,
      1038,
      1042,
      1046,
      1049,
      1053,
      1056,
      1058,
      1061,
      1063,
      1066,
      1067,
      1069,
      1071,
      1072,
      1073,
      1074,
      1074,
      1075,
      1075,
      1075,
      1074,
      1074,
      1073,
      1072,
      1071,
      1069,
      1067,
      1065,
      1063,
      1060,
      1058,
      1055,
      1052,
      1048,
      1044,
      1041,
      1036,
      1032,
      1027,
      1022,
      1017,
      1012,
      1006,
      1001,
      995,
      988,
      982,
      975,
      968,
      961,
      954,
      946,
      939,
      931,
      922,
      914,
      905,
      897,
      888,
      878,
      869,
      860,
      850,
      840,
      830,
      820,
      809,
      799,
      788,
      777,
      766,
      755,
      743,
    ],
    45: [
      938,
      944,
      950,
      957,
      963,
      969,
      974,
      980,
      985,
      990,
      996,
      1000,
      1005,
      1010,
      1014,
      1018,
      1022,
      1026,
      1030,
      1033,
      1036,
      1039,
      1042,
      1045,
      1047,
      1050,
      1052,
      1053,
      1055,
      1057,
      1058,
      1059,
      1060,
      1060,
      1060,
      1060,
      1061,
      1060,
      1059,
      1059,
      1058,
      1057,
      1055,
      1053,
      1051,
      1049,
      1047,
      1044,
      1041,
      1038,
      1035,
      1031,
      1028,
      1024,
      1019,
      1015,
      1010,
      1005,
      1000,
      995,
      989,
      984,
      978,
      971,
      965,
      958,
      951,
      944,
      937,
      929,
      922,
      914,
      906,
      898,
      889,
      881,
      872,
      862,
      854,
      844,
      835,
      825,
      815,
      805,
      795,
      784,
      774,
      763,
      752,
      741,
      730,
    ],
    50: [
      938,
      943,
      949,
      955,
      960,
      966,
      971,
      976,
      981,
      985,
      990,
      994,
      998,
      1002,
      1006,
      1010,
      1013,
      1017,
      1020,
      1023,
      1026,
      1029,
      1031,
      1033,
      1035,
      1037,
      1039,
      1040,
      1042,
      1043,
      1044,
      1044,
      1045,
      1045,
      1045,
      1045,
      1045,
      1044,
      1043,
      1042,
      1041,
      1040,
      1038,
      1036,
      1034,
      1032,
      1029,
      1026,
      1023,
      1020,
      1017,
      1013,
      1009,
      1005,
      1001,
      996,
      992,
      987,
      981,
      976,
      971,
      965,
      959,
      952,
      946,
      939,
      933,
      926,
      918,
      911,
      903,
      896,
      888,
      879,
      871,
      863,
      854,
      845,
      836,
      827,
      817,
      808,
      798,
      789,
      778,
      768,
      758,
      748,
      737,
      726,
      715,
    ],
    55: [
      938,
      943,
      948,
      953,
      958,
      962,
      967,
      971,
      976,
      980,
      983,
      987,
      991,
      994,
      998,
      1001,
      1004,
      1007,
      1010,
      1012,
      1014,
      1017,
      1019,
      1021,
      1022,
      1024,
      1025,
      1026,
      1027,
      1028,
      1028,
      1029,
      1029,
      1029,
      1029,
      1028,
      1028,
      1027,
      1026,
      1025,
      1023,
      1021,
      1019,
      1017,
      1015,
      1013,
      1010,
      1007,
      1004,
      1000,
      997,
      993,
      989,
      985,
      981,
      976,
      971,
      966,
      961,
      956,
      950,
      944,
      938,
      932,
      925,
      919,
      912,
      905,
      898,
      891,
      883,
      876,
      868,
      860,
      851,
      843,
      835,
      826,
      817,
      808,
      799,
      789,
      780,
      770,
      761,
      751,
      740,
      730,
      721,
      710,
      699,
    ],
    60: [
      938,
      942,
      946,
      951,
      955,
      959,
      963,
      967,
      970,
      974,
      977,
      980,
      983,
      986,
      989,
      991,
      994,
      996,
      998,
      1000,
      1002,
      1004,
      1005,
      1007,
      1008,
      1009,
      1010,
      1011,
      1011,
      1012,
      1012,
      1012,
      1012,
      1011,
      1011,
      1010,
      1009,
      1008,
      1007,
      1005,
      1004,
      1002,
      1000,
      997,
      995,
      992,
      989,
      986,
      983,
      979,
      976,
      972,
      968,
      964,
      959,
      954,
      949,
      944,
      939,
      934,
      928,
      922,
      916,
      910,
      904,
      897,
      890,
      884,
      877,
      869,
      862,
      854,
      846,
      839,
      830,
      822,
      814,
      805,
      796,
      788,
      779,
      770,
      760,
      751,
      741,
      732,
      722,
      712,
      702,
      692,
      682,
    ],
    65: [
      938,
      941,
      945,
      949,
      952,
      955,
      958,
      961,
      964,
      967,
      970,
      972,
      975,
      977,
      979,
      981,
      983,
      985,
      987,
      988,
      989,
      990,
      992,
      992,
      993,
      994,
      994,
      995,
      995,
      995,
      994,
      994,
      994,
      993,
      992,
      991,
      990,
      988,
      987,
      985,
      983,
      981,
      979,
      976,
      973,
      971,
      967,
      964,
      961,
      957,
      953,
      949,
      945,
      941,
      936,
      931,
      926,
      921,
      916,
      910,
      905,
      899,
      893,
      887,
      880,
      874,
      867,
      860,
      853,
      846,
      839,
      831,
      823,
      816,
      808,
      800,
      791,
      783,
      775,
      766,
      757,
      748,
      739,
      730,
      720,
      711,
      702,
      692,
      682,
      672,
      662,
    ],
    70: [
      938,
      940,
      943,
      946,
      949,
      952,
      954,
      956,
      958,
      961,
      962,
      964,
      966,
      968,
      969,
      971,
      972,
      973,
      974,
      975,
      976,
      977,
      977,
      977,
      978,
      978,
      978,
      978,
      977,
      977,
      976,
      976,
      975,
      974,
      972,
      971,
      969,
      968,
      966,
      964,
      961,
      959,
      957,
      954,
      951,
      948,
      944,
      941,
      937,
      934,
      930,
      925,
      921,
      917,
      912,
      907,
      902,
      897,
      892,
      886,
      880,
      874,
      868,
      862,
      856,
      849,
      843,
      836,
      829,
      822,
      814,
      807,
      799,
      792,
      784,
      776,
      768,
      759,
      751,
      743,
      734,
      725,
      717,
      708,
      698,
      689,
      680,
      671,
      661,
      652,
      642,
    ],
    75: [
      938,
      940,
      942,
      944,
      946,
      948,
      949,
      951,
      952,
      954,
      955,
      956,
      957,
      958,
      959,
      960,
      960,
      961,
      961,
      961,
      962,
      962,
      962,
      962,
      962,
      961,
      961,
      960,
      959,
      959,
      957,
      956,
      955,
      953,
      952,
      950,
      948,
      946,
      944,
      941,
      939,
      936,
      933,
      930,
      927,
      924,
      921,
      917,
      913,
      909,
      905,
      901,
      896,
      892,
      887,
      882,
      877,
      871,
      866,
      860,
      855,
      849,
      843,
      836,
      830,
      824,
      817,
      810,
      803,
      796,
      789,
      782,
      774,
      767,
      759,
      751,
      743,
      735,
      727,
      719,
      711,
      702,
      693,
      684,
      676,
      666,
      657,
      648,
      639,
      630,
      621,
    ],
    80: [
      938,
      939,
      940,
      941,
      943,
      944,
      944,
      945,
      946,
      947,
      947,
      947,
      948,
      948,
      948,
      948,
      948,
      948,
      948,
      948,
      947,
      947,
      946,
      945,
      945,
      944,
      943,
      941,
      940,
      939,
      937,
      936,
      934,
      932,
      930,
      928,
      926,
      924,
      921,
      918,
      916,
      912,
      910,
      906,
      903,
      899,
      896,
      892,
      888,
      884,
      879,
      875,
      870,
      865,
      860,
      855,
      850,
      845,
      839,
      834,
      828,
      822,
      816,
      810,
      803,
      797,
      790,
      784,
      777,
      770,
      762,
      755,
      748,
      741,
      733,
      725,
      717,
      709,
      701,
      693,
      685,
      677,
      667,
      659,
      651,
      643,
      634,
      625,
      616,
      607,
      598,
    ],
    85: [
      938,
      938,
      939,
      939,
      939,
      940,
      940,
      940,
      939,
      939,
      939,
      939,
      938,
      938,
      937,
      936,
      936,
      935,
      934,
      933,
      932,
      931,
      930,
      929,
      927,
      926,
      924,
      923,
      921,
      919,
      917,
      915,
      913,
      911,
      908,
      906,
      903,
      900,
      897,
      895,
      891,
      888,
      885,
      881,
      878,
      874,
      870,
      866,
      862,
      857,
      853,
      848,
      843,
      838,
      833,
      828,
      823,
      818,
      812,
      806,
      800,
      794,
      788,
      782,
      776,
      769,
      763,
      756,
      749,
      742,
      735,
      728,
      721,
      713,
      706,
      698,
      691,
      683,
      675,
      667,
      659,
      651,
      643,
      634,
      626,
      618,
      610,
      600,
      591,
      583,
      575,
    ],
    90: [
      938,
      937,
      937,
      937,
      936,
      935,
      935,
      934,
      933,
      932,
      931,
      930,
      929,
      927,
      926,
      925,
      923,
      922,
      920,
      918,
      917,
      915,
      913,
      911,
      910,
      907,
      905,
      903,
      901,
      899,
      896,
      894,
      891,
      889,
      885,
      883,
      880,
      877,
      873,
      870,
      866,
      863,
      859,
      855,
      852,
      848,
      843,
      839,
      835,
      830,
      826,
      821,
      816,
      811,
      806,
      800,
      795,
      789,
      784,
      778,
      772,
      766,
      760,
      754,
      747,
      741,
      734,
      728,
      721,
      714,
      707,
      700,
      693,
      686,
      678,
      671,
      663,
      656,
      648,
      640,
      632,
      624,
      616,
      609,
      600,
      592,
      584,
      576,
      566,
      558,
      550,
    ],
    95: [
      938,
      936,
      935,
      934,
      933,
      931,
      930,
      928,
      926,
      925,
      923,
      921,
      919,
      917,
      915,
      912,
      910,
      908,
      906,
      904,
      901,
      899,
      897,
      894,
      891,
      889,
      886,
      883,
      881,
      878,
      875,
      872,
      869,
      866,
      863,
      859,
      856,
      852,
      849,
      845,
      841,
      837,
      833,
      829,
      825,
      821,
      817,
      812,
      807,
      803,
      798,
      793,
      788,
      782,
      777,
      772,
      766,
      761,
      755,
      749,
      743,
      737,
      731,
      725,
      718,
      712,
      706,
      699,
      692,
      685,
      678,
      671,
      664,
      657,
      650,
      643,
      635,
      628,
      620,
      613,
      605,
      597,
      589,
      582,
      574,
      566,
      558,
      550,
      542,
      533,
      525,
    ],
    100: [
      938,
      936,
      934,
      932,
      929,
      927,
      925,
      922,
      920,
      917,
      915,
      912,
      909,
      906,
      903,
      901,
      898,
      895,
      892,
      889,
      886,
      883,
      879,
      876,
      873,
      870,
      867,
      863,
      860,
      857,
      853,
      850,
      846,
      843,
      839,
      835,
      832,
      828,
      824,
      820,
      816,
      811,
      807,
      803,
      798,
      794,
      789,
      784,
      779,
      774,
      769,
      764,
      759,
      754,
      748,
      743,
      737,
      732,
      726,
      720,
      714,
      708,
      702,
      696,
      689,
      683,
      676,
      670,
      663,
      656,
      650,
      643,
      635,
      629,
      622,
      614,
      607,
      599,
      592,
      585,
      577,
      570,
      562,
      554,
      547,
      539,
      532,
      524,
      516,
      508,
      500,
    ],
    105: [
      938,
      935,
      932,
      929,
      926,
      923,
      920,
      917,
      913,
      910,
      906,
      903,
      899,
      896,
      892,
      889,
      885,
      881,
      877,
      874,
      870,
      866,
      863,
      859,
      855,
      851,
      848,
      843,
      840,
      835,
      832,
      828,
      824,
      820,
      815,
      811,
      807,
      803,
      799,
      794,
      789,
      785,
      780,
      776,
      771,
      766,
      761,
      756,
      751,
      746,
      741,
      736,
      730,
      725,
      719,
      714,
      708,
      702,
      696,
      690,
      684,
      678,
      672,
      666,
      659,
      653,
      647,
      640,
      633,
      627,
      620,
      613,
      606,
      599,
      592,
      586,
      578,
      571,
      564,
      557,
      549,
      542,
      535,
      527,
      520,
      512,
      505,
      498,
      490,
      482,
      475,
    ],
    110: [
      938,
      934,
      930,
      927,
      923,
      919,
      915,
      911,
      907,
      903,
      899,
      894,
      890,
      886,
      881,
      877,
      872,
      868,
      863,
      859,
      854,
      850,
      846,
      841,
      836,
      832,
      828,
      823,
      819,
      815,
      810,
      806,
      801,
      796,
      792,
      787,
      782,
      778,
      773,
      768,
      763,
      759,
      754,
      749,
      744,
      739,
      734,
      728,
      723,
      718,
      712,
      707,
      701,
      696,
      690,
      684,
      678,
      673,
      667,
      661,
      655,
      649,
      642,
      636,
      630,
      623,
      617,
      611,
      604,
      597,
      591,
      584,
      577,
      570,
      563,
      557,
      550,
      543,
      536,
      529,
      521,
      514,
      507,
      500,
      493,
      486,
      478,
      471,
      464,
      457,
      449,
    ],
    115: [
      938,
      933,
      929,
      925,
      920,
      915,
      911,
      906,
      901,
      896,
      891,
      886,
      881,
      876,
      870,
      865,
      860,
      855,
      850,
      844,
      839,
      834,
      829,
      824,
      819,
      814,
      809,
      803,
      799,
      793,
      788,
      783,
      778,
      773,
      768,
      763,
      758,
      753,
      748,
      743,
      737,
      732,
      727,
      722,
      717,
      711,
      706,
      700,
      695,
      689,
      684,
      678,
      672,
      667,
      661,
      655,
      649,
      643,
      637,
      631,
      625,
      619,
      613,
      606,
      600,
      594,
      588,
      581,
      574,
      568,
      561,
      555,
      548,
      541,
      535,
      528,
      521,
      514,
      508,
      501,
      494,
      487,
      480,
      473,
      466,
      459,
      452,
      445,
      438,
      431,
      424,
    ],
    120: [
      938,
      932,
      927,
      922,
      917,
      912,
      906,
      901,
      895,
      889,
      883,
      878,
      872,
      866,
      860,
      854,
      848,
      842,
      836,
      830,
      824,
      819,
      813,
      807,
      801,
      796,
      790,
      784,
      778,
      773,
      767,
      762,
      756,
      750,
      745,
      739,
      734,
      728,
      723,
      717,
      712,
      706,
      701,
      695,
      690,
      684,
      678,
      673,
      667,
      661,
      655,
      650,
      644,
      638,
      632,
      626,
      620,
      614,
      608,
      602,
      596,
      590,
      583,
      577,
      571,
      565,
      558,
      552,
      545,
      539,
      533,
      526,
      520,
      513,
      506,
      500,
      493,
      487,
      480,
      473,
      467,
      460,
      453,
      446,
      440,
      433,
      427,
      420,
      413,
      407,
      400,
    ],
    125: [
      938,
      932,
      926,
      920,
      914,
      908,
      902,
      896,
      889,
      883,
      876,
      870,
      863,
      857,
      850,
      843,
      837,
      830,
      823,
      817,
      810,
      804,
      797,
      791,
      784,
      778,
      772,
      765,
      759,
      753,
      747,
      740,
      734,
      728,
      722,
      716,
      710,
      704,
      698,
      692,
      686,
      681,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      633,
      628,
      622,
      616,
      610,
      604,
      598,
      591,
      585,
      579,
      573,
      567,
      561,
      555,
      549,
      542,
      536,
      530,
      523,
      517,
      511,
      504,
      498,
      491,
      485,
      479,
      473,
      466,
      459,
      453,
      446,
      440,
      434,
      427,
      421,
      414,
      408,
      401,
      395,
      388,
      382,
      375,
    ],
    130: [
      938,
      931,
      925,
      918,
      912,
      905,
      898,
      891,
      884,
      877,
      870,
      862,
      855,
      848,
      840,
      833,
      826,
      819,
      811,
      804,
      797,
      790,
      782,
      775,
      768,
      761,
      754,
      747,
      740,
      733,
      727,
      720,
      713,
      707,
      700,
      693,
      687,
      681,
      674,
      668,
      662,
      655,
      649,
      643,
      637,
      631,
      625,
      619,
      613,
      606,
      600,
      594,
      588,
      582,
      576,
      570,
      564,
      558,
      551,
      545,
      539,
      533,
      527,
      520,
      514,
      508,
      502,
      496,
      490,
      483,
      477,
      471,
      465,
      458,
      452,
      446,
      439,
      433,
      427,
      421,
      415,
      408,
      402,
      396,
      390,
      383,
      377,
      371,
      365,
      359,
      353,
    ],
    135: [
      938,
      930,
      924,
      916,
      909,
      902,
      894,
      887,
      879,
      871,
      863,
      856,
      848,
      840,
      832,
      824,
      816,
      808,
      800,
      792,
      784,
      776,
      768,
      761,
      753,
      745,
      738,
      730,
      723,
      715,
      708,
      700,
      693,
      686,
      679,
      672,
      665,
      658,
      651,
      645,
      638,
      631,
      625,
      618,
      612,
      606,
      599,
      593,
      586,
      580,
      574,
      568,
      561,
      555,
      549,
      543,
      537,
      530,
      524,
      518,
      512,
      506,
      500,
      494,
      487,
      481,
      475,
      469,
      463,
      457,
      451,
      445,
      438,
      432,
      426,
      420,
      414,
      408,
      402,
      396,
      390,
      384,
      378,
      372,
      366,
      360,
      354,
      348,
      342,
      336,
      331,
    ],
    140: [
      938,
      930,
      922,
      915,
      907,
      899,
      891,
      883,
      874,
      866,
      858,
      849,
      841,
      832,
      824,
      815,
      807,
      798,
      789,
      781,
      773,
      764,
      756,
      748,
      739,
      731,
      723,
      714,
      707,
      698,
      690,
      683,
      675,
      667,
      659,
      652,
      644,
      637,
      629,
      622,
      615,
      608,
      601,
      594,
      588,
      581,
      574,
      568,
      561,
      555,
      548,
      542,
      536,
      529,
      523,
      517,
      511,
      504,
      498,
      492,
      486,
      480,
      474,
      468,
      462,
      456,
      449,
      444,
      437,
      432,
      426,
      420,
      414,
      408,
      402,
      396,
      390,
      384,
      378,
      373,
      367,
      361,
      355,
      349,
      344,
      338,
      333,
      327,
      321,
      316,
      310,
    ],
    145: [
      938,
      929,
      921,
      913,
      905,
      896,
      888,
      879,
      870,
      861,
      853,
      843,
      834,
      825,
      816,
      807,
      798,
      789,
      780,
      771,
      762,
      753,
      745,
      736,
      727,
      719,
      710,
      701,
      692,
      684,
      675,
      667,
      658,
      650,
      642,
      633,
      625,
      617,
      610,
      601,
      594,
      586,
      579,
      572,
      565,
      557,
      551,
      544,
      537,
      530,
      524,
      517,
      511,
      505,
      498,
      492,
      486,
      479,
      473,
      467,
      461,
      455,
      449,
      443,
      437,
      431,
      425,
      419,
      413,
      408,
      402,
      396,
      390,
      384,
      379,
      373,
      367,
      362,
      356,
      350,
      345,
      339,
      334,
      328,
      323,
      318,
      312,
      307,
      301,
      296,
      291,
    ],
    150: [
      938,
      929,
      920,
      912,
      903,
      894,
      885,
      876,
      867,
      857,
      848,
      838,
      829,
      819,
      810,
      800,
      791,
      782,
      772,
      763,
      754,
      744,
      735,
      726,
      717,
      708,
      699,
      689,
      680,
      671,
      662,
      653,
      644,
      635,
      626,
      618,
      609,
      601,
      592,
      584,
      576,
      567,
      559,
      551,
      544,
      536,
      529,
      521,
      514,
      507,
      501,
      494,
      487,
      481,
      475,
      468,
      462,
      456,
      450,
      444,
      438,
      432,
      426,
      420,
      414,
      408,
      402,
      396,
      391,
      385,
      379,
      374,
      368,
      363,
      357,
      352,
      346,
      341,
      335,
      330,
      325,
      319,
      314,
      309,
      304,
      299,
      293,
      288,
      284,
      278,
      273,
    ],
    155: [
      938,
      929,
      920,
      911,
      901,
      892,
      883,
      873,
      863,
      854,
      844,
      834,
      824,
      814,
      804,
      795,
      785,
      775,
      765,
      756,
      746,
      737,
      727,
      718,
      708,
      699,
      689,
      680,
      670,
      660,
      651,
      641,
      632,
      623,
      614,
      604,
      595,
      586,
      577,
      569,
      560,
      551,
      543,
      535,
      526,
      518,
      510,
      502,
      495,
      487,
      480,
      473,
      466,
      459,
      453,
      446,
      440,
      434,
      428,
      422,
      416,
      410,
      404,
      398,
      393,
      387,
      381,
      376,
      370,
      364,
      359,
      354,
      348,
      343,
      338,
      332,
      327,
      322,
      317,
      311,
      306,
      301,
      296,
      291,
      286,
      281,
      277,
      272,
      266,
      262,
      257,
    ],
    160: [
      938,
      928,
      919,
      910,
      900,
      891,
      881,
      871,
      861,
      851,
      841,
      830,
      820,
      810,
      800,
      790,
      780,
      770,
      760,
      750,
      740,
      731,
      721,
      711,
      701,
      691,
      681,
      671,
      661,
      651,
      641,
      632,
      622,
      612,
      603,
      594,
      584,
      575,
      565,
      556,
      547,
      538,
      529,
      520,
      512,
      503,
      495,
      487,
      479,
      471,
      463,
      455,
      448,
      441,
      434,
      427,
      421,
      415,
      409,
      403,
      397,
      391,
      385,
      379,
      374,
      368,
      363,
      357,
      352,
      346,
      341,
      336,
      331,
      326,
      320,
      315,
      310,
      305,
      300,
      295,
      290,
      285,
      281,
      276,
      271,
      266,
      262,
      257,
      253,
      248,
      244,
    ],
    165: [
      938,
      928,
      919,
      909,
      899,
      889,
      879,
      869,
      859,
      848,
      838,
      828,
      817,
      807,
      796,
      786,
      776,
      766,
      756,
      746,
      736,
      726,
      715,
      706,
      695,
      685,
      675,
      664,
      654,
      644,
      635,
      624,
      614,
      605,
      595,
      585,
      575,
      566,
      556,
      546,
      537,
      528,
      518,
      509,
      501,
      492,
      483,
      474,
      466,
      458,
      450,
      442,
      434,
      427,
      419,
      412,
      406,
      399,
      393,
      387,
      381,
      375,
      369,
      363,
      358,
      352,
      347,
      342,
      337,
      331,
      326,
      321,
      316,
      311,
      306,
      301,
      296,
      291,
      287,
      282,
      277,
      273,
      268,
      263,
      259,
      254,
      250,
      245,
      241,
      237,
      233,
    ],
    170: [
      938,
      928,
      918,
      908,
      898,
      888,
      878,
      868,
      857,
      847,
      836,
      826,
      815,
      804,
      794,
      783,
      773,
      763,
      752,
      743,
      732,
      722,
      712,
      701,
      691,
      680,
      670,
      660,
      650,
      640,
      629,
      619,
      609,
      600,
      589,
      579,
      569,
      559,
      549,
      540,
      530,
      521,
      511,
      502,
      492,
      483,
      474,
      466,
      457,
      449,
      440,
      432,
      425,
      417,
      409,
      402,
      395,
      388,
      381,
      375,
      369,
      363,
      357,
      352,
      346,
      341,
      335,
      330,
      325,
      320,
      315,
      310,
      305,
      300,
      295,
      291,
      286,
      281,
      277,
      272,
      268,
      263,
      258,
      254,
      249,
      245,
      241,
      237,
      233,
      228,
      224,
    ],
    175: [
      938,
      928,
      918,
      908,
      898,
      888,
      877,
      867,
      856,
      846,
      835,
      824,
      814,
      803,
      792,
      782,
      771,
      761,
      751,
      741,
      730,
      720,
      710,
      699,
      688,
      678,
      667,
      658,
      647,
      636,
      626,
      617,
      606,
      596,
      585,
      575,
      565,
      555,
      545,
      535,
      526,
      517,
      507,
      498,
      488,
      479,
      469,
      460,
      451,
      443,
      435,
      426,
      418,
      411,
      403,
      396,
      388,
      381,
      375,
      368,
      362,
      356,
      350,
      344,
      339,
      334,
      328,
      323,
      318,
      313,
      308,
      303,
      298,
      294,
      289,
      284,
      279,
      275,
      271,
      266,
      261,
      257,
      253,
      248,
      244,
      240,
      235,
      231,
      227,
      223,
      219,
    ],
  },
  '3': {
    0: [
      857,
      865,
      872,
      880,
      888,
      895,
      902,
      909,
      916,
      923,
      929,
      935,
      941,
      947,
      953,
      958,
      963,
      968,
      973,
      978,
      982,
      986,
      990,
      994,
      997,
      1001,
      1004,
      1007,
      1009,
      1012,
      1014,
      1016,
      1017,
      1019,
      1020,
      1021,
      1022,
      1022,
      1023,
      1023,
      1023,
      1022,
      1021,
      1020,
      1019,
      1018,
      1016,
      1014,
      1012,
      1010,
      1007,
      1005,
      1001,
      998,
      995,
      991,
      987,
      982,
      978,
      973,
      968,
      963,
      958,
      952,
      946,
      940,
      934,
      927,
      920,
      913,
      906,
      899,
      891,
      883,
      875,
      866,
      858,
      849,
      840,
      831,
      822,
      812,
      803,
      793,
      783,
      772,
      762,
      751,
      740,
      729,
      718,
    ],
    5: [
      857,
      865,
      872,
      880,
      888,
      895,
      902,
      909,
      916,
      922,
      929,
      935,
      941,
      947,
      952,
      958,
      963,
      968,
      973,
      977,
      982,
      986,
      990,
      993,
      997,
      1000,
      1003,
      1006,
      1009,
      1011,
      1013,
      1015,
      1017,
      1018,
      1019,
      1020,
      1021,
      1022,
      1022,
      1022,
      1022,
      1021,
      1021,
      1020,
      1018,
      1017,
      1015,
      1014,
      1011,
      1009,
      1006,
      1004,
      1001,
      997,
      994,
      990,
      986,
      982,
      977,
      972,
      967,
      962,
      957,
      951,
      945,
      939,
      933,
      926,
      919,
      912,
      905,
      898,
      890,
      882,
      874,
      866,
      857,
      848,
      840,
      830,
      821,
      812,
      802,
      792,
      782,
      772,
      761,
      751,
      740,
      729,
      718,
    ],
    10: [
      857,
      864,
      872,
      880,
      887,
      894,
      902,
      908,
      915,
      922,
      928,
      934,
      940,
      946,
      951,
      956,
      962,
      967,
      971,
      976,
      980,
      984,
      988,
      992,
      995,
      998,
      1001,
      1004,
      1007,
      1009,
      1011,
      1013,
      1015,
      1016,
      1017,
      1018,
      1019,
      1019,
      1020,
      1020,
      1019,
      1019,
      1018,
      1017,
      1016,
      1015,
      1013,
      1011,
      1009,
      1006,
      1004,
      1001,
      998,
      995,
      991,
      987,
      983,
      979,
      974,
      970,
      965,
      960,
      954,
      948,
      942,
      936,
      930,
      924,
      917,
      910,
      902,
      895,
      887,
      880,
      872,
      863,
      855,
      846,
      837,
      828,
      819,
      809,
      800,
      790,
      780,
      770,
      759,
      749,
      738,
      727,
      716,
    ],
    15: [
      857,
      864,
      872,
      879,
      887,
      894,
      901,
      907,
      914,
      920,
      926,
      932,
      938,
      944,
      949,
      954,
      959,
      964,
      969,
      973,
      977,
      981,
      985,
      989,
      992,
      995,
      998,
      1001,
      1003,
      1006,
      1008,
      1009,
      1011,
      1012,
      1013,
      1014,
      1015,
      1015,
      1016,
      1016,
      1015,
      1015,
      1014,
      1013,
      1012,
      1010,
      1009,
      1007,
      1005,
      1002,
      1000,
      997,
      994,
      990,
      987,
      983,
      979,
      974,
      970,
      965,
      960,
      955,
      950,
      944,
      938,
      932,
      926,
      919,
      912,
      905,
      898,
      891,
      883,
      876,
      867,
      859,
      851,
      842,
      833,
      824,
      815,
      806,
      796,
      786,
      777,
      766,
      756,
      746,
      735,
      724,
      713,
    ],
    20: [
      857,
      864,
      872,
      879,
      886,
      893,
      899,
      906,
      912,
      918,
      924,
      930,
      936,
      941,
      947,
      952,
      956,
      961,
      966,
      970,
      974,
      978,
      981,
      985,
      988,
      991,
      994,
      996,
      999,
      1001,
      1003,
      1005,
      1006,
      1007,
      1008,
      1009,
      1010,
      1010,
      1010,
      1010,
      1010,
      1009,
      1008,
      1007,
      1006,
      1004,
      1003,
      1001,
      998,
      996,
      993,
      990,
      987,
      984,
      980,
      977,
      972,
      968,
      964,
      959,
      954,
      949,
      943,
      938,
      932,
      926,
      920,
      913,
      906,
      899,
      892,
      885,
      877,
      870,
      862,
      853,
      845,
      837,
      828,
      819,
      810,
      801,
      791,
      781,
      772,
      762,
      751,
      741,
      731,
      720,
      709,
    ],
    25: [
      857,
      864,
      871,
      878,
      885,
      891,
      898,
      904,
      910,
      916,
      922,
      928,
      933,
      938,
      943,
      948,
      953,
      957,
      961,
      966,
      969,
      973,
      977,
      980,
      983,
      986,
      988,
      991,
      993,
      995,
      997,
      998,
      1000,
      1001,
      1002,
      1002,
      1003,
      1003,
      1003,
      1003,
      1002,
      1002,
      1001,
      1000,
      998,
      997,
      995,
      993,
      991,
      988,
      986,
      983,
      979,
      976,
      972,
      969,
      964,
      960,
      956,
      951,
      946,
      941,
      935,
      930,
      924,
      918,
      912,
      905,
      898,
      892,
      885,
      877,
      870,
      862,
      854,
      846,
      838,
      829,
      821,
      812,
      803,
      794,
      784,
      775,
      765,
      755,
      745,
      735,
      725,
      714,
      704,
    ],
    30: [
      857,
      864,
      870,
      877,
      883,
      890,
      896,
      902,
      908,
      913,
      919,
      924,
      929,
      934,
      939,
      944,
      948,
      952,
      956,
      960,
      964,
      967,
      971,
      974,
      977,
      979,
      982,
      984,
      986,
      988,
      989,
      991,
      992,
      993,
      994,
      994,
      995,
      995,
      995,
      994,
      994,
      993,
      992,
      991,
      989,
      988,
      986,
      984,
      981,
      979,
      976,
      973,
      970,
      966,
      963,
      959,
      955,
      950,
      946,
      941,
      936,
      931,
      925,
      920,
      914,
      908,
      902,
      895,
      889,
      882,
      875,
      868,
      860,
      853,
      845,
      837,
      829,
      820,
      812,
      803,
      794,
      785,
      776,
      766,
      757,
      747,
      737,
      727,
      717,
      707,
      697,
    ],
    35: [
      857,
      863,
      870,
      876,
      882,
      888,
      894,
      899,
      905,
      910,
      915,
      920,
      925,
      930,
      934,
      939,
      943,
      947,
      950,
      954,
      957,
      961,
      964,
      966,
      969,
      972,
      974,
      976,
      978,
      979,
      981,
      982,
      983,
      984,
      984,
      985,
      985,
      985,
      985,
      984,
      983,
      983,
      981,
      980,
      979,
      977,
      975,
      973,
      970,
      968,
      965,
      962,
      958,
      955,
      951,
      947,
      943,
      939,
      934,
      929,
      924,
      919,
      914,
      908,
      902,
      896,
      890,
      884,
      877,
      870,
      864,
      856,
      849,
      841,
      834,
      826,
      818,
      809,
      801,
      792,
      784,
      775,
      765,
      756,
      747,
      737,
      728,
      718,
      708,
      697,
      688,
    ],
    40: [
      857,
      863,
      869,
      875,
      880,
      886,
      891,
      897,
      902,
      907,
      911,
      916,
      920,
      925,
      929,
      933,
      937,
      940,
      944,
      947,
      950,
      953,
      956,
      958,
      961,
      963,
      965,
      967,
      968,
      970,
      971,
      972,
      973,
      973,
      974,
      974,
      974,
      974,
      973,
      973,
      972,
      971,
      969,
      968,
      966,
      964,
      962,
      960,
      957,
      955,
      952,
      949,
      945,
      942,
      938,
      934,
      930,
      925,
      921,
      916,
      911,
      906,
      900,
      895,
      889,
      883,
      877,
      870,
      864,
      857,
      850,
      843,
      836,
      828,
      821,
      813,
      805,
      797,
      789,
      780,
      771,
      763,
      753,
      745,
      735,
      726,
      716,
      707,
      697,
      687,
      677,
    ],
    45: [
      857,
      862,
      868,
      873,
      878,
      884,
      889,
      893,
      898,
      903,
      907,
      911,
      915,
      919,
      923,
      926,
      930,
      933,
      936,
      939,
      942,
      944,
      947,
      949,
      951,
      953,
      955,
      956,
      958,
      959,
      960,
      961,
      961,
      961,
      962,
      962,
      962,
      961,
      960,
      960,
      959,
      957,
      956,
      954,
      953,
      951,
      948,
      946,
      943,
      940,
      937,
      934,
      931,
      927,
      923,
      919,
      915,
      910,
      906,
      901,
      896,
      891,
      886,
      880,
      874,
      868,
      862,
      855,
      849,
      842,
      836,
      828,
      821,
      814,
      806,
      799,
      791,
      782,
      774,
      766,
      758,
      749,
      740,
      731,
      722,
      713,
      703,
      694,
      685,
      675,
      665,
    ],
    50: [
      857,
      862,
      867,
      872,
      876,
      881,
      885,
      890,
      894,
      898,
      902,
      906,
      909,
      913,
      916,
      919,
      922,
      925,
      928,
      931,
      933,
      935,
      937,
      939,
      941,
      943,
      944,
      945,
      946,
      947,
      948,
      948,
      949,
      949,
      949,
      948,
      948,
      947,
      947,
      945,
      944,
      943,
      941,
      940,
      938,
      935,
      933,
      930,
      928,
      925,
      922,
      918,
      915,
      911,
      907,
      903,
      899,
      894,
      889,
      885,
      880,
      874,
      869,
      863,
      857,
      851,
      845,
      839,
      833,
      826,
      819,
      812,
      805,
      798,
      790,
      783,
      775,
      767,
      759,
      751,
      742,
      734,
      725,
      716,
      707,
      698,
      689,
      680,
      671,
      661,
      652,
    ],
    55: [
      857,
      861,
      866,
      870,
      874,
      878,
      882,
      886,
      890,
      893,
      897,
      900,
      903,
      906,
      909,
      912,
      914,
      917,
      919,
      921,
      923,
      925,
      927,
      928,
      930,
      931,
      932,
      933,
      934,
      934,
      935,
      935,
      935,
      935,
      934,
      934,
      933,
      932,
      931,
      930,
      929,
      927,
      925,
      923,
      921,
      919,
      916,
      914,
      911,
      908,
      904,
      901,
      897,
      894,
      890,
      885,
      881,
      876,
      872,
      867,
      862,
      856,
      851,
      845,
      839,
      834,
      828,
      821,
      815,
      808,
      801,
      795,
      788,
      780,
      773,
      765,
      758,
      750,
      742,
      734,
      726,
      717,
      709,
      700,
      691,
      683,
      673,
      664,
      656,
      646,
      637,
    ],
    60: [
      857,
      861,
      864,
      868,
      872,
      875,
      879,
      882,
      885,
      888,
      891,
      894,
      896,
      899,
      901,
      904,
      906,
      908,
      910,
      911,
      913,
      914,
      916,
      917,
      918,
      919,
      920,
      920,
      920,
      921,
      921,
      921,
      920,
      920,
      919,
      919,
      918,
      917,
      915,
      914,
      912,
      910,
      908,
      906,
      904,
      902,
      899,
      896,
      893,
      890,
      886,
      883,
      879,
      875,
      871,
      867,
      862,
      858,
      853,
      848,
      843,
      837,
      832,
      826,
      821,
      814,
      808,
      802,
      796,
      790,
      782,
      776,
      769,
      762,
      754,
      747,
      739,
      732,
      724,
      716,
      708,
      700,
      691,
      683,
      674,
      666,
      657,
      648,
      639,
      630,
      621,
    ],
    65: [
      857,
      860,
      863,
      866,
      869,
      872,
      875,
      878,
      880,
      883,
      885,
      887,
      889,
      891,
      893,
      895,
      897,
      898,
      900,
      901,
      902,
      903,
      904,
      905,
      905,
      906,
      906,
      906,
      906,
      906,
      906,
      906,
      905,
      904,
      903,
      902,
      901,
      900,
      898,
      897,
      895,
      893,
      890,
      888,
      886,
      883,
      880,
      877,
      874,
      870,
      867,
      863,
      859,
      855,
      851,
      847,
      842,
      838,
      833,
      828,
      823,
      817,
      812,
      806,
      800,
      794,
      788,
      782,
      776,
      769,
      762,
      755,
      749,
      742,
      735,
      727,
      720,
      712,
      705,
      696,
      689,
      681,
      673,
      664,
      656,
      647,
      639,
      630,
      621,
      613,
      604,
    ],
    70: [
      857,
      859,
      862,
      864,
      867,
      869,
      871,
      873,
      875,
      877,
      879,
      880,
      882,
      883,
      885,
      886,
      887,
      888,
      889,
      890,
      890,
      891,
      891,
      892,
      892,
      892,
      892,
      892,
      891,
      891,
      890,
      890,
      889,
      888,
      887,
      885,
      884,
      882,
      880,
      878,
      876,
      874,
      872,
      869,
      866,
      863,
      860,
      857,
      854,
      850,
      847,
      843,
      839,
      835,
      830,
      826,
      821,
      816,
      812,
      806,
      801,
      796,
      791,
      785,
      779,
      773,
      767,
      761,
      754,
      748,
      741,
      735,
      728,
      721,
      714,
      707,
      699,
      691,
      684,
      677,
      668,
      661,
      653,
      645,
      636,
      628,
      620,
      612,
      603,
      594,
      586,
    ],
    75: [
      857,
      859,
      861,
      862,
      864,
      866,
      867,
      869,
      870,
      871,
      872,
      873,
      874,
      875,
      876,
      877,
      877,
      878,
      878,
      878,
      878,
      879,
      878,
      878,
      878,
      878,
      877,
      877,
      876,
      875,
      874,
      873,
      872,
      870,
      869,
      867,
      866,
      864,
      862,
      859,
      857,
      854,
      852,
      849,
      846,
      843,
      840,
      836,
      833,
      829,
      825,
      821,
      817,
      813,
      809,
      804,
      799,
      795,
      790,
      784,
      779,
      774,
      768,
      763,
      757,
      751,
      745,
      739,
      732,
      726,
      719,
      713,
      706,
      699,
      692,
      685,
      678,
      670,
      663,
      655,
      648,
      640,
      632,
      624,
      616,
      608,
      600,
      592,
      584,
      575,
      567,
    ],
    80: [
      857,
      858,
      859,
      860,
      861,
      862,
      863,
      864,
      864,
      865,
      866,
      866,
      866,
      867,
      867,
      867,
      867,
      867,
      866,
      866,
      866,
      865,
      865,
      865,
      864,
      863,
      862,
      861,
      860,
      859,
      857,
      856,
      854,
      852,
      851,
      849,
      846,
      844,
      842,
      840,
      837,
      834,
      831,
      828,
      825,
      822,
      818,
      815,
      811,
      807,
      803,
      799,
      795,
      791,
      786,
      781,
      777,
      772,
      767,
      762,
      756,
      751,
      745,
      740,
      734,
      728,
      722,
      716,
      709,
      703,
      696,
      690,
      683,
      676,
      669,
      662,
      655,
      648,
      640,
      633,
      626,
      619,
      610,
      602,
      595,
      588,
      579,
      571,
      563,
      555,
      547,
    ],
    85: [
      857,
      857,
      858,
      858,
      859,
      859,
      859,
      859,
      859,
      859,
      859,
      858,
      858,
      858,
      857,
      857,
      856,
      856,
      855,
      854,
      853,
      852,
      851,
      850,
      849,
      848,
      846,
      845,
      843,
      842,
      840,
      838,
      836,
      834,
      832,
      830,
      827,
      825,
      822,
      819,
      816,
      813,
      810,
      807,
      804,
      800,
      796,
      793,
      789,
      785,
      781,
      777,
      772,
      768,
      763,
      758,
      753,
      748,
      743,
      738,
      733,
      727,
      722,
      716,
      710,
      704,
      698,
      692,
      686,
      679,
      673,
      666,
      660,
      653,
      646,
      639,
      632,
      625,
      618,
      610,
      603,
      596,
      589,
      580,
      573,
      566,
      558,
      550,
      542,
      534,
      527,
    ],
    90: [
      857,
      857,
      856,
      856,
      856,
      855,
      855,
      854,
      853,
      853,
      852,
      851,
      850,
      849,
      848,
      847,
      846,
      844,
      843,
      842,
      840,
      839,
      837,
      836,
      834,
      832,
      830,
      829,
      826,
      824,
      822,
      820,
      818,
      815,
      813,
      810,
      807,
      804,
      802,
      798,
      795,
      792,
      789,
      785,
      782,
      778,
      774,
      770,
      766,
      762,
      758,
      753,
      749,
      744,
      739,
      734,
      729,
      724,
      719,
      714,
      708,
      703,
      697,
      692,
      686,
      680,
      674,
      668,
      662,
      655,
      649,
      642,
      636,
      629,
      622,
      615,
      609,
      601,
      594,
      588,
      580,
      573,
      566,
      559,
      551,
      543,
      536,
      529,
      520,
      513,
      505,
    ],
    95: [
      857,
      856,
      855,
      854,
      853,
      852,
      851,
      849,
      848,
      846,
      845,
      843,
      842,
      840,
      838,
      836,
      835,
      833,
      831,
      829,
      827,
      825,
      823,
      821,
      818,
      816,
      814,
      812,
      809,
      807,
      804,
      802,
      799,
      796,
      793,
      790,
      787,
      784,
      781,
      777,
      774,
      770,
      767,
      763,
      759,
      755,
      751,
      747,
      743,
      738,
      734,
      729,
      725,
      720,
      715,
      710,
      705,
      700,
      695,
      689,
      684,
      678,
      673,
      667,
      661,
      655,
      649,
      643,
      637,
      630,
      624,
      618,
      611,
      605,
      598,
      591,
      585,
      578,
      571,
      564,
      557,
      550,
      542,
      535,
      528,
      521,
      513,
      506,
      499,
      491,
      484,
    ],
    100: [
      857,
      855,
      854,
      852,
      850,
      848,
      846,
      844,
      842,
      840,
      838,
      836,
      833,
      831,
      829,
      826,
      824,
      821,
      819,
      816,
      814,
      811,
      808,
      806,
      803,
      800,
      798,
      795,
      792,
      789,
      786,
      783,
      780,
      777,
      773,
      770,
      767,
      763,
      759,
      756,
      752,
      748,
      744,
      740,
      736,
      732,
      728,
      723,
      719,
      715,
      710,
      705,
      700,
      696,
      691,
      685,
      680,
      675,
      670,
      664,
      659,
      653,
      647,
      642,
      636,
      630,
      624,
      618,
      612,
      606,
      599,
      593,
      586,
      580,
      574,
      567,
      560,
      553,
      547,
      540,
      533,
      526,
      519,
      512,
      505,
      498,
      491,
      484,
      477,
      470,
      462,
    ],
    105: [
      857,
      854,
      852,
      850,
      847,
      845,
      842,
      840,
      837,
      834,
      831,
      828,
      825,
      822,
      819,
      816,
      813,
      810,
      807,
      804,
      800,
      797,
      794,
      791,
      788,
      784,
      781,
      778,
      774,
      771,
      768,
      764,
      760,
      757,
      753,
      750,
      746,
      742,
      738,
      734,
      730,
      726,
      722,
      718,
      713,
      709,
      704,
      700,
      695,
      691,
      686,
      681,
      676,
      671,
      666,
      661,
      655,
      650,
      645,
      639,
      634,
      628,
      622,
      617,
      611,
      605,
      599,
      593,
      587,
      580,
      574,
      568,
      562,
      555,
      549,
      543,
      536,
      529,
      523,
      516,
      509,
      502,
      496,
      489,
      482,
      475,
      468,
      461,
      454,
      447,
      440,
    ],
    110: [
      857,
      854,
      851,
      848,
      845,
      841,
      838,
      835,
      831,
      828,
      824,
      821,
      817,
      813,
      810,
      806,
      802,
      799,
      795,
      791,
      787,
      784,
      780,
      776,
      772,
      768,
      764,
      761,
      757,
      753,
      749,
      745,
      741,
      737,
      733,
      729,
      725,
      721,
      717,
      712,
      708,
      704,
      699,
      695,
      690,
      686,
      681,
      676,
      672,
      667,
      662,
      657,
      652,
      646,
      641,
      636,
      631,
      625,
      620,
      614,
      609,
      603,
      597,
      591,
      585,
      580,
      574,
      568,
      562,
      555,
      549,
      543,
      537,
      531,
      524,
      518,
      512,
      505,
      498,
      492,
      485,
      479,
      472,
      466,
      459,
      452,
      446,
      439,
      432,
      425,
      419,
    ],
    115: [
      857,
      853,
      849,
      846,
      842,
      838,
      834,
      830,
      826,
      822,
      818,
      813,
      809,
      805,
      800,
      796,
      792,
      787,
      783,
      778,
      774,
      770,
      766,
      761,
      757,
      753,
      748,
      744,
      740,
      735,
      731,
      726,
      722,
      718,
      713,
      709,
      704,
      700,
      695,
      691,
      686,
      682,
      677,
      672,
      667,
      663,
      658,
      653,
      648,
      643,
      638,
      632,
      627,
      622,
      617,
      611,
      606,
      600,
      595,
      589,
      584,
      578,
      572,
      566,
      560,
      555,
      549,
      543,
      537,
      531,
      525,
      519,
      512,
      506,
      500,
      494,
      487,
      481,
      475,
      468,
      462,
      456,
      449,
      443,
      436,
      430,
      423,
      416,
      410,
      404,
      397,
    ],
    120: [
      857,
      852,
      848,
      844,
      839,
      835,
      830,
      826,
      821,
      816,
      811,
      806,
      801,
      796,
      791,
      786,
      782,
      777,
      772,
      767,
      762,
      757,
      752,
      747,
      742,
      737,
      732,
      727,
      723,
      718,
      713,
      708,
      703,
      699,
      694,
      689,
      684,
      679,
      675,
      669,
      665,
      660,
      655,
      650,
      645,
      640,
      635,
      629,
      624,
      619,
      614,
      609,
      603,
      598,
      592,
      587,
      581,
      576,
      570,
      565,
      559,
      553,
      548,
      542,
      536,
      530,
      524,
      518,
      512,
      506,
      500,
      494,
      488,
      482,
      476,
      470,
      464,
      458,
      451,
      445,
      439,
      433,
      426,
      420,
      414,
      408,
      402,
      395,
      389,
      383,
      376,
    ],
    125: [
      857,
      852,
      847,
      842,
      837,
      832,
      827,
      821,
      816,
      811,
      805,
      800,
      794,
      788,
      783,
      777,
      772,
      766,
      760,
      755,
      749,
      744,
      738,
      733,
      728,
      722,
      717,
      711,
      706,
      701,
      696,
      690,
      685,
      680,
      675,
      669,
      664,
      659,
      654,
      649,
      643,
      638,
      633,
      628,
      623,
      617,
      612,
      607,
      601,
      596,
      591,
      585,
      580,
      574,
      569,
      563,
      558,
      552,
      546,
      541,
      535,
      529,
      524,
      518,
      512,
      506,
      500,
      494,
      489,
      482,
      477,
      471,
      464,
      459,
      453,
      447,
      441,
      434,
      429,
      422,
      416,
      410,
      404,
      398,
      392,
      386,
      380,
      374,
      368,
      362,
      356,
    ],
    130: [
      857,
      851,
      846,
      840,
      835,
      829,
      823,
      817,
      811,
      805,
      799,
      793,
      787,
      781,
      775,
      769,
      762,
      756,
      750,
      744,
      738,
      732,
      726,
      720,
      714,
      708,
      702,
      696,
      690,
      685,
      679,
      673,
      667,
      662,
      656,
      650,
      645,
      639,
      634,
      628,
      623,
      617,
      612,
      606,
      601,
      595,
      590,
      584,
      579,
      573,
      568,
      562,
      557,
      551,
      546,
      540,
      534,
      529,
      523,
      517,
      512,
      506,
      500,
      494,
      489,
      483,
      477,
      471,
      465,
      460,
      453,
      448,
      442,
      436,
      430,
      424,
      418,
      413,
      407,
      401,
      395,
      389,
      383,
      377,
      372,
      365,
      360,
      354,
      348,
      342,
      336,
    ],
    135: [
      857,
      851,
      845,
      839,
      833,
      826,
      820,
      814,
      807,
      801,
      794,
      787,
      781,
      774,
      767,
      761,
      754,
      747,
      740,
      734,
      727,
      721,
      714,
      708,
      701,
      695,
      688,
      682,
      675,
      669,
      663,
      657,
      651,
      644,
      638,
      633,
      626,
      621,
      615,
      609,
      603,
      597,
      592,
      586,
      580,
      574,
      569,
      563,
      557,
      552,
      546,
      540,
      535,
      529,
      523,
      518,
      512,
      506,
      501,
      495,
      489,
      483,
      478,
      472,
      466,
      460,
      455,
      449,
      443,
      437,
      432,
      426,
      420,
      414,
      409,
      403,
      397,
      392,
      386,
      380,
      374,
      369,
      363,
      357,
      352,
      346,
      341,
      335,
      329,
      323,
      318,
    ],
    140: [
      857,
      850,
      844,
      837,
      831,
      824,
      817,
      810,
      803,
      796,
      789,
      782,
      775,
      767,
      760,
      753,
      746,
      739,
      732,
      724,
      717,
      710,
      703,
      696,
      689,
      683,
      676,
      669,
      662,
      655,
      649,
      642,
      635,
      629,
      622,
      616,
      609,
      603,
      597,
      590,
      584,
      578,
      572,
      566,
      560,
      554,
      548,
      542,
      537,
      531,
      525,
      519,
      513,
      508,
      502,
      496,
      490,
      485,
      479,
      473,
      468,
      462,
      456,
      450,
      445,
      439,
      433,
      428,
      422,
      416,
      411,
      405,
      399,
      394,
      388,
      383,
      377,
      371,
      366,
      360,
      355,
      349,
      344,
      338,
      333,
      328,
      322,
      317,
      311,
      306,
      301,
    ],
    145: [
      857,
      850,
      843,
      836,
      829,
      822,
      814,
      807,
      800,
      792,
      785,
      777,
      769,
      762,
      754,
      746,
      739,
      731,
      724,
      716,
      709,
      701,
      694,
      686,
      679,
      672,
      665,
      658,
      650,
      643,
      636,
      629,
      622,
      615,
      608,
      601,
      594,
      587,
      580,
      573,
      567,
      560,
      554,
      548,
      541,
      535,
      529,
      523,
      517,
      511,
      505,
      499,
      493,
      487,
      482,
      476,
      470,
      464,
      458,
      453,
      447,
      441,
      436,
      430,
      424,
      419,
      413,
      408,
      402,
      397,
      391,
      385,
      380,
      374,
      369,
      364,
      358,
      353,
      347,
      342,
      337,
      331,
      326,
      321,
      316,
      310,
      305,
      300,
      295,
      290,
      285,
    ],
    150: [
      857,
      849,
      842,
      835,
      827,
      820,
      812,
      804,
      796,
      788,
      781,
      773,
      764,
      756,
      748,
      740,
      732,
      725,
      717,
      709,
      701,
      693,
      686,
      678,
      670,
      663,
      655,
      648,
      640,
      632,
      625,
      617,
      610,
      602,
      595,
      588,
      580,
      573,
      566,
      559,
      552,
      545,
      538,
      531,
      524,
      518,
      511,
      505,
      498,
      492,
      486,
      480,
      474,
      468,
      462,
      457,
      451,
      445,
      439,
      434,
      428,
      422,
      417,
      411,
      405,
      400,
      394,
      389,
      383,
      378,
      373,
      367,
      362,
      356,
      351,
      346,
      341,
      335,
      330,
      325,
      320,
      315,
      309,
      305,
      300,
      294,
      289,
      285,
      280,
      275,
      270,
    ],
    155: [
      857,
      849,
      842,
      834,
      826,
      818,
      810,
      802,
      794,
      785,
      777,
      769,
      760,
      752,
      744,
      735,
      727,
      719,
      711,
      703,
      695,
      687,
      679,
      671,
      663,
      655,
      647,
      639,
      631,
      623,
      615,
      607,
      600,
      592,
      584,
      576,
      569,
      561,
      554,
      546,
      539,
      532,
      524,
      517,
      510,
      503,
      496,
      489,
      483,
      476,
      470,
      463,
      457,
      451,
      445,
      439,
      433,
      427,
      422,
      416,
      410,
      405,
      399,
      394,
      388,
      382,
      377,
      372,
      366,
      361,
      356,
      351,
      345,
      340,
      335,
      330,
      325,
      320,
      315,
      309,
      305,
      300,
      295,
      290,
      285,
      280,
      276,
      271,
      266,
      261,
      257,
    ],
    160: [
      857,
      849,
      841,
      833,
      825,
      817,
      808,
      800,
      791,
      783,
      774,
      766,
      757,
      748,
      740,
      731,
      723,
      714,
      706,
      698,
      690,
      682,
      673,
      665,
      657,
      649,
      640,
      632,
      624,
      616,
      607,
      599,
      591,
      583,
      575,
      567,
      559,
      552,
      544,
      536,
      528,
      521,
      513,
      506,
      498,
      491,
      484,
      477,
      470,
      463,
      456,
      449,
      443,
      437,
      430,
      424,
      418,
      412,
      406,
      400,
      395,
      389,
      384,
      378,
      373,
      367,
      362,
      357,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
      301,
      296,
      291,
      286,
      282,
      277,
      273,
      268,
      263,
      259,
      254,
      250,
      245,
    ],
    165: [
      857,
      849,
      841,
      832,
      824,
      816,
      807,
      798,
      790,
      781,
      772,
      763,
      754,
      745,
      737,
      728,
      719,
      711,
      703,
      694,
      686,
      677,
      669,
      661,
      652,
      644,
      635,
      626,
      618,
      610,
      602,
      593,
      585,
      577,
      568,
      560,
      552,
      544,
      536,
      528,
      520,
      512,
      504,
      497,
      489,
      482,
      474,
      467,
      459,
      453,
      446,
      439,
      432,
      425,
      419,
      412,
      406,
      400,
      394,
      388,
      382,
      377,
      371,
      365,
      360,
      355,
      350,
      344,
      339,
      334,
      329,
      324,
      319,
      314,
      309,
      304,
      300,
      295,
      290,
      285,
      281,
      276,
      271,
      267,
      262,
      258,
      254,
      249,
      245,
      240,
      236,
    ],
    170: [
      857,
      849,
      840,
      832,
      823,
      815,
      806,
      797,
      788,
      779,
      770,
      761,
      752,
      743,
      734,
      726,
      717,
      709,
      700,
      692,
      683,
      674,
      666,
      657,
      648,
      640,
      631,
      623,
      614,
      606,
      597,
      589,
      580,
      572,
      564,
      555,
      547,
      538,
      530,
      522,
      514,
      506,
      498,
      490,
      482,
      474,
      467,
      460,
      452,
      445,
      438,
      431,
      424,
      417,
      411,
      404,
      398,
      391,
      385,
      379,
      373,
      367,
      362,
      356,
      351,
      346,
      340,
      335,
      330,
      325,
      320,
      315,
      310,
      306,
      300,
      296,
      291,
      287,
      282,
      277,
      273,
      268,
      264,
      259,
      255,
      250,
      246,
      242,
      238,
      233,
      229,
    ],
    175: [
      857,
      848,
      840,
      832,
      823,
      814,
      805,
      797,
      788,
      779,
      769,
      760,
      751,
      742,
      733,
      724,
      716,
      707,
      698,
      690,
      681,
      673,
      664,
      655,
      646,
      637,
      629,
      621,
      612,
      603,
      595,
      587,
      578,
      569,
      560,
      552,
      543,
      535,
      527,
      519,
      511,
      503,
      495,
      487,
      479,
      471,
      463,
      455,
      447,
      440,
      433,
      426,
      419,
      412,
      406,
      399,
      393,
      386,
      380,
      374,
      368,
      362,
      356,
      351,
      345,
      340,
      334,
      329,
      324,
      319,
      314,
      310,
      305,
      300,
      295,
      290,
      286,
      281,
      277,
      272,
      268,
      263,
      259,
      254,
      250,
      246,
      241,
      237,
      233,
      229,
      225,
    ],
  },
  '4': {
    0: [
      907,
      916,
      924,
      933,
      941,
      949,
      957,
      965,
      973,
      980,
      987,
      994,
      1001,
      1007,
      1014,
      1020,
      1025,
      1031,
      1036,
      1041,
      1046,
      1051,
      1055,
      1060,
      1064,
      1067,
      1071,
      1074,
      1077,
      1080,
      1082,
      1084,
      1086,
      1088,
      1089,
      1091,
      1092,
      1092,
      1093,
      1093,
      1093,
      1092,
      1092,
      1091,
      1089,
      1088,
      1086,
      1084,
      1082,
      1080,
      1077,
      1074,
      1071,
      1067,
      1063,
      1059,
      1055,
      1050,
      1045,
      1040,
      1035,
      1029,
      1023,
      1017,
      1011,
      1004,
      997,
      990,
      983,
      975,
      967,
      959,
      951,
      942,
      933,
      924,
      915,
      906,
      896,
      886,
      876,
      865,
      855,
      844,
      833,
      821,
      810,
      799,
      787,
      775,
      763,
    ],
    5: [
      907,
      916,
      924,
      933,
      941,
      949,
      957,
      965,
      972,
      980,
      987,
      994,
      1000,
      1007,
      1013,
      1019,
      1025,
      1031,
      1036,
      1041,
      1046,
      1050,
      1055,
      1059,
      1063,
      1067,
      1070,
      1073,
      1076,
      1079,
      1081,
      1084,
      1086,
      1087,
      1089,
      1090,
      1091,
      1091,
      1092,
      1092,
      1092,
      1091,
      1091,
      1090,
      1089,
      1087,
      1086,
      1084,
      1081,
      1079,
      1076,
      1073,
      1070,
      1066,
      1062,
      1058,
      1054,
      1049,
      1045,
      1039,
      1034,
      1028,
      1023,
      1016,
      1010,
      1003,
      997,
      989,
      982,
      974,
      966,
      958,
      950,
      942,
      933,
      924,
      914,
      905,
      895,
      885,
      875,
      865,
      854,
      843,
      832,
      821,
      810,
      798,
      786,
      775,
      762,
    ],
    10: [
      907,
      916,
      924,
      933,
      941,
      949,
      957,
      964,
      972,
      979,
      986,
      993,
      999,
      1006,
      1012,
      1018,
      1024,
      1029,
      1034,
      1039,
      1044,
      1049,
      1053,
      1057,
      1061,
      1065,
      1068,
      1071,
      1074,
      1077,
      1079,
      1081,
      1083,
      1085,
      1086,
      1088,
      1088,
      1089,
      1089,
      1089,
      1089,
      1089,
      1088,
      1087,
      1086,
      1085,
      1083,
      1081,
      1079,
      1076,
      1073,
      1070,
      1067,
      1064,
      1060,
      1056,
      1051,
      1047,
      1042,
      1037,
      1031,
      1026,
      1020,
      1014,
      1007,
      1001,
      994,
      987,
      980,
      972,
      964,
      956,
      948,
      939,
      930,
      922,
      912,
      903,
      893,
      883,
      873,
      863,
      852,
      842,
      831,
      819,
      808,
      797,
      785,
      773,
      761,
    ],
    15: [
      907,
      915,
      924,
      932,
      940,
      948,
      956,
      963,
      970,
      977,
      984,
      991,
      998,
      1004,
      1010,
      1016,
      1021,
      1027,
      1032,
      1037,
      1041,
      1046,
      1050,
      1054,
      1058,
      1062,
      1065,
      1068,
      1071,
      1073,
      1076,
      1078,
      1080,
      1081,
      1082,
      1084,
      1084,
      1085,
      1085,
      1085,
      1085,
      1085,
      1084,
      1083,
      1082,
      1080,
      1079,
      1077,
      1074,
      1072,
      1069,
      1066,
      1063,
      1059,
      1055,
      1051,
      1047,
      1042,
      1037,
      1032,
      1027,
      1021,
      1016,
      1009,
      1003,
      997,
      990,
      983,
      975,
      968,
      960,
      952,
      944,
      935,
      927,
      918,
      909,
      899,
      890,
      880,
      870,
      860,
      849,
      838,
      828,
      817,
      806,
      794,
      782,
      771,
      759,
    ],
    20: [
      907,
      915,
      923,
      931,
      939,
      947,
      954,
      962,
      969,
      976,
      982,
      989,
      995,
      1001,
      1007,
      1013,
      1018,
      1023,
      1028,
      1033,
      1038,
      1042,
      1046,
      1050,
      1054,
      1057,
      1060,
      1063,
      1066,
      1068,
      1071,
      1073,
      1074,
      1076,
      1077,
      1078,
      1079,
      1079,
      1080,
      1080,
      1079,
      1079,
      1078,
      1077,
      1076,
      1074,
      1072,
      1070,
      1068,
      1066,
      1063,
      1060,
      1056,
      1053,
      1049,
      1045,
      1041,
      1036,
      1031,
      1026,
      1021,
      1015,
      1009,
      1003,
      997,
      991,
      984,
      977,
      969,
      962,
      954,
      946,
      938,
      930,
      921,
      912,
      903,
      894,
      884,
      875,
      865,
      855,
      844,
      834,
      823,
      812,
      801,
      790,
      779,
      767,
      755,
    ],
    25: [
      907,
      915,
      923,
      930,
      938,
      945,
      953,
      960,
      966,
      973,
      979,
      986,
      992,
      998,
      1003,
      1009,
      1014,
      1019,
      1024,
      1028,
      1033,
      1037,
      1041,
      1044,
      1048,
      1051,
      1054,
      1057,
      1060,
      1062,
      1064,
      1066,
      1068,
      1069,
      1070,
      1071,
      1072,
      1072,
      1072,
      1072,
      1072,
      1071,
      1070,
      1069,
      1068,
      1066,
      1064,
      1062,
      1060,
      1058,
      1055,
      1052,
      1048,
      1045,
      1041,
      1037,
      1032,
      1028,
      1023,
      1018,
      1013,
      1007,
      1001,
      995,
      989,
      983,
      976,
      969,
      962,
      954,
      947,
      939,
      931,
      922,
      914,
      905,
      896,
      887,
      878,
      868,
      858,
      848,
      838,
      828,
      817,
      807,
      796,
      785,
      773,
      762,
      750,
    ],
    30: [
      907,
      914,
      922,
      929,
      937,
      944,
      950,
      957,
      964,
      970,
      976,
      982,
      988,
      993,
      999,
      1004,
      1009,
      1014,
      1018,
      1023,
      1027,
      1031,
      1034,
      1038,
      1041,
      1044,
      1047,
      1050,
      1052,
      1054,
      1056,
      1058,
      1059,
      1061,
      1062,
      1062,
      1063,
      1063,
      1063,
      1063,
      1063,
      1062,
      1061,
      1060,
      1058,
      1057,
      1055,
      1053,
      1050,
      1048,
      1045,
      1042,
      1038,
      1035,
      1031,
      1027,
      1022,
      1018,
      1013,
      1008,
      1003,
      997,
      991,
      985,
      979,
      973,
      966,
      959,
      952,
      945,
      937,
      929,
      921,
      913,
      905,
      896,
      887,
      878,
      869,
      860,
      850,
      840,
      830,
      820,
      809,
      799,
      789,
      778,
      767,
      755,
      744,
    ],
    35: [
      907,
      914,
      921,
      928,
      935,
      942,
      948,
      954,
      961,
      966,
      972,
      978,
      983,
      988,
      994,
      998,
      1003,
      1007,
      1012,
      1016,
      1020,
      1023,
      1027,
      1030,
      1033,
      1036,
      1039,
      1041,
      1043,
      1045,
      1047,
      1048,
      1050,
      1051,
      1052,
      1052,
      1053,
      1053,
      1053,
      1052,
      1052,
      1051,
      1050,
      1049,
      1047,
      1045,
      1043,
      1041,
      1039,
      1036,
      1033,
      1030,
      1026,
      1023,
      1019,
      1015,
      1010,
      1006,
      1001,
      996,
      991,
      985,
      979,
      973,
      967,
      961,
      954,
      947,
      940,
      933,
      926,
      918,
      910,
      902,
      894,
      885,
      876,
      867,
      858,
      849,
      840,
      830,
      820,
      810,
      800,
      790,
      779,
      769,
      758,
      747,
      736,
    ],
    40: [
      907,
      914,
      920,
      927,
      933,
      939,
      945,
      951,
      957,
      962,
      968,
      973,
      978,
      983,
      988,
      992,
      996,
      1001,
      1004,
      1008,
      1012,
      1015,
      1018,
      1021,
      1024,
      1027,
      1029,
      1031,
      1033,
      1035,
      1036,
      1038,
      1039,
      1040,
      1040,
      1041,
      1041,
      1041,
      1041,
      1040,
      1039,
      1038,
      1037,
      1036,
      1034,
      1032,
      1030,
      1028,
      1025,
      1023,
      1020,
      1016,
      1013,
      1009,
      1005,
      1001,
      997,
      992,
      987,
      982,
      977,
      971,
      966,
      960,
      954,
      947,
      941,
      934,
      927,
      920,
      912,
      905,
      897,
      889,
      881,
      872,
      864,
      855,
      846,
      837,
      828,
      818,
      808,
      799,
      789,
      779,
      768,
      758,
      747,
      737,
      726,
    ],
    45: [
      907,
      913,
      919,
      925,
      931,
      937,
      942,
      948,
      953,
      958,
      963,
      968,
      972,
      977,
      981,
      985,
      989,
      993,
      996,
      1000,
      1003,
      1006,
      1009,
      1012,
      1014,
      1016,
      1018,
      1020,
      1022,
      1023,
      1025,
      1026,
      1027,
      1027,
      1027,
      1028,
      1028,
      1027,
      1027,
      1026,
      1025,
      1024,
      1023,
      1021,
      1020,
      1018,
      1015,
      1013,
      1010,
      1007,
      1004,
      1001,
      997,
      994,
      990,
      985,
      981,
      976,
      972,
      967,
      961,
      956,
      950,
      944,
      938,
      932,
      925,
      918,
      911,
      904,
      897,
      890,
      882,
      874,
      866,
      858,
      849,
      840,
      832,
      823,
      814,
      804,
      795,
      785,
      776,
      766,
      755,
      746,
      735,
      724,
      714,
    ],
    50: [
      907,
      912,
      918,
      923,
      929,
      934,
      939,
      944,
      949,
      953,
      957,
      962,
      966,
      970,
      974,
      977,
      981,
      984,
      988,
      990,
      993,
      996,
      998,
      1001,
      1003,
      1005,
      1007,
      1008,
      1009,
      1011,
      1011,
      1012,
      1013,
      1013,
      1013,
      1013,
      1013,
      1013,
      1012,
      1011,
      1010,
      1009,
      1007,
      1006,
      1004,
      1001,
      999,
      997,
      994,
      991,
      988,
      984,
      981,
      977,
      973,
      968,
      964,
      959,
      954,
      949,
      944,
      939,
      933,
      927,
      921,
      914,
      908,
      901,
      894,
      887,
      880,
      873,
      865,
      857,
      849,
      841,
      833,
      824,
      816,
      807,
      798,
      789,
      780,
      771,
      761,
      751,
      741,
      731,
      721,
      711,
      700,
    ],
    55: [
      907,
      912,
      917,
      922,
      926,
      931,
      935,
      940,
      944,
      948,
      952,
      956,
      959,
      962,
      966,
      969,
      972,
      975,
      978,
      980,
      983,
      985,
      987,
      989,
      991,
      992,
      994,
      995,
      996,
      997,
      997,
      998,
      998,
      998,
      998,
      998,
      997,
      997,
      996,
      995,
      993,
      992,
      990,
      988,
      986,
      984,
      981,
      979,
      976,
      973,
      969,
      966,
      962,
      958,
      954,
      950,
      945,
      941,
      936,
      931,
      925,
      920,
      914,
      908,
      902,
      896,
      889,
      883,
      876,
      869,
      862,
      854,
      847,
      839,
      831,
      823,
      815,
      807,
      798,
      790,
      781,
      772,
      763,
      754,
      744,
      735,
      725,
      716,
      706,
      696,
      686,
    ],
    60: [
      907,
      911,
      915,
      920,
      924,
      928,
      931,
      935,
      939,
      942,
      946,
      949,
      952,
      955,
      957,
      960,
      963,
      965,
      967,
      969,
      971,
      973,
      975,
      976,
      978,
      979,
      980,
      981,
      981,
      982,
      982,
      982,
      982,
      982,
      982,
      981,
      980,
      980,
      978,
      977,
      975,
      974,
      972,
      970,
      968,
      965,
      962,
      959,
      956,
      953,
      950,
      946,
      942,
      938,
      934,
      930,
      925,
      920,
      915,
      910,
      905,
      899,
      894,
      888,
      882,
      875,
      869,
      863,
      856,
      849,
      842,
      834,
      827,
      820,
      812,
      804,
      796,
      788,
      779,
      771,
      762,
      754,
      745,
      736,
      726,
      718,
      708,
      698,
      689,
      679,
      669,
    ],
    65: [
      907,
      910,
      914,
      918,
      921,
      924,
      927,
      930,
      933,
      936,
      939,
      941,
      944,
      946,
      948,
      951,
      953,
      955,
      956,
      958,
      959,
      961,
      962,
      963,
      964,
      965,
      965,
      965,
      966,
      966,
      966,
      966,
      966,
      965,
      964,
      963,
      962,
      961,
      960,
      958,
      956,
      955,
      952,
      950,
      948,
      945,
      942,
      939,
      936,
      933,
      929,
      925,
      921,
      917,
      913,
      908,
      904,
      899,
      894,
      889,
      883,
      878,
      872,
      866,
      860,
      854,
      847,
      841,
      834,
      828,
      820,
      813,
      806,
      799,
      791,
      783,
      775,
      767,
      759,
      751,
      742,
      734,
      725,
      716,
      707,
      698,
      689,
      680,
      670,
      661,
      651,
    ],
    70: [
      907,
      910,
      913,
      915,
      918,
      921,
      923,
      926,
      928,
      930,
      932,
      934,
      936,
      938,
      939,
      941,
      942,
      943,
      945,
      946,
      947,
      948,
      948,
      949,
      949,
      949,
      950,
      950,
      950,
      949,
      949,
      949,
      948,
      947,
      946,
      945,
      943,
      942,
      940,
      938,
      936,
      934,
      932,
      929,
      927,
      924,
      921,
      918,
      914,
      911,
      907,
      903,
      899,
      895,
      890,
      886,
      881,
      876,
      871,
      866,
      860,
      855,
      849,
      843,
      837,
      831,
      825,
      818,
      811,
      805,
      798,
      791,
      783,
      776,
      769,
      762,
      753,
      745,
      737,
      729,
      721,
      712,
      704,
      696,
      686,
      678,
      669,
      660,
      651,
      642,
      633,
    ],
    75: [
      907,
      909,
      911,
      913,
      915,
      917,
      919,
      920,
      922,
      923,
      925,
      926,
      927,
      928,
      929,
      930,
      931,
      932,
      933,
      933,
      933,
      934,
      934,
      934,
      934,
      934,
      934,
      933,
      933,
      932,
      931,
      930,
      929,
      928,
      927,
      925,
      923,
      922,
      920,
      917,
      915,
      913,
      910,
      907,
      905,
      902,
      898,
      895,
      891,
      888,
      884,
      880,
      876,
      871,
      867,
      862,
      857,
      852,
      847,
      842,
      837,
      831,
      825,
      819,
      813,
      807,
      801,
      794,
      788,
      781,
      774,
      767,
      760,
      753,
      745,
      738,
      730,
      722,
      714,
      707,
      699,
      690,
      682,
      674,
      665,
      656,
      648,
      639,
      630,
      621,
      612,
    ],
    80: [
      907,
      908,
      910,
      911,
      912,
      913,
      914,
      915,
      916,
      917,
      917,
      918,
      918,
      919,
      919,
      920,
      920,
      920,
      920,
      920,
      920,
      919,
      919,
      919,
      918,
      918,
      917,
      916,
      915,
      914,
      913,
      912,
      910,
      908,
      907,
      905,
      903,
      901,
      898,
      896,
      893,
      891,
      888,
      885,
      882,
      878,
      875,
      871,
      868,
      864,
      860,
      856,
      851,
      847,
      842,
      837,
      833,
      828,
      822,
      817,
      811,
      806,
      800,
      794,
      788,
      782,
      776,
      769,
      763,
      756,
      749,
      742,
      735,
      728,
      721,
      713,
      706,
      698,
      690,
      683,
      675,
      668,
      658,
      650,
      642,
      634,
      625,
      617,
      608,
      600,
      591,
    ],
    85: [
      907,
      907,
      908,
      909,
      909,
      909,
      910,
      910,
      910,
      910,
      910,
      910,
      910,
      909,
      909,
      908,
      908,
      908,
      907,
      907,
      906,
      905,
      904,
      903,
      902,
      901,
      900,
      898,
      897,
      895,
      894,
      892,
      890,
      888,
      886,
      884,
      881,
      879,
      876,
      874,
      871,
      868,
      865,
      861,
      858,
      855,
      851,
      847,
      843,
      839,
      835,
      831,
      826,
      822,
      817,
      812,
      807,
      802,
      797,
      791,
      786,
      780,
      774,
      768,
      762,
      756,
      750,
      743,
      737,
      730,
      724,
      717,
      710,
      703,
      696,
      688,
      681,
      673,
      666,
      658,
      650,
      643,
      635,
      626,
      618,
      611,
      603,
      594,
      585,
      577,
      569,
    ],
    90: [
      907,
      907,
      907,
      906,
      906,
      906,
      905,
      904,
      904,
      903,
      902,
      901,
      900,
      899,
      898,
      897,
      896,
      895,
      894,
      893,
      891,
      890,
      888,
      887,
      885,
      883,
      882,
      880,
      878,
      876,
      874,
      872,
      870,
      867,
      864,
      862,
      859,
      856,
      854,
      851,
      847,
      844,
      841,
      837,
      834,
      830,
      826,
      822,
      818,
      814,
      809,
      805,
      800,
      795,
      791,
      786,
      781,
      775,
      770,
      764,
      759,
      753,
      747,
      742,
      735,
      729,
      723,
      717,
      710,
      704,
      697,
      690,
      683,
      676,
      669,
      662,
      655,
      647,
      640,
      633,
      625,
      617,
      610,
      602,
      594,
      586,
      578,
      570,
      561,
      554,
      546,
    ],
    95: [
      907,
      906,
      905,
      904,
      903,
      902,
      900,
      899,
      897,
      896,
      894,
      893,
      891,
      889,
      888,
      886,
      884,
      882,
      880,
      879,
      876,
      875,
      872,
      870,
      868,
      866,
      864,
      861,
      859,
      856,
      854,
      851,
      849,
      846,
      843,
      840,
      837,
      834,
      830,
      827,
      823,
      820,
      816,
      813,
      809,
      805,
      801,
      796,
      792,
      788,
      783,
      778,
      774,
      769,
      764,
      759,
      753,
      748,
      743,
      737,
      732,
      726,
      720,
      714,
      708,
      702,
      696,
      689,
      683,
      677,
      670,
      663,
      656,
      649,
      642,
      635,
      628,
      621,
      614,
      607,
      599,
      592,
      584,
      577,
      569,
      561,
      553,
      546,
      538,
      530,
      522,
    ],
    100: [
      907,
      905,
      903,
      902,
      900,
      898,
      896,
      893,
      891,
      889,
      887,
      884,
      882,
      880,
      877,
      875,
      872,
      870,
      867,
      864,
      862,
      859,
      856,
      854,
      851,
      848,
      845,
      842,
      840,
      836,
      833,
      831,
      827,
      824,
      821,
      817,
      814,
      810,
      807,
      803,
      799,
      795,
      791,
      787,
      783,
      779,
      775,
      770,
      766,
      761,
      756,
      752,
      747,
      742,
      736,
      731,
      726,
      721,
      715,
      710,
      704,
      698,
      692,
      686,
      680,
      674,
      668,
      662,
      655,
      649,
      642,
      636,
      629,
      622,
      616,
      608,
      601,
      594,
      587,
      580,
      573,
      566,
      558,
      551,
      543,
      536,
      529,
      521,
      513,
      506,
      498,
    ],
    105: [
      907,
      904,
      902,
      899,
      897,
      894,
      891,
      888,
      885,
      882,
      879,
      876,
      873,
      870,
      866,
      863,
      860,
      857,
      853,
      850,
      847,
      843,
      840,
      837,
      834,
      830,
      827,
      823,
      820,
      816,
      813,
      809,
      805,
      802,
      798,
      795,
      791,
      787,
      783,
      779,
      774,
      771,
      766,
      762,
      757,
      753,
      748,
      744,
      739,
      734,
      729,
      724,
      719,
      714,
      709,
      703,
      698,
      693,
      687,
      681,
      676,
      670,
      664,
      658,
      652,
      646,
      640,
      633,
      627,
      621,
      614,
      608,
      601,
      594,
      588,
      581,
      574,
      567,
      560,
      554,
      546,
      539,
      532,
      525,
      518,
      510,
      503,
      496,
      488,
      481,
      474,
    ],
    110: [
      907,
      904,
      900,
      897,
      894,
      890,
      887,
      883,
      879,
      875,
      871,
      868,
      864,
      860,
      856,
      852,
      848,
      844,
      840,
      836,
      832,
      828,
      824,
      820,
      816,
      812,
      808,
      804,
      800,
      796,
      792,
      788,
      784,
      780,
      776,
      772,
      767,
      763,
      759,
      754,
      750,
      745,
      741,
      736,
      731,
      727,
      722,
      717,
      712,
      707,
      702,
      697,
      692,
      686,
      681,
      676,
      670,
      665,
      659,
      653,
      647,
      642,
      636,
      630,
      624,
      618,
      611,
      605,
      599,
      593,
      586,
      580,
      573,
      567,
      560,
      553,
      547,
      540,
      533,
      526,
      520,
      513,
      505,
      499,
      492,
      485,
      478,
      471,
      464,
      456,
      449,
    ],
    115: [
      907,
      903,
      899,
      895,
      891,
      886,
      882,
      878,
      873,
      869,
      864,
      859,
      855,
      850,
      845,
      841,
      836,
      832,
      827,
      822,
      818,
      813,
      808,
      804,
      799,
      794,
      790,
      785,
      781,
      776,
      772,
      767,
      763,
      758,
      753,
      749,
      744,
      739,
      735,
      730,
      725,
      720,
      715,
      710,
      706,
      701,
      695,
      691,
      685,
      680,
      675,
      669,
      664,
      659,
      653,
      648,
      642,
      637,
      631,
      625,
      619,
      613,
      607,
      601,
      595,
      589,
      583,
      577,
      571,
      564,
      558,
      552,
      545,
      539,
      532,
      526,
      520,
      513,
      506,
      499,
      493,
      486,
      480,
      473,
      466,
      459,
      452,
      445,
      439,
      432,
      425,
    ],
    120: [
      907,
      902,
      897,
      893,
      888,
      883,
      878,
      873,
      867,
      862,
      857,
      852,
      846,
      841,
      836,
      830,
      825,
      819,
      814,
      809,
      803,
      798,
      793,
      787,
      782,
      777,
      772,
      767,
      762,
      757,
      751,
      746,
      741,
      736,
      731,
      726,
      721,
      716,
      711,
      706,
      701,
      695,
      690,
      685,
      680,
      674,
      669,
      664,
      659,
      653,
      648,
      642,
      637,
      631,
      626,
      620,
      614,
      609,
      603,
      597,
      591,
      585,
      579,
      573,
      567,
      561,
      555,
      549,
      543,
      537,
      530,
      524,
      518,
      511,
      505,
      499,
      493,
      486,
      480,
      473,
      467,
      460,
      454,
      447,
      440,
      434,
      428,
      421,
      414,
      408,
      401,
    ],
    125: [
      907,
      902,
      896,
      891,
      885,
      879,
      874,
      868,
      862,
      856,
      850,
      844,
      838,
      832,
      826,
      820,
      814,
      808,
      801,
      796,
      789,
      784,
      778,
      772,
      766,
      760,
      754,
      749,
      743,
      737,
      731,
      726,
      720,
      715,
      709,
      704,
      698,
      693,
      687,
      682,
      676,
      671,
      665,
      660,
      654,
      649,
      644,
      638,
      632,
      627,
      621,
      615,
      610,
      604,
      599,
      593,
      587,
      581,
      575,
      570,
      564,
      558,
      552,
      546,
      540,
      534,
      528,
      522,
      516,
      510,
      503,
      497,
      491,
      485,
      479,
      473,
      466,
      460,
      454,
      447,
      441,
      435,
      429,
      422,
      416,
      410,
      403,
      397,
      391,
      384,
      378,
    ],
    130: [
      907,
      901,
      895,
      889,
      883,
      876,
      870,
      863,
      857,
      850,
      844,
      837,
      830,
      824,
      817,
      810,
      803,
      797,
      790,
      783,
      777,
      770,
      764,
      757,
      750,
      744,
      738,
      731,
      725,
      719,
      713,
      706,
      700,
      694,
      688,
      682,
      676,
      670,
      665,
      658,
      653,
      647,
      641,
      635,
      630,
      624,
      618,
      612,
      607,
      601,
      595,
      589,
      584,
      578,
      572,
      566,
      560,
      554,
      549,
      543,
      537,
      531,
      525,
      519,
      513,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
      459,
      453,
      447,
      440,
      434,
      428,
      423,
      416,
      410,
      404,
      398,
      392,
      386,
      380,
      374,
      367,
      362,
      356,
    ],
    135: [
      907,
      900,
      894,
      887,
      880,
      873,
      866,
      859,
      852,
      845,
      838,
      830,
      823,
      816,
      808,
      801,
      793,
      786,
      779,
      772,
      764,
      757,
      750,
      743,
      736,
      729,
      722,
      715,
      708,
      701,
      694,
      688,
      681,
      674,
      668,
      662,
      655,
      649,
      642,
      636,
      630,
      624,
      618,
      612,
      606,
      600,
      594,
      588,
      582,
      576,
      570,
      564,
      558,
      552,
      546,
      540,
      534,
      528,
      523,
      517,
      511,
      505,
      499,
      493,
      487,
      481,
      475,
      469,
      463,
      457,
      451,
      446,
      440,
      434,
      428,
      422,
      416,
      410,
      404,
      398,
      392,
      387,
      381,
      375,
      369,
      363,
      357,
      352,
      346,
      340,
      334,
    ],
    140: [
      907,
      900,
      893,
      885,
      878,
      871,
      863,
      855,
      848,
      840,
      832,
      824,
      816,
      808,
      800,
      792,
      785,
      777,
      769,
      761,
      753,
      745,
      738,
      730,
      723,
      715,
      707,
      700,
      693,
      685,
      678,
      671,
      663,
      656,
      649,
      642,
      635,
      628,
      622,
      615,
      608,
      602,
      595,
      589,
      583,
      576,
      570,
      564,
      558,
      552,
      545,
      539,
      533,
      527,
      521,
      515,
      509,
      503,
      498,
      492,
      486,
      480,
      474,
      468,
      462,
      456,
      450,
      445,
      439,
      433,
      427,
      421,
      415,
      410,
      404,
      398,
      393,
      387,
      381,
      375,
      370,
      364,
      358,
      352,
      347,
      342,
      336,
      330,
      325,
      320,
      314,
    ],
    145: [
      907,
      899,
      892,
      884,
      876,
      868,
      860,
      852,
      844,
      835,
      827,
      819,
      810,
      802,
      793,
      785,
      776,
      768,
      760,
      751,
      743,
      735,
      727,
      719,
      711,
      703,
      695,
      687,
      679,
      671,
      663,
      655,
      648,
      640,
      632,
      625,
      617,
      610,
      602,
      595,
      588,
      581,
      574,
      567,
      560,
      554,
      547,
      541,
      535,
      528,
      522,
      516,
      510,
      504,
      497,
      491,
      486,
      480,
      473,
      468,
      462,
      456,
      450,
      444,
      438,
      433,
      427,
      421,
      416,
      410,
      404,
      398,
      393,
      387,
      382,
      376,
      370,
      365,
      360,
      354,
      348,
      343,
      338,
      332,
      327,
      322,
      316,
      310,
      305,
      300,
      295,
    ],
    150: [
      907,
      899,
      891,
      883,
      874,
      866,
      857,
      849,
      840,
      831,
      822,
      814,
      805,
      796,
      787,
      778,
      769,
      761,
      752,
      743,
      735,
      726,
      718,
      709,
      701,
      692,
      684,
      676,
      667,
      659,
      650,
      642,
      634,
      626,
      618,
      610,
      602,
      594,
      586,
      578,
      570,
      563,
      555,
      548,
      541,
      534,
      527,
      520,
      513,
      506,
      500,
      494,
      487,
      481,
      475,
      469,
      463,
      457,
      451,
      445,
      439,
      433,
      427,
      422,
      416,
      410,
      405,
      399,
      394,
      388,
      382,
      377,
      371,
      366,
      360,
      355,
      350,
      344,
      339,
      334,
      329,
      323,
      318,
      313,
      308,
      303,
      297,
      293,
      288,
      283,
      278,
    ],
    155: [
      907,
      899,
      890,
      882,
      873,
      864,
      855,
      846,
      837,
      828,
      819,
      809,
      800,
      791,
      782,
      772,
      763,
      754,
      745,
      736,
      728,
      719,
      710,
      701,
      692,
      683,
      675,
      666,
      657,
      648,
      640,
      631,
      622,
      614,
      605,
      597,
      588,
      580,
      572,
      564,
      555,
      547,
      539,
      532,
      524,
      516,
      509,
      501,
      494,
      487,
      480,
      474,
      467,
      461,
      454,
      448,
      442,
      436,
      430,
      424,
      418,
      412,
      407,
      401,
      395,
      390,
      384,
      379,
      373,
      368,
      363,
      357,
      352,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      310,
      305,
      301,
      296,
      291,
      285,
      281,
      276,
      271,
      266,
      262,
    ],
    160: [
      907,
      898,
      890,
      881,
      872,
      863,
      853,
      844,
      835,
      825,
      816,
      806,
      796,
      787,
      777,
      768,
      758,
      749,
      740,
      731,
      722,
      713,
      704,
      694,
      685,
      676,
      667,
      658,
      649,
      640,
      631,
      622,
      613,
      604,
      595,
      586,
      577,
      569,
      560,
      551,
      543,
      535,
      526,
      518,
      510,
      502,
      494,
      487,
      479,
      472,
      464,
      457,
      450,
      443,
      437,
      430,
      424,
      418,
      412,
      406,
      400,
      394,
      388,
      383,
      377,
      372,
      366,
      361,
      356,
      350,
      345,
      340,
      335,
      330,
      325,
      319,
      315,
      309,
      305,
      300,
      295,
      290,
      285,
      280,
      276,
      271,
      266,
      262,
      257,
      253,
      249,
    ],
    165: [
      907,
      898,
      889,
      880,
      871,
      861,
      852,
      842,
      833,
      823,
      813,
      803,
      793,
      784,
      774,
      764,
      754,
      745,
      736,
      727,
      717,
      708,
      699,
      690,
      680,
      670,
      661,
      652,
      642,
      633,
      624,
      615,
      605,
      596,
      587,
      578,
      569,
      560,
      551,
      542,
      533,
      524,
      516,
      508,
      499,
      491,
      483,
      475,
      467,
      460,
      452,
      445,
      437,
      430,
      423,
      416,
      410,
      403,
      397,
      391,
      385,
      379,
      373,
      368,
      362,
      357,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
      301,
      296,
      291,
      287,
      282,
      277,
      273,
      268,
      264,
      259,
      255,
      250,
      246,
      242,
      237,
    ],
    170: [
      907,
      898,
      889,
      879,
      870,
      860,
      851,
      841,
      831,
      821,
      811,
      801,
      791,
      781,
      771,
      761,
      752,
      742,
      733,
      723,
      714,
      705,
      696,
      686,
      676,
      666,
      657,
      647,
      638,
      628,
      619,
      609,
      600,
      591,
      581,
      572,
      563,
      554,
      544,
      536,
      527,
      518,
      509,
      500,
      492,
      483,
      474,
      466,
      459,
      451,
      443,
      435,
      428,
      421,
      413,
      406,
      400,
      393,
      386,
      380,
      374,
      368,
      362,
      357,
      351,
      346,
      340,
      335,
      330,
      325,
      320,
      315,
      310,
      305,
      300,
      296,
      291,
      286,
      282,
      277,
      272,
      268,
      264,
      259,
      254,
      250,
      246,
      242,
      237,
      234,
      229,
    ],
    175: [
      907,
      898,
      888,
      879,
      870,
      860,
      850,
      840,
      830,
      820,
      810,
      800,
      790,
      780,
      770,
      760,
      750,
      741,
      731,
      722,
      712,
      703,
      693,
      683,
      673,
      664,
      654,
      645,
      635,
      625,
      616,
      606,
      597,
      587,
      578,
      568,
      558,
      549,
      540,
      531,
      522,
      514,
      505,
      497,
      488,
      479,
      470,
      462,
      453,
      445,
      438,
      430,
      422,
      415,
      408,
      400,
      393,
      387,
      380,
      374,
      368,
      362,
      356,
      350,
      344,
      339,
      333,
      328,
      323,
      318,
      313,
      308,
      303,
      299,
      294,
      289,
      285,
      280,
      276,
      271,
      266,
      262,
      258,
      253,
      249,
      245,
      241,
      236,
      232,
      228,
      224,
    ],
  },
  '5E': {
    0: [
      820,
      827,
      834,
      841,
      848,
      855,
      861,
      868,
      874,
      880,
      886,
      892,
      898,
      903,
      908,
      913,
      918,
      923,
      927,
      931,
      935,
      939,
      943,
      946,
      949,
      952,
      955,
      958,
      960,
      962,
      964,
      966,
      967,
      969,
      970,
      971,
      971,
      972,
      972,
      972,
      971,
      971,
      970,
      969,
      968,
      967,
      965,
      963,
      961,
      959,
      956,
      953,
      950,
      947,
      944,
      940,
      936,
      932,
      928,
      923,
      918,
      914,
      908,
      903,
      897,
      891,
      885,
      879,
      873,
      866,
      859,
      852,
      845,
      837,
      830,
      822,
      814,
      805,
      797,
      788,
      779,
      770,
      761,
      752,
      742,
      733,
      722,
      712,
      702,
      692,
      682,
    ],
    5: [
      820,
      827,
      834,
      841,
      848,
      855,
      861,
      868,
      874,
      880,
      886,
      892,
      897,
      903,
      908,
      913,
      918,
      922,
      927,
      931,
      935,
      939,
      942,
      946,
      949,
      952,
      955,
      957,
      959,
      962,
      964,
      965,
      967,
      968,
      969,
      970,
      970,
      971,
      971,
      971,
      971,
      970,
      969,
      968,
      967,
      966,
      964,
      962,
      960,
      958,
      955,
      953,
      950,
      946,
      943,
      939,
      935,
      931,
      927,
      922,
      918,
      913,
      908,
      902,
      896,
      891,
      885,
      878,
      872,
      865,
      858,
      851,
      844,
      836,
      829,
      821,
      813,
      805,
      796,
      787,
      779,
      770,
      760,
      751,
      742,
      732,
      722,
      712,
      702,
      691,
      681,
    ],
    10: [
      820,
      826,
      834,
      841,
      848,
      854,
      861,
      867,
      873,
      879,
      885,
      891,
      896,
      902,
      907,
      912,
      916,
      921,
      925,
      930,
      933,
      937,
      941,
      944,
      947,
      950,
      953,
      955,
      958,
      960,
      962,
      963,
      965,
      966,
      967,
      968,
      968,
      969,
      969,
      969,
      968,
      968,
      967,
      966,
      965,
      964,
      962,
      960,
      958,
      956,
      953,
      950,
      947,
      944,
      941,
      937,
      933,
      929,
      925,
      920,
      915,
      910,
      905,
      900,
      894,
      888,
      882,
      876,
      870,
      863,
      856,
      849,
      842,
      834,
      827,
      819,
      811,
      802,
      794,
      786,
      777,
      768,
      759,
      749,
      740,
      730,
      720,
      710,
      700,
      690,
      679,
    ],
    15: [
      820,
      826,
      833,
      840,
      847,
      854,
      860,
      866,
      872,
      878,
      884,
      889,
      895,
      900,
      905,
      910,
      915,
      919,
      923,
      927,
      931,
      935,
      938,
      941,
      944,
      947,
      950,
      952,
      955,
      957,
      959,
      960,
      961,
      963,
      964,
      964,
      965,
      965,
      965,
      965,
      965,
      964,
      963,
      962,
      961,
      960,
      958,
      956,
      954,
      952,
      949,
      946,
      943,
      940,
      937,
      933,
      929,
      925,
      921,
      916,
      911,
      906,
      901,
      896,
      890,
      884,
      878,
      872,
      866,
      859,
      852,
      845,
      838,
      831,
      823,
      815,
      807,
      799,
      791,
      782,
      773,
      764,
      755,
      746,
      737,
      727,
      717,
      708,
      697,
      687,
      677,
    ],
    20: [
      820,
      826,
      833,
      840,
      846,
      853,
      859,
      865,
      871,
      877,
      882,
      888,
      893,
      898,
      903,
      907,
      912,
      916,
      920,
      924,
      928,
      931,
      935,
      938,
      941,
      943,
      946,
      948,
      950,
      952,
      954,
      956,
      957,
      958,
      959,
      959,
      960,
      960,
      960,
      960,
      960,
      959,
      958,
      957,
      956,
      954,
      953,
      951,
      948,
      946,
      943,
      941,
      938,
      934,
      931,
      927,
      923,
      919,
      915,
      910,
      906,
      901,
      895,
      890,
      884,
      879,
      873,
      866,
      860,
      853,
      847,
      840,
      832,
      825,
      818,
      810,
      802,
      794,
      786,
      777,
      768,
      760,
      751,
      742,
      732,
      723,
      713,
      703,
      693,
      683,
      673,
    ],
    25: [
      820,
      826,
      833,
      839,
      845,
      851,
      857,
      863,
      869,
      874,
      880,
      885,
      890,
      895,
      899,
      904,
      908,
      912,
      916,
      920,
      924,
      927,
      930,
      933,
      936,
      939,
      941,
      943,
      945,
      947,
      948,
      950,
      951,
      952,
      953,
      953,
      954,
      954,
      954,
      954,
      953,
      952,
      951,
      950,
      949,
      947,
      945,
      943,
      941,
      939,
      936,
      933,
      930,
      927,
      924,
      920,
      916,
      912,
      907,
      903,
      898,
      893,
      888,
      883,
      877,
      871,
      865,
      859,
      853,
      846,
      840,
      833,
      826,
      818,
      811,
      803,
      795,
      787,
      779,
      770,
      762,
      753,
      745,
      736,
      726,
      717,
      707,
      698,
      688,
      678,
      668,
    ],
    30: [
      820,
      826,
      832,
      838,
      844,
      850,
      856,
      861,
      867,
      872,
      877,
      882,
      887,
      891,
      896,
      900,
      904,
      908,
      912,
      915,
      918,
      922,
      925,
      927,
      930,
      932,
      935,
      937,
      939,
      940,
      942,
      943,
      944,
      945,
      945,
      946,
      946,
      946,
      946,
      945,
      945,
      944,
      943,
      942,
      940,
      939,
      937,
      935,
      933,
      930,
      927,
      924,
      921,
      918,
      915,
      911,
      907,
      903,
      898,
      894,
      889,
      884,
      879,
      874,
      868,
      862,
      856,
      850,
      844,
      837,
      831,
      824,
      817,
      809,
      802,
      795,
      787,
      779,
      771,
      762,
      754,
      746,
      737,
      728,
      719,
      709,
      700,
      691,
      681,
      671,
      661,
    ],
    35: [
      820,
      825,
      831,
      837,
      843,
      848,
      854,
      859,
      864,
      869,
      874,
      878,
      883,
      887,
      891,
      895,
      899,
      903,
      906,
      909,
      912,
      915,
      918,
      921,
      923,
      925,
      927,
      929,
      931,
      932,
      934,
      935,
      936,
      936,
      937,
      937,
      937,
      937,
      937,
      936,
      935,
      934,
      933,
      932,
      930,
      929,
      927,
      925,
      922,
      920,
      917,
      914,
      911,
      907,
      904,
      900,
      896,
      892,
      888,
      883,
      878,
      873,
      868,
      863,
      857,
      852,
      846,
      840,
      833,
      827,
      820,
      813,
      806,
      799,
      792,
      784,
      777,
      769,
      761,
      753,
      744,
      736,
      727,
      719,
      709,
      701,
      691,
      682,
      672,
      663,
      653,
    ],
    40: [
      820,
      825,
      830,
      836,
      841,
      846,
      851,
      856,
      861,
      866,
      870,
      874,
      878,
      882,
      886,
      890,
      893,
      897,
      900,
      903,
      906,
      908,
      911,
      913,
      915,
      917,
      919,
      921,
      922,
      923,
      924,
      925,
      926,
      927,
      927,
      927,
      927,
      927,
      926,
      926,
      925,
      923,
      922,
      921,
      919,
      917,
      915,
      913,
      911,
      908,
      905,
      902,
      899,
      895,
      892,
      888,
      884,
      880,
      875,
      871,
      866,
      861,
      856,
      850,
      845,
      839,
      833,
      827,
      821,
      815,
      808,
      801,
      794,
      787,
      780,
      773,
      765,
      757,
      749,
      741,
      733,
      725,
      716,
      708,
      699,
      690,
      681,
      672,
      662,
      653,
      644,
    ],
    45: [
      820,
      824,
      830,
      835,
      839,
      844,
      849,
      853,
      857,
      862,
      866,
      870,
      873,
      877,
      880,
      884,
      887,
      890,
      893,
      896,
      898,
      900,
      903,
      905,
      907,
      908,
      910,
      911,
      912,
      914,
      914,
      915,
      915,
      916,
      916,
      916,
      915,
      915,
      914,
      913,
      912,
      911,
      910,
      908,
      906,
      905,
      902,
      900,
      897,
      895,
      892,
      889,
      885,
      882,
      878,
      874,
      870,
      866,
      861,
      857,
      852,
      847,
      842,
      836,
      831,
      825,
      819,
      813,
      807,
      801,
      794,
      788,
      781,
      774,
      766,
      759,
      752,
      744,
      736,
      728,
      720,
      712,
      703,
      695,
      687,
      678,
      669,
      660,
      651,
      642,
      632,
    ],
    50: [
      820,
      824,
      829,
      833,
      837,
      842,
      846,
      850,
      854,
      858,
      861,
      865,
      868,
      871,
      874,
      877,
      880,
      883,
      885,
      888,
      890,
      892,
      894,
      896,
      897,
      899,
      900,
      901,
      902,
      903,
      903,
      904,
      904,
      904,
      904,
      903,
      903,
      902,
      901,
      900,
      899,
      898,
      896,
      895,
      893,
      890,
      888,
      886,
      883,
      880,
      877,
      874,
      870,
      867,
      863,
      859,
      855,
      851,
      846,
      842,
      837,
      832,
      827,
      821,
      815,
      810,
      804,
      798,
      792,
      786,
      779,
      773,
      766,
      759,
      752,
      744,
      737,
      729,
      722,
      714,
      706,
      698,
      690,
      682,
      673,
      664,
      656,
      647,
      638,
      629,
      620,
    ],
    55: [
      820,
      823,
      828,
      832,
      835,
      839,
      843,
      846,
      850,
      853,
      856,
      859,
      862,
      865,
      868,
      870,
      873,
      875,
      877,
      879,
      881,
      883,
      884,
      885,
      887,
      888,
      889,
      890,
      890,
      891,
      891,
      891,
      891,
      891,
      891,
      890,
      889,
      888,
      887,
      886,
      885,
      883,
      882,
      880,
      878,
      875,
      873,
      870,
      867,
      864,
      861,
      858,
      854,
      851,
      847,
      843,
      839,
      834,
      830,
      825,
      820,
      815,
      810,
      805,
      799,
      793,
      788,
      782,
      776,
      769,
      763,
      756,
      749,
      742,
      735,
      728,
      721,
      714,
      706,
      698,
      691,
      683,
      674,
      666,
      658,
      650,
      641,
      632,
      624,
      615,
      606,
    ],
    60: [
      820,
      823,
      826,
      830,
      833,
      836,
      840,
      843,
      845,
      848,
      851,
      854,
      856,
      858,
      861,
      863,
      865,
      866,
      868,
      870,
      871,
      872,
      874,
      875,
      876,
      876,
      877,
      878,
      878,
      878,
      878,
      878,
      878,
      877,
      876,
      876,
      875,
      874,
      872,
      871,
      869,
      868,
      866,
      864,
      861,
      859,
      856,
      854,
      851,
      848,
      844,
      841,
      837,
      833,
      830,
      825,
      821,
      817,
      812,
      807,
      802,
      797,
      792,
      787,
      781,
      776,
      770,
      764,
      758,
      752,
      745,
      738,
      732,
      725,
      718,
      711,
      704,
      697,
      689,
      681,
      674,
      666,
      658,
      650,
      642,
      634,
      625,
      617,
      609,
      600,
      591,
    ],
    65: [
      820,
      822,
      825,
      828,
      831,
      834,
      836,
      839,
      841,
      843,
      845,
      847,
      849,
      851,
      853,
      855,
      856,
      858,
      859,
      860,
      861,
      862,
      863,
      863,
      864,
      864,
      865,
      865,
      865,
      865,
      864,
      864,
      863,
      862,
      862,
      861,
      859,
      858,
      857,
      855,
      853,
      851,
      849,
      847,
      844,
      842,
      839,
      836,
      833,
      830,
      826,
      823,
      819,
      815,
      811,
      807,
      803,
      798,
      793,
      789,
      784,
      779,
      773,
      768,
      763,
      757,
      751,
      745,
      739,
      733,
      726,
      720,
      713,
      707,
      700,
      693,
      686,
      678,
      671,
      663,
      656,
      648,
      641,
      633,
      625,
      617,
      609,
      600,
      592,
      584,
      575,
    ],
    70: [
      820,
      822,
      824,
      826,
      828,
      831,
      833,
      835,
      836,
      838,
      840,
      841,
      843,
      844,
      845,
      846,
      847,
      848,
      849,
      850,
      850,
      851,
      851,
      851,
      852,
      852,
      851,
      851,
      851,
      850,
      850,
      849,
      848,
      847,
      846,
      845,
      843,
      842,
      840,
      838,
      836,
      834,
      831,
      829,
      826,
      824,
      821,
      818,
      814,
      811,
      807,
      804,
      800,
      796,
      792,
      788,
      783,
      779,
      774,
      769,
      764,
      759,
      754,
      748,
      743,
      737,
      731,
      725,
      719,
      713,
      707,
      700,
      694,
      687,
      681,
      674,
      666,
      659,
      652,
      645,
      637,
      630,
      622,
      615,
      607,
      599,
      591,
      583,
      575,
      567,
      558,
    ],
    75: [
      820,
      821,
      823,
      824,
      826,
      828,
      829,
      830,
      831,
      833,
      834,
      835,
      835,
      836,
      837,
      837,
      838,
      839,
      839,
      839,
      839,
      839,
      839,
      839,
      839,
      838,
      838,
      837,
      836,
      836,
      835,
      834,
      833,
      831,
      829,
      828,
      826,
      824,
      822,
      820,
      818,
      816,
      813,
      810,
      808,
      805,
      802,
      798,
      795,
      791,
      788,
      784,
      780,
      776,
      772,
      767,
      763,
      758,
      753,
      748,
      743,
      738,
      733,
      728,
      722,
      716,
      711,
      705,
      699,
      692,
      686,
      680,
      673,
      667,
      660,
      653,
      647,
      639,
      632,
      625,
      618,
      610,
      603,
      596,
      588,
      580,
      572,
      565,
      557,
      549,
      541,
    ],
    80: [
      820,
      820,
      822,
      823,
      823,
      824,
      825,
      826,
      826,
      827,
      827,
      828,
      828,
      828,
      828,
      828,
      828,
      828,
      828,
      828,
      828,
      827,
      827,
      826,
      825,
      825,
      824,
      823,
      822,
      820,
      819,
      818,
      816,
      814,
      813,
      811,
      809,
      807,
      804,
      802,
      799,
      797,
      794,
      791,
      788,
      785,
      782,
      778,
      775,
      771,
      767,
      763,
      759,
      755,
      751,
      746,
      742,
      737,
      732,
      727,
      722,
      717,
      712,
      706,
      701,
      695,
      689,
      683,
      677,
      671,
      665,
      659,
      652,
      646,
      639,
      632,
      626,
      619,
      611,
      605,
      598,
      591,
      582,
      575,
      568,
      561,
      553,
      545,
      538,
      530,
      522,
    ],
    85: [
      820,
      820,
      820,
      821,
      821,
      821,
      821,
      821,
      821,
      821,
      821,
      821,
      820,
      820,
      820,
      819,
      819,
      818,
      817,
      817,
      816,
      815,
      814,
      813,
      812,
      810,
      809,
      808,
      806,
      805,
      803,
      801,
      799,
      797,
      795,
      793,
      791,
      788,
      786,
      783,
      780,
      777,
      774,
      771,
      768,
      765,
      761,
      758,
      754,
      750,
      746,
      742,
      738,
      734,
      729,
      725,
      720,
      715,
      710,
      705,
      700,
      695,
      690,
      684,
      678,
      673,
      667,
      661,
      655,
      649,
      643,
      637,
      630,
      624,
      618,
      611,
      604,
      597,
      590,
      583,
      576,
      570,
      563,
      555,
      548,
      541,
      534,
      526,
      518,
      510,
      503,
    ],
    90: [
      820,
      819,
      819,
      819,
      818,
      818,
      817,
      817,
      816,
      815,
      815,
      814,
      813,
      812,
      811,
      810,
      809,
      807,
      806,
      805,
      804,
      802,
      801,
      799,
      798,
      796,
      794,
      792,
      790,
      789,
      787,
      784,
      782,
      780,
      777,
      775,
      772,
      769,
      767,
      764,
      761,
      758,
      754,
      751,
      748,
      744,
      740,
      737,
      733,
      729,
      725,
      720,
      716,
      712,
      707,
      702,
      698,
      693,
      688,
      683,
      677,
      672,
      667,
      661,
      656,
      650,
      644,
      639,
      633,
      626,
      620,
      614,
      608,
      601,
      595,
      589,
      582,
      575,
      569,
      562,
      555,
      548,
      541,
      534,
      527,
      520,
      513,
      506,
      498,
      490,
      484,
    ],
    95: [
      820,
      818,
      818,
      817,
      816,
      815,
      813,
      812,
      811,
      809,
      808,
      807,
      805,
      803,
      802,
      800,
      799,
      797,
      795,
      793,
      791,
      789,
      787,
      786,
      783,
      781,
      779,
      777,
      774,
      772,
      770,
      767,
      764,
      762,
      759,
      756,
      753,
      750,
      747,
      744,
      741,
      737,
      734,
      730,
      727,
      723,
      719,
      715,
      711,
      707,
      703,
      698,
      694,
      689,
      684,
      680,
      675,
      670,
      665,
      660,
      655,
      649,
      644,
      638,
      633,
      627,
      621,
      615,
      610,
      604,
      597,
      591,
      585,
      579,
      573,
      566,
      560,
      553,
      546,
      540,
      533,
      526,
      519,
      513,
      506,
      499,
      492,
      485,
      478,
      471,
      463,
    ],
    100: [
      820,
      818,
      816,
      815,
      813,
      811,
      810,
      808,
      806,
      804,
      802,
      799,
      797,
      795,
      793,
      791,
      788,
      786,
      784,
      781,
      779,
      777,
      774,
      771,
      769,
      766,
      764,
      761,
      758,
      755,
      753,
      750,
      747,
      744,
      741,
      737,
      734,
      731,
      727,
      724,
      720,
      717,
      713,
      709,
      705,
      701,
      697,
      693,
      689,
      685,
      680,
      676,
      671,
      666,
      662,
      657,
      652,
      647,
      642,
      637,
      631,
      626,
      620,
      615,
      609,
      604,
      598,
      592,
      586,
      580,
      574,
      568,
      562,
      556,
      550,
      543,
      537,
      530,
      524,
      517,
      511,
      504,
      498,
      491,
      484,
      478,
      471,
      464,
      457,
      450,
      443,
    ],
    105: [
      820,
      817,
      815,
      813,
      810,
      808,
      806,
      803,
      800,
      798,
      795,
      792,
      790,
      787,
      784,
      781,
      778,
      775,
      772,
      769,
      767,
      764,
      761,
      758,
      755,
      751,
      748,
      745,
      742,
      739,
      736,
      732,
      729,
      725,
      722,
      719,
      715,
      711,
      708,
      704,
      700,
      696,
      692,
      688,
      684,
      680,
      675,
      671,
      667,
      662,
      658,
      653,
      648,
      644,
      639,
      634,
      629,
      624,
      618,
      613,
      608,
      602,
      597,
      591,
      586,
      580,
      574,
      569,
      563,
      557,
      551,
      545,
      539,
      533,
      527,
      520,
      514,
      508,
      502,
      495,
      489,
      482,
      476,
      469,
      462,
      456,
      449,
      443,
      436,
      429,
      423,
    ],
    110: [
      820,
      816,
      814,
      811,
      808,
      805,
      802,
      799,
      795,
      792,
      789,
      785,
      782,
      779,
      775,
      772,
      768,
      765,
      761,
      758,
      754,
      751,
      747,
      744,
      740,
      737,
      733,
      729,
      725,
      722,
      718,
      715,
      711,
      707,
      703,
      700,
      696,
      692,
      688,
      684,
      679,
      675,
      671,
      667,
      662,
      658,
      654,
      649,
      645,
      640,
      635,
      630,
      625,
      621,
      616,
      611,
      605,
      600,
      595,
      590,
      584,
      579,
      574,
      568,
      562,
      557,
      551,
      545,
      539,
      534,
      528,
      522,
      516,
      510,
      504,
      497,
      491,
      485,
      479,
      473,
      466,
      460,
      453,
      447,
      441,
      434,
      428,
      422,
      416,
      409,
      403,
    ],
    115: [
      820,
      816,
      812,
      809,
      805,
      802,
      798,
      794,
      791,
      787,
      783,
      779,
      775,
      771,
      767,
      763,
      758,
      754,
      750,
      746,
      742,
      738,
      734,
      730,
      726,
      722,
      718,
      714,
      710,
      705,
      701,
      697,
      693,
      689,
      685,
      681,
      676,
      672,
      668,
      664,
      659,
      655,
      650,
      646,
      641,
      636,
      632,
      627,
      622,
      618,
      613,
      608,
      603,
      598,
      593,
      588,
      582,
      577,
      572,
      566,
      561,
      556,
      550,
      545,
      539,
      533,
      528,
      522,
      516,
      510,
      504,
      499,
      493,
      487,
      481,
      475,
      469,
      463,
      456,
      450,
      444,
      438,
      432,
      426,
      420,
      414,
      407,
      401,
      395,
      389,
      382,
    ],
    120: [
      820,
      815,
      811,
      807,
      803,
      799,
      795,
      790,
      786,
      781,
      777,
      772,
      768,
      763,
      758,
      754,
      749,
      744,
      740,
      735,
      731,
      726,
      721,
      716,
      712,
      707,
      703,
      698,
      694,
      689,
      685,
      680,
      676,
      671,
      667,
      662,
      657,
      653,
      648,
      643,
      639,
      634,
      630,
      625,
      620,
      615,
      610,
      605,
      601,
      596,
      591,
      585,
      580,
      575,
      570,
      565,
      560,
      554,
      549,
      544,
      538,
      533,
      527,
      522,
      516,
      510,
      505,
      499,
      493,
      488,
      482,
      476,
      470,
      464,
      458,
      453,
      447,
      441,
      435,
      429,
      423,
      417,
      411,
      405,
      399,
      393,
      387,
      381,
      375,
      369,
      363,
    ],
    125: [
      820,
      815,
      810,
      806,
      801,
      796,
      791,
      786,
      781,
      776,
      771,
      766,
      761,
      756,
      750,
      745,
      740,
      735,
      730,
      724,
      719,
      714,
      709,
      704,
      699,
      693,
      688,
      683,
      678,
      673,
      669,
      663,
      659,
      653,
      649,
      644,
      639,
      634,
      629,
      624,
      619,
      614,
      609,
      604,
      599,
      594,
      589,
      584,
      579,
      574,
      569,
      564,
      558,
      553,
      548,
      543,
      537,
      532,
      526,
      521,
      516,
      510,
      505,
      499,
      494,
      488,
      482,
      477,
      471,
      465,
      460,
      454,
      448,
      442,
      437,
      431,
      425,
      419,
      413,
      408,
      402,
      396,
      390,
      384,
      378,
      373,
      367,
      361,
      355,
      350,
      344,
    ],
    130: [
      820,
      814,
      809,
      804,
      799,
      793,
      788,
      783,
      777,
      771,
      766,
      760,
      754,
      749,
      743,
      737,
      731,
      725,
      720,
      714,
      708,
      703,
      697,
      691,
      686,
      680,
      675,
      669,
      664,
      658,
      653,
      647,
      642,
      637,
      631,
      626,
      621,
      616,
      610,
      605,
      600,
      595,
      589,
      584,
      579,
      574,
      569,
      563,
      558,
      553,
      548,
      542,
      537,
      532,
      526,
      521,
      516,
      510,
      505,
      499,
      494,
      488,
      483,
      477,
      472,
      466,
      460,
      455,
      449,
      444,
      438,
      433,
      427,
      421,
      416,
      410,
      404,
      399,
      393,
      387,
      381,
      376,
      370,
      365,
      359,
      353,
      348,
      342,
      337,
      331,
      325,
    ],
    135: [
      820,
      814,
      808,
      803,
      797,
      791,
      785,
      779,
      773,
      767,
      761,
      755,
      748,
      742,
      736,
      730,
      723,
      717,
      711,
      705,
      698,
      692,
      686,
      680,
      674,
      668,
      662,
      656,
      650,
      644,
      638,
      632,
      626,
      621,
      615,
      609,
      604,
      598,
      593,
      587,
      581,
      576,
      570,
      565,
      560,
      554,
      549,
      543,
      538,
      532,
      527,
      522,
      516,
      511,
      505,
      500,
      494,
      489,
      483,
      478,
      473,
      467,
      462,
      456,
      451,
      445,
      440,
      434,
      429,
      423,
      417,
      412,
      406,
      401,
      395,
      390,
      384,
      379,
      373,
      368,
      362,
      357,
      352,
      346,
      340,
      335,
      329,
      324,
      319,
      313,
      308,
    ],
    140: [
      820,
      813,
      807,
      801,
      795,
      789,
      782,
      776,
      769,
      763,
      756,
      749,
      743,
      736,
      729,
      723,
      716,
      709,
      703,
      696,
      689,
      683,
      676,
      670,
      663,
      657,
      650,
      644,
      637,
      631,
      625,
      618,
      612,
      606,
      600,
      594,
      587,
      581,
      576,
      570,
      564,
      558,
      552,
      547,
      541,
      535,
      530,
      524,
      518,
      513,
      507,
      502,
      496,
      491,
      485,
      480,
      474,
      469,
      463,
      458,
      452,
      447,
      441,
      436,
      431,
      425,
      420,
      414,
      409,
      403,
      398,
      392,
      387,
      382,
      376,
      371,
      365,
      360,
      355,
      349,
      344,
      339,
      333,
      328,
      323,
      318,
      312,
      307,
      302,
      297,
      292,
    ],
    145: [
      820,
      813,
      806,
      800,
      793,
      787,
      780,
      773,
      766,
      759,
      752,
      745,
      738,
      731,
      724,
      716,
      709,
      702,
      695,
      688,
      681,
      674,
      667,
      661,
      654,
      647,
      640,
      633,
      626,
      619,
      613,
      606,
      599,
      593,
      586,
      579,
      573,
      566,
      560,
      554,
      548,
      541,
      535,
      529,
      523,
      517,
      511,
      506,
      500,
      494,
      489,
      483,
      477,
      472,
      466,
      461,
      455,
      450,
      444,
      439,
      433,
      428,
      422,
      417,
      411,
      406,
      401,
      395,
      390,
      385,
      379,
      374,
      369,
      363,
      358,
      353,
      348,
      342,
      337,
      332,
      327,
      322,
      316,
      311,
      306,
      301,
      296,
      291,
      286,
      282,
      277,
    ],
    150: [
      820,
      813,
      806,
      799,
      792,
      785,
      778,
      770,
      763,
      756,
      748,
      741,
      733,
      726,
      718,
      711,
      703,
      696,
      689,
      681,
      674,
      667,
      660,
      653,
      645,
      638,
      631,
      624,
      617,
      609,
      602,
      595,
      588,
      581,
      574,
      567,
      560,
      553,
      547,
      540,
      533,
      527,
      520,
      514,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
      459,
      454,
      448,
      443,
      437,
      431,
      426,
      420,
      415,
      409,
      404,
      399,
      393,
      388,
      383,
      378,
      372,
      367,
      362,
      357,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
      301,
      296,
      291,
      286,
      282,
      277,
      272,
      267,
      262,
    ],
    155: [
      820,
      812,
      805,
      798,
      791,
      783,
      776,
      768,
      761,
      753,
      745,
      737,
      730,
      722,
      714,
      706,
      699,
      691,
      683,
      676,
      668,
      661,
      654,
      646,
      638,
      631,
      624,
      616,
      608,
      601,
      593,
      586,
      579,
      571,
      564,
      557,
      549,
      542,
      535,
      528,
      521,
      514,
      507,
      500,
      494,
      487,
      480,
      474,
      468,
      462,
      455,
      449,
      443,
      438,
      432,
      426,
      420,
      415,
      409,
      404,
      398,
      393,
      388,
      382,
      377,
      372,
      367,
      362,
      356,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
      301,
      296,
      292,
      287,
      282,
      277,
      273,
      268,
      264,
      259,
      255,
      250,
    ],
    160: [
      820,
      812,
      805,
      797,
      790,
      782,
      774,
      766,
      758,
      750,
      742,
      734,
      726,
      718,
      710,
      702,
      695,
      687,
      679,
      671,
      664,
      656,
      648,
      641,
      633,
      625,
      617,
      609,
      601,
      594,
      586,
      578,
      571,
      563,
      555,
      548,
      540,
      533,
      526,
      518,
      511,
      504,
      497,
      490,
      483,
      476,
      469,
      462,
      456,
      449,
      443,
      436,
      430,
      424,
      418,
      412,
      406,
      401,
      395,
      389,
      384,
      378,
      373,
      368,
      363,
      357,
      352,
      347,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      308,
      303,
      298,
      293,
      289,
      284,
      279,
      275,
      270,
      266,
      261,
      257,
      252,
      248,
      244,
      239,
    ],
    165: [
      820,
      812,
      804,
      797,
      789,
      781,
      773,
      765,
      757,
      749,
      740,
      732,
      724,
      716,
      708,
      699,
      691,
      684,
      676,
      668,
      660,
      652,
      644,
      636,
      628,
      620,
      612,
      604,
      596,
      588,
      580,
      573,
      565,
      557,
      549,
      541,
      534,
      526,
      518,
      511,
      503,
      496,
      488,
      481,
      474,
      467,
      460,
      453,
      446,
      439,
      433,
      426,
      420,
      413,
      407,
      401,
      395,
      389,
      383,
      378,
      372,
      367,
      361,
      356,
      351,
      346,
      340,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
      301,
      297,
      292,
      287,
      283,
      278,
      274,
      269,
      265,
      260,
      256,
      252,
      247,
      243,
      239,
      235,
      231,
    ],
    170: [
      820,
      812,
      804,
      796,
      788,
      780,
      772,
      764,
      756,
      747,
      739,
      731,
      722,
      714,
      706,
      697,
      690,
      681,
      673,
      665,
      657,
      650,
      641,
      633,
      625,
      616,
      608,
      601,
      593,
      584,
      576,
      568,
      561,
      553,
      544,
      536,
      528,
      521,
      513,
      505,
      498,
      490,
      483,
      475,
      467,
      460,
      453,
      446,
      439,
      432,
      425,
      419,
      412,
      406,
      399,
      393,
      387,
      381,
      375,
      369,
      364,
      358,
      353,
      347,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      307,
      303,
      298,
      293,
      289,
      284,
      279,
      275,
      271,
      266,
      262,
      258,
      253,
      249,
      245,
      240,
      236,
      232,
      228,
      224,
    ],
    175: [
      820,
      812,
      804,
      796,
      788,
      780,
      772,
      763,
      755,
      747,
      738,
      730,
      721,
      713,
      704,
      696,
      688,
      680,
      672,
      664,
      656,
      648,
      639,
      631,
      623,
      615,
      607,
      598,
      590,
      582,
      574,
      566,
      558,
      549,
      541,
      533,
      525,
      517,
      510,
      502,
      494,
      487,
      479,
      472,
      464,
      456,
      449,
      441,
      435,
      428,
      421,
      414,
      407,
      401,
      395,
      388,
      382,
      376,
      370,
      364,
      359,
      353,
      347,
      342,
      336,
      331,
      326,
      321,
      316,
      312,
      307,
      302,
      297,
      293,
      288,
      284,
      279,
      275,
      270,
      266,
      262,
      257,
      253,
      249,
      244,
      240,
      236,
      232,
      228,
      224,
      220,
    ],
  },
  '5W': {
    0: [
      803,
      809,
      816,
      823,
      830,
      837,
      843,
      849,
      855,
      861,
      867,
      873,
      878,
      883,
      888,
      893,
      898,
      902,
      907,
      911,
      915,
      918,
      922,
      925,
      928,
      931,
      934,
      936,
      939,
      941,
      943,
      944,
      946,
      947,
      948,
      949,
      949,
      950,
      950,
      950,
      949,
      949,
      948,
      947,
      946,
      945,
      943,
      941,
      939,
      937,
      935,
      932,
      929,
      926,
      923,
      919,
      915,
      911,
      907,
      903,
      898,
      893,
      888,
      883,
      878,
      872,
      866,
      860,
      854,
      847,
      841,
      834,
      827,
      819,
      812,
      804,
      796,
      788,
      780,
      772,
      763,
      755,
      745,
      736,
      727,
      718,
      708,
      698,
      689,
      678,
      668,
    ],
    5: [
      803,
      809,
      816,
      823,
      830,
      837,
      843,
      849,
      855,
      861,
      867,
      872,
      878,
      883,
      888,
      893,
      898,
      902,
      906,
      910,
      914,
      918,
      921,
      925,
      928,
      931,
      933,
      936,
      938,
      940,
      942,
      944,
      945,
      946,
      947,
      948,
      949,
      949,
      949,
      949,
      949,
      948,
      948,
      947,
      945,
      944,
      942,
      941,
      939,
      936,
      934,
      931,
      928,
      925,
      922,
      918,
      915,
      911,
      906,
      902,
      897,
      893,
      888,
      882,
      877,
      871,
      865,
      859,
      853,
      846,
      840,
      833,
      826,
      819,
      811,
      804,
      796,
      788,
      780,
      771,
      762,
      754,
      745,
      736,
      727,
      717,
      708,
      698,
      688,
      678,
      668,
    ],
    10: [
      803,
      809,
      816,
      823,
      830,
      836,
      843,
      849,
      855,
      860,
      866,
      872,
      877,
      882,
      887,
      892,
      896,
      901,
      905,
      909,
      913,
      916,
      920,
      923,
      926,
      929,
      932,
      934,
      936,
      938,
      940,
      942,
      943,
      944,
      945,
      946,
      947,
      947,
      947,
      947,
      947,
      946,
      945,
      944,
      943,
      942,
      940,
      938,
      936,
      934,
      932,
      929,
      926,
      923,
      920,
      916,
      912,
      908,
      904,
      900,
      895,
      890,
      885,
      880,
      874,
      869,
      863,
      857,
      851,
      844,
      838,
      831,
      824,
      816,
      809,
      801,
      794,
      786,
      777,
      769,
      761,
      752,
      743,
      734,
      725,
      715,
      706,
      696,
      686,
      677,
      666,
    ],
    15: [
      803,
      809,
      816,
      823,
      829,
      835,
      842,
      848,
      854,
      859,
      865,
      870,
      875,
      880,
      885,
      890,
      894,
      899,
      903,
      907,
      910,
      914,
      917,
      921,
      924,
      926,
      929,
      931,
      933,
      935,
      937,
      939,
      940,
      941,
      942,
      943,
      943,
      944,
      944,
      943,
      943,
      942,
      942,
      941,
      940,
      938,
      936,
      935,
      932,
      930,
      928,
      925,
      922,
      919,
      916,
      912,
      908,
      904,
      900,
      896,
      891,
      886,
      881,
      876,
      870,
      865,
      859,
      853,
      847,
      840,
      834,
      827,
      820,
      813,
      805,
      798,
      790,
      782,
      774,
      766,
      757,
      749,
      740,
      731,
      722,
      713,
      703,
      694,
      683,
      674,
      664,
    ],
    20: [
      803,
      809,
      816,
      822,
      828,
      835,
      841,
      846,
      852,
      858,
      863,
      868,
      873,
      878,
      883,
      887,
      892,
      896,
      900,
      904,
      907,
      911,
      914,
      917,
      920,
      923,
      925,
      927,
      929,
      931,
      933,
      934,
      936,
      937,
      937,
      938,
      938,
      939,
      939,
      939,
      938,
      937,
      937,
      936,
      934,
      933,
      931,
      929,
      927,
      925,
      922,
      920,
      917,
      913,
      910,
      906,
      903,
      899,
      895,
      890,
      886,
      881,
      876,
      870,
      865,
      859,
      854,
      848,
      841,
      835,
      828,
      822,
      815,
      808,
      800,
      793,
      785,
      777,
      769,
      761,
      753,
      744,
      735,
      726,
      717,
      708,
      699,
      689,
      680,
      670,
      660,
    ],
    25: [
      803,
      809,
      815,
      821,
      827,
      833,
      839,
      845,
      850,
      856,
      861,
      866,
      871,
      875,
      880,
      884,
      888,
      892,
      896,
      900,
      903,
      907,
      910,
      913,
      915,
      918,
      920,
      922,
      924,
      926,
      927,
      929,
      930,
      931,
      932,
      932,
      932,
      933,
      932,
      932,
      932,
      931,
      930,
      929,
      928,
      926,
      924,
      922,
      920,
      918,
      915,
      913,
      910,
      906,
      903,
      899,
      896,
      891,
      887,
      883,
      878,
      873,
      868,
      863,
      858,
      852,
      847,
      841,
      834,
      828,
      822,
      815,
      808,
      801,
      793,
      786,
      779,
      771,
      763,
      754,
      746,
      738,
      729,
      720,
      712,
      703,
      693,
      684,
      674,
      665,
      655,
    ],
    30: [
      803,
      808,
      815,
      820,
      826,
      832,
      838,
      843,
      848,
      853,
      858,
      863,
      868,
      872,
      876,
      880,
      884,
      888,
      892,
      895,
      898,
      901,
      904,
      907,
      910,
      912,
      914,
      916,
      918,
      919,
      921,
      922,
      923,
      924,
      924,
      925,
      925,
      925,
      925,
      925,
      924,
      923,
      922,
      921,
      920,
      918,
      916,
      914,
      912,
      909,
      907,
      904,
      901,
      898,
      894,
      891,
      887,
      883,
      879,
      874,
      870,
      865,
      860,
      854,
      849,
      844,
      838,
      832,
      826,
      819,
      813,
      806,
      799,
      792,
      785,
      778,
      770,
      762,
      755,
      747,
      739,
      730,
      721,
      713,
      704,
      695,
      686,
      677,
      668,
      658,
      649,
    ],
    35: [
      803,
      808,
      814,
      819,
      825,
      830,
      836,
      841,
      846,
      850,
      855,
      859,
      864,
      868,
      872,
      876,
      880,
      883,
      886,
      890,
      893,
      895,
      898,
      901,
      903,
      905,
      907,
      909,
      910,
      912,
      913,
      914,
      915,
      916,
      916,
      916,
      916,
      916,
      916,
      916,
      915,
      914,
      913,
      912,
      910,
      908,
      906,
      904,
      902,
      900,
      897,
      894,
      891,
      888,
      884,
      880,
      876,
      872,
      868,
      864,
      859,
      854,
      849,
      844,
      839,
      833,
      827,
      821,
      815,
      809,
      803,
      796,
      789,
      782,
      775,
      768,
      760,
      753,
      745,
      737,
      729,
      721,
      712,
      704,
      695,
      687,
      677,
      668,
      659,
      650,
      640,
    ],
    40: [
      803,
      808,
      813,
      818,
      823,
      828,
      833,
      838,
      843,
      847,
      851,
      856,
      860,
      863,
      867,
      871,
      874,
      877,
      880,
      883,
      886,
      889,
      891,
      893,
      896,
      897,
      899,
      901,
      902,
      903,
      904,
      905,
      906,
      906,
      907,
      907,
      907,
      906,
      906,
      905,
      904,
      903,
      902,
      901,
      899,
      897,
      895,
      893,
      891,
      888,
      885,
      882,
      879,
      876,
      872,
      869,
      865,
      860,
      856,
      852,
      847,
      842,
      837,
      832,
      827,
      821,
      816,
      810,
      803,
      797,
      791,
      784,
      778,
      771,
      764,
      756,
      749,
      741,
      734,
      726,
      718,
      710,
      701,
      693,
      685,
      676,
      667,
      659,
      649,
      640,
      631,
    ],
    45: [
      803,
      807,
      812,
      817,
      822,
      826,
      831,
      835,
      839,
      843,
      847,
      851,
      855,
      858,
      862,
      865,
      868,
      871,
      874,
      876,
      879,
      881,
      883,
      885,
      887,
      889,
      890,
      892,
      893,
      894,
      895,
      895,
      896,
      896,
      896,
      896,
      896,
      895,
      895,
      894,
      893,
      892,
      890,
      889,
      887,
      885,
      883,
      880,
      878,
      875,
      872,
      869,
      866,
      863,
      859,
      855,
      851,
      847,
      843,
      838,
      834,
      829,
      824,
      819,
      813,
      808,
      802,
      796,
      790,
      784,
      778,
      771,
      764,
      757,
      750,
      743,
      736,
      729,
      721,
      713,
      705,
      698,
      689,
      681,
      673,
      664,
      656,
      647,
      638,
      629,
      620,
    ],
    50: [
      803,
      807,
      811,
      816,
      820,
      824,
      828,
      832,
      836,
      839,
      843,
      846,
      850,
      853,
      856,
      859,
      861,
      864,
      866,
      869,
      871,
      873,
      875,
      876,
      878,
      879,
      881,
      882,
      883,
      883,
      884,
      884,
      884,
      884,
      884,
      884,
      884,
      883,
      882,
      881,
      880,
      879,
      877,
      875,
      873,
      871,
      869,
      867,
      864,
      861,
      858,
      855,
      852,
      848,
      845,
      841,
      837,
      833,
      828,
      824,
      819,
      814,
      809,
      804,
      798,
      793,
      787,
      782,
      775,
      769,
      763,
      756,
      750,
      743,
      736,
      729,
      722,
      714,
      707,
      700,
      692,
      684,
      676,
      668,
      660,
      651,
      643,
      634,
      626,
      617,
      608,
    ],
    55: [
      803,
      806,
      810,
      814,
      818,
      822,
      825,
      829,
      832,
      835,
      838,
      841,
      844,
      847,
      849,
      852,
      854,
      856,
      859,
      860,
      862,
      864,
      865,
      867,
      868,
      869,
      870,
      871,
      871,
      872,
      872,
      872,
      872,
      872,
      872,
      871,
      871,
      870,
      869,
      868,
      866,
      865,
      863,
      861,
      859,
      857,
      854,
      852,
      849,
      846,
      843,
      840,
      836,
      833,
      829,
      825,
      821,
      817,
      812,
      808,
      803,
      798,
      793,
      788,
      782,
      777,
      771,
      766,
      760,
      753,
      747,
      741,
      734,
      727,
      720,
      713,
      707,
      699,
      692,
      684,
      677,
      669,
      661,
      653,
      645,
      637,
      628,
      620,
      612,
      603,
      595,
    ],
    60: [
      803,
      806,
      809,
      813,
      816,
      819,
      822,
      825,
      828,
      831,
      833,
      836,
      838,
      840,
      843,
      845,
      847,
      848,
      850,
      852,
      853,
      854,
      855,
      857,
      857,
      858,
      859,
      859,
      859,
      860,
      860,
      860,
      859,
      859,
      858,
      858,
      857,
      856,
      854,
      853,
      851,
      850,
      848,
      846,
      843,
      841,
      839,
      836,
      833,
      830,
      827,
      823,
      820,
      816,
      812,
      808,
      804,
      800,
      795,
      791,
      786,
      781,
      776,
      771,
      765,
      760,
      754,
      748,
      743,
      737,
      730,
      723,
      717,
      711,
      704,
      697,
      690,
      683,
      675,
      668,
      661,
      653,
      645,
      637,
      630,
      622,
      613,
      605,
      597,
      589,
      580,
    ],
    65: [
      803,
      805,
      808,
      811,
      814,
      816,
      819,
      821,
      823,
      826,
      828,
      830,
      832,
      834,
      835,
      837,
      838,
      840,
      841,
      842,
      843,
      844,
      845,
      846,
      846,
      847,
      847,
      847,
      847,
      847,
      846,
      846,
      845,
      845,
      844,
      843,
      842,
      840,
      839,
      837,
      836,
      834,
      832,
      829,
      827,
      824,
      822,
      819,
      816,
      813,
      809,
      806,
      802,
      799,
      795,
      791,
      786,
      782,
      777,
      773,
      768,
      763,
      758,
      753,
      747,
      742,
      736,
      730,
      724,
      718,
      712,
      705,
      699,
      693,
      686,
      679,
      672,
      665,
      658,
      651,
      643,
      636,
      628,
      621,
      613,
      605,
      597,
      589,
      581,
      573,
      565,
    ],
    70: [
      803,
      805,
      807,
      809,
      811,
      813,
      815,
      817,
      819,
      821,
      822,
      824,
      825,
      826,
      828,
      829,
      830,
      831,
      832,
      832,
      833,
      833,
      834,
      834,
      834,
      834,
      834,
      834,
      834,
      833,
      833,
      832,
      831,
      830,
      829,
      828,
      826,
      825,
      823,
      821,
      819,
      817,
      815,
      812,
      810,
      807,
      804,
      801,
      798,
      795,
      791,
      788,
      784,
      780,
      776,
      772,
      767,
      763,
      758,
      754,
      749,
      744,
      739,
      733,
      728,
      722,
      717,
      711,
      705,
      699,
      693,
      687,
      680,
      674,
      667,
      661,
      653,
      646,
      640,
      633,
      625,
      618,
      611,
      603,
      596,
      588,
      580,
      572,
      564,
      556,
      548,
    ],
    75: [
      803,
      804,
      806,
      807,
      809,
      810,
      812,
      813,
      814,
      815,
      816,
      817,
      818,
      819,
      820,
      820,
      821,
      821,
      822,
      822,
      822,
      822,
      822,
      822,
      822,
      821,
      821,
      820,
      820,
      819,
      818,
      817,
      816,
      814,
      813,
      812,
      810,
      808,
      806,
      804,
      802,
      799,
      797,
      794,
      792,
      789,
      786,
      783,
      779,
      776,
      772,
      768,
      765,
      761,
      756,
      752,
      748,
      743,
      739,
      734,
      729,
      724,
      719,
      713,
      708,
      702,
      697,
      691,
      685,
      679,
      673,
      667,
      661,
      654,
      648,
      641,
      634,
      627,
      620,
      614,
      607,
      599,
      592,
      585,
      577,
      569,
      562,
      554,
      547,
      539,
      531,
    ],
    80: [
      803,
      803,
      804,
      806,
      806,
      807,
      808,
      809,
      809,
      810,
      810,
      811,
      811,
      811,
      812,
      812,
      812,
      812,
      811,
      811,
      811,
      811,
      810,
      810,
      809,
      808,
      807,
      806,
      805,
      804,
      803,
      802,
      800,
      798,
      797,
      795,
      793,
      791,
      789,
      786,
      784,
      781,
      779,
      776,
      773,
      770,
      767,
      763,
      760,
      756,
      752,
      749,
      745,
      741,
      736,
      732,
      727,
      723,
      718,
      713,
      708,
      703,
      698,
      693,
      687,
      682,
      676,
      670,
      665,
      659,
      652,
      646,
      640,
      634,
      627,
      621,
      614,
      607,
      600,
      594,
      587,
      580,
      572,
      565,
      558,
      551,
      543,
      536,
      528,
      521,
      513,
    ],
    85: [
      803,
      803,
      803,
      804,
      804,
      804,
      804,
      804,
      804,
      804,
      804,
      804,
      804,
      803,
      803,
      803,
      802,
      802,
      801,
      800,
      800,
      799,
      798,
      797,
      796,
      795,
      793,
      792,
      791,
      789,
      787,
      786,
      784,
      782,
      780,
      778,
      776,
      773,
      771,
      768,
      765,
      763,
      760,
      757,
      754,
      750,
      747,
      743,
      740,
      736,
      732,
      728,
      724,
      720,
      716,
      711,
      706,
      702,
      697,
      692,
      687,
      682,
      677,
      671,
      666,
      660,
      655,
      649,
      643,
      637,
      631,
      625,
      619,
      613,
      606,
      600,
      593,
      587,
      580,
      573,
      566,
      560,
      553,
      545,
      538,
      531,
      525,
      517,
      509,
      502,
      495,
    ],
    90: [
      803,
      802,
      802,
      802,
      801,
      801,
      801,
      800,
      800,
      799,
      798,
      797,
      797,
      796,
      795,
      794,
      793,
      792,
      790,
      789,
      788,
      787,
      785,
      784,
      782,
      781,
      779,
      777,
      775,
      774,
      772,
      769,
      767,
      765,
      763,
      760,
      758,
      755,
      753,
      749,
      747,
      744,
      740,
      737,
      734,
      730,
      727,
      723,
      719,
      715,
      711,
      707,
      703,
      699,
      694,
      690,
      685,
      680,
      675,
      670,
      665,
      660,
      655,
      650,
      644,
      639,
      633,
      627,
      622,
      615,
      609,
      604,
      597,
      591,
      585,
      578,
      572,
      565,
      559,
      552,
      545,
      539,
      532,
      525,
      518,
      511,
      504,
      497,
      489,
      482,
      476,
    ],
    95: [
      803,
      801,
      801,
      800,
      799,
      798,
      797,
      796,
      794,
      793,
      792,
      790,
      789,
      787,
      786,
      784,
      783,
      781,
      779,
      778,
      776,
      774,
      772,
      771,
      768,
      766,
      764,
      762,
      760,
      758,
      755,
      753,
      750,
      748,
      745,
      742,
      739,
      737,
      734,
      731,
      727,
      724,
      721,
      717,
      714,
      710,
      706,
      702,
      698,
      694,
      690,
      686,
      681,
      677,
      672,
      668,
      663,
      658,
      653,
      648,
      643,
      638,
      633,
      627,
      622,
      616,
      611,
      605,
      599,
      593,
      587,
      581,
      575,
      569,
      563,
      557,
      550,
      544,
      537,
      531,
      525,
      518,
      511,
      504,
      498,
      491,
      484,
      477,
      470,
      463,
      456,
    ],
    100: [
      803,
      801,
      799,
      798,
      796,
      795,
      793,
      791,
      789,
      787,
      785,
      784,
      781,
      779,
      777,
      775,
      773,
      771,
      769,
      767,
      764,
      762,
      759,
      757,
      755,
      752,
      750,
      747,
      744,
      742,
      739,
      736,
      733,
      730,
      727,
      724,
      721,
      718,
      714,
      711,
      708,
      704,
      701,
      697,
      693,
      689,
      685,
      681,
      677,
      673,
      669,
      664,
      660,
      655,
      650,
      646,
      641,
      636,
      631,
      626,
      621,
      616,
      610,
      605,
      599,
      594,
      588,
      582,
      577,
      571,
      565,
      559,
      553,
      547,
      541,
      535,
      528,
      522,
      516,
      509,
      503,
      497,
      490,
      483,
      477,
      470,
      464,
      457,
      450,
      443,
      437,
    ],
    105: [
      803,
      800,
      798,
      796,
      794,
      792,
      789,
      787,
      784,
      782,
      779,
      777,
      774,
      771,
      769,
      766,
      763,
      760,
      758,
      755,
      752,
      749,
      747,
      744,
      741,
      738,
      735,
      732,
      729,
      726,
      723,
      719,
      716,
      713,
      709,
      706,
      702,
      699,
      696,
      692,
      688,
      684,
      680,
      677,
      673,
      668,
      664,
      660,
      656,
      651,
      647,
      642,
      638,
      633,
      628,
      623,
      619,
      614,
      609,
      603,
      598,
      593,
      588,
      582,
      577,
      571,
      566,
      560,
      554,
      548,
      542,
      537,
      531,
      525,
      519,
      512,
      506,
      500,
      494,
      488,
      481,
      475,
      469,
      462,
      456,
      449,
      443,
      436,
      430,
      423,
      417,
    ],
    110: [
      803,
      800,
      797,
      794,
      791,
      789,
      786,
      783,
      780,
      776,
      773,
      770,
      767,
      764,
      760,
      757,
      754,
      750,
      747,
      744,
      740,
      737,
      734,
      730,
      727,
      723,
      720,
      717,
      713,
      709,
      706,
      702,
      699,
      695,
      692,
      688,
      684,
      680,
      676,
      672,
      668,
      664,
      660,
      656,
      652,
      648,
      643,
      639,
      634,
      630,
      625,
      621,
      616,
      611,
      606,
      601,
      596,
      591,
      586,
      581,
      576,
      570,
      565,
      559,
      554,
      548,
      543,
      537,
      531,
      526,
      520,
      514,
      508,
      503,
      496,
      490,
      484,
      478,
      472,
      466,
      460,
      454,
      447,
      441,
      435,
      429,
      422,
      416,
      410,
      403,
      397,
    ],
    115: [
      803,
      799,
      796,
      792,
      789,
      786,
      782,
      778,
      775,
      771,
      767,
      764,
      760,
      756,
      752,
      748,
      744,
      740,
      736,
      733,
      729,
      725,
      721,
      717,
      713,
      709,
      705,
      701,
      698,
      693,
      690,
      686,
      682,
      678,
      674,
      670,
      666,
      661,
      657,
      653,
      649,
      645,
      640,
      636,
      631,
      627,
      622,
      618,
      613,
      608,
      604,
      599,
      594,
      589,
      584,
      579,
      574,
      569,
      564,
      558,
      553,
      548,
      542,
      537,
      531,
      526,
      521,
      515,
      509,
      503,
      498,
      492,
      486,
      480,
      474,
      469,
      462,
      457,
      450,
      445,
      439,
      433,
      426,
      420,
      415,
      408,
      402,
      396,
      390,
      384,
      378,
    ],
    120: [
      803,
      798,
      795,
      791,
      787,
      783,
      779,
      774,
      770,
      766,
      762,
      757,
      753,
      748,
      744,
      739,
      735,
      731,
      726,
      722,
      717,
      713,
      709,
      704,
      700,
      695,
      691,
      687,
      682,
      678,
      674,
      669,
      665,
      660,
      656,
      652,
      647,
      643,
      638,
      634,
      629,
      625,
      620,
      616,
      611,
      606,
      602,
      597,
      592,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      552,
      547,
      542,
      536,
      531,
      526,
      520,
      515,
      509,
      504,
      498,
      493,
      487,
      482,
      476,
      470,
      464,
      459,
      453,
      447,
      441,
      435,
      430,
      424,
      418,
      412,
      406,
      400,
      394,
      388,
      382,
      376,
      371,
      365,
      359,
    ],
    125: [
      803,
      798,
      794,
      789,
      785,
      780,
      775,
      771,
      766,
      761,
      756,
      751,
      746,
      741,
      736,
      731,
      726,
      721,
      716,
      711,
      706,
      701,
      697,
      692,
      687,
      682,
      677,
      672,
      668,
      662,
      658,
      653,
      649,
      644,
      639,
      634,
      629,
      625,
      620,
      615,
      610,
      606,
      601,
      596,
      591,
      586,
      581,
      576,
      571,
      566,
      561,
      556,
      551,
      546,
      541,
      536,
      531,
      525,
      520,
      515,
      509,
      504,
      498,
      493,
      488,
      482,
      477,
      471,
      466,
      460,
      454,
      449,
      443,
      437,
      432,
      426,
      420,
      415,
      409,
      403,
      397,
      392,
      386,
      380,
      375,
      369,
      363,
      357,
      352,
      346,
      340,
    ],
    130: [
      803,
      797,
      793,
      788,
      783,
      777,
      772,
      767,
      762,
      756,
      751,
      745,
      740,
      734,
      729,
      723,
      718,
      712,
      707,
      701,
      696,
      691,
      685,
      680,
      674,
      669,
      664,
      659,
      653,
      648,
      643,
      638,
      632,
      627,
      622,
      617,
      612,
      607,
      602,
      597,
      592,
      587,
      582,
      577,
      572,
      567,
      562,
      556,
      551,
      546,
      541,
      536,
      531,
      525,
      520,
      515,
      510,
      504,
      499,
      494,
      488,
      483,
      478,
      472,
      467,
      461,
      456,
      450,
      445,
      439,
      434,
      428,
      422,
      417,
      411,
      406,
      400,
      395,
      390,
      384,
      378,
      372,
      367,
      361,
      356,
      350,
      345,
      339,
      334,
      328,
      323,
    ],
    135: [
      803,
      797,
      792,
      786,
      781,
      775,
      769,
      764,
      758,
      752,
      746,
      740,
      734,
      728,
      722,
      716,
      710,
      704,
      698,
      692,
      686,
      681,
      675,
      669,
      663,
      657,
      651,
      646,
      640,
      634,
      629,
      623,
      618,
      612,
      606,
      601,
      596,
      590,
      585,
      579,
      574,
      569,
      564,
      558,
      553,
      548,
      543,
      537,
      532,
      527,
      521,
      516,
      511,
      505,
      500,
      495,
      489,
      484,
      479,
      474,
      468,
      463,
      457,
      452,
      447,
      441,
      436,
      430,
      425,
      419,
      414,
      409,
      403,
      397,
      392,
      386,
      381,
      376,
      370,
      365,
      359,
      354,
      349,
      343,
      338,
      332,
      327,
      322,
      317,
      311,
      306,
    ],
    140: [
      803,
      797,
      791,
      785,
      779,
      773,
      767,
      761,
      754,
      748,
      742,
      735,
      729,
      722,
      716,
      709,
      703,
      697,
      690,
      684,
      678,
      671,
      665,
      659,
      653,
      646,
      640,
      634,
      628,
      622,
      616,
      610,
      604,
      598,
      592,
      586,
      580,
      574,
      569,
      563,
      557,
      552,
      546,
      541,
      535,
      530,
      524,
      519,
      513,
      508,
      502,
      497,
      492,
      486,
      481,
      476,
      470,
      465,
      459,
      454,
      449,
      443,
      438,
      432,
      427,
      422,
      416,
      411,
      406,
      400,
      395,
      389,
      384,
      379,
      374,
      368,
      363,
      358,
      352,
      347,
      342,
      337,
      331,
      326,
      321,
      316,
      311,
      306,
      300,
      295,
      290,
    ],
    145: [
      803,
      796,
      790,
      784,
      777,
      771,
      764,
      758,
      751,
      744,
      738,
      731,
      724,
      717,
      710,
      704,
      697,
      690,
      683,
      676,
      670,
      663,
      657,
      650,
      643,
      637,
      630,
      624,
      617,
      611,
      604,
      598,
      592,
      585,
      579,
      573,
      566,
      560,
      554,
      548,
      542,
      536,
      530,
      524,
      518,
      512,
      507,
      501,
      496,
      490,
      485,
      479,
      473,
      468,
      463,
      457,
      452,
      446,
      441,
      435,
      430,
      425,
      419,
      414,
      409,
      404,
      398,
      393,
      387,
      382,
      377,
      372,
      367,
      361,
      356,
      351,
      346,
      341,
      335,
      330,
      325,
      320,
      315,
      310,
      305,
      300,
      295,
      290,
      285,
      281,
      276,
    ],
    150: [
      803,
      796,
      789,
      783,
      776,
      769,
      762,
      755,
      748,
      741,
      734,
      727,
      720,
      712,
      705,
      698,
      691,
      684,
      677,
      670,
      663,
      656,
      649,
      642,
      636,
      629,
      622,
      615,
      608,
      601,
      594,
      588,
      581,
      574,
      567,
      561,
      554,
      547,
      541,
      535,
      528,
      522,
      516,
      509,
      503,
      497,
      491,
      485,
      479,
      473,
      468,
      462,
      456,
      451,
      445,
      440,
      434,
      429,
      424,
      418,
      413,
      407,
      402,
      397,
      391,
      386,
      381,
      376,
      371,
      366,
      360,
      355,
      350,
      345,
      340,
      335,
      330,
      325,
      320,
      315,
      310,
      305,
      300,
      295,
      290,
      285,
      281,
      276,
      271,
      267,
      262,
    ],
    155: [
      803,
      795,
      789,
      782,
      775,
      768,
      760,
      753,
      746,
      738,
      731,
      723,
      716,
      708,
      701,
      694,
      686,
      679,
      672,
      665,
      657,
      650,
      643,
      636,
      629,
      622,
      615,
      607,
      600,
      593,
      586,
      579,
      572,
      565,
      558,
      551,
      544,
      537,
      530,
      523,
      517,
      510,
      503,
      497,
      490,
      484,
      477,
      471,
      465,
      459,
      453,
      447,
      441,
      436,
      430,
      424,
      419,
      413,
      408,
      402,
      397,
      392,
      386,
      381,
      376,
      371,
      366,
      360,
      355,
      350,
      345,
      340,
      335,
      330,
      325,
      320,
      315,
      310,
      306,
      301,
      296,
      291,
      287,
      282,
      277,
      273,
      268,
      264,
      259,
      254,
      250,
    ],
    160: [
      803,
      795,
      788,
      781,
      774,
      766,
      759,
      751,
      744,
      736,
      728,
      721,
      713,
      705,
      698,
      690,
      682,
      675,
      668,
      660,
      653,
      645,
      638,
      631,
      623,
      616,
      608,
      601,
      593,
      586,
      579,
      571,
      564,
      557,
      550,
      542,
      535,
      528,
      521,
      514,
      507,
      500,
      493,
      486,
      480,
      473,
      466,
      460,
      454,
      447,
      441,
      435,
      429,
      423,
      417,
      411,
      405,
      400,
      394,
      389,
      383,
      378,
      373,
      367,
      362,
      357,
      352,
      347,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      308,
      303,
      298,
      294,
      289,
      284,
      279,
      275,
      270,
      266,
      261,
      257,
      252,
      248,
      244,
      239,
    ],
    165: [
      803,
      795,
      788,
      780,
      773,
      765,
      758,
      750,
      742,
      734,
      726,
      718,
      711,
      703,
      695,
      687,
      679,
      672,
      664,
      657,
      649,
      642,
      634,
      627,
      619,
      611,
      604,
      596,
      588,
      581,
      573,
      566,
      558,
      551,
      543,
      536,
      529,
      521,
      514,
      507,
      499,
      492,
      485,
      478,
      471,
      465,
      458,
      451,
      444,
      438,
      431,
      425,
      419,
      413,
      407,
      401,
      395,
      389,
      383,
      378,
      372,
      367,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      307,
      302,
      297,
      293,
      288,
      283,
      279,
      274,
      270,
      265,
      261,
      256,
      252,
      248,
      244,
      239,
      235,
      231,
    ],
    170: [
      803,
      795,
      788,
      780,
      772,
      765,
      757,
      749,
      741,
      733,
      725,
      717,
      709,
      701,
      693,
      685,
      677,
      670,
      662,
      654,
      647,
      639,
      631,
      623,
      616,
      608,
      600,
      593,
      585,
      577,
      569,
      562,
      555,
      547,
      539,
      531,
      524,
      516,
      509,
      502,
      494,
      487,
      480,
      473,
      465,
      458,
      451,
      445,
      438,
      431,
      425,
      418,
      412,
      405,
      399,
      393,
      387,
      381,
      376,
      370,
      364,
      358,
      353,
      348,
      342,
      337,
      332,
      327,
      322,
      318,
      313,
      308,
      303,
      298,
      294,
      289,
      285,
      280,
      276,
      271,
      267,
      263,
      258,
      254,
      249,
      246,
      241,
      237,
      233,
      229,
      225,
    ],
    175: [
      803,
      795,
      787,
      780,
      772,
      764,
      756,
      748,
      740,
      732,
      724,
      716,
      708,
      700,
      692,
      684,
      676,
      668,
      661,
      653,
      645,
      638,
      630,
      622,
      614,
      606,
      598,
      591,
      583,
      575,
      567,
      560,
      552,
      544,
      536,
      528,
      521,
      513,
      506,
      498,
      491,
      484,
      477,
      470,
      462,
      455,
      448,
      440,
      434,
      427,
      420,
      414,
      407,
      401,
      395,
      389,
      383,
      377,
      371,
      365,
      359,
      354,
      348,
      343,
      337,
      332,
      327,
      322,
      317,
      313,
      308,
      303,
      298,
      294,
      289,
      285,
      280,
      276,
      271,
      267,
      263,
      258,
      254,
      250,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
    ],
  },
  '6': {
    0: [
      789,
      796,
      803,
      810,
      816,
      823,
      829,
      835,
      841,
      847,
      853,
      859,
      864,
      869,
      874,
      879,
      884,
      888,
      892,
      897,
      901,
      904,
      908,
      911,
      914,
      917,
      920,
      922,
      925,
      927,
      929,
      931,
      932,
      933,
      935,
      935,
      936,
      937,
      937,
      937,
      937,
      936,
      936,
      935,
      934,
      933,
      931,
      930,
      928,
      926,
      923,
      921,
      918,
      915,
      912,
      909,
      905,
      901,
      897,
      893,
      889,
      884,
      879,
      874,
      869,
      864,
      858,
      852,
      846,
      840,
      833,
      827,
      820,
      813,
      806,
      798,
      791,
      783,
      775,
      767,
      759,
      750,
      742,
      733,
      724,
      715,
      706,
      696,
      686,
      677,
      667,
    ],
    5: [
      789,
      796,
      803,
      809,
      816,
      823,
      829,
      835,
      841,
      847,
      853,
      858,
      864,
      869,
      874,
      879,
      883,
      888,
      892,
      896,
      900,
      904,
      907,
      911,
      914,
      917,
      919,
      922,
      924,
      926,
      928,
      930,
      931,
      933,
      934,
      935,
      935,
      936,
      936,
      936,
      936,
      936,
      935,
      934,
      933,
      932,
      931,
      929,
      927,
      925,
      923,
      920,
      917,
      914,
      911,
      908,
      904,
      901,
      897,
      892,
      888,
      883,
      878,
      873,
      868,
      863,
      857,
      851,
      845,
      839,
      833,
      826,
      819,
      812,
      805,
      798,
      790,
      782,
      774,
      766,
      758,
      749,
      741,
      732,
      723,
      714,
      705,
      696,
      686,
      676,
      666,
    ],
    10: [
      789,
      796,
      802,
      809,
      816,
      822,
      828,
      835,
      841,
      846,
      852,
      857,
      863,
      868,
      873,
      878,
      882,
      887,
      891,
      895,
      899,
      902,
      906,
      909,
      912,
      915,
      918,
      920,
      922,
      925,
      926,
      928,
      930,
      931,
      932,
      933,
      933,
      934,
      934,
      934,
      934,
      933,
      933,
      932,
      931,
      930,
      928,
      927,
      925,
      923,
      920,
      918,
      915,
      912,
      909,
      906,
      902,
      898,
      894,
      890,
      886,
      881,
      876,
      871,
      866,
      860,
      855,
      849,
      843,
      837,
      830,
      824,
      817,
      810,
      803,
      795,
      788,
      780,
      772,
      764,
      756,
      748,
      739,
      730,
      721,
      712,
      703,
      694,
      684,
      674,
      665,
    ],
    15: [
      789,
      795,
      802,
      809,
      815,
      822,
      828,
      834,
      840,
      845,
      851,
      856,
      861,
      866,
      871,
      876,
      880,
      885,
      889,
      893,
      896,
      900,
      903,
      907,
      910,
      912,
      915,
      917,
      920,
      922,
      923,
      925,
      926,
      928,
      929,
      929,
      930,
      930,
      931,
      931,
      930,
      930,
      929,
      928,
      927,
      926,
      924,
      923,
      921,
      919,
      916,
      914,
      911,
      908,
      905,
      902,
      898,
      894,
      890,
      886,
      882,
      877,
      872,
      867,
      862,
      856,
      851,
      845,
      839,
      833,
      826,
      820,
      813,
      806,
      799,
      792,
      784,
      777,
      769,
      761,
      752,
      744,
      736,
      727,
      718,
      709,
      700,
      690,
      681,
      671,
      662,
    ],
    20: [
      789,
      795,
      802,
      808,
      815,
      821,
      827,
      832,
      838,
      844,
      849,
      854,
      859,
      864,
      869,
      873,
      878,
      882,
      886,
      890,
      893,
      897,
      900,
      903,
      906,
      909,
      911,
      913,
      916,
      917,
      919,
      921,
      922,
      923,
      924,
      925,
      925,
      926,
      926,
      926,
      925,
      925,
      924,
      923,
      922,
      921,
      919,
      917,
      915,
      913,
      911,
      908,
      905,
      903,
      899,
      896,
      892,
      888,
      884,
      880,
      876,
      871,
      866,
      861,
      856,
      851,
      845,
      839,
      833,
      827,
      821,
      814,
      808,
      801,
      794,
      786,
      779,
      771,
      763,
      756,
      747,
      739,
      731,
      722,
      713,
      704,
      695,
      686,
      677,
      667,
      658,
    ],
    25: [
      789,
      795,
      801,
      808,
      814,
      819,
      825,
      831,
      836,
      842,
      847,
      852,
      857,
      861,
      866,
      870,
      874,
      878,
      882,
      886,
      889,
      893,
      896,
      899,
      901,
      904,
      906,
      908,
      910,
      912,
      914,
      915,
      916,
      917,
      918,
      919,
      919,
      919,
      919,
      919,
      919,
      918,
      918,
      917,
      915,
      914,
      912,
      911,
      909,
      906,
      904,
      901,
      898,
      895,
      892,
      889,
      885,
      881,
      877,
      873,
      869,
      864,
      859,
      854,
      849,
      843,
      838,
      832,
      826,
      820,
      814,
      807,
      801,
      794,
      787,
      779,
      772,
      765,
      757,
      749,
      741,
      733,
      724,
      716,
      707,
      698,
      690,
      680,
      671,
      662,
      652,
    ],
    30: [
      789,
      795,
      801,
      807,
      812,
      818,
      824,
      829,
      834,
      839,
      844,
      849,
      853,
      858,
      862,
      866,
      870,
      874,
      878,
      881,
      884,
      887,
      890,
      893,
      896,
      898,
      900,
      902,
      904,
      906,
      907,
      908,
      910,
      910,
      911,
      912,
      912,
      912,
      912,
      912,
      911,
      911,
      910,
      909,
      907,
      906,
      904,
      902,
      900,
      898,
      895,
      893,
      890,
      887,
      883,
      880,
      876,
      872,
      868,
      864,
      860,
      855,
      850,
      845,
      840,
      835,
      829,
      823,
      817,
      811,
      805,
      799,
      792,
      785,
      778,
      771,
      764,
      756,
      748,
      741,
      733,
      725,
      717,
      708,
      699,
      691,
      682,
      673,
      664,
      655,
      645,
    ],
    35: [
      789,
      794,
      800,
      806,
      811,
      816,
      822,
      827,
      832,
      836,
      841,
      845,
      850,
      854,
      858,
      862,
      866,
      869,
      872,
      876,
      879,
      881,
      884,
      887,
      889,
      891,
      893,
      895,
      897,
      898,
      900,
      901,
      902,
      902,
      903,
      903,
      903,
      903,
      903,
      903,
      902,
      901,
      900,
      899,
      898,
      896,
      894,
      892,
      890,
      888,
      885,
      883,
      880,
      876,
      873,
      870,
      866,
      862,
      858,
      854,
      849,
      845,
      840,
      835,
      829,
      824,
      819,
      813,
      807,
      801,
      795,
      788,
      782,
      775,
      768,
      761,
      754,
      746,
      738,
      731,
      723,
      715,
      707,
      699,
      690,
      682,
      673,
      664,
      655,
      646,
      637,
    ],
    40: [
      789,
      794,
      799,
      805,
      810,
      815,
      819,
      824,
      829,
      833,
      837,
      842,
      846,
      849,
      853,
      857,
      860,
      863,
      866,
      869,
      872,
      875,
      877,
      880,
      882,
      884,
      885,
      887,
      888,
      890,
      891,
      892,
      892,
      893,
      893,
      894,
      894,
      893,
      893,
      892,
      892,
      891,
      890,
      888,
      887,
      885,
      883,
      881,
      879,
      876,
      874,
      871,
      868,
      865,
      861,
      858,
      854,
      850,
      846,
      842,
      837,
      833,
      828,
      823,
      817,
      812,
      807,
      801,
      795,
      789,
      783,
      776,
      770,
      763,
      756,
      749,
      742,
      734,
      727,
      720,
      712,
      704,
      696,
      688,
      680,
      671,
      662,
      654,
      645,
      636,
      627,
    ],
    45: [
      789,
      794,
      799,
      803,
      808,
      813,
      817,
      821,
      825,
      830,
      833,
      837,
      841,
      844,
      848,
      851,
      854,
      857,
      860,
      862,
      865,
      867,
      869,
      872,
      873,
      875,
      877,
      878,
      879,
      880,
      881,
      882,
      882,
      883,
      883,
      883,
      883,
      882,
      882,
      881,
      880,
      879,
      878,
      876,
      875,
      873,
      871,
      869,
      866,
      864,
      861,
      858,
      855,
      852,
      848,
      844,
      841,
      837,
      832,
      828,
      824,
      819,
      814,
      809,
      804,
      798,
      793,
      787,
      781,
      775,
      769,
      763,
      756,
      750,
      743,
      736,
      729,
      722,
      714,
      707,
      699,
      691,
      683,
      675,
      667,
      659,
      651,
      642,
      633,
      625,
      616,
    ],
    50: [
      789,
      793,
      798,
      802,
      806,
      810,
      814,
      818,
      822,
      826,
      829,
      832,
      836,
      839,
      842,
      845,
      848,
      850,
      853,
      855,
      857,
      859,
      861,
      863,
      864,
      866,
      867,
      868,
      869,
      870,
      870,
      871,
      871,
      871,
      871,
      871,
      871,
      870,
      869,
      868,
      867,
      866,
      865,
      863,
      861,
      859,
      857,
      855,
      852,
      850,
      847,
      844,
      841,
      837,
      834,
      830,
      826,
      822,
      818,
      813,
      809,
      804,
      799,
      794,
      789,
      783,
      778,
      772,
      767,
      760,
      754,
      748,
      742,
      735,
      728,
      721,
      714,
      707,
      700,
      693,
      685,
      677,
      669,
      662,
      654,
      645,
      637,
      629,
      620,
      612,
      603,
    ],
    55: [
      789,
      793,
      797,
      800,
      804,
      808,
      811,
      815,
      818,
      821,
      824,
      827,
      830,
      833,
      836,
      838,
      840,
      843,
      845,
      847,
      848,
      850,
      852,
      853,
      855,
      855,
      856,
      857,
      858,
      859,
      859,
      859,
      859,
      859,
      859,
      858,
      858,
      857,
      856,
      855,
      854,
      852,
      851,
      849,
      847,
      845,
      842,
      840,
      837,
      834,
      832,
      828,
      825,
      822,
      818,
      814,
      810,
      806,
      802,
      797,
      793,
      788,
      783,
      778,
      773,
      767,
      762,
      756,
      751,
      744,
      738,
      732,
      726,
      719,
      712,
      706,
      699,
      692,
      685,
      677,
      670,
      662,
      654,
      647,
      639,
      631,
      623,
      615,
      606,
      598,
      590,
    ],
    60: [
      789,
      792,
      796,
      799,
      802,
      805,
      808,
      811,
      814,
      817,
      819,
      822,
      824,
      827,
      829,
      831,
      833,
      835,
      836,
      838,
      839,
      841,
      842,
      843,
      844,
      845,
      845,
      846,
      846,
      847,
      847,
      846,
      846,
      846,
      845,
      845,
      844,
      843,
      842,
      840,
      839,
      837,
      835,
      833,
      831,
      829,
      827,
      824,
      821,
      818,
      815,
      812,
      809,
      805,
      801,
      797,
      793,
      789,
      785,
      780,
      776,
      771,
      766,
      761,
      756,
      750,
      745,
      739,
      733,
      728,
      721,
      715,
      709,
      702,
      696,
      689,
      682,
      675,
      668,
      661,
      653,
      646,
      639,
      631,
      623,
      615,
      607,
      599,
      591,
      583,
      575,
    ],
    65: [
      789,
      792,
      794,
      797,
      800,
      803,
      805,
      807,
      810,
      812,
      814,
      816,
      818,
      820,
      822,
      823,
      825,
      826,
      827,
      829,
      830,
      831,
      831,
      832,
      833,
      833,
      833,
      834,
      834,
      834,
      833,
      833,
      833,
      832,
      831,
      830,
      829,
      828,
      826,
      825,
      823,
      821,
      819,
      817,
      815,
      813,
      810,
      807,
      804,
      801,
      798,
      795,
      791,
      787,
      784,
      780,
      776,
      771,
      767,
      762,
      758,
      753,
      748,
      743,
      738,
      732,
      727,
      721,
      715,
      709,
      703,
      697,
      691,
      684,
      678,
      671,
      664,
      657,
      651,
      643,
      636,
      629,
      622,
      614,
      606,
      599,
      591,
      583,
      575,
      567,
      559,
    ],
    70: [
      789,
      791,
      793,
      796,
      798,
      800,
      802,
      804,
      805,
      807,
      809,
      810,
      812,
      813,
      814,
      815,
      816,
      817,
      818,
      819,
      819,
      820,
      820,
      821,
      821,
      821,
      821,
      821,
      821,
      820,
      820,
      819,
      818,
      817,
      816,
      815,
      814,
      812,
      811,
      809,
      807,
      805,
      803,
      800,
      798,
      795,
      792,
      790,
      787,
      783,
      780,
      776,
      773,
      769,
      765,
      761,
      757,
      753,
      748,
      744,
      739,
      734,
      729,
      724,
      718,
      713,
      708,
      702,
      696,
      690,
      684,
      678,
      672,
      665,
      659,
      653,
      645,
      639,
      632,
      625,
      618,
      611,
      604,
      596,
      589,
      581,
      574,
      566,
      559,
      550,
      543,
    ],
    75: [
      789,
      790,
      792,
      794,
      795,
      797,
      798,
      799,
      801,
      802,
      803,
      804,
      805,
      806,
      806,
      807,
      807,
      808,
      808,
      809,
      809,
      809,
      809,
      809,
      809,
      808,
      808,
      808,
      807,
      806,
      805,
      804,
      803,
      802,
      801,
      799,
      798,
      796,
      794,
      792,
      790,
      788,
      785,
      783,
      780,
      777,
      774,
      771,
      768,
      765,
      761,
      758,
      754,
      750,
      746,
      742,
      738,
      733,
      728,
      724,
      719,
      714,
      709,
      704,
      699,
      693,
      688,
      682,
      676,
      670,
      664,
      658,
      652,
      646,
      639,
      633,
      627,
      619,
      613,
      606,
      600,
      592,
      585,
      578,
      571,
      563,
      556,
      548,
      541,
      533,
      525,
    ],
    80: [
      789,
      790,
      791,
      792,
      793,
      794,
      795,
      795,
      796,
      796,
      797,
      797,
      798,
      798,
      798,
      798,
      798,
      798,
      798,
      798,
      798,
      797,
      797,
      797,
      796,
      795,
      795,
      794,
      793,
      792,
      790,
      789,
      788,
      786,
      785,
      783,
      781,
      779,
      777,
      775,
      772,
      770,
      767,
      764,
      761,
      759,
      755,
      752,
      749,
      745,
      742,
      738,
      734,
      730,
      726,
      722,
      717,
      713,
      708,
      703,
      699,
      694,
      689,
      683,
      678,
      673,
      667,
      661,
      656,
      650,
      644,
      638,
      632,
      626,
      619,
      613,
      606,
      600,
      593,
      586,
      580,
      573,
      565,
      558,
      551,
      545,
      537,
      530,
      522,
      515,
      507,
    ],
    85: [
      789,
      789,
      790,
      790,
      790,
      791,
      791,
      791,
      791,
      791,
      791,
      791,
      791,
      790,
      790,
      789,
      789,
      788,
      788,
      787,
      787,
      786,
      785,
      784,
      783,
      782,
      781,
      780,
      778,
      777,
      775,
      774,
      772,
      770,
      768,
      766,
      764,
      762,
      759,
      757,
      754,
      751,
      749,
      746,
      742,
      739,
      736,
      733,
      729,
      725,
      722,
      718,
      714,
      710,
      705,
      701,
      697,
      692,
      687,
      683,
      678,
      673,
      668,
      662,
      657,
      652,
      646,
      640,
      635,
      629,
      623,
      617,
      611,
      605,
      599,
      592,
      586,
      579,
      573,
      566,
      559,
      553,
      546,
      538,
      532,
      525,
      518,
      511,
      503,
      496,
      489,
    ],
    90: [
      789,
      789,
      788,
      788,
      788,
      787,
      787,
      787,
      786,
      785,
      785,
      784,
      783,
      782,
      782,
      781,
      780,
      779,
      777,
      776,
      775,
      774,
      773,
      771,
      770,
      768,
      767,
      765,
      763,
      762,
      760,
      757,
      756,
      753,
      751,
      749,
      746,
      744,
      741,
      738,
      736,
      733,
      730,
      726,
      723,
      720,
      716,
      713,
      709,
      705,
      701,
      697,
      693,
      689,
      684,
      680,
      675,
      671,
      666,
      661,
      656,
      651,
      646,
      641,
      635,
      630,
      624,
      619,
      613,
      607,
      602,
      596,
      590,
      583,
      577,
      571,
      565,
      558,
      552,
      545,
      539,
      532,
      526,
      519,
      512,
      505,
      498,
      491,
      484,
      477,
      470,
    ],
    95: [
      789,
      788,
      787,
      786,
      785,
      784,
      783,
      782,
      781,
      780,
      778,
      777,
      776,
      774,
      773,
      772,
      770,
      768,
      767,
      765,
      763,
      762,
      760,
      758,
      756,
      754,
      752,
      750,
      748,
      746,
      744,
      741,
      739,
      736,
      734,
      731,
      728,
      726,
      723,
      720,
      717,
      713,
      710,
      707,
      703,
      700,
      696,
      692,
      688,
      684,
      680,
      676,
      672,
      668,
      663,
      659,
      654,
      649,
      644,
      639,
      634,
      629,
      624,
      619,
      614,
      608,
      603,
      597,
      591,
      586,
      580,
      574,
      568,
      562,
      556,
      550,
      543,
      537,
      531,
      524,
      518,
      511,
      505,
      498,
      492,
      485,
      478,
      471,
      465,
      458,
      451,
    ],
    100: [
      789,
      787,
      786,
      784,
      783,
      781,
      780,
      778,
      776,
      774,
      772,
      770,
      768,
      767,
      764,
      763,
      760,
      758,
      756,
      754,
      752,
      750,
      747,
      745,
      743,
      740,
      738,
      735,
      733,
      730,
      728,
      725,
      722,
      719,
      716,
      714,
      711,
      707,
      704,
      701,
      697,
      694,
      691,
      687,
      683,
      680,
      676,
      672,
      668,
      664,
      659,
      655,
      651,
      646,
      642,
      637,
      632,
      627,
      623,
      618,
      612,
      607,
      602,
      597,
      591,
      586,
      580,
      575,
      569,
      564,
      558,
      552,
      546,
      540,
      534,
      528,
      522,
      516,
      510,
      503,
      497,
      491,
      484,
      478,
      471,
      465,
      458,
      451,
      445,
      438,
      432,
    ],
    105: [
      789,
      787,
      785,
      783,
      780,
      778,
      776,
      774,
      771,
      769,
      766,
      764,
      761,
      759,
      756,
      753,
      751,
      748,
      746,
      743,
      740,
      737,
      735,
      732,
      729,
      726,
      723,
      720,
      718,
      714,
      712,
      708,
      705,
      702,
      699,
      696,
      692,
      689,
      685,
      682,
      678,
      675,
      671,
      667,
      663,
      659,
      655,
      651,
      647,
      643,
      638,
      634,
      629,
      625,
      620,
      615,
      610,
      606,
      600,
      595,
      590,
      585,
      580,
      575,
      569,
      564,
      558,
      553,
      547,
      541,
      536,
      530,
      524,
      518,
      512,
      506,
      500,
      494,
      488,
      482,
      476,
      469,
      463,
      457,
      450,
      444,
      438,
      431,
      425,
      419,
      412,
    ],
    110: [
      789,
      786,
      783,
      781,
      778,
      775,
      772,
      769,
      766,
      763,
      760,
      757,
      754,
      751,
      748,
      745,
      741,
      738,
      735,
      732,
      728,
      725,
      722,
      719,
      715,
      712,
      709,
      706,
      702,
      699,
      695,
      692,
      688,
      685,
      681,
      678,
      674,
      671,
      667,
      663,
      659,
      655,
      651,
      647,
      643,
      639,
      635,
      630,
      626,
      622,
      617,
      612,
      608,
      603,
      598,
      594,
      589,
      584,
      579,
      573,
      568,
      563,
      558,
      553,
      547,
      542,
      536,
      531,
      525,
      519,
      514,
      508,
      502,
      496,
      491,
      485,
      479,
      473,
      467,
      461,
      455,
      448,
      442,
      437,
      430,
      424,
      418,
      412,
      405,
      399,
      393,
    ],
    115: [
      789,
      785,
      782,
      779,
      776,
      772,
      769,
      765,
      762,
      758,
      754,
      751,
      747,
      743,
      740,
      736,
      732,
      728,
      725,
      721,
      717,
      713,
      710,
      706,
      702,
      698,
      695,
      691,
      687,
      683,
      679,
      676,
      672,
      668,
      664,
      660,
      656,
      652,
      648,
      644,
      640,
      636,
      632,
      627,
      623,
      619,
      614,
      610,
      605,
      601,
      596,
      591,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      552,
      547,
      541,
      536,
      531,
      525,
      520,
      514,
      509,
      503,
      498,
      492,
      486,
      481,
      475,
      469,
      463,
      458,
      452,
      446,
      440,
      434,
      428,
      422,
      416,
      410,
      404,
      398,
      392,
      386,
      380,
      374,
    ],
    120: [
      789,
      785,
      781,
      777,
      773,
      769,
      765,
      761,
      757,
      753,
      749,
      745,
      740,
      736,
      732,
      727,
      723,
      719,
      715,
      710,
      706,
      702,
      698,
      693,
      689,
      685,
      681,
      676,
      672,
      668,
      664,
      660,
      655,
      651,
      647,
      643,
      638,
      634,
      630,
      626,
      621,
      617,
      612,
      608,
      603,
      599,
      594,
      590,
      585,
      580,
      575,
      571,
      566,
      561,
      556,
      551,
      546,
      541,
      536,
      530,
      525,
      520,
      515,
      509,
      504,
      499,
      493,
      487,
      482,
      477,
      471,
      465,
      460,
      454,
      448,
      442,
      437,
      431,
      425,
      420,
      414,
      408,
      402,
      396,
      391,
      385,
      379,
      373,
      368,
      361,
      356,
    ],
    125: [
      789,
      784,
      780,
      776,
      771,
      767,
      762,
      758,
      753,
      748,
      743,
      739,
      734,
      729,
      724,
      719,
      715,
      710,
      705,
      700,
      695,
      691,
      686,
      681,
      676,
      672,
      667,
      663,
      658,
      653,
      649,
      644,
      639,
      635,
      630,
      626,
      621,
      617,
      612,
      607,
      603,
      598,
      594,
      589,
      584,
      579,
      575,
      570,
      565,
      560,
      555,
      550,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      504,
      499,
      494,
      488,
      483,
      477,
      472,
      467,
      461,
      456,
      450,
      444,
      439,
      434,
      428,
      422,
      417,
      411,
      405,
      400,
      394,
      388,
      383,
      377,
      372,
      366,
      360,
      355,
      349,
      343,
      338,
    ],
    130: [
      789,
      784,
      779,
      774,
      769,
      764,
      759,
      754,
      749,
      744,
      738,
      733,
      728,
      722,
      717,
      712,
      706,
      701,
      696,
      690,
      685,
      680,
      675,
      670,
      665,
      659,
      654,
      649,
      644,
      639,
      634,
      629,
      624,
      619,
      614,
      609,
      605,
      600,
      595,
      590,
      585,
      580,
      575,
      570,
      565,
      561,
      556,
      551,
      546,
      541,
      536,
      531,
      525,
      520,
      515,
      510,
      505,
      500,
      495,
      489,
      484,
      479,
      473,
      468,
      463,
      457,
      452,
      447,
      441,
      436,
      431,
      425,
      419,
      414,
      408,
      403,
      397,
      392,
      386,
      381,
      375,
      370,
      364,
      359,
      353,
      348,
      342,
      337,
      332,
      326,
      321,
    ],
    135: [
      789,
      783,
      778,
      773,
      767,
      762,
      756,
      751,
      745,
      739,
      734,
      728,
      722,
      716,
      710,
      705,
      699,
      693,
      687,
      682,
      676,
      670,
      665,
      659,
      653,
      648,
      642,
      637,
      631,
      626,
      620,
      615,
      610,
      604,
      599,
      594,
      589,
      583,
      578,
      573,
      568,
      563,
      558,
      553,
      547,
      542,
      537,
      532,
      527,
      522,
      517,
      512,
      506,
      501,
      496,
      491,
      486,
      480,
      475,
      470,
      465,
      459,
      454,
      449,
      443,
      438,
      433,
      427,
      422,
      417,
      411,
      406,
      400,
      395,
      390,
      384,
      379,
      374,
      368,
      363,
      358,
      352,
      347,
      341,
      336,
      331,
      326,
      320,
      315,
      310,
      305,
    ],
    140: [
      789,
      783,
      777,
      772,
      766,
      760,
      754,
      748,
      741,
      735,
      729,
      723,
      717,
      710,
      704,
      698,
      692,
      686,
      680,
      673,
      667,
      661,
      655,
      649,
      643,
      637,
      631,
      625,
      620,
      614,
      608,
      602,
      596,
      590,
      585,
      579,
      574,
      568,
      562,
      557,
      552,
      546,
      541,
      535,
      530,
      525,
      520,
      514,
      509,
      504,
      499,
      493,
      488,
      483,
      478,
      472,
      467,
      462,
      456,
      451,
      446,
      441,
      435,
      430,
      425,
      420,
      414,
      409,
      404,
      398,
      393,
      388,
      383,
      377,
      372,
      367,
      362,
      356,
      351,
      346,
      341,
      335,
      330,
      325,
      320,
      315,
      310,
      304,
      299,
      294,
      290,
    ],
    145: [
      789,
      783,
      777,
      770,
      764,
      758,
      751,
      745,
      738,
      732,
      725,
      719,
      712,
      705,
      699,
      692,
      686,
      679,
      673,
      666,
      660,
      654,
      647,
      641,
      634,
      628,
      622,
      616,
      609,
      603,
      597,
      591,
      584,
      578,
      572,
      566,
      560,
      554,
      548,
      542,
      536,
      531,
      525,
      519,
      514,
      508,
      503,
      498,
      492,
      487,
      481,
      476,
      471,
      465,
      460,
      455,
      449,
      444,
      439,
      434,
      428,
      423,
      418,
      412,
      407,
      402,
      397,
      391,
      386,
      381,
      376,
      371,
      366,
      360,
      355,
      350,
      345,
      340,
      335,
      330,
      325,
      319,
      315,
      310,
      305,
      300,
      295,
      290,
      285,
      280,
      275,
    ],
    150: [
      789,
      782,
      776,
      769,
      763,
      756,
      749,
      742,
      736,
      729,
      722,
      715,
      708,
      701,
      694,
      687,
      680,
      673,
      667,
      660,
      653,
      647,
      640,
      634,
      627,
      620,
      613,
      607,
      600,
      594,
      587,
      581,
      574,
      568,
      561,
      555,
      548,
      542,
      536,
      530,
      523,
      517,
      511,
      505,
      499,
      493,
      488,
      482,
      476,
      471,
      465,
      460,
      454,
      449,
      444,
      438,
      433,
      428,
      422,
      417,
      412,
      407,
      401,
      396,
      391,
      386,
      381,
      375,
      370,
      365,
      360,
      355,
      350,
      345,
      340,
      335,
      330,
      325,
      320,
      315,
      310,
      305,
      301,
      296,
      290,
      286,
      281,
      277,
      272,
      267,
      262,
    ],
    155: [
      789,
      782,
      775,
      768,
      762,
      755,
      747,
      740,
      733,
      726,
      719,
      711,
      704,
      697,
      690,
      683,
      676,
      669,
      662,
      655,
      648,
      641,
      634,
      627,
      620,
      613,
      606,
      600,
      593,
      586,
      579,
      572,
      565,
      558,
      552,
      545,
      538,
      532,
      525,
      519,
      512,
      506,
      499,
      493,
      487,
      481,
      475,
      469,
      463,
      457,
      451,
      445,
      440,
      434,
      429,
      423,
      418,
      413,
      407,
      402,
      397,
      392,
      386,
      381,
      376,
      371,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      307,
      302,
      297,
      292,
      288,
      283,
      278,
      274,
      269,
      264,
      260,
      255,
      251,
    ],
    160: [
      789,
      782,
      775,
      768,
      761,
      753,
      746,
      739,
      731,
      724,
      716,
      709,
      701,
      694,
      686,
      679,
      672,
      665,
      658,
      651,
      644,
      636,
      629,
      622,
      615,
      608,
      600,
      593,
      586,
      579,
      572,
      565,
      558,
      551,
      544,
      537,
      530,
      523,
      517,
      510,
      503,
      496,
      490,
      483,
      477,
      470,
      464,
      458,
      452,
      445,
      439,
      433,
      428,
      422,
      416,
      411,
      405,
      400,
      394,
      389,
      384,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      343,
      338,
      333,
      329,
      323,
      319,
      314,
      309,
      304,
      300,
      295,
      290,
      285,
      281,
      276,
      272,
      267,
      263,
      258,
      254,
      250,
      245,
      241,
    ],
    165: [
      789,
      782,
      774,
      767,
      760,
      752,
      745,
      737,
      730,
      722,
      714,
      707,
      699,
      691,
      684,
      676,
      669,
      662,
      655,
      647,
      640,
      633,
      625,
      618,
      610,
      603,
      596,
      588,
      581,
      574,
      567,
      560,
      552,
      545,
      538,
      531,
      524,
      517,
      510,
      503,
      496,
      489,
      482,
      475,
      469,
      462,
      456,
      449,
      443,
      437,
      430,
      424,
      418,
      412,
      406,
      401,
      395,
      389,
      384,
      378,
      373,
      368,
      362,
      357,
      352,
      347,
      342,
      337,
      333,
      328,
      323,
      318,
      314,
      309,
      304,
      299,
      294,
      290,
      286,
      281,
      276,
      272,
      267,
      263,
      259,
      254,
      250,
      246,
      241,
      237,
      233,
    ],
    170: [
      789,
      781,
      774,
      767,
      759,
      752,
      744,
      736,
      728,
      721,
      713,
      705,
      697,
      690,
      682,
      675,
      667,
      660,
      652,
      645,
      638,
      630,
      622,
      615,
      607,
      600,
      593,
      585,
      578,
      570,
      563,
      556,
      549,
      541,
      534,
      526,
      519,
      512,
      505,
      498,
      491,
      484,
      477,
      470,
      463,
      456,
      450,
      443,
      437,
      430,
      424,
      418,
      411,
      405,
      399,
      394,
      388,
      382,
      376,
      371,
      365,
      360,
      355,
      349,
      344,
      339,
      335,
      330,
      325,
      320,
      315,
      311,
      306,
      301,
      296,
      292,
      287,
      283,
      278,
      274,
      270,
      265,
      261,
      256,
      252,
      248,
      244,
      240,
      235,
      231,
      227,
    ],
    175: [
      789,
      781,
      774,
      766,
      759,
      751,
      743,
      736,
      728,
      720,
      712,
      704,
      696,
      688,
      681,
      673,
      666,
      659,
      651,
      644,
      636,
      628,
      621,
      613,
      605,
      598,
      591,
      583,
      576,
      568,
      561,
      554,
      546,
      538,
      531,
      523,
      516,
      509,
      502,
      495,
      488,
      481,
      474,
      467,
      460,
      453,
      446,
      439,
      433,
      426,
      420,
      413,
      407,
      401,
      395,
      389,
      383,
      378,
      372,
      366,
      361,
      355,
      350,
      345,
      340,
      335,
      330,
      325,
      320,
      315,
      310,
      306,
      301,
      297,
      292,
      288,
      283,
      278,
      274,
      270,
      265,
      261,
      257,
      252,
      248,
      244,
      240,
      235,
      232,
      227,
      223,
    ],
  },
  '7E': {
    0: [
      735,
      741,
      747,
      753,
      759,
      765,
      771,
      776,
      782,
      787,
      792,
      797,
      802,
      807,
      811,
      816,
      820,
      824,
      827,
      831,
      835,
      838,
      841,
      844,
      847,
      849,
      852,
      854,
      856,
      858,
      859,
      861,
      862,
      863,
      864,
      865,
      865,
      866,
      866,
      866,
      866,
      865,
      865,
      864,
      863,
      862,
      860,
      859,
      857,
      855,
      853,
      850,
      848,
      845,
      842,
      839,
      836,
      832,
      828,
      824,
      820,
      816,
      811,
      807,
      802,
      797,
      792,
      786,
      781,
      775,
      769,
      763,
      757,
      751,
      744,
      737,
      730,
      723,
      716,
      709,
      701,
      693,
      685,
      678,
      669,
      661,
      653,
      644,
      635,
      626,
      617,
    ],
    5: [
      735,
      741,
      747,
      753,
      759,
      765,
      771,
      776,
      782,
      787,
      792,
      797,
      802,
      806,
      811,
      815,
      819,
      823,
      827,
      831,
      834,
      837,
      841,
      843,
      846,
      849,
      851,
      853,
      855,
      857,
      859,
      860,
      862,
      863,
      864,
      864,
      865,
      865,
      865,
      865,
      865,
      865,
      864,
      863,
      862,
      861,
      859,
      858,
      856,
      854,
      852,
      849,
      847,
      844,
      841,
      838,
      835,
      831,
      828,
      824,
      820,
      815,
      811,
      806,
      801,
      796,
      791,
      786,
      780,
      775,
      769,
      763,
      756,
      750,
      743,
      737,
      730,
      723,
      715,
      708,
      700,
      693,
      685,
      677,
      669,
      660,
      652,
      643,
      635,
      626,
      617,
    ],
    10: [
      735,
      741,
      747,
      753,
      759,
      765,
      770,
      776,
      781,
      786,
      791,
      796,
      801,
      806,
      810,
      814,
      818,
      822,
      826,
      829,
      833,
      836,
      839,
      842,
      845,
      847,
      850,
      852,
      854,
      856,
      857,
      859,
      860,
      861,
      862,
      863,
      863,
      863,
      863,
      863,
      863,
      863,
      862,
      861,
      860,
      859,
      857,
      856,
      854,
      852,
      850,
      847,
      845,
      842,
      839,
      836,
      833,
      829,
      825,
      821,
      817,
      813,
      809,
      804,
      799,
      794,
      789,
      784,
      778,
      772,
      767,
      761,
      754,
      748,
      741,
      734,
      728,
      721,
      713,
      706,
      699,
      691,
      683,
      675,
      667,
      659,
      650,
      641,
      633,
      624,
      615,
    ],
    15: [
      735,
      741,
      747,
      753,
      759,
      764,
      770,
      775,
      780,
      785,
      790,
      795,
      800,
      804,
      808,
      813,
      817,
      820,
      824,
      828,
      831,
      834,
      837,
      840,
      842,
      845,
      847,
      849,
      851,
      853,
      855,
      856,
      857,
      858,
      859,
      860,
      860,
      860,
      860,
      860,
      860,
      859,
      859,
      858,
      857,
      855,
      854,
      852,
      851,
      849,
      846,
      844,
      841,
      839,
      836,
      832,
      829,
      825,
      822,
      818,
      814,
      810,
      805,
      800,
      796,
      791,
      785,
      780,
      775,
      769,
      763,
      757,
      751,
      744,
      738,
      731,
      724,
      717,
      710,
      703,
      695,
      688,
      680,
      672,
      664,
      656,
      647,
      639,
      630,
      621,
      612,
    ],
    20: [
      735,
      741,
      746,
      752,
      758,
      763,
      769,
      774,
      779,
      784,
      789,
      793,
      798,
      802,
      806,
      810,
      814,
      818,
      821,
      825,
      828,
      831,
      834,
      837,
      839,
      842,
      844,
      846,
      848,
      849,
      851,
      852,
      853,
      854,
      855,
      855,
      856,
      856,
      856,
      856,
      855,
      855,
      854,
      853,
      852,
      851,
      849,
      848,
      846,
      844,
      841,
      839,
      836,
      834,
      831,
      827,
      824,
      820,
      817,
      813,
      809,
      804,
      800,
      795,
      790,
      785,
      780,
      775,
      770,
      764,
      758,
      752,
      746,
      739,
      733,
      726,
      719,
      712,
      705,
      698,
      691,
      683,
      675,
      668,
      660,
      651,
      643,
      635,
      626,
      617,
      609,
    ],
    25: [
      735,
      740,
      746,
      752,
      757,
      762,
      767,
      772,
      777,
      782,
      787,
      791,
      795,
      800,
      804,
      808,
      811,
      815,
      818,
      821,
      824,
      827,
      830,
      833,
      835,
      837,
      839,
      841,
      843,
      845,
      846,
      847,
      848,
      849,
      850,
      850,
      850,
      850,
      850,
      850,
      850,
      849,
      848,
      847,
      846,
      845,
      843,
      841,
      840,
      837,
      835,
      833,
      830,
      827,
      824,
      821,
      818,
      814,
      810,
      806,
      802,
      798,
      793,
      789,
      784,
      779,
      774,
      769,
      763,
      757,
      752,
      746,
      740,
      733,
      727,
      720,
      713,
      707,
      699,
      692,
      685,
      677,
      670,
      662,
      654,
      646,
      638,
      629,
      621,
      612,
      604,
    ],
    30: [
      735,
      740,
      746,
      751,
      756,
      761,
      766,
      771,
      775,
      780,
      784,
      789,
      793,
      797,
      800,
      804,
      808,
      811,
      814,
      817,
      820,
      823,
      825,
      828,
      830,
      832,
      834,
      836,
      837,
      839,
      840,
      841,
      842,
      843,
      843,
      844,
      844,
      844,
      844,
      843,
      843,
      842,
      841,
      840,
      839,
      837,
      836,
      834,
      832,
      830,
      827,
      825,
      822,
      819,
      816,
      813,
      810,
      806,
      802,
      798,
      794,
      790,
      785,
      781,
      776,
      771,
      766,
      761,
      755,
      749,
      744,
      738,
      732,
      725,
      719,
      712,
      706,
      699,
      692,
      685,
      677,
      670,
      662,
      655,
      647,
      639,
      631,
      623,
      614,
      606,
      597,
    ],
    35: [
      735,
      740,
      745,
      750,
      755,
      760,
      764,
      769,
      773,
      777,
      781,
      785,
      789,
      793,
      797,
      800,
      803,
      806,
      809,
      812,
      815,
      818,
      820,
      822,
      824,
      826,
      828,
      830,
      831,
      832,
      833,
      834,
      835,
      835,
      836,
      836,
      836,
      836,
      836,
      835,
      835,
      834,
      833,
      832,
      830,
      829,
      827,
      825,
      823,
      821,
      818,
      816,
      813,
      810,
      807,
      804,
      800,
      797,
      793,
      789,
      785,
      781,
      776,
      771,
      766,
      762,
      756,
      751,
      746,
      740,
      734,
      729,
      722,
      716,
      710,
      703,
      697,
      690,
      683,
      676,
      669,
      661,
      654,
      646,
      638,
      631,
      623,
      615,
      606,
      598,
      590,
    ],
    40: [
      735,
      740,
      744,
      749,
      753,
      758,
      762,
      766,
      771,
      774,
      778,
      782,
      786,
      789,
      792,
      795,
      798,
      801,
      804,
      807,
      809,
      812,
      814,
      816,
      818,
      819,
      821,
      822,
      823,
      825,
      825,
      826,
      827,
      827,
      827,
      827,
      827,
      827,
      827,
      826,
      825,
      824,
      823,
      822,
      821,
      819,
      817,
      815,
      813,
      811,
      808,
      805,
      803,
      800,
      796,
      793,
      790,
      786,
      782,
      778,
      774,
      770,
      765,
      760,
      756,
      751,
      746,
      740,
      735,
      729,
      723,
      718,
      712,
      705,
      699,
      693,
      686,
      679,
      672,
      665,
      659,
      651,
      644,
      636,
      629,
      621,
      613,
      605,
      597,
      589,
      581,
    ],
    45: [
      735,
      739,
      744,
      748,
      752,
      756,
      760,
      764,
      768,
      771,
      775,
      778,
      781,
      785,
      787,
      790,
      793,
      796,
      798,
      801,
      803,
      805,
      807,
      809,
      810,
      812,
      813,
      814,
      815,
      816,
      817,
      817,
      818,
      818,
      818,
      818,
      818,
      817,
      817,
      816,
      815,
      814,
      813,
      811,
      810,
      808,
      806,
      804,
      802,
      799,
      797,
      794,
      791,
      788,
      785,
      781,
      778,
      774,
      770,
      766,
      762,
      757,
      753,
      749,
      743,
      738,
      733,
      728,
      723,
      717,
      711,
      706,
      700,
      693,
      687,
      681,
      674,
      668,
      661,
      654,
      647,
      640,
      632,
      625,
      617,
      610,
      602,
      594,
      586,
      579,
      571,
    ],
    50: [
      735,
      739,
      743,
      747,
      750,
      754,
      758,
      761,
      764,
      768,
      771,
      774,
      777,
      780,
      782,
      785,
      787,
      789,
      792,
      794,
      796,
      797,
      799,
      801,
      802,
      803,
      804,
      805,
      806,
      807,
      807,
      807,
      808,
      808,
      808,
      807,
      807,
      806,
      805,
      805,
      803,
      802,
      801,
      799,
      798,
      796,
      794,
      791,
      789,
      787,
      784,
      781,
      778,
      775,
      772,
      768,
      764,
      761,
      757,
      753,
      748,
      744,
      740,
      735,
      730,
      725,
      720,
      715,
      709,
      704,
      698,
      692,
      686,
      680,
      674,
      667,
      661,
      655,
      648,
      641,
      634,
      627,
      620,
      612,
      605,
      598,
      590,
      583,
      575,
      567,
      559,
    ],
    55: [
      735,
      738,
      742,
      745,
      749,
      752,
      755,
      758,
      761,
      764,
      767,
      769,
      772,
      774,
      777,
      779,
      781,
      783,
      785,
      786,
      788,
      790,
      791,
      792,
      793,
      794,
      795,
      796,
      796,
      797,
      797,
      797,
      797,
      797,
      796,
      796,
      795,
      794,
      793,
      792,
      791,
      790,
      788,
      786,
      785,
      783,
      780,
      778,
      776,
      773,
      770,
      767,
      764,
      761,
      758,
      754,
      750,
      746,
      742,
      738,
      734,
      730,
      725,
      720,
      715,
      710,
      705,
      700,
      695,
      689,
      683,
      678,
      672,
      666,
      659,
      653,
      647,
      641,
      634,
      627,
      620,
      613,
      606,
      599,
      592,
      585,
      577,
      569,
      562,
      554,
      546,
    ],
    60: [
      735,
      738,
      741,
      744,
      747,
      749,
      752,
      755,
      757,
      760,
      762,
      764,
      766,
      769,
      770,
      772,
      774,
      776,
      777,
      779,
      780,
      781,
      782,
      783,
      784,
      784,
      785,
      785,
      785,
      786,
      786,
      786,
      785,
      785,
      784,
      784,
      783,
      782,
      781,
      779,
      778,
      776,
      775,
      773,
      771,
      769,
      766,
      764,
      761,
      758,
      756,
      752,
      749,
      746,
      743,
      739,
      735,
      731,
      727,
      723,
      719,
      714,
      710,
      705,
      700,
      695,
      690,
      685,
      679,
      674,
      668,
      662,
      656,
      650,
      645,
      638,
      632,
      625,
      619,
      612,
      605,
      598,
      592,
      585,
      577,
      570,
      563,
      556,
      548,
      541,
      533,
    ],
    65: [
      735,
      737,
      740,
      742,
      745,
      747,
      749,
      751,
      754,
      755,
      757,
      759,
      761,
      762,
      764,
      765,
      767,
      768,
      769,
      770,
      771,
      772,
      773,
      773,
      774,
      774,
      774,
      774,
      774,
      774,
      774,
      774,
      773,
      772,
      772,
      771,
      770,
      768,
      767,
      766,
      764,
      762,
      760,
      758,
      756,
      754,
      751,
      749,
      746,
      743,
      740,
      737,
      734,
      730,
      727,
      723,
      719,
      715,
      711,
      707,
      702,
      698,
      693,
      688,
      684,
      679,
      673,
      668,
      663,
      657,
      652,
      646,
      640,
      634,
      628,
      622,
      616,
      609,
      603,
      597,
      590,
      583,
      576,
      569,
      562,
      555,
      548,
      541,
      534,
      526,
      519,
    ],
    70: [
      735,
      737,
      739,
      741,
      743,
      744,
      746,
      748,
      750,
      751,
      752,
      754,
      755,
      756,
      757,
      758,
      759,
      760,
      761,
      761,
      762,
      762,
      763,
      763,
      763,
      763,
      763,
      763,
      763,
      762,
      762,
      761,
      760,
      759,
      758,
      757,
      756,
      754,
      753,
      751,
      749,
      747,
      745,
      743,
      741,
      738,
      736,
      733,
      730,
      727,
      724,
      721,
      717,
      714,
      710,
      706,
      702,
      698,
      694,
      690,
      685,
      681,
      676,
      671,
      666,
      661,
      656,
      651,
      645,
      640,
      635,
      629,
      623,
      617,
      611,
      606,
      598,
      592,
      586,
      580,
      574,
      567,
      560,
      553,
      546,
      539,
      532,
      525,
      518,
      511,
      504,
    ],
    75: [
      735,
      736,
      738,
      739,
      741,
      742,
      743,
      744,
      745,
      746,
      747,
      748,
      749,
      750,
      750,
      751,
      751,
      752,
      752,
      752,
      753,
      753,
      752,
      752,
      752,
      752,
      752,
      751,
      750,
      750,
      749,
      748,
      747,
      746,
      744,
      743,
      741,
      740,
      738,
      736,
      734,
      732,
      730,
      727,
      725,
      722,
      719,
      716,
      713,
      710,
      707,
      704,
      700,
      696,
      693,
      689,
      685,
      681,
      676,
      672,
      668,
      663,
      658,
      653,
      649,
      643,
      638,
      633,
      628,
      622,
      617,
      611,
      605,
      599,
      594,
      588,
      582,
      575,
      569,
      563,
      557,
      550,
      543,
      536,
      530,
      523,
      516,
      509,
      502,
      495,
      488,
    ],
    80: [
      735,
      736,
      737,
      738,
      738,
      739,
      740,
      741,
      741,
      741,
      742,
      742,
      743,
      743,
      743,
      743,
      743,
      743,
      743,
      743,
      743,
      742,
      742,
      741,
      741,
      740,
      739,
      738,
      738,
      737,
      735,
      734,
      733,
      731,
      730,
      728,
      726,
      725,
      723,
      720,
      718,
      716,
      713,
      711,
      708,
      705,
      702,
      699,
      696,
      693,
      689,
      686,
      682,
      679,
      675,
      671,
      667,
      662,
      658,
      654,
      649,
      645,
      640,
      635,
      630,
      625,
      620,
      615,
      609,
      604,
      598,
      593,
      587,
      581,
      575,
      569,
      563,
      557,
      551,
      545,
      539,
      532,
      525,
      519,
      512,
      506,
      500,
      492,
      486,
      479,
      472,
    ],
    85: [
      735,
      735,
      736,
      736,
      736,
      736,
      737,
      737,
      737,
      737,
      737,
      736,
      736,
      736,
      736,
      735,
      735,
      734,
      734,
      733,
      733,
      732,
      731,
      730,
      729,
      728,
      727,
      726,
      725,
      723,
      722,
      720,
      719,
      717,
      715,
      713,
      711,
      709,
      707,
      704,
      702,
      699,
      697,
      694,
      691,
      688,
      685,
      682,
      678,
      675,
      671,
      668,
      664,
      660,
      656,
      652,
      648,
      644,
      639,
      635,
      630,
      626,
      621,
      616,
      611,
      606,
      601,
      596,
      590,
      585,
      579,
      574,
      568,
      562,
      557,
      551,
      545,
      539,
      533,
      526,
      520,
      514,
      508,
      501,
      495,
      489,
      482,
      475,
      468,
      462,
      455,
    ],
    90: [
      735,
      735,
      734,
      734,
      734,
      734,
      733,
      733,
      732,
      732,
      731,
      730,
      730,
      729,
      728,
      727,
      726,
      725,
      724,
      723,
      722,
      721,
      720,
      719,
      717,
      716,
      714,
      713,
      711,
      710,
      708,
      706,
      704,
      702,
      700,
      698,
      695,
      693,
      691,
      688,
      685,
      683,
      680,
      677,
      674,
      670,
      667,
      664,
      660,
      657,
      653,
      649,
      645,
      642,
      637,
      633,
      629,
      625,
      620,
      616,
      611,
      606,
      602,
      597,
      592,
      586,
      581,
      576,
      571,
      566,
      560,
      555,
      549,
      543,
      537,
      532,
      526,
      520,
      514,
      508,
      502,
      496,
      490,
      483,
      477,
      470,
      464,
      458,
      451,
      444,
      438,
    ],
    95: [
      735,
      734,
      733,
      733,
      732,
      731,
      730,
      729,
      728,
      727,
      725,
      724,
      723,
      722,
      720,
      719,
      718,
      716,
      715,
      713,
      712,
      710,
      709,
      707,
      705,
      703,
      702,
      700,
      698,
      696,
      694,
      691,
      689,
      687,
      684,
      682,
      680,
      677,
      674,
      671,
      668,
      665,
      662,
      659,
      656,
      653,
      649,
      646,
      642,
      638,
      634,
      631,
      627,
      623,
      618,
      614,
      610,
      605,
      601,
      596,
      592,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      551,
      546,
      540,
      535,
      530,
      524,
      518,
      512,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
      459,
      452,
      446,
      440,
      434,
      427,
      421,
    ],
    100: [
      735,
      733,
      732,
      731,
      729,
      728,
      726,
      725,
      723,
      722,
      720,
      718,
      716,
      715,
      713,
      711,
      709,
      707,
      705,
      703,
      701,
      699,
      697,
      695,
      693,
      691,
      689,
      686,
      684,
      682,
      679,
      677,
      674,
      672,
      669,
      666,
      663,
      660,
      657,
      654,
      651,
      648,
      645,
      641,
      638,
      635,
      631,
      627,
      623,
      620,
      616,
      612,
      608,
      603,
      599,
      595,
      590,
      586,
      581,
      577,
      572,
      567,
      562,
      557,
      552,
      547,
      542,
      537,
      532,
      526,
      521,
      515,
      510,
      504,
      499,
      493,
      487,
      481,
      476,
      470,
      464,
      458,
      452,
      446,
      440,
      434,
      428,
      422,
      416,
      410,
      403,
    ],
    105: [
      735,
      733,
      731,
      729,
      727,
      725,
      723,
      721,
      719,
      717,
      715,
      712,
      710,
      708,
      705,
      703,
      701,
      698,
      696,
      693,
      691,
      688,
      686,
      683,
      681,
      678,
      676,
      673,
      670,
      668,
      665,
      662,
      659,
      656,
      653,
      650,
      647,
      644,
      641,
      638,
      634,
      631,
      627,
      624,
      620,
      616,
      613,
      609,
      605,
      601,
      597,
      593,
      588,
      584,
      580,
      575,
      571,
      566,
      562,
      557,
      552,
      547,
      542,
      537,
      532,
      527,
      522,
      517,
      512,
      506,
      501,
      496,
      490,
      485,
      479,
      474,
      468,
      462,
      457,
      451,
      445,
      439,
      433,
      428,
      422,
      416,
      410,
      404,
      398,
      392,
      386,
    ],
    110: [
      735,
      732,
      730,
      728,
      725,
      723,
      720,
      717,
      715,
      712,
      709,
      706,
      704,
      701,
      698,
      695,
      692,
      689,
      686,
      683,
      680,
      678,
      675,
      672,
      669,
      666,
      663,
      660,
      657,
      654,
      650,
      647,
      644,
      641,
      638,
      634,
      631,
      627,
      624,
      621,
      617,
      613,
      610,
      606,
      602,
      598,
      594,
      590,
      586,
      582,
      578,
      574,
      569,
      565,
      561,
      556,
      551,
      547,
      542,
      537,
      532,
      528,
      523,
      518,
      513,
      508,
      502,
      497,
      492,
      487,
      481,
      476,
      471,
      465,
      460,
      454,
      449,
      443,
      438,
      432,
      426,
      420,
      415,
      409,
      403,
      397,
      392,
      386,
      380,
      375,
      369,
    ],
    115: [
      735,
      732,
      729,
      726,
      723,
      720,
      717,
      714,
      710,
      707,
      704,
      701,
      697,
      694,
      690,
      687,
      684,
      680,
      677,
      674,
      670,
      667,
      663,
      660,
      657,
      653,
      650,
      647,
      643,
      640,
      636,
      633,
      629,
      626,
      622,
      619,
      615,
      611,
      608,
      604,
      600,
      596,
      592,
      588,
      584,
      580,
      576,
      572,
      568,
      564,
      559,
      555,
      550,
      546,
      541,
      537,
      532,
      527,
      523,
      518,
      513,
      508,
      503,
      498,
      493,
      488,
      483,
      478,
      473,
      467,
      462,
      457,
      451,
      446,
      441,
      435,
      430,
      424,
      419,
      413,
      408,
      402,
      397,
      391,
      386,
      380,
      374,
      369,
      363,
      357,
      352,
    ],
    120: [
      735,
      731,
      728,
      725,
      721,
      717,
      714,
      710,
      706,
      703,
      699,
      695,
      691,
      687,
      683,
      680,
      676,
      672,
      668,
      664,
      660,
      657,
      653,
      649,
      645,
      641,
      637,
      634,
      630,
      626,
      622,
      619,
      615,
      611,
      607,
      603,
      599,
      595,
      591,
      587,
      583,
      579,
      575,
      571,
      567,
      563,
      558,
      554,
      550,
      545,
      541,
      536,
      532,
      527,
      523,
      518,
      513,
      508,
      504,
      499,
      494,
      489,
      484,
      479,
      474,
      469,
      464,
      459,
      454,
      448,
      443,
      438,
      433,
      427,
      422,
      417,
      411,
      406,
      401,
      395,
      390,
      384,
      379,
      373,
      368,
      362,
      357,
      352,
      346,
      341,
      335,
    ],
    125: [
      735,
      731,
      727,
      723,
      719,
      715,
      711,
      707,
      703,
      698,
      694,
      690,
      685,
      681,
      677,
      672,
      668,
      664,
      659,
      655,
      651,
      647,
      642,
      638,
      634,
      630,
      625,
      621,
      617,
      613,
      609,
      605,
      600,
      596,
      592,
      588,
      584,
      580,
      575,
      571,
      567,
      563,
      558,
      554,
      550,
      545,
      541,
      536,
      532,
      527,
      523,
      518,
      514,
      509,
      504,
      499,
      495,
      490,
      485,
      480,
      475,
      470,
      465,
      460,
      455,
      450,
      445,
      440,
      435,
      430,
      425,
      420,
      414,
      409,
      404,
      398,
      393,
      388,
      383,
      378,
      372,
      367,
      362,
      356,
      351,
      346,
      340,
      335,
      330,
      325,
      319,
    ],
    130: [
      735,
      730,
      726,
      722,
      717,
      713,
      708,
      704,
      699,
      694,
      689,
      685,
      680,
      675,
      670,
      666,
      661,
      656,
      651,
      647,
      642,
      637,
      632,
      628,
      623,
      619,
      614,
      609,
      605,
      600,
      596,
      591,
      587,
      582,
      578,
      573,
      569,
      564,
      560,
      555,
      551,
      547,
      542,
      537,
      533,
      528,
      524,
      519,
      515,
      510,
      505,
      501,
      496,
      491,
      486,
      482,
      477,
      472,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      386,
      381,
      376,
      371,
      366,
      360,
      355,
      350,
      345,
      340,
      335,
      330,
      324,
      319,
      314,
      309,
      304,
    ],
    135: [
      735,
      730,
      725,
      720,
      716,
      711,
      706,
      701,
      696,
      690,
      685,
      680,
      675,
      670,
      664,
      659,
      654,
      649,
      644,
      639,
      634,
      628,
      623,
      618,
      613,
      608,
      603,
      598,
      593,
      588,
      584,
      579,
      574,
      569,
      564,
      559,
      555,
      550,
      545,
      540,
      536,
      531,
      526,
      522,
      517,
      512,
      507,
      503,
      498,
      493,
      488,
      484,
      479,
      474,
      469,
      464,
      460,
      455,
      450,
      445,
      440,
      435,
      430,
      425,
      420,
      415,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      375,
      370,
      365,
      359,
      354,
      349,
      344,
      340,
      334,
      329,
      324,
      319,
      314,
      309,
      304,
      299,
      295,
      289,
    ],
    140: [
      735,
      730,
      725,
      719,
      714,
      709,
      703,
      698,
      692,
      687,
      681,
      676,
      670,
      665,
      659,
      654,
      648,
      642,
      637,
      631,
      626,
      621,
      615,
      610,
      604,
      599,
      594,
      588,
      583,
      578,
      572,
      567,
      562,
      557,
      551,
      546,
      541,
      536,
      531,
      526,
      521,
      516,
      511,
      506,
      502,
      497,
      492,
      487,
      482,
      477,
      472,
      467,
      463,
      458,
      453,
      448,
      443,
      438,
      433,
      428,
      423,
      418,
      413,
      409,
      403,
      399,
      393,
      389,
      384,
      379,
      374,
      369,
      364,
      359,
      354,
      349,
      344,
      339,
      334,
      329,
      324,
      319,
      314,
      310,
      305,
      300,
      295,
      290,
      285,
      281,
      276,
    ],
    145: [
      735,
      729,
      724,
      718,
      713,
      707,
      701,
      695,
      690,
      684,
      678,
      672,
      666,
      660,
      654,
      648,
      642,
      637,
      631,
      625,
      620,
      614,
      608,
      602,
      597,
      591,
      585,
      579,
      574,
      568,
      563,
      557,
      551,
      546,
      540,
      535,
      529,
      524,
      518,
      513,
      508,
      502,
      497,
      492,
      487,
      482,
      477,
      472,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      344,
      339,
      334,
      329,
      324,
      320,
      315,
      310,
      305,
      300,
      296,
      291,
      286,
      282,
      277,
      272,
      268,
      263,
    ],
    150: [
      735,
      729,
      723,
      717,
      711,
      705,
      699,
      693,
      687,
      681,
      675,
      669,
      662,
      656,
      650,
      644,
      638,
      632,
      626,
      620,
      614,
      608,
      602,
      596,
      590,
      584,
      578,
      572,
      566,
      560,
      554,
      548,
      542,
      536,
      530,
      524,
      519,
      513,
      507,
      501,
      496,
      490,
      485,
      479,
      474,
      469,
      463,
      458,
      453,
      448,
      443,
      438,
      433,
      428,
      423,
      417,
      413,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      359,
      354,
      349,
      344,
      339,
      335,
      330,
      325,
      320,
      316,
      311,
      306,
      301,
      297,
      292,
      288,
      283,
      278,
      274,
      270,
      265,
      260,
      256,
      251,
    ],
    155: [
      735,
      729,
      723,
      717,
      710,
      704,
      698,
      691,
      685,
      679,
      672,
      666,
      659,
      653,
      646,
      640,
      634,
      628,
      621,
      615,
      609,
      603,
      596,
      590,
      584,
      578,
      571,
      565,
      559,
      553,
      546,
      540,
      534,
      528,
      522,
      516,
      510,
      504,
      498,
      492,
      486,
      480,
      474,
      469,
      463,
      457,
      452,
      446,
      441,
      436,
      430,
      425,
      420,
      414,
      409,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      370,
      365,
      360,
      355,
      350,
      346,
      341,
      336,
      331,
      327,
      322,
      317,
      313,
      308,
      303,
      299,
      294,
      290,
      285,
      281,
      276,
      272,
      267,
      263,
      258,
      254,
      250,
      246,
      241,
    ],
    160: [
      735,
      729,
      722,
      716,
      710,
      703,
      696,
      690,
      683,
      677,
      670,
      663,
      657,
      650,
      643,
      637,
      631,
      624,
      618,
      611,
      605,
      599,
      592,
      585,
      579,
      572,
      566,
      559,
      553,
      547,
      540,
      534,
      528,
      521,
      515,
      509,
      502,
      496,
      490,
      484,
      478,
      472,
      466,
      460,
      454,
      448,
      442,
      436,
      431,
      425,
      420,
      414,
      409,
      404,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      344,
      339,
      334,
      330,
      325,
      320,
      315,
      311,
      306,
      302,
      297,
      293,
      288,
      284,
      279,
      275,
      271,
      266,
      262,
      258,
      253,
      249,
      245,
      240,
      236,
      232,
    ],
    165: [
      735,
      728,
      722,
      715,
      709,
      702,
      695,
      689,
      682,
      675,
      668,
      661,
      655,
      648,
      641,
      635,
      628,
      622,
      615,
      608,
      602,
      595,
      588,
      582,
      575,
      568,
      562,
      555,
      549,
      542,
      536,
      529,
      523,
      516,
      510,
      503,
      497,
      490,
      484,
      477,
      471,
      465,
      459,
      453,
      447,
      441,
      435,
      429,
      423,
      417,
      412,
      406,
      401,
      395,
      390,
      384,
      379,
      374,
      369,
      363,
      358,
      353,
      349,
      344,
      339,
      334,
      330,
      325,
      320,
      316,
      311,
      307,
      302,
      298,
      293,
      288,
      284,
      280,
      275,
      271,
      267,
      262,
      258,
      254,
      250,
      245,
      241,
      237,
      233,
      229,
      225,
    ],
    170: [
      735,
      728,
      722,
      715,
      708,
      702,
      695,
      688,
      681,
      674,
      667,
      660,
      653,
      646,
      640,
      633,
      626,
      620,
      613,
      606,
      600,
      593,
      586,
      579,
      572,
      566,
      559,
      552,
      545,
      539,
      533,
      526,
      519,
      512,
      506,
      499,
      493,
      486,
      480,
      473,
      467,
      461,
      454,
      448,
      441,
      436,
      430,
      424,
      418,
      412,
      406,
      400,
      394,
      389,
      383,
      378,
      373,
      367,
      362,
      357,
      352,
      347,
      342,
      337,
      332,
      327,
      323,
      318,
      314,
      309,
      304,
      300,
      295,
      291,
      287,
      282,
      277,
      273,
      269,
      265,
      261,
      256,
      252,
      248,
      244,
      240,
      236,
      232,
      228,
      224,
      220,
    ],
    175: [
      735,
      728,
      722,
      715,
      708,
      701,
      694,
      687,
      680,
      673,
      666,
      659,
      652,
      645,
      639,
      632,
      625,
      618,
      612,
      605,
      598,
      591,
      584,
      577,
      571,
      564,
      557,
      550,
      544,
      537,
      530,
      523,
      516,
      510,
      503,
      497,
      490,
      484,
      477,
      471,
      465,
      458,
      452,
      445,
      439,
      432,
      426,
      420,
      414,
      408,
      402,
      397,
      391,
      385,
      380,
      374,
      369,
      364,
      358,
      353,
      348,
      342,
      337,
      333,
      328,
      323,
      318,
      314,
      309,
      305,
      300,
      296,
      291,
      287,
      283,
      278,
      274,
      269,
      265,
      261,
      257,
      253,
      249,
      244,
      240,
      236,
      232,
      228,
      224,
      221,
      217,
    ],
  },
  '7W': {
    0: [
      779,
      786,
      793,
      800,
      807,
      814,
      820,
      827,
      833,
      839,
      845,
      850,
      856,
      861,
      866,
      871,
      876,
      881,
      885,
      890,
      894,
      898,
      901,
      905,
      908,
      911,
      914,
      917,
      919,
      921,
      924,
      925,
      927,
      929,
      930,
      931,
      932,
      932,
      933,
      933,
      933,
      933,
      932,
      932,
      931,
      930,
      928,
      927,
      925,
      923,
      921,
      919,
      916,
      913,
      910,
      907,
      904,
      900,
      896,
      892,
      888,
      883,
      878,
      874,
      868,
      863,
      858,
      852,
      846,
      840,
      834,
      827,
      820,
      814,
      807,
      799,
      792,
      784,
      776,
      768,
      760,
      752,
      743,
      735,
      726,
      717,
      708,
      698,
      688,
      679,
      669,
    ],
    5: [
      779,
      786,
      793,
      800,
      807,
      814,
      820,
      826,
      833,
      839,
      844,
      850,
      856,
      861,
      866,
      871,
      876,
      881,
      885,
      889,
      893,
      897,
      901,
      904,
      907,
      911,
      913,
      916,
      919,
      921,
      923,
      925,
      926,
      928,
      929,
      930,
      931,
      932,
      932,
      932,
      932,
      932,
      932,
      931,
      930,
      929,
      928,
      926,
      924,
      922,
      920,
      918,
      915,
      912,
      909,
      906,
      903,
      899,
      895,
      891,
      887,
      882,
      878,
      873,
      868,
      862,
      857,
      851,
      845,
      839,
      833,
      826,
      820,
      813,
      806,
      798,
      791,
      783,
      776,
      768,
      759,
      751,
      743,
      734,
      725,
      716,
      707,
      698,
      688,
      679,
      669,
    ],
    10: [
      779,
      786,
      793,
      800,
      807,
      813,
      820,
      826,
      832,
      838,
      844,
      849,
      855,
      860,
      865,
      870,
      875,
      879,
      884,
      888,
      892,
      896,
      899,
      903,
      906,
      909,
      912,
      914,
      917,
      919,
      921,
      923,
      925,
      926,
      927,
      928,
      929,
      930,
      930,
      930,
      930,
      930,
      929,
      929,
      928,
      927,
      925,
      924,
      922,
      920,
      918,
      915,
      913,
      910,
      907,
      904,
      900,
      897,
      893,
      889,
      884,
      880,
      875,
      870,
      865,
      860,
      854,
      849,
      843,
      837,
      831,
      824,
      817,
      810,
      803,
      796,
      789,
      781,
      773,
      765,
      757,
      749,
      740,
      732,
      723,
      714,
      705,
      695,
      686,
      677,
      667,
    ],
    15: [
      779,
      786,
      793,
      799,
      806,
      812,
      819,
      825,
      831,
      837,
      842,
      848,
      853,
      858,
      863,
      868,
      873,
      877,
      882,
      886,
      890,
      893,
      897,
      900,
      903,
      906,
      909,
      912,
      914,
      916,
      918,
      920,
      921,
      923,
      924,
      925,
      926,
      926,
      926,
      927,
      926,
      926,
      926,
      925,
      924,
      923,
      921,
      920,
      918,
      916,
      914,
      911,
      909,
      906,
      903,
      900,
      896,
      893,
      889,
      885,
      880,
      876,
      871,
      866,
      861,
      856,
      850,
      845,
      839,
      833,
      826,
      820,
      813,
      807,
      800,
      792,
      785,
      778,
      770,
      762,
      754,
      746,
      737,
      729,
      720,
      711,
      702,
      693,
      683,
      674,
      664,
    ],
    20: [
      779,
      786,
      792,
      799,
      805,
      812,
      818,
      824,
      829,
      835,
      841,
      846,
      851,
      856,
      861,
      866,
      870,
      874,
      879,
      882,
      886,
      890,
      893,
      897,
      900,
      902,
      905,
      908,
      910,
      912,
      914,
      915,
      917,
      918,
      919,
      920,
      921,
      921,
      922,
      922,
      921,
      921,
      920,
      920,
      919,
      918,
      916,
      915,
      913,
      911,
      908,
      906,
      903,
      900,
      897,
      894,
      891,
      887,
      883,
      879,
      875,
      870,
      866,
      861,
      856,
      850,
      845,
      839,
      833,
      827,
      821,
      815,
      808,
      801,
      794,
      787,
      780,
      772,
      765,
      757,
      749,
      740,
      732,
      724,
      715,
      706,
      697,
      688,
      679,
      670,
      660,
    ],
    25: [
      779,
      785,
      792,
      798,
      804,
      810,
      816,
      822,
      828,
      833,
      838,
      843,
      848,
      853,
      858,
      862,
      867,
      871,
      875,
      879,
      882,
      886,
      889,
      892,
      895,
      898,
      900,
      903,
      905,
      907,
      908,
      910,
      911,
      912,
      913,
      914,
      915,
      915,
      915,
      915,
      915,
      915,
      914,
      913,
      912,
      911,
      909,
      908,
      906,
      904,
      901,
      899,
      896,
      893,
      890,
      887,
      883,
      880,
      876,
      872,
      867,
      863,
      858,
      853,
      848,
      843,
      838,
      832,
      826,
      820,
      814,
      808,
      801,
      794,
      787,
      780,
      773,
      766,
      758,
      750,
      742,
      734,
      726,
      717,
      709,
      700,
      691,
      682,
      673,
      664,
      655,
    ],
    30: [
      779,
      785,
      791,
      797,
      803,
      809,
      815,
      820,
      825,
      831,
      836,
      841,
      845,
      850,
      854,
      859,
      863,
      867,
      870,
      874,
      877,
      881,
      884,
      887,
      889,
      892,
      894,
      896,
      898,
      900,
      902,
      903,
      904,
      905,
      906,
      907,
      907,
      908,
      908,
      907,
      907,
      907,
      906,
      905,
      904,
      902,
      901,
      899,
      897,
      895,
      893,
      890,
      887,
      885,
      881,
      878,
      875,
      871,
      867,
      863,
      859,
      854,
      849,
      844,
      839,
      834,
      829,
      823,
      817,
      811,
      805,
      799,
      792,
      785,
      779,
      772,
      765,
      757,
      749,
      742,
      734,
      726,
      718,
      709,
      701,
      693,
      684,
      675,
      666,
      657,
      647,
    ],
    35: [
      779,
      785,
      791,
      796,
      802,
      807,
      813,
      818,
      823,
      828,
      832,
      837,
      842,
      846,
      850,
      854,
      858,
      862,
      865,
      868,
      872,
      875,
      877,
      880,
      883,
      885,
      887,
      889,
      891,
      893,
      894,
      895,
      896,
      897,
      898,
      898,
      899,
      899,
      899,
      898,
      898,
      897,
      896,
      895,
      894,
      893,
      891,
      889,
      887,
      885,
      883,
      880,
      877,
      874,
      871,
      868,
      864,
      860,
      856,
      852,
      848,
      844,
      839,
      834,
      829,
      824,
      818,
      812,
      807,
      801,
      795,
      788,
      782,
      775,
      768,
      761,
      754,
      747,
      739,
      732,
      724,
      717,
      708,
      700,
      692,
      683,
      675,
      666,
      657,
      648,
      639,
    ],
    40: [
      779,
      784,
      790,
      795,
      800,
      805,
      810,
      815,
      820,
      824,
      829,
      833,
      837,
      841,
      845,
      849,
      852,
      856,
      859,
      862,
      865,
      868,
      870,
      873,
      875,
      877,
      879,
      881,
      883,
      884,
      885,
      886,
      887,
      888,
      888,
      889,
      889,
      889,
      888,
      888,
      888,
      887,
      886,
      885,
      883,
      882,
      880,
      878,
      876,
      874,
      871,
      868,
      865,
      862,
      859,
      856,
      852,
      848,
      844,
      840,
      836,
      831,
      827,
      822,
      817,
      811,
      806,
      800,
      794,
      789,
      783,
      776,
      770,
      763,
      757,
      750,
      743,
      735,
      728,
      721,
      713,
      705,
      697,
      689,
      681,
      673,
      664,
      656,
      647,
      638,
      629,
    ],
    45: [
      779,
      784,
      789,
      794,
      799,
      803,
      808,
      812,
      817,
      821,
      825,
      829,
      833,
      836,
      840,
      843,
      846,
      849,
      852,
      855,
      858,
      860,
      862,
      865,
      867,
      869,
      870,
      872,
      873,
      874,
      875,
      876,
      877,
      877,
      878,
      878,
      878,
      878,
      877,
      877,
      876,
      875,
      874,
      872,
      871,
      869,
      867,
      865,
      863,
      861,
      858,
      855,
      852,
      849,
      846,
      842,
      839,
      835,
      831,
      827,
      822,
      818,
      813,
      808,
      803,
      798,
      792,
      787,
      781,
      775,
      769,
      763,
      757,
      750,
      743,
      736,
      730,
      722,
      715,
      708,
      700,
      692,
      685,
      676,
      669,
      661,
      652,
      644,
      635,
      627,
      618,
    ],
    50: [
      779,
      784,
      788,
      792,
      797,
      801,
      805,
      809,
      813,
      817,
      820,
      824,
      827,
      831,
      834,
      837,
      840,
      842,
      845,
      847,
      850,
      852,
      854,
      856,
      857,
      859,
      861,
      862,
      863,
      864,
      865,
      865,
      866,
      866,
      866,
      866,
      866,
      865,
      865,
      864,
      863,
      862,
      860,
      859,
      857,
      856,
      854,
      851,
      849,
      846,
      844,
      841,
      838,
      835,
      831,
      828,
      824,
      820,
      816,
      812,
      807,
      803,
      798,
      793,
      788,
      782,
      777,
      772,
      766,
      760,
      754,
      748,
      742,
      735,
      728,
      722,
      715,
      708,
      701,
      693,
      686,
      678,
      670,
      663,
      655,
      647,
      639,
      631,
      622,
      614,
      605,
    ],
    55: [
      779,
      783,
      787,
      791,
      795,
      799,
      802,
      806,
      809,
      812,
      816,
      819,
      822,
      824,
      827,
      830,
      832,
      835,
      837,
      839,
      841,
      843,
      845,
      846,
      848,
      849,
      850,
      851,
      852,
      852,
      853,
      853,
      853,
      853,
      853,
      853,
      852,
      852,
      851,
      850,
      849,
      848,
      846,
      845,
      843,
      841,
      839,
      836,
      834,
      831,
      828,
      825,
      822,
      819,
      815,
      812,
      808,
      804,
      800,
      796,
      791,
      786,
      782,
      777,
      772,
      766,
      761,
      755,
      750,
      744,
      738,
      732,
      725,
      719,
      712,
      706,
      699,
      692,
      685,
      678,
      670,
      663,
      655,
      648,
      640,
      632,
      624,
      616,
      608,
      600,
      591,
    ],
    60: [
      779,
      782,
      786,
      789,
      793,
      796,
      799,
      802,
      805,
      808,
      811,
      813,
      816,
      818,
      820,
      823,
      825,
      827,
      828,
      830,
      832,
      833,
      834,
      836,
      837,
      838,
      839,
      839,
      840,
      840,
      840,
      840,
      840,
      840,
      840,
      839,
      838,
      838,
      837,
      835,
      834,
      833,
      831,
      829,
      827,
      825,
      823,
      820,
      818,
      815,
      812,
      809,
      806,
      802,
      799,
      795,
      791,
      787,
      783,
      778,
      774,
      769,
      764,
      759,
      754,
      749,
      743,
      738,
      733,
      727,
      720,
      714,
      708,
      702,
      696,
      689,
      682,
      675,
      668,
      661,
      654,
      647,
      639,
      632,
      624,
      616,
      609,
      600,
      592,
      585,
      576,
    ],
    65: [
      779,
      782,
      785,
      788,
      790,
      793,
      796,
      798,
      801,
      803,
      805,
      807,
      809,
      811,
      813,
      815,
      817,
      818,
      819,
      821,
      822,
      823,
      824,
      825,
      825,
      826,
      826,
      827,
      827,
      827,
      827,
      827,
      827,
      826,
      825,
      824,
      824,
      823,
      821,
      820,
      818,
      817,
      815,
      813,
      811,
      808,
      806,
      803,
      800,
      798,
      794,
      791,
      788,
      784,
      781,
      777,
      773,
      769,
      764,
      760,
      756,
      751,
      746,
      741,
      736,
      731,
      725,
      720,
      714,
      708,
      702,
      696,
      690,
      684,
      678,
      671,
      664,
      657,
      651,
      644,
      636,
      629,
      622,
      615,
      607,
      599,
      592,
      584,
      576,
      568,
      560,
    ],
    70: [
      779,
      781,
      784,
      786,
      788,
      790,
      792,
      794,
      796,
      798,
      800,
      801,
      803,
      804,
      805,
      807,
      808,
      809,
      810,
      811,
      811,
      812,
      813,
      813,
      814,
      814,
      814,
      814,
      814,
      813,
      813,
      813,
      812,
      811,
      810,
      809,
      808,
      807,
      805,
      804,
      802,
      800,
      798,
      796,
      793,
      791,
      788,
      785,
      782,
      779,
      776,
      773,
      769,
      766,
      762,
      758,
      754,
      750,
      745,
      741,
      736,
      732,
      727,
      722,
      716,
      711,
      706,
      700,
      695,
      689,
      683,
      677,
      671,
      665,
      659,
      652,
      645,
      638,
      632,
      625,
      618,
      611,
      604,
      597,
      589,
      582,
      574,
      567,
      559,
      551,
      544,
    ],
    75: [
      779,
      781,
      782,
      784,
      786,
      787,
      789,
      790,
      791,
      793,
      794,
      795,
      796,
      797,
      798,
      798,
      799,
      800,
      800,
      800,
      801,
      801,
      801,
      801,
      801,
      801,
      801,
      800,
      800,
      799,
      799,
      798,
      797,
      796,
      794,
      793,
      792,
      790,
      788,
      786,
      784,
      782,
      780,
      778,
      775,
      773,
      770,
      767,
      764,
      760,
      757,
      754,
      750,
      746,
      742,
      738,
      734,
      730,
      726,
      721,
      716,
      711,
      707,
      702,
      696,
      691,
      686,
      680,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      632,
      626,
      619,
      612,
      606,
      599,
      592,
      585,
      578,
      571,
      564,
      556,
      549,
      541,
      534,
      526,
    ],
    80: [
      779,
      780,
      781,
      782,
      783,
      784,
      785,
      786,
      787,
      787,
      788,
      788,
      789,
      789,
      789,
      790,
      790,
      790,
      790,
      790,
      790,
      789,
      789,
      789,
      788,
      788,
      787,
      786,
      785,
      785,
      784,
      782,
      781,
      780,
      778,
      776,
      775,
      773,
      771,
      769,
      767,
      764,
      762,
      759,
      756,
      754,
      751,
      748,
      744,
      741,
      737,
      734,
      730,
      726,
      722,
      718,
      714,
      710,
      705,
      700,
      696,
      691,
      686,
      681,
      676,
      670,
      665,
      659,
      654,
      648,
      642,
      636,
      630,
      624,
      618,
      612,
      605,
      599,
      592,
      586,
      579,
      573,
      565,
      558,
      551,
      545,
      537,
      530,
      522,
      516,
      508,
    ],
    85: [
      779,
      779,
      780,
      780,
      781,
      781,
      781,
      781,
      782,
      782,
      782,
      781,
      781,
      781,
      781,
      781,
      780,
      780,
      779,
      779,
      778,
      778,
      777,
      776,
      775,
      774,
      773,
      772,
      771,
      769,
      768,
      767,
      765,
      763,
      761,
      759,
      758,
      755,
      753,
      751,
      748,
      746,
      743,
      740,
      737,
      734,
      731,
      728,
      724,
      721,
      717,
      713,
      710,
      706,
      701,
      697,
      693,
      688,
      684,
      679,
      674,
      670,
      665,
      659,
      654,
      649,
      644,
      638,
      633,
      627,
      621,
      615,
      609,
      603,
      597,
      591,
      584,
      578,
      572,
      565,
      558,
      552,
      545,
      538,
      531,
      525,
      518,
      511,
      503,
      496,
      489,
    ],
    90: [
      779,
      779,
      779,
      778,
      778,
      778,
      778,
      777,
      777,
      776,
      775,
      775,
      774,
      773,
      772,
      772,
      771,
      770,
      769,
      768,
      767,
      765,
      764,
      763,
      762,
      760,
      759,
      757,
      756,
      754,
      752,
      750,
      748,
      746,
      744,
      742,
      740,
      737,
      735,
      732,
      729,
      727,
      724,
      721,
      718,
      714,
      711,
      707,
      704,
      700,
      696,
      693,
      688,
      684,
      680,
      676,
      671,
      667,
      662,
      658,
      653,
      648,
      643,
      638,
      632,
      627,
      622,
      616,
      611,
      605,
      599,
      594,
      588,
      582,
      575,
      570,
      563,
      557,
      551,
      544,
      538,
      531,
      525,
      518,
      511,
      504,
      498,
      491,
      483,
      477,
      470,
    ],
    95: [
      779,
      778,
      777,
      777,
      776,
      775,
      774,
      773,
      771,
      770,
      769,
      768,
      766,
      765,
      764,
      762,
      761,
      759,
      758,
      756,
      755,
      753,
      751,
      750,
      748,
      746,
      744,
      742,
      740,
      738,
      736,
      734,
      732,
      729,
      727,
      724,
      722,
      719,
      716,
      713,
      710,
      707,
      704,
      701,
      697,
      694,
      690,
      687,
      683,
      679,
      675,
      671,
      667,
      663,
      659,
      654,
      650,
      645,
      640,
      636,
      631,
      626,
      621,
      615,
      610,
      605,
      600,
      594,
      589,
      583,
      577,
      571,
      566,
      560,
      554,
      548,
      542,
      535,
      529,
      523,
      517,
      510,
      504,
      497,
      491,
      484,
      477,
      471,
      464,
      457,
      451,
    ],
    100: [
      779,
      778,
      776,
      775,
      773,
      772,
      770,
      768,
      766,
      765,
      763,
      761,
      759,
      757,
      755,
      753,
      751,
      749,
      747,
      745,
      743,
      741,
      738,
      736,
      734,
      732,
      729,
      727,
      725,
      722,
      720,
      717,
      715,
      712,
      709,
      706,
      703,
      700,
      697,
      694,
      691,
      688,
      684,
      681,
      677,
      674,
      670,
      666,
      662,
      658,
      654,
      650,
      646,
      641,
      637,
      632,
      628,
      623,
      618,
      613,
      609,
      603,
      598,
      593,
      588,
      583,
      577,
      572,
      566,
      561,
      555,
      549,
      544,
      538,
      532,
      526,
      520,
      514,
      508,
      501,
      495,
      489,
      483,
      476,
      470,
      464,
      457,
      450,
      444,
      438,
      431,
    ],
    105: [
      779,
      777,
      775,
      773,
      771,
      768,
      766,
      764,
      761,
      759,
      757,
      754,
      752,
      749,
      747,
      744,
      741,
      739,
      736,
      733,
      731,
      728,
      726,
      723,
      720,
      717,
      715,
      712,
      709,
      706,
      703,
      700,
      698,
      694,
      691,
      688,
      685,
      682,
      678,
      675,
      671,
      668,
      664,
      661,
      657,
      653,
      649,
      645,
      641,
      637,
      633,
      628,
      624,
      619,
      615,
      610,
      606,
      601,
      596,
      591,
      586,
      581,
      576,
      571,
      566,
      560,
      555,
      549,
      544,
      538,
      533,
      527,
      521,
      516,
      510,
      504,
      498,
      492,
      486,
      480,
      474,
      468,
      462,
      455,
      449,
      443,
      437,
      430,
      424,
      418,
      412,
    ],
    110: [
      779,
      776,
      774,
      771,
      768,
      765,
      763,
      760,
      757,
      754,
      750,
      747,
      744,
      741,
      738,
      735,
      732,
      729,
      725,
      722,
      719,
      716,
      713,
      710,
      706,
      703,
      700,
      697,
      694,
      690,
      687,
      684,
      680,
      677,
      674,
      670,
      667,
      663,
      659,
      656,
      652,
      648,
      644,
      641,
      636,
      632,
      628,
      624,
      620,
      616,
      611,
      607,
      602,
      598,
      593,
      588,
      584,
      579,
      574,
      569,
      564,
      559,
      554,
      548,
      543,
      538,
      532,
      527,
      522,
      516,
      511,
      505,
      499,
      494,
      488,
      482,
      477,
      470,
      465,
      459,
      453,
      446,
      441,
      435,
      429,
      423,
      416,
      410,
      404,
      398,
      392,
    ],
    115: [
      779,
      776,
      772,
      769,
      766,
      762,
      759,
      755,
      752,
      748,
      744,
      741,
      737,
      733,
      730,
      726,
      722,
      719,
      715,
      711,
      707,
      704,
      700,
      696,
      693,
      689,
      686,
      682,
      678,
      675,
      671,
      667,
      664,
      660,
      656,
      652,
      648,
      644,
      640,
      637,
      633,
      629,
      625,
      620,
      616,
      612,
      608,
      603,
      599,
      595,
      590,
      586,
      581,
      576,
      571,
      567,
      562,
      557,
      552,
      547,
      542,
      537,
      532,
      526,
      521,
      516,
      510,
      505,
      500,
      494,
      489,
      483,
      478,
      472,
      466,
      461,
      455,
      449,
      443,
      438,
      432,
      426,
      420,
      414,
      408,
      403,
      396,
      391,
      385,
      379,
      373,
    ],
    120: [
      779,
      775,
      771,
      767,
      764,
      759,
      755,
      751,
      747,
      743,
      739,
      735,
      730,
      726,
      722,
      718,
      713,
      709,
      705,
      701,
      696,
      692,
      688,
      684,
      680,
      676,
      671,
      667,
      663,
      659,
      655,
      651,
      647,
      643,
      639,
      635,
      630,
      626,
      622,
      618,
      614,
      609,
      605,
      601,
      596,
      592,
      587,
      583,
      578,
      574,
      569,
      565,
      560,
      555,
      550,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      505,
      500,
      494,
      489,
      484,
      478,
      473,
      467,
      462,
      456,
      451,
      445,
      440,
      434,
      429,
      423,
      417,
      412,
      406,
      400,
      394,
      389,
      383,
      377,
      372,
      366,
      360,
      354,
    ],
    125: [
      779,
      775,
      770,
      766,
      761,
      757,
      752,
      748,
      743,
      738,
      733,
      728,
      724,
      719,
      714,
      709,
      705,
      700,
      695,
      690,
      685,
      681,
      676,
      671,
      667,
      662,
      658,
      653,
      649,
      644,
      640,
      635,
      631,
      626,
      622,
      617,
      613,
      609,
      604,
      600,
      595,
      591,
      586,
      582,
      577,
      572,
      568,
      563,
      558,
      554,
      549,
      544,
      539,
      534,
      529,
      524,
      519,
      514,
      509,
      504,
      499,
      494,
      489,
      484,
      479,
      473,
      468,
      463,
      457,
      452,
      447,
      441,
      436,
      431,
      425,
      419,
      414,
      408,
      403,
      397,
      392,
      386,
      381,
      375,
      369,
      364,
      358,
      353,
      348,
      342,
      336,
    ],
    130: [
      779,
      774,
      769,
      764,
      759,
      754,
      749,
      744,
      739,
      733,
      728,
      723,
      717,
      712,
      707,
      702,
      696,
      691,
      686,
      680,
      675,
      670,
      665,
      660,
      655,
      650,
      645,
      640,
      635,
      630,
      625,
      620,
      615,
      610,
      606,
      601,
      596,
      591,
      586,
      582,
      577,
      572,
      568,
      563,
      558,
      553,
      548,
      544,
      539,
      534,
      529,
      524,
      519,
      514,
      509,
      504,
      499,
      494,
      489,
      484,
      479,
      474,
      469,
      463,
      458,
      453,
      448,
      442,
      437,
      432,
      426,
      421,
      416,
      410,
      405,
      400,
      394,
      389,
      384,
      378,
      373,
      367,
      362,
      357,
      351,
      346,
      340,
      335,
      330,
      324,
      319,
    ],
    135: [
      779,
      774,
      768,
      763,
      757,
      752,
      746,
      740,
      735,
      729,
      723,
      717,
      712,
      706,
      700,
      694,
      689,
      683,
      677,
      671,
      666,
      660,
      655,
      649,
      643,
      638,
      633,
      627,
      622,
      616,
      611,
      606,
      600,
      595,
      590,
      585,
      580,
      575,
      570,
      565,
      560,
      555,
      550,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      505,
      500,
      495,
      490,
      485,
      480,
      475,
      470,
      464,
      459,
      454,
      449,
      444,
      439,
      433,
      428,
      423,
      418,
      412,
      407,
      402,
      397,
      392,
      386,
      381,
      376,
      370,
      365,
      360,
      355,
      349,
      344,
      339,
      334,
      329,
      323,
      318,
      313,
      308,
      303,
    ],
    140: [
      779,
      773,
      767,
      762,
      756,
      750,
      744,
      737,
      731,
      725,
      719,
      713,
      706,
      700,
      694,
      688,
      682,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      633,
      627,
      622,
      616,
      610,
      604,
      598,
      592,
      587,
      581,
      576,
      570,
      564,
      559,
      554,
      548,
      543,
      538,
      533,
      527,
      522,
      517,
      512,
      507,
      502,
      497,
      492,
      487,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      446,
      440,
      435,
      430,
      425,
      420,
      415,
      410,
      404,
      399,
      394,
      389,
      384,
      379,
      373,
      368,
      363,
      358,
      353,
      348,
      343,
      338,
      332,
      327,
      322,
      318,
      312,
      307,
      302,
      297,
      292,
      288,
    ],
    145: [
      779,
      773,
      767,
      760,
      754,
      748,
      741,
      735,
      728,
      721,
      715,
      708,
      702,
      695,
      688,
      682,
      675,
      669,
      662,
      656,
      650,
      643,
      637,
      631,
      624,
      618,
      612,
      606,
      599,
      593,
      587,
      581,
      575,
      569,
      563,
      557,
      551,
      545,
      539,
      533,
      528,
      522,
      517,
      511,
      506,
      500,
      495,
      490,
      485,
      479,
      474,
      469,
      464,
      459,
      453,
      448,
      443,
      438,
      433,
      428,
      423,
      418,
      412,
      407,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      367,
      361,
      356,
      352,
      347,
      341,
      336,
      332,
      327,
      322,
      317,
      312,
      307,
      302,
      297,
      292,
      288,
      283,
      278,
      273,
    ],
    150: [
      779,
      772,
      766,
      759,
      753,
      746,
      739,
      732,
      725,
      718,
      711,
      704,
      697,
      690,
      683,
      677,
      670,
      663,
      657,
      650,
      643,
      637,
      630,
      623,
      617,
      610,
      603,
      597,
      590,
      584,
      577,
      571,
      564,
      558,
      551,
      545,
      539,
      533,
      526,
      520,
      514,
      508,
      502,
      497,
      491,
      485,
      480,
      474,
      469,
      463,
      458,
      453,
      447,
      442,
      437,
      432,
      426,
      421,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      381,
      375,
      371,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      312,
      307,
      302,
      297,
      293,
      288,
      283,
      279,
      274,
      269,
      265,
      260,
    ],
    155: [
      779,
      772,
      765,
      758,
      751,
      744,
      737,
      730,
      723,
      716,
      708,
      701,
      694,
      686,
      679,
      672,
      665,
      658,
      652,
      645,
      638,
      631,
      624,
      617,
      610,
      603,
      596,
      589,
      582,
      576,
      569,
      562,
      555,
      549,
      542,
      535,
      529,
      522,
      516,
      509,
      503,
      497,
      491,
      484,
      478,
      472,
      466,
      460,
      455,
      449,
      443,
      438,
      433,
      427,
      422,
      417,
      411,
      406,
      401,
      396,
      391,
      386,
      381,
      376,
      371,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      332,
      327,
      322,
      317,
      312,
      308,
      303,
      298,
      294,
      289,
      284,
      280,
      275,
      271,
      266,
      262,
      257,
      253,
      248,
    ],
    160: [
      779,
      772,
      765,
      758,
      750,
      743,
      736,
      728,
      721,
      713,
      706,
      698,
      691,
      683,
      676,
      669,
      662,
      655,
      648,
      640,
      633,
      626,
      619,
      612,
      605,
      597,
      590,
      583,
      576,
      569,
      562,
      555,
      548,
      541,
      534,
      527,
      520,
      514,
      507,
      500,
      494,
      487,
      481,
      474,
      468,
      462,
      455,
      449,
      443,
      437,
      431,
      426,
      420,
      415,
      409,
      404,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      343,
      338,
      334,
      329,
      324,
      319,
      315,
      310,
      305,
      301,
      296,
      291,
      287,
      282,
      278,
      273,
      269,
      264,
      260,
      256,
      251,
      247,
      243,
      239,
    ],
    165: [
      779,
      772,
      764,
      757,
      750,
      742,
      735,
      727,
      719,
      712,
      704,
      696,
      688,
      681,
      673,
      666,
      659,
      652,
      644,
      637,
      630,
      622,
      615,
      607,
      600,
      593,
      586,
      578,
      571,
      564,
      557,
      550,
      542,
      535,
      528,
      521,
      514,
      507,
      500,
      493,
      486,
      480,
      473,
      467,
      460,
      453,
      447,
      441,
      435,
      428,
      422,
      416,
      410,
      405,
      399,
      394,
      388,
      383,
      377,
      372,
      367,
      362,
      357,
      352,
      347,
      342,
      337,
      332,
      328,
      323,
      318,
      314,
      309,
      304,
      300,
      295,
      291,
      286,
      282,
      277,
      273,
      268,
      264,
      260,
      256,
      251,
      247,
      243,
      239,
      235,
      231,
    ],
    170: [
      779,
      772,
      764,
      757,
      749,
      741,
      734,
      726,
      718,
      710,
      702,
      695,
      687,
      679,
      672,
      664,
      657,
      649,
      642,
      635,
      627,
      620,
      612,
      604,
      597,
      590,
      582,
      575,
      568,
      560,
      553,
      546,
      538,
      531,
      524,
      517,
      510,
      503,
      496,
      489,
      482,
      475,
      468,
      461,
      454,
      448,
      441,
      435,
      428,
      422,
      416,
      410,
      404,
      398,
      392,
      386,
      381,
      375,
      370,
      364,
      359,
      354,
      349,
      344,
      339,
      334,
      329,
      325,
      320,
      315,
      311,
      306,
      302,
      297,
      293,
      288,
      283,
      279,
      275,
      270,
      266,
      262,
      257,
      253,
      249,
      245,
      241,
      237,
      233,
      228,
      225,
    ],
    175: [
      779,
      772,
      764,
      756,
      749,
      741,
      733,
      725,
      717,
      709,
      701,
      694,
      686,
      678,
      671,
      663,
      656,
      648,
      641,
      633,
      626,
      618,
      610,
      603,
      595,
      588,
      580,
      573,
      566,
      559,
      551,
      543,
      536,
      528,
      521,
      514,
      507,
      500,
      493,
      486,
      479,
      472,
      465,
      458,
      451,
      444,
      437,
      431,
      424,
      418,
      412,
      406,
      399,
      393,
      388,
      382,
      376,
      371,
      365,
      360,
      355,
      349,
      344,
      339,
      334,
      329,
      325,
      320,
      315,
      311,
      306,
      301,
      297,
      292,
      288,
      284,
      279,
      275,
      271,
      266,
      262,
      258,
      253,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
    ],
  },
  '8S': {
    0: [
      722,
      728,
      735,
      741,
      748,
      754,
      760,
      766,
      772,
      778,
      783,
      789,
      794,
      799,
      804,
      808,
      813,
      817,
      822,
      826,
      829,
      833,
      837,
      840,
      843,
      846,
      849,
      852,
      854,
      856,
      858,
      860,
      862,
      863,
      865,
      866,
      867,
      867,
      868,
      868,
      868,
      868,
      868,
      867,
      867,
      866,
      865,
      864,
      862,
      860,
      859,
      856,
      854,
      852,
      849,
      846,
      843,
      840,
      837,
      833,
      829,
      825,
      821,
      816,
      812,
      807,
      802,
      797,
      792,
      786,
      781,
      775,
      769,
      762,
      756,
      750,
      743,
      736,
      729,
      721,
      714,
      706,
      699,
      691,
      683,
      675,
      666,
      658,
      649,
      640,
      631,
    ],
    5: [
      722,
      728,
      735,
      741,
      748,
      754,
      760,
      766,
      772,
      777,
      783,
      788,
      793,
      798,
      803,
      808,
      813,
      817,
      821,
      825,
      829,
      833,
      836,
      840,
      843,
      846,
      848,
      851,
      854,
      856,
      858,
      860,
      861,
      863,
      864,
      865,
      866,
      867,
      867,
      867,
      868,
      868,
      867,
      867,
      866,
      865,
      864,
      863,
      861,
      860,
      858,
      856,
      853,
      851,
      848,
      846,
      843,
      839,
      836,
      832,
      828,
      824,
      820,
      816,
      811,
      806,
      802,
      796,
      791,
      786,
      780,
      774,
      768,
      762,
      755,
      749,
      742,
      735,
      728,
      721,
      713,
      706,
      698,
      690,
      682,
      674,
      666,
      657,
      649,
      640,
      631,
    ],
    10: [
      722,
      728,
      735,
      741,
      747,
      753,
      760,
      765,
      771,
      777,
      782,
      787,
      793,
      798,
      802,
      807,
      812,
      816,
      820,
      824,
      828,
      831,
      835,
      838,
      841,
      844,
      847,
      849,
      852,
      854,
      856,
      858,
      859,
      861,
      862,
      863,
      864,
      865,
      865,
      865,
      866,
      865,
      865,
      865,
      864,
      863,
      862,
      861,
      859,
      857,
      856,
      853,
      851,
      849,
      846,
      843,
      840,
      837,
      833,
      830,
      826,
      822,
      818,
      814,
      809,
      804,
      799,
      794,
      789,
      783,
      778,
      772,
      766,
      759,
      753,
      746,
      740,
      733,
      726,
      719,
      711,
      704,
      696,
      688,
      680,
      672,
      664,
      655,
      647,
      638,
      629,
    ],
    15: [
      722,
      728,
      734,
      741,
      747,
      753,
      759,
      765,
      770,
      776,
      781,
      786,
      791,
      796,
      801,
      805,
      810,
      814,
      818,
      822,
      826,
      829,
      833,
      836,
      839,
      842,
      844,
      847,
      849,
      851,
      853,
      855,
      856,
      858,
      859,
      860,
      861,
      861,
      862,
      862,
      862,
      862,
      862,
      861,
      860,
      859,
      858,
      857,
      855,
      854,
      852,
      850,
      847,
      845,
      842,
      839,
      836,
      833,
      830,
      826,
      822,
      818,
      814,
      810,
      805,
      800,
      795,
      790,
      785,
      779,
      774,
      768,
      762,
      756,
      749,
      743,
      736,
      729,
      722,
      715,
      708,
      700,
      693,
      685,
      677,
      669,
      661,
      652,
      644,
      635,
      626,
    ],
    20: [
      722,
      728,
      734,
      740,
      746,
      752,
      758,
      763,
      769,
      774,
      779,
      784,
      789,
      794,
      799,
      803,
      807,
      811,
      815,
      819,
      823,
      826,
      829,
      832,
      835,
      838,
      841,
      843,
      845,
      847,
      849,
      851,
      852,
      854,
      855,
      856,
      856,
      857,
      857,
      857,
      857,
      857,
      857,
      856,
      855,
      854,
      853,
      852,
      850,
      849,
      847,
      844,
      842,
      840,
      837,
      834,
      831,
      828,
      824,
      820,
      817,
      813,
      808,
      804,
      799,
      795,
      790,
      785,
      779,
      774,
      768,
      763,
      757,
      750,
      744,
      738,
      731,
      724,
      717,
      710,
      703,
      695,
      688,
      680,
      672,
      664,
      656,
      647,
      639,
      630,
      622,
    ],
    25: [
      722,
      728,
      734,
      739,
      745,
      751,
      756,
      762,
      767,
      772,
      777,
      782,
      787,
      791,
      796,
      800,
      804,
      808,
      812,
      815,
      819,
      822,
      825,
      828,
      831,
      834,
      836,
      838,
      840,
      842,
      844,
      846,
      847,
      848,
      849,
      850,
      851,
      851,
      851,
      851,
      851,
      851,
      850,
      850,
      849,
      848,
      847,
      845,
      844,
      842,
      840,
      838,
      835,
      833,
      830,
      827,
      824,
      821,
      817,
      813,
      810,
      806,
      801,
      797,
      792,
      788,
      783,
      778,
      772,
      767,
      761,
      755,
      750,
      744,
      737,
      731,
      724,
      717,
      711,
      704,
      696,
      689,
      681,
      674,
      666,
      658,
      650,
      642,
      633,
      625,
      616,
    ],
    30: [
      722,
      727,
      733,
      739,
      744,
      750,
      755,
      760,
      765,
      770,
      775,
      779,
      784,
      788,
      792,
      796,
      800,
      804,
      807,
      811,
      814,
      817,
      820,
      823,
      826,
      828,
      830,
      833,
      834,
      836,
      838,
      839,
      840,
      841,
      842,
      843,
      844,
      844,
      844,
      844,
      844,
      843,
      843,
      842,
      841,
      840,
      839,
      837,
      836,
      834,
      832,
      829,
      827,
      824,
      822,
      819,
      815,
      812,
      808,
      805,
      801,
      797,
      793,
      788,
      784,
      779,
      774,
      769,
      764,
      758,
      753,
      747,
      741,
      735,
      729,
      722,
      716,
      709,
      702,
      695,
      688,
      681,
      673,
      666,
      658,
      650,
      642,
      634,
      626,
      618,
      609,
    ],
    35: [
      722,
      727,
      732,
      738,
      743,
      748,
      753,
      758,
      763,
      767,
      772,
      776,
      780,
      784,
      788,
      792,
      796,
      799,
      802,
      806,
      809,
      812,
      814,
      817,
      819,
      822,
      824,
      826,
      827,
      829,
      831,
      832,
      833,
      834,
      834,
      835,
      835,
      836,
      836,
      835,
      835,
      835,
      834,
      833,
      832,
      831,
      829,
      828,
      826,
      824,
      822,
      820,
      817,
      814,
      812,
      809,
      805,
      802,
      798,
      795,
      791,
      787,
      783,
      778,
      774,
      769,
      764,
      759,
      754,
      748,
      743,
      737,
      731,
      725,
      719,
      713,
      706,
      699,
      693,
      685,
      678,
      671,
      664,
      657,
      649,
      641,
      633,
      625,
      618,
      609,
      601,
    ],
    40: [
      722,
      727,
      732,
      737,
      742,
      746,
      751,
      755,
      760,
      764,
      768,
      772,
      776,
      780,
      784,
      787,
      791,
      794,
      797,
      800,
      803,
      805,
      808,
      810,
      812,
      814,
      816,
      818,
      820,
      821,
      822,
      823,
      824,
      825,
      825,
      826,
      826,
      826,
      826,
      826,
      825,
      825,
      824,
      823,
      822,
      820,
      819,
      817,
      815,
      813,
      811,
      809,
      806,
      803,
      800,
      797,
      794,
      791,
      787,
      783,
      779,
      775,
      771,
      767,
      762,
      757,
      752,
      747,
      742,
      736,
      731,
      725,
      720,
      714,
      707,
      701,
      695,
      688,
      681,
      675,
      668,
      661,
      653,
      646,
      638,
      631,
      623,
      615,
      607,
      599,
      591,
    ],
    45: [
      722,
      726,
      731,
      735,
      740,
      744,
      749,
      753,
      757,
      761,
      765,
      768,
      772,
      775,
      779,
      782,
      785,
      788,
      791,
      793,
      796,
      798,
      800,
      802,
      805,
      806,
      808,
      809,
      811,
      812,
      813,
      814,
      814,
      815,
      815,
      816,
      816,
      816,
      815,
      815,
      814,
      813,
      812,
      811,
      810,
      809,
      807,
      805,
      803,
      801,
      799,
      796,
      794,
      791,
      788,
      785,
      781,
      778,
      774,
      770,
      766,
      762,
      758,
      754,
      749,
      744,
      739,
      734,
      729,
      723,
      718,
      712,
      707,
      701,
      694,
      688,
      682,
      675,
      669,
      662,
      655,
      648,
      641,
      634,
      626,
      619,
      612,
      604,
      596,
      588,
      580,
    ],
    50: [
      722,
      726,
      730,
      734,
      738,
      742,
      746,
      750,
      753,
      757,
      760,
      764,
      767,
      770,
      773,
      776,
      779,
      781,
      784,
      786,
      788,
      790,
      792,
      794,
      796,
      797,
      799,
      800,
      801,
      802,
      803,
      803,
      804,
      804,
      804,
      804,
      804,
      804,
      804,
      803,
      802,
      801,
      800,
      799,
      797,
      796,
      794,
      792,
      790,
      788,
      785,
      783,
      780,
      777,
      774,
      771,
      767,
      764,
      760,
      756,
      752,
      748,
      744,
      739,
      735,
      730,
      725,
      720,
      715,
      709,
      704,
      698,
      692,
      687,
      680,
      674,
      668,
      662,
      655,
      648,
      641,
      634,
      628,
      620,
      613,
      606,
      599,
      591,
      583,
      575,
      568,
    ],
    55: [
      722,
      725,
      729,
      733,
      736,
      740,
      743,
      747,
      750,
      753,
      756,
      759,
      762,
      764,
      767,
      769,
      772,
      774,
      776,
      778,
      780,
      782,
      784,
      785,
      787,
      788,
      789,
      790,
      791,
      791,
      792,
      792,
      793,
      793,
      792,
      792,
      792,
      791,
      791,
      790,
      789,
      788,
      787,
      785,
      784,
      782,
      780,
      778,
      776,
      773,
      771,
      768,
      765,
      762,
      759,
      756,
      752,
      749,
      745,
      741,
      737,
      733,
      728,
      724,
      719,
      714,
      709,
      704,
      699,
      694,
      688,
      683,
      677,
      671,
      665,
      659,
      653,
      646,
      640,
      633,
      627,
      620,
      613,
      606,
      599,
      591,
      584,
      577,
      569,
      562,
      554,
    ],
    60: [
      722,
      725,
      728,
      731,
      734,
      737,
      740,
      743,
      746,
      749,
      751,
      754,
      756,
      758,
      761,
      763,
      765,
      767,
      768,
      770,
      771,
      773,
      774,
      775,
      776,
      777,
      778,
      779,
      779,
      780,
      780,
      780,
      780,
      780,
      780,
      779,
      779,
      778,
      777,
      776,
      775,
      774,
      772,
      771,
      769,
      767,
      765,
      763,
      760,
      758,
      755,
      752,
      749,
      746,
      743,
      740,
      736,
      732,
      729,
      725,
      720,
      716,
      712,
      707,
      703,
      698,
      693,
      688,
      683,
      678,
      672,
      666,
      660,
      655,
      649,
      643,
      636,
      630,
      624,
      618,
      611,
      604,
      597,
      591,
      583,
      576,
      569,
      562,
      554,
      547,
      540,
    ],
    65: [
      722,
      724,
      727,
      730,
      732,
      735,
      737,
      740,
      742,
      744,
      746,
      748,
      750,
      752,
      754,
      756,
      757,
      758,
      760,
      761,
      762,
      763,
      764,
      765,
      766,
      767,
      767,
      767,
      768,
      768,
      768,
      768,
      767,
      767,
      766,
      766,
      765,
      764,
      763,
      762,
      760,
      759,
      757,
      755,
      753,
      751,
      749,
      747,
      744,
      742,
      739,
      736,
      733,
      730,
      726,
      723,
      719,
      715,
      711,
      707,
      703,
      699,
      695,
      690,
      685,
      680,
      676,
      670,
      665,
      660,
      655,
      649,
      643,
      638,
      632,
      626,
      619,
      613,
      607,
      601,
      594,
      587,
      581,
      574,
      567,
      560,
      553,
      546,
      539,
      532,
      524,
    ],
    70: [
      722,
      724,
      726,
      728,
      730,
      732,
      734,
      736,
      738,
      739,
      741,
      742,
      744,
      745,
      747,
      748,
      749,
      750,
      751,
      752,
      753,
      753,
      754,
      754,
      755,
      755,
      755,
      755,
      755,
      755,
      755,
      754,
      754,
      753,
      752,
      751,
      750,
      749,
      748,
      746,
      745,
      743,
      741,
      739,
      737,
      735,
      732,
      730,
      727,
      724,
      722,
      719,
      715,
      712,
      709,
      705,
      701,
      697,
      693,
      689,
      685,
      681,
      676,
      672,
      667,
      662,
      657,
      652,
      647,
      642,
      636,
      631,
      625,
      619,
      614,
      608,
      602,
      595,
      589,
      583,
      577,
      570,
      563,
      557,
      550,
      543,
      537,
      529,
      523,
      515,
      508,
    ],
    75: [
      722,
      723,
      725,
      726,
      728,
      729,
      731,
      732,
      733,
      734,
      736,
      737,
      737,
      738,
      739,
      740,
      741,
      741,
      742,
      742,
      743,
      743,
      743,
      743,
      743,
      743,
      743,
      743,
      742,
      742,
      741,
      740,
      740,
      739,
      738,
      736,
      735,
      734,
      732,
      731,
      729,
      727,
      725,
      722,
      720,
      718,
      715,
      713,
      710,
      707,
      704,
      701,
      697,
      694,
      690,
      687,
      683,
      679,
      675,
      671,
      666,
      662,
      657,
      653,
      648,
      643,
      638,
      633,
      628,
      623,
      617,
      612,
      606,
      600,
      595,
      589,
      583,
      577,
      571,
      565,
      559,
      553,
      545,
      539,
      533,
      526,
      519,
      512,
      506,
      499,
      491,
    ],
    80: [
      722,
      723,
      724,
      725,
      726,
      727,
      727,
      728,
      729,
      729,
      730,
      730,
      731,
      731,
      732,
      732,
      732,
      732,
      732,
      732,
      732,
      732,
      732,
      732,
      731,
      731,
      730,
      730,
      729,
      728,
      727,
      726,
      725,
      724,
      722,
      721,
      719,
      718,
      716,
      714,
      712,
      710,
      708,
      705,
      703,
      700,
      697,
      695,
      692,
      689,
      685,
      682,
      679,
      675,
      671,
      668,
      664,
      660,
      656,
      651,
      647,
      643,
      638,
      633,
      629,
      624,
      619,
      614,
      609,
      603,
      598,
      593,
      587,
      582,
      576,
      570,
      564,
      558,
      552,
      546,
      540,
      534,
      527,
      520,
      514,
      508,
      502,
      494,
      488,
      481,
      475,
    ],
    85: [
      722,
      722,
      723,
      723,
      723,
      724,
      724,
      724,
      724,
      724,
      724,
      724,
      724,
      724,
      724,
      724,
      723,
      723,
      723,
      722,
      722,
      721,
      721,
      720,
      719,
      718,
      717,
      716,
      715,
      714,
      713,
      711,
      710,
      708,
      707,
      705,
      703,
      701,
      699,
      697,
      695,
      693,
      690,
      687,
      685,
      682,
      679,
      676,
      673,
      670,
      666,
      663,
      659,
      656,
      652,
      648,
      644,
      640,
      636,
      632,
      627,
      623,
      618,
      614,
      609,
      604,
      599,
      594,
      589,
      583,
      578,
      573,
      567,
      562,
      556,
      550,
      544,
      539,
      533,
      526,
      521,
      515,
      508,
      501,
      495,
      489,
      483,
      477,
      469,
      463,
      457,
    ],
    90: [
      722,
      721,
      721,
      721,
      721,
      721,
      720,
      720,
      719,
      719,
      718,
      718,
      717,
      717,
      716,
      715,
      714,
      714,
      713,
      712,
      711,
      710,
      709,
      708,
      706,
      705,
      704,
      703,
      701,
      700,
      698,
      696,
      695,
      693,
      691,
      689,
      687,
      685,
      682,
      680,
      677,
      675,
      672,
      669,
      667,
      664,
      661,
      657,
      654,
      651,
      647,
      644,
      640,
      636,
      632,
      628,
      624,
      620,
      616,
      612,
      607,
      603,
      598,
      593,
      588,
      583,
      579,
      574,
      568,
      563,
      558,
      553,
      547,
      542,
      536,
      530,
      524,
      519,
      513,
      507,
      501,
      495,
      489,
      483,
      476,
      470,
      464,
      458,
      451,
      445,
      439,
    ],
    95: [
      722,
      721,
      720,
      719,
      719,
      718,
      717,
      716,
      715,
      714,
      713,
      711,
      710,
      709,
      708,
      707,
      705,
      704,
      703,
      701,
      700,
      699,
      697,
      696,
      694,
      692,
      690,
      689,
      687,
      685,
      683,
      681,
      679,
      677,
      675,
      673,
      670,
      668,
      665,
      663,
      660,
      657,
      654,
      651,
      648,
      645,
      642,
      638,
      635,
      631,
      628,
      624,
      620,
      616,
      612,
      608,
      604,
      600,
      596,
      591,
      587,
      582,
      577,
      573,
      568,
      563,
      558,
      553,
      548,
      543,
      537,
      532,
      527,
      521,
      516,
      510,
      504,
      499,
      493,
      487,
      481,
      475,
      470,
      464,
      458,
      451,
      445,
      439,
      433,
      426,
      420,
    ],
    100: [
      722,
      720,
      719,
      718,
      716,
      715,
      713,
      712,
      710,
      708,
      707,
      705,
      703,
      702,
      700,
      698,
      696,
      694,
      693,
      691,
      689,
      687,
      685,
      683,
      681,
      679,
      677,
      675,
      673,
      670,
      668,
      666,
      663,
      661,
      659,
      656,
      653,
      651,
      648,
      645,
      642,
      639,
      636,
      633,
      629,
      626,
      623,
      619,
      615,
      612,
      608,
      604,
      600,
      596,
      592,
      588,
      584,
      580,
      575,
      571,
      566,
      561,
      557,
      552,
      547,
      542,
      537,
      532,
      527,
      522,
      517,
      511,
      506,
      501,
      495,
      490,
      484,
      479,
      473,
      467,
      462,
      456,
      450,
      444,
      438,
      432,
      426,
      420,
      414,
      408,
      402,
    ],
    105: [
      722,
      720,
      718,
      716,
      714,
      712,
      710,
      708,
      705,
      703,
      701,
      699,
      696,
      694,
      692,
      690,
      687,
      685,
      683,
      680,
      678,
      676,
      673,
      671,
      668,
      666,
      663,
      661,
      658,
      656,
      653,
      650,
      648,
      645,
      642,
      639,
      636,
      633,
      630,
      627,
      624,
      621,
      617,
      614,
      611,
      607,
      603,
      600,
      596,
      592,
      588,
      584,
      580,
      576,
      572,
      568,
      563,
      559,
      555,
      550,
      546,
      541,
      536,
      531,
      527,
      522,
      517,
      512,
      507,
      501,
      496,
      491,
      486,
      480,
      475,
      469,
      464,
      459,
      453,
      447,
      442,
      436,
      431,
      425,
      419,
      413,
      407,
      401,
      396,
      390,
      384,
    ],
    110: [
      722,
      719,
      717,
      714,
      712,
      709,
      706,
      704,
      701,
      698,
      695,
      693,
      690,
      687,
      684,
      681,
      678,
      676,
      673,
      670,
      667,
      664,
      661,
      659,
      656,
      653,
      650,
      647,
      644,
      641,
      638,
      635,
      632,
      629,
      626,
      623,
      620,
      616,
      613,
      610,
      606,
      603,
      599,
      596,
      592,
      588,
      584,
      581,
      577,
      573,
      569,
      565,
      561,
      556,
      552,
      548,
      543,
      539,
      534,
      530,
      525,
      520,
      516,
      511,
      506,
      501,
      496,
      491,
      486,
      481,
      476,
      471,
      465,
      460,
      455,
      450,
      444,
      439,
      433,
      428,
      422,
      417,
      411,
      406,
      400,
      394,
      389,
      383,
      377,
      372,
      366,
    ],
    115: [
      722,
      719,
      716,
      712,
      709,
      706,
      703,
      700,
      697,
      693,
      690,
      687,
      683,
      680,
      676,
      673,
      670,
      666,
      663,
      660,
      656,
      653,
      650,
      646,
      643,
      640,
      637,
      633,
      630,
      627,
      623,
      620,
      617,
      613,
      610,
      606,
      603,
      599,
      596,
      592,
      589,
      585,
      581,
      577,
      574,
      570,
      566,
      562,
      558,
      554,
      549,
      545,
      541,
      537,
      532,
      528,
      523,
      519,
      514,
      510,
      505,
      500,
      496,
      491,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      446,
      440,
      435,
      430,
      424,
      419,
      414,
      409,
      403,
      398,
      392,
      387,
      382,
      376,
      371,
      365,
      360,
      354,
      349,
    ],
    120: [
      722,
      718,
      715,
      711,
      707,
      704,
      700,
      696,
      692,
      688,
      685,
      681,
      677,
      673,
      669,
      665,
      661,
      658,
      654,
      650,
      646,
      642,
      639,
      635,
      631,
      627,
      624,
      620,
      616,
      612,
      609,
      605,
      602,
      598,
      594,
      590,
      586,
      583,
      579,
      575,
      571,
      567,
      563,
      559,
      555,
      551,
      547,
      543,
      539,
      535,
      530,
      526,
      522,
      517,
      513,
      508,
      504,
      499,
      495,
      490,
      485,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      446,
      441,
      436,
      431,
      426,
      421,
      416,
      411,
      405,
      400,
      395,
      390,
      385,
      379,
      374,
      369,
      363,
      358,
      353,
      348,
      342,
      337,
      331,
    ],
    125: [
      722,
      718,
      713,
      709,
      705,
      701,
      697,
      693,
      688,
      684,
      680,
      675,
      671,
      666,
      662,
      658,
      653,
      649,
      645,
      640,
      636,
      632,
      628,
      623,
      619,
      615,
      611,
      607,
      603,
      599,
      595,
      591,
      587,
      583,
      579,
      575,
      570,
      566,
      562,
      558,
      554,
      550,
      546,
      542,
      538,
      533,
      529,
      525,
      521,
      516,
      512,
      507,
      503,
      498,
      494,
      489,
      485,
      480,
      476,
      471,
      466,
      461,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      325,
      320,
      315,
    ],
    130: [
      722,
      717,
      713,
      708,
      703,
      699,
      694,
      689,
      684,
      680,
      675,
      670,
      665,
      660,
      656,
      651,
      646,
      641,
      636,
      632,
      627,
      622,
      618,
      613,
      608,
      604,
      599,
      595,
      590,
      586,
      581,
      577,
      573,
      568,
      564,
      559,
      555,
      551,
      546,
      542,
      538,
      533,
      529,
      525,
      520,
      516,
      512,
      507,
      503,
      498,
      494,
      489,
      485,
      480,
      476,
      471,
      466,
      462,
      457,
      452,
      448,
      443,
      438,
      433,
      429,
      424,
      419,
      414,
      409,
      404,
      399,
      394,
      389,
      384,
      379,
      375,
      369,
      364,
      359,
      354,
      349,
      344,
      339,
      334,
      329,
      324,
      319,
      314,
      309,
      304,
      299,
    ],
    135: [
      722,
      717,
      712,
      707,
      702,
      697,
      691,
      686,
      681,
      676,
      670,
      665,
      660,
      655,
      649,
      644,
      639,
      634,
      629,
      623,
      618,
      613,
      608,
      603,
      598,
      593,
      588,
      583,
      578,
      573,
      569,
      564,
      559,
      554,
      550,
      545,
      540,
      536,
      531,
      527,
      522,
      518,
      513,
      508,
      504,
      499,
      495,
      490,
      486,
      481,
      477,
      472,
      467,
      463,
      458,
      453,
      449,
      444,
      439,
      435,
      430,
      425,
      420,
      416,
      411,
      406,
      401,
      396,
      392,
      387,
      382,
      377,
      372,
      367,
      362,
      357,
      352,
      348,
      343,
      338,
      333,
      328,
      323,
      318,
      313,
      309,
      304,
      299,
      294,
      289,
      285,
    ],
    140: [
      722,
      716,
      711,
      705,
      700,
      694,
      689,
      683,
      678,
      672,
      666,
      661,
      655,
      649,
      644,
      638,
      633,
      627,
      622,
      616,
      611,
      605,
      600,
      594,
      589,
      584,
      578,
      573,
      568,
      562,
      557,
      552,
      547,
      541,
      536,
      531,
      526,
      521,
      517,
      512,
      507,
      502,
      497,
      493,
      488,
      483,
      479,
      474,
      469,
      465,
      460,
      455,
      451,
      446,
      441,
      437,
      432,
      427,
      422,
      418,
      413,
      408,
      403,
      399,
      394,
      389,
      384,
      380,
      375,
      370,
      365,
      360,
      356,
      351,
      346,
      341,
      336,
      332,
      327,
      322,
      317,
      313,
      308,
      303,
      299,
      294,
      289,
      285,
      280,
      275,
      271,
    ],
    145: [
      722,
      716,
      710,
      704,
      698,
      693,
      687,
      681,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      633,
      627,
      621,
      616,
      610,
      604,
      598,
      592,
      587,
      581,
      575,
      569,
      564,
      558,
      552,
      547,
      541,
      536,
      530,
      525,
      519,
      514,
      508,
      503,
      498,
      493,
      488,
      483,
      478,
      473,
      468,
      463,
      459,
      454,
      449,
      444,
      440,
      435,
      430,
      425,
      421,
      416,
      411,
      406,
      402,
      397,
      392,
      387,
      383,
      378,
      373,
      369,
      364,
      359,
      354,
      350,
      345,
      340,
      336,
      331,
      326,
      322,
      317,
      312,
      308,
      303,
      299,
      294,
      289,
      285,
      280,
      276,
      271,
      267,
      262,
      258,
    ],
    150: [
      722,
      716,
      709,
      703,
      697,
      691,
      685,
      678,
      672,
      666,
      659,
      653,
      647,
      641,
      635,
      629,
      622,
      616,
      610,
      604,
      598,
      592,
      586,
      580,
      574,
      568,
      562,
      556,
      550,
      544,
      538,
      532,
      526,
      520,
      514,
      509,
      503,
      497,
      492,
      486,
      481,
      475,
      470,
      465,
      459,
      454,
      449,
      444,
      439,
      435,
      430,
      425,
      420,
      415,
      410,
      406,
      401,
      396,
      392,
      387,
      382,
      377,
      373,
      368,
      363,
      359,
      354,
      349,
      345,
      340,
      336,
      331,
      326,
      322,
      317,
      313,
      308,
      303,
      299,
      294,
      290,
      285,
      281,
      277,
      272,
      268,
      263,
      259,
      255,
      250,
      246,
    ],
    155: [
      722,
      715,
      709,
      703,
      696,
      690,
      683,
      676,
      670,
      663,
      657,
      650,
      644,
      637,
      631,
      625,
      618,
      612,
      606,
      599,
      593,
      587,
      580,
      574,
      568,
      561,
      555,
      549,
      543,
      536,
      530,
      524,
      518,
      512,
      506,
      500,
      494,
      488,
      482,
      476,
      470,
      465,
      459,
      453,
      448,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      388,
      383,
      378,
      373,
      369,
      364,
      359,
      355,
      350,
      345,
      341,
      336,
      331,
      327,
      323,
      318,
      314,
      309,
      305,
      300,
      296,
      291,
      287,
      282,
      278,
      274,
      270,
      265,
      261,
      256,
      252,
      248,
      244,
      240,
      236,
    ],
    160: [
      722,
      715,
      708,
      702,
      695,
      688,
      682,
      675,
      668,
      661,
      654,
      648,
      641,
      635,
      628,
      621,
      615,
      609,
      602,
      596,
      589,
      582,
      576,
      569,
      563,
      556,
      550,
      543,
      537,
      530,
      524,
      518,
      511,
      505,
      499,
      492,
      486,
      480,
      474,
      468,
      462,
      456,
      450,
      444,
      438,
      433,
      427,
      421,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      381,
      376,
      371,
      366,
      362,
      357,
      352,
      348,
      343,
      338,
      334,
      329,
      325,
      321,
      316,
      311,
      307,
      303,
      298,
      294,
      290,
      285,
      281,
      277,
      272,
      268,
      264,
      260,
      255,
      251,
      247,
      243,
      239,
      235,
      231,
      227,
    ],
    165: [
      722,
      715,
      708,
      701,
      694,
      688,
      681,
      674,
      667,
      660,
      653,
      646,
      639,
      632,
      626,
      619,
      612,
      606,
      599,
      592,
      586,
      579,
      572,
      565,
      559,
      552,
      546,
      539,
      532,
      526,
      519,
      513,
      506,
      500,
      493,
      487,
      480,
      474,
      467,
      461,
      455,
      449,
      443,
      437,
      431,
      425,
      419,
      414,
      408,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      367,
      362,
      357,
      352,
      347,
      343,
      338,
      334,
      329,
      324,
      320,
      316,
      311,
      307,
      303,
      298,
      294,
      289,
      285,
      281,
      277,
      272,
      268,
      264,
      260,
      256,
      252,
      247,
      243,
      239,
      236,
      231,
      228,
      224,
      220,
    ],
    170: [
      722,
      715,
      708,
      701,
      694,
      687,
      680,
      673,
      666,
      658,
      651,
      644,
      638,
      631,
      624,
      617,
      611,
      604,
      597,
      590,
      583,
      576,
      569,
      563,
      556,
      549,
      542,
      536,
      529,
      523,
      516,
      509,
      502,
      496,
      489,
      483,
      476,
      470,
      463,
      457,
      450,
      444,
      437,
      431,
      426,
      420,
      414,
      408,
      402,
      396,
      391,
      385,
      380,
      375,
      370,
      365,
      360,
      355,
      350,
      345,
      340,
      336,
      331,
      327,
      322,
      318,
      313,
      309,
      304,
      300,
      296,
      291,
      287,
      283,
      278,
      274,
      270,
      266,
      262,
      258,
      254,
      250,
      246,
      242,
      238,
      234,
      230,
      226,
      222,
      218,
      214,
    ],
    175: [
      722,
      715,
      708,
      701,
      694,
      686,
      679,
      672,
      665,
      658,
      651,
      644,
      637,
      630,
      623,
      616,
      609,
      603,
      596,
      589,
      582,
      575,
      568,
      561,
      554,
      548,
      541,
      534,
      527,
      520,
      513,
      506,
      500,
      493,
      487,
      480,
      474,
      467,
      461,
      454,
      448,
      441,
      435,
      428,
      422,
      416,
      410,
      404,
      398,
      393,
      387,
      382,
      376,
      371,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      327,
      322,
      318,
      313,
      309,
      305,
      300,
      296,
      292,
      287,
      283,
      279,
      275,
      270,
      266,
      262,
      258,
      254,
      250,
      246,
      242,
      238,
      234,
      230,
      227,
      222,
      219,
      215,
      211,
    ],
  },
  '8E': {
    0: [
      731,
      737,
      744,
      751,
      757,
      764,
      770,
      776,
      782,
      788,
      793,
      799,
      804,
      809,
      814,
      819,
      823,
      828,
      832,
      836,
      840,
      844,
      848,
      851,
      854,
      857,
      860,
      863,
      865,
      868,
      870,
      872,
      873,
      875,
      876,
      877,
      878,
      879,
      879,
      880,
      880,
      880,
      879,
      879,
      878,
      877,
      876,
      875,
      874,
      872,
      870,
      868,
      866,
      863,
      860,
      858,
      854,
      851,
      848,
      844,
      840,
      836,
      832,
      827,
      823,
      818,
      813,
      808,
      802,
      797,
      791,
      785,
      779,
      772,
      766,
      759,
      752,
      745,
      738,
      731,
      723,
      716,
      708,
      700,
      692,
      683,
      675,
      666,
      658,
      648,
      639,
    ],
    5: [
      731,
      737,
      744,
      751,
      757,
      763,
      770,
      776,
      782,
      787,
      793,
      798,
      804,
      809,
      814,
      818,
      823,
      827,
      832,
      836,
      840,
      844,
      847,
      851,
      854,
      857,
      860,
      862,
      865,
      867,
      869,
      871,
      873,
      874,
      875,
      876,
      877,
      878,
      879,
      879,
      879,
      879,
      879,
      878,
      878,
      877,
      876,
      874,
      873,
      871,
      869,
      867,
      865,
      862,
      860,
      857,
      854,
      850,
      847,
      843,
      839,
      835,
      831,
      826,
      822,
      817,
      812,
      807,
      801,
      796,
      790,
      784,
      778,
      772,
      765,
      758,
      752,
      745,
      737,
      730,
      723,
      715,
      707,
      699,
      691,
      683,
      674,
      665,
      657,
      648,
      639,
    ],
    10: [
      731,
      737,
      744,
      750,
      757,
      763,
      769,
      775,
      781,
      787,
      792,
      798,
      803,
      808,
      813,
      817,
      822,
      826,
      831,
      835,
      838,
      842,
      846,
      849,
      852,
      855,
      858,
      861,
      863,
      865,
      867,
      869,
      871,
      872,
      873,
      875,
      875,
      876,
      877,
      877,
      877,
      877,
      877,
      876,
      875,
      874,
      873,
      872,
      870,
      869,
      867,
      865,
      862,
      860,
      857,
      854,
      851,
      848,
      844,
      841,
      837,
      833,
      829,
      824,
      820,
      815,
      810,
      804,
      799,
      793,
      788,
      782,
      776,
      769,
      763,
      756,
      749,
      742,
      735,
      728,
      720,
      713,
      705,
      697,
      689,
      681,
      672,
      664,
      655,
      646,
      637,
    ],
    15: [
      731,
      737,
      744,
      750,
      756,
      762,
      768,
      774,
      780,
      786,
      791,
      796,
      801,
      806,
      811,
      816,
      820,
      824,
      829,
      833,
      836,
      840,
      843,
      847,
      850,
      853,
      855,
      858,
      860,
      862,
      864,
      866,
      868,
      869,
      870,
      871,
      872,
      873,
      873,
      873,
      873,
      873,
      873,
      872,
      872,
      871,
      870,
      868,
      867,
      865,
      863,
      861,
      859,
      856,
      853,
      850,
      847,
      844,
      840,
      837,
      833,
      829,
      825,
      820,
      816,
      811,
      806,
      800,
      795,
      790,
      784,
      778,
      772,
      765,
      759,
      753,
      746,
      739,
      732,
      724,
      717,
      709,
      701,
      694,
      686,
      677,
      669,
      660,
      652,
      643,
      634,
    ],
    20: [
      731,
      737,
      743,
      750,
      756,
      762,
      767,
      773,
      779,
      784,
      789,
      794,
      799,
      804,
      809,
      813,
      818,
      822,
      826,
      830,
      833,
      837,
      840,
      843,
      846,
      849,
      852,
      854,
      856,
      858,
      860,
      862,
      863,
      865,
      866,
      867,
      868,
      868,
      868,
      869,
      869,
      868,
      868,
      867,
      867,
      866,
      864,
      863,
      861,
      860,
      858,
      855,
      853,
      851,
      848,
      845,
      842,
      839,
      835,
      831,
      827,
      823,
      819,
      815,
      810,
      805,
      800,
      795,
      790,
      784,
      778,
      772,
      766,
      760,
      754,
      747,
      740,
      733,
      726,
      719,
      712,
      704,
      696,
      689,
      681,
      672,
      664,
      656,
      647,
      638,
      630,
    ],
    25: [
      731,
      737,
      743,
      749,
      755,
      760,
      766,
      772,
      777,
      782,
      787,
      792,
      797,
      801,
      806,
      810,
      814,
      818,
      822,
      826,
      829,
      833,
      836,
      839,
      842,
      844,
      847,
      849,
      851,
      853,
      855,
      857,
      858,
      859,
      860,
      861,
      862,
      862,
      862,
      862,
      862,
      862,
      862,
      861,
      860,
      859,
      858,
      856,
      855,
      853,
      851,
      849,
      846,
      844,
      841,
      838,
      835,
      831,
      828,
      824,
      820,
      816,
      812,
      807,
      803,
      798,
      793,
      788,
      782,
      777,
      771,
      765,
      759,
      753,
      747,
      740,
      733,
      727,
      720,
      712,
      705,
      698,
      690,
      682,
      674,
      666,
      658,
      650,
      641,
      633,
      624,
    ],
    30: [
      731,
      736,
      742,
      748,
      754,
      759,
      764,
      770,
      775,
      780,
      784,
      789,
      794,
      798,
      802,
      806,
      810,
      814,
      818,
      821,
      825,
      828,
      831,
      834,
      836,
      839,
      841,
      843,
      845,
      847,
      849,
      850,
      851,
      852,
      853,
      854,
      855,
      855,
      855,
      855,
      855,
      854,
      854,
      853,
      852,
      851,
      850,
      848,
      846,
      844,
      842,
      840,
      838,
      835,
      832,
      829,
      826,
      823,
      819,
      815,
      811,
      807,
      803,
      799,
      794,
      789,
      784,
      779,
      774,
      768,
      762,
      757,
      751,
      744,
      738,
      732,
      725,
      718,
      711,
      704,
      697,
      689,
      682,
      674,
      667,
      658,
      650,
      642,
      634,
      625,
      617,
    ],
    35: [
      731,
      736,
      742,
      747,
      752,
      758,
      763,
      767,
      772,
      777,
      781,
      786,
      790,
      794,
      798,
      802,
      806,
      809,
      813,
      816,
      819,
      822,
      825,
      828,
      830,
      832,
      834,
      836,
      838,
      840,
      841,
      842,
      844,
      844,
      845,
      846,
      846,
      846,
      846,
      846,
      846,
      845,
      845,
      844,
      843,
      842,
      840,
      838,
      837,
      835,
      833,
      830,
      828,
      825,
      822,
      819,
      816,
      812,
      809,
      805,
      801,
      797,
      793,
      788,
      783,
      779,
      774,
      769,
      763,
      758,
      752,
      746,
      740,
      734,
      728,
      721,
      715,
      708,
      701,
      694,
      687,
      680,
      673,
      665,
      657,
      649,
      641,
      633,
      625,
      617,
      608,
    ],
    40: [
      731,
      736,
      741,
      746,
      751,
      756,
      760,
      765,
      769,
      774,
      778,
      782,
      786,
      790,
      794,
      797,
      801,
      804,
      807,
      810,
      813,
      815,
      818,
      821,
      823,
      825,
      827,
      828,
      830,
      831,
      833,
      834,
      835,
      835,
      836,
      836,
      837,
      837,
      837,
      836,
      836,
      835,
      834,
      833,
      832,
      831,
      829,
      828,
      826,
      824,
      821,
      819,
      816,
      814,
      811,
      807,
      804,
      801,
      797,
      793,
      789,
      785,
      781,
      776,
      772,
      767,
      762,
      757,
      752,
      746,
      740,
      734,
      729,
      723,
      716,
      710,
      703,
      697,
      690,
      683,
      676,
      669,
      661,
      654,
      646,
      638,
      631,
      623,
      615,
      606,
      598,
    ],
    45: [
      731,
      735,
      740,
      745,
      749,
      754,
      758,
      762,
      766,
      770,
      774,
      778,
      782,
      785,
      788,
      792,
      795,
      798,
      801,
      803,
      806,
      808,
      811,
      813,
      815,
      817,
      818,
      820,
      821,
      822,
      823,
      824,
      825,
      825,
      826,
      826,
      826,
      826,
      826,
      825,
      825,
      824,
      823,
      822,
      820,
      819,
      817,
      815,
      813,
      811,
      809,
      806,
      804,
      801,
      798,
      795,
      791,
      788,
      784,
      780,
      776,
      772,
      768,
      763,
      758,
      753,
      748,
      743,
      738,
      733,
      727,
      721,
      715,
      710,
      703,
      697,
      690,
      684,
      677,
      670,
      663,
      656,
      649,
      641,
      634,
      626,
      619,
      611,
      603,
      595,
      587,
    ],
    50: [
      731,
      735,
      739,
      743,
      747,
      752,
      755,
      759,
      763,
      767,
      770,
      773,
      777,
      780,
      783,
      786,
      789,
      791,
      794,
      796,
      798,
      800,
      802,
      804,
      806,
      807,
      809,
      810,
      811,
      812,
      813,
      814,
      814,
      814,
      815,
      815,
      814,
      814,
      814,
      813,
      812,
      811,
      810,
      809,
      807,
      806,
      804,
      802,
      800,
      798,
      795,
      792,
      790,
      787,
      784,
      780,
      777,
      773,
      770,
      766,
      762,
      757,
      753,
      749,
      744,
      739,
      734,
      729,
      724,
      718,
      712,
      707,
      701,
      695,
      689,
      682,
      676,
      670,
      663,
      656,
      649,
      642,
      635,
      628,
      620,
      613,
      606,
      598,
      590,
      582,
      574,
    ],
    55: [
      731,
      734,
      738,
      742,
      746,
      749,
      753,
      756,
      759,
      762,
      765,
      768,
      771,
      774,
      777,
      779,
      782,
      784,
      786,
      788,
      790,
      792,
      793,
      795,
      797,
      798,
      799,
      800,
      801,
      801,
      802,
      802,
      803,
      803,
      802,
      802,
      802,
      801,
      801,
      800,
      799,
      798,
      797,
      795,
      793,
      792,
      790,
      788,
      785,
      783,
      780,
      778,
      775,
      772,
      768,
      765,
      762,
      758,
      754,
      750,
      746,
      742,
      737,
      733,
      728,
      723,
      718,
      713,
      708,
      703,
      697,
      691,
      685,
      679,
      673,
      667,
      661,
      654,
      648,
      641,
      634,
      627,
      620,
      613,
      606,
      599,
      591,
      584,
      576,
      568,
      561,
    ],
    60: [
      731,
      734,
      737,
      740,
      744,
      747,
      750,
      753,
      755,
      758,
      761,
      763,
      766,
      768,
      770,
      772,
      774,
      776,
      778,
      780,
      781,
      783,
      784,
      785,
      786,
      787,
      788,
      789,
      789,
      790,
      790,
      790,
      790,
      790,
      790,
      789,
      789,
      788,
      787,
      786,
      785,
      783,
      782,
      780,
      778,
      777,
      774,
      772,
      770,
      767,
      765,
      762,
      759,
      756,
      752,
      749,
      745,
      741,
      738,
      734,
      729,
      725,
      721,
      716,
      711,
      706,
      701,
      696,
      691,
      686,
      680,
      674,
      668,
      663,
      657,
      651,
      644,
      638,
      632,
      625,
      618,
      611,
      604,
      598,
      590,
      583,
      576,
      569,
      561,
      553,
      546,
    ],
    65: [
      731,
      733,
      736,
      739,
      742,
      744,
      747,
      749,
      751,
      753,
      756,
      758,
      760,
      761,
      763,
      765,
      767,
      768,
      769,
      771,
      772,
      773,
      774,
      775,
      775,
      776,
      777,
      777,
      777,
      777,
      777,
      777,
      777,
      776,
      776,
      775,
      774,
      773,
      772,
      771,
      770,
      768,
      767,
      765,
      763,
      761,
      758,
      756,
      753,
      751,
      748,
      745,
      742,
      739,
      735,
      732,
      728,
      724,
      720,
      716,
      712,
      708,
      703,
      698,
      694,
      689,
      684,
      678,
      673,
      668,
      663,
      656,
      651,
      645,
      639,
      634,
      627,
      621,
      614,
      608,
      601,
      594,
      588,
      581,
      574,
      567,
      560,
      552,
      545,
      538,
      530,
    ],
    70: [
      731,
      733,
      735,
      737,
      739,
      741,
      743,
      745,
      747,
      749,
      750,
      752,
      753,
      755,
      756,
      757,
      758,
      759,
      760,
      761,
      762,
      763,
      763,
      764,
      764,
      765,
      765,
      765,
      765,
      764,
      764,
      764,
      763,
      762,
      762,
      761,
      760,
      758,
      757,
      756,
      754,
      752,
      750,
      748,
      746,
      744,
      741,
      739,
      736,
      733,
      730,
      727,
      724,
      721,
      717,
      714,
      710,
      706,
      702,
      698,
      693,
      689,
      685,
      680,
      675,
      670,
      665,
      660,
      655,
      649,
      644,
      638,
      632,
      627,
      621,
      615,
      609,
      602,
      596,
      590,
      584,
      577,
      570,
      563,
      557,
      550,
      543,
      536,
      529,
      521,
      514,
    ],
    75: [
      731,
      732,
      734,
      736,
      737,
      739,
      740,
      741,
      742,
      744,
      745,
      746,
      747,
      748,
      748,
      749,
      750,
      751,
      751,
      752,
      752,
      752,
      752,
      753,
      752,
      752,
      752,
      752,
      752,
      751,
      750,
      750,
      749,
      748,
      747,
      746,
      744,
      743,
      741,
      739,
      738,
      736,
      734,
      731,
      729,
      727,
      724,
      721,
      718,
      715,
      712,
      709,
      706,
      702,
      699,
      695,
      691,
      687,
      683,
      679,
      675,
      670,
      665,
      661,
      656,
      651,
      646,
      641,
      636,
      630,
      625,
      619,
      614,
      608,
      602,
      596,
      590,
      583,
      578,
      571,
      565,
      559,
      552,
      545,
      539,
      532,
      525,
      518,
      511,
      505,
      497,
    ],
    80: [
      731,
      732,
      733,
      734,
      735,
      736,
      736,
      737,
      738,
      738,
      739,
      740,
      740,
      740,
      741,
      741,
      741,
      741,
      741,
      741,
      741,
      741,
      741,
      741,
      740,
      740,
      739,
      739,
      738,
      737,
      736,
      735,
      734,
      733,
      731,
      730,
      728,
      727,
      725,
      723,
      721,
      719,
      716,
      714,
      711,
      709,
      706,
      703,
      700,
      697,
      694,
      690,
      687,
      683,
      679,
      676,
      672,
      668,
      663,
      659,
      655,
      650,
      646,
      641,
      636,
      631,
      626,
      621,
      616,
      611,
      605,
      600,
      594,
      588,
      582,
      577,
      571,
      565,
      558,
      552,
      546,
      540,
      532,
      526,
      520,
      514,
      508,
      500,
      493,
      487,
      480,
    ],
    85: [
      731,
      731,
      732,
      732,
      732,
      733,
      733,
      733,
      733,
      733,
      733,
      733,
      733,
      733,
      733,
      733,
      732,
      732,
      731,
      731,
      731,
      730,
      729,
      729,
      728,
      727,
      726,
      725,
      724,
      723,
      722,
      720,
      719,
      717,
      715,
      714,
      712,
      710,
      708,
      706,
      703,
      701,
      698,
      696,
      693,
      690,
      687,
      684,
      681,
      678,
      674,
      671,
      667,
      664,
      660,
      656,
      652,
      648,
      644,
      639,
      635,
      630,
      625,
      621,
      616,
      611,
      606,
      601,
      596,
      590,
      585,
      579,
      574,
      568,
      562,
      557,
      551,
      545,
      539,
      532,
      526,
      520,
      514,
      507,
      501,
      495,
      489,
      483,
      475,
      468,
      462,
    ],
    90: [
      731,
      730,
      730,
      730,
      730,
      730,
      729,
      729,
      728,
      728,
      727,
      727,
      726,
      725,
      725,
      724,
      723,
      722,
      721,
      721,
      719,
      719,
      717,
      716,
      715,
      714,
      713,
      711,
      710,
      708,
      707,
      705,
      703,
      701,
      699,
      697,
      695,
      693,
      691,
      688,
      686,
      683,
      680,
      677,
      674,
      671,
      668,
      665,
      662,
      658,
      655,
      651,
      648,
      644,
      640,
      636,
      632,
      627,
      623,
      619,
      614,
      610,
      605,
      600,
      595,
      590,
      585,
      580,
      575,
      570,
      564,
      559,
      553,
      548,
      542,
      536,
      530,
      525,
      519,
      513,
      506,
      501,
      495,
      488,
      482,
      476,
      469,
      463,
      456,
      450,
      444,
    ],
    95: [
      731,
      730,
      729,
      728,
      728,
      727,
      726,
      725,
      724,
      723,
      721,
      720,
      719,
      718,
      717,
      715,
      714,
      713,
      711,
      710,
      709,
      707,
      705,
      704,
      702,
      701,
      699,
      697,
      695,
      693,
      691,
      689,
      687,
      685,
      683,
      681,
      678,
      676,
      673,
      670,
      668,
      665,
      662,
      659,
      656,
      652,
      649,
      646,
      642,
      639,
      635,
      631,
      627,
      624,
      620,
      615,
      611,
      607,
      603,
      598,
      593,
      589,
      584,
      579,
      574,
      570,
      564,
      559,
      554,
      549,
      543,
      538,
      533,
      527,
      521,
      516,
      510,
      504,
      498,
      493,
      487,
      480,
      475,
      469,
      463,
      456,
      450,
      444,
      438,
      431,
      425,
    ],
    100: [
      731,
      729,
      728,
      727,
      725,
      724,
      722,
      721,
      719,
      717,
      716,
      714,
      712,
      710,
      709,
      707,
      705,
      703,
      701,
      699,
      697,
      695,
      693,
      691,
      689,
      687,
      685,
      683,
      681,
      678,
      676,
      674,
      671,
      669,
      666,
      664,
      661,
      658,
      655,
      652,
      650,
      646,
      643,
      640,
      637,
      633,
      630,
      626,
      623,
      619,
      615,
      611,
      607,
      603,
      599,
      595,
      591,
      586,
      582,
      577,
      573,
      568,
      563,
      558,
      553,
      548,
      544,
      538,
      533,
      528,
      523,
      517,
      512,
      506,
      501,
      495,
      490,
      484,
      478,
      472,
      467,
      461,
      455,
      449,
      443,
      437,
      431,
      425,
      419,
      413,
      406,
    ],
    105: [
      731,
      729,
      727,
      725,
      723,
      721,
      719,
      716,
      714,
      712,
      710,
      707,
      705,
      703,
      700,
      698,
      696,
      693,
      691,
      689,
      686,
      684,
      681,
      679,
      676,
      674,
      671,
      669,
      666,
      663,
      661,
      658,
      655,
      653,
      650,
      647,
      644,
      641,
      638,
      634,
      631,
      628,
      625,
      621,
      618,
      614,
      610,
      607,
      603,
      599,
      595,
      591,
      587,
      583,
      579,
      574,
      570,
      565,
      561,
      556,
      552,
      547,
      542,
      537,
      532,
      527,
      522,
      517,
      512,
      507,
      502,
      496,
      491,
      486,
      480,
      475,
      469,
      464,
      458,
      452,
      446,
      441,
      435,
      429,
      424,
      418,
      412,
      406,
      400,
      394,
      388,
    ],
    110: [
      731,
      728,
      726,
      723,
      720,
      718,
      715,
      712,
      710,
      707,
      704,
      701,
      698,
      695,
      692,
      690,
      687,
      684,
      681,
      678,
      675,
      672,
      669,
      666,
      663,
      661,
      657,
      655,
      652,
      649,
      646,
      642,
      639,
      636,
      633,
      630,
      627,
      623,
      620,
      616,
      613,
      610,
      606,
      602,
      599,
      595,
      591,
      587,
      583,
      579,
      575,
      571,
      567,
      563,
      558,
      554,
      549,
      545,
      540,
      536,
      531,
      526,
      521,
      517,
      512,
      507,
      502,
      496,
      491,
      486,
      481,
      476,
      470,
      465,
      460,
      454,
      449,
      443,
      438,
      432,
      426,
      421,
      416,
      410,
      404,
      399,
      393,
      387,
      381,
      376,
      370,
    ],
    115: [
      731,
      728,
      725,
      721,
      718,
      715,
      712,
      709,
      705,
      702,
      698,
      695,
      691,
      688,
      685,
      681,
      678,
      674,
      671,
      668,
      664,
      661,
      657,
      654,
      651,
      647,
      644,
      641,
      637,
      634,
      630,
      627,
      624,
      620,
      617,
      613,
      610,
      606,
      602,
      599,
      595,
      591,
      588,
      584,
      580,
      576,
      572,
      568,
      564,
      560,
      555,
      551,
      547,
      543,
      538,
      534,
      529,
      524,
      520,
      515,
      511,
      506,
      501,
      496,
      491,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      450,
      445,
      440,
      434,
      429,
      424,
      418,
      413,
      407,
      402,
      396,
      391,
      385,
      379,
      374,
      369,
      363,
      358,
      352,
    ],
    120: [
      731,
      727,
      723,
      720,
      716,
      712,
      709,
      705,
      701,
      697,
      693,
      689,
      685,
      681,
      677,
      673,
      669,
      665,
      661,
      658,
      654,
      650,
      646,
      642,
      638,
      635,
      631,
      627,
      623,
      619,
      616,
      612,
      608,
      604,
      601,
      597,
      593,
      589,
      585,
      581,
      578,
      573,
      570,
      565,
      561,
      557,
      553,
      549,
      545,
      540,
      536,
      532,
      527,
      523,
      518,
      514,
      509,
      505,
      500,
      495,
      490,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      446,
      441,
      436,
      430,
      425,
      420,
      415,
      410,
      404,
      399,
      394,
      389,
      383,
      378,
      372,
      367,
      362,
      356,
      351,
      346,
      340,
      335,
    ],
    125: [
      731,
      726,
      722,
      718,
      714,
      710,
      705,
      701,
      697,
      692,
      688,
      683,
      679,
      675,
      670,
      666,
      661,
      657,
      652,
      648,
      644,
      639,
      635,
      631,
      627,
      622,
      618,
      614,
      610,
      606,
      601,
      597,
      593,
      589,
      585,
      581,
      577,
      573,
      569,
      564,
      560,
      556,
      552,
      548,
      543,
      539,
      535,
      530,
      526,
      522,
      517,
      513,
      508,
      504,
      499,
      495,
      490,
      485,
      480,
      476,
      471,
      466,
      461,
      456,
      452,
      447,
      442,
      437,
      432,
      426,
      422,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      381,
      375,
      370,
      365,
      360,
      354,
      349,
      344,
      339,
      334,
      328,
      323,
      318,
    ],
    130: [
      731,
      726,
      721,
      717,
      712,
      707,
      703,
      698,
      693,
      688,
      683,
      678,
      673,
      668,
      663,
      658,
      654,
      649,
      644,
      639,
      634,
      629,
      625,
      620,
      615,
      611,
      606,
      601,
      597,
      592,
      588,
      583,
      579,
      574,
      570,
      565,
      561,
      557,
      552,
      548,
      544,
      539,
      535,
      530,
      526,
      522,
      517,
      513,
      508,
      504,
      499,
      494,
      490,
      485,
      481,
      476,
      471,
      466,
      462,
      457,
      452,
      447,
      442,
      438,
      433,
      428,
      423,
      418,
      413,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      352,
      348,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      307,
      302,
    ],
    135: [
      731,
      726,
      721,
      715,
      710,
      705,
      700,
      695,
      689,
      684,
      679,
      673,
      668,
      662,
      657,
      652,
      647,
      641,
      636,
      631,
      626,
      620,
      615,
      610,
      605,
      600,
      595,
      590,
      585,
      580,
      575,
      570,
      565,
      560,
      556,
      551,
      546,
      541,
      537,
      532,
      528,
      523,
      518,
      514,
      509,
      504,
      500,
      495,
      491,
      486,
      481,
      477,
      472,
      467,
      463,
      458,
      453,
      448,
      444,
      439,
      434,
      429,
      424,
      420,
      415,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      375,
      370,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
      302,
      297,
      292,
      287,
    ],
    140: [
      731,
      725,
      720,
      714,
      709,
      703,
      697,
      692,
      686,
      680,
      674,
      669,
      663,
      657,
      652,
      646,
      640,
      634,
      629,
      623,
      618,
      612,
      607,
      601,
      596,
      590,
      585,
      579,
      574,
      568,
      563,
      558,
      553,
      547,
      542,
      537,
      532,
      527,
      522,
      517,
      512,
      507,
      503,
      498,
      493,
      488,
      484,
      479,
      474,
      469,
      465,
      460,
      455,
      450,
      446,
      441,
      436,
      431,
      426,
      422,
      417,
      412,
      407,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      369,
      364,
      359,
      354,
      349,
      344,
      339,
      335,
      330,
      325,
      320,
      315,
      311,
      306,
      301,
      296,
      292,
      287,
      282,
      278,
      273,
    ],
    145: [
      731,
      725,
      719,
      713,
      707,
      701,
      695,
      689,
      683,
      677,
      671,
      665,
      659,
      652,
      646,
      640,
      634,
      629,
      623,
      617,
      611,
      605,
      599,
      593,
      587,
      582,
      576,
      570,
      564,
      558,
      553,
      547,
      541,
      536,
      530,
      525,
      519,
      514,
      509,
      503,
      498,
      493,
      488,
      483,
      478,
      473,
      468,
      463,
      458,
      453,
      449,
      444,
      439,
      434,
      429,
      425,
      420,
      415,
      410,
      405,
      401,
      396,
      391,
      386,
      382,
      377,
      372,
      367,
      363,
      358,
      353,
      348,
      343,
      339,
      334,
      329,
      324,
      320,
      315,
      310,
      306,
      301,
      296,
      292,
      287,
      283,
      278,
      274,
      269,
      265,
      260,
    ],
    150: [
      731,
      724,
      718,
      712,
      706,
      699,
      693,
      687,
      680,
      674,
      667,
      661,
      655,
      648,
      642,
      636,
      630,
      623,
      617,
      611,
      605,
      599,
      593,
      586,
      580,
      574,
      568,
      562,
      556,
      550,
      544,
      538,
      532,
      526,
      520,
      514,
      508,
      502,
      497,
      491,
      485,
      480,
      475,
      469,
      464,
      459,
      454,
      449,
      444,
      439,
      434,
      429,
      424,
      419,
      414,
      410,
      405,
      400,
      395,
      390,
      386,
      381,
      376,
      371,
      367,
      362,
      357,
      352,
      348,
      343,
      338,
      334,
      329,
      324,
      320,
      315,
      311,
      306,
      301,
      297,
      292,
      288,
      283,
      279,
      275,
      270,
      265,
      261,
      257,
      252,
      248,
    ],
    155: [
      731,
      724,
      718,
      711,
      705,
      698,
      691,
      685,
      678,
      671,
      665,
      658,
      651,
      645,
      638,
      632,
      625,
      619,
      613,
      606,
      600,
      594,
      587,
      581,
      574,
      568,
      561,
      555,
      548,
      542,
      536,
      530,
      523,
      517,
      511,
      505,
      499,
      493,
      487,
      481,
      475,
      469,
      463,
      458,
      452,
      447,
      441,
      436,
      431,
      426,
      421,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      381,
      377,
      372,
      367,
      362,
      358,
      353,
      349,
      344,
      339,
      335,
      330,
      325,
      321,
      316,
      312,
      307,
      303,
      298,
      294,
      289,
      285,
      281,
      276,
      272,
      268,
      263,
      259,
      255,
      250,
      246,
      242,
      238,
    ],
    160: [
      731,
      724,
      717,
      711,
      704,
      697,
      690,
      683,
      676,
      669,
      662,
      655,
      649,
      642,
      635,
      629,
      622,
      616,
      609,
      602,
      596,
      589,
      582,
      575,
      569,
      562,
      556,
      549,
      543,
      536,
      530,
      523,
      517,
      510,
      504,
      498,
      491,
      485,
      479,
      473,
      466,
      460,
      454,
      449,
      443,
      437,
      431,
      425,
      420,
      415,
      410,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      370,
      365,
      360,
      355,
      351,
      346,
      341,
      337,
      332,
      328,
      323,
      319,
      314,
      310,
      305,
      301,
      296,
      292,
      288,
      283,
      279,
      275,
      270,
      266,
      262,
      257,
      253,
      249,
      245,
      241,
      237,
      233,
      229,
    ],
    165: [
      731,
      724,
      717,
      710,
      703,
      696,
      689,
      682,
      675,
      668,
      661,
      654,
      647,
      640,
      633,
      626,
      620,
      613,
      606,
      599,
      592,
      585,
      578,
      572,
      565,
      558,
      552,
      545,
      538,
      531,
      525,
      518,
      512,
      505,
      498,
      492,
      485,
      479,
      472,
      466,
      460,
      453,
      447,
      441,
      435,
      429,
      423,
      418,
      412,
      406,
      401,
      395,
      390,
      385,
      380,
      375,
      370,
      365,
      360,
      355,
      350,
      346,
      341,
      336,
      332,
      327,
      323,
      318,
      314,
      309,
      305,
      301,
      296,
      292,
      287,
      283,
      279,
      275,
      270,
      266,
      262,
      258,
      254,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
    ],
    170: [
      731,
      724,
      717,
      710,
      703,
      695,
      688,
      681,
      674,
      666,
      659,
      652,
      645,
      638,
      631,
      625,
      618,
      611,
      604,
      597,
      590,
      583,
      576,
      569,
      562,
      555,
      548,
      542,
      535,
      528,
      521,
      514,
      508,
      501,
      494,
      488,
      481,
      475,
      468,
      462,
      455,
      448,
      442,
      436,
      430,
      424,
      418,
      412,
      406,
      400,
      394,
      389,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      343,
      339,
      334,
      329,
      325,
      320,
      316,
      311,
      307,
      302,
      298,
      294,
      290,
      285,
      281,
      276,
      273,
      268,
      264,
      260,
      256,
      252,
      248,
      243,
      240,
      236,
      232,
      227,
      224,
      220,
      216,
    ],
    175: [
      731,
      724,
      717,
      709,
      702,
      695,
      688,
      680,
      673,
      666,
      659,
      651,
      644,
      637,
      630,
      624,
      616,
      610,
      603,
      595,
      588,
      581,
      574,
      568,
      561,
      554,
      547,
      540,
      533,
      526,
      519,
      512,
      505,
      498,
      492,
      485,
      479,
      472,
      466,
      459,
      453,
      446,
      439,
      433,
      426,
      420,
      414,
      408,
      402,
      397,
      391,
      385,
      380,
      374,
      369,
      364,
      359,
      354,
      349,
      344,
      339,
      334,
      330,
      325,
      320,
      316,
      312,
      307,
      303,
      298,
      294,
      290,
      285,
      281,
      277,
      272,
      268,
      264,
      260,
      256,
      252,
      248,
      244,
      240,
      236,
      232,
      228,
      224,
      220,
      216,
      213,
    ],
  },
  '9E': {
    0: [
      742,
      748,
      756,
      763,
      770,
      777,
      783,
      790,
      796,
      803,
      809,
      815,
      821,
      826,
      832,
      837,
      842,
      847,
      852,
      856,
      861,
      865,
      869,
      873,
      876,
      880,
      883,
      886,
      889,
      891,
      894,
      896,
      898,
      900,
      902,
      903,
      904,
      905,
      906,
      907,
      907,
      907,
      907,
      907,
      907,
      906,
      905,
      904,
      903,
      901,
      900,
      898,
      895,
      893,
      891,
      888,
      885,
      882,
      878,
      875,
      871,
      867,
      863,
      858,
      854,
      849,
      844,
      839,
      833,
      828,
      822,
      816,
      810,
      803,
      797,
      790,
      783,
      776,
      769,
      761,
      754,
      746,
      738,
      730,
      721,
      713,
      704,
      695,
      687,
      677,
      668,
    ],
    5: [
      742,
      748,
      756,
      763,
      770,
      777,
      783,
      790,
      796,
      802,
      809,
      815,
      820,
      826,
      831,
      837,
      842,
      846,
      851,
      856,
      860,
      864,
      868,
      872,
      876,
      879,
      882,
      885,
      888,
      891,
      893,
      895,
      898,
      899,
      901,
      902,
      904,
      905,
      905,
      906,
      906,
      907,
      907,
      906,
      906,
      905,
      904,
      903,
      902,
      900,
      899,
      897,
      895,
      892,
      890,
      887,
      884,
      881,
      878,
      874,
      870,
      866,
      862,
      857,
      853,
      848,
      843,
      838,
      833,
      827,
      821,
      815,
      809,
      803,
      796,
      789,
      782,
      775,
      768,
      761,
      753,
      745,
      737,
      729,
      721,
      712,
      704,
      695,
      686,
      677,
      667,
    ],
    10: [
      742,
      748,
      755,
      762,
      769,
      776,
      783,
      789,
      796,
      802,
      808,
      814,
      819,
      825,
      830,
      835,
      840,
      845,
      850,
      854,
      859,
      863,
      867,
      870,
      874,
      877,
      881,
      884,
      886,
      889,
      891,
      894,
      896,
      897,
      899,
      900,
      901,
      902,
      903,
      904,
      904,
      904,
      904,
      904,
      903,
      903,
      902,
      901,
      899,
      898,
      896,
      894,
      892,
      890,
      887,
      884,
      882,
      878,
      875,
      871,
      867,
      864,
      859,
      855,
      850,
      845,
      840,
      835,
      830,
      824,
      819,
      813,
      806,
      800,
      794,
      787,
      780,
      773,
      766,
      758,
      750,
      743,
      735,
      727,
      718,
      710,
      701,
      693,
      683,
      674,
      665,
    ],
    15: [
      742,
      748,
      755,
      762,
      769,
      775,
      782,
      788,
      795,
      801,
      806,
      812,
      818,
      823,
      828,
      834,
      838,
      843,
      848,
      852,
      856,
      860,
      864,
      868,
      871,
      875,
      878,
      881,
      883,
      886,
      888,
      890,
      892,
      894,
      895,
      897,
      898,
      899,
      900,
      900,
      900,
      900,
      900,
      900,
      900,
      899,
      898,
      897,
      895,
      894,
      892,
      890,
      888,
      886,
      883,
      880,
      877,
      874,
      870,
      867,
      863,
      859,
      855,
      851,
      846,
      841,
      836,
      831,
      826,
      820,
      814,
      808,
      802,
      796,
      789,
      783,
      776,
      769,
      761,
      754,
      746,
      739,
      731,
      723,
      715,
      706,
      698,
      689,
      680,
      671,
      662,
    ],
    20: [
      742,
      748,
      755,
      762,
      768,
      775,
      781,
      787,
      793,
      799,
      805,
      810,
      816,
      821,
      826,
      831,
      836,
      840,
      845,
      849,
      853,
      857,
      861,
      864,
      868,
      871,
      874,
      876,
      879,
      882,
      884,
      886,
      888,
      889,
      891,
      892,
      893,
      894,
      894,
      895,
      895,
      895,
      895,
      895,
      894,
      893,
      892,
      891,
      890,
      888,
      886,
      884,
      882,
      880,
      877,
      874,
      871,
      868,
      864,
      861,
      857,
      853,
      849,
      844,
      840,
      835,
      830,
      825,
      819,
      814,
      808,
      802,
      796,
      790,
      783,
      777,
      770,
      763,
      756,
      748,
      741,
      733,
      725,
      717,
      709,
      701,
      692,
      684,
      675,
      666,
      657,
    ],
    25: [
      742,
      748,
      754,
      761,
      767,
      773,
      779,
      785,
      791,
      797,
      802,
      808,
      813,
      818,
      823,
      828,
      832,
      837,
      841,
      845,
      849,
      853,
      856,
      860,
      863,
      866,
      869,
      871,
      874,
      876,
      878,
      880,
      882,
      883,
      884,
      886,
      887,
      887,
      888,
      888,
      888,
      888,
      888,
      888,
      887,
      886,
      885,
      884,
      882,
      881,
      879,
      877,
      874,
      872,
      869,
      867,
      864,
      860,
      857,
      853,
      849,
      845,
      841,
      837,
      832,
      827,
      822,
      817,
      812,
      806,
      800,
      794,
      788,
      782,
      776,
      769,
      762,
      755,
      748,
      741,
      734,
      726,
      718,
      710,
      702,
      694,
      685,
      677,
      668,
      660,
      650,
    ],
    30: [
      742,
      747,
      754,
      760,
      766,
      772,
      778,
      783,
      789,
      794,
      800,
      805,
      810,
      814,
      819,
      824,
      828,
      832,
      836,
      840,
      844,
      847,
      851,
      854,
      857,
      860,
      862,
      865,
      867,
      869,
      871,
      873,
      875,
      876,
      877,
      878,
      879,
      880,
      880,
      880,
      880,
      880,
      880,
      879,
      878,
      877,
      876,
      875,
      873,
      872,
      870,
      868,
      865,
      863,
      860,
      857,
      854,
      851,
      847,
      844,
      840,
      836,
      832,
      827,
      822,
      818,
      813,
      808,
      802,
      797,
      791,
      785,
      779,
      773,
      766,
      760,
      753,
      746,
      739,
      732,
      725,
      717,
      709,
      701,
      694,
      685,
      677,
      669,
      660,
      651,
      643,
    ],
    35: [
      742,
      747,
      753,
      759,
      765,
      770,
      776,
      781,
      786,
      791,
      796,
      801,
      806,
      810,
      815,
      819,
      823,
      827,
      831,
      834,
      838,
      841,
      844,
      847,
      850,
      853,
      855,
      857,
      860,
      861,
      863,
      865,
      866,
      867,
      868,
      869,
      870,
      870,
      871,
      871,
      871,
      870,
      870,
      869,
      868,
      867,
      866,
      865,
      863,
      861,
      859,
      857,
      854,
      852,
      849,
      846,
      843,
      840,
      836,
      832,
      829,
      825,
      820,
      816,
      811,
      806,
      801,
      796,
      791,
      785,
      780,
      774,
      768,
      762,
      755,
      749,
      742,
      735,
      728,
      721,
      714,
      706,
      699,
      691,
      683,
      675,
      667,
      659,
      650,
      642,
      633,
    ],
    40: [
      742,
      747,
      752,
      758,
      763,
      768,
      773,
      778,
      783,
      788,
      793,
      797,
      801,
      806,
      810,
      814,
      817,
      821,
      825,
      828,
      831,
      834,
      837,
      840,
      842,
      845,
      847,
      849,
      851,
      853,
      854,
      855,
      857,
      858,
      858,
      859,
      860,
      860,
      860,
      860,
      860,
      859,
      859,
      858,
      857,
      856,
      854,
      853,
      851,
      849,
      847,
      845,
      842,
      840,
      837,
      834,
      830,
      827,
      823,
      820,
      816,
      812,
      807,
      803,
      798,
      793,
      788,
      783,
      778,
      772,
      767,
      761,
      755,
      749,
      743,
      736,
      730,
      723,
      716,
      709,
      702,
      694,
      687,
      679,
      672,
      664,
      656,
      648,
      639,
      631,
      622,
    ],
    45: [
      742,
      746,
      751,
      756,
      761,
      766,
      771,
      775,
      780,
      784,
      788,
      793,
      797,
      800,
      804,
      808,
      811,
      815,
      818,
      821,
      824,
      826,
      829,
      831,
      834,
      836,
      838,
      840,
      841,
      843,
      844,
      845,
      846,
      847,
      847,
      848,
      848,
      848,
      848,
      848,
      848,
      847,
      846,
      845,
      844,
      843,
      841,
      840,
      838,
      836,
      833,
      831,
      828,
      826,
      823,
      820,
      816,
      813,
      809,
      805,
      801,
      797,
      793,
      789,
      784,
      779,
      774,
      769,
      764,
      758,
      752,
      747,
      741,
      735,
      728,
      722,
      715,
      709,
      702,
      695,
      688,
      681,
      673,
      666,
      658,
      650,
      643,
      635,
      626,
      618,
      610,
    ],
    50: [
      742,
      746,
      750,
      755,
      759,
      764,
      768,
      772,
      776,
      780,
      784,
      788,
      791,
      795,
      798,
      801,
      804,
      807,
      810,
      813,
      815,
      818,
      820,
      822,
      824,
      826,
      828,
      829,
      830,
      832,
      833,
      834,
      834,
      835,
      835,
      836,
      836,
      836,
      835,
      835,
      834,
      834,
      833,
      831,
      830,
      829,
      827,
      825,
      823,
      821,
      819,
      816,
      813,
      811,
      808,
      804,
      801,
      798,
      794,
      790,
      786,
      782,
      777,
      773,
      768,
      763,
      758,
      753,
      748,
      743,
      737,
      731,
      725,
      719,
      713,
      706,
      700,
      693,
      687,
      680,
      673,
      666,
      659,
      651,
      643,
      636,
      628,
      620,
      612,
      604,
      596,
    ],
    55: [
      742,
      745,
      749,
      753,
      757,
      761,
      765,
      769,
      772,
      776,
      779,
      782,
      785,
      789,
      792,
      794,
      797,
      800,
      802,
      804,
      807,
      809,
      811,
      812,
      814,
      815,
      817,
      818,
      819,
      820,
      821,
      822,
      822,
      822,
      822,
      822,
      822,
      822,
      821,
      821,
      820,
      819,
      818,
      817,
      815,
      813,
      812,
      810,
      808,
      805,
      803,
      800,
      797,
      794,
      791,
      788,
      784,
      781,
      777,
      773,
      769,
      765,
      760,
      756,
      751,
      746,
      741,
      736,
      731,
      726,
      720,
      714,
      708,
      702,
      696,
      690,
      683,
      677,
      670,
      663,
      656,
      649,
      642,
      635,
      628,
      620,
      613,
      605,
      597,
      589,
      582,
    ],
    60: [
      742,
      745,
      748,
      752,
      755,
      759,
      762,
      765,
      768,
      771,
      774,
      777,
      779,
      782,
      784,
      787,
      789,
      791,
      793,
      795,
      797,
      799,
      800,
      802,
      803,
      804,
      805,
      806,
      807,
      808,
      808,
      808,
      809,
      809,
      809,
      808,
      808,
      807,
      807,
      806,
      805,
      804,
      802,
      801,
      799,
      797,
      795,
      793,
      791,
      788,
      786,
      783,
      780,
      777,
      774,
      770,
      767,
      763,
      759,
      755,
      751,
      747,
      742,
      738,
      733,
      728,
      723,
      718,
      713,
      708,
      702,
      696,
      690,
      684,
      678,
      672,
      666,
      659,
      653,
      646,
      639,
      632,
      625,
      618,
      611,
      604,
      596,
      589,
      581,
      573,
      566,
    ],
    65: [
      742,
      744,
      747,
      750,
      753,
      756,
      758,
      761,
      764,
      766,
      768,
      771,
      773,
      775,
      777,
      779,
      781,
      783,
      784,
      786,
      787,
      788,
      790,
      791,
      792,
      792,
      793,
      794,
      794,
      794,
      795,
      795,
      795,
      794,
      794,
      793,
      793,
      792,
      791,
      790,
      789,
      787,
      786,
      784,
      782,
      780,
      778,
      776,
      773,
      771,
      768,
      765,
      762,
      759,
      755,
      752,
      748,
      745,
      741,
      737,
      732,
      728,
      724,
      719,
      714,
      709,
      704,
      699,
      694,
      688,
      683,
      677,
      671,
      665,
      660,
      654,
      647,
      640,
      634,
      628,
      621,
      614,
      607,
      600,
      593,
      586,
      579,
      571,
      564,
      556,
      549,
    ],
    70: [
      742,
      744,
      746,
      748,
      751,
      753,
      755,
      757,
      759,
      761,
      763,
      764,
      766,
      768,
      769,
      771,
      772,
      773,
      775,
      776,
      777,
      778,
      778,
      779,
      779,
      780,
      780,
      780,
      781,
      781,
      780,
      780,
      780,
      779,
      778,
      778,
      777,
      776,
      775,
      773,
      772,
      770,
      768,
      766,
      764,
      762,
      760,
      757,
      755,
      752,
      749,
      746,
      743,
      740,
      736,
      733,
      729,
      725,
      721,
      717,
      713,
      708,
      704,
      699,
      694,
      689,
      684,
      679,
      674,
      669,
      663,
      657,
      651,
      646,
      640,
      634,
      627,
      621,
      615,
      608,
      602,
      595,
      588,
      582,
      575,
      567,
      560,
      553,
      546,
      538,
      531,
    ],
    75: [
      742,
      743,
      745,
      746,
      748,
      750,
      751,
      753,
      754,
      755,
      757,
      758,
      759,
      760,
      761,
      762,
      763,
      764,
      764,
      765,
      766,
      766,
      766,
      767,
      767,
      767,
      767,
      767,
      767,
      766,
      766,
      765,
      764,
      763,
      763,
      762,
      760,
      759,
      757,
      756,
      754,
      752,
      750,
      748,
      746,
      744,
      741,
      738,
      736,
      733,
      730,
      727,
      723,
      720,
      716,
      713,
      709,
      705,
      701,
      697,
      692,
      688,
      683,
      679,
      674,
      669,
      664,
      659,
      653,
      648,
      642,
      637,
      631,
      625,
      619,
      613,
      607,
      601,
      595,
      589,
      582,
      576,
      569,
      562,
      555,
      549,
      541,
      534,
      527,
      520,
      513,
    ],
    80: [
      742,
      742,
      743,
      745,
      746,
      747,
      748,
      748,
      749,
      750,
      751,
      751,
      752,
      752,
      753,
      753,
      754,
      754,
      754,
      754,
      754,
      754,
      754,
      754,
      754,
      754,
      753,
      753,
      752,
      751,
      750,
      750,
      748,
      747,
      746,
      745,
      743,
      742,
      740,
      738,
      736,
      734,
      732,
      729,
      727,
      724,
      722,
      719,
      716,
      713,
      710,
      706,
      703,
      699,
      696,
      692,
      688,
      684,
      680,
      676,
      671,
      667,
      662,
      657,
      653,
      648,
      643,
      637,
      632,
      627,
      621,
      616,
      610,
      604,
      598,
      593,
      587,
      581,
      574,
      568,
      562,
      555,
      548,
      542,
      535,
      529,
      522,
      515,
      508,
      501,
      495,
    ],
    85: [
      742,
      742,
      742,
      743,
      743,
      744,
      744,
      744,
      744,
      744,
      744,
      744,
      744,
      744,
      744,
      744,
      744,
      744,
      743,
      743,
      743,
      742,
      742,
      741,
      740,
      740,
      739,
      738,
      737,
      736,
      735,
      733,
      732,
      731,
      729,
      727,
      726,
      724,
      722,
      720,
      717,
      715,
      713,
      710,
      708,
      705,
      702,
      699,
      696,
      693,
      689,
      686,
      682,
      679,
      675,
      671,
      667,
      663,
      659,
      654,
      650,
      645,
      641,
      636,
      631,
      626,
      621,
      616,
      611,
      605,
      600,
      594,
      589,
      583,
      577,
      571,
      565,
      559,
      553,
      547,
      541,
      535,
      528,
      521,
      515,
      509,
      503,
      496,
      488,
      481,
      475,
    ],
    90: [
      742,
      741,
      741,
      741,
      740,
      740,
      740,
      740,
      739,
      739,
      738,
      738,
      737,
      736,
      736,
      735,
      734,
      734,
      733,
      732,
      731,
      730,
      729,
      728,
      727,
      726,
      724,
      723,
      722,
      720,
      719,
      717,
      716,
      714,
      712,
      710,
      708,
      706,
      703,
      701,
      699,
      696,
      693,
      691,
      688,
      685,
      682,
      679,
      675,
      672,
      668,
      665,
      661,
      657,
      653,
      649,
      645,
      641,
      637,
      632,
      628,
      623,
      619,
      614,
      609,
      604,
      599,
      594,
      588,
      583,
      578,
      572,
      567,
      561,
      555,
      550,
      544,
      538,
      532,
      526,
      519,
      513,
      507,
      501,
      494,
      488,
      482,
      475,
      468,
      462,
      456,
    ],
    95: [
      742,
      740,
      740,
      739,
      738,
      737,
      736,
      735,
      734,
      733,
      732,
      731,
      729,
      728,
      727,
      726,
      724,
      723,
      722,
      721,
      719,
      718,
      716,
      715,
      713,
      712,
      710,
      708,
      706,
      704,
      703,
      701,
      699,
      697,
      694,
      692,
      690,
      687,
      685,
      682,
      679,
      677,
      674,
      671,
      668,
      664,
      661,
      658,
      654,
      651,
      647,
      643,
      640,
      636,
      632,
      628,
      623,
      619,
      615,
      610,
      606,
      601,
      596,
      592,
      587,
      582,
      577,
      572,
      566,
      561,
      555,
      550,
      545,
      539,
      533,
      528,
      522,
      516,
      510,
      504,
      498,
      492,
      486,
      480,
      474,
      467,
      461,
      455,
      449,
      442,
      436,
    ],
    100: [
      742,
      740,
      738,
      737,
      735,
      734,
      732,
      731,
      729,
      727,
      725,
      724,
      722,
      720,
      718,
      716,
      715,
      713,
      711,
      709,
      707,
      705,
      703,
      701,
      699,
      697,
      695,
      693,
      691,
      689,
      686,
      684,
      682,
      679,
      677,
      674,
      671,
      669,
      666,
      663,
      660,
      657,
      654,
      651,
      647,
      644,
      641,
      637,
      633,
      630,
      626,
      622,
      618,
      614,
      610,
      606,
      601,
      597,
      593,
      588,
      584,
      579,
      574,
      569,
      564,
      559,
      554,
      549,
      544,
      539,
      533,
      528,
      523,
      517,
      512,
      506,
      500,
      495,
      489,
      483,
      477,
      471,
      465,
      459,
      453,
      447,
      441,
      435,
      429,
      423,
      416,
    ],
    105: [
      742,
      739,
      737,
      735,
      733,
      731,
      728,
      726,
      724,
      722,
      719,
      717,
      714,
      712,
      710,
      707,
      705,
      702,
      700,
      698,
      695,
      693,
      690,
      688,
      686,
      683,
      680,
      678,
      675,
      673,
      670,
      667,
      665,
      662,
      659,
      656,
      653,
      650,
      647,
      644,
      641,
      637,
      634,
      631,
      627,
      624,
      620,
      616,
      613,
      609,
      605,
      601,
      597,
      592,
      588,
      584,
      580,
      575,
      571,
      566,
      561,
      557,
      552,
      547,
      542,
      537,
      532,
      527,
      522,
      516,
      511,
      506,
      500,
      495,
      490,
      484,
      478,
      473,
      467,
      461,
      456,
      450,
      444,
      438,
      432,
      427,
      421,
      414,
      409,
      403,
      397,
    ],
    110: [
      742,
      738,
      736,
      733,
      730,
      728,
      725,
      722,
      719,
      716,
      713,
      710,
      707,
      704,
      701,
      698,
      695,
      692,
      689,
      686,
      683,
      680,
      678,
      675,
      672,
      669,
      666,
      663,
      660,
      657,
      654,
      651,
      648,
      644,
      641,
      638,
      635,
      632,
      628,
      625,
      621,
      618,
      614,
      611,
      607,
      603,
      599,
      596,
      592,
      588,
      584,
      579,
      575,
      571,
      567,
      562,
      558,
      553,
      549,
      544,
      539,
      535,
      530,
      525,
      520,
      515,
      510,
      505,
      500,
      495,
      489,
      484,
      479,
      473,
      468,
      463,
      457,
      452,
      446,
      440,
      434,
      429,
      424,
      418,
      412,
      406,
      401,
      395,
      389,
      383,
      377,
    ],
    115: [
      742,
      738,
      735,
      731,
      728,
      725,
      721,
      718,
      714,
      711,
      707,
      703,
      700,
      696,
      693,
      689,
      686,
      682,
      679,
      675,
      672,
      668,
      665,
      662,
      658,
      655,
      651,
      648,
      645,
      641,
      638,
      634,
      631,
      627,
      624,
      620,
      617,
      613,
      610,
      606,
      602,
      599,
      595,
      591,
      587,
      583,
      579,
      575,
      571,
      567,
      563,
      559,
      554,
      550,
      545,
      541,
      536,
      532,
      527,
      523,
      518,
      513,
      508,
      503,
      498,
      493,
      488,
      483,
      478,
      473,
      468,
      463,
      457,
      452,
      447,
      441,
      436,
      430,
      425,
      420,
      414,
      409,
      403,
      398,
      392,
      386,
      381,
      375,
      370,
      364,
      359,
    ],
    120: [
      742,
      737,
      733,
      730,
      726,
      722,
      718,
      714,
      709,
      705,
      701,
      697,
      693,
      689,
      685,
      681,
      677,
      672,
      669,
      665,
      661,
      657,
      653,
      649,
      645,
      641,
      637,
      633,
      630,
      626,
      622,
      618,
      615,
      611,
      607,
      603,
      599,
      595,
      592,
      588,
      584,
      580,
      576,
      572,
      568,
      564,
      559,
      555,
      551,
      547,
      542,
      538,
      534,
      529,
      525,
      520,
      515,
      511,
      506,
      502,
      497,
      492,
      487,
      482,
      477,
      472,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      426,
      421,
      416,
      410,
      405,
      400,
      394,
      389,
      384,
      378,
      373,
      367,
      362,
      357,
      351,
      346,
      340,
    ],
    125: [
      742,
      737,
      732,
      728,
      723,
      719,
      714,
      710,
      705,
      700,
      696,
      691,
      686,
      682,
      677,
      673,
      668,
      663,
      659,
      654,
      650,
      645,
      641,
      637,
      632,
      628,
      624,
      620,
      615,
      611,
      607,
      603,
      599,
      594,
      590,
      586,
      582,
      578,
      574,
      570,
      566,
      561,
      557,
      553,
      549,
      544,
      540,
      536,
      531,
      527,
      523,
      518,
      514,
      509,
      504,
      500,
      495,
      491,
      486,
      481,
      476,
      471,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      380,
      375,
      370,
      365,
      359,
      354,
      349,
      344,
      339,
      333,
      328,
      323,
    ],
    130: [
      742,
      736,
      731,
      726,
      721,
      716,
      711,
      706,
      701,
      696,
      690,
      685,
      680,
      675,
      670,
      665,
      660,
      655,
      650,
      645,
      640,
      635,
      630,
      625,
      620,
      616,
      611,
      606,
      602,
      597,
      592,
      588,
      583,
      579,
      574,
      570,
      566,
      561,
      557,
      552,
      548,
      544,
      539,
      535,
      530,
      526,
      521,
      517,
      512,
      508,
      503,
      499,
      494,
      490,
      485,
      480,
      476,
      471,
      466,
      461,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      367,
      362,
      357,
      352,
      347,
      342,
      336,
      331,
      326,
      321,
      316,
      311,
      306,
    ],
    135: [
      742,
      736,
      730,
      725,
      719,
      714,
      708,
      703,
      697,
      691,
      686,
      680,
      674,
      669,
      663,
      658,
      652,
      647,
      641,
      636,
      630,
      625,
      620,
      615,
      609,
      604,
      599,
      594,
      589,
      584,
      579,
      574,
      569,
      564,
      559,
      554,
      550,
      545,
      540,
      536,
      531,
      527,
      522,
      517,
      513,
      508,
      504,
      499,
      494,
      490,
      485,
      480,
      476,
      471,
      466,
      462,
      457,
      452,
      447,
      443,
      438,
      433,
      428,
      423,
      418,
      413,
      409,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      369,
      364,
      359,
      354,
      349,
      345,
      339,
      334,
      330,
      325,
      320,
      315,
      310,
      305,
      300,
      295,
      290,
    ],
    140: [
      742,
      735,
      729,
      724,
      718,
      712,
      706,
      699,
      693,
      687,
      681,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      634,
      628,
      622,
      616,
      611,
      605,
      599,
      594,
      588,
      583,
      577,
      572,
      566,
      561,
      556,
      550,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      506,
      501,
      496,
      491,
      486,
      482,
      477,
      472,
      467,
      463,
      458,
      453,
      449,
      444,
      439,
      434,
      429,
      425,
      420,
      415,
      410,
      406,
      401,
      396,
      391,
      386,
      381,
      376,
      372,
      367,
      362,
      357,
      352,
      347,
      342,
      338,
      333,
      328,
      323,
      318,
      314,
      309,
      304,
      299,
      294,
      290,
      285,
      281,
      276,
    ],
    145: [
      742,
      735,
      729,
      722,
      716,
      710,
      703,
      697,
      690,
      684,
      677,
      671,
      664,
      658,
      652,
      645,
      639,
      633,
      627,
      621,
      615,
      609,
      603,
      597,
      591,
      585,
      579,
      573,
      567,
      561,
      555,
      550,
      544,
      538,
      533,
      527,
      521,
      516,
      511,
      505,
      500,
      495,
      490,
      485,
      480,
      475,
      470,
      465,
      461,
      456,
      451,
      446,
      441,
      436,
      432,
      427,
      422,
      417,
      413,
      408,
      403,
      398,
      394,
      389,
      384,
      379,
      374,
      370,
      365,
      360,
      355,
      350,
      346,
      341,
      336,
      332,
      327,
      322,
      317,
      313,
      308,
      303,
      299,
      294,
      289,
      285,
      280,
      276,
      271,
      267,
      262,
    ],
    150: [
      742,
      735,
      728,
      721,
      715,
      708,
      701,
      694,
      687,
      680,
      674,
      667,
      660,
      654,
      647,
      641,
      634,
      628,
      621,
      615,
      608,
      602,
      596,
      589,
      583,
      577,
      571,
      564,
      558,
      552,
      546,
      540,
      534,
      528,
      522,
      516,
      510,
      504,
      498,
      493,
      487,
      482,
      476,
      471,
      466,
      460,
      455,
      450,
      445,
      440,
      436,
      431,
      426,
      421,
      416,
      411,
      406,
      402,
      397,
      392,
      387,
      383,
      378,
      373,
      369,
      364,
      359,
      354,
      350,
      345,
      340,
      336,
      331,
      326,
      322,
      317,
      313,
      308,
      303,
      299,
      294,
      290,
      285,
      281,
      276,
      272,
      267,
      263,
      259,
      254,
      250,
    ],
    155: [
      742,
      734,
      727,
      720,
      713,
      706,
      699,
      692,
      685,
      678,
      671,
      664,
      657,
      650,
      643,
      636,
      630,
      623,
      616,
      610,
      603,
      597,
      590,
      583,
      577,
      570,
      563,
      557,
      550,
      544,
      538,
      531,
      525,
      519,
      512,
      506,
      500,
      494,
      488,
      482,
      476,
      470,
      465,
      459,
      453,
      448,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      387,
      383,
      378,
      373,
      369,
      364,
      359,
      355,
      350,
      345,
      341,
      336,
      331,
      327,
      322,
      318,
      313,
      309,
      304,
      300,
      295,
      291,
      286,
      282,
      277,
      273,
      269,
      264,
      260,
      256,
      252,
      248,
      243,
      239,
    ],
    160: [
      742,
      734,
      727,
      720,
      712,
      705,
      698,
      690,
      683,
      676,
      668,
      661,
      654,
      647,
      640,
      633,
      626,
      619,
      612,
      606,
      599,
      592,
      585,
      578,
      571,
      564,
      558,
      551,
      544,
      538,
      531,
      524,
      518,
      511,
      505,
      499,
      492,
      486,
      480,
      473,
      467,
      461,
      455,
      449,
      443,
      438,
      432,
      426,
      421,
      415,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      375,
      371,
      366,
      361,
      356,
      352,
      347,
      343,
      338,
      333,
      329,
      324,
      320,
      315,
      311,
      306,
      302,
      297,
      293,
      289,
      284,
      280,
      276,
      271,
      267,
      263,
      259,
      254,
      250,
      246,
      242,
      238,
      234,
      230,
    ],
    165: [
      742,
      734,
      726,
      719,
      712,
      704,
      696,
      689,
      681,
      674,
      666,
      659,
      652,
      645,
      637,
      630,
      623,
      616,
      609,
      602,
      595,
      588,
      581,
      574,
      567,
      560,
      553,
      546,
      539,
      533,
      526,
      519,
      512,
      506,
      499,
      493,
      486,
      479,
      473,
      466,
      460,
      454,
      448,
      442,
      436,
      430,
      424,
      418,
      412,
      407,
      401,
      396,
      391,
      386,
      381,
      376,
      370,
      365,
      361,
      356,
      351,
      347,
      342,
      337,
      333,
      328,
      324,
      319,
      315,
      310,
      306,
      301,
      297,
      293,
      288,
      284,
      280,
      275,
      271,
      267,
      263,
      259,
      255,
      250,
      246,
      242,
      238,
      234,
      230,
      226,
      222,
    ],
    170: [
      742,
      734,
      726,
      719,
      711,
      703,
      696,
      688,
      680,
      672,
      665,
      657,
      650,
      643,
      636,
      628,
      621,
      614,
      607,
      600,
      592,
      585,
      578,
      571,
      564,
      557,
      550,
      543,
      536,
      529,
      522,
      515,
      508,
      501,
      495,
      488,
      482,
      475,
      469,
      462,
      455,
      449,
      442,
      436,
      430,
      424,
      418,
      412,
      406,
      401,
      395,
      389,
      384,
      379,
      374,
      369,
      363,
      359,
      353,
      348,
      344,
      339,
      334,
      330,
      326,
      321,
      317,
      312,
      308,
      303,
      299,
      295,
      290,
      286,
      281,
      277,
      273,
      269,
      265,
      261,
      257,
      252,
      248,
      244,
      240,
      236,
      232,
      228,
      225,
      221,
      217,
    ],
    175: [
      742,
      734,
      726,
      718,
      711,
      703,
      695,
      687,
      679,
      672,
      664,
      656,
      649,
      642,
      634,
      627,
      620,
      613,
      605,
      598,
      591,
      583,
      576,
      569,
      562,
      555,
      548,
      541,
      534,
      527,
      520,
      512,
      505,
      499,
      492,
      486,
      479,
      473,
      466,
      460,
      453,
      446,
      440,
      433,
      426,
      421,
      415,
      409,
      403,
      397,
      391,
      385,
      380,
      375,
      369,
      364,
      359,
      354,
      349,
      344,
      339,
      335,
      330,
      326,
      321,
      317,
      312,
      308,
      303,
      299,
      295,
      290,
      286,
      282,
      278,
      273,
      269,
      265,
      261,
      257,
      253,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
      217,
      213,
    ],
  },
  '9S': {
    0: [
      738,
      745,
      752,
      759,
      766,
      773,
      780,
      787,
      793,
      799,
      806,
      812,
      817,
      823,
      829,
      834,
      839,
      844,
      849,
      854,
      858,
      862,
      866,
      870,
      874,
      877,
      881,
      884,
      887,
      889,
      892,
      894,
      896,
      898,
      900,
      902,
      903,
      904,
      905,
      905,
      906,
      906,
      906,
      906,
      906,
      905,
      904,
      903,
      902,
      901,
      899,
      897,
      895,
      893,
      890,
      888,
      885,
      882,
      879,
      875,
      871,
      867,
      863,
      859,
      854,
      850,
      845,
      839,
      834,
      829,
      823,
      817,
      811,
      804,
      798,
      791,
      785,
      777,
      770,
      763,
      755,
      748,
      740,
      732,
      723,
      715,
      706,
      697,
      689,
      680,
      670,
    ],
    5: [
      738,
      745,
      752,
      759,
      766,
      773,
      780,
      786,
      793,
      799,
      805,
      811,
      817,
      823,
      828,
      834,
      839,
      844,
      848,
      853,
      858,
      862,
      866,
      870,
      873,
      877,
      880,
      883,
      886,
      889,
      891,
      894,
      896,
      898,
      899,
      901,
      902,
      903,
      904,
      905,
      905,
      905,
      905,
      905,
      905,
      904,
      904,
      903,
      901,
      900,
      898,
      896,
      894,
      892,
      890,
      887,
      884,
      881,
      878,
      874,
      870,
      866,
      862,
      858,
      853,
      849,
      844,
      839,
      833,
      828,
      822,
      816,
      810,
      804,
      797,
      791,
      784,
      777,
      769,
      762,
      755,
      747,
      739,
      731,
      723,
      714,
      705,
      697,
      688,
      679,
      670,
    ],
    10: [
      738,
      745,
      752,
      759,
      766,
      773,
      779,
      786,
      792,
      798,
      805,
      810,
      816,
      822,
      827,
      832,
      838,
      843,
      847,
      852,
      856,
      860,
      864,
      868,
      872,
      875,
      878,
      881,
      884,
      887,
      889,
      892,
      894,
      896,
      897,
      899,
      900,
      901,
      902,
      903,
      903,
      903,
      903,
      903,
      903,
      902,
      901,
      900,
      899,
      897,
      896,
      894,
      892,
      890,
      887,
      884,
      881,
      878,
      875,
      871,
      868,
      864,
      860,
      855,
      851,
      846,
      841,
      836,
      831,
      825,
      820,
      814,
      808,
      801,
      795,
      788,
      781,
      774,
      767,
      760,
      752,
      744,
      737,
      728,
      720,
      712,
      703,
      695,
      686,
      677,
      667,
    ],
    15: [
      738,
      744,
      751,
      758,
      765,
      772,
      778,
      785,
      791,
      797,
      803,
      809,
      815,
      820,
      825,
      831,
      836,
      840,
      845,
      850,
      854,
      858,
      862,
      865,
      869,
      872,
      876,
      879,
      881,
      884,
      886,
      888,
      891,
      892,
      894,
      895,
      897,
      897,
      898,
      899,
      899,
      899,
      899,
      899,
      899,
      898,
      897,
      896,
      895,
      893,
      892,
      890,
      888,
      885,
      883,
      880,
      877,
      874,
      871,
      867,
      863,
      860,
      855,
      851,
      847,
      842,
      837,
      832,
      827,
      821,
      815,
      809,
      803,
      797,
      791,
      784,
      777,
      770,
      763,
      756,
      748,
      741,
      733,
      725,
      717,
      708,
      700,
      691,
      682,
      673,
      664,
    ],
    20: [
      738,
      744,
      751,
      758,
      764,
      771,
      777,
      784,
      790,
      796,
      801,
      807,
      813,
      818,
      823,
      828,
      833,
      838,
      842,
      846,
      850,
      855,
      858,
      862,
      865,
      869,
      872,
      874,
      877,
      880,
      882,
      884,
      886,
      888,
      889,
      890,
      892,
      892,
      893,
      894,
      894,
      894,
      894,
      894,
      893,
      893,
      892,
      891,
      889,
      888,
      886,
      884,
      882,
      880,
      877,
      874,
      871,
      868,
      865,
      861,
      857,
      853,
      849,
      845,
      841,
      836,
      831,
      826,
      821,
      815,
      809,
      803,
      797,
      791,
      785,
      778,
      771,
      764,
      757,
      750,
      743,
      735,
      727,
      719,
      711,
      703,
      695,
      686,
      677,
      669,
      659,
    ],
    25: [
      738,
      744,
      751,
      757,
      763,
      770,
      776,
      782,
      788,
      794,
      799,
      805,
      810,
      815,
      820,
      825,
      829,
      834,
      838,
      842,
      846,
      850,
      854,
      857,
      860,
      864,
      867,
      869,
      872,
      874,
      876,
      878,
      880,
      882,
      883,
      884,
      885,
      886,
      887,
      887,
      887,
      887,
      887,
      887,
      886,
      885,
      885,
      883,
      882,
      880,
      879,
      877,
      874,
      872,
      869,
      867,
      864,
      861,
      857,
      854,
      850,
      846,
      842,
      837,
      833,
      828,
      823,
      818,
      813,
      807,
      802,
      796,
      790,
      784,
      777,
      771,
      764,
      757,
      750,
      743,
      736,
      728,
      720,
      712,
      704,
      696,
      688,
      679,
      671,
      662,
      653,
    ],
    30: [
      738,
      744,
      750,
      756,
      762,
      768,
      774,
      780,
      786,
      791,
      796,
      801,
      806,
      811,
      816,
      821,
      825,
      829,
      834,
      837,
      841,
      845,
      848,
      852,
      855,
      858,
      860,
      863,
      865,
      867,
      870,
      871,
      873,
      874,
      876,
      877,
      878,
      878,
      879,
      879,
      879,
      879,
      879,
      878,
      878,
      877,
      876,
      875,
      873,
      871,
      870,
      868,
      865,
      863,
      860,
      857,
      854,
      851,
      848,
      844,
      840,
      837,
      832,
      828,
      823,
      819,
      814,
      809,
      804,
      798,
      792,
      787,
      781,
      774,
      768,
      762,
      755,
      748,
      741,
      734,
      727,
      719,
      712,
      704,
      696,
      688,
      680,
      671,
      663,
      654,
      645,
    ],
    35: [
      738,
      743,
      749,
      755,
      761,
      767,
      772,
      778,
      783,
      788,
      793,
      798,
      803,
      807,
      812,
      816,
      820,
      824,
      828,
      832,
      835,
      839,
      842,
      845,
      848,
      851,
      853,
      855,
      858,
      860,
      862,
      863,
      865,
      866,
      867,
      868,
      869,
      869,
      870,
      870,
      870,
      870,
      869,
      869,
      868,
      867,
      866,
      864,
      863,
      861,
      859,
      857,
      855,
      852,
      850,
      847,
      844,
      840,
      837,
      833,
      829,
      825,
      821,
      817,
      812,
      808,
      803,
      798,
      792,
      787,
      781,
      775,
      770,
      764,
      757,
      751,
      744,
      738,
      731,
      724,
      716,
      709,
      701,
      694,
      686,
      678,
      670,
      662,
      653,
      645,
      636,
    ],
    40: [
      738,
      743,
      749,
      754,
      759,
      765,
      770,
      775,
      780,
      785,
      789,
      794,
      798,
      803,
      807,
      811,
      815,
      818,
      822,
      825,
      829,
      832,
      835,
      837,
      840,
      843,
      845,
      847,
      849,
      851,
      852,
      854,
      855,
      856,
      857,
      858,
      859,
      859,
      859,
      859,
      859,
      859,
      858,
      857,
      856,
      855,
      854,
      853,
      851,
      849,
      847,
      845,
      842,
      840,
      837,
      834,
      831,
      828,
      824,
      821,
      817,
      813,
      809,
      804,
      800,
      795,
      790,
      785,
      780,
      774,
      769,
      763,
      757,
      751,
      745,
      738,
      732,
      725,
      718,
      711,
      704,
      697,
      690,
      682,
      674,
      666,
      658,
      650,
      642,
      634,
      625,
    ],
    45: [
      738,
      742,
      748,
      753,
      758,
      763,
      767,
      772,
      776,
      781,
      785,
      789,
      793,
      797,
      801,
      805,
      808,
      812,
      815,
      818,
      821,
      824,
      827,
      829,
      832,
      834,
      836,
      838,
      839,
      841,
      842,
      844,
      845,
      846,
      846,
      847,
      847,
      847,
      847,
      847,
      847,
      846,
      846,
      845,
      844,
      843,
      841,
      839,
      838,
      836,
      834,
      831,
      829,
      826,
      823,
      820,
      817,
      814,
      810,
      806,
      803,
      799,
      794,
      790,
      785,
      780,
      775,
      771,
      765,
      760,
      754,
      749,
      743,
      737,
      731,
      724,
      718,
      711,
      704,
      697,
      690,
      683,
      676,
      669,
      661,
      653,
      645,
      638,
      629,
      621,
      613,
    ],
    50: [
      738,
      742,
      747,
      751,
      756,
      760,
      764,
      769,
      773,
      777,
      781,
      784,
      788,
      792,
      795,
      798,
      801,
      804,
      807,
      810,
      813,
      815,
      818,
      820,
      822,
      824,
      826,
      827,
      829,
      830,
      831,
      832,
      833,
      834,
      834,
      834,
      835,
      835,
      834,
      834,
      834,
      833,
      832,
      831,
      830,
      828,
      827,
      825,
      823,
      821,
      819,
      816,
      814,
      811,
      808,
      805,
      802,
      798,
      795,
      791,
      787,
      783,
      779,
      774,
      770,
      765,
      760,
      755,
      750,
      744,
      739,
      733,
      727,
      721,
      715,
      709,
      702,
      696,
      689,
      682,
      675,
      668,
      661,
      654,
      646,
      639,
      631,
      623,
      615,
      607,
      599,
    ],
    55: [
      738,
      741,
      746,
      750,
      754,
      758,
      761,
      765,
      769,
      772,
      776,
      779,
      782,
      785,
      788,
      791,
      794,
      797,
      799,
      802,
      804,
      806,
      808,
      810,
      812,
      813,
      815,
      816,
      817,
      818,
      819,
      820,
      820,
      821,
      821,
      821,
      821,
      821,
      820,
      820,
      819,
      818,
      817,
      816,
      815,
      813,
      811,
      810,
      807,
      805,
      803,
      800,
      798,
      795,
      792,
      789,
      785,
      782,
      778,
      774,
      770,
      766,
      762,
      757,
      753,
      748,
      743,
      738,
      733,
      727,
      722,
      716,
      710,
      704,
      698,
      692,
      685,
      679,
      673,
      666,
      659,
      652,
      645,
      638,
      630,
      623,
      616,
      608,
      600,
      592,
      584,
    ],
    60: [
      738,
      741,
      745,
      748,
      752,
      755,
      758,
      761,
      765,
      768,
      770,
      773,
      776,
      779,
      781,
      784,
      786,
      788,
      790,
      792,
      794,
      796,
      798,
      799,
      801,
      802,
      803,
      804,
      805,
      806,
      806,
      807,
      807,
      807,
      807,
      807,
      807,
      806,
      806,
      805,
      804,
      803,
      801,
      800,
      799,
      797,
      795,
      793,
      791,
      788,
      786,
      783,
      780,
      777,
      774,
      771,
      767,
      764,
      760,
      756,
      752,
      748,
      743,
      739,
      734,
      730,
      725,
      720,
      714,
      709,
      704,
      698,
      692,
      686,
      680,
      674,
      668,
      661,
      655,
      648,
      641,
      635,
      628,
      621,
      613,
      606,
      599,
      591,
      584,
      576,
      568,
    ],
    65: [
      738,
      740,
      743,
      746,
      749,
      752,
      755,
      757,
      760,
      763,
      765,
      767,
      770,
      772,
      774,
      776,
      778,
      779,
      781,
      783,
      784,
      786,
      787,
      788,
      789,
      790,
      791,
      791,
      792,
      792,
      793,
      793,
      793,
      793,
      792,
      792,
      791,
      791,
      790,
      789,
      788,
      786,
      785,
      783,
      782,
      780,
      778,
      775,
      773,
      771,
      768,
      765,
      762,
      759,
      756,
      752,
      749,
      745,
      741,
      737,
      733,
      729,
      725,
      720,
      715,
      711,
      706,
      700,
      695,
      690,
      685,
      679,
      673,
      667,
      662,
      656,
      649,
      643,
      636,
      630,
      623,
      616,
      609,
      603,
      596,
      588,
      581,
      574,
      567,
      559,
      551,
    ],
    70: [
      738,
      740,
      742,
      745,
      747,
      749,
      751,
      753,
      755,
      757,
      759,
      761,
      763,
      764,
      766,
      768,
      769,
      770,
      772,
      773,
      774,
      775,
      775,
      776,
      777,
      778,
      778,
      778,
      778,
      778,
      779,
      778,
      778,
      778,
      777,
      776,
      775,
      774,
      773,
      772,
      771,
      769,
      768,
      766,
      764,
      762,
      759,
      757,
      755,
      752,
      749,
      746,
      743,
      740,
      737,
      733,
      729,
      726,
      722,
      718,
      713,
      709,
      705,
      700,
      695,
      691,
      686,
      681,
      675,
      670,
      665,
      659,
      653,
      647,
      642,
      636,
      630,
      623,
      617,
      611,
      604,
      597,
      590,
      584,
      577,
      570,
      563,
      556,
      549,
      541,
      534,
    ],
    75: [
      738,
      739,
      741,
      743,
      744,
      746,
      748,
      749,
      750,
      752,
      753,
      754,
      756,
      757,
      758,
      759,
      760,
      761,
      761,
      762,
      763,
      763,
      764,
      764,
      764,
      764,
      765,
      764,
      764,
      764,
      764,
      763,
      763,
      762,
      761,
      760,
      759,
      757,
      756,
      755,
      753,
      751,
      749,
      747,
      745,
      743,
      741,
      738,
      735,
      733,
      730,
      727,
      723,
      720,
      716,
      713,
      709,
      705,
      701,
      697,
      693,
      689,
      684,
      679,
      675,
      670,
      665,
      660,
      655,
      649,
      644,
      638,
      633,
      627,
      621,
      615,
      609,
      603,
      597,
      591,
      584,
      578,
      571,
      564,
      558,
      551,
      544,
      537,
      530,
      523,
      515,
    ],
    80: [
      738,
      738,
      740,
      741,
      742,
      743,
      744,
      745,
      746,
      746,
      747,
      748,
      748,
      749,
      749,
      750,
      750,
      751,
      751,
      751,
      751,
      752,
      751,
      751,
      751,
      751,
      751,
      750,
      750,
      749,
      748,
      748,
      746,
      745,
      744,
      743,
      742,
      740,
      738,
      737,
      735,
      733,
      731,
      729,
      726,
      724,
      721,
      718,
      716,
      713,
      710,
      706,
      703,
      700,
      696,
      692,
      688,
      684,
      680,
      676,
      672,
      667,
      663,
      658,
      654,
      649,
      644,
      639,
      633,
      628,
      623,
      617,
      612,
      606,
      600,
      594,
      588,
      582,
      576,
      570,
      564,
      557,
      550,
      544,
      537,
      531,
      525,
      517,
      510,
      504,
      497,
    ],
    85: [
      738,
      738,
      738,
      739,
      739,
      740,
      740,
      740,
      740,
      741,
      741,
      741,
      741,
      741,
      741,
      741,
      741,
      741,
      740,
      740,
      740,
      739,
      739,
      739,
      738,
      737,
      737,
      736,
      735,
      734,
      733,
      731,
      730,
      729,
      727,
      726,
      724,
      722,
      720,
      718,
      716,
      714,
      712,
      709,
      707,
      704,
      701,
      698,
      695,
      692,
      689,
      686,
      682,
      679,
      675,
      671,
      667,
      663,
      659,
      655,
      650,
      646,
      641,
      637,
      632,
      627,
      622,
      617,
      612,
      606,
      601,
      595,
      590,
      585,
      579,
      573,
      567,
      561,
      555,
      549,
      543,
      537,
      530,
      523,
      517,
      511,
      505,
      498,
      490,
      484,
      477,
    ],
    90: [
      738,
      737,
      737,
      737,
      737,
      736,
      736,
      736,
      735,
      735,
      734,
      734,
      733,
      733,
      732,
      732,
      731,
      730,
      730,
      729,
      728,
      727,
      726,
      725,
      724,
      723,
      722,
      721,
      719,
      718,
      717,
      715,
      713,
      712,
      710,
      708,
      706,
      704,
      702,
      700,
      697,
      695,
      692,
      690,
      687,
      684,
      681,
      678,
      675,
      671,
      668,
      665,
      661,
      657,
      653,
      650,
      645,
      641,
      637,
      633,
      628,
      624,
      619,
      615,
      610,
      605,
      600,
      595,
      590,
      584,
      579,
      574,
      568,
      563,
      557,
      551,
      545,
      540,
      534,
      528,
      521,
      515,
      509,
      503,
      496,
      490,
      484,
      478,
      470,
      464,
      458,
    ],
    95: [
      738,
      737,
      736,
      735,
      734,
      733,
      732,
      731,
      730,
      729,
      728,
      727,
      726,
      725,
      724,
      722,
      721,
      720,
      719,
      717,
      716,
      715,
      713,
      712,
      710,
      709,
      707,
      706,
      704,
      702,
      700,
      699,
      697,
      694,
      692,
      690,
      688,
      686,
      683,
      681,
      678,
      675,
      673,
      670,
      667,
      664,
      661,
      657,
      654,
      650,
      647,
      643,
      640,
      636,
      632,
      628,
      624,
      619,
      615,
      611,
      606,
      602,
      597,
      592,
      587,
      583,
      578,
      573,
      567,
      562,
      557,
      552,
      546,
      540,
      535,
      529,
      524,
      518,
      512,
      506,
      500,
      494,
      488,
      482,
      476,
      469,
      463,
      457,
      451,
      444,
      438,
    ],
    100: [
      738,
      736,
      734,
      733,
      731,
      730,
      728,
      727,
      725,
      723,
      722,
      720,
      718,
      716,
      715,
      713,
      711,
      709,
      708,
      706,
      704,
      702,
      700,
      698,
      697,
      694,
      692,
      690,
      688,
      686,
      684,
      682,
      680,
      677,
      675,
      672,
      670,
      667,
      664,
      662,
      659,
      656,
      653,
      650,
      647,
      643,
      640,
      636,
      633,
      629,
      626,
      622,
      618,
      614,
      610,
      606,
      602,
      597,
      593,
      589,
      584,
      579,
      575,
      570,
      565,
      560,
      555,
      550,
      545,
      540,
      535,
      529,
      524,
      519,
      513,
      507,
      502,
      496,
      490,
      485,
      479,
      473,
      467,
      461,
      455,
      449,
      443,
      437,
      431,
      425,
      418,
    ],
    105: [
      738,
      735,
      733,
      731,
      729,
      727,
      725,
      722,
      720,
      718,
      715,
      713,
      711,
      708,
      706,
      704,
      701,
      699,
      697,
      694,
      692,
      690,
      687,
      685,
      683,
      680,
      678,
      675,
      673,
      670,
      668,
      665,
      662,
      660,
      657,
      654,
      652,
      648,
      646,
      643,
      639,
      636,
      633,
      630,
      626,
      623,
      619,
      616,
      612,
      608,
      604,
      601,
      597,
      592,
      588,
      584,
      580,
      576,
      571,
      567,
      562,
      557,
      553,
      548,
      543,
      538,
      533,
      528,
      523,
      518,
      513,
      507,
      502,
      497,
      491,
      486,
      480,
      475,
      469,
      463,
      457,
      452,
      446,
      440,
      434,
      429,
      423,
      417,
      411,
      405,
      399,
    ],
    110: [
      738,
      735,
      732,
      729,
      726,
      724,
      721,
      718,
      715,
      712,
      709,
      706,
      703,
      700,
      698,
      694,
      692,
      689,
      686,
      683,
      680,
      677,
      674,
      672,
      669,
      666,
      663,
      660,
      657,
      654,
      652,
      648,
      646,
      642,
      639,
      636,
      633,
      630,
      627,
      623,
      620,
      617,
      613,
      610,
      606,
      603,
      599,
      595,
      591,
      587,
      583,
      579,
      575,
      571,
      567,
      563,
      558,
      554,
      549,
      545,
      540,
      535,
      531,
      526,
      521,
      516,
      511,
      506,
      501,
      496,
      491,
      486,
      480,
      475,
      470,
      464,
      459,
      453,
      448,
      442,
      437,
      431,
      426,
      420,
      414,
      408,
      403,
      397,
      391,
      385,
      380,
    ],
    115: [
      738,
      734,
      731,
      727,
      724,
      721,
      717,
      714,
      710,
      707,
      703,
      700,
      696,
      693,
      689,
      686,
      682,
      679,
      675,
      672,
      669,
      665,
      662,
      658,
      655,
      652,
      649,
      645,
      642,
      639,
      635,
      632,
      629,
      625,
      622,
      619,
      615,
      612,
      608,
      605,
      601,
      598,
      594,
      590,
      586,
      583,
      579,
      575,
      571,
      567,
      563,
      558,
      554,
      550,
      546,
      541,
      537,
      532,
      528,
      523,
      519,
      514,
      509,
      504,
      499,
      495,
      490,
      485,
      480,
      474,
      469,
      464,
      459,
      454,
      449,
      443,
      438,
      433,
      427,
      422,
      416,
      411,
      406,
      400,
      394,
      389,
      383,
      378,
      372,
      366,
      361,
    ],
    120: [
      738,
      733,
      730,
      726,
      722,
      718,
      714,
      710,
      706,
      701,
      697,
      693,
      689,
      685,
      681,
      677,
      673,
      669,
      665,
      661,
      657,
      653,
      650,
      646,
      642,
      638,
      635,
      631,
      627,
      623,
      620,
      616,
      612,
      609,
      605,
      601,
      598,
      594,
      590,
      586,
      583,
      579,
      575,
      571,
      567,
      563,
      559,
      555,
      551,
      547,
      542,
      538,
      534,
      529,
      525,
      520,
      516,
      511,
      507,
      502,
      498,
      493,
      488,
      483,
      478,
      473,
      469,
      464,
      458,
      454,
      449,
      443,
      438,
      433,
      428,
      423,
      417,
      412,
      407,
      402,
      396,
      391,
      386,
      380,
      375,
      370,
      365,
      359,
      354,
      348,
      343,
    ],
    125: [
      738,
      733,
      728,
      724,
      719,
      715,
      710,
      706,
      701,
      696,
      692,
      687,
      683,
      678,
      674,
      669,
      664,
      660,
      655,
      651,
      647,
      642,
      638,
      634,
      629,
      625,
      621,
      617,
      613,
      609,
      605,
      600,
      597,
      592,
      588,
      585,
      581,
      577,
      572,
      568,
      564,
      560,
      556,
      552,
      548,
      544,
      540,
      535,
      531,
      527,
      522,
      518,
      514,
      509,
      505,
      500,
      496,
      491,
      486,
      482,
      477,
      472,
      468,
      463,
      458,
      453,
      448,
      443,
      439,
      433,
      428,
      424,
      418,
      413,
      408,
      403,
      398,
      393,
      388,
      382,
      377,
      372,
      367,
      362,
      356,
      351,
      346,
      341,
      336,
      330,
      325,
    ],
    130: [
      738,
      732,
      727,
      722,
      717,
      712,
      707,
      702,
      697,
      692,
      687,
      682,
      676,
      671,
      666,
      661,
      656,
      651,
      646,
      641,
      636,
      632,
      627,
      622,
      617,
      613,
      608,
      604,
      599,
      594,
      590,
      586,
      581,
      577,
      572,
      568,
      564,
      560,
      555,
      551,
      547,
      543,
      538,
      534,
      530,
      525,
      521,
      517,
      512,
      508,
      503,
      499,
      494,
      490,
      485,
      481,
      476,
      472,
      467,
      462,
      458,
      453,
      448,
      443,
      439,
      434,
      429,
      424,
      419,
      414,
      409,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      369,
      364,
      359,
      354,
      349,
      344,
      339,
      334,
      329,
      324,
      319,
      314,
      309,
    ],
    135: [
      738,
      732,
      726,
      721,
      715,
      710,
      704,
      699,
      693,
      687,
      682,
      676,
      671,
      665,
      660,
      654,
      649,
      643,
      638,
      632,
      627,
      622,
      617,
      612,
      606,
      601,
      596,
      591,
      586,
      581,
      576,
      571,
      567,
      562,
      557,
      553,
      548,
      544,
      539,
      534,
      530,
      525,
      521,
      516,
      512,
      508,
      503,
      499,
      494,
      490,
      485,
      480,
      476,
      471,
      467,
      462,
      458,
      453,
      448,
      443,
      439,
      434,
      429,
      424,
      420,
      415,
      410,
      405,
      400,
      396,
      391,
      386,
      381,
      376,
      371,
      366,
      361,
      357,
      352,
      347,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      308,
      303,
      298,
      293,
    ],
    140: [
      738,
      731,
      726,
      720,
      714,
      708,
      702,
      696,
      690,
      683,
      677,
      671,
      665,
      659,
      654,
      648,
      642,
      636,
      630,
      625,
      619,
      613,
      608,
      602,
      596,
      591,
      585,
      580,
      574,
      569,
      564,
      558,
      553,
      548,
      543,
      538,
      533,
      528,
      523,
      518,
      514,
      509,
      504,
      500,
      495,
      490,
      486,
      481,
      477,
      472,
      467,
      463,
      458,
      453,
      449,
      444,
      440,
      435,
      430,
      425,
      421,
      416,
      411,
      407,
      402,
      397,
      392,
      388,
      383,
      378,
      373,
      368,
      364,
      359,
      354,
      349,
      345,
      340,
      335,
      330,
      325,
      321,
      316,
      311,
      306,
      301,
      297,
      292,
      288,
      283,
      278,
    ],
    145: [
      738,
      731,
      725,
      718,
      712,
      706,
      699,
      693,
      686,
      680,
      673,
      667,
      661,
      654,
      648,
      642,
      636,
      630,
      624,
      618,
      612,
      606,
      600,
      594,
      588,
      582,
      576,
      570,
      564,
      558,
      553,
      547,
      541,
      536,
      530,
      525,
      519,
      514,
      509,
      504,
      498,
      494,
      489,
      484,
      479,
      474,
      469,
      465,
      460,
      455,
      451,
      446,
      441,
      437,
      432,
      427,
      423,
      418,
      413,
      409,
      404,
      399,
      394,
      390,
      385,
      381,
      376,
      371,
      366,
      362,
      357,
      352,
      348,
      343,
      338,
      333,
      329,
      324,
      319,
      315,
      310,
      306,
      301,
      296,
      292,
      287,
      283,
      278,
      274,
      269,
      265,
    ],
    150: [
      738,
      731,
      724,
      717,
      711,
      704,
      697,
      690,
      683,
      677,
      670,
      663,
      657,
      650,
      644,
      637,
      631,
      624,
      618,
      612,
      605,
      599,
      593,
      586,
      580,
      574,
      567,
      561,
      555,
      549,
      543,
      537,
      531,
      525,
      519,
      513,
      507,
      502,
      496,
      490,
      485,
      480,
      474,
      469,
      464,
      459,
      454,
      449,
      445,
      440,
      435,
      430,
      426,
      421,
      416,
      411,
      407,
      402,
      397,
      393,
      388,
      383,
      379,
      374,
      369,
      365,
      360,
      356,
      351,
      346,
      342,
      337,
      332,
      328,
      324,
      319,
      314,
      310,
      305,
      301,
      296,
      292,
      287,
      283,
      278,
      274,
      269,
      265,
      261,
      256,
      252,
    ],
    155: [
      738,
      730,
      723,
      716,
      709,
      702,
      695,
      688,
      681,
      674,
      667,
      660,
      653,
      646,
      640,
      633,
      626,
      620,
      613,
      607,
      600,
      593,
      587,
      580,
      573,
      567,
      560,
      554,
      547,
      541,
      535,
      528,
      522,
      516,
      510,
      503,
      497,
      491,
      486,
      480,
      474,
      468,
      462,
      457,
      451,
      446,
      441,
      436,
      431,
      426,
      421,
      416,
      411,
      407,
      402,
      397,
      392,
      388,
      383,
      378,
      374,
      369,
      365,
      360,
      355,
      351,
      347,
      342,
      337,
      333,
      328,
      324,
      319,
      315,
      310,
      306,
      301,
      297,
      292,
      288,
      284,
      280,
      275,
      271,
      266,
      262,
      258,
      254,
      249,
      245,
      241,
    ],
    160: [
      738,
      730,
      723,
      716,
      708,
      701,
      694,
      686,
      679,
      672,
      664,
      657,
      650,
      643,
      636,
      630,
      623,
      616,
      609,
      602,
      595,
      588,
      581,
      574,
      568,
      561,
      555,
      548,
      541,
      534,
      528,
      521,
      515,
      508,
      502,
      495,
      489,
      483,
      477,
      471,
      465,
      459,
      453,
      447,
      441,
      436,
      430,
      424,
      419,
      414,
      409,
      404,
      399,
      395,
      390,
      385,
      380,
      376,
      371,
      366,
      362,
      357,
      353,
      348,
      343,
      339,
      334,
      330,
      326,
      321,
      317,
      312,
      308,
      303,
      299,
      295,
      290,
      286,
      282,
      278,
      273,
      269,
      265,
      260,
      256,
      252,
      248,
      244,
      240,
      236,
      232,
    ],
    165: [
      738,
      730,
      723,
      715,
      708,
      700,
      692,
      685,
      677,
      670,
      663,
      655,
      648,
      641,
      634,
      627,
      620,
      613,
      606,
      599,
      592,
      584,
      577,
      571,
      564,
      557,
      550,
      543,
      536,
      530,
      523,
      516,
      509,
      503,
      496,
      489,
      483,
      476,
      470,
      464,
      457,
      451,
      445,
      439,
      433,
      428,
      422,
      416,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      375,
      370,
      366,
      361,
      356,
      352,
      347,
      343,
      338,
      334,
      329,
      325,
      320,
      316,
      312,
      307,
      303,
      298,
      294,
      290,
      286,
      281,
      277,
      273,
      269,
      265,
      261,
      256,
      252,
      248,
      244,
      240,
      236,
      232,
      228,
      224,
    ],
    170: [
      738,
      730,
      722,
      715,
      707,
      699,
      692,
      684,
      676,
      669,
      661,
      654,
      647,
      639,
      632,
      625,
      618,
      611,
      603,
      596,
      589,
      582,
      575,
      568,
      561,
      554,
      547,
      540,
      533,
      526,
      519,
      512,
      505,
      498,
      492,
      485,
      479,
      472,
      465,
      459,
      452,
      446,
      440,
      434,
      428,
      422,
      416,
      410,
      404,
      399,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      349,
      344,
      340,
      335,
      331,
      326,
      322,
      317,
      313,
      309,
      304,
      300,
      296,
      292,
      287,
      283,
      279,
      275,
      271,
      266,
      262,
      258,
      254,
      250,
      246,
      242,
      238,
      234,
      230,
      226,
      223,
      219,
    ],
    175: [
      738,
      730,
      722,
      714,
      707,
      699,
      691,
      683,
      676,
      668,
      660,
      653,
      646,
      638,
      631,
      624,
      617,
      609,
      602,
      595,
      587,
      580,
      573,
      566,
      559,
      552,
      545,
      538,
      531,
      523,
      516,
      509,
      502,
      496,
      489,
      482,
      476,
      470,
      463,
      456,
      450,
      443,
      436,
      430,
      424,
      418,
      412,
      406,
      401,
      395,
      389,
      384,
      378,
      373,
      369,
      364,
      359,
      354,
      349,
      344,
      340,
      335,
      331,
      326,
      322,
      317,
      313,
      309,
      304,
      300,
      296,
      291,
      287,
      283,
      279,
      275,
      271,
      267,
      262,
      258,
      254,
      250,
      246,
      242,
      238,
      235,
      230,
      227,
      223,
      219,
      215,
    ],
  },
  '10': {
    0: [
      750,
      757,
      765,
      772,
      779,
      786,
      793,
      799,
      806,
      812,
      818,
      825,
      830,
      836,
      842,
      847,
      852,
      857,
      862,
      866,
      871,
      875,
      879,
      883,
      887,
      890,
      893,
      896,
      899,
      902,
      904,
      907,
      909,
      911,
      912,
      914,
      915,
      916,
      917,
      917,
      918,
      918,
      918,
      918,
      917,
      916,
      916,
      914,
      913,
      912,
      910,
      908,
      906,
      903,
      901,
      898,
      895,
      892,
      888,
      885,
      881,
      877,
      872,
      868,
      863,
      858,
      853,
      848,
      842,
      836,
      831,
      824,
      818,
      812,
      805,
      798,
      791,
      784,
      776,
      769,
      761,
      753,
      745,
      737,
      728,
      720,
      711,
      702,
      693,
      683,
      674,
    ],
    5: [
      750,
      757,
      765,
      772,
      779,
      786,
      793,
      799,
      806,
      812,
      818,
      824,
      830,
      836,
      841,
      847,
      852,
      857,
      861,
      866,
      870,
      875,
      879,
      882,
      886,
      890,
      893,
      896,
      899,
      901,
      904,
      906,
      908,
      910,
      912,
      913,
      914,
      915,
      916,
      917,
      917,
      917,
      917,
      917,
      916,
      916,
      915,
      914,
      912,
      911,
      909,
      907,
      905,
      902,
      900,
      897,
      894,
      891,
      887,
      884,
      880,
      876,
      871,
      867,
      862,
      857,
      852,
      847,
      841,
      836,
      830,
      824,
      818,
      811,
      804,
      797,
      790,
      783,
      776,
      768,
      760,
      753,
      744,
      736,
      728,
      719,
      710,
      701,
      692,
      683,
      674,
    ],
    10: [
      750,
      757,
      764,
      772,
      779,
      785,
      792,
      799,
      805,
      811,
      817,
      823,
      829,
      835,
      840,
      845,
      851,
      855,
      860,
      865,
      869,
      873,
      877,
      881,
      884,
      888,
      891,
      894,
      897,
      899,
      902,
      904,
      906,
      908,
      909,
      911,
      912,
      913,
      914,
      914,
      915,
      915,
      915,
      914,
      914,
      913,
      912,
      911,
      910,
      908,
      906,
      904,
      902,
      900,
      897,
      894,
      891,
      888,
      885,
      881,
      877,
      873,
      869,
      864,
      860,
      855,
      850,
      844,
      839,
      833,
      827,
      821,
      815,
      808,
      802,
      795,
      788,
      781,
      773,
      766,
      758,
      750,
      742,
      734,
      725,
      717,
      708,
      699,
      690,
      681,
      671,
    ],
    15: [
      750,
      757,
      764,
      771,
      778,
      785,
      791,
      798,
      804,
      810,
      816,
      822,
      828,
      833,
      838,
      843,
      848,
      853,
      858,
      862,
      867,
      871,
      874,
      878,
      882,
      885,
      888,
      891,
      894,
      896,
      899,
      901,
      903,
      904,
      906,
      907,
      908,
      909,
      910,
      911,
      911,
      911,
      911,
      910,
      910,
      909,
      908,
      907,
      906,
      904,
      902,
      900,
      898,
      896,
      893,
      890,
      887,
      884,
      880,
      877,
      873,
      869,
      864,
      860,
      855,
      850,
      845,
      840,
      834,
      829,
      823,
      817,
      811,
      804,
      798,
      791,
      784,
      777,
      769,
      762,
      754,
      746,
      738,
      730,
      721,
      713,
      704,
      696,
      687,
      677,
      668,
    ],
    20: [
      750,
      757,
      764,
      771,
      777,
      784,
      790,
      796,
      803,
      808,
      814,
      820,
      825,
      831,
      836,
      841,
      846,
      850,
      855,
      859,
      863,
      867,
      871,
      875,
      878,
      881,
      884,
      887,
      890,
      892,
      894,
      896,
      898,
      900,
      901,
      902,
      903,
      904,
      905,
      905,
      906,
      906,
      905,
      905,
      904,
      904,
      903,
      901,
      900,
      898,
      896,
      894,
      892,
      890,
      887,
      884,
      881,
      878,
      874,
      870,
      867,
      863,
      858,
      854,
      849,
      844,
      839,
      834,
      828,
      823,
      817,
      811,
      805,
      798,
      792,
      785,
      778,
      771,
      763,
      756,
      748,
      741,
      733,
      724,
      716,
      708,
      699,
      690,
      681,
      672,
      663,
    ],
    25: [
      750,
      757,
      763,
      770,
      776,
      783,
      789,
      795,
      801,
      806,
      812,
      817,
      823,
      828,
      833,
      838,
      842,
      847,
      851,
      855,
      859,
      863,
      866,
      870,
      873,
      876,
      879,
      882,
      884,
      886,
      888,
      890,
      892,
      894,
      895,
      896,
      897,
      898,
      898,
      899,
      899,
      899,
      898,
      898,
      897,
      896,
      895,
      894,
      892,
      891,
      889,
      887,
      884,
      882,
      879,
      876,
      873,
      870,
      866,
      863,
      859,
      855,
      850,
      846,
      841,
      836,
      831,
      826,
      821,
      815,
      809,
      803,
      797,
      791,
      784,
      777,
      770,
      763,
      756,
      749,
      741,
      733,
      725,
      717,
      709,
      701,
      692,
      684,
      675,
      666,
      657,
    ],
    30: [
      750,
      756,
      763,
      769,
      775,
      781,
      787,
      793,
      798,
      804,
      809,
      814,
      819,
      824,
      829,
      833,
      838,
      842,
      846,
      850,
      854,
      857,
      861,
      864,
      867,
      870,
      873,
      875,
      877,
      880,
      882,
      883,
      885,
      886,
      887,
      888,
      889,
      890,
      890,
      890,
      890,
      890,
      890,
      889,
      889,
      888,
      886,
      885,
      883,
      882,
      880,
      878,
      875,
      873,
      870,
      867,
      864,
      860,
      857,
      853,
      849,
      845,
      841,
      836,
      831,
      827,
      822,
      816,
      811,
      805,
      799,
      793,
      787,
      781,
      774,
      768,
      761,
      754,
      747,
      739,
      732,
      724,
      717,
      709,
      700,
      692,
      684,
      675,
      667,
      658,
      649,
    ],
    35: [
      750,
      756,
      762,
      768,
      774,
      779,
      785,
      790,
      796,
      801,
      806,
      811,
      815,
      820,
      824,
      829,
      833,
      837,
      841,
      844,
      848,
      851,
      854,
      857,
      860,
      863,
      865,
      868,
      870,
      872,
      873,
      875,
      876,
      878,
      879,
      879,
      880,
      881,
      881,
      881,
      881,
      880,
      880,
      879,
      878,
      877,
      876,
      875,
      873,
      871,
      869,
      867,
      864,
      862,
      859,
      856,
      853,
      849,
      846,
      842,
      838,
      834,
      830,
      825,
      820,
      815,
      810,
      805,
      800,
      794,
      788,
      782,
      776,
      770,
      763,
      757,
      750,
      743,
      736,
      729,
      721,
      714,
      706,
      698,
      690,
      682,
      674,
      666,
      657,
      648,
      640,
    ],
    40: [
      750,
      756,
      761,
      767,
      772,
      777,
      783,
      788,
      793,
      797,
      802,
      807,
      811,
      815,
      819,
      823,
      827,
      831,
      834,
      838,
      841,
      844,
      847,
      850,
      852,
      855,
      857,
      859,
      861,
      863,
      864,
      866,
      867,
      868,
      869,
      869,
      870,
      870,
      870,
      870,
      870,
      869,
      869,
      868,
      867,
      866,
      864,
      863,
      861,
      859,
      857,
      854,
      852,
      849,
      846,
      843,
      840,
      836,
      833,
      829,
      825,
      821,
      817,
      812,
      807,
      802,
      797,
      792,
      787,
      781,
      775,
      769,
      763,
      757,
      751,
      744,
      737,
      731,
      724,
      716,
      709,
      702,
      694,
      686,
      678,
      670,
      662,
      654,
      646,
      637,
      629,
    ],
    45: [
      750,
      755,
      760,
      765,
      770,
      775,
      780,
      785,
      789,
      794,
      798,
      802,
      806,
      810,
      814,
      817,
      821,
      824,
      827,
      831,
      833,
      836,
      839,
      841,
      844,
      846,
      848,
      850,
      851,
      853,
      854,
      855,
      856,
      857,
      858,
      858,
      858,
      858,
      858,
      858,
      858,
      857,
      856,
      855,
      854,
      853,
      851,
      849,
      847,
      845,
      843,
      841,
      838,
      835,
      832,
      829,
      826,
      822,
      819,
      815,
      811,
      806,
      802,
      798,
      793,
      788,
      783,
      778,
      772,
      766,
      761,
      755,
      749,
      743,
      736,
      730,
      723,
      717,
      710,
      702,
      695,
      688,
      680,
      673,
      665,
      657,
      649,
      641,
      633,
      624,
      616,
    ],
    50: [
      750,
      755,
      759,
      764,
      768,
      773,
      777,
      781,
      785,
      789,
      793,
      797,
      801,
      804,
      808,
      811,
      814,
      817,
      820,
      823,
      825,
      828,
      830,
      832,
      834,
      836,
      838,
      839,
      840,
      842,
      843,
      844,
      844,
      845,
      845,
      846,
      846,
      845,
      845,
      845,
      844,
      843,
      842,
      841,
      840,
      838,
      837,
      835,
      833,
      831,
      828,
      826,
      823,
      820,
      817,
      814,
      810,
      807,
      803,
      799,
      795,
      791,
      786,
      782,
      777,
      772,
      767,
      762,
      756,
      751,
      745,
      739,
      733,
      727,
      721,
      714,
      708,
      701,
      694,
      687,
      680,
      673,
      665,
      658,
      650,
      643,
      635,
      627,
      619,
      610,
      602,
    ],
    55: [
      750,
      754,
      758,
      762,
      766,
      770,
      774,
      778,
      782,
      785,
      788,
      792,
      795,
      798,
      801,
      804,
      806,
      809,
      812,
      814,
      816,
      818,
      820,
      822,
      824,
      825,
      827,
      828,
      829,
      830,
      831,
      831,
      832,
      832,
      832,
      832,
      832,
      832,
      831,
      830,
      830,
      829,
      827,
      826,
      825,
      823,
      821,
      819,
      817,
      815,
      812,
      809,
      807,
      804,
      800,
      797,
      793,
      790,
      786,
      782,
      778,
      774,
      769,
      765,
      760,
      755,
      750,
      745,
      739,
      734,
      728,
      722,
      716,
      710,
      704,
      697,
      691,
      684,
      678,
      671,
      664,
      657,
      649,
      642,
      634,
      627,
      619,
      611,
      603,
      595,
      588,
    ],
    60: [
      750,
      754,
      757,
      761,
      764,
      768,
      771,
      774,
      777,
      780,
      783,
      786,
      789,
      791,
      794,
      796,
      799,
      801,
      803,
      805,
      807,
      808,
      810,
      811,
      813,
      814,
      815,
      816,
      816,
      817,
      818,
      818,
      818,
      818,
      818,
      818,
      817,
      817,
      816,
      815,
      814,
      813,
      812,
      810,
      808,
      807,
      805,
      802,
      800,
      798,
      795,
      792,
      789,
      786,
      783,
      779,
      776,
      772,
      768,
      764,
      760,
      756,
      751,
      746,
      742,
      737,
      731,
      726,
      721,
      716,
      710,
      704,
      698,
      692,
      686,
      679,
      673,
      666,
      660,
      653,
      646,
      639,
      632,
      625,
      617,
      610,
      603,
      595,
      587,
      579,
      572,
    ],
    65: [
      750,
      753,
      756,
      759,
      762,
      765,
      768,
      770,
      773,
      775,
      778,
      780,
      782,
      784,
      786,
      788,
      790,
      792,
      794,
      795,
      796,
      798,
      799,
      800,
      801,
      802,
      803,
      803,
      804,
      804,
      804,
      804,
      804,
      804,
      803,
      803,
      802,
      801,
      800,
      799,
      798,
      796,
      795,
      793,
      791,
      789,
      787,
      785,
      782,
      780,
      777,
      774,
      771,
      768,
      764,
      761,
      757,
      753,
      749,
      745,
      741,
      736,
      732,
      727,
      722,
      717,
      712,
      707,
      702,
      696,
      691,
      684,
      679,
      673,
      667,
      661,
      654,
      648,
      641,
      635,
      628,
      621,
      614,
      607,
      599,
      592,
      585,
      577,
      570,
      562,
      555,
    ],
    70: [
      750,
      752,
      755,
      757,
      759,
      762,
      764,
      766,
      768,
      770,
      772,
      774,
      775,
      777,
      778,
      780,
      781,
      783,
      784,
      785,
      786,
      787,
      787,
      788,
      789,
      789,
      789,
      790,
      790,
      790,
      790,
      789,
      789,
      788,
      788,
      787,
      786,
      785,
      784,
      782,
      781,
      779,
      777,
      775,
      773,
      771,
      769,
      766,
      764,
      761,
      758,
      755,
      752,
      748,
      745,
      741,
      737,
      733,
      729,
      725,
      721,
      716,
      712,
      707,
      702,
      697,
      692,
      687,
      681,
      676,
      671,
      665,
      659,
      653,
      647,
      641,
      634,
      628,
      622,
      615,
      609,
      601,
      595,
      588,
      581,
      573,
      566,
      559,
      552,
      544,
      537,
    ],
    75: [
      750,
      752,
      754,
      755,
      757,
      759,
      760,
      762,
      763,
      764,
      766,
      767,
      768,
      769,
      770,
      771,
      772,
      773,
      773,
      774,
      775,
      775,
      776,
      776,
      776,
      776,
      776,
      776,
      776,
      775,
      775,
      774,
      773,
      772,
      771,
      770,
      769,
      768,
      766,
      765,
      763,
      761,
      759,
      757,
      755,
      752,
      750,
      747,
      744,
      741,
      738,
      735,
      732,
      728,
      725,
      721,
      717,
      713,
      709,
      705,
      700,
      696,
      691,
      686,
      681,
      676,
      671,
      666,
      661,
      655,
      650,
      644,
      638,
      632,
      626,
      620,
      614,
      607,
      601,
      595,
      589,
      582,
      575,
      568,
      561,
      555,
      547,
      540,
      533,
      526,
      518,
    ],
    80: [
      750,
      751,
      752,
      753,
      754,
      756,
      756,
      757,
      758,
      759,
      760,
      760,
      761,
      761,
      762,
      762,
      763,
      763,
      763,
      763,
      763,
      763,
      763,
      763,
      763,
      762,
      762,
      761,
      761,
      760,
      759,
      758,
      757,
      756,
      755,
      753,
      752,
      750,
      748,
      746,
      745,
      743,
      740,
      738,
      735,
      733,
      730,
      727,
      724,
      721,
      718,
      715,
      711,
      708,
      704,
      700,
      696,
      692,
      688,
      683,
      679,
      674,
      670,
      665,
      660,
      655,
      650,
      645,
      639,
      634,
      628,
      623,
      617,
      611,
      605,
      599,
      593,
      587,
      580,
      574,
      568,
      562,
      554,
      548,
      541,
      535,
      528,
      520,
      514,
      507,
      500,
    ],
    85: [
      750,
      750,
      751,
      751,
      752,
      752,
      753,
      753,
      753,
      753,
      753,
      753,
      753,
      753,
      753,
      753,
      753,
      753,
      752,
      752,
      751,
      751,
      750,
      750,
      749,
      748,
      748,
      747,
      746,
      745,
      743,
      742,
      741,
      739,
      737,
      736,
      734,
      732,
      730,
      728,
      726,
      723,
      721,
      718,
      716,
      713,
      710,
      707,
      704,
      701,
      697,
      694,
      690,
      686,
      682,
      679,
      674,
      670,
      666,
      662,
      657,
      653,
      648,
      643,
      638,
      633,
      628,
      623,
      617,
      612,
      606,
      601,
      595,
      589,
      584,
      577,
      572,
      566,
      559,
      553,
      547,
      540,
      534,
      527,
      521,
      514,
      508,
      501,
      493,
      487,
      480,
    ],
    90: [
      750,
      750,
      750,
      750,
      749,
      749,
      749,
      748,
      748,
      747,
      747,
      746,
      746,
      745,
      744,
      744,
      743,
      742,
      741,
      741,
      739,
      739,
      737,
      737,
      735,
      734,
      733,
      732,
      730,
      729,
      727,
      726,
      724,
      722,
      720,
      718,
      716,
      714,
      711,
      709,
      707,
      704,
      701,
      699,
      696,
      693,
      690,
      686,
      683,
      679,
      676,
      672,
      669,
      665,
      661,
      657,
      653,
      648,
      644,
      639,
      635,
      630,
      626,
      621,
      616,
      611,
      606,
      601,
      595,
      590,
      584,
      579,
      573,
      567,
      562,
      556,
      550,
      544,
      538,
      532,
      525,
      519,
      513,
      507,
      500,
      493,
      487,
      480,
      473,
      467,
      460,
    ],
    95: [
      750,
      749,
      748,
      748,
      747,
      746,
      745,
      744,
      743,
      742,
      740,
      739,
      738,
      737,
      736,
      734,
      733,
      732,
      730,
      729,
      727,
      726,
      724,
      723,
      721,
      720,
      718,
      716,
      714,
      713,
      711,
      709,
      707,
      705,
      702,
      700,
      698,
      695,
      692,
      690,
      687,
      684,
      681,
      678,
      675,
      672,
      669,
      665,
      662,
      658,
      654,
      651,
      647,
      643,
      639,
      635,
      630,
      626,
      622,
      617,
      613,
      608,
      603,
      598,
      593,
      588,
      583,
      578,
      573,
      567,
      562,
      556,
      551,
      545,
      539,
      534,
      528,
      522,
      516,
      510,
      504,
      497,
      491,
      485,
      479,
      472,
      466,
      460,
      454,
      447,
      440,
    ],
    100: [
      750,
      748,
      747,
      746,
      744,
      742,
      741,
      739,
      738,
      736,
      734,
      732,
      730,
      729,
      727,
      725,
      723,
      721,
      719,
      717,
      715,
      713,
      711,
      709,
      707,
      705,
      703,
      701,
      699,
      696,
      694,
      692,
      689,
      687,
      684,
      682,
      679,
      676,
      673,
      670,
      667,
      664,
      661,
      658,
      655,
      651,
      648,
      644,
      640,
      637,
      633,
      629,
      625,
      621,
      617,
      612,
      608,
      604,
      599,
      595,
      590,
      585,
      580,
      575,
      570,
      566,
      560,
      555,
      550,
      545,
      539,
      534,
      528,
      523,
      517,
      511,
      506,
      500,
      494,
      488,
      482,
      476,
      470,
      464,
      458,
      452,
      445,
      439,
      433,
      427,
      420,
    ],
    105: [
      750,
      748,
      746,
      744,
      742,
      739,
      737,
      735,
      732,
      730,
      728,
      725,
      723,
      720,
      718,
      715,
      713,
      711,
      708,
      706,
      703,
      701,
      698,
      696,
      693,
      691,
      688,
      685,
      683,
      680,
      678,
      675,
      672,
      669,
      666,
      663,
      660,
      657,
      654,
      651,
      648,
      644,
      641,
      638,
      634,
      630,
      627,
      623,
      619,
      615,
      611,
      607,
      603,
      599,
      595,
      590,
      586,
      581,
      577,
      572,
      567,
      563,
      558,
      553,
      548,
      543,
      538,
      533,
      527,
      522,
      517,
      511,
      506,
      500,
      495,
      489,
      483,
      478,
      472,
      466,
      460,
      455,
      449,
      443,
      437,
      431,
      425,
      419,
      413,
      407,
      401,
    ],
    110: [
      750,
      747,
      745,
      742,
      739,
      736,
      733,
      730,
      727,
      724,
      721,
      718,
      715,
      712,
      709,
      706,
      703,
      700,
      697,
      694,
      691,
      688,
      685,
      682,
      679,
      676,
      673,
      670,
      667,
      664,
      661,
      658,
      655,
      652,
      649,
      645,
      642,
      638,
      635,
      632,
      628,
      625,
      621,
      617,
      614,
      610,
      606,
      602,
      598,
      594,
      590,
      586,
      581,
      577,
      573,
      568,
      564,
      559,
      555,
      550,
      545,
      540,
      535,
      531,
      526,
      520,
      515,
      510,
      505,
      500,
      494,
      489,
      484,
      478,
      473,
      467,
      462,
      456,
      451,
      445,
      439,
      433,
      428,
      422,
      416,
      410,
      405,
      399,
      393,
      387,
      381,
    ],
    115: [
      750,
      747,
      743,
      740,
      737,
      733,
      730,
      726,
      722,
      719,
      715,
      712,
      708,
      705,
      701,
      697,
      694,
      690,
      686,
      683,
      679,
      676,
      672,
      669,
      665,
      662,
      659,
      655,
      652,
      648,
      645,
      641,
      638,
      634,
      631,
      627,
      624,
      620,
      616,
      613,
      609,
      605,
      601,
      597,
      593,
      589,
      585,
      581,
      577,
      573,
      569,
      564,
      560,
      556,
      551,
      547,
      542,
      537,
      533,
      528,
      523,
      518,
      513,
      509,
      504,
      498,
      493,
      488,
      483,
      478,
      473,
      467,
      462,
      457,
      451,
      446,
      440,
      435,
      430,
      424,
      418,
      413,
      407,
      401,
      396,
      390,
      385,
      379,
      373,
      368,
      362,
    ],
    120: [
      750,
      746,
      742,
      738,
      734,
      730,
      726,
      722,
      718,
      714,
      709,
      705,
      701,
      697,
      693,
      689,
      684,
      680,
      676,
      672,
      668,
      664,
      660,
      656,
      652,
      648,
      644,
      640,
      637,
      633,
      629,
      625,
      621,
      617,
      613,
      610,
      606,
      602,
      598,
      594,
      590,
      586,
      582,
      578,
      574,
      569,
      565,
      561,
      557,
      552,
      548,
      543,
      539,
      535,
      530,
      525,
      521,
      516,
      511,
      507,
      502,
      497,
      492,
      487,
      482,
      477,
      472,
      467,
      462,
      456,
      451,
      446,
      441,
      436,
      430,
      425,
      420,
      414,
      409,
      404,
      398,
      393,
      387,
      382,
      376,
      371,
      365,
      360,
      354,
      349,
      343,
    ],
    125: [
      750,
      745,
      741,
      736,
      732,
      727,
      723,
      718,
      713,
      709,
      704,
      699,
      694,
      690,
      685,
      680,
      676,
      671,
      666,
      662,
      657,
      653,
      648,
      644,
      639,
      635,
      631,
      626,
      622,
      618,
      613,
      609,
      605,
      601,
      597,
      592,
      588,
      584,
      580,
      576,
      571,
      567,
      563,
      559,
      554,
      550,
      546,
      541,
      537,
      532,
      528,
      523,
      519,
      514,
      509,
      505,
      500,
      495,
      490,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      446,
      441,
      436,
      431,
      426,
      421,
      415,
      410,
      405,
      400,
      395,
      389,
      384,
      379,
      373,
      368,
      363,
      357,
      352,
      347,
      342,
      336,
      331,
      325,
    ],
    130: [
      750,
      745,
      740,
      735,
      730,
      725,
      720,
      714,
      709,
      704,
      698,
      693,
      688,
      683,
      678,
      672,
      667,
      662,
      657,
      652,
      647,
      642,
      637,
      632,
      627,
      622,
      617,
      613,
      608,
      603,
      599,
      594,
      589,
      585,
      580,
      576,
      571,
      567,
      562,
      558,
      553,
      549,
      544,
      540,
      535,
      531,
      527,
      522,
      517,
      513,
      508,
      504,
      499,
      494,
      490,
      485,
      480,
      475,
      470,
      466,
      461,
      456,
      451,
      446,
      441,
      436,
      431,
      426,
      421,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      380,
      375,
      370,
      365,
      360,
      355,
      350,
      345,
      339,
      334,
      329,
      324,
      319,
      314,
      309,
    ],
    135: [
      750,
      744,
      739,
      733,
      728,
      722,
      717,
      711,
      705,
      699,
      694,
      688,
      682,
      676,
      671,
      665,
      660,
      654,
      648,
      643,
      637,
      632,
      627,
      621,
      616,
      611,
      605,
      600,
      595,
      590,
      585,
      580,
      575,
      570,
      565,
      560,
      555,
      550,
      546,
      541,
      536,
      532,
      527,
      522,
      518,
      513,
      508,
      504,
      499,
      494,
      489,
      485,
      480,
      475,
      470,
      466,
      461,
      456,
      451,
      446,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      367,
      362,
      357,
      352,
      348,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      307,
      303,
      297,
      293,
    ],
    140: [
      750,
      744,
      738,
      732,
      726,
      720,
      714,
      708,
      701,
      695,
      689,
      683,
      677,
      671,
      665,
      659,
      653,
      646,
      641,
      635,
      629,
      623,
      617,
      612,
      606,
      600,
      594,
      589,
      583,
      578,
      572,
      567,
      561,
      556,
      551,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      505,
      500,
      496,
      491,
      486,
      481,
      476,
      472,
      467,
      462,
      457,
      452,
      448,
      443,
      438,
      433,
      428,
      423,
      418,
      414,
      409,
      404,
      399,
      394,
      389,
      384,
      379,
      375,
      370,
      365,
      360,
      355,
      350,
      345,
      340,
      335,
      331,
      325,
      321,
      316,
      311,
      306,
      301,
      297,
      292,
      287,
      283,
      278,
    ],
    145: [
      750,
      744,
      737,
      731,
      724,
      718,
      711,
      705,
      698,
      692,
      685,
      678,
      672,
      665,
      659,
      653,
      646,
      640,
      634,
      628,
      621,
      615,
      609,
      603,
      597,
      591,
      585,
      579,
      573,
      567,
      561,
      555,
      549,
      544,
      538,
      532,
      527,
      521,
      515,
      510,
      505,
      500,
      494,
      489,
      484,
      479,
      474,
      469,
      464,
      460,
      455,
      450,
      445,
      440,
      435,
      430,
      425,
      421,
      416,
      411,
      406,
      401,
      397,
      392,
      387,
      382,
      377,
      372,
      367,
      363,
      358,
      353,
      348,
      344,
      339,
      334,
      329,
      324,
      320,
      315,
      310,
      306,
      301,
      296,
      291,
      287,
      282,
      278,
      273,
      268,
      264,
    ],
    150: [
      750,
      743,
      737,
      730,
      723,
      716,
      709,
      702,
      695,
      688,
      681,
      675,
      668,
      661,
      654,
      648,
      641,
      634,
      628,
      621,
      615,
      609,
      602,
      596,
      589,
      583,
      577,
      570,
      564,
      558,
      551,
      545,
      539,
      533,
      527,
      521,
      515,
      509,
      503,
      497,
      491,
      486,
      480,
      475,
      470,
      464,
      459,
      454,
      449,
      444,
      439,
      434,
      429,
      424,
      419,
      414,
      409,
      405,
      400,
      395,
      390,
      385,
      381,
      376,
      371,
      366,
      362,
      357,
      352,
      347,
      343,
      338,
      333,
      328,
      324,
      319,
      315,
      310,
      305,
      301,
      296,
      292,
      287,
      282,
      278,
      274,
      269,
      264,
      260,
      256,
      251,
    ],
    155: [
      750,
      743,
      736,
      729,
      722,
      715,
      707,
      700,
      693,
      686,
      678,
      671,
      664,
      657,
      650,
      643,
      637,
      630,
      623,
      616,
      609,
      603,
      596,
      589,
      582,
      576,
      569,
      563,
      556,
      549,
      543,
      536,
      530,
      524,
      517,
      511,
      505,
      499,
      493,
      487,
      480,
      475,
      469,
      463,
      457,
      451,
      446,
      441,
      435,
      430,
      425,
      420,
      415,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      376,
      371,
      366,
      361,
      357,
      352,
      347,
      343,
      338,
      333,
      329,
      324,
      320,
      315,
      310,
      306,
      301,
      297,
      292,
      288,
      284,
      279,
      275,
      270,
      266,
      261,
      257,
      253,
      249,
      244,
      240,
    ],
    160: [
      750,
      743,
      735,
      728,
      721,
      713,
      706,
      698,
      691,
      683,
      676,
      668,
      661,
      654,
      647,
      640,
      633,
      626,
      619,
      612,
      605,
      598,
      591,
      584,
      577,
      570,
      563,
      556,
      550,
      543,
      536,
      529,
      523,
      516,
      510,
      503,
      497,
      490,
      484,
      478,
      471,
      465,
      459,
      453,
      447,
      441,
      435,
      430,
      424,
      418,
      413,
      408,
      403,
      398,
      393,
      387,
      382,
      378,
      373,
      368,
      363,
      358,
      354,
      349,
      344,
      340,
      335,
      331,
      326,
      322,
      317,
      312,
      308,
      303,
      299,
      295,
      290,
      286,
      281,
      277,
      273,
      269,
      264,
      260,
      255,
      251,
      247,
      243,
      239,
      235,
      231,
    ],
    165: [
      750,
      742,
      735,
      727,
      720,
      712,
      705,
      697,
      689,
      682,
      674,
      666,
      659,
      652,
      644,
      637,
      630,
      623,
      616,
      609,
      601,
      594,
      587,
      580,
      573,
      566,
      559,
      552,
      545,
      538,
      531,
      524,
      517,
      510,
      504,
      497,
      490,
      484,
      477,
      470,
      464,
      458,
      451,
      445,
      439,
      433,
      427,
      421,
      415,
      410,
      404,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      344,
      339,
      334,
      330,
      325,
      321,
      316,
      311,
      307,
      303,
      298,
      294,
      289,
      285,
      281,
      277,
      272,
      268,
      264,
      260,
      256,
      252,
      247,
      243,
      239,
      235,
      231,
      227,
      223,
    ],
    170: [
      750,
      742,
      735,
      727,
      719,
      712,
      704,
      696,
      688,
      680,
      672,
      665,
      657,
      650,
      643,
      635,
      628,
      621,
      614,
      606,
      599,
      591,
      584,
      577,
      570,
      563,
      555,
      548,
      541,
      535,
      527,
      520,
      513,
      506,
      499,
      493,
      486,
      479,
      473,
      466,
      459,
      452,
      446,
      439,
      433,
      427,
      421,
      415,
      409,
      403,
      398,
      392,
      386,
      381,
      376,
      371,
      365,
      360,
      355,
      350,
      345,
      341,
      336,
      331,
      327,
      322,
      318,
      313,
      309,
      304,
      300,
      296,
      291,
      287,
      283,
      278,
      274,
      270,
      266,
      262,
      257,
      253,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
      217,
    ],
    175: [
      750,
      742,
      735,
      727,
      719,
      711,
      703,
      695,
      687,
      679,
      672,
      664,
      656,
      649,
      641,
      634,
      627,
      619,
      612,
      605,
      597,
      590,
      582,
      575,
      568,
      561,
      553,
      547,
      539,
      532,
      525,
      517,
      510,
      503,
      497,
      490,
      483,
      477,
      470,
      463,
      457,
      450,
      443,
      436,
      430,
      424,
      418,
      411,
      405,
      399,
      394,
      388,
      382,
      377,
      371,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      327,
      322,
      318,
      313,
      309,
      304,
      300,
      296,
      291,
      287,
      283,
      279,
      274,
      270,
      266,
      262,
      257,
      254,
      249,
      245,
      241,
      237,
      233,
      230,
      226,
      222,
      218,
      214,
    ],
  },
  '11': {
    0: [
      750,
      756,
      763,
      769,
      776,
      782,
      788,
      794,
      800,
      806,
      811,
      817,
      822,
      827,
      832,
      837,
      841,
      845,
      849,
      853,
      857,
      861,
      864,
      868,
      871,
      874,
      876,
      879,
      881,
      883,
      885,
      887,
      888,
      890,
      891,
      892,
      892,
      893,
      893,
      893,
      893,
      893,
      893,
      892,
      891,
      890,
      889,
      887,
      885,
      883,
      881,
      879,
      877,
      874,
      871,
      868,
      865,
      861,
      857,
      853,
      849,
      845,
      840,
      836,
      831,
      826,
      820,
      815,
      809,
      803,
      797,
      791,
      785,
      778,
      772,
      765,
      757,
      750,
      743,
      735,
      727,
      719,
      711,
      703,
      694,
      686,
      677,
      668,
      659,
      650,
      641,
    ],
    5: [
      750,
      756,
      763,
      769,
      776,
      782,
      788,
      794,
      800,
      806,
      811,
      816,
      822,
      827,
      831,
      836,
      841,
      845,
      849,
      853,
      857,
      860,
      864,
      867,
      870,
      873,
      876,
      878,
      881,
      883,
      884,
      886,
      888,
      889,
      890,
      891,
      892,
      892,
      893,
      893,
      893,
      892,
      892,
      891,
      890,
      889,
      888,
      886,
      885,
      883,
      881,
      878,
      876,
      873,
      870,
      867,
      864,
      860,
      856,
      853,
      848,
      844,
      840,
      835,
      830,
      825,
      820,
      814,
      808,
      803,
      797,
      790,
      784,
      777,
      770,
      764,
      757,
      749,
      742,
      734,
      727,
      718,
      710,
      702,
      694,
      685,
      676,
      667,
      659,
      649,
      640,
    ],
    10: [
      750,
      756,
      763,
      769,
      775,
      782,
      788,
      794,
      799,
      805,
      810,
      816,
      821,
      826,
      830,
      835,
      840,
      844,
      848,
      852,
      856,
      859,
      862,
      866,
      869,
      871,
      874,
      877,
      879,
      881,
      883,
      884,
      886,
      887,
      888,
      889,
      890,
      890,
      891,
      891,
      891,
      890,
      890,
      889,
      888,
      887,
      886,
      884,
      882,
      880,
      878,
      876,
      873,
      871,
      868,
      865,
      861,
      858,
      854,
      850,
      846,
      842,
      837,
      833,
      828,
      822,
      817,
      812,
      806,
      800,
      794,
      788,
      782,
      775,
      768,
      761,
      754,
      747,
      740,
      732,
      724,
      716,
      708,
      700,
      692,
      683,
      674,
      665,
      656,
      648,
      638,
    ],
    15: [
      750,
      756,
      762,
      769,
      775,
      781,
      787,
      793,
      798,
      804,
      809,
      814,
      819,
      824,
      829,
      833,
      838,
      842,
      846,
      850,
      853,
      857,
      860,
      863,
      866,
      869,
      871,
      874,
      876,
      878,
      880,
      881,
      883,
      884,
      885,
      886,
      887,
      887,
      887,
      887,
      887,
      887,
      886,
      885,
      884,
      883,
      882,
      880,
      879,
      877,
      874,
      872,
      870,
      867,
      864,
      861,
      857,
      854,
      850,
      846,
      842,
      838,
      833,
      829,
      824,
      818,
      813,
      808,
      802,
      796,
      790,
      784,
      778,
      771,
      765,
      758,
      751,
      743,
      736,
      728,
      721,
      713,
      705,
      697,
      688,
      680,
      671,
      662,
      653,
      645,
      635,
    ],
    20: [
      750,
      756,
      762,
      768,
      774,
      780,
      786,
      791,
      797,
      802,
      807,
      812,
      817,
      822,
      827,
      831,
      835,
      839,
      843,
      847,
      850,
      854,
      857,
      860,
      863,
      865,
      868,
      870,
      872,
      874,
      876,
      877,
      879,
      880,
      881,
      881,
      882,
      882,
      882,
      882,
      882,
      882,
      881,
      880,
      879,
      878,
      877,
      875,
      873,
      871,
      869,
      867,
      864,
      861,
      858,
      855,
      852,
      848,
      844,
      841,
      836,
      832,
      828,
      823,
      818,
      813,
      808,
      802,
      797,
      791,
      785,
      779,
      772,
      766,
      759,
      752,
      745,
      738,
      731,
      723,
      716,
      708,
      700,
      692,
      684,
      675,
      667,
      658,
      649,
      640,
      631,
    ],
    25: [
      750,
      755,
      762,
      767,
      773,
      779,
      785,
      790,
      795,
      800,
      805,
      810,
      815,
      819,
      824,
      828,
      832,
      836,
      840,
      843,
      846,
      850,
      853,
      855,
      858,
      861,
      863,
      865,
      867,
      869,
      870,
      872,
      873,
      874,
      875,
      876,
      876,
      876,
      876,
      876,
      876,
      875,
      875,
      874,
      873,
      872,
      870,
      868,
      867,
      864,
      862,
      860,
      857,
      854,
      851,
      848,
      845,
      841,
      837,
      833,
      829,
      825,
      820,
      816,
      811,
      806,
      801,
      795,
      790,
      784,
      778,
      772,
      765,
      759,
      752,
      745,
      739,
      732,
      724,
      717,
      709,
      701,
      694,
      686,
      677,
      669,
      661,
      652,
      643,
      634,
      626,
    ],
    30: [
      750,
      755,
      761,
      767,
      772,
      778,
      783,
      788,
      793,
      798,
      803,
      807,
      812,
      816,
      820,
      824,
      828,
      832,
      835,
      838,
      842,
      845,
      848,
      850,
      853,
      855,
      857,
      859,
      861,
      863,
      864,
      865,
      866,
      867,
      868,
      868,
      869,
      869,
      869,
      869,
      868,
      868,
      867,
      866,
      865,
      863,
      862,
      860,
      858,
      856,
      854,
      851,
      849,
      846,
      843,
      840,
      836,
      832,
      829,
      825,
      821,
      816,
      812,
      807,
      802,
      797,
      792,
      786,
      781,
      775,
      769,
      763,
      757,
      750,
      744,
      737,
      730,
      723,
      716,
      709,
      701,
      693,
      686,
      677,
      670,
      661,
      653,
      645,
      636,
      627,
      619,
    ],
    35: [
      750,
      755,
      760,
      766,
      771,
      776,
      781,
      786,
      791,
      795,
      800,
      804,
      808,
      812,
      816,
      820,
      823,
      827,
      830,
      833,
      836,
      839,
      841,
      844,
      846,
      848,
      850,
      852,
      854,
      855,
      857,
      858,
      859,
      859,
      860,
      860,
      860,
      860,
      860,
      860,
      859,
      859,
      858,
      857,
      855,
      854,
      852,
      851,
      849,
      846,
      844,
      841,
      839,
      836,
      833,
      829,
      826,
      822,
      818,
      814,
      810,
      806,
      801,
      797,
      792,
      787,
      781,
      776,
      770,
      765,
      759,
      753,
      747,
      740,
      734,
      727,
      720,
      713,
      706,
      699,
      691,
      684,
      676,
      668,
      660,
      652,
      644,
      636,
      627,
      619,
      610,
    ],
    40: [
      750,
      754,
      760,
      765,
      769,
      774,
      779,
      783,
      788,
      792,
      796,
      800,
      804,
      808,
      811,
      815,
      818,
      821,
      824,
      827,
      830,
      832,
      835,
      837,
      839,
      841,
      843,
      844,
      846,
      847,
      848,
      849,
      850,
      850,
      851,
      851,
      851,
      851,
      850,
      850,
      849,
      848,
      847,
      846,
      845,
      843,
      841,
      840,
      837,
      835,
      833,
      830,
      827,
      824,
      821,
      818,
      814,
      810,
      806,
      802,
      798,
      794,
      790,
      785,
      780,
      775,
      769,
      764,
      758,
      753,
      747,
      741,
      735,
      728,
      722,
      715,
      709,
      702,
      695,
      687,
      680,
      673,
      665,
      657,
      649,
      642,
      634,
      625,
      617,
      609,
      600,
    ],
    45: [
      750,
      754,
      759,
      763,
      768,
      772,
      777,
      781,
      785,
      789,
      792,
      796,
      800,
      803,
      806,
      809,
      812,
      815,
      818,
      820,
      823,
      825,
      827,
      829,
      831,
      833,
      834,
      835,
      837,
      838,
      838,
      839,
      840,
      840,
      840,
      840,
      840,
      840,
      839,
      839,
      838,
      837,
      836,
      834,
      833,
      831,
      829,
      827,
      825,
      823,
      820,
      817,
      814,
      811,
      808,
      805,
      801,
      797,
      793,
      789,
      785,
      781,
      776,
      772,
      766,
      761,
      756,
      751,
      745,
      739,
      734,
      728,
      722,
      715,
      709,
      702,
      696,
      689,
      682,
      675,
      668,
      660,
      653,
      645,
      637,
      630,
      622,
      613,
      605,
      597,
      589,
    ],
    50: [
      750,
      754,
      758,
      762,
      766,
      770,
      774,
      778,
      781,
      785,
      788,
      791,
      795,
      798,
      800,
      803,
      806,
      808,
      811,
      813,
      815,
      817,
      819,
      820,
      822,
      824,
      825,
      826,
      827,
      827,
      828,
      829,
      829,
      829,
      829,
      829,
      829,
      828,
      827,
      826,
      825,
      824,
      823,
      821,
      820,
      818,
      816,
      814,
      811,
      809,
      806,
      803,
      800,
      797,
      794,
      790,
      787,
      783,
      779,
      775,
      770,
      766,
      762,
      757,
      751,
      746,
      741,
      736,
      731,
      725,
      719,
      713,
      707,
      701,
      694,
      688,
      681,
      675,
      668,
      661,
      654,
      646,
      639,
      631,
      624,
      617,
      608,
      601,
      592,
      584,
      576,
    ],
    55: [
      750,
      753,
      757,
      761,
      764,
      768,
      771,
      774,
      778,
      781,
      784,
      786,
      789,
      792,
      794,
      797,
      799,
      801,
      803,
      805,
      807,
      808,
      810,
      811,
      813,
      814,
      814,
      815,
      816,
      816,
      817,
      817,
      817,
      817,
      817,
      816,
      816,
      815,
      814,
      813,
      812,
      811,
      809,
      808,
      806,
      804,
      802,
      799,
      797,
      794,
      791,
      788,
      785,
      782,
      779,
      775,
      771,
      767,
      763,
      759,
      755,
      750,
      746,
      741,
      736,
      731,
      726,
      720,
      715,
      709,
      703,
      697,
      691,
      685,
      679,
      672,
      666,
      659,
      652,
      645,
      638,
      631,
      624,
      617,
      609,
      602,
      594,
      586,
      578,
      571,
      563,
    ],
    60: [
      750,
      753,
      756,
      759,
      762,
      765,
      768,
      771,
      774,
      776,
      779,
      781,
      783,
      786,
      788,
      790,
      792,
      793,
      795,
      796,
      798,
      799,
      800,
      801,
      802,
      803,
      804,
      804,
      804,
      805,
      805,
      805,
      805,
      804,
      804,
      803,
      802,
      801,
      800,
      799,
      798,
      796,
      795,
      793,
      791,
      789,
      786,
      784,
      781,
      778,
      775,
      772,
      769,
      766,
      762,
      759,
      755,
      751,
      747,
      743,
      738,
      734,
      729,
      724,
      719,
      714,
      709,
      703,
      698,
      693,
      686,
      680,
      674,
      669,
      662,
      656,
      649,
      643,
      636,
      629,
      622,
      615,
      608,
      601,
      594,
      586,
      579,
      571,
      563,
      556,
      548,
    ],
    65: [
      750,
      752,
      755,
      757,
      760,
      763,
      765,
      767,
      769,
      772,
      774,
      776,
      777,
      779,
      781,
      782,
      784,
      785,
      786,
      787,
      789,
      789,
      790,
      791,
      791,
      792,
      792,
      792,
      793,
      792,
      792,
      792,
      791,
      791,
      790,
      789,
      788,
      787,
      786,
      784,
      783,
      781,
      779,
      777,
      775,
      772,
      770,
      767,
      765,
      762,
      759,
      755,
      752,
      749,
      745,
      741,
      737,
      733,
      729,
      725,
      720,
      716,
      711,
      706,
      701,
      696,
      691,
      686,
      680,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      632,
      625,
      619,
      612,
      605,
      598,
      592,
      585,
      577,
      570,
      563,
      555,
      548,
      540,
      533,
    ],
    70: [
      750,
      752,
      754,
      756,
      758,
      760,
      762,
      763,
      765,
      767,
      768,
      770,
      771,
      772,
      773,
      775,
      776,
      776,
      777,
      778,
      779,
      779,
      780,
      780,
      780,
      780,
      780,
      780,
      780,
      779,
      779,
      778,
      777,
      777,
      776,
      774,
      773,
      772,
      770,
      769,
      767,
      765,
      763,
      761,
      758,
      756,
      753,
      750,
      747,
      744,
      741,
      738,
      734,
      731,
      727,
      723,
      719,
      715,
      711,
      707,
      702,
      697,
      693,
      688,
      683,
      678,
      672,
      667,
      662,
      656,
      650,
      645,
      638,
      633,
      627,
      621,
      613,
      607,
      601,
      594,
      588,
      581,
      574,
      567,
      560,
      553,
      546,
      539,
      531,
      524,
      516,
    ],
    75: [
      750,
      751,
      753,
      754,
      756,
      757,
      758,
      759,
      761,
      762,
      763,
      764,
      764,
      765,
      766,
      766,
      767,
      768,
      768,
      768,
      768,
      769,
      768,
      768,
      768,
      768,
      768,
      767,
      767,
      766,
      765,
      764,
      763,
      762,
      761,
      759,
      758,
      756,
      754,
      752,
      750,
      748,
      746,
      743,
      741,
      738,
      735,
      733,
      729,
      726,
      723,
      720,
      716,
      712,
      708,
      705,
      700,
      696,
      692,
      687,
      683,
      678,
      673,
      669,
      664,
      658,
      653,
      648,
      642,
      637,
      631,
      625,
      620,
      613,
      607,
      601,
      595,
      588,
      582,
      576,
      570,
      563,
      555,
      549,
      542,
      535,
      528,
      521,
      514,
      507,
      499,
    ],
    80: [
      750,
      750,
      751,
      752,
      753,
      754,
      755,
      755,
      756,
      756,
      757,
      757,
      758,
      758,
      758,
      758,
      758,
      758,
      758,
      758,
      758,
      757,
      757,
      757,
      756,
      755,
      755,
      754,
      753,
      752,
      751,
      749,
      748,
      747,
      745,
      743,
      742,
      740,
      738,
      735,
      733,
      731,
      728,
      726,
      723,
      720,
      717,
      714,
      711,
      708,
      704,
      701,
      697,
      693,
      689,
      685,
      681,
      677,
      672,
      668,
      663,
      659,
      654,
      649,
      644,
      639,
      633,
      628,
      623,
      617,
      611,
      606,
      600,
      594,
      588,
      582,
      576,
      569,
      563,
      557,
      551,
      544,
      536,
      530,
      524,
      517,
      510,
      503,
      496,
      489,
      482,
    ],
    85: [
      750,
      750,
      750,
      751,
      751,
      751,
      751,
      751,
      751,
      751,
      751,
      751,
      751,
      750,
      750,
      750,
      749,
      749,
      748,
      748,
      747,
      746,
      745,
      745,
      744,
      742,
      741,
      740,
      739,
      737,
      736,
      734,
      733,
      731,
      729,
      727,
      725,
      723,
      721,
      718,
      716,
      713,
      711,
      708,
      705,
      702,
      699,
      695,
      692,
      688,
      685,
      681,
      677,
      673,
      669,
      665,
      661,
      657,
      652,
      648,
      643,
      638,
      633,
      628,
      623,
      618,
      613,
      608,
      602,
      597,
      591,
      585,
      580,
      574,
      568,
      562,
      556,
      550,
      543,
      537,
      531,
      524,
      518,
      511,
      505,
      498,
      492,
      485,
      477,
      471,
      464,
    ],
    90: [
      750,
      749,
      749,
      749,
      748,
      748,
      748,
      747,
      747,
      746,
      745,
      744,
      744,
      743,
      742,
      741,
      740,
      739,
      738,
      737,
      736,
      735,
      733,
      732,
      731,
      729,
      728,
      726,
      724,
      723,
      721,
      719,
      717,
      715,
      713,
      711,
      708,
      706,
      703,
      701,
      698,
      695,
      692,
      689,
      686,
      683,
      680,
      676,
      673,
      669,
      665,
      661,
      657,
      653,
      649,
      645,
      641,
      636,
      632,
      627,
      622,
      618,
      613,
      608,
      603,
      597,
      592,
      587,
      581,
      576,
      570,
      565,
      559,
      553,
      547,
      542,
      535,
      529,
      523,
      517,
      511,
      505,
      498,
      492,
      485,
      479,
      473,
      466,
      459,
      452,
      446,
    ],
    95: [
      750,
      749,
      748,
      747,
      746,
      745,
      744,
      743,
      742,
      741,
      739,
      738,
      737,
      735,
      734,
      732,
      731,
      730,
      728,
      726,
      725,
      723,
      721,
      720,
      718,
      716,
      714,
      712,
      710,
      708,
      706,
      704,
      701,
      699,
      696,
      694,
      691,
      689,
      686,
      683,
      680,
      677,
      674,
      670,
      667,
      664,
      660,
      657,
      653,
      649,
      645,
      641,
      637,
      633,
      629,
      625,
      620,
      616,
      611,
      606,
      602,
      597,
      592,
      587,
      582,
      577,
      571,
      566,
      561,
      555,
      549,
      544,
      538,
      533,
      527,
      521,
      515,
      509,
      503,
      497,
      491,
      485,
      478,
      472,
      466,
      459,
      453,
      447,
      441,
      434,
      427,
    ],
    100: [
      750,
      748,
      747,
      745,
      744,
      742,
      740,
      739,
      737,
      735,
      733,
      731,
      730,
      728,
      726,
      724,
      722,
      720,
      718,
      716,
      713,
      711,
      709,
      707,
      705,
      702,
      700,
      698,
      695,
      693,
      690,
      688,
      685,
      682,
      680,
      677,
      674,
      671,
      668,
      665,
      662,
      658,
      655,
      652,
      648,
      644,
      641,
      637,
      633,
      629,
      625,
      621,
      617,
      613,
      608,
      604,
      599,
      595,
      590,
      585,
      581,
      576,
      571,
      566,
      561,
      555,
      550,
      545,
      539,
      534,
      529,
      523,
      517,
      512,
      506,
      500,
      494,
      489,
      483,
      477,
      471,
      465,
      459,
      452,
      446,
      440,
      434,
      428,
      422,
      415,
      409,
    ],
    105: [
      750,
      747,
      745,
      743,
      741,
      739,
      737,
      735,
      732,
      730,
      728,
      725,
      723,
      720,
      718,
      715,
      713,
      710,
      707,
      705,
      702,
      700,
      697,
      694,
      692,
      689,
      686,
      683,
      681,
      678,
      675,
      672,
      669,
      666,
      663,
      660,
      657,
      653,
      650,
      647,
      643,
      640,
      636,
      633,
      629,
      625,
      621,
      617,
      613,
      609,
      605,
      601,
      596,
      592,
      588,
      583,
      579,
      574,
      569,
      564,
      560,
      555,
      550,
      545,
      539,
      534,
      529,
      524,
      518,
      513,
      508,
      502,
      497,
      491,
      485,
      480,
      474,
      468,
      462,
      456,
      451,
      445,
      439,
      433,
      427,
      421,
      415,
      409,
      403,
      397,
      391,
    ],
    110: [
      750,
      747,
      744,
      742,
      739,
      736,
      733,
      731,
      728,
      725,
      722,
      719,
      716,
      713,
      710,
      707,
      703,
      701,
      697,
      694,
      691,
      688,
      685,
      682,
      679,
      676,
      672,
      669,
      666,
      663,
      660,
      656,
      653,
      650,
      647,
      643,
      639,
      636,
      632,
      629,
      625,
      621,
      617,
      614,
      610,
      606,
      602,
      598,
      593,
      589,
      585,
      581,
      576,
      572,
      567,
      563,
      558,
      553,
      548,
      544,
      539,
      534,
      529,
      524,
      519,
      513,
      508,
      503,
      498,
      492,
      487,
      481,
      476,
      470,
      465,
      459,
      453,
      448,
      442,
      436,
      431,
      425,
      419,
      413,
      407,
      402,
      396,
      390,
      384,
      378,
      372,
    ],
    115: [
      750,
      746,
      743,
      740,
      737,
      733,
      730,
      727,
      723,
      720,
      716,
      713,
      709,
      705,
      702,
      698,
      695,
      691,
      687,
      684,
      680,
      677,
      673,
      670,
      666,
      662,
      659,
      655,
      652,
      648,
      645,
      641,
      637,
      634,
      630,
      626,
      622,
      619,
      615,
      611,
      607,
      603,
      599,
      595,
      591,
      587,
      582,
      578,
      574,
      569,
      565,
      561,
      556,
      552,
      547,
      542,
      537,
      533,
      528,
      523,
      518,
      513,
      508,
      503,
      498,
      493,
      487,
      482,
      477,
      472,
      466,
      461,
      455,
      450,
      445,
      439,
      434,
      428,
      422,
      417,
      411,
      405,
      400,
      394,
      389,
      383,
      377,
      371,
      366,
      360,
      354,
    ],
    120: [
      750,
      746,
      742,
      738,
      735,
      731,
      727,
      723,
      719,
      715,
      711,
      707,
      703,
      698,
      694,
      690,
      686,
      682,
      678,
      674,
      670,
      666,
      662,
      658,
      654,
      650,
      646,
      642,
      638,
      634,
      630,
      626,
      622,
      618,
      614,
      610,
      606,
      602,
      597,
      593,
      589,
      585,
      581,
      576,
      572,
      568,
      563,
      559,
      555,
      550,
      546,
      541,
      536,
      532,
      527,
      522,
      518,
      513,
      508,
      503,
      498,
      493,
      488,
      483,
      478,
      472,
      467,
      462,
      457,
      452,
      446,
      441,
      436,
      430,
      425,
      419,
      414,
      408,
      403,
      397,
      392,
      387,
      381,
      376,
      370,
      364,
      359,
      354,
      348,
      342,
      337,
    ],
    125: [
      750,
      745,
      741,
      737,
      732,
      728,
      724,
      719,
      715,
      710,
      706,
      701,
      696,
      692,
      687,
      683,
      678,
      673,
      669,
      664,
      660,
      655,
      651,
      646,
      642,
      637,
      633,
      628,
      624,
      619,
      615,
      611,
      607,
      602,
      598,
      594,
      589,
      585,
      580,
      576,
      572,
      567,
      563,
      559,
      554,
      550,
      545,
      540,
      536,
      531,
      527,
      522,
      517,
      512,
      508,
      503,
      498,
      493,
      488,
      483,
      478,
      473,
      468,
      463,
      458,
      453,
      448,
      442,
      437,
      432,
      427,
      422,
      416,
      411,
      406,
      400,
      395,
      390,
      385,
      379,
      374,
      368,
      363,
      358,
      352,
      347,
      342,
      336,
      331,
      326,
      320,
    ],
    130: [
      750,
      745,
      740,
      735,
      731,
      726,
      721,
      716,
      711,
      706,
      701,
      696,
      691,
      685,
      680,
      675,
      670,
      665,
      660,
      655,
      650,
      645,
      640,
      635,
      630,
      626,
      621,
      616,
      611,
      606,
      602,
      597,
      592,
      587,
      583,
      578,
      573,
      569,
      564,
      560,
      555,
      550,
      546,
      541,
      536,
      532,
      527,
      522,
      518,
      513,
      508,
      503,
      499,
      494,
      489,
      484,
      479,
      474,
      469,
      464,
      459,
      454,
      449,
      444,
      439,
      434,
      429,
      424,
      418,
      413,
      408,
      403,
      398,
      393,
      387,
      382,
      377,
      371,
      367,
      361,
      356,
      351,
      346,
      340,
      335,
      330,
      325,
      320,
      315,
      309,
      304,
    ],
    135: [
      750,
      744,
      739,
      734,
      729,
      723,
      718,
      713,
      707,
      702,
      696,
      691,
      685,
      680,
      674,
      669,
      663,
      658,
      652,
      647,
      641,
      636,
      631,
      625,
      620,
      615,
      609,
      604,
      599,
      594,
      589,
      583,
      578,
      573,
      568,
      563,
      558,
      554,
      549,
      544,
      539,
      534,
      529,
      524,
      520,
      515,
      510,
      505,
      500,
      495,
      490,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      446,
      441,
      436,
      431,
      426,
      421,
      416,
      411,
      406,
      400,
      396,
      390,
      385,
      380,
      375,
      370,
      365,
      360,
      355,
      349,
      344,
      340,
      334,
      329,
      324,
      319,
      314,
      309,
      304,
      299,
      294,
      289,
    ],
    140: [
      750,
      744,
      738,
      733,
      727,
      721,
      716,
      710,
      704,
      698,
      692,
      686,
      680,
      674,
      668,
      662,
      657,
      651,
      645,
      639,
      633,
      628,
      622,
      616,
      610,
      605,
      599,
      594,
      588,
      582,
      577,
      571,
      566,
      560,
      555,
      550,
      544,
      539,
      534,
      529,
      524,
      518,
      513,
      508,
      503,
      498,
      493,
      488,
      483,
      478,
      473,
      468,
      464,
      458,
      454,
      449,
      444,
      439,
      434,
      429,
      424,
      419,
      414,
      409,
      404,
      399,
      393,
      388,
      383,
      378,
      373,
      368,
      364,
      358,
      353,
      348,
      343,
      338,
      333,
      328,
      324,
      318,
      314,
      309,
      304,
      299,
      294,
      289,
      285,
      280,
      275,
    ],
    145: [
      750,
      743,
      738,
      732,
      726,
      719,
      713,
      707,
      701,
      695,
      688,
      682,
      676,
      669,
      663,
      657,
      651,
      644,
      638,
      632,
      626,
      620,
      614,
      608,
      602,
      596,
      590,
      584,
      578,
      572,
      566,
      560,
      555,
      549,
      543,
      537,
      532,
      526,
      520,
      515,
      509,
      504,
      499,
      493,
      488,
      483,
      478,
      473,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      367,
      362,
      357,
      353,
      347,
      343,
      338,
      333,
      328,
      323,
      318,
      314,
      309,
      304,
      299,
      295,
      290,
      285,
      280,
      276,
      271,
      267,
      262,
    ],
    150: [
      750,
      743,
      737,
      731,
      724,
      718,
      711,
      705,
      698,
      692,
      685,
      678,
      672,
      665,
      659,
      652,
      646,
      639,
      633,
      627,
      620,
      614,
      608,
      601,
      595,
      589,
      582,
      576,
      570,
      564,
      557,
      551,
      545,
      539,
      533,
      527,
      520,
      515,
      508,
      503,
      497,
      491,
      485,
      480,
      474,
      469,
      463,
      458,
      453,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      367,
      362,
      357,
      352,
      348,
      343,
      338,
      333,
      328,
      323,
      319,
      314,
      309,
      305,
      300,
      295,
      291,
      286,
      281,
      277,
      272,
      268,
      263,
      258,
      254,
      250,
    ],
    155: [
      750,
      743,
      736,
      730,
      723,
      716,
      710,
      703,
      696,
      689,
      682,
      675,
      668,
      661,
      655,
      648,
      642,
      635,
      628,
      622,
      615,
      609,
      602,
      595,
      589,
      582,
      575,
      569,
      562,
      556,
      549,
      543,
      537,
      530,
      524,
      517,
      511,
      505,
      499,
      492,
      486,
      480,
      474,
      468,
      462,
      457,
      451,
      445,
      440,
      434,
      429,
      424,
      418,
      413,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      344,
      339,
      334,
      329,
      325,
      320,
      315,
      311,
      306,
      301,
      297,
      292,
      288,
      283,
      278,
      274,
      269,
      265,
      261,
      256,
      252,
      248,
      244,
      239,
    ],
    160: [
      750,
      743,
      736,
      729,
      722,
      715,
      708,
      701,
      694,
      687,
      680,
      673,
      666,
      659,
      652,
      645,
      638,
      631,
      625,
      618,
      611,
      604,
      597,
      590,
      584,
      577,
      570,
      563,
      556,
      550,
      543,
      536,
      530,
      523,
      517,
      510,
      503,
      497,
      490,
      484,
      478,
      471,
      465,
      459,
      453,
      447,
      441,
      435,
      429,
      423,
      418,
      412,
      407,
      402,
      396,
      391,
      386,
      381,
      375,
      370,
      365,
      361,
      356,
      351,
      346,
      341,
      336,
      332,
      327,
      322,
      318,
      313,
      308,
      304,
      299,
      295,
      290,
      286,
      281,
      277,
      272,
      268,
      264,
      259,
      255,
      251,
      247,
      242,
      238,
      234,
      230,
    ],
    165: [
      750,
      742,
      736,
      729,
      721,
      714,
      707,
      700,
      693,
      685,
      678,
      671,
      663,
      656,
      649,
      642,
      635,
      629,
      621,
      615,
      608,
      601,
      594,
      586,
      579,
      572,
      566,
      559,
      552,
      545,
      538,
      531,
      524,
      518,
      511,
      504,
      497,
      491,
      484,
      477,
      471,
      464,
      458,
      452,
      445,
      439,
      433,
      427,
      421,
      415,
      409,
      403,
      398,
      392,
      387,
      382,
      376,
      371,
      366,
      361,
      356,
      350,
      346,
      341,
      336,
      332,
      327,
      322,
      317,
      313,
      308,
      304,
      299,
      295,
      290,
      286,
      281,
      277,
      273,
      268,
      264,
      259,
      255,
      251,
      247,
      243,
      239,
      235,
      231,
      227,
      223,
    ],
    170: [
      750,
      742,
      735,
      728,
      721,
      714,
      706,
      699,
      691,
      684,
      677,
      669,
      662,
      655,
      648,
      640,
      633,
      627,
      619,
      613,
      605,
      598,
      591,
      584,
      577,
      570,
      563,
      556,
      548,
      542,
      535,
      528,
      521,
      514,
      507,
      500,
      493,
      487,
      480,
      473,
      466,
      460,
      453,
      446,
      440,
      434,
      427,
      421,
      415,
      409,
      403,
      397,
      391,
      386,
      380,
      375,
      370,
      364,
      359,
      354,
      348,
      343,
      338,
      334,
      329,
      324,
      319,
      315,
      310,
      306,
      301,
      296,
      292,
      288,
      284,
      279,
      274,
      270,
      266,
      262,
      258,
      253,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
      217,
    ],
    175: [
      750,
      742,
      735,
      728,
      721,
      713,
      706,
      698,
      691,
      683,
      676,
      668,
      661,
      654,
      647,
      639,
      632,
      625,
      618,
      611,
      604,
      596,
      589,
      582,
      575,
      568,
      561,
      554,
      547,
      540,
      533,
      525,
      518,
      511,
      504,
      497,
      490,
      484,
      477,
      471,
      464,
      457,
      450,
      444,
      437,
      430,
      424,
      418,
      412,
      405,
      399,
      393,
      388,
      382,
      376,
      371,
      365,
      360,
      355,
      350,
      344,
      339,
      334,
      329,
      324,
      320,
      315,
      310,
      306,
      301,
      297,
      292,
      288,
      283,
      279,
      275,
      270,
      266,
      262,
      258,
      254,
      250,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
      217,
      214,
    ],
  },
  '12': {
    0: [
      805,
      812,
      819,
      826,
      833,
      840,
      847,
      853,
      860,
      866,
      872,
      878,
      884,
      889,
      894,
      900,
      905,
      909,
      914,
      918,
      922,
      926,
      930,
      934,
      937,
      940,
      943,
      946,
      949,
      951,
      953,
      955,
      957,
      958,
      959,
      961,
      961,
      962,
      962,
      963,
      962,
      962,
      962,
      961,
      960,
      959,
      957,
      956,
      954,
      952,
      950,
      947,
      944,
      942,
      938,
      935,
      931,
      928,
      923,
      919,
      915,
      910,
      905,
      900,
      895,
      889,
      883,
      877,
      871,
      865,
      858,
      851,
      845,
      837,
      830,
      822,
      814,
      807,
      799,
      790,
      782,
      773,
      764,
      755,
      746,
      736,
      727,
      717,
      707,
      697,
      687,
    ],
    5: [
      805,
      812,
      819,
      826,
      833,
      840,
      847,
      853,
      860,
      866,
      872,
      878,
      883,
      889,
      894,
      899,
      904,
      909,
      913,
      918,
      922,
      926,
      930,
      933,
      937,
      940,
      943,
      946,
      948,
      950,
      952,
      954,
      956,
      958,
      959,
      960,
      961,
      961,
      962,
      962,
      962,
      961,
      961,
      960,
      959,
      958,
      957,
      955,
      953,
      951,
      949,
      946,
      944,
      941,
      938,
      934,
      931,
      927,
      923,
      918,
      914,
      909,
      904,
      899,
      894,
      888,
      883,
      877,
      870,
      864,
      857,
      851,
      844,
      836,
      829,
      821,
      814,
      806,
      798,
      789,
      781,
      772,
      763,
      754,
      745,
      736,
      726,
      716,
      707,
      696,
      686,
    ],
    10: [
      805,
      811,
      819,
      826,
      833,
      839,
      846,
      853,
      859,
      865,
      871,
      877,
      882,
      888,
      893,
      898,
      903,
      908,
      912,
      916,
      921,
      924,
      928,
      932,
      935,
      938,
      941,
      944,
      946,
      949,
      951,
      952,
      954,
      955,
      957,
      958,
      959,
      959,
      959,
      960,
      959,
      959,
      959,
      958,
      957,
      956,
      954,
      953,
      951,
      949,
      946,
      944,
      941,
      938,
      935,
      932,
      928,
      924,
      920,
      916,
      911,
      907,
      902,
      897,
      891,
      886,
      880,
      874,
      868,
      862,
      855,
      848,
      841,
      834,
      827,
      819,
      811,
      804,
      795,
      787,
      779,
      770,
      761,
      752,
      743,
      734,
      724,
      714,
      705,
      695,
      685,
    ],
    15: [
      805,
      811,
      818,
      825,
      832,
      839,
      845,
      852,
      858,
      864,
      870,
      875,
      881,
      886,
      891,
      896,
      901,
      906,
      910,
      914,
      918,
      922,
      926,
      929,
      932,
      935,
      938,
      941,
      943,
      945,
      947,
      949,
      951,
      952,
      953,
      954,
      955,
      956,
      956,
      956,
      956,
      955,
      955,
      954,
      953,
      952,
      950,
      949,
      947,
      945,
      942,
      940,
      937,
      934,
      931,
      928,
      924,
      920,
      916,
      912,
      907,
      903,
      898,
      893,
      887,
      882,
      876,
      870,
      864,
      857,
      851,
      844,
      837,
      830,
      823,
      815,
      808,
      800,
      792,
      784,
      775,
      767,
      758,
      749,
      740,
      730,
      721,
      711,
      702,
      691,
      682,
    ],
    20: [
      805,
      811,
      818,
      825,
      831,
      838,
      844,
      850,
      856,
      862,
      868,
      873,
      879,
      884,
      889,
      894,
      898,
      903,
      907,
      911,
      915,
      919,
      922,
      925,
      928,
      931,
      934,
      937,
      939,
      941,
      943,
      945,
      946,
      947,
      949,
      949,
      950,
      950,
      951,
      951,
      951,
      950,
      949,
      949,
      948,
      946,
      945,
      943,
      941,
      939,
      937,
      934,
      931,
      928,
      925,
      922,
      918,
      914,
      910,
      906,
      901,
      897,
      892,
      887,
      881,
      876,
      870,
      864,
      858,
      852,
      845,
      839,
      832,
      825,
      817,
      810,
      802,
      794,
      787,
      778,
      770,
      761,
      753,
      744,
      735,
      726,
      716,
      707,
      697,
      687,
      678,
    ],
    25: [
      805,
      811,
      817,
      824,
      830,
      837,
      843,
      849,
      854,
      860,
      865,
      871,
      876,
      881,
      886,
      890,
      895,
      899,
      903,
      907,
      911,
      914,
      918,
      921,
      924,
      926,
      929,
      931,
      934,
      935,
      937,
      939,
      940,
      941,
      942,
      943,
      944,
      944,
      944,
      944,
      944,
      943,
      943,
      942,
      941,
      939,
      938,
      936,
      934,
      932,
      929,
      927,
      924,
      921,
      918,
      914,
      910,
      907,
      902,
      898,
      894,
      889,
      884,
      879,
      874,
      868,
      863,
      857,
      851,
      844,
      838,
      831,
      825,
      817,
      810,
      803,
      795,
      788,
      780,
      771,
      763,
      755,
      746,
      737,
      728,
      720,
      710,
      701,
      691,
      682,
      672,
    ],
    30: [
      805,
      811,
      817,
      823,
      829,
      835,
      841,
      847,
      852,
      857,
      863,
      868,
      873,
      877,
      882,
      886,
      890,
      894,
      898,
      902,
      906,
      909,
      912,
      915,
      918,
      920,
      923,
      925,
      927,
      929,
      930,
      932,
      933,
      934,
      935,
      936,
      936,
      936,
      936,
      936,
      936,
      935,
      934,
      933,
      932,
      931,
      929,
      927,
      925,
      923,
      920,
      918,
      915,
      912,
      909,
      905,
      901,
      897,
      893,
      889,
      885,
      880,
      875,
      870,
      864,
      859,
      853,
      848,
      841,
      835,
      829,
      822,
      815,
      808,
      801,
      794,
      787,
      779,
      771,
      763,
      755,
      747,
      738,
      729,
      720,
      712,
      702,
      693,
      684,
      674,
      665,
    ],
    35: [
      805,
      810,
      816,
      822,
      828,
      833,
      839,
      844,
      849,
      854,
      859,
      864,
      869,
      873,
      877,
      881,
      885,
      889,
      893,
      896,
      900,
      903,
      906,
      908,
      911,
      913,
      915,
      918,
      919,
      921,
      922,
      924,
      925,
      926,
      926,
      927,
      927,
      927,
      927,
      927,
      926,
      925,
      925,
      923,
      922,
      921,
      919,
      917,
      915,
      912,
      910,
      907,
      904,
      901,
      898,
      894,
      890,
      886,
      882,
      878,
      874,
      869,
      864,
      859,
      854,
      848,
      843,
      837,
      830,
      824,
      818,
      811,
      805,
      798,
      791,
      784,
      776,
      769,
      761,
      753,
      745,
      737,
      728,
      720,
      711,
      702,
      693,
      684,
      675,
      666,
      656,
    ],
    40: [
      805,
      810,
      815,
      821,
      826,
      831,
      837,
      842,
      846,
      851,
      856,
      860,
      864,
      868,
      872,
      876,
      880,
      883,
      887,
      890,
      893,
      896,
      898,
      901,
      903,
      905,
      907,
      909,
      911,
      912,
      913,
      914,
      915,
      916,
      916,
      917,
      917,
      917,
      916,
      916,
      915,
      914,
      913,
      912,
      911,
      909,
      907,
      905,
      903,
      900,
      898,
      895,
      892,
      889,
      885,
      882,
      878,
      874,
      870,
      866,
      861,
      856,
      852,
      846,
      841,
      835,
      830,
      824,
      818,
      812,
      805,
      799,
      792,
      785,
      778,
      771,
      764,
      756,
      749,
      741,
      733,
      725,
      717,
      708,
      700,
      691,
      682,
      674,
      664,
      655,
      646,
    ],
    45: [
      805,
      809,
      815,
      820,
      824,
      829,
      834,
      839,
      843,
      847,
      851,
      855,
      859,
      863,
      867,
      870,
      873,
      877,
      880,
      882,
      885,
      888,
      890,
      892,
      894,
      896,
      898,
      899,
      901,
      902,
      903,
      904,
      905,
      905,
      905,
      905,
      905,
      905,
      905,
      904,
      903,
      902,
      901,
      899,
      898,
      896,
      894,
      892,
      890,
      887,
      884,
      882,
      878,
      875,
      872,
      868,
      864,
      860,
      856,
      852,
      847,
      842,
      837,
      832,
      827,
      821,
      816,
      810,
      804,
      798,
      792,
      785,
      778,
      772,
      765,
      758,
      750,
      743,
      735,
      728,
      720,
      712,
      704,
      695,
      687,
      679,
      670,
      661,
      652,
      643,
      634,
    ],
    50: [
      805,
      809,
      814,
      818,
      823,
      827,
      831,
      835,
      839,
      843,
      847,
      850,
      854,
      857,
      860,
      864,
      867,
      869,
      872,
      875,
      877,
      879,
      881,
      883,
      885,
      886,
      888,
      889,
      890,
      891,
      892,
      892,
      893,
      893,
      893,
      893,
      893,
      892,
      892,
      891,
      890,
      889,
      887,
      886,
      884,
      882,
      880,
      878,
      875,
      873,
      870,
      867,
      864,
      860,
      857,
      853,
      849,
      845,
      841,
      836,
      832,
      827,
      822,
      817,
      811,
      806,
      800,
      795,
      789,
      782,
      776,
      770,
      763,
      756,
      750,
      743,
      735,
      728,
      720,
      713,
      705,
      697,
      689,
      681,
      673,
      665,
      656,
      648,
      639,
      630,
      621,
    ],
    55: [
      805,
      808,
      812,
      817,
      821,
      824,
      828,
      832,
      835,
      839,
      842,
      845,
      848,
      851,
      854,
      857,
      859,
      861,
      864,
      866,
      868,
      870,
      871,
      873,
      875,
      876,
      877,
      878,
      879,
      879,
      880,
      880,
      880,
      880,
      880,
      880,
      879,
      879,
      878,
      877,
      875,
      874,
      873,
      871,
      869,
      867,
      865,
      862,
      860,
      857,
      854,
      851,
      847,
      844,
      840,
      836,
      832,
      828,
      824,
      820,
      815,
      810,
      805,
      800,
      795,
      789,
      784,
      778,
      772,
      766,
      760,
      753,
      747,
      740,
      733,
      726,
      719,
      712,
      705,
      697,
      690,
      682,
      674,
      666,
      658,
      650,
      641,
      633,
      625,
      616,
      607,
    ],
    60: [
      805,
      808,
      811,
      815,
      818,
      822,
      825,
      828,
      831,
      834,
      837,
      839,
      842,
      844,
      847,
      849,
      851,
      853,
      855,
      857,
      858,
      860,
      861,
      862,
      863,
      864,
      865,
      866,
      866,
      867,
      867,
      867,
      867,
      866,
      866,
      865,
      865,
      864,
      863,
      861,
      860,
      858,
      857,
      855,
      853,
      851,
      848,
      846,
      843,
      840,
      837,
      834,
      830,
      827,
      823,
      819,
      815,
      811,
      806,
      802,
      797,
      792,
      787,
      782,
      777,
      771,
      766,
      760,
      754,
      748,
      742,
      735,
      729,
      722,
      716,
      709,
      702,
      695,
      687,
      680,
      673,
      665,
      658,
      649,
      642,
      634,
      626,
      617,
      609,
      601,
      592,
    ],
    65: [
      805,
      807,
      810,
      813,
      816,
      819,
      821,
      824,
      827,
      829,
      831,
      833,
      835,
      837,
      839,
      841,
      843,
      844,
      846,
      847,
      848,
      849,
      850,
      851,
      852,
      852,
      853,
      853,
      853,
      853,
      853,
      853,
      852,
      852,
      851,
      850,
      849,
      848,
      847,
      845,
      844,
      842,
      840,
      838,
      836,
      833,
      831,
      828,
      825,
      822,
      819,
      815,
      812,
      808,
      804,
      800,
      796,
      792,
      788,
      783,
      778,
      773,
      768,
      763,
      758,
      752,
      747,
      741,
      735,
      729,
      723,
      716,
      710,
      704,
      697,
      690,
      683,
      676,
      669,
      662,
      655,
      647,
      640,
      632,
      624,
      616,
      609,
      600,
      592,
      584,
      576,
    ],
    70: [
      805,
      807,
      809,
      811,
      814,
      816,
      818,
      820,
      822,
      824,
      825,
      827,
      829,
      830,
      831,
      833,
      834,
      835,
      836,
      837,
      837,
      838,
      839,
      839,
      839,
      840,
      840,
      840,
      839,
      839,
      839,
      838,
      837,
      837,
      836,
      834,
      833,
      832,
      830,
      828,
      827,
      825,
      823,
      820,
      818,
      815,
      812,
      810,
      806,
      803,
      800,
      796,
      793,
      789,
      785,
      781,
      777,
      772,
      768,
      763,
      758,
      754,
      749,
      743,
      738,
      732,
      727,
      721,
      715,
      709,
      703,
      697,
      690,
      684,
      678,
      671,
      663,
      657,
      650,
      643,
      636,
      628,
      621,
      614,
      606,
      598,
      590,
      583,
      575,
      567,
      559,
    ],
    75: [
      805,
      806,
      808,
      809,
      811,
      813,
      814,
      816,
      817,
      818,
      819,
      820,
      821,
      822,
      823,
      824,
      824,
      825,
      826,
      826,
      826,
      827,
      827,
      827,
      827,
      826,
      826,
      826,
      825,
      825,
      824,
      823,
      822,
      820,
      819,
      818,
      816,
      815,
      813,
      811,
      809,
      807,
      804,
      802,
      799,
      796,
      793,
      790,
      787,
      784,
      780,
      777,
      773,
      769,
      765,
      761,
      756,
      752,
      747,
      743,
      738,
      733,
      728,
      723,
      717,
      712,
      706,
      700,
      695,
      688,
      683,
      676,
      670,
      663,
      657,
      651,
      644,
      637,
      630,
      623,
      616,
      609,
      601,
      594,
      587,
      579,
      571,
      564,
      556,
      549,
      541,
    ],
    80: [
      805,
      805,
      806,
      808,
      809,
      810,
      810,
      811,
      812,
      813,
      813,
      814,
      814,
      814,
      815,
      815,
      815,
      815,
      815,
      815,
      815,
      814,
      814,
      814,
      813,
      813,
      812,
      811,
      810,
      809,
      808,
      807,
      805,
      804,
      802,
      801,
      799,
      797,
      795,
      792,
      790,
      788,
      785,
      782,
      780,
      777,
      774,
      770,
      767,
      763,
      760,
      756,
      752,
      748,
      744,
      740,
      735,
      731,
      726,
      721,
      716,
      712,
      706,
      701,
      696,
      690,
      685,
      679,
      673,
      667,
      661,
      655,
      649,
      643,
      636,
      629,
      623,
      616,
      609,
      603,
      596,
      589,
      581,
      574,
      567,
      560,
      552,
      545,
      537,
      530,
      522,
    ],
    85: [
      805,
      805,
      805,
      806,
      806,
      806,
      807,
      807,
      807,
      807,
      807,
      807,
      806,
      806,
      806,
      806,
      805,
      805,
      804,
      804,
      803,
      802,
      801,
      800,
      800,
      799,
      797,
      796,
      795,
      793,
      792,
      790,
      789,
      787,
      785,
      783,
      781,
      779,
      776,
      774,
      771,
      768,
      766,
      763,
      760,
      757,
      753,
      750,
      746,
      743,
      739,
      735,
      731,
      727,
      723,
      718,
      714,
      709,
      704,
      700,
      694,
      689,
      684,
      679,
      674,
      668,
      663,
      657,
      651,
      645,
      639,
      633,
      627,
      621,
      615,
      608,
      601,
      595,
      588,
      581,
      574,
      568,
      561,
      553,
      546,
      540,
      533,
      525,
      517,
      510,
      503,
    ],
    90: [
      805,
      804,
      804,
      804,
      803,
      803,
      803,
      802,
      802,
      801,
      800,
      799,
      799,
      798,
      797,
      796,
      795,
      794,
      793,
      792,
      791,
      790,
      788,
      787,
      786,
      784,
      783,
      781,
      779,
      778,
      776,
      773,
      772,
      769,
      767,
      765,
      762,
      760,
      757,
      755,
      752,
      749,
      746,
      742,
      739,
      736,
      732,
      729,
      725,
      721,
      717,
      713,
      709,
      705,
      700,
      696,
      691,
      687,
      682,
      677,
      672,
      667,
      662,
      656,
      651,
      645,
      640,
      634,
      629,
      623,
      617,
      611,
      604,
      598,
      592,
      586,
      579,
      573,
      566,
      560,
      553,
      546,
      539,
      533,
      525,
      518,
      512,
      505,
      497,
      490,
      483,
    ],
    95: [
      805,
      803,
      803,
      802,
      801,
      800,
      799,
      798,
      796,
      795,
      794,
      792,
      791,
      790,
      788,
      787,
      785,
      784,
      782,
      780,
      779,
      777,
      775,
      773,
      771,
      769,
      767,
      765,
      763,
      761,
      759,
      757,
      754,
      752,
      749,
      746,
      744,
      741,
      738,
      735,
      732,
      729,
      725,
      722,
      718,
      715,
      711,
      707,
      704,
      700,
      695,
      691,
      687,
      683,
      678,
      674,
      669,
      664,
      659,
      654,
      649,
      644,
      639,
      633,
      628,
      623,
      617,
      611,
      606,
      600,
      594,
      588,
      582,
      576,
      569,
      563,
      557,
      550,
      544,
      538,
      531,
      524,
      517,
      511,
      504,
      497,
      490,
      484,
      477,
      470,
      463,
    ],
    100: [
      805,
      803,
      801,
      800,
      798,
      796,
      795,
      793,
      791,
      789,
      787,
      785,
      783,
      781,
      779,
      777,
      775,
      773,
      771,
      768,
      766,
      764,
      762,
      759,
      757,
      755,
      752,
      750,
      747,
      745,
      742,
      739,
      737,
      734,
      731,
      728,
      725,
      721,
      718,
      715,
      712,
      708,
      705,
      701,
      697,
      694,
      690,
      686,
      682,
      677,
      673,
      669,
      665,
      660,
      655,
      651,
      646,
      641,
      636,
      631,
      626,
      621,
      616,
      610,
      605,
      599,
      594,
      588,
      582,
      577,
      571,
      565,
      559,
      553,
      547,
      540,
      534,
      528,
      522,
      515,
      509,
      503,
      496,
      489,
      483,
      476,
      469,
      462,
      456,
      449,
      442,
    ],
    105: [
      805,
      802,
      800,
      798,
      796,
      793,
      791,
      788,
      786,
      783,
      781,
      778,
      776,
      773,
      770,
      768,
      765,
      762,
      759,
      757,
      754,
      751,
      748,
      745,
      743,
      740,
      737,
      734,
      731,
      728,
      725,
      722,
      719,
      715,
      712,
      709,
      706,
      702,
      699,
      695,
      691,
      688,
      684,
      680,
      676,
      672,
      668,
      664,
      660,
      655,
      651,
      647,
      642,
      637,
      633,
      628,
      623,
      618,
      613,
      608,
      603,
      598,
      592,
      587,
      582,
      576,
      570,
      565,
      559,
      553,
      548,
      542,
      536,
      530,
      524,
      518,
      512,
      505,
      499,
      493,
      486,
      480,
      474,
      467,
      461,
      454,
      448,
      442,
      435,
      429,
      422,
    ],
    110: [
      805,
      801,
      799,
      796,
      793,
      790,
      787,
      784,
      781,
      778,
      775,
      771,
      768,
      765,
      761,
      758,
      755,
      752,
      748,
      745,
      742,
      738,
      735,
      732,
      728,
      725,
      722,
      718,
      715,
      712,
      708,
      704,
      701,
      697,
      694,
      690,
      686,
      683,
      679,
      675,
      671,
      667,
      663,
      659,
      655,
      651,
      646,
      642,
      638,
      633,
      629,
      624,
      619,
      615,
      610,
      605,
      600,
      595,
      590,
      585,
      580,
      574,
      569,
      564,
      558,
      553,
      547,
      541,
      536,
      530,
      524,
      519,
      513,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
      458,
      452,
      446,
      440,
      433,
      427,
      421,
      414,
      408,
      402,
    ],
    115: [
      805,
      801,
      797,
      794,
      791,
      787,
      783,
      780,
      776,
      772,
      768,
      765,
      761,
      757,
      753,
      749,
      745,
      741,
      737,
      734,
      730,
      726,
      722,
      718,
      714,
      710,
      707,
      703,
      699,
      695,
      691,
      687,
      683,
      679,
      675,
      672,
      667,
      663,
      659,
      655,
      651,
      647,
      642,
      638,
      634,
      629,
      625,
      620,
      616,
      611,
      607,
      602,
      597,
      592,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      551,
      546,
      540,
      535,
      530,
      524,
      518,
      513,
      507,
      502,
      496,
      490,
      484,
      478,
      472,
      467,
      461,
      455,
      449,
      443,
      437,
      431,
      425,
      418,
      412,
      406,
      400,
      394,
      388,
      382,
    ],
    120: [
      805,
      800,
      796,
      792,
      788,
      784,
      780,
      776,
      771,
      767,
      762,
      758,
      754,
      749,
      745,
      740,
      736,
      731,
      727,
      722,
      718,
      713,
      709,
      705,
      700,
      696,
      692,
      687,
      683,
      679,
      675,
      670,
      666,
      662,
      657,
      653,
      649,
      644,
      640,
      636,
      631,
      627,
      622,
      618,
      613,
      608,
      604,
      599,
      594,
      590,
      585,
      580,
      575,
      570,
      565,
      560,
      555,
      550,
      544,
      539,
      534,
      529,
      523,
      518,
      512,
      507,
      502,
      496,
      490,
      485,
      479,
      473,
      468,
      462,
      456,
      450,
      445,
      439,
      433,
      427,
      421,
      416,
      409,
      404,
      398,
      392,
      386,
      380,
      375,
      368,
      363,
    ],
    125: [
      805,
      800,
      795,
      791,
      786,
      781,
      776,
      772,
      767,
      762,
      757,
      752,
      747,
      742,
      737,
      732,
      727,
      722,
      717,
      712,
      707,
      702,
      697,
      692,
      687,
      682,
      678,
      673,
      668,
      663,
      658,
      654,
      649,
      645,
      640,
      635,
      630,
      626,
      621,
      616,
      612,
      607,
      602,
      597,
      593,
      588,
      583,
      578,
      573,
      568,
      563,
      558,
      553,
      548,
      543,
      538,
      533,
      528,
      522,
      517,
      512,
      506,
      501,
      496,
      490,
      485,
      480,
      474,
      468,
      463,
      457,
      452,
      446,
      441,
      435,
      429,
      423,
      418,
      412,
      406,
      401,
      395,
      389,
      384,
      378,
      372,
      366,
      360,
      355,
      349,
      344,
    ],
    130: [
      805,
      799,
      794,
      789,
      784,
      779,
      773,
      768,
      762,
      757,
      751,
      746,
      740,
      735,
      729,
      724,
      718,
      713,
      707,
      701,
      696,
      690,
      685,
      680,
      674,
      669,
      664,
      659,
      653,
      648,
      643,
      638,
      633,
      628,
      623,
      618,
      613,
      608,
      603,
      598,
      593,
      588,
      583,
      578,
      573,
      568,
      563,
      558,
      553,
      548,
      543,
      537,
      532,
      527,
      522,
      517,
      512,
      506,
      501,
      496,
      490,
      485,
      480,
      474,
      469,
      463,
      458,
      453,
      447,
      442,
      436,
      431,
      425,
      420,
      414,
      408,
      403,
      398,
      392,
      386,
      381,
      375,
      370,
      364,
      358,
      353,
      347,
      342,
      337,
      331,
      326,
    ],
    135: [
      805,
      799,
      793,
      787,
      782,
      776,
      770,
      764,
      758,
      752,
      746,
      740,
      734,
      728,
      722,
      716,
      710,
      704,
      698,
      692,
      686,
      680,
      674,
      668,
      663,
      657,
      651,
      645,
      640,
      634,
      629,
      623,
      617,
      612,
      606,
      601,
      596,
      591,
      585,
      580,
      574,
      569,
      564,
      559,
      554,
      548,
      543,
      538,
      533,
      528,
      523,
      517,
      512,
      507,
      502,
      496,
      491,
      486,
      480,
      475,
      470,
      464,
      459,
      454,
      448,
      443,
      438,
      432,
      427,
      421,
      416,
      411,
      405,
      400,
      394,
      389,
      384,
      378,
      372,
      367,
      362,
      357,
      351,
      345,
      340,
      335,
      330,
      324,
      319,
      314,
      309,
    ],
    140: [
      805,
      798,
      792,
      786,
      780,
      774,
      767,
      761,
      755,
      748,
      742,
      735,
      729,
      722,
      716,
      709,
      703,
      696,
      690,
      684,
      677,
      671,
      665,
      658,
      652,
      646,
      640,
      633,
      627,
      621,
      615,
      609,
      603,
      597,
      591,
      586,
      580,
      574,
      568,
      563,
      557,
      552,
      546,
      541,
      535,
      530,
      525,
      519,
      514,
      509,
      503,
      498,
      493,
      487,
      482,
      477,
      471,
      466,
      461,
      455,
      450,
      445,
      439,
      434,
      429,
      424,
      418,
      413,
      408,
      402,
      397,
      391,
      386,
      381,
      376,
      370,
      365,
      360,
      354,
      349,
      344,
      338,
      333,
      328,
      323,
      318,
      313,
      308,
      302,
      298,
      293,
    ],
    145: [
      805,
      798,
      791,
      785,
      778,
      772,
      765,
      758,
      751,
      744,
      738,
      731,
      724,
      717,
      710,
      703,
      696,
      689,
      683,
      676,
      669,
      663,
      656,
      649,
      643,
      636,
      629,
      623,
      616,
      610,
      603,
      597,
      591,
      584,
      578,
      572,
      565,
      559,
      553,
      547,
      541,
      535,
      529,
      524,
      518,
      512,
      507,
      501,
      496,
      490,
      485,
      480,
      474,
      469,
      463,
      458,
      453,
      447,
      442,
      437,
      431,
      426,
      421,
      416,
      410,
      405,
      400,
      394,
      389,
      384,
      379,
      373,
      368,
      363,
      358,
      353,
      348,
      343,
      337,
      332,
      327,
      322,
      317,
      312,
      307,
      302,
      297,
      292,
      288,
      282,
      278,
    ],
    150: [
      805,
      797,
      791,
      784,
      777,
      770,
      763,
      756,
      748,
      741,
      734,
      727,
      719,
      712,
      705,
      698,
      691,
      684,
      676,
      669,
      662,
      655,
      649,
      642,
      635,
      628,
      621,
      614,
      607,
      600,
      593,
      586,
      580,
      573,
      566,
      559,
      553,
      546,
      540,
      533,
      527,
      521,
      515,
      508,
      502,
      496,
      491,
      485,
      479,
      474,
      468,
      462,
      457,
      451,
      446,
      441,
      435,
      430,
      425,
      419,
      414,
      409,
      403,
      398,
      393,
      388,
      383,
      377,
      372,
      367,
      362,
      357,
      352,
      347,
      342,
      336,
      332,
      327,
      322,
      317,
      312,
      307,
      302,
      297,
      292,
      288,
      283,
      278,
      273,
      269,
      264,
    ],
    155: [
      805,
      797,
      790,
      783,
      776,
      768,
      761,
      753,
      746,
      738,
      731,
      723,
      716,
      708,
      701,
      693,
      686,
      679,
      671,
      664,
      657,
      649,
      642,
      635,
      628,
      621,
      613,
      606,
      599,
      592,
      584,
      577,
      570,
      563,
      556,
      549,
      542,
      535,
      529,
      522,
      515,
      508,
      502,
      495,
      489,
      483,
      476,
      470,
      464,
      459,
      453,
      447,
      441,
      436,
      430,
      425,
      419,
      414,
      409,
      403,
      398,
      393,
      388,
      382,
      377,
      372,
      367,
      362,
      357,
      352,
      347,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      307,
      303,
      298,
      293,
      289,
      284,
      279,
      275,
      270,
      265,
      261,
      256,
      252,
    ],
    160: [
      805,
      797,
      790,
      782,
      775,
      767,
      759,
      752,
      744,
      736,
      728,
      720,
      712,
      705,
      697,
      689,
      682,
      674,
      667,
      660,
      652,
      644,
      637,
      630,
      622,
      614,
      607,
      599,
      592,
      585,
      577,
      570,
      563,
      555,
      548,
      541,
      533,
      526,
      519,
      512,
      505,
      498,
      491,
      485,
      478,
      472,
      465,
      459,
      452,
      446,
      440,
      434,
      428,
      423,
      417,
      411,
      406,
      400,
      395,
      389,
      384,
      379,
      374,
      369,
      363,
      358,
      354,
      348,
      343,
      339,
      334,
      329,
      324,
      319,
      314,
      309,
      305,
      300,
      295,
      290,
      286,
      281,
      277,
      272,
      268,
      263,
      259,
      254,
      250,
      246,
      241,
    ],
    165: [
      805,
      797,
      789,
      782,
      774,
      766,
      758,
      750,
      742,
      734,
      726,
      718,
      710,
      702,
      694,
      687,
      679,
      671,
      664,
      656,
      648,
      641,
      633,
      625,
      617,
      610,
      602,
      594,
      587,
      579,
      572,
      564,
      557,
      549,
      542,
      534,
      527,
      519,
      512,
      505,
      497,
      490,
      483,
      476,
      470,
      463,
      456,
      449,
      443,
      437,
      430,
      424,
      418,
      412,
      406,
      401,
      395,
      389,
      384,
      378,
      373,
      368,
      362,
      357,
      352,
      347,
      342,
      337,
      333,
      328,
      323,
      318,
      313,
      308,
      304,
      299,
      294,
      290,
      285,
      281,
      276,
      272,
      267,
      263,
      259,
      254,
      250,
      246,
      241,
      237,
      233,
    ],
    170: [
      805,
      797,
      789,
      781,
      773,
      765,
      757,
      749,
      741,
      733,
      725,
      716,
      708,
      700,
      692,
      685,
      677,
      669,
      661,
      654,
      646,
      638,
      630,
      622,
      614,
      606,
      599,
      591,
      583,
      575,
      568,
      561,
      553,
      545,
      537,
      529,
      522,
      514,
      507,
      500,
      492,
      485,
      477,
      470,
      463,
      456,
      450,
      443,
      436,
      430,
      423,
      417,
      411,
      404,
      398,
      393,
      387,
      381,
      376,
      370,
      365,
      359,
      354,
      349,
      344,
      339,
      334,
      329,
      324,
      319,
      315,
      310,
      305,
      301,
      296,
      292,
      287,
      282,
      278,
      274,
      269,
      265,
      260,
      256,
      252,
      247,
      243,
      239,
      235,
      231,
      227,
    ],
    175: [
      805,
      796,
      789,
      781,
      773,
      765,
      757,
      749,
      740,
      732,
      724,
      715,
      707,
      699,
      691,
      683,
      675,
      668,
      660,
      652,
      644,
      636,
      628,
      620,
      612,
      605,
      597,
      589,
      581,
      574,
      566,
      558,
      550,
      542,
      534,
      526,
      519,
      511,
      504,
      497,
      489,
      482,
      475,
      467,
      460,
      452,
      445,
      438,
      432,
      425,
      419,
      412,
      406,
      400,
      394,
      388,
      382,
      376,
      371,
      365,
      360,
      354,
      349,
      344,
      339,
      334,
      329,
      324,
      319,
      314,
      310,
      305,
      300,
      296,
      291,
      287,
      282,
      277,
      273,
      269,
      265,
      260,
      256,
      252,
      247,
      243,
      239,
      235,
      231,
      227,
      223,
    ],
  },
  '13': {
    0: [
      789,
      795,
      802,
      809,
      815,
      821,
      827,
      833,
      839,
      844,
      849,
      855,
      859,
      864,
      869,
      873,
      878,
      882,
      886,
      889,
      893,
      896,
      899,
      902,
      905,
      908,
      910,
      912,
      914,
      916,
      917,
      919,
      920,
      921,
      921,
      922,
      922,
      922,
      922,
      922,
      921,
      921,
      920,
      919,
      917,
      916,
      914,
      912,
      909,
      907,
      904,
      902,
      898,
      895,
      892,
      888,
      884,
      880,
      876,
      871,
      866,
      861,
      856,
      851,
      845,
      840,
      834,
      828,
      821,
      815,
      808,
      801,
      794,
      787,
      780,
      772,
      764,
      756,
      748,
      740,
      731,
      722,
      714,
      704,
      695,
      686,
      677,
      667,
      657,
      647,
      637,
    ],
    5: [
      789,
      795,
      802,
      808,
      815,
      821,
      827,
      833,
      838,
      844,
      849,
      854,
      859,
      864,
      869,
      873,
      877,
      881,
      885,
      889,
      892,
      896,
      899,
      902,
      905,
      907,
      909,
      912,
      914,
      915,
      917,
      918,
      919,
      920,
      921,
      921,
      922,
      922,
      922,
      921,
      921,
      920,
      919,
      918,
      916,
      915,
      913,
      911,
      909,
      906,
      904,
      901,
      898,
      895,
      891,
      887,
      883,
      879,
      875,
      870,
      866,
      861,
      856,
      850,
      845,
      839,
      833,
      827,
      821,
      814,
      808,
      801,
      794,
      786,
      779,
      771,
      764,
      756,
      747,
      739,
      731,
      722,
      713,
      704,
      695,
      686,
      676,
      667,
      657,
      647,
      637,
    ],
    10: [
      789,
      795,
      802,
      808,
      814,
      821,
      826,
      832,
      838,
      843,
      848,
      853,
      858,
      863,
      868,
      872,
      876,
      880,
      884,
      888,
      891,
      894,
      898,
      900,
      903,
      906,
      908,
      910,
      912,
      914,
      915,
      916,
      918,
      918,
      919,
      920,
      920,
      920,
      920,
      919,
      919,
      918,
      917,
      916,
      915,
      913,
      911,
      909,
      907,
      904,
      902,
      899,
      896,
      892,
      889,
      885,
      881,
      877,
      873,
      868,
      864,
      859,
      854,
      848,
      843,
      837,
      831,
      825,
      819,
      812,
      806,
      799,
      792,
      784,
      777,
      770,
      762,
      754,
      746,
      738,
      729,
      720,
      712,
      703,
      694,
      684,
      675,
      665,
      656,
      646,
      636,
    ],
    15: [
      789,
      795,
      802,
      808,
      814,
      820,
      826,
      831,
      837,
      842,
      847,
      852,
      857,
      862,
      866,
      870,
      874,
      878,
      882,
      886,
      889,
      892,
      895,
      898,
      901,
      903,
      905,
      907,
      909,
      911,
      912,
      914,
      915,
      915,
      916,
      917,
      917,
      917,
      917,
      916,
      916,
      915,
      914,
      913,
      911,
      910,
      908,
      906,
      903,
      901,
      898,
      895,
      892,
      889,
      886,
      882,
      878,
      874,
      870,
      865,
      860,
      855,
      850,
      845,
      839,
      834,
      828,
      822,
      815,
      809,
      802,
      796,
      789,
      782,
      774,
      767,
      759,
      751,
      743,
      735,
      726,
      718,
      709,
      700,
      691,
      682,
      673,
      663,
      654,
      644,
      634,
    ],
    20: [
      789,
      795,
      801,
      807,
      813,
      819,
      825,
      830,
      835,
      841,
      846,
      850,
      855,
      860,
      864,
      868,
      872,
      876,
      879,
      883,
      886,
      889,
      892,
      895,
      897,
      900,
      902,
      904,
      906,
      907,
      909,
      910,
      911,
      911,
      912,
      912,
      913,
      913,
      912,
      912,
      911,
      910,
      909,
      908,
      907,
      905,
      903,
      901,
      899,
      896,
      894,
      891,
      888,
      884,
      881,
      877,
      873,
      869,
      865,
      860,
      856,
      851,
      845,
      840,
      835,
      829,
      823,
      817,
      811,
      805,
      798,
      791,
      784,
      777,
      770,
      762,
      755,
      747,
      739,
      731,
      722,
      714,
      705,
      697,
      688,
      679,
      669,
      660,
      651,
      641,
      631,
    ],
    25: [
      789,
      795,
      801,
      807,
      812,
      818,
      823,
      829,
      834,
      839,
      844,
      848,
      853,
      857,
      861,
      865,
      869,
      873,
      876,
      879,
      882,
      885,
      888,
      891,
      893,
      895,
      897,
      899,
      901,
      902,
      904,
      905,
      906,
      906,
      907,
      907,
      907,
      907,
      907,
      906,
      905,
      905,
      903,
      902,
      901,
      899,
      897,
      895,
      893,
      890,
      887,
      884,
      881,
      878,
      874,
      871,
      867,
      863,
      858,
      854,
      849,
      844,
      839,
      834,
      829,
      823,
      817,
      811,
      805,
      799,
      792,
      785,
      778,
      771,
      764,
      757,
      749,
      741,
      734,
      726,
      717,
      709,
      700,
      692,
      683,
      674,
      665,
      656,
      646,
      637,
      627,
    ],
    30: [
      789,
      794,
      800,
      806,
      811,
      817,
      822,
      827,
      832,
      836,
      841,
      845,
      850,
      854,
      858,
      861,
      865,
      869,
      872,
      875,
      878,
      881,
      883,
      886,
      888,
      890,
      892,
      894,
      895,
      896,
      898,
      898,
      899,
      900,
      900,
      900,
      900,
      900,
      900,
      899,
      898,
      897,
      896,
      895,
      893,
      891,
      890,
      887,
      885,
      882,
      880,
      877,
      874,
      870,
      867,
      863,
      859,
      855,
      851,
      846,
      841,
      837,
      831,
      826,
      821,
      815,
      810,
      804,
      797,
      791,
      785,
      778,
      771,
      764,
      757,
      750,
      742,
      735,
      727,
      719,
      711,
      702,
      694,
      685,
      677,
      668,
      659,
      650,
      641,
      632,
      622,
    ],
    35: [
      789,
      794,
      800,
      805,
      810,
      815,
      820,
      825,
      829,
      834,
      838,
      842,
      846,
      850,
      854,
      857,
      861,
      864,
      867,
      870,
      873,
      875,
      878,
      880,
      882,
      884,
      885,
      887,
      888,
      889,
      890,
      891,
      892,
      892,
      893,
      893,
      892,
      892,
      892,
      891,
      890,
      889,
      888,
      886,
      885,
      883,
      881,
      878,
      876,
      873,
      871,
      868,
      864,
      861,
      858,
      854,
      850,
      846,
      841,
      837,
      832,
      827,
      822,
      817,
      812,
      806,
      800,
      794,
      788,
      782,
      776,
      769,
      762,
      755,
      748,
      741,
      734,
      726,
      718,
      710,
      703,
      695,
      686,
      678,
      669,
      661,
      652,
      643,
      634,
      625,
      615,
    ],
    40: [
      789,
      794,
      799,
      804,
      809,
      813,
      818,
      822,
      826,
      831,
      835,
      838,
      842,
      846,
      849,
      852,
      855,
      858,
      861,
      864,
      866,
      869,
      871,
      873,
      875,
      876,
      878,
      879,
      880,
      881,
      882,
      883,
      883,
      884,
      884,
      884,
      883,
      883,
      882,
      881,
      880,
      879,
      878,
      876,
      875,
      873,
      870,
      868,
      866,
      863,
      860,
      857,
      854,
      850,
      847,
      843,
      839,
      835,
      831,
      826,
      821,
      817,
      812,
      806,
      801,
      795,
      790,
      784,
      778,
      771,
      765,
      759,
      752,
      745,
      738,
      731,
      724,
      716,
      708,
      701,
      693,
      685,
      677,
      668,
      660,
      652,
      643,
      634,
      625,
      616,
      607,
    ],
    45: [
      789,
      793,
      798,
      803,
      807,
      811,
      815,
      819,
      823,
      827,
      831,
      834,
      838,
      841,
      844,
      847,
      850,
      852,
      855,
      857,
      860,
      862,
      863,
      865,
      867,
      868,
      870,
      871,
      872,
      872,
      873,
      874,
      874,
      874,
      874,
      874,
      873,
      873,
      872,
      871,
      870,
      868,
      867,
      865,
      863,
      861,
      859,
      857,
      854,
      851,
      848,
      845,
      842,
      838,
      835,
      831,
      827,
      823,
      818,
      814,
      809,
      804,
      799,
      794,
      789,
      783,
      777,
      772,
      766,
      759,
      753,
      747,
      740,
      733,
      726,
      719,
      712,
      705,
      697,
      690,
      682,
      674,
      666,
      658,
      650,
      641,
      633,
      624,
      616,
      607,
      598,
    ],
    50: [
      789,
      793,
      797,
      801,
      805,
      809,
      813,
      816,
      820,
      823,
      827,
      830,
      833,
      836,
      838,
      841,
      843,
      846,
      848,
      850,
      852,
      854,
      855,
      857,
      858,
      859,
      860,
      861,
      862,
      863,
      863,
      863,
      863,
      863,
      863,
      862,
      862,
      861,
      860,
      859,
      858,
      856,
      855,
      853,
      851,
      849,
      846,
      844,
      841,
      838,
      835,
      832,
      829,
      825,
      821,
      817,
      813,
      809,
      805,
      800,
      796,
      791,
      786,
      781,
      775,
      769,
      764,
      758,
      752,
      746,
      740,
      733,
      727,
      720,
      713,
      706,
      699,
      692,
      684,
      677,
      669,
      662,
      654,
      646,
      638,
      629,
      621,
      613,
      604,
      596,
      587,
    ],
    55: [
      789,
      792,
      796,
      800,
      803,
      807,
      810,
      813,
      816,
      819,
      822,
      825,
      827,
      830,
      832,
      834,
      836,
      839,
      841,
      842,
      844,
      845,
      846,
      848,
      849,
      850,
      850,
      851,
      852,
      852,
      852,
      852,
      852,
      851,
      851,
      850,
      849,
      849,
      847,
      846,
      845,
      843,
      841,
      839,
      837,
      835,
      832,
      830,
      827,
      824,
      821,
      818,
      814,
      811,
      807,
      803,
      799,
      794,
      790,
      785,
      781,
      776,
      771,
      766,
      760,
      755,
      749,
      743,
      738,
      731,
      725,
      719,
      712,
      706,
      699,
      692,
      685,
      678,
      671,
      663,
      656,
      648,
      640,
      632,
      625,
      617,
      608,
      600,
      592,
      584,
      575,
    ],
    60: [
      789,
      792,
      795,
      798,
      801,
      804,
      807,
      810,
      812,
      815,
      817,
      819,
      822,
      824,
      826,
      827,
      829,
      831,
      832,
      834,
      835,
      836,
      837,
      838,
      839,
      839,
      840,
      840,
      840,
      840,
      840,
      840,
      839,
      839,
      838,
      837,
      836,
      835,
      834,
      832,
      831,
      829,
      827,
      825,
      822,
      820,
      817,
      815,
      812,
      809,
      806,
      802,
      799,
      795,
      791,
      787,
      783,
      779,
      774,
      770,
      765,
      760,
      755,
      750,
      744,
      739,
      733,
      727,
      722,
      716,
      709,
      703,
      696,
      690,
      683,
      676,
      669,
      663,
      655,
      648,
      641,
      633,
      626,
      618,
      610,
      602,
      594,
      586,
      578,
      570,
      562,
    ],
    65: [
      789,
      791,
      794,
      797,
      799,
      802,
      804,
      806,
      808,
      810,
      812,
      814,
      816,
      817,
      819,
      820,
      821,
      823,
      824,
      825,
      826,
      826,
      827,
      827,
      828,
      828,
      828,
      828,
      828,
      828,
      827,
      827,
      826,
      825,
      825,
      823,
      822,
      821,
      819,
      818,
      816,
      814,
      812,
      809,
      807,
      804,
      802,
      799,
      796,
      792,
      789,
      786,
      782,
      778,
      774,
      770,
      766,
      762,
      757,
      752,
      748,
      743,
      738,
      732,
      727,
      722,
      716,
      710,
      704,
      698,
      692,
      686,
      679,
      673,
      667,
      660,
      653,
      646,
      639,
      632,
      625,
      617,
      610,
      602,
      594,
      587,
      579,
      571,
      563,
      555,
      547,
    ],
    70: [
      789,
      791,
      793,
      795,
      797,
      799,
      801,
      802,
      804,
      805,
      807,
      808,
      809,
      810,
      811,
      812,
      813,
      814,
      815,
      815,
      816,
      816,
      816,
      816,
      816,
      816,
      816,
      816,
      815,
      815,
      814,
      813,
      812,
      811,
      810,
      809,
      807,
      806,
      804,
      802,
      800,
      798,
      795,
      793,
      790,
      788,
      785,
      782,
      779,
      775,
      772,
      768,
      764,
      760,
      756,
      752,
      748,
      744,
      739,
      734,
      729,
      725,
      719,
      714,
      709,
      703,
      698,
      692,
      686,
      680,
      674,
      668,
      661,
      655,
      649,
      643,
      635,
      628,
      621,
      615,
      607,
      600,
      593,
      586,
      578,
      571,
      563,
      555,
      548,
      540,
      532,
    ],
    75: [
      789,
      790,
      792,
      793,
      795,
      796,
      797,
      798,
      799,
      800,
      801,
      802,
      803,
      803,
      804,
      804,
      805,
      805,
      805,
      805,
      805,
      805,
      805,
      805,
      805,
      804,
      804,
      803,
      802,
      801,
      800,
      799,
      798,
      796,
      795,
      793,
      792,
      790,
      788,
      786,
      783,
      781,
      778,
      776,
      773,
      770,
      767,
      764,
      761,
      757,
      753,
      750,
      746,
      742,
      738,
      734,
      729,
      725,
      720,
      715,
      711,
      705,
      700,
      695,
      690,
      684,
      679,
      673,
      667,
      661,
      655,
      649,
      643,
      636,
      630,
      624,
      617,
      610,
      603,
      597,
      590,
      583,
      575,
      568,
      561,
      553,
      546,
      538,
      531,
      523,
      515,
    ],
    80: [
      789,
      790,
      791,
      792,
      792,
      793,
      794,
      794,
      795,
      795,
      795,
      796,
      796,
      796,
      796,
      796,
      796,
      796,
      795,
      795,
      795,
      794,
      794,
      793,
      792,
      791,
      790,
      789,
      788,
      787,
      786,
      784,
      783,
      781,
      779,
      777,
      775,
      773,
      771,
      769,
      766,
      764,
      761,
      758,
      755,
      752,
      749,
      745,
      742,
      738,
      735,
      731,
      727,
      723,
      719,
      714,
      710,
      705,
      700,
      696,
      691,
      686,
      681,
      675,
      670,
      665,
      659,
      653,
      648,
      642,
      635,
      629,
      623,
      617,
      610,
      604,
      598,
      591,
      584,
      578,
      571,
      564,
      556,
      549,
      543,
      536,
      528,
      521,
      513,
      506,
      498,
    ],
    85: [
      789,
      789,
      789,
      790,
      790,
      790,
      790,
      790,
      790,
      790,
      790,
      789,
      789,
      788,
      788,
      787,
      787,
      786,
      785,
      785,
      784,
      783,
      782,
      781,
      780,
      778,
      777,
      776,
      774,
      772,
      771,
      769,
      767,
      765,
      763,
      761,
      759,
      756,
      754,
      751,
      748,
      745,
      743,
      740,
      736,
      733,
      730,
      726,
      723,
      719,
      715,
      711,
      707,
      703,
      699,
      694,
      690,
      685,
      680,
      675,
      670,
      665,
      660,
      655,
      650,
      644,
      639,
      633,
      627,
      621,
      615,
      609,
      603,
      597,
      591,
      584,
      578,
      572,
      565,
      558,
      551,
      545,
      538,
      530,
      524,
      517,
      510,
      503,
      495,
      488,
      481,
    ],
    90: [
      789,
      788,
      788,
      788,
      788,
      787,
      787,
      786,
      785,
      784,
      784,
      783,
      782,
      781,
      780,
      779,
      777,
      776,
      775,
      774,
      772,
      771,
      769,
      768,
      766,
      765,
      763,
      761,
      759,
      757,
      755,
      753,
      751,
      749,
      746,
      744,
      741,
      739,
      736,
      733,
      730,
      727,
      724,
      721,
      717,
      714,
      710,
      707,
      703,
      699,
      695,
      691,
      687,
      682,
      678,
      674,
      669,
      664,
      659,
      655,
      649,
      644,
      639,
      634,
      629,
      623,
      617,
      612,
      606,
      600,
      595,
      589,
      582,
      576,
      570,
      564,
      557,
      551,
      545,
      538,
      532,
      525,
      518,
      512,
      505,
      498,
      491,
      484,
      477,
      470,
      463,
    ],
    95: [
      789,
      788,
      787,
      786,
      785,
      784,
      783,
      782,
      780,
      779,
      778,
      776,
      775,
      773,
      771,
      770,
      768,
      766,
      764,
      763,
      761,
      759,
      757,
      755,
      753,
      751,
      749,
      747,
      744,
      742,
      740,
      737,
      735,
      732,
      729,
      727,
      724,
      721,
      718,
      715,
      712,
      708,
      705,
      701,
      698,
      694,
      691,
      687,
      683,
      679,
      675,
      670,
      666,
      662,
      657,
      653,
      648,
      643,
      638,
      633,
      628,
      623,
      618,
      613,
      607,
      602,
      596,
      591,
      585,
      579,
      573,
      567,
      562,
      556,
      549,
      543,
      537,
      531,
      524,
      518,
      511,
      505,
      498,
      492,
      485,
      478,
      472,
      465,
      458,
      451,
      445,
    ],
    100: [
      789,
      787,
      786,
      784,
      783,
      781,
      779,
      778,
      776,
      774,
      772,
      770,
      767,
      765,
      763,
      761,
      759,
      757,
      754,
      752,
      749,
      747,
      745,
      742,
      740,
      737,
      735,
      732,
      729,
      727,
      724,
      721,
      718,
      715,
      712,
      709,
      706,
      703,
      699,
      696,
      693,
      689,
      686,
      682,
      678,
      674,
      670,
      666,
      662,
      658,
      654,
      650,
      645,
      641,
      636,
      631,
      627,
      622,
      617,
      612,
      607,
      602,
      596,
      591,
      586,
      580,
      575,
      569,
      563,
      558,
      552,
      546,
      540,
      534,
      528,
      522,
      516,
      510,
      504,
      497,
      491,
      485,
      478,
      472,
      465,
      459,
      452,
      446,
      439,
      433,
      426,
    ],
    105: [
      789,
      787,
      785,
      783,
      780,
      778,
      776,
      773,
      771,
      768,
      766,
      763,
      760,
      758,
      755,
      752,
      749,
      746,
      744,
      741,
      738,
      735,
      732,
      729,
      726,
      723,
      720,
      717,
      714,
      711,
      708,
      704,
      702,
      698,
      695,
      691,
      688,
      685,
      681,
      677,
      674,
      670,
      666,
      662,
      658,
      654,
      650,
      646,
      642,
      637,
      633,
      628,
      624,
      619,
      615,
      610,
      605,
      600,
      595,
      590,
      585,
      580,
      575,
      569,
      564,
      558,
      553,
      548,
      542,
      536,
      530,
      525,
      519,
      513,
      507,
      501,
      495,
      489,
      483,
      477,
      470,
      464,
      458,
      452,
      445,
      439,
      433,
      426,
      420,
      414,
      407,
    ],
    110: [
      789,
      786,
      784,
      781,
      778,
      775,
      772,
      769,
      766,
      763,
      760,
      757,
      753,
      750,
      747,
      743,
      740,
      737,
      733,
      730,
      726,
      723,
      720,
      716,
      713,
      709,
      706,
      703,
      699,
      695,
      692,
      688,
      685,
      681,
      677,
      674,
      670,
      666,
      662,
      659,
      655,
      651,
      647,
      643,
      638,
      634,
      630,
      626,
      621,
      617,
      612,
      607,
      603,
      598,
      593,
      588,
      584,
      579,
      574,
      568,
      563,
      558,
      553,
      547,
      542,
      537,
      531,
      526,
      520,
      515,
      509,
      503,
      497,
      492,
      486,
      480,
      474,
      468,
      462,
      456,
      450,
      444,
      438,
      432,
      426,
      419,
      413,
      407,
      401,
      395,
      389,
    ],
    115: [
      789,
      786,
      782,
      779,
      776,
      772,
      769,
      765,
      762,
      758,
      754,
      750,
      746,
      743,
      739,
      735,
      731,
      727,
      723,
      719,
      715,
      711,
      707,
      703,
      700,
      696,
      692,
      688,
      684,
      680,
      676,
      672,
      668,
      664,
      660,
      656,
      652,
      648,
      644,
      640,
      636,
      631,
      627,
      623,
      618,
      614,
      610,
      605,
      601,
      596,
      591,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      552,
      547,
      542,
      537,
      531,
      526,
      521,
      515,
      510,
      504,
      499,
      493,
      488,
      482,
      476,
      470,
      465,
      459,
      453,
      448,
      441,
      436,
      430,
      424,
      418,
      412,
      406,
      400,
      394,
      388,
      382,
      376,
      370,
    ],
    120: [
      789,
      785,
      781,
      778,
      774,
      770,
      766,
      762,
      757,
      753,
      749,
      744,
      740,
      735,
      731,
      726,
      722,
      717,
      713,
      708,
      704,
      700,
      695,
      691,
      687,
      682,
      678,
      673,
      669,
      665,
      661,
      656,
      652,
      647,
      643,
      639,
      634,
      630,
      626,
      621,
      617,
      612,
      608,
      603,
      599,
      594,
      589,
      585,
      580,
      575,
      571,
      566,
      561,
      556,
      551,
      546,
      541,
      536,
      531,
      526,
      520,
      515,
      510,
      505,
      499,
      494,
      488,
      483,
      477,
      472,
      466,
      461,
      455,
      450,
      444,
      438,
      432,
      427,
      421,
      416,
      410,
      404,
      398,
      392,
      387,
      381,
      375,
      369,
      364,
      358,
      352,
    ],
    125: [
      789,
      784,
      780,
      776,
      772,
      767,
      763,
      758,
      753,
      748,
      743,
      738,
      734,
      729,
      724,
      718,
      714,
      709,
      704,
      699,
      694,
      689,
      684,
      679,
      674,
      669,
      665,
      660,
      655,
      650,
      645,
      641,
      636,
      631,
      626,
      622,
      617,
      613,
      608,
      603,
      598,
      594,
      589,
      584,
      579,
      575,
      570,
      565,
      560,
      555,
      550,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      505,
      499,
      494,
      489,
      483,
      478,
      473,
      467,
      462,
      456,
      451,
      446,
      440,
      435,
      429,
      423,
      418,
      412,
      407,
      401,
      396,
      390,
      384,
      379,
      373,
      368,
      362,
      357,
      351,
      345,
      340,
      334,
    ],
    130: [
      789,
      784,
      779,
      775,
      770,
      765,
      760,
      754,
      749,
      744,
      739,
      733,
      728,
      722,
      717,
      711,
      706,
      700,
      695,
      689,
      684,
      678,
      673,
      667,
      662,
      657,
      652,
      646,
      641,
      636,
      631,
      626,
      620,
      615,
      610,
      605,
      600,
      595,
      590,
      585,
      580,
      575,
      570,
      565,
      560,
      555,
      550,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      505,
      500,
      494,
      489,
      484,
      479,
      473,
      468,
      463,
      458,
      452,
      447,
      442,
      436,
      431,
      426,
      420,
      414,
      409,
      404,
      398,
      393,
      388,
      382,
      376,
      371,
      366,
      360,
      355,
      349,
      344,
      338,
      333,
      328,
      323,
      317,
    ],
    135: [
      789,
      784,
      778,
      773,
      768,
      762,
      757,
      751,
      746,
      740,
      734,
      728,
      722,
      716,
      710,
      704,
      698,
      692,
      686,
      680,
      674,
      669,
      663,
      657,
      651,
      645,
      639,
      634,
      628,
      622,
      617,
      611,
      605,
      600,
      595,
      589,
      584,
      579,
      573,
      568,
      562,
      557,
      552,
      547,
      542,
      536,
      531,
      526,
      521,
      516,
      511,
      506,
      500,
      495,
      490,
      485,
      480,
      474,
      469,
      464,
      459,
      453,
      448,
      443,
      438,
      432,
      427,
      422,
      416,
      411,
      406,
      400,
      395,
      390,
      384,
      379,
      374,
      369,
      363,
      358,
      353,
      348,
      342,
      337,
      332,
      326,
      321,
      316,
      311,
      306,
      301,
    ],
    140: [
      789,
      783,
      778,
      772,
      766,
      760,
      754,
      748,
      742,
      736,
      730,
      723,
      717,
      711,
      704,
      698,
      691,
      685,
      679,
      672,
      666,
      660,
      653,
      647,
      641,
      635,
      628,
      622,
      616,
      610,
      604,
      598,
      592,
      586,
      580,
      574,
      568,
      562,
      557,
      551,
      546,
      540,
      535,
      529,
      524,
      518,
      513,
      508,
      502,
      497,
      492,
      486,
      481,
      476,
      471,
      465,
      460,
      455,
      450,
      444,
      439,
      434,
      429,
      424,
      418,
      413,
      408,
      403,
      397,
      392,
      387,
      381,
      376,
      371,
      366,
      361,
      356,
      350,
      345,
      340,
      335,
      330,
      325,
      320,
      315,
      309,
      305,
      299,
      294,
      290,
      285,
    ],
    145: [
      789,
      783,
      777,
      771,
      765,
      759,
      752,
      746,
      739,
      733,
      726,
      719,
      712,
      706,
      699,
      692,
      685,
      679,
      672,
      665,
      659,
      652,
      645,
      639,
      632,
      625,
      619,
      612,
      605,
      599,
      592,
      586,
      579,
      573,
      567,
      560,
      554,
      548,
      542,
      535,
      529,
      524,
      518,
      512,
      506,
      501,
      495,
      490,
      484,
      479,
      473,
      468,
      463,
      457,
      452,
      447,
      442,
      436,
      431,
      426,
      421,
      415,
      410,
      405,
      400,
      394,
      389,
      384,
      379,
      374,
      369,
      364,
      359,
      353,
      348,
      343,
      339,
      333,
      328,
      323,
      318,
      313,
      308,
      304,
      299,
      294,
      289,
      284,
      280,
      275,
      270,
    ],
    150: [
      789,
      782,
      776,
      770,
      763,
      757,
      750,
      743,
      737,
      730,
      722,
      715,
      708,
      701,
      694,
      687,
      680,
      673,
      666,
      659,
      652,
      645,
      638,
      631,
      624,
      617,
      610,
      603,
      596,
      589,
      582,
      575,
      568,
      562,
      555,
      548,
      542,
      535,
      528,
      522,
      516,
      509,
      503,
      497,
      491,
      485,
      479,
      473,
      467,
      462,
      456,
      451,
      445,
      440,
      435,
      429,
      424,
      419,
      413,
      408,
      403,
      398,
      393,
      387,
      382,
      377,
      372,
      367,
      362,
      357,
      352,
      347,
      342,
      337,
      332,
      327,
      322,
      317,
      313,
      308,
      303,
      298,
      293,
      289,
      284,
      279,
      275,
      270,
      265,
      261,
      256,
    ],
    155: [
      789,
      782,
      776,
      769,
      762,
      755,
      748,
      741,
      734,
      727,
      720,
      712,
      705,
      697,
      690,
      683,
      675,
      668,
      661,
      654,
      646,
      639,
      632,
      625,
      617,
      610,
      603,
      595,
      588,
      581,
      573,
      566,
      559,
      552,
      545,
      538,
      531,
      524,
      517,
      510,
      504,
      497,
      490,
      484,
      477,
      471,
      465,
      459,
      453,
      447,
      441,
      435,
      430,
      424,
      419,
      413,
      408,
      402,
      397,
      392,
      387,
      382,
      377,
      371,
      366,
      361,
      356,
      351,
      346,
      341,
      337,
      331,
      327,
      322,
      317,
      312,
      308,
      303,
      298,
      293,
      289,
      284,
      280,
      275,
      271,
      266,
      262,
      257,
      253,
      248,
      244,
    ],
    160: [
      789,
      782,
      775,
      768,
      761,
      754,
      747,
      740,
      732,
      725,
      717,
      710,
      702,
      694,
      687,
      679,
      672,
      664,
      657,
      649,
      642,
      634,
      627,
      619,
      612,
      604,
      596,
      589,
      581,
      574,
      566,
      559,
      552,
      544,
      537,
      529,
      522,
      515,
      508,
      501,
      494,
      487,
      480,
      473,
      467,
      460,
      453,
      447,
      440,
      434,
      428,
      422,
      416,
      411,
      405,
      399,
      394,
      388,
      383,
      378,
      372,
      367,
      362,
      357,
      352,
      347,
      343,
      337,
      333,
      328,
      323,
      318,
      313,
      309,
      304,
      300,
      295,
      290,
      286,
      281,
      277,
      272,
      268,
      264,
      259,
      255,
      250,
      246,
      242,
      237,
      233,
    ],
    165: [
      789,
      782,
      775,
      768,
      761,
      753,
      746,
      738,
      731,
      723,
      715,
      708,
      700,
      692,
      684,
      676,
      669,
      661,
      654,
      646,
      638,
      631,
      623,
      615,
      607,
      599,
      591,
      584,
      576,
      569,
      561,
      553,
      546,
      538,
      530,
      523,
      515,
      508,
      501,
      493,
      486,
      479,
      472,
      465,
      458,
      451,
      444,
      438,
      431,
      425,
      418,
      412,
      406,
      400,
      394,
      388,
      383,
      377,
      372,
      366,
      361,
      356,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      317,
      312,
      307,
      303,
      298,
      294,
      289,
      284,
      280,
      276,
      271,
      267,
      262,
      258,
      254,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
    ],
    170: [
      789,
      782,
      775,
      767,
      760,
      753,
      745,
      737,
      730,
      722,
      714,
      706,
      698,
      690,
      682,
      675,
      667,
      659,
      651,
      643,
      636,
      628,
      620,
      612,
      604,
      596,
      588,
      580,
      572,
      565,
      557,
      549,
      541,
      533,
      526,
      518,
      510,
      503,
      495,
      488,
      481,
      473,
      466,
      459,
      451,
      444,
      438,
      431,
      424,
      418,
      411,
      405,
      398,
      392,
      386,
      380,
      375,
      369,
      364,
      358,
      353,
      347,
      342,
      337,
      332,
      327,
      323,
      318,
      313,
      308,
      304,
      299,
      294,
      290,
      285,
      281,
      277,
      272,
      268,
      264,
      259,
      255,
      251,
      247,
      243,
      238,
      234,
      230,
      226,
      222,
      218,
    ],
    175: [
      789,
      782,
      774,
      767,
      760,
      752,
      745,
      737,
      729,
      721,
      713,
      705,
      697,
      689,
      681,
      673,
      665,
      658,
      650,
      642,
      634,
      626,
      618,
      610,
      602,
      594,
      586,
      578,
      570,
      562,
      555,
      546,
      538,
      530,
      522,
      515,
      507,
      500,
      492,
      485,
      478,
      471,
      463,
      456,
      448,
      441,
      433,
      427,
      420,
      413,
      407,
      400,
      394,
      388,
      382,
      376,
      370,
      364,
      359,
      353,
      348,
      342,
      337,
      332,
      327,
      322,
      317,
      312,
      308,
      303,
      298,
      294,
      289,
      285,
      280,
      276,
      272,
      267,
      263,
      259,
      255,
      251,
      247,
      242,
      238,
      234,
      230,
      226,
      222,
      218,
      214,
    ],
  },
  '14': {
    0: [
      701,
      707,
      713,
      719,
      725,
      730,
      736,
      742,
      747,
      752,
      758,
      762,
      767,
      772,
      777,
      781,
      785,
      789,
      793,
      797,
      800,
      804,
      807,
      810,
      813,
      815,
      818,
      820,
      823,
      825,
      826,
      828,
      829,
      831,
      832,
      833,
      833,
      834,
      834,
      835,
      835,
      834,
      834,
      833,
      833,
      832,
      830,
      829,
      828,
      826,
      824,
      822,
      820,
      817,
      815,
      812,
      809,
      806,
      802,
      799,
      795,
      791,
      787,
      783,
      778,
      774,
      769,
      764,
      759,
      753,
      748,
      742,
      736,
      730,
      724,
      718,
      712,
      705,
      698,
      691,
      684,
      677,
      669,
      662,
      654,
      646,
      638,
      630,
      622,
      614,
      605,
    ],
    5: [
      701,
      706,
      713,
      719,
      725,
      730,
      736,
      741,
      747,
      752,
      757,
      762,
      767,
      772,
      776,
      781,
      785,
      789,
      793,
      796,
      800,
      803,
      806,
      809,
      812,
      815,
      818,
      820,
      822,
      824,
      826,
      827,
      829,
      830,
      831,
      832,
      833,
      833,
      834,
      834,
      834,
      834,
      833,
      833,
      832,
      831,
      830,
      829,
      827,
      825,
      823,
      821,
      819,
      817,
      814,
      811,
      808,
      805,
      802,
      798,
      794,
      790,
      786,
      782,
      777,
      773,
      768,
      763,
      758,
      753,
      747,
      742,
      736,
      730,
      723,
      717,
      711,
      704,
      697,
      690,
      683,
      676,
      669,
      661,
      653,
      645,
      638,
      629,
      621,
      613,
      604,
    ],
    10: [
      701,
      706,
      712,
      718,
      724,
      730,
      736,
      741,
      746,
      752,
      757,
      761,
      766,
      771,
      775,
      780,
      784,
      788,
      792,
      795,
      799,
      802,
      805,
      808,
      811,
      814,
      816,
      818,
      821,
      822,
      824,
      826,
      827,
      828,
      830,
      830,
      831,
      832,
      832,
      832,
      832,
      832,
      831,
      831,
      830,
      829,
      828,
      827,
      825,
      823,
      821,
      819,
      817,
      814,
      812,
      809,
      806,
      803,
      800,
      796,
      792,
      788,
      784,
      780,
      776,
      771,
      766,
      761,
      756,
      751,
      745,
      739,
      734,
      728,
      721,
      715,
      709,
      702,
      695,
      688,
      681,
      674,
      667,
      659,
      652,
      644,
      636,
      628,
      619,
      611,
      602,
    ],
    15: [
      701,
      706,
      712,
      718,
      724,
      729,
      735,
      740,
      745,
      751,
      755,
      760,
      765,
      769,
      774,
      778,
      782,
      786,
      790,
      793,
      797,
      800,
      803,
      806,
      809,
      811,
      814,
      816,
      818,
      820,
      822,
      823,
      825,
      826,
      827,
      828,
      828,
      829,
      829,
      829,
      829,
      829,
      828,
      828,
      827,
      826,
      825,
      823,
      822,
      820,
      818,
      816,
      814,
      811,
      808,
      806,
      803,
      799,
      796,
      792,
      789,
      785,
      781,
      776,
      772,
      767,
      762,
      758,
      752,
      747,
      742,
      736,
      730,
      724,
      718,
      712,
      706,
      699,
      692,
      685,
      678,
      671,
      664,
      656,
      649,
      641,
      633,
      625,
      617,
      609,
      600,
    ],
    20: [
      701,
      706,
      712,
      718,
      723,
      729,
      734,
      739,
      744,
      749,
      754,
      759,
      763,
      768,
      772,
      776,
      780,
      784,
      787,
      791,
      794,
      797,
      800,
      803,
      806,
      808,
      810,
      813,
      815,
      816,
      818,
      819,
      821,
      822,
      823,
      824,
      824,
      824,
      825,
      825,
      825,
      824,
      824,
      823,
      822,
      821,
      820,
      819,
      817,
      815,
      813,
      811,
      809,
      806,
      804,
      801,
      798,
      795,
      791,
      787,
      784,
      780,
      776,
      771,
      767,
      762,
      758,
      753,
      748,
      742,
      737,
      731,
      725,
      720,
      713,
      707,
      701,
      694,
      687,
      681,
      674,
      667,
      659,
      652,
      644,
      637,
      629,
      621,
      613,
      605,
      596,
    ],
    25: [
      701,
      706,
      712,
      717,
      722,
      728,
      733,
      738,
      743,
      747,
      752,
      756,
      761,
      765,
      769,
      773,
      777,
      780,
      784,
      787,
      790,
      793,
      796,
      799,
      802,
      804,
      806,
      808,
      810,
      812,
      813,
      815,
      816,
      817,
      818,
      818,
      819,
      819,
      819,
      819,
      819,
      819,
      818,
      817,
      816,
      815,
      814,
      813,
      811,
      809,
      807,
      805,
      803,
      800,
      797,
      794,
      791,
      788,
      785,
      781,
      777,
      773,
      769,
      765,
      761,
      756,
      751,
      746,
      741,
      736,
      730,
      725,
      719,
      713,
      707,
      701,
      695,
      688,
      682,
      675,
      668,
      661,
      654,
      646,
      639,
      631,
      623,
      615,
      607,
      600,
      591,
    ],
    30: [
      701,
      706,
      711,
      716,
      721,
      726,
      731,
      736,
      741,
      745,
      750,
      754,
      758,
      762,
      766,
      770,
      773,
      777,
      780,
      783,
      786,
      789,
      792,
      794,
      797,
      799,
      801,
      803,
      805,
      806,
      808,
      809,
      810,
      811,
      812,
      812,
      812,
      813,
      813,
      813,
      812,
      812,
      811,
      810,
      809,
      808,
      807,
      805,
      804,
      802,
      800,
      797,
      795,
      792,
      790,
      787,
      784,
      780,
      777,
      773,
      770,
      766,
      762,
      757,
      753,
      748,
      743,
      739,
      734,
      728,
      723,
      717,
      711,
      706,
      700,
      693,
      687,
      681,
      674,
      667,
      660,
      654,
      646,
      639,
      632,
      624,
      617,
      609,
      601,
      593,
      585,
    ],
    35: [
      701,
      705,
      710,
      715,
      720,
      725,
      730,
      734,
      738,
      743,
      747,
      751,
      755,
      759,
      762,
      766,
      769,
      772,
      775,
      778,
      781,
      784,
      786,
      789,
      791,
      793,
      795,
      797,
      798,
      800,
      801,
      802,
      803,
      804,
      804,
      805,
      805,
      805,
      805,
      805,
      804,
      804,
      803,
      802,
      801,
      800,
      798,
      797,
      795,
      793,
      791,
      789,
      786,
      783,
      781,
      778,
      775,
      771,
      768,
      764,
      760,
      756,
      752,
      748,
      744,
      739,
      734,
      729,
      724,
      719,
      714,
      708,
      702,
      697,
      691,
      685,
      678,
      672,
      666,
      659,
      652,
      645,
      638,
      631,
      624,
      616,
      609,
      601,
      593,
      585,
      577,
    ],
    40: [
      701,
      705,
      710,
      714,
      719,
      723,
      728,
      732,
      736,
      740,
      744,
      747,
      751,
      755,
      758,
      761,
      764,
      767,
      770,
      773,
      775,
      778,
      780,
      782,
      784,
      786,
      788,
      790,
      791,
      792,
      793,
      794,
      795,
      796,
      796,
      796,
      796,
      796,
      796,
      796,
      795,
      795,
      794,
      793,
      792,
      790,
      789,
      787,
      785,
      783,
      781,
      778,
      776,
      773,
      770,
      767,
      764,
      761,
      757,
      754,
      750,
      746,
      742,
      738,
      733,
      728,
      724,
      719,
      714,
      708,
      703,
      698,
      692,
      686,
      680,
      674,
      668,
      662,
      655,
      648,
      642,
      635,
      628,
      621,
      614,
      606,
      599,
      592,
      584,
      576,
      568,
    ],
    45: [
      701,
      705,
      709,
      713,
      717,
      722,
      725,
      729,
      733,
      737,
      740,
      744,
      747,
      750,
      753,
      756,
      759,
      762,
      764,
      767,
      769,
      771,
      773,
      775,
      777,
      779,
      780,
      782,
      783,
      784,
      785,
      785,
      786,
      787,
      787,
      787,
      787,
      787,
      786,
      786,
      785,
      784,
      783,
      782,
      781,
      779,
      778,
      776,
      774,
      772,
      770,
      767,
      765,
      762,
      759,
      756,
      752,
      749,
      746,
      742,
      738,
      734,
      730,
      726,
      721,
      716,
      712,
      707,
      702,
      697,
      691,
      686,
      680,
      674,
      668,
      662,
      656,
      650,
      644,
      637,
      631,
      624,
      617,
      610,
      603,
      596,
      588,
      581,
      573,
      566,
      558,
    ],
    50: [
      701,
      704,
      708,
      712,
      716,
      720,
      723,
      727,
      730,
      733,
      736,
      739,
      742,
      745,
      748,
      751,
      753,
      756,
      758,
      760,
      762,
      764,
      766,
      768,
      769,
      770,
      772,
      773,
      774,
      775,
      775,
      776,
      776,
      777,
      777,
      777,
      776,
      776,
      775,
      775,
      774,
      773,
      772,
      771,
      769,
      768,
      766,
      764,
      762,
      760,
      757,
      755,
      752,
      749,
      746,
      743,
      740,
      736,
      733,
      729,
      725,
      721,
      717,
      712,
      708,
      703,
      698,
      694,
      689,
      684,
      678,
      673,
      667,
      661,
      656,
      649,
      643,
      637,
      631,
      625,
      618,
      611,
      605,
      598,
      591,
      584,
      577,
      569,
      562,
      554,
      547,
    ],
    55: [
      701,
      704,
      707,
      711,
      714,
      717,
      721,
      724,
      727,
      730,
      732,
      735,
      738,
      740,
      743,
      745,
      747,
      749,
      751,
      753,
      755,
      756,
      758,
      759,
      761,
      762,
      763,
      763,
      764,
      765,
      765,
      766,
      766,
      766,
      766,
      765,
      765,
      764,
      764,
      763,
      762,
      761,
      759,
      758,
      756,
      755,
      753,
      751,
      749,
      746,
      744,
      741,
      738,
      735,
      732,
      729,
      726,
      722,
      719,
      715,
      711,
      707,
      702,
      698,
      694,
      689,
      684,
      679,
      674,
      669,
      664,
      658,
      653,
      647,
      642,
      635,
      629,
      623,
      617,
      611,
      604,
      598,
      591,
      584,
      577,
      571,
      564,
      556,
      549,
      542,
      534,
    ],
    60: [
      701,
      703,
      706,
      709,
      712,
      715,
      718,
      720,
      723,
      725,
      728,
      730,
      732,
      735,
      736,
      738,
      740,
      742,
      744,
      745,
      747,
      748,
      749,
      750,
      751,
      752,
      753,
      753,
      754,
      754,
      754,
      754,
      754,
      754,
      754,
      753,
      753,
      752,
      751,
      750,
      749,
      748,
      746,
      745,
      743,
      741,
      739,
      737,
      734,
      732,
      729,
      727,
      724,
      721,
      718,
      714,
      711,
      707,
      703,
      700,
      696,
      692,
      687,
      683,
      678,
      674,
      669,
      664,
      659,
      654,
      649,
      643,
      637,
      632,
      627,
      621,
      614,
      608,
      602,
      596,
      589,
      583,
      577,
      570,
      563,
      556,
      550,
      543,
      535,
      528,
      521,
    ],
    65: [
      701,
      703,
      705,
      708,
      710,
      713,
      715,
      717,
      719,
      721,
      723,
      725,
      727,
      729,
      730,
      732,
      733,
      735,
      736,
      737,
      738,
      739,
      740,
      741,
      741,
      742,
      742,
      743,
      743,
      743,
      743,
      743,
      742,
      742,
      741,
      741,
      740,
      739,
      738,
      737,
      735,
      734,
      732,
      730,
      728,
      726,
      724,
      722,
      719,
      717,
      714,
      711,
      708,
      705,
      702,
      699,
      695,
      691,
      688,
      684,
      680,
      676,
      671,
      667,
      662,
      658,
      653,
      648,
      643,
      638,
      633,
      627,
      621,
      616,
      611,
      605,
      599,
      593,
      587,
      581,
      574,
      568,
      561,
      555,
      548,
      541,
      535,
      528,
      521,
      514,
      507,
    ],
    70: [
      701,
      702,
      704,
      706,
      708,
      710,
      712,
      714,
      715,
      717,
      718,
      720,
      721,
      722,
      724,
      725,
      726,
      727,
      728,
      728,
      729,
      730,
      730,
      731,
      731,
      731,
      732,
      732,
      731,
      731,
      731,
      730,
      730,
      729,
      728,
      727,
      726,
      725,
      724,
      722,
      721,
      719,
      717,
      715,
      713,
      711,
      709,
      706,
      704,
      701,
      698,
      695,
      692,
      689,
      686,
      682,
      679,
      675,
      671,
      667,
      663,
      659,
      654,
      650,
      645,
      641,
      636,
      631,
      626,
      621,
      616,
      610,
      605,
      599,
      594,
      588,
      582,
      576,
      570,
      564,
      558,
      552,
      545,
      539,
      533,
      526,
      519,
      513,
      506,
      499,
      492,
    ],
    75: [
      701,
      702,
      703,
      705,
      706,
      708,
      709,
      710,
      711,
      712,
      713,
      714,
      715,
      716,
      717,
      717,
      718,
      719,
      719,
      720,
      720,
      720,
      720,
      720,
      720,
      720,
      720,
      720,
      719,
      719,
      718,
      718,
      717,
      716,
      715,
      714,
      712,
      711,
      709,
      708,
      706,
      704,
      702,
      700,
      698,
      695,
      693,
      690,
      688,
      685,
      682,
      679,
      675,
      672,
      669,
      665,
      661,
      658,
      654,
      650,
      646,
      641,
      637,
      632,
      628,
      623,
      618,
      613,
      608,
      603,
      598,
      593,
      588,
      582,
      576,
      571,
      565,
      559,
      553,
      547,
      541,
      536,
      528,
      522,
      516,
      510,
      503,
      496,
      490,
      483,
      476,
    ],
    80: [
      701,
      701,
      702,
      703,
      704,
      705,
      706,
      706,
      707,
      708,
      708,
      709,
      709,
      709,
      710,
      710,
      710,
      710,
      710,
      710,
      710,
      710,
      710,
      710,
      709,
      709,
      708,
      708,
      707,
      706,
      705,
      704,
      703,
      702,
      701,
      699,
      698,
      696,
      694,
      692,
      691,
      688,
      686,
      684,
      682,
      679,
      676,
      674,
      671,
      668,
      665,
      661,
      658,
      655,
      651,
      647,
      644,
      640,
      636,
      632,
      628,
      623,
      619,
      614,
      610,
      605,
      600,
      595,
      590,
      585,
      580,
      575,
      569,
      564,
      558,
      553,
      547,
      541,
      535,
      530,
      524,
      518,
      511,
      505,
      499,
      493,
      487,
      480,
      473,
      467,
      461,
    ],
    85: [
      701,
      701,
      701,
      702,
      702,
      702,
      702,
      703,
      703,
      703,
      703,
      703,
      703,
      703,
      702,
      702,
      702,
      702,
      701,
      701,
      700,
      700,
      699,
      699,
      698,
      697,
      696,
      695,
      694,
      693,
      692,
      690,
      689,
      688,
      686,
      684,
      683,
      681,
      679,
      677,
      675,
      672,
      670,
      667,
      665,
      662,
      659,
      656,
      653,
      650,
      647,
      644,
      640,
      637,
      633,
      629,
      625,
      622,
      617,
      613,
      609,
      605,
      600,
      596,
      591,
      586,
      582,
      577,
      572,
      567,
      562,
      556,
      551,
      546,
      540,
      534,
      529,
      523,
      518,
      511,
      506,
      500,
      494,
      487,
      482,
      476,
      470,
      464,
      456,
      450,
      444,
    ],
    90: [
      701,
      700,
      700,
      700,
      700,
      699,
      699,
      699,
      698,
      698,
      697,
      697,
      696,
      696,
      695,
      694,
      693,
      693,
      692,
      691,
      690,
      689,
      688,
      687,
      686,
      685,
      684,
      682,
      681,
      680,
      678,
      677,
      675,
      673,
      671,
      669,
      667,
      665,
      663,
      661,
      658,
      656,
      653,
      651,
      648,
      645,
      642,
      639,
      636,
      632,
      629,
      626,
      622,
      618,
      615,
      611,
      607,
      603,
      599,
      595,
      590,
      586,
      581,
      577,
      572,
      567,
      563,
      558,
      553,
      548,
      543,
      537,
      532,
      527,
      521,
      516,
      510,
      505,
      499,
      493,
      487,
      482,
      476,
      470,
      464,
      458,
      452,
      446,
      439,
      433,
      427,
    ],
    95: [
      701,
      700,
      699,
      698,
      697,
      697,
      696,
      695,
      694,
      693,
      692,
      691,
      690,
      689,
      688,
      686,
      685,
      684,
      683,
      681,
      680,
      679,
      677,
      676,
      674,
      673,
      671,
      670,
      668,
      666,
      664,
      662,
      660,
      658,
      656,
      654,
      652,
      649,
      647,
      644,
      642,
      639,
      636,
      633,
      631,
      628,
      624,
      621,
      618,
      614,
      611,
      607,
      604,
      600,
      596,
      592,
      588,
      584,
      580,
      576,
      571,
      567,
      562,
      558,
      553,
      548,
      543,
      539,
      534,
      529,
      523,
      518,
      513,
      508,
      502,
      497,
      492,
      486,
      481,
      475,
      469,
      463,
      458,
      452,
      446,
      440,
      434,
      428,
      422,
      416,
      410,
    ],
    100: [
      701,
      699,
      698,
      697,
      695,
      694,
      693,
      691,
      690,
      688,
      686,
      685,
      683,
      682,
      680,
      678,
      677,
      675,
      673,
      671,
      670,
      668,
      666,
      664,
      663,
      660,
      659,
      656,
      654,
      652,
      650,
      648,
      646,
      643,
      641,
      639,
      636,
      633,
      631,
      628,
      625,
      622,
      619,
      616,
      613,
      610,
      607,
      603,
      600,
      596,
      593,
      589,
      585,
      581,
      577,
      573,
      569,
      565,
      561,
      556,
      552,
      548,
      543,
      538,
      534,
      529,
      524,
      519,
      514,
      509,
      504,
      499,
      494,
      489,
      483,
      478,
      473,
      467,
      462,
      456,
      451,
      445,
      439,
      434,
      428,
      422,
      416,
      411,
      405,
      399,
      393,
    ],
    105: [
      701,
      699,
      697,
      695,
      693,
      691,
      689,
      687,
      685,
      683,
      681,
      679,
      677,
      675,
      673,
      670,
      668,
      666,
      664,
      662,
      660,
      657,
      655,
      653,
      651,
      648,
      646,
      643,
      641,
      639,
      636,
      634,
      631,
      628,
      626,
      623,
      620,
      617,
      615,
      611,
      608,
      605,
      602,
      599,
      596,
      592,
      589,
      585,
      582,
      578,
      574,
      570,
      566,
      563,
      559,
      554,
      550,
      546,
      542,
      537,
      533,
      528,
      524,
      519,
      514,
      510,
      505,
      500,
      495,
      490,
      485,
      480,
      475,
      470,
      464,
      459,
      454,
      448,
      443,
      438,
      432,
      427,
      421,
      416,
      410,
      405,
      399,
      393,
      388,
      382,
      376,
    ],
    110: [
      701,
      698,
      696,
      693,
      691,
      689,
      686,
      684,
      681,
      678,
      676,
      673,
      671,
      668,
      665,
      663,
      660,
      657,
      655,
      652,
      649,
      647,
      644,
      641,
      639,
      636,
      633,
      631,
      628,
      625,
      622,
      619,
      617,
      614,
      611,
      607,
      605,
      601,
      598,
      595,
      592,
      588,
      585,
      582,
      578,
      575,
      571,
      567,
      564,
      560,
      556,
      552,
      548,
      544,
      540,
      536,
      531,
      527,
      523,
      518,
      514,
      509,
      505,
      500,
      495,
      490,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      445,
      440,
      435,
      430,
      425,
      419,
      414,
      409,
      403,
      398,
      392,
      387,
      381,
      376,
      370,
      365,
      359,
    ],
    115: [
      701,
      698,
      695,
      692,
      689,
      686,
      683,
      680,
      677,
      674,
      671,
      668,
      664,
      661,
      658,
      655,
      652,
      649,
      646,
      642,
      639,
      636,
      633,
      630,
      627,
      624,
      621,
      618,
      615,
      611,
      608,
      605,
      602,
      599,
      596,
      592,
      589,
      585,
      582,
      579,
      575,
      572,
      568,
      565,
      561,
      557,
      553,
      550,
      546,
      542,
      538,
      534,
      530,
      526,
      521,
      517,
      513,
      508,
      504,
      499,
      495,
      490,
      486,
      481,
      476,
      472,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      401,
      396,
      391,
      386,
      380,
      375,
      369,
      364,
      359,
      353,
      348,
      343,
    ],
    120: [
      701,
      697,
      694,
      690,
      687,
      684,
      680,
      677,
      673,
      669,
      666,
      662,
      659,
      655,
      651,
      648,
      644,
      641,
      637,
      633,
      630,
      626,
      623,
      619,
      615,
      612,
      609,
      605,
      602,
      598,
      595,
      591,
      588,
      584,
      581,
      577,
      574,
      570,
      566,
      563,
      559,
      555,
      552,
      548,
      544,
      540,
      536,
      532,
      528,
      524,
      520,
      516,
      512,
      507,
      503,
      499,
      494,
      490,
      485,
      481,
      476,
      472,
      467,
      463,
      458,
      453,
      448,
      444,
      438,
      434,
      429,
      424,
      419,
      414,
      409,
      404,
      399,
      394,
      389,
      384,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      342,
      337,
      332,
      327,
    ],
    125: [
      701,
      697,
      693,
      689,
      685,
      681,
      677,
      673,
      669,
      665,
      661,
      657,
      653,
      649,
      645,
      641,
      637,
      633,
      628,
      624,
      621,
      617,
      613,
      609,
      605,
      601,
      597,
      593,
      589,
      585,
      582,
      578,
      574,
      570,
      566,
      563,
      559,
      555,
      551,
      547,
      543,
      539,
      535,
      531,
      527,
      523,
      519,
      515,
      511,
      507,
      503,
      498,
      494,
      490,
      485,
      481,
      477,
      472,
      467,
      463,
      458,
      454,
      449,
      444,
      440,
      435,
      430,
      425,
      421,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      381,
      376,
      371,
      366,
      362,
      357,
      351,
      346,
      341,
      336,
      331,
      326,
      321,
      316,
      311,
    ],
    130: [
      701,
      696,
      692,
      688,
      683,
      679,
      675,
      670,
      666,
      661,
      657,
      652,
      648,
      643,
      639,
      634,
      630,
      625,
      621,
      616,
      612,
      607,
      603,
      599,
      594,
      590,
      586,
      581,
      577,
      573,
      569,
      565,
      561,
      556,
      552,
      548,
      544,
      540,
      536,
      532,
      528,
      524,
      520,
      515,
      511,
      507,
      503,
      499,
      494,
      490,
      486,
      481,
      477,
      473,
      468,
      464,
      459,
      455,
      450,
      446,
      441,
      436,
      432,
      427,
      423,
      418,
      413,
      408,
      403,
      399,
      394,
      389,
      384,
      379,
      375,
      370,
      365,
      360,
      355,
      350,
      345,
      340,
      335,
      330,
      326,
      321,
      316,
      311,
      306,
      301,
      297,
    ],
    135: [
      701,
      696,
      691,
      686,
      682,
      677,
      672,
      667,
      662,
      657,
      653,
      648,
      643,
      638,
      633,
      628,
      623,
      618,
      614,
      609,
      604,
      599,
      594,
      589,
      585,
      580,
      575,
      571,
      566,
      561,
      557,
      552,
      548,
      543,
      539,
      535,
      530,
      526,
      521,
      517,
      513,
      509,
      504,
      500,
      496,
      491,
      487,
      483,
      478,
      474,
      469,
      465,
      460,
      456,
      452,
      447,
      443,
      438,
      434,
      429,
      424,
      420,
      415,
      410,
      406,
      401,
      396,
      392,
      387,
      382,
      377,
      372,
      368,
      363,
      358,
      354,
      349,
      344,
      340,
      334,
      330,
      325,
      320,
      315,
      311,
      306,
      301,
      296,
      291,
      287,
      282,
    ],
    140: [
      701,
      695,
      690,
      685,
      680,
      675,
      670,
      665,
      659,
      654,
      649,
      643,
      638,
      633,
      628,
      622,
      617,
      612,
      607,
      602,
      597,
      591,
      586,
      581,
      576,
      571,
      566,
      561,
      556,
      551,
      546,
      541,
      536,
      531,
      526,
      522,
      517,
      512,
      508,
      503,
      499,
      494,
      490,
      485,
      481,
      476,
      472,
      467,
      463,
      458,
      454,
      449,
      445,
      440,
      436,
      431,
      427,
      422,
      417,
      413,
      408,
      404,
      399,
      394,
      390,
      385,
      380,
      376,
      371,
      366,
      362,
      357,
      352,
      348,
      343,
      338,
      334,
      329,
      324,
      319,
      315,
      310,
      306,
      301,
      296,
      292,
      287,
      283,
      278,
      274,
      269,
    ],
    145: [
      701,
      695,
      690,
      684,
      679,
      673,
      668,
      662,
      657,
      651,
      645,
      640,
      634,
      629,
      623,
      618,
      612,
      607,
      601,
      596,
      590,
      585,
      579,
      574,
      569,
      563,
      558,
      552,
      547,
      542,
      536,
      531,
      525,
      520,
      515,
      510,
      505,
      500,
      495,
      490,
      485,
      480,
      476,
      471,
      466,
      462,
      457,
      452,
      448,
      443,
      439,
      434,
      430,
      425,
      420,
      416,
      411,
      407,
      402,
      398,
      393,
      388,
      384,
      379,
      375,
      370,
      365,
      361,
      356,
      351,
      347,
      342,
      338,
      333,
      329,
      324,
      320,
      315,
      310,
      306,
      301,
      297,
      292,
      288,
      283,
      279,
      274,
      270,
      265,
      261,
      257,
    ],
    150: [
      701,
      695,
      689,
      683,
      678,
      672,
      666,
      660,
      654,
      648,
      642,
      637,
      631,
      625,
      619,
      613,
      608,
      602,
      596,
      591,
      585,
      579,
      573,
      568,
      562,
      556,
      550,
      545,
      539,
      533,
      528,
      522,
      516,
      511,
      505,
      500,
      495,
      489,
      484,
      478,
      473,
      468,
      463,
      458,
      453,
      449,
      444,
      439,
      434,
      430,
      425,
      420,
      416,
      411,
      406,
      402,
      397,
      393,
      388,
      383,
      379,
      374,
      370,
      365,
      360,
      356,
      351,
      347,
      342,
      338,
      333,
      329,
      324,
      320,
      315,
      311,
      306,
      302,
      297,
      293,
      289,
      284,
      280,
      276,
      271,
      267,
      262,
      258,
      254,
      250,
      246,
    ],
    155: [
      701,
      694,
      689,
      683,
      677,
      670,
      664,
      658,
      652,
      646,
      640,
      634,
      628,
      622,
      616,
      610,
      604,
      598,
      592,
      586,
      580,
      574,
      568,
      562,
      556,
      550,
      544,
      538,
      532,
      526,
      521,
      515,
      509,
      503,
      497,
      491,
      486,
      480,
      475,
      469,
      463,
      458,
      453,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      413,
      408,
      403,
      398,
      394,
      389,
      384,
      380,
      375,
      371,
      366,
      362,
      357,
      352,
      348,
      344,
      339,
      334,
      330,
      326,
      321,
      317,
      312,
      308,
      303,
      299,
      295,
      290,
      286,
      282,
      277,
      273,
      269,
      264,
      260,
      256,
      252,
      248,
      244,
      240,
      235,
    ],
    160: [
      701,
      694,
      688,
      682,
      676,
      669,
      663,
      657,
      650,
      644,
      638,
      632,
      625,
      619,
      613,
      607,
      601,
      595,
      589,
      582,
      576,
      570,
      564,
      557,
      551,
      545,
      539,
      533,
      527,
      521,
      515,
      509,
      502,
      497,
      490,
      484,
      479,
      473,
      467,
      461,
      455,
      450,
      444,
      439,
      433,
      428,
      422,
      417,
      412,
      407,
      402,
      397,
      392,
      388,
      383,
      378,
      373,
      369,
      364,
      359,
      355,
      350,
      346,
      341,
      337,
      332,
      328,
      324,
      319,
      315,
      311,
      306,
      302,
      297,
      293,
      289,
      284,
      280,
      276,
      272,
      268,
      264,
      259,
      255,
      251,
      247,
      243,
      239,
      235,
      231,
      227,
    ],
    165: [
      701,
      694,
      688,
      681,
      675,
      669,
      662,
      656,
      649,
      643,
      636,
      630,
      624,
      617,
      611,
      605,
      598,
      592,
      586,
      579,
      573,
      567,
      560,
      554,
      548,
      542,
      535,
      529,
      523,
      516,
      510,
      504,
      498,
      491,
      485,
      479,
      473,
      467,
      461,
      455,
      449,
      443,
      437,
      432,
      426,
      420,
      415,
      409,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      369,
      364,
      360,
      355,
      350,
      346,
      341,
      337,
      332,
      328,
      324,
      319,
      315,
      310,
      306,
      302,
      298,
      293,
      289,
      285,
      281,
      276,
      272,
      268,
      264,
      260,
      256,
      252,
      248,
      243,
      240,
      236,
      232,
      228,
      224,
      220,
    ],
    170: [
      701,
      694,
      688,
      681,
      675,
      668,
      661,
      655,
      648,
      642,
      635,
      629,
      622,
      616,
      610,
      603,
      597,
      590,
      584,
      577,
      571,
      564,
      558,
      552,
      545,
      539,
      532,
      526,
      520,
      513,
      507,
      500,
      494,
      488,
      482,
      475,
      469,
      463,
      457,
      450,
      444,
      438,
      432,
      427,
      421,
      415,
      409,
      404,
      399,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      344,
      339,
      335,
      330,
      326,
      321,
      317,
      313,
      308,
      304,
      300,
      296,
      291,
      287,
      283,
      278,
      275,
      270,
      266,
      262,
      258,
      254,
      250,
      246,
      242,
      238,
      234,
      230,
      226,
      222,
      219,
      215,
    ],
    175: [
      701,
      694,
      687,
      681,
      674,
      668,
      661,
      654,
      648,
      641,
      634,
      628,
      621,
      615,
      609,
      602,
      596,
      589,
      583,
      576,
      569,
      563,
      557,
      550,
      544,
      537,
      531,
      524,
      518,
      511,
      504,
      498,
      492,
      485,
      479,
      473,
      467,
      461,
      455,
      448,
      442,
      436,
      429,
      423,
      418,
      412,
      406,
      401,
      395,
      390,
      384,
      379,
      374,
      369,
      364,
      359,
      354,
      349,
      344,
      340,
      335,
      331,
      326,
      322,
      317,
      313,
      309,
      304,
      300,
      296,
      292,
      287,
      283,
      279,
      275,
      271,
      267,
      263,
      259,
      255,
      250,
      246,
      243,
      239,
      235,
      231,
      227,
      223,
      219,
      215,
      212,
    ],
  },
  '15': {
    0: [
      758,
      766,
      773,
      781,
      789,
      796,
      804,
      811,
      818,
      825,
      831,
      838,
      844,
      851,
      857,
      862,
      868,
      874,
      879,
      884,
      889,
      893,
      898,
      902,
      906,
      910,
      914,
      918,
      921,
      924,
      927,
      930,
      932,
      934,
      936,
      938,
      940,
      941,
      942,
      943,
      944,
      944,
      944,
      944,
      944,
      944,
      943,
      942,
      941,
      940,
      938,
      937,
      935,
      932,
      930,
      927,
      924,
      921,
      918,
      915,
      911,
      907,
      902,
      898,
      894,
      889,
      884,
      878,
      873,
      867,
      861,
      855,
      849,
      842,
      836,
      829,
      822,
      814,
      807,
      799,
      791,
      783,
      775,
      766,
      758,
      749,
      740,
      731,
      721,
      712,
      703,
    ],
    5: [
      758,
      765,
      773,
      781,
      789,
      796,
      803,
      811,
      818,
      824,
      831,
      838,
      844,
      850,
      856,
      862,
      868,
      873,
      878,
      883,
      888,
      893,
      897,
      902,
      906,
      910,
      913,
      917,
      920,
      923,
      926,
      929,
      931,
      933,
      935,
      937,
      939,
      940,
      941,
      942,
      943,
      943,
      944,
      944,
      943,
      943,
      942,
      941,
      940,
      939,
      937,
      936,
      934,
      931,
      929,
      926,
      924,
      920,
      917,
      914,
      910,
      906,
      902,
      897,
      893,
      888,
      883,
      877,
      872,
      866,
      860,
      854,
      848,
      842,
      835,
      828,
      821,
      813,
      806,
      798,
      790,
      782,
      774,
      766,
      757,
      748,
      739,
      730,
      721,
      711,
      702,
    ],
    10: [
      758,
      765,
      773,
      781,
      788,
      796,
      803,
      810,
      817,
      824,
      830,
      837,
      843,
      849,
      855,
      861,
      866,
      872,
      877,
      882,
      887,
      891,
      896,
      900,
      904,
      908,
      912,
      915,
      918,
      921,
      924,
      927,
      929,
      931,
      933,
      935,
      936,
      938,
      939,
      940,
      940,
      941,
      941,
      941,
      941,
      940,
      940,
      939,
      938,
      936,
      935,
      933,
      931,
      929,
      926,
      924,
      921,
      918,
      914,
      911,
      907,
      903,
      899,
      895,
      890,
      885,
      880,
      875,
      869,
      864,
      858,
      852,
      845,
      839,
      832,
      825,
      818,
      811,
      803,
      796,
      788,
      780,
      772,
      763,
      755,
      746,
      737,
      728,
      719,
      709,
      699,
    ],
    15: [
      758,
      765,
      773,
      780,
      788,
      795,
      802,
      809,
      816,
      822,
      829,
      835,
      841,
      847,
      853,
      859,
      864,
      869,
      875,
      880,
      884,
      889,
      893,
      897,
      901,
      905,
      909,
      912,
      915,
      918,
      921,
      923,
      926,
      928,
      930,
      931,
      933,
      934,
      935,
      936,
      936,
      937,
      937,
      937,
      937,
      936,
      936,
      935,
      933,
      932,
      930,
      929,
      927,
      924,
      922,
      919,
      916,
      913,
      910,
      906,
      902,
      899,
      894,
      890,
      885,
      881,
      875,
      870,
      865,
      859,
      853,
      847,
      841,
      834,
      828,
      821,
      814,
      807,
      799,
      791,
      784,
      776,
      768,
      759,
      751,
      742,
      733,
      724,
      715,
      706,
      696,
    ],
    20: [
      758,
      765,
      772,
      780,
      787,
      794,
      801,
      808,
      814,
      821,
      827,
      833,
      839,
      845,
      850,
      856,
      861,
      866,
      871,
      876,
      881,
      885,
      889,
      893,
      897,
      901,
      904,
      908,
      911,
      913,
      916,
      918,
      921,
      923,
      925,
      926,
      928,
      929,
      930,
      930,
      931,
      931,
      931,
      931,
      931,
      930,
      930,
      929,
      927,
      926,
      924,
      923,
      920,
      918,
      916,
      913,
      910,
      907,
      904,
      900,
      896,
      892,
      888,
      884,
      879,
      874,
      869,
      864,
      858,
      853,
      847,
      841,
      835,
      828,
      822,
      815,
      808,
      801,
      793,
      786,
      778,
      770,
      762,
      753,
      745,
      737,
      728,
      719,
      710,
      700,
      691,
    ],
    25: [
      758,
      765,
      772,
      779,
      786,
      793,
      799,
      806,
      812,
      818,
      824,
      830,
      836,
      842,
      847,
      852,
      858,
      862,
      867,
      872,
      876,
      880,
      884,
      888,
      892,
      896,
      899,
      902,
      905,
      908,
      910,
      912,
      914,
      916,
      918,
      920,
      921,
      922,
      923,
      923,
      924,
      924,
      924,
      924,
      923,
      923,
      922,
      921,
      920,
      918,
      917,
      915,
      913,
      910,
      908,
      905,
      902,
      899,
      895,
      892,
      888,
      884,
      880,
      876,
      871,
      866,
      861,
      856,
      850,
      845,
      839,
      833,
      827,
      820,
      814,
      807,
      800,
      793,
      786,
      778,
      771,
      763,
      755,
      746,
      738,
      730,
      721,
      712,
      703,
      694,
      685,
    ],
    30: [
      758,
      764,
      771,
      778,
      785,
      791,
      797,
      804,
      810,
      816,
      821,
      827,
      833,
      838,
      843,
      848,
      853,
      858,
      862,
      867,
      871,
      875,
      879,
      882,
      886,
      889,
      892,
      895,
      898,
      900,
      903,
      905,
      907,
      909,
      910,
      912,
      913,
      914,
      914,
      915,
      915,
      915,
      915,
      915,
      914,
      914,
      913,
      912,
      910,
      909,
      907,
      905,
      903,
      901,
      898,
      895,
      892,
      889,
      886,
      882,
      878,
      874,
      870,
      866,
      861,
      856,
      851,
      846,
      841,
      835,
      829,
      823,
      817,
      811,
      804,
      797,
      790,
      783,
      776,
      769,
      761,
      753,
      745,
      737,
      729,
      721,
      712,
      704,
      695,
      686,
      676,
    ],
    35: [
      758,
      764,
      771,
      777,
      783,
      789,
      795,
      801,
      807,
      812,
      818,
      823,
      828,
      833,
      838,
      843,
      848,
      852,
      856,
      861,
      865,
      868,
      872,
      875,
      879,
      882,
      884,
      887,
      890,
      892,
      894,
      896,
      898,
      900,
      901,
      902,
      903,
      904,
      904,
      905,
      905,
      905,
      905,
      904,
      904,
      903,
      902,
      901,
      899,
      898,
      896,
      894,
      892,
      889,
      887,
      884,
      881,
      877,
      874,
      870,
      866,
      862,
      858,
      854,
      849,
      844,
      839,
      834,
      829,
      823,
      817,
      812,
      805,
      799,
      793,
      786,
      779,
      772,
      765,
      758,
      750,
      742,
      735,
      727,
      719,
      710,
      702,
      693,
      685,
      676,
      667,
    ],
    40: [
      758,
      764,
      770,
      776,
      781,
      787,
      793,
      798,
      804,
      809,
      814,
      819,
      824,
      828,
      833,
      837,
      842,
      846,
      850,
      854,
      857,
      861,
      864,
      867,
      870,
      873,
      876,
      878,
      880,
      883,
      885,
      886,
      888,
      889,
      890,
      891,
      892,
      893,
      893,
      893,
      894,
      893,
      893,
      892,
      892,
      891,
      890,
      888,
      887,
      885,
      883,
      881,
      879,
      876,
      873,
      871,
      867,
      864,
      861,
      857,
      853,
      849,
      845,
      840,
      836,
      831,
      826,
      821,
      815,
      810,
      804,
      798,
      792,
      786,
      780,
      773,
      766,
      759,
      752,
      745,
      737,
      730,
      722,
      715,
      706,
      698,
      690,
      682,
      673,
      664,
      656,
    ],
    45: [
      758,
      763,
      769,
      774,
      779,
      785,
      790,
      795,
      800,
      805,
      809,
      814,
      818,
      823,
      827,
      831,
      835,
      839,
      842,
      846,
      849,
      852,
      855,
      858,
      861,
      863,
      866,
      868,
      870,
      872,
      874,
      875,
      876,
      878,
      879,
      879,
      880,
      880,
      881,
      881,
      881,
      880,
      880,
      879,
      878,
      877,
      876,
      874,
      873,
      871,
      869,
      867,
      864,
      862,
      859,
      856,
      853,
      849,
      846,
      842,
      838,
      834,
      830,
      825,
      821,
      816,
      811,
      806,
      800,
      795,
      789,
      783,
      777,
      771,
      765,
      758,
      751,
      745,
      738,
      730,
      723,
      716,
      708,
      700,
      692,
      684,
      676,
      668,
      660,
      651,
      642,
    ],
    50: [
      758,
      763,
      768,
      773,
      777,
      782,
      787,
      791,
      796,
      800,
      805,
      809,
      813,
      817,
      820,
      824,
      828,
      831,
      834,
      837,
      840,
      843,
      846,
      848,
      851,
      853,
      855,
      857,
      859,
      860,
      862,
      863,
      864,
      865,
      866,
      866,
      867,
      867,
      867,
      867,
      866,
      866,
      865,
      864,
      863,
      862,
      861,
      859,
      857,
      855,
      853,
      851,
      848,
      845,
      843,
      840,
      836,
      833,
      829,
      825,
      821,
      817,
      813,
      809,
      804,
      799,
      794,
      789,
      784,
      778,
      772,
      766,
      761,
      754,
      748,
      742,
      735,
      728,
      722,
      715,
      707,
      700,
      692,
      685,
      677,
      669,
      661,
      653,
      645,
      637,
      628,
    ],
    55: [
      758,
      762,
      767,
      771,
      775,
      780,
      784,
      788,
      792,
      796,
      799,
      803,
      807,
      810,
      813,
      816,
      820,
      823,
      825,
      828,
      831,
      833,
      836,
      838,
      840,
      842,
      843,
      845,
      846,
      848,
      849,
      850,
      851,
      851,
      852,
      852,
      852,
      852,
      852,
      852,
      851,
      850,
      849,
      848,
      847,
      846,
      844,
      842,
      840,
      838,
      836,
      834,
      831,
      828,
      825,
      822,
      819,
      815,
      811,
      808,
      804,
      799,
      795,
      790,
      786,
      781,
      776,
      771,
      766,
      760,
      755,
      748,
      742,
      737,
      730,
      724,
      717,
      711,
      704,
      697,
      690,
      683,
      675,
      668,
      660,
      653,
      645,
      637,
      629,
      621,
      612,
    ],
    60: [
      758,
      761,
      765,
      769,
      773,
      777,
      780,
      784,
      787,
      790,
      794,
      797,
      800,
      803,
      806,
      808,
      811,
      814,
      816,
      818,
      821,
      822,
      824,
      826,
      828,
      829,
      831,
      832,
      833,
      834,
      835,
      836,
      836,
      837,
      837,
      837,
      837,
      836,
      836,
      835,
      835,
      834,
      833,
      831,
      830,
      828,
      827,
      825,
      823,
      820,
      818,
      815,
      812,
      810,
      806,
      803,
      800,
      796,
      792,
      788,
      784,
      780,
      776,
      771,
      767,
      762,
      757,
      751,
      746,
      741,
      735,
      729,
      723,
      717,
      711,
      705,
      698,
      692,
      685,
      679,
      671,
      664,
      657,
      650,
      643,
      635,
      627,
      619,
      611,
      604,
      596,
    ],
    65: [
      758,
      761,
      764,
      767,
      770,
      773,
      777,
      779,
      782,
      785,
      788,
      790,
      793,
      795,
      798,
      800,
      802,
      804,
      806,
      808,
      810,
      811,
      813,
      814,
      816,
      817,
      818,
      819,
      819,
      820,
      820,
      821,
      821,
      821,
      821,
      821,
      820,
      820,
      819,
      818,
      817,
      816,
      815,
      813,
      812,
      810,
      808,
      806,
      804,
      801,
      799,
      796,
      793,
      790,
      787,
      783,
      780,
      776,
      772,
      768,
      764,
      760,
      755,
      751,
      746,
      741,
      736,
      731,
      726,
      720,
      715,
      709,
      703,
      697,
      691,
      685,
      678,
      672,
      665,
      659,
      652,
      645,
      638,
      631,
      624,
      616,
      609,
      601,
      593,
      585,
      577,
    ],
    70: [
      758,
      760,
      763,
      765,
      768,
      770,
      773,
      775,
      777,
      779,
      781,
      784,
      785,
      787,
      789,
      791,
      792,
      794,
      796,
      797,
      798,
      799,
      800,
      802,
      802,
      803,
      804,
      804,
      805,
      805,
      805,
      805,
      805,
      805,
      804,
      804,
      803,
      802,
      801,
      800,
      799,
      798,
      796,
      794,
      793,
      791,
      788,
      786,
      784,
      781,
      778,
      776,
      773,
      769,
      766,
      763,
      759,
      755,
      751,
      747,
      743,
      739,
      734,
      730,
      725,
      720,
      715,
      710,
      704,
      699,
      694,
      688,
      682,
      676,
      670,
      664,
      658,
      651,
      645,
      638,
      632,
      625,
      618,
      611,
      604,
      596,
      589,
      582,
      574,
      567,
      559,
    ],
    75: [
      758,
      760,
      762,
      763,
      765,
      767,
      769,
      770,
      772,
      774,
      775,
      776,
      778,
      779,
      780,
      782,
      783,
      784,
      785,
      786,
      786,
      787,
      788,
      788,
      789,
      789,
      789,
      789,
      789,
      789,
      789,
      789,
      788,
      788,
      787,
      786,
      785,
      784,
      783,
      782,
      780,
      778,
      777,
      775,
      773,
      771,
      768,
      766,
      763,
      760,
      758,
      754,
      751,
      748,
      745,
      741,
      737,
      733,
      729,
      725,
      721,
      717,
      712,
      708,
      703,
      698,
      693,
      688,
      682,
      677,
      671,
      666,
      660,
      654,
      648,
      642,
      636,
      630,
      623,
      617,
      610,
      604,
      596,
      590,
      583,
      576,
      568,
      561,
      554,
      547,
      539,
    ],
    80: [
      758,
      759,
      760,
      761,
      762,
      764,
      765,
      766,
      767,
      768,
      768,
      769,
      770,
      771,
      771,
      772,
      772,
      773,
      773,
      774,
      774,
      774,
      774,
      775,
      775,
      775,
      774,
      774,
      774,
      773,
      773,
      772,
      771,
      770,
      769,
      768,
      767,
      765,
      764,
      762,
      761,
      759,
      757,
      754,
      752,
      750,
      747,
      745,
      742,
      739,
      736,
      733,
      729,
      726,
      722,
      719,
      715,
      711,
      707,
      703,
      698,
      694,
      689,
      685,
      680,
      675,
      670,
      665,
      660,
      654,
      649,
      643,
      637,
      632,
      626,
      620,
      614,
      608,
      601,
      595,
      588,
      582,
      575,
      568,
      561,
      555,
      548,
      541,
      533,
      526,
      520,
    ],
    85: [
      758,
      758,
      759,
      759,
      760,
      760,
      761,
      761,
      761,
      761,
      762,
      762,
      762,
      762,
      762,
      762,
      762,
      762,
      762,
      762,
      761,
      761,
      761,
      761,
      760,
      760,
      759,
      758,
      757,
      757,
      756,
      755,
      754,
      752,
      751,
      749,
      748,
      746,
      744,
      742,
      740,
      738,
      736,
      734,
      731,
      729,
      726,
      723,
      720,
      717,
      714,
      710,
      707,
      703,
      700,
      696,
      692,
      688,
      684,
      680,
      675,
      671,
      666,
      661,
      657,
      652,
      647,
      641,
      636,
      631,
      626,
      620,
      614,
      609,
      603,
      597,
      591,
      585,
      579,
      572,
      566,
      559,
      553,
      546,
      540,
      533,
      527,
      520,
      512,
      505,
      499,
    ],
    90: [
      758,
      757,
      757,
      757,
      757,
      757,
      756,
      756,
      756,
      755,
      755,
      754,
      754,
      753,
      753,
      752,
      751,
      751,
      750,
      750,
      749,
      748,
      747,
      746,
      745,
      744,
      743,
      742,
      741,
      740,
      738,
      737,
      735,
      734,
      732,
      730,
      729,
      727,
      724,
      722,
      720,
      718,
      715,
      713,
      710,
      707,
      704,
      701,
      698,
      695,
      691,
      688,
      684,
      681,
      677,
      673,
      669,
      665,
      660,
      656,
      652,
      647,
      642,
      638,
      633,
      628,
      623,
      618,
      613,
      607,
      602,
      596,
      591,
      585,
      579,
      573,
      567,
      561,
      556,
      549,
      543,
      537,
      531,
      524,
      517,
      511,
      505,
      498,
      490,
      484,
      478,
    ],
    95: [
      758,
      757,
      756,
      755,
      754,
      753,
      752,
      751,
      750,
      749,
      748,
      747,
      746,
      744,
      743,
      742,
      741,
      740,
      738,
      737,
      736,
      735,
      733,
      732,
      730,
      729,
      727,
      726,
      724,
      723,
      721,
      719,
      717,
      715,
      713,
      711,
      709,
      707,
      704,
      702,
      699,
      697,
      694,
      691,
      688,
      685,
      682,
      679,
      675,
      672,
      668,
      665,
      661,
      657,
      653,
      649,
      645,
      641,
      637,
      632,
      628,
      623,
      619,
      614,
      609,
      604,
      599,
      594,
      589,
      583,
      578,
      573,
      567,
      561,
      556,
      550,
      544,
      538,
      532,
      526,
      520,
      514,
      508,
      502,
      495,
      489,
      483,
      476,
      470,
      463,
      456,
    ],
    100: [
      758,
      756,
      755,
      753,
      751,
      750,
      748,
      746,
      745,
      743,
      741,
      739,
      737,
      736,
      734,
      732,
      730,
      728,
      727,
      725,
      723,
      721,
      719,
      717,
      715,
      713,
      712,
      709,
      707,
      705,
      703,
      701,
      699,
      696,
      694,
      691,
      689,
      686,
      684,
      681,
      678,
      675,
      672,
      669,
      666,
      663,
      660,
      656,
      653,
      649,
      645,
      642,
      638,
      634,
      630,
      626,
      622,
      617,
      613,
      608,
      604,
      599,
      595,
      590,
      585,
      580,
      575,
      570,
      565,
      559,
      554,
      549,
      543,
      538,
      532,
      526,
      521,
      515,
      509,
      503,
      497,
      491,
      485,
      479,
      473,
      467,
      460,
      454,
      448,
      442,
      435,
    ],
    105: [
      758,
      755,
      753,
      751,
      749,
      746,
      744,
      742,
      739,
      737,
      734,
      732,
      729,
      727,
      724,
      722,
      720,
      717,
      715,
      712,
      710,
      707,
      705,
      703,
      700,
      698,
      695,
      693,
      690,
      688,
      685,
      683,
      680,
      677,
      675,
      672,
      669,
      666,
      663,
      660,
      657,
      654,
      651,
      648,
      644,
      641,
      637,
      634,
      630,
      626,
      623,
      619,
      615,
      611,
      606,
      602,
      598,
      594,
      589,
      585,
      580,
      575,
      571,
      566,
      561,
      556,
      551,
      546,
      541,
      535,
      530,
      525,
      519,
      514,
      508,
      503,
      497,
      492,
      486,
      480,
      474,
      468,
      463,
      457,
      451,
      445,
      439,
      432,
      427,
      420,
      414,
    ],
    110: [
      758,
      755,
      752,
      749,
      746,
      743,
      740,
      737,
      734,
      731,
      728,
      724,
      721,
      718,
      715,
      712,
      709,
      706,
      703,
      700,
      697,
      694,
      691,
      688,
      685,
      682,
      680,
      677,
      674,
      671,
      668,
      665,
      662,
      659,
      656,
      653,
      649,
      646,
      643,
      640,
      636,
      633,
      630,
      626,
      622,
      619,
      615,
      611,
      607,
      604,
      600,
      596,
      591,
      587,
      583,
      579,
      574,
      570,
      565,
      561,
      556,
      552,
      547,
      542,
      537,
      532,
      527,
      522,
      517,
      512,
      506,
      501,
      496,
      490,
      485,
      480,
      474,
      469,
      463,
      457,
      452,
      446,
      441,
      434,
      429,
      423,
      417,
      411,
      405,
      399,
      394,
    ],
    115: [
      758,
      754,
      751,
      747,
      743,
      740,
      736,
      732,
      729,
      725,
      721,
      717,
      714,
      710,
      706,
      702,
      699,
      695,
      691,
      688,
      685,
      681,
      677,
      674,
      671,
      667,
      664,
      660,
      657,
      654,
      650,
      647,
      644,
      640,
      637,
      633,
      630,
      626,
      623,
      619,
      616,
      612,
      608,
      605,
      601,
      597,
      593,
      589,
      585,
      581,
      577,
      573,
      569,
      564,
      560,
      556,
      551,
      547,
      542,
      538,
      533,
      528,
      523,
      519,
      514,
      509,
      504,
      499,
      494,
      488,
      483,
      478,
      473,
      467,
      462,
      457,
      451,
      446,
      440,
      435,
      429,
      424,
      418,
      413,
      407,
      401,
      396,
      390,
      384,
      379,
      373,
    ],
    120: [
      758,
      753,
      749,
      745,
      741,
      736,
      732,
      728,
      723,
      719,
      715,
      710,
      706,
      702,
      697,
      693,
      689,
      685,
      680,
      676,
      672,
      668,
      664,
      660,
      656,
      652,
      648,
      645,
      641,
      637,
      633,
      630,
      626,
      622,
      618,
      615,
      611,
      607,
      603,
      599,
      595,
      592,
      588,
      584,
      580,
      576,
      572,
      568,
      563,
      559,
      555,
      551,
      546,
      542,
      538,
      533,
      529,
      524,
      519,
      515,
      510,
      505,
      500,
      496,
      491,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      450,
      445,
      440,
      435,
      429,
      424,
      419,
      414,
      408,
      403,
      397,
      392,
      386,
      381,
      376,
      370,
      364,
      359,
      353,
    ],
    125: [
      758,
      753,
      748,
      743,
      738,
      734,
      729,
      724,
      719,
      714,
      709,
      704,
      699,
      694,
      689,
      684,
      679,
      674,
      670,
      665,
      661,
      656,
      651,
      647,
      642,
      638,
      634,
      629,
      625,
      621,
      617,
      612,
      608,
      604,
      600,
      596,
      592,
      588,
      584,
      580,
      576,
      572,
      567,
      563,
      559,
      555,
      551,
      546,
      542,
      538,
      534,
      529,
      525,
      520,
      516,
      511,
      507,
      502,
      497,
      493,
      488,
      483,
      478,
      474,
      469,
      464,
      459,
      454,
      449,
      444,
      439,
      434,
      429,
      424,
      418,
      413,
      408,
      403,
      398,
      392,
      387,
      382,
      377,
      372,
      366,
      361,
      356,
      350,
      345,
      339,
      335,
    ],
    130: [
      758,
      752,
      747,
      742,
      736,
      731,
      725,
      720,
      714,
      709,
      703,
      698,
      692,
      687,
      681,
      676,
      671,
      665,
      660,
      655,
      650,
      644,
      639,
      634,
      629,
      624,
      620,
      615,
      610,
      605,
      601,
      596,
      592,
      587,
      583,
      578,
      574,
      569,
      565,
      561,
      556,
      552,
      548,
      544,
      539,
      535,
      530,
      526,
      522,
      517,
      513,
      508,
      504,
      499,
      495,
      490,
      485,
      481,
      476,
      471,
      467,
      462,
      457,
      452,
      447,
      442,
      438,
      433,
      428,
      423,
      418,
      413,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      372,
      367,
      362,
      357,
      352,
      347,
      342,
      337,
      332,
      326,
      321,
      316,
    ],
    135: [
      758,
      752,
      746,
      740,
      734,
      728,
      722,
      716,
      710,
      704,
      698,
      692,
      686,
      680,
      674,
      668,
      662,
      657,
      651,
      645,
      639,
      634,
      628,
      623,
      617,
      612,
      607,
      601,
      596,
      591,
      586,
      581,
      576,
      571,
      566,
      561,
      556,
      552,
      547,
      543,
      538,
      533,
      529,
      524,
      520,
      515,
      511,
      506,
      502,
      497,
      493,
      488,
      483,
      479,
      474,
      470,
      465,
      460,
      455,
      451,
      446,
      441,
      437,
      432,
      427,
      422,
      417,
      412,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      364,
      358,
      353,
      348,
      344,
      339,
      334,
      329,
      324,
      319,
      314,
      309,
      304,
      299,
    ],
    140: [
      758,
      751,
      745,
      739,
      732,
      726,
      719,
      713,
      706,
      700,
      693,
      687,
      680,
      674,
      668,
      661,
      655,
      649,
      643,
      637,
      631,
      625,
      618,
      612,
      607,
      601,
      595,
      589,
      583,
      577,
      572,
      566,
      561,
      555,
      550,
      545,
      540,
      534,
      530,
      525,
      520,
      515,
      510,
      506,
      501,
      497,
      492,
      487,
      482,
      478,
      473,
      469,
      464,
      459,
      455,
      450,
      445,
      441,
      436,
      431,
      427,
      422,
      417,
      412,
      407,
      403,
      398,
      393,
      388,
      384,
      379,
      374,
      369,
      364,
      360,
      355,
      350,
      345,
      340,
      335,
      331,
      326,
      321,
      316,
      311,
      307,
      302,
      297,
      293,
      288,
      283,
    ],
    145: [
      758,
      751,
      744,
      737,
      730,
      724,
      717,
      710,
      703,
      696,
      689,
      682,
      675,
      669,
      662,
      655,
      649,
      642,
      636,
      629,
      623,
      616,
      610,
      603,
      597,
      591,
      584,
      578,
      572,
      566,
      560,
      553,
      548,
      542,
      536,
      530,
      525,
      519,
      514,
      508,
      503,
      498,
      493,
      488,
      483,
      479,
      474,
      469,
      464,
      460,
      455,
      450,
      445,
      441,
      436,
      431,
      427,
      422,
      417,
      413,
      408,
      403,
      399,
      394,
      389,
      384,
      380,
      375,
      370,
      366,
      361,
      356,
      352,
      347,
      342,
      337,
      333,
      328,
      323,
      319,
      314,
      309,
      305,
      300,
      296,
      291,
      286,
      282,
      277,
      273,
      268,
    ],
    150: [
      758,
      750,
      743,
      736,
      729,
      722,
      714,
      707,
      700,
      692,
      685,
      678,
      671,
      664,
      657,
      650,
      643,
      636,
      629,
      623,
      616,
      609,
      602,
      595,
      589,
      582,
      575,
      569,
      562,
      556,
      549,
      543,
      536,
      530,
      524,
      518,
      512,
      506,
      500,
      494,
      488,
      483,
      477,
      472,
      467,
      462,
      457,
      452,
      447,
      443,
      438,
      433,
      428,
      424,
      419,
      414,
      409,
      405,
      400,
      395,
      391,
      386,
      382,
      377,
      372,
      367,
      363,
      358,
      354,
      349,
      344,
      340,
      335,
      331,
      326,
      322,
      317,
      312,
      308,
      303,
      299,
      294,
      290,
      286,
      281,
      276,
      272,
      268,
      263,
      259,
      255,
    ],
    155: [
      758,
      750,
      743,
      735,
      728,
      720,
      712,
      705,
      697,
      690,
      682,
      675,
      667,
      660,
      653,
      646,
      639,
      631,
      624,
      617,
      610,
      603,
      596,
      588,
      581,
      574,
      567,
      560,
      554,
      547,
      540,
      533,
      527,
      520,
      514,
      507,
      501,
      495,
      488,
      482,
      476,
      470,
      464,
      458,
      453,
      448,
      442,
      437,
      432,
      427,
      422,
      418,
      413,
      408,
      403,
      399,
      394,
      389,
      384,
      380,
      375,
      371,
      366,
      361,
      357,
      352,
      348,
      343,
      339,
      334,
      330,
      325,
      321,
      316,
      312,
      307,
      303,
      298,
      294,
      290,
      285,
      281,
      277,
      272,
      268,
      264,
      260,
      255,
      251,
      247,
      243,
    ],
    160: [
      758,
      750,
      742,
      734,
      726,
      719,
      711,
      703,
      695,
      687,
      679,
      672,
      664,
      657,
      649,
      642,
      634,
      627,
      620,
      612,
      605,
      597,
      590,
      583,
      575,
      568,
      561,
      554,
      547,
      540,
      533,
      526,
      519,
      512,
      505,
      498,
      492,
      485,
      479,
      472,
      466,
      460,
      454,
      448,
      442,
      436,
      430,
      424,
      419,
      414,
      409,
      404,
      399,
      395,
      390,
      385,
      380,
      376,
      371,
      367,
      362,
      357,
      353,
      348,
      344,
      339,
      335,
      330,
      326,
      322,
      317,
      312,
      308,
      304,
      300,
      295,
      291,
      286,
      282,
      278,
      274,
      270,
      265,
      261,
      257,
      253,
      248,
      245,
      240,
      237,
      232,
    ],
    165: [
      758,
      750,
      742,
      734,
      726,
      718,
      709,
      701,
      693,
      685,
      677,
      670,
      662,
      654,
      647,
      639,
      632,
      624,
      616,
      608,
      601,
      593,
      586,
      579,
      571,
      564,
      556,
      549,
      542,
      534,
      527,
      520,
      513,
      506,
      499,
      492,
      485,
      478,
      471,
      465,
      458,
      452,
      445,
      439,
      433,
      427,
      421,
      415,
      410,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      370,
      365,
      360,
      356,
      351,
      347,
      342,
      338,
      333,
      329,
      324,
      320,
      316,
      311,
      307,
      303,
      298,
      294,
      290,
      285,
      281,
      277,
      273,
      269,
      264,
      261,
      256,
      252,
      248,
      244,
      240,
      236,
      232,
      228,
      224,
    ],
    170: [
      758,
      750,
      741,
      733,
      725,
      717,
      708,
      700,
      692,
      684,
      676,
      668,
      660,
      653,
      645,
      637,
      630,
      622,
      614,
      606,
      598,
      590,
      583,
      575,
      568,
      560,
      553,
      546,
      538,
      530,
      523,
      516,
      508,
      501,
      494,
      487,
      480,
      473,
      466,
      459,
      452,
      445,
      439,
      433,
      427,
      420,
      415,
      409,
      403,
      397,
      391,
      386,
      381,
      376,
      371,
      366,
      362,
      357,
      352,
      348,
      343,
      338,
      334,
      330,
      325,
      321,
      316,
      312,
      308,
      304,
      299,
      295,
      291,
      286,
      282,
      278,
      274,
      270,
      266,
      262,
      258,
      254,
      250,
      246,
      242,
      238,
      234,
      230,
      226,
      222,
      218,
    ],
    175: [
      758,
      749,
      741,
      733,
      725,
      716,
      708,
      700,
      691,
      683,
      675,
      667,
      659,
      652,
      644,
      636,
      628,
      620,
      612,
      604,
      596,
      589,
      581,
      573,
      566,
      558,
      551,
      543,
      535,
      527,
      520,
      513,
      505,
      498,
      492,
      485,
      478,
      471,
      464,
      457,
      450,
      442,
      435,
      429,
      423,
      417,
      411,
      405,
      399,
      393,
      387,
      382,
      376,
      371,
      366,
      362,
      357,
      352,
      347,
      343,
      338,
      334,
      329,
      325,
      321,
      316,
      312,
      307,
      303,
      299,
      294,
      290,
      286,
      282,
      278,
      274,
      270,
      266,
      262,
      257,
      254,
      249,
      246,
      241,
      238,
      234,
      230,
      226,
      222,
      218,
      215,
    ],
  },
  '16': {
    0: [
      712,
      718,
      725,
      732,
      739,
      746,
      752,
      759,
      765,
      771,
      777,
      783,
      789,
      795,
      800,
      805,
      810,
      815,
      820,
      824,
      829,
      833,
      837,
      841,
      844,
      848,
      851,
      854,
      857,
      860,
      862,
      865,
      867,
      869,
      871,
      872,
      873,
      875,
      876,
      876,
      877,
      877,
      877,
      877,
      877,
      877,
      876,
      875,
      874,
      873,
      871,
      869,
      868,
      865,
      863,
      861,
      858,
      855,
      852,
      849,
      845,
      842,
      838,
      833,
      829,
      825,
      820,
      815,
      810,
      805,
      800,
      794,
      788,
      782,
      776,
      770,
      763,
      757,
      750,
      743,
      735,
      728,
      720,
      712,
      705,
      697,
      688,
      680,
      671,
      663,
      654,
    ],
    5: [
      712,
      718,
      725,
      732,
      739,
      746,
      752,
      759,
      765,
      771,
      777,
      783,
      789,
      794,
      800,
      805,
      810,
      815,
      819,
      824,
      828,
      833,
      837,
      840,
      844,
      847,
      851,
      854,
      857,
      859,
      862,
      864,
      866,
      868,
      870,
      871,
      873,
      874,
      875,
      876,
      876,
      876,
      877,
      877,
      876,
      876,
      875,
      874,
      873,
      872,
      870,
      869,
      867,
      865,
      862,
      860,
      857,
      854,
      851,
      848,
      845,
      841,
      837,
      833,
      828,
      824,
      819,
      814,
      809,
      804,
      799,
      793,
      787,
      781,
      775,
      769,
      762,
      756,
      749,
      742,
      734,
      727,
      720,
      712,
      704,
      696,
      688,
      679,
      671,
      662,
      653,
    ],
    10: [
      712,
      718,
      725,
      732,
      739,
      745,
      752,
      758,
      764,
      771,
      776,
      782,
      788,
      793,
      799,
      804,
      809,
      814,
      818,
      823,
      827,
      831,
      835,
      839,
      842,
      846,
      849,
      852,
      855,
      858,
      860,
      862,
      864,
      866,
      868,
      869,
      871,
      872,
      873,
      874,
      874,
      874,
      874,
      874,
      874,
      874,
      873,
      872,
      871,
      870,
      868,
      866,
      864,
      862,
      860,
      857,
      855,
      852,
      849,
      846,
      842,
      838,
      834,
      830,
      826,
      822,
      817,
      812,
      807,
      802,
      796,
      791,
      785,
      779,
      773,
      766,
      760,
      753,
      746,
      739,
      732,
      725,
      717,
      710,
      702,
      694,
      686,
      677,
      669,
      660,
      651,
    ],
    15: [
      712,
      718,
      725,
      732,
      738,
      745,
      751,
      757,
      763,
      769,
      775,
      781,
      786,
      792,
      797,
      802,
      807,
      812,
      816,
      820,
      825,
      829,
      833,
      836,
      840,
      843,
      846,
      849,
      852,
      855,
      857,
      859,
      861,
      863,
      865,
      866,
      867,
      869,
      869,
      870,
      870,
      871,
      871,
      871,
      870,
      870,
      869,
      868,
      867,
      866,
      864,
      862,
      860,
      858,
      856,
      854,
      851,
      848,
      845,
      841,
      838,
      834,
      830,
      826,
      822,
      818,
      813,
      808,
      803,
      798,
      792,
      787,
      781,
      775,
      769,
      762,
      756,
      750,
      743,
      736,
      728,
      721,
      714,
      706,
      698,
      690,
      682,
      674,
      665,
      657,
      648,
    ],
    20: [
      712,
      718,
      725,
      731,
      737,
      744,
      750,
      756,
      762,
      768,
      773,
      779,
      784,
      790,
      795,
      799,
      804,
      809,
      813,
      818,
      822,
      826,
      829,
      833,
      836,
      839,
      843,
      845,
      848,
      851,
      853,
      855,
      857,
      859,
      860,
      862,
      863,
      864,
      865,
      865,
      866,
      866,
      866,
      866,
      865,
      865,
      864,
      863,
      862,
      860,
      859,
      857,
      855,
      853,
      850,
      848,
      845,
      842,
      839,
      836,
      832,
      828,
      825,
      820,
      816,
      812,
      807,
      802,
      797,
      792,
      787,
      781,
      775,
      769,
      763,
      757,
      751,
      744,
      737,
      730,
      723,
      716,
      708,
      701,
      693,
      685,
      677,
      669,
      661,
      652,
      644,
    ],
    25: [
      712,
      718,
      724,
      730,
      737,
      743,
      749,
      754,
      760,
      766,
      771,
      777,
      782,
      787,
      792,
      796,
      801,
      805,
      810,
      814,
      818,
      821,
      825,
      828,
      832,
      835,
      838,
      840,
      843,
      845,
      848,
      850,
      851,
      853,
      854,
      856,
      857,
      858,
      858,
      859,
      859,
      859,
      859,
      859,
      859,
      858,
      857,
      856,
      855,
      853,
      852,
      850,
      848,
      846,
      843,
      841,
      838,
      835,
      832,
      828,
      825,
      821,
      817,
      813,
      809,
      804,
      800,
      795,
      790,
      785,
      779,
      774,
      768,
      762,
      756,
      750,
      744,
      737,
      730,
      723,
      716,
      709,
      702,
      694,
      687,
      679,
      671,
      663,
      654,
      646,
      638,
    ],
    30: [
      712,
      717,
      724,
      730,
      735,
      741,
      747,
      753,
      758,
      763,
      769,
      774,
      779,
      783,
      788,
      792,
      797,
      801,
      805,
      809,
      813,
      816,
      820,
      823,
      826,
      829,
      832,
      834,
      837,
      839,
      841,
      843,
      845,
      846,
      847,
      849,
      850,
      850,
      851,
      851,
      851,
      852,
      851,
      851,
      850,
      850,
      849,
      848,
      846,
      845,
      843,
      841,
      839,
      837,
      835,
      832,
      829,
      826,
      823,
      819,
      816,
      812,
      808,
      804,
      800,
      795,
      791,
      786,
      781,
      776,
      771,
      765,
      759,
      753,
      748,
      741,
      735,
      728,
      722,
      715,
      708,
      701,
      694,
      686,
      679,
      671,
      663,
      655,
      647,
      639,
      630,
    ],
    35: [
      712,
      717,
      723,
      729,
      734,
      740,
      745,
      750,
      755,
      761,
      765,
      770,
      775,
      779,
      784,
      788,
      792,
      796,
      800,
      804,
      807,
      811,
      814,
      817,
      820,
      822,
      825,
      827,
      830,
      832,
      834,
      835,
      837,
      838,
      839,
      840,
      841,
      842,
      842,
      842,
      843,
      842,
      842,
      842,
      841,
      840,
      839,
      838,
      837,
      835,
      833,
      831,
      829,
      827,
      824,
      822,
      819,
      816,
      813,
      809,
      806,
      802,
      798,
      794,
      790,
      785,
      780,
      776,
      771,
      766,
      760,
      755,
      749,
      743,
      737,
      731,
      725,
      718,
      712,
      705,
      698,
      691,
      684,
      677,
      669,
      662,
      654,
      646,
      638,
      630,
      622,
    ],
    40: [
      712,
      717,
      722,
      727,
      733,
      738,
      743,
      748,
      753,
      757,
      762,
      766,
      771,
      775,
      779,
      783,
      787,
      791,
      794,
      798,
      801,
      804,
      807,
      810,
      812,
      815,
      817,
      819,
      821,
      823,
      825,
      826,
      828,
      829,
      830,
      831,
      831,
      832,
      832,
      832,
      832,
      832,
      832,
      831,
      830,
      829,
      828,
      827,
      825,
      824,
      822,
      820,
      818,
      815,
      813,
      810,
      807,
      804,
      801,
      797,
      794,
      790,
      786,
      782,
      778,
      773,
      768,
      763,
      759,
      754,
      748,
      743,
      737,
      731,
      726,
      719,
      713,
      707,
      700,
      693,
      687,
      680,
      673,
      666,
      658,
      650,
      643,
      635,
      627,
      619,
      611,
    ],
    45: [
      712,
      716,
      721,
      726,
      731,
      736,
      740,
      745,
      749,
      754,
      758,
      762,
      766,
      770,
      774,
      777,
      781,
      784,
      788,
      791,
      794,
      797,
      799,
      802,
      804,
      806,
      809,
      810,
      812,
      814,
      815,
      817,
      818,
      819,
      820,
      820,
      821,
      821,
      821,
      821,
      821,
      821,
      820,
      819,
      818,
      817,
      816,
      815,
      813,
      811,
      809,
      807,
      805,
      802,
      800,
      797,
      794,
      791,
      788,
      784,
      780,
      776,
      773,
      768,
      764,
      759,
      755,
      750,
      745,
      740,
      735,
      729,
      724,
      718,
      712,
      706,
      700,
      694,
      687,
      680,
      674,
      667,
      660,
      653,
      646,
      638,
      631,
      623,
      616,
      608,
      600,
    ],
    50: [
      712,
      716,
      720,
      725,
      729,
      733,
      738,
      742,
      746,
      750,
      754,
      757,
      761,
      765,
      768,
      771,
      774,
      777,
      780,
      783,
      786,
      788,
      791,
      793,
      795,
      797,
      799,
      801,
      802,
      804,
      805,
      806,
      807,
      808,
      808,
      809,
      809,
      809,
      809,
      809,
      808,
      808,
      807,
      806,
      805,
      804,
      803,
      801,
      799,
      797,
      795,
      793,
      791,
      788,
      785,
      783,
      779,
      776,
      773,
      769,
      766,
      762,
      758,
      754,
      749,
      745,
      740,
      735,
      730,
      725,
      720,
      714,
      709,
      703,
      698,
      691,
      685,
      679,
      673,
      666,
      660,
      653,
      646,
      639,
      632,
      625,
      617,
      610,
      602,
      594,
      587,
    ],
    55: [
      712,
      715,
      719,
      723,
      727,
      731,
      735,
      738,
      742,
      746,
      749,
      752,
      756,
      759,
      762,
      765,
      767,
      770,
      773,
      775,
      777,
      780,
      782,
      784,
      785,
      787,
      789,
      790,
      791,
      792,
      793,
      794,
      795,
      796,
      796,
      796,
      796,
      796,
      796,
      795,
      795,
      794,
      793,
      792,
      791,
      790,
      788,
      786,
      784,
      782,
      780,
      778,
      775,
      773,
      770,
      767,
      764,
      761,
      757,
      754,
      750,
      746,
      742,
      738,
      733,
      729,
      724,
      719,
      714,
      709,
      704,
      698,
      693,
      687,
      682,
      676,
      669,
      663,
      657,
      651,
      644,
      637,
      631,
      624,
      617,
      610,
      602,
      595,
      588,
      580,
      572,
    ],
    60: [
      712,
      715,
      718,
      722,
      725,
      728,
      732,
      735,
      738,
      741,
      744,
      747,
      750,
      752,
      755,
      757,
      760,
      762,
      764,
      766,
      768,
      770,
      772,
      774,
      775,
      776,
      778,
      779,
      780,
      780,
      781,
      782,
      782,
      783,
      783,
      783,
      782,
      782,
      782,
      781,
      780,
      779,
      778,
      777,
      776,
      774,
      772,
      771,
      769,
      766,
      764,
      762,
      759,
      756,
      753,
      750,
      747,
      744,
      740,
      737,
      733,
      729,
      725,
      720,
      716,
      712,
      707,
      702,
      697,
      692,
      687,
      681,
      676,
      670,
      665,
      659,
      653,
      647,
      640,
      634,
      628,
      621,
      614,
      608,
      601,
      594,
      587,
      579,
      573,
      565,
      557,
    ],
    65: [
      712,
      714,
      717,
      720,
      723,
      726,
      729,
      731,
      734,
      736,
      739,
      741,
      743,
      746,
      748,
      750,
      752,
      754,
      756,
      757,
      759,
      760,
      762,
      763,
      764,
      765,
      766,
      767,
      767,
      768,
      768,
      769,
      769,
      769,
      769,
      768,
      768,
      767,
      767,
      766,
      765,
      764,
      763,
      761,
      760,
      758,
      756,
      754,
      752,
      750,
      747,
      745,
      742,
      739,
      736,
      733,
      729,
      726,
      722,
      719,
      715,
      711,
      707,
      702,
      698,
      693,
      689,
      684,
      679,
      674,
      669,
      663,
      658,
      652,
      647,
      641,
      635,
      629,
      623,
      617,
      610,
      604,
      597,
      591,
      584,
      577,
      570,
      563,
      556,
      549,
      541,
    ],
    70: [
      712,
      714,
      716,
      718,
      721,
      723,
      725,
      727,
      729,
      731,
      733,
      735,
      737,
      739,
      740,
      742,
      743,
      745,
      746,
      747,
      749,
      750,
      751,
      752,
      752,
      753,
      754,
      754,
      754,
      755,
      755,
      755,
      755,
      754,
      754,
      753,
      753,
      752,
      751,
      750,
      749,
      748,
      746,
      744,
      743,
      741,
      739,
      737,
      734,
      732,
      729,
      727,
      724,
      721,
      718,
      714,
      711,
      707,
      704,
      700,
      696,
      692,
      688,
      683,
      679,
      674,
      670,
      665,
      660,
      655,
      650,
      645,
      639,
      633,
      628,
      622,
      616,
      610,
      604,
      598,
      592,
      586,
      579,
      573,
      566,
      559,
      552,
      546,
      539,
      532,
      525,
    ],
    75: [
      712,
      713,
      715,
      717,
      718,
      720,
      722,
      723,
      725,
      726,
      727,
      729,
      730,
      731,
      732,
      734,
      735,
      736,
      736,
      738,
      738,
      739,
      740,
      740,
      740,
      741,
      741,
      741,
      741,
      741,
      741,
      740,
      740,
      739,
      739,
      738,
      737,
      736,
      735,
      733,
      732,
      730,
      729,
      727,
      725,
      723,
      721,
      718,
      716,
      713,
      711,
      708,
      705,
      702,
      699,
      695,
      692,
      688,
      684,
      680,
      677,
      672,
      668,
      664,
      659,
      655,
      650,
      645,
      640,
      635,
      630,
      625,
      619,
      614,
      608,
      603,
      597,
      591,
      585,
      579,
      573,
      567,
      560,
      554,
      547,
      541,
      534,
      527,
      521,
      514,
      507,
    ],
    80: [
      712,
      712,
      714,
      715,
      716,
      717,
      718,
      719,
      720,
      721,
      722,
      722,
      723,
      724,
      724,
      725,
      726,
      726,
      727,
      727,
      727,
      728,
      728,
      728,
      728,
      728,
      728,
      727,
      727,
      726,
      726,
      725,
      725,
      724,
      723,
      722,
      721,
      719,
      718,
      716,
      715,
      713,
      711,
      709,
      707,
      705,
      702,
      700,
      697,
      694,
      692,
      689,
      685,
      682,
      679,
      675,
      672,
      668,
      664,
      660,
      656,
      652,
      648,
      643,
      639,
      634,
      630,
      625,
      620,
      615,
      610,
      604,
      599,
      594,
      588,
      583,
      577,
      571,
      565,
      559,
      553,
      547,
      541,
      534,
      528,
      522,
      516,
      509,
      502,
      496,
      489,
    ],
    85: [
      712,
      712,
      712,
      713,
      713,
      714,
      714,
      715,
      715,
      715,
      716,
      716,
      716,
      716,
      716,
      716,
      716,
      717,
      716,
      716,
      716,
      716,
      716,
      715,
      715,
      714,
      714,
      713,
      713,
      712,
      711,
      710,
      709,
      708,
      707,
      705,
      704,
      702,
      701,
      699,
      697,
      695,
      693,
      690,
      688,
      686,
      683,
      681,
      678,
      675,
      672,
      669,
      665,
      662,
      659,
      655,
      651,
      648,
      644,
      640,
      636,
      631,
      627,
      623,
      618,
      614,
      609,
      604,
      599,
      594,
      589,
      584,
      578,
      573,
      568,
      562,
      557,
      551,
      545,
      539,
      533,
      527,
      521,
      515,
      509,
      503,
      497,
      491,
      483,
      477,
      470,
    ],
    90: [
      712,
      711,
      711,
      711,
      711,
      711,
      711,
      710,
      710,
      710,
      710,
      709,
      709,
      708,
      708,
      707,
      707,
      706,
      706,
      706,
      705,
      704,
      703,
      703,
      702,
      701,
      700,
      699,
      698,
      697,
      696,
      694,
      693,
      692,
      690,
      688,
      687,
      685,
      683,
      681,
      679,
      676,
      674,
      672,
      669,
      667,
      664,
      661,
      658,
      655,
      652,
      648,
      645,
      642,
      638,
      634,
      631,
      627,
      623,
      619,
      615,
      610,
      606,
      602,
      597,
      592,
      588,
      583,
      578,
      573,
      568,
      563,
      557,
      552,
      547,
      541,
      536,
      530,
      524,
      519,
      513,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      464,
      458,
      452,
    ],
    95: [
      712,
      711,
      710,
      709,
      708,
      708,
      707,
      706,
      705,
      704,
      703,
      702,
      701,
      700,
      699,
      698,
      698,
      696,
      695,
      694,
      693,
      692,
      691,
      690,
      689,
      687,
      686,
      685,
      683,
      682,
      680,
      678,
      677,
      675,
      673,
      671,
      669,
      667,
      665,
      663,
      660,
      658,
      655,
      653,
      650,
      647,
      644,
      641,
      638,
      635,
      631,
      628,
      625,
      621,
      617,
      614,
      610,
      606,
      602,
      598,
      593,
      589,
      585,
      580,
      576,
      571,
      566,
      561,
      556,
      551,
      547,
      541,
      536,
      531,
      525,
      520,
      515,
      509,
      504,
      498,
      492,
      486,
      481,
      475,
      469,
      463,
      457,
      451,
      445,
      438,
      433,
    ],
    100: [
      712,
      710,
      709,
      707,
      706,
      704,
      703,
      702,
      700,
      699,
      697,
      696,
      694,
      693,
      691,
      689,
      688,
      686,
      685,
      683,
      682,
      680,
      678,
      677,
      675,
      673,
      672,
      670,
      668,
      666,
      664,
      662,
      660,
      658,
      656,
      654,
      651,
      649,
      647,
      644,
      642,
      639,
      636,
      633,
      630,
      627,
      624,
      621,
      618,
      614,
      611,
      607,
      604,
      600,
      596,
      592,
      589,
      585,
      580,
      576,
      572,
      568,
      563,
      559,
      554,
      549,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      504,
      499,
      494,
      488,
      483,
      477,
      472,
      466,
      460,
      455,
      449,
      443,
      437,
      431,
      426,
      420,
      413,
    ],
    105: [
      712,
      709,
      707,
      705,
      704,
      701,
      700,
      697,
      695,
      693,
      691,
      689,
      687,
      685,
      683,
      680,
      678,
      676,
      674,
      672,
      670,
      668,
      666,
      664,
      662,
      659,
      657,
      655,
      653,
      651,
      648,
      646,
      644,
      642,
      639,
      637,
      634,
      631,
      629,
      626,
      623,
      620,
      617,
      614,
      611,
      608,
      604,
      601,
      598,
      594,
      591,
      587,
      583,
      579,
      575,
      571,
      567,
      563,
      559,
      555,
      551,
      546,
      542,
      537,
      532,
      528,
      523,
      519,
      514,
      509,
      504,
      499,
      494,
      488,
      483,
      478,
      473,
      467,
      462,
      456,
      451,
      446,
      440,
      435,
      429,
      423,
      417,
      412,
      406,
      400,
      395,
    ],
    110: [
      712,
      709,
      706,
      704,
      701,
      698,
      696,
      693,
      691,
      688,
      685,
      682,
      680,
      677,
      674,
      672,
      669,
      666,
      664,
      661,
      659,
      656,
      654,
      651,
      648,
      646,
      643,
      641,
      638,
      635,
      633,
      630,
      627,
      625,
      622,
      619,
      616,
      613,
      610,
      607,
      604,
      601,
      598,
      595,
      591,
      588,
      585,
      581,
      578,
      574,
      570,
      566,
      563,
      559,
      555,
      551,
      546,
      542,
      538,
      534,
      529,
      525,
      521,
      516,
      511,
      507,
      502,
      497,
      492,
      487,
      483,
      478,
      473,
      467,
      462,
      457,
      452,
      447,
      441,
      436,
      431,
      426,
      420,
      414,
      409,
      404,
      398,
      393,
      387,
      382,
      376,
    ],
    115: [
      712,
      708,
      705,
      702,
      699,
      696,
      692,
      689,
      686,
      683,
      679,
      676,
      673,
      670,
      666,
      663,
      660,
      657,
      654,
      650,
      647,
      644,
      641,
      638,
      635,
      632,
      629,
      626,
      623,
      620,
      617,
      614,
      611,
      608,
      605,
      602,
      599,
      596,
      592,
      589,
      586,
      583,
      579,
      576,
      572,
      569,
      565,
      561,
      558,
      554,
      550,
      546,
      542,
      538,
      534,
      530,
      526,
      522,
      517,
      513,
      509,
      504,
      500,
      495,
      490,
      486,
      481,
      476,
      471,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      421,
      416,
      411,
      406,
      400,
      395,
      389,
      384,
      379,
      374,
      368,
      363,
      358,
    ],
    120: [
      712,
      708,
      704,
      700,
      697,
      693,
      689,
      685,
      681,
      678,
      674,
      670,
      666,
      662,
      659,
      655,
      651,
      647,
      644,
      640,
      636,
      633,
      629,
      626,
      623,
      619,
      616,
      612,
      609,
      606,
      602,
      599,
      595,
      592,
      588,
      585,
      582,
      578,
      575,
      571,
      568,
      564,
      561,
      557,
      553,
      550,
      546,
      542,
      538,
      534,
      530,
      526,
      522,
      518,
      514,
      510,
      506,
      501,
      497,
      493,
      488,
      484,
      479,
      475,
      470,
      465,
      461,
      456,
      451,
      446,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      396,
      391,
      387,
      381,
      376,
      371,
      366,
      361,
      355,
      350,
      345,
      340,
    ],
    125: [
      712,
      707,
      703,
      699,
      694,
      690,
      686,
      681,
      677,
      673,
      668,
      664,
      660,
      655,
      651,
      647,
      643,
      638,
      634,
      630,
      626,
      622,
      618,
      614,
      610,
      606,
      602,
      598,
      595,
      591,
      587,
      584,
      580,
      576,
      572,
      569,
      565,
      561,
      558,
      554,
      550,
      546,
      543,
      539,
      535,
      531,
      527,
      523,
      519,
      515,
      511,
      507,
      503,
      499,
      495,
      490,
      486,
      482,
      477,
      473,
      468,
      464,
      459,
      455,
      450,
      445,
      441,
      436,
      431,
      427,
      422,
      417,
      412,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      343,
      338,
      333,
      328,
      323,
    ],
    130: [
      712,
      707,
      702,
      697,
      692,
      688,
      683,
      678,
      673,
      668,
      663,
      659,
      654,
      649,
      644,
      639,
      635,
      630,
      625,
      621,
      616,
      612,
      607,
      603,
      598,
      594,
      590,
      585,
      581,
      577,
      573,
      569,
      565,
      561,
      557,
      553,
      549,
      545,
      541,
      537,
      533,
      529,
      525,
      521,
      517,
      513,
      509,
      505,
      501,
      497,
      493,
      488,
      484,
      480,
      476,
      471,
      467,
      463,
      458,
      454,
      449,
      445,
      440,
      436,
      431,
      426,
      422,
      417,
      412,
      408,
      403,
      398,
      394,
      389,
      384,
      379,
      374,
      370,
      365,
      360,
      355,
      350,
      345,
      340,
      336,
      331,
      326,
      321,
      316,
      311,
      307,
    ],
    135: [
      712,
      706,
      701,
      696,
      691,
      685,
      680,
      675,
      669,
      664,
      659,
      653,
      648,
      643,
      638,
      633,
      628,
      622,
      617,
      612,
      607,
      602,
      597,
      593,
      588,
      583,
      578,
      573,
      569,
      564,
      560,
      555,
      551,
      546,
      542,
      538,
      533,
      529,
      525,
      521,
      517,
      513,
      508,
      504,
      500,
      496,
      492,
      487,
      483,
      479,
      475,
      470,
      466,
      462,
      457,
      453,
      449,
      444,
      440,
      435,
      431,
      426,
      422,
      417,
      413,
      408,
      404,
      399,
      394,
      390,
      385,
      380,
      376,
      371,
      366,
      362,
      357,
      352,
      347,
      343,
      338,
      333,
      328,
      324,
      319,
      314,
      310,
      305,
      300,
      296,
      291,
    ],
    140: [
      712,
      706,
      700,
      694,
      689,
      683,
      677,
      672,
      666,
      660,
      654,
      649,
      643,
      638,
      632,
      626,
      621,
      616,
      610,
      605,
      599,
      594,
      589,
      583,
      578,
      573,
      568,
      562,
      557,
      552,
      547,
      542,
      537,
      533,
      528,
      523,
      519,
      514,
      510,
      505,
      501,
      497,
      492,
      488,
      484,
      479,
      475,
      471,
      466,
      462,
      458,
      453,
      449,
      445,
      440,
      436,
      431,
      427,
      422,
      418,
      413,
      409,
      404,
      400,
      396,
      391,
      386,
      382,
      377,
      373,
      368,
      363,
      359,
      354,
      350,
      345,
      340,
      336,
      331,
      327,
      322,
      317,
      313,
      308,
      304,
      299,
      294,
      290,
      285,
      281,
      276,
    ],
    145: [
      712,
      705,
      699,
      693,
      687,
      681,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      633,
      627,
      621,
      615,
      610,
      604,
      598,
      592,
      587,
      581,
      575,
      569,
      564,
      558,
      553,
      547,
      542,
      536,
      531,
      526,
      520,
      515,
      510,
      505,
      500,
      495,
      491,
      486,
      481,
      477,
      472,
      468,
      463,
      459,
      455,
      450,
      446,
      441,
      437,
      433,
      428,
      424,
      419,
      415,
      410,
      406,
      401,
      397,
      393,
      388,
      384,
      379,
      375,
      370,
      366,
      361,
      357,
      352,
      348,
      343,
      339,
      334,
      330,
      325,
      320,
      316,
      312,
      307,
      302,
      298,
      294,
      289,
      285,
      281,
      276,
      272,
      267,
      263,
    ],
    150: [
      712,
      705,
      699,
      692,
      686,
      679,
      673,
      667,
      660,
      654,
      647,
      641,
      635,
      629,
      622,
      616,
      610,
      604,
      598,
      592,
      586,
      580,
      574,
      568,
      562,
      556,
      550,
      544,
      539,
      533,
      527,
      521,
      516,
      510,
      505,
      499,
      494,
      488,
      483,
      478,
      473,
      468,
      463,
      458,
      454,
      449,
      444,
      440,
      435,
      431,
      426,
      422,
      417,
      413,
      409,
      404,
      400,
      395,
      391,
      386,
      382,
      378,
      373,
      369,
      364,
      360,
      355,
      351,
      347,
      342,
      338,
      333,
      329,
      324,
      320,
      315,
      311,
      307,
      302,
      298,
      293,
      289,
      285,
      280,
      276,
      272,
      268,
      263,
      259,
      255,
      251,
    ],
    155: [
      712,
      705,
      698,
      691,
      685,
      678,
      671,
      665,
      658,
      651,
      645,
      638,
      632,
      625,
      619,
      613,
      606,
      600,
      594,
      587,
      581,
      574,
      568,
      562,
      556,
      549,
      543,
      537,
      531,
      525,
      519,
      513,
      507,
      501,
      496,
      490,
      484,
      479,
      473,
      467,
      462,
      457,
      451,
      446,
      441,
      436,
      431,
      427,
      422,
      418,
      413,
      408,
      404,
      399,
      395,
      390,
      386,
      382,
      377,
      373,
      368,
      364,
      359,
      355,
      351,
      347,
      342,
      338,
      333,
      329,
      325,
      320,
      316,
      311,
      307,
      303,
      299,
      294,
      290,
      286,
      282,
      277,
      273,
      269,
      265,
      261,
      257,
      252,
      248,
      244,
      240,
    ],
    160: [
      712,
      704,
      698,
      691,
      684,
      677,
      670,
      663,
      656,
      649,
      642,
      636,
      629,
      622,
      616,
      609,
      603,
      596,
      590,
      583,
      576,
      570,
      563,
      557,
      551,
      544,
      538,
      531,
      525,
      519,
      513,
      507,
      500,
      494,
      488,
      482,
      476,
      470,
      465,
      459,
      453,
      448,
      442,
      437,
      431,
      426,
      421,
      416,
      411,
      406,
      402,
      397,
      392,
      388,
      383,
      379,
      374,
      370,
      365,
      361,
      357,
      352,
      348,
      344,
      339,
      335,
      331,
      326,
      322,
      318,
      313,
      309,
      305,
      301,
      296,
      292,
      288,
      284,
      280,
      275,
      271,
      267,
      263,
      259,
      255,
      251,
      247,
      243,
      239,
      235,
      231,
    ],
    165: [
      712,
      704,
      697,
      690,
      683,
      676,
      669,
      662,
      654,
      647,
      641,
      634,
      627,
      620,
      613,
      607,
      600,
      593,
      586,
      580,
      573,
      566,
      560,
      553,
      546,
      540,
      533,
      527,
      521,
      514,
      508,
      501,
      495,
      489,
      482,
      476,
      470,
      464,
      458,
      452,
      446,
      440,
      435,
      429,
      424,
      418,
      413,
      408,
      402,
      397,
      393,
      388,
      383,
      379,
      374,
      369,
      365,
      360,
      356,
      352,
      347,
      343,
      338,
      334,
      330,
      326,
      321,
      317,
      313,
      309,
      305,
      300,
      296,
      292,
      288,
      283,
      279,
      275,
      271,
      267,
      263,
      259,
      255,
      251,
      247,
      243,
      239,
      235,
      231,
      227,
      224,
    ],
    170: [
      712,
      704,
      697,
      690,
      682,
      675,
      668,
      661,
      653,
      646,
      639,
      632,
      625,
      619,
      612,
      605,
      598,
      591,
      584,
      577,
      570,
      564,
      557,
      550,
      543,
      537,
      531,
      524,
      517,
      510,
      504,
      497,
      491,
      485,
      479,
      472,
      466,
      460,
      454,
      447,
      441,
      435,
      430,
      424,
      418,
      412,
      407,
      402,
      396,
      391,
      386,
      381,
      377,
      372,
      367,
      363,
      358,
      353,
      349,
      344,
      340,
      336,
      331,
      327,
      323,
      319,
      315,
      310,
      306,
      302,
      298,
      294,
      289,
      285,
      281,
      277,
      273,
      269,
      265,
      261,
      257,
      253,
      249,
      245,
      241,
      237,
      234,
      230,
      226,
      222,
      218,
    ],
    175: [
      712,
      704,
      697,
      689,
      682,
      675,
      667,
      660,
      653,
      646,
      638,
      631,
      625,
      618,
      611,
      604,
      597,
      590,
      583,
      576,
      569,
      562,
      555,
      549,
      542,
      536,
      529,
      522,
      515,
      508,
      501,
      495,
      489,
      482,
      476,
      470,
      464,
      458,
      451,
      445,
      438,
      432,
      426,
      421,
      415,
      409,
      403,
      398,
      393,
      388,
      382,
      377,
      373,
      368,
      363,
      359,
      354,
      349,
      345,
      340,
      336,
      332,
      327,
      323,
      319,
      315,
      310,
      306,
      302,
      298,
      294,
      290,
      286,
      281,
      277,
      273,
      269,
      265,
      261,
      257,
      253,
      249,
      245,
      242,
      238,
      234,
      230,
      226,
      222,
      219,
      215,
    ],
  },
  '17': {
    0: [
      691,
      697,
      704,
      710,
      716,
      722,
      728,
      734,
      740,
      745,
      750,
      756,
      761,
      766,
      771,
      775,
      780,
      784,
      789,
      792,
      796,
      800,
      804,
      807,
      810,
      813,
      816,
      819,
      821,
      823,
      826,
      827,
      829,
      831,
      832,
      833,
      834,
      835,
      836,
      836,
      837,
      837,
      837,
      836,
      836,
      835,
      834,
      833,
      832,
      831,
      829,
      827,
      825,
      823,
      821,
      818,
      815,
      813,
      809,
      806,
      803,
      799,
      795,
      791,
      787,
      783,
      778,
      773,
      768,
      763,
      758,
      753,
      747,
      741,
      735,
      729,
      723,
      717,
      710,
      703,
      696,
      689,
      682,
      675,
      667,
      659,
      651,
      644,
      635,
      627,
      619,
    ],
    5: [
      691,
      697,
      703,
      710,
      716,
      722,
      728,
      734,
      739,
      745,
      750,
      756,
      761,
      766,
      770,
      775,
      780,
      784,
      788,
      792,
      796,
      800,
      803,
      806,
      810,
      813,
      815,
      818,
      821,
      823,
      825,
      827,
      829,
      830,
      832,
      833,
      834,
      835,
      835,
      836,
      836,
      836,
      836,
      836,
      835,
      835,
      834,
      833,
      831,
      830,
      828,
      827,
      825,
      822,
      820,
      817,
      815,
      812,
      809,
      805,
      802,
      798,
      794,
      790,
      786,
      782,
      777,
      772,
      768,
      763,
      757,
      752,
      746,
      740,
      735,
      728,
      722,
      716,
      709,
      702,
      695,
      688,
      681,
      674,
      666,
      659,
      651,
      643,
      635,
      627,
      618,
    ],
    10: [
      691,
      697,
      703,
      710,
      716,
      722,
      727,
      733,
      739,
      744,
      750,
      755,
      760,
      765,
      769,
      774,
      779,
      783,
      787,
      791,
      795,
      798,
      802,
      805,
      808,
      811,
      814,
      817,
      819,
      821,
      823,
      825,
      827,
      828,
      830,
      831,
      832,
      833,
      833,
      834,
      834,
      834,
      834,
      834,
      833,
      832,
      832,
      831,
      829,
      828,
      826,
      824,
      822,
      820,
      818,
      815,
      812,
      810,
      806,
      803,
      800,
      796,
      792,
      788,
      784,
      780,
      775,
      770,
      765,
      760,
      755,
      750,
      744,
      738,
      732,
      726,
      720,
      713,
      707,
      700,
      693,
      686,
      679,
      672,
      664,
      657,
      649,
      641,
      633,
      625,
      617,
    ],
    15: [
      691,
      697,
      703,
      709,
      715,
      721,
      727,
      732,
      738,
      743,
      748,
      753,
      758,
      763,
      768,
      772,
      777,
      781,
      785,
      789,
      793,
      796,
      800,
      803,
      806,
      809,
      811,
      814,
      816,
      819,
      821,
      822,
      824,
      826,
      827,
      828,
      829,
      830,
      830,
      831,
      831,
      831,
      831,
      830,
      830,
      829,
      828,
      827,
      826,
      824,
      823,
      821,
      819,
      817,
      814,
      812,
      809,
      806,
      803,
      799,
      796,
      792,
      788,
      784,
      780,
      776,
      771,
      766,
      762,
      757,
      751,
      746,
      740,
      735,
      729,
      722,
      716,
      710,
      704,
      697,
      690,
      683,
      676,
      668,
      661,
      653,
      646,
      638,
      630,
      622,
      614,
    ],
    20: [
      691,
      697,
      703,
      709,
      714,
      720,
      726,
      731,
      737,
      742,
      747,
      752,
      757,
      761,
      766,
      770,
      774,
      778,
      782,
      786,
      790,
      793,
      797,
      800,
      803,
      805,
      808,
      810,
      813,
      815,
      817,
      818,
      820,
      821,
      823,
      824,
      825,
      825,
      826,
      826,
      826,
      826,
      826,
      826,
      825,
      824,
      823,
      822,
      821,
      819,
      818,
      816,
      814,
      811,
      809,
      806,
      804,
      801,
      798,
      794,
      791,
      787,
      783,
      779,
      775,
      770,
      766,
      761,
      756,
      751,
      746,
      741,
      735,
      729,
      724,
      718,
      711,
      705,
      698,
      692,
      685,
      678,
      671,
      664,
      656,
      649,
      641,
      633,
      625,
      617,
      609,
    ],
    25: [
      691,
      697,
      702,
      708,
      714,
      719,
      724,
      730,
      735,
      740,
      745,
      750,
      754,
      759,
      763,
      767,
      771,
      775,
      779,
      783,
      786,
      789,
      792,
      795,
      798,
      801,
      804,
      806,
      808,
      810,
      812,
      813,
      815,
      816,
      817,
      818,
      819,
      820,
      820,
      820,
      820,
      820,
      820,
      820,
      819,
      818,
      817,
      816,
      814,
      813,
      811,
      809,
      807,
      805,
      802,
      800,
      797,
      794,
      791,
      787,
      784,
      780,
      776,
      772,
      768,
      764,
      759,
      754,
      749,
      745,
      739,
      734,
      728,
      723,
      717,
      711,
      705,
      698,
      692,
      685,
      679,
      672,
      665,
      657,
      650,
      643,
      635,
      628,
      620,
      612,
      604,
    ],
    30: [
      691,
      696,
      702,
      707,
      713,
      718,
      723,
      728,
      733,
      738,
      742,
      747,
      751,
      756,
      760,
      764,
      768,
      771,
      775,
      778,
      782,
      785,
      788,
      791,
      793,
      796,
      798,
      800,
      802,
      804,
      806,
      807,
      809,
      810,
      811,
      812,
      812,
      813,
      813,
      813,
      813,
      813,
      813,
      812,
      811,
      811,
      809,
      808,
      807,
      805,
      803,
      801,
      799,
      797,
      794,
      792,
      789,
      786,
      783,
      779,
      776,
      772,
      768,
      764,
      760,
      755,
      751,
      746,
      741,
      736,
      731,
      726,
      720,
      715,
      709,
      703,
      697,
      690,
      684,
      678,
      671,
      664,
      657,
      650,
      643,
      635,
      628,
      620,
      612,
      605,
      596,
    ],
    35: [
      691,
      696,
      701,
      706,
      711,
      716,
      721,
      726,
      731,
      735,
      739,
      744,
      748,
      752,
      756,
      760,
      763,
      767,
      770,
      773,
      776,
      779,
      782,
      785,
      787,
      790,
      792,
      794,
      796,
      797,
      799,
      800,
      802,
      803,
      803,
      804,
      805,
      805,
      805,
      805,
      805,
      805,
      804,
      804,
      803,
      802,
      801,
      799,
      798,
      796,
      794,
      792,
      790,
      787,
      785,
      782,
      779,
      776,
      773,
      770,
      766,
      762,
      758,
      754,
      750,
      746,
      741,
      736,
      732,
      727,
      721,
      716,
      711,
      705,
      699,
      693,
      687,
      681,
      675,
      668,
      662,
      655,
      648,
      641,
      634,
      627,
      619,
      611,
      604,
      596,
      588,
    ],
    40: [
      691,
      696,
      700,
      705,
      710,
      715,
      719,
      724,
      728,
      732,
      736,
      740,
      744,
      748,
      751,
      755,
      758,
      762,
      765,
      768,
      771,
      773,
      776,
      778,
      781,
      783,
      785,
      786,
      788,
      790,
      791,
      792,
      793,
      794,
      795,
      795,
      796,
      796,
      796,
      796,
      796,
      795,
      795,
      794,
      793,
      792,
      790,
      789,
      787,
      786,
      784,
      781,
      779,
      777,
      774,
      771,
      768,
      765,
      762,
      759,
      755,
      751,
      747,
      743,
      739,
      734,
      730,
      725,
      720,
      715,
      710,
      705,
      699,
      694,
      688,
      682,
      676,
      670,
      664,
      658,
      651,
      644,
      637,
      631,
      623,
      616,
      609,
      602,
      594,
      587,
      579,
    ],
    45: [
      691,
      695,
      700,
      704,
      708,
      713,
      717,
      721,
      725,
      729,
      733,
      736,
      740,
      743,
      747,
      750,
      753,
      756,
      759,
      761,
      764,
      766,
      769,
      771,
      773,
      775,
      777,
      778,
      780,
      781,
      782,
      783,
      784,
      785,
      785,
      785,
      786,
      786,
      786,
      785,
      785,
      784,
      784,
      783,
      782,
      780,
      779,
      777,
      776,
      774,
      772,
      770,
      767,
      765,
      762,
      759,
      756,
      753,
      750,
      746,
      742,
      739,
      735,
      731,
      726,
      722,
      717,
      713,
      708,
      703,
      698,
      692,
      687,
      681,
      676,
      670,
      664,
      658,
      652,
      645,
      639,
      632,
      625,
      619,
      612,
      604,
      597,
      590,
      583,
      575,
      568,
    ],
    50: [
      691,
      695,
      699,
      703,
      707,
      711,
      714,
      718,
      722,
      725,
      729,
      732,
      735,
      738,
      741,
      744,
      747,
      749,
      752,
      754,
      757,
      759,
      761,
      763,
      765,
      766,
      768,
      769,
      770,
      771,
      772,
      773,
      774,
      774,
      775,
      775,
      775,
      775,
      774,
      774,
      773,
      773,
      772,
      771,
      769,
      768,
      767,
      765,
      763,
      761,
      759,
      757,
      754,
      751,
      749,
      746,
      743,
      739,
      736,
      732,
      729,
      725,
      721,
      717,
      713,
      708,
      703,
      699,
      694,
      689,
      684,
      678,
      673,
      668,
      662,
      656,
      650,
      644,
      638,
      632,
      625,
      619,
      612,
      606,
      599,
      592,
      585,
      578,
      570,
      563,
      555,
    ],
    55: [
      691,
      694,
      698,
      702,
      705,
      708,
      712,
      715,
      718,
      721,
      724,
      727,
      730,
      733,
      735,
      738,
      740,
      743,
      745,
      747,
      749,
      751,
      753,
      754,
      756,
      757,
      758,
      759,
      760,
      761,
      762,
      762,
      763,
      763,
      763,
      763,
      763,
      763,
      762,
      762,
      761,
      760,
      759,
      758,
      756,
      755,
      753,
      751,
      749,
      747,
      745,
      742,
      740,
      737,
      734,
      731,
      728,
      725,
      721,
      718,
      714,
      710,
      706,
      702,
      698,
      693,
      688,
      684,
      679,
      674,
      669,
      663,
      658,
      653,
      647,
      641,
      635,
      629,
      623,
      617,
      611,
      604,
      598,
      591,
      585,
      578,
      571,
      564,
      557,
      549,
      542,
    ],
    60: [
      691,
      694,
      697,
      700,
      703,
      706,
      709,
      712,
      715,
      717,
      720,
      722,
      725,
      727,
      729,
      731,
      733,
      735,
      737,
      739,
      741,
      742,
      744,
      745,
      746,
      747,
      748,
      749,
      750,
      750,
      751,
      751,
      751,
      751,
      751,
      751,
      750,
      750,
      749,
      748,
      747,
      746,
      745,
      744,
      742,
      740,
      739,
      737,
      734,
      732,
      730,
      727,
      725,
      722,
      719,
      716,
      712,
      709,
      705,
      702,
      698,
      694,
      690,
      686,
      682,
      677,
      672,
      668,
      663,
      658,
      653,
      647,
      642,
      637,
      631,
      626,
      620,
      614,
      608,
      602,
      595,
      589,
      583,
      576,
      570,
      563,
      556,
      549,
      542,
      535,
      528,
    ],
    65: [
      691,
      693,
      696,
      699,
      701,
      704,
      706,
      708,
      711,
      713,
      715,
      717,
      719,
      721,
      723,
      724,
      726,
      728,
      729,
      730,
      732,
      733,
      734,
      735,
      736,
      737,
      737,
      738,
      738,
      738,
      739,
      739,
      739,
      738,
      738,
      738,
      737,
      736,
      735,
      734,
      733,
      732,
      730,
      729,
      727,
      725,
      723,
      721,
      719,
      717,
      714,
      711,
      709,
      706,
      703,
      699,
      696,
      693,
      689,
      685,
      681,
      677,
      673,
      669,
      665,
      660,
      656,
      651,
      646,
      641,
      636,
      631,
      625,
      620,
      615,
      609,
      603,
      597,
      591,
      585,
      579,
      573,
      566,
      560,
      554,
      547,
      540,
      533,
      527,
      520,
      513,
    ],
    70: [
      691,
      693,
      695,
      697,
      699,
      701,
      703,
      705,
      706,
      708,
      710,
      711,
      713,
      714,
      716,
      717,
      718,
      719,
      721,
      722,
      722,
      723,
      724,
      725,
      725,
      726,
      726,
      726,
      726,
      726,
      726,
      726,
      725,
      725,
      724,
      724,
      723,
      722,
      721,
      720,
      718,
      717,
      715,
      713,
      711,
      709,
      707,
      705,
      703,
      700,
      698,
      695,
      692,
      689,
      686,
      682,
      679,
      675,
      672,
      668,
      664,
      660,
      656,
      651,
      647,
      642,
      638,
      633,
      628,
      623,
      618,
      613,
      608,
      602,
      597,
      591,
      586,
      579,
      574,
      568,
      562,
      556,
      549,
      543,
      537,
      531,
      524,
      517,
      511,
      504,
      497,
    ],
    75: [
      691,
      692,
      694,
      695,
      697,
      698,
      700,
      701,
      702,
      703,
      705,
      706,
      707,
      708,
      709,
      710,
      710,
      711,
      712,
      712,
      713,
      713,
      714,
      714,
      714,
      714,
      714,
      714,
      714,
      714,
      713,
      713,
      712,
      711,
      710,
      709,
      708,
      707,
      706,
      704,
      703,
      701,
      699,
      697,
      695,
      693,
      691,
      688,
      686,
      683,
      680,
      677,
      674,
      671,
      668,
      665,
      661,
      657,
      654,
      650,
      646,
      642,
      637,
      633,
      629,
      624,
      620,
      615,
      610,
      605,
      600,
      595,
      590,
      584,
      579,
      573,
      568,
      562,
      556,
      550,
      545,
      539,
      532,
      526,
      520,
      513,
      507,
      500,
      494,
      487,
      481,
    ],
    80: [
      691,
      692,
      693,
      694,
      695,
      695,
      696,
      697,
      698,
      699,
      699,
      700,
      700,
      701,
      701,
      702,
      702,
      702,
      703,
      703,
      703,
      703,
      703,
      703,
      703,
      702,
      702,
      702,
      701,
      700,
      700,
      699,
      698,
      697,
      696,
      695,
      693,
      692,
      690,
      689,
      687,
      685,
      683,
      681,
      679,
      676,
      674,
      671,
      668,
      666,
      663,
      660,
      656,
      653,
      650,
      646,
      643,
      639,
      635,
      631,
      627,
      623,
      619,
      614,
      610,
      606,
      601,
      596,
      591,
      586,
      581,
      576,
      571,
      566,
      560,
      555,
      549,
      544,
      538,
      532,
      526,
      520,
      514,
      508,
      502,
      496,
      490,
      483,
      477,
      470,
      464,
    ],
    85: [
      691,
      691,
      692,
      692,
      692,
      693,
      693,
      693,
      693,
      694,
      694,
      694,
      694,
      694,
      694,
      694,
      694,
      694,
      693,
      693,
      693,
      692,
      692,
      692,
      691,
      690,
      689,
      689,
      688,
      687,
      686,
      685,
      684,
      682,
      681,
      679,
      678,
      676,
      674,
      672,
      670,
      668,
      666,
      664,
      661,
      659,
      656,
      653,
      651,
      648,
      645,
      641,
      638,
      635,
      631,
      628,
      624,
      620,
      616,
      612,
      608,
      604,
      600,
      595,
      591,
      586,
      582,
      577,
      572,
      567,
      562,
      557,
      552,
      546,
      541,
      536,
      530,
      525,
      519,
      513,
      508,
      502,
      496,
      489,
      484,
      478,
      472,
      466,
      459,
      453,
      447,
    ],
    90: [
      691,
      691,
      690,
      690,
      690,
      690,
      690,
      689,
      689,
      689,
      688,
      688,
      687,
      687,
      686,
      685,
      685,
      684,
      684,
      683,
      682,
      682,
      681,
      680,
      679,
      678,
      677,
      676,
      674,
      673,
      672,
      670,
      669,
      667,
      666,
      664,
      662,
      660,
      658,
      656,
      654,
      651,
      649,
      647,
      644,
      641,
      638,
      635,
      632,
      629,
      626,
      623,
      620,
      616,
      612,
      609,
      605,
      601,
      597,
      593,
      589,
      585,
      580,
      576,
      571,
      567,
      562,
      557,
      552,
      547,
      543,
      538,
      532,
      527,
      522,
      517,
      511,
      506,
      500,
      495,
      489,
      483,
      477,
      472,
      465,
      460,
      454,
      448,
      441,
      435,
      429,
    ],
    95: [
      691,
      690,
      689,
      689,
      688,
      687,
      686,
      685,
      684,
      683,
      682,
      682,
      681,
      679,
      678,
      677,
      676,
      675,
      674,
      673,
      672,
      671,
      669,
      668,
      667,
      665,
      664,
      663,
      661,
      659,
      658,
      656,
      654,
      652,
      650,
      648,
      646,
      644,
      642,
      639,
      637,
      634,
      632,
      629,
      626,
      623,
      620,
      617,
      614,
      611,
      608,
      604,
      601,
      597,
      593,
      590,
      586,
      582,
      578,
      574,
      569,
      565,
      561,
      556,
      552,
      547,
      542,
      538,
      533,
      528,
      523,
      518,
      513,
      508,
      502,
      497,
      492,
      486,
      481,
      476,
      470,
      464,
      459,
      453,
      447,
      441,
      436,
      430,
      424,
      418,
      412,
    ],
    100: [
      691,
      689,
      688,
      687,
      686,
      684,
      683,
      681,
      680,
      678,
      677,
      675,
      674,
      672,
      671,
      669,
      668,
      666,
      664,
      663,
      661,
      660,
      658,
      656,
      655,
      653,
      651,
      649,
      647,
      645,
      643,
      641,
      639,
      637,
      635,
      632,
      630,
      628,
      625,
      623,
      620,
      617,
      614,
      611,
      609,
      605,
      602,
      599,
      596,
      592,
      589,
      585,
      582,
      578,
      574,
      570,
      566,
      562,
      558,
      554,
      550,
      545,
      541,
      537,
      532,
      527,
      523,
      518,
      513,
      508,
      503,
      498,
      493,
      488,
      483,
      478,
      473,
      467,
      462,
      456,
      451,
      446,
      440,
      434,
      429,
      423,
      417,
      412,
      406,
      400,
      394,
    ],
    105: [
      691,
      689,
      687,
      685,
      683,
      681,
      680,
      677,
      676,
      673,
      671,
      669,
      667,
      665,
      663,
      661,
      659,
      657,
      655,
      653,
      651,
      649,
      647,
      644,
      642,
      640,
      638,
      636,
      634,
      631,
      629,
      627,
      624,
      622,
      619,
      617,
      614,
      611,
      609,
      606,
      603,
      600,
      597,
      594,
      591,
      588,
      584,
      581,
      577,
      574,
      570,
      567,
      563,
      559,
      555,
      551,
      547,
      543,
      539,
      535,
      530,
      526,
      522,
      517,
      512,
      508,
      503,
      499,
      494,
      489,
      484,
      479,
      474,
      469,
      464,
      459,
      453,
      448,
      443,
      437,
      432,
      427,
      421,
      416,
      410,
      405,
      399,
      394,
      388,
      383,
      377,
    ],
    110: [
      691,
      688,
      686,
      684,
      681,
      679,
      676,
      674,
      671,
      669,
      666,
      663,
      661,
      658,
      656,
      653,
      651,
      648,
      645,
      643,
      640,
      638,
      635,
      633,
      630,
      628,
      625,
      623,
      620,
      617,
      615,
      612,
      609,
      607,
      604,
      601,
      598,
      595,
      592,
      589,
      586,
      583,
      580,
      577,
      573,
      570,
      566,
      563,
      559,
      556,
      552,
      548,
      544,
      540,
      536,
      532,
      528,
      524,
      520,
      515,
      511,
      507,
      502,
      498,
      493,
      489,
      484,
      479,
      474,
      469,
      465,
      460,
      455,
      450,
      445,
      439,
      435,
      429,
      424,
      419,
      414,
      409,
      403,
      398,
      392,
      387,
      382,
      376,
      371,
      365,
      360,
    ],
    115: [
      691,
      688,
      685,
      682,
      679,
      676,
      673,
      670,
      667,
      664,
      661,
      658,
      655,
      651,
      648,
      645,
      642,
      639,
      636,
      633,
      630,
      627,
      624,
      621,
      618,
      616,
      612,
      610,
      606,
      604,
      601,
      598,
      595,
      592,
      589,
      586,
      582,
      579,
      576,
      573,
      569,
      566,
      563,
      559,
      556,
      552,
      549,
      545,
      541,
      537,
      534,
      530,
      526,
      522,
      518,
      514,
      509,
      505,
      501,
      497,
      492,
      488,
      483,
      479,
      474,
      470,
      465,
      460,
      455,
      451,
      446,
      441,
      436,
      431,
      426,
      421,
      416,
      411,
      406,
      401,
      396,
      391,
      385,
      380,
      375,
      370,
      365,
      359,
      354,
      349,
      343,
    ],
    120: [
      691,
      687,
      684,
      680,
      677,
      673,
      670,
      666,
      663,
      659,
      656,
      652,
      649,
      645,
      641,
      638,
      634,
      631,
      627,
      624,
      620,
      617,
      614,
      610,
      607,
      603,
      600,
      597,
      594,
      590,
      587,
      584,
      580,
      577,
      574,
      570,
      567,
      564,
      560,
      557,
      553,
      550,
      546,
      542,
      539,
      535,
      531,
      527,
      524,
      520,
      516,
      512,
      508,
      504,
      499,
      495,
      491,
      487,
      482,
      478,
      474,
      469,
      465,
      460,
      456,
      451,
      446,
      442,
      437,
      432,
      427,
      423,
      418,
      413,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      368,
      363,
      358,
      353,
      348,
      342,
      337,
      332,
      327,
    ],
    125: [
      691,
      687,
      683,
      679,
      675,
      671,
      667,
      663,
      659,
      655,
      651,
      647,
      643,
      639,
      635,
      631,
      627,
      623,
      619,
      615,
      611,
      607,
      603,
      600,
      596,
      592,
      588,
      584,
      581,
      577,
      574,
      570,
      566,
      563,
      559,
      556,
      552,
      548,
      545,
      541,
      537,
      533,
      530,
      526,
      522,
      518,
      514,
      510,
      507,
      502,
      498,
      494,
      490,
      486,
      482,
      478,
      473,
      469,
      465,
      460,
      456,
      451,
      447,
      442,
      438,
      433,
      428,
      424,
      419,
      414,
      410,
      405,
      400,
      395,
      390,
      386,
      381,
      376,
      371,
      366,
      361,
      356,
      351,
      346,
      341,
      337,
      331,
      326,
      321,
      317,
      312,
    ],
    130: [
      691,
      686,
      682,
      678,
      673,
      669,
      664,
      660,
      655,
      651,
      646,
      642,
      637,
      633,
      628,
      624,
      620,
      615,
      611,
      607,
      602,
      598,
      594,
      589,
      585,
      581,
      577,
      573,
      569,
      565,
      561,
      557,
      553,
      549,
      545,
      541,
      537,
      533,
      530,
      526,
      522,
      518,
      514,
      510,
      506,
      502,
      498,
      494,
      490,
      486,
      482,
      477,
      473,
      469,
      465,
      460,
      456,
      452,
      447,
      443,
      438,
      434,
      430,
      425,
      420,
      416,
      411,
      407,
      402,
      397,
      393,
      388,
      383,
      379,
      373,
      369,
      364,
      359,
      354,
      350,
      345,
      340,
      335,
      330,
      326,
      321,
      316,
      311,
      306,
      302,
      297,
    ],
    135: [
      691,
      686,
      681,
      676,
      672,
      667,
      662,
      657,
      652,
      647,
      642,
      637,
      632,
      628,
      623,
      618,
      613,
      608,
      604,
      599,
      594,
      589,
      585,
      580,
      575,
      571,
      566,
      562,
      557,
      553,
      549,
      544,
      540,
      536,
      532,
      527,
      523,
      519,
      515,
      511,
      507,
      503,
      499,
      495,
      490,
      486,
      482,
      478,
      474,
      470,
      465,
      461,
      457,
      453,
      448,
      444,
      440,
      435,
      431,
      426,
      422,
      417,
      413,
      408,
      404,
      399,
      395,
      390,
      385,
      381,
      376,
      372,
      367,
      362,
      357,
      353,
      348,
      344,
      339,
      334,
      329,
      325,
      320,
      315,
      310,
      306,
      302,
      297,
      292,
      288,
      283,
    ],
    140: [
      691,
      686,
      680,
      675,
      670,
      665,
      660,
      654,
      649,
      644,
      638,
      633,
      628,
      623,
      618,
      613,
      607,
      602,
      597,
      592,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      552,
      547,
      542,
      537,
      533,
      528,
      523,
      519,
      514,
      510,
      505,
      501,
      497,
      492,
      488,
      484,
      480,
      475,
      471,
      467,
      463,
      458,
      454,
      450,
      445,
      441,
      437,
      432,
      428,
      424,
      419,
      415,
      410,
      406,
      402,
      397,
      392,
      388,
      383,
      379,
      374,
      370,
      365,
      361,
      356,
      352,
      347,
      342,
      338,
      333,
      328,
      324,
      319,
      315,
      310,
      306,
      301,
      296,
      292,
      288,
      283,
      278,
      274,
      270,
    ],
    145: [
      691,
      685,
      680,
      674,
      669,
      663,
      657,
      652,
      646,
      641,
      635,
      629,
      624,
      619,
      613,
      608,
      602,
      597,
      591,
      586,
      581,
      575,
      570,
      564,
      559,
      554,
      548,
      543,
      538,
      533,
      527,
      522,
      517,
      512,
      507,
      502,
      498,
      493,
      488,
      484,
      479,
      474,
      470,
      466,
      461,
      457,
      452,
      448,
      444,
      439,
      435,
      431,
      426,
      422,
      417,
      413,
      409,
      404,
      400,
      395,
      391,
      386,
      382,
      378,
      373,
      368,
      364,
      360,
      355,
      351,
      346,
      342,
      337,
      333,
      328,
      324,
      319,
      315,
      310,
      306,
      301,
      297,
      292,
      288,
      283,
      279,
      275,
      270,
      266,
      262,
      257,
    ],
    150: [
      691,
      685,
      679,
      673,
      667,
      662,
      656,
      650,
      644,
      638,
      632,
      626,
      621,
      615,
      609,
      604,
      598,
      592,
      586,
      581,
      575,
      569,
      563,
      558,
      552,
      547,
      541,
      535,
      530,
      524,
      519,
      514,
      508,
      503,
      497,
      492,
      487,
      482,
      477,
      472,
      467,
      462,
      458,
      453,
      448,
      444,
      439,
      435,
      430,
      426,
      421,
      417,
      412,
      408,
      404,
      399,
      395,
      390,
      386,
      381,
      377,
      373,
      368,
      364,
      359,
      355,
      350,
      346,
      342,
      337,
      333,
      328,
      324,
      320,
      315,
      311,
      306,
      302,
      297,
      293,
      289,
      284,
      280,
      276,
      271,
      267,
      263,
      259,
      254,
      250,
      246,
    ],
    155: [
      691,
      685,
      679,
      672,
      666,
      660,
      654,
      648,
      642,
      636,
      630,
      624,
      618,
      612,
      606,
      600,
      594,
      588,
      582,
      576,
      570,
      564,
      558,
      552,
      546,
      540,
      535,
      529,
      523,
      517,
      512,
      506,
      500,
      495,
      489,
      484,
      478,
      473,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      428,
      423,
      418,
      414,
      409,
      405,
      400,
      396,
      391,
      387,
      382,
      378,
      373,
      369,
      365,
      360,
      356,
      351,
      347,
      343,
      338,
      334,
      329,
      325,
      321,
      316,
      312,
      308,
      303,
      299,
      295,
      290,
      286,
      282,
      278,
      274,
      269,
      265,
      261,
      257,
      252,
      248,
      244,
      240,
      236,
    ],
    160: [
      691,
      684,
      678,
      672,
      665,
      659,
      653,
      646,
      640,
      634,
      628,
      621,
      615,
      609,
      603,
      597,
      591,
      585,
      578,
      572,
      566,
      560,
      554,
      548,
      542,
      536,
      530,
      523,
      518,
      511,
      506,
      500,
      494,
      488,
      482,
      476,
      471,
      465,
      460,
      454,
      449,
      443,
      438,
      433,
      428,
      422,
      417,
      413,
      408,
      404,
      399,
      394,
      390,
      385,
      380,
      376,
      371,
      367,
      363,
      358,
      354,
      349,
      345,
      341,
      336,
      332,
      328,
      323,
      319,
      315,
      310,
      306,
      302,
      298,
      293,
      289,
      285,
      281,
      277,
      272,
      268,
      264,
      260,
      256,
      252,
      248,
      244,
      240,
      236,
      232,
      228,
    ],
    165: [
      691,
      684,
      678,
      671,
      665,
      658,
      652,
      645,
      639,
      632,
      626,
      620,
      613,
      607,
      601,
      595,
      588,
      582,
      575,
      569,
      563,
      556,
      551,
      544,
      538,
      532,
      525,
      519,
      513,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
      459,
      453,
      448,
      442,
      437,
      431,
      426,
      421,
      415,
      410,
      405,
      400,
      395,
      391,
      386,
      381,
      377,
      372,
      367,
      363,
      358,
      354,
      350,
      345,
      341,
      336,
      332,
      328,
      324,
      319,
      315,
      311,
      307,
      302,
      298,
      294,
      290,
      285,
      281,
      277,
      273,
      269,
      265,
      261,
      257,
      253,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
    ],
    170: [
      691,
      684,
      678,
      671,
      664,
      658,
      651,
      644,
      638,
      631,
      625,
      618,
      612,
      606,
      599,
      593,
      586,
      580,
      573,
      567,
      561,
      554,
      548,
      542,
      535,
      529,
      523,
      516,
      510,
      504,
      497,
      491,
      485,
      479,
      473,
      467,
      461,
      455,
      449,
      443,
      437,
      432,
      427,
      421,
      416,
      410,
      405,
      400,
      395,
      389,
      384,
      380,
      375,
      371,
      366,
      361,
      357,
      352,
      348,
      343,
      339,
      334,
      330,
      326,
      322,
      317,
      313,
      309,
      305,
      300,
      296,
      292,
      288,
      284,
      280,
      275,
      271,
      267,
      263,
      259,
      255,
      251,
      247,
      243,
      239,
      235,
      232,
      228,
      224,
      220,
      216,
    ],
    175: [
      691,
      684,
      677,
      671,
      664,
      657,
      651,
      644,
      637,
      631,
      624,
      618,
      611,
      605,
      598,
      592,
      585,
      579,
      572,
      566,
      560,
      553,
      546,
      540,
      534,
      528,
      521,
      514,
      508,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
      459,
      453,
      447,
      441,
      435,
      429,
      423,
      418,
      413,
      407,
      402,
      396,
      391,
      386,
      381,
      376,
      372,
      367,
      362,
      358,
      353,
      348,
      344,
      339,
      335,
      330,
      326,
      322,
      318,
      313,
      309,
      305,
      301,
      296,
      292,
      289,
      284,
      280,
      276,
      272,
      268,
      264,
      260,
      256,
      252,
      248,
      244,
      240,
      236,
      232,
      228,
      225,
      221,
      217,
      213,
    ],
  },
  '18': {
    0: [
      678,
      683,
      688,
      693,
      699,
      704,
      708,
      713,
      718,
      723,
      727,
      731,
      735,
      740,
      743,
      747,
      751,
      754,
      758,
      761,
      764,
      767,
      770,
      772,
      775,
      777,
      779,
      781,
      783,
      785,
      786,
      788,
      789,
      790,
      791,
      791,
      792,
      792,
      792,
      792,
      792,
      792,
      791,
      791,
      790,
      789,
      788,
      787,
      785,
      783,
      782,
      780,
      777,
      775,
      772,
      770,
      767,
      764,
      761,
      758,
      754,
      751,
      747,
      743,
      738,
      734,
      730,
      725,
      721,
      716,
      711,
      705,
      700,
      694,
      689,
      683,
      677,
      671,
      665,
      659,
      652,
      646,
      639,
      632,
      625,
      618,
      611,
      603,
      596,
      588,
      580,
    ],
    5: [
      678,
      683,
      688,
      693,
      698,
      703,
      708,
      713,
      718,
      722,
      727,
      731,
      735,
      739,
      743,
      747,
      751,
      754,
      757,
      761,
      764,
      767,
      769,
      772,
      774,
      777,
      779,
      781,
      783,
      784,
      786,
      787,
      788,
      789,
      790,
      791,
      791,
      792,
      792,
      792,
      792,
      791,
      791,
      790,
      789,
      788,
      787,
      786,
      784,
      783,
      781,
      779,
      777,
      774,
      772,
      769,
      766,
      763,
      760,
      757,
      754,
      750,
      746,
      742,
      738,
      734,
      729,
      725,
      720,
      715,
      710,
      705,
      699,
      694,
      688,
      683,
      677,
      670,
      664,
      658,
      652,
      645,
      638,
      631,
      624,
      617,
      610,
      603,
      595,
      588,
      580,
    ],
    10: [
      678,
      683,
      688,
      693,
      698,
      703,
      708,
      713,
      717,
      722,
      726,
      730,
      735,
      739,
      742,
      746,
      750,
      753,
      756,
      760,
      763,
      765,
      768,
      771,
      773,
      775,
      777,
      779,
      781,
      783,
      784,
      786,
      787,
      788,
      789,
      789,
      790,
      790,
      790,
      790,
      790,
      790,
      789,
      789,
      788,
      787,
      786,
      784,
      783,
      781,
      779,
      777,
      775,
      773,
      770,
      767,
      765,
      762,
      758,
      755,
      752,
      748,
      744,
      740,
      736,
      732,
      727,
      723,
      718,
      713,
      708,
      703,
      698,
      692,
      687,
      681,
      675,
      669,
      662,
      656,
      650,
      643,
      637,
      630,
      623,
      616,
      609,
      601,
      594,
      586,
      579,
    ],
    15: [
      678,
      683,
      688,
      693,
      698,
      703,
      707,
      712,
      717,
      721,
      725,
      729,
      733,
      737,
      741,
      745,
      748,
      752,
      755,
      758,
      761,
      764,
      766,
      769,
      771,
      773,
      775,
      777,
      779,
      781,
      782,
      783,
      784,
      785,
      786,
      787,
      787,
      787,
      788,
      788,
      787,
      787,
      786,
      786,
      785,
      784,
      783,
      781,
      780,
      778,
      776,
      774,
      772,
      770,
      767,
      764,
      762,
      759,
      755,
      752,
      748,
      745,
      741,
      737,
      733,
      729,
      724,
      720,
      715,
      710,
      705,
      700,
      695,
      689,
      683,
      678,
      672,
      666,
      660,
      653,
      647,
      640,
      634,
      627,
      620,
      613,
      606,
      599,
      591,
      584,
      576,
    ],
    20: [
      678,
      682,
      687,
      692,
      697,
      702,
      707,
      711,
      716,
      720,
      724,
      728,
      732,
      736,
      739,
      743,
      746,
      749,
      753,
      756,
      758,
      761,
      764,
      766,
      768,
      770,
      772,
      774,
      776,
      777,
      779,
      780,
      781,
      782,
      783,
      783,
      784,
      784,
      784,
      784,
      784,
      783,
      783,
      782,
      781,
      780,
      779,
      777,
      776,
      774,
      772,
      770,
      768,
      765,
      763,
      760,
      757,
      754,
      751,
      748,
      744,
      741,
      737,
      733,
      729,
      725,
      720,
      715,
      711,
      706,
      701,
      696,
      690,
      685,
      679,
      674,
      668,
      662,
      656,
      649,
      643,
      636,
      630,
      623,
      616,
      609,
      602,
      595,
      588,
      580,
      572,
    ],
    25: [
      678,
      682,
      687,
      692,
      696,
      701,
      706,
      710,
      714,
      718,
      722,
      726,
      730,
      733,
      737,
      740,
      744,
      747,
      750,
      753,
      755,
      758,
      760,
      763,
      765,
      767,
      769,
      770,
      772,
      773,
      775,
      776,
      777,
      777,
      778,
      779,
      779,
      779,
      779,
      779,
      779,
      778,
      778,
      777,
      776,
      775,
      773,
      772,
      770,
      769,
      767,
      765,
      762,
      760,
      757,
      755,
      752,
      749,
      746,
      742,
      739,
      735,
      731,
      727,
      723,
      719,
      714,
      710,
      705,
      700,
      695,
      690,
      685,
      679,
      674,
      668,
      662,
      656,
      650,
      644,
      638,
      632,
      625,
      618,
      611,
      604,
      597,
      590,
      583,
      575,
      568,
    ],
    30: [
      678,
      682,
      687,
      691,
      696,
      700,
      704,
      708,
      712,
      716,
      720,
      724,
      727,
      731,
      734,
      737,
      740,
      743,
      746,
      749,
      751,
      754,
      756,
      758,
      760,
      762,
      764,
      766,
      767,
      768,
      770,
      771,
      771,
      772,
      773,
      773,
      773,
      773,
      773,
      773,
      773,
      772,
      771,
      771,
      770,
      768,
      767,
      766,
      764,
      762,
      760,
      758,
      756,
      753,
      751,
      748,
      745,
      742,
      739,
      735,
      732,
      728,
      724,
      721,
      716,
      712,
      708,
      703,
      698,
      694,
      689,
      683,
      678,
      673,
      667,
      662,
      656,
      650,
      644,
      638,
      632,
      625,
      618,
      612,
      605,
      598,
      591,
      584,
      577,
      570,
      563,
    ],
    35: [
      678,
      682,
      686,
      690,
      695,
      699,
      703,
      707,
      710,
      714,
      718,
      721,
      724,
      728,
      731,
      734,
      737,
      740,
      742,
      745,
      747,
      749,
      751,
      753,
      755,
      757,
      759,
      760,
      762,
      763,
      764,
      765,
      765,
      766,
      766,
      767,
      767,
      767,
      767,
      766,
      766,
      765,
      764,
      763,
      762,
      761,
      760,
      758,
      756,
      754,
      752,
      750,
      748,
      745,
      743,
      740,
      737,
      734,
      731,
      727,
      724,
      720,
      716,
      712,
      708,
      704,
      699,
      695,
      690,
      686,
      681,
      675,
      670,
      665,
      659,
      654,
      648,
      642,
      636,
      630,
      624,
      617,
      611,
      604,
      598,
      591,
      584,
      577,
      570,
      563,
      556,
    ],
    40: [
      678,
      681,
      686,
      690,
      693,
      697,
      701,
      705,
      708,
      712,
      715,
      718,
      721,
      724,
      727,
      730,
      733,
      735,
      738,
      740,
      742,
      744,
      746,
      748,
      750,
      751,
      753,
      754,
      755,
      756,
      757,
      758,
      758,
      759,
      759,
      759,
      759,
      759,
      759,
      758,
      758,
      757,
      756,
      755,
      754,
      753,
      751,
      749,
      748,
      746,
      744,
      741,
      739,
      737,
      734,
      731,
      728,
      725,
      722,
      718,
      715,
      711,
      707,
      703,
      699,
      694,
      690,
      686,
      681,
      676,
      671,
      666,
      661,
      656,
      650,
      645,
      639,
      633,
      627,
      621,
      615,
      609,
      602,
      596,
      590,
      583,
      576,
      569,
      562,
      555,
      548,
    ],
    45: [
      678,
      681,
      685,
      689,
      692,
      696,
      699,
      702,
      706,
      709,
      712,
      715,
      717,
      720,
      723,
      725,
      728,
      730,
      732,
      734,
      736,
      738,
      740,
      742,
      743,
      745,
      746,
      747,
      748,
      749,
      749,
      750,
      750,
      751,
      751,
      751,
      751,
      750,
      750,
      749,
      749,
      748,
      747,
      746,
      744,
      743,
      742,
      740,
      738,
      736,
      734,
      731,
      729,
      726,
      724,
      721,
      718,
      715,
      711,
      708,
      704,
      700,
      697,
      693,
      688,
      684,
      680,
      675,
      671,
      666,
      661,
      656,
      651,
      645,
      640,
      634,
      629,
      623,
      617,
      611,
      605,
      599,
      593,
      586,
      580,
      573,
      566,
      560,
      553,
      546,
      539,
    ],
    50: [
      678,
      681,
      684,
      688,
      691,
      694,
      697,
      700,
      703,
      706,
      708,
      711,
      714,
      716,
      718,
      721,
      723,
      725,
      727,
      729,
      730,
      732,
      733,
      735,
      736,
      737,
      738,
      739,
      740,
      741,
      741,
      741,
      742,
      742,
      742,
      742,
      741,
      741,
      740,
      740,
      739,
      738,
      737,
      735,
      734,
      733,
      731,
      729,
      727,
      725,
      723,
      720,
      718,
      715,
      712,
      709,
      706,
      703,
      700,
      696,
      693,
      689,
      685,
      681,
      677,
      673,
      668,
      664,
      659,
      654,
      650,
      644,
      639,
      634,
      629,
      623,
      617,
      612,
      606,
      600,
      594,
      588,
      582,
      575,
      569,
      562,
      556,
      549,
      543,
      535,
      529,
    ],
    55: [
      678,
      680,
      683,
      686,
      689,
      692,
      695,
      697,
      700,
      702,
      705,
      707,
      709,
      711,
      713,
      715,
      717,
      719,
      720,
      722,
      724,
      725,
      726,
      727,
      728,
      729,
      730,
      731,
      731,
      732,
      732,
      732,
      732,
      732,
      732,
      732,
      731,
      731,
      730,
      729,
      728,
      727,
      726,
      724,
      723,
      721,
      719,
      717,
      715,
      713,
      711,
      708,
      706,
      703,
      700,
      697,
      694,
      691,
      687,
      684,
      680,
      676,
      672,
      668,
      664,
      660,
      655,
      651,
      646,
      642,
      637,
      631,
      626,
      621,
      616,
      611,
      605,
      599,
      594,
      588,
      582,
      576,
      569,
      563,
      557,
      551,
      544,
      538,
      531,
      524,
      517,
    ],
    60: [
      678,
      680,
      683,
      685,
      688,
      690,
      692,
      694,
      697,
      699,
      701,
      703,
      705,
      706,
      708,
      710,
      711,
      713,
      714,
      715,
      716,
      717,
      718,
      719,
      720,
      721,
      721,
      722,
      722,
      722,
      722,
      722,
      722,
      722,
      721,
      721,
      720,
      720,
      719,
      718,
      717,
      715,
      714,
      712,
      711,
      709,
      707,
      705,
      703,
      700,
      698,
      695,
      693,
      690,
      687,
      684,
      681,
      677,
      674,
      670,
      666,
      663,
      659,
      655,
      650,
      646,
      642,
      637,
      633,
      628,
      623,
      618,
      613,
      608,
      603,
      597,
      591,
      586,
      580,
      575,
      569,
      562,
      556,
      550,
      544,
      538,
      531,
      525,
      519,
      512,
      505,
    ],
    65: [
      678,
      680,
      682,
      684,
      686,
      688,
      690,
      692,
      693,
      695,
      697,
      698,
      700,
      701,
      702,
      704,
      705,
      706,
      707,
      708,
      709,
      710,
      710,
      711,
      711,
      712,
      712,
      712,
      712,
      712,
      712,
      712,
      711,
      711,
      710,
      710,
      709,
      708,
      707,
      706,
      704,
      703,
      701,
      699,
      698,
      696,
      694,
      692,
      689,
      687,
      684,
      682,
      679,
      676,
      673,
      670,
      666,
      663,
      660,
      656,
      652,
      648,
      644,
      640,
      636,
      632,
      627,
      623,
      618,
      613,
      609,
      603,
      598,
      593,
      588,
      583,
      578,
      571,
      566,
      560,
      555,
      549,
      543,
      537,
      531,
      524,
      518,
      512,
      506,
      499,
      492,
    ],
    70: [
      678,
      679,
      681,
      682,
      684,
      686,
      687,
      688,
      690,
      691,
      692,
      693,
      694,
      695,
      696,
      697,
      698,
      699,
      700,
      700,
      701,
      701,
      701,
      702,
      702,
      702,
      702,
      702,
      702,
      701,
      701,
      701,
      700,
      699,
      698,
      697,
      697,
      695,
      694,
      693,
      691,
      690,
      688,
      686,
      684,
      682,
      680,
      678,
      675,
      673,
      670,
      667,
      664,
      661,
      658,
      655,
      651,
      648,
      645,
      641,
      637,
      633,
      629,
      625,
      621,
      616,
      612,
      608,
      603,
      598,
      593,
      588,
      583,
      578,
      573,
      568,
      562,
      556,
      551,
      546,
      540,
      534,
      528,
      522,
      516,
      510,
      504,
      498,
      491,
      485,
      479,
    ],
    75: [
      678,
      679,
      680,
      681,
      682,
      683,
      684,
      685,
      686,
      687,
      688,
      688,
      689,
      690,
      690,
      691,
      691,
      692,
      692,
      692,
      692,
      692,
      692,
      692,
      692,
      692,
      692,
      691,
      691,
      690,
      690,
      689,
      688,
      687,
      686,
      685,
      684,
      682,
      681,
      679,
      678,
      676,
      674,
      672,
      670,
      668,
      665,
      663,
      660,
      658,
      655,
      652,
      649,
      646,
      643,
      639,
      636,
      632,
      629,
      625,
      621,
      617,
      613,
      609,
      605,
      600,
      596,
      591,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      552,
      547,
      541,
      535,
      530,
      525,
      519,
      513,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
    ],
    80: [
      678,
      678,
      679,
      680,
      680,
      681,
      682,
      682,
      682,
      683,
      683,
      683,
      684,
      684,
      684,
      684,
      684,
      684,
      684,
      684,
      684,
      683,
      683,
      683,
      682,
      682,
      681,
      680,
      680,
      679,
      678,
      677,
      676,
      675,
      673,
      672,
      671,
      669,
      667,
      666,
      664,
      662,
      660,
      657,
      655,
      653,
      650,
      648,
      645,
      642,
      639,
      636,
      633,
      630,
      627,
      623,
      620,
      616,
      612,
      609,
      605,
      601,
      597,
      592,
      588,
      584,
      579,
      575,
      570,
      565,
      561,
      556,
      551,
      546,
      540,
      535,
      530,
      525,
      519,
      514,
      509,
      503,
      497,
      491,
      485,
      480,
      474,
      468,
      462,
      456,
      450,
    ],
    85: [
      678,
      678,
      678,
      678,
      678,
      679,
      679,
      679,
      679,
      678,
      678,
      678,
      678,
      678,
      677,
      677,
      677,
      676,
      676,
      675,
      675,
      674,
      673,
      673,
      672,
      671,
      670,
      669,
      668,
      667,
      666,
      665,
      663,
      662,
      660,
      659,
      657,
      655,
      653,
      651,
      649,
      647,
      645,
      642,
      640,
      637,
      635,
      632,
      629,
      626,
      623,
      620,
      617,
      614,
      610,
      607,
      603,
      599,
      596,
      592,
      588,
      584,
      580,
      575,
      571,
      567,
      562,
      558,
      553,
      548,
      544,
      539,
      534,
      529,
      524,
      518,
      513,
      508,
      503,
      497,
      492,
      486,
      481,
      475,
      469,
      464,
      459,
      453,
      446,
      440,
      434,
    ],
    90: [
      678,
      677,
      677,
      677,
      677,
      676,
      676,
      675,
      675,
      674,
      674,
      673,
      672,
      671,
      671,
      670,
      669,
      668,
      667,
      666,
      666,
      665,
      664,
      663,
      661,
      660,
      659,
      658,
      656,
      655,
      653,
      652,
      650,
      648,
      647,
      645,
      643,
      641,
      639,
      637,
      634,
      632,
      629,
      627,
      624,
      622,
      619,
      616,
      613,
      610,
      607,
      604,
      600,
      597,
      593,
      590,
      586,
      582,
      578,
      574,
      570,
      566,
      562,
      558,
      554,
      549,
      545,
      540,
      535,
      531,
      526,
      521,
      516,
      511,
      506,
      501,
      496,
      491,
      486,
      480,
      475,
      470,
      464,
      459,
      453,
      447,
      442,
      436,
      430,
      424,
      419,
    ],
    95: [
      678,
      677,
      676,
      675,
      675,
      674,
      673,
      672,
      671,
      670,
      669,
      668,
      666,
      665,
      664,
      663,
      662,
      660,
      659,
      658,
      656,
      655,
      654,
      652,
      651,
      649,
      648,
      646,
      644,
      643,
      641,
      639,
      637,
      635,
      633,
      631,
      629,
      626,
      624,
      622,
      619,
      617,
      614,
      611,
      608,
      606,
      603,
      600,
      596,
      593,
      590,
      587,
      583,
      580,
      576,
      572,
      569,
      565,
      561,
      557,
      553,
      549,
      544,
      540,
      536,
      531,
      527,
      522,
      518,
      513,
      509,
      504,
      499,
      494,
      489,
      484,
      479,
      473,
      468,
      463,
      458,
      453,
      447,
      442,
      436,
      431,
      425,
      420,
      414,
      408,
      403,
    ],
    100: [
      678,
      676,
      675,
      674,
      673,
      671,
      670,
      668,
      667,
      665,
      664,
      662,
      661,
      659,
      657,
      656,
      654,
      652,
      651,
      649,
      647,
      645,
      643,
      642,
      640,
      638,
      636,
      634,
      632,
      630,
      628,
      626,
      623,
      621,
      619,
      617,
      614,
      612,
      609,
      606,
      604,
      601,
      598,
      595,
      592,
      589,
      586,
      583,
      580,
      576,
      573,
      570,
      566,
      562,
      559,
      555,
      551,
      547,
      543,
      539,
      535,
      531,
      527,
      522,
      518,
      513,
      509,
      505,
      500,
      495,
      490,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      446,
      441,
      435,
      430,
      425,
      420,
      414,
      409,
      403,
      398,
      392,
      387,
    ],
    105: [
      678,
      676,
      674,
      672,
      671,
      669,
      667,
      665,
      663,
      661,
      659,
      657,
      655,
      653,
      651,
      649,
      646,
      644,
      642,
      640,
      638,
      636,
      633,
      631,
      629,
      627,
      624,
      622,
      620,
      617,
      615,
      613,
      610,
      608,
      605,
      602,
      599,
      597,
      594,
      591,
      588,
      585,
      582,
      579,
      576,
      573,
      570,
      566,
      563,
      559,
      556,
      552,
      549,
      545,
      541,
      537,
      533,
      529,
      525,
      521,
      517,
      513,
      509,
      504,
      500,
      495,
      491,
      486,
      482,
      477,
      472,
      468,
      463,
      458,
      453,
      448,
      443,
      438,
      433,
      428,
      423,
      418,
      413,
      407,
      403,
      397,
      392,
      387,
      381,
      376,
      371,
    ],
    110: [
      678,
      675,
      673,
      671,
      669,
      667,
      664,
      662,
      659,
      657,
      654,
      652,
      649,
      647,
      644,
      642,
      639,
      636,
      634,
      631,
      629,
      626,
      623,
      621,
      618,
      616,
      613,
      610,
      607,
      605,
      602,
      599,
      596,
      594,
      591,
      588,
      585,
      582,
      579,
      576,
      573,
      570,
      566,
      563,
      560,
      556,
      553,
      550,
      546,
      542,
      539,
      535,
      531,
      527,
      524,
      520,
      516,
      512,
      507,
      503,
      499,
      495,
      490,
      486,
      482,
      477,
      473,
      468,
      464,
      459,
      454,
      450,
      445,
      440,
      435,
      430,
      426,
      421,
      416,
      411,
      406,
      401,
      396,
      390,
      385,
      380,
      375,
      370,
      365,
      360,
      355,
    ],
    115: [
      678,
      675,
      672,
      670,
      667,
      664,
      662,
      659,
      656,
      653,
      650,
      647,
      644,
      641,
      638,
      635,
      632,
      629,
      626,
      623,
      619,
      616,
      614,
      610,
      607,
      604,
      601,
      598,
      595,
      592,
      589,
      586,
      583,
      580,
      577,
      574,
      570,
      567,
      564,
      561,
      557,
      554,
      551,
      547,
      544,
      540,
      536,
      533,
      529,
      525,
      522,
      518,
      514,
      510,
      506,
      502,
      498,
      494,
      490,
      485,
      481,
      477,
      473,
      468,
      464,
      460,
      455,
      450,
      446,
      441,
      437,
      432,
      427,
      422,
      418,
      413,
      408,
      403,
      398,
      393,
      389,
      384,
      379,
      373,
      369,
      364,
      359,
      354,
      349,
      344,
      339,
    ],
    120: [
      678,
      674,
      672,
      668,
      665,
      662,
      659,
      656,
      652,
      649,
      646,
      642,
      639,
      635,
      632,
      628,
      625,
      621,
      618,
      614,
      611,
      607,
      604,
      600,
      597,
      594,
      590,
      587,
      583,
      580,
      576,
      573,
      570,
      566,
      563,
      559,
      556,
      553,
      549,
      546,
      542,
      538,
      535,
      531,
      528,
      524,
      520,
      516,
      513,
      509,
      505,
      501,
      497,
      493,
      489,
      485,
      480,
      476,
      472,
      468,
      464,
      459,
      455,
      451,
      446,
      442,
      437,
      433,
      428,
      424,
      419,
      414,
      410,
      405,
      400,
      396,
      391,
      386,
      381,
      376,
      372,
      367,
      362,
      357,
      352,
      348,
      343,
      338,
      333,
      328,
      323,
    ],
    125: [
      678,
      674,
      671,
      667,
      664,
      660,
      657,
      653,
      649,
      645,
      641,
      638,
      634,
      630,
      626,
      622,
      618,
      614,
      610,
      606,
      602,
      598,
      595,
      591,
      587,
      583,
      579,
      576,
      572,
      568,
      564,
      561,
      557,
      553,
      549,
      546,
      542,
      538,
      534,
      531,
      527,
      523,
      519,
      516,
      512,
      508,
      504,
      500,
      496,
      492,
      488,
      484,
      480,
      476,
      472,
      468,
      463,
      459,
      455,
      451,
      446,
      442,
      438,
      433,
      429,
      424,
      420,
      415,
      411,
      406,
      402,
      397,
      393,
      388,
      383,
      378,
      374,
      369,
      365,
      360,
      355,
      350,
      346,
      341,
      336,
      332,
      327,
      322,
      317,
      313,
      308,
    ],
    130: [
      678,
      674,
      670,
      666,
      662,
      658,
      654,
      650,
      646,
      642,
      638,
      633,
      629,
      625,
      620,
      616,
      612,
      608,
      603,
      599,
      595,
      590,
      586,
      582,
      577,
      573,
      569,
      565,
      561,
      556,
      552,
      548,
      544,
      540,
      536,
      532,
      528,
      524,
      520,
      516,
      512,
      508,
      504,
      500,
      496,
      492,
      488,
      484,
      480,
      476,
      472,
      468,
      464,
      459,
      455,
      451,
      447,
      442,
      438,
      434,
      430,
      425,
      421,
      416,
      412,
      408,
      403,
      399,
      394,
      390,
      385,
      381,
      376,
      371,
      367,
      362,
      358,
      353,
      349,
      344,
      339,
      335,
      330,
      325,
      321,
      316,
      312,
      307,
      302,
      298,
      293,
    ],
    135: [
      678,
      673,
      669,
      665,
      661,
      656,
      652,
      648,
      643,
      639,
      634,
      629,
      625,
      620,
      615,
      611,
      606,
      601,
      597,
      592,
      587,
      583,
      578,
      573,
      569,
      564,
      559,
      555,
      550,
      546,
      541,
      537,
      532,
      528,
      524,
      519,
      515,
      511,
      507,
      502,
      498,
      494,
      490,
      486,
      481,
      477,
      473,
      469,
      465,
      460,
      456,
      452,
      448,
      443,
      439,
      435,
      431,
      426,
      422,
      417,
      413,
      409,
      404,
      400,
      396,
      391,
      387,
      382,
      378,
      373,
      369,
      364,
      360,
      355,
      351,
      347,
      342,
      337,
      333,
      328,
      324,
      319,
      315,
      310,
      306,
      302,
      297,
      293,
      288,
      284,
      280,
    ],
    140: [
      678,
      673,
      669,
      664,
      659,
      655,
      650,
      645,
      640,
      636,
      631,
      626,
      621,
      616,
      611,
      606,
      601,
      596,
      591,
      586,
      581,
      576,
      571,
      566,
      561,
      556,
      551,
      546,
      541,
      536,
      531,
      527,
      522,
      517,
      512,
      507,
      503,
      498,
      494,
      489,
      485,
      480,
      476,
      471,
      467,
      463,
      458,
      454,
      450,
      445,
      441,
      437,
      432,
      428,
      424,
      419,
      415,
      411,
      406,
      402,
      398,
      393,
      389,
      384,
      380,
      376,
      371,
      367,
      362,
      358,
      354,
      349,
      345,
      340,
      336,
      331,
      327,
      323,
      318,
      314,
      309,
      305,
      301,
      296,
      292,
      288,
      283,
      279,
      275,
      270,
      266,
    ],
    145: [
      678,
      673,
      668,
      663,
      658,
      653,
      648,
      643,
      638,
      633,
      628,
      622,
      617,
      612,
      607,
      602,
      596,
      591,
      586,
      580,
      575,
      570,
      565,
      559,
      554,
      549,
      544,
      538,
      533,
      528,
      523,
      518,
      512,
      507,
      502,
      497,
      492,
      487,
      482,
      477,
      472,
      468,
      463,
      458,
      454,
      449,
      445,
      440,
      436,
      431,
      427,
      422,
      418,
      413,
      409,
      405,
      400,
      396,
      392,
      387,
      383,
      378,
      374,
      370,
      365,
      361,
      357,
      352,
      348,
      344,
      339,
      335,
      330,
      326,
      322,
      317,
      313,
      309,
      305,
      300,
      296,
      292,
      288,
      283,
      279,
      275,
      271,
      266,
      262,
      258,
      254,
    ],
    150: [
      678,
      672,
      667,
      662,
      657,
      652,
      647,
      641,
      636,
      630,
      625,
      620,
      614,
      609,
      603,
      598,
      592,
      587,
      581,
      576,
      570,
      565,
      559,
      554,
      548,
      543,
      537,
      532,
      526,
      521,
      515,
      510,
      505,
      499,
      494,
      488,
      483,
      478,
      472,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      428,
      423,
      418,
      414,
      409,
      405,
      400,
      396,
      391,
      387,
      382,
      378,
      374,
      369,
      365,
      361,
      356,
      352,
      348,
      343,
      339,
      335,
      330,
      326,
      322,
      318,
      313,
      309,
      305,
      301,
      296,
      292,
      288,
      284,
      280,
      275,
      271,
      267,
      263,
      259,
      255,
      251,
      247,
      243,
    ],
    155: [
      678,
      672,
      667,
      662,
      656,
      651,
      645,
      640,
      634,
      628,
      623,
      617,
      611,
      606,
      600,
      594,
      589,
      583,
      577,
      572,
      566,
      560,
      554,
      549,
      543,
      537,
      532,
      526,
      520,
      515,
      509,
      503,
      498,
      492,
      487,
      481,
      476,
      470,
      465,
      459,
      454,
      448,
      443,
      437,
      432,
      427,
      422,
      417,
      412,
      407,
      402,
      398,
      393,
      388,
      384,
      379,
      375,
      370,
      366,
      362,
      357,
      353,
      349,
      344,
      340,
      336,
      331,
      327,
      323,
      319,
      314,
      310,
      306,
      302,
      298,
      293,
      289,
      285,
      281,
      277,
      273,
      268,
      265,
      261,
      257,
      253,
      249,
      245,
      241,
      237,
      233,
    ],
    160: [
      678,
      672,
      667,
      661,
      656,
      650,
      644,
      638,
      633,
      627,
      621,
      615,
      609,
      603,
      598,
      592,
      586,
      580,
      574,
      568,
      562,
      556,
      550,
      545,
      539,
      533,
      527,
      521,
      516,
      510,
      504,
      498,
      492,
      487,
      481,
      475,
      470,
      464,
      458,
      452,
      447,
      441,
      436,
      430,
      425,
      419,
      414,
      409,
      404,
      398,
      393,
      388,
      383,
      378,
      374,
      369,
      365,
      360,
      356,
      351,
      347,
      342,
      338,
      334,
      330,
      325,
      321,
      317,
      313,
      308,
      304,
      300,
      296,
      292,
      288,
      284,
      280,
      276,
      271,
      267,
      263,
      259,
      256,
      252,
      247,
      244,
      240,
      236,
      232,
      228,
      225,
    ],
    165: [
      678,
      672,
      666,
      661,
      655,
      649,
      643,
      637,
      631,
      625,
      620,
      614,
      608,
      602,
      596,
      590,
      584,
      578,
      571,
      565,
      559,
      554,
      548,
      542,
      536,
      530,
      524,
      518,
      512,
      506,
      500,
      494,
      488,
      482,
      476,
      470,
      464,
      459,
      453,
      447,
      442,
      436,
      430,
      425,
      419,
      413,
      408,
      402,
      397,
      392,
      386,
      381,
      376,
      371,
      366,
      361,
      356,
      352,
      347,
      343,
      339,
      334,
      330,
      326,
      321,
      317,
      313,
      309,
      305,
      300,
      296,
      292,
      288,
      284,
      280,
      276,
      272,
      268,
      264,
      260,
      256,
      252,
      248,
      244,
      241,
      237,
      233,
      229,
      225,
      222,
      218,
    ],
    170: [
      678,
      672,
      666,
      660,
      655,
      649,
      643,
      637,
      631,
      625,
      618,
      612,
      606,
      600,
      594,
      588,
      582,
      576,
      569,
      563,
      558,
      552,
      545,
      539,
      533,
      528,
      521,
      515,
      509,
      503,
      497,
      491,
      485,
      479,
      473,
      467,
      461,
      455,
      449,
      443,
      437,
      432,
      426,
      420,
      415,
      409,
      404,
      398,
      392,
      387,
      381,
      376,
      371,
      366,
      360,
      355,
      351,
      346,
      341,
      337,
      332,
      328,
      324,
      320,
      315,
      311,
      307,
      303,
      298,
      294,
      290,
      286,
      282,
      278,
      274,
      270,
      266,
      262,
      258,
      254,
      251,
      247,
      243,
      239,
      235,
      232,
      228,
      224,
      221,
      217,
      213,
    ],
    175: [
      678,
      672,
      666,
      660,
      654,
      648,
      642,
      636,
      630,
      624,
      618,
      612,
      606,
      599,
      593,
      587,
      581,
      575,
      568,
      562,
      556,
      550,
      544,
      538,
      532,
      526,
      520,
      513,
      507,
      501,
      495,
      489,
      483,
      477,
      471,
      465,
      460,
      454,
      448,
      441,
      435,
      429,
      424,
      418,
      412,
      407,
      401,
      395,
      390,
      384,
      378,
      373,
      368,
      362,
      357,
      352,
      347,
      342,
      338,
      333,
      329,
      324,
      320,
      316,
      311,
      307,
      303,
      299,
      295,
      291,
      287,
      283,
      278,
      275,
      271,
      267,
      263,
      259,
      255,
      251,
      247,
      243,
      240,
      236,
      232,
      228,
      225,
      221,
      217,
      214,
      210,
    ],
  },
  '19': {
    0: [
      649,
      653,
      658,
      663,
      668,
      673,
      678,
      682,
      687,
      691,
      695,
      699,
      703,
      707,
      711,
      715,
      718,
      721,
      725,
      728,
      731,
      733,
      736,
      738,
      741,
      743,
      745,
      747,
      749,
      750,
      752,
      753,
      754,
      755,
      756,
      757,
      757,
      757,
      758,
      758,
      758,
      757,
      757,
      756,
      755,
      755,
      753,
      752,
      751,
      749,
      748,
      746,
      744,
      741,
      739,
      736,
      734,
      731,
      728,
      725,
      722,
      718,
      715,
      711,
      707,
      703,
      699,
      694,
      690,
      685,
      681,
      676,
      671,
      665,
      660,
      655,
      649,
      643,
      638,
      632,
      625,
      619,
      613,
      606,
      600,
      593,
      586,
      579,
      572,
      565,
      558,
    ],
    5: [
      649,
      653,
      658,
      663,
      668,
      673,
      678,
      682,
      687,
      691,
      695,
      699,
      703,
      707,
      711,
      714,
      718,
      721,
      724,
      727,
      730,
      733,
      736,
      738,
      740,
      743,
      745,
      746,
      748,
      750,
      751,
      752,
      754,
      755,
      755,
      756,
      757,
      757,
      757,
      757,
      757,
      757,
      756,
      756,
      755,
      754,
      753,
      752,
      750,
      749,
      747,
      745,
      743,
      741,
      738,
      736,
      733,
      730,
      728,
      724,
      721,
      718,
      714,
      710,
      706,
      702,
      698,
      694,
      689,
      685,
      680,
      675,
      670,
      665,
      660,
      654,
      649,
      643,
      637,
      631,
      625,
      619,
      612,
      606,
      599,
      593,
      586,
      579,
      572,
      565,
      557,
    ],
    10: [
      649,
      653,
      658,
      663,
      668,
      673,
      677,
      682,
      686,
      690,
      695,
      699,
      703,
      706,
      710,
      714,
      717,
      720,
      723,
      726,
      729,
      732,
      734,
      737,
      739,
      741,
      743,
      745,
      747,
      748,
      750,
      751,
      752,
      753,
      754,
      755,
      755,
      755,
      756,
      756,
      755,
      755,
      755,
      754,
      753,
      752,
      751,
      750,
      749,
      747,
      745,
      743,
      741,
      739,
      737,
      734,
      732,
      729,
      726,
      723,
      719,
      716,
      712,
      708,
      705,
      701,
      696,
      692,
      688,
      683,
      678,
      673,
      668,
      663,
      658,
      652,
      647,
      641,
      635,
      629,
      623,
      617,
      611,
      604,
      597,
      591,
      584,
      577,
      570,
      563,
      556,
    ],
    15: [
      649,
      653,
      658,
      663,
      668,
      672,
      677,
      681,
      685,
      690,
      694,
      698,
      701,
      705,
      709,
      712,
      716,
      719,
      722,
      725,
      728,
      730,
      733,
      735,
      737,
      739,
      741,
      743,
      745,
      746,
      748,
      749,
      750,
      751,
      752,
      752,
      753,
      753,
      753,
      753,
      753,
      752,
      752,
      751,
      750,
      750,
      748,
      747,
      746,
      744,
      742,
      740,
      738,
      736,
      734,
      731,
      729,
      726,
      723,
      720,
      716,
      713,
      709,
      705,
      701,
      698,
      693,
      689,
      685,
      680,
      675,
      670,
      665,
      660,
      655,
      649,
      644,
      638,
      632,
      626,
      620,
      614,
      608,
      601,
      595,
      588,
      581,
      574,
      567,
      561,
      553,
    ],
    20: [
      649,
      653,
      658,
      663,
      667,
      672,
      676,
      680,
      684,
      688,
      692,
      696,
      700,
      704,
      707,
      710,
      714,
      717,
      720,
      723,
      725,
      728,
      730,
      733,
      735,
      737,
      739,
      740,
      742,
      743,
      745,
      746,
      747,
      748,
      748,
      749,
      749,
      749,
      749,
      749,
      749,
      749,
      748,
      748,
      747,
      746,
      744,
      743,
      742,
      740,
      738,
      736,
      734,
      732,
      730,
      727,
      724,
      722,
      719,
      715,
      712,
      709,
      705,
      701,
      697,
      693,
      689,
      685,
      680,
      676,
      671,
      666,
      661,
      656,
      651,
      645,
      640,
      634,
      628,
      622,
      616,
      610,
      604,
      598,
      591,
      584,
      578,
      571,
      564,
      557,
      550,
    ],
    25: [
      649,
      653,
      658,
      662,
      666,
      671,
      675,
      679,
      683,
      687,
      691,
      694,
      698,
      701,
      705,
      708,
      711,
      714,
      717,
      720,
      722,
      725,
      727,
      729,
      731,
      733,
      735,
      737,
      738,
      739,
      741,
      742,
      743,
      743,
      744,
      744,
      745,
      745,
      745,
      745,
      744,
      744,
      743,
      743,
      742,
      741,
      739,
      738,
      737,
      735,
      733,
      731,
      729,
      727,
      724,
      722,
      719,
      716,
      713,
      710,
      706,
      703,
      700,
      696,
      692,
      688,
      684,
      679,
      675,
      670,
      666,
      661,
      656,
      650,
      645,
      640,
      634,
      629,
      623,
      617,
      611,
      605,
      599,
      593,
      586,
      580,
      573,
      566,
      559,
      552,
      545,
    ],
    30: [
      649,
      653,
      657,
      661,
      666,
      670,
      674,
      678,
      681,
      685,
      689,
      692,
      696,
      699,
      702,
      705,
      708,
      711,
      714,
      716,
      719,
      721,
      723,
      725,
      727,
      729,
      731,
      732,
      733,
      735,
      736,
      737,
      738,
      738,
      739,
      739,
      739,
      739,
      739,
      739,
      739,
      738,
      738,
      737,
      736,
      735,
      733,
      732,
      730,
      729,
      727,
      725,
      723,
      720,
      718,
      715,
      712,
      710,
      707,
      703,
      700,
      696,
      693,
      689,
      685,
      681,
      677,
      673,
      668,
      664,
      659,
      654,
      649,
      644,
      639,
      634,
      628,
      622,
      617,
      611,
      605,
      599,
      593,
      586,
      580,
      573,
      567,
      560,
      553,
      547,
      540,
    ],
    35: [
      649,
      652,
      657,
      661,
      665,
      669,
      672,
      676,
      680,
      683,
      686,
      690,
      693,
      696,
      699,
      702,
      705,
      707,
      710,
      712,
      714,
      717,
      719,
      720,
      722,
      724,
      725,
      727,
      728,
      729,
      730,
      731,
      732,
      732,
      733,
      733,
      733,
      733,
      733,
      732,
      732,
      731,
      731,
      730,
      729,
      728,
      726,
      725,
      723,
      721,
      719,
      717,
      715,
      713,
      710,
      708,
      705,
      702,
      699,
      696,
      692,
      689,
      685,
      681,
      678,
      674,
      669,
      665,
      660,
      656,
      651,
      646,
      641,
      636,
      631,
      626,
      620,
      615,
      609,
      603,
      598,
      591,
      585,
      579,
      573,
      567,
      560,
      553,
      546,
      540,
      533,
    ],
    40: [
      649,
      652,
      656,
      660,
      664,
      667,
      671,
      674,
      677,
      681,
      684,
      687,
      690,
      693,
      695,
      698,
      701,
      703,
      705,
      708,
      710,
      712,
      713,
      715,
      717,
      718,
      720,
      721,
      722,
      723,
      724,
      724,
      725,
      725,
      726,
      726,
      726,
      726,
      725,
      725,
      724,
      724,
      723,
      722,
      721,
      719,
      718,
      716,
      715,
      713,
      711,
      709,
      707,
      704,
      702,
      699,
      696,
      693,
      690,
      687,
      683,
      680,
      676,
      672,
      669,
      665,
      660,
      656,
      651,
      647,
      642,
      637,
      632,
      627,
      622,
      617,
      611,
      606,
      600,
      595,
      589,
      583,
      577,
      571,
      565,
      558,
      552,
      545,
      538,
      532,
      525,
    ],
    45: [
      649,
      652,
      655,
      659,
      662,
      666,
      669,
      672,
      675,
      678,
      681,
      684,
      686,
      689,
      691,
      694,
      696,
      698,
      700,
      702,
      704,
      706,
      708,
      709,
      711,
      712,
      713,
      714,
      715,
      716,
      716,
      717,
      717,
      718,
      718,
      718,
      718,
      717,
      717,
      716,
      716,
      715,
      714,
      713,
      712,
      710,
      709,
      707,
      705,
      703,
      701,
      699,
      697,
      694,
      692,
      689,
      686,
      683,
      680,
      677,
      673,
      670,
      666,
      662,
      658,
      654,
      650,
      646,
      641,
      637,
      632,
      627,
      622,
      617,
      612,
      607,
      602,
      596,
      591,
      585,
      579,
      573,
      567,
      561,
      555,
      549,
      542,
      536,
      530,
      523,
      516,
    ],
    50: [
      649,
      652,
      655,
      658,
      661,
      664,
      667,
      670,
      672,
      675,
      678,
      680,
      683,
      685,
      687,
      689,
      691,
      693,
      695,
      697,
      698,
      700,
      701,
      703,
      704,
      705,
      706,
      707,
      707,
      708,
      709,
      709,
      709,
      709,
      709,
      709,
      709,
      708,
      708,
      707,
      706,
      705,
      704,
      703,
      702,
      700,
      699,
      697,
      695,
      693,
      691,
      689,
      686,
      684,
      681,
      678,
      675,
      672,
      669,
      666,
      662,
      658,
      655,
      651,
      647,
      643,
      639,
      634,
      630,
      626,
      621,
      616,
      611,
      606,
      601,
      596,
      590,
      585,
      579,
      574,
      568,
      562,
      557,
      550,
      545,
      538,
      532,
      526,
      520,
      513,
      506,
    ],
    55: [
      649,
      651,
      654,
      657,
      660,
      662,
      665,
      667,
      670,
      672,
      674,
      676,
      678,
      680,
      682,
      684,
      686,
      688,
      689,
      691,
      692,
      693,
      694,
      695,
      696,
      697,
      698,
      699,
      699,
      700,
      700,
      700,
      700,
      700,
      700,
      700,
      699,
      698,
      698,
      697,
      696,
      695,
      694,
      692,
      691,
      689,
      688,
      686,
      684,
      682,
      679,
      677,
      674,
      672,
      669,
      666,
      663,
      660,
      657,
      653,
      650,
      646,
      643,
      639,
      635,
      631,
      626,
      622,
      618,
      613,
      609,
      603,
      599,
      594,
      589,
      584,
      578,
      573,
      568,
      562,
      557,
      550,
      545,
      539,
      533,
      527,
      521,
      514,
      508,
      502,
      495,
    ],
    60: [
      649,
      651,
      653,
      656,
      658,
      660,
      662,
      665,
      667,
      669,
      670,
      672,
      674,
      676,
      677,
      679,
      680,
      682,
      683,
      684,
      685,
      686,
      687,
      688,
      689,
      689,
      690,
      690,
      690,
      691,
      691,
      691,
      690,
      690,
      690,
      689,
      689,
      688,
      687,
      686,
      685,
      684,
      682,
      681,
      679,
      678,
      676,
      674,
      672,
      669,
      667,
      665,
      662,
      659,
      656,
      653,
      650,
      647,
      644,
      640,
      637,
      633,
      629,
      626,
      622,
      618,
      613,
      609,
      604,
      600,
      595,
      591,
      586,
      581,
      576,
      571,
      566,
      560,
      555,
      549,
      544,
      538,
      532,
      526,
      521,
      515,
      508,
      502,
      496,
      490,
      483,
    ],
    65: [
      649,
      650,
      652,
      654,
      656,
      658,
      660,
      662,
      663,
      665,
      667,
      668,
      669,
      671,
      672,
      673,
      674,
      675,
      676,
      677,
      678,
      679,
      679,
      680,
      680,
      681,
      681,
      681,
      681,
      681,
      681,
      681,
      680,
      680,
      679,
      678,
      678,
      677,
      676,
      674,
      673,
      672,
      670,
      669,
      667,
      665,
      663,
      661,
      659,
      656,
      654,
      651,
      649,
      646,
      643,
      640,
      637,
      634,
      630,
      627,
      623,
      619,
      616,
      612,
      608,
      604,
      599,
      595,
      591,
      586,
      581,
      577,
      572,
      567,
      562,
      557,
      552,
      546,
      541,
      536,
      530,
      524,
      519,
      513,
      507,
      502,
      495,
      489,
      483,
      477,
      471,
    ],
    70: [
      649,
      650,
      652,
      653,
      655,
      656,
      658,
      659,
      660,
      661,
      662,
      663,
      664,
      665,
      666,
      667,
      668,
      669,
      669,
      670,
      670,
      671,
      671,
      671,
      671,
      671,
      672,
      671,
      671,
      671,
      670,
      670,
      669,
      669,
      668,
      667,
      666,
      665,
      664,
      662,
      661,
      659,
      658,
      656,
      654,
      652,
      650,
      648,
      645,
      643,
      640,
      638,
      635,
      632,
      629,
      626,
      622,
      619,
      616,
      612,
      609,
      605,
      601,
      597,
      593,
      589,
      585,
      580,
      576,
      571,
      567,
      562,
      557,
      552,
      547,
      542,
      537,
      531,
      526,
      521,
      516,
      511,
      505,
      499,
      493,
      488,
      482,
      476,
      470,
      464,
      458,
    ],
    75: [
      649,
      650,
      651,
      652,
      653,
      654,
      655,
      656,
      657,
      657,
      658,
      659,
      659,
      660,
      660,
      661,
      661,
      662,
      662,
      662,
      662,
      662,
      662,
      662,
      662,
      662,
      662,
      661,
      661,
      660,
      660,
      659,
      658,
      657,
      656,
      655,
      654,
      652,
      651,
      650,
      648,
      646,
      644,
      642,
      640,
      638,
      636,
      634,
      631,
      629,
      626,
      623,
      620,
      617,
      614,
      611,
      608,
      604,
      601,
      597,
      593,
      590,
      586,
      582,
      578,
      573,
      569,
      565,
      561,
      556,
      551,
      547,
      542,
      537,
      532,
      527,
      522,
      517,
      511,
      506,
      501,
      496,
      490,
      484,
      479,
      473,
      468,
      462,
      456,
      450,
      444,
    ],
    80: [
      649,
      649,
      650,
      651,
      651,
      652,
      652,
      653,
      653,
      653,
      654,
      654,
      654,
      654,
      654,
      654,
      655,
      654,
      654,
      654,
      654,
      654,
      654,
      653,
      653,
      652,
      652,
      651,
      650,
      650,
      649,
      647,
      646,
      645,
      644,
      643,
      641,
      640,
      638,
      636,
      635,
      633,
      631,
      629,
      626,
      624,
      622,
      619,
      617,
      614,
      611,
      608,
      605,
      602,
      599,
      596,
      592,
      589,
      585,
      582,
      578,
      574,
      570,
      566,
      562,
      558,
      553,
      549,
      545,
      540,
      536,
      531,
      526,
      521,
      516,
      511,
      507,
      501,
      496,
      491,
      486,
      480,
      475,
      469,
      464,
      459,
      453,
      447,
      441,
      435,
      430,
    ],
    85: [
      649,
      649,
      649,
      649,
      649,
      649,
      650,
      649,
      649,
      649,
      649,
      649,
      649,
      649,
      648,
      648,
      648,
      647,
      647,
      646,
      646,
      645,
      644,
      644,
      643,
      642,
      641,
      640,
      639,
      638,
      637,
      636,
      634,
      633,
      632,
      630,
      628,
      627,
      625,
      623,
      621,
      619,
      617,
      614,
      612,
      609,
      607,
      604,
      602,
      599,
      596,
      593,
      590,
      587,
      583,
      580,
      576,
      573,
      569,
      566,
      562,
      558,
      554,
      550,
      546,
      542,
      537,
      533,
      528,
      524,
      519,
      515,
      510,
      505,
      500,
      495,
      491,
      485,
      480,
      475,
      470,
      465,
      460,
      454,
      448,
      443,
      438,
      433,
      426,
      420,
      415,
    ],
    90: [
      649,
      648,
      648,
      648,
      647,
      647,
      647,
      646,
      646,
      645,
      645,
      644,
      643,
      643,
      642,
      641,
      640,
      640,
      639,
      638,
      637,
      636,
      635,
      634,
      633,
      632,
      631,
      629,
      628,
      627,
      625,
      624,
      622,
      620,
      619,
      617,
      615,
      613,
      611,
      609,
      607,
      605,
      602,
      600,
      597,
      595,
      592,
      589,
      586,
      583,
      580,
      577,
      574,
      571,
      567,
      564,
      560,
      557,
      553,
      549,
      545,
      541,
      537,
      533,
      529,
      525,
      521,
      516,
      512,
      507,
      503,
      498,
      493,
      489,
      484,
      479,
      474,
      469,
      464,
      459,
      454,
      449,
      444,
      438,
      433,
      428,
      422,
      417,
      411,
      406,
      400,
    ],
    95: [
      649,
      648,
      647,
      646,
      646,
      645,
      644,
      643,
      642,
      641,
      640,
      639,
      638,
      637,
      636,
      634,
      633,
      632,
      631,
      630,
      628,
      627,
      626,
      624,
      623,
      621,
      620,
      618,
      617,
      615,
      613,
      612,
      610,
      608,
      606,
      604,
      602,
      599,
      597,
      595,
      592,
      590,
      587,
      585,
      582,
      579,
      577,
      574,
      571,
      568,
      564,
      561,
      558,
      554,
      551,
      548,
      544,
      540,
      536,
      533,
      529,
      525,
      521,
      517,
      512,
      508,
      504,
      500,
      495,
      491,
      486,
      481,
      477,
      472,
      467,
      463,
      458,
      453,
      448,
      443,
      438,
      433,
      428,
      422,
      417,
      412,
      407,
      402,
      396,
      390,
      385,
    ],
    100: [
      649,
      647,
      646,
      645,
      644,
      643,
      641,
      640,
      639,
      637,
      636,
      634,
      632,
      631,
      629,
      628,
      626,
      624,
      623,
      621,
      620,
      618,
      616,
      614,
      613,
      611,
      609,
      607,
      605,
      603,
      601,
      599,
      597,
      595,
      593,
      590,
      588,
      586,
      583,
      581,
      578,
      575,
      573,
      570,
      567,
      564,
      561,
      558,
      555,
      552,
      548,
      545,
      542,
      538,
      535,
      531,
      527,
      524,
      520,
      516,
      512,
      508,
      504,
      500,
      495,
      491,
      487,
      482,
      478,
      474,
      469,
      464,
      460,
      455,
      451,
      446,
      441,
      436,
      431,
      426,
      421,
      416,
      411,
      406,
      401,
      396,
      391,
      386,
      381,
      375,
      370,
    ],
    105: [
      649,
      647,
      645,
      644,
      642,
      640,
      639,
      637,
      635,
      633,
      631,
      629,
      627,
      625,
      623,
      621,
      619,
      617,
      615,
      613,
      611,
      609,
      607,
      605,
      602,
      600,
      598,
      596,
      594,
      591,
      589,
      587,
      584,
      582,
      579,
      577,
      574,
      572,
      569,
      566,
      564,
      561,
      558,
      555,
      552,
      549,
      546,
      542,
      539,
      536,
      532,
      529,
      525,
      522,
      518,
      514,
      511,
      507,
      503,
      499,
      495,
      491,
      487,
      483,
      478,
      474,
      470,
      466,
      461,
      457,
      452,
      448,
      443,
      438,
      434,
      429,
      424,
      420,
      414,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      375,
      370,
      365,
      360,
      355,
    ],
    110: [
      649,
      646,
      644,
      642,
      640,
      638,
      636,
      634,
      631,
      629,
      627,
      624,
      622,
      619,
      617,
      614,
      612,
      609,
      607,
      604,
      602,
      600,
      597,
      595,
      592,
      590,
      587,
      585,
      582,
      580,
      577,
      574,
      572,
      569,
      566,
      563,
      561,
      558,
      555,
      552,
      549,
      546,
      543,
      540,
      537,
      533,
      530,
      527,
      523,
      520,
      516,
      513,
      509,
      505,
      502,
      498,
      494,
      490,
      486,
      482,
      478,
      474,
      470,
      466,
      462,
      457,
      453,
      449,
      444,
      440,
      435,
      431,
      426,
      422,
      417,
      412,
      408,
      403,
      398,
      394,
      389,
      384,
      379,
      374,
      369,
      364,
      359,
      355,
      350,
      345,
      340,
    ],
    115: [
      649,
      646,
      644,
      641,
      639,
      636,
      633,
      631,
      628,
      625,
      622,
      620,
      617,
      614,
      611,
      608,
      605,
      602,
      599,
      596,
      594,
      591,
      588,
      585,
      582,
      579,
      576,
      574,
      571,
      568,
      565,
      562,
      559,
      556,
      553,
      550,
      547,
      544,
      541,
      538,
      535,
      531,
      528,
      525,
      521,
      518,
      515,
      511,
      508,
      504,
      500,
      497,
      493,
      489,
      485,
      482,
      478,
      474,
      470,
      466,
      462,
      458,
      453,
      449,
      445,
      441,
      436,
      432,
      428,
      423,
      419,
      414,
      410,
      405,
      401,
      396,
      391,
      387,
      382,
      377,
      373,
      368,
      363,
      358,
      354,
      349,
      344,
      339,
      335,
      330,
      325,
    ],
    120: [
      649,
      646,
      643,
      640,
      637,
      634,
      631,
      628,
      625,
      621,
      618,
      615,
      612,
      608,
      605,
      602,
      599,
      595,
      592,
      589,
      585,
      582,
      579,
      576,
      572,
      569,
      566,
      563,
      559,
      556,
      553,
      550,
      547,
      543,
      540,
      537,
      534,
      530,
      527,
      524,
      520,
      517,
      513,
      510,
      507,
      503,
      499,
      496,
      492,
      488,
      485,
      481,
      477,
      473,
      469,
      465,
      461,
      458,
      454,
      449,
      445,
      441,
      437,
      433,
      429,
      424,
      420,
      416,
      411,
      407,
      402,
      398,
      393,
      389,
      385,
      380,
      375,
      371,
      366,
      362,
      357,
      352,
      348,
      343,
      339,
      334,
      329,
      324,
      320,
      315,
      311,
    ],
    125: [
      649,
      645,
      642,
      639,
      635,
      632,
      629,
      625,
      622,
      618,
      614,
      611,
      607,
      603,
      600,
      596,
      592,
      589,
      585,
      581,
      578,
      574,
      570,
      567,
      563,
      559,
      556,
      552,
      549,
      545,
      542,
      538,
      535,
      531,
      528,
      524,
      521,
      517,
      514,
      510,
      506,
      503,
      499,
      496,
      492,
      488,
      484,
      481,
      477,
      473,
      469,
      465,
      462,
      458,
      454,
      450,
      446,
      442,
      438,
      433,
      429,
      425,
      421,
      417,
      412,
      408,
      404,
      400,
      395,
      391,
      386,
      382,
      378,
      373,
      369,
      364,
      360,
      355,
      351,
      346,
      342,
      337,
      333,
      328,
      324,
      319,
      315,
      310,
      305,
      301,
      297,
    ],
    130: [
      649,
      645,
      641,
      638,
      634,
      630,
      626,
      623,
      619,
      615,
      611,
      607,
      603,
      599,
      595,
      591,
      587,
      582,
      578,
      574,
      570,
      566,
      562,
      558,
      554,
      550,
      546,
      542,
      538,
      534,
      531,
      527,
      523,
      519,
      515,
      512,
      508,
      504,
      500,
      497,
      493,
      489,
      485,
      481,
      478,
      474,
      470,
      466,
      462,
      458,
      454,
      450,
      446,
      442,
      438,
      434,
      430,
      426,
      422,
      418,
      414,
      410,
      405,
      401,
      397,
      392,
      388,
      384,
      380,
      375,
      371,
      367,
      362,
      358,
      353,
      349,
      345,
      340,
      336,
      332,
      327,
      323,
      318,
      314,
      309,
      305,
      300,
      296,
      292,
      288,
      283,
    ],
    135: [
      649,
      644,
      641,
      637,
      633,
      628,
      624,
      620,
      616,
      612,
      607,
      603,
      599,
      594,
      590,
      586,
      581,
      577,
      572,
      568,
      564,
      559,
      555,
      550,
      546,
      542,
      537,
      533,
      529,
      525,
      520,
      516,
      512,
      508,
      504,
      500,
      496,
      492,
      488,
      484,
      480,
      476,
      472,
      468,
      464,
      460,
      456,
      452,
      448,
      444,
      440,
      436,
      432,
      428,
      423,
      419,
      415,
      411,
      407,
      403,
      399,
      394,
      390,
      386,
      382,
      377,
      373,
      369,
      365,
      360,
      356,
      352,
      348,
      343,
      339,
      335,
      330,
      326,
      321,
      317,
      313,
      309,
      304,
      300,
      296,
      292,
      287,
      283,
      279,
      274,
      270,
    ],
    140: [
      649,
      644,
      640,
      636,
      631,
      627,
      622,
      618,
      613,
      609,
      604,
      600,
      595,
      590,
      586,
      581,
      576,
      572,
      567,
      562,
      558,
      553,
      548,
      544,
      539,
      534,
      530,
      525,
      520,
      516,
      511,
      507,
      502,
      498,
      493,
      489,
      484,
      480,
      476,
      471,
      467,
      463,
      459,
      455,
      451,
      446,
      442,
      438,
      434,
      430,
      426,
      422,
      418,
      413,
      409,
      405,
      401,
      397,
      393,
      388,
      384,
      380,
      376,
      372,
      367,
      363,
      359,
      355,
      350,
      346,
      342,
      338,
      333,
      329,
      325,
      321,
      316,
      312,
      308,
      304,
      299,
      295,
      291,
      287,
      283,
      279,
      274,
      270,
      266,
      262,
      258,
    ],
    145: [
      649,
      644,
      639,
      635,
      630,
      626,
      621,
      616,
      611,
      606,
      601,
      597,
      592,
      587,
      582,
      577,
      572,
      567,
      562,
      557,
      552,
      547,
      543,
      538,
      533,
      528,
      523,
      518,
      513,
      508,
      503,
      498,
      494,
      489,
      484,
      479,
      474,
      470,
      465,
      460,
      456,
      451,
      447,
      443,
      438,
      434,
      430,
      425,
      421,
      417,
      413,
      408,
      404,
      400,
      396,
      392,
      388,
      383,
      379,
      375,
      371,
      366,
      362,
      358,
      354,
      350,
      346,
      341,
      337,
      333,
      329,
      325,
      320,
      316,
      312,
      308,
      304,
      300,
      295,
      291,
      287,
      283,
      279,
      275,
      271,
      267,
      263,
      259,
      255,
      250,
      247,
    ],
    150: [
      649,
      644,
      639,
      634,
      629,
      624,
      619,
      614,
      609,
      604,
      599,
      594,
      589,
      584,
      579,
      573,
      568,
      563,
      558,
      553,
      548,
      542,
      537,
      532,
      527,
      522,
      517,
      512,
      507,
      501,
      496,
      491,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      447,
      442,
      437,
      432,
      427,
      423,
      418,
      414,
      409,
      405,
      401,
      396,
      392,
      388,
      384,
      379,
      375,
      371,
      367,
      363,
      358,
      354,
      350,
      346,
      342,
      337,
      333,
      329,
      325,
      321,
      317,
      313,
      308,
      304,
      300,
      296,
      292,
      288,
      284,
      280,
      276,
      272,
      268,
      264,
      260,
      256,
      252,
      248,
      244,
      240,
      236,
    ],
    155: [
      649,
      643,
      638,
      633,
      628,
      623,
      618,
      613,
      607,
      602,
      597,
      592,
      586,
      581,
      576,
      570,
      565,
      560,
      554,
      549,
      544,
      538,
      533,
      528,
      522,
      517,
      512,
      506,
      501,
      496,
      491,
      485,
      480,
      475,
      470,
      464,
      459,
      454,
      449,
      444,
      439,
      434,
      429,
      424,
      419,
      414,
      409,
      404,
      399,
      395,
      390,
      386,
      381,
      377,
      373,
      368,
      364,
      360,
      356,
      351,
      347,
      343,
      339,
      335,
      331,
      327,
      322,
      318,
      314,
      310,
      306,
      302,
      298,
      294,
      290,
      286,
      282,
      278,
      274,
      270,
      266,
      262,
      258,
      254,
      250,
      246,
      243,
      239,
      235,
      231,
      227,
    ],
    160: [
      649,
      643,
      638,
      633,
      628,
      622,
      617,
      611,
      606,
      601,
      595,
      590,
      584,
      579,
      573,
      568,
      562,
      557,
      551,
      546,
      540,
      535,
      529,
      524,
      518,
      513,
      507,
      502,
      497,
      491,
      486,
      480,
      475,
      470,
      464,
      459,
      454,
      448,
      443,
      438,
      432,
      427,
      422,
      417,
      412,
      406,
      402,
      397,
      392,
      387,
      382,
      377,
      372,
      368,
      364,
      359,
      355,
      351,
      346,
      342,
      338,
      334,
      330,
      325,
      321,
      317,
      313,
      309,
      305,
      301,
      297,
      293,
      289,
      285,
      281,
      277,
      273,
      269,
      265,
      261,
      257,
      254,
      250,
      246,
      242,
      238,
      235,
      231,
      227,
      224,
      220,
    ],
    165: [
      649,
      643,
      638,
      632,
      627,
      622,
      616,
      610,
      605,
      599,
      594,
      588,
      583,
      577,
      572,
      566,
      560,
      554,
      549,
      543,
      538,
      532,
      527,
      521,
      515,
      510,
      504,
      499,
      493,
      488,
      482,
      477,
      471,
      465,
      460,
      454,
      449,
      444,
      438,
      433,
      427,
      422,
      417,
      412,
      406,
      401,
      396,
      391,
      386,
      381,
      376,
      371,
      366,
      361,
      356,
      352,
      347,
      343,
      339,
      335,
      330,
      326,
      322,
      318,
      314,
      310,
      306,
      302,
      298,
      293,
      289,
      286,
      282,
      278,
      274,
      270,
      266,
      262,
      258,
      254,
      251,
      247,
      243,
      239,
      236,
      232,
      228,
      224,
      221,
      217,
      214,
    ],
    170: [
      649,
      643,
      638,
      632,
      627,
      621,
      615,
      610,
      604,
      598,
      593,
      587,
      581,
      576,
      570,
      564,
      559,
      553,
      547,
      542,
      536,
      530,
      524,
      519,
      513,
      508,
      502,
      496,
      490,
      485,
      479,
      474,
      468,
      463,
      457,
      452,
      446,
      440,
      435,
      429,
      424,
      419,
      413,
      408,
      402,
      397,
      392,
      387,
      381,
      376,
      371,
      366,
      361,
      356,
      351,
      347,
      342,
      337,
      333,
      329,
      325,
      321,
      316,
      312,
      308,
      304,
      300,
      296,
      292,
      288,
      284,
      280,
      276,
      273,
      269,
      265,
      261,
      257,
      253,
      249,
      246,
      242,
      238,
      234,
      231,
      227,
      224,
      220,
      217,
      213,
      209,
    ],
    175: [
      649,
      643,
      638,
      632,
      626,
      621,
      615,
      609,
      604,
      598,
      592,
      587,
      581,
      575,
      569,
      564,
      558,
      552,
      546,
      541,
      535,
      529,
      523,
      518,
      512,
      506,
      500,
      494,
      489,
      483,
      477,
      472,
      466,
      461,
      455,
      450,
      444,
      439,
      433,
      427,
      422,
      416,
      411,
      406,
      400,
      395,
      389,
      384,
      379,
      374,
      368,
      363,
      358,
      353,
      348,
      343,
      339,
      334,
      330,
      326,
      321,
      317,
      313,
      309,
      305,
      301,
      297,
      293,
      289,
      285,
      281,
      277,
      273,
      269,
      265,
      262,
      258,
      254,
      250,
      247,
      243,
      239,
      235,
      231,
      228,
      224,
      221,
      217,
      214,
      210,
      207,
    ],
  },
  '20': {
    0: [
      630,
      635,
      640,
      645,
      649,
      654,
      658,
      663,
      667,
      671,
      676,
      680,
      683,
      687,
      691,
      694,
      698,
      701,
      704,
      707,
      710,
      713,
      715,
      718,
      720,
      722,
      724,
      726,
      728,
      729,
      731,
      732,
      733,
      734,
      735,
      736,
      736,
      736,
      737,
      737,
      737,
      736,
      736,
      735,
      735,
      734,
      733,
      732,
      730,
      729,
      727,
      725,
      723,
      721,
      719,
      717,
      714,
      711,
      708,
      705,
      702,
      699,
      696,
      692,
      688,
      684,
      680,
      676,
      672,
      667,
      663,
      658,
      653,
      648,
      643,
      637,
      632,
      626,
      621,
      615,
      609,
      603,
      597,
      590,
      584,
      578,
      571,
      564,
      558,
      551,
      544,
    ],
    5: [
      630,
      635,
      640,
      644,
      649,
      654,
      658,
      663,
      667,
      671,
      675,
      679,
      683,
      687,
      691,
      694,
      697,
      701,
      704,
      707,
      710,
      712,
      715,
      717,
      720,
      722,
      724,
      726,
      727,
      729,
      730,
      732,
      733,
      734,
      734,
      735,
      736,
      736,
      736,
      736,
      736,
      736,
      735,
      735,
      734,
      733,
      732,
      731,
      730,
      728,
      727,
      725,
      723,
      721,
      718,
      716,
      713,
      711,
      708,
      705,
      702,
      698,
      695,
      691,
      688,
      684,
      680,
      675,
      671,
      667,
      662,
      657,
      652,
      647,
      642,
      637,
      632,
      626,
      620,
      614,
      608,
      602,
      596,
      590,
      584,
      577,
      571,
      564,
      557,
      550,
      543,
    ],
    10: [
      630,
      635,
      640,
      644,
      649,
      654,
      658,
      662,
      667,
      671,
      675,
      679,
      683,
      686,
      690,
      693,
      697,
      700,
      703,
      706,
      709,
      711,
      714,
      716,
      718,
      721,
      723,
      724,
      726,
      728,
      729,
      730,
      731,
      732,
      733,
      734,
      734,
      734,
      735,
      735,
      735,
      734,
      734,
      733,
      733,
      732,
      731,
      729,
      728,
      727,
      725,
      723,
      721,
      719,
      717,
      714,
      712,
      709,
      706,
      703,
      700,
      697,
      693,
      689,
      686,
      682,
      678,
      674,
      669,
      665,
      660,
      655,
      651,
      646,
      640,
      635,
      630,
      624,
      619,
      613,
      607,
      601,
      595,
      589,
      582,
      576,
      569,
      562,
      556,
      549,
      542,
    ],
    15: [
      630,
      635,
      639,
      644,
      649,
      653,
      657,
      662,
      666,
      670,
      674,
      678,
      682,
      685,
      689,
      692,
      695,
      698,
      701,
      704,
      707,
      710,
      712,
      714,
      717,
      719,
      721,
      722,
      724,
      726,
      727,
      728,
      729,
      730,
      731,
      731,
      732,
      732,
      732,
      732,
      732,
      732,
      731,
      731,
      730,
      729,
      728,
      727,
      725,
      724,
      722,
      720,
      718,
      716,
      714,
      712,
      709,
      706,
      703,
      700,
      697,
      694,
      690,
      686,
      683,
      679,
      675,
      671,
      667,
      662,
      657,
      653,
      648,
      643,
      638,
      632,
      627,
      621,
      616,
      610,
      604,
      598,
      592,
      586,
      580,
      573,
      567,
      560,
      553,
      546,
      539,
    ],
    20: [
      630,
      634,
      639,
      644,
      648,
      652,
      657,
      661,
      665,
      669,
      673,
      676,
      680,
      684,
      687,
      690,
      693,
      696,
      699,
      702,
      705,
      707,
      710,
      712,
      714,
      716,
      718,
      720,
      721,
      723,
      724,
      725,
      726,
      727,
      728,
      728,
      728,
      729,
      729,
      729,
      729,
      728,
      728,
      727,
      726,
      725,
      724,
      723,
      722,
      720,
      718,
      717,
      715,
      712,
      710,
      708,
      705,
      702,
      699,
      696,
      693,
      690,
      686,
      683,
      679,
      675,
      671,
      667,
      662,
      658,
      653,
      649,
      644,
      639,
      634,
      629,
      623,
      618,
      612,
      607,
      601,
      595,
      588,
      582,
      576,
      570,
      563,
      556,
      550,
      543,
      536,
    ],
    25: [
      630,
      634,
      639,
      643,
      647,
      652,
      656,
      660,
      664,
      667,
      671,
      675,
      678,
      682,
      685,
      688,
      691,
      694,
      697,
      699,
      702,
      704,
      707,
      709,
      711,
      713,
      715,
      716,
      718,
      719,
      720,
      721,
      722,
      723,
      723,
      724,
      724,
      724,
      725,
      724,
      724,
      724,
      723,
      722,
      722,
      721,
      720,
      718,
      717,
      715,
      713,
      712,
      710,
      707,
      705,
      702,
      700,
      697,
      694,
      691,
      688,
      685,
      681,
      678,
      674,
      670,
      666,
      662,
      657,
      653,
      648,
      644,
      639,
      634,
      629,
      624,
      618,
      613,
      607,
      601,
      596,
      590,
      584,
      578,
      571,
      565,
      559,
      552,
      545,
      539,
      532,
    ],
    30: [
      630,
      634,
      638,
      643,
      647,
      651,
      655,
      658,
      662,
      666,
      669,
      673,
      676,
      679,
      682,
      685,
      688,
      691,
      694,
      696,
      699,
      701,
      703,
      705,
      707,
      709,
      710,
      712,
      713,
      714,
      715,
      716,
      717,
      718,
      718,
      719,
      719,
      719,
      719,
      719,
      719,
      718,
      718,
      717,
      716,
      715,
      714,
      712,
      711,
      709,
      707,
      706,
      703,
      701,
      699,
      696,
      694,
      691,
      688,
      685,
      682,
      678,
      675,
      671,
      668,
      664,
      660,
      655,
      651,
      647,
      642,
      638,
      633,
      628,
      623,
      617,
      612,
      607,
      601,
      595,
      590,
      584,
      578,
      572,
      566,
      559,
      553,
      547,
      540,
      533,
      527,
    ],
    35: [
      630,
      634,
      638,
      642,
      646,
      649,
      653,
      657,
      660,
      664,
      667,
      670,
      673,
      676,
      679,
      682,
      685,
      687,
      690,
      692,
      694,
      697,
      699,
      700,
      702,
      704,
      705,
      707,
      708,
      709,
      710,
      711,
      712,
      712,
      713,
      713,
      713,
      713,
      713,
      713,
      712,
      712,
      711,
      710,
      709,
      708,
      707,
      705,
      704,
      702,
      700,
      698,
      696,
      694,
      692,
      689,
      686,
      684,
      681,
      678,
      674,
      671,
      667,
      664,
      660,
      656,
      652,
      648,
      644,
      639,
      635,
      630,
      625,
      620,
      615,
      610,
      605,
      600,
      594,
      588,
      583,
      577,
      571,
      565,
      559,
      553,
      547,
      540,
      533,
      527,
      520,
    ],
    40: [
      630,
      634,
      637,
      641,
      645,
      648,
      652,
      655,
      658,
      661,
      664,
      667,
      670,
      673,
      676,
      678,
      681,
      683,
      686,
      688,
      690,
      692,
      694,
      695,
      697,
      698,
      700,
      701,
      702,
      703,
      704,
      705,
      705,
      706,
      706,
      706,
      706,
      706,
      706,
      706,
      705,
      704,
      704,
      703,
      702,
      700,
      699,
      698,
      696,
      694,
      692,
      690,
      688,
      686,
      683,
      681,
      678,
      675,
      672,
      669,
      666,
      662,
      659,
      655,
      652,
      648,
      643,
      639,
      635,
      631,
      626,
      622,
      617,
      612,
      607,
      602,
      597,
      591,
      586,
      580,
      575,
      569,
      563,
      557,
      551,
      545,
      539,
      532,
      526,
      519,
      513,
    ],
    45: [
      630,
      633,
      637,
      640,
      643,
      647,
      650,
      653,
      656,
      659,
      662,
      664,
      667,
      670,
      672,
      674,
      677,
      679,
      681,
      683,
      685,
      686,
      688,
      690,
      691,
      692,
      694,
      695,
      696,
      696,
      697,
      697,
      698,
      698,
      699,
      699,
      698,
      698,
      698,
      697,
      697,
      696,
      695,
      694,
      693,
      692,
      690,
      689,
      687,
      685,
      683,
      681,
      679,
      677,
      674,
      671,
      669,
      666,
      663,
      660,
      656,
      653,
      649,
      646,
      642,
      638,
      634,
      630,
      626,
      621,
      617,
      612,
      607,
      602,
      597,
      592,
      587,
      582,
      577,
      571,
      566,
      560,
      554,
      548,
      542,
      536,
      530,
      524,
      517,
      511,
      505,
    ],
    50: [
      630,
      633,
      636,
      639,
      642,
      645,
      648,
      651,
      653,
      656,
      658,
      661,
      663,
      666,
      668,
      670,
      672,
      674,
      676,
      677,
      679,
      681,
      682,
      683,
      685,
      686,
      687,
      688,
      688,
      689,
      689,
      690,
      690,
      690,
      690,
      690,
      690,
      690,
      689,
      688,
      688,
      687,
      686,
      685,
      683,
      682,
      681,
      679,
      677,
      675,
      673,
      671,
      669,
      666,
      664,
      661,
      658,
      655,
      652,
      649,
      646,
      642,
      639,
      635,
      631,
      627,
      623,
      619,
      615,
      611,
      606,
      602,
      596,
      592,
      587,
      582,
      577,
      571,
      566,
      561,
      555,
      550,
      544,
      538,
      532,
      526,
      520,
      514,
      508,
      502,
      495,
    ],
    55: [
      630,
      633,
      635,
      638,
      641,
      643,
      646,
      648,
      651,
      653,
      655,
      657,
      659,
      661,
      663,
      665,
      667,
      669,
      670,
      672,
      673,
      674,
      675,
      676,
      678,
      678,
      679,
      680,
      680,
      681,
      681,
      681,
      681,
      681,
      681,
      681,
      681,
      680,
      679,
      679,
      678,
      677,
      676,
      674,
      673,
      672,
      670,
      668,
      666,
      664,
      662,
      660,
      658,
      655,
      652,
      650,
      647,
      644,
      641,
      638,
      634,
      631,
      627,
      623,
      620,
      616,
      612,
      607,
      603,
      599,
      595,
      590,
      585,
      580,
      575,
      570,
      565,
      560,
      555,
      549,
      544,
      538,
      533,
      527,
      521,
      515,
      509,
      503,
      497,
      491,
      485,
    ],
    60: [
      630,
      632,
      635,
      637,
      639,
      641,
      644,
      646,
      648,
      650,
      651,
      653,
      655,
      657,
      658,
      660,
      661,
      663,
      664,
      665,
      666,
      667,
      668,
      669,
      670,
      671,
      671,
      672,
      672,
      672,
      672,
      672,
      672,
      672,
      672,
      671,
      671,
      670,
      669,
      668,
      667,
      666,
      665,
      663,
      662,
      660,
      659,
      657,
      655,
      653,
      650,
      648,
      646,
      643,
      640,
      637,
      635,
      632,
      628,
      625,
      622,
      618,
      615,
      611,
      607,
      603,
      599,
      595,
      590,
      586,
      582,
      577,
      572,
      568,
      563,
      558,
      553,
      548,
      542,
      537,
      532,
      526,
      521,
      515,
      509,
      504,
      498,
      492,
      486,
      480,
      473,
    ],
    65: [
      630,
      632,
      634,
      636,
      638,
      640,
      641,
      643,
      645,
      646,
      648,
      649,
      651,
      652,
      653,
      654,
      656,
      657,
      658,
      658,
      659,
      660,
      661,
      661,
      662,
      662,
      663,
      663,
      663,
      663,
      663,
      663,
      662,
      662,
      661,
      661,
      660,
      659,
      658,
      657,
      656,
      655,
      653,
      652,
      650,
      648,
      646,
      645,
      642,
      640,
      638,
      635,
      633,
      630,
      627,
      624,
      622,
      618,
      615,
      612,
      608,
      605,
      601,
      597,
      594,
      590,
      586,
      581,
      577,
      573,
      568,
      564,
      559,
      554,
      550,
      545,
      540,
      534,
      529,
      524,
      519,
      514,
      508,
      502,
      497,
      491,
      485,
      479,
      474,
      468,
      462,
    ],
    70: [
      630,
      631,
      633,
      635,
      636,
      637,
      639,
      640,
      641,
      643,
      644,
      645,
      646,
      647,
      648,
      649,
      649,
      650,
      651,
      651,
      652,
      652,
      653,
      653,
      653,
      653,
      654,
      653,
      653,
      653,
      653,
      652,
      652,
      651,
      651,
      650,
      649,
      648,
      647,
      645,
      644,
      643,
      641,
      639,
      638,
      636,
      634,
      632,
      629,
      627,
      625,
      622,
      619,
      617,
      614,
      611,
      608,
      605,
      601,
      598,
      594,
      591,
      587,
      583,
      579,
      576,
      572,
      567,
      563,
      559,
      554,
      550,
      545,
      540,
      536,
      531,
      526,
      520,
      515,
      510,
      505,
      500,
      494,
      489,
      483,
      478,
      472,
      466,
      461,
      455,
      449,
    ],
    75: [
      630,
      631,
      632,
      633,
      634,
      635,
      636,
      637,
      638,
      639,
      640,
      640,
      641,
      642,
      642,
      643,
      643,
      643,
      644,
      644,
      644,
      644,
      645,
      644,
      644,
      644,
      644,
      644,
      643,
      643,
      642,
      642,
      641,
      640,
      639,
      638,
      637,
      636,
      635,
      633,
      632,
      630,
      628,
      626,
      625,
      623,
      620,
      618,
      616,
      613,
      611,
      608,
      605,
      603,
      600,
      597,
      594,
      590,
      587,
      583,
      580,
      576,
      573,
      569,
      565,
      561,
      557,
      553,
      548,
      544,
      540,
      535,
      530,
      526,
      521,
      516,
      511,
      506,
      501,
      496,
      491,
      486,
      480,
      475,
      469,
      464,
      459,
      453,
      447,
      442,
      436,
    ],
    80: [
      630,
      631,
      631,
      632,
      633,
      633,
      634,
      634,
      635,
      635,
      635,
      636,
      636,
      636,
      636,
      636,
      636,
      636,
      637,
      636,
      636,
      636,
      636,
      636,
      635,
      635,
      634,
      634,
      633,
      632,
      631,
      631,
      630,
      629,
      627,
      626,
      625,
      623,
      622,
      620,
      619,
      617,
      615,
      613,
      611,
      609,
      607,
      604,
      602,
      599,
      597,
      594,
      591,
      588,
      585,
      582,
      579,
      575,
      572,
      568,
      565,
      561,
      557,
      554,
      550,
      546,
      541,
      537,
      533,
      529,
      524,
      520,
      515,
      511,
      506,
      501,
      496,
      491,
      486,
      481,
      476,
      471,
      466,
      460,
      455,
      450,
      445,
      439,
      433,
      427,
      422,
    ],
    85: [
      630,
      630,
      630,
      631,
      631,
      631,
      631,
      631,
      631,
      631,
      631,
      631,
      631,
      630,
      630,
      630,
      630,
      629,
      629,
      628,
      628,
      628,
      627,
      627,
      626,
      625,
      624,
      623,
      622,
      621,
      620,
      619,
      618,
      617,
      615,
      614,
      612,
      611,
      609,
      607,
      605,
      603,
      601,
      599,
      597,
      595,
      592,
      590,
      587,
      585,
      582,
      579,
      576,
      573,
      570,
      567,
      563,
      560,
      556,
      553,
      549,
      546,
      542,
      538,
      534,
      530,
      526,
      522,
      517,
      513,
      509,
      504,
      500,
      495,
      490,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      451,
      445,
      440,
      435,
      430,
      425,
      418,
      413,
      408,
    ],
    90: [
      630,
      630,
      630,
      629,
      629,
      629,
      629,
      628,
      628,
      627,
      627,
      626,
      625,
      625,
      624,
      623,
      623,
      622,
      621,
      620,
      620,
      619,
      618,
      617,
      616,
      615,
      614,
      613,
      611,
      610,
      609,
      608,
      606,
      605,
      603,
      601,
      600,
      598,
      596,
      594,
      592,
      590,
      587,
      585,
      583,
      580,
      578,
      575,
      572,
      570,
      567,
      564,
      561,
      558,
      554,
      551,
      548,
      544,
      541,
      537,
      533,
      530,
      526,
      522,
      518,
      514,
      510,
      506,
      501,
      497,
      493,
      488,
      483,
      479,
      474,
      470,
      465,
      460,
      455,
      450,
      445,
      441,
      436,
      430,
      425,
      420,
      415,
      410,
      404,
      399,
      394,
    ],
    95: [
      630,
      629,
      629,
      628,
      627,
      627,
      626,
      625,
      624,
      623,
      622,
      621,
      620,
      619,
      618,
      617,
      616,
      615,
      614,
      612,
      611,
      610,
      609,
      607,
      606,
      605,
      603,
      602,
      600,
      599,
      597,
      596,
      594,
      592,
      590,
      588,
      586,
      584,
      582,
      580,
      578,
      576,
      573,
      571,
      568,
      566,
      563,
      560,
      557,
      554,
      551,
      548,
      545,
      542,
      539,
      535,
      532,
      528,
      525,
      521,
      517,
      513,
      510,
      506,
      502,
      498,
      493,
      489,
      485,
      481,
      476,
      472,
      467,
      463,
      458,
      454,
      449,
      444,
      439,
      435,
      430,
      425,
      420,
      415,
      410,
      405,
      400,
      395,
      389,
      384,
      379,
    ],
    100: [
      630,
      629,
      628,
      627,
      626,
      624,
      623,
      622,
      621,
      619,
      618,
      616,
      615,
      613,
      612,
      610,
      609,
      607,
      606,
      604,
      603,
      601,
      599,
      598,
      596,
      595,
      593,
      591,
      589,
      588,
      586,
      584,
      582,
      580,
      578,
      575,
      573,
      571,
      569,
      566,
      564,
      561,
      559,
      556,
      553,
      551,
      548,
      545,
      542,
      539,
      536,
      533,
      529,
      526,
      523,
      519,
      516,
      512,
      508,
      505,
      501,
      497,
      493,
      489,
      485,
      481,
      477,
      473,
      469,
      464,
      460,
      455,
      451,
      446,
      442,
      438,
      433,
      428,
      423,
      419,
      414,
      409,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      369,
      364,
    ],
    105: [
      630,
      628,
      627,
      625,
      624,
      622,
      621,
      619,
      617,
      615,
      613,
      612,
      610,
      608,
      606,
      604,
      602,
      600,
      598,
      596,
      594,
      592,
      590,
      588,
      586,
      584,
      582,
      580,
      578,
      576,
      574,
      572,
      569,
      567,
      565,
      562,
      560,
      557,
      555,
      552,
      550,
      547,
      544,
      542,
      539,
      536,
      533,
      530,
      527,
      523,
      520,
      517,
      514,
      510,
      507,
      503,
      499,
      496,
      492,
      488,
      484,
      481,
      477,
      473,
      469,
      465,
      460,
      456,
      452,
      448,
      443,
      439,
      435,
      430,
      426,
      421,
      417,
      412,
      407,
      403,
      398,
      393,
      388,
      384,
      379,
      374,
      369,
      364,
      359,
      354,
      349,
    ],
    110: [
      630,
      628,
      626,
      624,
      622,
      620,
      618,
      616,
      614,
      611,
      609,
      607,
      604,
      602,
      600,
      597,
      595,
      593,
      590,
      588,
      586,
      583,
      581,
      579,
      576,
      574,
      572,
      569,
      567,
      564,
      562,
      559,
      557,
      554,
      552,
      549,
      547,
      544,
      541,
      538,
      536,
      533,
      530,
      527,
      524,
      521,
      518,
      514,
      511,
      508,
      505,
      501,
      498,
      494,
      491,
      487,
      483,
      480,
      476,
      472,
      468,
      464,
      460,
      456,
      452,
      448,
      444,
      440,
      436,
      431,
      427,
      423,
      418,
      414,
      409,
      405,
      400,
      396,
      391,
      387,
      382,
      377,
      373,
      368,
      363,
      358,
      354,
      349,
      344,
      339,
      334,
    ],
    115: [
      630,
      627,
      625,
      623,
      620,
      618,
      615,
      613,
      610,
      608,
      605,
      602,
      599,
      597,
      594,
      591,
      589,
      586,
      583,
      580,
      577,
      575,
      572,
      569,
      566,
      564,
      561,
      558,
      556,
      553,
      550,
      547,
      545,
      542,
      539,
      536,
      533,
      530,
      527,
      524,
      521,
      518,
      515,
      512,
      509,
      506,
      503,
      499,
      496,
      492,
      489,
      485,
      482,
      478,
      475,
      471,
      467,
      463,
      460,
      456,
      452,
      448,
      444,
      440,
      436,
      432,
      428,
      424,
      419,
      415,
      411,
      406,
      402,
      398,
      393,
      389,
      384,
      380,
      375,
      371,
      366,
      362,
      357,
      353,
      348,
      344,
      339,
      334,
      330,
      325,
      320,
    ],
    120: [
      630,
      627,
      624,
      622,
      619,
      616,
      613,
      610,
      607,
      604,
      601,
      598,
      595,
      592,
      588,
      585,
      582,
      579,
      576,
      573,
      569,
      566,
      563,
      560,
      557,
      554,
      551,
      548,
      545,
      542,
      539,
      536,
      532,
      529,
      526,
      523,
      520,
      517,
      514,
      511,
      507,
      504,
      501,
      498,
      494,
      491,
      488,
      484,
      481,
      477,
      474,
      470,
      466,
      463,
      459,
      455,
      451,
      448,
      444,
      440,
      436,
      432,
      428,
      424,
      420,
      416,
      412,
      408,
      403,
      399,
      395,
      390,
      386,
      382,
      377,
      373,
      369,
      364,
      360,
      356,
      351,
      347,
      342,
      338,
      333,
      329,
      324,
      319,
      315,
      310,
      306,
    ],
    125: [
      630,
      627,
      624,
      621,
      617,
      614,
      611,
      607,
      604,
      601,
      597,
      594,
      590,
      587,
      583,
      579,
      576,
      572,
      569,
      565,
      562,
      558,
      555,
      551,
      548,
      544,
      541,
      537,
      534,
      531,
      527,
      524,
      521,
      517,
      514,
      511,
      507,
      504,
      501,
      497,
      494,
      490,
      487,
      483,
      480,
      476,
      473,
      469,
      466,
      462,
      458,
      455,
      451,
      447,
      444,
      440,
      436,
      432,
      428,
      424,
      420,
      416,
      412,
      408,
      404,
      400,
      396,
      392,
      388,
      383,
      379,
      375,
      371,
      366,
      362,
      358,
      353,
      349,
      345,
      340,
      336,
      332,
      327,
      323,
      319,
      314,
      310,
      305,
      301,
      297,
      292,
    ],
    130: [
      630,
      626,
      623,
      619,
      616,
      612,
      609,
      605,
      601,
      597,
      594,
      590,
      586,
      582,
      578,
      574,
      570,
      566,
      562,
      559,
      555,
      551,
      547,
      543,
      539,
      535,
      531,
      527,
      524,
      520,
      516,
      513,
      509,
      505,
      502,
      498,
      495,
      491,
      487,
      484,
      480,
      477,
      473,
      470,
      466,
      462,
      459,
      455,
      451,
      447,
      444,
      440,
      436,
      432,
      429,
      425,
      421,
      417,
      413,
      409,
      405,
      401,
      397,
      393,
      389,
      385,
      381,
      376,
      372,
      368,
      364,
      360,
      355,
      351,
      347,
      343,
      339,
      334,
      330,
      326,
      322,
      317,
      313,
      309,
      304,
      300,
      296,
      292,
      287,
      283,
      279,
    ],
    135: [
      630,
      626,
      622,
      619,
      615,
      611,
      607,
      603,
      599,
      594,
      590,
      586,
      582,
      578,
      574,
      569,
      565,
      561,
      557,
      552,
      548,
      544,
      540,
      535,
      531,
      527,
      523,
      519,
      515,
      510,
      506,
      502,
      498,
      494,
      490,
      486,
      483,
      479,
      475,
      471,
      467,
      464,
      460,
      456,
      452,
      448,
      445,
      441,
      437,
      433,
      429,
      426,
      422,
      418,
      414,
      410,
      406,
      402,
      398,
      394,
      390,
      386,
      382,
      378,
      374,
      370,
      366,
      362,
      358,
      353,
      349,
      345,
      341,
      337,
      333,
      328,
      324,
      320,
      316,
      312,
      308,
      304,
      299,
      295,
      291,
      287,
      282,
      279,
      274,
      270,
      266,
    ],
    140: [
      630,
      626,
      622,
      618,
      613,
      609,
      605,
      601,
      596,
      592,
      587,
      583,
      578,
      574,
      569,
      565,
      560,
      556,
      551,
      547,
      542,
      538,
      533,
      529,
      524,
      520,
      515,
      511,
      506,
      502,
      497,
      493,
      489,
      484,
      480,
      476,
      471,
      467,
      463,
      459,
      455,
      451,
      447,
      443,
      439,
      435,
      431,
      428,
      424,
      420,
      416,
      412,
      408,
      404,
      400,
      396,
      392,
      388,
      384,
      380,
      376,
      372,
      368,
      364,
      360,
      356,
      352,
      348,
      344,
      340,
      335,
      331,
      327,
      323,
      319,
      315,
      311,
      307,
      303,
      298,
      295,
      290,
      286,
      282,
      278,
      274,
      270,
      266,
      262,
      258,
      254,
    ],
    145: [
      630,
      625,
      621,
      617,
      612,
      608,
      603,
      599,
      594,
      589,
      585,
      580,
      575,
      571,
      566,
      561,
      556,
      551,
      547,
      542,
      537,
      532,
      528,
      523,
      518,
      513,
      508,
      504,
      499,
      494,
      490,
      485,
      480,
      475,
      471,
      466,
      462,
      457,
      453,
      448,
      444,
      440,
      435,
      431,
      427,
      423,
      419,
      415,
      411,
      407,
      403,
      399,
      395,
      391,
      387,
      383,
      379,
      375,
      371,
      367,
      363,
      359,
      355,
      351,
      347,
      343,
      339,
      335,
      331,
      327,
      323,
      318,
      315,
      310,
      306,
      302,
      298,
      294,
      290,
      286,
      282,
      278,
      274,
      270,
      266,
      262,
      259,
      255,
      251,
      247,
      243,
    ],
    150: [
      630,
      625,
      621,
      616,
      611,
      607,
      602,
      597,
      592,
      587,
      582,
      577,
      572,
      568,
      563,
      558,
      553,
      548,
      543,
      537,
      532,
      527,
      522,
      517,
      512,
      507,
      502,
      497,
      492,
      488,
      483,
      478,
      473,
      468,
      463,
      458,
      454,
      449,
      444,
      439,
      435,
      430,
      425,
      421,
      416,
      412,
      408,
      404,
      399,
      395,
      391,
      387,
      383,
      379,
      375,
      371,
      367,
      363,
      359,
      355,
      351,
      347,
      343,
      339,
      335,
      331,
      327,
      323,
      319,
      315,
      311,
      307,
      303,
      299,
      295,
      291,
      287,
      283,
      279,
      275,
      271,
      267,
      263,
      260,
      256,
      252,
      248,
      244,
      241,
      237,
      233,
    ],
    155: [
      630,
      625,
      620,
      615,
      611,
      606,
      601,
      596,
      590,
      585,
      580,
      575,
      570,
      565,
      560,
      555,
      549,
      544,
      539,
      534,
      528,
      523,
      518,
      513,
      508,
      503,
      497,
      492,
      487,
      482,
      477,
      472,
      467,
      461,
      456,
      451,
      447,
      442,
      437,
      432,
      427,
      422,
      417,
      412,
      408,
      403,
      398,
      394,
      389,
      385,
      381,
      377,
      372,
      368,
      364,
      360,
      356,
      352,
      348,
      344,
      340,
      336,
      332,
      328,
      324,
      320,
      316,
      312,
      308,
      304,
      300,
      296,
      293,
      289,
      285,
      281,
      277,
      273,
      269,
      265,
      261,
      258,
      254,
      250,
      246,
      243,
      239,
      235,
      232,
      228,
      224,
    ],
    160: [
      630,
      625,
      620,
      615,
      610,
      605,
      600,
      594,
      589,
      584,
      579,
      573,
      568,
      563,
      558,
      552,
      547,
      541,
      536,
      531,
      525,
      520,
      515,
      509,
      504,
      498,
      493,
      488,
      483,
      477,
      472,
      467,
      461,
      456,
      451,
      446,
      441,
      436,
      430,
      425,
      420,
      415,
      410,
      405,
      400,
      396,
      391,
      386,
      381,
      377,
      372,
      368,
      363,
      359,
      355,
      351,
      347,
      343,
      339,
      335,
      331,
      327,
      323,
      319,
      315,
      311,
      307,
      303,
      299,
      295,
      291,
      287,
      284,
      280,
      276,
      272,
      268,
      265,
      261,
      257,
      253,
      249,
      245,
      242,
      238,
      235,
      231,
      227,
      224,
      220,
      217,
    ],
    165: [
      630,
      625,
      620,
      615,
      609,
      604,
      599,
      593,
      588,
      583,
      577,
      572,
      567,
      561,
      556,
      550,
      545,
      539,
      534,
      528,
      523,
      517,
      512,
      506,
      501,
      495,
      490,
      484,
      479,
      474,
      468,
      463,
      457,
      452,
      447,
      441,
      436,
      431,
      426,
      420,
      415,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      375,
      371,
      366,
      361,
      357,
      352,
      348,
      344,
      340,
      335,
      331,
      327,
      323,
      319,
      315,
      311,
      307,
      303,
      300,
      296,
      292,
      288,
      284,
      280,
      277,
      273,
      269,
      265,
      261,
      258,
      254,
      250,
      246,
      243,
      239,
      236,
      232,
      228,
      225,
      221,
      218,
      214,
      211,
    ],
    170: [
      630,
      625,
      619,
      614,
      609,
      604,
      598,
      593,
      587,
      582,
      576,
      571,
      566,
      560,
      554,
      549,
      543,
      537,
      532,
      527,
      521,
      515,
      510,
      504,
      499,
      493,
      487,
      482,
      476,
      471,
      465,
      460,
      455,
      449,
      444,
      438,
      433,
      427,
      422,
      417,
      412,
      407,
      401,
      396,
      391,
      386,
      381,
      376,
      371,
      366,
      361,
      356,
      352,
      347,
      343,
      338,
      334,
      330,
      326,
      322,
      318,
      314,
      310,
      306,
      302,
      298,
      294,
      290,
      287,
      283,
      279,
      275,
      271,
      268,
      264,
      260,
      256,
      253,
      249,
      246,
      242,
      238,
      234,
      231,
      227,
      224,
      220,
      217,
      213,
      210,
      206,
    ],
    175: [
      630,
      625,
      619,
      614,
      609,
      603,
      598,
      592,
      587,
      581,
      576,
      570,
      565,
      559,
      554,
      548,
      542,
      537,
      531,
      525,
      520,
      514,
      509,
      503,
      497,
      492,
      486,
      480,
      474,
      469,
      463,
      458,
      453,
      448,
      442,
      437,
      431,
      426,
      420,
      414,
      410,
      404,
      399,
      394,
      389,
      384,
      379,
      374,
      368,
      364,
      359,
      354,
      349,
      344,
      339,
      335,
      331,
      327,
      323,
      318,
      315,
      311,
      306,
      303,
      299,
      295,
      291,
      287,
      283,
      280,
      276,
      272,
      268,
      265,
      261,
      257,
      253,
      250,
      246,
      242,
      239,
      235,
      231,
      228,
      224,
      221,
      217,
      214,
      211,
      207,
      204,
    ],
  },
  '21': {
    0: [
      711,
      717,
      723,
      729,
      735,
      741,
      747,
      752,
      758,
      763,
      768,
      774,
      778,
      783,
      788,
      792,
      797,
      801,
      805,
      808,
      812,
      815,
      819,
      822,
      825,
      827,
      830,
      832,
      835,
      837,
      838,
      840,
      841,
      843,
      844,
      845,
      846,
      846,
      846,
      847,
      847,
      846,
      846,
      845,
      845,
      844,
      842,
      841,
      839,
      838,
      836,
      834,
      831,
      829,
      826,
      823,
      820,
      817,
      814,
      810,
      806,
      802,
      798,
      794,
      789,
      784,
      779,
      774,
      769,
      764,
      758,
      752,
      746,
      740,
      734,
      728,
      721,
      714,
      707,
      700,
      693,
      686,
      678,
      670,
      663,
      654,
      646,
      638,
      630,
      621,
      612,
    ],
    5: [
      711,
      717,
      723,
      729,
      735,
      741,
      747,
      752,
      758,
      763,
      768,
      773,
      778,
      783,
      787,
      792,
      796,
      800,
      804,
      808,
      812,
      815,
      818,
      821,
      824,
      827,
      829,
      832,
      834,
      836,
      838,
      839,
      841,
      842,
      843,
      844,
      845,
      845,
      846,
      846,
      846,
      846,
      845,
      845,
      844,
      843,
      842,
      840,
      839,
      837,
      835,
      833,
      831,
      828,
      825,
      823,
      820,
      816,
      813,
      809,
      805,
      801,
      797,
      793,
      788,
      784,
      779,
      774,
      768,
      763,
      757,
      752,
      746,
      740,
      733,
      727,
      720,
      713,
      707,
      699,
      692,
      685,
      677,
      670,
      662,
      654,
      646,
      637,
      629,
      621,
      612,
    ],
    10: [
      711,
      717,
      723,
      729,
      735,
      741,
      746,
      752,
      757,
      762,
      768,
      773,
      777,
      782,
      787,
      791,
      795,
      799,
      803,
      807,
      810,
      814,
      817,
      820,
      823,
      825,
      828,
      830,
      832,
      834,
      836,
      838,
      839,
      840,
      841,
      842,
      843,
      844,
      844,
      844,
      844,
      844,
      843,
      843,
      842,
      841,
      840,
      838,
      837,
      835,
      833,
      831,
      828,
      826,
      823,
      820,
      817,
      814,
      811,
      807,
      803,
      799,
      795,
      791,
      786,
      781,
      776,
      771,
      766,
      761,
      755,
      749,
      744,
      737,
      731,
      725,
      718,
      711,
      704,
      697,
      690,
      683,
      675,
      668,
      660,
      652,
      644,
      636,
      627,
      619,
      610,
    ],
    15: [
      711,
      716,
      722,
      728,
      734,
      740,
      746,
      751,
      756,
      761,
      766,
      771,
      776,
      781,
      785,
      789,
      793,
      797,
      801,
      805,
      808,
      811,
      815,
      818,
      820,
      823,
      825,
      828,
      830,
      832,
      833,
      835,
      836,
      837,
      838,
      839,
      840,
      840,
      841,
      841,
      841,
      840,
      840,
      839,
      838,
      837,
      836,
      835,
      833,
      831,
      829,
      827,
      825,
      822,
      819,
      817,
      814,
      810,
      807,
      803,
      799,
      795,
      791,
      787,
      782,
      777,
      773,
      768,
      762,
      757,
      751,
      745,
      740,
      734,
      727,
      721,
      714,
      708,
      701,
      694,
      687,
      679,
      672,
      664,
      656,
      649,
      641,
      633,
      624,
      616,
      607,
    ],
    20: [
      711,
      716,
      722,
      728,
      734,
      739,
      745,
      750,
      755,
      760,
      765,
      770,
      774,
      779,
      783,
      787,
      791,
      795,
      798,
      802,
      805,
      809,
      812,
      814,
      817,
      820,
      822,
      824,
      826,
      828,
      830,
      831,
      832,
      833,
      834,
      835,
      836,
      836,
      836,
      836,
      836,
      836,
      835,
      834,
      834,
      832,
      831,
      830,
      828,
      826,
      824,
      822,
      820,
      817,
      814,
      811,
      808,
      805,
      801,
      798,
      794,
      790,
      786,
      781,
      777,
      772,
      767,
      762,
      757,
      752,
      746,
      740,
      735,
      729,
      722,
      716,
      709,
      703,
      696,
      689,
      682,
      675,
      667,
      660,
      652,
      644,
      636,
      628,
      620,
      611,
      603,
    ],
    25: [
      711,
      716,
      722,
      727,
      733,
      738,
      743,
      748,
      753,
      758,
      763,
      767,
      772,
      776,
      780,
      784,
      788,
      792,
      795,
      799,
      802,
      805,
      808,
      810,
      813,
      815,
      817,
      819,
      821,
      823,
      825,
      826,
      827,
      828,
      829,
      830,
      830,
      830,
      830,
      830,
      830,
      830,
      829,
      828,
      827,
      826,
      825,
      823,
      822,
      820,
      818,
      815,
      813,
      810,
      808,
      805,
      802,
      798,
      795,
      791,
      787,
      783,
      779,
      775,
      770,
      765,
      760,
      756,
      750,
      745,
      739,
      734,
      728,
      722,
      716,
      709,
      703,
      696,
      690,
      683,
      675,
      668,
      661,
      653,
      646,
      638,
      630,
      622,
      614,
      606,
      597,
    ],
    30: [
      711,
      716,
      721,
      727,
      732,
      737,
      742,
      747,
      751,
      756,
      760,
      765,
      769,
      773,
      777,
      781,
      784,
      788,
      791,
      794,
      797,
      800,
      803,
      805,
      808,
      810,
      812,
      814,
      816,
      817,
      819,
      820,
      821,
      822,
      822,
      823,
      823,
      824,
      824,
      823,
      823,
      823,
      822,
      821,
      820,
      819,
      817,
      816,
      814,
      812,
      810,
      808,
      805,
      802,
      800,
      797,
      793,
      790,
      786,
      783,
      779,
      775,
      771,
      766,
      762,
      757,
      752,
      747,
      742,
      737,
      731,
      725,
      720,
      714,
      707,
      701,
      695,
      688,
      681,
      675,
      668,
      660,
      653,
      646,
      638,
      631,
      623,
      615,
      607,
      599,
      591,
    ],
    35: [
      711,
      715,
      721,
      726,
      731,
      735,
      740,
      745,
      749,
      753,
      758,
      762,
      766,
      769,
      773,
      777,
      780,
      783,
      786,
      789,
      792,
      795,
      797,
      800,
      802,
      804,
      806,
      807,
      809,
      810,
      812,
      813,
      814,
      814,
      815,
      815,
      816,
      816,
      815,
      815,
      815,
      814,
      813,
      812,
      811,
      810,
      808,
      807,
      805,
      803,
      801,
      798,
      796,
      793,
      790,
      787,
      784,
      780,
      777,
      773,
      769,
      765,
      761,
      756,
      752,
      747,
      742,
      737,
      732,
      727,
      721,
      716,
      710,
      704,
      698,
      692,
      685,
      679,
      672,
      665,
      658,
      651,
      644,
      637,
      629,
      622,
      614,
      606,
      599,
      591,
      582,
    ],
    40: [
      711,
      715,
      720,
      725,
      729,
      734,
      738,
      742,
      746,
      750,
      754,
      758,
      762,
      765,
      769,
      772,
      775,
      778,
      781,
      784,
      786,
      789,
      791,
      793,
      795,
      797,
      799,
      800,
      801,
      803,
      804,
      805,
      805,
      806,
      806,
      806,
      807,
      806,
      806,
      806,
      805,
      804,
      803,
      802,
      801,
      800,
      798,
      796,
      794,
      792,
      790,
      787,
      785,
      782,
      779,
      776,
      773,
      769,
      766,
      762,
      758,
      754,
      750,
      745,
      741,
      736,
      731,
      726,
      721,
      715,
      710,
      704,
      699,
      693,
      687,
      681,
      674,
      668,
      661,
      654,
      648,
      641,
      634,
      626,
      619,
      612,
      604,
      597,
      589,
      581,
      573,
    ],
    45: [
      711,
      715,
      719,
      723,
      728,
      732,
      736,
      740,
      743,
      747,
      751,
      754,
      758,
      761,
      764,
      767,
      770,
      772,
      775,
      777,
      780,
      782,
      784,
      786,
      788,
      789,
      790,
      792,
      793,
      794,
      795,
      795,
      796,
      796,
      797,
      797,
      797,
      796,
      796,
      795,
      795,
      794,
      793,
      791,
      790,
      788,
      787,
      785,
      783,
      780,
      778,
      776,
      773,
      770,
      767,
      764,
      760,
      757,
      753,
      749,
      745,
      741,
      737,
      733,
      728,
      723,
      718,
      713,
      708,
      703,
      697,
      692,
      686,
      680,
      674,
      668,
      662,
      656,
      649,
      642,
      636,
      629,
      622,
      614,
      607,
      600,
      593,
      585,
      578,
      570,
      562,
    ],
    50: [
      711,
      714,
      718,
      722,
      726,
      730,
      733,
      737,
      740,
      744,
      747,
      750,
      753,
      756,
      758,
      761,
      764,
      766,
      768,
      771,
      772,
      774,
      776,
      778,
      779,
      781,
      782,
      783,
      784,
      784,
      785,
      786,
      786,
      786,
      786,
      786,
      786,
      785,
      785,
      784,
      783,
      782,
      781,
      779,
      778,
      776,
      774,
      772,
      770,
      768,
      765,
      762,
      760,
      757,
      754,
      750,
      747,
      743,
      740,
      736,
      732,
      728,
      723,
      719,
      714,
      709,
      705,
      700,
      695,
      689,
      684,
      678,
      672,
      667,
      661,
      655,
      648,
      642,
      636,
      629,
      622,
      616,
      609,
      602,
      594,
      588,
      580,
      573,
      565,
      557,
      550,
    ],
    55: [
      711,
      714,
      717,
      721,
      724,
      728,
      731,
      734,
      737,
      740,
      743,
      745,
      748,
      750,
      753,
      755,
      757,
      759,
      761,
      763,
      765,
      766,
      768,
      769,
      770,
      771,
      772,
      773,
      774,
      774,
      775,
      775,
      775,
      775,
      775,
      774,
      774,
      773,
      772,
      771,
      770,
      769,
      768,
      766,
      765,
      763,
      761,
      759,
      756,
      754,
      751,
      748,
      746,
      743,
      739,
      736,
      732,
      729,
      725,
      721,
      717,
      713,
      709,
      704,
      699,
      694,
      690,
      685,
      680,
      675,
      669,
      663,
      658,
      652,
      646,
      640,
      634,
      628,
      621,
      615,
      608,
      601,
      595,
      588,
      581,
      574,
      567,
      559,
      552,
      544,
      537,
    ],
    60: [
      711,
      713,
      716,
      719,
      722,
      725,
      728,
      731,
      733,
      736,
      738,
      740,
      743,
      745,
      747,
      749,
      750,
      752,
      754,
      755,
      757,
      758,
      759,
      760,
      761,
      761,
      762,
      763,
      763,
      763,
      764,
      763,
      763,
      763,
      763,
      762,
      761,
      760,
      759,
      758,
      757,
      756,
      754,
      752,
      751,
      749,
      746,
      744,
      742,
      739,
      736,
      733,
      730,
      727,
      724,
      721,
      717,
      713,
      709,
      706,
      701,
      697,
      693,
      688,
      684,
      679,
      674,
      669,
      664,
      659,
      653,
      647,
      642,
      636,
      631,
      624,
      618,
      612,
      606,
      599,
      593,
      586,
      580,
      573,
      566,
      559,
      552,
      545,
      538,
      530,
      523,
    ],
    65: [
      711,
      713,
      715,
      718,
      720,
      723,
      725,
      727,
      729,
      731,
      733,
      735,
      737,
      739,
      740,
      742,
      743,
      744,
      746,
      747,
      748,
      749,
      750,
      750,
      751,
      751,
      752,
      752,
      752,
      752,
      752,
      751,
      751,
      750,
      750,
      749,
      748,
      747,
      746,
      744,
      743,
      741,
      740,
      738,
      736,
      734,
      731,
      729,
      726,
      724,
      721,
      718,
      715,
      711,
      708,
      704,
      701,
      697,
      693,
      689,
      685,
      681,
      676,
      672,
      667,
      662,
      657,
      652,
      647,
      642,
      637,
      631,
      625,
      620,
      614,
      608,
      602,
      596,
      590,
      583,
      577,
      570,
      564,
      557,
      550,
      543,
      537,
      530,
      523,
      515,
      508,
    ],
    70: [
      711,
      712,
      714,
      716,
      718,
      720,
      722,
      724,
      725,
      727,
      728,
      730,
      731,
      732,
      733,
      735,
      735,
      736,
      737,
      738,
      739,
      739,
      740,
      740,
      740,
      740,
      740,
      740,
      740,
      740,
      739,
      739,
      738,
      737,
      736,
      735,
      734,
      733,
      731,
      730,
      728,
      726,
      724,
      722,
      720,
      718,
      715,
      713,
      710,
      707,
      704,
      701,
      698,
      695,
      691,
      688,
      684,
      680,
      676,
      672,
      668,
      663,
      659,
      654,
      649,
      645,
      640,
      635,
      629,
      624,
      619,
      614,
      608,
      602,
      597,
      591,
      584,
      578,
      572,
      566,
      560,
      554,
      547,
      541,
      534,
      527,
      520,
      514,
      507,
      500,
      493,
    ],
    75: [
      711,
      712,
      713,
      715,
      716,
      718,
      719,
      720,
      721,
      722,
      723,
      724,
      725,
      726,
      726,
      727,
      728,
      728,
      728,
      729,
      729,
      729,
      729,
      729,
      729,
      729,
      729,
      728,
      728,
      727,
      726,
      725,
      725,
      723,
      722,
      721,
      720,
      718,
      716,
      715,
      713,
      711,
      709,
      706,
      704,
      702,
      699,
      696,
      693,
      690,
      687,
      684,
      681,
      677,
      674,
      670,
      666,
      662,
      658,
      654,
      650,
      645,
      641,
      636,
      631,
      627,
      622,
      617,
      612,
      606,
      601,
      596,
      590,
      584,
      579,
      573,
      567,
      561,
      555,
      549,
      543,
      537,
      530,
      523,
      517,
      511,
      504,
      497,
      491,
      484,
      477,
    ],
    80: [
      711,
      711,
      712,
      713,
      714,
      715,
      716,
      716,
      717,
      717,
      718,
      718,
      719,
      719,
      719,
      719,
      719,
      719,
      719,
      719,
      719,
      719,
      719,
      718,
      718,
      717,
      716,
      716,
      715,
      714,
      713,
      712,
      711,
      709,
      708,
      706,
      705,
      703,
      701,
      699,
      697,
      695,
      692,
      690,
      687,
      685,
      682,
      679,
      676,
      673,
      670,
      666,
      663,
      659,
      656,
      652,
      648,
      644,
      640,
      635,
      631,
      627,
      622,
      618,
      613,
      608,
      603,
      598,
      593,
      588,
      582,
      577,
      572,
      566,
      560,
      555,
      549,
      543,
      537,
      531,
      525,
      519,
      512,
      506,
      500,
      494,
      487,
      480,
      474,
      467,
      461,
    ],
    85: [
      711,
      711,
      711,
      712,
      712,
      712,
      712,
      712,
      712,
      712,
      712,
      712,
      712,
      712,
      712,
      711,
      711,
      711,
      710,
      710,
      709,
      708,
      708,
      707,
      706,
      705,
      704,
      703,
      702,
      701,
      699,
      698,
      696,
      695,
      693,
      691,
      689,
      687,
      685,
      683,
      681,
      678,
      676,
      673,
      670,
      667,
      665,
      661,
      658,
      655,
      652,
      648,
      645,
      641,
      637,
      633,
      629,
      625,
      621,
      617,
      612,
      608,
      603,
      599,
      594,
      589,
      584,
      579,
      574,
      569,
      563,
      558,
      553,
      547,
      542,
      536,
      530,
      524,
      519,
      512,
      506,
      500,
      494,
      488,
      482,
      476,
      470,
      464,
      456,
      450,
      444,
    ],
    90: [
      711,
      710,
      710,
      710,
      710,
      709,
      709,
      709,
      708,
      707,
      707,
      706,
      706,
      705,
      704,
      703,
      703,
      702,
      701,
      700,
      699,
      698,
      696,
      696,
      694,
      693,
      691,
      690,
      689,
      687,
      685,
      684,
      682,
      680,
      678,
      676,
      674,
      671,
      669,
      667,
      664,
      661,
      659,
      656,
      653,
      650,
      647,
      644,
      640,
      637,
      633,
      630,
      626,
      622,
      618,
      614,
      610,
      606,
      602,
      598,
      593,
      589,
      584,
      579,
      574,
      569,
      565,
      560,
      554,
      549,
      544,
      539,
      533,
      528,
      522,
      517,
      511,
      505,
      500,
      494,
      488,
      482,
      476,
      470,
      463,
      458,
      452,
      445,
      438,
      432,
      426,
    ],
    95: [
      711,
      710,
      709,
      708,
      707,
      706,
      706,
      705,
      704,
      702,
      701,
      700,
      699,
      698,
      697,
      695,
      694,
      693,
      691,
      690,
      688,
      687,
      685,
      684,
      682,
      680,
      679,
      677,
      675,
      673,
      671,
      669,
      667,
      665,
      663,
      660,
      658,
      655,
      653,
      650,
      647,
      644,
      641,
      638,
      635,
      632,
      629,
      625,
      622,
      618,
      615,
      611,
      607,
      603,
      599,
      595,
      591,
      587,
      583,
      578,
      574,
      569,
      564,
      560,
      555,
      550,
      545,
      540,
      535,
      530,
      524,
      519,
      514,
      508,
      503,
      497,
      492,
      486,
      481,
      475,
      469,
      463,
      457,
      451,
      446,
      439,
      433,
      427,
      422,
      415,
      409,
    ],
    100: [
      711,
      709,
      708,
      706,
      705,
      704,
      702,
      701,
      699,
      697,
      696,
      694,
      692,
      691,
      689,
      687,
      685,
      683,
      682,
      680,
      678,
      676,
      674,
      672,
      670,
      668,
      666,
      664,
      661,
      659,
      657,
      654,
      652,
      650,
      647,
      644,
      642,
      639,
      636,
      633,
      630,
      627,
      624,
      621,
      618,
      614,
      611,
      607,
      604,
      600,
      596,
      592,
      588,
      584,
      580,
      576,
      572,
      567,
      563,
      559,
      554,
      549,
      545,
      540,
      535,
      530,
      525,
      520,
      515,
      510,
      505,
      500,
      494,
      489,
      484,
      478,
      473,
      467,
      462,
      456,
      450,
      444,
      439,
      433,
      427,
      422,
      415,
      409,
      404,
      398,
      391,
    ],
    105: [
      711,
      708,
      707,
      705,
      703,
      701,
      699,
      697,
      695,
      693,
      690,
      688,
      686,
      684,
      681,
      679,
      677,
      675,
      672,
      670,
      668,
      665,
      663,
      660,
      658,
      655,
      653,
      650,
      648,
      645,
      643,
      640,
      637,
      634,
      632,
      629,
      626,
      623,
      620,
      616,
      613,
      610,
      607,
      603,
      600,
      596,
      593,
      589,
      585,
      581,
      577,
      573,
      569,
      565,
      561,
      557,
      552,
      548,
      544,
      539,
      534,
      530,
      525,
      520,
      516,
      511,
      506,
      501,
      496,
      491,
      485,
      480,
      475,
      470,
      464,
      459,
      453,
      448,
      442,
      437,
      431,
      426,
      420,
      414,
      409,
      403,
      397,
      391,
      386,
      380,
      375,
    ],
    110: [
      711,
      708,
      706,
      703,
      701,
      698,
      696,
      693,
      690,
      688,
      685,
      682,
      680,
      677,
      674,
      671,
      668,
      666,
      663,
      660,
      657,
      654,
      651,
      649,
      646,
      643,
      640,
      637,
      634,
      631,
      628,
      625,
      622,
      619,
      616,
      613,
      610,
      606,
      603,
      600,
      596,
      593,
      589,
      586,
      582,
      578,
      575,
      571,
      567,
      563,
      559,
      555,
      551,
      546,
      542,
      538,
      533,
      529,
      524,
      520,
      515,
      510,
      506,
      501,
      496,
      491,
      486,
      481,
      476,
      471,
      466,
      461,
      456,
      450,
      445,
      440,
      434,
      429,
      424,
      418,
      412,
      407,
      402,
      397,
      391,
      385,
      380,
      374,
      369,
      363,
      357,
    ],
    115: [
      711,
      707,
      705,
      702,
      699,
      696,
      693,
      689,
      686,
      683,
      680,
      677,
      673,
      670,
      667,
      664,
      660,
      657,
      654,
      650,
      647,
      644,
      640,
      637,
      634,
      631,
      627,
      624,
      621,
      618,
      614,
      611,
      607,
      604,
      601,
      597,
      594,
      590,
      587,
      583,
      580,
      576,
      572,
      568,
      565,
      561,
      557,
      553,
      549,
      545,
      540,
      536,
      532,
      528,
      523,
      519,
      514,
      510,
      505,
      501,
      496,
      491,
      486,
      482,
      477,
      472,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      426,
      421,
      416,
      411,
      405,
      400,
      395,
      389,
      384,
      378,
      373,
      367,
      362,
      357,
      351,
      346,
      341,
    ],
    120: [
      711,
      707,
      704,
      700,
      697,
      693,
      689,
      686,
      682,
      679,
      675,
      671,
      667,
      664,
      660,
      656,
      652,
      648,
      645,
      641,
      637,
      634,
      630,
      626,
      623,
      619,
      615,
      612,
      608,
      604,
      601,
      597,
      593,
      590,
      586,
      582,
      578,
      575,
      571,
      567,
      563,
      559,
      555,
      551,
      547,
      543,
      539,
      535,
      531,
      527,
      522,
      518,
      514,
      509,
      505,
      500,
      496,
      491,
      487,
      482,
      477,
      473,
      468,
      463,
      458,
      453,
      448,
      443,
      439,
      433,
      428,
      423,
      418,
      413,
      408,
      403,
      398,
      392,
      387,
      382,
      377,
      372,
      366,
      361,
      356,
      351,
      345,
      341,
      335,
      330,
      324,
    ],
    125: [
      711,
      706,
      703,
      699,
      695,
      691,
      687,
      683,
      678,
      674,
      670,
      666,
      662,
      657,
      653,
      649,
      645,
      640,
      636,
      632,
      628,
      624,
      620,
      616,
      612,
      607,
      603,
      599,
      595,
      591,
      587,
      583,
      579,
      575,
      571,
      567,
      563,
      559,
      555,
      551,
      547,
      543,
      539,
      535,
      531,
      526,
      522,
      518,
      514,
      509,
      505,
      500,
      496,
      491,
      487,
      482,
      478,
      473,
      469,
      464,
      459,
      454,
      450,
      445,
      440,
      435,
      430,
      425,
      420,
      416,
      410,
      405,
      401,
      395,
      390,
      385,
      380,
      375,
      370,
      365,
      360,
      355,
      350,
      344,
      339,
      334,
      329,
      324,
      319,
      314,
      309,
    ],
    130: [
      711,
      706,
      702,
      697,
      693,
      688,
      684,
      679,
      675,
      670,
      665,
      661,
      656,
      651,
      647,
      642,
      638,
      633,
      628,
      624,
      619,
      615,
      610,
      606,
      601,
      597,
      592,
      588,
      583,
      579,
      574,
      570,
      566,
      562,
      557,
      553,
      549,
      544,
      540,
      536,
      532,
      527,
      523,
      519,
      514,
      510,
      506,
      501,
      497,
      492,
      488,
      483,
      479,
      474,
      470,
      465,
      460,
      456,
      451,
      446,
      442,
      437,
      432,
      427,
      422,
      418,
      413,
      408,
      403,
      398,
      393,
      388,
      383,
      378,
      373,
      369,
      363,
      358,
      353,
      348,
      344,
      339,
      334,
      329,
      324,
      318,
      314,
      309,
      304,
      299,
      294,
    ],
    135: [
      711,
      706,
      701,
      696,
      691,
      686,
      681,
      676,
      671,
      666,
      661,
      656,
      651,
      646,
      641,
      636,
      631,
      626,
      621,
      616,
      611,
      606,
      601,
      596,
      591,
      587,
      582,
      577,
      572,
      567,
      563,
      558,
      553,
      549,
      544,
      539,
      535,
      530,
      526,
      521,
      517,
      512,
      508,
      503,
      499,
      494,
      490,
      485,
      481,
      476,
      471,
      467,
      462,
      458,
      453,
      448,
      444,
      439,
      434,
      430,
      425,
      420,
      415,
      411,
      406,
      401,
      396,
      391,
      387,
      381,
      377,
      372,
      367,
      362,
      357,
      352,
      348,
      343,
      338,
      333,
      328,
      323,
      318,
      313,
      309,
      304,
      299,
      294,
      290,
      285,
      280,
    ],
    140: [
      711,
      705,
      700,
      695,
      690,
      684,
      679,
      674,
      668,
      663,
      657,
      652,
      646,
      641,
      636,
      630,
      625,
      620,
      614,
      609,
      604,
      598,
      593,
      588,
      583,
      578,
      572,
      567,
      562,
      557,
      552,
      547,
      542,
      536,
      532,
      527,
      522,
      517,
      512,
      507,
      503,
      498,
      493,
      489,
      484,
      479,
      474,
      470,
      465,
      461,
      456,
      451,
      446,
      442,
      437,
      432,
      428,
      423,
      418,
      414,
      409,
      404,
      399,
      395,
      390,
      385,
      380,
      375,
      371,
      366,
      361,
      356,
      351,
      347,
      342,
      337,
      332,
      328,
      323,
      318,
      313,
      309,
      304,
      300,
      295,
      290,
      285,
      281,
      276,
      272,
      267,
    ],
    145: [
      711,
      705,
      699,
      694,
      688,
      683,
      677,
      671,
      665,
      660,
      654,
      648,
      642,
      637,
      631,
      625,
      620,
      614,
      609,
      603,
      597,
      592,
      586,
      581,
      575,
      569,
      564,
      559,
      553,
      547,
      542,
      537,
      531,
      526,
      520,
      515,
      510,
      505,
      500,
      495,
      490,
      484,
      480,
      475,
      470,
      465,
      460,
      455,
      451,
      446,
      441,
      436,
      432,
      427,
      422,
      417,
      412,
      408,
      403,
      398,
      394,
      389,
      384,
      379,
      375,
      370,
      365,
      360,
      356,
      351,
      346,
      342,
      337,
      332,
      328,
      323,
      318,
      314,
      309,
      304,
      300,
      295,
      291,
      286,
      281,
      277,
      273,
      268,
      264,
      259,
      255,
    ],
    150: [
      711,
      705,
      699,
      693,
      687,
      681,
      675,
      669,
      663,
      657,
      651,
      645,
      639,
      633,
      627,
      621,
      615,
      609,
      603,
      598,
      592,
      586,
      580,
      574,
      568,
      563,
      557,
      551,
      545,
      539,
      534,
      528,
      522,
      517,
      511,
      505,
      500,
      494,
      489,
      483,
      478,
      473,
      467,
      462,
      457,
      452,
      447,
      442,
      437,
      432,
      427,
      422,
      418,
      413,
      408,
      403,
      399,
      394,
      389,
      384,
      380,
      375,
      370,
      365,
      361,
      356,
      352,
      347,
      342,
      337,
      333,
      328,
      324,
      319,
      314,
      310,
      306,
      301,
      296,
      292,
      288,
      283,
      278,
      274,
      270,
      265,
      261,
      256,
      252,
      248,
      244,
    ],
    155: [
      711,
      704,
      698,
      692,
      686,
      680,
      674,
      667,
      661,
      655,
      648,
      642,
      636,
      630,
      623,
      617,
      611,
      605,
      599,
      593,
      587,
      581,
      575,
      569,
      563,
      557,
      550,
      544,
      538,
      532,
      526,
      520,
      515,
      509,
      503,
      497,
      491,
      485,
      480,
      474,
      468,
      463,
      457,
      452,
      446,
      441,
      436,
      431,
      425,
      420,
      415,
      410,
      405,
      400,
      395,
      391,
      386,
      381,
      376,
      372,
      367,
      362,
      358,
      353,
      348,
      344,
      339,
      334,
      330,
      325,
      321,
      316,
      312,
      307,
      303,
      298,
      294,
      289,
      285,
      281,
      276,
      272,
      268,
      263,
      259,
      254,
      250,
      246,
      242,
      238,
      234,
    ],
    160: [
      711,
      704,
      698,
      692,
      685,
      679,
      672,
      666,
      659,
      653,
      646,
      640,
      633,
      627,
      621,
      614,
      608,
      602,
      596,
      590,
      583,
      577,
      571,
      564,
      558,
      552,
      545,
      539,
      533,
      527,
      521,
      514,
      508,
      502,
      496,
      490,
      484,
      478,
      472,
      466,
      460,
      454,
      449,
      443,
      437,
      432,
      426,
      421,
      415,
      410,
      405,
      400,
      395,
      390,
      385,
      380,
      375,
      370,
      365,
      361,
      356,
      351,
      346,
      342,
      337,
      333,
      328,
      324,
      319,
      315,
      310,
      306,
      301,
      297,
      293,
      288,
      284,
      279,
      275,
      271,
      267,
      263,
      258,
      254,
      250,
      246,
      242,
      237,
      233,
      230,
      225,
    ],
    165: [
      711,
      704,
      697,
      691,
      684,
      678,
      671,
      665,
      658,
      651,
      644,
      638,
      631,
      625,
      618,
      612,
      606,
      599,
      593,
      587,
      580,
      574,
      567,
      561,
      554,
      548,
      542,
      535,
      529,
      522,
      516,
      510,
      503,
      497,
      491,
      485,
      478,
      472,
      466,
      460,
      454,
      448,
      442,
      436,
      430,
      425,
      419,
      413,
      408,
      402,
      397,
      392,
      387,
      382,
      377,
      371,
      366,
      361,
      356,
      352,
      347,
      342,
      338,
      333,
      328,
      324,
      319,
      315,
      311,
      306,
      302,
      297,
      293,
      288,
      284,
      280,
      276,
      271,
      267,
      263,
      259,
      255,
      251,
      247,
      242,
      238,
      234,
      231,
      227,
      223,
      219,
    ],
    170: [
      711,
      704,
      697,
      691,
      684,
      677,
      670,
      664,
      657,
      650,
      643,
      636,
      630,
      623,
      617,
      610,
      604,
      598,
      591,
      584,
      578,
      571,
      565,
      558,
      552,
      545,
      539,
      532,
      526,
      519,
      513,
      506,
      500,
      493,
      487,
      481,
      474,
      468,
      462,
      456,
      450,
      443,
      437,
      431,
      425,
      420,
      414,
      408,
      402,
      397,
      391,
      386,
      381,
      375,
      370,
      365,
      360,
      355,
      350,
      345,
      340,
      336,
      331,
      326,
      322,
      317,
      313,
      309,
      304,
      300,
      295,
      291,
      287,
      283,
      278,
      274,
      270,
      266,
      261,
      257,
      253,
      249,
      245,
      241,
      237,
      233,
      229,
      225,
      221,
      217,
      214,
    ],
    175: [
      711,
      704,
      697,
      690,
      684,
      677,
      670,
      663,
      656,
      649,
      642,
      636,
      629,
      623,
      616,
      610,
      603,
      596,
      590,
      583,
      576,
      570,
      563,
      557,
      550,
      544,
      537,
      531,
      524,
      517,
      510,
      504,
      497,
      491,
      485,
      478,
      472,
      466,
      460,
      454,
      447,
      441,
      435,
      428,
      422,
      416,
      411,
      405,
      399,
      393,
      388,
      382,
      377,
      372,
      367,
      361,
      357,
      351,
      346,
      341,
      336,
      332,
      327,
      322,
      318,
      313,
      309,
      304,
      300,
      296,
      291,
      287,
      283,
      279,
      274,
      270,
      266,
      262,
      258,
      254,
      250,
      245,
      241,
      238,
      234,
      230,
      226,
      222,
      218,
      214,
      210,
    ],
  },
};
