import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import Button from 'hollywood/dist/components/Button';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import validator from './validator';

const UserForm = ({ user, onSubmit, onChange, onReset, processing, markAsDirty }) => {
  const initialValues = {
    ...user,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validator}
      onSubmit={onSubmit}
      render={({ isValid, values, resetForm }) => {
        let disabled = !processing && !isValid;
        if (markAsDirty && !processing) {
          // Provided values and initialValues are the same and it was valid before...was it?
          if (
            initialValues.firstname === values.firstname &&
            initialValues.lastname === values.lastname &&
            initialValues.telephone === values.telephone
          ) {
            disabled = false;
          }
        }

        return (
          <Form>
            <div className="form-field">
              <TextField
                testId="firstname"
                name="firstname"
                title="First name"
                isRequired
                onChange={(e, val) => {
                  onChange('firstname', val);
                }}
              />
              <TextField
                testId="lastname"
                name="lastname"
                title="Last name"
                isRequired
                onChange={(e, val) => {
                  onChange('lastname', val);
                }}
              />
              <TextField
                testId="telephone"
                name="telephone"
                title="Telephone number"
                type="tel"
                isRequired
                onChange={(e, val) => {
                  onChange('telephone', val);
                }}
              />
              <TextField
                testId="mobile"
                name="mobile"
                title="Mobile number"
                type="tel"
                isRequired
                onChange={(e, val) => {
                  onChange('mobile', val);
                }}
              />
              <TextField testId="email" name="email" title="Contact email address" type="email" disabled />
              <Button loading={processing} format="primary" type="submit" disabled={disabled} testId="save">
                Save
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault();
                  resetForm();
                  onReset();
                }}
                format="secondary"
                type="submit"
                testId="reset"
                disabled={!isValid}
              >
                Reset
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  processing: PropTypes.bool.isRequired,
  markAsDirty: PropTypes.bool.isRequired,
};

export default UserForm;
