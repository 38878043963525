import { POI_BATTERY_STORAGE, POI_EV_CHARGING, POI_SMART_HEATING, POI_SMART_LIGHTING, POI_SOLAR_PV } from '@zing/neo-common/dist/config';
import { ReactComponent as BatteryStorage } from '@zing/neo-common/dist/svg/battery.svg';
import { ReactComponent as SmartHeating } from '@zing/neo-common/dist/svg/temperature-control.svg';
import { ReactComponent as SmartLighting } from '@zing/neo-common/dist/svg/light-bulb.svg';
import { ReactComponent as SolarPV } from '@zing/neo-common/dist/svg/solar-powered-house.svg';
import { ReactComponent as VehicleCharging } from '@zing/neo-common/dist/svg/car-electric.svg';
import CheckboxGroup from 'hollywood/dist/components/forms/CheckboxGroup';
import PropTypes from 'prop-types';
import React from 'react';

import './PoiCheckboxes.scss';

const getPoiIcon = poi => {
  switch (poi.key) {
    case POI_SOLAR_PV.key:
      return <SolarPV />;
    case POI_BATTERY_STORAGE.key:
      return <BatteryStorage />;
    case POI_EV_CHARGING.key:
      return <VehicleCharging />;
    case POI_SMART_HEATING.key:
      return <SmartHeating />;
    case POI_SMART_LIGHTING.key:
      return <SmartLighting />;
    default:
      return null;
  }
};

const PoiCheckboxes = ({ options, onChange }) => {
  const checkboxes = options.map(option => ({
    value: option.key,
    label: (
      <>
        {getPoiIcon(option)}
        <span>{option.label}</span>
      </>
    ),
  }));

  return (
    <div className="poi-checkboxes">
      <CheckboxGroup
        name="products_of_interest"
        id="products_of_interest"
        testId="products_of_interest"
        className="poi-checkbox"
        options={checkboxes}
        onChange={onChange}
      />
    </div>
  );
};

PoiCheckboxes.propTypes = {
  options: PropTypes.shape([]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PoiCheckboxes;
