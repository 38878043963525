import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { CustomerFields } from '../CustomerFields/CustomerFields';
import { durationOptions, travelTimeOptions } from '../../../config';
import { Form, Formik } from 'formik';
import { initialValues } from './initialValues';
import { isSubmitDisabled } from './is-submit-disabled';
import {
  PRODUCTS_OF_INTEREST_LIST,
  customerTypesAllowedInApp,
  LADS_CUSTOMER,
  COMMERCIAL_CUSTOMER,
  RESIDENTIAL_CUSTOMER,
} from '@zing/neo-common/dist/config';
import { SelectField } from '@zing/neo-common/dist/components/forms/fields/SelectField';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import { TextareaField } from '@zing/neo-common/dist/components/forms/fields/TextareaField';
import { useMachine } from '@xstate/react';
import Addresses from '../../addresses/addresses';
import addressMachine from '../../../machines/addresses.machine';
import BackButton from '../../buttons/back';
import Button from 'hollywood/dist/components/Button';
import PoiCheckboxes from '../../PoiCheckboxes';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import validator from './validator';

import './create-opportunity.scss';

const channelOptionsData = [
  { label: 'Face to face (f2f)', value: '178190006', residential: true, commercial: true, scheme: false },
  { label: 'Press', value: '178190017', residential: true, commercial: true, scheme: false },
  { label: 'Events', value: '178190018', residential: true, commercial: true, scheme: false },
  { label: 'Self Generated', value: '178190003', residential: true, commercial: true, scheme: false },
  // Removed/added options - UKSQA-1315
  // { label: 'Highlands Solar', value: '178190022', residential: true, commercial: true, scheme: true },
  // { label: 'LAD Scheme', value: '178190033', residential: true, commercial: true, scheme: true },
  { label: 'Scheme', value: '178190037', residential: true, commercial: true, scheme: true },
];

export const CreateOpportunityForm = ({ onSubmit, addressOptions = {} }) => {
  let formikSetFieldValue = null;
  const [current, send, service] = useMachine(addressMachine);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          if (formikSetFieldValue) {
            formikSetFieldValue('lat', position.coords.latitude);
            formikSetFieldValue('lng', position.coords.longitude);
          }
        },
        () => {}
      );
    }
  }, []);

  // one time hook up the transitions
  useEffect(() => {
    const subscription = service.subscribe(state => {
      Object.keys(state.context).forEach(key => {
        if (formikSetFieldValue) {
          formikSetFieldValue(key, state.context[key]);
        }
      });
    });
    return subscription.unsubscribe;
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validator}
      onSubmit={onSubmit}
      render={({ values, isValid, dirty, errors, setFieldValue }) => {
        if (formikSetFieldValue === null) {
          formikSetFieldValue = setFieldValue;
        }

        const channelOptions = channelOptionsData
          .map(option => {
            if (LADS_CUSTOMER === values.lead_category && option.scheme === true) {
              return option;
            }
            if (COMMERCIAL_CUSTOMER === values.lead_category && option.commercial === true) {
              return option;
            }
            if (RESIDENTIAL_CUSTOMER === values.lead_category && option.residential === true) {
              return option;
            }
            return undefined;
          })
          .filter(x => x !== undefined);

        // if a channel is selected, but is no longer available in the filtered channels, unset it
        if (channelOptions.length > 0 && values.channel !== '' && !channelOptions.find(x => x.value === values.channel)) {
          setFieldValue('channel', '');
        }

        return (
          <Form>
            <fieldset>
              <h3>Installation type</h3>
              <div className="range-center">
                <PoiCheckboxes options={PRODUCTS_OF_INTEREST_LIST} />
              </div>
            </fieldset>
            <fieldset>
              <h3>Customer details</h3>
              <CustomerFields options={{ shown: { lnumber: false, title: false } }} />
            </fieldset>
            <fieldset>
              <h3>Description</h3>
              <TextareaField name="description" title="Description / Notes" />
            </fieldset>
            <fieldset>
              <Addresses current={current} send={send} options={addressOptions} />
            </fieldset>
            <fieldset>
              <h3>Meeting details</h3>
              <Grid gutters="sm" data-testid="address-form">
                <Col width="6">
                  <SelectField name="duration" title="Duration" options={durationOptions} />
                </Col>
                <Col width="6">
                  <SelectField name="travel_time" title="Travel time" options={travelTimeOptions} />
                </Col>
              </Grid>
              <h3>Property hub</h3>
              <Grid gutters="sm" data-testid="property-hub-form">
                <Col width="6">
                  <TextField name="property_hub_job_id" title="Job id" />
                </Col>
                <Col width="6">
                  <TextField name="property_hub_address_id" title="Address id" />
                </Col>
              </Grid>
              <h3>Customer type</h3>
              <Grid gutters="sm" data-testid="property-hub-form">
                <Col width="6">
                  <SelectField
                    name="eon_customer"
                    title="E.ON Customer?"
                    options={[
                      { label: 'Yes', value: '178190001' },
                      { label: 'No', value: '178190002' },
                    ]}
                    isRequired
                  />
                </Col>
                <Col width="6">
                  <SelectField name="lead_category" title="Customer Type" options={customerTypesAllowedInApp} isRequired />
                </Col>
                <Col width="6">
                  <SelectField name="channel" title="Channel" options={channelOptions} isRequired />
                </Col>
              </Grid>
            </fieldset>
            <Grid align="right">
              <Col width="auto">
                <BackButton link="/opportunities" />
                <Button format="primary" type="submit" disabled={isSubmitDisabled(dirty, isValid, errors)} testId="create">
                  Create appointment
                </Button>
              </Col>
            </Grid>
          </Form>
        );
      }}
    />
  );
};

CreateOpportunityForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addressOptions: PropTypes.shape({}).isRequired,
};
