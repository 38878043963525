/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { get } from '@zing/neo-common/dist/lib/safe';
import { getProfilePicturePath } from './get-profile-picture-path';
import Button from 'hollywood/dist/components/Button';
import Icon from '@zing/neo-common/dist/components/Icon';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import useOfflineService from '../../hooks/use-offline-service';

export const ProfilePicture = ({ user, settings, onUploadProfilePicture, removeProfilePicture }) => {
  const photoInput = useRef(null);
  const [isPhotoSelected, setIsPhotoSelected] = useState(false);
  const [offline] = useOfflineService();

  const hasProfilePicture = user => get(user, 'profile_image_filename');

  let profilePicture = <Icon icon="person" className="profilePictureIcon" />;
  let profileLabel = 'Add your profile picture';
  let profileButtonLabel = 'Add';
  const isProfilePicture = hasProfilePicture(user);
  if (isProfilePicture) {
    profileButtonLabel = 'Change';
    profileLabel = 'Change your profile picture';
    profilePicture = <img alt="profile" src={getProfilePicturePath(get(user, 'profile_image_filename'))} className="profilePictureImage" />;
  }
  const isUploadingPhoto = get(settings, 'photo_upload.status') === 'Processing';

  return (
    <div className="profilePicture">
      <Grid gutters="sm" valign="center">
        <Col>
          {isProfilePicture && (
            <span
              className="removeProfilePictureButton"
              onClick={() => {
                removeProfilePicture();
              }}
            >
              <Icon icon="trash" />
            </span>
          )}
          <div className="circle">{profilePicture}</div>
        </Col>
        <Col>
          <span>{profileLabel}</span>
          <div className="profileButton">
            {!isPhotoSelected && !isUploadingPhoto && (
              <Button
                disabled={offline.matches('offline')}
                testId="dialogButton"
                format="primary"
                size="small"
                onClick={() => {
                  photoInput.current.click();
                }}
              >
                {profileButtonLabel}
              </Button>
            )}
            {(isUploadingPhoto || isPhotoSelected) && (
              <Button
                disabled={offline.matches('offline')}
                testId="uploadButton"
                loading={isUploadingPhoto}
                format="primary"
                size="small"
                onClick={() => {
                  if (photoInput.current.files && photoInput.current.files.length > 0) {
                    const formData = new FormData();
                    formData.append('profile_image', photoInput.current.files[0]);
                    onUploadProfilePicture(formData);
                    // uploadProfilePicture(formData);
                  }
                }}
              >
                Upload
              </Button>
            )}

            <input
              onChange={() => {
                if (photoInput.current.files && photoInput.current.files.length > 0) {
                  setIsPhotoSelected(true);
                }
              }}
              ref={photoInput}
              type="file"
              name="profile_image"
              id="profile_image"
              style={{ display: 'none' }}
            />
          </div>
        </Col>
      </Grid>
    </div>
  );
};

ProfilePicture.propTypes = {
  onUploadProfilePicture: PropTypes.func.isRequired,
  removeProfilePicture: PropTypes.func.isRequired,
  settings: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
};
