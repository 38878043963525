export default {
  values: {
    onArrival: {
      siteSafetyInductions: 'NA',
      siteEmergencyProcedures: 'NA',
      unusualEnvironmentConditions: 'Yes',
      appropriateEquipment: 'Yes',
      opportunityInCalendar: 'Yes',
      opportunityInCalendarNote: '',
      done: false,
    },
    meetCustomer: {
      vulnerableInHousehold: 'No',
      specialPowerRequirements: 'No',
      freeFromRiskyPets: 'Yes',
      isCustomerPresentForVisit: 'Yes',
      customerAuthForSurvey: 'Yes',
      vulnerableInHouseholdNote: '',
      specialPowerRequirementsNote: '',
      freeFromRiskyPetsNote: '',
      done: false,
    },
    startSurvey: {
      familiarWithProcedure: 'Yes',
      safeRouteOfAccess: 'Yes',
      areaSuitablyLit: 'Yes',
      correctToolsAndCondition: 'Yes',
      isWorkAreaSafe: 'Yes',
      anyAdditionalHazards: 'No',
      done: false,
    },
    afterwards: {
      asbestosOnSite: 'No',
      done: false,
    },
  },
  submitCount: 0,
  touched: {},
  errors: {},
};
