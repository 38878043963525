// all calc producers live in here
import { toNumber } from '@zing/neo-common/dist/lib/safe';
// eslint-disable-next-line import/no-cycle
import {
  // GS_RATIO_THRESHOLD,
  REASON_WHICH_VAT_RATE_G1,
  // REASON_WHICH_VAT_RATE_G2,
  REASON_WHICH_VAT_RATE_S1,
  // REASON_WHICH_VAT_RATE_S2,
  reducePerPanelBy30Products,
  VAT_HIGHER_RATE,
  VAT_LOWER_RATE,
} from './pim-calcs';
// import { BATTERY_ONLY } from '@zing/neo-common/dist/accessors/pim-comparison';
import { divide } from '@zing/neo-common/dist/lib/number';
import { VAT_RATE1, VAT_RATE2 } from '../../config';
import { isCommercialCustomer } from '@zing/neo-common/dist/lib/customer-types';

/**
 * Formula is price = cost / (1 - gm) - value
 *
 * E.g. if cost = £100, and GM = 35%, then price = 100 / (1-0.35) = £153.85.
 * @param gm
 * @param value
 */
export const gm = (gm, value) => value / (1 - gm) - value;

/**
 * Determines which GM category key to use depending on the current lead customer
 * @param leadCategory
 * @returns {string}
 */
const leadCategoryKey = leadCategory => {
  switch (Number(leadCategory)) {
    case 178190003:
      return 'gmLow';
    case 178190004:
      return 'gmHigh';
    // reserved (for future use) 'gmHigher'
  }
  return 'gmResidential';
};

/**
 * Calculate GM value from cost and GM ratio
 * @param draft
 */
export const gmValueFromCostAndGm = leadCategory => draft => {
  // this is where we can switch the GM type (depending on lead category)

  draft.gmType = 'gmResidential';
  draft.gmUsed = draft.product.gmResidential;
  draft.gmForcedResidential = true;

  // detect if API supports multi-GM
  if (draft.product.gmLow === undefined || draft.product.gmHigh === undefined || draft.product.gmHigher === undefined) {
    // eslint-disable-next-line no-console
    console.warn('API does not support multi GM values yet');
  } else {
    draft.gmType = leadCategoryKey(leadCategory);
    draft.gmUsed = draft.product[draft.gmType] || 0;
    draft.gmForcedResidential = false;
  }

  // so that tables can display correctly per product
  draft.product.gmUsed = draft.gmUsed;

  draft.product.gmValuePerItem = gm(toNumber(draft.gmUsed), toNumber(draft.product.costPerItem));
  draft.product.gmValuePerKw = gm(toNumber(draft.gmUsed), toNumber(draft.product.costPerKw));
  draft.product.gmValuePerPanel = gm(toNumber(draft.gmUsed), toNumber(draft.product.costPerPanel));
};

/**
 * Calculate ex vat prices (Cost + GM) - Pre GS Split
 * @param draft
 */
export const exVatPriceFromCostAndGm = draft => {
  draft.product.exVatPricePerItem = toNumber(draft.product.costPerItem) + toNumber(draft.product.gmValuePerItem);
  draft.product.exVatPricePerKw = toNumber(draft.product.costPerKw) + toNumber(draft.product.gmValuePerKw);
  draft.product.exVatPricePerPanel = toNumber(draft.product.costPerPanel) + toNumber(draft.product.gmValuePerPanel);
};

/**
 * Split base price into Goods/ services for per item, per kw and per panel
 * @param draft
 */
export const exVatGoodsServicePrices = draft => {
  draft.product.exVatPricePerItemGoods = toNumber(draft.product.exVatPricePerItem) * toNumber(draft.product.gsRatioGoods);
  draft.product.exVatPricePerItemServices = toNumber(draft.product.exVatPricePerItem) * toNumber(draft.product.gsRatioServices);
  draft.product.exVatPricePerKwGoods = toNumber(draft.product.exVatPricePerKw) * toNumber(draft.product.gsRatioGoods);
  draft.product.exVatPricePerKwServices = toNumber(draft.product.exVatPricePerKw) * toNumber(draft.product.gsRatioServices);
  draft.product.exVatPricePerPanelGoods = toNumber(draft.product.exVatPricePerPanel) * toNumber(draft.product.gsRatioGoods);
  draft.product.exVatPricePerPanelServices = toNumber(draft.product.exVatPricePerPanel) * toNumber(draft.product.gsRatioServices);
};

/**
 * Calculate the Goods/Services Costs per Item/Kw/Panel
 * @param draft
 */
export const costGoodsServicePrices = draft => {
  // todo: do we even need these totals?
  draft.product.costTotal = 0;
  draft.product.costTotalGoods = 0;
  draft.product.costTotalServices = 0;

  draft.product.costPerItemGoods = toNumber(draft.product.costPerItem) * toNumber(draft.product.gsRatioGoods);
  draft.product.costPerItemServices = toNumber(draft.product.costPerItem) * toNumber(draft.product.gsRatioServices);
  draft.product.costPerKwGoods = draft.product.costPerKw * toNumber(draft.product.gsRatioGoods);
  draft.product.costPerKwServices = draft.product.costPerKw * toNumber(draft.product.gsRatioServices);
  draft.product.costPerPanelGoods = draft.product.costPerPanel * toNumber(draft.product.gsRatioGoods);
  draft.product.costPerPanelServices = draft.product.costPerPanel * toNumber(draft.product.gsRatioServices);
};

export const gmGoodsServicePrices = draft => {
  draft.product.gmValueTotal = draft.product.gmValuePerItem + draft.product.gmValuePerKw + draft.product.gmValuePerPanel;
  draft.product.gmValueTotalGoods = toNumber(draft.product.gmValueTotal) * toNumber(draft.product.gsRatioGoods);
  draft.product.gmValueTotalServices = toNumber(draft.product.gmValueTotal) * toNumber(draft.product.gsRatioServices);
};

export const costTotalGoods = (numberOfPanels, pvArrayOutput) => draft => {
  if (!draft.product) {
    // eslint-disable-next-line no-console
    console.warn('product missing in costTotalGoods');
    draft.quantity = 0;
    draft.product = {
      costTotal: 0,
      costTotalGoods: 0,
      costTotalServices: 0,
    };
    return;
  }

  // Per item
  const costPerItemTotal = toNumber(draft.quantity) * toNumber(draft.product.costPerItem);
  const costPerItemTotalGoods = draft.quantity * draft.product.costPerItemGoods;
  const costPerItemTotalServices = draft.quantity * draft.product.costPerItemServices;

  // Per kW
  const costPerKwTotal = pvArrayOutput * draft.product.costPerKw;
  const costPerKwTotalGoods = pvArrayOutput * draft.product.costPerKwGoods;
  const costPerKwTotalServices = pvArrayOutput * draft.product.costPerKwServices;

  // Per Panel
  let costPerPanelTotal = numberOfPanels * draft.product.costPerPanel;
  let costPerPanelTotalGoods = numberOfPanels * draft.product.costPerPanelGoods;
  let costPerPanelTotalServices = numberOfPanels * draft.product.costPerPanelServices;

  // UKSQA-1186 we need to override the number of panels for some SPECIFIC products
  if (reducePerPanelBy30Products.includes(draft.product.prodId)) {
    // const reducedNumberOfPanels = numberOfPanels - 30;
    const reducedNumberOfPanels = numberOfPanels;
    costPerPanelTotal = reducedNumberOfPanels * draft.product.costPerPanel;
    costPerPanelTotalGoods = reducedNumberOfPanels * draft.product.costPerPanelGoods;
    costPerPanelTotalServices = reducedNumberOfPanels * draft.product.costPerPanelServices;
  }

  draft.product.costTotal = costPerItemTotal + costPerKwTotal + costPerPanelTotal;
  draft.product.costTotalGoods = costPerItemTotalGoods + costPerKwTotalGoods + costPerPanelTotalGoods;
  draft.product.costTotalServices = costPerItemTotalServices + costPerKwTotalServices + costPerPanelTotalServices;
};
export const sumProductsByKey = (items, key) => items.map(x => x.product[key]).reduce((current, next) => current + next, 0);
export const calcTotals = (processed, fields = []) =>
  fields.reduce((current, next) => {
    current[next] = sumProductsByKey(processed, next);
    return current;
  }, {});
export const costGroupings = groupings => draft => {
  // foreach grouping.. sum the cost of all products in that grouping
  // console.log('groupings', groupings);
  draft.product.costTotalGoodsGroupSolar = 0;
  draft.product.costTotalGoodsGroupBattery = 0;
  draft.product.costTotalGoodsGroupAddon = 0;
  draft.product.costTotalGoodsGroupEv = 0;

  if (groupings.GOODS_TYPE_SOLAR_COSTS.includes(draft.product.type)) {
    draft.product.costTotalGoodsGroupSolar += draft.product.costTotalGoods;
  }
  if (groupings.GOODS_TYPE_BATTERY_COSTS.includes(draft.product.type)) {
    draft.product.costTotalGoodsGroupBattery += draft.product.costTotalGoods;
  }
  if (groupings.GOODS_TYPE_ADDON_COSTS.includes(draft.product.type)) {
    draft.product.costTotalGoodsGroupAddon += draft.product.costTotalGoods;
  }
  if (groupings.GOODS_TYPE_EV_COSTS.includes(draft.product.type)) {
    draft.product.costTotalGoodsGroupEv += draft.product.costTotalGoods;
  }
};
export const exVatTotalPricing = (numberOfPanels, pvArrayOutput) => draft => {
  if (!draft.product) {
    // eslint-disable-next-line no-console
    console.warn('product missing in exVatTotalPricing');
    draft.quantity = 0;
    draft.product = {
      exVatPerItemTotal: 0,
      exVatPerItemTotalGoods: 0,
      exVatPerItemTotalServices: 0,
      exVatPerKwTotal: 0,
      exVatPerKwTotalGoods: 0,
      exVatPerKwTotalServices: 0,
      exVatPerPanelTotal: 0,
      exVatPerPanelTotalGoods: 0,
      exVatPerPanelTotalServices: 0,
    };
    return;
  }

  draft.product.exVatPerItemTotal = toNumber(draft.quantity) * toNumber(draft.product.exVatPricePerItem);
  draft.product.exVatPerItemTotalGoods = draft.quantity * draft.product.exVatPricePerItemGoods;
  draft.product.exVatPerItemTotalServices = draft.quantity * draft.product.exVatPricePerItemServices;

  draft.product.exVatPerKwTotal = pvArrayOutput * draft.product.exVatPricePerKw;
  draft.product.exVatPerKwTotalGoods = pvArrayOutput * draft.product.exVatPricePerKwGoods;
  draft.product.exVatPerKwTotalServices = pvArrayOutput * draft.product.exVatPricePerKwServices;

  draft.product.exVatPerPanelTotal = numberOfPanels * draft.product.exVatPricePerPanel;
  draft.product.exVatPerPanelTotalGoods = numberOfPanels * draft.product.exVatPricePerPanelGoods;
  draft.product.exVatPerPanelTotalServices = numberOfPanels * draft.product.exVatPricePerPanelServices;

  // UKSQA-1186 we need to override the cost per panel for some SPECIFIC products
  if (reducePerPanelBy30Products.includes(draft.product.prodId)) {
    // const reducedNumberOfPanels = numberOfPanels - 30;
    const reducedNumberOfPanels = numberOfPanels;
    draft.product.exVatPerPanelTotal = reducedNumberOfPanels * draft.product.exVatPricePerPanel;
    draft.product.exVatPerPanelTotalGoods = reducedNumberOfPanels * draft.product.exVatPricePerPanelGoods;
    draft.product.exVatPerPanelTotalServices = reducedNumberOfPanels * draft.product.exVatPricePerPanelServices;
  }
};
export const exVatTotals = draft => {
  draft.product.exVatTotalGoods =
    draft.product.exVatPerItemTotalGoods + draft.product.exVatPerKwTotalGoods + draft.product.exVatPerPanelTotalGoods;

  draft.product.exVatTotalServices =
    draft.product.exVatPerItemTotalServices + draft.product.exVatPerKwTotalServices + draft.product.exVatPerPanelTotalServices;

  draft.product.exVatTotal = draft.product.exVatPerItemTotal + draft.product.exVatPerKwTotal + draft.product.exVatPerPanelTotal;
};
export const applyDiscount = percentage => draft => {
  draft.product.discountedExVatPerItemTotalGoods = draft.product.exVatPerItemTotalGoods;
  draft.product.discountedExVatPerItemTotalServices = draft.product.exVatPerItemTotalServices;
  draft.product.discountedExVatPerItemTotal = draft.product.exVatPerItemTotal;

  draft.product.discountedExVatPerKwTotalGoods = draft.product.exVatPerKwTotalGoods;
  draft.product.discountedExVatPerKwTotalServices = draft.product.exVatPerKwTotalServices;
  draft.product.discountedExVatPerKwTotal = draft.product.exVatPerKwTotal;

  draft.product.discountedExVatPerPanelTotalGoods = draft.product.exVatPerPanelTotalGoods;
  draft.product.discountedExVatPerPanelTotalServices = draft.product.exVatPerPanelTotalServices;
  draft.product.discountedExVatPerPanelTotal = draft.product.exVatPerPanelTotal;

  draft.product.discountedExVatTotalGoods = draft.product.exVatTotalGoods;
  draft.product.discountedExVatTotalServices = draft.product.exVatTotalServices;
  draft.product.discountedExVatTotal = draft.product.exVatTotal;

  draft.product.discounted = 0;

  if (draft.product.discountable) {
    const appliedDiscount = 1 - percentage;
    // discount is NOW applied to both goods & Services!
    draft.product.discountedExVatPerItemTotalGoods = draft.product.exVatPerItemTotalGoods * appliedDiscount;
    draft.product.discountedExVatPerItemTotalServices = draft.product.exVatPerItemTotalServices * appliedDiscount;
    draft.product.discountedExVatPerItemTotal =
      draft.product.discountedExVatPerItemTotalGoods + draft.product.discountedExVatPerItemTotalServices;

    draft.product.discountedExVatPerKwTotalGoods = draft.product.exVatPerKwTotalGoods * appliedDiscount;
    draft.product.discountedExVatPerKwTotalServices = draft.product.exVatPerKwTotalServices * appliedDiscount;
    draft.product.discountedExVatPerKwTotal =
      draft.product.discountedExVatPerKwTotalGoods + draft.product.discountedExVatPerKwTotalServices;

    draft.product.discountedExVatPerPanelTotalGoods = draft.product.exVatPerPanelTotalGoods * appliedDiscount;
    draft.product.discountedExVatPerPanelTotalServices = draft.product.exVatPerPanelTotalServices * appliedDiscount;
    draft.product.discountedExVatPerPanelTotal =
      draft.product.discountedExVatPerPanelTotalGoods + draft.product.discountedExVatPerPanelTotalServices;

    // now sum the things together
    draft.product.discountedExVatTotalGoods =
      draft.product.discountedExVatPerItemTotalGoods +
      draft.product.discountedExVatPerKwTotalGoods +
      draft.product.discountedExVatPerPanelTotalGoods;

    draft.product.discountedExVatTotalServices =
      draft.product.discountedExVatPerItemTotalServices +
      draft.product.discountedExVatPerKwTotalServices +
      draft.product.discountedExVatPerPanelTotalServices;

    draft.product.discountedExVatTotal = draft.product.discountedExVatTotalGoods + draft.product.discountedExVatTotalServices;

    // these values should be INC vat so will be moved to a later process
    // draft.product.discounted = draft.product.exVatTotal - draft.product.discountedExVatTotal;
    // draft.product.discountedP = divide(draft.product.exVatTotal, draft.product.discountedExVatTotal) - 1;
  }
};
export const expectedGmValue = draft => {
  // exVAT_after_discount_price - cost_pre_GM)
  draft.product.expectedGmValue = draft.product.discountedExVatTotal - draft.product.costTotal;
};
export const expectedGmPercentage = draft => {
  // expected_gm_percentage = expected_gm_value / (total_ex-VAT_after-discount_price)
  draft.product.expectedGmPercentage = draft.product.expectedGmValue / draft.product.discountedExVatTotal;
};

export const determineWhichGsVatRates = (customerIsEligibleForLowerVatRate, gsRatio, journey, leadCategory) => draft => {
  let whichVatRateGoods = VAT_LOWER_RATE; // batteryOnly OR (GSRatio > 0.6 AND NOT(eligibleCustomer)) ? vatHigherRate : vatLowerRate
  let whichVatRateServices = VAT_LOWER_RATE; //= batteryOnly ? vatHigherRate : vatLowerRate
  let whichVatRateGoodsReason = '';
  let whichVatRateServicesReason = '';

  if (draft.product.energyEfficiencyMeasure === false) {
    whichVatRateGoods = VAT_HIGHER_RATE;
    whichVatRateServices = VAT_HIGHER_RATE;
    whichVatRateGoodsReason = REASON_WHICH_VAT_RATE_G1;
    whichVatRateServicesReason = REASON_WHICH_VAT_RATE_S1;
  }

  /**
   * UKSQA-1432
   * ----------
   * None of the following is required when VAT rate becomes 0% for battery only.
   * Effective from 1st Feb 2024
   */
  // if (new Date() < new Date('2024-02-01')) {
  //   if (draft.product.energyEfficiencyMeasure === true) {
  //     if (journey.type === BATTERY_ONLY || (gsRatio > GS_RATIO_THRESHOLD && customerIsEligibleForLowerVatRate === false)) {
  //       whichVatRateGoods = VAT_HIGHER_RATE;
  //       whichVatRateGoodsReason = REASON_WHICH_VAT_RATE_G2;
  //     }
  //     if (journey.type === BATTERY_ONLY) {
  //       whichVatRateServices = VAT_HIGHER_RATE;
  //       whichVatRateServicesReason = REASON_WHICH_VAT_RATE_S2;
  //     }
  //   }
  // }

  // Lead category override VAT settings
  if (isCommercialCustomer(leadCategory)) {
    whichVatRateGoods = VAT_HIGHER_RATE;
    whichVatRateServices = VAT_HIGHER_RATE;
    whichVatRateGoodsReason = REASON_WHICH_VAT_RATE_G1;
    whichVatRateServicesReason = REASON_WHICH_VAT_RATE_S1;
  }

  draft.product.whichVatRateGoods = whichVatRateGoods;
  draft.product.whichVatRateServices = whichVatRateServices;
  draft.product.whichVatRateGoodsReason = whichVatRateGoodsReason;
  draft.product.whichVatRateServicesReason = whichVatRateServicesReason;
};

export const vatRates = draft => {
  draft.product.vatRateGoods = draft.product.whichVatRateGoods === VAT_LOWER_RATE ? VAT_RATE1 : VAT_RATE2;
  draft.product.vatRateServices = draft.product.whichVatRateServices === VAT_LOWER_RATE ? VAT_RATE1 : VAT_RATE2;
};
/**
 * Uses the (discounted) values to determine vat values
 * @param draft
 */
export const vatGs = draft => {
  draft.product.vatPerItemTotalGoods = draft.product.discountedExVatPerItemTotalGoods * draft.product.vatRateGoods;
  draft.product.vatPerItemTotalServices = draft.product.discountedExVatPerItemTotalServices * draft.product.vatRateServices;
  draft.product.vatPerItemTotal = draft.product.vatPerItemTotalGoods + draft.product.vatPerItemTotalServices;

  draft.product.vatPerKwTotalGoods = draft.product.discountedExVatPerKwTotalGoods * draft.product.vatRateGoods;
  draft.product.vatPerKwTotalServices = draft.product.discountedExVatPerKwTotalServices * draft.product.vatRateServices;
  draft.product.vatPerKwTotal = draft.product.vatPerKwTotalGoods + draft.product.vatPerKwTotalServices;

  draft.product.vatPerPanelTotalGoods = draft.product.discountedExVatPerPanelTotalGoods * draft.product.vatRateGoods;
  draft.product.vatPerPanelTotalServices = draft.product.discountedExVatPerPanelTotalServices * draft.product.vatRateServices;
  draft.product.vatPerPanelTotal = draft.product.vatPerPanelTotalGoods + draft.product.vatPerPanelTotalServices;
};
/**
 * Always uses the NON discounted values to determine vat values
 * - note: we do not want to store these values on the product chain
 *         they could be confused with the real VAT calcs and get exposed somewhere they shouldn't
 * @param draft
 */
export const preDiscountVatGs = draft => {
  // draft.product.exVatTotalGoods is the total goods, including quantities per item, per kW and per panel
  const preDiscountVatGoods = draft.product.exVatTotalGoods * draft.product.vatRateGoods;

  // draft.product.exVatTotalServices is the total services, including quantities per item, per kW and per panel
  const preDiscountVatServices = draft.product.exVatTotalServices * draft.product.vatRateServices;

  const preDiscountVatTotal = preDiscountVatGoods + preDiscountVatServices;

  // draft.product.exVatTotal is the total goods and services, including quantities per item, per kW and per panel
  draft.product.preDiscountIncVatTotal = preDiscountVatTotal + draft.product.exVatTotal;
};

export const discounted = draft => {
  if (draft.product.discountable) {
    draft.product.discounted = draft.product.preDiscountIncVatTotal - draft.product.incVatTotal;
    draft.product.discountedP = divide(draft.product.preDiscountIncVatTotal, draft.product.incVatTotal) - 1;
  }
};

export const vatPerRates = draft => {
  draft.product.vatAtRate1 = 0;
  draft.product.vatAtRate2 = 0;
  draft.product.amountOnWhichVatRate1Charged = 0;
  draft.product.amountOnWhichVatRate2Charged = 0;

  if (draft.product.whichVatRateGoods === VAT_LOWER_RATE) {
    draft.product.vatAtRate1 += draft.product.vatPerItemTotalGoods + draft.product.vatPerKwTotalGoods + draft.product.vatPerPanelTotalGoods;
    draft.product.amountOnWhichVatRate1Charged += draft.product.discountedExVatTotalGoods;
  }
  if (draft.product.whichVatRateServices === VAT_LOWER_RATE) {
    draft.product.vatAtRate1 +=
      draft.product.vatPerItemTotalServices + draft.product.vatPerKwTotalServices + draft.product.vatPerPanelTotalServices;
    draft.product.amountOnWhichVatRate1Charged += draft.product.discountedExVatTotalServices;
  }
  if (draft.product.whichVatRateGoods === VAT_HIGHER_RATE) {
    draft.product.vatAtRate2 += draft.product.vatPerItemTotalGoods + draft.product.vatPerKwTotalGoods + draft.product.vatPerPanelTotalGoods;
    draft.product.amountOnWhichVatRate2Charged += draft.product.discountedExVatTotalGoods;
  }
  if (draft.product.whichVatRateServices === VAT_HIGHER_RATE) {
    draft.product.vatAtRate2 +=
      draft.product.vatPerItemTotalServices + draft.product.vatPerKwTotalServices + draft.product.vatPerPanelTotalServices;
    draft.product.amountOnWhichVatRate2Charged += draft.product.discountedExVatTotalServices;
  }
};
export const incVat = draft => {
  draft.product.incVatPerItemTotal = draft.product.vatPerItemTotal + draft.product.discountedExVatPerItemTotal;
  draft.product.incVatPerKwTotal = draft.product.vatPerKwTotal + draft.product.discountedExVatPerKwTotal;
  draft.product.incVatPerPanelTotal = draft.product.vatPerPanelTotal + draft.product.discountedExVatPerPanelTotal;
  draft.product.incVatTotal = draft.product.incVatPerItemTotal + draft.product.incVatPerKwTotal + draft.product.incVatPerPanelTotal;
};
export const vatTotals = draft => {
  draft.product.vatTotalGoods = draft.product.vatPerItemTotalGoods + draft.product.vatPerKwTotalGoods + draft.product.vatPerPanelTotalGoods;

  draft.product.vatTotalServices =
    draft.product.vatPerItemTotalServices + draft.product.vatPerKwTotalServices + draft.product.vatPerPanelTotalServices;

  draft.product.vatTotal = draft.product.vatPerItemTotal + draft.product.vatPerKwTotal + draft.product.vatPerPanelTotal;
};
export const duplicateQuantity = draft => {
  // this is needed in table rendering loops
  draft.product.quantity = draft.quantity;
};
export const cleanRequires = draft => {
  if (Array.isArray(draft?.product.requires)) {
    draft.product.requires = [];
  }
};
export const displayPricesWithVat = draft => {
  // produces priceWithVatIndividual and priceWithVatRow
  draft.priceWithVatIndividual = divide(draft.product.incVatTotal, draft.quantity || 1);
  draft.priceWithVatRow = draft.product.incVatTotal;
};
