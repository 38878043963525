import * as Yup from 'yup';

const schema = Yup.object({
  dateFromIsDirty: Yup.boolean(),
  dateToIsDirty: Yup.boolean(),
  query: Yup.string().when(['dateFromIsDirty', 'dateFrom', 'dateToIsDirty', 'dateTo'], {
    is: (dateFromIsDirty, dateFrom, dateToIsDirty, dateTo) =>
      // this is only required IF we do not have a valid date range
      // prettier-ignore
      !(dateFromIsDirty && dateFrom !== "" && dateToIsDirty && dateTo !== ""),
    then: Yup.string()
      .min(2)
      .required('A valid query is required'),
  }),

  // we use $context here to avoid circular dependency in Yup object
  dateTo: Yup.string().when(['dateToIsDirty', '$dateFrom'], {
    is: (dateToIsDirty, dateFrom) => dateToIsDirty && dateFrom !== '',
    then: Yup.string().required('End date is required'),
  }),
  dateFrom: Yup.string().when(['dateFromIsDirty', 'dateTo'], {
    is: (dateFromIsDirty, dateTo) => dateFromIsDirty && dateTo !== '',
    then: Yup.string().required('From date is required'),
  }),
});

export const validate = context => {
  try {
    // we deliberately pass in context twice, see Yup circular dependency
    schema.validateSync(context, { abortEarly: false, context });
    return null;
  } catch (error) {
    return error;
  }
};

export default schema;
