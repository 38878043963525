/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

const Typography = () => (
  <>
    <h1>Heading text 1</h1>
    <h2>Heading text 2</h2>
    <h3>Heading text 3</h3>
    <h4>Heading text 4</h4>
    <h5>Heading text 5</h5>

    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. <a href="./">This is a link</a>. Consectetur corporis eveniet esse molestias
      magnam non. Nobis placeat quasi, magnam quam dolor maiores illum est, porro, asperiores nam voluptates totam obcaecati!
    </p>
    <p>
      <strong>This is bold!</strong> <em>this is italic</em>. Consectetur corporis eveniet esse molestias magnam non. Nobis placeat quasi,
      magnam quam dolor maiores illum est, porro, asperiores nam voluptates totam obcaecati!
    </p>
    <ul>
      <li>Bulleted list item 1</li>
      <li>Bulleted list item 2</li>
      <li>Bulleted list item 3</li>
      <li>Bulleted list item 4</li>
    </ul>
    <p className="range-right">
      This text is right aligned. Consectetur corporis eveniet esse molestias magnam non. Nobis placeat quasi, magnam quam dolor maiores
      illum est, porro, asperiores nam voluptates totam obcaecati!
    </p>
    <ol>
      <li>Numbered list item 1</li>
      <li>Numbered list item 2</li>
      <li>Numbered list item 3</li>
      <li>Numbered list item 4</li>
    </ol>
    <p className="range-center">
      This text is center aligned. Consectetur corporis eveniet esse molestias magnam non. Nobis placeat quasi, magnam quam dolor maiores
      illum est, porro, asperiores nam voluptates totam obcaecati!
    </p>
  </>
);

export default Typography;
