import React from 'react';
import classnames from 'classnames';
import IconButton from '@zing/neo-common/dist/components/IconButton';
import PropTypes from 'prop-types';

// todo: is this the same in both tables?
const TableRowIcon = ({ isExpanded, api }) => {
  const classNames = classnames('pipeline_icon');
  return (
    <div className="pipeline_table_row_icon" data-testid="pipeline_table_row_icon">
      <div className={classnames('pipeline_table_cell', 'tab_col')}>
        {isExpanded && <IconButton icon="chevron-down" className={classNames} onClick={() => api.close()} />}
        {!isExpanded && <IconButton icon="chevron-right" className={classNames} onClick={() => api.open()} />}
      </div>
    </div>
  );
};

TableRowIcon.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  api: PropTypes.shape({
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  }).isRequired,
};

export default TableRowIcon;
