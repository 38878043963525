import React from 'react';
import { Col } from '@zing/neo-common/dist/components/Grid';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './stat-container.scss';

const StatContainer = ({ description, children, type }) => (
  <Col className={classNames('stat_container', type)}>
    <h3>{description}</h3>
    {children}
  </Col>
);

StatContainer.propTypes = {
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['lime', 'bordeaux', 'turquoise']).isRequired,
};

export default StatContainer;
