export const MODE_READ = 'read';
export const MODE_UNREAD = 'unread';

const logic = (mode, notifications, processing, error, filterByReadStatus) => {
  const items = filterByReadStatus(notifications, mode === MODE_READ);
  return {
    displayItems: !processing && !error && items.length > 0,
    displayUnavailable: !processing && !error && items.length === 0,
    error,
    items,
    mode,
    processing,
  };
};

export default logic;
