import { appointmentMatchUrl } from '../../../../utils/opportunity';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { withRouter } from 'react-router-dom';
import BackButton from '../../../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import PropTypes from 'prop-types';
import React from 'react';

const Actions = ({ match }) => (
  <div className="actions">
    <Grid valign="center" align="right">
      <Col width="auto">
        <BackButton link={appointmentMatchUrl(match, ['survey', 'additional-products'])} />
        <Button format="primary" link={appointmentMatchUrl(match, ['survey', 'payment-options'])} testId="payment-options">
          Payment options
        </Button>
      </Col>
    </Grid>
  </div>
);

Actions.propTypes = {
  match: PropTypes.shape({}).isRequired,
};
export default withRouter(Actions);
