import { getNumber, toFixed } from '@zing/neo-common/dist/lib/safe';
import { pounds, poundsPence } from '@zing/neo-common/dist/lib/display';
import classnames from 'classnames';
import last from 'lodash.last';
import PropTypes from 'prop-types';
import React from 'react';
import usePimSurveyMachine from '../../contexts/survey-context';

import './future-cashflow.scss';

const FutureCashflow = ({ expanded }) => {
  const { current } = usePimSurveyMachine();
  const { currentComparison } = current.context;
  const { calcs } = current.context.comparisons[currentComparison];
  const cashflow = calcs?.cashflow || [];
  return (
    <section className={classnames('future-cashflow', expanded ? 'expanded' : null)}>
      <h1 className="eon-red">Future cashflow</h1>

      <table className={classnames(['future-cashflow__table', expanded ? 'expanded' : null])}>
        <thead>
          <tr>
            <th className="year">Year</th>
            <th className="pag lime">
              PV annual
              <br /> generation (kWh)
            </th>
            <th className="ai lime">
              Avoided
              <br /> imports (kWh)
            </th>
            <th className="er turq">
              Export rate
              <br /> (&pound;/kWh)
            </th>
            <th className="ep turq">
              Electricity price
              <br /> (&pound;/kWh)
            </th>
            <th className="ei red">
              Export
              <br /> income (&pound;)
            </th>
            <th className="ais red">
              Avoided import
              <br /> saving (&pound;)
            </th>
            <th className="ctb bordeaux">
              Cumulative total
              <br /> benefits (&pound;)
            </th>
          </tr>
        </thead>
        <tbody>
          {cashflow.map(item => (
            <tr key={item.year}>
              <td className="year">{item.year}</td>
              <td className="pag">{toFixed(item.annualGeneration)}</td>
              <td className="ai">{toFixed(item.avoidedImport)}</td>
              <td className="er">{poundsPence(item.exportRate)}</td>
              <td className="ep">{poundsPence(item.electricityPrice)}</td>
              <td className="ei">{poundsPence(item.exportIncome)}</td>
              <td className="ais">{poundsPence(item.avoidedImportSaving)}</td>
              <td className="ctb">{poundsPence(item.cumulativeTotalBenefit)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="range-right">
        <h3 className="benefits-message">
          25 year benefits <span data-testid="cashflow__benefits">{pounds(getNumber(last(cashflow), 'cumulativeTotalBenefit'))}</span>
        </h3>
      </div>
    </section>
  );
};

FutureCashflow.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default FutureCashflow;
