import { api } from '@zing/neo-common/dist/lib/api';
import { Form, Formik } from 'formik';
import { get } from '@zing/neo-common/dist/lib/safe';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import Button from 'hollywood/dist/components/Button';
import dateFormat from 'date-fns/format';
import Input from 'hollywood/dist/components/forms/Input';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DiagnosticsHeader from '../../../components/diagnostics-header';

/**
 * Make the fetchMessages service function
 * @param type requests | responses
 * @returns {function(*, {params?: *, accessToken: *}): *}
 */
export const makeFetchMessages = (type = 'requests') => (c, { params = '', accessToken }) =>
  api({
    accessToken,
    method: 'GET',
    url: `/crm/messages/${type}?${encodeURI(params)}`,
  })
    .then(response => response.data || [])
    .catch(error => {
      throw new Error(error.response.data || error);
    });

export const CrmMessageListTableItem = ({ message, index, type }) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <tr
      className={index % 2 === 1 ? 'highlight-row' : ''}
      onClick={() => {
        setShowDetail(!showDetail);
      }}
    >
      {showDetail === true && (
        <>
          <td colSpan={5}>
            <pre>{JSON.stringify(message, null, 2)}</pre>
          </td>
        </>
      )}
      {showDetail === false && (
        <>
          <td>{dateFormat(new Date(message.created_at), 'D/M/YYYY HH:mm:ss')}</td>
          <td>{get(message, 'json_blob.MessageName', '')}</td>
          <td>{message.lnumber}</td>
          <td>{message.crm_id}</td>
          {type === 'requests' && <td>{message.response}</td>}
          {type === 'responses' && <td>{message.processed_status}</td>}
        </>
      )}
    </tr>
  );
};

CrmMessageListTableItem.propTypes = {
  message: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    crm_id: PropTypes.string.isRequired,
    lnumber: PropTypes.string.isRequired,
    processed_status: PropTypes.string.isRequired,
    response: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export const CrmMessageListTable = ({ messages, type }) => (
  <table className="">
    <thead>
      <tr>
        <td className="subHeading" width="15%">
          Created at
        </td>
        <td className="subHeading" width="30%">
          Message name
        </td>
        <td className="subHeading" width="15%">
          lnumber
        </td>
        <td className="subHeading" width="15%">
          Crm ID
        </td>
        {type === 'requests' && (
          <td width="25%" className="subHeading">
            Response
          </td>
        )}
        {type === 'responses' && (
          <td width="20%" className="subHeading">
            Processed Status
          </td>
        )}
      </tr>
    </thead>
    <tbody>
      {messages.map((message, index) => (
        <CrmMessageListTableItem message={message} index={index} type={type} />
      ))}
    </tbody>
  </table>
);

CrmMessageListTable.propTypes = {
  messages: PropTypes.shape([]).isRequired,
  type: PropTypes.string.isRequired,
};

export const PageForm = ({ current, send, accessToken, type }) => {
  const title = `CRM Message ${type === 'requests' ? 'Requests' : 'Responses'}`;
  return (
    <>
      <DiagnosticsHeader title={title} />
      <main className="diagnostics">
        <Formik
          initialValues={{ date_from: '', date_to: '', lnumber: '', limit: '10' }}
          onSubmit={formValues => {
            const params = Object.keys(formValues)
              .map(key => (formValues[key] !== '' ? `${key}=${formValues[key]}` : null))
              .filter(x => x !== null)
              .join('&');

            send({ type: 'SEARCH', params, accessToken });
          }}
          render={({ values, handleChange }) => (
            <>
              <Form className="crm_messages" data-testid="crm_messages">
                <Grid className="" gutters="xl">
                  <Col width="12">
                    <Grid gutters="sm">
                      <Col width="3">
                        <label htmlFor="date_from">Date from</label>
                      </Col>
                      <Col width="3">
                        <label htmlFor="date_to">Date to</label>
                      </Col>
                      <Col width="2">
                        <label htmlFor="lnumber">LNumber</label>
                      </Col>
                      <Col width="2">
                        <label htmlFor="limit">Limit</label>
                      </Col>
                    </Grid>
                  </Col>
                  <Col width="12">
                    <Grid gutters="sm" align="left">
                      <Col width="3">
                        <Input
                          type="date"
                          name="date_from"
                          id="date_from"
                          placeholder="From"
                          testId="date_from_filter"
                          max={values.date_to}
                        />
                      </Col>
                      <Col width="3">
                        <Input type="date" name="date_to" id="date_to" placeholder="To" testId="date_to_filter" min={values.date_from} />
                      </Col>
                      <Col width="2">
                        <Input name="lnumber" title="LNumber" />
                      </Col>
                      <Col width="2">
                        <select name="limit" id="limit" data-testid="limit" className="hollywood-select" onChange={handleChange}>
                          <option value="10">10</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </select>
                      </Col>
                      <Col width="2">
                        <Button format="primary" size="medium" type="submit">
                          {current.matches('error') ? 'Retry' : 'Search'}
                        </Button>
                      </Col>
                    </Grid>
                  </Col>
                </Grid>
              </Form>
              <Grid gutters="xl">
                <Col width="12">
                  <br />
                  <br />
                  {current.matches('error') && (
                    <>
                      <h2>There was an error</h2>
                      <div>{current.context.error.message || 'Unknown error'}</div>
                    </>
                  )}
                  {current.matches('success') && current.context.messages.length > 0 && (
                    <CrmMessageListTable messages={current.context.messages} type={type} />
                  )}
                  {current.matches('success') && current.context.messages.length === 0 && <div>No results.</div>}
                </Col>
              </Grid>
            </>
          )}
        />
      </main>
    </>
  );
};

PageForm.propTypes = {
  current: PropTypes.shape({
    matches: PropTypes.func.isRequired,
    context: PropTypes.shape({
      error: PropTypes.bool.isRequired,
      messages: PropTypes.shape([]).isRequired,
    }).isRequired,
  }).isRequired,
  send: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
