import React, { useReducer, useEffect } from 'react';
// import _set from 'lodash.set';
import PropTypes from 'prop-types';

import Button from 'hollywood/dist/components/Button';
import OnArrival from './sections/on-arrival';
import MeetCustomer from './sections/meet-customer';
import StartSurvey from './sections/start-survey';
import Afterwards from './sections/afterwards';
import initialState from './initial-state';

import powraReducer from './powra-reducer';
import { isValid } from './utils';
import './powra-form.scss';

function PowraForm({ sections, values, onSubmit, onCancel, showDebug = false, showCancel = false }) {
  // Form state (values, touched, errors, submitCount)
  const [state, dispatch] = useReducer(powraReducer, { ...initialState, values: { ...initialState.values, ...values } });

  useEffect(() => {
    dispatch({ type: 'MOUNT' });
  }, []);
  // Event Handlers
  const handleChange = e => dispatch({ type: 'CHANGE', name: e.target.name, value: e.target.value });
  const handleBlur = e => dispatch({ type: 'BLUR', name: e.target.name });
  const handleSubmit = () => {
    dispatch({ type: 'SUBMIT' });
    if (!isValid(state)) {
      return;
    }
    onSubmit(state.values);
  };

  return (
    <>
      {sections.includes('onArrival') && <OnArrival state={state} handleChange={handleChange} handleBlur={handleBlur} />}
      {sections.includes('meetCustomer') && <MeetCustomer state={state} handleChange={handleChange} handleBlur={handleBlur} />}
      {sections.includes('startSurvey') && <StartSurvey state={state} handleChange={handleChange} handleBlur={handleBlur} />}
      {sections.includes('afterwards') && <Afterwards state={state} handleChange={handleChange} handleBlur={handleBlur} />}
      {showDebug && <pre style={{ fontFamily: 'monospace', fontSize: 12, lineHeight: 1.3 }}>{JSON.stringify(state, null, 2)}</pre>}
      <div className="range-right">
        {showCancel && (
          <Button type="button" format="third" onClick={onCancel} testId="powra-cancel">
            Cancel
          </Button>
        )}
        <Button type="button" format="primary" onClick={handleSubmit} testId="powra-submit">
          Save
        </Button>
      </div>
    </>
  );
}

PowraForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.oneOf(['onArrival'])).isRequired,
  showCancel: PropTypes.bool,
  showDebug: PropTypes.bool,
  values: PropTypes.shape({}),
};
PowraForm.defaultProps = {
  showCancel: false,
  showDebug: false,
  values: {},
};

export default PowraForm;
