import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { crmMachineConfig } from '../../../machines/crm-messages.machine';
import { Machine } from 'xstate';
import { useMachine } from '@xstate/react';
import { makeFetchMessages, PageForm } from './crm-message-components';

const machine = Machine(crmMachineConfig, {
  services: {
    fetchMessages: makeFetchMessages('responses'),
  },
});

const CrmMessageResponsesPage = ({ auth }) => {
  const [current, send] = useMachine(machine);
  const accessToken = auth.access_token;
  return <PageForm accessToken={accessToken} current={current} send={send} type="responses" />;
};

CrmMessageResponsesPage.propTypes = {
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({ auth }) => ({ auth }))(CrmMessageResponsesPage);
