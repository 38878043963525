import { clamp, fixedFloat, range } from '@zing/neo-common/dist/lib/number';
import { toFloat, toInt } from '@zing/neo-common/dist/lib/safe';

/**
 * This function returns the yearly degradation figures for a known product
 *
 * ORIGINAL EXCEL EXAMPLE
 * (yearly degradation - matrix lookup for specific panel)
 * = INDEX(PVDetails.tb, MATCH('Indicative Costs'!$D$9, PVNames.list, 0), MATCH('Price Lookups'!$P$3, PVDetails.headings, 0))
 * (compound degradation)
 * = Q6 * (1 - R6)
 *
 * @param yearlyDegradationMatrix
 * @param forYears
 * @returns {*[]}
 */
export const compoundDegradation = (yearlyDegradationMatrix, forYears) => {
  // console.log('Compound Degradation:');
  // console.log({ yearlyDegradationMatrix, years: forYears });
  let result = [];

  const years = clamp(toInt(forYears), 1, 100);

  range(1, years).map(year => {
    // in year 1, degradation is 0%
    if (year === 1) {
      result[0] = 1;
      return null;
    }
    let degradation = 0;
    if (typeof yearlyDegradationMatrix[year - 2] !== 'undefined') {
      // if a specific degradation value exists in array
      degradation = toFloat(yearlyDegradationMatrix[year - 2]);
    } else {
      // use last defined element in yearly degradation array for all further calculations
      degradation = toFloat(yearlyDegradationMatrix[yearlyDegradationMatrix.length - 1]);
    }

    result[year - 1] = toFloat(result[year - 2]) - toFloat(degradation) / 100;

    return null;
  });

  // after calculations are done - round to 3dp
  result = result.map(x => Math.max(fixedFloat(x, 3), 0));
  // console.log({ result });
  return result;
};
