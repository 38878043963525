import { fetchPresenter } from '../redux/presenter/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const useFetchPresenter = presenter => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!presenter) {
      dispatch(fetchPresenter());
    }
  }, [dispatch, presenter]);
};

export default useFetchPresenter;
