import * as actionTypes from './action-types';
import initialState from './initialstate';
import produce from 'immer';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { cleanQuoteBeforeStoringInState } from '../session/reducers/quotes';
import { PIM_CREATE_QUOTE, PIM_CREATE_QUOTE_COMMIT } from '../session/actionTypes';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';

const pimSession = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_ALL:
        return initialState;

      case actionTypes.RESET_PIM_SURVEY:
        return initialState;

      case actionTypes.RESET_PIM_SURVEY_QUOTES: {
        draft.quotes = [];
        break;
      }

      case actionTypes.START_PIM_SURVEY: {
        draft.quotes = [];
        draft.surveyMachine = undefined; // clear last state machine session
        break;
      }

      case PIM_CREATE_QUOTE: {
        draft.quotes.push(cleanQuoteBeforeStoringInState(action.payload));
        break;
      }

      case PIM_CREATE_QUOTE_COMMIT: {
        const idx = draft.quotes.findIndex(quote => appUuid(quote) === appUuid(action.payload));
        if (idx > -1) {
          draft.quotes[idx] = cleanQuoteBeforeStoringInState(action.payload);
        }
        break;
      }
    }
    return draft;
  });

export default pimSession;
