import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';

import './dashboard-nav.scss';

const DashboardNav = ({ Icon, isNotificationActive, NavLink }) => (
  <div className="dashboard_nav" data-testid="dashboard_nav">
    <Grid>
      <Col>
        <NavLink to="/opportunities" activeClassName="active" data-testid="appointments_link">
          <Icon icon="calendar" />
          Appointments
        </NavLink>
      </Col>
      <Col>
        <NavLink to="/pipeline/pipelineSearch" activeClassName="active" data-testid="pipeline_link">
          <Icon icon="filter" />
          Pipeline
        </NavLink>
      </Col>
      <Col>
        <NavLink to="/notifications/unread" activeClassName="active" isActive={isNotificationActive} data-testid="notifications_link">
          <Icon icon="notification" />
          Notifications
        </NavLink>
      </Col>
    </Grid>
  </div>
);

DashboardNav.propTypes = {
  Icon: PropTypes.node.isRequired,
  isNotificationActive: PropTypes.func.isRequired,
  NavLink: PropTypes.node.isRequired,
};

export default DashboardNav;
