import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Offline = ({ shaded }) => (
  <div className={classnames('pipeline_quote_is_offline', shaded ? 'shaded' : '')} data-testid="pipeline_quote_is_offline">
    <div className="range-center">
      <p>You are currently offline, this feature is not available.</p>
    </div>
  </div>
);

Offline.propTypes = {
  shaded: PropTypes.bool.isRequired,
};

export default Offline;
