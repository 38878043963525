import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { showPercentSign } from '../product-tables/utils';
import { toFixedNumber } from '@zing/neo-common/dist/lib/safe';
import ProductTableDataDriven from '../product-table-data-driven';
import PropTypes from 'prop-types';
import React from 'react';

const TableHead = ({ pvArrayOutput, numberOfPanels }) => (
  <thead>
    <tr>
      <th colSpan={4} width="24%">
        &nbsp;
      </th>
      <th colSpan={12} className="center productPricing">
        Ex Vat Pricing (Inc quantity)
      </th>
    </tr>
    <tr>
      <th colSpan={2}>&nbsp;</th>
      <th colSpan={2} className="gs center">
        GS Ratio
      </th>
      <th className="center perItem" colSpan={3}>
        Per item * Quantity
      </th>
      <th className="center perKw" colSpan={3}>
        Per kW * {toFixedNumber(pvArrayOutput, 2)} kW
      </th>
      <th className="center perPanel" colSpan={3}>
        Per Panel * {numberOfPanels} Panels
      </th>
      <th className="center exVatHeading" colSpan={3}>
        Summed Totals
      </th>
    </tr>
    <tr>
      <th className="left">Prod ID</th>
      <th className="center">Quantity</th>
      <th className="center gs">Goods</th>
      <th className="center gs">Services</th>

      {/* ExVat Total */}
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Total</th>

      {/* ExVat Per Kw */}
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Total</th>

      {/* ExVat Per Panel */}
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Total</th>

      {/* ExVat Per Totals */}
      <th className="money exVat">Goods</th>
      <th className="money exVat">Services</th>
      <th className="money exVat">Total</th>
    </tr>
  </thead>
);

TableHead.propTypes = {
  pvArrayOutput: PropTypes.shape([]).isRequired,
  numberOfPanels: PropTypes.number.isRequired,
};

const rowConfig = [
  { field: 'prodId', className: 'left', pipeline: [], totals: false, totalClassName: 'noBorder' },
  { field: 'quantity', className: 'center', pipeline: [], totals: false, totalClassName: 'noBorder' },
  { field: 'gsRatioGoods', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'gsRatioServices', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'exVatPerItemTotalGoods', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerItemTotalServices', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerItemTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerKwTotalGoods', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerKwTotalServices', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerKwTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerPanelTotalGoods', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerPanelTotalServices', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatPerPanelTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatTotalGoods', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatTotalServices', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'exVatTotal', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
];

const ProductExVatPricingTable = ({ selectedProducts = [], pvArrayOutput, numberOfPanels }) => (
  <ProductTableDataDriven
    title="Product Ex-Vat pricing"
    values={selectedProducts}
    rowConfig={rowConfig}
    TableHead={<TableHead numberOfPanels={numberOfPanels} pvArrayOutput={pvArrayOutput} />}
  />
);

ProductExVatPricingTable.propTypes = {
  selectedProducts: PropTypes.shape({}).isRequired,
  pvArrayOutput: PropTypes.shape({}).isRequired,
  numberOfPanels: PropTypes.number.isRequired,
};

export default ProductExVatPricingTable;
