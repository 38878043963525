import React, { useContext } from 'react';
import Toggle from '../../hollywood-xstate/toggle';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import {
  FILTER_BATTERY_INVERTER_AC_CONNECTED,
  FILTER_BATTERY_INVERTER_BOTH,
  FILTER_BATTERY_INVERTER_HYBRID,
} from '@zing/neo-common/dist/config';

const BatteryInverterToggle = () => {
  const {
    current: {
      context: { currentComparison, comparisons },
    },
    send,
  } = useContext(PimSurveyMachineContext);

  if (comparisons[currentComparison].pimComparison?.getJourney()?.hasSolarPV === false) {
    return null;
  }

  // number of roofs!
  const { roofs } = comparisons[currentComparison];
  if (Array.isArray(roofs) && roofs.length >= 3) {
    return null;
  }

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>Battery inverter filter</p>
        </Col>
        <Col width="fixed" className="right-col" testId="meterType">
          <Toggle
            name={`context.comparisons.${currentComparison}.batteryInverterToggle`}
            options={[FILTER_BATTERY_INVERTER_HYBRID, FILTER_BATTERY_INVERTER_AC_CONNECTED, FILTER_BATTERY_INVERTER_BOTH]}
            goodOption={[FILTER_BATTERY_INVERTER_HYBRID, FILTER_BATTERY_INVERTER_AC_CONNECTED]}
            value={comparisons[currentComparison].batteryInverterToggle}
            onChange={(_, value) => {
              send({ type: 'BATTERY_INVERTER_TOGGLE', currentComparison, value });
            }}
          />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default BatteryInverterToggle;
