import { ZONE_DATA } from '../../CalculationsData';
import { clamp, parseInt10 } from '@zing/neo-common/dist/lib/number';
import { toString } from '../../../lib/to-string';

// IMPORTANT! DO NOT CHANGE TO NUMBERS, THIS IS A STRING LOOKUP
// define valid zone ids (array used in case one is removed from set)
const validZones = [
  '1',
  '2',
  '3',
  '4',
  '5E',
  '5W',
  '6',
  '7E',
  '7W',
  '8E',
  '8S',
  '9E',
  '9S',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
];

/**
 * This function returns a data value from the matrix of pre-defined array values based on orientation and inclination for each MCS zone
 *
 * returns annual kWh yield per kW of unshaded array
 *
 * STATIC DATA
 *
 *   {zone : { orientation : [inclination,...]}
 *
 *
 * @param zone
 * @param roofInclination
 * @param roofOrientation
 * @returns {boolean|*}
 */
export const roofYieldStaticMatrix = (zone, roofInclination, roofOrientation) => {
  if (validZones.indexOf(toString(zone)) === -1) {
    return false;
  }
  const inclination = clamp(parseInt10(roofInclination), 0, 90);
  let orientation = clamp(Math.abs(parseInt10(roofOrientation)), 0, 175);
  orientation = Math.round(orientation / 5) * 5;
  return ZONE_DATA[zone][orientation][inclination];
};
