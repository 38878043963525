import { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { useService } from '@xstate/react';

const usePipelineSearchService = () => {
  const app = useContext(AppContext);
  const [appState] = useService(app);
  return useService(appState?.context?.pipelineSearch);
};

export default usePipelineSearchService;
