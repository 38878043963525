import { connect } from 'react-redux';
import { fetchPimAsPromise, importPim } from '../../../redux/pim/actions';
import { FILTER_1_PHASE, FILTER_3_PHASE } from '@zing/neo-common/dist/redux/product/accessor';
import { Formik, Form } from 'formik';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import Button from 'hollywood/dist/components/Button';
import Modal from 'hollywood/dist/components/Modal';
import classnames from 'classnames';
import pimAccessor from '@zing/neo-common/dist/accessors/pim';
import PimComparison from '@zing/neo-common/dist/accessors/pim-comparison';
import pimProductTypes from '@zing/neo-common/dist/accessors/pim-product-types';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import Toggle from 'hollywood/dist/components/forms/Toggle/Toggle';
import {
  FILTER_BATTERY_INVERTER_AC_CONNECTED,
  FILTER_BATTERY_INVERTER_BOTH,
  FILTER_BATTERY_INVERTER_HYBRID,
} from '@zing/neo-common/dist/config';
import DiagnosticsHeader from '../../../components/diagnostics-header';

import './packages.scss';
import ProductStatus from '../../../components/ProductStatus';
import PIMInfo from '../../../components/pim-info';
import { TextareaField } from '@zing/neo-common/dist/components/forms/fields/TextareaField';

const noop = () => {};
const initialValues = {
  addon: '',
  battery: '',
  fixing: '',
  installation: '',
  inverter: '',
  meteringKit: '',
  package: '',
  panel: '',
  scaffolding: '',
  type: '',
  isolator: '',
  smartMeter: '',
};

const ListTableItem = ({ item, index, onSelect, selected, setSelected }) => (
  <tr
    className={classnames({ 'highlight-row': index % 2 === 1 }, { selected: selected === item.id })}
    onClick={() => {
      setSelected(item.id);
      onSelect(item.id);
    }}
  >
    <>
      <td>{item.prodId}</td>
      <td>{item.name}</td>
      <td>{item.type}</td>
      <td>
        <ProductStatus status={item.prodStatus} />
      </td>
    </>
  </tr>
);

ListTableItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    prodId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    prodStatus: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export const ListTable = ({ items = [], onSelect }) => {
  const [selected, setSelected] = useState(null);
  if (items.length === 0) {
    return null;
  }

  return items.map((item, index) => (
    <ListTableItem item={item} index={index} onSelect={onSelect} selected={selected} setSelected={setSelected} />
  ));
};

ListTable.propTypes = {
  items: PropTypes.shape([]).isRequired,
  onSelect: PropTypes.func.isRequired,
};

const TableHeaderRow = ({ title }) => (
  <>
    <tr>
      <td colSpan="4" className="tableHeaderRowTitle">
        <h2>{title}</h2>
      </td>
    </tr>
    <tr>
      <td className="subHeading">Prod ID</td>
      <td className="subHeading">Name</td>
      <td className="subHeading">Type</td>
      <td className="subHeading">Status</td>
    </tr>
  </>
);

TableHeaderRow.propTypes = {
  title: PropTypes.string.isRequired,
};

/**
 * PIM packages and products are already filtered in the reducer, so we dont need to access then via the accessor here
 *
 * @param pim
 * @returns {*}
 * @constructor
 */
const PimPackagesPage = ({ pim, fetchPimAsPromise, importPim, user }) => {
  const [importingPlytix, importingPlytixSet] = useState(false);
  const accessor = useMemo(() => pimAccessor(pim), [pim]);
  const comparison = useMemo(() => new PimComparison(accessor), [accessor]);
  const canImportPlytix = user?.role === 'Admin' || user?.id === 3 || user?.id === 19;

  const [showPimImportNotesModal, setShowPimImportNotesModal] = useState(false);

  return (
    <>
      <DiagnosticsHeader title="PIM Packages">
        <div className="diagnostics-header-buttons">
          {canImportPlytix && (
            <Button
              loading={importingPlytix}
              disabled={importingPlytix}
              onClick={() => {
                setShowPimImportNotesModal(true);
              }}
              format="primary"
            >
              Import Plytix
            </Button>
          )}
          <Button
            disabled={importingPlytix}
            onClick={() => {
              fetchPimAsPromise();
            }}
            format="primary"
          >
            Refresh PIM
          </Button>
        </div>
      </DiagnosticsHeader>
      <main className="diagnostics">
        <PIMInfo pim={pim} />
        <Formik
          initialValues={initialValues}
          render={({ values, setValues, setFieldValue }) => {
            const meteringKits = values.inverter ? accessor.requiresProducts(Number(values.inverter)) : [];
            const meteringItems = values.metering_kit
              ? accessor.requiresProductsByType(Number(values.metering_kit), pimProductTypes.meters)
              : [];
            const optimisers = values.inverter
              ? accessor.requiresProductsByType(Number(values.inverter), pimProductTypes.pvPanelOptimiser)
              : [];
            const batteryComponents = values.inverter
              ? accessor.requiresProductsByTypes(Number(values.inverter), [
                  pimProductTypes.batteryController,
                  pimProductTypes.batteryModule,
                ])
              : [];

            return (
              <>
                <Grid gutters="xl">
                  <Col width="12">
                    <Grid gutters="sm">
                      <Col width="4">
                        <label htmlFor="package">Package</label>
                        <select
                          name="package"
                          className="hollywood-select"
                          onChange={e => {
                            const { meterType, batteryInverterToggle } = values;
                            const prodId = e.target.value;
                            comparison
                              .setPackage(prodId)
                              .setMeterType(meterType)
                              .setBatteryInverterToggle(batteryInverterToggle);

                            setValues({
                              ...initialValues,
                              package: prodId,
                              meterType,
                              batteryInverterToggle,
                            });
                          }}
                        >
                          <option value="">All</option>
                          {comparison.getPackages().map(item => (
                            <option value={item.prodId}>
                              {item.prodId} - {item.name}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col width="2">
                        <label htmlFor="meterType">Meter type</label>
                        <select
                          name="meterType"
                          className="hollywood-select"
                          onChange={e => {
                            const meterType = e.target.value;
                            comparison.setMeterType(meterType);
                            setFieldValue('meterType', meterType);
                          }}
                        >
                          <option value="">All</option>
                          <option value={FILTER_1_PHASE}>{FILTER_1_PHASE}</option>
                          <option value={FILTER_3_PHASE}>{FILTER_3_PHASE}</option>
                        </select>
                      </Col>
                      <Col width="4">
                        <label htmlFor="batteryInverterToggle">Battery inverter filter</label>
                        <Toggle
                          name="batteryInverterToggle"
                          id="batteryInverterToggle"
                          testId="batteryInverterToggle"
                          options={[FILTER_BATTERY_INVERTER_HYBRID, FILTER_BATTERY_INVERTER_AC_CONNECTED, FILTER_BATTERY_INVERTER_BOTH]}
                          goodOption={FILTER_BATTERY_INVERTER_HYBRID}
                          badOption={FILTER_BATTERY_INVERTER_AC_CONNECTED}
                          onChange={(e, v) => {
                            const batteryInverterToggle = v;
                            comparison.setBatteryInverterToggle(batteryInverterToggle);
                            setFieldValue('batteryInverterToggle', batteryInverterToggle);
                          }}
                        />
                      </Col>
                    </Grid>
                  </Col>
                  <table
                    width="100%"
                    style={{
                      tableLout: 'fixed',
                    }}
                  >
                    <tbody>
                      {comparison.getPanels().length > 0 && (
                        <>
                          <TableHeaderRow title="Panels" />
                          <ListTable items={comparison.getPanels()} onSelect={value => setFieldValue('panel', value)} />
                        </>
                      )}
                      {comparison.getInverters().length > 0 && (
                        <>
                          <TableHeaderRow title="PV Inverters" />
                          <ListTable items={comparison.getInverters()} onSelect={value => setFieldValue('inverter', value)} />
                        </>
                      )}
                      {comparison.getBatteries().length > 0 && (
                        <>
                          <TableHeaderRow title="Batteries" />
                          <ListTable items={comparison.getBatteries()} onSelect={value => setFieldValue('battery', value)} />
                        </>
                      )}
                      {comparison.getFixings().length > 0 && (
                        <>
                          <TableHeaderRow title="Fixings" />
                          <ListTable items={comparison.getFixings()} onSelect={value => setFieldValue('fixing', value)} />
                        </>
                      )}
                      {comparison.getAddons().length > 0 && (
                        <>
                          <TableHeaderRow title="Addons" />
                          <ListTable items={comparison.getAddons()} onSelect={value => setFieldValue('addon', value)} />
                        </>
                      )}
                      {comparison.getIsolators().length > 0 && (
                        <>
                          <TableHeaderRow title="Isolators" />
                          <ListTable items={comparison.getIsolators()} onSelect={value => setFieldValue('isolator', value)} />
                        </>
                      )}
                      {comparison.getIsolators().length > 0 && (
                        <>
                          <TableHeaderRow title="Isolators" />
                          <ListTable items={comparison.getIsolators()} onSelect={value => setFieldValue('isolator', value)} />
                        </>
                      )}
                      {comparison.getSmartMeters().length > 0 && (
                        <>
                          <TableHeaderRow title="Smart Meters" />
                          <ListTable items={comparison.getSmartMeters()} onSelect={value => setFieldValue('smartMeter', value)} />
                        </>
                      )}
                      {comparison.getInstallations().length > 0 && (
                        <>
                          <TableHeaderRow title="Installation" />
                          <ListTable items={comparison.getInstallations()} onSelect={value => setFieldValue('installation', value)} />
                        </>
                      )}
                      {comparison.getScaffolding().length > 0 && (
                        <>
                          <TableHeaderRow title="Scaffolding" />
                          <ListTable items={comparison.getScaffolding()} onSelect={value => setFieldValue('scaffolding', value)} />
                        </>
                      )}
                      {meteringKits.length > 0 && (
                        <>
                          <TableHeaderRow title="Metering kit" />
                          <ListTable items={meteringKits} onSelect={value => setFieldValue('meteringKit', value)} startsCollapsed={false} />
                        </>
                      )}
                      {meteringItems.length > 0 && (
                        <>
                          <TableHeaderRow title="Required metering items" />
                          <ListTable items={meteringItems} onSelect={noop} startsCollapsed={false} />
                        </>
                      )}
                      {optimisers.length > 0 && (
                        <>
                          <TableHeaderRow title="Required optimisers" />
                          <ListTable items={optimisers} onSelect={noop} startsCollapsed={false} />
                        </>
                      )}
                      {batteryComponents.length > 0 && (
                        <>
                          <TableHeaderRow title="Required battery components" />
                          <ListTable items={batteryComponents} onSelect={noop} startsCollapsed={false} />
                        </>
                      )}
                    </tbody>
                  </table>
                </Grid>
              </>
            );
          }}
        />
      </main>

      {canImportPlytix && (
        <Modal isOpen={showPimImportNotesModal} onClose={() => setShowPimImportNotesModal(false)} className="pim-import-notes-modal">
          <h3>PIM Import</h3>
          <p>Please enter a note about this PIM import. This will be visible to all users.</p>
          <Formik
            initialValues={{ notes: '' }}
            onReset={() => setShowPimImportNotesModal(false)}
            onSubmit={(values, formik) => {
              importPim(values);
              importingPlytixSet(true);
              setTimeout(() => {
                importingPlytixSet(false);
                setShowPimImportNotesModal(false);
                formik.resetForm();
                fetchPimAsPromise();
              }, 30000);
            }}
          >
            <Form>
              <TextareaField name="notes" id="notes" placeholder="Notes" label="Notes" />
              <div>
                <Button type="reset" format="secondary">
                  Cancel
                </Button>
                <Button type="submit" format="primary" disabled={importingPlytix}>
                  Import{importingPlytix ? 'ing...' : ''}
                </Button>
              </div>
            </Form>
          </Formik>
        </Modal>
      )}
    </>
  );
};

PimPackagesPage.propTypes = {
  pim: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    relationships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  fetchPimAsPromise: PropTypes.func.isRequired,
  importPim: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(({ pim, user }) => ({ pim, user: user.user }), { fetchPimAsPromise, importPim })(PimPackagesPage);
