import React from 'react';
import camelCase from 'lodash.camelcase';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Textarea = ({
  autoFocus,
  className,
  disabled,
  hasError,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  rows,
  testId,
  value,
}) => {
  const useId = id || name;
  const useTestId = testId || camelCase(`${name}`);
  return (
    <textarea
      name={name}
      id={useId}
      className={classnames('hollywood-textarea', className, hasError ? 'invalid' : null)}
      placeholder={placeholder}
      rows={rows}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      data-testid={useTestId}
      value={value}
      onChange={event => {
        if (onChange) onChange(event, event.target.value);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    />
  );
};

Textarea.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  testId: PropTypes.string,
  value: PropTypes.string,
};

Textarea.defaultProps = {
  autoFocus: false,
  className: null,
  disabled: false,
  hasError: false,
  id: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyDown: null,
  placeholder: null,
  rows: 2,
  testId: null,
  value: null,
};

export default Textarea;
