import React, { useContext } from 'react';

// Context
export const PimSurveyMachineContext = React.createContext({ service: undefined, send: undefined });

// Hook
const usePimSurveyMachine = () => {
  const context = useContext(PimSurveyMachineContext);
  if (context === undefined) {
    throw new Error('usePimSurveyMachine must be used within a PimSurveyMachineContext');
  }
  return context;
};

export default usePimSurveyMachine;
