import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { hasErrors } from '@zing/neo-common/dist/lib/formik';
import { Link } from 'react-router-dom';
import { login } from '../../../redux/auth/actions';
import { Schema } from './validator';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import Button from 'hollywood/dist/components/Button';
import Icon from '@zing/neo-common/dist/components/Icon';
import React from 'react';
import useAppService from '../../../hooks/use-app-service';
import PropTypes from 'prop-types';

const LoginForm = ({ user, login, auth }) => {
  const password = process.env.NODE_ENV === 'development' ? 'password' : '';
  const { appApi } = useAppService();

  return (
    <Formik
      initialValues={{ email: '', password }}
      validationSchema={Schema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        login(values, user, appApi).catch(error => {
          setSubmitting(false);
          setStatus(error);
        });
      }}
    >
      {({ dirty, errors, isSubmitting }) => (
        <Form data-testid="login-form" className="form">
          <TextField name="email" title="Email" autoFocus type="email" className="inverted" testId="email" />
          <TextField name="password" title="Password" type="password" className="inverted" testId="password" />
          {auth && !auth.processing && auth.error && (
            <p className="auth-error" data-testid="authError">
              <Icon icon="alert" /> Invalid credentials. Please try again.
            </p>
          )}
          <Grid valign="center" gutters="sm">
            <Col>
              <Link to="/trouble-signing-in">Having trouble signing in?</Link>
            </Col>
            <Col width="auto">
              <Button
                format="primary"
                className="inverted"
                type="submit"
                size="medium"
                disabled={isSubmitting || !dirty || hasErrors(errors)}
                testId="sign_in"
              >
                Sign in
              </Button>
            </Col>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  user: PropTypes.shape({}).isRequired,
  login: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    processing: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(({ user, auth }) => ({ user, auth }), { login })(LoginForm);
