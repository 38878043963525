import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import NumberInput from '../../hollywood-xstate/number-input';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const TrenchingRequirementMeters = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor="trenchingRequirementMeters">Total trenching requirement</label>
        </Col>
        <Col width="fixed" className="right-col">
          <NumberInput
            min={0}
            max={200}
            type="number"
            name="trenchingRequirementMeters"
            prefix="m"
            value={current.context.trenchingRequirementMeters}
            onChange={(_, value) => {
              send({ type: 'TRENCHING_REQUIREMENT_METERS', value });
            }}
          />
          <FieldError field="trenchingRequirementMeters" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default TrenchingRequirementMeters;
