import { ReactComponent as BatteryStorage } from '@zing/neo-common/dist/svg/battery.svg';
import { ReactComponent as SmartHeating } from '@zing/neo-common/dist/svg/temperature-control.svg';
import { ReactComponent as SmartLighting } from '@zing/neo-common/dist/svg/light-bulb.svg';
import { ReactComponent as SolarPV } from '@zing/neo-common/dist/svg/solar-powered-house.svg';
import { ReactComponent as VehicleCharging } from '@zing/neo-common/dist/svg/car-electric.svg';
import PropTypes from 'prop-types';
import React from 'react';

import './opportunity-icons.scss';

const OpportunityIcons = ({ api }) => (
  <div className="opportunity_icons" data-testid="opportunity_icons">
    {api.hasSolar() && <SolarPV className="icon sales_focus_col_icon" />}
    {api.hasBattery() && <BatteryStorage className="icon sales_focus_col_icon" />}
    {api.hasEv() && <VehicleCharging className="icon sales_focus_col_icon icon--flush" />}
    {api.hasSmartHeating() && <SmartHeating className="icon sales_focus_col_icon icon--flush" />}
    {api.hasSmartLighting() && <SmartLighting className="icon sales_focus_col_icon icon--flush" />}
  </div>
);

OpportunityIcons.propTypes = {
  api: PropTypes.shape({
    hasBattery: PropTypes.func.isRequired,
    hasSolar: PropTypes.func.isRequired,
    hasEv: PropTypes.func.isRequired,
    hasSmartHeating: PropTypes.func.isRequired,
    hasSmartLighting: PropTypes.func.isRequired,
  }).isRequired,
};

export default OpportunityIcons;
