import { AppContext } from '../contexts/app-context';
import { useContext } from 'react';
import { useService } from '@xstate/react';
import useConstant from 'use-constant';

const useMenuService = () => {
  const app = useContext(AppContext);
  const [appState] = useService(app);
  const [menu, send] = useService(appState?.context?.menu);
  const api = useConstant(() => ({
    open: () => send('OPEN'),
    close: () => send('CLOSE'),
  }));
  api.isOpen = () => menu.matches('open');
  api.isClosed = () => menu.matches('closed');
  return [api, menu, send];
};

export default useMenuService;
