import producePipeline from 'immer-produce-pipeline';
import { acBatteryTypes, batteryTypes, hybridBatteryTypes, pvInverterTypes } from '@zing/neo-common/dist/accessors/pim-comparison';
import { cashFlow, cashFlowFirstYear, cashFlowLastYear } from '../../utils/calculations/cashflow';
import { costsAndSavings as costsAndSavingsFn } from '../../utils/calculations/costs-and-savings';
import { divide } from '@zing/neo-common/dist/lib/number';
import { FILTER_1_PHASE, FILTER_3_PHASE } from '@zing/neo-common/dist/redux/product/accessor';
// eslint-disable-next-line import/no-cycle
import { goodsTypeMappings } from './pim-export';
// eslint-disable-next-line import/no-cycle
import {
  applyDiscount,
  calcTotals,
  cleanRequires,
  costGoodsServicePrices,
  costGroupings,
  costTotalGoods,
  determineWhichGsVatRates,
  discounted,
  displayPricesWithVat,
  duplicateQuantity,
  expectedGmPercentage,
  expectedGmValue,
  exVatTotalPricing,
  exVatTotals,
  exVatPriceFromCostAndGm,
  exVatGoodsServicePrices,
  gmGoodsServicePrices,
  gmValueFromCostAndGm,
  incVat,
  preDiscountVatGs,
  vatGs,
  vatPerRates,
  vatRates,
  vatTotals,
} from './calc-producers';

import { scaffoldProductTypes, installProductTypes, kitProductTypes } from '@zing/neo-common/dist/accessors/pim-product-types';

export const CASHFLOW_YEARS = 25;
export const GS_RATIO_THRESHOLD = 0.6;
export const VAT_LOWER_RATE = 1;
export const VAT_HIGHER_RATE = 2;
export const REASON_WHICH_VAT_RATE_G1 = 'G100001'; // 'not energyEfficiencyMeasure';
export const REASON_WHICH_VAT_RATE_G2 = 'G100002'; // 'energyEfficiencyMeasure && Battery only || gsRatio > threshold || not customerIsEligibleForLowerVatRate';
export const REASON_WHICH_VAT_RATE_S1 = 'S100001'; // 'not energyEfficiencyMeasure';
export const REASON_WHICH_VAT_RATE_S2 = 'S100002'; // 'energyEfficiencyMeasure && Battery only'

// UKSQA-1186
export const reducePerPanelBy30Products = ['INSTALL-SOLAR-XLRG-UPLIFT'];

const surveyProductPricingPipelinePart1 = ({ leadCategory, numberOfPanels, pvArrayOutput, discountPercentage = 0 }) =>
  producePipeline([
    // we now need to redo the original import items in real time due to variable GM ratio
    // they work at the product level (not at draft.product) so need to be transformed
    gmValueFromCostAndGm(leadCategory),
    exVatPriceFromCostAndGm,
    exVatGoodsServicePrices,
    costGoodsServicePrices,
    gmGoodsServicePrices,
    costTotalGoods(numberOfPanels, pvArrayOutput),
    costGroupings(goodsTypeMappings),
    exVatTotalPricing(numberOfPanels, pvArrayOutput),
    exVatTotals,
    applyDiscount(discountPercentage),
    expectedGmValue,
    expectedGmPercentage,
  ]);

// we want to know the discounted value INC VAT
// this is not currently possible because we apply the discount and then add VAT
// so we propose to add a preDiscountVATPrice per product (that's calculated after the VAT rates) so we can get a pre discount INC total
// and therefore calculate the difference to give us the discount INC VAT

const surveyProductPricingPipelinePart2 = ({ customerIsEligibleForLowerVatRate = false, gsRatio, journey, leadCategory }) =>
  producePipeline([
    determineWhichGsVatRates(customerIsEligibleForLowerVatRate, gsRatio, journey, leadCategory),
    vatRates,
    vatGs,
    incVat,
    vatTotals,
    vatPerRates,
    preDiscountVatGs,
    discounted,
    duplicateQuantity,
    cleanRequires,
    displayPricesWithVat,
  ]);

const getVatRates = values => {
  const vatRateGoods = values.length && values[0]?.product?.vatRateGoods ? values[0].product.vatRateGoods : 0;
  const vatRateServices = values.length && values[0]?.product?.vatRateServices ? values[0].product.vatRateServices : 0;
  return {
    vatRateGoods,
    vatRateServices,
  };
};

const isFixedDiscountAllowed = values => {
  const uniqueVatRates = new Set();
  values.forEach(val => {
    uniqueVatRates.add(val?.product?.vatRateGoods);
    uniqueVatRates.add(val?.product?.vatRateServices);
  });
  return uniqueVatRates.size <= 1;
};

const performCalcs = ({
  // eslint-disable-next-line no-unused-vars
  label = undefined,
  customerIsEligibleForLowerVatRate = false,
  discountPercentage = 0,
  journey,
  leadCategory,
  numberOfPanels = 0,
  pvArrayOutput = 0,
  selectedProductsFlat = [],
}) => {
  // pre gs ratio calcs
  const preGsRatio = selectedProductsFlat.map(
    surveyProductPricingPipelinePart1({
      discountPercentage,
      leadCategory,
      numberOfPanels,
      pvArrayOutput,
    })
  );

  /**
   * VAT GS RATIO FORMULA
   * - the VAT gs ratio is determined by the total cost of goods (to EON)
   * - We must only include energyEfficiencyMeasure items in the total cost calc and can not reuse the real costTotalGoods
   *   which gets sent to the CRM
   */
  const energyEfficiencyMeasureProducts = preGsRatio.filter(x => x?.product?.energyEfficiencyMeasure === true);
  const totalsPreGsRatio = calcTotals(energyEfficiencyMeasureProducts, ['discountedExVatTotal', 'costTotalGoods']);
  const gsRatio = divide(totalsPreGsRatio.costTotalGoods, totalsPreGsRatio.discountedExVatTotal);

  // post gs ratio calcs
  const values = preGsRatio.map(
    surveyProductPricingPipelinePart2({
      customerIsEligibleForLowerVatRate,
      gsRatio,
      journey,
      leadCategory,
    })
  );

  return {
    preGsRatio,
    totalsPreGsRatio, // todo: jae. disable passing this out?
    gsRatio,
    values,
  };
};

const injectBespokeProducts = (selectedProductsFlat, overrides) => {
  const result = [...selectedProductsFlat];

  if (overrides.scaffolding) {
    result.push(overrides.scaffolding);
  }
  if (overrides.installation) {
    result.push(overrides.installation);
  }
  if (overrides.kit) {
    result.push(overrides.kit);
  }
  return result;
};

/**
 * Notes from Stuart:
 * Here's an updated/simplified logic file - i have directly mapped the products to be equivalent those we got back from
 * our recent installers tender, so make future management easier.
 *
 * There should be three 'base' solar-installation products, depending on no. panels, plus one for battery-only, and then
 * everything else is now an uplift:
 *
 * - Large systems >30 panels
 * - More than 2 roof elevations
 * - Battery-with-PV
 * - Slate
 * - In-roof
 *
 * I need to validate the product costs but i think that's of secondary importance after the logic in the file.
 */
const upsellInstallationItems = ({
  evInstallationType,
  inRoof,
  flatRoofs,
  // isRosemaryOrSlateRoofPreset,
  journey,
  meterType,
  numberOfPanels,
  numberOfRoofElevations,
  pimComparison,
  selectedProductsFlat,
  scaffoldComplex,
  trenchingRequirementMeters,
}) => {
  const complexInstall = scaffoldComplex === 'yes';
  const isEvReady = evInstallationType === 'EV Ready';

  const fullProductsList = [...selectedProductsFlat];

  const findAndAddProduct = (rule, prodId, quantity = 1, isBundle = false) => {
    const product = pimComparison.findByProdId(prodId);
    if (!product) {
      // eslint-disable-next-line no-console
      console.error('Rule: %s, Product: "%s" Not found!', rule, prodId);
      return;
    }
    // console.log('Rule: %s, Product: "%s" found!', rule, prodId);
    fullProductsList.push({ product, quantity });

    if (isBundle) {
      // add in the bundles required products
      const bundleProducts = pimComparison.getProductRequiredProducts(product);
      if (Array.isArray(bundleProducts) && bundleProducts.length) {
        bundleProducts.forEach(item => fullProductsList.push({ ...item, requiredBy: prodId }));
      }
    }
  };

  // force a BIRD-GUARD-STANDARD
  // findAndAddProduct('My test product', 'TEST_PRODUCT');

  if (journey.hasSolarPV && numberOfPanels >= 1 && numberOfPanels <= 10) {
    findAndAddProduct('Extra-Small PV system, 1-10 panels', 'INSTALL-SOLAR-XSMALL');
  }
  if (journey.hasSolarPV && numberOfPanels >= 11 && numberOfPanels <= 16) {
    findAndAddProduct('Small PV system, 11-16 panels', 'INSTALL-SOLAR-SMALL');
  }
  if (journey.hasSolarPV && numberOfPanels >= 17 && numberOfPanels <= 22) {
    findAndAddProduct('Medium PV system, 17-22 panels', 'INSTALL-SOLAR-MED');
  }
  if (journey.hasSolarPV && numberOfPanels >= 23) {
    findAndAddProduct('Large PV system, >=23 panels', 'INSTALL-SOLAR-LARGE');
  }
  if (journey.hasSolarPV && numberOfPanels > 30) {
    findAndAddProduct('Extra-large PV system, over 30 panels (uplift)', 'INSTALL-SOLAR-XLRG-UPLIFT');
  }
  if (journey.hasSolarPV && numberOfRoofElevations >= 3) {
    findAndAddProduct('Multi-roof PV system, 3 or more roofs (uplift)', 'INSTALL-MULTIROOF-UPLIFT');
  }
  // if (journey.hasSolarPV && isRosemaryOrSlateRoofPreset) {
  //   findAndAddProduct('Slate installation (uplift)', 'INSTALL-SLATE-UPLIFT');
  // }
  if (journey.hasSolarPV && inRoof) {
    findAndAddProduct('in-roof (Viridian) system (uplift)', 'INSTALL-INROOF-RETRO-UPLIFT');
  }
  if (journey.hasSolarPV && journey.hasBattery) {
    findAndAddProduct('Battery installation - add-on with PV (uplift)', 'INSTALL-BATTERY-WITH-PV');
  }
  if (journey.hasSolarPV === false && journey.hasBattery) {
    findAndAddProduct('Battery installation - battery only', 'INSTALL-BATTERY-ONLY');
  }
  if (complexInstall) {
    findAndAddProduct('Complex scaffolding - level 1', 'SCAFF-COMPLEX-UPLIFT-01');
  }
  // ROOF FIXINGS
  if (journey.hasSolarPV) {
    // if (inRoof) {
    //   findAndAddProduct('Viridian fixings', 'FIXINGS-VIRIDIAN');
    // } else if (inRoof === false && Boolean(isRosemaryOrSlateRoofPreset) === true) {
    //   findAndAddProduct('On-roof Slate fixings', 'FIXINGS-SLATE');
    // } else if (inRoof === false && Boolean(isRosemaryOrSlateRoofPreset) === false) {
    //   findAndAddProduct('On-roof standard fixings', 'FIXINGS-PLAIN-TILE');
    // }
    // if (roofFixing?.prodId) {
    //   console.log('Add roof fixing');
    //   findAndAddProduct(roofFixing.name, roofFixing.prodId);
    // }
    if (Array.isArray(flatRoofs)) {
      const numFlatRoofs = flatRoofs.filter(x => x === true).length;
      if (numFlatRoofs > 0) {
        findAndAddProduct('Flat-roof section', 'FIXINGS-FLAT-ROOF');
      }
    }
  }
  if (trenchingRequirementMeters > 0) {
    findAndAddProduct('Trenching (per m)', 'INSTALL-TRENCHING', trenchingRequirementMeters);
  }
  if (isEvReady) {
    findAndAddProduct('EV - "EV Ready"', 'INSTALL-EV-READY');
  }

  /**
   * UKSQA-1094 Additional items bundles
   * Logic to determine when and how many of a bundle should be added
   *
   * Remember than bundles are special! They are TOP LEVEL items that can contain required files 2 levels deep.
   * Add a function to pim to get them?
   */
  const pvInverter = fullProductsList.find(x => pvInverterTypes.includes(x.product.type));
  const batteryInverter = fullProductsList.find(x => batteryTypes.includes(x.product.type));

  /**
   * 1 Phase
   * MTG-BUNDLE-1PH-GIV-HYB
   * MTG-BUNDLE-1PH-GIV-AC
   * MTG-BUNDLE-1PH-SOLAREDGE
   * MTG-BUNDLE-1PH-SOLIS
   *
   * 3 Phase
   * MTG-BUNDLE-3PH-GIV-HYB
   * MTG-BUNDLE-3PH-GIV-AC
   * MTG-BUNDLE-3PH-SOLAREDGE
   * MTG-BUNDLE-3PH-SOLIS
   */

  // not mutually exclusive.. it's possible for it to be neither!
  const isSinglePhase = meterType === FILTER_1_PHASE;
  const isThreePhase = meterType === FILTER_3_PHASE;
  const isBatteryAc = acBatteryTypes.includes(batteryInverter?.product?.type);
  const isBatteryHybrid = hybridBatteryTypes.includes(batteryInverter?.product?.type);
  const isPvSolis = pvInverter?.product?.manufacturer === 'Solis';
  const isPvSolarEdge = pvInverter?.product?.manufacturer === 'SolarEdge';

  // Rule 1 - If we have a PV inverter and do not have a battery
  if (pvInverter && !batteryInverter) {
    if (isPvSolis && isSinglePhase) {
      findAndAddProduct('Rule 1: Adding Solis 1ph bundle', 'MTG-BUNDLE-1PH-SOLIS', 1, true);
    } else if (isPvSolis && isThreePhase) {
      findAndAddProduct('Rule 1: Adding Solis 3ph bundle', 'MTG-BUNDLE-3PH-SOLIS', 1, true);
    } else if (isPvSolarEdge && isSinglePhase) {
      findAndAddProduct('Rule 1: Adding SolarEdge 1ph bundle', 'MTG-BUNDLE-1PH-SOLAREDGE', 1, true);
    } else if (isPvSolarEdge && isThreePhase) {
      findAndAddProduct('Rule 1: Adding SolarEdge 3ph bundle', 'MTG-BUNDLE-3PH-SOLAREDGE', 1, true);
    }
  }
  // Rule 2 - If we have a PV inverter and a battery
  else if (pvInverter && batteryInverter) {
    if (isPvSolis && isBatteryAc && isSinglePhase) {
      findAndAddProduct('Rule 2: Adding Solis/AC 1ph bundle', 'MTG-BUNDLE-1PH-GIV-AC', 1, true);
    } else if (isPvSolis && isBatteryAc && isThreePhase) {
      findAndAddProduct('Rule 2: Adding Solis/AC 3ph bundle', 'MTG-BUNDLE-3PH-GIV-AC', 1, true);
    } else if (isPvSolarEdge && isBatteryAc && isSinglePhase) {
      findAndAddProduct('Rule 2: Adding SolarEdge/Giv/AC 1ph bundle', 'MTG-BUNDLE-1PH-GIV-AC', 1, true);
      // Removed UKSQA-1344 - findAndAddProduct('Rule 2: Adding SolarEdge/AC 1ph bundle', 'MTG-BUNDLE-1PH-SOLAREDGE', 1, true);
    } else if (isPvSolarEdge && isBatteryAc && isThreePhase) {
      findAndAddProduct('Rule 2: Adding SolarEdge/Giv/AC 3ph bundle', 'MTG-BUNDLE-3PH-GIV-AC', 1, true);
      // Removed UKSQA-1344 - findAndAddProduct('Rule 2: Adding SolarEdge/AC 3ph bundle', 'MTG-BUNDLE-3PH-SOLAREDGE', 1, true);
    }
  }
  // Rule 3 - If we do not have a PV inverter and do have a battery
  else if (!pvInverter && batteryInverter) {
    if (isBatteryAc && isSinglePhase) {
      findAndAddProduct('Rule 3: Adding NoPV/AC 1ph bundle', 'MTG-BUNDLE-1PH-GIV-AC', 1, true);
    } else if (isBatteryAc && isThreePhase) {
      findAndAddProduct('Rule 3: Adding NoPV/AC 3ph bundle', 'MTG-BUNDLE-3PH-GIV-AC', 1, true);
    } else if (isBatteryHybrid && isSinglePhase) {
      findAndAddProduct('Rule 3: Adding NoPV/Hybrid 1ph bundle', 'MTG-BUNDLE-1PH-GIV-HYB', 1, true);
    } else if (isBatteryHybrid && isThreePhase) {
      findAndAddProduct('Rule 3: Adding NoPV/Hybrid 3ph bundle', 'MTG-BUNDLE-3PH-GIV-HYB', 1, true);
    }
  }

  // Adjust quantities for some products
  const productsToUpsellQuantities = ['P370-MC4-FM'];
  // if any of these products are found, then we should update them
  const processedProductList = fullProductsList.map(item => {
    item.exportQuantity = item.quantity;
    if (productsToUpsellQuantities.includes(item?.product?.prodId)) {
      item.exportQuantity = numberOfPanels;
      // console.log('item', item);
    }
    return item;
  });

  // console.log('processedProductList', processedProductList);
  return processedProductList;
};

/**
 * Process all of the items in matchingProductTypes that are not bespoke, and set their costs to zero.
 *
 *
 * @param {*} bespokeProdId
 * @param {*} matchingProductTypes
 * @returns
 */
const zeroMatchingProductCosts = (bespokeProdId, matchingProductTypes) => item => {
  if (item.product.prodId === bespokeProdId) {
    return item;
  }
  if (matchingProductTypes.includes(item.product.type)) {
    // must not affect the original object!
    return {
      ...item,
      product: {
        ...item.product,
        costPerItem: 0,
        costPerKw: 0,
        costPerPanel: 0,
      },
    };
  }
  return item;
};

/**
 * Applies bespoke pricing overrides where applicable
 * @param {*} productList
 * @param {*} overrides
 * @returns
 */
const applyBespokePricing = (productList, overrides) => {
  let processedProductList = productList;

  if (overrides.isKitCostOverridden) {
    // console.log('kitProductTypes', kitProductTypes);
    const bespokeProdId = overrides.kit.product.prodId;
    processedProductList = processedProductList.map(zeroMatchingProductCosts(bespokeProdId, kitProductTypes));
  }
  if (overrides.isScaffoldingCostOverridden) {
    // console.log('scaffoldProductTypes', scaffoldProductTypes);
    const bespokeProdId = overrides.scaffolding.product.prodId;
    processedProductList = processedProductList.map(zeroMatchingProductCosts(bespokeProdId, scaffoldProductTypes));
  }
  if (overrides.isInstallCostOverridden) {
    // console.log('installProductTypes', installProductTypes);
    const bespokeProdId = overrides.installation.product.prodId;
    processedProductList = processedProductList.map(zeroMatchingProductCosts(bespokeProdId, installProductTypes));
  }

  return processedProductList;
};

/**
 * Main entry point to pim calcs,
 * pass it come values and you get all the calcs for a survey
 *
 * @param customerIsEligibleForLowerVatRate
 * @param degradationMatrix
 * @param discountPercentage
 * @param discountType
 * @param energyInflationRate
 * @param estimatedElectricityDemandKwh
 * @param evInstallationType
 * @param existingGridConnectedPvOnSite
 * @param existingPvAnnualYieldKwh
 * @param exportRate
 * @param flatRoofs
 * @param importRate
 * @param isNumOccupants
 * @param inRoof
//  * @param isRosemaryOrSlateRoofPreset
 * @param journey
 * @param meterType
 * @param numberOfPanels
 * @param numberOfRoofElevations
 * @param outDuringTheDay
 * @param paymentType
 * @param pimComparison
 * @param pvArrayOutput
 * @param rpi
 * @param scaffoldComplex
 * @param selectedProductsFlat
 * @param systemTotalBatteryCapacityKwh
 * @param systemTotalInstallPvKwh
 * @param trenchingRequirementMeters
 * @returns {{settings: {discountPercentage: *, selectedProductsFlat: *, journey: *, numberOfPanels: *, customerIsEligibleForLowerVatRate: *, pvArrayOutput: *}, costsAndSavings: {electricityBillSavings: *, exportIncome: *, returnInYearOne: *, twentyFiveYearBenefit: *, totalAnnualIncomeAndSavings: *, totalCost: *}, cashflow: (function(*=, *=, *=, *=, *=, *=, *=, *=, *=, *=, *=, *, *=, *=): []), values: *, totals: *, gsRatio: *}}
 */
const pimCalcs = ({
  customerIsEligibleForLowerVatRate,
  degradationMatrix,
  discountPercentage,
  discountCode,
  discountFixed,
  discountType,
  energyInflationRate,
  estimatedElectricityDemandKwh,
  evInstallationType,
  existingGridConnectedPvOnSite,
  existingPvAnnualYieldKwh,
  exportRate,
  flatRoofs,
  importRate,
  isNumOccupants,
  inRoof,
  // isRosemaryOrSlateRoofPreset,
  journey,
  leadCategory,
  meterType,
  numberOfPanels,
  numberOfRoofElevations,
  outDuringTheDay,
  paymentType,
  pimComparison,
  pvArrayOutput,
  rpi,
  scaffoldComplex,
  selectedProductsFlat,
  systemTotalBatteryCapacityKwh,
  systemTotalInstallPvKwh,
  trenchingRequirementMeters,
  productsToOverrideCosts,
  overrides = {},
  depositAmount,
  gridEmissionFactor,
}) => {
  // Inject the bespoke overrides (inc cost / gm ratio)
  // Kit cost is basically all of the items that are not scaffold/installation
  // i.e. inverters/ solar panels / meters etc
  const postBespokeOverrideProductsList = injectBespokeProducts(selectedProductsFlat, overrides);

  // upsell Installation products
  const upsoldProductList = upsellInstallationItems({
    evInstallationType,
    flatRoofs,
    inRoof,
    // isRosemaryOrSlateRoofPreset,
    journey,
    meterType,
    numberOfPanels,
    numberOfRoofElevations,
    pimComparison,
    scaffoldComplex,
    selectedProductsFlat: postBespokeOverrideProductsList,
    trenchingRequirementMeters,
    productsToOverrideCosts,
  });

  // Discount code overrides - if a discount code is selected, add the corresponding product to the list, but zero the cost (UKSQA-1431)
  if (discountType === 'code' && discountCode) {
    const discountCodeProduct = pimComparison.findByProdId(discountCode);
    if (discountCodeProduct) {
      upsoldProductList.push({ product: { ...discountCodeProduct, costPerItem: 0 }, quantity: 1 });
    }
  }

  // Now that we have our full products list, it's time to process it and zero
  // any costs that are overridden by bespoke pricing
  const productsListReadyForCalcs = applyBespokePricing(upsoldProductList, overrides);

  // we need to run the calcs so that we can pre calculate the correct discount percentage
  // for fixed discounts
  const pre = performCalcs({
    label: 'BeforeDiscount',
    customerIsEligibleForLowerVatRate,
    discountPercentage: 0,
    journey,
    leadCategory,
    numberOfPanels,
    pvArrayOutput,
    selectedProductsFlat: productsListReadyForCalcs,
    inFixedDiscountMode: false,
  });
  // const preTotals = calcTotals(pre.values, ['costTotalGoods', 'exVatTotal']);

  // Handle fixed discounts!
  const vatRates = getVatRates(pre.values);
  const fixedDiscountAllowed = isFixedDiscountAllowed(pre.values);

  const inFixedDiscountMode = fixedDiscountAllowed === true && discountFixed > 0 && (discountType === 'fixed' || discountType === 'code');

  const isFixedDiscountDisallowed = fixedDiscountAllowed === false && discountType === 'fixed';
  const inPercentageDiscountMode = discountPercentage > 0 && (discountType === 'percentage' || discountType === 'code');
  const inColleagueDiscountMode = discountPercentage > 0 && discountType === 'colleague';

  // what is the max discount allowed under current values?
  // const totalCostOfExVatGoods = preTotals.costTotalGoods;
  // const discountedExVatTotalFloorLimit = totalCostOfExVatGoods / GS_RATIO_THRESHOLD;

  // now find the value in £ (INC VAT) that is the maximum discount to keep us above that floor
  // const maxAllowedDiscount = (preTotals.exVatTotal - discountedExVatTotalFloorLimit) * (1 + VAT_RATE1);

  let discountPercentageFixed = 0;
  let discountValueInPoundsIncVat = 0;
  if (inFixedDiscountMode) {
    // we need to calculate the discountPercentage value to get the same result as a fixed discount!
    // what is the exVat fixed discount value?
    const fixedDiscountExVatValue = discountFixed / (1 + vatRates.vatRateGoods);
    const discountableProducts = pre.values.filter(item => item.product.discountable);
    const exVatDiscountableProductsTotal = discountableProducts.reduce((current, next) => current + next?.product?.discountedExVatTotal, 0);
    discountPercentageFixed = fixedDiscountExVatValue / exVatDiscountableProductsTotal;
    discountValueInPoundsIncVat = discountFixed;
  }

  // calculate without a discount
  const beforeDiscount = performCalcs({
    label: 'BeforeDiscount',
    customerIsEligibleForLowerVatRate,
    discountPercentage: 0,
    journey,
    leadCategory,
    numberOfPanels,
    pvArrayOutput,
    selectedProductsFlat: productsListReadyForCalcs,
    inFixedDiscountMode: false,
  });
  const beforeDiscountTotals = calcTotals(beforeDiscount.values, [
    'exVatTotalGoods',
    'exVatTotal',
    'incVatTotal',
    'discountedExVatTotal',
    'costTotalGoods',
  ]);

  // calculate with a discount if there is one
  const postDiscount = performCalcs({
    customerIsEligibleForLowerVatRate,
    label: 'PostDiscount',
    discountPercentage: inFixedDiscountMode ? discountPercentageFixed : discountPercentage,
    journey,
    leadCategory,
    numberOfPanels,
    pvArrayOutput,
    selectedProductsFlat: productsListReadyForCalcs,
  });

  // totals
  const totalsFieldNames = [
    'amountOnWhichVatRate1Charged',
    'amountOnWhichVatRate2Charged',
    'costTotal',
    'costTotalGoods',
    'costTotalGoodsGroupSolar',
    'costTotalGoodsGroupBattery',
    'costTotalGoodsGroupAddon',
    'costTotalGoodsGroupEv',
    'costTotalServices',
    'discounted',
    'discountedExVatPerItemTotal',
    'discountedExVatPerKwTotal',
    'discountedExVatPerPanelTotal',
    'discountedExVatTotal',
    'discountedExVatTotalGoods',
    'discountedExVatTotalServices',
    'expectedGmValue',
    'exVatTotal',
    'exVatTotalGoods',
    'exVatTotalServices',
    'gmValueTotal',
    'gmValueTotalGoods',
    'gmValueTotalServices',
    'incVatPerItemTotal',
    'incVatPerKwTotal',
    'incVatPerPanelTotal',
    'incVatTotal',
    'preDiscountIncVat',
    'vatAtRate1',
    'vatAtRate2',
    'vatPerItemTotal',
    'vatPerItemTotalGoods',
    'vatPerItemTotalServices',
    'vatPerKwTotal',
    'vatPerKwTotalGoods',
    'vatPerKwTotalServices',
    'vatPerPanelTotal',
    'vatPerPanelTotalGoods',
    'vatPerPanelTotalServices',
    'vatTotal',
    'vatTotalGoods',
    'vatTotalServices',
  ];
  const postDiscountTotals = calcTotals(postDiscount.values, totalsFieldNames);
  postDiscountTotals.expectedGmPercentage = postDiscountTotals.expectedGmValue / postDiscountTotals.discountedExVatTotal;

  if (inPercentageDiscountMode) {
    discountValueInPoundsIncVat = postDiscountTotals.discounted;
  }
  if (inColleagueDiscountMode) {
    discountValueInPoundsIncVat = postDiscountTotals.discounted;
  }

  // for now we will mock objects that the current cashflow requires..
  // once we have removed the old survey, we can tidy up the function calls
  // bit annoying, but much safer than changing existing code that will soon be deprecated
  const decisions = { estimated_electricity_demand: isNumOccupants ? 'NUM_OCCUPANTS' : '' };
  const calcSettings = { exportRate, rpi, electricityPriceInflation: energyInflationRate * 100 };

  // NOTE! systemTotalInstallPvKwh is actually the COMBINED systemTotalInstallPvKwh value
  // so we need to subtract the EXISTING from the values passed to CASHFLOW CALCS
  const cashflow = cashFlow(
    CASHFLOW_YEARS,
    systemTotalInstallPvKwh - existingPvAnnualYieldKwh,
    importRate / 100,
    existingPvAnnualYieldKwh,
    calcSettings,
    journey.hasSolarPV,
    journey.hasBattery,
    existingGridConnectedPvOnSite,
    outDuringTheDay,
    estimatedElectricityDemandKwh,
    systemTotalBatteryCapacityKwh,
    null, // param not used in function
    degradationMatrix,
    decisions,
    gridEmissionFactor
  );

  const costsAndSavings = costsAndSavingsFn(postDiscountTotals.incVatTotal, cashFlowFirstYear(cashflow), cashFlowLastYear(cashflow));

  const paymentOptions = {
    totalCostIncVat: postDiscountTotals.incVatTotal,
    depositIncVat: paymentType === 'oneOff' ? depositAmount : 0,
  };

  // if this is the GHG scheme, we do things differently.
  if (paymentType === 'ghgScheme') {
    paymentOptions.totalDueAfterInstallation = 0;
    paymentOptions.depositIncVat = postDiscountTotals.incVatTotal;
  } else {
    paymentOptions.totalDueAfterInstallation = paymentOptions.totalCostIncVat - paymentOptions.depositIncVat;
  }

  // All products have the same VAT rates applied (once the rates have been calculated)
  // get the rates from the first product
  const vatRateGoods = postDiscount.values.length ? postDiscount.values[0].product.vatRateGoods : 0;
  const vatRateServices = postDiscount.values.length ? postDiscount.values[0].product.vatRateServices : 0;

  const calc = {
    cashflow,
    costsAndSavings,
    gsRatio: postDiscount.gsRatio,
    gsRatioGoods: postDiscount.gsRatio,
    gsRatioServices: 1 - postDiscount.gsRatio,
    gsRatioThresholdReached: postDiscount.gsRatio >= GS_RATIO_THRESHOLD,
    settings: {
      customerIsEligibleForLowerVatRate,
      discountPercentage,
      discountType,
      discountCode,
      journey,
      numberOfPanels,
      pvArrayOutput,
      selectedProductsFlat: productsListReadyForCalcs,
    },
    totals: postDiscountTotals,
    beforeDiscountTotals,
    values: postDiscount.values,
    vatRateGoods,
    vatRateServices,
    vatGsRatioTotals: postDiscount.totalsPreGsRatio,
    paymentOptions,
    discountCalcs: {
      inFixedDiscountMode,
      // discountedExVatTotalFloorLimit,
      discountFixed,
      discountPercentageFixed,
      discountValueInPoundsIncVat,
      discountType,
      gsRatioThreshold: GS_RATIO_THRESHOLD,
      isFixedDiscountDisallowed,
      beforeDiscountTotals: {
        costTotalGoods: beforeDiscountTotals.costTotalGoods,
        discountedExVatTotal: beforeDiscountTotals.discountedExVatTotal,
        incVatTotal: beforeDiscountTotals.incVatTotal,
      },
      postDiscountTotals: {
        costTotalGoods: postDiscountTotals.costTotalGoods,
        discountedExVatTotal: postDiscountTotals.discountedExVatTotal,
        incVatTotal: postDiscountTotals.incVatTotal,
      },
    },
  };

  // console.log('discountCalcs', calc.discountCalcs);
  return calc;
};

export default pimCalcs;
