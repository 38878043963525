import React, { useContext } from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import Toggle from '../../hollywood-xstate/toggle';
import FieldError from '../../hollywood-xstate/field-error';

const G99Override = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;
  const { overrideG99 } = current.value.comparison[currentComparison];

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor="isG99Overridden">Choose G99 required</label>
        </Col>
        <Col width="fixed" className="right-col">
          <Toggle
            name="isG99Overridden"
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
              { label: 'Use Calculated', value: 'suggested' },
            ]}
            value={overrideG99}
            badOption={['yes', 'no']}
            onChange={(_, value) => {
              send({ type: 'OVERRIDE_G99_DECISION', currentComparison, value });
            }}
          />
          <FieldError field="isG99Overridden" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default G99Override;
