import React, { useState } from 'react';
import Button from 'hollywood/dist/components/Button';
import Icon from '@zing/neo-common/dist/components/Icon';
import Input from '../../../hollywood-xstate/input';
import Modal from 'hollywood/dist/components/Modal';
import NumberInput from '../../../hollywood-xstate/number-input';
import PropTypes from 'prop-types';
import Toggle from '../../../hollywood-xstate/toggle';
import usePimSurveyMachine from '../../../contexts/survey-context';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { Link, withRouter } from 'react-router-dom';
import { OrientationCompass } from './orientation-compass';
import { toFixed, getNumber } from '@zing/neo-common/dist/lib/safe';
import { appointmentMatchUrl } from '../../../../../utils/opportunity';
import { getContextCurrentComparison } from '../../../../../machines/survey.machine';
import { MAX_NUM_ROOFS, MAX_PANELS_PER_ROOF } from '../../../../../config';
import RoofImage from './roof-image';

const canShowAddRoofButton = (index, roofs) => index === roofs.length - 1 && index < MAX_NUM_ROOFS - 1;
const canShowRemoveRoofButton = index => index > 0;

const Roof = ({ index, match }) => {
  const { current, send } = usePimSurveyMachine();
  const { currentComparison } = current.context;
  const [isEditNameModalOpen, isEditNameModalOpenSet] = useState(false);
  const context = getContextCurrentComparison(current);
  const { roofs } = context;
  const roof = roofs[index];

  return (
    <>
      <div className="roof" data-testid={`roof${index}`}>
        <Grid gutters="sm">
          <Col>
            <h2 className="roof__name" data-testid={`roofName${index}`}>
              {roof.roofName}
            </h2>
            <button
              type="button"
              onClick={() => {
                isEditNameModalOpenSet(true);
              }}
            >
              <Icon icon="pencil" className="eon-red" />
            </button>
          </Col>

          {canShowAddRoofButton(index, roofs) && (
            <Col width="auto">
              <Button
                testId={`addRoof${index}`}
                format="primary"
                size="small"
                type="button"
                onClick={() => {
                  send({ type: 'ADD_ROOF', currentComparison });
                }}
              >
                Add another roof
              </Button>
            </Col>
          )}

          {canShowRemoveRoofButton(index, roofs) && (
            <Col width="auto">
              <button
                data-testid={`removeRoof${index}`}
                type="button"
                onClick={() => {
                  send({ type: 'REMOVE_ROOF', currentComparison, index });
                }}
              >
                <Icon icon="trash" className="light" />
              </button>
            </Col>
          )}
        </Grid>
        <Grid gutters="sm">
          <Col width="auto">
            <label htmlFor={`context.comparisons.${currentComparison}.roofs[${index}].slopeElevation`}>Pitch of roof</label>
            <Input
              type="number"
              name={`context.comparisons.${currentComparison}.roofs[${index}].slopeElevation`}
              suffix="°"
              min={0}
              max={90}
              value={typeof roof.slopeElevation !== 'undefined' ? roof.slopeElevation : ''}
              onChange={(_, value) => {
                send({ type: 'SLOPE_ELEVATION', currentComparison, index, value });
              }}
            />
            <p className="light sm">(Degrees from horizontal)</p>
          </Col>

          <Col width="auto">
            <label htmlFor={`context.comparisons.${currentComparison}.roofs[${index}].azimuthElevation`}>
              Orientation of roof (degrees)
            </label>
            <Input
              type="number"
              name={`context.comparisons.${currentComparison}.roofs[${index}].azimuthElevation`}
              suffix="°"
              min={-180}
              max={180}
              value={typeof roof.azimuthElevation !== 'undefined' ? roof.azimuthElevation : ''}
              onChange={(_, value) => {
                send({ type: 'AZIMUTH_ELEVATION', currentComparison, index, value });
              }}
            />
            <p className="light sm">(From South, +ive for Westerly)</p>
          </Col>

          <Col width="auto">
            <label htmlFor={`context.comparisons.${currentComparison}.roofs[${index}].numberOfPanelsElevation`}>Number of panels</label>
            <NumberInput
              min={0}
              max={MAX_PANELS_PER_ROOF}
              name={`context.comparisons.${currentComparison}.roofs[${index}].numberOfPanelsElevation`}
              value={typeof roof.numberOfPanelsElevation !== 'undefined' ? roof.numberOfPanelsElevation : ''}
              onChange={(_, value) => {
                send({ type: 'NUMBER_OF_PANELS_ELEVATION', currentComparison, index, value });
              }}
            />
          </Col>

          <Col>
            <label htmlFor={`context.comparisons.${currentComparison}.roofs[${index}].flatRoof`}>Flat roof?</label>
            <Toggle
              name={`context.comparisons.${currentComparison}.roofs[${index}].flatRoof`}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
              goodOption="no"
              badOption="yes"
              value={roof.flatRoof}
              onChange={(_, value) => {
                send({ type: 'FLAT_ROOF', currentComparison, index, value });
              }}
            />
          </Col>
          <Col width="auto" className="sunpath-buttons">
            {roof.sunpathSaved ? (
              <Link to={appointmentMatchUrl(match, ['survey', 'sunpath', index])} className="edit-sunpath-btn">
                <Icon icon="tick" />
                Sun path diagram added
              </Link>
            ) : (
              <Button format="secondary" link={appointmentMatchUrl(match, ['survey', 'sunpath', index])}>
                <span className="plus" data-testid={`sunpath_button_${index}`}>
                  +
                </span>{' '}
                Sun path diagram
              </Button>
            )}
          </Col>
        </Grid>

        <div className="roof__details">
          <Grid gutters="sm">
            <Col>
              <p className="tight">Approx. orientation of roof</p>
              <OrientationCompass index={index} azimuthElevation={roof.azimuthElevation} compassBearing={roof.compassBearing} />
            </Col>
            <Col>
              <p className="tight">Individual panel power</p>
              <h3>{getNumber(roof, 'individualPanelPowerW')} Watts</h3>
            </Col>
            <Col>
              <p className="tight">Rated power</p>
              <h3>{toFixed(getNumber(roof, 'ratedPowerKw'), 3)} kWp</h3>
            </Col>
            <Col>
              <p className="tight">Annual yield (calculated)</p>
              <h3>{toFixed(getNumber(roof, 'annualYieldKwhElevation'), 0)} kWh</h3>
            </Col>
          </Grid>
        </div>
        <RoofImage index={index} offline={false} />
      </div>

      <Modal
        isOpen={isEditNameModalOpen}
        className="edit-roof-name-modal"
        onClose={() => {
          isEditNameModalOpenSet(false);
          send({ type: 'EDIT_ROOF_NAME', currentComparison, index, value: roof.roofName });
        }}
      >
        <h3>Edit the roof name</h3>
        <div className="form-field">
          <Input
            type="text"
            name="editRoofName"
            id="editRoofName"
            value={roof.roofNameEditing}
            onChange={(e, value) => {
              send({ type: 'EDIT_ROOF_NAME', currentComparison, index, value });
            }}
            onKeyDown={e => {
              if (roof.roofNameEditing.length > 0 && e.key === 'Enter') {
                send({ type: 'SAVE_ROOF_NAME', currentComparison, index });
                isEditNameModalOpenSet(false);
              }
              if (e.key === 'Escape') {
                isEditNameModalOpenSet(false);
                send({ type: 'EDIT_ROOF_NAME', currentComparison, index, value: roof.roofName });
              }
            }}
          />
          <span>Eg. Back roof</span>
        </div>
        <div className="range-right">
          <Button
            format="primary"
            disabled={roof.roofNameEditing.length === 0}
            onClick={() => {
              send({ type: 'SAVE_ROOF_NAME', currentComparison, index });
              isEditNameModalOpenSet(false);
            }}
          >
            Save name
          </Button>
        </div>
      </Modal>
    </>
  );
};

Roof.propTypes = {
  index: PropTypes.number.isRequired,
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(Roof);
