import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import FieldError from '../../hollywood-xstate/field-error';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Toggle from '../../hollywood-xstate/toggle';
import Input from '../../hollywood-xstate/input';
import { limitDecimals, percentageAsNumber } from '@zing/neo-common/dist/lib/number';
import NumberInput from '../../hollywood-xstate/number-input';

import './index.scss';

export const OverridePricing = ({ costAction, costName, title, toggleName, toggleAction, showGsRatio, gsRatioName, gsRatioAction }) => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const comparison = comparisons[currentComparison];

  const isOverridden = !!comparison[toggleName];
  const cost = Number(comparison[costName]);
  const gsRatio = showGsRatio ? Number(comparison[gsRatioName]) : 0;

  const labelVerticalAlign = isOverridden ? 'top' : 'center';
  const innerFormWidth = showGsRatio ? '6' : '12';

  return (
    <fieldset>
      <Grid gutters="sm" valign={labelVerticalAlign}>
        <Col>
          <p style={{ marginTop: isOverridden ? '10px' : 0 }}>{title}</p>
        </Col>
        <Col width="fixed" className="right-col">
          <Grid gutters="xl">
            <Col>
              <Toggle
                name={toggleName}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ]}
                goodOption="no"
                badOption="yes"
                value={isOverridden ? 'yes' : 'no'}
                onChange={(_, v) => {
                  if (v === 'no') {
                    send({ type: `${toggleAction}_NO`, currentComparison, value: false });
                  } else if (v === 'yes') {
                    send({ type: `${toggleAction}_YES`, currentComparison, value: true });
                  }
                }}
              />
              <FieldError field={toggleName} />
              {isOverridden ? (
                <>
                  <div style={{ paddingTop: '40px;' }}>&nbsp;</div>
                  <Grid gutters="sm">
                    <Col width={innerFormWidth}>
                      <div className="">
                        <label htmlFor={costName} className="right-col-inner-label">
                          Override cost
                        </label>
                        <Input
                          type="number"
                          prefix="£"
                          name={costName}
                          min={0}
                          value={cost}
                          onChange={(_, value) => {
                            const valueToSend = limitDecimals(Math.max(0, value), 2);
                            send({ type: costAction, currentComparison, value: valueToSend });
                          }}
                        />
                      </div>
                    </Col>
                    {showGsRatio ? (
                      <Col>
                        <div className="">
                          <label htmlFor={gsRatioName} className="right-col-inner-label">
                            GS Ratio
                          </label>
                          <NumberInput
                            name={gsRatioName}
                            prefix="%"
                            step="1.00"
                            min={0}
                            max={100}
                            value={gsRatio !== 0 ? percentageAsNumber(gsRatio) : ''}
                            onChange={(_, value) => {
                              const valueToSend = limitDecimals(Math.max(0, value), 2);
                              send({ type: gsRatioAction, currentComparison, value: valueToSend / 100 });
                            }}
                          />
                        </div>
                      </Col>
                    ) : null}
                  </Grid>
                </>
              ) : null}
            </Col>
          </Grid>
        </Col>
      </Grid>
    </fieldset>
  );
};

OverridePricing.defaultProps = {
  showGsRatio: false,
  gsRatioName: '',
  gsRatioAction: '',
};
OverridePricing.propTypes = {
  toggleName: PropTypes.string.isRequired,
  toggleAction: PropTypes.string.isRequired,
  costAction: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  costName: PropTypes.string.isRequired,
  showGsRatio: PropTypes.bool,
  gsRatioName: PropTypes.string,
  gsRatioAction: PropTypes.string,
};

/**
 * Bespoke pricing for complex scaffolding items, allows to override installation,
 * scaffolding and kit costs (inc GsRatio)
 *
 * https://jira.dev.eon.com/browse/UKSQA-1006
 *
 * @returns
 */
const BespokePricing = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const comparison = comparisons[currentComparison];
  const toggleName = 'isScaffoldingCostOverridden';
  const costName = 'overriddenScaffoldingCost';
  const isOverridden = !!comparison[toggleName];
  const cost = Number(comparison[costName]);
  const labelVerticalAlign = isOverridden ? 'top' : 'center';
  const { expectedScaffoldCost } = comparison;
  return (
    <>
      <h3>Bespoke pricing</h3>
      <p>
        If bespoke pricing has been received from installers, etc, please use the below fields, and enter items as{' '}
        <strong>ex-VAT costs</strong> (before margin, discount, etc).
      </p>

      <fieldset>
        <Grid gutters="sm" valign={labelVerticalAlign}>
          <Col>
            <p style={{ marginTop: isOverridden ? '10px' : 0 }}>Bespoke scaffolding cost</p>
          </Col>
          <Col width="fixed" className="right-col">
            <Grid gutters="xl">
              <Col>
                <Toggle
                  name={toggleName}
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                  goodOption="no"
                  badOption="yes"
                  value={isOverridden ? 'yes' : 'no'}
                  onChange={(_, v) => {
                    if (v === 'no') {
                      send({ type: `IS_SCAFFOLDING_COST_OVERRIDDEN_NO`, currentComparison, expectedScaffoldCost, value: false });
                    } else if (v === 'yes') {
                      send({ type: `IS_SCAFFOLDING_COST_OVERRIDDEN_YES`, currentComparison, expectedScaffoldCost, value: true });
                    }
                  }}
                />
                <FieldError field={toggleName} />
                {isOverridden ? (
                  <>
                    <div style={{ paddingTop: '40px;' }}>&nbsp;</div>
                    <Grid gutters="sm">
                      <Col width={12}>
                        <div className="">
                          <label htmlFor={costName} className="right-col-inner-label">
                            Override cost
                          </label>
                          <Input
                            type="number"
                            prefix="£"
                            name={costName}
                            min={0}
                            value={cost.toString() === '0' ? '' : cost} // UKSQA-1363 - remove leading zero
                            onChange={(_, value) => {
                              const valueToSend = limitDecimals(Math.max(0, value), 2);
                              send({ type: 'BESPOKE_SCAFFOLDING_COST', currentComparison, value: valueToSend });
                            }}
                          />
                        </div>
                      </Col>
                    </Grid>
                  </>
                ) : null}
              </Col>
            </Grid>
          </Col>
        </Grid>
      </fieldset>

      <OverridePricing
        title="Bespoke installation cost"
        toggleName="isInstallCostOverridden"
        toggleAction="IS_INSTALL_COST_OVERRIDDEN"
        costAction="BESPOKE_INSTALL_COST"
        costName="overriddenInstallCost"
      />

      <OverridePricing
        title="Bespoke kit cost"
        toggleName="isKitCostOverridden"
        toggleAction="IS_KIT_COST_OVERRIDDEN"
        costAction="BESPOKE_KIT_COST"
        costName="overriddenKitCost"
        gsRatioName="overriddenKitGsRatio"
        showGsRatio
        gsRatioAction="BESPOKE_KIT_GS_RATIO"
      />
    </>
  );
};

export default BespokePricing;
