import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Header from '../../components/Header';
import debounce from 'lodash.debounce';
import { CreateOpportunityForm } from '../../components/forms/CreateOpportunityForm';
import { get, getNumber } from '@zing/neo-common/dist/lib/safe';
import { processCreateOpportunityValues } from './process-create-opportunity-values';
import { createOpportunity as create } from '@zing/neo-common/dist/redux/opportunity/actions';
import { appPropTypes } from '../../app-prop-types';

import './NewOpportunity.scss';

const createDebounced = debounce(create, 1000, { leading: true });

const Page = ({ user, create, history }) => (
  <>
    <Header title="Create appointment" />
    <main className="create-opportunity" data-testid="create-opportunity">
      <CreateOpportunityForm
        onSubmit={values => {
          const processed = processCreateOpportunityValues(values, getNumber(user, 'id'));
          create(processed);
          history.push('/opportunities');
        }}
        addressOptions={{ show: { lat: true, lng: true, mpan: true } }}
      />
    </main>
  </>
);

Page.propTypes = {
  create: PropTypes.func.isRequired,
  history: PropTypes.shape(appPropTypes.historyPropTypes).isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ user }) => ({ user: get(user, 'user') });

const CreateOpportunity = connect(mapStateToProps, { create: createDebounced })(Page);

export default CreateOpportunity;
