/* eslint-disable no-unreachable */
import { appointmentMatchUrl } from '../../utils/opportunity';
import { appPropTypes } from '../../app-prop-types';
import { connect } from 'react-redux';
import { CUSTOMER_DETAILS } from '../../utils/paths';
import { get } from '@zing/neo-common/dist/lib/safe';
import { updatePowra } from '../../redux/session/actions';
import AppointmentDetails from '../../components/appointment-details';
import Header from '../../components/Header';
import hooks from '../../hooks';
import initialState from '../../redux/pim-session/initialstate';
import meetingAccessor from '@zing/neo-common/dist/accessors/meeting';
import Modal from 'hollywood/dist/components/Modal';
import opportunityAccessor from '@zing/neo-common/dist/accessors/opportunity';
import PowraForm from '../../pim/survey/components/powra-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import { fetchPimAsPromise } from '../../redux/pim/actions';

import './appointment.scss';

const Appointment = ({ history, location, match, user, session, updatePowra, fetchPimAsPromise }) => {
  const [showPowraModal, showPowraModalSet] = useState(false);
  const [opportunityUuid, meetingUuid] = hooks.useMatchOpportunity();
  const [pimSession] = useLocalStorage('pimSession', initialState);
  const opportunity = hooks.useOpportunity(opportunityUuid, meetingUuid);
  const o = opportunityAccessor(opportunity);
  const meeting = o.findMeeting(meetingUuid);
  const m = meetingAccessor(meeting);
  const whenUniqueId = get(history, 'location.state.whenUniqueId', '');

  const isSessionPowraArrivalDone = session?.powra?.onArrival?.done || false;
  const isOpportunityPowraArrivalDone = opportunity?.powra?.onArrival?.done || false;
  const canShowModal = !isSessionPowraArrivalDone || !isOpportunityPowraArrivalDone;

  // eslint-disable-next-line no-unused-vars
  const startAppointment = async () => {
    const { survey, pimSurvey } = opportunity;

    // see if we are changing opportunity
    // eslint-disable-next-line no-console
    if (
      pimSession &&
      pimSession.opportunity &&
      // eslint-disable-next-line camelcase
      pimSession.opportunity?.app_uuid !== opportunity.app_uuid &&
      // eslint-disable-next-line no-alert
      !window.confirm(
        'There is unsaved information in the app from a previous opportunity, because a quote was not generated. Are you sure you want to launch this new opportunity now?'
      )
    ) {
      // Continue using the current session in local storage
      history.push(appointmentMatchUrl(match, 'start-survey'), { mode: 'restartPimSession' });
      return false;
    }

    // Fetch latest PIM version
    try {
      await fetchPimAsPromise();
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Failed to fetch the latest pricebook. Please make sure you are online and try again.');
      return false;
    }

    // does the opportunity have a pimSurvey object whose values should be loaded?
    if (pimSurvey && pimSurvey?.version) {
      history.push(appointmentMatchUrl(match, 'start-survey'), { mode: 'restartPimSurvey', pimSurvey, user, opportunity, meeting });
      return false;
    }

    // does the opportunity have a survey object whose values should be loaded?
    if (survey) {
      history.push(appointmentMatchUrl(match, 'start-survey'), { mode: 'restartOldSurvey', survey, user, opportunity, meeting });
      return false;
    }

    // POWRA
    // in all of the scenarios above, they must have already gone through the onArrival powra..
    if (!isSessionPowraArrivalDone && !isOpportunityPowraArrivalDone) {
      showPowraModalSet(true);
      return false;
    }

    // Powra already completed, go back to survey (in progress)
    history.push(appointmentMatchUrl(match, 'start-survey'), { mode: 'brandNewPimSurvey', user, opportunity, meeting });

    return false;
  };

  const submitPowra = powra => {
    powra.onArrival.done = true;
    updatePowra(powra);
    history.push(appointmentMatchUrl(match, 'start-survey'), { mode: 'brandNewPimSurvey', user, opportunity, meeting, powra });
  };

  return (
    <>
      <Header title="Appointment details" />
      <AppointmentDetails
        location={location}
        m={m}
        o={o}
        opportunity={opportunity}
        startAppointmentSession={startAppointment}
        whenUniqueId={whenUniqueId}
        onEditDetailsClick={() => {
          history.push({
            pathname: appointmentMatchUrl(match, CUSTOMER_DETAILS),
            state: {
              updateOpportunityInSession: true,
              isPimSurvey: true,
              back: location.pathname,
            },
          });
        }}
      />

      {canShowModal && (
        <Modal isOpen={showPowraModal} className="powra-modal" onClose={() => showPowraModalSet(false)}>
          <PowraForm
            onCancel={() => showPowraModalSet(false)}
            onSubmit={submitPowra}
            sections={['onArrival']}
            showCancel
            values={session?.powra}
          />
        </Modal>
      )}
    </>
  );
};

Appointment.propTypes = {
  location: PropTypes.shape(appPropTypes.locationPropTypes).isRequired,
  history: PropTypes.shape(appPropTypes.historyPropTypes).isRequired,
  match: PropTypes.shape(appPropTypes.matchPropTypes).isRequired,
  user: PropTypes.shape({}).isRequired,
  session: PropTypes.shape({
    powra: PropTypes.shape({
      onArrival: PropTypes.shape({
        done: PropTypes.bool,
      }),
    }),
  }).isRequired,
  updatePowra: PropTypes.func.isRequired,
  fetchPimAsPromise: PropTypes.func.isRequired,
};

export default connect(({ user, session, pim }) => ({ user: user?.user, session, pim }), { updatePowra, fetchPimAsPromise })(Appointment);
