import { POSTCODE_DATA } from '../../CalculationsData';
import { toString } from '@zing/neo-common/dist/lib/safe';

/**
 *
 * INPUTS
 *   postcode = Electricity sourced from own PV (kWh)
 *
 * OUTPUTS
 *   zone_id	= MCS zone ID
 *
 * EXTERNAL DATA
 *   This function uses the matrix of postcode look-up values to obtain the MCS zone id
 *
 * !!IMPORTANT!! - Data must be presented longest postcode part first, this aids in correct postcode
 * identification combined with shortest search period to effect the greatest efficiency
 *
 * @param postcode
 * @returns {null|*}
 */
export const mcsZoneFromPostcode = postcode => {
  const normalisedPostcode = toString(postcode).toUpperCase();
  const key = Object.keys(POSTCODE_DATA).find(item => toString(normalisedPostcode).startsWith(item, 0));
  return typeof POSTCODE_DATA[key] !== 'undefined' ? POSTCODE_DATA[key] : null;
};
