import React from 'react';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import PropTypes from 'prop-types';
import Toggle from '../../../hollywood/toggle';
import Textarea from '../../../hollywood/textarea';
import ErrorMessage from '../../../../../components/ErrorMessage';

import { yesNoOptions, yesNoNaOptions, shouldStopWork, fieldHasError } from '../utils';

const MeetCustomer = ({ state, handleChange, handleBlur }) => (
  <div className="powra-form__section">
    <h3>Meeting the customer</h3>
    <div className="powra-form__field">
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Are there any vulnerable customers in the household? (If &lsquo;Yes&rsquo;, Please provide details.)</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="meetCustomer.vulnerableInHousehold"
              id="meetCustomer.vulnerableInHousehold"
              options={yesNoOptions}
              goodOption="No"
              badOption="Yes"
              value={state.values.meetCustomer.vulnerableInHousehold}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          {state.values.meetCustomer.vulnerableInHousehold === 'Yes' && (
            <Col width="12">
              <Textarea
                name="meetCustomer.vulnerableInHouseholdNote"
                id="meetCustomer.vulnerableInHouseholdNote"
                placeholder="Please provide details"
                testId="vulnerableInHouseholdNote"
                value={state.values.meetCustomer.vulnerableInHouseholdNote}
                hasError={fieldHasError('meetCustomer.vulnerableInHouseholdNote', state)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {fieldHasError('meetCustomer.vulnerableInHouseholdNote', state) && (
                <p className="hollywood-validation-error">{state.errors.meetCustomer.vulnerableInHouseholdNote}</p>
              )}
            </Col>
          )}
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Are there any special requirements regarding losing power at the property? If &lsquo;Yes&rsquo;, enter details below.</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="meetCustomer.specialPowerRequirements"
              id="meetCustomer.specialPowerRequirements"
              options={yesNoOptions}
              goodOption="No"
              badOption="Yes"
              value={state.values.meetCustomer.specialPowerRequirements}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          {state.values.meetCustomer.specialPowerRequirements === 'Yes' && (
            <Col width="12">
              <Textarea
                name="meetCustomer.specialPowerRequirementsNote"
                id="meetCustomer.specialPowerRequirementsNote"
                placeholder="Please provide details"
                testId="specialPowerRequirementsNote"
                value={state.values.meetCustomer.specialPowerRequirementsNote}
                hasError={fieldHasError('meetCustomer.specialPowerRequirementsNote', state)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {fieldHasError('meetCustomer.specialPowerRequirementsNote', state) && (
                <p className="hollywood-validation-error">{state.errors.meetCustomer.specialPowerRequirementsNote}</p>
              )}
            </Col>
          )}
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>
              Is the property free from any pets or animals that could put you at risk? (If &lsquo;No&rsquo;, then please add a
              &lsquo;Note&rsquo; regarding the risk)
            </p>
          </Col>
          <Col width="auto">
            <Toggle
              name="meetCustomer.freeFromRiskyPets"
              id="meetCustomer.freeFromRiskyPets"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.meetCustomer.freeFromRiskyPets}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          {state.values.meetCustomer.freeFromRiskyPets === 'No' && (
            <Col width="12">
              <Textarea
                name="meetCustomer.freeFromRiskyPetsNote"
                id="meetCustomer.freeFromRiskyPetsNote"
                placeholder="Please provide details"
                testId="freeFromRiskyPetsNote"
                value={state.values.meetCustomer.freeFromRiskyPetsNote}
                hasError={fieldHasError('meetCustomer.freeFromRiskyPetsNote', state)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {fieldHasError('meetCustomer.freeFromRiskyPetsNote', state) && (
                <p className="hollywood-validation-error">{state.errors.meetCustomer.freeFromRiskyPetsNote}</p>
              )}
            </Col>
          )}
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Is the householder (or an appointed adult) to be present for the duration of the visit?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="meetCustomer.isCustomerPresentForVisit"
              id="meetCustomer.isCustomerPresentForVisit"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.meetCustomer.isCustomerPresentForVisit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Do you have authorisation from the customer to begin the survey?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="meetCustomer.customerAuthForSurvey"
              id="meetCustomer.customerAuthForSurvey"
              options={yesNoNaOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.meetCustomer.customerAuthForSurvey}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      {shouldStopWork('meetCustomer', state) && (
        <ErrorMessage testId="powra-error">Stop work and contact your line manager before you continue</ErrorMessage>
      )}
    </div>
  </div>
);

MeetCustomer.propTypes = {
  state: PropTypes.shape({
    values: PropTypes.shape({
      meetCustomer: PropTypes.shape({
        vulnerableInHousehold: PropTypes.string,
        specialPowerRequirements: PropTypes.string,
        freeFromRiskyPets: PropTypes.string,
        isCustomerPresentForVisit: PropTypes.string,
        customerAuthForSurvey: PropTypes.string,
        vulnerableInHouseholdNote: PropTypes.string,
        specialPowerRequirementsNote: PropTypes.string,
        freeFromRiskyPetsNote: PropTypes.string,
      }),
    }),
    errors: PropTypes.shape({
      meetCustomer: PropTypes.shape({
        vulnerableInHousehold: PropTypes.string,
        specialPowerRequirements: PropTypes.string,
        freeFromRiskyPets: PropTypes.string,
        isCustomerPresentForVisit: PropTypes.string,
        customerAuthForSurvey: PropTypes.string,
        vulnerableInHouseholdNote: PropTypes.string,
        specialPowerRequirementsNote: PropTypes.string,
        freeFromRiskyPetsNote: PropTypes.string,
      }),
    }),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default MeetCustomer;
