import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const NoMeetings = ({ shaded }) => (
  <div className={classnames('pipeline_no_quotes', shaded ? 'shaded' : null)} data-testid="pipeline_no_quotes">
    <div className="no_quotes range-center">
      <p>There are no meetings for this opportunity.</p>
    </div>
  </div>
);

NoMeetings.propTypes = {
  shaded: PropTypes.bool.isRequired,
};

export default NoMeetings;
