import React from 'react';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import Button from 'hollywood/dist/components/Button';
import FieldError from '../../../../pim/survey/hollywood/field-error';
import Input from '../../../../pim/survey/hollywood/input';
import PropTypes from 'prop-types';

const SearchForm = ({ values, api, isCustomerSearch }) => (
  <form className="pipeline_search" data-testid="pipeline_search_form" onSubmit={e => e.preventDefault()}>
    <Grid gutters="lg" valign="center">
      <Col>
        <Grid gutters="sm" valign="center">
          <Col width="4">
            <label htmlFor="search">{isCustomerSearch ? 'Customer search' : 'Quote search'}</label>
            <Input
              type="search"
              name="context.pipelineQuery"
              id="context.pipelineQuery"
              placeholder="Search the pipeline"
              value={values?.query}
              onChange={e => {
                api.updateQuery(e.target.value);
              }}
            />
            <FieldError field="context.pipelineQuery" />
          </Col>
          <Col width="8">
            <div className="form-field">
              <label htmlFor="dateFrom">Date range</label>
              <Grid gutters="sm" align="left" valign="top">
                <Col width="4">
                  <Input
                    type="date"
                    name="dateFrom"
                    id="dateFrom"
                    placeholder="From"
                    testId="date_from_filter"
                    value={values?.dateFrom}
                    max={values?.dateTo}
                    onChange={e => {
                      api.updateDateFrom(e.target.value);
                    }}
                  />
                  <FieldError field="dateFrom" />
                </Col>
                <Col width="4">
                  <Input
                    type="date"
                    name="dateTo"
                    id="dateTo"
                    placeholder="To"
                    testId="date_to_filter"
                    value={values?.dateTo}
                    min={values?.dateFrom}
                    onChange={e => {
                      api.updateDateTo(e.target.value);
                    }}
                  />
                  <FieldError field="dateTo" />
                </Col>
                <Col width="4">
                  <Button
                    format="primary"
                    size="medium"
                    type="submit"
                    disabled={values?.canSearch === false}
                    onClick={event => {
                      event.preventDefault();
                      api.search();
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    format="secondary"
                    type="button"
                    onClick={() => {
                      api.reset();
                    }}
                    testId="reset_filters"
                  >
                    Reset
                  </Button>
                </Col>
              </Grid>
            </div>
          </Col>
        </Grid>
      </Col>
    </Grid>
  </form>
);

SearchForm.propTypes = {
  api: PropTypes.shape({
    reset: PropTypes.func.isRequired,
    retry: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    updateDateFrom: PropTypes.func.isRequired,
    updateDateTo: PropTypes.func.isRequired,
    updateQuery: PropTypes.func.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    query: PropTypes.string.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    canSearch: PropTypes.bool.isRequired,
  }).isRequired,
  isCustomerSearch: PropTypes.bool.isRequired,
};
export default SearchForm;
