import * as types from './actionTypes';
import { initialState } from './initialState';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import { SESSION_RESET } from '../session/actionTypes';

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_RESET:
      return initialState;
    case RESET_ALL:
      return initialState;
    case types.AUTH:
    case types.AUTH_BEGIN:
    case types.AUTH_SIGN_OUT:
      return initialState;

    case types.AUTH_SUCCESS:
      return {
        ...state,
        ...action.data,
      };

    case types.AUTH_ERROR:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};
