import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { showPercentSign } from '../../pim/survey/components/product-tables/utils';
import { toFixedNumber, toFixedNumberPercentage } from '@zing/neo-common/dist/lib/safe';
// eslint-disable-next-line import/no-cycle
import { trueOrFalse } from '../../pages/diagnostics/pim/csv-export-utils';
import format from 'date-fns/format';
import pimProductTypes from '@zing/neo-common/dist/accessors/pim-product-types';
import producePipeline from 'immer-produce-pipeline';

// This file gathers all the facts needed to export the pim data via CSV

export const EXPORT_CATEGORY_COSTS = 'Costs';
export const EXPORT_CATEGORY_HMRC = 'HMRC';
export const EXPORT_CATEGORY_EX_VAT = 'ex-VAT';
export const EXPORT_CATEGORY_GM = 'GM';
export const EXPORT_CATEGORY_VAT = 'VAT';
export const EXPORT_CATEGORY_INC_VAT = 'Inc VAT';
export const EXPORT_CATEGORY_TIME = 'Time';
export const EXPORT_CATEGORY_PRODUCTS = 'Products';

const exportDateFormat = () => format(new Date(), 'DD/MM/YYYY HH:mm:ss');

/**
 * Determines which costings category the different product types get grouped into when calculating totals
 * @type {{GOODS_TYPE_EV_COSTS: [*], GOODS_TYPE_ADDON_COSTS: *[], GOODS_TYPE_BATTERY_COSTS: *[], GOODS_TYPE_SOLAR_COSTS: *[]}}
 */
export const goodsTypeMappings = {
  GOODS_TYPE_SOLAR_COSTS: [
    pimProductTypes.roofFixing,
    pimProductTypes.pvPanel,
    pimProductTypes.pvPanelOptimiser,
    pimProductTypes.pvInverter1Ph,
    pimProductTypes.pvInverter3Ph,
    pimProductTypes.meters,
    pimProductTypes.communications,
    pimProductTypes.sundries,
    pimProductTypes.pvPackage,
    pimProductTypes.installation,
    pimProductTypes.scaffolding,
  ],
  GOODS_TYPE_BATTERY_COSTS: [
    pimProductTypes.hybridBatteryInverter1Ph,
    pimProductTypes.hybridBatteryInverter3Ph,
    pimProductTypes.acBatteryInverter1Ph,
    pimProductTypes.acBatteryInverter3Ph,
    pimProductTypes.batteryInverter,
    pimProductTypes.batteryModule,
    pimProductTypes.batteryController,
  ],
  GOODS_TYPE_ADDON_COSTS: [
    pimProductTypes.solarAddOn,
    pimProductTypes.batteryAddOn,
    pimProductTypes.installationAddOn,
    pimProductTypes.smartHeatingAddOn,
    pimProductTypes.smartHomeAddOn,
    pimProductTypes.evAddOn,
    pimProductTypes.addOn,
    pimProductTypes.eOnDiscountOrOffer,
    pimProductTypes.oAndMOption,
    pimProductTypes.eOnTariff,
  ],
  GOODS_TYPE_EV_COSTS: [pimProductTypes.evCharger],
};

/**
 * * Each column is an object of two parts
 * {
 *   title:   The value for the title row
 *
 *   category: Describes the export category.
 *             This isn't used but it will help when discussing with stuart, they are used in the
 *             XLS file provided
 *
 *   value:    A function that accepts PIM calcs & a quote and returns the value
 *
 *   format:   An optional format function (arrays of functions) to process value through
 * }
 */
const columns = [
  {
    title: 'Solar costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ calcs }) => calcs?.totals?.costTotalGoodsGroupSolar,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Battery costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ calcs }) => calcs?.totals?.costTotalGoodsGroupBattery,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Add-ons costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ calcs }) => calcs?.totals?.costTotalGoodsGroupAddon,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Install costs',
    category: EXPORT_CATEGORY_COSTS,
    // eslint-disable-next-line camelcase
    value: ({ quote }) => quote?.expected_install_cost,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Scaff costs',
    category: EXPORT_CATEGORY_COSTS,
    // eslint-disable-next-line camelcase
    value: ({ quote }) => quote?.expected_scaffold_cost,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'EV costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ calcs }) => calcs?.totals?.costTotalGoodsGroupEv,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Finance costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ quote }) => quote?.expected_finance_contrib_ex_vat,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Sum costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ calcs }) => calcs?.totals?.costTotal, // todo: this isn't going to match as some addons have services value, and above is goods only
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Goods costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ calcs }) => calcs?.totals?.costTotalGoods,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Services costs',
    category: EXPORT_CATEGORY_COSTS,
    value: ({ calcs }) => calcs?.totals?.costTotalServices,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'HMRC Goods %',
    category: EXPORT_CATEGORY_HMRC,
    value: ({ calcs }) => calcs?.gsRatioGoods,
    format: [toFixedNumberPercentage, toFixedNumber, showPercentSign],
    display: [],
  },
  {
    title: 'Goods VAT thresh.?',
    category: EXPORT_CATEGORY_HMRC,
    value: ({ calcs }) => trueOrFalse(calcs?.gsRatioThresholdReached),
  },
  // PLEASE NOTE! We do not want to expose this figure in the calcs, just in case someone uses it later on.
  // exVatTotal - discountedExVatTotal
  {
    title: 'Discount amount ex-VAT',
    category: EXPORT_CATEGORY_EX_VAT,
    value: ({ calcs }) => calcs?.totals?.exVatTotal - calcs?.totals?.discountedExVatTotal,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Goods ex-VAT (inc. discount)',
    category: EXPORT_CATEGORY_EX_VAT,
    value: ({ calcs }) => calcs?.totals?.discountedExVatTotalGoods,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Services ex-VAT (inc. discount)',
    category: EXPORT_CATEGORY_EX_VAT,
    value: ({ calcs }) => calcs?.totals?.discountedExVatTotalServices,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Cust. price ex-Vat',
    category: EXPORT_CATEGORY_EX_VAT,
    value: ({ calcs }) => calcs?.totals?.discountedExVatTotal,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'GM amount',
    category: EXPORT_CATEGORY_GM,
    // eslint-disable-next-line camelcase
    value: ({ quote }) => quote?.expected_gm_value,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'GM%',
    category: EXPORT_CATEGORY_GM,
    // eslint-disable-next-line camelcase
    value: ({ quote }) => quote?.expected_gm_percentage,
    format: [toFixedNumberPercentage, showPercentSign],
    display: [],
  },
  {
    title: 'Solar Goods VAT rate',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.vatRateGoods,
    format: [toFixedNumberPercentage, showPercentSign],
    display: [],
  },
  {
    title: 'Solar Services VAT rate',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.vatRateServices,
    format: [toFixedNumberPercentage, showPercentSign],
    display: [],
  },
  {
    title: 'Goods VAT amount',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.totals?.vatTotalGoods,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Services VAT amount',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.totals?.vatTotalServices,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'VAT amount at lower rate',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.totals?.vatAtRate1,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'VAT amount at higher rate',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.totals?.vatAtRate2,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Amount on which lower rate charged',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.totals?.amountOnWhichVatRate1Charged,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Amount on which higher rate charged',
    category: EXPORT_CATEGORY_VAT,
    value: ({ calcs }) => calcs?.totals?.amountOnWhichVatRate2Charged,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Package Price',
    category: EXPORT_CATEGORY_INC_VAT,
    // eslint-disable-next-line camelcase
    value: ({ quote }) => quote?.total_with_vat_before_discount,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Discount inc-VAT',
    category: EXPORT_CATEGORY_INC_VAT,
    value: ({ quote }) => quote?.discount,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Cust. Price',
    category: EXPORT_CATEGORY_INC_VAT,
    // eslint-disable-next-line camelcase
    value: ({ quote }) => quote?.total_with_vat,
    format: [toFixedNumber],
    display: [poundsPence],
  },
  {
    title: 'Timestamp',
    category: EXPORT_CATEGORY_TIME,
    value: () => exportDateFormat(),
  },
  {
    title: 'ProdIds',
    category: EXPORT_CATEGORY_PRODUCTS,
    value: ({ calcs }) => (Array.isArray(calcs.values) && calcs.values.map(x => x?.product?.prodId)) || '',
    display: [prodIds => (Array.isArray(prodIds) ? prodIds.join(', ') : '')],
  },
];

export const pimExportTitles = ({ extendedInfo = false }) =>
  columns.map(({ title, category }) => {
    if (extendedInfo) {
      return {
        title,
        category,
      };
    }
    return title;
  });

/**
 * can return values in two formats
 * @param calcs
 * @param quote
 * @param extendedInfo default off = value as numbers, true  = extended object info for table rendering
 * @returns {({category: *, value: *}|*)[]}
 */
export const pimExport = ({ calcs, quote, extendedInfo = false }) =>
  columns.map(column => {
    let rawValue = column.value({ calcs, quote });
    if (column?.format && Array.isArray(column.format)) {
      rawValue = producePipeline(column.format)(rawValue);
    }
    if (extendedInfo) {
      const { format, value, ...rest } = column;
      return { rawValue, ...rest };
    }
    return rawValue;
  });
