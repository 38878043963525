import React, { useContext, useState } from 'react';
import Button from 'hollywood/dist/components/Button';
import PropTypes from 'prop-types';
import SunpathDiagram from '@zing/neo-common/dist/components/SunpathDiagram';
import { getArray } from '@zing/neo-common/dist/lib/safe';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { shadingFactor } from '../../../../utils/calculations/utils/shading-factor';

import './sunpath.scss';

const PimSunpath = ({ history, match }) => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { roofs } = comparisons[currentComparison];
  const roofId = match?.params?.roofId || null;
  const roof = roofs[roofId];
  const [shadedSegments, shadedSegmentsSet] = useState(getArray(roof, 'shadedSegments'));

  if (!roof) {
    // eslint-disable-next-line no-console
    console.error('No roof found!');
    return null;
  }

  return (
    <main className="sunpath" data-testid="sunpath-page">
      <SunpathDiagram
        shaded={shadedSegments}
        onPanelPress={panelNumber => {
          if (shadedSegments.includes(panelNumber)) {
            // Already shaded, remove it
            shadedSegmentsSet(shadedSegments.filter(i => i !== panelNumber));
          } else {
            // Not shaded, add it
            const x = [...shadedSegments];
            x.push(panelNumber);
            shadedSegmentsSet(x);
          }
        }}
      />
      <Grid gutters="lg" valign="center">
        <Col width="auto">
          <h4>
            Number of shaded segments on sunpath
            <br /> {shadedSegments.length}
          </h4>
        </Col>
        <Col>
          <h4>
            Shading factor
            <br /> {shadingFactor(shadedSegments.length)}
          </h4>
        </Col>
        <Col width="auto">
          <Button format="secondary" onClick={() => shadedSegmentsSet([])}>
            Reset
          </Button>
        </Col>
      </Grid>

      <div className="range-right">
        <Button onClick={() => history.goBack()} testId="cancel-btn">
          Cancel
        </Button>
        <Button
          format="primary"
          onClick={() => {
            send({ type: 'ROOF_SUNPATH', currentComparison, index: roofId, shadedSegments });
            history.goBack();
          }}
          testId="save-btn"
        >
          Save sunpath
        </Button>
      </div>
    </main>
  );
};

PimSunpath.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: {
      roofId: PropTypes.string.isRequired,
    },
  }).isRequired,
  roof: PropTypes.shape({}).isRequired,
};

export default PimSunpath;
