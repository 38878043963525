import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import camelCase from 'lodash.camelcase';
import { createArrayInputOptions } from '../utils/inputs';

const Toggle = ({
  badOption,
  className,
  disabled,
  goodOption,
  hasError,
  id,
  name,
  onBlur,
  onChange,
  options,
  testId,
  testIdUseLabels,
  value,
  validator,
}) => {
  const useTestId = testId || camelCase(`${name}`);

  return (
    <div className={classnames('hollywood-toggle', className, hasError ? 'invalid' : 'valid')}>
      {createArrayInputOptions({ options, id, name, useTestId, testIdUseLabels }).map(option => (
        <Fragment key={option.id}>
          <input
            type="radio"
            className={classnames(
              'hollywood-toggle__input',
              [].concat(goodOption).includes(option.value) ? 'hollywood-toggle__input--good' : null,
              [].concat(badOption).includes(option.value) ? 'hollywood-toggle__input--bad' : null
            )}
            value={option.value}
            id={option.id}
            name={name}
            disabled={disabled}
            data-testid={option.testId}
            checked={value === option.value}
            onChange={event => {
              if (validator) {
                validator.touch(name);
              }

              if (onChange) onChange(event, event.target.value);
            }}
            onBlur={event => {
              if (validator) {
                validator.touch(name);
              }
              if (onBlur) onBlur(event, event.target.value);
            }}
          />
          <label className="hollywood-toggle__btn" htmlFor={option.id}>
            {option.label}
          </label>
        </Fragment>
      ))}
    </div>
  );
};

Toggle.propTypes = {
  badOption: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  goodOption: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        id: PropTypes.string,
        testId: PropTypes.string,
      }),
      PropTypes.string.isRequired,
    ])
  ).isRequired,
  testId: PropTypes.string,
  testIdUseLabels: PropTypes.bool,
  value: PropTypes.string,
  validator: PropTypes.shape({
    touch: PropTypes.func,
  }).isRequired,
};

Toggle.defaultProps = {
  badOption: null,
  className: null,
  disabled: false,
  goodOption: null,
  hasError: false,
  id: null,
  onBlur: null,
  onChange: null,
  testId: null,
  testIdUseLabels: false,
  value: '',
};

export default Toggle;
