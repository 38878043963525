import * as config from '@zing/neo-common/dist/config';

const loadPoiFromOpportunity = opportunity => {
  const productsOfInterest = [];
  if (opportunity.battery === 'Yes') {
    productsOfInterest.push(config.POI_BATTERY_STORAGE.key);
  }
  if (opportunity.solar === 'Yes') {
    productsOfInterest.push(config.POI_SOLAR_PV.key);
  }
  if (opportunity.ev === 'Yes') {
    productsOfInterest.push(config.POI_EV_CHARGING.key);
  }
  if (opportunity.smart_heating_controls === 'Yes') {
    productsOfInterest.push(config.POI_SMART_HEATING.key);
  }
  if (opportunity.smart_lighting_controls === 'Yes') {
    productsOfInterest.push(config.POI_SMART_LIGHTING.key);
  }

  return productsOfInterest;
};

export default loadPoiFromOpportunity;
