const initialState = {
  uuid: null,
  group: null,
  packages: [],
  products: [],
  relationships: [],
  timestamp: null,
  created_at: null,
};

export default initialState;
