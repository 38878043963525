import format from 'date-fns/format';
import Encryption from '@zing/neo-common/dist/lib/Encryption';

import { get } from '@zing/neo-common/dist/lib/safe';

export const commitNotifications = payload =>
  payload.map(item =>
    new Encryption(process.env.REACT_APP_ENCRYPTION_ENABLED).encryptJson(process.env.REACT_APP_ENCRYPTION_PASSWORD, item)
  );

export const getNotifications = notifications =>
  new Encryption(process.env.REACT_APP_ENCRYPTION_ENABLED).decryptJson(
    process.env.REACT_APP_ENCRYPTION_PASSWORD,
    notifications === null ? [] : notifications
  );

export const isRead = notification => notification.marked_as_read !== null;

export const filterByReadStatus = (notifications, read = false) =>
  notifications.filter(notification => {
    if (read) {
      return isRead(notification);
    }
    return !isRead(notification);
  });

export const notificationTitle = notification => get(notification, 'title', '');

export const notificationContent = notification => get(notification, 'content', '');

export const notificationCreatedDateTime = notification =>
  notification && notification.created_at ? format(new Date(notification.created_at), 'YYYY-MM-DD HH:mm:ss') : '';

export const notificationCreatedDisplay = notification =>
  notification && notification.created_at ? format(new Date(notification.created_at), 'dddd Do MMMM, YYYY') : '';
