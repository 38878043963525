import React, { useContext } from 'react';
// import CableLength from '../../components/cable-length';
import ChargePointType from '../../components/charge-point-type';
// import ConnectionType from '../../components/connection-type';
import EvInstallationType from '../../components/installation-type';
// import IncludeRfid from '../../components/include-rfid';
import OlevGrant from '../../components/olev-grant';
import ProductTables from '../../components/product-tables';
// import SocketType from '../../components/socket-type';
import SurveyFooter from '../../components/survey-footer';
import TrenchingRequirementMeters from '../../components/trenching-requirement-meters';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const EvCharging = () => {
  const { current } = useContext(PimSurveyMachineContext);
  return (
    <main className="survey-page ev-charging" data-testid="evChargingTab">
      <EvInstallationType />
      {current.matches(`comparison.${current.context.currentComparison}.ev.fullInstall`) && (
        <>
          {/* <SocketType /> */}
          {/* <CableLength /> */}
          {/* <IncludeRfid /> */}
          {/* <ConnectionType /> */}
          <ChargePointType />
          <OlevGrant />
        </>
      )}
      <TrenchingRequirementMeters />
      <SurveyFooter />
      <ProductTables />
    </main>
  );
};

export default EvCharging;
