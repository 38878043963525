export const initialValues = {
  house_number: '',
  address_line_1: '',
  address_line_2: '',
  channel: '178190006',
  city: '',
  country: 'UK',
  date_of_customer_request: '',
  duration: '',
  email: '',
  eon_customer: '',
  firstname: '',
  invoice_house_number: '',
  invoice_address_line_1: '',
  invoice_address_line_2: '',
  invoice_city: '',
  invoice_country: 'UK',
  invoice_postcode: '',
  lastname: '',
  lat: '52.478',
  lead_category: '',
  lng: '-1.899',
  meeting_guid: '',
  meeting_status: 'Committed',
  meeting_type: 'Survey',
  mobile: '',
  mpan: '',
  notes: '',
  opportunity_guid: '',
  postcode: '',
  products_of_interest: [],
  status_reason: '178190025',
  survey_appt_date: '',
  telephone: '',
  travel_time: '',
  different_invoice_address: false,
};
