import { getNumber, toNumber } from '@zing/neo-common/dist/lib/safe';
import { divide, fixedFloat } from '@zing/neo-common/dist/lib/number';

/**
 * Total of the product costs sections
 * @param productCosts
 * @returns {*}
 */
export const calcTotalProductCosts = productCosts =>
  getNumber(productCosts, 'pvPackageCosts.total') +
  getNumber(productCosts, 'batteryPackageCosts.total') +
  getNumber(productCosts, 'addonProductCosts.total') +
  getNumber(productCosts, 'installCosts.total');

/**
 * Calculate the costs and savings
 * @param totalCostInput
 * @param cashFlowFirstYear
 * @param cashFlowLastYear
 * @returns {{electricityBillSavings: *, exportIncome: *, returnInYearOne: *, twentyFiveYearBenefit: *, totalAnnualIncomeAndSavings: *, totalCost: *}}
 */
export const costsAndSavings = (totalCostInput = 0, cashFlowFirstYear, cashFlowLastYear) => {
  const totalCost = toNumber(totalCostInput);
  const electricityBillSavings = fixedFloat(getNumber(cashFlowFirstYear, 'currentExpectedSavings'), 2);
  const twentyFiveYearBenefit = getNumber(cashFlowLastYear, 'cumulativeTotalBenefit');
  const exportIncome = fixedFloat(getNumber(cashFlowFirstYear, 'exportIncome'), 2);
  const totalAnnualIncomeAndSavings = fixedFloat(electricityBillSavings + exportIncome, 2);
  const returnInYearOne = fixedFloat(divide(totalAnnualIncomeAndSavings, totalCost), 4);

  return {
    electricityBillSavings,
    exportIncome,
    returnInYearOne,
    totalAnnualIncomeAndSavings,
    totalCost,
    twentyFiveYearBenefit,
  };
};
