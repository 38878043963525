import { differenceInMilliseconds, isDate, parse } from 'date-fns';

export const AUTH_UNAUTHORISED = 'Not logged in';
export const AUTH_INVALID = 'Invalid auth';
export const AUTH_INVALID_TOKEN_TYPE = 'Invalid token type';
export const AUTH_EXPIRED = 'Session expired, please login.';
export const AUTH_EXPIRY_UNKNOWN = 'Expiry is not a date';

// test functions return TRUE if there was an error!
const testType = auth => auth === null || typeof auth !== 'object';

const testShape = auth =>
  typeof auth.access_token === 'undefined' ||
  typeof auth.expires === 'undefined' ||
  typeof auth.expires_in === 'undefined' ||
  typeof auth.scope === 'undefined' ||
  typeof auth.token_type === 'undefined' ||
  typeof auth.refresh_token === 'undefined';

const testToken = auth => auth.token_type !== 'Bearer';

const testExpiresTime = (auth, now) => {
  const diff = differenceInMilliseconds(now, auth.expires);
  return diff > 0;
};

const checkExpiresFormat = auth => typeof auth.expires !== 'undefined' && auth.expires.length > 0;

export const checkAuth = (auth, now = new Date()) => {
  const result = {
    valid: false,
    reason: null,
  };
  if (testType(auth)) {
    result.reason = AUTH_UNAUTHORISED;
    return result;
  }
  if (testShape(auth)) {
    result.reason = AUTH_INVALID;
    return result;
  }
  if (testToken(auth)) {
    result.reason = AUTH_INVALID_TOKEN_TYPE;
    return result;
  }
  let { expires } = auth;
  if (checkExpiresFormat(auth)) {
    expires = parse(expires);
  }
  if (!isDate(expires)) {
    result.reason = AUTH_EXPIRY_UNKNOWN;
    return result;
  }
  if (testExpiresTime(auth, now)) {
    result.reason = AUTH_EXPIRED;
    return result;
  }

  // no more tests, let them in
  result.valid = true;
  return result;
};
