import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';

export const onSubmitEmailQuote = (quotes, setProcessing, setQuoteSent, emailQuote, opportunityAppUuid) => values => {
  const quotesToEmail = values.quotes.filter(quote => quote.should_email_quote);

  if (quotesToEmail.length > 0) {
    setProcessing(true);
    // eslint-disable-next-line array-callback-return
    quotesToEmail.map(q => {
      const quote = quotes.find(q1 => appUuid(q1) === appUuid(q));
      if (quote) {
        // Okay, we now have everything we need...
        const details = {
          email: values.customer_email,
          cc: values.cc_email,
          bcc: values.bcc_email,
          subject: values.subject,
          appQuoteUrl: `${process.env.REACT_APP_API_BASE_URL.replace(/\/api/, '/')}cust/login?auth_token=${quote.token}`,
        };
        emailQuote(opportunityAppUuid, quote.id, details);
      }
    });

    setTimeout(() => {
      setProcessing(false);
      setQuoteSent(true);
    }, 2000);
  }
};
