/* eslint-disable camelcase */
import { Machine, assign, spawn, actions } from 'xstate';
import { assignToMeAsPromise, assignToUserAsPromise, searchOpportunitiesAsPromise } from '../../redux/opportunities/actions';
import { SORT_ORDER_DESC } from '../../components/pipeline/utils';
import { sortOpportunities } from '../../redux/opportunities/sort-opportunities';
import { yupToFormErrors } from 'formik';
import pick from 'lodash.pick';
import { validate } from './pipeline-search.schema'; // can reuse in both
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';

const { raise } = actions;

const canSearch = () => true;

// todo: move this to a file for reuse
export const makeFilters = context => pick(context, ['query', 'dateFrom', 'dateTo']);

// add this item as every result row (must handle sorting..)
const customerSearchItemMachine = Machine({
  id: 'customerSearchItem',
  type: 'parallel',
  context: {
    accessToken: undefined,
    email: undefined,
    firstname: undefined,
    lastname: undefined,
    opportunity: undefined,
  },
  states: {
    display: {
      initial: 'compacted',
      states: {
        compacted: {
          on: {
            OPEN: 'expanded',
          },
        },
        expanded: {
          on: {
            CLOSE: 'compacted',
          },
        },
      },
    },
    assignToMe: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            ASSIGN_TO_ME: 'assignToMe',
            ASSIGN_TO_USER: 'assignToUser',
          },
        },
        assignToMe: {
          id: 'assignToMe',
          invoke: {
            id: 'assignToMe',
            src: (c, e) =>
              assignToMeAsPromise(c.accessToken, e.opportunityAppUuid, e.meetingAppUuid).then(data => ({ data, originalEvent: e })),
            onDone: {
              target: 'idle',
              actions: [
                assign({
                  opportunity: (c, e) => {
                    const { opportunity } = c;

                    // update the opportunity as it is now assigned to me (in the backend)
                    opportunity.sales_consultant_email = c.email;
                    opportunity.sales_consultant_firstname = c.firstname;
                    opportunity.sales_consultant_lastname = c.lastname;

                    // Get the list of updated meetings from the API response
                    const meetingsToUpdate = e.data.data.meetings;

                    // Update the meetings locally
                    opportunity.meetings = opportunity.meetings.map(meeting => {
                      if (meetingsToUpdate.includes(appUuid(meeting))) {
                        meeting.sales_consultant_email = c.email;
                        meeting.sales_consultant_firstname = c.firstname;
                        meeting.sales_consultant_lastname = c.lastname;
                      }
                      return meeting;
                    });

                    return opportunity;
                  },
                }),
                raise('OPPORTUNITY_ASSIGN_DISABLED'),
              ],
            },
            onError: 'failure',
          },
        },
        assignToUser: {
          id: 'assignToUser',
          invoke: {
            id: 'assignToUser',
            src: (c, e) =>
              assignToUserAsPromise(c.accessToken, e.user.app_uuid, e.opportunityAppUuid, e.meetingAppUuid).then(data => ({
                data,
                originalEvent: e,
              })),
            onDone: {
              target: 'idle',
              actions: [
                assign({
                  opportunity: (c, e) => {
                    const { opportunity } = c;

                    // update the opportunity as it is now assigned to me (in the backend)
                    opportunity.sales_consultant_email = e.data.originalEvent.user.email;
                    opportunity.sales_consultant_firstname = e.data.originalEvent.user.firstname;
                    opportunity.sales_consultant_lastname = e.data.originalEvent.user.lastname;

                    // Get the list of updated meetings from the API response
                    const meetingsToUpdate = e.data.data.meetings;

                    // Update the meetings locally
                    opportunity.meetings = opportunity.meetings.map(meeting => {
                      if (meetingsToUpdate.includes(appUuid(meeting))) {
                        meeting.sales_consultant_email = e.data.originalEvent.user.email;
                        meeting.sales_consultant_firstname = e.data.originalEvent.user.firstname;
                        meeting.sales_consultant_lastname = e.data.originalEvent.user.lastname;
                      }
                      return meeting;
                    });

                    return opportunity;
                  },
                }),
              ],
            },
            onError: 'failure',
          },
        },
        failure: {},
      },
    },
  },
});

/**
 * Pipeline customer search
 *
 * Reuses lots of the pipeline search, accepts params so that the search form works the same in both
 *
 * - Offline: no functionality
 * - Online: live API only, does not interact with neoDb, does not store in local data
 */
const pipelineCustomerSearchMachine = Machine(
  {
    id: 'customerSearch',
    initial: 'offline',
    context: {
      accessToken: 'Passed via withContext',
      email: undefined, // used to identify who can `assign to me`
      canSearch: false,
      dateFrom: '',
      dateFromIsDirty: false,
      dateTo: '',
      dateToIsDirty: false,
      query: '',
      searchIsDirty: false,
      validation: null,
      sortBy: 'created_at',
      sortOrder: SORT_ORDER_DESC,
      opportunities: [],
    },
    states: {
      offline: {
        entry: assign({ canSearch: () => false }),
        id: 'offline',
        on: {
          STATUS_ONLINE: 'online.hist',
          RESET: {
            actions: 'resetForm',
          },
          AUTH_LOGOUT: {
            actions: ['resetForm', 'clearSession'],
          },
        },
      },
      online: {
        entry: assign({ canSearch: () => true }),
        states: {
          hist: {
            type: 'history',
            history: 'deep',
            target: 'idle',
          },
          idle: {
            on: {
              SEARCH: 'searchingViaApi',
              STATUS_OFFLINE: '#offline',
            },
          },
          searchingViaApi: {
            id: 'searchingViaApi',
            invoke: {
              id: 'searchCustomers',
              src: 'customerSearch',
              onDone: 'listView',
              onError: 'failure',
            },
          },
          listView: {
            id: 'listView',
            entry: ['assignSearchResults', 'clearSearchIsDirty'], // BUG: clears previous search when coming from offline!
            on: {
              SEARCH: {
                target: '#searchingViaApi',
                cond: 'canSearch',
              },
              STATUS_OFFLINE: '#offline',
              RESET: {
                target: 'idle',
                actions: 'resetForm',
              },
              AUTH_LOGOUT: {
                target: 'idle',
                actions: ['resetForm', 'clearSession'],
              },
            },
          },
          failure: {
            on: {
              RESET: {
                target: 'idle',
                actions: 'resetForm',
              },
              RETRY: {
                target: '#searchingViaApi',
                cond: 'canSearch',
              },
              AUTH_LOGOUT: {
                target: 'idle',
                actions: ['resetForm', 'clearSession'],
              },
            },
          },
        },
      },
    },
    on: {
      NOTIFY_AUTH: {
        actions: [
          assign({
            accessToken: (_, event) => event.accessToken,
            email: (_, event) => event.email,
            firstname: (_, event) => event.firstname,
            lastname: (_, event) => event.lastname,
          }),
        ],
      },
      // todo: we have this (hack) to handle the dual login API calls, we can remove once this is done properly
      USER_DETAILS: {
        actions: [
          assign({
            email: (_, event) => event.email,
            firstname: (_, event) => event.firstname,
            lastname: (_, event) => event.lastname,
          }),
        ],
      },
      UPDATE_QUERY: {
        actions: ['assignQuery', 'assignSearchIsDirty', 'validateSearch', 'assignCanSearch'],
      },
      UPDATE_DATE_FROM: {
        actions: ['assignDateFrom', 'assignSearchIsDirty', 'validateSearch', 'assignCanSearch'],
      },
      UPDATE_DATE_TO: {
        actions: ['assignDateTo', 'assignSearchIsDirty', 'validateSearch', 'assignCanSearch'],
      },
      UPDATE_SORT_BY: {
        actions: ['assignSortBy', 'sortOpportunities'],
      },
      UPDATE_SORT_ORDER: {
        actions: ['assignSortOrder', 'sortOpportunities'],
      },
    },
  },
  {
    actions: {
      clearSession: assign({ accessToken: '', email: '', opportunities: [] }),
      assignQuery: assign({ query: (c, e) => e.value }),
      assignSearchIsDirty: assign({ searchIsDirty: () => true }),
      assignDateFrom: assign({
        dateFrom: (c, e) => e.value,
        dateFromIsDirty: true,
      }),
      assignDateTo: assign({
        dateTo: (c, e) => e.value,
        dateToIsDirty: true,
      }),
      assignCanSearch: assign({
        canSearch: c => c.searchIsDirty === true && c.validation === null,
      }),
      assignSortBy: assign({
        sortBy: (_, e) => e.value,
      }),
      assignSortOrder: assign({
        sortOrder: (_, e) => e.value,
      }),
      clearSearchIsDirty: assign({ searchIsDirty: () => false }),
      resetForm: assign({
        canSearch: false,
        dateFrom: '',
        dateFromIsDirty: false,
        dateTo: '',
        dateToIsDirty: false,
        query: '',
      }),
      sortOpportunities: assign({
        opportunities: context => {
          const { sortBy, sortOrder, opportunities } = context;
          return sortOpportunities(opportunities, sortBy, sortOrder);
        },
      }),
      validateSearch: assign({
        validation: c => {
          const result = validate(c);
          return result ? yupToFormErrors(result) : null;
        },
      }),
      assignSearchResults: assign({
        opportunities: (c, e) => {
          if (e.data) {
            // we are not supposed to do this!
            // It was suggested to use the `stop` action instead but I couldn't get it to work
            // todo: come back to it! could break on xstate update
            c.opportunities.map(item => {
              item.ref.parent.stopChild(item.name);
              item.ref.parent.removeChild(item.name);
              return undefined;
            });

            return e.data.map(opportunity => {
              // eslint-disable-next-line camelcase
              const { created_at, lnumber, lastname, city, sales_consultant_email } = opportunity;
              const name = `cust_${opportunity.app_uuid}`;

              return {
                name,
                created_at, // sort by column
                lnumber,
                lastname,
                city,
                sales_consultant_email,
                ref: spawn(
                  customerSearchItemMachine.withContext({
                    accessToken: c.accessToken,
                    // opportunity: processOpportunity(opportunity),
                    opportunity,
                    email: c.email,
                    firstname: c.firstname,
                    lastname: c.lastname,
                  }),
                  name
                ),
              };
            });
          }

          // if we are coming back online and have search results stored.. keep them
          if (e.type === 'STATUS_ONLINE' && c.opportunities.length) {
            return c.opportunities;
          }

          return [];
        },
      }),
    },
    guards: {
      canSearch,
    },
    services: {
      customerSearch: c => searchOpportunitiesAsPromise(makeFilters(c), c.accessToken, 'search-all', 20),
    },
  }
);

export default pipelineCustomerSearchMachine;
