import React from 'react';
import PropTypes from 'prop-types';
import get from '@zing/neo-common/dist/lib/safe/get';

export default function PIMInfo({ pim }) {
  const pimGroup = get(pim, 'group', null);
  return (
    <div className="range-center sm" style={{ marginBottom: '1rem' }}>
      <div>Current PIM Version: {pim.uuid || 'none'}</div>
      {pimGroup ? <div>{pimGroup}</div> : null}
    </div>
  );
}

PIMInfo.propTypes = {
  pim: PropTypes.shape({
    uuid: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
};
