import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { api } from '@zing/neo-common/dist/lib/api';
import Button from 'hollywood/dist/components/Button/Button';
import Modal from 'hollywood/dist/components/Modal/Modal';
import DiagnosticsHeader from '../../../components/diagnostics-header';
import { fetchPimAsPromise } from '../../../redux/pim/actions';
import PIMInfo from '../../../components/pim-info';

const PimVersionsPage = ({ auth, currentPim, fetchPimAsPromise }) => {
  const [pimList, setPimlist] = React.useState([]);
  const [isConfirmingPimDeletion, setIsConfirmingPimDeletion] = React.useState(null);

  /**
   * Fetch PIM List
   */
  async function fetchPims() {
    try {
      const pims = await api({
        accessToken: auth.access_token,
        url: '/pimlist',
        method: 'GET',
      });
      setPimlist([pims.data.latest, ...pims.data.list]);
    } catch (error) {
      setPimlist([]);
      // eslint-disable-next-line no-alert
      alert('Failed to load pricebooks. Please make sure you are online and try again.');
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  React.useEffect(() => {
    fetchPims();
  }, []);

  /**
   * Download PIM Json
   * @param {string} uuid
   */
  async function downloadPim(uuid) {
    const json = await api({
      accessToken: auth.access_token,
      url: `/pim/download?uuid=${uuid}`,
      method: 'GET',
    });
    const fileName = json.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
    const blob = new Blob([JSON.stringify(json.data, null, 2)], { type: 'text/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  /**
   * Delete PIM
   */
  async function confirmDeletePim(uuid) {
    setIsConfirmingPimDeletion(uuid);
  }
  async function deletePim(uuid) {
    await api({
      accessToken: auth.access_token,
      url: `/pim/${uuid}`,
      method: 'DELETE',
    });
    await fetchPims();
    setIsConfirmingPimDeletion(null);
  }

  /**
   * Switch PIM
   */
  async function switchPim(uuid) {
    await fetchPimAsPromise(uuid);
    await fetchPims();
  }

  return (
    <>
      <DiagnosticsHeader title="PIM Versions" />
      <main className="diagnostics">
        <PIMInfo pim={currentPim} />
        {pimList.length === 0 ? (
          <p>Loading pricebooks...</p>
        ) : (
          <>
            <table className="pimList">
              <thead>
                <tr>
                  <td className="subHeading" width={400}>
                    UUID
                  </td>
                  <td className="subHeading">Name</td>
                  <td className="subHeading">Notes</td>
                  <td className="subHeading" width={220}>
                    Created At
                  </td>
                  <td className="subHeading" width={100}>
                    Actions
                  </td>
                  <td width={120}>&nbsp;</td>
                  <td width={120}>&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                {pimList.map((pim, index) => (
                  <tr key={pim.uuid} style={index % 2 === 0 ? { backgroundColor: '#f5f5f5' } : {}}>
                    <td>{pim.uuid}</td>
                    <td>{pim.pim_group}</td>
                    <td>{pim.notes}</td>
                    <td>{pim.created_at}</td>

                    <td>
                      {currentPim.uuid === pim.uuid ? (
                        <span
                          style={{
                            display: 'inline-block',
                            color: '#ffffff',
                            backgroundColor: 'green',
                            borderRadius: 2,
                            fontSize: 14,
                            fontWeight: 500,
                            textTransform: 'uppercase',
                            padding: '2px 8px',
                          }}
                        >
                          Current
                        </span>
                      ) : (
                        <Button format="primary" size="small" type="button" onClick={() => switchPim(pim.uuid)}>
                          Switch
                        </Button>
                      )}
                    </td>
                    <td>
                      <Button format="tertiary" size="small" type="button" onClick={() => downloadPim(pim.uuid)}>
                        Download
                      </Button>
                    </td>
                    <td>
                      <Button
                        disabled={currentPim.uuid === pim.uuid}
                        format="secondary"
                        size="small"
                        type="button"
                        onClick={() => confirmDeletePim(pim.uuid)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Modal isOpen={isConfirmingPimDeletion} onClose={() => setIsConfirmingPimDeletion(null)} className="pim-delete-modal">
              <h3>Are you sure?</h3>
              <p>
                Deleting this pricebook cannot be undone. It will no longer be visible in the app and wont be selectable for any new quotes
                or requotes.
              </p>
              <Button format="secondary" onClick={() => setIsConfirmingPimDeletion(null)}>
                Cancel
              </Button>
              <Button type="submit" format="primary" onClick={() => deletePim(isConfirmingPimDeletion)}>
                Delete Pricebook
              </Button>
            </Modal>
          </>
        )}
      </main>
    </>
  );
};

PimVersionsPage.propTypes = {
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
  currentPim: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  fetchPimAsPromise: PropTypes.func.isRequired,
};

export default connect(({ pim, auth }) => ({ currentPim: pim, auth }), { fetchPimAsPromise })(PimVersionsPage);
