import { QUOTE_EMAIL } from './actionTypes';

import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';
import { api } from '@zing/neo-common/dist/lib/api';

export const fetchQuoteEmailTemplate = () =>
  offlineRequestAction({
    type: QUOTE_EMAIL,
    url: '/templates/email-quote',
  });

export const fetchQuoteEmailTemplateAsPromise = () => (dispatch, getState) => {
  const state = getState();
  // eslint-disable-next-line camelcase
  const accessToken = state?.auth?.access_token;
  dispatch({ type: 'QUOTE_EMAIL' });
  return api({
    accessToken,
    url: '/templates/email-quote',
    method: 'GET',
  })
    .then(response => {
      dispatch({ type: 'QUOTE_EMAIL_COMMIT', payload: response.data });
      return response.data;
    })
    .catch(err => {
      dispatch({ type: 'QUOTE_EMAIL_ROLLBACK' });
      return err;
    });
};
