import { degrees, intensity, radians, timeAngle } from './utils';
import { fixedFloat, parseInt10 } from '@zing/neo-common/dist/lib/number';

/**
 * This function returns peak DC power values for an array of solar installations (i.e. multiple roofs)
 *
 *
 * //NOTE declination angle is described as the angle of the sun on the earth at a given time and date. For the purposes of this calculation, the "best" day of the year in the UK is 21st June and has been fixed on that date
 * in order to calculate the maximum output on the "best" day f the year for the given arrays.
 *
 *
 * ORIGINAL EXCEL EXAMPLE
 *   Function INTENSITY(declination As Variant, latitude As Variant, panel_tilt As Variant, _
 *     panel_azimuth As Variant, time_angle As Variant, Optional Degrees = False) As Variant
 *
 *   If Degrees = True Then
 *     declination = WorksheetFunction.Radians(declination)
 *     latitude = WorksheetFunction.Radians(latitude)
 *     panel_tilt = WorksheetFunction.Radians(panel_tilt)
 *     panel_azimuth = WorksheetFunction.Radians(panel_azimuth)
 *     time_angle = WorksheetFunction.Radians(time_angle)
 *
 *   End If
 *
 *     INTENSITY = Sin(declination) * Sin(latitude) * Cos(panel_tilt) _
 *           - Sin(declination) * Cos(latitude) * Sin(panel_tilt) * Cos(panel_azimuth) _
 *           + Cos(declination) * Cos(latitude) * Cos(panel_tilt) * Cos(time_angle) _
 *           + Cos(declination) * Sin(latitude) * Sin(panel_tilt) * Cos(panel_azimuth) * Cos(time_angle) _
 *           + Cos(declination) * Sin(panel_tilt) * Sin(panel_azimuth) * Sin(time_angle)
 *
 *   If INTENSITY < 0 Then INTENSITY = 0
 *
 *
 * declination
 *   =DEGREES(ASIN(0.39795 * COS(RADIANS(0.98563 * (K4-DATE(YEAR(NOW()), 1, 0) - 173 )))))
 *     K4	=21/06/2019 (i.e. for the current year)
 *
 *   End Function
 *
 * INPUTS
 * latitude				= latitude of the property
 * panelPowerKw			= kW power output value of the Panel used
 * panelArray				= array[{inclination, orientation, number_of_panels}, {...}, ...]
 *   inclination			= decgrees from horizontal
 *   orientation			= degrees from south
 *   panels				= number of panels in this single array section
 *   ...					= add further arrays
 *
 *
 * @param latitude
 * @param panelPowerKw
 * @param panelArray
 * @returns {number}
 */

export const peakDcPower = (latitude, panelPowerKw, panelArray = []) => {
  // calculate declination value for 21st June of this year
  const today = new Date(); // today's date - to get current year
  const bestDay = new Date(`21 June ${today.getFullYear()}`); // optimum day for best generation in a given year
  const firstDay = new Date(`1 January ${today.getFullYear()}`); // first day of the year
  const timeDiff = Math.abs(bestDay.getTime() - firstDay.getTime());
  const dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // We add one, because in the original sheet the starting day of the year is 0 and we cannot do this in Javascript, doing so adds 100th to the value which is still in line with the dp used for calculations

  const declination = fixedFloat(degrees(Math.asin(0.39795 * Math.cos(radians(0.98563 * (dayDifference - 173))))), 2);

  if (latitude === null || latitude === '' || parseFloat(latitude) === 0) {
    latitude = 52.477357;
  } // default lat value when missing

  // true when we are entering values in degrees - NOTE all values must be in the same measurement
  const valuesInDegrees = true;

  let peakDcOutput = 0;
  const arrayIntensity = [];
  let i = 0;

  for (i = 0; i < 24 * 60; i += 15) {
    // loop minutes in day by 15 minute intervals
    arrayIntensity[i] = { arrays: [], timeOfDay: '', peakOutputAtTime: 0 };

    if (Array.isArray(panelArray)) {
      // todo: I ABSOLUTELY HATE THIS CODE!
      // FIX ASAP
      // eslint-disable-next-line no-loop-func
      panelArray.forEach((_arraySection, panelIndex) => {
        // loop panel arrays to calculate intensity per array/time increment
        const panelTimeAngle = timeAngle(i);
        const timeOfDay = `${Math.floor(i / 60)}:${String(i % 60).length === 1 ? `0${i % 60}` : i % 60}`;
        const panelIntensity = intensity(
          declination,
          latitude,
          panelArray[panelIndex].inclination,
          panelArray[panelIndex].orientation,
          panelTimeAngle,
          valuesInDegrees
        );
        const arrayRatedPower = parseFloat(panelPowerKw) * parseInt10(panelArray[panelIndex].panels);
        const arrayPowerAtTime = arrayRatedPower * parseFloat(panelIntensity);
        arrayIntensity[i].arrays[panelIndex] = {
          timeOfDay,
          timeAngle: panelTimeAngle,
          intensity: panelIntensity,
          arrayRatedPower,
          arrayPowerAtTime,
        };
      });
    }

    // todo: convert to reduce
    let peakOutput = 0;
    if (Array.isArray(panelArray)) {
      // eslint-disable-next-line no-loop-func
      panelArray.forEach((_arraySection, panelIndex) => {
        // loop panel arrays to calculate overall panel peak output
        peakOutput += parseFloat(arrayIntensity[i].arrays[panelIndex].arrayPowerAtTime);
      });
    }

    arrayIntensity[i].peakOutputAtTime = peakOutput;
    if (peakDcOutput < peakOutput) {
      peakDcOutput = peakOutput;
    }
  }

  // todo: I'm not convinced we need to generate this large array to make a single value calc
  // console log it to see the memory size.. we can at least skip the empty rows
  return peakDcOutput;
};
