import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { ProductListTable } from '../../Products/ProductListTable';
import DiagnosticsHeader from '../../../components/diagnostics-header';
import pimAccessor from '@zing/neo-common/dist/accessors/pim';
import PropTypes from 'prop-types';
import React from 'react';
import PIMInfo from '../../../components/pim-info';

const PimProductsPage = ({ pim }) => {
  const accessor = pimAccessor(pim);
  const products = accessor.products();
  const productTypes = accessor.productTypes();
  return (
    <>
      <DiagnosticsHeader title="PIM Products" />
      <main className="diagnostics">
        <PIMInfo pim={pim} />

        <Formik
          initialValues={{ type: '' }}
          isInitialValid
          render={({ values, handleChange }) => {
            const filteredProducts = values.type === '' ? products : products.filter(x => x.type === values.type);
            return (
              <>
                <Grid gutters="xl">
                  <Col width="12" />
                  <Col width="12">
                    <Grid gutters="xl">
                      <Col width="4">Product Type</Col>
                    </Grid>
                  </Col>
                  <Col width="12">
                    <Grid gutters="xl">
                      <Col width="3">
                        <select name="type" className="hollywood-select" onChange={handleChange}>
                          <option value="">All</option>
                          {productTypes.map(type => (
                            <option value={type}>{type}</option>
                          ))}
                        </select>
                        <br />
                        <i>Click a product row to view JSON data</i>
                      </Col>
                    </Grid>
                  </Col>
                </Grid>
                <Grid gutters="xl">
                  <Col width="12">
                    <ProductListTable products={filteredProducts} />
                  </Col>
                </Grid>
              </>
            );
          }}
        />
      </main>
    </>
  );
};

PimProductsPage.propTypes = {
  pim: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    packages: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    relationships: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect(({ pim }) => ({ pim }))(PimProductsPage);
