import initialState from '../../../pim/survey/components/powra-form/initial-state';
import merge from 'lodash.merge';
import { get } from '@zing/neo-common/dist/lib/safe';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import { SESSION_BEGIN, SESSION_UPDATE_POWRA, SESSION_RELOAD } from '../actionTypes';
import { START_PIM_SURVEY } from '../../pim-session/action-types';

export const powra = (state = initialState.values, action) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState.values;

    // Ensure we refresh the POWRA when starting a new PIM survey
    case START_PIM_SURVEY: {
      const powra = get(action.opportunity, 'powra', {});
      return merge({}, initialState.values, powra);
    }

    case SESSION_BEGIN: {
      const powra = get(action.opportunity, 'powra', {});
      return merge({}, initialState.values, powra);
    }

    case SESSION_RELOAD: {
      return merge({}, get(action.session, 'powra', {}));
    }

    // updated, we now always get/expect a complete powra object
    case SESSION_UPDATE_POWRA: {
      return action.powra;
    }

    default:
      return state;
  }
};
