import merge from 'lodash.merge';
import isEqual from 'lodash.isequal';
import { getBoolean } from '@zing/neo-common/dist/lib/safe';
import { customerAddress, customerInvoice, filterEmptyStrings, invoiceIsBlank } from '@zing/neo-common/dist/redux/opportunity/accessor';

export const fixDifferentInvoiceAddress = item => {
  if (getBoolean(item, 'different_invoice_address') === false) {
    const address = customerAddress(item).filter(filterEmptyStrings);
    const invoice = customerInvoice(item).filter(filterEmptyStrings);
    return invoiceIsBlank(item) || !isEqual(address, invoice);
  }
  return false;
};

export const processOpportunities = payload => {
  if (Array.isArray(payload)) {
    return payload.map(item =>
      merge({}, item, {
        different_invoice_address: fixDifferentInvoiceAddress(item),
        created_at_timestamp: new Date(item.created_at).getTime() / 1000,
      })
    );
  }
  return payload;
};
