import { assign, Machine } from 'xstate';
import a from '@zing/neo-common/dist/accessors/opportunity';

const addressMachine = Machine(
  {
    id: 'addresses',
    initial: 'same_invoice_address',
    context: {
      house_number: '',
      address_line_1: '',
      address_city: '',
      address_postcode: '',
      invoice_house_number: '',
      invoice_address_line_1: '',
      invoice_city: '',
      invoice_postcode: '',
    },
    states: {
      same_invoice_address: {
        on: {
          TOGGLE_SAME_INVOICE: {
            target: 'different_invoice_address',
          },
          HOUSE_NUMBER: {
            target: 'same_invoice_address',
            actions: assign({
              house_number: (_, { value = '' }) => value,
              invoice_house_number: (_, { value = '' }) => value,
            }),
          },
          ADDRESS_LINE_1: {
            target: 'same_invoice_address',
            actions: assign({
              address_line_1: (_, { value = '' }) => value,
              invoice_address_line_1: (_, { value = '' }) => value,
            }),
          },
          ADDRESS_CITY: {
            target: 'same_invoice_address',
            actions: assign({
              address_city: (_, { value = '' }) => value,
              invoice_city: (_, { value = '' }) => value,
            }),
          },
          ADDRESS_POSTCODE: {
            target: 'same_invoice_address',
            actions: assign({
              address_postcode: (_, { value = '' }) => value,
              invoice_postcode: (_, { value = '' }) => value,
            }),
          },
          LOAD: [
            {
              target: 'same_invoice_address',
              actions: 'updateFromOpportunity',
              cond: 'addressMatchesInvoice',
            },
            {
              target: 'different_invoice_address',
              actions: 'updateFromOpportunity',
            },
          ],
        },
      },
      different_invoice_address: {
        on: {
          TOGGLE_SAME_INVOICE: {
            target: 'same_invoice_address',
            actions: assign({
              invoice_house_number: ctx => ctx.house_number,
              invoice_address_line_1: ctx => ctx.address_line_1,
              invoice_city: ctx => ctx.address_city,
              invoice_postcode: ctx => ctx.address_postcode,
            }),
          },
          HOUSE_NUMBER: {
            target: 'different_invoice_address',
            actions: assign({ house_number: (_, { value = '' }) => value }),
          },
          ADDRESS_LINE_1: {
            target: 'different_invoice_address',
            actions: assign({ address_line_1: (_, { value = '' }) => value }),
          },
          ADDRESS_CITY: {
            target: 'different_invoice_address',
            actions: assign({ address_city: (_, { value = '' }) => value }),
          },
          ADDRESS_POSTCODE: {
            target: 'different_invoice_address',
            actions: assign({ address_postcode: (_, { value = '' }) => value }),
          },
          INVOICE_HOUSE_NUMBER: {
            target: 'different_invoice_address',
            actions: assign({ invoice_house_number: (_, { value = '' }) => value }),
          },
          INVOICE_ADDRESS_LINE_1: {
            target: 'different_invoice_address',
            actions: assign({ invoice_address_line_1: (_, { value = '' }) => value }),
          },
          INVOICE_CITY: {
            target: 'different_invoice_address',
            actions: assign({ invoice_city: (_, { value = '' }) => value }),
          },
          INVOICE_POSTCODE: {
            target: 'different_invoice_address',
            actions: assign({ invoice_postcode: (_, { value = '' }) => value }),
          },
        },
      },
    },
  },
  {
    actions: {
      updateFromOpportunity: assign({
        house_number: (_, { opportunity }) => a(opportunity).houseNumber(),
        address_line_1: (_, { opportunity }) => a(opportunity).addressLine1(),
        address_city: (_, { opportunity }) => a(opportunity).city(),
        address_postcode: (_, { opportunity }) => a(opportunity).postcode(),
        invoice_house_number: (_, { opportunity }) => a(opportunity).invoiceHouseNumber(),
        invoice_address_line_1: (_, { opportunity }) => a(opportunity).invoiceAddressLine1(),
        invoice_city: (_, { opportunity }) => a(opportunity).invoiceCity(),
        invoice_postcode: (_, { opportunity }) => a(opportunity).invoicePostcode(),
      }),
    },
    guards: {
      addressMatchesInvoice: (_, { opportunity }) => {
        const o = a(opportunity);
        return (
          o.houseNumber() === o.invoiceHouseNumber() &&
          o.addressLine1() === o.invoiceAddressLine1() &&
          o.city() === o.invoiceCity() &&
          o.postcode() === o.invoicePostcode()
        );
      },
    },
  }
);

export default addressMachine;
