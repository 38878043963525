import PropTypes from 'prop-types';
import React from 'react';
import LoginPage from '../login-page';
import { connect } from 'react-redux';
import { login } from '../../../redux/auth/actions';
import LoginForm from '../../../components/forms/LoginForm';

const Login = ({ auth, location, login, user }) => (
  <LoginPage
    auth={auth}
    config={{
      allowLoginViaToken: false,
    }}
    location={location}
    login={login}
    user={user}
  >
    <p>To login please enter your email and password</p>
    <LoginForm />
    <a href={`${process.env.REACT_APP_API_BASE_URL}/azure/oauth`} className="azure-login" data-testid="azure-login">
      Azure login
    </a>
  </LoginPage>
);

Login.propTypes = {
  auth: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  login: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default connect(({ auth, user }) => ({ auth, user }), { login })(Login);
