import React from 'react';
import PropTypes from 'prop-types';
import useSurveyValidation from '../../contexts/survey-validation-context';
import HollywoodRadios from '../../hollywood/radios';

const Radio = props => {
  const { validator } = useSurveyValidation();
  const { name, ...rest } = props;
  return <HollywoodRadios name={name} {...rest} hasError={validator.error(name)} />;
};

Radio.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        id: PropTypes.string,
        testId: PropTypes.string,
      }),
      PropTypes.string.isRequired,
    ])
  ).isRequired,
  testId: PropTypes.string,
  value: PropTypes.string,
};

Radio.defaultProps = {
  id: '',
  className: null,
  disabled: false,
  onBlur: null,
  onChange: null,
  testId: null,
  value: '',
};

export default Radio;
