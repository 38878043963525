import React from 'react';
import IconButton from '@zing/neo-common/dist/components/IconButton';
import PropTypes from 'prop-types';

export const ApplicantDetailsButton = ({ setShowApplicantDetails, showApplicantDetails }) => {
  const chevron = showApplicantDetails ? 'chevron-up' : 'chevron-down';
  const testId = showApplicantDetails ? 'hide_details' : 'show_details';
  const label = showApplicantDetails ? 'Hide applicant details' : 'Show applicant details';
  return (
    <p className="show-applicant-details">
      <IconButton
        name="show-applicant-details"
        testId="show_applicant_details"
        onClick={() => {
          setShowApplicantDetails(!showApplicantDetails);
        }}
        icon={chevron}
      />
      <strong data-testid={testId}>{label}</strong>
    </p>
  );
};

ApplicantDetailsButton.propTypes = {
  showApplicantDetails: PropTypes.bool.isRequired,
  setShowApplicantDetails: PropTypes.func.isRequired,
};
