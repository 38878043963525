import { get } from '@zing/neo-common/dist/lib/safe';
import { Link } from 'react-router-dom';
import Icon from '@zing/neo-common/dist/components/Icon';
import initialState from '../../redux/pim-session/initialstate';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import useMenuService from '../../hooks/use-menu-service';

import './menus.scss';

/**
 * AppMenu
 * The main app menu for survey pages, but NOT diagnostics
 */
const AppMenu = ({ session, canSeeDiagnostics }) => {
  const [pimSession] = useLocalStorage('pimSession', initialState);
  const continueSessionUrl = get(session, 'survey.current_url', '/');
  const [api] = useMenuService();

  const menuRef = useRef();

  const handleOutsideClick = event => {
    if (!menuRef.current.contains(event.target)) {
      api.close();
    }
  };

  const isOpen = api.isOpen();
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <nav className={`app-menu ${api.isOpen() ? 'app-menu--open' : ''}`} data-testid="app-menu" ref={menuRef}>
      <button type="button" className="app-menu__close-btn" onClick={() => api.close()} data-testid="menu-close-btn">
        <Icon icon="close" />
      </button>
      <div className="app-menu__body">
        <h3>Quick Links</h3>
        <ul>
          <li>
            <Link to={continueSessionUrl} onClick={() => api.close()}>
              <Icon icon="continue" />
              Continue session
            </Link>
          </li>
          {pimSession.opportunity && (
            <li>
              <Link to="/risk-assessment" onClick={() => api.close()}>
                <Icon icon="alert" />
                Risk assessment
              </Link>
            </li>
          )}
        </ul>
        <h3>My App</h3>
        <ul>
          <li>
            <Link to="/opportunities" onClick={() => api.close()}>
              <Icon icon="calendar" />
              Appointments
            </Link>
          </li>
          <li>
            <Link to="/pipeline/pipelineSearch" onClick={() => api.close()} alt="pipeline">
              <Icon icon="filter" />
              Pipeline
            </Link>
          </li>
          <li>
            <Link to="/notifications/unread" onClick={() => api.close()}>
              <Icon icon="notification" />
              Notifications
            </Link>
          </li>
          <li>
            <Link to="/presenter" onClick={() => api.close()}>
              <Icon icon="presentation" />
              Presentation
            </Link>
          </li>
        </ul>
      </div>
      <div className="app-menu__footer">
        <ul>
          <li>
            <Link to="/settings/app" onClick={() => api.close()}>
              <Icon icon="cog" />
              Settings
            </Link>
          </li>
          {canSeeDiagnostics && (
            <li>
              <Link to="/diagnostics" target="_blank" onClick={() => api.close()}>
                <Icon icon="dashboard" />
                Diagnostics
              </Link>
            </li>
          )}
          <li>
            <Link to="/reset" className="app-menu__logout-btn" onClick={() => api.close()}>
              <Icon icon="logout" />
              Log out
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};
AppMenu.propTypes = {
  session: PropTypes.shape({
    opportunity: PropTypes.shape({}),
  }).isRequired,
  canSeeDiagnostics: PropTypes.bool.isRequired,
};

export default AppMenu;
