import * as config from '@zing/neo-common/dist/config';
import merge from 'lodash.merge';
import { apiDateFormat } from '@zing/neo-common/dist/lib/date';
import { makeGuid } from '@zing/neo-common/dist/lib/make-guid';

/**
 * Fix API bug.
 * - never send 0 for no
 * - 1 for yes
 * - '' for no
 *
 * @param poi
 * @param item
 * @returns {*}
 */
const inProductsOfInterest = (poi, item) => (poi.includes(item) ? 1 : 0);

/**
 * Ensures that the correct details are passed to the API from the form
 *
 * @param values
 * @param userId
 * @returns {*}
 */
export const processCreateOpportunityValues = (values, userId) => {
  // eslint-disable-next-line camelcase
  const { products_of_interest = [], ...rest } = values;

  return merge(
    {},
    rest,
    {
      app_opportunity_uuid: makeGuid(userId),
      app_meeting_uuid: makeGuid(userId, new Date(), 'meeting'),
      survey_appt_date: apiDateFormat(new Date()),
      date_of_customer_request: apiDateFormat(new Date()),
      lat: Number(values.lat),
      lng: Number(values.lng),
    },
    {
      battery: inProductsOfInterest(products_of_interest, config.POI_BATTERY_STORAGE.key),
      ev: inProductsOfInterest(products_of_interest, config.POI_EV_CHARGING.key),
      smart_heating_controls: inProductsOfInterest(products_of_interest, config.POI_SMART_HEATING.key),
      smart_lighting_controls: inProductsOfInterest(products_of_interest, config.POI_SMART_LIGHTING.key),
      solar: inProductsOfInterest(products_of_interest, config.POI_SOLAR_PV.key),
    }
  );
};
