export default (items, id, changes) =>
  items.map(item => {
    if (item.id === id) {
      return {
        ...item,
        ...changes,
      };
    }
    return item;
  });
