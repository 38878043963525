/* eslint-disable no-console */
import PimComparison, { BATTERY_ONLY_PACKAGE_PROD_ID } from '@zing/neo-common/dist/accessors/pim-comparison';
// eslint-disable-next-line import/no-cycle
import { pimExport, pimExportTitles } from '../../../machines/survey/pim-export';
import initialContext from '../../../machines/survey.machine.initial-context';
import { interpret } from 'xstate';
// eslint-disable-next-line import/no-cycle
import surveyMachine from '../../../machines/survey.machine';
// eslint-disable-next-line import/no-cycle
import createQuoteObject from '../../../pim/survey/pages/payment-options/create-quote';
import { FILTER_1_PHASE } from '@zing/neo-common/dist/redux/product/accessor';
import { limitDecimals } from '@zing/neo-common/dist/lib/number';

export const trueOrFalse = x => (x === true ? 'TRUE' : 'FALSE');

export const buildCsvExportInputs = (selections, pim) => {
  const { pkg, panels, batteries, scaffolding, leadCategory, discountType, discount, discountCode, roofFixing } = selections;
  const pricingModels = [];
  // packages.forEach(pkg => {
  const comparison = new PimComparison(pim);
  comparison.setPackage(pkg.prodId);

  const selectedBatteries = comparison.getBatteries().filter(b => batteries.includes(b.prodId));
  const selectedScaffolding = comparison.getScaffolding().find(s => s.prodId === scaffolding);

  // Handle solar packages
  if (pkg.prodId !== BATTERY_ONLY_PACKAGE_PROD_ID) {
    const selectedPanels = comparison.getPanels().filter(p => panels.includes(p.prodId));

    [null].concat(selectedBatteries).forEach(battery => {
      selectedPanels.forEach(panel => {
        for (let numPanels = 4; numPanels <= 20; numPanels += 1) {
          pricingModels.push({
            pkg,
            battery,
            isBatteryOnly: false,
            panel,
            numPanels,
            scaffolding: selectedScaffolding,
            leadCategory,
            discountType,
            discount,
            discountCode,
            roofFixing,
          });
        }
      });
    });
  }

  // Handle battery only
  if (pkg.prodId === BATTERY_ONLY_PACKAGE_PROD_ID) {
    selectedBatteries.forEach(battery => {
      pricingModels.push({
        pkg,
        battery,
        isBatteryOnly: true,
        panel: null,
        numPanels: 0,
        leadCategory,
        discountType,
        discount,
        discountCode,
        roofFixing,
      });
    });
  }
  // });
  return pricingModels;
};

export const buildCsvTitlesRow = () => {
  // Titles
  const csvExportTitles = pimExportTitles({ extendedInfo: true });
  return ['Package name', 'Number of panels', 'Panel prodId', 'Battery prodId', 'Is battery only?'].concat(
    csvExportTitles.map(x => x.title)
  );
};

const COMPLETED_LOADING = 'COMPLETED_LOADING';
const currentComparison = 'survey';
const DEBUG = false;

// no longer used, we use the single version instead.
// export const processPricingModels = (pim, pricingModels, results) => {
//   pricingModels.forEach(model => {
//     // Fire up the survey machine and send it messages

//     const pimContext = initialContext(pim);
//     const surveyService = interpret(surveyMachine.withContext(pimContext)).onTransition(state => {
//       if (state.event.type === COMPLETED_LOADING) {
//         // build a quote for the current session
//         const { model } = state.event;
//         const pimSession = {}; // fake session for cresting quote
//         const { currentComparison, comparisons } = state.context;
//         const { pimComparison, calcs = {} } = comparisons[currentComparison];
//         const quote = createQuoteObject(pimSession, state, pimComparison);
//         const csvExportValues = pimExport({ calcs, quote, extendedInfo: true });

//         // we now know the inputs, the quote & the csv export values
//         // put them all together into one CSV line
//         const row = [model.pkg.name, model.numPanels, model.panel?.name || '', model.battery?.name || '', model.isBatteryOnly].concat(
//           csvExportValues.map(x => x.rawValue)
//         );

//         results.push(row);
//       }
//     });

//     surveyService.start();
//     const { send } = surveyService;

//     // select the correct package
//     if (!model.isBatteryOnly) {
//       send({ type: 'SELECT_SOLAR_PACKAGE', currentComparison, prodId: model.pkg.prodId });
//     }
//     if (model.panel) {
//       send({ type: 'SELECT_PANEL', currentComparison, prodId: model.panel.prodId });
//     }
//     if (model.numPanels) {
//       send({ type: 'SLOPE_ELEVATION', currentComparison, index: 0, value: 0 });
//       send({ type: 'AZIMUTH_ELEVATION', currentComparison, index: 0, value: 0 });
//       send({ type: 'NUMBER_OF_PANELS_ELEVATION', currentComparison, index: 0, value: model.numPanels });
//     }
//     if (model.battery) {
//       send({ type: 'BATTERY_SELECT', currentComparison, prodId: model.battery.prodId });
//     }
//     send({ type: COMPLETED_LOADING, model });
//   });
// };

export const processPricingModel = (pim, model, results, defaults) => {
  // Fire up the survey machine and send it messages
  const pimContext = initialContext(pim, defaults);
  const surveyService = interpret(surveyMachine.withContext(pimContext)).onTransition(state => {
    // console.log('processPricingModel->state', state);
    if (state.event.type === COMPLETED_LOADING) {
      // build a quote for the current session
      const { model } = state.event;
      const pimSession = {}; // fake session for cresting quote
      const { currentComparison, comparisons } = state.context;
      const { pimComparison, calcs = {} } = comparisons[currentComparison];
      const quote = createQuoteObject(pimSession, state, pimComparison);
      const csvExportValues = pimExport({ calcs, quote, extendedInfo: true });

      // we now know the inputs, the quote & the csv export values
      // put them all together into one CSV line
      const row = [
        model.pkg.name,
        model.numPanels,
        model.panel?.prodId || '',
        model.battery?.prodId || '',
        trueOrFalse(model.isBatteryOnly),
      ].concat(csvExportValues.map(x => x.rawValue));

      if (DEBUG || window.debug === true) {
        console.log('');
        console.log('DEBUG');
        console.log('state.event', state.event);
        console.log('model', model);
        console.log('current', state);
        console.log('pimSession', pimSession);
        console.log('currentComparison', currentComparison);
        console.log('comparisons', comparisons);
        console.log('pimComparison', pimComparison);
        console.log('calcs', calcs);
        console.log('quote', quote);
        console.log('csvExportValues', csvExportValues);
        console.log('row', row);
      }

      // How to get the results out async?
      // trigger callback?
      results.push(row);
    }
  });

  surveyService.start();
  const { send } = surveyService;

  // Init values
  send({ type: 'POSTCODE', currentComparison, value: 'NG15 0DR' });

  // select the correct package
  if (model.isBatteryOnly) {
    send({ type: 'UNSELECT_SOLAR_PACKAGE', currentComparison });
    send({ type: 'METER_TYPE', currentComparison, value: FILTER_1_PHASE });
  } else {
    send({ type: 'SELECT_SOLAR_PACKAGE', currentComparison, prodId: model.pkg.prodId });
  }
  // if (model.scaffolding) {
  //   send({ type: 'EXPECTED_SCAFFOLD_COST', currentComparison, prodId: model.scaffolding });
  // }
  if (model.panel) {
    send({ type: 'SELECT_PANEL', currentComparison, prodId: model.panel.prodId });
  }
  if (model.numPanels) {
    send({ type: 'METER_TYPE', currentComparison, value: FILTER_1_PHASE });
    send({ type: 'SLOPE_ELEVATION', currentComparison, index: 0, value: 35 });
    send({ type: 'AZIMUTH_ELEVATION', currentComparison, index: 0, value: 45 });
    send({ type: 'NUMBER_OF_PANELS_ELEVATION', currentComparison, index: 0, value: model.numPanels });
  }
  if (model.roofFixing) {
    send({ type: 'SELECT_ROOF_FIXING', currentComparison, prodId: model.roofFixing });
  }
  if (model.battery) {
    send({ type: 'BATTERY_ADD', currentComparison });
    send({ type: 'BATTERY_SELECT', currentComparison, prodId: model.battery.prodId });
  }
  if (model.scaffolding) {
    send({ type: 'EXPECTED_SCAFFOLD_COST', currentComparison, prodId: model.scaffolding.prodId });
  }
  if (model.leadCategory) {
    send({ type: 'LEAD_CATEGORY', currentComparison, value: model.leadCategory });
  }

  if (model.discountType) {
    send({ type: 'DISCOUNT_TYPE', currentComparison, value: model.discountType });
  }
  if (model.discountType === 'code' && model.discountCode) {
    send({ type: 'DISCOUNT_CODE', currentComparison, value: model.discountCode });
  }
  if (model.discountType === 'percentage') {
    const valueToSend = limitDecimals(Math.max(0, model.discount), 2);
    send({ type: 'DISCOUNT_PERCENTAGE', currentComparison, value: valueToSend / 100 });
  }
  if (model.discountType === 'fixed') {
    const valueToSend = limitDecimals(Math.max(0, model.discount), 2);
    send({ type: 'DISCOUNT_FIXED', currentComparison, value: valueToSend });
  }

  send({ type: COMPLETED_LOADING, model });
};
