/* eslint-disable camelcase */
import { api } from '@zing/neo-common/dist/lib/api';
import { assign, Machine, sendParent } from 'xstate';
import { pure } from 'xstate/lib/actions';
import { sendToastMessage } from './actions';
import pick from 'lodash.pick';

// eslint-disable-next-line no-unused-vars
const fakeService = () =>
  new Promise((resolve, reject) => {
    const decision = Math.random() < 0.5;
    if (decision) {
      return resolve('all ok');
    }
    return reject(Error('Everything exploded'));
  });

const processUser = user => {
  user.enabled = user.enabled ? String(user.enabled) : '0';
  return user;
};

function filterUsers(filter) {
  return user => {
    if (filter === 'all') {
      return true;
    }
    if (filter === 'enabled') {
      return user.enabled === '1';
    }
    if (filter === 'disabled') {
      return user.enabled === '0';
    }
    return false;
  };
}

const fields = ['app_uuid', 'email', 'email_login', 'enabled', 'firstname', 'lastname', 'mobile', 'role', 'telephone'];

const diagnosticsUsersMachine = Machine(
  {
    id: 'diagnosticsUsers',
    initial: 'listView',
    context: {
      accessToken: undefined,
      users: [],
      filter: 'all',
      user: undefined,
    },
    states: {
      listView: {
        id: 'listView',
        initial: 'idle',
        states: {
          idle: {
            on: {
              FETCH: 'fetching',
            },
          },
          fetching: {
            invoke: {
              src: 'getUsers',
              onDone: {
                target: 'storeUsers',
                actions: assign({
                  users: (context, e) => e.data.data.map(x => processUser(pick(x, fields))).filter(filterUsers(context.filter)),
                }),
              },
              onError: 'getUsersFailed',
            },
          },
          storeUsers: {
            always: 'list',
          },
          list: {
            on: {
              EDIT: '#editUser.init',
              FILTER: {
                actions: assign({
                  filter: (_, e) => e.filter,
                }),
                target: 'fetching',
              },
            },
          },
          getUsersFailed: {
            on: {
              RETRY: 'fetching',
            },
          },
        },
        on: {
          ADD: 'addUser.idle',
        },
      },
      addUser: {
        states: {
          idle: {
            on: {
              SAVE: 'saving',
            },
          },
          saving: {
            invoke: {
              src: 'addUser',
              onDone: {
                target: '#editUser.init',
                actions: sendToastMessage({ message: 'User created.' }),
              },
              onError: {
                target: 'failed',
                actions: sendToastMessage({ message: 'Error creating user.' }),
              },
            },
          },
          failed: {
            on: {
              RETRY: 'saving',
            },
          },
        },
        on: {
          CANCEL: '#listView',
        },
      },
      editUser: {
        id: 'editUser',
        states: {
          init: {
            entry: assign({ user: undefined, users: undefined }),
            invoke: {
              src: 'getUser',
              onDone: {
                target: 'editing',
                actions: assign({ user: (_, e) => processUser(pick(e.data.data, fields)) }),
              },
              onError: {
                target: 'failed',
                actions: sendToastMessage({ message: 'Error retrieving user.' }),
              },
            },
          },
          editing: {
            on: {
              UPDATE: 'updating',
            },
          },
          updating: {
            invoke: {
              src: 'updateUser',
              onDone: {
                target: '#listView.idle',
                actions: sendToastMessage({ message: 'User updated.' }),
              },
              onError: {
                target: 'failed',
                actions: sendToastMessage({ message: 'Error updating user.' }),
              },
            },
          },
          failed: {
            on: {
              RETRY: 'updating',
            },
          },
        },
        on: {
          CANCEL: '#listView',
        },
      },
    },
    on: {
      AUTH_LOGOUT: {
        actions: assign({ accessToken: '', users: [] }),
      },
      NOTIFY_AUTH: {
        actions: [assign({ accessToken: (_, event) => event.accessToken })],
      },
      // todo: we have this (hack) to handle the dual login API calls, we can remove once this is done properly
      USER_DETAILS: {
        actions: [
          assign({
            email: (_, event) => event.email,
          }),
        ],
      },
    },
  },
  {
    actions: {
      // Notify the parent app that something interesting happened
      // - message: text description
      // - actionType: the action this message is related too (allows decision making)
      // - payload: additional payload to send to the parent
      notifyParent: pure((_, { message = '', actionType = '', payload = {} }) =>
        sendParent({ type: 'NOTIFY_PARENT', message, actionType, payload })
      ),
    },
    services: {
      getUsers: ({ accessToken }) => {
        const url = `/cms/users?limit=100`; // force low data during test
        return api({ accessToken, url, method: 'GET' });
      },
      getUser: ({ accessToken }, e) => {
        const url = `/cms/users/${e.data.data.app_uuid}`;
        return api({ accessToken, url, method: 'GET' });
      },
      addUser: ({ accessToken }, { data }) => {
        const url = `/cms/users`;
        return api({ accessToken, url, method: 'POST', data });
      },
      updateUser: ({ accessToken }, { app_uuid, data }) => {
        const url = `/cms/users/${app_uuid}`;
        return api({ accessToken, url, method: 'PUT', data });
      },
    },
  }
);

export default diagnosticsUsersMachine;
