import AdditionalProducts from '../tabs/additional-products';
import Battery from '../tabs/battery';
import EnergyDetails from '../tabs/energy-details';
import EvCharging from '../tabs/ev-charging';
import G99 from '../tabs/g99';
import InstallationNotes from '../tabs/installation-notes';
import PaymentOptions from '../pages/payment-options';
import PimEmailQuote from '../pages/email-quote';
import PimG99Application from '../pages/g99-application';
import PimQuote from '../pages/quote';
import PimSunpath from '../tabs/sunpath';
import PropertyDetails from '../tabs/property-details';
import Settings from '../tabs/settings';
import SolarPv from '../tabs/solar-pv';
import TermsAndConditions from '../../../pages/TermsAndConditions';
import YourPackage from '../pages/your-package';
import { ThankYouForChoosing } from '../../../pages/ThankYouForChoosing';

export const diagnosticSurveyRoutes = [
  { path: '/appointment/:id/:meetingId/survey/additional-products', component: AdditionalProducts },
  { path: '/appointment/:id/:meetingId/survey/battery', component: Battery },
  { path: '/appointment/:id/:meetingId/survey/email-quote/:quoteId', component: PimEmailQuote },
  { path: '/appointment/:id/:meetingId/survey/energy-details', component: EnergyDetails },
  { path: '/appointment/:id/:meetingId/survey/ev-charging', component: EvCharging },
  { path: '/appointment/:id/:meetingId/survey/g99', component: G99 },
  { path: '/appointment/:id/:meetingId/survey/g99-application', component: PimG99Application },
  { path: '/appointment/:id/:meetingId/survey/installation-notes', component: InstallationNotes },
  { path: '/appointment/:id/:meetingId/survey/payment-options', component: PaymentOptions },
  { path: '/appointment/:id/:meetingId/survey/property-details', component: PropertyDetails },
  { path: '/appointment/:id/:meetingId/survey/quote/:quoteId', component: PimQuote },
  { path: '/appointment/:id/:meetingId/survey/settings', component: Settings },
  { path: '/appointment/:id/:meetingId/survey/solar-pv', component: SolarPv },
  { path: '/appointment/:id/:meetingId/survey/sunpath/:roofId', component: PimSunpath },
  { path: '/appointment/:id/:meetingId/survey/terms-and-conditions/:quoteId?', component: TermsAndConditions },
  { path: '/appointment/:id/:meetingId/survey/thank-you-for-choosing-eon/:quoteId', component: ThankYouForChoosing },
  { path: '/appointment/:id/:meetingId/survey/your-package', component: YourPackage },
];
