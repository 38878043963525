import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { resetOpportunities } from '../redux/opportunities/actions';
import { logout } from '../redux/auth/actions';
import { reset as resetOffline } from '@zing/neo-common/dist/redux/offline/actions';
import * as PropTypes from 'prop-types';
import useAppService from '../hooks/use-app-service';

const Reset = ({ clearOpportunities, resetOffline, logout }) => {
  const { appApi } = useAppService();
  clearOpportunities();
  resetOffline();
  logout();
  appApi.auth.logout();
  return <Redirect to="/login" />;
};

Reset.propTypes = {
  clearOpportunities: PropTypes.func.isRequired,
  resetOffline: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(null, { clearOpportunities: resetOpportunities, resetOffline, logout })(Reset);
