export const PIM_CREATE_QUOTE = 'PIM_CREATE_QUOTE';
export const PIM_CREATE_QUOTE_COMMIT = 'PIM_CREATE_QUOTE_COMMIT';
export const SESSION_ADD_SURVEY_PACKAGE = 'SESSION_ADD_SURVEY_PACKAGE';
export const SESSION_AGREE_QUOTE = 'SESSION_AGREE_QUOTE';
export const SESSION_AGREE_QUOTE_COMMIT = 'SESSION_AGREE_QUOTE_COMMIT';
export const SESSION_AGREE_QUOTE_REQUEST = 'SESSION_AGREE_QUOTE_REQUEST';
export const SESSION_AGREE_QUOTE_ROLLBACK = 'SESSION_AGREE_QUOTE_ROLLBACK';
export const SESSION_BEGIN = 'SESSION_BEGIN';
export const SESSION_CALC_SETTINGS = 'SESSION_CALC_SETTINGS';
export const SESSION_CREATE_QUOTE = 'SESSION_CREATE_QUOTE';
export const SESSION_CREATE_QUOTE_COMMIT = 'SESSION_CREATE_QUOTE_COMMIT';
export const SESSION_CREATE_QUOTE_REQUEST = 'SESSION_CREATE_QUOTE_REQUEST';
export const SESSION_CREATE_QUOTE_ROLLBACK = 'SESSION_CREATE_QUOTE_ROLLBACK';
export const SESSION_CURRENT_URL = 'SESSION_CURRENT_URL';
export const SESSION_CUSTOMER_FREE_EPC = 'SESSION_CUSTOMER_FREE_EPC';
export const SESSION_DELETE_ROOF_IMAGE = 'SESSION_DELETE_ROOF_IMAGE';
export const SESSION_DELETE_SURVEY_PACKAGE = 'SESSION_DELETE_SURVEY_PACKAGE';
export const SESSION_EMAIL_QUOTE = 'SESSION_EMAIL_QUOTE';
export const SESSION_EMAIL_QUOTE_COMMIT = 'SESSION_EMAIL_QUOTE_COMMIT';
export const SESSION_EMAIL_QUOTE_REQUEST = 'SESSION_EMAIL_QUOTE_REQUEST';
export const SESSION_EMAIL_QUOTE_ROLLBACK = 'SESSION_EMAIL_QUOTE_ROLLBACK';
export const SESSION_RE_EMAIL_QUOTE = 'SESSION_RE_EMAIL_QUOTE';
export const SESSION_RE_EMAIL_QUOTE_COMMIT = 'SESSION_RE_EMAIL_QUOTE_COMMIT';
export const SESSION_RE_EMAIL_QUOTE_REQUEST = 'SESSION_RE_EMAIL_QUOTE_REQUEST';
export const SESSION_RE_EMAIL_QUOTE_ROLLBACK = 'SESSION_RE_EMAIL_QUOTE_ROLLBACK';
export const SESSION_POWRA_AFTERWARDS = 'SESSION_POWRA_AFTERWARDS';
export const SESSION_POWRA_ARRIVAL = 'SESSION_POWRA_ARRIVAL';
export const SESSION_POWRA_MEET_CUSTOMER = 'SESSION_POWRA_MEET_CUSTOMER';
export const SESSION_POWRA_START_SURVEY = 'SESSION_POWRA_START_SURVEY';
export const SESSION_RELOAD = 'SESSION_RELOAD';
export const SESSION_RENAME_SURVEY_PACKAGE = 'SESSION_RENAME_SURVEY_PACKAGE';
export const SESSION_RESET = 'SESSION_RESET';
export const SESSION_FINISH = 'SESSION_FINISH';
export const SESSION_RESET_QUOTES = 'SESSION_RESET_QUOTES';
export const SESSION_SAVE_SUNPATH = 'SESSION_SAVE_SUNPATH';
export const SESSION_SEND = 'SESSION_SEND';
export const SESSION_SEND_BEGIN = 'SESSION_SEND_BEGIN';
export const SESSION_SEND_ERROR = 'SESSION_SEND_ERROR';
export const SESSION_SEND_SUCCESS = 'SESSION_SEND_SUCCESS';
export const SESSION_UPDATE_OPPORTUNITY = 'SESSION_UPDATE_OPPORTUNITY';
export const SESSION_UPDATE_POWRA = 'SESSION_UPDATE_POWRA';
export const SESSION_UPDATE_SURVEY = 'SESSION_UPDATE_SURVEY';
export const SESSION_UPDATE_SURVEY_PACKAGE = 'SESSION_UPDATE_SURVEY_PACKAGE';
export const SESSION_UPDATE_SURVEY_PACKAGE_DELETE_BATTERIES = 'SESSION_UPDATE_SURVEY_PACKAGE_DELETE_BATTERIES';
export const SESSION_UPDATE_SURVEY_PACKAGE_EMAIL_QUOTE = 'SESSION_UPDATE_SURVEY_PACKAGE_EMAIL_QUOTE';
export const SESSION_UPLOAD_ROOF_IMAGE = 'SESSION_UPLOAD_ROOF_IMAGE';
