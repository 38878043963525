import * as Yup from 'yup';
import { ONE_REQUIRED, REQUIRED, REQUIRED_NUMBER } from '../../../../components/Survey/validate/messages';

require('@zing/neo-common/dist/lib/yup');

const comparisonShape = Yup.object().shape({
  importRate: Yup.number()
    .typeError(REQUIRED_NUMBER)
    .required(REQUIRED),
  energyConsumptionProfile: Yup.string().required(REQUIRED),

  annualElectricityDemandKwh: Yup.string().when(['$context.currentComparison', '$context.comparisons'], {
    is: (currentComparison, comparisons) =>
      !comparisons[currentComparison].annualElectricityCost && !comparisons[currentComparison].numberOfOccupants,
    then: Yup.string().required(ONE_REQUIRED),
  }),
  annualElectricityCost: Yup.string().when(['$context.currentComparison', '$context.comparisons'], {
    is: (currentComparison, comparisons) =>
      !comparisons[currentComparison].annualElectricityDemandKwh && !comparisons[currentComparison].numberOfOccupants,
    then: Yup.string().required(ONE_REQUIRED),
  }),
  numberOfOccupants: Yup.string().when(['$context.currentComparison', '$context.comparisons'], {
    is: (currentComparison, comparisons) =>
      !comparisons[currentComparison].annualElectricityDemandKwh && !comparisons[currentComparison].annualElectricityCost,
    then: Yup.string().required(ONE_REQUIRED),
  }),

  meterType: Yup.string().required(ONE_REQUIRED),
});

const schema = comparison =>
  Yup.object().shape({
    context: Yup.object().shape({
      // Next two are required if existingGridConnectedPvOnSite
      existingPvAnnualYieldKwh: Yup.mixed().when('$value.existingGridConnectedPvOnSite', {
        is: 'yes',
        then: () =>
          Yup.number()
            .min(0)
            .required(REQUIRED),
      }),
      existingPvInverterTotalRatedOutputKw: Yup.mixed().when('$value.existingGridConnectedPvOnSite', {
        is: 'yes',
        then: () =>
          Yup.number()
            .min(0)
            .required(REQUIRED),
      }),

      comparisons: Yup.object().shape({
        [comparison]: comparisonShape,
      }),
      mpan: Yup.string().required(REQUIRED),
      eonCustomer: Yup.string().required(REQUIRED),
    }),
    value: Yup.object().shape({
      // Must be 'yes' if battery only
      existingGridConnectedPvOnSite: Yup.string().when(
        ['$context.currentComparison', '$context.comparisons'],
        (currentComparison, comparisons) => {
          if (comparisons[currentComparison].isBatteryOnly === true) {
            return Yup.string().oneOf(['yes'], REQUIRED);
          }
          return Yup.string().oneOf(['yes', 'no'], REQUIRED);
        }
      ),
    }),
  });

export default schema;
