import * as Yup from 'yup';
import {
  MSG_EMAIL_INVALID,
  MSG_EMAIL_REQUIRED,
  MSG_FIRST_NAME_REQUIRED,
  MSG_LAST_NAME_REQUIRED,
  MSG_MOBILE_REQUIRED,
  MSG_PHONE_REQUIRED,
  MSG_ROLE_IS_REQUIRED,
} from '../../../../config';

require('@zing/neo-common/dist/lib/yup');

const MSG_PASSWORD_REQUIRED = 'Password is required';
const MSG_PASSWORD_INVALID =
  'Password must be at least 12 characters, and must contain 3 out of 4 of the following types of characters: numbers, uppercase letters, lowercase letters, special characters';
const MSG_PASSWORD_MATCH = 'Passwords do not match';

const passwordSchema = Yup.string()
  .min(12, MSG_PASSWORD_INVALID)
  .matches(/^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!$#%]).*$/, MSG_PASSWORD_INVALID);

const passwordConfirmationSchema = Yup.string().oneOf([Yup.ref('password'), null], MSG_PASSWORD_MATCH);

export const sharedValidator = Yup.object().shape({
  firstname: Yup.string()
    .required(MSG_FIRST_NAME_REQUIRED)
    .nullable(),
  lastname: Yup.string()
    .required(MSG_LAST_NAME_REQUIRED)
    .nullable(),
  mobile: Yup.string()
    .required(MSG_MOBILE_REQUIRED)
    .nullable(),
  telephone: Yup.string()
    .required(MSG_PHONE_REQUIRED)
    .nullable(),
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_EMAIL_REQUIRED)
    .nullable(),
  email_login: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_EMAIL_REQUIRED)
    .nullable(),
  role: Yup.string().required(MSG_ROLE_IS_REQUIRED),
});

export const addUserValidator = sharedValidator.concat(
  Yup.object().shape({
    password: Yup.string().when('role', role => (role === 'External' ? passwordSchema.required(MSG_PASSWORD_REQUIRED) : passwordSchema)),
    password_confirmation: Yup.string().when('role', role =>
      role === 'External' ? passwordConfirmationSchema.required(MSG_PASSWORD_MATCH) : passwordConfirmationSchema
    ),
  })
);

export const editUserValidator = sharedValidator.concat(
  Yup.object().shape({
    password: passwordSchema,
    password_confirmation: Yup.string().when('password', password =>
      password ? passwordConfirmationSchema.required(MSG_PASSWORD_MATCH) : passwordConfirmationSchema
    ),
  })
);
