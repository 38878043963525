import React from 'react';
import Icon from '@zing/neo-common/dist/components/Icon';

const Icons = () => (
  <>
    <Icon icon="alert" />
    <Icon icon="arrow" />
    <Icon icon="binoculars" />
    <Icon icon="calendar" />
    <Icon icon="chevron-left" />
    <Icon icon="chevron-up" />
    <Icon icon="chevron-right" />
    <Icon icon="chevron-down" />
    <Icon icon="clock" />
    <Icon icon="close" />
    <Icon icon="cog" />
    <Icon icon="continue" />
    <Icon icon="dashboard" />
    <Icon icon="download" />
    <Icon icon="email" />
    <Icon icon="filter" />
    <Icon icon="filters" />
    <Icon icon="home" />
    <Icon icon="leaf" />
    <Icon icon="lock" />
    <Icon icon="logout" />
    <Icon icon="menu" />
    <Icon icon="more" />
    <Icon icon="notification" />
    <Icon icon="pages" />
    <Icon icon="pencil" />
    <Icon icon="person" />
    <Icon icon="presentation" />
    <Icon icon="print" />
    <Icon icon="pylon" />
    <Icon icon="search" />
    <Icon icon="sun" />
    <Icon icon="tick" />
    <Icon icon="trash" />
  </>
);

export default Icons;
