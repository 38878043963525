import React from 'react';
import PropTypes from 'prop-types';
import BackButton from '../../components/buttons/back';
import Header from '../../components/Header';
import quoteAccessor from '@zing/neo-common/dist/accessors/quote';
import TermsPdf from '@zing/neo-common/dist/components/terms-pdf/terms-pdf';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { connect } from 'react-redux';
import { get } from '@zing/neo-common/dist/lib/safe';
import { opportunityMatchQuoteId } from '@zing/neo-common/dist/redux/opportunity/accessor';

import './Terms.scss';

const TermsAndConditions = ({ session, match, history }) => {
  const quoteId = opportunityMatchQuoteId(match);
  const quoteValues = get(session, `quotes[${quoteId}]`);
  const quote = quoteAccessor(quoteValues);
  const pdfUrl = quote.termsAndConditionsPdfLink(process.env.REACT_APP_API_STATIC_BASE_URL);

  return (
    <>
      <Header title="">
        <div className="terms-header-buttons">
          <BackButton onClick={() => history.goBack()} />
        </div>
      </Header>
      <main className="terms-and-conditions" data-testid="terms-and-conditions">
        <Grid gutters="lg">
          <Col width="12" align="right">
            <h1>Terms &amp; conditions</h1>
          </Col>
          <Col>
            <TermsPdf url={pdfUrl} />
          </Col>
        </Grid>
      </main>
    </>
  );
};

TermsAndConditions.propTypes = {
  session: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
};

export default connect(({ session }) => ({ session }))(TermsAndConditions);
