import { assign, Machine, sendParent } from 'xstate';

const initialState = {
  accessToken: undefined,
  expiresIn: undefined,
  expires: undefined,
  scope: undefined,
  tokenType: undefined,
  refreshToken: undefined,
  requires2Fa: undefined,
  email: undefined,
  firstname: undefined,
  lastname: undefined,
};

const authMachine = Machine(
  {
    id: 'auth',
    initial: 'loggedOut',
    context: {
      ...initialState,
    },
    states: {
      loggedOut: {
        on: {
          AUTH_SUCCESS: {
            target: 'loggedIn',
            actions: ['setSession', 'notifyAuth'],
          },
          AUTH_REFRESH: {
            target: 'loggedIn',
            actions: ['setSession', 'notifyAuth'],
          },
        },
      },
      loggedIn: {
        on: {
          AUTH_LOGOUT: {
            target: 'loggedOut',
            actions: 'clearSession',
          },
        },
      },
    },
    on: {
      AUTH_NOTIFY: {
        actions: 'notifyAuth',
      },
      // todo: we have this (hack) to handle the dual login API calls, we can remove once this is done properly
      USER_DETAILS: {
        actions: [
          assign({
            email: (_, event) => event.email,
            firstname: (_, event) => event.firstname,
            lastname: (_, event) => event.lastname,
          }),
        ],
      },
    },
  },
  {
    actions: {
      clearSession: assign({ ...initialState }),
      notifyAuth: sendParent(context => ({
        type: 'NOTIFY_AUTH',
        accessToken: context.accessToken,
        email: context.email,
        firstname: context.firstname,
        lastname: context.lastname,
      })),
      setSession: assign({
        accessToken: (_, e) => e.access_token,
        expiresIn: (_, e) => e.expires_in,
        expires: (_, e) => new Date(e.expires),
        scope: (_, e) => e.scope,
        tokenType: (_, e) => e.token_type,
        refreshToken: (_, e) => e.refresh_token,
        requires2Fa: (_, e) => e.requires_2fa,
        email: (_, e) => e.email,
        firstname: (_, e) => e?.user?.firstname,
        lastname: (_, e) => e?.user?.lastname,
      }),
    },
  }
);

export default authMachine;
