import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DiagnosticsHeader from '../../../components/diagnostics-header';
import { api } from '@zing/neo-common/dist/lib/api';
import { VoucherCodeForm } from '../../../components/voucher-code-form';

import './voucher-codes.scss';

const VoucherCodesEditPage = ({ auth }) => {
  const history = useHistory();
  const { uuid } = useParams();
  const [state, setState] = React.useState('LOADING');
  const [voucher, setVoucher] = React.useState(null);

  async function fetchCode() {
    try {
      const response = await api({
        accessToken: auth.access_token,
        url: `/voucher/management/search?uuid=${uuid}`,
        method: 'GET',
      });
      const data = response?.data?.output?.data?.[0] || null;
      if (!data) {
        setState('FETCH_ERROR');
        return;
      }
      setVoucher(data);
      setState('IDLE');
    } catch (error) {
      setVoucher(null);
      setState('FETCH_ERROR');
    }
  }

  React.useEffect(() => {
    fetchCode();
  }, [uuid]);

  async function handleSubmit(values) {
    setState('SUBMITTING');
    try {
      await api({
        accessToken: auth.access_token,
        url: `/voucher/management/update`,
        method: 'PUT',
        data: { ...values, active: values.active === '1', single_use: values.single_use === '1' },
      });
      history.push('/diagnostics/voucher-codes/list');
    } catch (error) {
      setState('UPDATE_ERROR');
    }
  }

  return (
    <>
      <DiagnosticsHeader title="Voucher Codes" />
      <main className="voucher-codes">
        <h1>Edit voucher code</h1>
        {state === 'FETCH_ERROR' ? <p>Failed to load voucher code</p> : null}

        {state === 'LOADING' ? <p>Loading...</p> : null}

        {['IDLE', 'SUBMITTING', 'UPDATE_ERROR'].includes(state) ? (
          <VoucherCodeForm
            initialValues={{
              ...voucher,
              single_use: voucher.single_use.toString(),
              active: voucher.active.toString(),
              available_from_date: voucher.available_from_date.split(' ')[0],
              available_to_date: voucher.available_to_date.split(' ')[0],
            }}
            onSubmit={values => handleSubmit(values)}
            state={state}
            error={state === 'UPDATE_ERROR' ? 'Sorry, something went wrong. Please try again.' : null}
          />
        ) : null}
      </main>
    </>
  );
};

VoucherCodesEditPage.propTypes = {
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({ auth }) => ({ auth }))(VoucherCodesEditPage);
