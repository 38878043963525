import { filterOpportunities } from '../../redux/opportunities/filter-opportunities';
import { Machine, assign, spawn } from 'xstate';
import { processOpportunities } from '../../redux/opportunities/process-opportunities';
import { searchOpportunitiesAsPromise } from '../../redux/opportunities/actions';
import { SORT_ORDER_DESC } from '../../components/pipeline/utils';
import { sortOpportunities } from '../../redux/opportunities/sort-opportunities';
import { validate } from './pipeline-search.schema';
import { yupToFormErrors } from 'formik';
import pick from 'lodash.pick';
import pipelineItemMachine from './pipeline-item.machine';

const canSearch = () => true;

export const makeFilters = context => pick(context, ['query', 'dateFrom', 'dateTo']);

/**
 * Pipeline search
 *
 * - Searches IndexedDB when offline
 * - Initially searches IndexedDB when online and then searches API + indexedDB
 */
const pipelineSearchMachine = Machine(
  {
    id: 'pipelineSearch',
    initial: 'offline',
    context: {
      accessToken: 'Passed via withContext',
      canSearch: false,
      dateFrom: '',
      dateFromIsDirty: false,
      dateTo: '',
      dateToIsDirty: false,
      query: '',
      searchIsDirty: false,
      validation: null,
      sortBy: 'created_at',
      sortOrder: SORT_ORDER_DESC,
      opportunities: [],
      chilly: undefined,
    },
    states: {
      offline: {
        entry: 'onGoingOffline',
        states: {
          // waiting for db connection
          waitingForDb: {
            id: 'offline.waitingForDb',
            on: {
              INDEXED_DB_READY: '#offline.loadLocalOpportunities',
            },
          },
          loadLocalOpportunities: {
            id: 'offline.loadLocalOpportunities',
            entry: assign({ opportunities: () => [] }),
            invoke: {
              id: 'localDbSearch',
              src: 'localDbSearch',
              onDone: 'listView',
            },
          },
          listView: {
            id: 'offline.listView',
            entry: ['onEnterListView', 'clearSearchIsDirty'],
            initial: 'listView',
            states: {
              listView: {
                on: {
                  VIEW_QUOTE: 'quoteView',
                  REQUOTE: {
                    actions: 'initRequote',
                  },
                },
              },
              quoteView: {
                on: {
                  BACK: 'listView',
                },
              },
            },
            on: {
              SEARCH: {
                target: '#offline.loadLocalOpportunities',
                cond: 'canSearch',
              },
              INDEXED_DB_READY: '#offline.loadLocalOpportunities',
            },
          },
        },
        on: {
          RESET: {
            target: '.waitingForDb',
            actions: 'resetForm',
          },
          STATUS_ONLINE: [
            {
              target: 'online.hist',
            },
          ],
        },
      },
      online: {
        entry: 'onGoingOnline',
        states: {
          hist: {
            type: 'history',
            history: 'deep',
            target: '#local.waitingForDb',
          },
          local: {
            states: {
              waitingForDb: {
                id: 'local.waitingForDb',
                on: {
                  INDEXED_DB_READY: { target: 'loadLocalOpportunities', actions: assign({ neoDb: (_, e) => e.neoDb }) },
                  STATUS_OFFLINE: '#offline.loadLocalOpportunities',
                },
              },
              loadLocalOpportunities: {
                id: 'online.loadLocalOpportunities',
                entry: [assign({ opportunities: () => [] })],
                invoke: {
                  id: 'localDbSearch',
                  src: 'localDbSearch',
                  onDone: 'listView',
                },
              },
              listView: {
                id: 'listView',
                entry: ['onEnterListView', 'clearSearchIsDirty'],
                initial: 'listView',
                states: {
                  listView: {
                    on: {
                      VIEW_QUOTE: 'quoteView',
                      REQUOTE: {
                        actions: 'initRequote',
                      },
                    },
                  },
                  quoteView: {
                    on: {
                      BACK: 'listView',
                    },
                  },
                },
                on: {
                  SEARCH: {
                    target: '#searchingViaApi',
                    cond: 'canSearch',
                  },
                  STATUS_OFFLINE: '#offline.loadLocalOpportunities',
                  INDEXED_DB_READY: '#online.loadLocalOpportunities',
                },
              },
            },
            on: {
              RESET: {
                target: '.loadLocalOpportunities',
                actions: 'resetForm',
              },
            },
          },
          live: {
            states: {
              searchingViaApi: {
                id: 'searchingViaApi',
                invoke: {
                  id: 'searchPipeline',
                  src: c => searchOpportunitiesAsPromise(makeFilters(c), c.accessToken),
                  onDone: 'success',
                  onError: 'failure',
                },
              },
              failure: {
                on: {
                  RETRY: {
                    target: '#searchingViaApi',
                    cond: 'canSearch',
                  },
                },
              },
              success: {
                entry: 'clearSearchIsDirty',
                invoke: {
                  src: 'saveLiveApiResults',
                  onDone: '#online.loadLocalOpportunities',
                  onError: 'failure',
                },
              },
            },
          },
        },
      },
    },
    on: {
      AUTH_LOGOUT: {
        actions: ['clearSession', 'resetForm'],
      },
      NOTIFY_AUTH: {
        actions: 'saveAccessToken',
      },
      // todo: we have this (hack) to handle the dual login API calls, we can remove once this is done properly
      USER_DETAILS: {
        actions: [
          assign({
            email: (_, event) => event.email,
          }),
        ],
      },
      UPDATE_QUERY: {
        actions: ['assignQuery', 'assignSearchIsDirty', 'validateSearch', 'assignCanSearch'],
      },
      UPDATE_DATE_FROM: {
        actions: ['assignDateFrom', 'assignSearchIsDirty', 'validateSearch', 'assignCanSearch'],
      },
      UPDATE_DATE_TO: {
        actions: ['assignDateTo', 'assignSearchIsDirty', 'validateSearch', 'assignCanSearch'],
      },
      UPDATE_SORT_BY: {
        actions: ['assignSortBy', 'sortOpportunities'],
      },
      UPDATE_SORT_ORDER: {
        actions: ['assignSortOrder', 'sortOpportunities'],
      },
    },
  },
  {
    actions: {
      onGoingOffline: assign((_, __, m) => {
        if (m?.state?.children) {
          const children = Object.keys(m.state.children);
          children.map(child => m.state.children[child].send('STATUS_OFFLINE'));
        }
        return undefined;
      }),
      onGoingOnline: assign((_, __, m) => {
        if (m?.state?.children) {
          const children = Object.keys(m.state.children);
          children.map(child => m.state.children[child].send('STATUS_ONLINE'));
        }
        return undefined;
      }),
      onEnterListView: assign({
        opportunities: (c, e, m) => {
          const { accessToken, opportunities } = c;
          // ensure no reset when coming back online,
          // ok, this is where we are going to spawn our machines (if we need to)
          if (!e.data) {
            return c.opportunities;
          }

          opportunities.map(item => {
            item.ref.parent.stopChild(item.name);
            item.ref.parent.removeChild(item.name);
            return undefined;
          });

          return e.data.map(opportunity => {
            // eslint-disable-next-line camelcase
            const { created_at, lnumber, lastname, city } = opportunity;
            const name = `pipe_${opportunity.app_uuid}`;

            const ref = spawn(pipelineItemMachine.withContext({ accessToken, opportunity }), name);

            // ensure machine starts in correct online status!
            if (m.state.matches('online')) {
              ref.send('STATUS_ONLINE');
            }

            // API will add the sales consultant info at the opportunity level
            const salesConsultant = [opportunity.sales_consultant_firstname || '', opportunity.sales_consultant_lastname || ''].join(' ');
            return {
              name,
              // sort by columns
              created_at,
              lnumber,
              lastname,
              city,
              ref,
              sales_consultant: salesConsultant,
            };
          });
        },
      }),
      assignQuery: assign({ query: (c, e) => e.value }),
      assignSearchIsDirty: assign({ searchIsDirty: () => true }),
      assignDateFrom: assign({
        dateFrom: (c, e) => e.value,
        dateFromIsDirty: true,
      }),
      assignDateTo: assign({
        dateTo: (c, e) => e.value,
        dateToIsDirty: true,
      }),
      assignCanSearch: assign({
        canSearch: c => c.searchIsDirty === true && c.validation === null,
      }),
      assignSortBy: assign({
        sortBy: (_, e) => e.value,
      }),
      assignSortOrder: assign({
        sortOrder: (_, e) => e.value,
      }),
      clearSearchIsDirty: assign({ searchIsDirty: () => false }),
      resetForm: assign({
        canSearch: false,
        dateFrom: '',
        dateFromIsDirty: false,
        dateTo: '',
        dateToIsDirty: false,
        query: '',
      }),
      sortOpportunities: assign({
        opportunities: context => {
          const { sortBy, sortOrder, opportunities } = context;
          return sortOpportunities(opportunities, sortBy, sortOrder);
        },
      }),
      validateSearch: assign({
        validation: c => {
          const result = validate(c);
          return result ? yupToFormErrors(result) : null;
        },
      }),
      clearSession: assign({
        // eslint-disable-next-line arrow-body-style
        accessToken: () => {
          // console.log('CLEAR SESSION');
          return '';
        },
        opportunities: [],
      }),
      saveAccessToken: assign({ accessToken: (_, event) => event.accessToken }),
    },
    guards: {
      canSearch,
    },
    services: {
      // TODO: Make this search a dexie search instead of getting all
      // records, calling toArray. then filtering the array, and sorting.
      // it takes forever to do this.
      // https://dexie.org/docs/API-Reference#quick-reference
      localDbSearch: context =>
        context.neoDb.opportunities.toArray().then(items => {
          const { sortBy, sortOrder } = context;
          const filteredItems = sortOpportunities(filterOpportunities(items, makeFilters(context)), sortBy, sortOrder);
          return filteredItems;
        }),
      saveLiveApiResults: (context, event) => {
        const processed = processOpportunities(event.data || []);
        return context.neoDb.table('opportunities').bulkPut(processed);
      },
    },
  }
);

export default pipelineSearchMachine;
