import React from 'react';
import Button from 'hollywood/dist/components/Button';
import Icon from '@zing/neo-common/dist/components/Icon';
import PropTypes from 'prop-types';
import BackButton from '../../../../../components/buttons/back';

import './quote-header-buttons.scss';

const QuoteHeaderButtons = ({ processing, backUrl, emailUrl, logic }) => {
  // users not allowed to sign if this is a lads scheme customer
  const isLadsCustomer = logic.isLadsCustomer();
  // const isQuoteAgreed = logic.agreed();
  // const isExpired = logic.isExpired();

  return (
    <div className="quote-header-buttons">
      <BackButton link={backUrl} />
      <Button link={emailUrl} testId="email_quote">
        <Icon icon="email" />
        <span style={{ marginLeft: 5 }}>Email quote</span>
      </Button>
      {!isLadsCustomer && (
        <Button
          loading={processing}
          format="primary"
          onClick={() => {
            window.scrollTo({
              top: 400000,
              left: 0,
              behavior: 'smooth',
            });
          }}
        >
          Skip to agreement
        </Button>
      )}
    </div>
  );
};

QuoteHeaderButtons.propTypes = {
  processing: PropTypes.bool.isRequired,
  backUrl: PropTypes.string.isRequired,
  emailUrl: PropTypes.string.isRequired,
  logic: PropTypes.shape({
    agreed: PropTypes.func.isRequired,
    isLadsCustomer: PropTypes.func.isRequired,
    isExpired: PropTypes.func.isRequired,
  }).isRequired,
};

export default QuoteHeaderButtons;
