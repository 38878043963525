import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import camelCase from 'lodash.camelcase';

const Input = ({
  autoFocus,
  className,
  disabled,
  id,
  max,
  maxLength,
  min,
  minLength,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  prefix,
  step,
  suffix,
  testId,
  type,
  value,
  hasError,
}) => {
  const useTestId = testId || camelCase(`${name}`);
  const useId = id || name;
  return (
    <div className={classnames(prefix ? 'hollywood-prefixed-input' : null, suffix ? 'hollywood-suffixed-input' : null, className)}>
      {prefix && <div className="hollywood-input-prefix">{prefix}</div>}
      <input
        name={name}
        id={useId}
        type={type}
        className={classnames('hollywood-input', className, hasError ? 'invalid' : null)}
        placeholder={placeholder}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        data-testid={useTestId}
        value={value}
        step={step}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={event => {
          if (onChange) onChange(event, event.target.value);
        }}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
      {suffix && <div className="hollywood-input-suffix">{suffix}</div>}
    </div>
  );
};

Input.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLength: PropTypes.number,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  step: PropTypes.number,
  suffix: PropTypes.string,
  testId: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'url', 'password', 'date', 'search', 'tel']),
  value: PropTypes.string,
};

Input.defaultProps = {
  id: null,
  autoFocus: false,
  className: null,
  disabled: false,
  hasError: false,
  max: null,
  maxLength: null,
  min: null,
  minLength: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyDown: null,
  placeholder: null,
  prefix: null,
  step: null,
  suffix: null,
  testId: null,
  type: 'text',
  value: null,
};

export default Input;
