import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Button = ({ size, format, inverted, className, type, link, disabled, loading, children, onClick, testId, id }) => {
  const sharedProps = {
    className: classnames(
      'hollywood-btn',
      format ? `hollywood-btn--${format}` : null,
      `hollywood-btn--${size}`,
      inverted ? 'hollywood-btn--inverted' : null,
      loading ? 'hollywood-btn--loading' : null,
      className
    ),
    onClick,
    disabled,
    'data-testid': testId,
    children: (
      <>
        {children}
        {loading && <span className="hollywood-btn__spinner" />}
      </>
    ),
    id,
  };

  if (link) {
    // Link buttons
    return <Link to={link} {...sharedProps} />;
  }
  // Button buttons
  // eslint-disable-next-line react/button-has-type
  return <button {...sharedProps} type={type} />;
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  id: PropTypes.string,
  inverted: PropTypes.bool,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  testId: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  disabled: false,
  format: null,
  id: '',
  inverted: false,
  link: null,
  loading: false,
  onClick: null,
  size: 'medium',
  testId: null,
  type: 'button',
};

export default Button;
