import * as display from '@zing/neo-common/dist/lib/display';
import { intro } from '../../components/Survey/narrative-default-text';
import { systemTotalRatedPowerKw } from '../../pim/survey/pages/payment-options/utils';

const narrativeDefaultText = (pimComparison, scaffoldComplex, roofs, numberOfPanels) => {
  const journey = pimComparison.getJourney();
  const panel = pimComparison.getPanel();
  const numberOfRoofs = journey.hasSolarPV ? roofs.length : 0;
  const panelPowerRating = journey.hasSolarPV ? pimComparison.getIndividualPanelPowerKw() * 1000 : 0;
  const systemSize = journey.hasSolarPV ? systemTotalRatedPowerKw(roofs) : 0;

  const result = {
    intro: '',
    lines: [],
  };

  if (journey.hasSolarPV) {
    const roofNames = roofs
      .map(x => x.roofName)
      .join(', ')
      .replace(/, ([^,]*)$/, ' & $1');

    if (panel && numberOfPanels > 0 && numberOfRoofs > 0 && systemSize > 0 && panelPowerRating > 0) {
      let panelLine = `${numberOfPanels} x ${panelPowerRating} W panels giving a system size of ${display.number(
        systemSize,
        2
      )} kW, to be fitted on ${roofNames}`;

      panelLine += '.';
      result.lines.push(panelLine);
    }
  }
  if (journey.hasBattery) {
    const batteryStorageKwh = pimComparison.getSystemTotalBatteryCapacityKwh();
    if (batteryStorageKwh > 0) {
      result.lines.push(`${batteryStorageKwh} kWh battery storage.`);
    }
  }

  if (result.lines.length > 0) {
    result.intro = intro;
  }

  return result;
};

export default narrativeDefaultText;
