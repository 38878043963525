import React from 'react';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import PropTypes from 'prop-types';
import Toggle from '../../../hollywood/toggle';
import ErrorMessage from '../../../../../components/ErrorMessage';
import { yesNoOptions, shouldStopWork } from '../utils';

const StartSurvey = ({ state, handleChange, handleBlur }) => (
  <div className="powra-form__section">
    <h3>Starting the survey</h3>
    <div className="powra-form__field">
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Are you familiar with the procedure or method statement for the task?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="startSurvey.familiarWithProcedure"
              id="startSurvey.familiarWithProcedure"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.startSurvey.familiarWithProcedure}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>
              Have you identified a safe route of access and egress from the property, and is it clear from obstructions/trip hazards/ other
              work parties?
            </p>
          </Col>
          <Col width="auto">
            <Toggle
              name="startSurvey.safeRouteOfAccess"
              id="startSurvey.safeRouteOfAccess"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.startSurvey.safeRouteOfAccess}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Is the working area suitably lit and the right temperature for your work?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="startSurvey.areaSuitablyLit"
              id="startSurvey.areaSuitablyLit"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.startSurvey.areaSuitablyLit}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Do you have the right PPE and tools for the task and are they in good condition?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="startSurvey.correctToolsAndCondition"
              id="startSurvey.correctToolsAndCondition"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.startSurvey.correctToolsAndCondition}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Is each work area tidy, organised and safe to start work?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="startSurvey.isWorkAreaSafe"
              id="startSurvey.isWorkAreaSafe"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.startSurvey.isWorkAreaSafe}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Take a moment to look around each work area. Are there any additional hazards which may be hidden or obstructed?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="startSurvey.anyAdditionalHazards"
              id="startSurvey.anyAdditionalHazards"
              options={yesNoOptions}
              goodOption="No"
              badOption="Yes"
              value={state.values.startSurvey.anyAdditionalHazards}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      {shouldStopWork('startSurvey', state) && (
        <ErrorMessage testId="powra-error">Stop work and contact your line manager before you continue</ErrorMessage>
      )}
    </div>
  </div>
);

StartSurvey.propTypes = {
  state: PropTypes.shape({
    values: PropTypes.shape({
      startSurvey: PropTypes.shape({
        familiarWithProcedure: PropTypes.string.isRequired,
        safeRouteOfAccess: PropTypes.string.isRequired,
        areaSuitablyLit: PropTypes.string.isRequired,
        correctToolsAndCondition: PropTypes.string.isRequired,
        isWorkAreaSafe: PropTypes.string.isRequired,
        anyAdditionalHazards: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default StartSurvey;
