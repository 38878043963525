import { appointmentUrl } from '../../../../utils/opportunity';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { connect } from 'react-redux';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { UserContext } from '../../contexts/user-context';
import { TotalComponentsPricing } from '../total-components-pricing';
import { updatePowra } from '../../../../redux/session/actions';
import { withRouter } from 'react-router-dom';
import BackButton from '../../../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import Modal from 'hollywood/dist/components/Modal';
import PowraForm from '../powra-form';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useSurveyValidation from '../../contexts/survey-validation-context';

import './index.scss';

/**
 * There are edge cases where we need to override the threshold check
 * - in a battery only scenario
 * - when the user is eligible for lower vat rate
 *
 * I have split the logic into this function so that we can extend edge cases as they arise
 *
 * @param {*} calcs
 * @param {*} customerIsEligibleForLowerVatRate
 * @returns
 */
// const determineIfCalcsValidate = calcs => {
//   if (!calcs) return true;
//   if (calcs.settings.journey.isBatteryOnly) {
//     return true;
//   }
//   console.log('calcs:', calcs);
//   return calcs?.discountCalcs?.isDiscountWithinLimit;
// };
const determineIfCalcsValidate = () => true;

const SurveyFooter = ({ history, session }) => {
  const [showPowraModal, showPowraModalSet] = useState(false);
  const [pimSession] = useLocalStorage('pimSession');
  const { current } = useContext(PimSurveyMachineContext);
  const { validator } = useSurveyValidation();
  const { currentComparison, comparisons } = current.context;
  const { calcs } = comparisons[currentComparison];
  const { opportunity } = pimSession;
  const user = useContext(UserContext);

  const canSeePricing = user && user.role !== 'External';

  const calcsValidate = determineIfCalcsValidate(calcs);
  const canSubmitForm = validator?.tabs?.canProgress === true && calcsValidate;
  const shouldShowPowra = session?.powra?.afterwards?.done !== true;
  const openPowraModal = () => showPowraModalSet(true);
  const closePowraModal = () => showPowraModalSet(false);

  const submitPowra = powra => {
    powra.afterwards.done = true;
    updatePowra(powra);
    history.push(appointmentUrl(opportunity, 'survey/your-package'));
  };

  return (
    <div className="surveyActions">
      <Grid valign="center">
        <Col>{canSeePricing && <TotalComponentsPricing calcs={calcs} calcsValidate={calcsValidate} />}</Col>
        <Col width="auto">
          <BackButton
            onClick={() => {
              history.push({ pathname: '../presenter', state: { nextUrl: history.location.pathname } });
            }}
          />
          <Button
            format="primary"
            testId="submitSurvey"
            disabled={!canSubmitForm}
            onClick={() => {
              if (shouldShowPowra) {
                openPowraModal();
                return;
              }
              history.push(appointmentUrl(opportunity, 'survey/your-package'));
            }}
          >
            Package options
          </Button>
        </Col>
      </Grid>
      {shouldShowPowra && (
        <Modal isOpen={showPowraModal} className="powra-modal" onClose={closePowraModal}>
          <PowraForm onCancel={closePowraModal} onSubmit={submitPowra} sections={['afterwards']} showCancel values={session?.powra} />
        </Modal>
      )}
    </div>
  );
};

SurveyFooter.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
  }).isRequired,
  session: PropTypes.shape({
    powra: PropTypes.shape({
      afterwards: PropTypes.shape({
        done: PropTypes.bool.isRequired,
      }),
    }),
  }).isRequired,
};

export default connect(({ session }) => ({ session }), { updatePowra })(withRouter(SurveyFooter));
