import * as opportunitiesActions from './actionTypes';
import * as opportunityActions from '@zing/neo-common/dist/redux/opportunity/actionTypes';
import _findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import produce from 'immer';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { AUTH_NEW_USER } from '../auth/actionTypes';
import { get, getNumber } from '@zing/neo-common/dist/lib/safe';
import { initialState } from './initialState';
import { processOpportunities } from './process-opportunities';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import { unixTimestamp } from '@zing/neo-common/dist/lib/date';

const transformAppCreatedOpportunity = action => {
  const { payload = {} } = action;
  payload.app_uuid = payload.app_opportunity_uuid;
  payload.when = payload.survey_appt_date;
  payload.meetings = [
    {
      app_uuid: appUuid(payload),
      when: payload.survey_appt_date,
    },
  ];
  return payload;
};

export const opportunities = db => (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_ALL:
        db.table('opportunities').clear();
        return initialState;

      case AUTH_NEW_USER:
        db.table('opportunities').clear();
        return initialState;

      case opportunitiesActions.RESET_OPPORTUNITIES:
        db.table('opportunities').clear();
        return initialState;

      case opportunitiesActions.OPPORTUNITIES_REQUEST: {
        // console.log('OPPORTUNITIES_REQUEST');
        draft.processing = true;
        draft.error = false;
        draft.errors = {};
        draft.timestamp = null;
        draft.opportunities = [];
        break;
      }

      case opportunitiesActions.OPPORTUNITIES_ROLLBACK:
        draft.processing = false;
        draft.error = true;
        draft.errors = action.payload.errors;
        draft.timestamp = null;
        draft.opportunities = [];
        break;

      case opportunitiesActions.OPPORTUNITIES_COMMIT:
        // console.log('OPPORTUNITIES_COMMIT');
        draft.processing = false;
        draft.error = false;
        draft.errors = {};
        draft.timestamp = unixTimestamp();
        draft.opportunities = [];
        db.table('opportunities').bulkPut(processOpportunities(action.payload));
        break;

      // deprecated - moved to state machine
      // case opportunitiesActions.OPPORTUNITIES_SEARCH_COMMIT:
      //   // console.log('OPPORTUNITIES_SEARCH_COMMIT');
      //   draft.processing = false;
      //   draft.error = false;
      //   draft.errors = {};
      //   draft.timestamp = unixTimestamp();
      //   draft.opportunities = [];
      //   db.table('opportunities').bulkPut(processOpportunities(action.payload));
      //   break;

      // Optimistic update
      case opportunityActions.OPPORTUNITY_UPDATE:
        // console.log('OPPORTUNITY_UPDATE');
        draft.opportunities = [];
        db.table('opportunities').put(action.payload);
        break;

      // Optimistic add - useful when offline.
      case opportunityActions.OPPORTUNITY_CREATE: {
        // console.log('OPPORTUNITY_CREATE', action);
        // REALLY IMPORTANT!
        // Must clone the object or we will send the encrypted version to the API
        const clone = cloneDeep(action);
        draft.processing = false;
        draft.error = false;
        draft.errors = {};
        draft.opportunities = [];
        db.table('opportunities').add(transformAppCreatedOpportunity(clone));
        break;
      }

      // result coming back from API (when online)
      case opportunityActions.OPPORTUNITY_CREATE_COMMIT:
        // console.log('OPPORTUNITY_CREATE_COMMIT');
        draft.processing = false;
        draft.error = false;
        draft.errors = {};
        draft.opportunities = [];
        db.table('opportunities').put(action.payload);
        break;

      case opportunityActions.OPPORTUNITY_LNUMBER_COMMIT: {
        // console.log('OPPORTUNITY_LNUMBER_COMMIT');
        const lnumber = get(action, 'payload.lnumber');
        const uuid = appUuid(action.commitMeta);
        draft.opportunities = [];
        if (lnumber && uuid) {
          const index = _findIndex(state.opportunities, { app_uuid: uuid });
          if (index !== -1) {
            db.table('opportunities')
              .where('app_uuid')
              .equals(uuid)
              .modify({ lnumber });
          }
        }
        break;
      }

      case opportunityActions.OPPORTUNITY_APPLY_FOR_G99_COMMIT: {
        // todo: We need tp update API to return app_uuid as well as id so we can find it in IndexedDB
        // console.log('');
        // console.log('');
        // console.log('OPPORTUNITY_APPLY_FOR_G99_COMMIT');
        // console.log('action', action);
        const id = getNumber(action, 'payload.id');
        const uuid = appUuid(action.commitMeta);
        if (id) {
          const index = _findIndex(state.opportunities, { id });
          if (index !== -1) {
            // console.log('index found, updating opportunity with ', action?.payload);
            db.table('opportunities')
              .where('app_uuid')
              .equals(uuid)
              .modify({ g99_application: action?.payload });
          }
        }
        draft.timestamp = unixTimestamp();
        break;
      }
    }
    return draft;
  });
