import { appointmentMatchUrl } from '../../../../../utils/opportunity';
import { withRouter } from 'react-router-dom';
import BackButton from '../../../../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import Header from '../../../../../components/Header';
import PropTypes from 'prop-types';
import React from 'react';

const HeaderButtons = ({ match }) => (
  <Header bordered={false}>
    <div className="your-package-header-buttons">
      <BackButton link={appointmentMatchUrl(match, ['survey', 'additional-products'])} />
      <Button format="primary" link={appointmentMatchUrl(match, ['survey', 'payment-options'])} testId="paymentOptions">
        Payment options
      </Button>
    </div>
  </Header>
);

HeaderButtons.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default withRouter(HeaderButtons);
