import React from 'react';
import Logo from '@zing/neo-common/dist/components/Logo';

export const Logos = () => (
  <>
    <h3>Standard</h3>
    <Logo testId="standard-logo" />
    <h3>Inverted</h3>
    <Logo inverted testId="inverted-logo" />
  </>
);
