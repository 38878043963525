import React from 'react';
import Checkbox from 'hollywood/dist/components/forms/Checkbox';
import PropTypes from 'prop-types';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';

const Addresses = ({ current, send, options }) => {
  const config = Object.assign({ show: { lat: false, lng: false, mpan: false } }, options);
  return (
    <Grid gutters="sm" data-testid="address-form">
      <Col width="6">
        <h3>House details</h3>
      </Col>
      <Col width="6">
        <h3>Invoice details</h3>
      </Col>
      <Col width="6">
        <h3>&nbsp;</h3>
      </Col>
      <Col width="6">
        <Checkbox
          name="different_invoice_address"
          id="different_invoice_address"
          label="Invoice address differs to property address?"
          testId="different_invoice_address"
          onChange={() => send('TOGGLE_SAME_INVOICE')}
        />
      </Col>
      <Col width="6">
        <TextField
          name="house_number"
          title="House number / name"
          isRequired
          onChange={e => send({ type: 'HOUSE_NUMBER', value: e.target.value })}
        />
        <TextField
          name="address_line_1"
          title="Address line 1"
          isRequired
          onChange={e => send({ type: 'ADDRESS_LINE_1', value: e.target.value })}
        />
        <TextField name="city" title="Town / City" isRequired onChange={e => send({ type: 'ADDRESS_CITY', value: e.target.value })} />
        <TextField name="postcode" title="Postcode" isRequired onChange={e => send({ type: 'ADDRESS_POSTCODE', value: e.target.value })} />
        {config.show.lat && <TextField name="lat" title="Latitude" type="text" isRequired />}
        {config.show.lng && <TextField name="lng" title="Longitude" type="text" isRequired />}
        {config.show.mpan && <TextField name="mpan" title="MPAN" type="number" isRequired />}
      </Col>
      <Col width="6">
        <Grid gutters="sm" data-testid="invoice_address-form">
          <Col>
            <TextField
              name="invoice_house_number"
              title="House number / name"
              isRequired
              onChange={e => send({ type: 'INVOICE_HOUSE_NUMBER', value: e.target.value })}
              disabled={current.value === 'same_invoice_address'}
            />
            <TextField
              name="invoice_address_line_1"
              title="Address line 1"
              isRequired
              onChange={e => send({ type: 'INVOICE_ADDRESS_LINE_1', value: e.target.value })}
              disabled={current.value === 'same_invoice_address'}
            />
            <TextField
              name="invoice_city"
              title="Town / City"
              isRequired
              onChange={e => send({ type: 'INVOICE_CITY', value: e.target.value })}
              disabled={current.value === 'same_invoice_address'}
            />
            <TextField
              name="invoice_postcode"
              title="Postcode"
              isRequired
              onChange={e => send({ type: 'INVOICE_POSTCODE', value: e.target.value })}
              disabled={current.value === 'same_invoice_address'}
            />
          </Col>
        </Grid>
      </Col>
    </Grid>
  );
};

Addresses.propTypes = {
  current: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  send: PropTypes.func.isRequired,
  options: PropTypes.shape({}).isRequired,
};

export default Addresses;
