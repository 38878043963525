import { METHOD_POST } from '@zing/neo-common/dist/lib/api';
import { get } from '@zing/neo-common/dist/lib/safe';

export const AUTHORIZE_VIA_USERNAME_PASSWORD_URL = '/oauth/authorize';
export const authorizeUsernamePassword = (username, password) => ({
  method: METHOD_POST,
  url: '/oauth/authorize',
  data: {
    grant_type: 'password',
    username,
    password,
  },
});

export const AUTHORIZE_VIA_ACCESS_TOKEN_URL = '/oauth/authorize-via-auth-code';
export const authorizeAccessToken = accessToken => ({
  method: METHOD_POST,
  url: AUTHORIZE_VIA_ACCESS_TOKEN_URL,
  data: {
    auth_token: accessToken,
  },
});

export const isAccessTokenLogin = values => get(values, 'auth_token', null) !== null;

export const makeLoginOptions = values => {
  const accessToken = get(values, 'auth_token', null);
  const email = get(values, 'email', null);
  const password = get(values, 'password', null);
  return isAccessTokenLogin(values) ? authorizeAccessToken(accessToken) : authorizeUsernamePassword(email, password);
};
