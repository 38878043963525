import Encryption from '@zing/neo-common/dist/lib/Encryption';
import merge from 'lodash.merge';
import startOfDay from 'date-fns/start_of_day';
import { appUuid, parseApiDate } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { findByAppUuid } from '@zing/neo-common/dist/redux/product/accessor';
import { get, getArray } from '@zing/neo-common/dist/lib/safe';

/**
 * Mostly used in tests, it helps our `opportunity` contain a `when` and `meetingId` using the first meeting
 * @param opportunity
 * @param index
 * @returns {*}
 */
export const mapMeetingDetails = (opportunity, index = 0) => {
  const meetings = get(opportunity, 'meetings', []);
  const m = meetings[index];
  if (m) {
    return { ...opportunity, when: m.when, meetingId: appUuid(m) };
  }
  return opportunity;
};

export const mapMeetingDetailsById = (opportunity, meetingId = null) => {
  const meetings = getArray(opportunity, 'meetings');
  const m = meetings.find(findByAppUuid(meetingId));
  if (m) {
    return { ...opportunity, when: m.when, meetingId: appUuid(m) };
  }
  return opportunity;
};

export const getOpportunities = opportunities =>
  new Encryption(process.env.REACT_APP_ENCRYPTION_ENABLED).decryptJson(
    process.env.REACT_APP_ENCRYPTION_PASSWORD,
    opportunities === null ? [] : opportunities
  );

/**
 * Break this function down into smaller chunks for testing..
 * @param opportunities
 * @returns {Array}
 */
export const groupOpportunitiesByDate = opportunities => {
  if (!Array.isArray(opportunities)) {
    return [];
  }
  const opportunitiesByDate = {};
  opportunities.forEach(opportunity => {
    const when = startOfDay(parseApiDate(opportunity.when));
    opportunitiesByDate[when] = opportunitiesByDate[when] || [];
    opportunitiesByDate[when].push(opportunity);
  });
  return opportunitiesByDate;
};

export const processMeetings = opportunities => {
  if (!Array.isArray(opportunities)) {
    return [];
  }
  const result = [];
  opportunities.forEach(opportunity => {
    const meetings = getArray(opportunity, 'meetings');
    meetings.forEach(m => {
      result.push(merge({}, opportunity, { when: m.when, meetingId: appUuid(m) }));
    });
  });
  return result;
};

// eslint-disable-next-line array-callback-return
export const sortMeetings = meetings =>
  meetings.sort((a, b) => {
    const x = new Date(parseApiDate(a.when));
    const y = new Date(parseApiDate(b.when));
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });

export const commitOpportunities = payload =>
  payload.map(item =>
    new Encryption(process.env.REACT_APP_ENCRYPTION_ENABLED).encryptJson(process.env.REACT_APP_ENCRYPTION_PASSWORD, item)
  );

export const getOpportunity = (opportunities, uuid, meetingId = null) => {
  if (opportunities && uuid) {
    const opportunity = opportunities.find(findByAppUuid(uuid));
    if (opportunity && meetingId) {
      return mapMeetingDetailsById(opportunity, meetingId);
    }
  }
  return null;
};
