import { connect } from 'react-redux';
import { isAdmin } from '@zing/neo-common/dist/redux/user/accessor';
import { PrivateRoute } from '../../components/PrivateRoute';
import { Switch, withRouter } from 'react-router-dom';
import diagnosticsSections from '../../routes/diagnostics-routes';
import PropTypes from 'prop-types';
import React from 'react';

import './diagnostics-page.scss';
import WelcomeDiagnostics from '.';

const DiagnosticsPage = ({ user, location }) => {
  const isUserAdmin = isAdmin(user.user);
  const isWelcomePage = location.pathname === '/diagnostics';

  if (isWelcomePage) {
    return <WelcomeDiagnostics />;
  }

  return (
    <Switch>
      {diagnosticsSections.map(section => {
        if (section.isAdminOnly && isUserAdmin === false) {
          return null;
        }
        return section.routes.map((route => <PrivateRoute path={route.path} component={route.component} exact={!!route.exact} />: null));
      })}
    </Switch>
  );
};

DiagnosticsPage.propTypes = {
  user: PropTypes.shape({
    user: PropTypes.shape({}).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(connect(({ user }) => ({ user }))(DiagnosticsPage));
