import React, { useContext } from 'react';
import Actions from './actions';
import ComparisonSelection from '../../components/comparison-selection';
import FutureCashflow from '../../components/future-cashflow';
import HeaderButtons from './header-buttons';
import YourCarbonFootprint from '../../components/your-carbon-footprint';
import YourCostsAndSavings from '../../components/your-costs-savings';
import YourEnergySpend from '../../components/your-energy-spend';
import YourPackageDetails from '../../components/your-package-details';
import YourPackageStats from '../../components/your-package-stats';
import YourPackageTitle from '../../components/your-package-title';
import { UserContext } from '../../contexts/user-context';

import './your-package.scss';

const YourPackage = () => {
  const user = useContext(UserContext);
  const canSeePricing = user && user.role !== 'External';

  return (
    <>
      <HeaderButtons />
      <main className="your-package" data-testid="your-package">
        <ComparisonSelection />
        <YourPackageTitle />
        <YourPackageDetails />
        <YourPackageStats />
        {canSeePricing && <YourCostsAndSavings />}
        <YourEnergySpend />
        <YourCarbonFootprint />
        <FutureCashflow />
        <Actions />
      </main>
    </>
  );
};

export default YourPackage;
