import React, { useContext } from 'react';
import Radios from '../../hollywood-xstate/radios/radios';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const OlevGrant = () => {
  const {
    current: {
      context: { currentComparison, comparisons },
    },
    send,
  } = useContext(PimSurveyMachineContext);
  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>OLEV Grant</p>
        </Col>
        <Col width="fixed" className="right-col">
          <Radios
            name={`context.comparisons.${currentComparison}.evOlevGrant`}
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            value={comparisons[currentComparison].evOlevGrant}
            onChange={(_, value) => {
              send({ type: 'EV_OLEV_GRANT', currentComparison, value });
            }}
          />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default OlevGrant;
