import merge from 'lodash.merge';
import { METHOD_PUT, METHOD_POST } from '@zing/neo-common/dist/lib/api';
import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';
import {
  SESSION_ADD_SURVEY_PACKAGE,
  SESSION_BEGIN,
  SESSION_CALC_SETTINGS,
  SESSION_CURRENT_URL,
  SESSION_CUSTOMER_FREE_EPC,
  SESSION_DELETE_SURVEY_PACKAGE,
  SESSION_POWRA_AFTERWARDS,
  SESSION_POWRA_ARRIVAL,
  SESSION_POWRA_MEET_CUSTOMER,
  SESSION_POWRA_START_SURVEY,
  SESSION_UPDATE_POWRA,
  SESSION_RENAME_SURVEY_PACKAGE,
  SESSION_SAVE_SUNPATH,
  SESSION_SEND,
  SESSION_UPDATE_SURVEY,
  SESSION_UPDATE_SURVEY_PACKAGE,
  SESSION_UPDATE_SURVEY_PACKAGE_EMAIL_QUOTE,
  SESSION_CREATE_QUOTE,
  PIM_CREATE_QUOTE,
  SESSION_UPDATE_OPPORTUNITY,
  SESSION_EMAIL_QUOTE,
  SESSION_RE_EMAIL_QUOTE,
  SESSION_AGREE_QUOTE,
  SESSION_RESET,
  SESSION_FINISH,
  SESSION_UPDATE_SURVEY_PACKAGE_DELETE_BATTERIES,
  SESSION_RELOAD,
} from './actionTypes';
import { SalesConsultantInitialState } from './reducers/initial-state/sales-consultant';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';

export const startSession = (opportunity, salesConsultant = SalesConsultantInitialState, started = new Date()) => ({
  type: SESSION_BEGIN,
  opportunity,
  started,
  salesConsultant,
});

export const reloadSession = session => ({
  type: SESSION_RELOAD,
  session,
});

export const answerPowraArrival = answers => ({
  type: SESSION_POWRA_ARRIVAL,
  answers,
});

export const answerPowraMeetCustomer = answers => ({
  type: SESSION_POWRA_MEET_CUSTOMER,
  answers,
});

export const answerPowraStartSurvey = answers => ({
  type: SESSION_POWRA_START_SURVEY,
  answers,
});

export const answerPowraAfterwards = answers => ({
  type: SESSION_POWRA_AFTERWARDS,
  answers,
});

/**
 * We now send the whole powra object and simply write it to the store
 * @param powra
 * @returns {{powra: *, type: string}}
 */
export const updatePowra = powra => ({
  type: SESSION_UPDATE_POWRA,
  powra,
});

export const updateSurvey = updates => ({
  type: SESSION_UPDATE_SURVEY,
  updates,
});

export const saveSunpath = (index, sunpath) => ({
  type: SESSION_SAVE_SUNPATH,
  index,
  sunpath,
});

export const sessionCurrentUrl = currentUrl => ({
  type: SESSION_CURRENT_URL,
  currentUrl,
});

export const sessionCalcSettings = calcSettings => ({
  type: SESSION_CALC_SETTINGS,
  calcSettings,
});

export const addPackage = values => ({
  type: SESSION_ADD_SURVEY_PACKAGE,
  values,
});

export const updatePackage = (packageId, values) => ({
  type: SESSION_UPDATE_SURVEY_PACKAGE,
  packageId,
  values,
});

export const renamePackage = (packageId, name) => ({
  type: SESSION_RENAME_SURVEY_PACKAGE,
  packageId,
  name,
});

export const deletePackage = packageId => ({
  type: SESSION_DELETE_SURVEY_PACKAGE,
  packageId,
});

export const updatePackageEmailQuote = (packageId, values) => ({
  type: SESSION_UPDATE_SURVEY_PACKAGE_EMAIL_QUOTE,
  packageId,
  values,
});

export const updateFreeEPC = value => ({
  type: SESSION_CUSTOMER_FREE_EPC,
  value,
});

export const sessionUpdateSurveyPackageDeleteBatteries = packageId => ({
  type: SESSION_UPDATE_SURVEY_PACKAGE_DELETE_BATTERIES,
  packageId,
});

export const convertSessionIntoRequestOpportunity = session => {
  const opportunity = merge({}, session.opportunity);
  opportunity.survey = merge(
    {},
    session.survey,
    { calcSettings: session.calcSettings },
    { started: session.started },
    { opportunity: null }
  );
  opportunity.powra = merge({}, session.powra);
  return opportunity;
};

export const sendOpportunityWithCollectedSurvey = session => {
  const opportunity = convertSessionIntoRequestOpportunity(session);
  const id = appUuid(opportunity);
  return offlineRequestAction({
    type: SESSION_SEND,
    url: `/opportunities/${id}`,
    payload: opportunity,
    method: METHOD_PUT,
    commitMeta: opportunity,
    rollbackMeta: opportunity,
  });
};

export const createQuote = (appUuid, quote) =>
  offlineRequestAction({
    type: SESSION_CREATE_QUOTE,
    url: `/opportunities/${appUuid}/quotes`,
    method: METHOD_POST,
    payload: quote,
    commitMeta: quote,
    rollbackMeta: quote,
  });

export const pimCreateQuote = (appUuid, quote) =>
  offlineRequestAction({
    type: PIM_CREATE_QUOTE,
    url: `/opportunities/${appUuid}/quotes`,
    method: METHOD_POST,
    payload: quote,
    commitMeta: quote,
    rollbackMeta: null,
  });

export const sessionUpdateOpportunity = opportunity => ({
  type: SESSION_UPDATE_OPPORTUNITY,
  opportunity,
});

export const emailQuote = (appUuid, quoteId, details) =>
  offlineRequestAction({
    type: SESSION_EMAIL_QUOTE,
    url: `/opportunities/${appUuid}/quotes/${quoteId}/email`,
    method: METHOD_POST,
    payload: {
      app_uuid: appUuid,
      quote_id: quoteId,
      ...details,
    },
    commitMeta: {
      app_uuid: appUuid,
      quote_id: quoteId,
      ...details,
    },
    rollbackMeta: {
      app_uuid: appUuid,
      quote_id: quoteId,
      ...details,
    },
  });

export const reEmailQuote = (appUuid, quoteId) =>
  offlineRequestAction({
    type: SESSION_RE_EMAIL_QUOTE,
    url: `/opportunities/${appUuid}/quotes/${quoteId}/email`,
    method: METHOD_POST,
  });

export const agreeQuote = (quoteId, details) =>
  offlineRequestAction({
    type: SESSION_AGREE_QUOTE,
    url: `/quotes/${quoteId}/agree`,
    method: METHOD_POST,
    payload: {
      quote_id: quoteId,
      ...details,
    },
    commitMeta: {
      quote_id: quoteId,
      ...details,
    },
    rollbackMeta: {
      quote_id: quoteId,
      ...details,
    },
  });

export const sessionEnd = () => ({
  type: SESSION_FINISH,
});

export const sessionReset = () => ({
  type: SESSION_RESET,
});
