import { assign } from '@xstate/immer';
import { BATTERY_ONLY_PACKAGE_PROD_ID } from '@zing/neo-common/dist/accessors/pim-comparison';
import { cardinalFromCompassBearing } from '../utils/calculations/utils/cardinal-from-compass-bearing';
import { initialRoof } from './survey.machine.initial-context';
import { LADS_CUSTOMER, STANDARD_EXPORT_RATE } from '@zing/neo-common/dist/config';
import { mcsZoneFromPostcode } from '../utils/calculations/utils/mcs-zone-from-postcode';
import { shadingFactor } from '../utils/calculations/utils/shading-factor';
import assignAllRoofCalcs from './survey/assign-all-roof-calcs';
import assignEstimatedElectricityDemandKwh from './survey/assign-estimated-electricity-demand-kwh';
import comparisonG99Calcs from './survey/g99-calcs';
import ensureMpanInteger from './survey/ensure-mpan-integer';
import ensurePositiveInteger from './survey/ensure-positive-integer';
import ensureZero from './survey/ensure-zero';
import narrativeDefaultText from './survey/narrative-default-text';
// eslint-disable-next-line import/no-cycle
import pimCalcs from './survey/pim-calcs';
import { limitDecimals } from '@zing/neo-common/dist/lib/number';

export const BESPOKE_SCAFFOLD_COST = 'BESPOKE-SCAFF-COST';

// Utility functions to help update state, especially when accessing comparison nested data
const createAssignByValue = (name, processor = null) =>
  // eslint-disable-next-line no-return-assign
  assign((context, event, meta) => (context[name] = processor ? processor(event.value, context, event, meta) : event.value));

const createAssignComparisonByValue = (name, processor = null) =>
  assign(
    // eslint-disable-next-line no-return-assign
    (context, event, meta) =>
      (context.comparisons[event.currentComparison][name] = processor ? processor(event.value, context, event, meta) : event.value)
  );

const makeOverrides = (options, bespokeProducts) => {
  const { isScaffoldingCostOverridden, isInstallCostOverridden, isKitCostOverridden } = options;
  const result = {
    isScaffoldingCostOverridden,
    isInstallCostOverridden,
    isKitCostOverridden,
  };
  if (isScaffoldingCostOverridden === true) {
    result.scaffolding = {
      product: {
        ...bespokeProducts.scaffolding,
        costPerItem: options.overriddenScaffoldingCost,
        costPerKw: 0,
        costPerPanel: 0,
      },
      quantity: 1,
      exportQuantity: 1,
    };
  }
  if (isInstallCostOverridden === true) {
    result.installation = {
      product: {
        ...bespokeProducts.installation,
        costPerItem: options.overriddenInstallCost,
        costPerKw: 0,
        costPerPanel: 0,
      },
      quantity: 1,
      exportQuantity: 1,
    };
  }
  if (isKitCostOverridden === true) {
    result.kit = {
      product: {
        ...bespokeProducts.kit,
        costPerItem: options.overriddenKitCost,
        costPerKw: 0,
        costPerPanel: 0,
        gsRatio: options.overriddenKitGsRatio,
        gsRatioGoods: options.overriddenKitGsRatio,
        gsRatioServices: 1 - Number(options.overriddenKitGsRatio),
      },
      quantity: 1,
      exportQuantity: 1,
    };
  }
  return result;
};

const surveyMachineOptions = {
  actions: {
    // Pim Comparison -> Comparison
    updateFromPimComparison: assign(context => {
      const { currentComparison } = context;
      const { pimComparison } = context.comparisons[currentComparison];
      const journey = pimComparison.getJourney();

      context.comparisons[currentComparison].battery = pimComparison.getBattery();
      context.comparisons[currentComparison].batteryCapacityToggle = pimComparison.getBatteryCapacityToggle();
      context.comparisons[currentComparison].batteryInverterToggle = pimComparison.getBatteryInverterToggle();
      context.comparisons[currentComparison].inverter = pimComparison.getInverter();
      context.comparisons[currentComparison].panel = pimComparison.getPanel();
      context.comparisons[currentComparison].roofFixing = pimComparison.getRoofFixing();
      context.comparisons[currentComparison].hasSolarPV = journey.hasSolarPV;
      context.comparisons[currentComparison].isBatteryOnly = journey.isBatteryOnly;

      // todo: what if the package is `battery-only` ?
      const solarPackage = pimComparison.getPackage();
      context.comparisons[currentComparison].solarPvPackage =
        // eslint-disable-next-line camelcase
        solarPackage?.prod_id !== BATTERY_ONLY_PACKAGE_PROD_ID ? solarPackage : undefined;

      // Remove scaffolding costs items if we no longer have a solar package
      if (!journey.hasSolarPV) {
        context.comparisons[currentComparison].expectedScaffoldCost = pimComparison.getExpectedScaffoldCost();
      }

      // if the battery is longer available from the filters list, then remove it!
      const { battery } = context.comparisons[currentComparison];
      if (battery && !pimComparison.isBatteryAvailable(battery)) {
        // remove battery and reset selection
        pimComparison.setBattery();
        context.comparisons[currentComparison].battery = undefined;
      }
    }),

    // Comparisons
    assignRenameComparison: createAssignComparisonByValue('name'),
    assignCurrentComparison: (context, event) => {
      context.currentComparison = event.currentComparison;
    },
    // Property details
    assignPropertyType: createAssignByValue('propertyType'),
    assignLeadCategory: createAssignByValue('leadCategory'),
    assignPropertyIsListedBuilding: createAssignByValue('propertyIsListedBuilding'),
    assignPostcode: assign((context, { value }) => {
      context.postcode = value;
    }),
    assignMcsZoneFromPostcode: assign(context => {
      if (context.postcode) {
        context.postcodeZoneId = mcsZoneFromPostcode(context.postcode);
      }
    }),
    assignG99Application: createAssignByValue('g99Application'),
    assignLatitude: createAssignByValue('latitude', ensurePositiveInteger),
    assignLNumber: createAssignByValue('lnumber'),
    assignUserId: createAssignByValue('userId', ensurePositiveInteger),

    // Energy details
    assignExistingPvAnnualYieldKwh: createAssignByValue('existingPvAnnualYieldKwh'),
    resetExistingPvAnnualYieldKwh: createAssignByValue('existingPvAnnualYieldKwh', ensureZero),
    assignExistingPvInverterTotalRatedOutputKw: createAssignByValue('existingPvInverterTotalRatedOutputKw'),
    resetExistingPvInverterTotalRatedOutputKw: createAssignByValue('existingPvInverterTotalRatedOutputKw', ensureZero),

    assignMeterType: assign((context, { currentComparison, value }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setMeterType(value);
      context.comparisons[currentComparison].meterType = value;
    }),

    assignEonCustomer: createAssignByValue('eonCustomer'),
    assignMpan: createAssignByValue('mpan', ensureMpanInteger),
    assignImportRate: createAssignComparisonByValue('importRate', ensurePositiveInteger),
    assignEnergyConsumptionProfile: createAssignComparisonByValue('energyConsumptionProfile'),
    assignAnnualElectricityDemandKwh: createAssignComparisonByValue('annualElectricityDemandKwh', ensurePositiveInteger),
    assignAnnualElectricityCost: createAssignComparisonByValue('annualElectricityCost', ensurePositiveInteger),
    assignNumberOfOccupants: createAssignComparisonByValue('numberOfOccupants', ensurePositiveInteger),
    assignEstimatedElectricityDemandKwh: createAssignComparisonByValue(
      'estimatedElectricityDemandKwh',
      assignEstimatedElectricityDemandKwh
    ),

    // Solar PV
    assignSolarPvPackage: assign((context, { currentComparison, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setPackage(prodId);
      context.comparisons[currentComparison].solarPvPackage = pimComparison.getPackage();
    }),
    unassignSolarPvPackage: assign((context, { currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setPackage(undefined);
      context.comparisons[currentComparison].solarPvPackage = undefined;
    }),
    assignSolarPvPanel: assign((context, { currentComparison, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setPanel(prodId);
      context.comparisons[currentComparison].panel = pimComparison.getPanel();
    }),
    unassignSolarPvPanel: assign((context, { currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setPanel(undefined);
      context.comparisons[currentComparison].panel = undefined;
    }),
    assignSolarPvInverter: assign((context, { currentComparison, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setInverter(prodId);
      context.comparisons[currentComparison].inverter = pimComparison.getInverter();
    }),
    unassignSolarPvInverter: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].inverter = undefined;
    }),
    assignRoofFixing: assign((context, { currentComparison, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setRoofFixing(prodId);
      context.comparisons[currentComparison].roofFixing = pimComparison.getRoofFixing();
    }),
    unassignRoofFixing: assign((context, { currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setRoofFixing(undefined);
      context.comparisons[currentComparison].roofFixing = undefined;
    }),
    assignTrenchingRequirementMeters: createAssignByValue('trenchingRequirementMeters', ensurePositiveInteger),
    assignExpectedScaffoldCost: assign((context, { currentComparison, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      context.comparisons[currentComparison].expectedScaffoldCost = prodId;
      pimComparison.setExpectedScaffoldCost(prodId);
    }),
    assignExpectedScaffoldCostAsBespoke: assign((context, { currentComparison }) => {
      // no need to tell the comparison anymore, it's injected later in the chain
      // const { pimComparison } = context.comparisons[currentComparison];
      context.comparisons[currentComparison].expectedScaffoldCost = BESPOKE_SCAFFOLD_COST;
      // pimComparison.setExpectedScaffoldCost(BESPOKE_SCAFFOLD_COST);
    }),
    clearExpectedScaffoldCostIfBespoke: assign((context, { currentComparison, expectedScaffoldCost = '' }) => {
      if (expectedScaffoldCost === BESPOKE_SCAFFOLD_COST) {
        const { pimComparison } = context.comparisons[currentComparison];
        context.comparisons[currentComparison].expectedScaffoldCost = undefined;
        pimComparison.setExpectedScaffoldCost(undefined);
      }
    }),

    // assignIsRosemaryOrSlateRoofPreset: createAssignComparisonByValue('isRosemaryOrSlateRoofPreset'),

    assignIsInstallCostOverridden: createAssignComparisonByValue('isInstallCostOverridden'),
    assignOverriddenInstallCost: createAssignComparisonByValue('overriddenInstallCost'),
    clearOverriddenInstallCost: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].overriddenInstallCost = 0;
    }),

    assignIsScaffoldingCostOverridden: createAssignComparisonByValue('isScaffoldingCostOverridden'),
    assignOverriddenScaffoldingCost: createAssignComparisonByValue('overriddenScaffoldingCost'),
    clearOverriddenScaffoldingCost: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].overriddenScaffoldingCost = 0;
    }),

    assignIsKitCostOverridden: createAssignComparisonByValue('isKitCostOverridden'),
    assignOverriddenKitCost: createAssignComparisonByValue('overriddenKitCost'),
    clearOverriddenKitCost: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].overriddenKitCost = 0;
    }),
    assignOverriddenKitGsRatio: createAssignComparisonByValue('overriddenKitGsRatio'),
    clearOverriddenKitGsRatio: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].overriddenKitGsRatio = 1;
    }),

    assignScaffoldComplex: createAssignByValue('scaffoldComplex'),

    assignIsKitLocationExternal: assign((context, { value, currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      const CANOPY_ID = 'CANOPY-FOR-EXTERNAL-INSTALL';

      // Find out if the canopy is already selected
      const additionalProducts = pimComparison.getAdditionalProducts();
      const canopyIndex = additionalProducts.findIndex(ap => ap?.product?.prodId === CANOPY_ID);
      const canopyIsSelected = canopyIndex !== -1;

      // If the user selects 'yes' add  the canopy if not already selected
      if (value === 'yes' && !canopyIsSelected) {
        pimComparison.addNewAdditionalProduct();
        pimComparison.setAdditionalProduct(pimComparison.getAdditionalProducts().length - 1, CANOPY_ID);
      } else if (value === 'no' && canopyIsSelected) {
        pimComparison.deleteAdditionalProductAtIndex(canopyIndex);
      }

      // Update the context
      context.externalKitLocation = value;
      context.comparisons[currentComparison].additionalProducts = pimComparison.getAdditionalProducts();
    }),
    assignComments: createAssignByValue('comments'),

    // Solar PV Roofs
    assignAddRoof: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].roofs.push(initialRoof(context.comparisons[currentComparison].roofs.length + 1));
    }),
    assignRemoveRoof: assign((context, { currentComparison, index }) => {
      context.comparisons[currentComparison].roofs.splice(index, 1);
    }),
    assignEditRoofName: assign((context, { currentComparison, index, value }) => {
      context.comparisons[currentComparison].roofs[index].roofNameEditing = value;
    }),
    assignSaveRoofName: assign((context, { currentComparison, index }) => {
      context.comparisons[currentComparison].roofs[index].roofName = context.comparisons[currentComparison].roofs[index].roofNameEditing;
    }),
    assignRoofSunpath: assign((context, { currentComparison, index, shadedSegments }) => {
      context.comparisons[currentComparison].roofs[index].shadedSegments = shadedSegments;
      context.comparisons[currentComparison].roofs[index].shadingFactorElevation = Number(shadingFactor(shadedSegments.length));
      context.comparisons[currentComparison].roofs[index].numberOfShadedSegmentsElevation = shadedSegments.length;
      context.comparisons[currentComparison].roofs[index].sunpathSaved = true;
    }),

    assignSlopeElevation: assign((context, { currentComparison, index, value }) => {
      context.comparisons[currentComparison].roofs[index].slopeElevation = value;
    }),
    assignAzimuthElevation: assign((context, { currentComparison, index, value }) => {
      context.comparisons[currentComparison].roofs[index].azimuthElevation = value;
      context.comparisons[currentComparison].roofs[index].compassBearing = cardinalFromCompassBearing(value);
    }),
    assignNumberOfPanelsElevation: assign((context, { currentComparison, index, value }) => {
      context.comparisons[currentComparison].roofs[index].numberOfPanelsElevation = ensurePositiveInteger(value);
    }),
    assignFlatRoof: assign((context, { currentComparison, index, value }) => {
      context.comparisons[currentComparison].roofs[index].flatRoof = value;
    }),
    assignAllRoofCalcs: assign(assignAllRoofCalcs),
    clearUploadImageErrorMessage: assign((context, { currentComparison, index }) => {
      context.comparisons[currentComparison].roofs[index].uploadError = undefined;
    }),
    assignSaveUploadImageResponse: assign((context, { data }) => {
      const { currentComparison, index, image } = data;
      context.comparisons[currentComparison].roofs[index].image = image;
    }),
    assignUploadImageErrorMessage: assign((context, { data }) => {
      const { currentComparison, index, uploadError } = data;
      context.comparisons[currentComparison].roofs[index].uploadError = uploadError;
    }),
    assignDeleteRoofImage: assign((context, { currentComparison, index }) => {
      context.comparisons[currentComparison].roofs[index].image = undefined;
    }),

    // Battery
    assignBatteryInverterToggle: assign((context, { currentComparison, value }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setBatteryInverterToggle(value);
      context.comparisons[currentComparison].batteryInverterToggle = value;
    }),
    assignBatteryCapacityToggle: assign((context, { currentComparison, value }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setBatteryCapacityToggle(value);
      context.comparisons[currentComparison].batteryCapacityToggle = value;
    }),
    assignBatterySelected: assign((context, { currentComparison, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setBattery(prodId);
      context.comparisons[currentComparison].battery = pimComparison.getBattery();
    }),
    assignBatteryUnselected: assign((context, { currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setBattery(undefined);
      context.comparisons[currentComparison].battery = undefined;
    }),
    assignBatteryDelete: assign((context, { currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setBattery(undefined);
      context.comparisons[currentComparison].battery = undefined;
    }),

    // EV Charger
    assignEvInstallationType: createAssignComparisonByValue('evInstallationType'),
    assignEvFilterSocketType: createAssignComparisonByValue('evFilterSocketType'),
    assignEvFilterCableLength: createAssignComparisonByValue('evFilterCableLength'),
    assignEvFilterIncludeRfid: createAssignComparisonByValue('evFilterIncludeRfid'),
    assignEvFilterConnectionType: createAssignComparisonByValue('evFilterConnectionType'),
    assignEvChargePoint: assign((context, { currentComparison, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setEvChargePoint(prodId);
      context.comparisons[currentComparison].evChargePoint = pimComparison.getEvChargePoint();
    }),
    assignEvOlevGrant: createAssignComparisonByValue('evOlevGrant'),

    // Additional Products
    assignAdditionalProductAddNew: assign((context, { currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.addNewAdditionalProduct();
      context.comparisons[currentComparison].additionalProducts = pimComparison.getAdditionalProducts();
    }),
    assignAdditionalProductSelected: assign((context, { currentComparison, index, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setAdditionalProduct(index, prodId);
      context.comparisons[currentComparison].additionalProducts = pimComparison.getAdditionalProducts();
    }),
    assignAdditionalProductUnselected: assign((context, { currentComparison, index }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.unsetAdditionalProduct(index);
      context.comparisons[currentComparison].additionalProducts = pimComparison.getAdditionalProducts();
    }),

    // Mandatory Additional Products
    assignMandatoryAdditionalProducts: assign((context, { currentComparison }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.updateMandatoryProducts('assignMandatoryAdditionalProducts');
      context.comparisons[currentComparison].mandatoryAdditionalProducts = pimComparison.getMandatoryAdditionalProducts();
    }),
    assignMandatoryAdditionalProductSelected: assign((context, { currentComparison, category, prodId }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.setMandatoryAdditionalProduct(category, prodId);
      context.comparisons[currentComparison].mandatoryAdditionalProducts = pimComparison.getMandatoryAdditionalProducts();
    }),
    assignMandatoryAdditionalProductUnselected: assign((context, { currentComparison, category }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.unsetMandatoryAdditionalProduct(category);
      context.comparisons[currentComparison].mandatoryAdditionalProducts = pimComparison.getMandatoryAdditionalProducts();
    }),
    assignAdditionalProductDelete: assign((context, { currentComparison, index }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.deleteAdditionalProductAtIndex(index);
      context.comparisons[currentComparison].additionalProducts = pimComparison.getAdditionalProducts();
    }),
    assignAdditionalProductQuantity: assign((context, { currentComparison, index, quantity }) => {
      const { pimComparison } = context.comparisons[currentComparison];
      pimComparison.updateAdditionalProductQuantity(index, quantity);
      context.comparisons[currentComparison].additionalProducts = pimComparison.getAdditionalProducts();
    }),

    // Comparisons
    assignEditComparisonName: assign((context, { currentComparison, value }) => {
      context.comparisons[currentComparison].nameEditing = value;
    }),
    assignSaveComparisonName: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].name = context.comparisons[currentComparison].nameEditing;
    }),

    // Calculation settings
    assignDiscountType: createAssignComparisonByValue('discountType'),
    assignDiscountPercentage: createAssignComparisonByValue('discountPercentage'),
    assignDiscountFixed: createAssignComparisonByValue('discountFixed'),
    assignDiscountCode: assign((context, { currentComparison, value }) => {
      if (value === null) {
        context.comparisons[currentComparison].discountCode = '';
        context.comparisons[currentComparison].discountFixed = 0;
        context.comparisons[currentComparison].discountPercentage = 0;
        return;
      }

      context.comparisons[currentComparison].discountCode = value.voucher_code;

      if (value.voucher_type === 'fixed') {
        context.comparisons[currentComparison].discountFixed = limitDecimals(Math.max(0, value.discount), 2);
        context.comparisons[currentComparison].discountPercentage = 0;
      } else {
        context.comparisons[currentComparison].discountPercentage = limitDecimals(Math.max(0, value.discount), 2) / 100;
        context.comparisons[currentComparison].discountFixed = 0;
      }
    }),
    assignColleagueDiscountPercentage: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].discountPercentage = 0.15;
    }),
    assignNoDiscountPercentage: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].discountPercentage = 0;
    }),
    assignNoDiscountFixed: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].discountFixed = '';
    }),
    assignNoDiscountCode: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].discountCode = '';
    }),
    assignEnergyInflationRate: createAssignByValue('energyInflationRate'),
    assignRpi: createAssignByValue('rpi'),
    assignExportRate: createAssignByValue('exportRate'),
    enableCustomExportRate: assign(context => {
      context.customExportRate = true;
    }),
    disableCustomExportRate: assign(context => {
      context.customExportRate = false;
    }),

    // UKSQA-1444 - Default export rate is now dynamic and needs to update based on customer type AND package type
    determineExportRate: assign(context => {
      const currentComparison = context.comparisons[context.currentComparison];
      const { leadCategory } = context;

      const journey = currentComparison.pimComparison.getJourney();
      console.log('journey', journey);

      if (leadCategory === LADS_CUSTOMER && !context.customExportRate) {
        context.exportRate = STANDARD_EXPORT_RATE;
      }
      if (leadCategory !== LADS_CUSTOMER && !context.customExportRate) {
        if (journey.isBatteryOnly) {
          context.exportRate = context.defaultValues.default_export_rate_battery_only;
        }
        if (journey.hasSolarPV) {
          context.exportRate = journey.hasBattery
            ? context.defaultValues.default_export_rate_solar_pv_battery
            : context.defaultValues.default_export_rate_solar_pv;
        }
      }

      console.log('context.exportRate', context.exportRate);
    }),

    // Payment options
    assignPaymentType: createAssignComparisonByValue('paymentType'),
    assignIncludeCoolingOffWaiver: createAssignComparisonByValue('includeCoolingOffWaiver'),
    assignSunpathAnalysisPossible: createAssignComparisonByValue('sunpathAnalysisPossible'),
    assignNarrative: createAssignComparisonByValue('narrative'),

    // Mode
    assignIsRequoting: assign(context => {
      context.isRequoting = true;
    }),
    assignIsRequotingFalse: assign(context => {
      context.isRequoting = false;
    }),

    // Calcs
    calculateG99: assign(comparisonG99Calcs),
    assignIsNotNumOccupants: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].isNumOccupants = false;
    }),
    assignIsNumOccupants: assign((context, { currentComparison }) => {
      context.comparisons[currentComparison].isNumOccupants = true;
    }),
    calcs: assign((context, event, meta) => {
      const {
        comparisons,
        currentComparison,
        energyInflationRate,
        existingPvAnnualYieldKwh,
        exportRate,
        leadCategory,
        rpi,
        scaffoldComplex,
        trenchingRequirementMeters,
      } = context;
      const {
        discountFixed,
        discountCode,
        discountPercentage,
        discountType,
        energyConsumptionProfile,
        estimatedElectricityDemandKwh,
        evInstallationType,
        importRate,
        isNumOccupants,
        // isRosemaryOrSlateRoofPreset,
        paymentType,
        pimComparison,
        roofs,
        systemTotalInstallPvKwhCombined,
        isInstallCostOverridden,
        isScaffoldingCostOverridden,
        isKitCostOverridden,
        overriddenInstallCost,
        overriddenScaffoldingCost,
        overriddenKitCost,
        overriddenKitGsRatio,
      } = comparisons[currentComparison];

      // move these values to context?

      // UKSQA-1333 - Set customerIsEligibleForLowerVatRate to 'yes' for all and hide field
      const customerIsEligibleForLowerVatRate = true;
      // let customerIsEligibleForLowerVatRate = false;
      // if (event.type === 'CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_YES') {
      //   customerIsEligibleForLowerVatRate = true;
      // } else if (event.type === 'CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_NO') {
      //   customerIsEligibleForLowerVatRate = false;
      // } else {
      //   customerIsEligibleForLowerVatRate = meta.state?.value?.customerIsEligibleForLowerVatRate === 'yes' || false;
      // }

      // move these values to context?
      let existingGridConnectedPvOnSite = false;
      if (event.type === 'EXISTING_GRID_CONNECTED_PV_ON_SITE_YES') {
        existingGridConnectedPvOnSite = true;
      } else if (event.type === 'EXISTING_GRID_CONNECTED_PV_ON_SITE_NO') {
        existingGridConnectedPvOnSite = false;
      } else {
        existingGridConnectedPvOnSite = meta.state?.value.existingGridConnectedPvOnSite === 'yes' || false;
      }

      const outDuringTheDay = Number(energyConsumptionProfile) === 178190000;
      const journey = pimComparison.getJourney();
      const numberOfPanels = pimComparison.getNumberOfPanels();
      const numberOfRoofElevations = roofs.length;
      const flatRoofs = roofs.map(x => x.flatRoof === 'yes');
      const pvArrayOutput = pimComparison.getPvArrayOutput();
      const selectedProductsFlat = pimComparison.getRequiredProductsFlat();
      const systemTotalBatteryCapacityKwh = pimComparison.getSystemTotalBatteryCapacityKwh();
      const degradationMatrix = pimComparison.getPanelDegradationMatrix();
      const solarPackage = pimComparison.getPackage();
      const inRoof = Boolean(solarPackage?.inRoofItem) || false;
      const fixedDiscountProduct = pimComparison.getFixedDiscount();

      // window.pimComparison = pimComparison;
      const bespokeProducts = pimComparison.getBespokeProducts();

      const productsToOverrideCosts = {
        isInstallCostOverridden,
        isScaffoldingCostOverridden,
        isKitCostOverridden,
        overriddenInstallCost,
        overriddenScaffoldingCost,
        overriddenKitCost,
        overriddenKitGsRatio,
      };

      const overrides = makeOverrides(context.comparisons[currentComparison], bespokeProducts);

      context.comparisons[currentComparison].calcs = pimCalcs({
        customerIsEligibleForLowerVatRate,
        degradationMatrix,
        discountFixed,
        discountCode,
        discountPercentage,
        discountType,
        energyInflationRate,
        estimatedElectricityDemandKwh,
        evInstallationType,
        existingGridConnectedPvOnSite,
        existingPvAnnualYieldKwh,
        exportRate,
        flatRoofs,
        fixedDiscountProduct,
        importRate,
        inRoof,
        isNumOccupants,
        // isRosemaryOrSlateRoofPreset,
        journey,
        leadCategory: leadCategory || '178190000',
        meterType: pimComparison.getMeterType(),
        numberOfPanels,
        numberOfRoofElevations,
        outDuringTheDay,
        paymentType,
        pimComparison,
        pvArrayOutput,
        productsToOverrideCosts,
        rpi,
        scaffoldComplex,
        selectedProductsFlat,
        systemTotalBatteryCapacityKwh,
        systemTotalInstallPvKwh: systemTotalInstallPvKwhCombined, // #UKQSA-1105 we need to use the combined system + existing PV
        trenchingRequirementMeters,
        overrides,
        depositAmount: context.defaultValues.default_deposit_amount,
        gridEmissionFactor: context.defaultValues.default_grid_emission_factor,
      });

      context.comparisons[currentComparison].narrativeDefaultText = narrativeDefaultText(
        pimComparison,
        scaffoldComplex,
        roofs,
        numberOfPanels
      );
    }),
  },
  guards: {
    condValueIsEmptyString: (_, { value }) => value === '',
    condValueIsNotEmptyString: (_, { value }) => value !== '',
  },
};

export default surveyMachineOptions;
