import React from 'react';

import Typography from './Typography';
import FormComponents from './FormComponents';
import Buttons from './Buttons';
import { Cols } from './Cols';
import { Grids } from './Grids';
import { Logos } from './Logos';
import { Headers } from './Headers';
import Icons from './Icons';

import './StyleGuide.scss';
import EmailPreview from '../../components/EmailPreview';

const emailMarkup = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>
<body>
  <table>
    <thead>
      <tr>
        <th>First name:</th>
        <th>Last name:</th>
        <th>Email name:</th>
      </tr>
    </thead>
    <tbody>
      {{#each contacts}}
      <tr>
        <td style="height: 300px;">{{this.firstname}}</td>
        <td>{{this.lastname}}</td>
        <td>{{this.email}}</td>
      </tr>
      {{/each}}
    </tbody>
  </table>
</body>
</html>`;

const emailData = {
  contacts: [
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
    {
      firstname: 'Jane',
      lastname: 'Doe',
      email: 'jane.doe@test.com',
    },
  ],
};

const StyleGuide = () => (
  <main className="style-guide">
    <section id="typography">
      <Typography />
    </section>

    <section id="forms">
      <h2>Form Components</h2>
      <FormComponents />
    </section>

    <section id="buttons">
      <h2>Buttons</h2>
      <Buttons />
    </section>

    <section id="logos">
      <h2>Logos</h2>
      <Logos />
    </section>

    <section id="grid">
      <h2>Grid</h2>
      <Grids />
    </section>

    <section id="columns">
      <h2>Columns</h2>
      <Cols />
    </section>

    <section id="headers">
      <h2>Menus</h2>
      <Headers />
    </section>

    <section id="icons">
      <h2>Icons</h2>
      <Icons />
    </section>

    <section id="email-preview">
      <h2>Email Preview</h2>
      <p>
        The <code>EmailPreview</code> component takes two props:
      </p>
      <ul>
        <li>
          <code>template</code> (string) - a twig.js html template. See the{' '}
          <a href="https://github.com/twigjs/twig.js/wiki" className="eon-red" target="_blank" rel="noopener noreferrer">
            twig.js docs
          </a>{' '}
          for template usage
        </li>
        <li>
          <code>data</code> (object) - an object containing the data to be interpolated into the template
        </li>
        <li>
          <code>width</code> (number) - the width to display the preview
        </li>
        <li>
          <code>height</code> (number) - the height to display the preview
        </li>
      </ul>
      <EmailPreview template={emailMarkup} data={emailData} width={380} height={535} />
    </section>
  </main>
);

export default StyleGuide;
