import React, { useContext } from 'react';
// import FieldError from '../../hollywood-xstate/field-error';
import ProductTables from '../../components/product-tables';
import SurveyFooter from '../../components/survey-footer';
import Toggle from '../../hollywood-xstate/toggle';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

import './property-details.scss';
import PropertyType from '../../components/property-type';
import LeadCategory from '../../components/lead-category';

const PropertyDetailsTab = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  return (
    <main className="survey-page property-details" data-testid="property_details">
      <LeadCategory />
      {/* UKSQA-1333 - Set customerIsEligibleForLowerVatRate to 'yes' for all and hide field */}
      {/* <fieldset>
        <Grid gutters="sm" valign="center">
          <Col>
            <label htmlFor="value.customerIsEligibleForLowerVatRate">
              Is this customer eligible for the lower-rate of VAT?
              <br />
              (is the person signing the contract 60 or over, or on one of the eligible benefits?)
            </label>
          </Col>
          <Col width="fixed" className="right-col">
            <Toggle
              name="value.customerIsEligibleForLowerVatRate"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
              badOption="no"
              goodOption="yes"
              value={current.value.customerIsEligibleForLowerVatRate}
              onChange={(_, v) => {
                if (v === 'no') {
                  send('CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_NO');
                }
                if (v === 'yes') {
                  send('CUSTOMER_IS_ELIGIBLE_FOR_LOWER_VAT_RATE_YES');
                }
              }}
            />
            <FieldError field="value.customerIsEligibleForLowerVatRate" />
          </Col>
        </Grid>
      </fieldset> */}
      <fieldset>
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Is the property listed, in a conservation area or subject to other planning restrictions?</p>
          </Col>
          <Col width="fixed" className="right-col">
            <Toggle
              name="propertyIsListedBuilding"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
              badOption="yes"
              goodOption="no"
              value={current.value.propertyIsListedBuilding}
              onChange={(_, v) => {
                if (v === 'no') {
                  send({ type: 'PROPERTY_IS_LISTED_BUILDING_NO', value: false });
                }
                if (v === 'yes') {
                  send({ type: 'PROPERTY_IS_LISTED_BUILDING_YES', value: true });
                }
              }}
            />
          </Col>
        </Grid>
      </fieldset>
      <PropertyType />

      <SurveyFooter />
      <ProductTables />
    </main>
  );
};

export default PropertyDetailsTab;
