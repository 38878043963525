import { toFloat, toInt } from '@zing/neo-common/dist/lib/safe';
import { roofYieldStaticMatrix } from './roof-yield-static-matrix';
import { fixedFloat } from '@zing/neo-common/dist/lib/number';

/**
 * This function returns annual yield of THIS roof
 *
 * NOTE: This function requires a lookup matrix of data based up on the zone_id, inclination and orientation
 *
 * ORIGINAL EXCEL EXAMPLE
 *   (original calc)
 *   ='Hidden Calcs'!C19*K8*C10
 *   (hidden calcs - C19)
 *   =INDEX(INDIRECT("'"&Location&"'!C3:AL93"),MATCH('Hidden Calcs'!C16,'Zone 1 - London'!$B$3:$B$93,0),MATCH('Hidden Calcs'!C17,'Zone 1 - London'!$C$2:$AL$2,0))
 *
 * INPUTS
 *   zone_id		= lookup table zone for property location		(Location)
 *   inclination	= roof inclination (variation from horizontal)	('Zone 1 - London'!$B$3:$B$93)
 *   orientation	= roof orientation (variation from South)		('Zone 1 - London'!$C$2:$AL$2)
 *   roof_kw		= Rated Power of array (kW)						(K8)
 *   shading		= shading factor								(C10)
 *
 * OUTPUTS
 *   annual_yield	= total annual yield in kWh
 *
 * EXTERNAL DATA
 *   This function uses the matrix of array yeild data for value lookups
 *
 * @param zoneId
 * @param inclination
 * @param orientation
 * @param roofKw
 * @param shading
 * @returns {number|*}
 */
export const roofAnnualYieldKwh = (zoneId, inclination, orientation, roofKw, shading) => {
  if (
    zoneId !== '' &&
    !Number.isNaN(toInt(inclination)) &&
    !Number.isNaN(toInt(orientation)) &&
    !Number.isNaN(toFloat(roofKw)) &&
    !Number.isNaN(toFloat(shading))
  ) {
    const matrix = roofYieldStaticMatrix(zoneId, toInt(inclination), toInt(orientation));
    if (matrix !== false) {
      return fixedFloat(toFloat(matrix) * toFloat(roofKw) * toFloat(shading), 2);
    }
  }
  return 0;
};
