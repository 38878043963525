import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useSurveyValidation from '../../contexts/survey-validation-context';

import { createArrayInputOptions } from '../utils/inputs';
import camelCase from 'lodash.camelcase';

const Select = ({ autoFocus, className, disabled, id, name, onBlur, onChange, options, placeholder, testId, testIdUseLabels, value }) => {
  const { validator } = useSurveyValidation();
  const useId = id || name;
  const useTestId = testId || camelCase(`${name}`);
  return (
    <select
      name={name}
      id={useId}
      className={classnames('hollywood-select', className, validator.hasError(name) ? 'invalid' : null)}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      data-testid={useTestId}
      value={value}
      onChange={event => {
        validator.touch(name);
        if (onChange) onChange(event, event.target.value);
      }}
      onBlur={event => {
        validator.touch(name);
        if (onBlur) onBlur(event, event.target.value);
      }}
    >
      <option value="">{placeholder || 'Please select'}</option>
      {createArrayInputOptions({ options, id, name, testId, testIdUseLabels }).map(option => (
        <option key={option.value} value={option.value} data-testid={option.testId || ''} disabled={option.disabled}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
      }),
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ])
  ).isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  testIdUseLabels: PropTypes.bool,
};

Select.defaultProps = {
  id: null,
  placeholder: null,
  className: null,
  disabled: false,
  autoFocus: false,
  onChange: null,
  onBlur: null,
  testId: null,
  value: '',
  testIdUseLabels: false,
};

export default Select;
