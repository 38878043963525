import React from 'react';
import PropTypes from 'prop-types';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { useParams, Link } from 'react-router-dom';
import classnames from 'classnames';

import './pipeline-nav.scss';

const PipelineNav = ({ canSearchCustomers }) => {
  const { mode } = useParams();
  return (
    <>
      <div className="pipeline_nav" data-testid="pipeline_nav">
        <Grid valign="center" align="center">
          <Col width="auto">
            {canSearchCustomers ? (
              <div className="pipeline_filter" data-testid="pipeline_filter">
                <Link
                  to="/pipeline/pipelineSearch"
                  data-testid="pipeline_search"
                  className={classnames('pipelineSearch', mode === 'pipelineSearch' ? 'active' : null)}
                >
                  Quote search
                </Link>
                <Link
                  to="/pipeline/customerSearch"
                  data-testid="customer_search"
                  className={classnames('customerSearch', mode === 'customerSearch' ? 'active' : null)}
                >
                  Customer search
                </Link>
              </div>
            ) : (
              <h4>Quote search</h4>
            )}
          </Col>
        </Grid>
      </div>
    </>
  );
};

PipelineNav.propTypes = {
  canSearchCustomers: PropTypes.bool.isRequired,
};

export default PipelineNav;
