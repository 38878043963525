import React from 'react';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import PropTypes from 'prop-types';
import Toggle from '../../../hollywood/toggle';
import ErrorMessage from '../../../../../components/ErrorMessage';

import { yesNoOptions, shouldStopWork } from '../utils';

const Afterwards = ({ state, handleChange, handleBlur }) => (
  <div className="powra-form__section">
    <h3>Afterwards</h3>
    <div className="powra-form__field">
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>
              Have you identified any possible asbestos on the site, or is the customer aware of any asbestos on site? (If
              &lsquo;Yes&rsquo;, take photos of the location and add details in the Technical Survey submission.)
            </p>
          </Col>
          <Col width="auto">
            <Toggle
              name="afterwards.asbestosOnSite"
              id="afterwards.asbestosOnSite"
              options={yesNoOptions}
              goodOption="No"
              badOption="Yes"
              value={state.values.afterwards.asbestosOnSite}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      {shouldStopWork('afterwards', state) && (
        <ErrorMessage testId="powra-error">Stop work and contact your line manager before you continue</ErrorMessage>
      )}
    </div>
  </div>
);

Afterwards.propTypes = {
  state: PropTypes.shape({
    values: PropTypes.shape({
      afterwards: PropTypes.shape({
        asbestosOnSite: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default Afterwards;
