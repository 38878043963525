import React, { useContext } from 'react';
import Button from 'hollywood/dist/components/Button';
import FieldError from '../../hollywood-xstate/field-error';
import Icon from '@zing/neo-common/dist/components/Icon';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { toFixed } from '@zing/neo-common/dist/lib/safe';
import { createActiveRetiredProductsOptionList } from '../../utils/option-list-active-retired-products';

const Battery = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { pimComparison } = comparisons[currentComparison];
  const canAddBattery = pimComparison.getBatteries().length > 0 && current.matches(`comparison.${currentComparison}.battery.idle`);

  const batteryOptions = createActiveRetiredProductsOptionList(pimComparison.getBatteries());
  return (
    <>
      <div className="batteries__headings">
        <Grid gutters="sm">
          <Col width="fixed" className="battery-col">
            <strong>Battery module option</strong>
          </Col>
          <Col width="auto" className="range-right" />
        </Grid>
      </div>
      {!current.matches(`comparison.${currentComparison}.battery.idle`) && (
        <div className="batteries__row">
          <Grid gutters="sm" valign="center">
            <Col width="fixed" className="battery-col">
              <Select
                name={`context.comparisons[${currentComparison}].battery`}
                value={pimComparison.getBattery()?.prodId}
                options={batteryOptions}
                onChange={(_, prodId) => {
                  if (prodId) {
                    send({ type: 'BATTERY_SELECT', currentComparison, prodId });
                  } else {
                    send({ type: 'BATTERY_UNSELECT', currentComparison });
                  }
                }}
              />
              <FieldError field={`context.comparisons[${currentComparison}].battery`} />
            </Col>
            {!current.matches(`comparison.${currentComparison}.battery.idle`) && (
              <Col width="auto">
                <button
                  type="button"
                  data-testid="deleteBattery"
                  onClick={() => {
                    send({ type: 'BATTERY_DELETE', currentComparison });
                  }}
                >
                  <Icon icon="trash" className="light" />
                </button>
              </Col>
            )}
            <Col className="range-right" />
          </Grid>
        </div>
      )}
      <div className="batteries__total">
        <Grid gutters="sm" valign="center">
          <Col width="auto">
            {canAddBattery && (
              <Button
                format="secondary"
                testId="addBattery"
                onClick={() => {
                  send('BATTERY_ADD');
                }}
                disabled={false}
              >
                <span className="plus">+</span> Add battery
              </Button>
            )}
            {pimComparison.getBatteries().length === 0 && (
              <span className="battery-warning">
                Not possible to add a battery with current {pimComparison?.getMeterType()?.toLowerCase()} configuration
              </span>
            )}
          </Col>
          <Col className="range-right">
            <h3>Total battery capacity</h3>
          </Col>
          <Col width="auto" className="range-right">
            <h3 data-testid="rowsTotal">{toFixed(pimComparison.getSystemTotalBatteryCapacityKwh(), 1)} kWh</h3>
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default Battery;
