import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { get } from '@zing/neo-common/dist/lib/safe';
import { ProfilePicture } from './ProfilePicture';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UserForm from './UserForm';

export const ProfileSettings = ({
  removeProfilePicture,
  resetEditableUserDetails,
  settings,
  updateEditableUserDetails,
  updateUserDetails,
  uploadProfilePicture,
  user,
}) => {
  const [editableUser, setEditableUser] = useState(user);

  return (
    <>
      <Grid gutters="sm" valign="top">
        <Col>
          <UserForm
            processing={get(settings, 'update_user.status') === 'Processing'}
            user={{ ...user, ...settings.editable_user_details }}
            // Related to non-saved but editable fix.  Helps ensure button isn't disabled
            markAsDirty={settings.editable_user_details !== null}
            onChange={(field, val) => {
              setEditableUser({ ...editableUser, [field]: val });
            }}
            onReset={() => {
              resetEditableUserDetails();
            }}
            onSubmit={values => {
              const userDetails = {
                firstname: values.firstname,
                lastname: values.lastname,
                mobile: values.mobile,
                telephone: values.telephone,
              };
              updateUserDetails(userDetails);
              // resetEditableUserDetails();
            }}
          />
        </Col>
        <Col>
          <div className="form-field">
            <ProfilePicture
              onUploadProfilePicture={formData => {
                // Fix to persist non-saved but edited changes
                updateEditableUserDetails({ ...editableUser });
                // And now doing the actual upload
                uploadProfilePicture(formData);
              }}
              user={user}
              settings={settings}
              removeProfilePicture={removeProfilePicture}
            />
          </div>
        </Col>
      </Grid>
    </>
  );
};

ProfileSettings.propTypes = {
  removeProfilePicture: PropTypes.func.isRequired,
  resetEditableUserDetails: PropTypes.func.isRequired,
  updateEditableUserDetails: PropTypes.func.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  uploadProfilePicture: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    editable_user_details: PropTypes.bool.isRequired,
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
};
