export const InitialQuoteState = {
  pim_uuid: '',
  quote_reference: '',
  // sharepoint_document_location: '',
  house_number: '',
  address_line_1: '',
  agreed: '',
  annual_electricity_demand: '',
  annual_gen_kwh: 0,
  annual_generation_year_1: 0,
  annual_generation_year_2: 0,
  annual_generation_year_3: 0,
  annual_generation_year_4: 0,
  annual_yield_kwh_elevation_1: 0,
  annual_yield_kwh_elevation_2: 0,
  annual_yield_kwh_elevation_3: 0,
  annual_yield_kwh_elevation_4: 0,
  appointment_is_phone: '',
  appointment_sat: '',
  avoided_import_kwh_year_1: '',
  azimuth_elevation_1: 0,
  azimuth_elevation_2: 0,
  azimuth_elevation_3: 0,
  azimuth_elevation_4: 0,
  battery_rated_capacity: 0,
  benefit_year_20: 0,
  city: '',
  co2_saving_kg: 0,
  comments: '',
  // created_at: '',
  crm_id: '',
  customer_deposit: 0,
  customer_electricity_price: 0,
  // deleted_at: '',
  discount: 0,
  electricity_price_inflation_rate: '',
  email: '',
  energy_usage_profile: '',
  epc_rating: '',
  export_payment_annual: 0,
  export_tariff: 0,
  custom_export_rate: false,
  extras: '',
  final_balance_to_be_paid: 0,
  first_year_benefit_total: 0,
  firstname: '',
  fit_payment_annual: 0,
  g59_requirement: '',
  grid_connected_pv_on_site: '',
  // id: '',
  install_days: 1,
  installation_cost: 0,
  installation_type: '',
  is_customer_waiver_signed: '',
  is_epc_rating_required: '',
  is_g59_required: '',
  is_payment_plan: '',
  kwh_kwp_elevation_1: 0,
  kwh_kwp_elevation_2: 0,
  kwh_kwp_elevation_3: 0,
  kwh_kwp_elevation_4: 0,
  lastname: '',
  // lnumber: '',
  mcs_postcode_region: '',
  message_id: '',
  meter_type: '',
  mobile: '',
  monthly_payment_amount: 0,
  mpan: '',
  narrative: '',
  number_of_occupants: '',
  number_of_panels_elevation_1: 0,
  number_of_panels_elevation_2: 0,
  number_of_panels_elevation_3: 0,
  number_of_panels_elevation_4: 0,
  number_of_shaded_segments_elevation_1: 0,
  number_of_shaded_segments_elevation_2: 0,
  number_of_shaded_segments_elevation_3: 0,
  number_of_shaded_segments_elevation_4: 0,
  package_id: 0,
  postcode: '',
  pv_performance_efficiency: 0,
  reduction_in_energy_costs_annual: 0,
  return_year_1: 0,
  self_consumption: 0,
  self_sufficiency_crm: '',
  shaded_segments_1: '',
  shaded_segments_2: '',
  shaded_segments_3: '',
  shaded_segments_4: '',
  shading_factor_elevation_1: 0,
  shading_factor_elevation_2: 0,
  shading_factor_elevation_3: 0,
  shading_factor_elevation_4: 0,
  slope_elevation_1: 0,
  slope_elevation_2: 0,
  slope_elevation_3: 0,
  slope_elevation_4: 0,
  survey_date: '',
  technical_components: '',
  telephone: '',
  token: '',
  token_expiry: '',
  total_estimated_annual_output: 0,
  total_panels: '',
  total_specific_yield_kwh_kwp: 0,
  total_with_vat: 0,
  total_with_vat_before_discount: 0,
  total_without_vat: 0,
  total_without_vat_before_discount: 0,
  upfront_payment_amount: 0,
  upfront_payment_percentage: 0,
  user_id: 0,
  vat_amount_higher_rate: 0,
  vat_amount_lower_rate: 0,
  vat_rate: '',
  year_1_kwh: 0,
  year_2_kwh: 0,
  year_3_kwh: 0,
  year_4_kwh: 0,
  year_5_kwh: 0,
  agreed_terms_and_conditions: false,
  agreed_cooling_off_waiver: false,
  payment_options: {},
  add_on_products: [],
  batteries: [],
  panel: {},
  roof_fixing: {},
  system_total_peak_dc_power: 0,
};
