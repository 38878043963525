import React from 'react';
import Button from 'hollywood/dist/components/Button';
import PropTypes from 'prop-types';
import { appointmentMatchUrl } from '../../../../utils/opportunity';
import { withRouter } from 'react-router-dom';

const CompleteLetterOfAuthority = ({ match }) => (
  <div className="range-right">
    <Button testId="g99-application" format="secondary" link={appointmentMatchUrl(match, ['survey', 'g99-application'])}>
      + Complete G99 Letter of Authority
    </Button>
  </div>
);

CompleteLetterOfAuthority.propTypes = {
  match: PropTypes.shape({}).isRequired,
};
export default withRouter(CompleteLetterOfAuthority);
