import React from 'react';
import Header from '../../components/Header';

export const Headers = () => (
  <>
    <h3>Default</h3>
    <Header testId="header" />

    <h3>Title</h3>
    <Header title="look at me ma, im testing!" testId="header-title" />

    <h3>Inverted logo</h3>
    <Header logo="inverted" testId="header-logo-inverted" />

    <h3>No Logo</h3>
    <Header logo={false} testId="header-no-logo" />

    <h3>Menu inverted</h3>
    <Header menuButton="inverted" testId="header-menu-inverted" />

    <h3>Menu no button</h3>
    <Header menuButton={false} testId="header-no-menu-button" />
  </>
);
