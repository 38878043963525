import _set from 'lodash.set';
import { validate } from './utils';

export default function powraReducer(state, action) {
  const updates = { ...state };
  switch (action.type) {
    case 'MOUNT':
      _set(updates, `errors`, validate(state));
      return updates;
    case 'CHANGE':
      _set(updates, `values.${action.name}`, action.value);
      _set(updates, `errors`, validate(state));
      return updates;
    case 'BLUR':
      _set(updates, `touched.${action.name}`, true);
      _set(updates, `errors`, validate(state));
      return updates;
    case 'SUBMIT':
      _set(updates, `errors`, validate(state));
      _set(updates, `submitCount`, updates.submitCount + 1);
      return updates;
    default:
      throw new Error();
  }
}
