// We've decided not to make the chart 100% true to the percentages.
// The bars now increase by the same percentage, regardless of the numbers etc.
// This is because the true percentages just dont look great and crop the

export const normalizeEnergySpend = values => {
  // 5 equal steps from 15% up to 100%
  const baseCurrentPercentage = 15;
  const currentIncrement = 21.25;

  // 5 equal steps from 6% up t0 42%
  const baseProjectedPercentage = 6;
  const projectedIncrement = 9;

  return values.map((item, index) => {
    const { currentExpectedSpend, projectedSpend, currentExpectedSavings, year } = item;
    return {
      currentExpectedSpend,
      currentP: index === 0 ? baseCurrentPercentage : baseCurrentPercentage + currentIncrement * index,
      projectedSpend,
      projectedP: index === 0 ? baseProjectedPercentage : baseProjectedPercentage + projectedIncrement * index,
      currentExpectedSavings,
      year,
    };
  });
};
