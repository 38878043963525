import React from 'react';
import { Grid } from '@zing/neo-common/dist/components/Grid';

export const Grids = () => (
  <>
    <h3>Renders children</h3>
    <Grid testId="grid-children">
      <span>Hello</span>
    </Grid>

    <h3>Gutters</h3>
    <Grid gutters="sm" testId="grid-gutters" />

    <h3>Align</h3>
    <Grid align="fill" testId="grid-align" />

    <h3>V-Align</h3>
    <Grid valign="center" testId="grid-valign" />
  </>
);
