import React from 'react';
import Login from '../pages/login';
import Reset from '../components/Reset';
import StyleGuide from '../pages/StyleGuide';
import { ExposeHistory } from './ExposeHistory';
import { PrivateRoute } from '../components/PrivateRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TroubleSigningIn } from '../pages/TroubleSigningIn';
import privateRoutes from './private-routes';

const Routes = () => (
  <>
    <Route path="/" component={ExposeHistory} />
    <Switch>
      <Route path="/login" exact component={Login.Azure} />
      <Route path="/login-username" exact component={Login.Username} />
      <Route path="/style-guide" component={StyleGuide} />
      <Route exact path="/trouble-signing-in" component={TroubleSigningIn} />
      {privateRoutes.map(route => (
        <PrivateRoute key={route.path} path={route.path} component={route.component} exact={!!route.exact} />
      ))}
      <Route path="/reset" component={Reset} />
      <Redirect from="/" to="/login" />
    </Switch>
  </>
);

export default Routes;
