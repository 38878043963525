import { useService } from '@xstate/react';
import * as accessor from '@zing/neo-common/dist/redux/opportunity/accessor';
import Button from 'hollywood/dist/components/Button';
import classnames from 'classnames';
import TableRowIcon from './table-row-icon';
import DisplayMeetings from '../meetings/display-meetings';
import PropTypes from 'prop-types';
import React from 'react';
import useConstant from 'use-constant';
import AssignTo from '../../components/assign-to/assign-to';

const CustomerTableRow = ({ item, shaded, cellClasses, visitOpportunity }) => {
  const [state, send] = useService(item.ref);
  const api = useConstant(() => ({
    open: () => send('OPEN'),
    close: () => send('CLOSE'),
    assignToMe: values => send('ASSIGN_TO_ME', values),
    assignToUser: values => send('ASSIGN_TO_USER', values),
  }));

  const { opportunity } = state.context;
  const { meetings = [] } = opportunity;
  const isExpanded = state.matches('display.expanded');

  return (
    <>
      <div className={classnames('pipeline_table_row', shaded ? 'shaded' : '')}>
        <div className={cellClasses('tab_col')}>
          <TableRowIcon isExpanded={isExpanded} api={api} />
        </div>
        <div className={cellClasses('date_col')} data-testid="created">
          {accessor.createdDateShort(opportunity)}
        </div>
        <div className={cellClasses('id_col')} data-testid="lnumber">
          {accessor.opportunityLnumber(opportunity)}
        </div>
        <div className={cellClasses(null)} data-testid="name">
          {accessor.customerDisplayName(opportunity)}
        </div>
        <div className={cellClasses('city_col')} data-testid="city">
          {accessor.customerCity(opportunity)}
        </div>
        <div className={cellClasses('sales_consultant_col')} data-testid="sales_consultant">
          {accessor.salesConsultantDisplayName(opportunity)}
        </div>
        <div className="pipeline_table_cell actions_col">
          {/* eslint-disable-next-line camelcase */}
          {opportunity.sales_consultant_email === state.context.email && (
            <Button
              format="primary"
              size="small"
              type="button"
              onClick={() => {
                visitOpportunity({ opportunity });
              }}
            >
              View
            </Button>
          )}
          <AssignTo api={api} opportunityAppUuid={accessor.appUuid(opportunity)} />
        </div>
      </div>
      <DisplayMeetings
        api={api}
        isExpanded={isExpanded}
        meetings={meetings}
        opportunity={opportunity}
        shaded={shaded}
        cellClasses={cellClasses}
      />
    </>
  );
};

CustomerTableRow.propTypes = {
  item: PropTypes.shape({
    ref: PropTypes.shape({}).isRequired,
  }).isRequired,
  shaded: PropTypes.bool.isRequired,
  cellClasses: PropTypes.func.isRequired,
  visitOpportunity: PropTypes.func.isRequired,
};

export default CustomerTableRow;
