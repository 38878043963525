import React from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Textarea from '../../hollywood-xstate/textarea';
import usePimSurveyMachineValidation from '../../contexts/survey-context';

import './instalation-notes.scss';

const InstallationNotes = () => {
  const { current, send } = usePimSurveyMachineValidation();
  return (
    <main className="survey-page installation-notes" data-testid="installationNotesTab">
      <h3 className="tight">Installation comments</h3>
      <p>Add any notes needed for installation.</p>
      <Textarea
        name="context.comments"
        value={current.context.comments}
        onChange={(_, value) => {
          send({ type: 'COMMENTS', value });
        }}
        rows={8}
      />
      <FieldError field="context.comments" />
    </main>
  );
};

export default InstallationNotes;
