import merge from 'lodash.merge';
import produce, { createDraft, finishDraft } from 'immer';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { get, toString } from '@zing/neo-common/dist/lib/safe';
import { initialState } from './survey-initialState';
import { mcsZoneFromPostcode } from '../../../utils/calculations/utils/mcs-zone-from-postcode';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import {
  SESSION_ADD_SURVEY_PACKAGE,
  SESSION_BEGIN,
  SESSION_CURRENT_URL,
  SESSION_CUSTOMER_FREE_EPC,
  SESSION_DELETE_SURVEY_PACKAGE,
  SESSION_RELOAD,
  SESSION_RENAME_SURVEY_PACKAGE,
  SESSION_RESET,
  SESSION_FINISH,
  SESSION_SAVE_SUNPATH,
  SESSION_UPDATE_SURVEY,
  SESSION_UPDATE_SURVEY_PACKAGE,
  SESSION_UPDATE_SURVEY_PACKAGE_DELETE_BATTERIES,
  SESSION_UPDATE_SURVEY_PACKAGE_EMAIL_QUOTE,
} from '../actionTypes';
import {
  SETTINGS_PROFILE_IMAGE_REMOVE_COMMIT,
  SETTINGS_PROFILE_IMAGE_UPLOAD_COMMIT,
  SETTINGS_UPDATE_USER_COMMIT,
} from '../../settings/actionTypes';

//
// todo: Deprecate this reducer but it's still used.
//

export const survey = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, draft => {
    switch (action.type) {
      case SESSION_RESET:
        return initialState;

      case SESSION_FINISH:
        return initialState;

      case RESET_ALL:
        return initialState;

      case SESSION_RELOAD: {
        return merge({}, get(action.session, 'survey'));
      }

      case SESSION_BEGIN: {
        // definitions!
        // current = the current session opportunity/survey in progress
        // new = the new opportunity/survey that is about to be started
        const postcode = get(action.opportunity, 'postcode', '');
        const postcodeZoneId = toString(mcsZoneFromPostcode(postcode));
        const newOpportunityUuid = appUuid(action.opportunity);
        const currentOpportunityUuid = appUuid(state);

        // Are we starting a new journey?
        if (newOpportunityUuid !== currentOpportunityUuid) {
          const session = createDraft(merge({}, initialState, get(action, 'opportunity.survey', {})));
          session.sales_consultant = merge({}, action.salesConsultant);
          session.app_uuid = newOpportunityUuid;
          session.postcode = postcode;
          session.postcode_zone_id = postcodeZoneId;
          return finishDraft(session);
        }

        // are we re-starting an existing journey?
        // if so, do not overwrite the existing session, we could lose values!!
        // this will mean that we will IGNORE any updates that have come from the API while a session is in progress
        // Agreed with JC on 10/07/19
        draft.postcode = postcode;
        draft.postcode_zone_id = postcodeZoneId;
        break;
      }

      case SESSION_CURRENT_URL: {
        return {
          ...state,
          current_url: action.currentUrl,
        };
      }

      case SESSION_UPDATE_SURVEY: {
        // eslint-disable-next-line camelcase
        const { current_url, ...updates } = action.updates;
        return {
          ...state,
          ...updates,
        };
      }

      case SESSION_ADD_SURVEY_PACKAGE: {
        return {
          ...state,
          packages: [...state.packages, action.values],
        };
      }

      case SESSION_DELETE_SURVEY_PACKAGE: {
        const result = merge({}, state);
        result.packages.splice(action.packageId, 1);
        return result;
      }

      case SESSION_RENAME_SURVEY_PACKAGE: {
        const result = merge({}, state);
        result.packages[action.packageId].package_name = action.name;
        return result;
      }

      case SESSION_UPDATE_SURVEY_PACKAGE: {
        const result = merge({}, state);
        if (result.packages[action.packageId]) {
          result.packages[action.packageId] = merge({}, result.packages[action.packageId], action.values);
        }
        return result;
      }

      case SESSION_UPDATE_SURVEY_PACKAGE_DELETE_BATTERIES: {
        const result = merge({}, state);
        if (result.packages[action.packageId]) {
          result.packages[action.packageId].batteries = [];
        }
        return result;
      }

      case SESSION_UPDATE_SURVEY_PACKAGE_EMAIL_QUOTE: {
        const result = merge({}, state);
        result.packages[action.packageId].email_quote = merge({}, action.values);
        return result;
      }

      case SESSION_SAVE_SUNPATH: {
        const index = Number(action.index);
        if (Array.isArray(draft.roofs) && draft.roofs[index]) {
          draft.roofs[index].number_of_shaded_segments = action.sunpath.number_of_shaded_segments;
          draft.roofs[index].shaded_segments = action.sunpath.shaded_segments;
          draft.roofs[index].shading_factor = action.sunpath.shading_factor;
          draft.roofs[index].sunpath_saved = true;
        }
        break;
      }

      case SESSION_CUSTOMER_FREE_EPC: {
        return merge({}, state, { customer_free_epc: action.value });
      }

      case SETTINGS_UPDATE_USER_COMMIT:
        draft.sales_consultant.firstname = action.firstname;
        draft.sales_consultant.lastname = action.lastname;
        draft.sales_consultant.mobile = action.mobile;
        draft.sales_consultant.telephone = action.telephone;
        break;

      case SETTINGS_PROFILE_IMAGE_UPLOAD_COMMIT:
        draft.sales_consultant.profile_image_data = action.payload.profile_image_data;
        draft.sales_consultant.profile_image_mime_type = action.payload.profile_image_mime_type;
        draft.sales_consultant.profile_image_filename = action.payload.profile_image_filename;
        draft.sales_consultant.profile_image_uploaded_at = action.payload.profile_image_uploaded_at;
        break;

      case SETTINGS_PROFILE_IMAGE_REMOVE_COMMIT:
        draft.sales_consultant.profile_image_data = '';
        draft.sales_consultant.profile_image_mime_type = '';
        draft.sales_consultant.profile_image_filename = '';
        draft.sales_consultant.profile_image_uploaded_at = '';
        break;
    }
  });
