import { poundsPence } from '@zing/neo-common/dist/lib/display';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// import ServiceGoodsBreakdown from '../../../../components/calcs/service-goods-breakdown/service-goods-breakdown';

import './index.scss';

export const TotalComponentsPricing = ({ calcs, calcsValidate }) => {
  const discountValueInPoundsIncVat = Number(calcs?.discountCalcs?.discountValueInPoundsIncVat);
  const hasDiscount = discountValueInPoundsIncVat > 0;

  return (
    <>
      Total components price
      <h3 className="footerComponentPrice" data-testid="footerComponentPrice">
        {poundsPence(calcs?.totals?.incVatTotal)}{' '}
        {hasDiscount && (
          <span className={classNames('footerComponentDiscount', !calcsValidate ? 'fixedDiscountError' : undefined)}>
            Including a discount of {poundsPence(discountValueInPoundsIncVat)}
          </span>
        )}
      </h3>
      {/* Removed - UKSQA-1358 */}
      {/* <ServiceGoodsBreakdown
        goodsVatRate={calcs?.vatRateGoods}
        servicesVatRate={calcs?.vatRateServices}
        goodsRatio={calcs?.gsRatioGoods}
        servicesRatio={calcs?.gsRatioServices}
      /> */}
    </>
  );
};

TotalComponentsPricing.propTypes = {
  calcs: PropTypes.shape({
    totals: PropTypes.shape({
      incVatTotal: PropTypes.number.isRequired,
    }).isRequired,
    discountCalcs: PropTypes.shape({
      discountValueInPoundsIncVat: PropTypes.number.isRequired,
    }).isRequired,
    vatRateServices: PropTypes.number.isRequired,
    gsRatioGoods: PropTypes.number.isRequired,
    gsRatioServices: PropTypes.number.isRequired,
    vatRateGoods: PropTypes.number.isRequired,
  }).isRequired,
  calcsValidate: PropTypes.bool.isRequired,
};
