import { POI_BATTERY_STORAGE, POI_SOLAR_PV } from '@zing/neo-common/dist/config';
import { getArray } from '@zing/neo-common/dist/lib/safe';

export const PLEASE_SELECT_A_PRODUCT = 'Please select at least one product';
export const PLEASE_SELECT_VALID_OPTION = 'Please select a Solar PV or Battery Storage option.';

export const validateProductsOfInterest = values => {
  const productsOfInterest = getArray(values, 'products_of_interest');
  if (productsOfInterest.length === 0) {
    return {
      products_of_interest: PLEASE_SELECT_A_PRODUCT,
    };
  }
  if (productsOfInterest.includes(POI_SOLAR_PV.key) || productsOfInterest.includes(POI_BATTERY_STORAGE.key)) {
    return {};
  }
  return {
    products_of_interest: PLEASE_SELECT_VALID_OPTION,
  };
};
