import { AppSettings } from './AppSettings';
import { connect } from 'react-redux';
import { fetchAPIVersion } from '../../redux/api_version/actions';
import { fetchOpportunitiesFromDateAsPromise } from '../../redux/opportunities/actions';
import { fetchPim, fetchPimAsPromise } from '../../redux/pim/actions';
import { fetchPresenterAsPromise } from '../../redux/presenter/actions';
import { fetchQuoteEmailTemplate, fetchQuoteEmailTemplateAsPromise } from '../../redux/quote-email/actions';
import { get } from '@zing/neo-common/dist/lib/safe';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { NavLink } from 'react-router-dom';
import { ProfileSettings } from './ProfileSettings';
import Header from '../../components/Header';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useSettingsService from '../../hooks/use-settings-service';
import {
  refreshAppData,
  refreshResources,
  uploadProfilePicture,
  removeProfilePicture,
  updateUserDetails,
  cacheResources,
  resetEditableUserDetails,
  updateEditableUserDetails,
} from '../../redux/settings/settings';

import './Settings.scss';
import { fetchDefaults } from '../../redux/defaults/actions';

const Settings = ({
  apiVersion,
  cacheResources,
  defaults,
  fetchAPIVersion,
  fetchOpportunitiesFromDateAsPromise,
  fetchPimAsPromise,
  fetchPresenterAsPromise,
  fetchQuoteEmailTemplateAsPromise,
  fetchDefaults,
  location,
  presenterVersion,
  refreshResources,
  removeProfilePicture,
  resetEditableUserDetails,
  settings,
  updateEditableUserDetails,
  updateUserDetails,
  uploadProfilePicture,
  user,
}) => {
  const [state, send] = useSettingsService();
  // eslint-disable-next-line react/prop-types
  const isProfile = location.pathname.endsWith('/profile');
  useEffect(() => {
    fetchAPIVersion();
    resetEditableUserDetails();
  }, []);

  // if we have just reloaded the page, make sure the states matches the url
  useEffect(() => {
    if (isProfile && state.matches('app')) {
      send('SETTINGS_PROFILE');
    } else if (!isProfile && state.matches('profile')) {
      send('SETTINGS_APP');
    }
  }, [isProfile]);

  return (
    <>
      <Header title="Settings" />
      <main className="settings shaded" data-testid="settings-page">
        {state.matches('app') && (
          <AppSettings
            apiVersion={apiVersion}
            cacheResources={cacheResources}
            defaults={defaults}
            fetchOpportunitiesFromDateAsPromise={fetchOpportunitiesFromDateAsPromise}
            fetchPimAsPromise={fetchPimAsPromise}
            fetchPresenterAsPromise={fetchPresenterAsPromise}
            fetchQuoteEmailTemplateAsPromise={fetchQuoteEmailTemplateAsPromise}
            fetchDefaults={fetchDefaults}
            presenterVersion={presenterVersion}
            refreshResources={refreshResources}
            settings={settings}
            userRole={user.role}
          />
        )}
        {state.matches('profile') && (
          <ProfileSettings
            user={user}
            settings={settings}
            resetEditableUserDetails={resetEditableUserDetails}
            uploadProfilePicture={uploadProfilePicture}
            updateUserDetails={updateUserDetails}
            updateEditableUserDetails={updateEditableUserDetails}
            removeProfilePicture={removeProfilePicture}
          />
        )}
      </main>
      <div className="settings-nav" data-testid="settings-nav">
        <Grid>
          <Col>
            <NavLink to="/settings/app" activeClassName="active">
              App Settings
            </NavLink>
          </Col>
          <Col>
            <NavLink data-testid="profile-settings-link" to="/settings/profile" activeClassName="active">
              Profile Settings
            </NavLink>
          </Col>
        </Grid>
      </div>
    </>
  );
};

Settings.propTypes = {
  apiVersion: PropTypes.shape({}).isRequired,
  cacheResources: PropTypes.shape({}).isRequired,
  defaults: PropTypes.shape({}).isRequired,
  fetchAPIVersion: PropTypes.func.isRequired,
  fetchOpportunitiesFromDateAsPromise: PropTypes.func.isRequired,
  fetchPimAsPromise: PropTypes.func.isRequired,
  fetchPresenterAsPromise: PropTypes.func.isRequired,
  fetchQuoteEmailTemplateAsPromise: PropTypes.func.isRequired,
  fetchDefaults: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  presenterVersion: PropTypes.shape({}).isRequired,
  refreshResources: PropTypes.func.isRequired,
  removeProfilePicture: PropTypes.func.isRequired,
  resetEditableUserDetails: PropTypes.func.isRequired,
  settings: PropTypes.shape({}).isRequired,
  updateEditableUserDetails: PropTypes.func.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  uploadProfilePicture: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  accessToken: get(state, 'auth.access_token'),
  apiVersion: get(state, 'apiVersion.version'),
  defaults: get(state, 'defaults.values'),
  presenterVersion: get(state, 'presenter.presenter.title'),
  settings: state.settings,
  user: get(state, 'user.user'),
});

export default connect(mapStateToProps, {
  cacheResources,
  fetchAPIVersion,
  fetchOpportunitiesFromDateAsPromise,
  fetchPim,
  fetchPimAsPromise,
  fetchPresenterAsPromise,
  fetchQuoteEmailTemplate,
  fetchQuoteEmailTemplateAsPromise,
  refreshAppData,
  refreshResources,
  removeProfilePicture,
  resetEditableUserDetails,
  updateEditableUserDetails,
  updateUserDetails,
  uploadProfilePicture,
  fetchDefaults,
})(Settings);
