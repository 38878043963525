import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import snakeCase from 'lodash.snakecase';

const BreakdownSectionRow = ({ testId = '', title, value, prefix = '', suffix = '' }) => (
  <Grid className="breakdown__section__row">
    <Col>{title}</Col>
    <Col width="auto" className="range-right" data-testid={snakeCase(testId)}>
      <strong>
        {prefix}
        {value}
        {suffix}
      </strong>
    </Col>
  </Grid>
);
BreakdownSectionRow.propTypes = {
  testId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  suffix: PropTypes.string.isRequired,
};

export default BreakdownSectionRow;
