import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import optionsListNameProdId from '../../utils/options-list-name-prod-id';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const ChargePointType = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { pimComparison } = comparisons[currentComparison];
  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>Charge point type</p>
        </Col>
        <Col width="fixed" className="right-col">
          <Select
            name={`context.comparisons[${currentComparison}].evChargePoint`}
            value={pimComparison.getEvChargePoint()?.prodId}
            options={pimComparison.getEvChargers().map(optionsListNameProdId)}
            onChange={(_, prodId) => {
              if (prodId) {
                send({ type: 'EV_CHARGE_POINT', currentComparison, prodId });
              } else {
                send({ type: 'EV_CHARGE_POINT', currentComparison });
              }
            }}
          />
          <FieldError field={`context.comparisons[${currentComparison}].evChargePoint`} />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default ChargePointType;
