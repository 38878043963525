import React from 'react';
import { appointmentUrl } from '../../utils/opportunity';
import { cleanSessionBeforeRequote } from './pipeline-page/clean-session-before-requote';
import CustomerPageComponent from './customer-page';
import PipelinePageComponent from './pipeline-page';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Header from '../Header';
import PipelineNav from './components/pipeline-nav';

import './pipeline.scss';

/**
 * Page component
 * @param history
 * @param reloadSession
 * @param salesConsultant
 * @param startSession
 * @returns {*}
 * @constructor
 */
const PipelinePage = ({ history, isAdmin, canSearchCustomers, reloadSession, salesConsultant, startSession }) => {
  const { mode } = useParams();

  const visitOpportunity = ({ opportunity, session = null, reload = false }) => {
    const visitUrl = appointmentUrl(opportunity);
    if (reload) {
      reloadSession(cleanSessionBeforeRequote(session));
      startSession(opportunity, salesConsultant);
    } else {
      // load the item into session
      startSession(opportunity, salesConsultant);
    }
    history.push(visitUrl, { back: `/pipeline/${mode}`, requote: reload });
  };

  return (
    <>
      <Header title="Pipeline" bordered={false} />
      <PipelineNav canSearchCustomers={canSearchCustomers} />
      {mode === 'pipelineSearch' && (
        <PipelinePageComponent isAdmin={isAdmin} salesConsultant={salesConsultant} visitOpportunity={visitOpportunity} />
      )}
      {mode === 'customerSearch' && <CustomerPageComponent isAdmin={isAdmin} visitOpportunity={visitOpportunity} />}
    </>
  );
};

PipelinePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  canSearchCustomers: PropTypes.bool.isRequired,
  reloadSession: PropTypes.func.isRequired,
  salesConsultant: PropTypes.shape({}).isRequired,
  startSession: PropTypes.func.isRequired,
};

export default PipelinePage;
