import React, { useContext, useEffect, useRef, useState } from 'react';
import ComparisonSelection from './components/comparison-selection';
import initialContext from '../../machines/survey.machine.initial-context';
import initialState from '../../redux/pim-session/initialstate';
import PropTypes from 'prop-types';
import SurveyHeader from './components/survey-header';
import surveyMachine from '../../machines/survey.machine';
import SurveyRoutes from './routes/survey-routes';
import SurveyTabs from './components/survey-tabs';
import useLocalStorage from '../../hooks/useLocalStorage';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { historyPropTypes } from '../../app-prop-types/history';
import { isFullPageView, isSurveyPage, rehydrateMachine } from './utils/survey';
import { PimContext } from './contexts/pim-context';
import { PimSurveyMachineContext } from './contexts/survey-context';
import { SurveyValidationContextProvider } from './contexts/survey-validation-context';
import { useMachine } from '@xstate/react/lib';
import { withRouter } from 'react-router-dom';

import './survey.scss';

// keep outside of function so object does not get recreated (causing re-render)
const surveyMachineOptions = { devTools: true };

const PimSurveyPage = ({ history, sessionCurrentUrl, defaults }) => {
  const [hasRehydrated, hasRehydratedSet] = useState(false);
  const { pim } = useContext(PimContext);
  const [pimSession, pimSessionSet] = useLocalStorage('pimSession', initialState);
  const currentPage = useRef(undefined); // is a ref to avoid double render when setting value (happens when using useState())

  const { pathname } = history.location;

  // machine must always be started with a state from localstorage, but only once.
  // Do not switch to useEffect, that will run contents after useMachine below which is too late to initialise options
  if (!hasRehydrated) {
    if (pimSession.surveyMachine) {
      surveyMachineOptions.state = rehydrateMachine(pim, pimSession.surveyMachine);
      hasRehydratedSet(true);
    }
  }

  const [current, send, service] = useMachine(surveyMachine.withContext({ ...initialContext(pim, defaults) }), surveyMachineOptions);

  useEffect(() => {
    const subscription = service.subscribe(state => {
      // eslint-disable-next-line no-console
      // console.log('Survey', state.event, state);
      pimSession.surveyMachine = state;
      pimSessionSet(pimSession);
    });
    return subscription.unsubscribe;
  }, []);

  useEffect(() => {
    if (history && pathname !== currentPage.current) {
      currentPage.current = pathname;
      // fire redux action so we can `continue session`
      sessionCurrentUrl(currentPage.current);
    }
  }, [pathname]);

  const showFullPage = isFullPageView(pathname);
  // console.log('Rendering survey');

  return (
    <PimSurveyMachineContext.Provider value={{ current, send, service }}>
      <SurveyValidationContextProvider service={service}>
        {showFullPage && <SurveyRoutes />}
        {!showFullPage && (
          <Grid gutters="lg">
            <Col width="12">
              <SurveyHeader />
              <main className="survey-page" data-testid="">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  <>
                    {isSurveyPage(pathname) && (
                      <>
                        <ComparisonSelection current={current} send={send} />
                        <SurveyTabs baseUrl="/appointment" />
                      </>
                    )}
                    <SurveyRoutes />
                  </>
                </form>
              </main>
            </Col>
          </Grid>
        )}
      </SurveyValidationContextProvider>
    </PimSurveyMachineContext.Provider>
  );
};

PimSurveyPage.propTypes = {
  history: PropTypes.shape(historyPropTypes).isRequired,
  sessionCurrentUrl: PropTypes.func.isRequired,
  defaults: PropTypes.shape({}).isRequired,
};

export default withRouter(PimSurveyPage);
