import { appointmentMatchUrl } from '../../../../utils/opportunity';
import { isSurveyPage } from '../../utils/survey';
import { withRouter } from 'react-router-dom';
import { appPropTypes } from '../../../../app-prop-types';
import BackButton from '../../../../components/buttons/back';
import Header from '../../../../components/Header';
import PropTypes from 'prop-types';
import React from 'react';

const SurveyHeader = ({ history, match }) => (
  <Header bordered={false} title={isSurveyPage(history.location.pathname) ? 'Household details' : ''}>
    {history.location.pathname.match(/your-package$/) && (
      <div className="your-package-header-buttons">
        <BackButton link={appointmentMatchUrl(match, ['survey', 'additional-products'])} />
      </div>
    )}
  </Header>
);

SurveyHeader.propTypes = {
  history: PropTypes.shape(appPropTypes.historyPropTypes).isRequired,
  match: PropTypes.shape(appPropTypes.matchPropTypes).isRequired,
};

export default withRouter(SurveyHeader);
