import React from 'react';
import Toggle from '../../../hollywood/toggle';
import Textarea from '../../../hollywood/textarea';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import ErrorMessage from '../../../../../components/ErrorMessage';
import PropTypes from 'prop-types';

import { yesNoOptions, yesNoNaOptions, shouldStopWork, fieldHasError } from '../utils';

const OnArrival = ({ state, handleChange, handleBlur }) => (
  <div className="powra-form__section">
    <h3>On arrival</h3>
    <div className="powra-form__field">
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Have you completed any relevant safety inductions for the site?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="onArrival.siteSafetyInductions"
              id="onArrival.siteSafetyInductions"
              options={yesNoNaOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.onArrival.siteSafetyInductions}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Do you know the emergency procedures for the site, including where to go and who to contact?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="onArrival.siteEmergencyProcedures"
              id="onArrival.siteEmergencyProcedures"
              options={yesNoNaOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.onArrival.siteEmergencyProcedures}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>
              Is the work location free from any unusual environmental conditions which could impact your work, i.e. ice, heavy rain, strong
              winds etc?
            </p>
          </Col>
          <Col width="auto">
            <Toggle
              name="onArrival.unusualEnvironmentConditions"
              id="onArrival.unusualEnvironmentConditions"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.onArrival.unusualEnvironmentConditions}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Do you have appropriate equipment to safely transport any tools/equipment to the work place?</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="onArrival.appropriateEquipment"
              id="onArrival.appropriateEquipment"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.onArrival.appropriateEquipment}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Grid>
      </div>
      <div className="powra-form__field">
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Is this appointment scheduled in your calendar? (If &lsquo;No&rsquo;, please add a &lsquo;Note&rsquo; as to why)</p>
          </Col>
          <Col width="auto">
            <Toggle
              name="onArrival.opportunityInCalendar"
              id="onArrival.opportunityInCalendar"
              options={yesNoOptions}
              goodOption="Yes"
              badOption="No"
              value={state.values.onArrival.opportunityInCalendar}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          {state.values.onArrival.opportunityInCalendar === 'No' && (
            <Col width="12">
              <Textarea
                name="onArrival.opportunityInCalendarNote"
                id="onArrival.opportunityInCalendarNote"
                placeholder="Please provide details"
                testId="freeFromRiskyPetsNote"
                value={state.values.onArrival.opportunityInCalendarNote}
                hasError={fieldHasError('onArrival.opportunityInCalendarNote', state)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {fieldHasError('onArrival.opportunityInCalendarNote', state) && (
                <p className="hollywood-validation-error">{state.errors.onArrival.opportunityInCalendarNote}</p>
              )}
            </Col>
          )}
        </Grid>
      </div>
      {shouldStopWork('onArrival', state) && (
        <ErrorMessage testId="powra-error">Stop work and contact your line manager before you continue</ErrorMessage>
      )}
    </div>
  </div>
);

OnArrival.propTypes = {
  state: PropTypes.shape({
    values: PropTypes.shape({
      onArrival: PropTypes.shape({
        siteSafetyInductions: PropTypes.string,
        siteEmergencyProcedures: PropTypes.string,
        unusualEnvironmentConditions: PropTypes.string,
        appropriateEquipment: PropTypes.string,
        opportunityInCalendar: PropTypes.string,
        opportunityInCalendarNote: PropTypes.string,
      }),
    }),
    errors: PropTypes.shape({
      onArrival: PropTypes.shape({
        siteSafetyInductions: PropTypes.string,
        siteEmergencyProcedures: PropTypes.string,
        unusualEnvironmentConditions: PropTypes.string,
        appropriateEquipment: PropTypes.string,
        opportunityInCalendar: PropTypes.string,
        opportunityInCalendarNote: PropTypes.string,
      }),
    }),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default OnArrival;
