import React from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash.snakecase';

import './stat.scss';

const Stat = ({ description, testId = 'stat', prefix = '', suffix = '', value }) => (
  <p className="stat tight">
    <span className="h1" data-testid={snakeCase(testId)}>
      {prefix}
      {value}
      {suffix}
    </span>
    <br />
    {description}
  </p>
);

Stat.defaultProps = {
  prefix: '',
  suffix: '',
};

Stat.propTypes = {
  testId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

export default Stat;
