import * as Yup from 'yup';
import { NOT_PRESENT_WHEN_NO_PV, REQUIRED, REQUIRED_WHEN_COMPLEX, BETWEEN } from '../../../../components/Survey/validate/messages';
import { FILTER_1_PHASE, FILTER_3_PHASE } from '@zing/neo-common/dist/redux/product/accessor';
import { MAX_PANELS_PER_ROOF } from '../../../../config';

const schema = comparison =>
  Yup.object().shape({
    context: Yup.object().shape({
      comparisons: Yup.object().shape({
        [comparison]: Yup.object().shape({
          panel: Yup.object().shape({
            prodId: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
              is: true,
              then: Yup.string().required(REQUIRED),
            }),
          }),
          inverter: Yup.object().shape({
            prodId: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
              is: true,
              then: Yup.string().required(REQUIRED),
            }),
          }),
          expectedScaffoldCost: Yup.string()
            .when([`$context.comparisons.${comparison}.hasSolarPV`], {
              is: true,
              then: Yup.string().required(REQUIRED),
            })
            .when([`$context.comparisons.${comparison}.hasSolarPV`], {
              is: false,
              then: Yup.string().length(0),
            }),
          meterType: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
            is: true,
            then: Yup.string()
              .oneOf([FILTER_1_PHASE, FILTER_3_PHASE])
              .required(REQUIRED),
          }),
          roofFixing: Yup.object().shape({
            prodId: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
              is: true,
              then: Yup.string().required(REQUIRED),
            }),
          }),
          roofs: Yup.array().of(
            Yup.object({
              roofName: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
                is: true,
                then: Yup.string().required(REQUIRED),
              }),

              slopeElevation: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
                is: true,
                then: () =>
                  Yup.number()
                    .min(0, BETWEEN(0, 90))
                    .max(90, BETWEEN(0, 90))
                    .required(REQUIRED),
              }),
              azimuthElevation: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
                is: true,
                then: () =>
                  Yup.number()
                    .min(-180, BETWEEN(-180, 180))
                    .max(180, BETWEEN(-180, 180))
                    .required(REQUIRED),
              }),
              numberOfPanelsElevation: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
                is: true,
                then: () =>
                  Yup.number()
                    .min(0)
                    .max(MAX_PANELS_PER_ROOF)
                    .required(REQUIRED),
              }),
            })
          ),
        }),
      }),
      externalKitLocation: Yup.string().required(REQUIRED),
      scaffoldComplex: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
        is: false,
        then: Yup.string().matches(/no/, NOT_PRESENT_WHEN_NO_PV),
      }),
      comments: Yup.string().when('$context.scaffoldComplex', {
        is: 'yes',
        then: Yup.string().required(REQUIRED_WHEN_COMPLEX),
      }),
    }),
    // value: Yup.object().shape({
    //   isRosemaryOrSlateRoofPreset: Yup.string().when([`$context.comparisons.${comparison}.hasSolarPV`], {
    //     is: false,
    //     then: Yup.string().matches(/no/, NOT_PRESENT_WHEN_NO_PV),
    //   }),
    // }),
  });

export default schema;
