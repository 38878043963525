import React from 'react';
import PropTypes from 'prop-types';

import './ProductStatus.scss';

export default function ProductStatus({ status }) {
  return <span className={`product-status product-status--${status}`}>{status}</span>;
}

ProductStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
