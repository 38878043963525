import { assign } from 'xstate';

/**
 * Pass in a `fetchMessages` service when creating the Machine .e.g
 *
 * const fetchMessages = values =>
 *  new Promise((resolve, reject) =>
 *    setTimeout(() => reject("Lnumber missing"), 100);
 *  );
 *
 */
export const crmMachineConfig = {
  id: 'crmMessages',
  initial: 'idle',
  context: {
    messages: [],
    error: undefined,
  },
  on: {
    SEARCH: 'loading',
  },
  states: {
    idle: {},
    loading: {
      entry: assign({ error: '', messages: [] }),
      invoke: {
        id: 'fetchMessages',
        src: 'fetchMessages',
        onDone: {
          target: 'success',
          actions: [
            assign({ messages: (_, e) => e.data }),
            {
              type: 'notify',
              info: true,
              message: `Success fetching messages`,
            },
          ],
        },
        onError: {
          target: 'error',
          actions: [assign({ error: (_, e) => e.data }), { type: 'notify', warn: true, message: `Error fetching messages` }],
        },
      },
    },
    success: {},
    error: {},
  },
};
