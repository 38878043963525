import React from 'react';
import PropTypes from 'prop-types';
import * as filters from '../../redux/opportunities/hide-opportunities';
import DashboardNav from '../../components/dashboard-nav';
import Header from '../../components/Header';
import OpportunitiesList from '../../components/opportunities/opportunities-list/opportunities-list';
import OpportunitiesNav from '../../components/opportunities/opportunities-nav/opportunities-nav';

const OpportunitiesPage = ({ calendarOpen, filter, opportunityDates, opportunitiesByDate, setCalendarOpen, setFilter }) => (
  <>
    <Header title="Your appointments" bordered={false} />
    <OpportunitiesNav
      calendarOpen={calendarOpen}
      filter={filter}
      opportunityDates={opportunityDates}
      setCalendarOpen={setCalendarOpen}
      setFilter={setFilter}
    />
    <main className="opportunities shaded" data-testid="opportunities-page">
      <OpportunitiesList opportunityDates={opportunityDates} opportunitiesByDate={opportunitiesByDate} />
    </main>
    <DashboardNav />
  </>
);

OpportunitiesPage.propTypes = {
  calendarOpen: PropTypes.bool.isRequired,
  filter: PropTypes.oneOf([filters.FILTER_THIS_WEEK, filters.FILTER_NEXT_WEEK, filters.FILTER_THIS_MONTH, filters.FILTER_ALL]).isRequired,
  opportunityDates: PropTypes.shape([]).isRequired,
  opportunitiesByDate: PropTypes.shape([]).isRequired,
  setCalendarOpen: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default OpportunitiesPage;
