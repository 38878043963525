import { useEffect, useState } from 'react';
import neoDb from '../databases/neo';

const useOpportunities = timestamp => {
  const [opportunities, setOpportunities] = useState([]);
  useEffect(() => {
    if (timestamp !== null) {
      neoDb
        .table('opportunities')
        .toArray()
        .then(items => {
          setOpportunities(items);
        });
    }
  }, [timestamp]);
  return opportunities;
};

export default useOpportunities;
