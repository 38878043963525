import { Machine, assign, send } from 'xstate';
import { processPricingModel } from '../pages/diagnostics/pim/csv-export-utils';

const initialContext = {
  pricingModels: [],
  numModels: 0,
  currentModelIndex: 0,
  percentageComplete: 0,
  results: [],
  pim: undefined,
};

export const csvExportMachine = defaults =>
  Machine(
    {
      id: 'csvExport',
      initial: 'idle',
      context: initialContext,
      states: {
        idle: {
          on: {
            LOAD: {
              target: 'generating',
              actions: 'loadInputs',
            },
          },
        },
        generating: {
          on: {
            PROCESS_ROW: [
              {
                target: 'generating',
                actions: ['processRow', 'updateItemGenerated', 'updatePercentageComplete', send('PROCESS_ROW', { delay: 10 })],
                cond: c => c.currentModelIndex < c.pricingModels.length,
              },
              {
                target: 'generated',
              },
            ],
          },
        },
        generated: {
          on: {
            RESET: {
              target: 'idle',
              actions: 'resetContext',
            },
          },
        },
      },
    },
    {
      guards: {},
      actions: {
        resetContext: assign(initialContext),
        processRow: assign({
          results: context => {
            const index = context.currentModelIndex;
            const inputs = context.pricingModels[index];
            const results = [];
            processPricingModel(context.pim, inputs, results, defaults);
            return context.results.concat(results);
          },
        }),
        loadInputs: assign({
          pricingModels: (context, event) => event.pricingModels,
          numModels: (context, event) => event.pricingModels.length,
          pim: (context, event) => event.pim,
        }),
        updateItemGenerated: assign({
          currentModelIndex: context =>
            context.currentModelIndex < context.pricingModels.length ? context.currentModelIndex + 1 : context.currentModelIndex,
        }),
        updatePercentageComplete: assign({
          percentageComplete: context => (context.numModels > 0 ? (context.currentModelIndex / context.numModels) * 100 : 0),
        }),
      },
    }
  );

/**
{
  "type": "START",
  "pricingModels": [3,6,2,3,4,1,2]
}
*
 */
