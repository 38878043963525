import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const RouterScroll = ({ location, children }) => {
  // Scroll to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Just render children
  return children;
};

export default withRouter(RouterScroll);
