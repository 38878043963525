/* eslint-disable camelcase */
import React from 'react';
import DiagnosticsHeader from '../../../../components/diagnostics-header';
import { Form, Formik } from 'formik';
import NumberInput from 'hollywood/dist/components/forms/NumberInput';
import Input from 'hollywood/dist/components/forms/Input';
import Button from 'hollywood/dist/components/Button';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDefaults, fetchDefaults } from '../../../../redux/defaults/actions';

import { get } from '@zing/neo-common/dist/lib/safe/get';

function AppSettingsPage({ defaults, updateDefaults, fetchDefaults }) {
  const initialValues = {
    default_energy_inflation_rate: get(defaults.values, 'default_energy_inflation_rate', '0.068'),
    default_rpi: get(defaults.values, 'default_rpi', '0.025'),
    default_grid_emission_factor: get(defaults.values, 'default_grid_emission_factor', '0.23104'),
    default_deposit_amount: get(defaults.values, 'default_deposit_amount', '250'),
    default_export_rate_battery_only: get(defaults.values, 'default_export_rate_battery_only', '16.5'),
    default_export_rate_solar_pv: get(defaults.values, 'default_export_rate_solar_pv', '25'),
    default_export_rate_solar_pv_battery: get(defaults.values, 'default_export_rate_solar_pv_battery', '40'),
  };

  return (
    <>
      <DiagnosticsHeader title="App Settings" />
      <main className="diagnostics">
        <Grid align="center" valign="center" gutters="md">
          <Col>
            <h1>Default Values</h1>
          </Col>
          <Col width="auto">
            <Button onClick={fetchDefaults} format="primary" loading={defaults.processing}>
              Reload Defaults
            </Button>
          </Col>
        </Grid>
        {!defaults.processing && defaults.errors && (
          <div className="error-message">
            <Grid align="center" valign="center" gutters="md">
              <Col>
                <p style={{ textAlign: 'left' }}>There was an error loading the latest default values. These values may be out of date.</p>
              </Col>
              <Col width="auto">
                <Button onClick={fetchDefaults} format="primary" inverted size="small" loading={defaults.processing}>
                  Reload Defaults
                </Button>
              </Col>
            </Grid>
          </div>
        )}
        {defaults.values && (
          <Formik initialValues={initialValues} onSubmit={values => updateDefaults(values)}>
            <Form>
              <Grid gutters="sm">
                <Col width="4">
                  <label htmlFor="default_energy_inflation_rate">Electricity price inflation</label>
                  <Input name="default_energy_inflation_rate" id="default_energy_inflation_rate" />
                </Col>
                <Col width="4">
                  <label htmlFor="default_rpi">RPI (for FIT and export rate)</label>
                  <Input name="default_rpi" id="default_rpi" />
                </Col>
                <Col width="4">
                  <label htmlFor="default_grid_emission_factor">Grid emission factor</label>
                  <Input name="default_grid_emission_factor" id="default_grid_emission_factor" />
                </Col>
                <Col width="4">
                  <label htmlFor="default_deposit_amount">Default deposit amount</label>
                  <NumberInput name="default_deposit_amount" id="default_deposit_amount" prefix="£" step="1" />
                </Col>
              </Grid>

              <h3 className="tight" style={{ marginTop: '2rem' }}>
                Enhanced Export Rates
              </h3>
              <Grid gutters="sm">
                <Col width="4">
                  <label htmlFor="default_export_rate_battery_only">Battery only</label>
                  <NumberInput name="default_export_rate_battery_only" id="default_export_rate_battery_only" prefix="p/kWh" step="0.1" />
                </Col>
                <Col width="4">
                  <label htmlFor="default_export_rate_solar_pv">Solar PV</label>
                  <NumberInput name="default_export_rate_solar_pv" id="default_export_rate_solar_pv" prefix="p/kWh" step="0.1" />
                </Col>
                <Col width="4">
                  <label htmlFor="default_export_rate_solar_pv_battery">Solar PV & Battery</label>
                  <NumberInput
                    name="default_export_rate_solar_pv_battery"
                    id="default_export_rate_solar_pv_battery"
                    prefix="p/kWh"
                    step="0.1"
                  />
                </Col>
              </Grid>

              <div style={{ marginTop: '3rem' }}>
                <Button format="tertiary" type="submit" loading={defaults.processing}>
                  Save
                </Button>
              </div>
            </Form>
          </Formik>
        )}
      </main>
    </>
  );
}

AppSettingsPage.propTypes = {
  defaults: PropTypes.shape({
    processing: PropTypes.bool,
    errors: PropTypes.shape({}),
    values: PropTypes.shape({}),
  }).isRequired,
  updateDefaults: PropTypes.func.isRequired,
  fetchDefaults: PropTypes.func.isRequired,
};

export default connect(({ defaults }) => ({ defaults }), { updateDefaults, fetchDefaults })(AppSettingsPage);
