import React, { useContext } from 'react';
import { isAdmin } from '@zing/neo-common/dist/redux/user/accessor';
import createQuoteObject from '../../pages/payment-options/create-quote';
import ProductCostTable from '../product-cost-table';
import ProductExportTable from '../product-export-table';
import ProductExVatDiscountingTable from '../product-ex-vat-discounting-table';
import ProductExVatPricingTable from '../product-ex-vat-pricing-table';
import ProductPricingTable from '../product-pricing-table';
import ProductSelectionTable from '../product-selection-table';
import ProductVatSplitsTable from '../product-vat-splits';
import ProductVatTable from '../product-vat-table';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { pimExport, pimExportTitles } from '../../../../machines/survey/pim-export';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { UserContext } from '../../contexts/user-context';

import './product-tables.scss';

const ProductTables = () => {
  const user = useContext(UserContext);
  const { current } = useContext(PimSurveyMachineContext);
  const [pimSession] = useLocalStorage('pimSession');

  // Admin users only
  if (!isAdmin(user)) {
    return null;
  }

  const { currentComparison, comparisons } = current.context;
  const { pimComparison, calcs = {}, discountPercentage } = comparisons[currentComparison];
  const selectedProducts = pimComparison.getRequiredProducts();
  const numberOfPanels = pimComparison.getNumberOfPanels();
  const pvArrayOutput = pimComparison.getPvArrayOutput();

  /**
   * The following code is being used to understand how to generate the CSV export values for a survey
   * - we will need a quote object
   * - we will need the calcs
   */
  const quote = createQuoteObject(pimSession, current, pimComparison);
  const csvExportTitles = pimExportTitles({ extendedInfo: true });
  const csvExportValues = pimExport({ calcs, quote, extendedInfo: true });

  return (
    <div data-testid="pricingCostTables">
      <ProductSelectionTable selectedProducts={selectedProducts} />
      <ProductCostTable selectedProducts={calcs.values} />
      <ProductPricingTable selectedProducts={calcs.values} />
      <ProductExVatPricingTable selectedProducts={calcs.values} pvArrayOutput={pvArrayOutput} numberOfPanels={numberOfPanels} />
      <ProductExVatDiscountingTable
        calcs={calcs}
        selectedProducts={calcs.values}
        pvArrayOutput={pvArrayOutput}
        numberOfPanels={numberOfPanels}
        discountPercentage={discountPercentage}
      />
      <ProductVatTable selectedProducts={calcs.values} />
      <ProductVatSplitsTable selectedProducts={calcs.values} />
      <ProductExportTable csvExportTitles={csvExportTitles} csvExportValues={csvExportValues} />
    </div>
  );
};

export default ProductTables;
