import { fetchQuoteEmailTemplate } from '../redux/quote-email/actions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

/**
 * Fetch the email template if we haven't already got one
 * @param template
 */
const useFetchQuoteEmailTemplate = template => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!template) {
      dispatch(fetchQuoteEmailTemplate());
    }
  }, [template]);
};

export default useFetchQuoteEmailTemplate;
