import React from 'react';
import {
  EXPORT_CATEGORY_COSTS,
  EXPORT_CATEGORY_EX_VAT,
  EXPORT_CATEGORY_GM,
  EXPORT_CATEGORY_HMRC,
  EXPORT_CATEGORY_INC_VAT,
  EXPORT_CATEGORY_TIME,
  EXPORT_CATEGORY_VAT,
  EXPORT_CATEGORY_PRODUCTS,
} from '../../../../machines/survey/pim-export';
import * as classnames from 'classnames';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import producePipeline from 'immer-produce-pipeline';
import PropTypes from 'prop-types';

const columnTypeClassMap = {
  [EXPORT_CATEGORY_COSTS]: 'gs',
  [EXPORT_CATEGORY_HMRC]: 'gm',
  [EXPORT_CATEGORY_EX_VAT]: 'gm2',
  [EXPORT_CATEGORY_GM]: 'costs',
  [EXPORT_CATEGORY_VAT]: 'perItem',
  [EXPORT_CATEGORY_INC_VAT]: 'perKw',
  [EXPORT_CATEGORY_TIME]: 'exVat',
  [EXPORT_CATEGORY_PRODUCTS]: 'gs',
};

const ProductExportTable = ({ csvExportTitles = [], csvExportValues = [] }) => (
  <div style={{ marginTop: '60px', marginBottom: '60px' }}>
    <fieldset>
      <h2>Export values</h2>
      <Grid gutters="sm" valign="center">
        <Col className="horizontalScrollable">
          <table className="padded_table dataDrivenProductTable">
            <thead>
              <tr>
                {csvExportTitles.map(column => (
                  <th className={classnames('right', columnTypeClassMap[column.category])}>{column.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {csvExportValues.map(column => (
                  <td className={classnames('right', columnTypeClassMap[column.category])}>
                    {producePipeline(column?.display || [])(column.rawValue)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </Col>
      </Grid>
    </fieldset>
  </div>
);

ProductExportTable.propTypes = {
  csvExportTitles: PropTypes.shape([]).isRequired,
  csvExportValues: PropTypes.shape([]).isRequired,
};

export default ProductExportTable;
