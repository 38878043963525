import React from 'react';
import PropTypes from 'prop-types';

export const OrientationCompass = ({ index, compassBearing, azimuthElevation }) => (
  <h3 data-testid={`compass_bearing_cardinal-${index}`}>
    <svg
      style={{
        display: 'inline-block',
        verticalAlign: 'text-bottom',
        marginRight: 5,
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32"
      height="32"
    >
      <path
        d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm1 30v-2a1 1 0 0 0-2 0v2A14 14 0 0 1 2.05 17H4a1 1 0 0 0 0-2H2.05A14 14 0 0 1 15 2.05V4a1 1 0 0 0 2 0V2.05A14 14 0 0 1 30 15h-2a1 1 0 0 0 0 2h2a14 14 0 0 1-13 13z"
        fill="#ea1c0a"
      />
      <path
        className="needle"
        style={{ transformOrigin: 'center center', transform: `rotate(${azimuthElevation}deg)` }}
        d="M19.35 15.76L17 6.24a1 1 0 0 0-1.94 0l-2.38 9.52a1 1 0 0 0 0 .48L15 25.76a1 1 0 0 0 1.94 0l2.38-9.52a1 1 0 0 0 .03-.48zM16 10.61L17.35 16h-2.7z"
        fill="#ea1c0a"
      />
    </svg>
    {compassBearing}
  </h3>
);

OrientationCompass.propTypes = {
  azimuthElevation: PropTypes.number.isRequired,
  compassBearing: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
