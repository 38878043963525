import React from 'react';

import Button from 'hollywood/dist/components/Button';

const Buttons = () => (
  <>
    <h3>Unstyled</h3>
    <Button>Button</Button>
    <Button disabled>Disabled</Button>

    <br />
    <br />
    <hr />
    <br />

    <h3>Primary</h3>
    <Button format="primary" size="small">
      Small
    </Button>
    <Button format="primary" size="medium">
      Medium
    </Button>
    <Button format="primary" size="large">
      Large
    </Button>
    <br />
    <Button format="primary" size="small" disabled>
      Small disabled
    </Button>
    <Button format="primary" size="medium" disabled>
      Medium disabled
    </Button>
    <Button format="primary" size="large" disabled>
      Large disabled
    </Button>
    <br />
    <Button format="primary" size="small" loading>
      Small loading
    </Button>
    <Button format="primary" size="medium" loading>
      Medium loading
    </Button>
    <Button format="primary" size="large" loading>
      Large loading
    </Button>

    <br />
    <br />
    <hr />
    <br />

    <h3>Secondary</h3>
    <Button format="secondary">Button</Button>
    <Button format="secondary" disabled>
      Disabled
    </Button>
  </>
);

export default Buttons;
