import React from 'react';
import camelCase from 'lodash.camelcase';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const NumberInput = ({
  autoFocus,
  className,
  disabled,
  hasError,
  id,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  placeholder,
  prefix,
  step,
  testId,
  value,
}) => {
  const safeValue = Number(value || 0);
  const safeStep = Number(step || 0);
  const safeMin = Number(min);
  const safeMax = Number(max);
  const safeStepString = typeof step === 'string' ? step : '';
  const decimalPlaces = safeStepString.split('.')[1] ? safeStepString.split('.')[1].length : 0;
  const useTestId = testId || camelCase(`${name}`);
  const useId = id || name;

  return (
    <div className={classnames('hollywood-number-input', prefix ? 'hollywood-prefixed-input' : null, className)}>
      {prefix && <div className="hollywood-input-prefix">{prefix}</div>}
      <input
        type="number"
        name={name}
        id={useId}
        className={classnames('hollywood-input', className, hasError ? 'invalid' : null)}
        placeholder={placeholder}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        data-testid={useTestId}
        min={min}
        max={max}
        step={step}
        value={value}
        onBlur={onBlur}
        onChange={event => {
          if (onChange) onChange(event, event.target.value);
        }}
        onFocus={onFocus}
        onKeyPress={event => {
          if (onKeyPress) onKeyPress(event, event.target.value);
        }}
      />
      <button
        type="button"
        className="hollywood-number-input__control"
        disabled={disabled || (max !== null && safeValue >= safeMax)}
        onClick={event => {
          const newValue = (safeValue + safeStep).toFixed(decimalPlaces);
          if (onChange) onChange(event, newValue);
        }}
        data-testid={useTestId ? `${useTestId}_increment` : null}
      >
        +
      </button>
      <button
        type="button"
        className="hollywood-number-input__control"
        disabled={disabled || (min !== null && safeValue <= safeMin)}
        onClick={event => {
          const newValue = (safeValue - safeStep).toFixed(decimalPlaces);
          if (onChange) onChange(event, newValue);
        }}
        data-testid={useTestId ? `${useTestId}_decrement` : null}
      >
        -
      </button>
    </div>
  );
};

NumberInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string.isRequired,
  max: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  step: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.string,
};

NumberInput.defaultProps = {
  autoFocus: false,
  className: null,
  disabled: false,
  hasError: false,
  max: null,
  min: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyPress: null,
  placeholder: null,
  prefix: null,
  step: '1',
  testId: null,
  value: null,
};

export default NumberInput;
