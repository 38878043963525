import { appointmentUrl } from '../../../../../utils/opportunity';
import { dateFormat } from '../../../../../utils/lib';
import { format } from 'date-fns';
import { appPropTypes } from '../../../../../app-prop-types';
import PropTypes from 'prop-types';
import React from 'react';

const makeLabel = (item, index) => {
  const parts = [index + 1];
  // survey_date
  // eslint-disable-next-line camelcase
  const surveyDate = new Date(item?.survey_date);
  parts.push(format(surveyDate, dateFormat));
  parts.push(`${item.customer_package_name}`);
  if (item?.agreed) {
    parts.push('Agreed');
  }
  return parts.join(' - ');
};

const QuoteSelector = ({ quoteId, opportunity, history, quotes }) => (
  <div className="chooseQuote" data-testid="chooseQuote">
    <form>
      <select
        name="quoteId"
        value={quoteId}
        className="hollywood-select"
        onChange={e => {
          const newUrl = appointmentUrl(opportunity, ['survey', 'quote', e.target.value]);
          history.push(newUrl);
        }}
      >
        {quotes.map((item, index) => (
          <option value={index} key={`${item.customer_package_name}_${item.name}`} selected={index === quoteId ? 'selected' : ''}>
            {makeLabel(item, index)}
          </option>
        ))}
      </select>
    </form>
  </div>
);

QuoteSelector.propTypes = {
  quoteId: PropTypes.string.isRequired,
  opportunity: PropTypes.shape({}).isRequired,
  history: PropTypes.shape(appPropTypes.historyPropTypes).isRequired,
  quotes: PropTypes.shape([]).isRequired,
};

export default QuoteSelector;
