import './polyfills';
import { detect } from 'detect-browser';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import IeMessage from './components/IeMessage/IeMessage';
import React from 'react';
import ReactDOM from 'react-dom';

import './scss/index.scss'; // This must be imported before App! Components rendered by App need to be able to override the global styles

import App from './App';

const browser = detect();
let renderApp = true;

if (browser) {
  const major = parseInt(browser.version.split('.')[0], 10);
  if (browser.name === 'ie' && major === 11) {
    renderApp = false;
    ReactDOM.render(<IeMessage />, document.getElementById('root'));
  }
}

if (renderApp) {
  store.subscribe(() => {
    localStorage['neo-quotation-tool-store'] = JSON.stringify(store.getState());
  });

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );

  // https://www.cypress.io/blog/2018/11/14/testing-redux-store/#Access-Redux-store
  // expose store when run in Cypress
  if (window.Cypress) {
    window.store = store;
  }

  serviceWorker.unregister();
  // serviceWorker.register();
}
