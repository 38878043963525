import React from 'react';
import PropTypes from 'prop-types';

import './Panel.scss';

const Panel = ({ flush, className, children }) => <div className={`panel ${flush ? 'panel--flush' : ''} ${className}`}>{children}</div>;

Panel.propTypes = {
  flush: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Panel.defaultProps = {
  flush: false,
  className: '',
};
export default Panel;
