import camelCase from 'lodash.camelcase';

export const createArrayInputOptions = ({ options, id, name, testId = null, testIdUseLabels = false }) => {
  const useId = id || name;
  return options.map(option => {
    const isObject = typeof option === 'object';
    const label = isObject ? option.label : option;
    const value = isObject ? option.value : option;
    const disabled = isObject ? Boolean(option.disabled) : false;
    let useTestId = testId ? `${testId}_${value}` : `${useId}_${value}`;
    if (testIdUseLabels) {
      useTestId = `${useId}_${label}`;
    }
    return {
      label,
      value,
      disabled,
      id: camelCase(`${useId}_${value}`),
      testId: camelCase(useTestId),
    };
  });
};
