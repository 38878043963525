/* eslint-disable no-console */
import sumPanelsReducer from './sum-panels-reducer';
import { multiplyBy1000 } from '@zing/neo-common/dist/lib/number';
import { peakDcPower } from '../../utils/calculations/utils/peak-dc-power';
import { roofAnnualYieldKwh } from '../../utils/calculations/utils/roof-annual-yield-kwh';
import { roofRatedPowerKw } from '../../utils/calculations/utils/roof-rated-power-kw';
import { roofYieldStaticMatrix } from '../../utils/calculations/utils/roof-yield-static-matrix';
import { toInt, toNumber } from '@zing/neo-common/dist/lib/safe';

// Calcs
// Recalculates all roofs
// - sums all roof values for system totals
// - only runs for the current comparison.
const assignAllRoofCalcs = (context, { currentComparison }) => {
  const { postcodeZoneId = '', latitude = '' } = context;
  if (!currentComparison) {
    console.warn(`assignAllRoofCalcs requires a currentComparison`);
    return;
  }
  const comparison = context.comparisons[currentComparison];
  if (comparison === undefined) {
    console.warn(`assignAllRoofCalcs requires a currentComparison`);
    return;
  }
  const { pimComparison } = context.comparisons[currentComparison];
  const individualPanelPowerKw = Number(comparison.panel?.powerRatingKw);

  const { roofs } = comparison;
  const journey = pimComparison.getJourney();

  if (pimComparison.getNumberOfRoofs() !== roofs.length) {
    // Warning: setting this causing the inverter to auto select
    // only do it when the value actually changes
    pimComparison.setNumberOfRoofs(roofs.length);
  }

  roofs.forEach(roof => {
    const ratedPowerKw =
      journey.hasSolarPV && roof.numberOfPanelsElevation > 0 ? roofRatedPowerKw(individualPanelPowerKw, roof.numberOfPanelsElevation) : 0;

    const matrix = roofYieldStaticMatrix(postcodeZoneId, toInt(roof.slopeElevation), toInt(roof.azimuthElevation));

    roof.annualYieldKwhElevation = journey.hasSolarPV
      ? roofAnnualYieldKwh(postcodeZoneId, roof.slopeElevation, roof.azimuthElevation, ratedPowerKw, roof.shadingFactorElevation)
      : 0;
    roof.individualPanelPowerW = journey.hasSolarPV ? multiplyBy1000(individualPanelPowerKw) : 0;
    roof.ratedPowerKw = ratedPowerKw;
    roof.postcodeZoneId = postcodeZoneId;
    roof.kwhKwpElevation = matrix !== false ? matrix : '';
    roof.installedCapacity = roof.numberOfPanelsElevation * individualPanelPowerKw;
  });

  // now that we have updated all the roofs for this comparison..
  // Update the system totals
  comparison.totalPanels = comparison.roofs.reduce(sumPanelsReducer, 0);

  if (pimComparison.getNumberOfPanels() !== comparison.totalPanels) {
    // Warning: setting this causing the inverter to auto select
    // only do it when the value actually changes
    pimComparison.setNumberOfPanels(comparison.totalPanels);
  }

  comparison.systemTotalPeakDcPower = journey.hasSolarPV
    ? peakDcPower(
        latitude,
        individualPanelPowerKw,
        roofs.map(roof => ({
          inclination: toNumber(roof.slopeElevation),
          orientation: toNumber(roof.azimuthElevation),
          panels: toNumber(roof.numberOfPanelsElevation),
        }))
      )
    : 0;

  if (pimComparison.getSystemTotalPeakDcPower() !== comparison.systemTotalPeakDcPower) {
    // Warning: setting this causing the inverter to auto select
    // only do it when the value actually changes
    pimComparison.setSystemTotalPeakDcPower(comparison.systemTotalPeakDcPower);
  }

  // this is the NEW PV SYSTEM total install, it does not include Existing..
  comparison.systemTotalInstallPvKwh =
    journey.hasSolarPV && comparison.totalPanels > 0
      ? roofs.reduce((current, roof) => current + Number(roof.annualYieldKwhElevation), 0)
      : 0;

  comparison.systemTotalInstallPvKwhCombined = comparison.systemTotalInstallPvKwh + Number(context.existingPvAnnualYieldKwh);

  comparison.totalSpecificYieldKwhKwp =
    journey.hasSolarPV && comparison.totalPanels > 0 ? roofs.reduce((current, roof) => current + Number(roof.ratedPowerKw), 0) : 0;
};

export default assignAllRoofCalcs;
