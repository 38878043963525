import { CostsAndSavingsInitialState } from './costs-and-savings';
import { PaymentOptionsInitialState } from './payment-options';
import { FILTER_BATTERY_CAPACITY_ALL, FILTER_BATTERY_INVERTER_HYBRID } from '@zing/neo-common/dist/config';

export const PackageInitialState = {
  agreed_cooling_off_waiver: false,
  agreed_terms_and_conditions: false,
  apr_rate: 0,
  batteries: [],
  battery_inverter_toggle: FILTER_BATTERY_INVERTER_HYBRID,
  battery_capacity_toggle: FILTER_BATTERY_CAPACITY_ALL,
  battery_highest_rating_power_kw: '',
  cashflow: [],
  charge_point_type: '',
  charge_point: null,
  costs_and_savings: { ...CostsAndSavingsInitialState },
  customer_signature: '',
  email_quote: {
    should_email_quote: false,
    customer_email: '',
    cc_email: '',
    bcc_email: '',
    subject: 'Your E.ON Solar & Battery Quotation',
  },
  ev_filter_cable_length: '',
  ev_filter_include_rfid: '',
  ev_filter_socket_type: '',
  ev_filter_socketed: '',
  ev_olev_grant: '',
  g99: {
    existingPV: 0,
    proposedPV: 0,
    battery: 0,
    totalOnSiteGeneration: 0,
  },
  include_cooling_off_waiver: '',
  installation_type: '',
  inverter: '',
  inverter_type: '',
  inverter_power_rating_kw: '',
  narrative_default_text: null,
  package_name: '',
  panel: null,
  panel_id: '',
  payment_options: { ...PaymentOptionsInitialState },
  payment_type: '',
  poiHasBattery: false,
  poiHasSolarPv: false,
  product_costs: null,
  roofs: [],
  self_sufficiency_percent: '',
  signed: false,
  signed_and_agreed_at: '',
  solar_package: '',
  solar_package_in_roof: false,
  sunpath_analysis_possible: '',
  system_total_combined_pv_annual_yield_kwh: '', // existing + install pv (KWH)
  system_total_battery_power_rating_kw: '',
  system_total_combined_annual_yield_kw: '', // new + existing PV
  system_total_install_pv_kwh: '', // only new, does not include existing PV
  system_total_on_site_generation_kw: '',
  system_total_peak_dc_power: '',
  system_total_rated_power_kw: '',
  system_total_size: '',
};
