import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import CustomerDetailsForm from '../../components/forms/customer-details-form';
import Header from '../../components/Header';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { get, getBoolean } from '@zing/neo-common/dist/lib/safe';
import { opportunityMatchUrl } from '../../utils/opportunity';
import { sessionUpdateOpportunity } from '../../redux/session/actions';
import { updateOpportunity } from '@zing/neo-common/dist/redux/opportunity/actions';
import hooks from '../../hooks';

import './CustomerDetails.scss';
import useLocalStorage from '../../hooks/useLocalStorage';
import initialState from '../../redux/pim-session/initialstate';

const EditCustomerDetails = ({ history, match, location, updateOpportunity, sessionUpdateOpportunity }) => {
  const [pimSession, pimSessionSet] = useLocalStorage('pimSession', initialState);
  const backUrl = get(location, 'state.back', opportunityMatchUrl(match));
  const updateOpportunityInSession = getBoolean(location, 'state.updateOpportunityInSession');
  const isPimSurvey = getBoolean(location, 'state.isPimSurvey');
  const [opportunityUuid, meetingUuid] = hooks.useMatchOpportunity();
  const opportunity = hooks.useOpportunity(opportunityUuid, meetingUuid);

  return (
    <>
      <Header title="Customer details" />
      <main className="customer-details" data-testid="customer-details">
        <CustomerDetailsForm
          opportunity={opportunity}
          onSubmit={values => {
            updateOpportunity(appUuid(values), values);
            if (isPimSurvey) {
              pimSession.opportunity = values;
              pimSessionSet(pimSession);
            } else if (updateOpportunityInSession) {
              sessionUpdateOpportunity(values);
            }
            history.push(backUrl);
          }}
          backUrl={backUrl}
        />
      </main>
    </>
  );
};

EditCustomerDetails.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  updateOpportunity: PropTypes.func.isRequired,
  sessionUpdateOpportunity: PropTypes.func.isRequired,
};

export default connect(() => ({}), { updateOpportunity, sessionUpdateOpportunity })(EditCustomerDetails);
