import React from 'react';
import BatteryCapacityToggle from '../../components/battery-capacity-toggle';
import BatteryComponent from '../../components/battery';
import BatteryInverterToggle from '../../components/battery-inverter-toggle';
import ProductTables from '../../components/product-tables';
import SurveyFooter from '../../components/survey-footer';
import TrenchingRequirementMeters from '../../components/trenching-requirement-meters';

import './battery.scss';

const Battery = () => (
  <main className="survey-page battery" data-testid="battery">
    <BatteryInverterToggle />
    <BatteryCapacityToggle />
    <BatteryComponent />
    <TrenchingRequirementMeters />
    <SurveyFooter />
    <ProductTables />
  </main>
);

export default Battery;
