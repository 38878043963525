import React from 'react';
import CompleteLetterOfAuthority from '../../components/complete-letter-of-authority';
import G99Calcs from '../../components/g99-calcs';
import G99Explanation from '../../components/g99-explanation';
import G99Override from '../../components/g99-override';
import G99Required from '../../components/g99-required';
import ProductTables from '../../components/product-tables';
import SurveyFooter from '../../components/survey-footer';

import './index.scss';

const G99 = () => (
  <main className="survey-page g99" data-testid="g99Tab">
    <G99Calcs />
    <G99Required />
    <G99Override />
    <CompleteLetterOfAuthority />
    <G99Explanation />
    <SurveyFooter />
    <ProductTables />
  </main>
);

export default G99;
