import { NavLink } from 'react-router-dom';
import diagnosticsSections from '../../routes/diagnostics-routes';
import Icon from '@zing/neo-common/dist/components/Icon';
import PropTypes from 'prop-types';
import React, { useRef, useEffect, Fragment } from 'react';
import useDiagnosticsMenuService from '../../hooks/use-diagnostics-menu-service';

const DiagnosticsMenu = ({ isUserAdmin }) => {
  const [api] = useDiagnosticsMenuService();

  const menuRef = useRef();

  const handleOutsideClick = event => {
    if (!menuRef.current.contains(event.target)) {
      api.close();
    }
  };

  const isOpen = api.isOpen();
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <nav className={`diagnostics-menu ${api.isOpen() ? 'diagnostics-menu--open' : ''}`} ref={menuRef}>
      <div className="app-menu__body" style={{ flex: 1, overflowY: 'auto', padding: '0 30px', margin: '0 -30px' }}>
        <h1>
          <NavLink exact to="/diagnostics">
            Diagnostics
          </NavLink>
        </h1>
        {diagnosticsSections.map(section => {
          if (section.isAdminOnly && isUserAdmin === false) {
            return null;
          }
          return (
            <Fragment key={section.name}>
              <h3>{section.name}</h3>
              <ul>
                {section.routes
                  .filter(route => route.showInMenu)
                  .map(route => (
                    <li key={route.path}>
                      <NavLink to={route.path} activeClassName="active" className="diagnostics_link" onClick={() => api.close()}>
                        {route.title}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </Fragment>
          );
        })}
      </div>

      <button type="button" className="diagnostics-menu__close-btn" onClick={() => api.close()}>
        <Icon icon="close" />
      </button>
    </nav>
  );
};

DiagnosticsMenu.propTypes = {
  isUserAdmin: PropTypes.bool.isRequired,
};

export default DiagnosticsMenu;
