import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Input from '../../hollywood-xstate/input/input';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const Estimates = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;
  return (
    <fieldset className="estimates">
      <p>Complete ONE of the following three fields to estimate annual electricity demand:</p>
      <Grid gutters="sm" valign="top">
        <Col>
          <label htmlFor={`context.comparisons.${currentComparison}.annualElectricityDemandKwh`}>Annual electricity demand</label>
          <Input
            type="number"
            prefix="kWh"
            name={`context.comparisons.${currentComparison}.annualElectricityDemandKwh`}
            min={0}
            value={String(current.context.comparisons[currentComparison].annualElectricityDemandKwh) || ''}
            onChange={(_, value) => {
              send({ type: 'ANNUAL_ELECTRICITY_DEMAND_KWH', currentComparison, value });
            }}
          />
          <FieldError field={`context.comparisons.${currentComparison}.annualElectricityDemandKwh`} />
        </Col>
        <Col>
          <label htmlFor={`context.comparisons.${currentComparison}.annualElectricityCost`}>Annual electricity cost</label>
          <Input
            type="number"
            prefix="£"
            name={`context.comparisons.${currentComparison}.annualElectricityCost`}
            min={0}
            value={String(current.context.comparisons[currentComparison].annualElectricityCost) || ''}
            onChange={(_, value) => {
              send({ type: 'ANNUAL_ELECTRICITY_COST', currentComparison, value });
            }}
          />
          <FieldError field={`context.comparisons.${currentComparison}.annualElectricityCost`} />
        </Col>
        <Col>
          <label htmlFor={`context.comparisons.${currentComparison}.numberOfOccupants`}>Number of occupants</label>
          <Input
            type="number"
            name={`context.comparisons.${currentComparison}.numberOfOccupants`}
            min={0}
            value={String(current.context.comparisons[currentComparison].numberOfOccupants) || ''}
            onChange={(_, value) => {
              send({ type: 'NUMBER_OF_OCCUPANTS', currentComparison, value });
            }}
          />
          <FieldError field={`context.comparisons.${currentComparison}.numberOfOccupants`} />
        </Col>
        <Col width="auto">
          <strong className="equals eon-red"> = </strong>
        </Col>
        <Col width="fixed" className="right-col">
          <span className="sm">Estimated electricity demand</span>
          <h3 className="estimation" data-testid="estimation">
            {current.context.comparisons[currentComparison].estimatedElectricityDemandKwh || 0} kWh
          </h3>
        </Col>
      </Grid>
    </fieldset>
  );
};

export default Estimates;
