import React, { useState } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { api as client } from '@zing/neo-common/dist/lib/api';
import Modal from 'hollywood/dist/components/Modal';
import Button from 'hollywood/dist/components/Button';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';

import './assign-to.scss';

function getSuggestionValue(user) {
  return `${user.firstname} ${user.lastname}`;
}
function renderSuggestion(user) {
  return `${user.firstname} ${user.lastname} - ${user.email}`;
}

function AssignTo({ api, opportunityAppUuid, meetingAppUuid, auth }) {
  const accessToken = auth.access_token;
  const [showModal, setShowModal] = useState(false);
  const [userResults, setUserResults] = useState([]);
  const [userQuery, setUserQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  async function fetchUserResults({ value }) {
    if (value.length < 3) return;
    const query = value.toLowerCase();
    const url = `${process.env.REACT_APP_API_BASE_URL}/cms/users?query=${query}&?limit=100`; // force low data during test
    const response = await client({ accessToken, url, method: 'GET' });

    setUserResults(response.data);
  }

  function clearUserResults() {
    setUserResults([]);
  }

  function onSuggestionSelected(_, { suggestion }) {
    setSelectedUser(suggestion);
  }

  function closeModal() {
    setShowModal(false);
    setUserQuery('');
    setSelectedUser(null);
  }

  return (
    <>
      <Button format="secondary" size="small" onClick={() => setShowModal(true)}>
        Assign to user
      </Button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} className="assign-to-modal">
        <Grid gutters="xs" valign="bottom">
          <Col>
            <label htmlFor="userQuery" className="hollywoood-label">
              Assign to user:
            </label>
            <Autosuggest
              suggestions={userResults}
              onSuggestionsFetchRequested={fetchUserResults}
              onSuggestionsClearRequested={clearUserResults}
              renderSuggestion={renderSuggestion}
              getSuggestionValue={getSuggestionValue}
              onSuggestionSelected={onSuggestionSelected}
              inputProps={{
                value: userQuery,
                type: 'search',
                id: 'userQuery',
                className: 'hollywood-input',
                onChange: (_, { newValue }) => {
                  setUserQuery(newValue);
                  setSelectedUser(null);
                },
              }}
            />
          </Col>
          <Col width="auto">
            <Button
              format="primary"
              type="button"
              disabled={!selectedUser}
              onClick={() => {
                if (!selectedUser) return;

                api.assignToUser({
                  user: selectedUser,
                  opportunityAppUuid,
                  meetingAppUuid,
                });

                closeModal();
              }}
            >
              Assign
            </Button>
          </Col>
        </Grid>
        <br />
        <div className="range-right">
          <Button
            format="primary"
            type="button"
            onClick={() => {
              api.assignToMe({
                opportunityAppUuid,
                meetingAppUuid,
              });
              closeModal();
            }}
          >
            Assign to me
          </Button>
        </div>
      </Modal>
    </>
  );
}

AssignTo.propTypes = {
  api: PropTypes.shape({
    assignToMe: PropTypes.func.isRequired,
    assignToUser: PropTypes.func.isRequired,
  }).isRequired,
  opportunityAppUuid: PropTypes.string.isRequired,
  meetingAppUuid: PropTypes.string.isRequired,
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({ auth }) => ({ auth }))(AssignTo);
