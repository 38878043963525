import { calcTotals } from '../../../../machines/survey/calc-producers';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import producePipeline from 'immer-produce-pipeline';
import PropTypes from 'prop-types';
import React from 'react';

const ProductTableDataDriven = ({ title = '', values = [], rowConfig, TableHead, PostTable }) => {
  const totalsFieldNames = rowConfig.filter(x => x.totals === true).map(x => x.field);
  const totalsValues = calcTotals(values, totalsFieldNames);

  return (
    <div style={{ marginTop: '60px', marginBottom: '60px' }}>
      <fieldset>
        <h2>{title}</h2>
        <Grid gutters="sm" valign="center">
          <Col className="horizontalScrollable">
            <table className="padded_table dataDrivenProductTable">
              {TableHead}
              <tbody>
                {values.map(item => (
                  <tr>
                    {rowConfig.map(row => (
                      <td className={row.className}>{producePipeline(row.pipeline)(item.product[row.field])}</td>
                    ))}
                  </tr>
                ))}
                <tr className="totalsRow">
                  {rowConfig.map(row => {
                    if (!row.totals) {
                      return <td className={row.totalClassName ? row.totalClassName : ''}>&nbsp;</td>;
                    }
                    return <td className="total">{producePipeline(row.totalsPipeline)(totalsValues[row.field])}</td>;
                  })}
                </tr>
              </tbody>
            </table>
          </Col>
        </Grid>
        {PostTable && (
          <Grid>
            <Col>{PostTable}</Col>
          </Grid>
        )}
      </fieldset>
    </div>
  );
};

ProductTableDataDriven.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.shape([]).isRequired,
  roConfig: PropTypes.shape({}).isRequired,
  TableHead: PropTypes.node.isRequired,
  PostTable: PropTypes.node,
  rowConfig: PropTypes.shape([]).isRequired,
};

ProductTableDataDriven.defaultProps = {
  PostTable: null,
};

export default ProductTableDataDriven;
