import { distanceInWordsToNow } from 'date-fns';
import { get } from '@zing/neo-common/dist/lib/safe';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { threeWeeksAgo } from '../../utils/three-weeks-ago';
import Button from 'hollywood/dist/components/Button';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useSettingsService from '../../hooks/use-settings-service';

const getLastUpdated = date => {
  if (!date) {
    return null;
  }
  return `Last updated ${distanceInWordsToNow(new Date(date))} ago`;
};

const getSupportEmail = defaults => {
  const email = get(defaults, 'app_support_email');
  if (!email) {
    return null;
  }
  return (
    <a data-testid="app-support-link" href={`mailto:${email}`}>
      {email}
    </a>
  );
};

export const AppSettings = ({
  apiVersion,
  cacheResources,
  defaults,
  fetchOpportunitiesFromDateAsPromise,
  fetchPimAsPromise,
  fetchPresenterAsPromise,
  fetchQuoteEmailTemplateAsPromise,
  fetchDefaults,
  presenterVersion,
  refreshResources,
  settings,
  userRole,
}) => {
  const [settingsState, send] = useSettingsService();
  const isOffline = settingsState.matches('app.offline');

  return (
    <>
      <h2>App Status</h2>
      <Grid gutters="sm" valign="top">
        <Col>
          <ul>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">Connection status</span>
                </Col>
                <Col>
                  <span className={classnames('value', isOffline ? 'offline-warning' : null)}>{isOffline ? 'Offline' : 'Online'}</span>
                </Col>
              </Grid>
            </li>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">Connection speed</span>
                </Col>
                <Col>
                  <span className="value">
                    {!isOffline && (
                      <a
                        data-testid="connect-speed-link"
                        href="https://fast.com"
                        className="connection-speed-button"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Test
                      </a>
                    )}
                    {isOffline && 'Unavailable'}
                  </span>
                </Col>
              </Grid>
            </li>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">App version</span>
                </Col>
                <Col>
                  <span className="value">{process.env.REACT_APP_APP_VERSION}</span>
                </Col>
              </Grid>
            </li>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">Presentation version</span>
                </Col>
                <Col>
                  <span className="value">{presenterVersion}</span>
                </Col>
              </Grid>
            </li>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">API version</span>
                </Col>
                <Col>
                  <span className="value">{apiVersion}</span>
                </Col>
              </Grid>
            </li>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">Browser string</span> <br />
                  <span className="value">{navigator.userAgent}</span>
                </Col>
              </Grid>
            </li>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">Role</span>
                </Col>
                <Col>
                  <span className="value" data-testid="userRole">
                    {userRole}
                  </span>
                </Col>
              </Grid>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">App Data</span>
                  <p className="last-updated" data-testid="refresh-app-data-last-updated">
                    {getLastUpdated(get(settings, 'refresh_app_data.last_updated'))}
                  </p>
                </Col>
                <Col>
                  <span className="value">
                    <Button
                      disabled={isOffline}
                      testId="refresh-app-data-button"
                      format="primary"
                      size="small"
                      loading={
                        settingsState.matches('app.online') &&
                        (settingsState.matches('app.online.refreshEmailTemplate.fetch') ||
                          settingsState.matches('app.online.refreshEmailTemplate.waiting') ||
                          settingsState.matches('app.online.refreshOpportunities.fetch') ||
                          settingsState.matches('app.online.refreshPimData.fetch') ||
                          settingsState.matches('app.online.refreshPresenter.fetch') ||
                          settingsState.matches('app.online.refreshDefaults.fetch'))
                      }
                      onClick={() => {
                        send({
                          type: 'REFRESH_PIM_DATA',
                          exec: () => fetchPimAsPromise(),
                        });
                        send({
                          type: 'REFRESH_EMAIL_TEMPLATE',
                          exec: () => fetchQuoteEmailTemplateAsPromise(),
                        });
                        send({
                          type: 'REFRESH_PRESENTER',
                          exec: () => fetchPresenterAsPromise(),
                        });
                        send({
                          type: 'REFRESH_OPPORTUNITIES',
                          exec: () => fetchOpportunitiesFromDateAsPromise(threeWeeksAgo()),
                        });
                        send({
                          type: 'REFRESH_DEFAULTS',
                          exec: () => fetchDefaults(),
                        });

                        // Okay, this is all because Service Worker is unable to intercept fetch for srcdom inside iframe.  WIP fix.
                        // Images and videos are in CSS right now - might also appear in HTML.  Will need to update at some point.
                        // fetchPresenterAsPromise().then(data => {
                        //   const presenter = data;
                        //   const slides = presenter.slides;
                        //   const target = presenter.slides.length;
                        //   let counter = 0;
                        //   const newSlides = [];
                        //   console.log('Searching slides and replacing any URL paths with data already cached');
                        //   // eslint-disable-next-line array-callback-return
                        //   slides.map(slide => {
                        //     const pattern = new RegExp('/cms/.*.(png|jpg|jpeg|gif|mp4)');
                        //     const results = pattern.exec(slide.css);
                        //     if (results) {
                        //       const url = results[0];
                        //       // Let's find the cached version of it...
                        //       db.resources.get({ url: url }).then(resp => {
                        //         if (resp) {
                        //           const dataUri = resp.data;
                        //           // Let's do a string replacement...
                        //           const newCss = slide.css.replace(`"${url}"`, dataUri);
                        //           newSlides.push({ ...slide, css: newCss });
                        //           counter += 1;
                        //           if (counter === target) {
                        //             console.log('completed last one');
                        //             savePresenter({ ...presenter, slides: newSlides });
                        //             refreshAppData('Completed');
                        //           } else {
                        //             console.log(`done ${counter} out of ${target} slides, pressing on`);
                        //           }
                        //         } else {
                        //           counter += 1;
                        //           if (counter === target) {
                        //             console.log('completed last one');
                        //             savePresenter({ ...presenter, slides: newSlides });
                        //             refreshAppData('Completed');
                        //           } else {
                        //             console.log(
                        //               `Unable to find cached resource ${counter} out of ${target} slides, pressing on`
                        //             );
                        //           }
                        //         }
                        //       });
                        //     } else {
                        //       counter += 1;
                        //       if (counter === target) {
                        //         console.log('completed last one');
                        //         savePresenter({ ...presenter, slides: newSlides });
                        //         refreshAppData('Completed');
                        //       } else {
                        //         console.log(`skipped ${counter} out of ${target} slides, pressing on`);
                        //       }
                        //     }
                        //   });
                        // });
                      }}
                    >
                      Refresh
                    </Button>
                  </span>
                </Col>
              </Grid>
            </li>
            <li>
              <Grid gutters="sm" valign="center">
                <Col>
                  <span className="label">Resources</span>
                  <p className="last-updated" data-testid="refresh-resources-last-updated">
                    {getLastUpdated(get(settings, 'refresh_resources.last_updated'), new Date())}
                  </p>
                </Col>
                <Col>
                  <span className="value">
                    <Button
                      disabled={isOffline}
                      testId="refresh-resources-button"
                      format="primary"
                      size="small"
                      loading={get(settings, 'refresh_resources.status') === 'Processing'}
                      onClick={() => {
                        cacheResources();
                        setTimeout(() => {
                          refreshResources('Completed');
                        }, 20000);
                      }}
                    >
                      Refresh
                    </Button>
                  </span>
                </Col>
              </Grid>
            </li>
          </ul>
          <div className="support-helpdesk">
            <h3>App support Helpdesk</h3>
            <p>For app support issues, please contact: </p>
            {getSupportEmail(defaults)}
          </div>
        </Col>
      </Grid>
    </>
  );
};

AppSettings.propTypes = {
  apiVersion: PropTypes.shape({}).isRequired,
  cacheResources: PropTypes.func.isRequired,
  defaults: PropTypes.shape({}).isRequired,
  fetchOpportunitiesFromDateAsPromise: PropTypes.func.isRequired,
  fetchPimAsPromise: PropTypes.func.isRequired,
  fetchPresenterAsPromise: PropTypes.func.isRequired,
  fetchQuoteEmailTemplateAsPromise: PropTypes.func.isRequired,
  fetchDefaults: PropTypes.func.isRequired,
  presenterVersion: PropTypes.shape({}).isRequired,
  refreshResources: PropTypes.func.isRequired,
  settings: PropTypes.shape({}).isRequired,
  userRole: PropTypes.string.isRequired,
};
