import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import PowraForm from '../../pim/survey/components/powra-form';

import { updatePowra } from '../../redux/session/actions';

const RiskAssessment = ({ session, updatePowra, history }) => {
  const submitPowra = powra => {
    powra.onArrival.done = true;
    powra.meetCustomer.done = true;
    powra.startSurvey.done = true;
    powra.afterwards.done = true;
    updatePowra(powra);
    history.goBack();
  };

  return (
    <>
      <Header />
      <main className="risk-assessment" data-testid="risk-assessment-page">
        <PowraForm sections={['onArrival', 'meetCustomer', 'startSurvey', 'afterwards']} values={session.powra} onSubmit={submitPowra} />
      </main>
    </>
  );
};

RiskAssessment.propTypes = {
  session: PropTypes.shape({
    powra: PropTypes.shape({}).isRequired,
  }).isRequired,
  updatePowra: PropTypes.func.isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
};

export default connect(({ session }) => ({ session }), { updatePowra })(RiskAssessment);
