import { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { useService } from '@xstate/react';

const useAppService = () => {
  const app = useContext(AppContext);
  const [state, send, service] = useService(app);

  const appApi = {
    auth: {
      loginBegin: () => send('AUTH_BEGIN'),
      loginError: error => send('AUTH_ERROR', error),
      loginSuccess: session => send('AUTH_SUCCESS', session),
      logout: () => send('AUTH_LOGOUT'),
      refresh: session => state.context.auth.send('AUTH_REFRESH', session),
    },
  };

  return { state, send, service, appApi };
};

export default useAppService;
