import { toBoolean } from '@zing/neo-common/dist/lib/safe';

export const isSubmitDisabled = (isDirty = false, isValid = false, errors = null) => {
  const dirty = toBoolean(isDirty);
  const valid = toBoolean(isValid);

  if (errors && Object.keys(errors).length > 0) {
    return true;
  }

  if (dirty) {
    return !valid;
  }

  return true;
};
