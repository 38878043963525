import Header from '../Header';
import PropTypes from 'prop-types';
import React from 'react';

const DiagnosticsHeader = ({ title, children }) => (
  <Header title={title} diagnostics bordered logo={false}>
    {children}
  </Header>
);

DiagnosticsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
DiagnosticsHeader.defaultProps = {
  children: null,
};
export default DiagnosticsHeader;
