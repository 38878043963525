/**
 * Ensure we clean out any old comparisons before beginning a journey,
 *
 * @bug UKSQA-1010
 *
 * @important
 * Old sessions that are restarted which contain batteries that no longer exist in comparison packages
 * "Not the primary survey", do not update when we load new battery data and then break the battery survey tab
 * very important we remove any comparisons when requoting..
 *
 * @param session
 */
import { getArray } from '@zing/neo-common/dist/lib/safe';
import cloneDeep from 'lodash.clonedeep';

// L000321 test example
export const cleanSessionBeforeRequote = session => {
  const packages = getArray(session, 'survey.packages');
  if (packages.length <= 1) {
    return session;
  }
  const cleaned = cloneDeep(session);
  cleaned.survey.packages = [packages[0]];
  return cleaned;
};
