import React from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { Required } from '@zing/neo-common/dist/components/Required';
import PropTypes from 'prop-types';

export const LeftField = ({ title, value, testId, bordered, isRequired = false }) => {
  const borderless = bordered ? '' : 'borderless';
  return (
    <div className={`opportunity-details-row ${borderless}`}>
      <Grid gutters="sm">
        <Col width="4">
          <strong>
            {title}
            {isRequired && <Required testId={testId} />}
          </strong>
        </Col>
        <Col data-testid={testId}>{value}</Col>
      </Grid>
    </div>
  );
};

LeftField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  testId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  bordered: PropTypes.bool,
};

LeftField.defaultProps = {
  isRequired: false,
  bordered: true,
};
