import * as types from './actionTypes';
import initialState from './initialState';

import updateObjectById from '../../lib/updateObjectById';
import { commitNotifications } from './accessor';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;
    case types.RESET_NOTIFICATIONS:
      return initialState;
    case types.NOTIFICATIONS_REQUEST:
      return {
        ...state,
        processing: true,
        errors: null,
      };
    case types.NOTIFICATIONS_COMMIT:
      return {
        ...state,
        processing: false,
        errors: null,
        notifications: commitNotifications(action.payload),
      };
    case types.NOTIFICATIONS_ROLLBACK:
      return {
        ...state,
        processing: false,
        errors: action.payload.errors,
      };
    case types.NOTIFICATION_READ_COMMIT:
      return {
        ...state,
        notifications: commitNotifications(
          updateObjectById(state.notifications, action.id, {
            marked_as_read: new Date().toISOString(),
          })
        ),
      };
    case types.NOTIFICATION_READ_ROLLBACK:
      return {
        ...state,
        notifications: commitNotifications(
          updateObjectById(state.notifications, action.id, {
            marked_as_read: null,
          })
        ),
      };
    case types.NOTIFICATION_READ:
      // optimistic UI update
      return {
        ...state,
        notifications: commitNotifications(
          updateObjectById(state.notifications, Number(action.commitMeta.id), {
            marked_as_read: new Date().toISOString(),
          })
        ),
      };
    default:
      return state;
  }
};
