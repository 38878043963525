import * as types from './actionTypes';
import { initialState } from './initialState';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_ALL:
      return initialState;
    case types.RESET_API_VERSION:
      return initialState;
    case types.API_VERSION_REQUEST:
      return {
        ...state,
        processing: true,
        errors: null,
      };
    case types.API_VERSION_COMMIT:
      return {
        ...state,
        processing: false,
        errors: null,
        version: action.payload.version,
      };
    case types.API_VERSION_ROLLBACK:
      return {
        ...state,
        processing: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};
