import { appointmentUrl } from '../../../../utils/opportunity';
import { withRouter } from 'react-router-dom';
import NoQuotes from './no-quotes';
import Offline from '../../components/offline';
import PipelineQuoteTable from './pipeline-quote-table';
import PropTypes from 'prop-types';
import React from 'react';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';

const DisplayQuotes = ({ api, isAdmin, isOffline, isExpanded, shaded, quotes, opportunity, history, salesConsultant }) => {
  const viewQuote = quote => {
    history.push('/pipeline/view-quote', { back: '/pipeline', quote, oppUuid: appUuid(opportunity) });
  };
  return (
    <>
      {isOffline && isExpanded && <Offline shaded={shaded} />}
      {!isOffline && isExpanded && (
        <>
          {Array.isArray(quotes) && quotes.length === 0 && <NoQuotes quotes={quotes} shaded={shaded} />}
          {Array.isArray(quotes) && quotes.length > 0 && (
            <PipelineQuoteTable
              api={api}
              isAdmin={isAdmin}
              shaded={shaded}
              quotes={quotes}
              viewQuote={viewQuote}
              requoteOpportunity={quote => {
                // does the opportunity have a survey object whose values should be loaded?
                // todo: I have requested that quotes return the meeting.app_uuid so we can find it in the meetings array
                const { meeting_id: meetingId = 0 } = quote;
                let meeting = null;
                if (Array.isArray(opportunity.meetings) && opportunity.meetings.length) {
                  meeting = opportunity.meetings.find(x => x.id === meetingId);
                }

                // now build the user from the CURRENT user!!!
                const user = {
                  email: salesConsultant?.email,
                  firstname: salesConsultant?.firstname,
                  id: salesConsultant?.id,
                  lastname: salesConsultant?.lastname,
                  mobile: salesConsultant?.mobile,
                  // eslint-disable-next-line camelcase
                  profile_image_filename: salesConsultant?.profile_image_filename,
                  telephone: salesConsultant?.telephone,
                };

                // eslint-disable-next-line camelcase
                opportunity.meetingId = meeting?.app_uuid;

                history.push(appointmentUrl(opportunity, 'start-survey'), {
                  mode: 'requote',
                  quote,
                  user,
                  opportunity,
                  meeting,
                });
              }}
            />
          )}
        </>
      )}
    </>
  );
};

DisplayQuotes.propTypes = {
  api: PropTypes.shape({
    setAsSigned: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  opportunity: PropTypes.shape({
    meetingId: PropTypes.string,
    meetings: PropTypes.shape([
      {
        app_uuid: PropTypes.string.isRequired,
      },
    ]).isRequired,
  }).isRequired,
  quotes: PropTypes.shape([]).isRequired,
  salesConsultant: PropTypes.shape({
    profile_image_filename: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    id: PropTypes.string,
    mobile: PropTypes.string,
    telephone: PropTypes.string,
  }).isRequired,
  shaded: PropTypes.bool.isRequired,
};

export default withRouter(DisplayQuotes);
