import { AccountAddress } from '@zing/neo-common/dist/components/AccountAddress';
import { appointmentUrl } from '../../utils/opportunity';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { get } from '@zing/neo-common/dist/lib/safe';
import { Link } from 'react-router-dom';
import accessors from '@zing/neo-common/dist/accessors';
import { Cancelled } from '../chips';
import Icon from '@zing/neo-common/dist/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

import './Opportunity.scss';

export const Opportunity = ({ opportunity, whenUniqueId }) => {
  const uuid = `opportunity_${appUuid(opportunity)}`;
  const meetingId = get(opportunity, 'meetingId', 0);
  const o = accessors.opportunityAccessor(opportunity);
  const meeting = o.findMeeting(meetingId);
  const m = accessors.meetingAccessor(meeting);

  const pathname = appointmentUrl(opportunity);

  return (
    <Link to={{ pathname, state: { whenUniqueId } }} className="opportunity" id={uuid} data-testid={uuid}>
      <Grid>
        <Col valign="center">
          <Grid gutters="xs">
            <Col width="auto">
              <Icon icon="clock" className="eon-turquoise" />
            </Col>
            {m.isTelephoneMeeting() && (
              <Col>
                <h3 className="flush opportunity_time">{o.time()}</h3>
                <p className="light">&nbsp;</p>
                <p className="light">{o.type()}</p>

                {m.isCancelled() && (
                  <p>
                    <Cancelled />
                  </p>
                )}
                {!m.isCancelled() && <p className="light">Telephone</p>}
              </Col>
            )}
            {!m.isTelephoneMeeting() && (
              <Col>
                <h3 className="flush opportunity_time">{o.time()}</h3>
                <p className="light">{m.driveTime()}</p>
                <p className="light">{o.type()}</p>

                {m.isCancelled() && (
                  <p>
                    <Cancelled />
                  </p>
                )}
                {!m.isCancelled() && <p className="light">Face to face</p>}
              </Col>
            )}
          </Grid>
        </Col>
        <Col>
          <AccountAddress
            houseNumber={o.houseNumber()}
            addressLine1={o.addressLine1()}
            addressLine2={o.addressLine2()}
            city={o.city()}
            postcode={o.postcode()}
          />
        </Col>
        <Col>
          <p>{o.customerName()}</p>
          <p>{o.mobile()}</p>
        </Col>
        <Col valign="top">
          <p>{m.salesConsultantDisplayName()}</p>
          <p>{o.salesFocus()}</p>
          <p>{o.lNumber()}</p>
          <p>{o.leadCategoryFriendly()}</p>
        </Col>
      </Grid>
    </Link>
  );
};

Opportunity.propTypes = {
  opportunity: PropTypes.shape({}).isRequired,
  whenUniqueId: PropTypes.string.isRequired,
};
