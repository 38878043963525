import { api } from '@zing/neo-common/dist/lib/api';
import { get } from '@zing/neo-common/dist/lib/safe';
import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';
import { PRESENTER } from './actionTypes';

export const fetchPresenter = () =>
  offlineRequestAction({
    type: PRESENTER,
    url: '/presenter',
  });

export const fetchPresenterAsPromise = () => (dispatch, getState) =>
  // eslint-disable-next-line
  new Promise((resolve, reject) => {
    return api({
      accessToken: getState().auth.access_token || '',
      method: 'GET',
      url: '/presenter',
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        const response = get(error, 'response', null);
        reject(response);
      });
  });
