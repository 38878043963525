import React, { useContext } from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { toFixed } from '@zing/neo-common/dist/lib/safe';
import { PimSurveyMachineContext } from '../../../contexts/survey-context';

const SystemTotals = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;
  const { totalPanels, systemTotalPeakDcPower, systemTotalInstallPvKwh, totalSpecificYieldKwhKwp } = current.context.comparisons[
    currentComparison
  ];

  return (
    <div className="system-totals">
      <Grid gutters="sm" valign="center">
        <Col>
          <h2>System totals</h2>
        </Col>
      </Grid>
      <Grid gutters="sm">
        <Col>
          <p className="tight">Total panels</p>
          <h3 data-testid="totalPanels">{totalPanels}</h3>
        </Col>
        <Col>
          <p className="tight">Total rated power</p>
          <h3 data-testid="totalSpecificYieldKwhKwp">{toFixed(totalSpecificYieldKwhKwp)} kWp</h3>
        </Col>
        <Col>
          <p className="tight">Total yield (per annum)</p>
          <h3 data-testid="systemTotalInstallPvKwh">{toFixed(systemTotalInstallPvKwh, 0)} kWh</h3>
        </Col>
        <Col>
          <p className="tight">Peak DC power</p>
          <h3 data-testid="systemTotalPeakDcPower">{toFixed(systemTotalPeakDcPower)}</h3>
        </Col>
      </Grid>
    </div>
  );
};

export default SystemTotals;
