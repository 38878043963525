import React from 'react';
import DiagnosticsHeader from '../../../../components/diagnostics-header';
import PropTypes from 'prop-types';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { useMachine } from '@xstate/react';
import mscMachine from './machine';

// import SELF_CONSUMPTION_PV from '../../../../utils/self-consumption-pv.json';
// import SELF_CONSUMPTION_BATTERY from '../../../../utils/self-consumption-battery.json';
import SELF_CONSUMPTION_PV from '../../../../utils/self-consumption-data/self-consumption-pv--2022-12-16.json';
import SELF_CONSUMPTION_BATTERY from '../../../../utils/self-consumption-data/self-consumption-battery--2022-12-16.json';
import './mcs.scss';

const annualElectricityConsumptions = Object.keys(SELF_CONSUMPTION_BATTERY[0]);

const makeClampedSection = value => `${value} kWh to ${Number(value) + 299} kWh`;
const numberAsPercentage = value => `${(value * 100).toFixed(2)}%`;

const McsDataTable = ({ pvValues, batteryValues, outDuringDay, annualElectricityConsumption }) => {
  const columns = Object.keys(batteryValues);
  const keys = Object.keys(batteryValues[columns[0]]);

  return (
    <table className="pvTable">
      <thead>
        <tr>
          <th className="occupancyHeader" colSpan={2 + columns.length}>
            Occupancy: {Number(outDuringDay) === 1 ? 'Out during day' : 'Home all day'}. Annual electricity consumption:{' '}
            {annualElectricityConsumption} kWh to {Number(annualElectricityConsumption) + 499} kWh.
          </th>
        </tr>
        <tr>
          <th className="batterySubHeader" colSpan={2 + columns.length}>
            Battery Energy Storage Usable Capacity, kWh
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="batteryRangeHeader">&nbsp;</td>
          <td className="batteryRangeHeader">
            PV
            <br />
            Only
          </td>
          {columns.map(column => (
            <td className="batteryRangeHeader">
              ≥ {column}
              <br />
              &lt; {Number(column) + 1}
            </td>
          ))}
        </tr>
        {keys.map(key => (
          <tr>
            <td className="clampedSection">{makeClampedSection(key)}</td>
            <td className="valueP">{numberAsPercentage(pvValues[key])}</td>
            {columns.map(column => (
              <td className="valueP">{numberAsPercentage(batteryValues[column][key])}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

McsDataTable.propTypes = {
  batteryValues: PropTypes.shape([]).isRequired,
  pvValues: PropTypes.shape([]).isRequired,
  outDuringDay: PropTypes.number.isRequired,
  annualElectricityConsumption: PropTypes.string.isRequired,
};

const McsDataTables = ({ pvValues, batteryValues, outDuringDay }) =>
  annualElectricityConsumptions.map(annualElectricityConsumption => (
    <McsDataTable
      pvValues={pvValues[outDuringDay][annualElectricityConsumption]}
      batteryValues={batteryValues[outDuringDay][annualElectricityConsumption]}
      annualElectricityConsumption={annualElectricityConsumption}
      outDuringDay={outDuringDay}
    />
  ));

McsDataTables.propTypes = {
  batteryValues: PropTypes.shape([]).isRequired,
  pvValues: PropTypes.shape([]).isRequired,
  outDuringDay: PropTypes.number.isRequired,
};

const McsDataPage = () => {
  const [state, send] = useMachine(mscMachine);
  const outDuringDay = state.matches('status.outDuringDay') ? 1 : 0;

  return (
    <>
      <DiagnosticsHeader title="MCS Data" />
      <main className="diagnostics">
        <>
          <Grid gutters="sm">
            <Col width="2">
              <label htmlFor="outDuringDay">Out during day</label>
              <select
                name="outDuringDay"
                className="hollywood-select"
                onChange={event => {
                  if (Number(event.target.value) === 0) {
                    send('HOME_ALL_DAY');
                  }
                  if (Number(event.target.value) === 1) {
                    send('OUT_DURING_DAY');
                  }
                }}
              >
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </select>
            </Col>
            <Col width="4">
              <label htmlFor="annualElectricityConsumption">Annual electricity consumption</label>
              <select
                name="annualElectricityConsumption"
                className="hollywood-select"
                onChange={event => {
                  if (event.target.value !== '') {
                    send('VIEW_SINGLE');
                    send({ type: 'ANNUAL_ELECTRICITY_CONSUMPTION', value: event.target.value });
                    return;
                  }
                  send({ type: 'ANNUAL_ELECTRICITY_CONSUMPTION', value: undefined });
                  send('VIEW_ALL');
                }}
              >
                <option value="">All</option>
                {annualElectricityConsumptions.map(annualElectricityConsumption => (
                  <option
                    value={annualElectricityConsumption}
                    selected={annualElectricityConsumption === state.context.annualElectricityConsumption}
                  >
                    {annualElectricityConsumption}
                  </option>
                ))}
              </select>
            </Col>
          </Grid>
          <Grid gutters="xl">
            <Col width="12">
              {state.matches('view.single') && (
                <McsDataTable
                  pvValues={SELF_CONSUMPTION_PV[outDuringDay][state.context.annualElectricityConsumption]}
                  batteryValues={SELF_CONSUMPTION_BATTERY[outDuringDay][state.context.annualElectricityConsumption]}
                  annualElectricityConsumption={state.context.annualElectricityConsumption}
                  outDuringDay={outDuringDay}
                />
              )}
              {state.matches('view.all') && (
                <McsDataTables pvValues={SELF_CONSUMPTION_PV} batteryValues={SELF_CONSUMPTION_BATTERY} outDuringDay={outDuringDay} />
              )}
            </Col>
          </Grid>
        </>
      </main>
    </>
  );
};

export default McsDataPage;
