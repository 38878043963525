import * as Yup from 'yup';
import { MORE_THAN, REQUIRED, REQUIRED_NUMBER, REQUIRED_PRODUCT } from '../../../../components/Survey/validate/messages';

const productsSchema = Yup.object().shape({
  product: Yup.object()
    .shape({
      id: Yup.number()
        .typeError(REQUIRED_PRODUCT)
        .required(REQUIRED)
        .moreThan(0, MORE_THAN),
    })
    .required(REQUIRED_PRODUCT),
  quantity: Yup.number()
    .typeError(REQUIRED_NUMBER)
    .required(REQUIRED)
    .moreThan(0, MORE_THAN),
});

const comparisonShape = (comparison, values) => {
  const pimComparison = values?.context?.comparisons?.[comparison]?.pimComparison;

  let minMandatoryProducts = 0;
  if (pimComparison?.getIsolators()?.length > 0) {
    minMandatoryProducts += 1;
  }
  if (pimComparison?.getBirdGuards()?.length > 0) {
    minMandatoryProducts += 1;
  }
  if (pimComparison?.getSmartMeters()?.length > 0) {
    minMandatoryProducts += 1;
  }
  return Yup.object().shape({
    additionalProducts: Yup.array()
      .min(0)
      .of(productsSchema),
    mandatoryAdditionalProducts: Yup.array()
      .min(minMandatoryProducts, 'Please select all mandatory products')
      .of(productsSchema),
  });
};

const schema = (comparison, values) =>
  Yup.object().shape({
    context: Yup.object().shape({
      comparisons: Yup.object().shape({
        [comparison]: comparisonShape(comparison, values),
      }),
    }),
    value: Yup.object().shape({}),
  });

export default schema;
