import React, { useContext } from 'react';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import PropTypes from 'prop-types';
import Comments from '../comments';
import ExpectedScaffoldCost from '../expected-scaffold-cost';
import ScaffoldComplex from '../scaffold-complex';

const Scaffolding = ({ userIsAdmin }) => {
  const { current } = useContext(PimSurveyMachineContext);
  return (
    <>
      <h3>Scaffolding</h3>
      <span>If any elevation exceeds 9m in length, please refer to bespoke quote process.</span>
      <ExpectedScaffoldCost userIsAdmin={userIsAdmin} />
      <ScaffoldComplex />
      {current.context.scaffoldComplex === 'yes' ? <Comments /> : null}
    </>
  );
};

Scaffolding.propTypes = {
  userIsAdmin: PropTypes.bool.isRequired,
};

export default Scaffolding;
