import AssignTo from '../../components/assign-to/assign-to';
import { apiDate } from '@zing/neo-common/dist/lib/date';
import * as accessor from '@zing/neo-common/dist/redux/opportunity/accessor';
import classnames from 'classnames';
import NoMeetings from './no-meetings';
import PropTypes from 'prop-types';
import React from 'react';

const DisplayMeetings = ({ api, cellClasses, isExpanded, meetings, opportunity, shaded }) => {
  if (!isExpanded || !Array.isArray(meetings)) {
    return null;
  }
  if (meetings.length === 0) {
    return <NoMeetings shaded={shaded} />;
  }
  return (
    <div className="pipeline_quote_table" data-testid="pipeline_quote_table">
      {meetings.map(meeting => (
        <div className={classnames('pipeline_table_row', 'pipeline_table_quote_row', shaded ? 'shaded' : '')}>
          <div className={classnames('pipeline_table_cell', 'tab_col')}>&nbsp;</div>
          <div className="pipeline_table_cell date_col" data-testid="created">
            {apiDate(meeting.when)}
          </div>
          <div className="pipeline_table_cell quote_reference_col" data-testid="lnumber">
            ⎻
          </div>
          <div className={cellClasses(null)} data-testid="name">
            ⎻
          </div>
          <div className={cellClasses('city_col')} data-testid="city">
            ⎻
          </div>
          <div className={cellClasses('sales_consultant_col')} data-testid="sales_consultant">
            {accessor.salesConsultantDisplayName(meeting)}
          </div>
          <div className="pipeline_table_cell actions_col">
            <AssignTo api={api} opportunityAppUuid={accessor.appUuid(opportunity)} meetingAppUuid={accessor.appUuid(meeting)} />
          </div>
        </div>
      ))}
    </div>
  );
};

DisplayMeetings.propTypes = {
  api: PropTypes.shape({
    assignToMe: PropTypes.func.isRequired,
  }).isRequired,
  cellClasses: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  meetings: PropTypes.shape([]).isRequired,
  opportunity: PropTypes.shape({}).isRequired,
  shaded: PropTypes.bool.isRequired,
};

export default DisplayMeetings;
