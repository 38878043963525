import {
  appUuid,
  customerAddressLine1,
  customerCity,
  customerCountry,
  customerCounty,
  customerDisplayName,
  customerEmail,
  customerFirstName,
  customerLastName,
  customerMobile,
  customerPostcode,
  customerTelephone,
  customerTitle,
  opportunityLnumber,
} from '@zing/neo-common/dist/redux/opportunity/accessor';

import { get, getBoolean } from '@zing/neo-common/dist/lib/safe';
import { format } from 'date-fns';
import merge from 'lodash.merge';

export const dateFormat = 'DD/MM/YYYY';

export const reference = 'DummyRef';

export const makeInitialValues = opportunity => {
  const completedValues = {
    is_email_pdf_to_customer_required: true,
    agreed_g99_application_signature: false,
  };
  const completed = getBoolean(opportunity, 'g99_application');
  if (completed) {
    completedValues.is_email_pdf_to_customer_required = getBoolean(opportunity, 'g99_application.is_email_pdf_to_customer_required');
    completedValues.reference = get(opportunity, 'g99_application.reference');
    completedValues.created_at = format(new Date(get(opportunity, 'g99_application.created_at')), dateFormat);
  }
  return merge(
    {
      app_uuid: appUuid(opportunity),
      address_line_1: customerAddressLine1(opportunity),
      address_line_2: '',
      city: customerCity(opportunity),
      country: customerCountry(opportunity),
      county: customerCounty(opportunity),
      created_at: format(new Date(), dateFormat),
      email: customerEmail(opportunity),
      firstname: customerFirstName(opportunity),
      is_email_pdf_to_customer_required: false,
      lastname: customerLastName(opportunity),
      mobile: customerMobile(opportunity),
      postcode: customerPostcode(opportunity),
      reference: opportunityLnumber(opportunity) || reference,
      signature: customerDisplayName(opportunity),
      telephone: customerTelephone(opportunity),
      title: customerTitle(opportunity),
    },
    completedValues
  );
};
