import * as actionTypes from './action-types';
import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';
import { api } from '@zing/neo-common/dist/lib/api';

export function reset() {
  return { type: actionTypes.RESET_PIM };
}

export const fetchPim = () =>
  offlineRequestAction({
    type: actionTypes.PIM,
    url: '/pim',
  });

/**
 * Can only be called when online
 * Doesn't need to be queued
 *
 * @chainable
 * @returns {*}
 */
export const fetchPimAsPromise = uuid => (dispatch, getState) => {
  const state = getState();
  // eslint-disable-next-line camelcase
  const accessToken = state?.auth?.access_token;
  dispatch({ type: 'PIM_REQUEST' });

  return api({
    accessToken,
    url: '/pim',
    method: 'GET',
    params: uuid ? { uuid } : {},
  })
    .then(response => {
      // eslint-disable-next-line no-console
      dispatch({ type: 'PIM_COMMIT', payload: response.data });
      return response.data;
    })
    .catch(err => {
      dispatch({ type: 'PIM_ROLLBACK' });
      return err;
    });
};

export const importPim = ({ notes }) =>
  offlineRequestAction({
    type: 'PIM_IMPORTER',
    method: 'POST',
    url: '/product-importer/v2/plytix/import',
    payload: { notes },
  });
