import React from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Textarea from '../../hollywood-xstate/textarea';
import usePimSurveyMachineValidation from '../../contexts/survey-context';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';

const Comments = () => {
  const { current, send } = usePimSurveyMachineValidation();
  return (
    <fieldset>
      <Grid gutters="sm" valign="top">
        <Col>
          <label htmlFor="context.comments">Installation Notes</label>
        </Col>
        <Col width="fixed" className="comments-col">
          <Textarea
            name="context.comments"
            value={current.context.comments}
            onChange={(_, value) => {
              send({ type: 'COMMENTS', value });
            }}
          />
          <FieldError field="context.comments" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default Comments;
