import React from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Input from '../../hollywood-xstate/input/input';
import usePimSurveyMachine from '../../contexts/survey-context';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { limitDecimals } from '@zing/neo-common/dist/lib/number';

const ImportRate = () => {
  const { current, send } = usePimSurveyMachine();
  const { currentComparison } = current.context;

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor="importRate">Customer electricity price</label>
        </Col>
        <Col width="fixed" className="right-col">
          <Input
            type="number"
            name="importRate"
            suffix="p/kWh"
            value={String(current.context.comparisons[currentComparison].importRate)}
            onChange={(_, value) => {
              const valueToSend = limitDecimals(Math.max(0, value), 3);
              send({ type: 'IMPORT_RATE', currentComparison, value: valueToSend });
            }}
          />
          <FieldError field="importRate" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default ImportRate;
