import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DiagnosticsHeader from '../../../components/diagnostics-header';
import { api } from '@zing/neo-common/dist/lib/api';
import { VoucherCodeForm } from '../../../components/voucher-code-form';

import './voucher-codes.scss';

const VoucherCodesAddPage = ({ auth }) => {
  const history = useHistory();
  const [state, setState] = React.useState('IDLE');

  async function handleSubmit(values) {
    setState('SUBMITTING');
    try {
      await api({
        accessToken: auth.access_token,
        url: `/voucher/management/create`,
        method: 'POST',
        data: { ...values, active: values.active === '1', single_use: values.single_use === '1' },
      });
      history.push('/diagnostics/voucher-codes/list');
    } catch (error) {
      setState('ERROR');
    }
  }

  return (
    <>
      <DiagnosticsHeader title="Voucher Codes" />
      <main className="voucher-codes">
        <h1>Add new voucher code</h1>
        <VoucherCodeForm
          initialValues={{
            voucher_code: '',
            voucher_type: '',
            description: '',
            single_use: '',
            discount: '',
            available_from_date: '',
            available_to_date: '',
            active: '',
          }}
          onSubmit={values => handleSubmit(values)}
          state={state}
          error={state === 'ERROR' ? 'Failed to add voucher code. Please try again.' : null}
        />
      </main>
    </>
  );
};

VoucherCodesAddPage.propTypes = {
  auth: PropTypes.shape({
    access_token: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({ auth }) => ({ auth }))(VoucherCodesAddPage);
