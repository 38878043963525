import { toFixed, toNumber } from '@zing/neo-common/dist/lib/safe';
import { clamp } from '@zing/neo-common/dist/lib/number';

export const shadingFactor = sunpathCells => {
  /*
    This function returns the sunpath shading factor based on the number of cells selected on the Sunpath Diagram

    ORIGINAL EXCEL EXAMPLE
      =1-(C9*0.01)

    INPUTS
      sunpath_cells	= Number of cells shaded	(C9)

    OUTPUTS
      shading_factor	= Shading factor as a 2dp float from 0.00 to 1.00

    */
  const cells = clamp(toNumber(sunpathCells), 0, 84);
  return cells > 0 ? toFixed(1 - cells * 0.01) : toFixed(1);
};
