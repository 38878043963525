import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash.camelcase';

const FieldError = ({ field, testId, hasError, error }) => {
  if (hasError) {
    const useTestId = testId || camelCase(`${field}Error`);
    return (
      <span data-name={field} className="hollywood-validation-error" data-testid={useTestId}>
        {error}
      </span>
    );
  }
  return null;
};

FieldError.propTypes = {
  field: PropTypes.string.isRequired,
  testId: PropTypes.string,
  error: PropTypes.string,
  hasError: PropTypes.bool,
};

FieldError.defaultProps = {
  testId: null,
  error: null,
  hasError: false,
};

export default FieldError;
