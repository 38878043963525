import optionsListNameProdId from './options-list-name-prod-id';
import optionsListProdId from './options-list-prod-id';

export function createActiveRetiredProductsOptionList(products, labelType = 'name') {
  let mapper;
  switch (labelType) {
    case 'name':
      mapper = optionsListNameProdId;
      break;
    case 'id':
      mapper = optionsListProdId;
      break;
  }
  const active = products.filter(panel => panel.prodStatus === 'Active');
  const retired = products.filter(panel => panel.prodStatus === 'Retired');
  let options = active.map(mapper).sort((a, b) => (a.label > b.label ? 1 : -1));
  if (retired.length > 0) {
    options = options.concat({ label: 'Retired:', value: '__RETIRED__', disabled: true });
    options = options.concat(retired.map(mapper).sort((a, b) => (a.label > b.label ? 1 : -1)));
  }

  return options;
}
