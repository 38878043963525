import { api } from '@zing/neo-common/dist/lib/api';
import { Machine, sendParent, assign } from 'xstate';
import format from 'date-fns/format';

const pipelineItemMachine = Machine(
  {
    id: 'pipelineItem',
    initial: 'offline',
    context: {
      accessToken: undefined,
      opportunity: undefined,
      quotes: [],
    },
    states: {
      offline: {
        initial: 'closed',
        states: {
          closed: {
            id: 'offline.closed',
            on: {
              OPEN: 'open',
              STATUS_ONLINE: '#online.closed',
            },
          },
          open: {
            id: 'offline.open',
            on: {
              CLOSE: 'closed',
              STATUS_ONLINE: '#online.open',
            },
          },
        },
        on: {},
      },
      online: {
        initial: 'closed',
        states: {
          closed: {
            id: 'online.closed',
            on: {
              OPEN: 'open',
              STATUS_OFFLINE: '#offline.closed',
            },
          },
          open: {
            id: 'online.open',
            initial: 'fetching',
            states: {
              fetching: {
                invoke: {
                  src: 'fetchQuotes',
                  onDone: 'saveQuotes',
                  onError: 'failed',
                },
              },
              saveQuotes: {
                entry: assign({ quotes: (_, e) => e.data.data }),
                always: 'viewQuotes',
              },
              viewQuotes: {
                on: {
                  SET_AS_SIGNED: 'signQuote',
                },
              },
              failed: {
                on: {
                  RETRY: 'fetching',
                },
              },
              signQuote: {
                invoke: {
                  src: 'setAsSigned',
                  onDone: 'fetching',
                  onError: 'fetching',
                },
              },
            },
            on: {
              CLOSE: 'closed',
              STATUS_OFFLINE: '#offline.open',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      notifyParentViewQuote: () => {
        sendParent({ type: 'VIEW_QUOTE', quote: { quoteId: 1234 } });
      },
      notifyParentRequote: () => {
        sendParent({ type: 'REQUOTE', quote: { quoteId: 4321 } });
      },
    },
    services: {
      fetchQuotes: ({ accessToken, opportunity }) => {
        const url = `/opportunities/${opportunity.app_uuid}/quotes`;
        return api({ accessToken, url, method: 'GET' });
      },
      setAsSigned: ({ accessToken }, event) => {
        const now = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
        const url = `/quotes/${event.id}/agreed-by`;
        const data = {
          signed_and_agreed_at: now,
          agreed: 1,
          agreed_terms_and_conditions: 1,
          agreed_terms_and_conditions_at: now,
          agreed_cooling_off_waiver: 1,
          agreed_cooling_off_waiver_at: now,
          quote_signed_by: event.signedBy,
        };
        return api({ accessToken, url, method: 'POST', data });
      },
    },
  }
);

export default pipelineItemMachine;
