import React from 'react';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import {
  isRead,
  notificationContent,
  notificationCreatedDateTime,
  notificationCreatedDisplay,
  notificationTitle,
} from '../../redux/notifications/accessor';
import Button from 'hollywood/dist/components/Button';
import PropTypes from 'prop-types';

import './Notification.scss';

const Notification = ({ notification, markAsRead, processing }) => (
  <div className="notification" data-testid={`notification-${notification.id}`}>
    <Grid gutters="md">
      <Col>
        <h3 className="tight">{notificationTitle(notification)}</h3>
        <p>{notificationContent(notification)}</p>
        <time dateTime={notificationCreatedDateTime(notification)}>{notificationCreatedDisplay(notification)}</time>
      </Col>
      {!isRead(notification) && (
        <Col width="auto">
          <Button
            format="primary"
            size="small"
            type="button"
            onClick={markAsRead}
            loading={processing}
            testId={`mark_as_read_${notification.id}`}
          >
            Mark as read
          </Button>
        </Col>
      )}
    </Grid>
  </div>
);

Notification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  markAsRead: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default Notification;
