import React from 'react';
import { diagnosticSurveyRoutes } from './routes.map';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { Switch } from 'react-router-dom';

const SurveyRoutes = () => (
  <Switch>
    {diagnosticSurveyRoutes.map(route => (
      <PrivateRoute key={route.path} path={route.path} component={route.component} exact={!!route.exact} />
    ))}
  </Switch>
);

export default SurveyRoutes;
