import React from 'react';
import PropTypes from 'prop-types';
import * as filters from '../../../redux/opportunities/hide-opportunities';

import './opportunities-filter.scss';

const OpportunitiesFilter = ({ filter, setFilter }) => (
  <div className="opportunities_filter" data-testid="opportunities_filter">
    <button
      type="button"
      data-testid="this_week"
      className={filter === filters.FILTER_THIS_WEEK ? 'active' : ''}
      onClick={() => setFilter(filters.FILTER_THIS_WEEK)}
    >
      This week
    </button>
    <button
      type="button"
      data-testid="next_week"
      className={filter === filters.FILTER_NEXT_WEEK ? 'active' : ''}
      onClick={() => setFilter(filters.FILTER_NEXT_WEEK)}
    >
      Next week
    </button>
    <button
      type="button"
      data-testid="this_month"
      className={filter === filters.FILTER_THIS_MONTH ? 'active' : ''}
      onClick={() => setFilter(filters.FILTER_THIS_MONTH)}
    >
      This month
    </button>
    <button
      type="button"
      data-testid="all"
      className={filter === filters.FILTER_ALL ? 'active' : ''}
      onClick={() => setFilter(filters.FILTER_ALL)}
    >
      All
    </button>
  </div>
);

OpportunitiesFilter.propTypes = {
  filter: PropTypes.oneOf([filters.FILTER_THIS_WEEK, filters.FILTER_NEXT_WEEK, filters.FILTER_THIS_MONTH, filters.FILTER_ALL]).isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default OpportunitiesFilter;
