import { initialState } from './initialState';
import {
  SETTINGS_RESET,
  SETTINGS_ADD_ENCRYPTED_DATA,
  SETTINGS_ADD_UNENCRYPTED_DATA,
  SETTINGS_REFRESH_APP_DATA,
  SETTINGS_REFRESH_RESOURCES,
  SETTINGS_PROFILE_IMAGE_UPLOAD_REQUEST,
  SETTINGS_PROFILE_IMAGE_UPLOAD_COMMIT,
  SETTINGS_PROFILE_IMAGE_UPLOAD_ROLLBACK,
  SETTINGS_PROFILE_IMAGE_REMOVE_REQUEST,
  SETTINGS_PROFILE_IMAGE_REMOVE_COMMIT,
  SETTINGS_PROFILE_IMAGE_REMOVE_ROLLBACK,
  SETTINGS_UPDATE_USER_REQUEST,
  SETTINGS_UPDATE_USER_COMMIT,
  SETTINGS_UPDATE_USER_ROLLBACK,
  SETTINGS_UPDATE_EDITABLE_USER_DETAILS,
  SETTINGS_RESET_EDITABLE_USER_DETAILS,
} from './actionTypes';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';

export default function settings(state = initialState, action) {
  switch (action.type) {
    case RESET_ALL:
      return initialState;
    case SETTINGS_RESET:
      return initialState;

    case SETTINGS_ADD_ENCRYPTED_DATA:
      return {
        ...state,
        encrypted_data: action.data,
      };

    case SETTINGS_ADD_UNENCRYPTED_DATA:
      return {
        ...state,
        unencrypted_data: action.data,
      };

    case SETTINGS_REFRESH_APP_DATA:
      return {
        ...state,
        refresh_app_data: {
          ...state.refresh_app_data,
          status: action.status,
          last_updated: action.status === 'Completed' ? new Date() : state.refresh_app_data.last_updated,
        },
      };

    case SETTINGS_REFRESH_RESOURCES:
      return {
        ...state,
        refresh_resources: {
          ...state.refresh_resources,
          status: action.status,
          last_updated: action.status === 'Completed' ? new Date() : state.refresh_resources.last_updated,
        },
      };

    case SETTINGS_PROFILE_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        photo_upload: {
          status: 'Processing',
          last_updated: null,
        },
      };

    case SETTINGS_PROFILE_IMAGE_UPLOAD_ROLLBACK:
      return {
        ...state,
        photo_upload: {
          status: null,
          last_updated: null,
        },
      };

    case SETTINGS_PROFILE_IMAGE_UPLOAD_COMMIT:
      return {
        ...state,
        photo_upload: {
          status: 'Completed',
          last_updated: action.photo_uploaded_at ? new Date(action.photo_uploaded_at) : new Date(),
        },
      };

    case SETTINGS_PROFILE_IMAGE_REMOVE_REQUEST:
      return {
        ...state,
        remove_photo: {
          status: 'Processing',
          last_updated: null,
        },
      };

    case SETTINGS_PROFILE_IMAGE_REMOVE_ROLLBACK:
      return {
        ...state,
        remove_photo: {
          status: null,
          last_updated: null,
        },
      };

    case SETTINGS_PROFILE_IMAGE_REMOVE_COMMIT:
      return {
        ...state,
        remove_photo: {
          status: 'Completed',
          last_updated: new Date(),
        },
      };

    case SETTINGS_UPDATE_USER_REQUEST:
      return {
        ...state,
        update_user: {
          status: 'Processing',
          last_updated: null,
        },
      };

    case SETTINGS_UPDATE_USER_COMMIT:
      return {
        ...state,
        update_user: {
          status: 'Completed',
          last_updated: new Date(),
        },
      };

    case SETTINGS_UPDATE_USER_ROLLBACK:
      return {
        ...state,
        update_user: {
          status: null,
          last_updated: null,
        },
      };

    case SETTINGS_UPDATE_EDITABLE_USER_DETAILS:
      return {
        ...state,
        editable_user_details: {
          ...action.userDetails,
        },
      };

    case SETTINGS_RESET_EDITABLE_USER_DETAILS:
      return {
        ...state,
        editable_user_details: initialState.editable_user_details,
      };

    default:
      return state;
  }
}
