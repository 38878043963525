import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { customerTypesAllowedInApp, externalUserCustomerTypes } from '@zing/neo-common/dist/config';
import { UserContext } from '../../contexts/user-context';

const LeadCategory = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const user = useContext(UserContext);

  const leadCategoryOptions = user && user.role === 'External' ? externalUserCustomerTypes : customerTypesAllowedInApp;

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor="context.leadCategory">Customer type</label>
        </Col>
        <Col width="fixed" className="right-col">
          <Select
            name="context.leadCategory"
            options={leadCategoryOptions}
            value={current.matches('leadCategory.none') ? '' : current.context.leadCategory}
            onChange={(_, value) => {
              send({ type: 'LEAD_CATEGORY', value });
            }}
            testIdUseLabels
          />
          <FieldError field="context.leadCategory" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default LeadCategory;
