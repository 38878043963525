import React from 'react';
import PropTypes from 'prop-types';

const Back = ({ Button, Icon, text = 'Back', testId = 'back', ...props }) => (
  <Button {...props} testId={testId}>
    <Icon icon="chevron-left" /> {text}
  </Button>
);

Back.propTypes = {
  Button: PropTypes.node.isRequired,
  Icon: PropTypes.node.isRequired,
  text: PropTypes.string,
  testId: PropTypes.string,
};

Back.defaultProps = {
  text: 'Back',
  testId: 'back',
};

export default Back;
