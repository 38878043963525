import { API_VERSION, RESET_API_VERSION } from './actionTypes';
import { offlineRequestAction } from '@zing/neo-common/dist/redux/offline/offline-request-action';

export const TAG = 'Version';

export const fetchAPIVersion = () =>
  offlineRequestAction({
    type: API_VERSION,
    url: '/version',
    tag: TAG,
    removeTag: TAG,
  });

export const resetAPIVersion = () => ({ type: RESET_API_VERSION });
