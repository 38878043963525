import { SESSION_BEGIN, SESSION_RELOAD, SESSION_RESET, SESSION_UPDATE_OPPORTUNITY, SESSION_FINISH } from '../actionTypes';
import { OPPORTUNITY_APPLY_FOR_G99_COMMIT } from '@zing/neo-common/dist/redux/opportunity/actionTypes';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import merge from 'lodash.merge';
import { get } from '@zing/neo-common/dist/lib/safe';

export const initialState = null;

export const opportunity = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_FINISH:
      return initialState;

    case SESSION_RESET:
      return initialState;

    case RESET_ALL:
      return initialState;

    case SESSION_BEGIN: {
      return action.opportunity;
    }

    case SESSION_RELOAD: {
      return merge({}, get(action.session, 'opportunity', {}));
    }

    case SESSION_UPDATE_OPPORTUNITY:
      return merge({}, state, action.opportunity);

    case OPPORTUNITY_APPLY_FOR_G99_COMMIT: {
      if (action.payload.id === state.id) {
        return {
          ...state,
          g99_application: action.payload,
        };
      }
      return state;
    }

    default:
      return state;
  }
};
