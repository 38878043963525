import { useService } from '@xstate/react';
import Button from 'hollywood/dist/components/Button';
import classnames from 'classnames';
import DisplayQuotes from '../quotes/display-quotes';
import opportunityAccessor from '@zing/neo-common/dist/accessors/opportunity';
import OpportunityIcons from './opportunity-icons';
import PipelineTableRowIcon from './pipeline-table-row-icon';
import PropTypes from 'prop-types';
import React from 'react';
import useConstant from 'use-constant';

export const salesConsultantDisplayName = item => [item.firstname || '', item.lastname || ''].filter(x => String(x).length > 0).join(' ');

const PipelineTableRow = ({ isAdmin, item, salesConsultant, shaded, testId, visitOpportunity }) => {
  const [state, send] = useService(item.ref);
  const api = useConstant(() => ({
    open: () => send('OPEN'),
    close: () => send('CLOSE'),
    setAsSigned: id => send({ type: 'SET_AS_SIGNED', id, signedBy: salesConsultantDisplayName(salesConsultant) }),
  }));
  const { opportunity, quotes } = state.context;
  const o = opportunityAccessor(opportunity);

  const isExpanded = state.matches('offline.open') || state.matches('online.open');
  const isOffline = state.matches('offline');
  const cellClasses = cell => classnames('pipeline_table_cell', cell, isExpanded ? 'expanded' : null);

  return (
    <>
      <div className={classnames('pipeline_table_row', shaded ? 'shaded' : '')} data-testid={testId}>
        <div className={cellClasses('tab_col')}>
          <PipelineTableRowIcon isOffline={isOffline} isExpanded={isExpanded} api={api} />
        </div>
        <div className={cellClasses('date_col')} data-testid="created">
          {o.createdDateShort()}
        </div>
        <div className={cellClasses('id_col')} data-testid="lnumber">
          {o.lNumber()}
        </div>
        <div className={cellClasses(null)} data-testid="name">
          {o.customerName()}
        </div>
        <div className={cellClasses('city_col')} data-testid="city">
          {o.city()}
        </div>
        {!isAdmin && (
          <div className={cellClasses('focus_col')} data-testid="sales_focus">
            <OpportunityIcons api={o} />
          </div>
        )}

        <div className={cellClasses('sales_consultant_col')} data-testid="sales_consultant">
          {o.salesConsultantDisplayName()}
        </div>
        {isAdmin && (
          <div className={cellClasses('signed_agreed_col')} data-testid="signed_agreed">
            &nbsp;
          </div>
        )}
        <div className="pipeline_table_cell actions_col">
          <Button format="primary" size="small" type="button" onClick={() => visitOpportunity({ opportunity })}>
            Start New
          </Button>
        </div>
      </div>
      <DisplayQuotes
        api={api}
        isAdmin={isAdmin}
        isExpanded={isExpanded}
        isOffline={isOffline}
        opportunity={opportunity}
        quotes={quotes}
        salesConsultant={salesConsultant}
        shaded={shaded}
        visitOpportunity={visitOpportunity}
      />
    </>
  );
};

PipelineTableRow.propTypes = {
  item: PropTypes.shape({
    ref: PropTypes.shape({}).isRequired,
  }).isRequired,
  opportunity: PropTypes.shape({}).isRequired,
  salesConsultant: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
  }).isRequired,
  shaded: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  testId: PropTypes.string.isRequired,
  visitOpportunity: PropTypes.func.isRequired,
};

export default PipelineTableRow;
