export const RoofInitialState = {
  compass_bearing: '',
  name: '',
  number_of_panels: '',
  number_of_shaded_segments: 0,
  orientation_elevation: '',
  shaded_segments: [],
  shading_factor: 1,
  slope_elevation: '',
  sunpath_saved: false,
  flat_roof: 'No',
};
