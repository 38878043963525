import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { BESPOKE_SCAFFOLD_COST } from '../../../../machines/survey.machine.options';
import PropTypes from 'prop-types';
import { createActiveRetiredProductsOptionList } from '../../utils/option-list-active-retired-products';

export const filterScaffoldingOptions = option => !['SCAFF-COMPLEX-UPLIFT-01', 'BESPOKE-SCAFF-COST'].includes(option.prodId);

const ExpectedScaffoldCost = ({ userIsAdmin }) => {
  const {
    current: {
      context: { comparisons, currentComparison },
    },
    send,
  } = useContext(PimSurveyMachineContext);
  const { pimComparison } = comparisons[currentComparison];

  let options = pimComparison.getScaffolding()?.filter(filterScaffoldingOptions);
  options = createActiveRetiredProductsOptionList(options, 'id');

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor={`current.context.comparisons.${currentComparison}.expectedScaffoldCost`}>Scaffolding faces / storeys</label>
        </Col>
        <Col width="fixed" className="right-col">
          <Select
            name={`current.context.comparisons.${currentComparison}.expectedScaffoldCost`}
            options={options}
            value={comparisons[currentComparison]?.expectedScaffoldCost}
            onChange={(_, prodId) => {
              send({ type: 'EXPECTED_SCAFFOLD_COST', currentComparison, prodId });
              // Tell the machine what we have just done
              if (userIsAdmin && prodId !== BESPOKE_SCAFFOLD_COST) {
                send({ type: 'IS_SCAFFOLDING_COST_OVERRIDDEN_NO', currentComparison, prodId, value: false });
              }
            }}
            disabled={comparisons[currentComparison]?.solarPvPackage === undefined}
          />
          <FieldError field={`current.context.comparisons.${currentComparison}.expectedScaffoldCost`} />
        </Col>
      </Grid>
    </fieldset>
  );
};

ExpectedScaffoldCost.propTypes = {
  userIsAdmin: PropTypes.bool.isRequired,
};

export default ExpectedScaffoldCost;
