import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { propertyTypes } from '../../../../config';

const PropertyType = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor="context.propertyType">Type of property</label>
        </Col>
        <Col width="fixed" className="right-col">
          <Select
            name="context.propertyType"
            options={propertyTypes}
            value={current.matches('propertyType.none') ? '' : current.context.propertyType}
            onChange={(_, value) => {
              send({ type: 'PROPERTY_TYPE', value });
            }}
            testIdUseLabels
          />
          <FieldError field="context.propertyType" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default PropertyType;
