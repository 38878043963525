import { ProductBullets } from '@zing/neo-common/dist/components/Bullets/ProductBullets';
import ProductImage from '@zing/neo-common/dist/components/ProductImage';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ProductStatus from '../../components/ProductStatus';

import './ProductListTable.scss';

const ProductListTableItem = ({ product, index }) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <tr
      className={index % 2 === 1 ? 'highlight-row' : ''}
      onClick={() => {
        setShowDetail(!showDetail);
      }}
    >
      {showDetail === true && (
        <>
          <td colSpan={4}>
            <pre>{JSON.stringify(product, null, 2)}</pre>
          </td>
        </>
      )}
      {showDetail === false && (
        <>
          <td className="prodId">{product.prodId}</td>
          <td className="name">{product.name}</td>
          <td className="componentColumn">
            <ProductImage product={product} />
            <div className="productImageText">{product.image}</div>
          </td>
          <td className="bullets">
            <ProductBullets product={product} />
          </td>
          <td>
            <ProductStatus status={product.prodStatus} />
          </td>
        </>
      )}
    </tr>
  );
};
ProductListTableItem.propTypes = {
  index: PropTypes.number.isRequired,
  product: PropTypes.shape({
    prodId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    prodStatus: PropTypes.string.isRequired,
  }).isRequired,
};

export const ProductListTable = ({ products }) => (
  <table className="productList">
    <thead>
      <tr>
        <td className="subHeading">Prod ID</td>
        <td className="subHeading">Name</td>
        <td className="subHeading" width={180}>
          Component
        </td>
        <td className="subHeading">Bullets</td>
        <td className="subHeading" width={100}>
          Status
        </td>
      </tr>
    </thead>
    <tbody>
      {products.map((product, index) => (
        <ProductListTableItem product={product} index={index} />
      ))}
    </tbody>
  </table>
);

ProductListTable.propTypes = {
  products: PropTypes.shape([]).isRequired,
};
