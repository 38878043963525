import React, { useEffect } from 'react';
import BackButton from '../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import connect from 'react-redux/es/connect/connect';
import Header from '../../components/Header';
import Logo from '@zing/neo-common/dist/components/Logo';
import PropTypes from 'prop-types';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { fetchPim } from '../../redux/pim/actions';
import { fetchPresenter } from '../../redux/presenter/actions';
import { get, getArray } from '@zing/neo-common/dist/lib/safe';

import './ThankYouForMeetingUs.scss';

const ThankYouForMeetingUs = ({ fetchPim, fetchPresenter, location, pimProducts, presenter, products }) => {
  useEffect(() => {
    const relationships = getArray(pimProducts, 'relationships');
    if (!relationships.length) {
      fetchPim();
    }
  }, [pimProducts]);

  useEffect(() => {
    const packageData = get(products, 'packages_with_products', null);
    if (!packageData) {
      fetchPim();
    }
  }, [products]);

  useEffect(() => {
    if (!presenter) {
      fetchPresenter();
    }
  }, [presenter]);

  const url = location.pathname.replace('/thank-you-for-meeting-us', '');
  return (
    <>
      <Header logo={false} transparent />
      <main className="thank-you-meeting-us" data-testid="thank-you-meeting-us-page">
        <Grid>
          <Col className="image-col" />
          <Col width="fixed" className="content-col">
            <Logo inverted />
            <h1 className="range-right">
              Thank you
              <br /> for meeting
              <br /> us today
            </h1>
            <div className="actions range-right">
              <BackButton link={url} inverted />
              <Button format="primary" inverted testId="start" link={`${url}/before-we-start`}>
                Start
              </Button>
            </div>
          </Col>
        </Grid>
      </main>
    </>
  );
};

ThankYouForMeetingUs.propTypes = {
  fetchPim: PropTypes.func.isRequired,
  fetchPresenter: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  presenter: PropTypes.shape({}).isRequired,
  products: PropTypes.shape({}).isRequired,
  pimProducts: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ products, presenter, pimProducts }) => ({
  presenter: presenter.presenter || null,
  products,
  pimProducts,
});

export default connect(mapStateToProps, {
  fetchPresenter,
  fetchPim,
})(ThankYouForMeetingUs);
