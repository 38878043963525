import React, { useContext } from 'react';
// import Toggle from '../../hollywood-xstate/toggle';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import FieldError from '../../hollywood-xstate/field-error';
import { createActiveRetiredProductsOptionList } from '../../utils/option-list-active-retired-products';

const IsRosemaryOrSlateRoofPreset = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { pimComparison } = comparisons[currentComparison];

  const roofFixingOptions = createActiveRetiredProductsOptionList(pimComparison.getRoofFixings());

  return (
    <>
      {/* <fieldset>
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Slate Roof present?</p>
          </Col>
          <Col width="fixed" className="right-col">
            <Toggle
              name="value.isRosemaryOrSlateRoofPreset"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
              goodOption="no"
              badOption="yes"
              value={current.value.isRosemaryOrSlateRoofPreset}
              onChange={(_, v) => {
                if (v === 'no') {
                  send({ type: 'IS_ROSEMARY_OR_SLATE_ROOF_PRESET_NO', currentComparison, value: false });
                }
                if (v === 'yes') {
                  send({ type: 'IS_ROSEMARY_OR_SLATE_ROOF_PRESET_YES', currentComparison, value: true });
                }
              }}
            />
            <FieldError field="value.isRosemaryOrSlateRoofPreset" />
          </Col>
        </Grid>
      </fieldset> */}
      <fieldset>
        <Grid gutters="sm" valign="center">
          <Col>
            <p>Roof fixing</p>
          </Col>
          <Col width="fixed" className="right-col">
            <Select
              name={`context.comparisons.${currentComparison}.roofFixing`}
              disabled={!pimComparison.getJourney().hasSolarPV}
              value={pimComparison.getRoofFixing()?.prodId}
              options={roofFixingOptions}
              onChange={(_, prodId) => {
                if (prodId) {
                  send({ type: 'SELECT_ROOF_FIXING', currentComparison, prodId });
                } else {
                  send({ type: 'UNSELECT_ROOF_FIXING', currentComparison });
                }
              }}
            />
            <FieldError field="value.roofFixing" />
          </Col>
        </Grid>
      </fieldset>
    </>
  );
};

IsRosemaryOrSlateRoofPreset.propTypes = {};

IsRosemaryOrSlateRoofPreset.defaultProps = {};

export default IsRosemaryOrSlateRoofPreset;
