import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import './Map.scss';

const Pin = () => (
  <div className="map-pin">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 54" width="40" height="54">
      <path d="M20 54q20-22.95 20-34a20 20 0 0 0-40 0q0 11 20 34z" fill="#ea1c0a" />
      <circle cx="20" cy="20" r="15" fill="#fff" />
      <path
        fill="none"
        stroke="#ea1c0a"
        strokeWidth=".9"
        d="M15.78 22.77h2.95v2.94h-2.95zM21.74 22.77h2.95v4.38h-2.95zM14.42 12.45a.27.27 0 0 0-.25.19l-2.46 8.16a.22.22 0 0 0 0 .08.27.27 0 0 0 .27.27h16.43a.28.28 0 0 0 .18-.34l-2.46-8.16a.27.27 0 0 0-.25-.19z"
      />
      <path d="M13.55 21.15v5.79a.27.27 0 0 0 .27.27h12.53a.28.28 0 0 0 .27-.27v-5.79z" fill="none" stroke="#ea1c0a" strokeWidth=".9" />
    </svg>
  </div>
);

const Map = ({ lat, lng, zoom, testId }) => (
  <div className="map" data-testid={testId}>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyCiMi3UhQnv6qPGY0dA-F9Y1IbARoOFpQU',
      }}
      defaultCenter={{ lat, lng }}
      defaultZoom={zoom}
    >
      <Pin lat={lat} lng={lng} data-testid={testId ? `${testId}-pin` : ''} />
    </GoogleMapReact>
  </div>
);

Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number,
  testId: PropTypes.string,
};

Map.defaultProps = {
  zoom: 14,
  testId: null,
};

export default Map;
