import React from 'react';
import Button from 'hollywood/dist/components/Button';
import PropTypes from 'prop-types';

const NoResults = ({ api }) => (
  <div className="pipeline_no_results" data-testid="pipeline_no_results">
    <div className="no_results range-center">
      <p>There are no results matching your filters.</p>
      <p>
        <Button
          format="secondary"
          type="button"
          onClick={() => {
            api.reset();
          }}
          testId="no_results_reset_filters"
        >
          Reset filters
        </Button>
      </p>
    </div>
  </div>
);

NoResults.propTypes = {
  api: PropTypes.shape({
    reset: PropTypes.func.isRequired,
  }).isRequired,
};

export default NoResults;
