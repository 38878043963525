import { get } from '@zing/neo-common/dist/lib/safe';
import { CUSTOMER_DETAILS } from '../../utils/paths';
import { opportunityMatchId, opportunityMatchMeetingId } from '@zing/neo-common/dist/redux/opportunity/accessor';

export const storeCurrentUrl = (computedMatch, prevUrl, currentUrl, sessionCurrentUrl) => {
  // check if we are in a session..
  const id = opportunityMatchId(computedMatch);
  const meetingId = opportunityMatchMeetingId(computedMatch);
  const path = get(computedMatch, 'path', null);
  if (id && meetingId && path) {
    // if the path is a sub page anything other than the edit customer details path
    const parts = path.split('/');
    if (parts[4] && parts[4] === CUSTOMER_DETAILS) {
      return;
    }
    if (currentUrl !== prevUrl) {
      sessionCurrentUrl(currentUrl);
    }
  }
};
