import React, { useContext } from 'react';
import backgroundImage from './carbon-footprint-image.png';
import display from '@zing/neo-common/dist/lib/display';
import DonutChart from '../../../../components/DonutChart';
import { cashFlowFirstYear } from '../../../../utils/calculations/cashflow';
import { clamp } from '@zing/neo-common/dist/lib/number';
import { Grid, Col } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

import './your-carbon-footprint.scss';

const YourCarbonFootprint = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { calcs } = comparisons[currentComparison];

  const firstYearCashflow = cashFlowFirstYear(calcs?.cashflow);
  const currentEmissions = Number(firstYearCashflow.co2EmissionsFromGrid) * 1000;
  const projectedEmissions = clamp(Number(firstYearCashflow.co2Emissions) * 1000, 0, 100000000);

  return (
    <section className="carbon-footprint">
      <Grid>
        <Col width="fixed" className="carbon-footprint__image" style={{ backgroundImage: `url(${backgroundImage})` }} />
        <Col className="carbon-footprint__content">
          <h1 className="eon-red">How solar can reduce your carbon footprint</h1>
          <p className="lg">
            Projected CO<sub>2</sub> produced over the next year
          </p>
          <Grid gutters="md">
            <Col>
              <h3 className="range-center">Before installation</h3>
              <div className="carbon-footprint__chart grid-electricity">
                <span className="carbon-footprint__chart__text" data-testid="carbon__grid">
                  {display.number(currentEmissions, 0)} kg
                </span>
                <DonutChart percentage={100} testId="carbon__grid_donut" />
              </div>
              <p className="range-center">
                Current CO<sub>2</sub> produced
              </p>
            </Col>
            <Col>
              <h3 className="range-center">After installation</h3>
              <div className="carbon-footprint__chart solar">
                <span className="carbon-footprint__chart__text" data-testid="carbon__solar">
                  {display.number(projectedEmissions, 0)} kg
                </span>
                <DonutChart percentage={clamp((projectedEmissions / currentEmissions) * 100, 0, 100)} testId="carbon__solar_donut" />
              </div>
              <p className="range-center">
                Projected CO<sub>2</sub> produced
              </p>
            </Col>
          </Grid>
        </Col>
      </Grid>
    </section>
  );
};

YourCarbonFootprint.propTypes = {};

export default YourCarbonFootprint;
