import { isHybridBattery } from '@zing/neo-common/dist/redux/product/accessor';
import { toNumber, getNumber } from '@zing/neo-common/dist/lib/safe';
import { G99_REQUIRED_THRESHOLD } from '@zing/neo-common/dist/config';

export const highestRatingPowerKw = batteries => {
  if (Array.isArray(batteries) && batteries.length > 0) {
    return Math.max(...batteries.map(x => Number(x.powerRatingKw)), 0);
  }
  return 0;
};

const comparisonG99Calcs = (context, event, meta) => {
  if (!context.currentComparison) {
    // eslint-disable-next-line no-console
    console.warn(`g99Calcs requires a currentComparison`);
    return;
  }

  const { type } = event;
  const { currentComparison } = context;
  const { pimComparison } = context.comparisons[currentComparison];
  const journey = pimComparison.getJourney();
  const battery = pimComparison.getBattery();
  const inverter = pimComparison.getInverter();

  const actualExistingPvInverterTotalRatedOutputKw = context.existingPvInverterTotalRatedOutputKw;
  const existingGridConnectedPvOnSite =
    (type === 'EXISTING_GRID_CONNECTED_PV_ON_SITE_YES' || meta?.state?.value?.existingGridConnectedPvOnSite === 'yes') &&
    type !== 'EXISTING_GRID_CONNECTED_PV_ON_SITE_NO';

  const existingPV = existingGridConnectedPvOnSite ? actualExistingPvInverterTotalRatedOutputKw : 0;

  const hasHybridBattery = battery && isHybridBattery(battery);
  const actualProposedPv = getNumber(inverter, 'powerRatingKw');
  const proposedPV = !journey.hasSolarPV || hasHybridBattery ? 0 : actualProposedPv;
  const batteryKw = toNumber(battery ? highestRatingPowerKw([battery]) : 0);
  const totalOnSiteGeneration = Number(existingPV) + Number(proposedPV) + Number(batteryKw);
  const isG99Required = totalOnSiteGeneration > G99_REQUIRED_THRESHOLD;

  // ensure dynamic calcs available
  if (!context.comparisons[currentComparison].dynamic) {
    context.comparisons[currentComparison].dynamic = { calcs: { g99: {} } };
  }

  context.comparisons[currentComparison].dynamic.calcs.g99 = {
    actualExistingPvInverterTotalRatedOutputKw,
    actualProposedPv,
    battery: batteryKw,
    existingGridConnectedPvOnSite,
    existingPV,
    hasHybridBattery,
    isG99Required,
    proposedPV,
    totalOnSiteGeneration,
  };
};

export default comparisonG99Calcs;
