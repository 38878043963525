import { Machine, assign } from 'xstate';

const mcsMachine = Machine({
  id: 'mcsData',
  type: 'parallel',
  context: {
    annualElectricityConsumption: '1500',
  },
  states: {
    status: {
      initial: 'outDuringDay',
      states: {
        outDuringDay: {
          on: {
            HOME_ALL_DAY: {
              target: 'homeAllDay',
            },
          },
        },
        homeAllDay: {
          on: {
            OUT_DURING_DAY: {
              target: 'outDuringDay',
            },
          },
        },
      },
    },
    view: {
      initial: 'single',
      states: {
        single: {
          on: {
            VIEW_ALL: {
              target: 'all',
            },
          },
        },
        all: {
          on: {
            VIEW_SINGLE: {
              target: 'single',
            },
          },
        },
      },
    },
  },
  on: {
    ANNUAL_ELECTRICITY_CONSUMPTION: {
      actions: assign({ annualElectricityConsumption: (_, event) => event.value }),
    },
  },
});

export default mcsMachine;
