import React, { useContext } from 'react';
import backgroundImage from './costs-and-savings-image.png';
import BreakdownSectionRow from './breakdown-section-row';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { getNumber, toFixed } from '@zing/neo-common/dist/lib/safe';
import { percentageAsNumber } from '@zing/neo-common/dist/lib/number';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { pounds } from '@zing/neo-common/dist/lib/display';

import './costs-savings.scss';

const YourCostsAndSavings = () => {
  const { current } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { calcs } = comparisons[currentComparison];

  const totalCost = pounds(getNumber(calcs?.costsAndSavings, 'totalCost'));
  const returnInYearOne = toFixed(percentageAsNumber(getNumber(calcs?.costsAndSavings, 'returnInYearOne')), 1);
  const twentyFiveYearBenefit = pounds(getNumber(calcs?.costsAndSavings, 'twentyFiveYearBenefit'));
  const electricityBillSavings = pounds(getNumber(calcs?.costsAndSavings, 'electricityBillSavings'));
  const exportIncome = pounds(getNumber(calcs?.costsAndSavings, 'exportIncome'));
  const totalAnnualIncomeSavings = pounds(getNumber(calcs?.costsAndSavings, 'totalAnnualIncomeAndSavings'));
  return (
    <section className="costs-and-savings">
      <Grid>
        <Col className="breakdown">
          <h1 className="eon-red">Costs and savings</h1>
          <div className="breakdown__section total">
            <Grid>
              <Col>Total cost</Col>
              <Col width="auto" className="range-right" data-testid="costs__total_cost">
                <strong>{totalCost}</strong>
              </Col>
            </Grid>
          </div>
          <div className="breakdown__section financials">
            <h3 className="tight">Financials</h3>
            <BreakdownSectionRow title="Return in year one" value={returnInYearOne} testId="costs__return_in_year_one" suffix="%" />
            <BreakdownSectionRow title="25 year projected benefit" value={twentyFiveYearBenefit} testId="costs__twenty_five_year_benefit" />
          </div>
          <div className="breakdown__section savings">
            <h3 className="tight">Savings</h3>
            <BreakdownSectionRow title="Electricity bill savings" value={electricityBillSavings} testId="costs__electricity_bill_savings" />
            <BreakdownSectionRow title="Export income" value={exportIncome} testId="costs__export_income" />
            <BreakdownSectionRow
              title="Total annual income &amp; savings"
              value={totalAnnualIncomeSavings}
              testId="costs__total_annual_income_savings"
            />
          </div>
        </Col>
        <Col className="savings-image" style={{ backgroundImage: `url(${backgroundImage})` }} />
      </Grid>
    </section>
  );
};

YourCostsAndSavings.propTypes = {};

export default YourCostsAndSavings;
