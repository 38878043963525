import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Radios from '../../hollywood-xstate/radios/radios';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { FILTER_1_PHASE, FILTER_3_PHASE } from '@zing/neo-common/dist/redux/product/accessor';
import { PimSurveyMachineContext } from '../../contexts/survey-context';

const MeterType = () => {
  const {
    current: {
      context: { currentComparison, comparisons },
    },
    send,
  } = useContext(PimSurveyMachineContext);
  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <p>Meter type</p>
        </Col>
        <Col width="fixed" className="right-col" testId="meterType">
          <Radios
            name="meterType"
            options={[FILTER_1_PHASE, FILTER_3_PHASE]}
            value={comparisons[currentComparison].meterType}
            onChange={(_, value) => {
              send({ type: 'METER_TYPE', currentComparison, value });
            }}
          />
          <FieldError field="meterType" />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default MeterType;
