import { toNumber } from '@zing/neo-common/dist/lib/safe';
import { clamp, fixedFloat } from '@zing/neo-common/dist/lib/number';

/**
 * This function returns the Rated Power in kW of all panels for a single elevation
 *
 * ORIGINAL EXCEL EXAMPLE
 *   =INDEX(PVDetails.tb,MATCH($K$2,PVNames.list,0),MATCH($J8,PVDetails.headings,0))*K5
 *
 * INPUTS
 *   panel_kw	= kW rating of a single panel			(C16)
 *   panels		= the number of panels on THIS roof		(K6)
 *
 * OUTPUTS
 *   rated_power_kw	= total rated output of this elevation in kW
 *
 *
 * EXAMPLE DATA FROM ORIGINAL SHEET - panel_kw
 *   Energise 280				0.280
 *   Energise 300				0.300
 *   Energise 315				0.315
 *   Energise 320				0.320
 *   Optimise 280				0.280
 *   Optimise 300				0.300
 *   Optimise 315				0.315
 *   Optimise 320				0.320
 *   Nhance 260W Solis			0.260
 *   Nhance 300W Solis			0.300
 *   Nhance 260W SolarEdge		0.260
 *   Nhance 300W SolarEdge		0.300
 *
 *
 * @param panelKw
 * @param numPanels
 * @returns {number}
 */
export const roofRatedPowerKw = (panelKw, numPanels) => {
  const perPanelKw = toNumber(panelKw);
  const totalPanels = clamp(toNumber(numPanels), 1, 60);
  return perPanelKw && totalPanels ? fixedFloat(perPanelKw * totalPanels, 3) : 0;
};
