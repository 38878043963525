import React, { Fragment } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { createArrayInputOptions } from '../utils/inputs';
import camelCase from 'lodash.camelcase';

const Radio = ({ value, name, id, options, className, disabled, onChange, testId, hasError, onBlur }) => {
  const useTestId = testId || camelCase(`${name}`);
  const useId = id || name;
  return createArrayInputOptions({ options, useId, name, useTestId }).map(option => (
    <Fragment key={option.id}>
      <input
        checked={value === option.value}
        className={classnames('hollywood-radio', className, hasError ? 'invalid' : null)}
        data-testid={option.testId}
        disabled={disabled}
        id={option.id}
        name={name}
        onBlur={onBlur}
        onChange={event => {
          if (onChange) onChange(event, event.target.value);
        }}
        type="radio"
        value={option.value}
      />
      <label className="hollywood-radio-label" htmlFor={option.id}>
        {option.label}
      </label>
    </Fragment>
  ));
};

Radio.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        id: PropTypes.string,
        testId: PropTypes.string,
      }),
      PropTypes.string.isRequired,
    ])
  ).isRequired,
  testId: PropTypes.string,
  value: PropTypes.string,
};

Radio.defaultProps = {
  className: null,
  disabled: false,
  hasError: false,
  onBlur: null,
  onChange: null,
  testId: null,
  value: '',
};

export default Radio;
