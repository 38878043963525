/* eslint-disable no-unreachable */
import { ApplicantDetailsButton } from './ApplicantDetailsButton';
import { applyForG99, updateOpportunity } from '@zing/neo-common/dist/redux/opportunity/actions';
import { appointmentMatchUrl } from '../../../../utils/opportunity';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import Button from 'hollywood/dist/components/Button';
import Checkbox from 'hollywood/dist/components/forms/Checkbox/Checkbox';
import { TextField } from '@zing/neo-common/dist/components/forms/fields/TextField';
import { CustomerFields } from '../../../../components/forms/CustomerFields/CustomerFields';
import BackButton from '../../../../components/buttons/back';

import { getBoolean, getNumber } from '@zing/neo-common/dist/lib/safe';
import { makeInitialValues } from './initialValues';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import Header from '../../../../components/Header';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useMatchOpportunity from '../../../../hooks/use-match-opportunity';
import useOpportunity from '../../../../hooks/use-opportunity';
import validator from './validator';

import './G99Application.scss';
import format from 'date-fns/format';

/**
 * The only difference here is that we need to ALSO update the PimSession.opportunity details
 * with the application updates.
 */

const G99Application = ({ applyForG99, match, updateOpportunity }) => {
  const [pimSession, pimSessionSet] = useLocalStorage('pimSession');
  const [id, meetingId] = useMatchOpportunity();
  const dbOpportunity = useOpportunity(id, meetingId);
  const { opportunity } = pimSession;
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison } = current.context;

  const completed = getBoolean(dbOpportunity, 'g99_application');
  const [showApplicantDetails, setShowApplicantDetails] = useState(true);
  const [agreeProcessing, setAgreeProcessing] = useState(false);
  const [sendProcessing, setSendProcessing] = useState(false);
  const backUrl = appointmentMatchUrl(match, ['survey', 'g99']);

  const initialValues = makeInitialValues(opportunity);
  const initialTouched = Object.fromEntries(Object.entries(initialValues).map(([k]) => [k, true]));

  return (
    <>
      <Header title=" G99 Letter of Authority" />
      <main className="g99-application page" data-testid="g99-application-page">
        <Grid gutters="sm">
          <Col>
            {completed && (
              <p className="completed-message" data-testid="completed_message">
                This application was submitted on {format(new Date(opportunity.g99_application.updated_at), 'DD/MM/YYYY  hh:mm:ss')}
              </p>
            )}

            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validator}
                validateOnMount
                /* Set all fields to touched so validation errors show immediately  */
                initialTouched={initialTouched}
                onSubmit={values => {
                  // eslint-disable-next-line camelcase
                  const { created_at, ...userValues } = values; // remove created_at from form values
                  const id = getNumber(opportunity, 'id');

                  // update the opportunity (in IndexedDb via redux)
                  const updatedOpportunity = merge({}, opportunity, values);
                  updateOpportunity(id, updatedOpportunity);

                  // and in PimSession
                  const hasPostCodeChanged = pimSession.opportunity.postcode !== updatedOpportunity.postcode;
                  pimSession.opportunity = updatedOpportunity;
                  pimSessionSet(pimSession);

                  if (hasPostCodeChanged) {
                    send({ type: 'POSTCODE', currentComparison, value: updatedOpportunity.postcode || '' });
                  }

                  // OK! now make the application
                  const application = {
                    ...userValues,
                    is_email_pdf_to_customer_required: values.is_email_pdf_to_customer_required ? 'Yes' : 'No',
                  };

                  if (application.signature) {
                    setAgreeProcessing(true);
                  } else {
                    setSendProcessing(true);
                  }

                  applyForG99(id, application);
                }}
                render={({ isValid, submitForm, setFieldValue, values }) => (
                  <>
                    {/* <pre>{JSON.stringify({ errors, touched, values }, null, 2)}</pre> */}
                    <ApplicantDetailsButton showApplicantDetails={showApplicantDetails} setShowApplicantDetails={setShowApplicantDetails} />
                    <Form>
                      {showApplicantDetails && (
                        <div className="applicant-details" data-testid="applicant_details">
                          <fieldset>
                            <Grid gutters="sm" data-testid="reference-form">
                              <Col width="6">
                                <TextField name="reference" title="Reference No" testId="reference" disabled />
                              </Col>
                              <Col width="6">
                                <TextField name="created_at" title="Date" testId="date" disabled />
                              </Col>
                            </Grid>
                            <CustomerFields options={{ required: { title: false }, shown: { lnumber: false } }} />
                            <Grid gutters="sm" data-testid="address-form">
                              <Col width="6">
                                <TextField name="address_line_1" title="Address line 1" isRequired />
                                <TextField name="city" title="Town / City" isRequired />
                                <TextField name="postcode" title="Postcode" isRequired />
                              </Col>
                            </Grid>
                          </fieldset>
                        </div>
                      )}
                      <p className="declaration">
                        I, the account holder, do hereby give authority for the Appointed Party to make a G99 application and accept a
                        binding contract for the enduring conditions of connection to my Distribution Network Operator (DNO). This
                        application will be a request to the DNO for their permission for a Solar PV system to be installed at my premises
                        and connected to their network. This will allow the DNO to provide any permission or to advise me of any
                        restrictions on the size of the system to be installed. This is neither confirmation nor a contract agreeing that I
                        will be purchasing a Solar PV and/or battery system, it is merely a request to the DNO for permission should I wish
                        to install Solar PV and/or battery at my premises.
                      </p>
                      <p className="declaration">
                        I understand that my DNO has up to 45 working days (9 weeks) to respond to the grid application. And if supplied by
                        an iDNO (independent DNO) then they have 45 working days to consider the application before passing to the regional
                        DNO (who then has a further 45 working days to respond). E.ON will submit my G99 application after my property
                        technical survey has been completed and payment options agreed.
                      </p>

                      {/* REMOVED: UKSQA-1338 */}
                      {/* <div className="signature-box-container">
                        Customer signature: {customerDisplayName(opportunity)}
                        <div className="signature-box">
                          <p>e-signature functionality not yet implemented</p>
                        </div>
                      </div> */}

                      <p className="declaration">
                        <Checkbox
                          name="agreed_g99_application_signature"
                          id="agreed_g99_application_signature"
                          testId="agreed_g99_application_signature"
                          label={<>I have read and agree to the above.</>}
                        />
                      </p>

                      {!completed && (
                        <div className="actions">
                          <Button
                            format="primary"
                            type="button"
                            testId="save"
                            loading={agreeProcessing}
                            disabled={!isValid || values.agreed_g99_application_signature === false}
                            onClick={() => {
                              setFieldValue('signature', initialValues.signature);
                              submitForm();
                            }}
                          >
                            Agree and Apply
                          </Button>
                          <span>or</span>
                          <Button
                            format="tertiary"
                            type="button"
                            testId="email-to-customer"
                            loading={sendProcessing}
                            disabled={!isValid || values.agreed_g99_application_signature === true}
                            onClick={() => {
                              setFieldValue('signature', '');
                              submitForm();
                            }}
                          >
                            Email to Customer
                          </Button>
                        </div>
                      )}
                      <BackButton link={backUrl} testId="cancel_back" />
                    </Form>
                  </>
                )}
              />
            </div>
          </Col>
          <Col>
            <img src="/static/images/DNO-Map.png" alt="DNO Map" />
          </Col>
        </Grid>
      </main>
    </>
  );
};

G99Application.propTypes = {
  applyForG99: PropTypes.func.isRequired,
  // sendUnsignedG99Application: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,

  updateOpportunity: PropTypes.func.isRequired,
};

export default connect(() => ({}), { applyForG99, updateOpportunity })(G99Application);
