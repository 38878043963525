import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { showPercentSign, yesNo } from '../product-tables/utils';
import { toFixedNumber } from '@zing/neo-common/dist/lib/safe/to-fixed-number';
import { VAT_RATE1, VAT_RATE2 } from '../../../../config';
import ProductTableDataDriven from '../product-table-data-driven';
import PropTypes from 'prop-types';
import React from 'react';

const TableHead = (
  <thead>
    <tr>
      <th colSpan={5}>&nbsp;</th>
      <th colSpan={21} className="center productPricing borderRight">
        Product VAT Split
      </th>
    </tr>
    <tr>
      <th colSpan={3}>&nbsp;</th>
      <th colSpan={2} className="gs center">
        GS Ratio
      </th>
      <th className="gm center" colSpan={2}>
        Which VAT Rate
      </th>
      <th className="center perItem" colSpan={2}>
        Amount on which VAT charged
      </th>
      <th className="center perKw" colSpan={2}>
        VAT at Rate
      </th>
    </tr>
    <tr>
      <th className="left">Prod ID</th>
      <th className="center">EEM</th>
      <th className="money">ExVat</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="center gm">Goods</th>
      <th className="center gm">Services</th>
      <th className="money gs">
        1 <span className="tinyVat">({percentageAsNumber(VAT_RATE1)}%)</span>
      </th>
      <th className="money gm">
        2 <span className="tinyVat">({percentageAsNumber(VAT_RATE2)}%)</span>
      </th>
      <th className="money gs">
        1 <span className="tinyVat">({percentageAsNumber(VAT_RATE1)}%)</span>
      </th>
      <th className="money gm">
        2 <span className="tinyVat">({percentageAsNumber(VAT_RATE2)}%)</span>
      </th>
    </tr>
  </thead>
);

const rowConfig = [
  { field: 'prodId', className: 'left', pipeline: [], totals: false, totalClassName: 'noBorder' },
  { field: 'energyEfficiencyMeasure', className: 'center', pipeline: [yesNo], totals: false, totalClassName: 'noBorder' },
  { field: 'discountedExVatTotal', className: 'money', pipeline: [poundsPence], totals: false, totalClassName: 'noBorder' },
  { field: 'gsRatioGoods', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'gsRatioServices', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'whichVatRateGoods', className: 'center gm', pipeline: [toFixedNumber], totals: false },
  { field: 'whichVatRateServices', className: 'center gm', pipeline: [toFixedNumber], totals: false },
  { field: 'amountOnWhichVatRate1Charged', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'amountOnWhichVatRate2Charged', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatAtRate1', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'vatAtRate2', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
];

const ProductVatSplitsTable = ({ selectedProducts = [] }) => (
  <ProductTableDataDriven title="Product VAT Split" values={selectedProducts} rowConfig={rowConfig} TableHead={TableHead} />
);

ProductVatSplitsTable.propTypes = {
  selectedProducts: PropTypes.shape([]).isRequired,
};

export default ProductVatSplitsTable;
