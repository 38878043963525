import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { showPercentSign, yesNo } from '../product-tables/utils';
import { toFixedNumber } from '@zing/neo-common/dist/lib/safe';
import ProductTableDataDriven from '../product-table-data-driven';
import PropTypes from 'prop-types';
import React from 'react';

import './product-discounting-table.scss';

const TableHead = ({ pvArrayOutput, numberOfPanels, discountPercentage }) => (
  <thead>
    <tr>
      <th colSpan={4} width="24%">
        &nbsp;
      </th>
      <th colSpan={12} className="center productPricing">
        Discounted Ex Vat Pricing (Inc quantity) with {percentageAsNumber(discountPercentage)}% discount applied
      </th>
    </tr>
    <tr>
      <th colSpan={2}>&nbsp;</th>

      <th colSpan={2} className="gs center">
        GS Ratio
      </th>
      <th className="center perItem" colSpan={3}>
        Per item * Quantity
      </th>
      <th className="center perKw" colSpan={3}>
        Per kW * {toFixedNumber(pvArrayOutput, 2)} kW
      </th>
      <th className="center perPanel" colSpan={3}>
        Per Panel * {numberOfPanels} Panels
      </th>
      <th className="center exVatHeading" colSpan={3}>
        Summed Totals
      </th>
    </tr>
    <tr>
      <th className="left">Prod ID</th>
      <th className="center">Discountable</th>
      <th className="center gs">Goods</th>
      <th className="center gs">Services</th>

      {/* ExVat Total */}
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Total</th>

      {/* ExVat Per Kw */}
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Total</th>

      {/* ExVat Per Panel */}
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money gm">Total</th>

      {/* ExVat Per Totals */}
      <th className="money exVat">Goods</th>
      <th className="money exVat">Services</th>
      <th className="money exVat">Total</th>
    </tr>
  </thead>
);

TableHead.propTypes = {
  pvArrayOutput: PropTypes.shape([]).isRequired,
  numberOfPanels: PropTypes.number.isRequired,
  discountPercentage: PropTypes.number.isRequired,
};

const rowConfig = [
  { field: 'prodId', className: '', pipeline: [], totals: false, totalClassName: 'noBorder' },
  { field: 'discountable', className: 'center', pipeline: [yesNo], totals: false, totalClassName: 'noBorder' },
  { field: 'gsRatioGoods', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },
  { field: 'gsRatioServices', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign], totals: false },

  {
    field: 'discountedExVatPerItemTotalGoods',
    className: 'money gs',
    pipeline: [poundsPence],
    totals: true,
    totalsPipeline: [poundsPence],
  },
  {
    field: 'discountedExVatPerItemTotalServices',
    className: 'money gs',
    pipeline: [poundsPence],
    totals: true,
    totalsPipeline: [poundsPence],
  },
  { field: 'discountedExVatPerItemTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },

  { field: 'discountedExVatPerKwTotalGoods', className: 'money gs', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  {
    field: 'discountedExVatPerKwTotalServices',
    className: 'money gs',
    pipeline: [poundsPence],
    totals: true,
    totalsPipeline: [poundsPence],
  },
  { field: 'discountedExVatPerKwTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },

  {
    field: 'discountedExVatPerPanelTotalGoods',
    className: 'money gs',
    pipeline: [poundsPence],
    totals: true,
    totalsPipeline: [poundsPence],
  },
  {
    field: 'discountedExVatPerPanelTotalServices',
    className: 'money gs',
    pipeline: [poundsPence],
    totals: true,
    totalsPipeline: [poundsPence],
  },
  { field: 'discountedExVatPerPanelTotal', className: 'money gm', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'discountedExVatTotalGoods', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'discountedExVatTotalServices', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
  { field: 'discountedExVatTotal', className: 'money exVat', pipeline: [poundsPence], totals: true, totalsPipeline: [poundsPence] },
];

//
const ProductExVatDiscountingTable = ({ calcs = {}, selectedProducts = [], pvArrayOutput, numberOfPanels, discountPercentage }) => (
  <div>
    <ProductTableDataDriven
      title="Product Ex-Vat discounting"
      values={selectedProducts}
      rowConfig={rowConfig}
      TableHead={<TableHead numberOfPanels={numberOfPanels} pvArrayOutput={pvArrayOutput} discountPercentage={discountPercentage} />}
      PostTable={
        <div style={{ fontStyle: 'italic', fontSize: 'small' }}>
          60% test ratio inputs = Cost total goods: {poundsPence(calcs?.vatGsRatioTotals?.costTotalGoods)}, Discounted ex vat total:{' '}
          {poundsPence(calcs?.vatGsRatioTotals?.discountedExVatTotal)} = GS Ratio:{' '}
          {showPercentSign(toFixedNumber(percentageAsNumber(calcs?.gsRatio)))}
        </div>
      }
    />
  </div>
);

ProductExVatDiscountingTable.propTypes = {
  calcs: PropTypes.shape({}).isRequired,
  selectedProducts: PropTypes.shape([]).isRequired,
  pvArrayOutput: PropTypes.shape([]).isRequired,
  numberOfPanels: PropTypes.number.isRequired,
  discountPercentage: PropTypes.number.isRequired,
};

export default ProductExVatDiscountingTable;
