import React, { useContext } from 'react';
import Button from 'hollywood/dist/components/Button';
import FieldError from '../../hollywood-xstate/field-error';
import Icon from '@zing/neo-common/dist/components/Icon';
import NumberInput from '../../hollywood-xstate/number-input';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { getNumber } from '@zing/neo-common/dist/lib/safe/get-number';
import { createActiveRetiredProductsOptionList } from '../../utils/option-list-active-retired-products';

const MAX_NUM_ADDITIONAL_PRODUCTS = 7;

const AdditionalProducts = () => {
  const { current, send } = useContext(PimSurveyMachineContext);
  const { currentComparison, comparisons } = current.context;
  const { pimComparison, calcs = {} } = comparisons[currentComparison];

  const chosenMandatoryAdditionalProducts = [...pimComparison.getMandatoryAdditionalProducts()];
  const chosenOptionalAdditionalProducts = [...pimComparison.getAdditionalProducts()];

  const isolators = pimComparison.getIsolators();
  const birdDeterrents = pimComparison.getBirdGuards();
  const smartMeters = pimComparison.getSmartMeters();
  const allAvailableMandatoryProducts = [...isolators, ...birdDeterrents, ...smartMeters];

  let rowsTotal = 0;

  const hasUnselectedItem =
    chosenOptionalAdditionalProducts.length > 0 && chosenOptionalAdditionalProducts.some(x => x.product === undefined);

  return (
    <>
      {/* <pre>{JSON.stringify({ allAvailableMandatoryProducts }, null, 2)}</pre> */}
      {allAvailableMandatoryProducts.length > 0 && chosenMandatoryAdditionalProducts.length > 0 && (
        <>
          <div className="additional-products__headings">
            <Grid gutters="sm">
              <Col width="fixed" className="product-col">
                <strong>Required additional products</strong>
              </Col>
              <Col>
                <strong>Quantity</strong>
              </Col>
              <Col width="auto" className="range-right">
                <strong>Price</strong>
              </Col>
            </Grid>
          </div>

          {chosenMandatoryAdditionalProducts.map(row => {
            const productValuesFromCalcs = calcs?.values?.find(x => x?.product?.prodId === row?.product?.prodId);
            const priceWithVatIndividual = getNumber(productValuesFromCalcs, `priceWithVatIndividual`);
            const priceWithVatRow = getNumber(productValuesFromCalcs, `priceWithVatRow`);
            rowsTotal += priceWithVatRow;

            let label = '';
            let options = [];
            switch (row.type) {
              case 'smart-meter':
                label = 'Smart Meter';
                options = createActiveRetiredProductsOptionList(smartMeters);
                break;
              case 'isolator':
                label = 'Isolator';
                options = createActiveRetiredProductsOptionList(isolators);
                break;
              case 'bird-guard':
                label = 'Bird Deterrent';
                options = createActiveRetiredProductsOptionList(birdDeterrents);
                break;
            }

            if (options.length === 0) {
              return null;
            }

            return (
              <div className="additional-products__row" key={row?.product?.prodId}>
                <label htmlFor={`mandatoryAdditionalProducts[${row.type}]`}>
                  <strong>{label}</strong>
                </label>
                <Grid gutters="sm" valign="center">
                  <Col width="fixed" className="product-col">
                    <Select
                      name={`mandatoryAdditionalProducts[${row.type}]`}
                      value={row?.product?.prodId}
                      options={options}
                      onChange={(event, prodId) => {
                        if (prodId) {
                          send({ type: 'MANDATORY_ADDITIONAL_PRODUCT_SELECT', currentComparison, prodId, category: row.type });
                        } else {
                          send({ type: 'MANDATORY_ADDITIONAL_PRODUCT_UNSELECT', currentComparison, category: row.type });
                        }
                      }}
                    />
                    {/* <pre>{JSON.stringify(row, null, 2)}</pre> */}
                  </Col>
                  <Col width="auto">
                    <NumberInput
                      disabled
                      min={1}
                      max={100}
                      value={row?.quantity}
                      type="number"
                      name={`mandatoryAdditionalProducts[${row.type}].quantity`}
                    />
                  </Col>
                  <Col className="range-right">
                    <p className="tight light" data-testid={`priceWithVatIndividual_${row.type}`}>
                      Item price {poundsPence(priceWithVatIndividual)}
                    </p>
                    <p data-testid={`priceWithVatRow_${row.type}`}>
                      <strong>{poundsPence(priceWithVatRow)}</strong>
                    </p>
                  </Col>
                </Grid>
              </div>
            );
          })}
        </>
      )}

      <div className="additional-products__headings">
        <Grid gutters="sm">
          <Col width="fixed" className="product-col">
            <strong>Optional additional products</strong>
          </Col>
          <Col>
            <strong>Quantity</strong>
          </Col>
          <Col width="auto" className="range-right">
            <strong>Price</strong>
          </Col>
        </Grid>
      </div>

      {chosenOptionalAdditionalProducts.map((row, index) => {
        const productValuesFromCalcs = calcs?.values?.find(x => x?.product?.prodId === row?.product?.prodId);
        const priceWithVatIndividual = getNumber(productValuesFromCalcs, `priceWithVatIndividual`);
        const priceWithVatRow = getNumber(productValuesFromCalcs, `priceWithVatRow`);
        rowsTotal += priceWithVatRow;

        const options = createActiveRetiredProductsOptionList(
          // filter out any options from the dropdown that have already been selected
          pimComparison.getAddons().filter(addon => {
            // is it a selected product?
            if (
              [...chosenMandatoryAdditionalProducts, ...chosenOptionalAdditionalProducts].find(x => x?.product?.prodId === addon.prodId)
            ) {
              // is it the current row?
              return addon.prodId === row?.product?.prodId;
            }
            return true;
          })
        );

        return (
          <div className="additional-products__row" key={row?.product?.prodId}>
            <Grid gutters="sm" valign="center">
              <Col width="fixed" className="product-col">
                <Select
                  name={`additionalProducts[${index}]`}
                  value={row?.product?.prodId}
                  options={options}
                  onChange={(event, prodId) => {
                    if (prodId) {
                      send({ type: 'ADDITIONAL_PRODUCT_SELECT', currentComparison, prodId, index });
                    } else {
                      send({ type: 'ADDITIONAL_PRODUCT_UNSELECT', currentComparison, index });
                    }
                  }}
                />
              </Col>
              <Col width="auto">
                <NumberInput
                  disabled={row === undefined}
                  min={1}
                  max={100}
                  value={row?.quantity}
                  type="number"
                  name={`additionalProducts[${index}].quantity`}
                  onChange={(event, value) => {
                    const quantity = Number(value);
                    send({ type: 'ADDITIONAL_PRODUCT_QUANTITY', currentComparison, quantity, index });
                  }}
                />
                <FieldError field={`additionalProducts[${index}].quantity`} />
              </Col>

              <Col width="auto">
                <button
                  type="button"
                  data-testid={`deleteProduct${index}`}
                  onClick={() => {
                    send({ type: 'ADDITIONAL_PRODUCT_DELETE', currentComparison, index });
                  }}
                >
                  <Icon icon="trash" className="light" />
                </button>
              </Col>
              <Col className="range-right">
                <p className="tight light" data-testid={`priceWithVatIndividual_${index}`}>
                  Item price {poundsPence(priceWithVatIndividual)}
                </p>
                <p data-testid={`priceWithVatRow_${index}`}>
                  <strong>{poundsPence(priceWithVatRow)}</strong>
                </p>
              </Col>
            </Grid>
          </div>
        );
      })}

      <div className="additional-products__total">
        <Grid gutters="sm" valign="center">
          <Col width="auto">
            {chosenMandatoryAdditionalProducts.length + chosenOptionalAdditionalProducts.length < MAX_NUM_ADDITIONAL_PRODUCTS &&
              chosenOptionalAdditionalProducts.length < pimComparison.getAddons().length + chosenMandatoryAdditionalProducts.length && (
                <Button
                  testId="addProduct"
                  format="secondary"
                  name="addProductButton"
                  onClick={() => {
                    send({ type: 'ADDITIONAL_PRODUCT_ADD', currentComparison });
                  }}
                  disabled={hasUnselectedItem}
                >
                  <span className="plus">+</span> {chosenOptionalAdditionalProducts.length === 0 ? 'Add product' : 'Add another product'}
                </Button>
              )}
          </Col>
          <Col />
          <Col width="auto" className="range-right">
            <h3>Total</h3>
          </Col>
          <Col width="auto" className="range-right">
            <h3 data-testid="rows_total">{poundsPence(rowsTotal)}</h3>
          </Col>
        </Grid>
      </div>
    </>
  );
};

export default AdditionalProducts;
