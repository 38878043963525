import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { showPercentSign } from '../product-tables/utils';
import { toFixedNumber } from '@zing/neo-common/dist/lib/safe/to-fixed-number';
import ProductTableDataDriven from '../product-table-data-driven';
import PropTypes from 'prop-types';
import React from 'react';

const rowConfig = [
  { field: 'prodId', className: '', pipeline: [], totals: false, totalClassName: 'noBorder' },
  { field: 'type', className: '', pipeline: [], totals: false, totalClassName: 'noBorder' },
  { field: 'gsRatioGoods', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign] },
  { field: 'gsRatioServices', className: 'center gs', pipeline: [percentageAsNumber, toFixedNumber, showPercentSign] },

  // Cost per Item
  // Goods / Services / Total
  { field: 'costPerItemGoods', className: 'money gs blgm', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerItemServices', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerItem', className: 'money costs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },

  // Cost per kW
  // Goods / Services / Total
  { field: 'costPerKwGoods', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerKwServices', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerKw', className: 'money costs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },

  // Cost per Panel
  // Goods / Services / Total
  { field: 'costPerPanelGoods', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerPanelServices', className: 'money gs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
  { field: 'costPerPanel', className: 'money costs', pipeline: [poundsPence], totalsPipeline: [poundsPence] },
];

const TableHead = (
  <thead>
    <tr>
      <th colSpan={4}>&nbsp;</th>
      <th colSpan={12} className="center productPricing borderRight">
        Costs
      </th>
    </tr>
    <tr>
      <th colSpan={2}>&nbsp;</th>
      <th colSpan={2} className="gs center">
        GS Ratio
      </th>
      <th className="center perItem blgm" colSpan={3}>
        Per Item
      </th>
      <th className="center perKw" colSpan={3}>
        Per kW
      </th>
      <th className="center perItem" colSpan={3}>
        Per Panel
      </th>
    </tr>
    <tr>
      <th className="left">Prod ID</th>
      <th className="left">Type</th>
      <th className="center gs">Goods</th>
      <th className="center gs">Services</th>
      <th className="money gs blgm">Goods</th>
      <th className="money gs">Services</th>
      <th className="money costs">Total</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money costs">Total</th>
      <th className="money gs">Goods</th>
      <th className="money gs">Services</th>
      <th className="money costs">Total</th>
    </tr>
  </thead>
);

const ProductCostTable = ({ selectedProducts = [] }) => (
  <ProductTableDataDriven title="Product costs" values={selectedProducts} rowConfig={rowConfig} TableHead={TableHead} />
);

ProductCostTable.propTypes = {
  selectedProducts: PropTypes.shape([]).isRequired,
};

export default ProductCostTable;
