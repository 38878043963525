import { Machine } from 'xstate';
import neo from '../databases/neo';
import { unixTimestamp } from '@zing/neo-common/dist/lib/date';
import subDays from 'date-fns/sub_days';

const GDPR_CLEANUP_MILLISECONDS = 60 * 60 * 24 * 1000; // 24 hours
const DAYS_TO_CLEANSE = 180;

window.neo = neo;
const gdprMachine = Machine({
  id: 'gdpr',
  initial: 'idle',
  states: {
    idle: {
      after: {
        [GDPR_CLEANUP_MILLISECONDS]: 'enforceGdpr',
      },
    },
    enforceGdpr: {
      invoke: {
        id: 'enforceGdpr',
        src: () => {
          const cleanseDate = subDays(new Date(), DAYS_TO_CLEANSE);
          const cleanseTimestamp = unixTimestamp(cleanseDate);
          return neo.opportunities
            .where('created_at_timestamp')
            .below(cleanseTimestamp)
            .delete();
        },
        onDone: 'idle',
        onError: 'failed',
      },
    },
    failed: {
      entry: (_, e) => {
        // eslint-disable-next-line no-console
        console.error('GDPR cleanup error', e.data);
      },
      after: {
        3000: 'idle',
      },
    },
  },
});

export default gdprMachine;
