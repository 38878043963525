export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

export const NOTIFICATIONS = 'NOTIFICATIONS';
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_COMMIT = 'NOTIFICATIONS_COMMIT';
export const NOTIFICATIONS_ROLLBACK = 'NOTIFICATIONS_ROLLBACK';

export const NOTIFICATION_READ = 'NOTIFICATION_READ';
export const NOTIFICATION_READ_COMMIT = 'NOTIFICATION_READ_COMMIT';
export const NOTIFICATION_READ_ROLLBACK = 'NOTIFICATION_READ_ROLLBACK';
