import * as Yup from 'yup';
import { getMaxDiscountPercentageLimit, getMaxFixedDiscountLimit } from '../../../../config';

require('@zing/neo-common/dist/lib/yup');

const schema = (comparison, user, values) => {
  const calcs = values?.context?.comparisons?.[comparison]?.calcs;
  const maxDiscountPercentage = getMaxDiscountPercentageLimit(user);
  const maxFixedDiscountFixed = getMaxFixedDiscountLimit(user, calcs);

  return Yup.object().shape({
    context: Yup.object().shape({
      comparisons: Yup.object().shape({
        [comparison]: Yup.object().shape({
          discountType: Yup.string(),
          discountPercentage: Yup.mixed().when([`$context.comparisons.${comparison}.discountType`], {
            is: 'percentage',
            then: Yup.number().max(maxDiscountPercentage / 100),
          }),
          discountFixed: Yup.mixed().when([`$context.comparisons.${comparison}.discountType`], {
            is: 'fixed',
            then: Yup.number().max(maxFixedDiscountFixed),
          }),
        }),
      }),
    }),
  });
};

export default schema;
