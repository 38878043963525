import producePipeline from 'immer-produce-pipeline';
import { get, toFixedNumber, toNumber } from '@zing/neo-common/dist/lib/safe';

/**
 * This is important!
 * The pipeline producer in this file processes the PIM product data on the way INTO the store.
 * We only process generic values here and delete any chaff.
 *
 * The actual calcs (dependant on num panels and power rating) are done later within a survey.
 * These are one time calcs that will enforce numeracy and margin rates
 */

/**
 * Delete old fields, not required in PIM
 * @param draft
 */
export const cleanProductFields = draft => {
  delete draft.isGoods;
  delete draft.noAcInstall;
  delete draft.noBatteryInstall;
  delete draft.noHybridInstall;
  delete draft.noPvInstall;
  delete draft.noPvandbatteryInstall;
  delete draft.priceIncVat;
  delete draft.pricePerKw;
  delete draft.pricePerPanel;
  delete draft.priceWithoutVat;
};

/**
 * UKSQA-1299 - product images (multiple) from PIM
 * Pluck out the first image in product.assets and put it in product.image
 */
export const processProductImages = draft => {
  draft.image = get(draft, 'assets[0].filename', '');
};

/**
 * Ensure our product data fields are numeric
 * @param draft
 */
export const makeFieldsNumeric = draft => {
  [
    'costPerItem',
    'costPerKw',
    'costPerPanel',
    'crmProdPurchasedPriority',
    'discountable',
    'energyRatingKwh',
    'ev8M',
    'evRfid',
    'evTether',
    'gmHigh',
    'gmHigher',
    'gmLow',
    'gmResidential',
    'goodsRatio',
    'maxConnPvKw',
    'minConnPvKw',
    'optimisers',
    'powerRatingKw',
    'pricePerKw',
    'pricePerPanel',
    'taxable',
    'trackers',
    // eslint-disable-next-line array-callback-return
  ].map(fieldName => {
    draft[fieldName] = Number(draft[fieldName]);
  });
};

/**
 * Ensure that fields even if passed numbers are boolean
 * @param draft
 */
export const makeFieldsBoolean = draft => {
  // eslint-disable-next-line array-callback-return
  ['discountable', 'taxable', 'pushProductToCrm', 'showInQuote', 'energyEfficiencyMeasure', 'inRoofItem'].map(fieldName => {
    draft[fieldName] = Boolean(draft[fieldName]);
  });
};

/**
 * Calculate the goods/ services ratios
 * @param draft
 */
export const gsRatio = draft => {
  draft.gsRatioGoods = toNumber(draft.goodsRatio);
  draft.gsRatioServices = toFixedNumber(1 - toNumber(draft.gsRatioGoods), 2);
  delete draft.goodsRatio;
};

const processProductsOnImport = producePipeline([cleanProductFields, makeFieldsNumeric, makeFieldsBoolean, gsRatio, processProductImages]);

export default processProductsOnImport;
