import React from 'react';
import { LeftField } from '../OpportunityDetails/LeftField';
import { AccountAddress } from '@zing/neo-common/dist/components/AccountAddress';
import {
  customerAddressLine1,
  customerAddressLine2,
  customerCity,
  customerDisplayName,
  customerEmail,
  customerHouseNumber,
  customerMobile,
  customerPostcode,
  customerTelephone,
} from '@zing/neo-common/dist/redux/opportunity/accessor';
import PropTypes from 'prop-types';
import { Required } from '@zing/neo-common/dist/components/Required';

export const CustomerDetailsFields = ({ opportunity }) => (
  <>
    <LeftField title="Name" value={customerDisplayName(opportunity)} testId="customer-name" isRequired />
    <LeftField title="Telephone" value={customerTelephone(opportunity)} testId="telephone" isRequired />
    <LeftField title="Mobile number" value={customerMobile(opportunity)} testId="mobile" isRequired />
    <LeftField title="Email" value={customerEmail(opportunity)} testId="email" isRequired />

    <LeftField
      title={
        <>
          Address
          <Required />
        </>
      }
      value={
        <AccountAddress
          houseNumber={customerHouseNumber(opportunity)}
          addressLine1={customerAddressLine1(opportunity)}
          addressLine2={customerAddressLine2(opportunity)}
          city={customerCity(opportunity)}
          postcode={customerPostcode(opportunity)}
        />
      }
      testId="account-address"
    />
  </>
);

CustomerDetailsFields.propTypes = {
  opportunity: PropTypes.shape({}).isRequired,
};
