import { appointmentMatchUrl } from '../../utils/opportunity';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { CustomerDetailsFields } from './CustomerDetailsFields';
import { Form, Formik } from 'formik';
import { PRESENTER, PROPERTY_DETAILS, SURVEY } from '../../utils/paths';
import { validateProductsOfInterest } from './validate-products-of-interest';
import { withRouter } from 'react-router-dom';
import * as config from '@zing/neo-common/dist/config';
import Actions from './Actions';
import FieldError from 'hollywood/dist/components/forms/FieldError';
import initialState from '../../redux/pim-session/initialstate';
import loadPoiFromOpportunity from './load-poi-from-opportunity';
import PoiCheckboxes from '../../components/PoiCheckboxes';
import PropTypes from 'prop-types';
import React from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

const BeforeWeStartForm = ({ history, match, enableStartAppointment, openPowraModal, shouldShowPowra }) => {
  const [pimSession, pimSessionSet] = useLocalStorage('pimSession', initialState);
  const { opportunity } = pimSession;

  const productsOfInterest =
    Array.isArray(pimSession?.productsOfInterest) && pimSession.productsOfInterest.length > 0
      ? pimSession.productsOfInterest
      : loadPoiFromOpportunity(opportunity);

  return (
    <Formik
      initialValues={{
        products_of_interest: productsOfInterest,
        next: '',
      }}
      onSubmit={values => {
        pimSession.productsOfInterest = values.products_of_interest || [];
        pimSessionSet(pimSession);

        // Map values.next to the correct url
        const nextUrl = {
          presentation: appointmentMatchUrl(match, PRESENTER),
          survey: appointmentMatchUrl(match, [SURVEY, PROPERTY_DETAILS]),
        };

        // Only show powra if this section hasn't been completed
        // The value passed in is where to go when the powra is completed
        if (shouldShowPowra) {
          openPowraModal(nextUrl[values.next]);
          return;
        }

        // If powra is not shown, go to the next url
        if (values.next === 'presentation') {
          history.push({
            pathname: nextUrl.presentation,
            state: { nextUrl: appointmentMatchUrl(match, [SURVEY, PROPERTY_DETAILS]) },
          });
        }
        if (values.next === 'survey') {
          history.push({
            pathname: nextUrl.survey,
          });
        }
      }}
      validate={validateProductsOfInterest}
    >
      {({ submitForm, setFieldValue }) => (
        <Form>
          <Grid gutters="lg">
            <Col>
              <Grid className="page-grid" gutters="md">
                <Col width="6">
                  <h3>Confirm customer details</h3>
                  <CustomerDetailsFields opportunity={opportunity} />
                </Col>
                <Col width="6">
                  <h3>Products of interest</h3>
                  <PoiCheckboxes options={config.PRODUCTS_OF_INTEREST_LIST} />
                  <FieldError field="products_of_interest" testId="products_of_interest_error" />
                </Col>
              </Grid>
            </Col>
          </Grid>
          <Actions
            enableStartAppointment={enableStartAppointment}
            onSubmit={next => {
              setFieldValue('next', next);
              submitForm();
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

BeforeWeStartForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
  enableStartAppointment: PropTypes.func.isRequired,
  openPowraModal: PropTypes.func.isRequired,
  shouldShowPowra: PropTypes.func.isRequired,
};

export default withRouter(BeforeWeStartForm);
