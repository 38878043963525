import merge from 'lodash.merge';
import {
  SESSION_CREATE_QUOTE,
  SESSION_CREATE_QUOTE_COMMIT,
  SESSION_BEGIN,
  SESSION_FINISH,
  SESSION_RESET,
  SESSION_AGREE_QUOTE,
  SESSION_AGREE_QUOTE_COMMIT,
  SESSION_RESET_QUOTES,
  SESSION_RELOAD,
} from '../actionTypes';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import { appUuid } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { getArray } from '@zing/neo-common/dist/lib/safe';
import produce from 'immer';

export const initialState = [];

const clean = produce(draft => {
  draft.pdf_html_source = null;
});

export const cleanQuoteBeforeStoringInState = quote => clean(quote);

export const quotes = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_BEGIN: {
      return initialState;
    }

    case SESSION_RESET_QUOTES: {
      return initialState;
    }

    case SESSION_RESET:
      return initialState;

    case SESSION_FINISH:
      return initialState;

    case SESSION_RELOAD: {
      return getArray(action.session, 'quotes');
    }

    case RESET_ALL:
      return initialState;

    case SESSION_CREATE_QUOTE: {
      return [...state, cleanQuoteBeforeStoringInState(action.payload)];
    }

    case SESSION_CREATE_QUOTE_COMMIT: {
      const quotes = state;
      const idx = quotes.findIndex(quote => appUuid(quote) === appUuid(action.payload));
      if (idx > -1) {
        quotes[idx] = cleanQuoteBeforeStoringInState(action.payload);
      }
      return [...quotes];
    }

    case SESSION_AGREE_QUOTE: {
      return state;
    }

    case SESSION_AGREE_QUOTE_COMMIT: {
      const quotes = state;
      const idx = quotes.findIndex(quote => quote.id === action.quote_id);
      if (idx > -1) {
        quotes[idx] = merge({}, quotes[idx], {
          agreed_terms_and_conditions: action.agreed_terms_and_conditions,
          agreed_terms_and_conditions_at: action.agreed_terms_and_conditions_at,
          agreed_cooling_off_waiver: action.agreed_cooling_off_waiver,
          agreed_cooling_off_waiver_at: action.agreed_cooling_off_waiver_at,
          signed_and_agreed_at: action.signed_and_agreed_at,
          agreed: action.agreed,
        });
      }
      return [...quotes];
    }
    default:
      return state;
  }
};
