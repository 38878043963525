import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { opportunityMatchPackageId, opportunityMatchQuoteId } from '@zing/neo-common/dist/redux/opportunity/accessor';
import { opportunityMatchUrl } from '../../utils/opportunity';
import { ReactComponent as CardSvg } from './svgs/card.svg';
import { ReactComponent as HouseSvg } from './svgs/house.svg';
import { ReactComponent as PaperSvg } from './svgs/paper.svg';
import { ReactComponent as PylonSvg } from './svgs/pylon.svg';
import BackButton from '../../components/buttons/back';
import Button from 'hollywood/dist/components/Button';
import Header from '../../components/Header';
import Icon from '@zing/neo-common/dist/components/Icon';
import React from 'react';
import PropTypes from 'prop-types';

import './ThankYouForChoosing.scss';

export const ThankYouForChoosing = ({ match, location }) => {
  const packageId = opportunityMatchPackageId(match);
  const quoteId = opportunityMatchQuoteId(match);
  const backUrl = location?.state?.backUrl || opportunityMatchUrl(match, ['quote', packageId, quoteId]);

  return (
    <>
      <Header menuButton="inverted" logo="inverted" transparent />
      <main className="thank-you-for-choosing" data-testid="thank-you-for-choosing">
        <Grid className="intro">
          <Col className="intro-img" />
          <Col width="auto" className="headline range-right">
            <h1>
              Thank you for
              <br /> choosing E.ON
            </h1>
            <p className="lg">
              <Icon icon="email" /> You have been emailed a copy of your quote
            </p>
          </Col>
        </Grid>

        <div className="page-content">
          <h2 className="h1 eon-red">What happens next</h2>
          <ol className="next-steps">
            <li>
              <div className="step-heading">
                <h3>We arrange payment</h3>
                <CardSvg />
              </div>

              <p className="step-description">
                Once we have received your order we will call you to arrange payment. Please note during this call we will also ask for your
                permission to perform a credit check.
              </p>
            </li>
            <li>
              <div className="step-heading">
                <h3>Technical review</h3>
                <HouseSvg />
              </div>

              <p className="step-description">
                We will then begin a technical review of your requirements. Once this is complete we will arrange a date to come and install
                your system.
              </p>
            </li>
            <li>
              <div className="step-heading">
                <h3>G99 application</h3>
                <PylonSvg />
              </div>

              <p className="step-description">
                Any storage or large solar installation greater than 3.68 kW may require an application to the local electricity network
                operator (a G99 application). We will complete this on your behalf if you have signed a G99 Letter of Authority. This
                application could take up to 45 working days and we cannot book an installation date until this is complete. We will,
                however, update you as soon as the G99 is complete.
              </p>
            </li>
            <li>
              <div className="step-heading">
                <h3>Handover pack</h3>
                <PaperSvg />
              </div>

              <p className="step-description">
                Once your installation is completed and we have received your final payment, we will send you a system handover pack with
                your MCS certificate.
              </p>
            </li>
          </ol>

          <div className="range-right">
            <BackButton link={backUrl} />
            <Button format="primary" link="/opportunities" testId="end_meeting">
              End meeting
            </Button>
          </div>
        </div>
      </main>
    </>
  );
};

ThankYouForChoosing.propTypes = {
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    state: {
      backUrl: PropTypes.string,
    },
  }).isRequired,
};
