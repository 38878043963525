import React from 'react';
import PropTypes from 'prop-types';
import PipelinePage from '../../components/pipeline';
import { connect } from 'react-redux';
import { get } from '@zing/neo-common/dist/lib/safe';
import { reloadSession, startSession } from '../../redux/session/actions';
import { SalesConsultantInitialState } from '../../redux/session/reducers/initial-state/sales-consultant';

/**
 * Responsible for connecting to redux & router, passing state, actions & router history into page component
 * @param accessToken
 * @param history
 * @param offline
 * @param reloadSession
 * @param salesConsultant
 * @param startSession
 * @param timestamp
 * @returns {*}
 * @constructor
 */
const Pipeline = ({ accessToken, history, isAdmin, canSearchCustomers, reloadSession, salesConsultant, startSession }) => (
  <PipelinePage
    accessToken={accessToken}
    history={history}
    isAdmin={isAdmin}
    canSearchCustomers={canSearchCustomers}
    reloadSession={reloadSession}
    salesConsultant={salesConsultant}
    startSession={startSession}
  />
);

Pipeline.propTypes = {
  accessToken: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  canSearchCustomers: PropTypes.bool.isRequired,
  reloadSession: PropTypes.func.isRequired,
  salesConsultant: PropTypes.shape({}).isRequired,
  startSession: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, user }) => ({
  accessToken: get(auth, 'access_token'),
  isAdmin: get(user, 'user.role') === 'Admin',
  canSearchCustomers: get(user, 'user.role') !== 'External',
  salesConsultant: get(user, 'user', SalesConsultantInitialState),
});

export default connect(mapStateToProps, { reloadSession, startSession })(Pipeline);
