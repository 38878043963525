import React from 'react';
import HollywoodToggle from '../../hollywood/toggle';
import PropTypes from 'prop-types';
import useSurveyValidation from '../../contexts/survey-validation-context';

const Toggle = props => {
  const { validator } = useSurveyValidation();
  const { name, ...rest } = props;
  return <HollywoodToggle name={name} {...rest} hasError={validator.hasError(name)} />;
};

Toggle.propTypes = {
  badOption: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  goodOption: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        id: PropTypes.string,
        testId: PropTypes.string,
      }),
      PropTypes.string.isRequired,
    ])
  ).isRequired,
  testId: PropTypes.string,
  testIdUseLabels: PropTypes.bool,
  value: PropTypes.string,
};

Toggle.defaultProps = {
  badOption: null,
  className: null,
  disabled: false,
  goodOption: null,
  hasError: false,
  id: null,
  onBlur: null,
  onChange: null,
  testId: null,
  testIdUseLabels: false,
  value: '',
};

export default Toggle;
