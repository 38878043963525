import neoDb from '../databases/neo';
import { useEffect, useState } from 'react';

const useEncryptedDbReady = (callback, filter = null) => {
  const [hasNotified, hasNotifiedSet] = useState(false);

  useEffect(() => {
    if (neoDb) {
      if (!hasNotified) {
        hasNotifiedSet(true);
        callback(neoDb);
      }
    }
  }, [neoDb, filter]);
};

export default useEncryptedDbReady;
