import React, { useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import isDate from 'date-fns/is_date';
import OpportunitiesPage from './opportunities-page';
import PropTypes from 'prop-types';
import useEncryptedDbReady from '../../hooks/use-encrypted-db-ready';
import useOpportunities from '../../hooks/use-opportunities';
import useScrollToTop from '../../hooks/use-scroll-to-top';
import { fetchOpportunitiesFromDate } from '../../redux/opportunities/actions';
import { FILTER_THIS_WEEK, hideOpportunities } from '../../redux/opportunities/hide-opportunities';
import { get, getNumber } from '@zing/neo-common/dist/lib/safe';
import { groupOpportunitiesByDate, processMeetings, sortMeetings } from '../../redux/opportunities/accessor';
import { threeWeeksAgo } from '../../utils/three-weeks-ago';

import './Opportunities.scss';

const Opportunities = ({ fetchOpportunitiesFromDate, timestamp, history }) => {
  const [filter, setFilter] = useState(FILTER_THIS_WEEK);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const opportunities = useOpportunities(timestamp);

  useScrollToTop(filter); // scroll when filter changed

  useEncryptedDbReady(() => {
    fetchOpportunitiesFromDate(threeWeeksAgo());
  });

  useEncryptedDbReady(() => {
    if (isDate(filter)) {
      fetchOpportunitiesFromDate(filter, 1);
    }
  }, filter);

  // did we just come back from an opportunity?
  const whenUniqueId = get(history, 'location.state.whenUniqueId', null);
  const element = document.getElementById(whenUniqueId);
  if (whenUniqueId && element) {
    const scrollTop = getNumber(element, 'offsetTop', 0) - 100;
    window.scrollTo(0, scrollTop);
  }

  const filtered = hideOpportunities(opportunities, filter);
  const opportunitiesByDate = groupOpportunitiesByDate(sortMeetings(processMeetings(filtered)));
  const opportunityDates = Object.keys(opportunitiesByDate);

  return (
    <OpportunitiesPage
      calendarOpen={calendarOpen}
      filter={filter}
      opportunityDates={opportunityDates}
      opportunitiesByDate={opportunitiesByDate}
      setCalendarOpen={setCalendarOpen}
      setFilter={setFilter}
    />
  );
};

Opportunities.propTypes = {
  fetchOpportunitiesFromDate: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  timestamp: PropTypes.number,
};

Opportunities.defaultProps = {
  timestamp: null,
};

const mapStateToProps = ({ opportunities }) => ({
  timestamp: opportunities.timestamp,
});

export default connect(mapStateToProps, { fetchOpportunitiesFromDate })(Opportunities);
