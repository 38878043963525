import React from 'react';
import PropTypes from 'prop-types';
import HollywoodNumberInput from '../../hollywood/number-input';
import usePimSurveyMachine from '../../contexts/survey-context';
import useSurveyValidation from '../../contexts/survey-validation-context';

const NumberInput = props => {
  const { send } = usePimSurveyMachine();
  const { validator } = useSurveyValidation();
  const { name, onBlur, onFocus, onChange, ...rest } = props;
  return (
    <HollywoodNumberInput
      name={name}
      {...rest}
      hasError={validator.hasError(name)}
      onBlur={e => {
        validator.touch(name);
        send({ type: 'BLUR', name });
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={(e, v) => {
        validator.touch(name);
        if (onChange) onChange(e, v);
      }}
      onFocus={e => {
        send({ type: 'FOCUS', name });
        if (onFocus) {
          onFocus(e);
        }
      }}
    />
  );
};

NumberInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  max: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  step: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.string,
};

NumberInput.defaultProps = {
  autoFocus: false,
  className: null,
  disabled: false,
  max: null,
  min: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyPress: null,
  placeholder: null,
  step: '1',
  testId: null,
  value: null,
};

export default NumberInput;
