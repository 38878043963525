import { SESSION_BEGIN, SESSION_RELOAD, SESSION_RESET, SESSION_FINISH } from '../actionTypes';
import { RESET_ALL } from '@zing/neo-common/dist/redux/reset/actionTypes';
import { get } from '@zing/neo-common/dist/lib/safe';

export const initialState = null;

export const started = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_RESET:
      return initialState;
    case SESSION_FINISH:
      return initialState;
    case RESET_ALL:
      return initialState;
    case SESSION_BEGIN: {
      return action.started;
    }
    case SESSION_RELOAD: {
      return get(action.session, 'started');
    }

    default:
      return state;
  }
};
