import { BETA_USER_IDS, getMaxDiscountPercentageLimit, getMaxFixedDiscountLimit } from '../../../../config';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { limitDecimals } from '@zing/neo-common/dist/lib/number';
import { percentageAsNumber } from '../../../../utils/calculations/utils/utils';
import { poundsPence } from '@zing/neo-common/dist/lib/display';
import { UserContext } from '../../contexts/user-context';
import classnames from 'classnames';
import FutureCashflow from '../../components/future-cashflow';
import IconButton from '@zing/neo-common/dist/components/IconButton';
import Input from '../../hollywood-xstate/input';
import NumberInput from '../../hollywood-xstate/number-input';
import Checkbox from '../../hollywood-xstate/checkbox';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Select from '../../hollywood-xstate/select';
import { TotalComponentsPricing } from '../../components/total-components-pricing';
import { VoucherInput } from './voucher-input/index';
import { connect } from 'react-redux';

import usePimSurveyMachineValidation from '../../contexts/survey-context';

import './settings.scss';

const DiscountLabel = ({ discountCalcs }) => (
  <label htmlFor="discount">
    Discount: {discountCalcs?.isFixedDiscountDisallowed && <span className="discountError">Not allowed with mixed VAT rates.</span>}
  </label>
);

DiscountLabel.propTypes = {
  discountCalcs: PropTypes.shape({
    isFixedDiscountDisallowed: PropTypes.bool.isRequired,
  }).isRequired,
};

const Settings = ({ auth }) => {
  const { current, send } = usePimSurveyMachineValidation();
  const { currentComparison } = current.context;
  const { calcs, discountPercentage, discountType, discountFixed, discountCode } = current.context.comparisons[currentComparison];

  const user = useContext(UserContext);
  const canSeePricing = user && user.role !== 'External';
  const showDebugInfo = BETA_USER_IDS.includes(user.id || undefined) && canSeePricing;

  const maxDiscountPercentageLimit = getMaxDiscountPercentageLimit(user);
  const maxFixedDiscountLimit = getMaxFixedDiscountLimit(user, calcs);

  const energyInflationRate = current.context.energyInflationRate !== 0 ? percentageAsNumber(current.context.energyInflationRate) : '';
  const rpi = current.context.rpi !== 0 ? percentageAsNumber(current.context.rpi) : '';
  const discountPercentageDisplayValue = discountPercentage !== 0 ? percentageAsNumber(discountPercentage) : '';
  const discountFixedDisplayValue = discountFixed !== 0 ? discountFixed : '';
  const calcsValidate = true;
  // const calcsValidate = calcs?.discountCalcs?.isDiscountWithinLimit;
  // const pim = pimAccessor(pimStore);
  // const discountProducts = pim.filterByType(pim.products(), pimProductTypes.eOnDiscountOrOffer);

  return (
    <>
      <main className="survey-page settings" data-testid="settingsTab">
        <h3 className="tight">Calculation settings</h3>
        <p>Calculations changes affect all options</p>
        <Grid gutters="sm" align="right" className="inputs">
          <Col width="4">
            <label htmlFor="energyInflationRate">Electricity price inflation</label>
            <NumberInput
              name="energyInflationRate"
              prefix="%"
              min={0}
              step="0.1"
              value={energyInflationRate.toFixed(1)}
              onChange={(_, value) => {
                const valueToSend = limitDecimals(Math.max(0, value), 3);
                const actualValueSending = (valueToSend / 100).toFixed(3);
                send({ type: 'ENERGY_INFLATION_RATE', value: actualValueSending });
              }}
            />
          </Col>
          <Col width="2">&nbsp;</Col>
          <Col width="4">
            <label htmlFor="rpi">RPI (for FIT and export rate)</label>
            <NumberInput
              name="rpi"
              prefix="%"
              step="0.10"
              min={0}
              value={rpi}
              onChange={(_, value) => {
                const valueToSend = limitDecimals(Math.max(0, value), 2);
                send({ type: 'RPI', value: valueToSend / 100 });
              }}
            />
          </Col>
          <Col width="2">&nbsp;</Col>
          <Col width="4">
            <label htmlFor="exportRate">Export rate</label>
            <NumberInput
              name="exportRate"
              prefix="p/kWh"
              step="0.100"
              value={current.context.exportRate}
              onChange={(_, value) => {
                send({ type: 'EXPORT_RATE', value });
              }}
              disabled={!current.context.customExportRate}
            />
            <div style={{ marginTop: 10 }}>
              <Checkbox
                name="customExportRate"
                label="Use custom export  rate?"
                checked={current.context.customExportRate === true}
                onChange={event => send({ type: `CUSTOM_EXPORT_RATE_${event.currentTarget.checked ? 'YES' : 'NO'}` })}
              />
            </div>
          </Col>
          <Col width="8">&nbsp;</Col>
        </Grid>

        {current.matches('calculationSettings.expanded') && (
          <>
            <h3 className="tight">Discount</h3>
            <Grid gutters="sm" align="right" className="discounts">
              <Col width="4">
                <div className="form-field">
                  <label htmlFor="discountType">Type</label>
                  <Select
                    name="discountType"
                    value={discountType}
                    options={[
                      { label: 'None', value: 'none' },
                      { label: 'Percentage', value: 'percentage' },
                      { label: 'Colleague', value: 'colleague' },
                      { label: 'Fixed', value: 'fixed' },
                      { label: 'Voucher Code', value: 'code' },
                    ]}
                    onChange={(_, value) => {
                      send({ type: 'DISCOUNT_TYPE', currentComparison, value });
                    }}
                  />
                </div>
              </Col>
              <Col width="2">&nbsp;</Col>
              <Col width="4">
                {discountType === 'code' && (
                  <>
                    <VoucherInput
                      discountCode={discountCode}
                      discountFixed={discountFixed}
                      discountPercentage={discountPercentage}
                      accessToken={auth.access_token}
                      onVoucherSelect={voucher => {
                        send({ type: 'DISCOUNT_CODE', currentComparison, value: voucher });
                      }}
                    />
                    {/* <Select
                      name="discountCode"
                      value={discountCode}
                      options={discountProducts.map(product => ({ label: product.name, value: product.prodId }))}
                      onChange={(_, value) => {
                        send({ type: 'DISCOUNT_CODE', currentComparison, value });
                      }}
                    /> */}
                  </>
                )}
                {discountType === 'colleague' && (
                  <>
                    <DiscountLabel discountCalcs={calcs?.discountCalcs} />
                    <NumberInput name="discount" prefix="%" step="0.10" value={discountPercentageDisplayValue} min={15} max={15} />
                    {discountPercentageDisplayValue > 15 ? (
                      <strong className="discountError">Maximum colleague discount is 15%</strong>
                    ) : null}
                  </>
                )}
                {discountType === 'percentage' && (
                  <>
                    <DiscountLabel discountCalcs={calcs?.discountCalcs} />
                    <NumberInput
                      name="discount"
                      title="Discount"
                      prefix="%"
                      min={0}
                      max={maxDiscountPercentageLimit}
                      step="0.10"
                      value={discountPercentageDisplayValue}
                      onChange={(_, value) => {
                        const valueToSend = limitDecimals(Math.max(0, value), 2);
                        send({ type: 'DISCOUNT_PERCENTAGE', currentComparison, value: valueToSend / 100 });
                      }}
                    />
                    {discountPercentageDisplayValue > maxDiscountPercentageLimit ? (
                      <strong className="discountError">Maximum allowed discount is {maxDiscountPercentageLimit}%</strong>
                    ) : null}
                  </>
                )}
                {discountType === 'fixed' && (
                  <>
                    <DiscountLabel discountCalcs={calcs?.discountCalcs} />
                    <Input
                      type="number"
                      prefix="£"
                      name="discount"
                      min={0}
                      max={maxFixedDiscountLimit}
                      value={discountFixedDisplayValue}
                      onChange={(_, value) => {
                        const valueToSend = limitDecimals(Math.max(0, value), 2);
                        send({ type: 'DISCOUNT_FIXED', currentComparison, value: valueToSend });
                      }}
                    />
                    {discountFixedDisplayValue > maxFixedDiscountLimit ? (
                      <strong className="discountError">Maximum allowed fixed discount is {poundsPence(maxFixedDiscountLimit)}</strong>
                    ) : null}
                  </>
                )}
              </Col>
              <Col width="2">&nbsp;</Col>
              {canSeePricing && (
                <>
                  <Col width="6">
                    <span className="sm">System value (Inc VAT)</span>
                    <br />
                    <strong className="h3">{poundsPence(calcs?.totals?.incVatTotal)}</strong>
                  </Col>
                  <Col width="6">
                    <span className="sm">Discount value (Inc VAT)</span>
                    <br />
                    <strong className="h3">{poundsPence(calcs?.totals?.discounted)}</strong>
                  </Col>
                </>
              )}
            </Grid>
          </>
        )}
        <div className="range-right">
          <IconButton
            icon="more"
            className={classnames(['extend_form', current.matches('calculationSettings.expanded') ? 'inverted' : ''])}
            format="secondary"
            onClick={() => {
              if (current.matches('calculationSettings.expanded')) {
                send('CALCULATION_SETTINGS_CONTRACT');
              } else {
                send('CALCULATION_SETTINGS_EXPAND');
              }
            }}
          />
        </div>

        {showDebugInfo && (
          <Grid gutters="md">
            <Col width="12" className="surveyActions">
              <TotalComponentsPricing calcs={calcs} calcsValidate={calcsValidate} />
            </Col>
          </Grid>
        )}
      </main>

      {showDebugInfo && <FutureCashflow expanded />}
    </>
  );
};

Settings.propTypes = {
  auth: PropTypes.shape({
    access_token: PropTypes.string,
  }).isRequired,
};

export default connect(({ auth }) => ({ auth }))(Settings);
