export default {
  processing: false,
  errors: null,
  values: {
    default_energy_inflation_rate: 0.68,
    default_rpi: 0.025,
    default_grid_emission_factor: 0.23104,
    default_deposit_amount: 250,
    default_export_rate_battery_only: 16.5,
    default_export_rate_solar_pv: 25,
    default_export_rate_solar_pv_battery: 40,
  },
};
