import * as Yup from 'yup';

export const MSG_EMAIL_INVALID = 'Invalid email';
export const MSG_EMAIL_REQUIRED = 'Email is required';
export const MSG_PASSWORD_MIN_LEN = 'Password must be at least 6 characters';
export const MSG_PASSWORD_REQUIRED = 'Password is required';

export const Schema = Yup.object().shape({
  email: Yup.string()
    .email(MSG_EMAIL_INVALID)
    .required(MSG_EMAIL_REQUIRED),
  password: Yup.string()
    .min(6, MSG_PASSWORD_MIN_LEN)
    .required(MSG_PASSWORD_REQUIRED),
});
