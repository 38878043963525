import React from 'react';
import { Formik, Form } from 'formik';

import Input from 'hollywood/dist/components/forms/Input';
import Textarea from 'hollywood/dist/components/forms/Textarea';
import Checkbox from 'hollywood/dist/components/forms/Checkbox';
import CheckboxGroup from 'hollywood/dist/components/forms/CheckboxGroup';
import Radios from 'hollywood/dist/components/forms/Radios';
import Slider from 'hollywood/dist/components/forms/Slider';
import RangeSlider from 'hollywood/dist/components/forms/RangeSlider';
import Toggle from 'hollywood/dist/components/forms/Toggle';
import Select from 'hollywood/dist/components/forms/Select';
import NumberInput from 'hollywood/dist/components/forms/NumberInput';
import FieldError from 'hollywood/dist/components/forms/FieldError';

const FormComponents = () => (
  <Formik
    initialValues={{
      textInput: '',
      textarea: '',
      disabledInput: "Can't touch this!",
      checkboxSingle: false,
      prefixedInput: 69,
      checkboxGroup: ['first'],
      slider: 5,
      rangeSlider: [10, 20],
      select: '',
      number: '',
    }}
    validate={values => {
      const errors = {};
      if (!values.textInput) {
        errors.textInput = 'Please fill in this input';
      }
      if (!values.textarea) {
        errors.textarea = 'Please fill in this textarea';
      }
      if (!values.prefixedInput) {
        errors.prefixedInput = 'Please enter a value';
      }
      if (!values.suffixedInput) {
        errors.suffixedInput = 'Please enter a value';
      }
      if (!values.prefixSuffixedInput) {
        errors.prefixSuffixedInput = 'Please enter a value';
      }
      if (!values.checkboxSingle) {
        errors.checkboxSingle = 'Click me. You know you want to!';
      }
      if (!values.checkboxGroup.length) {
        errors.checkboxGroup = 'Please choose at least one option';
      }
      if (!values.radios) {
        errors.radios = 'Please choose one';
      }
      if (!values.select) {
        errors.select = 'Please choose one';
      }
      if (!values.number) {
        errors.number = 'This is required';
      }
      return errors;
    }}
    onSubmit={values => {
      // eslint-disable-next-line no-console
      console.log(values);
    }}
    render={formikProps => {
      const { values } = formikProps;
      return (
        <Form>
          <div className="form-field">
            <label htmlFor="textInput">Input</label>
            <Input type="text" name="textInput" id="textInput" />
            <FieldError field="textInput" />
          </div>

          <div className="form-field">
            <label htmlFor="textarea">Textarea</label>
            <Textarea name="textarea" id="textarea" />
            <FieldError field="textarea" />
          </div>

          <div className="form-field">
            <label htmlFor="disabledInput">Disabled Input</label>
            <Input type="text" name="disabledInput" id="disabledInput" disabled />
          </div>

          <div className="form-field">
            <label htmlFor="prefixedInput">Input with prefix</label>
            <Input type="number" name="prefixedInput" id="prefixedInput" prefix="£" />
            <FieldError field="prefixedInput" />
          </div>

          <div className="form-field">
            <label htmlFor="suffixedInput">Input with suffix</label>
            <Input type="text" name="suffixedInput" id="suffixedInput" suffix="..." />
            <FieldError field="suffixedInput" />
          </div>

          <div className="form-field">
            <label htmlFor="prefixSuffixedInput">Input with both!</label>
            <Input type="text" name="prefixSuffixedInput" id="prefixSuffixedInput" prefix="£" suffix="p/m" />
            <FieldError field="prefixSuffixedInput" />
          </div>

          <div className="form-field">
            <Checkbox name="checkboxSingle" id="checkboxSingle" label="This is a single checkbox" />
            <FieldError field="checkboxSingle" />
          </div>

          <div className="form-field">
            <label htmlFor="checkboxGroup">Checkbox group</label>
            <CheckboxGroup
              name="checkboxGroup"
              id="checkboxGroup"
              testId="checkboxGroup"
              options={[
                { label: 'First', value: 'first' },
                { label: 'Second', value: 'second' },
                { label: 'Third', value: 'third' },
              ]}
            />
            <FieldError field="checkboxGroup" />
          </div>

          <div className="form-field">
            <label htmlFor="radios">Radios</label>
            <Radios
              name="radios"
              id="radios"
              testId="radios"
              options={[
                { label: 'First', value: 'first' },
                { label: 'Second', value: 'second' },
                { label: 'Third', value: 'third' },
              ]}
            />
            <FieldError field="radios" />
          </div>

          <div className="form-field">
            <label htmlFor="slider">Slider - Current value {values.slider}</label>
            <Slider name="slider" id="slider" min={0} max={10} step={1} />
          </div>

          <div className="form-field">
            <label htmlFor="rangeSlider">
              RangeSlider - Current values [{values.rangeSlider[0]}, {values.rangeSlider[1]}]
            </label>
            <RangeSlider name="rangeSlider" id="rangeSlider" min={0} max={30} step={1} />
          </div>

          <div className="form-field">
            <label htmlFor="toggle">
              Toggle Buttons <small>(essentially different looking radios)</small>
            </label>
            <Toggle
              name="toggle"
              id="toggle"
              testId="toggle"
              options={[
                { label: 'First', value: 'first' },
                { label: 'Second', value: 'second' },
                { label: 'Third', value: 'third' },
              ]}
            />
            <FieldError field="toggle" />
          </div>

          <div className="form-field">
            <label htmlFor="select">Select</label>
            <Select name="select" id="select" testId="select" options={['One', 'Two', 'Three', 'Four', 'Five']} />
            <FieldError field="select" />
          </div>

          <div className="form-field">
            <label htmlFor="select">Number Input</label>
            <NumberInput name="number" id="number" testId="number" min={0} max={10} step={1} />
            <FieldError field="number" />
          </div>

          <button type="submit" />
          <pre>{JSON.stringify(formikProps, null, 2)}</pre>
        </Form>
      );
    }}
  />
);

export default FormComponents;
