import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import '../product-tables/product-tables.scss';

const ProductSelectionTable = ({ selectedProducts = [] }) => (
  <div style={{ marginTop: '60px', marginBottom: '60px' }}>
    <fieldset>
      <h2>Product selection</h2>
      <Grid gutters="sm" valign="center">
        <Col>
          <table className="padded_table dataDrivenProductTable">
            <thead>
              <tr>
                <th width="4%">&nbsp;</th>
                <th width="30%" className="left">
                  Prod ID
                </th>
                <th width="30%" className="left">
                  Name
                </th>
                <th className="left">Type</th>
                <th className="center">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map(item => (
                <>
                  <tr className="productLevel0">
                    <td style={{ paddingLeft: '13px' }}>&#9737;</td>
                    <td>{item.product.prodId}</td>
                    <td>{item.product.name}</td>
                    <td>{item.product.type}</td>
                    <td className="center">{item.quantity}</td>
                  </tr>
                  {Array.isArray(item.product.requires) && item.product.requires.length > 0 && (
                    <>
                      {item.product.requires.map(level1 => (
                        <>
                          <tr className="productLevel1">
                            <td style={{ paddingLeft: '16px' }}>&#8627;</td>
                            <td>{level1?.product?.prodId}</td>
                            <td>{level1?.product?.name}</td>
                            <td>{level1?.product?.type}</td>
                            <td className="center">{level1?.quantity}</td>
                          </tr>
                          {Array.isArray(level1.product.requires) && level1.product.requires.length > 0 && (
                            <>
                              {level1.product.requires.map(level2 => (
                                <tr className="productLevel2">
                                  <td style={{ paddingLeft: '32px' }}>&#8627;</td>
                                  <td>{level2?.product?.prodId}</td>
                                  <td>{level2?.product?.name}</td>
                                  <td>{level2?.product?.type}</td>
                                  <td className="center">{level2?.quantity}</td>
                                </tr>
                              ))}
                            </>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </Col>
      </Grid>
    </fieldset>
  </div>
);

ProductSelectionTable.propTypes = { selectedProducts: PropTypes.shape([]).isRequired };

export default ProductSelectionTable;
