import Dexie from 'dexie';
import { encryption } from './encryption';
import middleware from 'dexie-easy-encrypt';

const db = new Dexie('neo');

middleware({
  db,
  encryption,
  tables: ['opportunities'],
  // eslint-disable-next-line no-console
  onEasyEncryptReady: () => console.log('Encryption ready callback!'),
});

db.version(1).stores({
  opportunities: `app_uuid`,
});

db.version(2).stores({
  opportunities: `app_uuid, created_at_timestamp`,
});

export const getOpportunityByIndex = index =>
  db
    .table('opportunities')
    .toArray()
    .then(items => items[index]);

export const getOpportunityById = id =>
  db
    .table('opportunities')
    .where('app_uuid')
    .equals(id)
    .first(opportunity => opportunity);

export const getOpportunities = () =>
  db
    .table('opportunities')
    .toArray()
    .then(items => items);

export const clearOpportunities = () => db.table('opportunities').clear();

export default db;
