import * as types from './actionTypes';
import initialState from './initialState';

export const defaults = (state = initialState, action) => {
  if (action.type.startsWith('DEFAULTS')) {
    console.log(action.type, action);
  }
  switch (action.type) {
    case types.DEFAULTS_REQUEST:
      return {
        ...state,
        processing: true,
        errors: null,
      };
    case types.DEFAULTS_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: null,
        values: action.payload,
      };
    case types.DEFAULTS_ERROR:
      return {
        ...state,
        processing: false,
        errors: action.payload,
        // values: null, // clear the defaults on error when  the  API actually works
      };
    default:
      return state;
  }
};
