import React from 'react';
import AdditionalProductsComponent from '../../components/additional-products';
import ProductTables from '../../components/product-tables';
import SurveyFooter from '../../components/survey-footer';

import './additional-products.scss';

const AdditionalProducts = () => (
  <main className="survey-page additional-products" data-testid="additionalProductsTab">
    <AdditionalProductsComponent />
    <SurveyFooter />
    <ProductTables />
  </main>
);

export default AdditionalProducts;
