import PimComparison from '@zing/neo-common/dist/accessors/pim-comparison';

export const isFullPageView = pathname =>
  [
    /g99-application$/,
    /quote\/[0-9]+$/,
    /terms-and-conditions\/[0-9]+$/,
    /thank-you-for-choosing-eon\/[0-9]+$/,
    /your-package$/,
  ].some(expr => expr.test(pathname));

export const isSurveyPage = pathname => [/your-package$/, /payment-options$/, /quotes$/].every(expr => !expr.test(pathname));

export const rehydrateMachine = (pim, surveyMachine) => {
  if (!surveyMachine) {
    return surveyMachine;
  }
  ['survey', 'one', 'two', 'three'].forEach(name => {
    surveyMachine.context.comparisons[name].pimComparison = new PimComparison(pim, surveyMachine.context.comparisons[name].pimComparison);
  });
  return surveyMachine;
};
