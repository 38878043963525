import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { CustomerDetailsFields } from './CustomerDetailsFields';
import { LeftField } from '../../pages/OpportunityDetails/LeftField';
import { locationPropTypes } from '../../app-prop-types/location';
import ErrorMessage from '../ErrorMessage';
import Map from '../Map';
import OpportunityActions from '../../pages/OpportunityDetails/OpportunityActions';
import Panel from '../Panel';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import schema from '../forms/customer-details-form/validator';

import './appointment-details.scss';

const AppointmentDetails = ({ o, m, opportunity, location, startAppointmentSession, whenUniqueId, onEditDetailsClick }) => {
  const [enableStartAppointment, setEnableStartAppointment] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // is the meeting cancelled?
  useEffect(() => {
    if (m.isCancelled()) {
      setEnableStartAppointment(false);
      setErrorMessage('This meeting was cancelled, please choose another.');
    }
  }, [m.isCancelled()]);

  // validate opportunity against edit details validator..
  if (!m.isCancelled()) {
    schema.isValid(opportunity).then(result => {
      setEnableStartAppointment(result);
      if (!result) {
        setErrorMessage('Missing appointment information. Please edit details to continue.');
      }
    });
  }

  // Map must only render once... If we render it with 0 values in lat/lng, it will later fail
  const canRenderMap = o.lat() !== 0 && o.lng() !== 0;

  return (
    <main className="appointment_details shaded" data-testid="appointment_details">
      {!enableStartAppointment && <ErrorMessage testId="appointment-error">{errorMessage}</ErrorMessage>}
      <Panel>
        <Grid gutters="lg">
          <Col>
            <Grid gutters="sm" className="details_date_time">
              <Col width="4">
                <h3 data-testid="opportunity-time">{o.time()}</h3>
                {!m.isTelephoneMeeting() && <div className="meeting_detail">{m.driveTime()}</div>}
              </Col>
              <Col width="auto">
                <h3 data-testid="opportunity-date">{o.date()}</h3>
                <div className="meeting_detail">{m.isTelephoneMeeting() ? 'Telephone' : 'Face to face'}</div>
              </Col>
            </Grid>
            <CustomerDetailsFields opportunity={opportunity} />
            <LeftField title="Lead category" value={o.leadCategoryFriendly()} testId="lead_category" />
            <LeftField title="E.ON Customer" value={o.eonCustomerYesNo()} testId="eon_customer" />
            <LeftField title="Customer ID" value={o.lNumber()} testId="customer-id" />
            <LeftField title="Status" value={o.status()} testId="status" />
            <LeftField title="Meeting status" value={m.status()} testId="meeting_status" />
            <LeftField bordered={false} title="Description / Notes" value={o.description()} testId="description" />
          </Col>
          <Col>{canRenderMap && <Map lat={o.lat()} lng={o.lng()} testId="map" />}</Col>
        </Grid>
      </Panel>
      <OpportunityActions
        location={location}
        onStartClick={startAppointmentSession}
        whenUniqueId={whenUniqueId}
        enableStartAppointment={enableStartAppointment}
        meetingIsCancelled={m.isCancelled()}
        onEditDetailsClick={onEditDetailsClick}
      />
    </main>
  );
};

AppointmentDetails.defaultProps = {
  whenUniqueId: '',
  onEditDetailsClick: null,
};

AppointmentDetails.propTypes = {
  o: PropTypes.shape({
    lat: PropTypes.func.isRequired,
    lng: PropTypes.func.isRequired,
    description: PropTypes.func.isRequired,
    lNumber: PropTypes.func.isRequired,
    time: PropTypes.func.isRequired,
    date: PropTypes.func.isRequired,
    status: PropTypes.func.isRequired,
    leadCategoryFriendly: PropTypes.func.isRequired,
    eonCustomerYesNo: PropTypes.func.isRequired,
  }).isRequired,
  m: PropTypes.shape({
    driveTime: PropTypes.func.isRequired,
    isCancelled: PropTypes.func.isRequired,
    isTelephoneMeeting: PropTypes.func.isRequired,
    status: PropTypes.func.isRequired,
  }).isRequired,
  opportunity: PropTypes.shape({}).isRequired,
  startAppointmentSession: PropTypes.func.isRequired,
  location: PropTypes.shape(locationPropTypes).isRequired,
  whenUniqueId: PropTypes.string,
  onEditDetailsClick: PropTypes.func,
};

export default AppointmentDetails;
