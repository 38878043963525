import React, { useContext } from 'react';
import FieldError from '../../hollywood-xstate/field-error';
import Select from '../../hollywood-xstate/select';
import { Col, Grid } from '@zing/neo-common/dist/components/Grid';
import { PimSurveyMachineContext } from '../../contexts/survey-context';
import { UserContext } from '../../contexts/user-context';
import { createActiveRetiredProductsOptionList } from '../../utils/option-list-active-retired-products';

const SolarPackage = () => {
  const user = useContext(UserContext);
  const {
    current: {
      context: { comparisons, currentComparison },
    },
    send,
  } = useContext(PimSurveyMachineContext);
  const { pimComparison } = comparisons[currentComparison];

  const packagesOptions =
    user?.role === 'External'
      ? [{ value: 'ENERGISE-SCHEMES-PKG', label: 'Energise (Funded)' }]
      : createActiveRetiredProductsOptionList(pimComparison.getSolarPackages(), 'name');

  return (
    <fieldset>
      <Grid gutters="sm" valign="center">
        <Col>
          <label htmlFor={`context.comparisons.${currentComparison}.solarPvPackage`}>Solar package</label>
        </Col>
        <Col width="fixed" className="right-col">
          <Select
            name={`context.comparisons.${currentComparison}.solarPvPackage`}
            value={pimComparison.getPackage()?.prodId}
            options={packagesOptions}
            onChange={(_, prodId) => {
              if (prodId) {
                send({ type: 'SELECT_SOLAR_PACKAGE', currentComparison, prodId });
              } else {
                send({ type: 'UNSELECT_SOLAR_PACKAGE', currentComparison });
              }
            }}
          />
          <FieldError field={`context.comparisons.${currentComparison}.solarPvPackage`} />
        </Col>
      </Grid>
    </fieldset>
  );
};

export default SolarPackage;
