import React from 'react';
import PropTypes from 'prop-types';
import HollywoodInput from '../../hollywood/input';
import usePimSurveyMachine from '../../contexts/survey-context';
import useSurveyValidation from '../../contexts/survey-validation-context';

const Input = props => {
  const { send } = usePimSurveyMachine();
  const { validator } = useSurveyValidation();

  const { name, onBlur, onFocus, onChange, ...rest } = props;
  return (
    <HollywoodInput
      name={name}
      id={name}
      {...rest}
      hasError={validator.hasError(name)}
      onBlur={e => {
        validator.touch(name);
        send({ type: 'BLUR', name });
        if (onBlur) {
          onBlur(e);
        }
      }}
      onChange={(e, v) => {
        validator.touch(name);
        if (onChange) onChange(e, v);
      }}
      onFocus={e => {
        send({ type: 'FOCUS', name });
        if (onFocus) {
          onFocus(e);
        }
      }}
    />
  );
};

Input.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  step: PropTypes.number,
  suffix: PropTypes.string,
  testId: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'url', 'password', 'date', 'search', 'tel']),
  value: PropTypes.string,
};

Input.defaultProps = {
  id: null,
  autoFocus: false,
  className: null,
  disabled: false,
  max: null,
  maxLength: null,
  min: null,
  minLength: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  onKeyDown: null,
  placeholder: null,
  prefix: null,
  step: null,
  suffix: null,
  testId: null,
  type: 'text',
  value: null,
};

export default Input;
