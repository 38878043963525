import React, { useRef } from 'react';
import { connect } from 'react-redux';
import Button from 'hollywood/dist/components/Button';
import Icon from '@zing/neo-common/dist/components/Icon';
import PropTypes from 'prop-types';
import { get } from '@zing/neo-common/dist/lib/safe';
import usePimSurveyMachine from '../../../contexts/survey-context';
import { useParams } from 'react-router-dom';

const RoofImage = ({ index, offline, accessToken }) => {
  const inputRef = useRef(null);
  const { current, send } = usePimSurveyMachine();
  const { currentComparison } = current.context;
  const { roofs } = current.context.comparisons[currentComparison];
  const roof = roofs[index];

  const { id: appUuid } = useParams();

  // Offline - do nothing
  if (offline.isOffline) {
    return null;
  }

  const currentState = current.value.comparison[currentComparison].roofs[`roof${index + 1}`];

  if (currentState === 'failure' && inputRef.current) {
    inputRef.current.value = '';
  }

  // If there is already an image
  if (roof.image) {
    const imageSrc = `${process.env.REACT_APP_API_STATIC_BASE_URL}/quote-attachments/${roof.image}`;
    return (
      <div className="roof__image">
        <h3 className="tight">Roof Image</h3>
        <a href={imageSrc} target="_blank" rel="noopener noreferrer" data-testid="roof-image-image" className="roof__image__image">
          <img src={imageSrc} alt="" width="80" height="80" />
        </a>
        <Button
          format="secondary"
          size="small"
          type="button"
          onClick={() => {
            send({ type: 'ROOF_IMAGE_DELETE', currentComparison, index });
          }}
          testId="roof-image-delete-button"
        >
          <Icon icon="trash" /> Delete image
        </Button>
      </div>
    );
  }

  return (
    <div className="roof__image">
      <h3 className="tight">Roof Image</h3>
      <input
        id="file"
        type="file"
        accept="image/*"
        data-testid="roof-image-input"
        className="roof__image__input"
        onChange={event => {
          send({ type: 'ROOF_IMAGE_UPLOAD', currentComparison, index, file: event.target.files[0], appUuid, accessToken });
        }}
        ref={inputRef}
      />
      <label htmlFor="file" data-testid="roof-image-upload-button" className="hollywood-btn hollywood-btn--secondary hollywood-btn--small">
        <span className="plus">+</span> Upload
        {currentState === 'uploading' ? 'ing' : ' image'}
      </label>
      {currentState === 'failure' && <p className="roof__image__error">{roof.uploadError}</p>}
    </div>
  );
};

RoofImage.propTypes = {
  index: PropTypes.number.isRequired,
  offline: PropTypes.shape({
    isOffline: PropTypes.bool.isRequired,
  }).isRequired,
  accessToken: PropTypes.string.isRequired,
};

export default connect(({ offline, auth }) => ({
  offline,
  accessToken: get(auth, 'access_token'),
}))(RoofImage);
