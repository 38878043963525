import * as Yup from 'yup';
import { REQUIRED_PRODUCT } from '../../../../components/Survey/validate/messages';

const schema = comparison =>
  Yup.object().shape({
    context: Yup.object().shape({
      comparisons: Yup.object().shape({
        [comparison]: Yup.object().shape({
          battery: Yup.string().when(['$context.currentComparison', '$context.comparisons', '$value.comparison'], {
            is: (currentComparison, comparisons, value) =>
              value[currentComparison].battery === 'selected' && comparisons[currentComparison].battery === undefined,
            then: Yup.string().required(REQUIRED_PRODUCT),
          }),
        }),
      }),
    }),
    value: Yup.object().shape({
      comparison: Yup.object().shape({
        [comparison]: Yup.object().shape({
          battery: Yup.string()
            .oneOf(['idle', 'selected'])
            .required(REQUIRED_PRODUCT),
        }),
      }),
    }),
  });

export default schema;
